import React, { PureComponent } from 'react';
import { styled, StyleSheet } from '@rexlabs/styling';

const defaultStyles = StyleSheet({
  progressBar: {
    marginLeft: '-.2rem',
    marginRight: '-.2rem',
    height: '.2rem',
    background: ({ token }) => token('color.neutral.idle.default')
  },

  progress: {
    width: '100%',
    height: '100%',
    background: ({ token }) =>
      token('campaignWizard.progressBar.color', token('palette.brand.600')),
    transition: 'transform .3s',
    transform: 'translateX(-100%)',
    transformOrigin: 'top left',
    borderRadius: '.2rem'
  }
});

@styled(defaultStyles)
class ProgressBar extends PureComponent {
  render() {
    const { styles: s, progress } = this.props;
    let realProgress = progress * 100;
    if (realProgress > 100) {
      realProgress = 100;
    }
    return (
      <div {...s('progressBar')}>
        <div
          {...s.with('progress')({
            transform: `translateX(${realProgress - 100}%)`
          })}
        />
      </div>
    );
  }
}

export default ProgressBar;

import { Box } from '@rexlabs/box';
import React from 'react';
import { HelpIcon } from 'src/view/components/tooltip';
import Body from './body';

const BodyWithHelpIcon = ({
  text,
  helpIconPlacement = 'bottom-start',
  helpIconStyle,
  helpIconText,
  ...props
}) => {
  return (
    <Body {...props}>
      {text}&nbsp;
      {helpIconText && (
        <HelpIcon
          placement={helpIconPlacement}
          Content={() => (
            <Box style={helpIconStyle}>
              <Body>{helpIconText}</Body>
            </Box>
          )}
        />
      )}
    </Body>
  );
};

export default BodyWithHelpIcon;

/* eslint-disable max-lines */
import ActionMenu from '@rexlabs/action-menu';
import { GhostCircleButton } from '@rexlabs/button';
import { withModel } from '@rexlabs/model-generator';
import { withWhereabouts } from '@rexlabs/react-whereabouts';
import { match } from '@rexlabs/whereabouts';
import React, { ReactElement, useEffect, useState } from 'react';
import { compose } from 'redux';
import ActionMenuIcon from 'src/assets/icons/action-menu.svg';
import sessionModel from 'src/data/models/custom/session';
import campaignsModel from 'src/data/models/entities/campaigns';
import ROUTES from 'src/routes/admin';
import { Runnable } from 'src/types';
import { useModal } from 'src/utils/modal';
import { push } from 'src/utils/whereabouts';
import AdsPreviewModal from 'src/view/modals/admin/campaign/ads-preview';
import CopyTokenModal from 'src/view/modals/admin/campaign/copy-token';
import PreviewLandingPageModal from 'src/view/modals/admin/campaign/landing-page-preview';
import ShareCampaignModal from 'src/view/modals/admin/campaign/share';

interface ModalProps {
  campaignId: string;
  closeModal(): void;
  match: { params: { campaignId: string } };
  whereabouts: any;
}

interface ModalData {
  component?: (props: ModalProps) => JSX.Element;
  route?: {
    config: {
      path: string;
      Component: any;
    };
  };
  routeParams?: Record<string, any>;
}

interface ActionMenuItem {
  label: string;
  onClick(): void;
}

const NO_MODAL: ModalData = {
  component: undefined,
  route: undefined,
  routeParams: {}
};

function CampaignWithStrategyActionMenu({
  campaign,
  whereabouts
}): ReactElement | null {
  const [modal, setModal] = useState(NO_MODAL);
  const modalController = useModal();

  useEffect(() => {
    if (
      match(whereabouts, ROUTES.CAMPAIGN.config) &&
      modal.route &&
      !match(whereabouts, modal.route.config)
    ) {
      push(modal.route, {
        params: { campaignId: campaign.id, ...modal.routeParams }
      });
    }
  });
  const closeModal: Runnable = () =>
    setModal({
      ...NO_MODAL,
      route: ROUTES.CAMPAIGN
    });

  const type = campaign?.type?.id;
  const commercialListing = campaign?.type_label === 'Commercial Listing';
  const isListing =
    ['single_listing', 'multi_listing_sold'].includes(type) ||
    commercialListing;
  const items: ActionMenuItem[] = [];
  const hasLandingPage = [
    'single_listing',
    'multi_listing_sold',
    'flex'
  ].includes(type);

  const strategyCampaignReport = match(whereabouts, {
    path: '/:accountIndex([0-9]+)/strategies/:strategyId/campaign/:campaignId'
  });

  items.push({
    label: 'Preview Ads',
    onClick: () =>
      modalController.openModal({
        title: 'Ads Preview',
        subtitle: campaign.name,
        contents: <AdsPreviewModal campaignId={campaign.id} />
      })
  });

  if (isListing) {
    items.push({
      label: 'Copy Token',
      onClick: () =>
        modalController.openModal({
          title: 'Campaign Token',
          subtitle: campaign.name,
          contents: <CopyTokenModal campaignId={campaign.id} />
        })
    });
  }

  if (hasLandingPage) {
    items.push({
      label: 'Preview Landing Page',
      onClick: () =>
        modalController.openModal({
          title: 'Landing Page Preview',
          subtitle: campaign.name,
          contents: (
            <PreviewLandingPageModal
              campaignId={campaign.id}
              campaigns={undefined}
            />
          )
        })
    });
  }

  if (!strategyCampaignReport) {
    items.push({
      label: 'Share Report',
      onClick: () =>
        modalController.openModal({
          title: 'Share Campaign Report',
          subtitle: campaign.name,
          contents: (
            <ShareCampaignModal
              campaignId={campaign.id}
              closeModal={modalController.closeModal}
            />
          )
        })
    });

    items.push({
      label: 'View Campaign',
      onClick: () =>
        push(ROUTES.STRATEGY_CAMPAIGN_REPORT, {
          params: {
            campaignId: campaign.id,
            strategyId: campaign.campaign_strategy.id
          }
        })
    });
  }

  const modalProps = {
    match: { params: { campaignId: campaign.id } },
    closeModal,
    whereabouts,
    campaignId: campaign.id
  };
  const Modal = modal.component;

  return (
    <span>
      <ActionMenu
        Icon={ActionMenuIcon}
        Button={GhostCircleButton}
        items={items}
        minWidth='190px'
      />

      {Modal && <Modal {...modalProps} />}
    </span>
  );
}

export default compose(
  withWhereabouts,
  withModel(campaignsModel),
  withModel(sessionModel)
)(CampaignWithStrategyActionMenu);

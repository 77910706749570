import { Box } from '@rexlabs/box';
import reduce from 'lodash/reduce';
import React, { CSSProperties } from 'react';
import { TooltipStateful } from '../../tooltip';

export type AdStats = {
  active?: number;
  creating?: number;
  in_review?: number;
  failed?: number;
};

export type AdsProps = {
  ads: AdStats;
  width: CSSProperties['width'];
};

export const Ads = ({ ads, width }: AdsProps) => {
  const { active = 0, creating = 0, in_review = 0, failed = 0 } = ads;
  const numAds = reduce(
    [active, creating, in_review, failed],
    (amt, res = 0) => amt + res
  );

  const content = (
    <div>
      <div>
        <strong>{active}</strong> active
      </div>
      <div>
        <strong>{creating}</strong> creating
      </div>
      <div>
        <strong>{in_review}</strong> in review
      </div>
      <div>
        <strong>{failed}</strong> failed
      </div>
    </div>
  );

  return (
    <Box style={{ width }}>
      <TooltipStateful
        placement='bottom'
        offset='0px'
        openOn={'HOVER'}
        closeOn={'HOVER'}
        hoverTimeout={250}
        closeDuration={250}
        Content={() => content}
      >
        <span>{numAds}</span>
      </TooltipStateful>
    </Box>
  );
};

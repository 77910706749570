import React, { FC, useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import { useCreateResourceDownload } from '../api/createResourceDownload';
import { useToast } from '@rexlabs/notifications';
import { StepEditorLayout } from 'src/features/wizard/components/StepEditorLayout';
import { ContentTypeEditorProps } from 'src/features/wizard';
import {
  ResourceDownloadForm,
  ResourceDownloadSubmitHandler
} from './ResourceDownloadForm';
import { ResourceDownloadFormGuide } from './ResourceDownloadFormGuide';

export type ResourceDownloadCreateFormProps = {
  resourceId?: string;
};

export const ResourceDownloadCreateForm: FC<ContentTypeEditorProps> = ({
  selectContent
}) => {
  const navigate = useNavigate();
  const toasts = useToast();

  const { mutateAsync: createResourceDownload } = useCreateResourceDownload();

  const handleCreate = useCallback<ResourceDownloadSubmitHandler>(
    async (data) => {
      try {
        await createResourceDownload(data).then((res) => {
          selectContent?.(res.id);
          navigate('../ResourceDownload');
        });
      } catch (e) {
        toasts.addToast({
          type: 'error',
          title: "Couldn't create resource download",
          description: e instanceof Error ? e.message : undefined
        });
      }
    },
    [createResourceDownload, navigate, selectContent, toasts]
  );

  const exitEditor = useCallback(() => navigate('..'), [navigate]);

  return (
    <StepEditorLayout
      title='Upload Resource'
      Preview={<ResourceDownloadFormGuide />}
    >
      <ResourceDownloadForm
        submitLabel='Save'
        onSubmit={handleCreate}
        onCancel={exitEditor}
      />
    </StepEditorLayout>
  );
};

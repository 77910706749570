import { Box } from '@rexlabs/box';
import { styled, StyleSheet, useToken } from '@rexlabs/styling';
import React from 'react';
import { FONT } from 'src/theme';

const defaultStyles = StyleSheet({
  container: {
    fontSize: '1.4rem',
    fontWeight: FONT.WEIGHTS.SEMIBOLD,
    color: ({ token }) => token('legacy.color.blue.grey'),
    borderBottom: ({ token }) =>
      `.1rem solid ${token('legacy.color.blue.greyLight')}`
  }
});

function UserListHeader(props) {
  const { styles: s } = props;
  const token = useToken();

  return (
    <Box
      p={token('spacing.m')}
      pt={0}
      flexDirection='row'
      alignItems='center'
      justifyContent='space-between'
      {...s('container')}
    >
      <Box flex={1}>Name</Box>
      <Box style={{ width: '28rem' }}>Email</Box>
      <Box style={{ width: '16rem' }}>Status</Box>
      <Box style={{ width: '7rem' }} />
    </Box>
  );
}

export default styled(defaultStyles)(UserListHeader);

import React, { PureComponent } from 'react';
import { styled, StyleSheet } from '@rexlabs/styling';
import getContrastColor from '../../../utils/contrast-color';

const defaultStyles = StyleSheet({
  container: {
    position: 'absolute',
    top: '44px',
    left: '44px',
    fontSize: '32px',
    fontWeight: '900',
    fontFamily: 'Circular Pro, Helvetica, Arial, sans-serif',
    textTransform: 'uppercase',
    color: '#fff',
    padding: '20px 48px'
  },

  gorditaFont: {
    fontFamily: 'Gordita, Circular Pro, Helvetica, Arial, sans-serif'
  },

  latoFont: {
    fontFamily: 'Lato, Circular Pro, Helvetica, Arial, sans-serif'
  }
});

@styled(defaultStyles)
class FacebookBanner extends PureComponent {
  render() {
    const { styles: s, adContentSet, gorditaFont, latoFont } = this.props;
    return (
      <div
        {...s.with('container', { gorditaFont, latoFont })({
          backgroundColor: adContentSet.brandColor
        })}
      >
        <span style={{ color: getContrastColor(adContentSet.brandColor) }}>
          {adContentSet.banner}
        </span>
      </div>
    );
  }
}

export default FacebookBanner;

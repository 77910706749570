import React from 'react';
import { FormField } from 'src/view/components/form';
import { TextArea, TextInput } from 'src/view/components/input';

const LandingPageTemplateFields = ({ template }) => {
  if (template === 'maximum_leads') {
    return (
      <>
        <FormField
          name='template_variables.maximum_leads.lead_capture_form_heading'
          label='Lead capture form headline'
          Input={TextInput}
        />
        <FormField
          name='template_variables.maximum_leads.lead_capture_form_sub_heading'
          label='Lead capture form subheading'
          Input={TextInput}
        />
        <FormField
          name='template_variables.maximum_leads.lead_capture_form_text'
          label='Lead capture form text'
          Input={TextArea}
          inputProps={{
            charLimit: 90,
            isSoftLimit: true,
            showCharLimit: true,
            style: {
              height: '100%',
              overflow: 'auto',
              resize: 'none'
            }
          }}
        />
      </>
    );
  } else if (template === 'qualitative_leads') {
    return (
      <>
        <FormField
          name='template_variables.qualitative_leads.lead_capture_form_heading'
          label='Lead capture form headline'
          Input={TextInput}
        />
        <FormField
          name='template_variables.qualitative_leads.lead_capture_form_sub_heading'
          label='Lead capture form subheading'
          Input={TextInput}
        />
        <FormField
          name='template_variables.qualitative_leads.lead_capture_form_text'
          label='Lead capture form text'
          Input={TextArea}
          inputProps={{
            charLimit: 90,
            isSoftLimit: true,
            showCharLimit: true,
            style: {
              height: '100%',
              overflow: 'auto',
              resize: 'none'
            }
          }}
        />
      </>
    );
  } else if (template === 'multi_listing_sold') {
    return (
      <>
        <FormField
          name='template_variables.multi_listing_sold.page_headline'
          label='Page headline'
          Input={TextInput}
        />
        <FormField
          name='template_variables.multi_listing_sold.lead_capture_form_heading'
          label='Lead capture form headline'
          Input={TextInput}
        />
        <FormField
          name='template_variables.multi_listing_sold.lead_capture_form_sub_heading'
          label='Lead capture form subheading'
          Input={TextInput}
        />
        <FormField
          name='template_variables.multi_listing_sold.lead_capture_form_button_text'
          label='Lead capture form button'
          Input={TextInput}
        />
      </>
    );
  } else {
    return null;
  }
};

export default LandingPageTemplateFields;

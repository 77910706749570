import { Box } from '@rexlabs/box';
import { withModel } from '@rexlabs/model-generator';
import { styled, StyleSheet, useToken } from '@rexlabs/styling';
import React from 'react';
import { compose } from 'redux';
import session from 'src/data/models/custom/session';
import wizardModel from 'src/data/models/custom/wizard';
import WIZARD from 'src/routes/wizard';
import { FONT } from 'src/theme';
import { currency } from 'src/utils/format';
import { Link } from 'src/utils/whereabouts';
import { FormField } from 'src/view/components/form';
import Slider from 'src/view/components/input/slider';
import Money, { currencyCodes } from 'src/view/components/money';
import { Tiny } from 'src/view/components/text';

import Warning from 'src/assets/icons/error.svg';

const defaultStyles = StyleSheet({
  warning: {
    padding: ({ token }) => token('spacing.xxs'),
    background: ({ token }) => token('legacy.color.orange.light'),
    color: ({ token }) => token('legacy.color.brown.default'),
    marginBottom: ({ token }) => token('spacing.xl'),

    '> svg': {
      minWidth: '2.3rem',
      margin: ({ token }) => token('spacing.xs'),
      color: ({ token }) => token('legacy.color.orange.default')
    }
  }
});

export function mapPropsToValues(props) {
  const { customPackage } = props;
  return {
    facebook: parseInt(customPackage?.budget?.facebook ?? 100),
    google: parseInt(customPackage?.budget?.google ?? 100),
    duration: customPackage?.duration?.value ?? 5,
    is_default: customPackage?.is_default
  };
}

export function handleSubmit(values, { props }) {
  const { customPackage, createPackage, updatePackage, campaigns } = props;

  const newCustomPackage = {
    id: customPackage?.id,
    data: {
      campaignId: campaigns?.item?.data?.id,
      budget: {
        facebook: values?.facebook,
        google: values?.google
      },
      duration: {
        value: values?.duration ?? 7
      },
      is_default: !!values?.is_default
    }
  };

  if (customPackage?.id) {
    return updatePackage(newCustomPackage);
  }
  return createPackage(newCustomPackage);
}

function EditBudgetForm({
  values,
  closeModal,
  fbNetworkEnabled,
  gNetworkEnabled,
  durationEnabled,
  readOnly,
  session,
  isCatalogCampaign = false,
  wizard: { setStep },
  styles: s
}) {
  const token = useToken();
  const agency = session.accounts.find((a) => a.id === session.currentAccountId)
    .agencies.data[0];
  const countryCode = agency.address.country.id;
  const locale = `en-${countryCode}`;
  const currencyCode = currencyCodes[countryCode];
  const currencySymbol = currency(0, true, locale, currencyCode).replace(
    /\d/g,
    ''
  );
  const duration = values?.duration;
  const minDailySpend = countryCode === 'GB' ? 5 : 7.5;

  const auFbMinPrice = isCatalogCampaign ? 400 : 50;
  const ukFbMinPrice = isCatalogCampaign ? 200 : 20;
  const fbMinPrice = countryCode === 'GB' ? ukFbMinPrice : auFbMinPrice

  const facebookWarning = fbNetworkEnabled
    ? values?.facebook / duration < minDailySpend && 'Facebook'
    : false;

  const googleWarning = gNetworkEnabled
    ? values?.google / duration < minDailySpend && 'Google Ads'
    : false;

  const networkWarnings = [facebookWarning, googleWarning].filter(
    (warning) => warning !== false
  );

  return (
    <Box>
      {!(!fbNetworkEnabled && readOnly) && (
        <FormField
          name='facebook'
          Input={Slider}
          inputProps={{
            min: fbMinPrice,
            max: 5000,
            prefix: currencySymbol,
            title: 'Facebook Ads',
            subtitle: 'Includes Facebook, Instagram and Messenger.',
            warning: !!facebookWarning,
            isDisabled: !fbNetworkEnabled,
            readOnly,
            EnableLink: (
              <Link to={WIZARD.EDIT_AUDIENCE}>
                {({ onClick, target }) => (
                  <a
                    href={target}
                    onClick={(e) => {
                      closeModal();
                      setStep({ step: 2 });
                      onClick(e);
                    }}
                  >
                    edit audience settings
                  </a>
                )}
              </Link>
            )
          }}
        />
      )}
      {!(!gNetworkEnabled && readOnly) && (
        <FormField
          name='google'
          Input={Slider}
          inputProps={{
            min: countryCode === 'GB' ? 20 : 50,
            max: 5000,
            prefix: currencySymbol,
            title: 'Google Ads',
            subtitle: 'Includes Websites, YouTube and Mobile apps.',
            warning: !!googleWarning,
            isDisabled: !gNetworkEnabled,
            readOnly,
            EnableLink: (
              <Link to={WIZARD.EDIT_AUDIENCE}>
                {({ onClick, target }) => (
                  <a
                    href={target}
                    onClick={(e) => {
                      closeModal();
                      setStep({ step: 2 });
                      onClick(e);
                    }}
                  >
                    edit audience settings
                  </a>
                )}
              </Link>
            )
          }}
        />
      )}
      {durationEnabled && (
        <FormField
          name='duration'
          Input={Slider}
          inputProps={{
            min: 3,
            max: 45,
            suffix: 'days',
            title: 'Campaign duration',
            readOnly
          }}
        />
      )}
      {(facebookWarning || googleWarning) && (
        <Box flex={1} {...s('warning')}>
          <Warning />
          <Tiny style={{ marginRight: token('spacing.xs') }}>
            Daily ad spends for{' '}
            {networkWarnings
              .map((network) => (
                <span
                  key={network}
                  style={{ fontWeight: FONT.WEIGHTS.SEMIBOLD }}
                >
                  {network}
                </span>
              ))
              .reduce((prev, curr) => [prev, ' and ', curr])}{' '}
            are too low. We recommend a daily spend of at least{' '}
            <Money
              amount={countryCode === 'GB' ? 5 : 7.5}
              currencyCode={currencyCode}
            />{' '}
            per network. Your campaign may perform poorly. To increase daily
            spend, increase budget or reduce campaign duration.
          </Tiny>
        </Box>
      )}
    </Box>
  );
}

export default compose(
  styled(defaultStyles),
  withModel(wizardModel),
  withModel(session)
)(EditBudgetForm);

import { Box } from '@rexlabs/box';
import { StyleSheet, useStyles } from '@rexlabs/styling';
import { Body, Heading } from '@rexlabs/text';
import React, { Children, CSSProperties, ReactNode } from 'react';

const defaultStyles = StyleSheet({
  container: {
    width: '100%',
    background: ({ token }) => token('color.container.static.contrast'),
    minHeight: '40rem'
  },

  largeText: {
    fontWeight: ({ token }) => token('typography.weight.light')
  },

  smallText: {
    color: ({ token }) => token('color.textStyle.body.subtext'),
    marginTop: '1.5rem',
    textAlign: 'center'
  }
});

export type EmptyStateSectionProps = {
  largeText?: ReactNode;
  smallText?: ReactNode;
  minHeight?: CSSProperties['minHeight'];
  styles?: CSSProperties;
  className?: string;
  children?: ReactNode;
  customBody?: ReactNode;
};

function EmptyStateSection({
  largeText,
  smallText,
  minHeight,
  children,
  styles,
  className,
  customBody
}: EmptyStateSectionProps) {
  const s = useStyles(defaultStyles);

  if (children) {
    const [header, ...subtitle] = Children.toArray(children);
    largeText = header;
    if (subtitle.length > 0) {
      smallText = <>{subtitle}</>;
    }
  }

  return (
    <Box
      {...s.with('container')({
        styles: {
          ...styles,
          minHeight
        },
        className
      })}
      flexDirection='column'
      alignItems='center'
      justifyContent='center'
    >
      {largeText && (
        <Heading
          level={2}
          {...s.with('largeText')({
            style: {
              marginBottom: smallText && '1.5rem'
            }
          })}
        >
          {largeText}
        </Heading>
      )}
      {smallText && <Body {...s('smallText')}>{smallText}</Body>}
      {customBody}
    </Box>
  );
}

export default EmptyStateSection;

import { GhostButton } from '@rexlabs/button';
import { StyleSheet } from '@rexlabs/styling';
import { memoize } from 'lodash';
import React, { PureComponent } from 'react';
import { withToken } from 'src/theme';
import { ModalStickyButtonGroup } from 'src/view/components/button';
import { List, StatsListHeader, StatsListItem } from 'src/view/components/list';
import { Modal } from 'src/view/components/modal';
import { statsReducer } from './conversions';

const modalStyles = StyleSheet({
  container: {
    height: '62rem'
  }
});

const listStyles = StyleSheet({
  container: {
    flexGrow: 1,

    '@media print': {
      flex: '1 1 50%'
    }
  },

  seperator: {
    width: ({ token }) => `calc(100% - (${token('spacing.xl')} * 2))`,
    margin: 'auto'
  }
});

@withToken
class LpEngagement extends PureComponent {
  memItems = memoize(this.items, () => 'other');

  items(lpStats, lpMeta, categoryId) {
    return Object.values(lpStats.reduce(statsReducer(lpMeta, categoryId), []));
  }

  render() {
    const { stats, token } = this.props;
    /* eslint-disable camelcase */
    const lpStats = stats?.landing_page_stats?.data ?? [];
    const lpMeta = stats?.landing_page_stats?.meta;
    const lpStatsTotal = stats?.summary?.landing_page_other_events;
    /* eslint-enable camelcase */

    const items = this.memItems(lpStats, lpMeta, 'other').filter(
      (i) => i.label !== 'Start filling the enquiry form'
    );

    return (
      <List
        items={items}
        isLoading={stats?.item?.status === 'loading'}
        getItemKey={(campaign) => campaign.id}
        Header={() => (
          <StatsListHeader
            title='Landing page engagement'
            description='This data shows how interested your visitors are, and provides meaningful insights into how they’re engaging with your landing page.'
            value={lpStatsTotal}
          />
        )}
        Item={({ data: stat }) => <StatsListItem stat={stat} />}
        animateRows={false}
        style={{ marginBottom: token('spacing.m'), overflow: 'auto' }}
        styles={listStyles}
      />
    );
  }
}

function LpEngagementModal({ subTitle, closeModal, ...props }) {
  return (
    <Modal
      title='Landing page engagement'
      subtitle={subTitle}
      onClose={closeModal}
      styles={modalStyles}
      noPadding
    >
      <LpEngagement {...props} />

      <ModalStickyButtonGroup padded>
        <GhostButton onClick={closeModal}>Close</GhostButton>
      </ModalStickyButtonGroup>
    </Modal>
  );
}

export { LpEngagementModal, LpEngagement };

import { filter } from 'lodash';

// not entirely acturate si symbol billion is G but has been changed to B
const SI_SYMBOL = ['', 'K', 'M', 'B', 'T', 'P', 'E'];

export function number(number) {
  return parseFloat(number || 0).toLocaleString('en-AU');
}

export function abbreviateNumber(number: number | string = 0, after = 0) {
  const parsedNumber = typeof number === 'string' ? parseFloat(number) : number;

  if (parsedNumber < after) return parsedNumber.toLocaleString('en-AU');

  // what tier? (determines SI symbol)
  const tier = (Math.log10(parsedNumber) / 3) | 0;

  // if zero, we don't need a suffix
  if (tier === 0) return parsedNumber.toLocaleString('en-AU');

  // get suffix and determine scale
  const suffix = SI_SYMBOL[tier];
  const scale = Math.pow(10, tier * 3);

  // scale the number
  const scaled = parsedNumber / scale;

  // Determine whether to render the number as an integer or a decimal
  const fractional = Math.floor((scaled % 1) * 100);
  const precision = fractional === 0 ? 0 : 1;

  // format number and add suffix
  return `${scaled.toFixed(precision)} ${suffix}`;
}

export function thousands(number, kAfter = 1000) {
  const parsedNumber = parseFloat(number || 0);
  if (parsedNumber >= kAfter) {
    return Math.round(parsedNumber / 1000).toLocaleString('en-AU') + 'k';
  }
  return (number || 0).toLocaleString('en-AU');
}

export function currency(
  amount: number | string = 0,
  integer = false,
  locale = 'en-AU',
  currency = 'AUD'
) {
  const number = typeof amount === 'string' ? parseFloat(amount) : amount;
  return number.toLocaleString(locale, {
    style: 'currency',
    currency,
    minimumFractionDigits: integer ? 0 : 2,
    maximumFractionDigits: integer ? 0 : 2
  });
}

export function percent(value: number | string = 0) {
  const number = typeof value === 'string' ? parseFloat(value) : value;
  return `${Math.floor(number * 10000) / 100}%`;
}

export function joinArray(array, joinStr, lastJoinStr) {
  if (array.length === 1) {
    return array[0];
  }
  const others = array.slice(0, -1);
  return others.join(joinStr) + lastJoinStr + array[array.length - 1];
}

export function timeFromSeconds(value) {
  const totalSeconds = parseFloat(value);

  const hours = Math.floor(totalSeconds / 3600);
  const minutes = Math.floor((totalSeconds % 3600) / 60);
  const seconds = Math.floor(totalSeconds % 60);

  const parts = [hours || null, minutes, seconds];

  return filter(parts, (value) => value !== null)
    .map((amt) => ('' + amt).padStart(2, '0'))
    .join(':');
}

export const interpolateRegex = /\${[^{]+}/g;

export function interpolate(string, params) {
  return string.replace(interpolateRegex, (match) => {
    const key = match.substring(2, match.length - 1);
    return params[key];
  });
}

/**
 * Creates a URL friendly slug of a string.
 * The slug will be in lowercase kebab-case with special characters removed.
 *
 * Will shortcut if `value` is undefined.
 * @param value The string to slugify
 * @returns The input string transformed into a slug
 */
export function slugify(value: undefined): undefined;
export function slugify(value: string): string;
export function slugify(value: string | undefined): string | undefined;
export function slugify(value: string | undefined): string | undefined {
  return value
    ?.toLowerCase()
    .trim()
    .replace(/[^\w\s-]/g, '')
    .replace(/[\s_-]+/g, '-')
    .replace(/^-+|-+$/g, '');
}

import { StyleSheet, useStyles } from '@rexlabs/styling';
import React, { FC } from 'react';
import { EmptyInput, EmptyInputProps } from '../EmptyInput';

const styles = StyleSheet({
  container: {
    height: '4rem'
  }
});

export type SimpleEmptyInputProps = Omit<
  EmptyInputProps,
  'className' | 'style'
>;

export const SimpleEmptyInput: FC<SimpleEmptyInputProps> = (props) => {
  const s = useStyles(styles, 'SimpleEmptyInput');
  return <EmptyInput {...s('container')} {...props} />;
};

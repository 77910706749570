export const audienceKeys = {
  detail: (accountId: string, campaignId: string) =>
    [accountId, 'audience', campaignId] as const
};

export const customAudienceKeys = {
  all: (accountId: string) => [accountId, 'custom-audiences'] as const,
  lists: (accountId: string) =>
    [...customAudienceKeys.all(accountId), 'lists'] as const,
  list: (accountId: string, params: Record<string, unknown>) =>
    [...customAudienceKeys.lists(accountId), params] as const,
  details: (accountId: string) =>
    [...customAudienceKeys.all(accountId), 'details'] as const,
  detail: (accountId: string, customAudienceId: string) =>
    [...customAudienceKeys.details(accountId), customAudienceId] as const
};

import { StyleSheet, useStyles } from '@rexlabs/styling';
import React, {FC, ReactNode, useCallback, useState} from 'react';
import { AdType } from 'src/features/campaign-definitions';
import { CommonData, TemplateData } from '../types';
import { parseAdTypes } from '../utils/helpers';
import { AdPreview } from './AdPreview';
import { NavigationHeader } from './NavigationHeader';

const styles = StyleSheet({
  container: {
    width: '100%',
    height: 'auto',
    overflowX: 'hidden',
    overflowY: 'scroll'
  },
  previewContainer: {
    width: '100%',
    maxHeight: '830px',
    padding: ({ token }) => `${token('spacing.xxl')} 0`,
    borderRadius: ({ token }) => token('border.radius.l'),
    overflow: 'hidden'
  }
});

export type AdPreviewCarouselProps = {
  initSlidePos?: number;
  previewTitleStyle: {
    large: boolean;
    modal?: boolean;
    report?: boolean;
  };
  adTypes: AdType[];
  commonData: CommonData;
  templateData: TemplateData;
  onChange?: (x: { prev: number; current: number }) => void;
  children: ReactNode;
};

export const AdPreviewCarousel: FC<AdPreviewCarouselProps> = ({
  initSlidePos = 0,
  previewTitleStyle,
  adTypes,
  commonData,
  templateData,
  onChange,
  children
}) => {
  const s = useStyles(styles);
  const [slidePos, setSlidePos] = useState({
    current: initSlidePos,
    prev: 0
  });
  const parsedAds = parseAdTypes(adTypes);
  const adCount = parsedAds.length;

  const showNext = useCallback((): void => {
    const { current: prev } = slidePos;
    const current = prev < adCount - 1 ? prev + 1 : adCount - 1;

    setSlidePos({ current, prev });
    onChange?.({
      prev,
      current
    });
  }, [slidePos, adCount, onChange]);

  const showPrev = useCallback((): void => {
    const { current: prev } = slidePos;
    const current = prev > 0 ? prev - 1 : 0;

    setSlidePos({ current, prev });
    onChange?.({
      prev,
      current
    });
  }, [slidePos, onChange]);

  return (
    <div {...s('container')}>
      <NavigationHeader
        previewTitleStyle={previewTitleStyle}
        adTypes={parsedAds}
        slidePos={slidePos.current}
        showPrev={showPrev}
        showNext={showNext}
      >
        {children}
      </NavigationHeader>
      <AdPreview
        {...s('previewContainer', {
          previewContainerModal: previewTitleStyle.modal,
          previewContainerReport: previewTitleStyle.report
        })}
        adTypes={parsedAds}
        commonData={commonData}
        templateData={templateData}
        slidePos={slidePos.current}
      />
    </div>
  );
};

import { Box } from '@rexlabs/box';
import { styled, StyleSheet } from '@rexlabs/styling';
import React from 'react';

import { cropText } from './shared/utils';

import GoogleIconChevron from 'src/assets/ad-previews/google/icon-chevron.svg';
import GoogleIconInfo from 'src/assets/ad-previews/google/icon-info.svg';

const defaultStyles = StyleSheet({
  container: {
    width: '30rem',
    height: '25rem',
    background: 'white',
    position: 'relative',
    border: '.1rem solid #e5e5e5',
    fontFamily: 'Roboto, Arial'
  },

  info: {
    position: 'absolute',
    top: 0,
    right: 0,
    background: 'white',
    color: '#00aecd',
    width: '1.8rem',
    height: '1.8rem'
  },

  image: {
    height: '15.7rem',
    width: '100%',
    background: 'grey no-repeat center center',
    backgroundSize: 'cover',
    position: 'relative',
    overflow: 'hidden'
  },

  heading: {
    padding: '.2rem 1.2rem',
    fontWeight: 700,
    fontSize: '1.4rem'
  },

  description: {
    padding: '.2rem 1.2rem .8rem',
    fontSize: '1rem',
    lineHeight: '1.2',
    fontWeight: 300
  },

  name: {
    padding: '.2rem 1.2rem',
    fontSize: '1rem',
    lineHeight: '1.2',
    fontWeight: 300
  },

  enquireButton: {
    margin: '0 1.2rem',
    fontWeight: 700,
    fontSize: '1.4rem',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
    border: '0 none',
    background: 'transparent',

    '> svg': {
      width: '1.2rem',
      height: '1.2rem',
      marginLeft: '.2rem'
    }
  }
});

const AdWordsNativePreview = (props) => {
  const { styles: s, campaignData, ad } = props;

  const isMultiListingSold = campaignData.type === 'multi_listing_sold';
  const slide =
    campaignData.slides.find((s) => s.id === ad?.attributes?.slide_id) || {};

  let imageUrl;
  let imageCrop;
  let title;
  const titleLength = 30;
  let headline;
  const headlineLength = 90;
  if (isMultiListingSold) {
    const image = campaignData.images.find((i) => i.id === slide?.image?.id);
    imageUrl = image?.sizes?.['crop.landscape']?.url;
    imageCrop = Object.values(image?.crops?.landscape?.top_left ?? {}).join();
    title = cropText(slide.small_text, titleLength);
    headline = cropText(slide.short_headline, headlineLength);
  } else {
    const isAgent = campaignData.type === 'agent_profile';

    const format = ad?.format?.id ?? '';
    const isSecondary = format.includes('responsive_secondary');

    const imageKey = isAgent
      ? 'agentImage'
      : isSecondary
      ? 'secondaryImage'
      : 'primaryImage';

    imageUrl = campaignData?.[imageKey]?.landscape;
    title = cropText(campaignData.title, titleLength);
    headline = cropText(campaignData.headline, headlineLength);
  }

  return (
    <Box {...s('container')} flexDirection='column'>
      <Box {...s('info')} justifyContent='center' alignItems='center'>
        <GoogleIconInfo {...s('infoSvg')} />
      </Box>
      <Box
        key={imageCrop}
        {...s.with('image')({
          backgroundImage: imageUrl ? `url(${imageUrl})` : 'none'
        })}
      />
      <Box {...s('wrapText')} flexDirection='row' alignItems='center' flex={1}>
        <Box {...s('text')} flex={1} flexDirection='column'>
          <span {...s('heading')}>{title}</span>
          <span {...s('description')}>{headline}</span>
          <Box
            flexDirection='row'
            alignItems='center'
            justifyContent='space-between'
          >
            <span {...s('name')}>{campaignData.agency}</span>
            <button {...s('enquireButton')}>
              <span>Enquire Now</span>
              <GoogleIconChevron />
            </button>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default styled(defaultStyles)(AdWordsNativePreview);

/* eslint-disable max-lines */
import { Box } from '@rexlabs/box';
import { Body } from '@rexlabs/text';
import { autobind } from 'core-decorators';
import pick from 'lodash/pick';
import React, { Component } from 'react';
import { formattedBrandName } from 'src/theme/index';
import { interpolate, interpolateRegex } from 'src/utils/format';
import AdvancedSettings from 'src/view/components/advanced-settings';
import ComponentRenderer from 'src/view/components/component-renderer';
import {
  FieldCell,
  FieldRow,
  FormField,
  LabelOptionalField
} from 'src/view/components/form';
import HiddenField from 'src/view/components/form/hidden-field';
import {
  ColorPicker,
  DomainInput,
  OptionWithDescription,
  Select,
  SelectedOptionWithDescription,
  TextInput
} from 'src/view/components/input';
import AgentSelection from 'src/view/components/input/agent/agent-selection';
import LogoImageInput from 'src/view/components/input/image/logo-image-input';
import { AdditionalLeadCaptureEmail } from 'src/view/components/input/landing-page';
import LandingPageTemplateFields from 'src/view/components/input/landing-page-template-fields';
import LandingPageFields from 'src/view/components/landing-page-fields';
import ListingDataSource from 'src/view/components/listing-data-source';
import { Label, Text, Tiny } from 'src/view/components/text';
import { HelpIcon } from 'src/view/components/tooltip';
import LandingPageResources from '../components/input/landing-page-resources';

export const baseValidations = {
  domain: ['required|is_domain|max:62', 'domain'],
  template: ['required', 'landing page objective'],
  lead_capture_email: ['email', 'lead capture email'],
  'branding_overrides.logo': ['required', 'logo'],
  'branding_overrides.brand_colour': ['required', 'brand colour'],
  'branding_overrides.background_colour': ['required', 'background colour']
};

export const multiListingSoldValidations = {
  ...baseValidations,

  'template_variables.multi_listing_sold.lead_capture_form_heading': [
    'required',
    'lead capture form heading'
  ],
  'template_variables.multi_listing_sold.lead_capture_form_sub_heading': [
    'required',
    'lead capture form sub heading'
  ],
  'template_variables.multi_listing_sold.lead_capture_form_button_text': [
    'required',
    'lead capture form button'
  ],
  'template_variables.multi_listing_sold.contact_email': [
    'required',
    'contact email'
  ],
  'template_variables.multi_listing_sold.contact_phone': [
    'required',
    'contact phone'
  ]
};

export const maximumLeadsValidations = {
  ...baseValidations,

  'template_variables.maximum_leads.lead_capture_form_heading': [
    'required',
    'lead capture form headline'
  ],
  'template_variables.maximum_leads.lead_capture_form_sub_heading': [
    'required',
    'lead capture form sub heading'
  ],
  'template_variables.maximum_leads.lead_capture_form_text': [
    'required',
    'lead capture form text'
  ]
};

export const qualitiativeLeadsValidations = {
  ...baseValidations,

  'template_variables.qualitative_leads.lead_capture_form_heading': [
    'required',
    'lead capture form headline'
  ],
  'template_variables.qualitative_leads.lead_capture_form_sub_heading': [
    'required',
    'lead capture form sub heading'
  ],
  'template_variables.qualitative_leads.lead_capture_form_text': [
    'required',
    'lead capture form text'
  ]
};

export const externalValidations = {
  external_url: ['required|url', 'external url']
};

export function mapPropsToValues({ campaigns }) {
  const landingPage = campaigns?.item?.data?.landing_pages?.items?.[0];

  return {
    id: landingPage?.id,
    objective: null,
    is_external: landingPage?.is_external,
    external_url: landingPage?.external_url,
    domain: landingPage?.domain,
    lead_capture_email: landingPage?.lead_capture_email,
    agent: landingPage?.agent?.id,
    virtual_tour_url: landingPage?.virtual_tour_url,
    statement_of_information: campaigns?.item?.data?.statement_of_information,
    template_variables: {
      maximum_leads: {
        lead_capture_form_heading:
          landingPage?.template_variables?.maximum_leads
            ?.lead_capture_form_heading,
        lead_capture_form_sub_heading:
          landingPage?.template_variables?.maximum_leads
            ?.lead_capture_form_sub_heading,
        lead_capture_form_text:
          landingPage?.template_variables?.maximum_leads?.lead_capture_form_text
      },
      qualitative_leads: {
        lead_capture_form_heading:
          landingPage?.template_variables?.qualitative_leads
            ?.lead_capture_form_heading,
        lead_capture_form_sub_heading:
          landingPage?.template_variables?.qualitative_leads
            ?.lead_capture_form_sub_heading,
        lead_capture_form_text:
          landingPage?.template_variables?.qualitative_leads
            ?.lead_capture_form_text
      },
      multi_listing_sold: {
        ...(landingPage?.template_variables?.multi_listing_sold ?? {})
      }
    },
    branding_overrides: {
      logo: landingPage?.branding_overrides?.logo,
      brand_colour: landingPage?.branding_overrides?.brand_colour,
      background_colour: landingPage?.branding_overrides?.background_colour
    },
    template: landingPage?.template?.id,
    type: campaigns?.item?.data?.type?.id
  };
}

function getApiData(values) {
  const {
    is_external,
    external_url,
    template,
    agent,
    template_variables,
    ...rest
  } = values;

  if (is_external) {
    return { is_external, external_url };
  } else {
    return {
      is_external,
      template: { id: template },
      agent: agent ? { id: agent } : {},
      template_variables: pick(template_variables, [template]),
      ...rest
    };
  }
}

export function handleSubmit(values, { props }) {
  const { campaigns } = props;
  return Promise.all([
    campaigns.updateLandingPage({
      id: campaigns?.item?.data?.id,
      landing_page_id: campaigns?.item?.data?.landing_pages?.items?.[0]?.id,
      data: getApiData(values)
    }),
    campaigns.updateItem({
      data: {
        statement_of_information_upload_id: values?.statement_of_information?.id
      }
    })
  ]);
}
@autobind
class EditLandingPageForm extends Component {
  state = {
    showAdvanced: false
  };

  advancedHasErrors(nextProps) {
    const errors = Object.keys(nextProps.errors).filter(
      (err) => nextProps.errors[err] !== undefined
    );
    const errorsInAdvanced = [
      'domain',
      'lead_capture_email',
      'branding_overrides'
    ];
    return errorsInAdvanced.includes(...errors);
  }

  componentWillReceiveProps(nextProps) {
    if (this.advancedHasErrors(nextProps) && !this.state.showAdvanced) {
      this.setState({ showAdvanced: true });
    }
  }

  handleClick() {
    this.setState((prevState) => ({ showAdvanced: !prevState.showAdvanced }));
  }

  togglePageType() {
    this.setState((prevState) => ({
      spokeLandingPage: !prevState.spokeLandingPage
    }));
  }

  renderLogoInput(props) {
    return (
      <Box style={{ width: '12rem' }}>
        <LogoImageInput {...props} />
      </Box>
    );
  }

  render() {
    const {
      values,
      status,
      dataSource,
      listingId,
      setFieldValue,
      campaignTemplate,
      hasRexIntegration
    } = this.props;
    const backgroundColor = values?.branding_overrides?.background_colour;

    return (
      <ComponentRenderer
        template={campaignTemplate}
        resource='landing_page'
        section='edit'
        components={{
          Box,
          Label,
          HelpIcon,
          Text,
          HiddenField,
          LandingPageFields,
          LandingPageTemplateFields,
          LandingPageResources,
          ListingDataSource,
          AdvancedSettings,
          FormField,
          TextInput,
          Select,
          AgentSelection,
          DomainInput,
          ColorPicker,
          FieldRow,
          FieldCell,
          AdditionalLeadCaptureEmail
        }}
        extraPropsFn={({ type, props: componentProps }) => {
          let extraProps;

          if (type === 'Text') {
            extraProps = Object.keys(componentProps).reduce((acc, key) => {
              const prop = componentProps[key];
              if (interpolateRegex.test(prop)) {
                acc[key] = interpolate(prop, { formattedBrandName });
              }
              return acc;
            }, {});
          }

          if (type === 'LandingPageFields') {
            extraProps = {
              isExternal: values.is_external,
              toggleIsExternal: (e) => {
                e.preventDefault();
                setFieldValue('is_external', !values.is_external);
              }
            };
          }

          if (type === 'FormField' && componentProps?.name === 'template') {
            extraProps = {
              label: (
                <span>
                  Pick landing page objective{' '}
                  <HelpIcon
                    Content={() => (
                      <Box style={{ width: '30rem' }}>
                        <Body>
                          {formattedBrandName} generates a slightly different
                          variation of your landing page depending on your main
                          goal.
                        </Body>
                      </Box>
                    )}
                  />
                </span>
              ),
              inputProps: {
                options: [
                  {
                    value: 'maximum_leads',
                    label: 'Get maximum leads',
                    text: 'max_leads',
                    description:
                      'To maximise number of leads, the website visitor can provide an email address to download a full photo gallery.'
                  },
                  {
                    value: 'qualitative_leads',
                    label: 'Get qualified leads',
                    text: 'qualified_leads',
                    description:
                      'To get leads from people who are seriously interested in your property, all property information will be shown to encourage filling out the enquiry form.'
                  },
                  {
                    value: 'phone_calls',
                    label: 'Get more phone calls',
                    text: 'phone_calls',
                    description:
                      'To get more calls, the landing page will emphasise your phone number as the main action to take.'
                  }
                ],
                Option: OptionWithDescription,
                OptionSelected: SelectedOptionWithDescription
              }
            };
          }

          if (type === 'LandingPageTemplateFields') {
            extraProps = {
              template: values?.template
            };
          }

          if (
            type === 'FormField' &&
            componentProps?.name === 'virtual_tour_url'
          ) {
            extraProps = {
              label: <LabelOptionalField text='Virtual tour URL' />
            };
          }

          if (type === 'ListingDataSource') {
            extraProps = {
              dataSource,
              listingId
            };
          }

          if (type === 'AdditionalLeadCaptureEmail') {
            extraProps = {
              label: (
                <LabelOptionalField text='Additional lead capture email'>
                  <HelpIcon
                    Content={() => (
                      <Box style={{ width: '30rem' }}>
                        <Body>
                          Leads will be sent to the agent&apos;s email address
                          by default. Defining an additional lead capture email
                          will also send the lead to that email.
                        </Body>
                      </Box>
                    )}
                  />
                </LabelOptionalField>
              )
            };
          }

          if (
            type === 'FormField' &&
            componentProps?.name === 'branding_overrides.logo'
          ) {
            extraProps = {
              Input: this.renderLogoInput,
              inputProps: {
                ...componentProps.inputProps,
                status,
                backgroundColor
              }
            };
          }

          if (type === 'LandingPageResources') {
            extraProps = {
              values
            };
          }

          if (type === 'AgentSelection') {
            extraProps = {
              readOnlyLeadCaptureEmail: hasRexIntegration
            };
          }

          return extraProps;
        }}
      />
    );
  }
}

export default EditLandingPageForm;

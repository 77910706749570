import { identify } from '@rexlabs/analytics';
import { Box } from '@rexlabs/box';
import { GhostButton, OutlineButton, PrimaryButton } from '@rexlabs/button';
import { Body } from '@rexlabs/text';
import { autobind } from 'core-decorators';
import React, { PureComponent } from 'react';
import { withToken } from 'src/theme';
import { api } from 'src/utils/api-client';
import { ModalStickyButtonGroup } from 'src/view/components/button';
import { Form, FormField, withForm } from 'src/view/components/form';
import { TextInput } from 'src/view/components/input';
import { Modal } from 'src/view/components/modal';
import withError from 'src/view/containers/with-error';

const forgotPasswordForm = {
  name: 'forgotPassword',
  handleSubmit: (values, { props, setMeta }) => {
    return api
      .post('/auth/forgot-password', { email: values.email })
      .then(() => {
        setMeta({ success: true });
      })
      .catch((e) => {
        props.errorModal.open(e);
      });
  },
  mapPropsToValues: (props) => ({
    email: props.email
  })
};

@withToken
@withError('errorModal')
@withForm(forgotPasswordForm)
@autobind
class ForgotPasswordModal extends PureComponent {
  render() {
    const {
      errorModal: { Error },
      forgotPassword,
      closeModal,
      token
    } = this.props;
    return forgotPassword?.meta?.success ? (
      <Box>
        <Box mb={token('spacing.xxl')}>
          <Body>Great! We sent you an email to reset your password.</Body>
        </Box>
        <ModalStickyButtonGroup>
          <OutlineButton onClick={closeModal}>Close</OutlineButton>
        </ModalStickyButtonGroup>
        <Error />
      </Box>
    ) : (
      <Box>
        <Body>
          No worries, just put in your account email address and we’ll send you
          an email to reset your password.
        </Body>
        <Box mb={token('spacing.xxl')}>
          <Form name='forgotPasswordForm'>
            <FormField
              name='email'
              label='Your account email'
              Input={TextInput}
              inputProps={{
                placeholder: 'e.g. ryan@greenrealestate.com.au'
              }}
              onBlur={(e) => {
                identify({
                  properties: { user: { email: e.target.value } }
                });
              }}
            />
          </Form>
        </Box>
        <ModalStickyButtonGroup>
          <GhostButton grey onClick={closeModal}>
            Cancel
          </GhostButton>
          <PrimaryButton
            form='forgotPasswordForm'
            onClick={forgotPassword.submitForm}
            isLoading={forgotPassword.isSubmitting}
          >
            Reset password
          </PrimaryButton>
        </ModalStickyButtonGroup>
        <Error />
      </Box>
    );
  }
}

class Core extends PureComponent {
  render() {
    const { closeModal } = this.props;
    return (
      <Modal title='Forgot password' width='40rem' onClose={closeModal}>
        <ForgotPasswordModal {...this.props} />
      </Modal>
    );
  }
}

export default Core;

import { Column, Grid } from '@rexlabs/grid';
import { useModelState } from '@rexlabs/model-generator';
import { StyleSheet, useStyles, useToken } from '@rexlabs/styling';
import React from 'react';
import { useFeature } from 'shared/hooks';
import sessionModel from 'src/data/models/custom/session';

const defaultStyles = StyleSheet({
  container: {
    fontSize: '1.4rem',
    fontWeight: ({ token }) => token('typography.weight.semibold'),
    color: ({ token }) => token('legacy.color.blue.grey')
  }
});

function CampaignListHeader() {
  const token = useToken();
  const s = useStyles(defaultStyles);
  const session = useModelState(sessionModel);
  const fbLeadAds = useFeature('facebook_lead_ads', session);
  return (
    <Grid
      columns={36}
      gutter={12}
      p={token('spacing.m')}
      pt={0}
      {...s('container')}
    >
      <Column width={fbLeadAds ? 12 : 15}>Name</Column>
      <Column width={5}>Status</Column>
      <Column width={4}>Impressions</Column>
      <Column width={3}>Clicks</Column>
      {fbLeadAds && <Column width={3}>Leads</Column>}
      <Column width={7}>Completion</Column>
      <Column width={2}></Column>
    </Grid>
  );
}

export default CampaignListHeader;

import { Box } from '@rexlabs/box';
import { styled, StyleSheet } from '@rexlabs/styling/lib/index';
import { autobind } from 'core-decorators';
import _ from 'lodash';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import CrossIcon from 'src/assets/icons/cross.svg';
import { Label, Tiny } from 'src/view/components/text';

const defaultStyles = StyleSheet({
  container: {
    marginBottom: '3.4rem'
  },

  fileName: {
    marginTop: '4.7rem'
  },

  progressBarOuter: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    flex: '0 0 auto',
    alignItems: 'center',
    marginTop: '1.2rem',
    marginBottom: '.5rem'
  },

  progressBar: {
    background: ({ token }) => token('color.container.static.contrast'),
    borderRadius: '.4rem',
    position: 'relative',
    height: '.8rem',
    width: '100%'
  },

  progressFilled: {
    background: ({ token }) => token('palette.brand.700'),
    borderRadius: '4px',
    position: 'absolute',
    top: 0,
    left: 0,
    width: '0%',
    height: '8px',
    transition: 'width .25s ease-in'
  },

  cancelOuter: {
    width: '3rem',
    textAlign: 'right'
  },

  crossButton: {
    border: '0 none',
    cursor: 'pointer',

    '> svg': {
      color: ({ token }) => token('legacy.color.blue.grey'),
      height: ({ token }) => token('spacing.m'),
      width: 10
    }
  }
});

@styled(defaultStyles)
@autobind
class VideoInProgress extends Component {
  static propTypes = {
    name: PropTypes.string.isRequired,
    progressPercentage: PropTypes.number.isRequired,
    clickCancel: PropTypes.func
  };

  static defaultProps = {
    clickCancel: _.noop
  };

  render() {
    const { name, progressPercentage, clickCancel, styles: s } = this.props;
    const progressWidth = Math.max(0, Math.min(100, progressPercentage));

    if (progressPercentage < 0 || progressPercentage > 100) {
      console.warn(
        `${progressPercentage} is not a valid value for prop progressPercentage: must be between 0 and 100`
      );
    }

    return (
      <Box {...s('container')} flexDirection='column'>
        <Label {...s('fileName')}>Uploading {name}</Label>

        <div {...s('progressBarOuter')}>
          <div key='progressBar' {...s('progressBar')}>
            <div
              {...s('progressFilled')}
              style={{ width: progressWidth + '%' }}
            />
          </div>
          <div key='cancel' {...s('cancelOuter')}>
            <button {...s('crossButton')} onClick={clickCancel}>
              <CrossIcon />
            </button>
          </div>
        </div>

        <Tiny grey>
          Please don&apos;t close the browser until it&apos;s finished
        </Tiny>
      </Box>
    );
  }
}

export default VideoInProgress;

import { Box } from '@rexlabs/box';
import { styled, StyleSheet } from '@rexlabs/styling';
import React, { PureComponent } from 'react';

const defaultStyles = StyleSheet({
  container: {
    width: '100%',
    paddingLeft: '1.5rem',
    paddingRight: '1.5rem',
    flexShrink: 0
  }
});

@styled(defaultStyles)
class FieldCell extends PureComponent {
  render() {
    const { styles: s, width, children } = this.props;
    return (
      <Box
        flexDirection='column'
        {...s.with('container')(this.props, { width: `${100 * width}%` })}
      >
        {children}
      </Box>
    );
  }
}

export default FieldCell;

import { Box } from '@rexlabs/box';
import { styled, StyleSheet } from '@rexlabs/styling';
import React, { PureComponent } from 'react';

const defaultStyles = StyleSheet({
  filters: {
    width: '100%',
    flexShrink: 0,
    marginTop: ({ token }) => token('spacing.xs'),
    marginBottom: ({ token }) => token('spacing.xs'),
    columnGap: ({ token }) => token('spacing.s')
  }
});

@styled(defaultStyles)
class Filters extends PureComponent {
  render() {
    const { children, styles: s } = this.props;
    return (
      <Box {...s.with('filters')(this.props)} flexDirection='row'>
        {children}
      </Box>
    );
  }
}

export default Filters;

import React from 'react';
import { TextInput as VividTextInput } from '@rexlabs/text-input';
import { StyleSheet } from '@rexlabs/styling';
import { standardStyles } from './text';

const textInputStyles = StyleSheet({
  ...standardStyles,

  container: {
    ...standardStyles.container,
    minHeight: '3rem !important',
    height: '3rem !important'
  }
});

const SmallTextInput = (props) => {
  return <VividTextInput styles={textInputStyles} {...props} />;
};

export default SmallTextInput;

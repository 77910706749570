import React, { PureComponent } from 'react';
import types from 'prop-types';
import {
  ResponsiveContainer,
  LineChart as RechartsLineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip
} from 'recharts';
import { withToken } from 'src/theme';
import YAxisTick from './utils/y-axis-tick';
import TooltipContent from './line-chart-tooltip';

@withToken
class LineChart extends PureComponent {
  static propTypes = {
    lines: types.array.isRequired,
    data: types.array.isRequired
  };

  static defaultProps = {
    YAxisTick
  };

  render() {
    const {
      lines,
      data,
      domain,
      chartMargin,
      width,
      height,
      YAxisTick,
      token
    } = this.props;
    const days = data.length;

    return (
      <ResponsiveContainer width={width} height={height}>
        <RechartsLineChart data={data} margin={chartMargin}>
          <XAxis
            dataKey='x'
            interval={
              days < 8
                ? 0
                : days < 15
                ? 2
                : days < 31
                ? 3
                : days < 61
                ? 5
                : days < 91
                ? 7
                : days < 121
                ? 9
                : 11
            }
            tickMargin={data.length > 151 ? 10 : 20}
            padding={{ left: 15, right: 15 }}
            tickSize={10}
            height={40}
            angle={data.length > 151 ? 30 : 0}
            stroke={token('legacy.color.blue.greyLight')}
          />
          {days > 0 && lines.length <= 2 && (
            <YAxis
              yAxisId='left'
              axisLine={false}
              tickLine={false}
              interval={1}
              tickMargin={20}
              width={40}
              tick={(props) => (
                <YAxisTick
                  {...props}
                  format={lines[0].format}
                  line={lines[0]}
                  lines={lines}
                />
              )}
              domain={domain}
            />
          )}
          {days > 0 && lines.length === 2 && (
            <YAxis
              yAxisId='right'
              width={40}
              orientation='right'
              axisLine={false}
              tickLine={false}
              tick={(props) => (
                <YAxisTick
                  {...props}
                  format={lines[1].format}
                  line={lines[1]}
                  lines={lines}
                />
              )}
              interval={1}
              tickMargin={20}
              domain={domain}
            />
          )}
          <CartesianGrid
            vertical={false}
            stroke={token('legacy.color.blue.greyLight')}
          />
          <Tooltip
            content={(props) => <TooltipContent {...props} lines={lines} />}
          />
          {lines.map((line, i) => (
            <Line
              key={line.dataKey}
              yAxisId={
                lines.length > 2 ? undefined : i === 1 ? 'right' : 'left'
              }
              type='linear'
              dataKey={line.dataKey}
              stroke={line.color}
              activeDot={{ r: 5 }}
              strokeWidth={1.6}
            />
          ))}
        </RechartsLineChart>
      </ResponsiveContainer>
    );
  }
}

export default LineChart;

import React, { FC } from 'react';
import { useParams } from 'react-router-dom';
import { useContentTypeSelection } from '../stores/contentTypes';
import { getContentTypeDefinition } from '../utils/contentTypes';

export const ContentTypeSelector: FC = () => {
  const { contentType = '' } = useParams();
  const { Selector } = getContentTypeDefinition(contentType);
  const [selectedIds, onSelection] = useContentTypeSelection(contentType);

  return <Selector selectedIds={selectedIds} onSelection={onSelection} />;
};

import {
  border,
  focus,
  padding,
  StyleSheet,
  useStyles
} from '@rexlabs/styling';
import React, { FC, ReactNode } from 'react';
import { Logo } from 'src/components/elements/logo';
import { Link } from 'utils/whereabouts';
import ROUTES from 'routes/admin';

const styles = StyleSheet({
  nav: {
    width: '100%',
    ...focus.styles({
      shadow: 'light'
    }),
    ...border.styles({
      bottom: {
        style: 'solid',
        width: 'thin',
        color: ({ token }) => token('color.border.container.static.light')
      }
    })
  },

  container: {
    marginLeft: 'auto',
    marginRight: 'auto',
    ...padding.styles({
      x: 'xl'
    })
  },

  layout: {
    position: 'relative',
    display: 'flex',
    height: '8rem',
    alignItems: 'center',
    justifyContent: 'space-between'
  },

  logo: {
    height: '4rem'
  }
});

export type NavBarProps = {
  children?: ReactNode;
};

export const NavBar: FC<NavBarProps> = ({ children }) => {
  const s = useStyles(styles);

  return (
    <nav {...s('nav')}>
      <div {...s('container')}>
        <div {...s('layout')}>
          <Link to={ROUTES.DASHBOARD}>
            <Logo {...s('logo')} />
          </Link>

          {children}
        </div>
      </div>
    </nav>
  );
};

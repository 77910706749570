import {Assign} from "src/types";
import {CatalogListing} from "src/features/listings/types";

export enum ListingCategory {
    ResidentialSale = 'residential_sale',
    ResidentialRental = 'residential_rental',
    HolidayRental = 'holiday_rental',
    RuralSale = 'rural_sale',
    LandSale = 'land_sale',
    BusinessSale = 'business_sale',
    CommercialSale = 'commercial_sale',
    CommercialSaleRental = 'commercial_sale_rental',
    CommercialRental = 'commercial_rental',
}

export type CatalogListingSetListing = Omit<CatalogListing, 'address'> & {
  address: CatalogListingSetListingAddress;
}

export type CatalogListingSetListingAddress = {
  full_address: string;
};

export type CatalogListingSet = {
    readonly id: string;
    name: string;
    show_agent_image: boolean;
    listing_min_price: number | null;
    listing_max_price: number | null;
    listing_sold: boolean;
    listing_categories: string[];
    sort: string | null,
    limit: number | null;
    agents: {
        data: Array<any>
    };
    readonly created_at: Date;
    readonly updated_at: Date;
};

export enum ListingStatus {
  Current = 'current',
  Sold = 'sold'
}
export type CatalogListingSetFormDTO = Assign<CatalogListingSet,
    {
      sort: Array<any>,
      listing_categories: Array<string[]>,
      agents: Array<any>,
      listing_status: ListingStatus;
    }
>;

type CatalogListingSetPostData = {
    agents?: { id: string }[];
};

export type CatalogListingSetPostDTO = Assign<
    CatalogListingSet,
    CatalogListingSetPostData
>;

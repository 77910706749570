import { Box } from '@rexlabs/box';
import { PrimaryButton } from '@rexlabs/button';
import { StyleSheet, useStyles, useToken } from '@rexlabs/styling';
import React from 'react';
import ROUTES from 'src/routes/admin';
import WIZARD from 'src/routes/wizard';
import { isSpoke } from 'src/theme';
import { Link } from 'src/utils/whereabouts';
import { GridCell, GridRow } from 'src/view/components/grid';
import { Body, Heading, Small } from 'src/view/components/text';
import FlinkSuccess from 'src/assets/images/success/flink-success.svg';
import SpokeSuccess from 'src/assets/images/success/spoke-success.svg';

const defaultStyles = StyleSheet({
  wrapContent: {
    paddingTop: '9rem'
  },

  wrapSuccessImage: {
    width: '150%',
    marginTop: '5rem',
    position: 'relative'
  },

  successImage: {
    width: '100%',
    opacity: 0.9,
    transition: '.4s opacity'
  },

  successImageMounted: {
    opacity: 1
  },

  successBackground: {
    position: 'absolute'
  }
});

function CreateSuccessScreen() {
  const token = useToken();
  const s = useStyles(defaultStyles);

  const homeRoute = ROUTES.DASHBOARD;
  const homeLinkText = 'my dashboard';

  return (
    <GridRow gutter={token('spacing.xxxl')}>
      <GridCell width={3 / 5} gutter={token('spacing.xxxl')}>
        <Box {...s('wrapContent')}>
          <Heading>Your campaign is ready!</Heading>

          <Box mt={token('spacing.xxxl')}>
            <Body grey>
              Stand by for enquiries - thousands of potential buyers will see
              your ads within the next 24 hours. But first, the networks need to
              approve your campaign.
            </Body>
            <Body grey>
              You can check on the progress and performance of your campaign at
              any time from the campaign dashboard.
            </Body>
          </Box>
          <Box
            flexDirection='row'
            alignItems='center'
            mt={token('spacing.xxxl')}
          >
            <Link to={WIZARD.CREATE}>
              {({ onClick }) => (
                <PrimaryButton onClick={onClick}>
                  Create your next campaign
                </PrimaryButton>
              )}
            </Link>
            <Small grey style={{ marginLeft: token('spacing.m') }}>
              or go to <Link to={homeRoute}>{homeLinkText}</Link>
            </Small>
          </Box>
        </Box>
      </GridCell>
      <GridCell gutter={token('spacing.xxxl')} width={2 / 5}>
        <Box {...s('wrapSuccessImage')}>
          {isSpoke ? <SpokeSuccess /> : <FlinkSuccess />}
        </Box>
      </GridCell>
    </GridRow>
  );
}

export default CreateSuccessScreen;

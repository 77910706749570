import { Box } from '@rexlabs/box';
import { Column, Grid } from '@rexlabs/grid';
import { useModelState } from '@rexlabs/model-generator';
import { padding, StyleSheet, useStyles, useToken } from '@rexlabs/styling';
import React from 'react';
import { useFeature } from 'shared/hooks';
import sessionModel from 'src/data/models/custom/session';
import { BORDER_RADIUS, FONT, UTILS } from 'src/theme';
import { number } from 'src/utils/format';
import {
  CampaignActionMenu,
  EmbedCampaignActionMenu,
  CampaignWithStrategyActionMenu
} from 'src/view/components/action-menu';
import Avatar from 'src/view/components/avatar';
import {
  CampaignProgress,
  CampaignStatus,
  STATUS
} from 'src/view/components/campaign';
import { Body, Small } from 'src/view/components/text';
import { StatusGoodTag } from '@rexlabs/tag';
import Icons from "@rexlabs/icons-next";
import { CATALOG_CAMPAIGN_NAME } from "src/features/wizard/utils/constants";
import { useCatalogListingSetListings } from "src/features/catalog-listing-sets/api/getCatalogListingSetListings";
import { CatalogListItemImage } from "src/features/catalog-listing-sets/components/CatalogListItemImage";
import { CatalogListingsCountTag } from "src/features/catalog-listing-sets/components/CatalogListingsCountTag";

const defaultStyles = StyleSheet({
  container: {
    display: 'flex',
    alignItems: 'center',
    flex: '1 0',
    margin: '0 6px',
    width: '100%',
    cursor: 'pointer',
    transition: 'background .2s',
    background: ({ token }) => token('color.container.interactive.idle'),
    ...padding.styles({
      y: 'm'
    }),

    ':hover': {
      background: ({ token }) => token('color.container.interactive.hover')
    },

    '> *': {
      flexShrink: 0
    }
  },

  avatar: {
    width: '7.2rem',
    height: '7.2rem',
    marginRight: ({ token }) => token('spacing.m'),
    background: 'pink no-repeat center center',
    backgroundSize: 'contain',
    borderRadius: BORDER_RADIUS.INPUT,
    flexShrink: 0
  },

  agentAvatar: {
    borderRadius: '50%',
    overflow: 'hidden'
  },

  sold: {
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
  },

  soldTag: {
    padding: ({ token }) => token('spacing.xxs'),
    paddingLeft: ({ token }) => token('spacing.xs'),
    paddingRight: ({ token }) => token('spacing.xs'),
    background: ({ token }) => token('legacy.color.red.default'),
    color: ({ token }) => token('legacy.color.white'),
    fontWeight: FONT.WEIGHTS.BOLD,
    fontSize: '1.3rem',
    textTransform: 'uppercase',
    borderRadius: BORDER_RADIUS.INPUT
  },

  statusBar: {
    width: '100%',
    height: '.5rem',
    borderRadius: '.5rem',
    background: ({ token }) => token('legacy.color.spoke')
  },

  campaignName: {
    ...UTILS.TRUNCATE,
    width: '22rem',
    paddingTop: '0',
    paddingBottom: '0',
    lineHeight: ({ token }) => token('typography.lineHeight.m')
  },

  campaignType: {
    ...UTILS.TRUNCATE,
    width: '22rem',
    paddingTop: '0',
    paddingBottom: '0',
    fontSize: ({ token }) => token('typography.size.m'),
    fontWeight: ({ token }) => token('typography.weight.semiMedium'),
    lineHeight: ({ token }) => token('typography.lineHeight.s')
  },

  strategyNameChip: {
    ...UTILS.TRUNCATE,
    maxWidth: '22rem',
    paddingTop: '0',
    paddingBottom: '0',
    marginTop: '0.2rem',
    fontWeight: ({ token }) => token('typography.weight.bold'),
    lineHeight: ({ token }) => token('typography.lineHeight.xs'),
    color: ({ token }) => token('color.textStyles.strategyChip.default')
  },

  pinIconContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: '24px',
    height: '24px',
    position: 'absolute',
    top: '-8px',
    left: '-8px',
    zIndex: 1,
    borderRadius: '100%',
    color: 'white',
    background: ({ token }) => token('legacy.color.red.default')
  },
});

const CampaignListItem = (props) => {
  const { onClick, data, embed, hideStrategyChip, isPinned = false } = props;
  const s = useStyles(defaultStyles);
  const token = useToken();
  const session = useModelState(sessionModel);
  const impressions = data?.stats?.totals?.impressions;
  const clicks = data?.stats?.totals?.link_clicks;
  const leads = data?.stats?.totals?.leads;
  const status = data?.status?.id;
  const prospecting = (data?.ad_content_sets?.items ?? []).find((i) =>
    (i?.type?.id ?? '').endsWith('.prospecting')
  );
  const isAgencySlide = (prospecting?.attributes?.slides ?? []).some(
    (s) => s.type.toLowerCase() === 'agency'
  );
  const image =
    data?.thumbnails?.[
      isAgencySlide ? 'original.thumbnail' : 'thumbnail.campaign'
    ]?.url;
  const fbLeadAds = useFeature('facebook_lead_ads', session);

  const campaignType = data?.type_label;
  const attachedStrategy = data?.campaign_strategy;
  const strategyName = data?.campaign_strategy?.name;
  const isCatalogCampaign = campaignType === CATALOG_CAMPAIGN_NAME;

  const listingSetId = data?.content_source?.entities?.[0]?.id;

  const {data: listingSetListingsData, isLoading: listingSetListingDataIsLoading, } = useCatalogListingSetListings({
    listingSetId: listingSetId ?? '',
    config: {
      enabled: listingSetId !== undefined && isCatalogCampaign
    }
  });
  const cataloguePreviewImages = listingSetListingsData?.pages?.[0]?.data?.slice(0,4)?.map((i) => i.thumbnails?.['original.thumbnail']?.url);

  const actionMenu = () => {
    if (status === STATUS.PROCESSING) {
      return null;
    } else if (attachedStrategy) {
      return <CampaignWithStrategyActionMenu campaign={data} {...props} />;
    } else if (embed) {
      return <EmbedCampaignActionMenu campaign={data} {...props} />;
    } else {
      return <CampaignActionMenu campaign={data} {...props} />;
    }
  };

  return (
    <Grid
      columns={36}
      gutter='12px'
      onClick={onClick}
      data-testid={`${data?.campaign_strategy ? 'strategy' : 'campaign'}.${
        data?.type?.id
      }.${data?.status?.id}.${data?.id}`}
    >
      <Box {...s('container')}>
        <Column
          width={fbLeadAds ? 12 : 15}
          flexDirection='row'
          alignItems='center'
          flexWrap='nowrap'
        >
          <Box style={{position: 'relative'}}>
            <Box {...s('pinIconContainer')} style={{visibility: isPinned ? 'visible' : 'hidden'}}>
              <Icons.Pin size={'m'} />
            </Box>
            {isCatalogCampaign ? (
              <Box width={'72px'} height={'72px'} marginRight={token('spacing.m')}>
                <CatalogListItemImage imageUrls={cataloguePreviewImages} />
              </Box>
              ) : (
                <Avatar
                  imageContain={isAgencySlide}
                  round={['agent_profile', 'multi_listing_sold'].includes(
                    data?.type?.id
                  )}
                  bgColor={prospecting?.attributes?.background_color}
                  isSold={data?.attributes?.is_sold}
                  src={image}
                />
              )
            }
          </Box>

          <Column
            width='auto'
            flexDirection='column'
            alignItems='start'
            flexWrap='nowrap'
            rowGap='0.5rem'
          >
            <Body {...s('campaignName')}>{data.name}</Body>
            <Body {...s('campaignType')}>{campaignType} {!isCatalogCampaign ? 'Campaign' : ''}</Body>
            {strategyName && !hideStrategyChip ? (
              <StatusGoodTag {...s('strategyNameChip')}>
                {strategyName}
              </StatusGoodTag>
            ) : null}
            {isCatalogCampaign ? (
                <CatalogListingsCountTag
                  count={listingSetListingsData?.pages?.[0]?.pagination?.total}
                  label={'Active listings'}
                  isLoading={listingSetListingDataIsLoading}
                />
            ) : null}
          </Column>
        </Column>

        <Column width={5}>
          <CampaignStatus status={status} label={data?.status?.label} />
        </Column>

        <Column width={4}>
          <Small>{impressions ? number(impressions) : '-'}</Small>
        </Column>

        <Column width={3}>
          <Small>{clicks ? number(clicks) : '-'}</Small>
        </Column>

        {fbLeadAds && (
          <Column width={3}>
            <Small>{leads ? number(leads) : '-'}</Small>
          </Column>
        )}

        <Column mt={token('spacing.xs')} width={7}>
          <CampaignProgress
            status={status}
            plan={data?.active_package}
            spent={parseInt(data?.duration?.spent || 0)}
            duration={parseInt(data?.duration?.total ?? 1)}
            recurring={data?.active_package?.type?.id === 'recurring'}
            lastWeek={
              data?.renew === true &&
              data?.limit_renewals === true &&
              data?.renewals?.left === 0
            }
            campaign={data}
          />
        </Column>

        <Column
          data-testid={'action-menu-btn'}
          width={2}
          justifyContent='flex-end'
          alignItems='center'
          onClick={(e) => {
            if (!e.target.innerText) {
              e.preventDefault();
              e.stopPropagation();
            }

            if (!embed) {
              e.stopPropagation();
            }
          }}
        >
          {actionMenu()}
        </Column>
      </Box>
    </Grid>
  );
};

export default CampaignListItem;

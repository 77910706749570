import { defaultFilter } from '@rexlabs/select-input';
import { useToken } from '@rexlabs/styling';
import _ from 'lodash';
import React from 'react';
import { LoadingSpinner } from 'src/view/components/loading';

export const selectFilter = (options, props) => {
  const filteredOptions = defaultFilter(options, props).filter(
    (opt) => !opt.isFixture
  );
  const fixtureOptions = options.filter((opt) => Boolean(opt.isFixture));
  return filteredOptions.concat(fixtureOptions);
};

export const pluckValue = (option) => option?.value ?? null;

export const filterOptionsByValue = (options, value, getValue = pluckValue) => {
  const filteredOptions = options.filter((option) =>
    _.isArray(value)
      ? value.includes(getValue(option))
      : getValue(option) === value
  );
  return filteredOptions;
};

export const LoadingIndicator = () => {
  const token = useToken();

  return (
    <LoadingSpinner
      styles={{ spinner: { paddingLeft: token('spacing.xl') } }}
      size={16}
      strokeWidth={3}
    />
  );
};

import { useMutation, useQueryClient } from 'react-query';
import { useAccountId } from 'src/features/accounts';
import { MutationResult } from 'src/lib/react-query';
import { api } from 'src/utils/api-client';
import { Testimonial, TestimonialDTO } from '../types';
import { transformTestimonialResponse } from '../utils/schemas';
import { testimonialsKeys } from './keys';

export type CreateTestimonialParams = {
  accountId: string;
  testimonial: TestimonialDTO;
};

export const createTestimonial = async ({
  testimonial
}: CreateTestimonialParams): Promise<Testimonial> => {
  const { data } = await api.post('/testimonials', testimonial, {
    params: {
      include: 'agent.thumbnails'
    }
  });
  return transformTestimonialResponse(data);
};

export type UseCreateTestimonialVariables = TestimonialDTO;

export const useCreateTestimonial = (): MutationResult<
  Testimonial,
  UseCreateTestimonialVariables
> => {
  const queryClient = useQueryClient();
  const accountId = useAccountId();

  return useMutation({
    mutationFn: (testimonial) => createTestimonial({ accountId, testimonial }),
    onSuccess: (data) => {
      queryClient.setQueryData(
        testimonialsKeys.detail({ accountId, testimonialId: data.id }),
        data
      );
      queryClient.invalidateQueries(testimonialsKeys.lists({ accountId }));
    }
  });
};

import config from 'src/config';
import { Credentials, OAuthTokenResponse } from 'src/types';
import { api, setAuthToken } from 'src/utils/api-client';
import { devtools, persist } from 'zustand/middleware';
import create from 'zustand/vanilla';

export type AuthState = {
  authData?: OAuthTokenResponse;
  login: (credentials: Credentials) => Promise<void>;
  logout: () => void;
};

export const authStore = create<AuthState>()(
  devtools(
    persist(
      (set) => ({
        authData: undefined,
        login: async ({ username, password }) => {
          const loginParams = {
            password,
            username,
            grant_type: 'password',
            client_id: config.OAUTH.CLIENT_ID,
            client_secret: config.OAUTH.CLIENT_SECRET,
            scope: '*'
          };

          const { data } = await api.post('/oauth/token', loginParams);

          if (typeof data.access_token === 'undefined')
            throw new Error('Invalid OAuth token response');
          setAuthToken(data.access_token);
          set({ authData: data });
        },
        logout: () => set({ authData: undefined })
      }),
      {
        name: 'auth-storage',
        onRehydrateStorage: () => (state) => {
          if (state?.authData) setAuthToken(state.authData.access_token);
        }
      }
    ),
    {
      name: 'AuthStore'
    }
  )
);

export const isAuthenticatedSelector = ({ authData }: AuthState): boolean =>
  typeof authData !== 'undefined';

export const isAuthenticated = (): boolean =>
  isAuthenticatedSelector(authStore.getState());

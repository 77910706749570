import React from 'react';
import Button from './default';
import { StyleSheet, useToken } from '@rexlabs/styling';

const buttonStyles = StyleSheet({
  container: {
    background: ({ token }) => token('legacy.color.grey.light'),

    ':hover': {
      background: ({ token }) => token('legacy.color.grey.default')
    },

    ':active': {
      opacity: 1,
      background: ({ token }) => token('legacy.color.blue.greyLight')
    }
  },

  disabled: {
    opacity: 0.7,
    ':hover': {
      opacity: 0.7,
      background: ({ token }) => token('legacy.color.grey.light')
    }
  },

  content: {
    display: 'inline-flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
    color: ({ token }) => token('legacy.color.blue.grey')
  }
});

export default ({ red, ...props }) => {
  const token = useToken();
  return (
    <Button
      color={token('legacy.color.blue.grey')}
      styles={buttonStyles}
      {...props}
    />
  );
};

import { ActionCreators, StateSelectors } from '@rexlabs/model-generator';
import { createRestAPIModelGenerator } from 'src/data/models/generator';

export const TYPE = 'wizard';

type State = {
  step: number | null;
  prevStep: number;
  type: string;
  goal?: unknown;
  slug: string;
  selectedEntityId: string | null;
  selectedEntities: Record<'listings' | 'agents' | 'agencies', unknown[]>;
  packages: unknown[];
  packageEstimates: Record<string, unknown>;
  audienceEstimate: unknown;
};

const initialState: State = {
  step: null,
  prevStep: 0,
  type: 'single_listing',
  goal: undefined,
  slug: 'listing',
  selectedEntityId: null,
  selectedEntities: {
    listings: [],
    agents: [],
    agencies: []
  },
  packages: [],
  packageEstimates: {},
  audienceEstimate: {}
};

const selectors = {
  step: (state) => state?.wizard?.step,
  prevStep: (state) => state?.wizard?.prevStep,
  type: (state) => state?.wizard?.type,
  goal: (state) => state?.wizard?.goal,
  slug: (state) => state?.wizard?.slug,
  selectedEntityId: (state) => state?.wizard?.selectedEntityId,
  selectedEntities: (state) => state?.wizard?.selectedEntities,
  packages: (state) => state?.wizard?.packages,
  packageEstimates: (state) => state?.wizard?.packageEstimates,
  audienceEstimate: (state) => state?.wizard?.audienceEstimate
} satisfies StateSelectors<typeof TYPE, State>;

const actionCreators = {
  setStep: {
    reduce: (state, action) => ({
      ...state,
      step: action?.payload?.step,
      prevStep: state.step !== state.prevStep ? state.step : state.prevStep
    })
  },
  setType: {
    reduce: (state, action) => ({
      ...state,
      type: action?.payload?.type
    })
  },
  setGoal: {
    reduce: (state, action) => ({
      ...state,
      goal: action?.payload?.goal
    })
  },
  flush: {
    reduce: (state) => ({
      ...initialState,
      slug: state.slug
    })
  },
  set: {
    reduce: (state, action) => ({
      ...state,
      ...action.payload,
      prevStep: state.step !== state.prevStep ? state.step : state.prevStep
    })
  },
  setSelectedListings: {
    reduce: (state, action) => ({
      ...state,
      selectedEntities: {
        ...state.selectedEntities,
        listings: action.payload
      }
    })
  },
  setSelectedAgents: {
    reduce: (state, action) => ({
      ...state,
      selectedEntities: {
        ...state.selectedEntities,
        agents: action.payload
      }
    })
  },
  setSelectedAgencies: {
    reduce: (state, action) => ({
      ...state,
      selectedEntities: {
        ...state.selectedEntities,
        agencies: action.payload
      }
    })
  }
} satisfies ActionCreators<State>;

const WizardModelGenerator = createRestAPIModelGenerator(TYPE);
const wizardModel = WizardModelGenerator.createModel({
  initialState,
  actionCreators,
  selectors
});

export default wizardModel;

import { StyleSheet, useStyles } from '@rexlabs/styling';
import React, { FC } from 'react';
import { EmptyInput, EmptyInputProps } from '../EmptyInput';

const styles = StyleSheet({
  container: {
    height: '10.4rem'
  }
});

export type PreviewEmptyInputProps = Omit<
  EmptyInputProps,
  'className' | 'style'
>;

export const PreviewEmptyInput: FC<PreviewEmptyInputProps> = (props) => {
  const s = useStyles(styles, 'PreviewEmptyInput');
  return <EmptyInput {...s('container')} {...props} isLarge />;
};

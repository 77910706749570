import React, { PureComponent } from 'react';
import { facebookNetworkAdTypes } from '../utils/spoke-value-lists';

import InstagramSingleImage from './instagram-ads/multi-listing-sold/single-image';
import InstagramCarousel from './instagram-ads/multi-listing-sold/carousel';

class MultiListingSoldInstagramAd extends PureComponent {
  render() {
    const { adType, adContentSet, disableWrapper, ad } = this.props;
    const [type, variation, slideNumber] = adType.split('.');
    switch (type) {
      case facebookNetworkAdTypes.CAROUSEL:
        // variation is used to render each slide in the carousel
        return (
          <InstagramCarousel
            ad={ad}
            variation={variation}
            slideNumber={slideNumber}
            adContentSet={adContentSet}
            disableWrapper={disableWrapper}
          />
        );
      case facebookNetworkAdTypes.SINGLE_IMAGE:
        return (
          <InstagramSingleImage
            ad={ad}
            adContentSet={adContentSet}
            disableWrapper={disableWrapper}
          />
        );
      default:
        return <span>Type {adType} not found</span>;
    }
  }
}

export default MultiListingSoldInstagramAd;

import { useCallback, useEffect } from 'react';

function useEvent(channel, eventName, callback, deps = []) {
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const callbackRef = useCallback(callback, deps);

  useEffect(() => {
    if (!channel) return;

    channel.bind(eventName, callbackRef);

    return () => {
      channel.unbind(eventName, callbackRef);
    };
  }, [channel, eventName, callbackRef]);
}

export default useEvent;

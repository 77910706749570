import { Box } from '@rexlabs/box';
import { withQuery } from '@rexlabs/model-generator';
import { styled, StyleSheet } from '@rexlabs/styling';
import React, { useEffect, useState } from 'react';
import { compose } from 'redux';
import { prepareAdData } from 'shared/utils/prepare-ad-data';
import { facebookQuery } from 'src/data/queries/facebook';
import AdwordsAdPreview from 'src/view/components/ad-previews/adwords';
import FacebookAdPreview from 'src/view/components/ad-previews/facebook';
import InstagramAdPreview from 'src/view/components/ad-previews/instagram';
import { StatsHeading } from 'src/view/components/stats';

import FacebookIcon from 'src/assets/ad-previews/facebook/facebook.svg';
import GoogleAdsIcon from 'src/assets/ad-previews/google/google-ads.png';
import InstagramIcon from 'src/assets/ad-previews/instagram/instagram.svg';

const defaultStyles = StyleSheet({
  /**
   * HACK: can't use display: none as the ads get scaled and when printing
   * the it renders while its still in the process of scaling /shrug
   */
  container: {
    height: 0,
    overflow: 'hidden',
    visibility: 'hidden',
    position: 'absolute',

    '@media print': {
      height: 'auto',
      overflow: 'auto',
      visibility: 'visible',
      position: 'relative',
      pageBreakBefore: 'always'
    }
  },

  description: {
    color: ({ token }) => token('legacy.color.blue.grey'),
    marginBottom: '4mm'
  },

  icons: {
    marginBottom: '4mm'
  },

  adsWrapper: {
    display: 'flex',
    height: '120mm'
  },

  adWrapper: {
    position: 'relative'
  },

  adWrapperInner: {
    position: 'absolute',
    transformOrigin: 'top left'
  }
});

function AdsPrintPreview({ styles: s, facebook: facebookAccount, ...props }) {
  const [campaignData, setCampaignData] = useState();

  const ads = props?.ads?.list?.items;
  const adsPrint = ads.reduce((acc, ad) => {
    if (ad.name === 'Facebook Single Image') {
      acc.facebook = ad;
    }

    if (ad.name === 'Instagram Single Image') {
      acc.instagram = ad;
    }

    if (ad.name === 'Google Triple Widescreen') {
      acc.google = ad;
    }

    return acc;
  }, {});

  const { facebook, instagram, google } = adsPrint;

  useEffect(() => {
    prepareAdData(props).then(setCampaignData);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (!campaignData) return null;

  const isRetargeting = (
    (facebook || instagram || google)?.ad_content_set_type?.id ?? ''
  ).endsWith('retargeting');

  const setData = {
    ...campaignData,
    facebookPage: facebookAccount?.item?.data?.pages?.[0]?.name,
    ...(campaignData?.[isRetargeting ? 'retargeting' : 'prospecting'] ?? {})
  };

  const AdWrapper = ({ width, scale, mr, children }) => (
    <div {...s('adWrapper')} style={{ width, marginRight: mr }}>
      <div {...s('adWrapperInner')} style={{ transform: `scale(${scale})` }}>
        {children}
      </div>
    </div>
  );

  return (
    <div {...s('container')}>
      <StatsHeading title='Ads Preview' />
      <p {...s('description')}>
        Below are some sample ads being used in your campaign across each ad
        network. Note: there are 71 variations on these ads being used in this
        campaign.
      </p>

      <Box flexDirection='row' h='110mm'>
        {facebook && (
          <Box flexDirection='column' alignItems='center'>
            <FacebookIcon {...s('icons')} />
            <AdWrapper width='66mm' scale='0.5' mr='4mm'>
              <FacebookAdPreview ad={facebook} campaignData={setData} />
            </AdWrapper>
          </Box>
        )}
        {instagram && (
          <Box flexDirection='column' alignItems='center'>
            <InstagramIcon {...s('icons')} />
            <AdWrapper width='57mm' scale='0.67' mr='4mm'>
              <InstagramAdPreview ad={instagram} campaignData={setData} />
            </AdWrapper>
          </Box>
        )}
        {google && (
          <Box flexDirection='column' alignItems='center'>
            <img src={GoogleAdsIcon} {...s('icons')} />
            <AdWrapper width='67mm' scale='1'>
              <AdwordsAdPreview ad={google} campaignData={setData} />
            </AdWrapper>
          </Box>
        )}
      </Box>
    </div>
  );
}

export default compose(
  styled(defaultStyles),
  withQuery(facebookQuery)
)(AdsPrintPreview);

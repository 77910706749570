import { useMemo } from 'react';

function useFeature(feature, session) {
  const currentAccount = useMemo(
    () =>
      session.accounts
        .filter(Boolean)
        .find((a) => a.id === session.currentAccountId),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [session.currentAccountId]
  );

  return !!currentAccount?.feature_flags?.includes(feature);
}

export default useFeature;

import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import Leaderboard, { types } from './leaderboard';

export default class LargeLeaderboard extends PureComponent {
  static propTypes = {
    brandColor: PropTypes.string,
    backgroundColor: PropTypes.string,
    shortHeadline: PropTypes.string,
    primaryImageUrls: PropTypes.object,
    logoUrls: PropTypes.object
  };

  render() {
    return <Leaderboard {...this.props} type={types.LARGE} />;
  }
}

import React, {
  CSSProperties,
  forwardRef,
  ReactEventHandler,
  RefCallback,
  useCallback,
  useEffect,
  useState
} from 'react';
import ImageCrop, {
  centerCrop,
  Crop,
  makeAspectCrop,
  PercentCrop,
  PixelCrop
} from 'react-image-crop';
import 'react-image-crop/dist/ReactCrop.css';
import { useEnsuredForwardedRef } from 'src/hooks';
import { BiConsumer, Consumer } from 'src/types';

export type ImageCropInputProps = {
  src: string;
  aspect?: number;
  value?: PercentCrop;
  onChange?: Consumer<PercentCrop>;
  onBlur?: Consumer<FocusEvent>;
  className?: string;
  style?: CSSProperties;
};

export const ImageCropInput = forwardRef<HTMLDivElement, ImageCropInputProps>(
  ({ src, aspect = 1, value, onChange, onBlur, className, style }, ref) => {
    const inputRef = useEnsuredForwardedRef(ref);
    const [crop, setCrop] = useState<Crop | undefined>(value);

    const setInputRef: RefCallback<ImageCrop> = (e): void => {
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      if (e) inputRef.current = e.componentRef.current!;
    };
    const trackCropChange = useCallback<BiConsumer<PixelCrop, PercentCrop>>(
      (_pixelCrop, percentCrop) => {
        setCrop(percentCrop);
      },
      []
    );
    const callOnChange = useCallback<BiConsumer<PixelCrop, PercentCrop>>(
      (_pixelCrop, percentCrop) => {
        onChange?.(percentCrop);
      },
      [onChange]
    );
    const setDefaultCrop = useCallback<ReactEventHandler<HTMLImageElement>>(
      (e) => {
        if (crop === undefined) {
          const h = e.currentTarget.height;
          const w = e.currentTarget.width;
          const crop = centerCrop(
            makeAspectCrop({ unit: '%', width: 100 }, aspect, w, h),
            w,
            h
          );
          setCrop(crop);
        }
      },
      [aspect, crop]
    );

    useEffect(() => {
      const listener: Consumer<FocusEvent> = (e) => onBlur?.(e);
      inputRef.current.addEventListener('blur', listener);
      return () => inputRef.current.removeEventListener('blur', listener);
    }, [inputRef, onBlur]);

    return (
      <ImageCrop
        ref={setInputRef}
        crop={crop}
        onChange={trackCropChange}
        onComplete={callOnChange}
        aspect={aspect}
        className={className}
        style={style}
        keepSelection
      >
        <img src={src} onLoad={setDefaultCrop} />
      </ImageCrop>
    );
  }
);

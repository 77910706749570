import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import Box from '@rexlabs/box';
import { styled, StyleSheet } from '@rexlabs/styling';

const fontFamily = 'Lato, sans-serif';

const defaultStyles = StyleSheet({
  container: {
    position: 'absolute',
    zIndex: 100,
    padding: '2px 12px 12px',
    bottom: 0,
    left: 0,
    right: 0,
    background:
      'linear-gradient(to bottom, rgba(0,0,0,0) 0%, rgba(0,0,0,.8) 100%)',
    color: 'white'
  },

  image: {
    flexShrink: 0,
    width: '88px',
    height: '88px',
    background: 'grey no-repeat center center',
    backgroundSize: 'cover',
    backgroundPosition: 'center',
    borderRadius: '88px',
    border: '1px solid white',
    marginLeft: '12px'
  },

  name: {
    fontSize: '18px',
    fontWeight: 400,
    fontFamily
  },

  agency: {
    fontSize: '15px',
    fontWeight: 500,
    fontFamily,
    opacity: 0.9
  }
});

@styled(defaultStyles)
export default class FacebookAgentOverlay extends PureComponent {
  static propTypes = {
    agentName: PropTypes.string,
    agencyName: PropTypes.string,
    agentProfileUrl: PropTypes.string
  };

  render() {
    const { styles: s, agentName, agencyName, agentProfileUrl } = this.props;

    // Agent is not required but if enabled they must have a profile image
    if (!agentProfileUrl) {
      return null;
    }

    return (
      <Box
        {...s('container')}
        flexDirection='row'
        alignItems='center'
        justifyContent='flex-end'
      >
        <Box flexDirection='column' alignItems='flex-end'>
          <span {...s('name')}>{agentName}</span>
          <span {...s('agency')}>{agencyName}</span>
        </Box>
        <Box
          {...s.with('image')({
            backgroundImage: agentProfileUrl
              ? `url(${agentProfileUrl})`
              : 'none'
          })}
        />
      </Box>
    );
  }
}

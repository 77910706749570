import { GhostButton, PrimaryButton } from '@rexlabs/button';
import { useToast } from '@rexlabs/notifications';
import {
  border,
  margin,
  padding,
  StyleSheet,
  useStyles
} from '@rexlabs/styling';
import { Heading, Small } from '@rexlabs/text';
import { TextInput } from '@rexlabs/text-input';
import React, {
  ChangeEventHandler,
  FC,
  useCallback,
  useMemo,
  useState
} from 'react';
import EmptyStateSection from 'src/view/components/empty-state-section';
import { LoadingSpinner } from 'src/view/components/loading';
import { useDeleteResourceDownload } from '../api/deleteResourceDownload';
import { useResourceDownloads } from '../api/getResourceDownloads';
import { useUpdateResourceDownload } from '../api/updateResourceDownload';
import {
  DeleteResourceHandler,
  EditResourceHandler,
  ResourceDownloadItem
} from './ResourceDownloadItem';
import { Link } from 'react-router-dom';

const styles = StyleSheet({
  container: {
    display: 'flex',
    flexDirection: 'column',
    minHeight: '0',

    '& > * + *': {
      ...margin.styles({
        top: 's'
      })
    }
  },

  toolbar: {
    display: 'flex',
    alignItems: 'center',

    '& > * + *': {
      ...margin.styles({
        left: 'xs'
      })
    }
  },

  loading: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center'
  },

  search: {
    flex: '1 1 auto'
  },

  listContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    flex: '1 1 auto',
    minHeight: '0'
  },

  list: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'stretch',
    overflowY: 'auto',
    width: '100%',

    '& > * + *': {
      ...border.styles({
        top: {
          width: 'thin',
          color: 'container.static.medium'
        },
        bottom: {
          width: 'none'
        }
      })
    }
  },

  item: {
    ...padding.styles({
      y: 'xs',
      x: 'm'
    })
  },

  refetch: {
    fontSize: ({ token }) => token('typography.size.l'),
    fontWeight: ({ token }) => token('typography.weight.medium'),
    color: ({ token }) => token('color.textStyle.primary.idle.default'),
    cursor: 'pointer',

    ':hover': {
      color: ({ token }) => token('color.textStyle.primary.hover.default'),
      textDecoration: 'underline'
    }
  },

  hideUnderline: {
    textDecoration: 'none'
  }
});

export type ResourceDownloadListProps = JSX.IntrinsicElements['div'] & {
  selectedIds?: string[];
  onSelection?: (resourceDownloadIds: string[]) => void;
};

export const ResourceDownloadList: FC<ResourceDownloadListProps> = ({
  onSelection,
  selectedIds = [],
  className,
  style,
  ...rest
}) => {
  const s = useStyles(styles);
  const toasts = useToast();
  const [search, setSearch] = useState<string>('');
  const {
    data,
    isLoading,
    isFetching,
    isError,
    fetchNextPage,
    hasNextPage,
    refetch
  } = useResourceDownloads({ q: `title.match(*${search}*)` });

  const selectionSet = useMemo(() => new Set(selectedIds), [selectedIds]);
  const fireChange = useCallback(() => {
    onSelection?.(Array.from(selectionSet.values()));
  }, [onSelection, selectionSet]);

  const { mutateAsync: updateResourceDownload } = useUpdateResourceDownload();
  const handleEdit = useCallback<EditResourceHandler>(
    async (id, resource) => {
      try {
        await updateResourceDownload({ id, data: resource });
      } catch (e) {
        toasts.addToast({
          type: 'error',
          title: `Unable to edit resource download ${resource.title}`,
          description: e instanceof Error ? e.message : undefined
        });
      }
    },
    [toasts, updateResourceDownload]
  );

  const { mutateAsync: deleteResourceDownload } = useDeleteResourceDownload();
  const handleDelete = useCallback<DeleteResourceHandler>(
    async (resource) => {
      try {
        await deleteResourceDownload({ id: resource.id });
        if (selectionSet.delete(resource.id)) fireChange();
      } catch (e) {
        toasts.addToast({
          type: 'error',
          title: `Unable to delete resource download ${resource.title}`,
          description: e instanceof Error ? e.message : undefined
        });
      }
    },
    [deleteResourceDownload, fireChange, selectionSet, toasts]
  );

  const handleChange = useCallback<ChangeEventHandler<HTMLInputElement>>(
    (e) => {
      const id = e.target.name;
      if (selectionSet.has(id)) {
        selectionSet.delete(id);
      } else {
        selectionSet.add(id);
      }
      fireChange();
    },
    [fireChange, selectionSet]
  );

  const resourceList = data?.pages.flatMap((page) => page.data) ?? [];
  return (
    <div {...s.with('container')({ className, style })} {...rest}>
      <Heading
        as={(p) => <h2 id='resource_download_header' {...p} />}
        level={2}
      >
        Resource Downloads
      </Heading>
      <div {...s('toolbar')}>
        <TextInput
          {...s('search')}
          type='search'
          value={search}
          onChange={({ target: { value } }) => setSearch(value)}
          placeholder='Search'
        />
        <Link {...s('hideUnderline')} to='create'>
          <PrimaryButton>Upload Resource</PrimaryButton>
        </Link>
      </div>
      {isLoading ? (
        <div {...s('loading')}>
          <LoadingSpinner />
          <Small grey>Loading...</Small>
        </div>
      ) : isError ? (
        <EmptyStateSection>
          Unable to fetch your downloadable resources.
          <a {...s('refetch')} onClick={() => refetch()}>
            Try again
          </a>
        </EmptyStateSection>
      ) : resourceList?.length === 0 ? (
        <EmptyStateSection>
          No resources found
          <div>To get started, upload a new resource.</div>
        </EmptyStateSection>
      ) : (
        <div {...s('listContainer')}>
          <div
            {...s('list')}
            role='listbox'
            aria-labelledby='resource_download_header'
            aria-setsize={data?.pages[0].pagination.total}
          >
            {resourceList?.map((resource, i) => (
              <ResourceDownloadItem
                onEdit={handleEdit}
                onDelete={handleDelete}
                {...s('item')}
                key={resource.id}
                posinset={i}
                resource={resource}
                onChange={handleChange}
                selected={selectionSet.has(resource.id)}
              />
            ))}
          </div>
          {hasNextPage ? (
            <GhostButton onClick={() => fetchNextPage()} isLoading={isFetching}>
              Load More
            </GhostButton>
          ) : null}
        </div>
      )}
    </div>
  );
};

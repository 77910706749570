import { border, margin, StyleSheet, useStyles } from '@rexlabs/styling';
import React, { memo, ReactNode } from 'react';

const styles = StyleSheet({
  container: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    fontSize: '1.4rem',
    color: ({ token }) => token('palette.grey.600'),
    transition: 'all .2s',

    '& > * + *': {
      ...margin.styles({
        left: 'xs'
      })
    }
  },

  number: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
    width: '2rem',
    height: '2rem',
    borderRadius: '9999px',
    fontVariantNumeric: 'tabular-nums',
    fontWeight: ({ token }) => token('typography.weight.semibold'),
    transition: 'all .2s',
    ...border.styles({
      all: {
        width: 'thin',
        color: 'neutral.idle.default'
      }
    })
  },

  activeNumber: {
    backgroundColor: ({ token }) => token('palette.brand.500'),
    borderColor: ({ token }) => token('palette.brand.500')
  },

  active: {
    color: ({ token }) => token('color.textStyle.body.default')
  }
});

export type WizardStepProps = {
  number: number;
  active?: boolean;
  children?: ReactNode;
};

export const WizardStepItem = memo(
  ({ number, active = false, children }: WizardStepProps) => {
    const s = useStyles(styles);

    return (
      <div {...s('container', { active })}>
        <span {...s('number', { activeNumber: active })}>{number}</span>
        <span>{children}</span>
      </div>
    );
  }
);

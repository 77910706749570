import { omit } from 'lodash';
import { useMutation, useQueryClient } from 'react-query';
import { useAccountId } from 'src/features/accounts';
import { MutationResult } from 'src/lib/react-query';
import { api } from 'src/utils/api-client';
import { Testimonial, TestimonialDTO } from '../types';
import { transformTestimonialResponse } from '../utils/schemas';
import { testimonialsKeys } from './keys';

export type UpdateTestimonialParams = {
  accountId: string;
  testimonialId: string;
  testimonial: Partial<TestimonialDTO>;
};

export const updateTestimonial = async ({
  testimonialId,
  testimonial
}: UpdateTestimonialParams): Promise<Testimonial> => {
  const { data } = await api.patch(
    `/testimonials/${testimonialId}`,
    testimonial,
    {
      params: {
        include: 'agent.thumbnails'
      }
    }
  );
  return transformTestimonialResponse(data);
};

export type UseUpdateTestimonialVariables = Omit<
  UpdateTestimonialParams,
  'accountId'
>;

export const useUpdateTestimonial = (): MutationResult<
  Testimonial,
  UseUpdateTestimonialVariables,
  { previousValue?: Testimonial }
> => {
  const queryClient = useQueryClient();
  const accountId = useAccountId();

  return useMutation({
    mutationFn: ({ testimonialId, testimonial }) =>
      updateTestimonial({ accountId, testimonialId, testimonial }),
    onMutate: async ({ testimonialId, testimonial }) => {
      const key = testimonialsKeys.detail({ accountId, testimonialId });

      await queryClient.cancelQueries(key);
      const previousValue = queryClient.getQueryData<Testimonial>(key);

      queryClient.setQueryData<Testimonial>(key, (old) => ({
        ...(old as Testimonial),
        ...omit(testimonial, 'agent_id')
      }));

      return { previousValue };
    },
    onError: (_err, { testimonialId }, ctx) => {
      queryClient.setQueryData(
        testimonialsKeys.detail({ accountId, testimonialId }),
        ctx?.previousValue
      );
    },
    onSuccess: () => {
      queryClient.invalidateQueries(testimonialsKeys.lists({ accountId }));
    },
    onSettled: (_data, _err, { testimonialId }) => {
      queryClient.invalidateQueries(
        testimonialsKeys.detail({ accountId, testimonialId })
      );
    }
  });
};

import React, { PureComponent } from 'react';
import InlineRectangle, { types } from './inline-rectangle';

export default class Square extends PureComponent {
  static propTypes = InlineRectangle.propTypes;
  render() {
    return (
      <InlineRectangle
        width={250}
        height={250}
        imageHeight={191}
        maxFontSize={24}
        minFontSize={14}
        type={types.SQUARE}
        {...this.props}
      />
    );
  }
}

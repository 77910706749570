import { Box } from '@rexlabs/box';
import { withWhereabouts } from '@rexlabs/react-whereabouts';
import { styled, StyleSheet } from '@rexlabs/styling';
import React from 'react';
import { compose } from 'redux';
import { Logo } from 'src/components/elements/logo';
import ROUTES from 'src/routes/auth';
import { Router } from 'src/utils/routing';
import { replace } from 'src/utils/whereabouts';
import { ModalTarget } from 'src/view/components/portal';

const defaultStyles = StyleSheet({
  container: {
    position: 'absolute',
    width: '100%',
    height: '100%',
    top: 0,
    left: 0,
    overflow: 'auto'
  },

  logo: {
    color: ({ token }) => token('legacy.color.primary.default'),
    height: '4.8rem',
    marginTop: '10rem',
    marginBottom: ({ token }) => token('spacing.xxl')
  }
});

function AuthLayout({ whereabouts, styles: s }) {
  const getRedirectPath = ({ path, query }) => {
    if (query.token) {
      return `${path}?token=${query.token}`;
    }

    if (query.redirect) {
      return query.redirect;
    }

    if (path !== '/select-agency') {
      return path;
    }
  };

  return (
    <Box
      {...s('container')}
      flexDirection='column'
      alignItems='center'
      justifyContent='flex-start'
    >
      <ModalTarget />
      <Logo type='rex_master' {...s('logo')} />

      <Box flexDirection='column' flex='1'>
        <Router
          routes={ROUTES}
          NotFound={() => {
            // Redirect any unknown routes to the login page
            // No 404 page!
            const check = /\/[0-9]+\/campaigns/;
            const redirect = getRedirectPath(whereabouts);

            replace(ROUTES.LOGIN, {
              query:
                whereabouts.path &&
                whereabouts.path !== '/' &&
                !check.exec(whereabouts.path) &&
                redirect
                  ? { redirect }
                  : undefined,
              hash: ''
            });
            return null;
          }}
        >
          {(matches) => <Box h='100%'>{matches}</Box>}
        </Router>
      </Box>
    </Box>
  );
}

export default compose(withWhereabouts(), styled(defaultStyles))(AuthLayout);

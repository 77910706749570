import { NormalisedItem, Select, useItems } from '@rexlabs/select';
import { identity } from 'lodash';
import React, { FC, useCallback, useEffect, useState } from 'react';
import { UseFormControlProps } from 'src/components/form';
import { Address, addressSchema } from 'src/features/audiences';
import { create } from 'src/lib/superstruct';
import { api } from 'src/utils/api-client';

const DEFAULT_COUNTRIES = ['au', 'gb', 'nz', 'ie', 'us', 'ca'];

type LocationItem = {
  id: string;
  label: string;
  type: {
    id: string;
    label: string;
  };
};

const getLocationSuggestions = async (
  type: string,
  countries: string[],
  input: string
): Promise<NormalisedItem[]> => {
  const { data } = await api.get<LocationItem[]>(
    '/location/autocomplete/predict',
    {
      type,
      countries: countries.join(','),
      input
    }
  );
  return data.map(({ id, label }) => ({
    id,
    label
  }));
};

export type LocationTypes = 'suburb' | 'address';

export type LocationSelectProps = UseFormControlProps<HTMLElement> & {
  type: LocationTypes;
  countries?: string[];
  value?: Address;
  clearOnSelection?: boolean;
  onChange?: (value?: Address) => void;
  onFocus?: () => void;
  onBlur?: () => void;
};

export const LocationSelect: FC<LocationSelectProps> = ({
  id,
  disabled,
  type,
  value,
  clearOnSelection = false,
  countries,
  onChange,
  onBlur
}) => {
  const initialValue: NormalisedItem | undefined = value
    ? {
        id: `${value.longitude},${value.latitude}`,
        label: value.full_address ?? ''
      }
    : undefined;

  const [locationValue, setLocationValue] = useState(initialValue);

  useEffect(() => {
    setLocationValue(initialValue);
  }, [initialValue?.id, initialValue?.label]);

  const getItems = useCallback(
    async (value) => {
      const data = await getLocationSuggestions(
        type,
        countries ?? DEFAULT_COUNTRIES,
        value
      );
      return data.map((v) => ({
        ...v,
        type,
        heading: type === 'address' ? 'Addresses' : 'Suburbs'
      }));
    },
    [type, countries]
  );
  const { getSelectProps } = useItems({
    minChars: 5,
    getItems
  });
  const handleChange = useCallback(
    async (e) => {
      const { id } = e.target.value;
      let result;
      if (id) {
        const { data } = await api.get(`/location/autocomplete/details/${id}`);
        result = create(data, addressSchema);
      }
      onChange?.(result);
    },
    [onChange]
  );
  const handleBlur = useCallback(() => {
    onBlur?.();
  }, [onBlur]);

  return (
    <Select
      id={id}
      disabled={disabled}
      normaliser={identity}
      value={clearOnSelection ? null : locationValue}
      {...getSelectProps()}
      multi={false}
      initialSelectedItem={initialValue}
      onChange={handleChange}
      onBlur={handleBlur}
    />
  );
};

import { StyleSheet, focus, useStyles } from '@rexlabs/styling';
import React, {FC, ReactNode} from 'react';
import ChevronLeft from 'src/assets/icons/chevron-left.svg';
import ChevronRight from 'src/assets/icons/chevron-right.svg';
import { AdType } from 'src/features/campaign-definitions';
import { BORDER_RADIUS } from 'src/theme';
import { adPreviews } from '../utils/ad-previews';

const styles = StyleSheet({
  previewTitle: {
    padding: ({ token }) => token('spacing.m'),
    justifyContent: 'space-between',
    display: 'flex',
    flexDirection: 'row',
    whiteSpace: 'nowrap',
    fontSize: '1.8rem',
    backgroundColor: ({ token }) => token('color.container.static.default'),
    ...focus.styles({
      shadow: 'medium'
    }),
    zIndex: 1
  },

  previewTitleLarge: {
    position: 'sticky',
    top: 0
  },

  previewTitleModal: {
    padding: ({ token }) => `${token('spacing.xs')} ${token('spacing.xl')}`
  },

  previewTitleReport: {
    padding: ({ token }) => `${token('spacing.xs')} ${token('spacing.xxl')}`,
    position: 'sticky',
    top: 0
  },

  truncate: {
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis'
  },

  wrapNav: {
    marginLeft: ({ token }) => token('spacing.xl'),
    position: 'relative',

    '&::before': {
      content: '" "',
      position: 'absolute',
      left: '4.35rem',
      top: '20%',
      height: '60%',
      width: '.1rem',
      background: ({ token }) => token('legacy.color.white')
    }
  },

  navText: {
    paddingTop: '10px',
    minWidth: '4.8rem',
  },

  navPositioning: {
    display: 'inline-flex',
    paddingLeft: ({ token }) => token('spacing.xl')
  },

  navButton: {
    border: '0 none',
    background: ({ token }) => token('legacy.color.blue.grey'),
    color: ({ token }) => token('legacy.color.white'),
    width: '4.4rem',
    height: '4.4rem',
    display: 'inline-flex',
    alignItems: 'center',
    justifyContent: 'center',
    cursor: 'pointer',

    '> svg': {
      display: 'inline-flex',
      width: '1.2rem',
      height: '1.2rem',
      transition: 'transform .2s'
    },

    ':hover': {
      '> svg': {
        transform: 'scale(1.3)'
      }
    },

    '[disabled]': {
      opacity: 0.5
    }
  },

  navPrev: {
    borderTopLeftRadius: BORDER_RADIUS.INPUT,
    borderBottomLeftRadius: BORDER_RADIUS.INPUT
  },

  navNext: {
    borderTopRightRadius: BORDER_RADIUS.INPUT,
    borderBottomRightRadius: BORDER_RADIUS.INPUT
  }
});

type NavigationHeaderProps = {
  previewTitleStyle: {
    large?: boolean;
    modal?: boolean;
    report?: boolean;
  };
  adTypes: AdType[];
  slidePos: number;
  showPrev: () => void;
  showNext: () => void;
  children: ReactNode;
};
export const NavigationHeader: FC<NavigationHeaderProps> = ({
  previewTitleStyle,
  adTypes,
  slidePos,
  showPrev,
  showNext,
  children
}) => {
  const s = useStyles(styles);
  const currentAdType = adTypes[slidePos];
  const adDefinition = adPreviews[currentAdType.format_id];
  return (
    <div
      {...s('previewTitle', {
        previewTitleLarge: previewTitleStyle.large,
        previewTitleModal: previewTitleStyle.modal,
        previewTitleReport: previewTitleStyle.report
      })}
    >
      <div {...s('truncate', 'navText')}>{adDefinition.label}</div>

      {children}
      <div {...s('navPositioning')}>
        <div {...s('navText')}>
          {slidePos + 1} of {adTypes.length}
        </div>
        <div {...s('wrapNav')}>
          <button
            {...s('navButton', 'navPrev')}
            onClick={showPrev}
            data-testid={'nav-prev-btn'}
          >
            <ChevronLeft />
          </button>
          <button
            {...s('navButton', 'navNext')}
            onClick={showNext}
            data-testid={'nav-next-btn'}
          >
            <ChevronRight />
          </button>
        </div>
      </div>
    </div>
  );
};

import { query } from '@rexlabs/model-generator';
import facebookModel from 'src/data/models/entities/facebook';

const facebookQuery = query`{
  ${facebookModel} (id: "fake", force: "true") {
    attributes {
      status {
        id
      }
      selected_page_id
    }
    pages
  }
}`;

const facebookStatusQuery = query`{
  ${facebookModel} (id: "fake", include: "pages") {
    attributes {
      status
    }
  }
}`;

export { facebookQuery, facebookStatusQuery };

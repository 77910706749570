import { Box } from '@rexlabs/box';
import { GhostButton, PrimaryButton } from '@rexlabs/button';
import { withModel } from '@rexlabs/model-generator';
import { autobind } from 'core-decorators';
import React, { PureComponent } from 'react';
import sessionModel from 'src/data/models/custom/session';
import wizardModel from 'src/data/models/custom/wizard';
import ROUTES from 'src/routes/admin';
import { withToken } from 'src/theme';
import { push } from 'src/utils/whereabouts';
import { ButtonBar } from 'src/view/components/button';
import ChevronLeft from 'src/assets/icons/chevron-left.svg';
import ChevronRight from 'src/assets/icons/chevron-right.svg';
import { AccountNotReadyScreenContent } from 'src/features/wizard/components/AccountNotReadyScreen';

@withToken
@withModel(wizardModel)
@withModel(sessionModel)
@autobind
class AccountNotReadyScreen extends PureComponent {
  handleExit() {
    const {
      wizard: { flush }
    } = this.props;
    push(ROUTES.CAMPAIGNS);
    flush();
  }

  render() {
    const {
      wizard: { setStep, step },
      token
    } = this.props;
    return (
      <Box flex={1} flexDirection='column'>
        <AccountNotReadyScreenContent />

        <ButtonBar>
          <GhostButton
            IconLeft={ChevronLeft}
            onClick={() => setStep({ step: step - 1 })}
          >
            Back
          </GhostButton>
          <Box flexDirection='row' alignItems='center'>
            <GhostButton grey onClick={this.handleExit}>
              Save and Exit
            </GhostButton>
            <Box ml={token('spacing.xl')}>
              <PrimaryButton
                onClick={() => null}
                IconRight={ChevronRight}
                isDisabled={true}
              >
                Next
              </PrimaryButton>
            </Box>
          </Box>
        </ButtonBar>
      </Box>
    );
  }
}

export default AccountNotReadyScreen;

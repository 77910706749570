import { createRestAPIModelGenerator } from 'src/data/models/generator';
import { api } from 'src/utils/api-client';

export const TYPE = 'stats';

const config = {
  entities: {
    api: {
      fetchItem: (type, args, id) =>
        api.get(`/campaigns/${id}/stats`, args).then(({ data }) => ({
          // Stats endpoint doesn't return an id, so we use the campaign id
          // as entity id for proper normalisation
          data: {
            id,
            ...data
          }
        }))
    }
  }
};

const StatsGenerator = createRestAPIModelGenerator(TYPE, config);
export default StatsGenerator.createEntityModel();

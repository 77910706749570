import { Box } from '@rexlabs/box';
import { CheckboxGroup, CheckboxOption } from '@rexlabs/checkbox';
import { Body, Tiny } from '@rexlabs/text';
import React, { forwardRef, useCallback, useMemo } from 'react';
import { UseFormControlReturn, useFormControl } from 'src/components/form';
import { Consumer } from 'src/types';
import { HelpIcon } from 'src/view/components/tooltip';
import { Network, NetworkType } from '../types';

export type NetworkSelectorProps = UseFormControlReturn<HTMLInputElement> & {
  name: string;
  value?: NetworkType[];
  onChange: Consumer<NetworkType>;
  networks: Network[];
};

const networkToCheckbox = ({
  id,
  label,
  enabled,
  locked,
  tooltip,
  sublabel
}: Network): CheckboxOption => {
  return {
    value: id,
    label: (
      <span>
        {label}{' '}
        {tooltip && (
          <span
            onClick={(e) => {
              e.preventDefault();
              e.stopPropagation();
            }}
          >
            <HelpIcon
              placement='top'
              Content={() => (
                <Box style={{ width: '20rem' }}>
                  <Body>{tooltip}</Body>
                </Box>
              )}
            />
          </span>
        )}
      </span>
    ),
    props: {
      disabled: !enabled || locked,
      description: sublabel ? <Tiny grey>{sublabel}</Tiny> : undefined
    }
  };
};

export const NetworkSelector = forwardRef<HTMLElement, NetworkSelectorProps>(
  (props) => {
    const {
      name,
      value,
      onChange,
      onBlur,
      networks,
      disabled,
      readOnly,
      isInvalid
    } = useFormControl(props);
    const options = useMemo(() => networks.map(networkToCheckbox), [networks]);

    const adaptedOnChange = useCallback(
      (e) => onChange(e.target.value),
      [onChange]
    );
    const adaptedOnBlur = useCallback((target) => onBlur?.(target), [onBlur]);

    return (
      <CheckboxGroup
        disabled={disabled || readOnly}
        meta={isInvalid ? { error: '' } : undefined}
        name={name}
        options={options}
        value={value}
        onChange={adaptedOnChange}
        onBlur={adaptedOnBlur}
      />
    );
  }
);

import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { styled, StyleSheet } from '@rexlabs/styling';
import Box from '@rexlabs/box';
import colorTheme from '../../../utils/color-theme';
import { SmallButton, LargeButton } from '../../button';
import ScaledText from '../../scaled-text';
import { COLORS } from 'src/theme';
import _ from 'lodash';

export const types = {
  DEFAULT: 'default',
  LARGE: 'large',
  SMALL: 'small'
};

const fontFamily = 'Lato, sans-serif';

const defaultStyles = StyleSheet({
  leaderboard: {
    border: 'solid 1px darkgray',
    overflow: 'hidden',
    display: 'flex',
    flexGrow: 0,
    flexShrink: 0
  },
  shortHeadline: {
    fontSize: '24px',
    lineHeight: '28px',
    fontWeight: '400',
    fontFamily,
    marginRight: '24px'
  },
  img: {
    objectFit: 'cover'
  },
  content: {
    backgroundColor: 'white'
  },
  logoWrapper: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center'
  },
  logo: {
    height: '100%',
    width: '100%',
    objectFit: 'contain'
  }
});

@styled(defaultStyles)
class Leaderboard extends PureComponent {
  static propTypes = {
    brandColor: PropTypes.string,
    shortHeadline: PropTypes.string,
    primaryImageUrls: PropTypes.object,
    logoUrls: PropTypes.object
  };

  static defaultProps = {
    type: types.DEFAULT
  };

  leaderboardDimension() {
    const { type } = this.props;
    switch (type) {
      case types.LARGE:
        return { width: '970px', height: '90px' };
      case types.DEFAULT:
      default:
        return { width: '728px', height: '90px' };
    }
  }

  logoWrapperDimensions() {
    const { type } = this.props;
    switch (type) {
      case types.LARGE:
        return { width: '190px', height: '90px' };
      case types.DEFAULT:
      default:
        return { width: '142px', height: '90px' };
    }
  }

  logoDimensions() {
    const { type } = this.props;
    return {
      maxWidth: `calc(0.6 * ${type === types.LARGE ? '190px' : '142px'})`,
      maxHeight: 'calc(0.7 * 90px)'
    };
  }

  button() {
    const { type, brandColor } = this.props;
    switch (type) {
      case types.LARGE:
        return (
          <Box width={134}>
            <LargeButton brandColor={brandColor}>Find out now</LargeButton>
          </Box>
        );
      case types.SMALL:
      default:
        return (
          <Box width={113}>
            <SmallButton brandColor={brandColor}>Find out now</SmallButton>
          </Box>
        );
    }
  }

  render() {
    const {
      brandColor,
      backgroundColor,
      shortHeadline,
      primaryImageUrls,
      logoUrls,
      type,
      styles: s
    } = this.props;

    const { brandColorBorder } = colorTheme(brandColor);
    const isLarge = type === types.LARGE;

    return (
      <Box {...s.with('leaderboard')({ ...this.leaderboardDimension() })}>
        <img
          width={204}
          height={90}
          src={_.get(primaryImageUrls, ['crop.landscape', 'url'])}
          {...s('img')}
        />
        <Box
          flex={1}
          justifyContent='space-between'
          alignItems='center'
          padding='0px 24px'
          {...s('content')}
        >
          <ScaledText
            maxWidth={isLarge ? 348 : 216}
            maxTextLines={2}
            maxFontSize={isLarge ? 32 : 22}
            minFontSize={isLarge ? 16 : 12}
            {...s.with('shortHeadline')({ color: COLORS.BLACK })}
          >
            {shortHeadline}
          </ScaledText>
          {this.button()}
        </Box>
        <Box
          {...s.with('logoWrapper')({
            ...this.logoWrapperDimensions(),
            backgroundColor: backgroundColor,
            borderLeft: brandColorBorder
          })}
        >
          <img
            src={_.get(logoUrls, 'original.url')}
            {...s.with('logo')({ ...this.logoDimensions() })}
          />
        </Box>
      </Box>
    );
  }
}

export default Leaderboard;

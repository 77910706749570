import { Box } from '@rexlabs/box';
import { PrimaryButton } from '@rexlabs/button';
import { withModel } from '@rexlabs/model-generator';
import { styled, StyleSheet, useToken } from '@rexlabs/styling';
import { Body, Heading } from '@rexlabs/text';
import React from 'react';
import { Helmet } from 'react-helmet';
import { compose } from 'redux';
import sessionModel from 'src/data/models/custom/session';
import { formattedBrandName } from 'src/theme';
import { AuthBox } from 'src/view/components/box';
import { Form, FormField, ReactForms } from 'src/view/components/form';
import { Select } from 'src/view/components/input';

const defaultStyles = StyleSheet({
  divider: {
    width: '100%',
    height: '.1rem',
    marginBottom: '1rem',
    background: ({ token }) => token('legacy.color.blue.greyLight')
  },

  fieldWrapper: {
    textAlign: 'left'
  }
});

function SelectAgencyScreen({ session, styles: s }) {
  const token = useToken();
  const handleSubmit = ({ agency }) => {
    return session.setAccount({ id: agency });
  };

  const agencies = session.accounts.map((a) => ({
    label: a.name,
    value: a.id
  }));

  return (
    <AuthBox>
      <Helmet>
        <title>{formattedBrandName} - Select agency</title>
      </Helmet>
      <Heading level={2}>Select Agency</Heading>
      <div {...s('divider')}></div>
      <Body>
        Your {formattedBrandName} account is associated with multiple agencies,
        please select an agency to continue.
      </Body>
      <ReactForms handleSubmit={handleSubmit}>
        {({ submitForm, isSubmitting }) => (
          <Form name='selectAgency'>
            <Box mb={token('spacing.xl')} {...s('fieldWrapper')}>
              <FormField
                name='agency'
                label='Select agency'
                Input={Select}
                inputProps={{ options: agencies }}
              />
            </Box>
            <PrimaryButton onClick={submitForm} isLoading={isSubmitting}>
              Continue
            </PrimaryButton>
          </Form>
        )}
      </ReactForms>
    </AuthBox>
  );
}

export default compose(
  styled(defaultStyles),
  withModel(sessionModel)
)(SelectAgencyScreen);

import ActionMenu from '@rexlabs/action-menu';
import Avatar from '@rexlabs/avatar';
import { Box } from '@rexlabs/box';
import { GhostCircleButton } from '@rexlabs/button';
import Icons from '@rexlabs/icons-next';
import { useModelActions } from '@rexlabs/model-generator';
import { StyleSheet, useStyles, useToken } from '@rexlabs/styling';
import { Body, Truncate } from '@rexlabs/text';
import { capitalize } from 'lodash';
import React, { forwardRef } from 'react';
import accountsModel from 'src/data/models/sysadmin/accounts';
import ROUTES from 'src/routes/sysadmin';
import { push } from 'src/utils/whereabouts';

const defaultStyles = StyleSheet({
  container: {
    width: '100%',
    padding: ({ token }) => token('spacing.m'),
    flexShrink: 0,
    transition: 'background .2s',

    ':hover': {
      background: ({ token }) => token('color.container.interactive.hover')
    },

    '> *': {
      flexShrink: 0
    }
  },

  name: {
    width: '29rem'
  }
});

function AccountListItem({ data }) {
  const token = useToken();
  const s = useStyles(defaultStyles);
  const accounts = useModelActions(accountsModel);

  const isReady = data?.ready;
  const items = [];
  if (isReady) {
    items.push({
      label: 'Block account for manual review',
      onClick: () => accounts.deactivate({ id: data.id })
    });
  } else {
    items.push({
      label: 'Activate account',
      onClick: () => accounts.activate({ id: data.id })
    });
  }

  items.push(
    {
      label: 'Provisioning',
      onClick: () =>
        push(ROUTES.PROVISION_STEPS, {
          params: {
            accountId: data.id,
            provisionId: data.provision.id
          }
        })
    },
    {
      label: 'Ads',
      onClick: () =>
        push(ROUTES.ADS, {
          params: {
            accountId: data.id
          }
        })
    }
  );

  return (
    <Box
      {...s('container')}
      flexDirection='row'
      alignItems='center'
      justifyContent='space-between'
    >
      <Box
        flexDirection='row'
        alignItems='center'
        flex={1}
        spacing={token('spacing.m')}
      >
        <Avatar size='l' name={data.name[0]} />
        <Truncate {...s('name')}>{data.name}</Truncate>
      </Box>

      <Box w='22rem'>
        <Body>{capitalize(data?.provision?.status)}</Body>
      </Box>

      <Box w='14rem'>
        <Body grey={!data.ready}>{data.ready ? 'Yes' : 'No'}</Body>
      </Box>

      <Box w='7rem' justifyContent='flex-end' alignItems='center'>
        <ActionMenu
          Icon={Icons.Kebab}
          items={items}
          Button={forwardRef((props, ref) => (
            <GhostCircleButton ref={ref} {...props} />
          ))}
        />
      </Box>
    </Box>
  );
}

export default AccountListItem;

import { Box } from '@rexlabs/box';
import { styled, StyleSheet, useToken } from '@rexlabs/styling';
import React, { Fragment, useState } from 'react';
import { FONT } from 'src/theme';
import Avatar from 'src/view/components/avatar';
import { FormField, HiddenField } from 'src/view/components/form';
import { TextInput } from 'src/view/components/input';
import SlideImageInput from 'src/view/components/input/image/slide-image-input';
import { Small, Tiny } from 'src/view/components/text';

import MenuIcon from 'src/assets/icons/menu.svg';
import Triangle from 'src/assets/icons/triangle.svg';

const defaultStyles = StyleSheet({
  containerOpen: {
    background: ({ token }) => token('legacy.color.grey.lighter')
  },

  itemContainer: {
    borderTop: ({ token }) =>
      `.1rem solid ${token('legacy.color.grey.default')}`,
    paddingTop: ({ token }) => token('spacing.xs'),
    paddingRight: ({ token }) => token('spacing.m'),
    paddingBottom: ({ token }) => token('spacing.xs')
  },

  settings: {
    color: ({ token }) => token('color.primary.idle.default'),
    padding: '.2rem 0',
    fontWeight: FONT.WEIGHTS.SEMIBOLD,

    '> svg': {
      verticalAlign: 'middle',
      transform: 'scale(1, -1)',
      trasnform: 'scale(1.3)'
    },

    ':hover': {
      color: ({ token }) => token('color.primary.hover.default'),
      cursor: 'pointer'
    }
  },

  hideSettings: {
    '> svg': {
      transform: 'scale(1, 1)'
    }
  },

  triangle: {
    color: ({ token }) => token('color.primary.idle.default')
  },

  settingsContainer: {
    display: 'none',
    padding: ({ token }) => token('spacing.m'),
    paddingBottom: ({ token }) => token('spacing.xl')
  },

  settingsContainerOpen: {
    display: 'block'
  },

  menuIcon: {
    color: ({ token }) => token('palette.grey.500')
  },

  menuIconDragging: {
    color: ({ token }) => token('color.primary.active.default')
  }
});

function Item({
  values,
  data,
  index,
  campaignId,
  backgroundColor,
  dragHandleProps,
  isDragging,
  image,
  styles: s
}) {
  const [showSettings, setShowSettings] = useState(false);
  const token = useToken();
  const type = data.type.toLowerCase();
  const isListing = type === 'listing';
  const isAgency = type === 'agency';
  const agencyLogo = data?.image?.sizes?.['original.thumbnail']?.url;
  const crop = Object.values(image?.crops?.square?.top_left || {}).join();
  const imageUrl = data?.image?.sizes?.['crop.square']?.url + `&key=${crop}`;
  const slide = values?.prospecting?.slides?.[index];

  return (
    <Box {...s({ containerOpen: showSettings })}>
      <Box flexDirection='row' alignItems='center' {...s('itemContainer')}>
        <div {...dragHandleProps}>
          <Box
            justifyItems='center'
            ml={token('spacing.m')}
            mr={token('spacing.m')}
          >
            <MenuIcon {...s('menuIcon', { menuIconDragging: isDragging })} />
          </Box>
        </div>

        <Box>
          <Avatar
            key={crop}
            round
            src={isAgency ? agencyLogo : imageUrl}
            imageContain={isAgency}
            bgColor={isAgency && backgroundColor}
          />
        </Box>

        <Box>
          <Tiny style={{ fontWeight: 'bold', padding: '.2rem 0' }}>
            Position {index + 1}
          </Tiny>
          <Small
            style={{
              padding: '.2rem 0',
              maxHeight: '4.3rem',
              overflow: 'hidden'
            }}
          >
            {isListing
              ? slide?.long_headline
              : isAgency
              ? data?.business_name
              : data?.full_name}
          </Small>
          <Tiny
            {...s('settings', { hideSettings: !showSettings })}
            onClick={() => setShowSettings(!showSettings)}
          >
            {showSettings ? 'Hide settings' : 'Show settings'}{' '}
            <Triangle {...s('triangle')} />
          </Tiny>
        </Box>
      </Box>

      <Box {...s('settingsContainer', { settingsContainerOpen: showSettings })}>
        <HiddenField name={`prospecting.slides.${index}.id`} />
        <HiddenField name={`prospecting.slides.${index}.type`} />
        <HiddenField name={`prospecting.slides.${index}.${type}`} />

        {isListing && (
          <Fragment>
            <HiddenField name={`prospecting.slides.${index}.beds`} />
            <HiddenField name={`prospecting.slides.${index}.baths`} />
            <HiddenField name={`prospecting.slides.${index}.cars`} />
          </Fragment>
        )}

        <Box w='20rem' style={isAgency ? { display: 'none' } : {}}>
          <FormField
            name={`prospecting.slides.${index}.image`}
            Input={SlideImageInput}
            inputProps={{
              campaignId
            }}
            sendImmediate
          />
        </Box>

        <FormField
          name={`prospecting.slides.${index}.short_headline`}
          label='Short headline'
          Input={TextInput}
          inputProps={{
            charLimit: isListing ? 30 : 50,
            showCharLimit: true,
            isSoftLimit: true
          }}
        />

        {isListing && (
          <Fragment>
            <FormField
              name={`prospecting.slides.${index}.long_headline`}
              label='Long headline'
              Input={TextInput}
              inputProps={{
                charLimit: 40,
                isSoftLimit: true,
                showCharLimit: true
              }}
            />

            <FormField
              name={`prospecting.slides.${index}.small_text`}
              label='Ad label text'
              Input={TextInput}
              inputProps={{
                charLimit: 25,
                showCharLimit: true,
                isSoftLimit: true
              }}
            />
          </Fragment>
        )}
      </Box>
    </Box>
  );
}

export default styled(defaultStyles)(Item);

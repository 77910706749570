/* eslint-disable @typescript-eslint/no-explicit-any */
import { Box } from '@rexlabs/box';
import {
  query,
  useEntityListQuery,
  useEntityQuery,
  useModelActions,
  withValueLists
} from '@rexlabs/model-generator';
import React, { ComponentType, useCallback, useMemo } from 'react';
import { compose } from 'redux';
import campaignsModel from 'src/data/models/entities/campaigns';
import customPackagesModel from 'src/data/models/entities/custom-packages';
import campaignGoalsModel from 'src/data/models/value-lists/campaign-goals';
import { LimitedBudgetEditor } from 'src/view/components/limited-budget';
import { LoadingSpinner } from 'src/view/components/loading';

const buildCampaignQuery = (id: string) => query`{
  ${campaignsModel} (id: ${id}) {
    id
    name
    goal {
      id
      is_advanced_strategy
    }
    type {
      id
    }
    selected_package {
      id
    }
    start_date
    audience_spec
    custom_package_options {
      is_available
    }
    campaign_template {
      slug
    }
  }
}`;

const buildCustomPackagesQuery = (id: string) => query`{
  ${customPackagesModel} (campaignId: ${id}) {
    id
    price
    duration
    name
    type
    is_custom
    is_default
    budget
    updated_at
  }
}`;
export interface LimitedBudgetEditorProps {
  campaignId: string;
  closeModal();
  onSave();
}

interface InjectedProps {
  valueLists: {
    networkAdTypes: any;
    campaignGoals: any;
  };
}

const LimitedBudgetEditorContainer = ({
  campaignId,
  valueLists: { campaignGoals: campaignGoalsValueList },
  closeModal,
  ...rest
}: LimitedBudgetEditorProps & InjectedProps) => {
  const campaignQuery = useMemo(
    () => buildCampaignQuery(campaignId),
    [campaignId]
  );
  const customPackagesQuery = useMemo(
    () => buildCustomPackagesQuery(campaignId),
    [campaignId]
  );
  const { data: campaign, status: campaignsStatus } = useEntityQuery(
    campaignQuery
  ) as any;
  const { data: customPackages, status: customPackagesStatus } =
    useEntityListQuery(customPackagesQuery) as any;
  const { items: campaignGoals, status: campaignGoalsStatus } =
    campaignGoalsValueList;
  const { updateItem, createItem } = useModelActions(customPackagesModel);

  const setCustomPackage = useCallback(
    async (data) => {
      if (customPackages[0]) {
        await updateItem({
          id: customPackages[0].id,
          data
        });
      } else {
        await createItem({
          data
        });
      }
    },
    [createItem, customPackages, updateItem]
  );

  if (
    !(
      campaignsStatus === 'loaded' &&
      customPackagesStatus === 'loaded' &&
      campaignGoalsStatus === 'loaded'
    )
  ) {
    return (
      <Box alignItems='center' justifyContent='center' w='80rem'>
        <LoadingSpinner />
      </Box>
    );
  }

  return (
    <LimitedBudgetEditor
      campaign={campaign}
      campaignGoal={campaignGoals.find((g) => g.id === campaign.goal.id)}
      customPackage={customPackages[0]}
      setCustomPackage={setCustomPackage}
      cancelEdit={closeModal}
      {...rest}
    />
  );
};

const Wrapper = compose((withValueLists as any)(campaignGoalsModel))(
  LimitedBudgetEditorContainer
) as ComponentType<LimitedBudgetEditorProps>;

export default (props: LimitedBudgetEditorProps) => <Wrapper {...props} />;

import { useQuery } from 'react-query';
import { QueryConfig, QueryResult } from 'src/lib/react-query';
import { PaginatedResponse, Pagination, PaginationParams } from 'src/types';
import { api } from 'src/utils/api-client';
import { Account } from '../types';
import { accountKeys } from './keys';

export type GetAccountsParams = PaginationParams & {
  includes?: string[];
};

export const getAccounts = async ({
  includes,
  ...rest
}: GetAccountsParams): Promise<PaginatedResponse<Account>> => {
  const params: Record<string, any> = { ...rest };
  if (includes) params.includes = includes.join(',');
  const { data, pagination } = await api.get('/accounts', params);
  return {
    data,
    pagination: pagination as Pagination
  };
};

type QueryFnType = typeof getAccounts;

export type UseAccountsOptions = {
  pagination?: PaginationParams;
  includes?: string[];
  config?: QueryConfig<QueryFnType>;
};

export const useAccounts = ({
  pagination = { page: 1 },
  includes,
  config
}: UseAccountsOptions = {}): QueryResult<PaginatedResponse<Account>> => {
  return useQuery<PaginatedResponse<Account>, Error>({
    queryFn: () => getAccounts({ ...pagination, includes }),
    queryKey: accountKeys.list({ pagination, includes }),
    ...config
  });
};

import React, { FC, useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import { useToast } from '@rexlabs/notifications';
import { StepEditorLayout } from 'src/features/wizard/components/StepEditorLayout';
import { ContentTypeEditorProps } from 'src/features/wizard';
import {
    CatalogListingSetForm,
    CatalogListingSetSubmitHandler
} from "src/features/catalog-listing-sets/components/details-form/CatalogListingSetForm";
import {
    CatalogListingSetFormGuide
} from "src/features/catalog-listing-sets/components/details-form/CatalogListingSetFormGuide";
import {useCreateCatalogListingSet} from "src/features/catalog-listing-sets/api/createCatalogListingSet";
import {parsePostData} from "src/features/catalog-listing-sets/utils/helpers";

export const CatalogListingSetCreateForm: FC<ContentTypeEditorProps> = ({
    selectContent
}) => {
    const navigate = useNavigate();
    const toasts = useToast();

    const { mutateAsync: createCatalogListingSet } = useCreateCatalogListingSet();

    const handleCreate = useCallback<CatalogListingSetSubmitHandler>(
        async (data) => {
            try {
                await createCatalogListingSet(parsePostData(data)).then((res) => {
                    selectContent?.(res.id);
                    navigate('../ListingSet');
                });
            } catch (e) {
                toasts.addToast({
                    type: 'error',
                    title: "Couldn't create listing set",
                    description: e instanceof Error ? e.message : undefined
                });
            }
        },
        [createCatalogListingSet, toasts, navigate]
    );

    const exitEditor = useCallback(() => navigate('..'), [navigate]);

    return (
        <StepEditorLayout
            title='Create New Catalogue'
            Preview={<CatalogListingSetFormGuide />}
        >
            <CatalogListingSetForm
                submitLabel='Save'
                onSubmit={handleCreate}
                onCancel={exitEditor}
            />
        </StepEditorLayout>
    );
};

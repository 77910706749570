/**
 * Takes text as a prop and returns it.
 * This was made for the ComponentRenderer so it can
 * interpolate text and thus to do so text needs to
 * be a prop and not a child.
 *
 * This can't be interpolated with ComponentRenderer:
 *
 * {
 *   type: 'Body'
 *   children: 'hello ${world}'
 * }
 *
 * But this can:
 *
 * {
 *   type: 'Body',
 *   children: {
 *     type: 'Text',
 *     props: { text: 'hello ${world}' }
 *   }
 * }
 */
const Text = ({ text }) => {
  return text;
};

export default Text;

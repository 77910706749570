import { StyleSheet, useStyles } from '@rexlabs/styling';
import { Tiny } from '@rexlabs/text';
import React, { HTMLAttributes, ReactNode } from 'react';

export type ErrorMessageProps = Omit<HTMLAttributes<any>, 'is'> & {
  children?: ReactNode;
};

const styles = StyleSheet({
  container: {
    color: ({ token }) => token('color.textStyle.body.error')
  }
});

export const ErrorMessage = ({
  children,
  className,
  style,
  ...props
}: ErrorMessageProps) => {
  const s = useStyles(styles);
  return (
    <Tiny as='span' {...s.with('container')({ className, style })} {...props}>
      {children}
    </Tiny>
  );
};

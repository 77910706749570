import { query } from '@rexlabs/model-generator';
import packagesModel from 'src/data/models/entities/packages';

export const packageQuery = (getCampaignId) => query`{
  ${packagesModel} (campaignId: ${getCampaignId}) {
    id
    price
    duration
    name
    admin_name
    budget_type
    type {
      id
    }
  }
}`;

import React from 'react';
import { TextInput as VividTextInput } from '@rexlabs/text-input';
import { StyleSheet } from '@rexlabs/styling';
import { TEXTS, FONT, BORDER_RADIUS } from 'src/theme';

export const standardStyles = {
  wrapper: {
    position: 'relative'
  },

  container: {
    position: 'relative',
    background: ({ token }) => token('legacy.color.white'),
    borderRadius: BORDER_RADIUS.INPUT,
    border: ({ token }) => `1px solid ${token('legacy.color.blue.grey')}`,
    paddingLeft: ({ token }) => token('spacing.m'),
    paddingRight: ({ token }) => token('spacing.m'),
    height: '4.5rem'
  },

  input: {
    ...TEXTS.BODY,
    border: 'none'
  },

  error: {
    borderColor: ({ token }) => token('legacy.color.red.default')
  },

  charLimit: {
    position: 'absolute',
    textAlign: 'right',
    top: '100%',
    fontSize: '1.2rem',
    color: ({ token }) => token('legacy.color.blue.grey'),
    padding: ({ token }) => token('spacing.xxs')
  },

  charLimitExceeded: {
    color: ({ token }) => token('legacy.color.red.default'),
    fontWeight: FONT.WEIGHTS.SEMIBOLD
  }
};

const textInputStyles = StyleSheet({
  ...standardStyles
});

const TextInput = React.forwardRef((props, ref) => (
  <VividTextInput styles={textInputStyles} {...props} ref={ref} />
));

export default TextInput;

import { createRestAPIModelGenerator } from 'src/data/models/generator';
import { api } from 'src/utils/api-client';

export const TYPE = 'campaignTemplates';

const config = {
  entities: {
    related: {
      campaign_template_configuration: {
        include: 'campaign_template_configuration'
      }
    },

    api: {
      fetchItem: (type, args, id) =>
        api
          .get(`/campaign-templates/${id}`, args)
          .then(({ data }) => ({ data: { ...data, id } })),
      fetchList: () => api.get('/campaign-templates')
    }
  }
};

const CampaignTemplatesGenerator = createRestAPIModelGenerator(TYPE, config);
export default CampaignTemplatesGenerator.createEntityModel();

import { Box } from '@rexlabs/box';
import { withQuery } from '@rexlabs/model-generator';
import { StyleSheet, useStyles } from '@rexlabs/styling';
import React, { useEffect, useState } from 'react';
import { campaignsLandingPageQuery } from 'src/data/queries/campaigns';
import LandingPagePreview from 'src/view/components/landing-page-preview';
import { RenderLoading } from 'src/view/components/loading';
import { getCampaignId } from 'src/view/modals/admin/campaign/ads-preview';
import { Select } from 'view/components/input';
import { useCatalogListingSetAdPreview } from 'src/features/catalog-listing-sets/api/getCatalogListingSetPreview';
import { CATALOG_CAMPAIGN_NAME } from "src/features/wizard/utils/constants";
import { useCatalogListingSetListings } from "src/features/catalog-listing-sets/api/getCatalogListingSetListings";
import { PreviewPlaceholder } from "src/features/wizard/components/PreviewPlaceholder";

const modalStyles = StyleSheet({
  container: {
    maxWidth: '1180px'
  },

  selectContainer: {
    position: 'relative',
    padding: '0 1.6rem 0.8rem 1.6rem'
  }
});

// eslint-disable-next-line @typescript-eslint/no-unused-vars
function LandingPagePreviewModal({ campaignId, campaigns }) {
  const s = useStyles(modalStyles);
  const status = campaigns?.item?.status;
  const values = campaigns?.item?.data?.landing_pages?.items?.[0];
  const landingPageId = values?.id;

  const [selectedListing, setSelectedListing] = useState();

  const listingSetId = campaigns?.item?.data?.content_source?.entities?.[0]?.id;
  const isCatalogCampaign =
    campaigns?.item?.data?.campaign_definition?.name === CATALOG_CAMPAIGN_NAME;

  const {data: listingSetListingsData, hasNextPage, fetchNextPage } = useCatalogListingSetListings({
    listingSetId: listingSetId ?? '',
    config: {
      enabled: listingSetId !== undefined && isCatalogCampaign
    }
  });

  const {data: listingSetPreviewData} = useCatalogListingSetAdPreview({
    listingSetId: listingSetId ?? '',
    listing_id: selectedListing,
    config: {
      enabled: listingSetId !== undefined && isCatalogCampaign && !!selectedListing
    }
  });

  const listingSelectOptions = listingSetListingsData?.pages?.flatMap(i => i.data)?.map((item) => (
    {
      label: item.address.full_address,
      value: item.id
    }
  )) ?? []

  const selectedListingPreviewData = listingSetPreviewData?.data?.[0];
  const displayCatalogPlaceholder = listingSelectOptions?.length === 0 && isCatalogCampaign;

  const handleChange = (selectedOption) => {
    setSelectedListing(selectedOption.value);
  };

  useEffect(() => {
    if (listingSetListingsData) setSelectedListing(listingSetListingsData?.pages?.[0]?.data?.[0]?.id)
  }, [listingSetListingsData])

  useEffect(() => {
    if (hasNextPage) {
      fetchNextPage();
    }
  }, [hasNextPage])

  return (
    <>
      {isCatalogCampaign ? (
        <Box {...s('selectContainer')}>
          <Select
            options={listingSelectOptions}
            value={selectedListing}
            onSelect={handleChange}
          />
        </Box>
      ) : null}
      <Box w='90vw' {...s('container')}>
        <RenderLoading isLoading={status === 'loading'}>
          {displayCatalogPlaceholder ? (
            <PreviewPlaceholder
              title={'Preview not available yet...'}
              subtitle={"Don’t sweat it, once your catalogue contains matching listings from your CRM your landing pages will be created automatically."}
            />
          ) : (
            <LandingPagePreview
              modal
              landingPageId={landingPageId}
              listingId={selectedListingPreviewData?.attributes?.listing?.listing_id}
              values={values}
            />
          )}
        </RenderLoading>
      </Box>
    </>
  );
}

export default withQuery(campaignsLandingPageQuery(getCampaignId))(
  LandingPagePreviewModal
);

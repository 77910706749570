import { Box } from '@rexlabs/box';
import { border, styled, StyleSheet } from '@rexlabs/styling';
import React from 'react';
import { Body, Small } from 'src/view/components/text';

const defaultStyles = StyleSheet({
  container: {
    flex: '1 0 24rem',
    paddingTop: '2rem',
    paddingBottom: '2rem',
    cursor: 'pointer',
    transition: 'background 200ms',
    background: ({ token }) => token('color.container.interactive.idle'),
    ...border.styles({
      all: {
        width: 'thin',
        color: 'container.interactive.idle'
      }
    }),

    ':hover': {
      background: ({ token }) => token('color.container.interactive.hover'),
      ...border.styles({
        all: {
          width: 'thin',
          color: 'container.interactive.hover'
        }
      })
    }
  },

  wrapper: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center'
  },

  value: {
    fontSize: '4.2rem',
    fontWeight: '500',
    letterSpacing: '-.02rem',
    lineHeight: '4rem'
  },

  moreLink: {
    marginTop: ({ token }) => token('spacing.xxs')
  }
});

function StatTile({ sub, title, value, onClick, styles: s }) {
  return (
    <Box {...s('container')} onClick={onClick}>
      <Box {...s('wrapper')}>
        <Body {...s('value')}>{value}</Body>
        <Small>
          {title} <span onClick={(e) => e.stopPropagation()}>{sub}</span>
        </Small>
        <a {...s('moreLink')}>More details</a>
      </Box>
    </Box>
  );
}

export default styled(defaultStyles)(StatTile);

import {
  NumberInput as VividNumberInput,
  NumberInputProps as VividNumberInputProps
} from '@rexlabs/text-input';
import { noop } from 'lodash';
import React, { ChangeEventHandler, forwardRef, useCallback } from 'react';
import { FormControlOptions, useFormControl } from 'src/components/form';
import { useControllableState } from 'src/hooks';

export type NumberInputProps = Omit<
  VividNumberInputProps,
  'inlist' | 'defaultValue'
> &
  FormControlOptions & {
    defaultValue?: number | null;
  };

export const NumberInput = forwardRef<HTMLInputElement, NumberInputProps>(
  (props, ref) => {
    const {
      value: rawValue,
      defaultValue,
      onChange,
      ...rest
    } = useFormControl(props);

    const [value, controlledOnChange] = useControllableState<number | null>({
      value: rawValue,
      defaultValue,
      onChange: noop
    });

    const updateValue = useCallback<ChangeEventHandler<HTMLInputElement>>(
      (e) => {
        const res = Number.parseFloat(e.target.value);
        controlledOnChange(Number.isNaN(res) ? null : res);
        onChange?.(e);
      },
      [controlledOnChange, onChange]
    );

    return (
      <VividNumberInput
        ref={ref}
        value={value}
        onChange={updateValue}
        {...rest}
      />
    );
  }
);

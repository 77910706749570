import { parseJSON } from 'date-fns';
import {
  Describe,
  Struct,
  coerce,
  date,
  define,
  enums,
  literal,
  nullable,
  number,
  object,
  optional,
  refine,
  size,
  string,
  unknown
} from 'superstruct';
import isEmail from 'validator/es/lib/isEmail';
import isMobilePhone from 'validator/es/lib/isMobilePhone';
import isURL from 'validator/es/lib/isURL';
import isUUID from 'validator/es/lib/isUUID';

export * from 'superstruct';

export const URL = refine(string(), 'url', (value) => {
  return isURL(value);
});

export const Email = refine(string(), 'email', (value) => {
  return isEmail(value) || 'Value must be an email address';
});

export const Phone = refine(string(), 'phone', (value) => {
  return isMobilePhone(value);
});

export const UUID = refine(string(), 'uuid', (value) => isUUID(value));

export const Date = coerce(date(), string(), (value) => parseJSON(value));

export const DEFAULT_MAX_STRING_LENGTH = 255;

export const String = (max = DEFAULT_MAX_STRING_LENGTH): Struct<string, null> =>
  size(string(), 0, max);

export const Optional = <T, U>(
  struct: Struct<T, U>
): Struct<T | undefined, U> =>
  coerce(optional(struct), optional(nullable(struct)), (value) =>
    value === null ? undefined : value
  );

export const Text = define<string>('Text', (v) => typeof v === 'string');

export const FloatString = coerce(number(), string(), (v) =>
  Number.parseFloat(v)
);

export type Type<T extends string> = {
  id: T;
  label: string;
};

export const knownTypeSchema = <U extends string, T extends readonly U[]>(
  values: T
): Describe<Type<T[number]>> =>
  object({
    id: enums<U, T>(values),
    label: string()
  }) as unknown as Describe<Type<T[number]>>;

export const unknownTypeSchema: Describe<Type<string>> = object({
  id: string(),
  label: string()
});

export const Type = <U extends string, T extends readonly U[]>(
  values: T
): Struct<
  T[number],
  {
    [K in T[number]]: K;
  }
> => coerce(enums(values), knownTypeSchema(values), (v) => v.id);

export const LiteralType = <T extends string>(ty: T): Struct<T, T> =>
  coerce(literal(ty), knownTypeSchema([ty]), (v) => v.id);

export const UnknownType = coerce(string(), unknownTypeSchema, (v) => v.id);

export const UnknownJSON = coerce(unknown(), string(), (v) => JSON.parse(v));

import { Box } from '@rexlabs/box';
import { query, withQuery } from '@rexlabs/model-generator';
import { styled, StyleSheet } from '@rexlabs/styling';
import React, { PureComponent } from 'react';
import adsModel from 'src/data/models/entities/ads';
import { BORDER_RADIUS, FONT, UTILS, withToken } from 'src/theme';
import Avatar from 'src/view/components/avatar';
import { CampaignStatus } from 'src/view/components/campaign';
import { Body } from 'src/view/components/text';

const defaultStyles = StyleSheet({
  container: {
    width: '100%',
    padding: ({ token }) => token('spacing.m'),
    flexShrink: 0,
    cursor: 'pointer',
    transition: 'background .2s',

    ':hover': {
      background: ({ token }) => token('legacy.color.grey.lighter')
    },

    '> *': {
      flexShrink: 0
    }
  },

  avatar: {
    width: '7.2rem',
    height: '7.2rem',
    marginRight: ({ token }) => token('spacing.m'),
    background: 'pink no-repeat center center',
    backgroundSize: 'contain',
    borderRadius: BORDER_RADIUS.INPUT,
    position: 'relative',
    flexShrink: 0
  },

  agentAvatar: {
    borderRadius: '50%',
    overflow: 'hidden'
  },

  sold: {
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
  },

  soldTag: {
    padding: ({ token }) => token('spacing.xxs'),
    paddingLeft: ({ token }) => token('spacing.xs'),
    paddingRight: ({ token }) => token('spacing.xs'),
    background: ({ token }) => token('legacy.color.red.default'),
    color: ({ token }) => token('legacy.color.white'),
    fontWeight: FONT.WEIGHTS.BOLD,
    fontSize: '1.3rem',
    textTransform: 'uppercase',
    borderRadius: BORDER_RADIUS.INPUT
  },

  campaignName: {
    ...UTILS.TRUNCATE,
    width: '29rem'
  },

  statusBar: {
    width: '100%',
    height: '.5rem',
    borderRadius: '.5rem',
    background: ({ token }) => token('legacy.color.spoke')
  }
});

const adsQuery = query`{
  ${adsModel} (campaignId: ${(p) => p.data.id}) {
    id
    format {
      id
      label
    }
    network {
      id
      label
    }
    status {
      id
      label
    }
    matchNetwork
  }
}`;

@withToken
@withQuery(adsQuery)
@styled(defaultStyles)
class AccountCampaignListItem extends PureComponent {
  render() {
    const { styles: s, onClick, data, ads, token } = this.props;

    return (
      <Box
        {...s('container')}
        flexDirection='row'
        alignItems='flex-start'
        justifyContent='space-between'
        onClick={onClick}
      >
        <Box flexDirection='row' alignItems='center' style={{ width: '30rem' }}>
          <Avatar
            round={data?.type?.id === 'agent_profile'}
            isSold={data?.attributes?.is_sold}
            src={data?.thumbnails?.['thumbnail.campaign']?.url}
          />
          <Box flexDirection='column'>
            <Body {...s('campaignName')}>{data.name}</Body>
            <CampaignStatus
              status={data?.status?.id}
              label={data?.status?.label}
            />
          </Box>
        </Box>

        <Box flex={1}>
          {ads.list.status === 'loaded' && (
            <Box style={{ width: '100%' }}>
              {ads.list.items.map((ad) => (
                <Box key={ads.id} flexDirection='row'>
                  <Box style={{ width: '25%' }} p={token('spacing.xxs')}>
                    {ad.network.label}
                  </Box>
                  <Box
                    style={{ width: '50%' }}
                    p={token('spacing.xxs')}
                    flexDirection='column'
                  >
                    <span>{ad.format.label}</span>
                    <span
                      style={{
                        fontSize: '1.2rem',
                        color: token('legacy.color.blue.grey')
                      }}
                    >
                      #{ad.id}
                    </span>
                  </Box>
                  <Box
                    style={{ width: '25%', textAlign: 'right' }}
                    p={token('spacing.xxs')}
                  >
                    {ad.status.label}
                  </Box>
                </Box>
              ))}
            </Box>
          )}
        </Box>
      </Box>
    );
  }
}

export default AccountCampaignListItem;

import { StyleSheet, padding, useStyles } from '@rexlabs/styling';
import React from 'react';
import {
  HTMLPolymorphicProps,
  forwardRef,
  poly
} from 'src/lib/polymorphic-factory';

const styles = StyleSheet({
  header: {
    color: ({ token }) => token('color.textStyle.body.default'),
    ...padding.styles({
      all: () => '1.8rem'
    })
  }
});

export type CardHeaderProps = HTMLPolymorphicProps<'div'>;

export const CardHeader = forwardRef<'div', CardHeaderProps>(
  ({ className, style, ...rest }, ref) => {
    const s = useStyles(styles, 'CardHeader');
    return (
      <poly.div
        ref={ref}
        {...s.with('header')({ className, style })}
        {...rest}
      />
    );
  }
);

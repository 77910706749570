import React, { Component } from 'react';
import _isEqual from 'lodash/isEqual';

import ListingFacebookAd from 'shared/components/listing-facebook-ad';
import MultiListingSoldFacebookAd from 'shared/components/multi-listing-sold-facebook-ad';
import AppraisalFacebookAd from 'shared/components/appraisal-facebook-ad';
import AgentFacebookAd from 'shared/components/agent-facebook-ad';
import DesktopFeedVideo from './desktop-feed-video';

class FacebookAdPreview extends Component {
  shouldComponentUpdate(nextProps) {
    return !_isEqual(this.props.campaignData, nextProps.campaignData);
  }

  render() {
    const { ad, campaignData } = this.props;
    // format is campaign_type.network.ad_type.ad_variation
    const format = ad?.format?.id ?? '';
    const split = format.split('.');
    const campaignType = split[0];
    const adType = split[2];

    if (adType === 'video') {
      return <DesktopFeedVideo adContentSet={campaignData} />;
    }

    if (campaignType === 'agent') {
      return <AgentFacebookAd ad={ad} adContentSet={campaignData} />;
    }

    if (campaignType === 'listing') {
      return (
        <ListingFacebookAd
          ad={ad}
          adType={adType}
          adContentSet={campaignData}
        />
      );
    }

    if (campaignType === 'multi_listing_sold') {
      return (
        <MultiListingSoldFacebookAd
          ad={ad}
          adType={adType}
          adContentSet={campaignData}
        />
      );
    }

    if (campaignType === 'appraisal') {
      return <AppraisalFacebookAd ad={ad} adContentSet={campaignData} />;
    }

    return <span>Ad not found</span>;
  }
}

export default FacebookAdPreview;

import React, { PureComponent } from 'react';
import ActionMenu from '@rexlabs/action-menu';
import SecondaryButton from './secondary';
import { styled, StyleSheet } from '@rexlabs/styling';

const defaultStyles = StyleSheet({
  container: {
    display: 'inline-block'
  }
});

@styled(defaultStyles)
class ActionButton extends PureComponent {
  render() {
    const { styles: s, buttonProps, children, ...props } = this.props;
    return (
      <div {...s('container')}>
        <ActionMenu
          {...props}
          Button={() => (
            <SecondaryButton {...buttonProps}>{children}</SecondaryButton>
          )}
          placement='bottom'
        />
      </div>
    );
  }
}

export default ActionButton;

import { useMutation, useQueryClient } from 'react-query';
import { useAccountId } from 'src/features/accounts';
import { MutationResult } from 'src/lib/react-query';
import { api } from 'src/utils/api-client';
import { CommercialListing } from '../types';
import { commercialListingKeys } from 'src/features/listings/api/keys';

export type DeleteCommercialListingParams = {
  accountId: string;
  listingId: string;
};

export const deleteCommercialListing = async ({
  listingId
}: DeleteCommercialListingParams): Promise<void> => {
  await api.delete(`/commercial-listings/${listingId}`);
};

export type UseDeleteCommercialListingVariables = Omit<
  DeleteCommercialListingParams,
  'accountId'
>;

export const useDeleteCommercialListing = (): MutationResult<
  void,
  UseDeleteCommercialListingVariables,
  { previousValue?: CommercialListing }
> => {
  const queryClient = useQueryClient();
  const accountId = useAccountId();

  return useMutation({
    mutationFn: (vars) => deleteCommercialListing({ accountId, ...vars }),
    onSettled: () => {
      queryClient.invalidateQueries(commercialListingKeys.lists({ accountId }));
    }
  });
};

import {
  focus,
  margin,
  padding,
  StyleSheet,
  useStyles
} from '@rexlabs/styling';
import React, { CSSProperties, FC, ReactNode, useMemo } from 'react';

const styles = (spacing: string): Record<'container', string> =>
  StyleSheet({
    container: {
      display: 'inline-flex',
      width: '100%',
      ...padding.styles({
        all: 's'
      }),

      '& > * + *': {
        ...margin.styles({
          left: spacing
        })
      }
    },
    sticky: {
      position: 'sticky',
      bottom: 0,
      background: ({ token }) =>
        `linear-gradient(to bottom, ${token(
          'palette.tint.light.800'
        )} 0%,${token('palette.white')} 70%)`,
      ...focus.styles({
        shadow: 'buttonGroup'
      })
    }
  });

export type ButtonGroupProps = {
  spacing?: string;
  sticky?: boolean;
  className?: string;
  style?: CSSProperties;
  children?: ReactNode;
};

export const ButtonGroup: FC<ButtonGroupProps> = ({
  spacing = 'xs',
  sticky = false,
  children,
  ...styling
}) => {
  const styleObject = useMemo(() => styles(spacing), [spacing]);
  const s = useStyles(styleObject, 'ButtonGroup');

  return (
    <div
      {...s.with('container', {
        sticky
      })(styling)}
    >
      {children}
    </div>
  );
};

import { noop } from 'lodash';
import React, { Component } from 'react';
import { autobind } from 'core-decorators';

@autobind
class OptionCore extends Component {
  static defaultProps = {
    isPassive: false,
    onHover: noop,
    onSelect: noop
  };

  componentDidMount() {
    const { isSelected, scrollIntoView } = this.props;
    if (isSelected) {
      setTimeout(() => scrollIntoView(this.el), 0);
    }
  }

  componentDidUpdate({ isActive: prevIsActive }) {
    const { isActive, scrollIntoView } = this.props;
    // When the option becomes active, we should scroll to it's position.
    if (isActive && !(prevIsActive === isActive)) {
      scrollIntoView(this.el);
    }
  }

  render() {
    const { isActive, isPassive, isSelected, children, option, term, ...rest } =
      this.props;
    return (
      <div
        {...rest}
        ref={this.setRef}
        onMouseEnter={isPassive ? this.nullEvent : this.handleMouseEnter}
        onMouseMove={isPassive ? undefined : this.handleMouseMove}
        onMouseDown={isPassive ? this.nullEvent : this.handleMouseDown}
        onTouchStart={isPassive ? undefined : this.handleTouchStart}
        onTouchMove={isPassive ? undefined : this.handleTouchMove}
        onTouchEnd={isPassive ? this.nullEvent : this.handleTouchEnd}
      >
        {children}
      </div>
    );
  }

  setRef(ref) {
    this.el = ref;
  }

  handleMouseHover(event) {
    const { index, option, onHover } = this.props;
    onHover(index, option, event);
  }

  handleMouseEnter(event) {
    this.handleMouseHover(event);
  }

  handleMouseMove(event) {
    this.handleMouseHover(event);
  }

  handleMouseDown(event) {
    const { option, onSelect } = this.props;
    onSelect(option, event);
  }

  handleTouchStart() {
    // When a touch starts from inside an Option, we know the screen *is not*
    // being dragged; we should hand it's end event with an `onSelect`.
    this.isBeingDragged = false;
  }

  handleTouchMove() {
    // As soon as the user starts dragging there finger on an Option, we no
    // longer want to action it's end with an `onSelect`.
    this.isBeingDragged = true;
  }

  handleTouchEnd(event) {
    if (this.isBeingDragged) return;

    this.handleMouseDown(event);
  }

  nullEvent(event) {
    event.stopPropagation();
    event.preventDefault();
  }
}

export default OptionCore;

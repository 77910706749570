import { Box } from '@rexlabs/box';
import CSSAnimationGroup from '@rexlabs/css-animation-group';
import { withModel } from '@rexlabs/model-generator';
import {
  RenderMatchedRoutes,
  withWhereabouts
} from '@rexlabs/react-whereabouts';
import { styled, StyleSheet } from '@rexlabs/styling';
import React, { PureComponent } from 'react';
import { Helmet } from 'react-helmet';
import wizardModel from 'src/data/models/custom/wizard';
import ROUTES from 'src/routes/admin';
import SYSADMIN from 'src/routes/sysadmin';
import { FONT, formattedBrandName, KEYFRAMES, withToken } from 'src/theme';
import { push } from 'src/utils/whereabouts';
import { ModalTarget } from 'src/view/components/portal';
import NotFoundScreen from 'src/view/screens/not-found';

import { AccountActionMenu } from '../components/action-menu';
import TopNavBar from '../components/top-nav';

const defaultStyles = StyleSheet({
  topNav: {
    width: '100%',
    padding: ({ token }) => `0 ${token('spacing.xl')}`,
    height: '7rem',
    background: ({ token }) => token('legacy.color.purple.default'),
    zIndex: 10
  },

  logoLink: {
    display: 'inline-flex'
  },

  logo: {
    width: '7rem'
  },

  link: {
    color: ({ token }) => token('legacy.color.white')
  },

  wrapContent: {
    paddingTop: '7rem'
  },

  content: {
    width: '100%',
    minWidth: '102.4rem',
    maxWidth: '102.4rem',
    padding: ({ token }) => token('spacing.xs'),
    paddingTop: ({ token }) => token('spacing.xxxl')
  },

  campaignPerformanceContent: {
    width: '100%',
    maxWidth: '80%',
    padding: ({ token }) => token('spacing.xs'),
    paddingTop: ({ token }) => token('spacing.xxxl')
  },

  innerContent: {
    position: 'relative',
    width: '100%'
  },

  avatar: {
    width: '4rem',
    height: '4rem',
    borderRadius: '4rem',
    background: ({ token }) => token('legacy.color.white'),
    marginLeft: ({ token }) => token('spacing.xs'),
    border: '0 none',
    color: ({ token }) => token('legacy.color.purple.default'),
    fontSize: '1.4rem',
    fontWeight: FONT.WEIGHTS.SEMIBOLD,
    cursor: 'pointer',
    transition: 'transform .2s',

    ':hover': {
      transform: 'scale(1.1)'
    }
  },

  iconButton: {
    width: '4rem',
    height: '4rem',
    borderRadius: '4rem',
    background: ({ token }) => token('legacy.color.primary.dark'),
    marginLeft: ({ token }) => token('spacing.xs'),
    color: ({ token }) => token('legacy.color.white'),
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    border: '0 none',
    cursor: 'pointer',
    transition: 'transform .2s',

    '> svg': {
      width: '1.4rem',
      height: '1.4rem'
    },

    ':hover': {
      transform: 'scale(1.1)'
    }
  },

  animationGroup: {
    display: 'flex',
    flex: 1,
    flexDirection: 'column',
    width: '100%',

    '& > div:nth-child(2)': {
      width: '100%',
      position: 'absolute'
    }
  },

  sysadmin: {
    color: ({ token }) => token('color.textStyle.primary.idle.default'),
    fontWeight: ({ token }) => token('typography.weight.black'),
    fontSize: '1.4rem'
  }
});

@withToken
@withWhereabouts()
@withModel(wizardModel)
@styled(defaultStyles)
class SysadminLayout extends PureComponent {
  componentDidMount() {
    const {
      wizard: { flush }
    } = this.props;
    // whenever the admin shell is rendered, we want to clear the wizard
    // state, just to make sure that even when the user leaves the campaign
    // creation process by manually typing in a url, we don't keep this information
    // hanging around!
    flush();
  }

  render() {
    const { styles: s, whereabouts, token } = this.props;

    const contentStyle =
      whereabouts.path === SYSADMIN.CAMPAIGN_PERFORMANCE.config.path
        ? 'campaignPerformanceContent'
        : 'content';

    return (
      <Box flex={1} flexDirection='column'>
        <Helmet>
          <title>{formattedBrandName} - System Admin</title>
        </Helmet>
        <ModalTarget />

        <TopNavBar
          Left={
            <Box ml={token('spacing.xxxl')}>
              <span {...s('sysadmin')}>SYSADMIN</span>
            </Box>
          }
          Right={
            <AccountActionMenu
              userMenuItems={[
                {
                  label: `Back to ${formattedBrandName} App`,
                  onClick: () => push(ROUTES.CAMPAIGNS)
                }
              ]}
            />
          }
          logoRoute={SYSADMIN.ACCOUNTS}
        />

        <Box flex='1' justifyContent='center' {...s('wrapContent')}>
          <Box {...s(contentStyle)} flexDirection='row' flex={1}>
            <Box flex={1} {...s('innerContent')}>
              <RenderMatchedRoutes routes={SYSADMIN} NotFound={NotFoundScreen}>
                {(matches) => (
                  <CSSAnimationGroup
                    leaveAnimation={KEYFRAMES.SCREEN_FADE_OUT}
                    leaveDuration='300ms'
                    enterAnimation={KEYFRAMES.SCREEN_FADE_IN}
                    enterDuration='300ms'
                    {...s('animationGroup')}
                  >
                    {matches}
                  </CSSAnimationGroup>
                )}
              </RenderMatchedRoutes>
            </Box>
          </Box>
        </Box>
      </Box>
    );
  }
}

export default SysadminLayout;

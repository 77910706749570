import React from 'react';
import PropTypes from 'prop-types';
import Box from '@rexlabs/box';
import { styled, StyleSheet } from '@rexlabs/styling';
import _ from 'lodash';
import ScaledText from '../../scaled-text';
import Arrow from '../../arrow';
import { COLORS } from 'src/theme';

const fontFamily = 'Lato, sans-serif';
const defaultStyles = StyleSheet({
  banner: {
    width: '468px',
    height: '50px',
    border: '1px solid darkgray',
    overflow: 'hidden',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    backgroundColor: 'white'
  },
  primaryImage: {
    width: '171px',
    height: '100%',
    objectFit: 'cover',
    backgroundPosition: 'center'
  },
  content: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    justifyContent: 'center',
    marginLeft: '20px'
  },
  shortHeadline: {
    fontFamily,
    fontSize: '20px',
    fontWeight: 300,
    lineHeight: '23px'
  },
  arrow: {
    margin: '0 16px'
  }
});

Banner.propTypes = {
  bannerAdText: PropTypes.string,
  shortHeadline: PropTypes.string,
  brandColor: PropTypes.string,
  primaryImageUrls: PropTypes.object
};

function Banner({ shortHeadline, primaryImageUrls, styles: s }) {
  return (
    <Box {...s('banner')}>
      <img
        {...s('primaryImage')}
        src={_.get(primaryImageUrls, ['crop.landscape', 'url'])}
      />
      <Box {...s('content')}>
        <ScaledText
          maxWidth={230}
          maxTextLines={2}
          maxFontSize={20}
          minFontSize={14}
          {...s.with('shortHeadline')({ color: COLORS.BLACK })}
        >
          {shortHeadline}
        </ScaledText>
      </Box>
      <Arrow color={COLORS.BLACK} width={20} height={20} {...s('arrow')} />
    </Box>
  );
}

export default styled(defaultStyles)(Banner);

import { getCroppedImage, makeAspectCrop } from '@rexlabs/crop-selector';
import { encode } from 'blurhash';
import { set } from 'lodash';
import { isImageMimeType } from './file';
/**
 * Hacky FE workaround!
 * ----
 * Rn BE doesn't provide cropped images, so what we do here is cropping them
 * as we get the image and the cropping data from the BE
 *
 * The crops will be stored in the crop data as a new `blob` property (as base64
 * data url strings!)
 *
 * If the image can't be cropped for whatever reason, we fall back to  using
 * the original image url
 *
 * @param  {Object} itemData
 * @return {Promise} - which resolves enhanced item data!
 */
export function cropEntityImages(itemData) {
  return new Promise((resolve) => {
    if (!itemData.images) {
      resolve(itemData);
      return;
    }

    let imageIndex = 0;
    if (itemData?.ad_content_sets) {
      const prospecting = (itemData?.ad_content_sets?.data ?? []).find((a) =>
        a.type.id.endsWith('prospecting')
      );

      const primaryId =
        prospecting?.attributes?.primary_image?.id ||
        prospecting?.attributes?.agent_image?.id;
      imageIndex = (itemData?.images ?? []).findIndex(
        (i) => i.id === primaryId
      );
    }

    const image = itemData?.images?.[imageIndex !== -1 ? imageIndex : 0];

    if (
      !image ||
      !image.crops ||
      !image.sizes ||
      !image.sizes['original.thumbnail']
    ) {
      resolve(itemData);
      return;
    }

    for (const cropKey in image?.crops ?? {}) {
      set(
        itemData,
        `images.${imageIndex}.crops.${cropKey}.blob`,
        image?.sizes?.['crop.' + cropKey]?.url
      );
      // const scale = image?.sizes?.original?.width
      //   ? image?.sizes?.['original.thumbnail']?.width ?? 0 /
      //     image?.sizes?.original?.width
      //   : 1;
      // const pixelCrop = {
      //   x: image?.crops?.[cropKey]?.top_left?.x ?? 0 * scale,
      //   y: image?.crops?.[cropKey]?.top_left?.y ?? 0 * scale,
      //   width: image?.crops?.[cropKey]?.width ?? 0 * scale,
      //   height: image?.crops?.[cropKey]?.height ?? 0 * scale
      // };
      // let cropBlob = await getCroppedImage({
      //   imageSrc: image?.sizes?.['original.thumbnail']?.url,
      //   pixelCrop
      // }).catch(e => {
      //   if (__DEV__) {
      //     // This might happen in dev env for example due to cross origin
      //     // issues...
      //     console.warn('Could not crop image', e);
      //   }
      // });

      // // For images that couldn't be cropped for whatever reason we want to
      // // fall back to using the original image
      // _.set(
      //   itemData,
      //   `images.${imageIndex}.crops.${cropKey}.blob`,
      //   cropBlob || image?.sizes?.['original.thumbnail']?.url
      // );
    }

    resolve(itemData);
  });
}

function cropHash(src, cropData) {
  return (
    `${src}::` +
    `${cropData.top_left.x}::` +
    `${cropData.top_left.y}::` +
    `${cropData.width}::` +
    `${cropData.height}`
  );
}

const cropCache: any[] = [];
export async function cropSrc(src, original, cropData) {
  const imageSrc =
    src instanceof File
      ? URL.createObjectURL(src)
      : src
      ? typeof src === 'string'
        ? src
        : src.url
      : undefined;

  if (!imageSrc || !cropData) {
    return imageSrc;
  }

  const cache = cropCache.find((c) => c.hash === cropHash(imageSrc, cropData));
  if (cache) {
    return cache.image;
  }

  const scale = original && src ? src.width / original.width : 1;
  const pixelCrop = {
    x: cropData.top_left.x * scale,
    y: cropData.top_left.y * scale,
    width: cropData.width * scale,
    height: cropData.height * scale
  };

  // Attempt to get the origin of the image src
  let origin;
  try {
    origin = new URL(imageSrc).origin;
  } catch (error) {
    console.warn('Unable to parse imageSrc');
  }

  const crop = await getCroppedImage({
    imageSrc,
    pixelCrop,
    quality: 0.5,
    crossOrigin: origin !== window?.location?.origin ? 'anonymous' : null
  }).catch((e) => {
    if (__DEV__) {
      console.warn('Could not crop image', e);
    }
  });

  const image = crop || imageSrc;
  cropCache.push({ hash: cropHash(imageSrc, cropData), image });

  return image;
}

export function getPixelCrop(imageRef, crop) {
  return {
    x: Math.round(imageRef.naturalWidth * (crop.x / 100)),
    y: Math.round(imageRef.naturalHeight * (crop.y / 100)),
    width: Math.round(imageRef.naturalWidth * (crop.width / 100)),
    height: Math.round(imageRef.naturalHeight * (crop.height / 100))
  };
}

export function transformCropData(crop) {
  return {
    top_left: {
      x: crop.x,
      y: crop.y
    },
    width: crop.width,
    height: crop.height
  };
}

export function getDefaultCrop(imageRef, ratio = 'square') {
  const cropAspect = ratio === 'square' ? 1 : 1.9;
  const imageAspect = imageRef.width / imageRef.height;
  const crop: any = { aspect: cropAspect };
  if (imageAspect > cropAspect) {
    const heightRatio = 100 / imageAspect;
    const widthPercent = heightRatio * cropAspect;
    crop.height = 100;
    crop.x = (100 - widthPercent) / 2;
    crop.y = 0;
  } else {
    const widthRatio = 100 * imageAspect;
    const heightPercent = widthRatio / cropAspect;
    crop.width = 100;
    crop.x = 0;
    crop.y = (100 - heightPercent) / 2;
  }

  const aspectCrop = makeAspectCrop(crop, imageAspect);
  const pixelCrop = getPixelCrop(imageRef, aspectCrop);

  return {
    aspect: aspectCrop,
    pixel: pixelCrop,
    crop: transformCropData(pixelCrop)
  };
}

export function getBase64FromUrl(url) {
  return new Promise((resolve) => {
    const img = new Image();
    img.onload = () => {
      const canvas = document.createElement('canvas');
      const ctx = canvas.getContext('2d')!;
      canvas.height = img.height;
      canvas.width = img.width;
      ctx.drawImage(img, 0, 0);
      const dataURL = canvas.toDataURL('image/png');
      resolve(dataURL);
    };
    img.src = url;
  });
}

/**
 * Load a image from a URL. Fails if the browser doesn't support the file format as an image.
 * @param src A URI to a resource that might be an image.
 * @returns A HTML image element with the loaded image.
 */
export const loadImage = (src: string): Promise<HTMLImageElement> => {
  return new Promise((resolve, reject) => {
    const img = new Image();
    img.onload = () => resolve(img);
    img.onerror = (e) => reject(e);
    img.src = src;
  });
};

export async function getImageFromFile(file): Promise<HTMLImageElement> {
  const src = URL.createObjectURL(file);
  const img = await loadImage(src);
  URL.revokeObjectURL(src);
  return img;
}

export const getImageData = (image: HTMLImageElement): ImageData => {
  const canvas = document.createElement('canvas');
  canvas.width = image.width;
  canvas.height = image.height;
  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
  const context = canvas.getContext('2d')!;
  context.drawImage(image, 0, 0);
  return context.getImageData(0, 0, image.width, image.height);
};

export const encodeBlurHash = async (
  image: HTMLImageElement
): Promise<string> => {
  const imageData = getImageData(image);
  return encode(imageData.data, image.width, image.height, 4, 4);
};

export const getFileBlurHash = async (file: File): Promise<string> => {
  if (!isImageMimeType(file.type))
    throw new Error('Cannot generate preview for this type of file.');

  const url = await getImageFromFile(file);
  const hash = await encodeBlurHash(url);
  return hash;
};

import {
  PopoverArrow,
  PopoverClose,
  PopoverContent as RadixPopoverContent,
  PopoverContentProps as RadixPopoverContentProps
} from '@radix-ui/react-popover';
import { GhostIconButton } from '@rexlabs/button';
import Icons from '@rexlabs/icons-next';
import {
  border,
  focus,
  padding,
  StyleSheet,
  useStyles
} from '@rexlabs/styling';
import React, { FC } from 'react';

const styles = StyleSheet({
  container: {
    position: 'relative',
    width: '28rem',
    background: ({ token }) => token('color.container.static.default'),
    ...border.styles({
      all: {
        color: 'neutral.idle.contrast',
        radius: 'm',
        style: 'solid',
        width: 'thin'
      }
    }),
    ...focus.styles({
      shadow: 'menu'
    }),
    ...padding.styles({
      all: 's'
    })
  },

  arrow: {
    fill: 'white'
  },

  close: {
    position: 'absolute',
    top: ({ token }) => token('spacing.s'),
    right: ({ token }) => token('spacing.s')
  }
});

export type PopoverContentProps = RadixPopoverContentProps & {
  arrowWidth?: string | number;
  arrowHeight?: string | number;
};

export const PopoverContent: FC<PopoverContentProps> = ({
  children,
  arrowWidth,
  arrowHeight,
  className,
  style,
  ...rest
}) => {
  const s = useStyles(styles, 'PopoverContent');
  return (
    <RadixPopoverContent {...rest} data-testid={'color-picker-dialog'}>
      <PopoverArrow width={arrowWidth} height={arrowHeight} {...s('arrow')} />
      <div {...s.with('container')({ className, style })}>
        <PopoverClose asChild>
          <GhostIconButton
            {...s('close')}
            size='xs'
            Icon={Icons.CrossSmall}
            data-testid={'close-btn'}
          />
        </PopoverClose>
        {children}
      </div>
    </RadixPopoverContent>
  );
};

import { Box } from '@rexlabs/box';
import { LinkButton } from '@rexlabs/button';
import LoadingSpinner from '@rexlabs/loading-spinner';
import { useToken } from '@rexlabs/styling';
import { Body } from '@rexlabs/text';
import React, { FC } from 'react';
import { Link } from 'react-router-dom';
import { abbreviateNumber } from 'src/utils/format';
import { useResourceDownload } from '../api/getResourceDownload';

export type ResourceDownloadSectionProps = {
  resourceDownloadId: string;
};

export const ResourceDownloadSection: FC<ResourceDownloadSectionProps> = ({
  resourceDownloadId
}) => {
  const token = useToken();
  const { data: resourceDownload, isLoading } = useResourceDownload({
    id: resourceDownloadId
  });

  if (isLoading) {
    return (
      <Box flexDirection='row' alignItems='center'>
        <Box mr={token('spacing.xs')}>
          <LoadingSpinner size={12} strokeWidth={3} />
        </Box>
        <Body grey>Loading resource...</Body>
      </Box>
    );
  }

  return (
    <>
      <Body as='span'>
        {resourceDownload?.upload.original_file_name ??
          resourceDownload?.upload.filename}

        <Link to='../provide-details'>
          <LinkButton
            style={{
              minWidth: 0,
              fontWeight: 300,
              fontSize: token('typography.size.l')
            }}
          >
            Edit
          </LinkButton>
        </Link>
      </Body>
      <Body grey as='span'>
        File size: {abbreviateNumber(resourceDownload?.upload.size)}B
      </Body>
    </>
  );
};

import { createRestAPIModelGenerator } from 'src/data/models/generator';
import { api } from 'src/utils/api-client';

export const TYPE = 'packageEstimates';

const config = {
  entities: {
    api: {
      fetchList: (type, args) =>
        api
          .get(`/campaigns/${args.campaignId}/quotes/package_estimates`)
          .then(({ data }) => ({
            data: data.map((item) => ({
              id: item.package.id,
              ...item.estimate
            }))
          })),
      fetchItem: (type, args, id) =>
        api
          .get(
            `/campaigns/${args.campaignId}/quotes/custom-packages/${id}/estimate`
          )
          .then(({ data, ...rest }) => ({ ...rest, data }))
    }
  }
};

const PackageEstimatesGenerator = createRestAPIModelGenerator(TYPE, config);
export default PackageEstimatesGenerator.createEntityModel();

import { Box } from '@rexlabs/box';
import { styled, StyleSheet, useToken } from '@rexlabs/styling';
import { Heading } from '@rexlabs/text';
import React, { useState } from 'react';
import { BORDER_RADIUS } from 'src/theme';
import { Body } from 'src/view/components/text';
import { EditCampaignNameModal } from 'src/view/modals/wizard/edit-campaign-name';

import { LinkButton } from '@rexlabs/button';
import { Logo } from 'src/components/elements/logo';

const defaultStyles = StyleSheet({
  container: {
    paddingTop: '1rem'
  },

  wrapThumbnail: {
    width: '100%',
    height: 'auto',
    marginRight: 0,
    position: 'relative',
    borderRadius: BORDER_RADIUS.INPUT,
    overflow: 'hidden'
  },

  round: {
    borderRadius: '50%'
  },

  thumbnail: {
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    zIndex: 1,
    background: ({ token }) =>
      `${token('color.container.static.contrast')} center center no-repeat`,
    color: ({ token }) => token('palette.color.white')
  },

  image: {
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    zIndex: 1,
    background: 'transparent no-repeat center center',
    backgroundSize: 'cover'
  },

  logo: {
    width: '30%',
    height: 'auto',
    color: ({ token }) => token('palette.shade.dark.100'),

    '> .mark-stroke': {
      fill: ({ token }) => token('color.container.static.contrast')
    }
  }
});

function CampaignPreview({
 styles: s,
 type,
 src,
 title,
 bgColor,
 imageContain,
 campaignId,
 children = null
}) {
  const token = useToken();
  const [showEditCampaignNameModal, setShowEditCampaignNameModal] =
    useState(false);

  return (
    <Box {...s('container')} flexDirection='column' flexShrink='0'>
      <Body grey>
        Campaign name
        <span>
          {' '}
          ∙{' '}
          <LinkButton
            onClick={(e) => {
              e.preventDefault();
              setShowEditCampaignNameModal(true);
            }}
            style={{ minWidth: 0 }}
          >
            Edit
          </LinkButton>
        </span>
      </Body>
      <Heading
        level={2}
        style={{ marginTop: 0, marginBottom: token('spacing.xl') }}
      >
        {title}
      </Heading>

      <Box
        {...s.with('wrapThumbnail', { round: type === 'multi_listing_sold' })({
          paddingBottom: ['agent_profile', 'multi_listing_sold'].includes(type)
            ? '100%'
            : `${(1 / 1.9) * 100}%`
        })}
      >
        {children ??
          <Box {...s('thumbnail')} alignItems='center' justifyContent='center'>
            <Box
              {...s.with('image')({
                backgroundColor: bgColor,
                backgroundImage: src ? `url(${src})` : 'none',
                backgroundSize: imageContain ? 'contain' : 'cover'
              })}
            />
            <Logo type='mark' {...s('logo')} />
          </Box>
        }
      </Box>

      {showEditCampaignNameModal && (
        <EditCampaignNameModal
          campaignName={title}
          campaignId={campaignId}
          closeModal={() => setShowEditCampaignNameModal(false)}
        />
      )}
    </Box>
  );
}

export default styled(defaultStyles)(CampaignPreview);

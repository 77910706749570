import { Box } from '@rexlabs/box';
import { margin, StyleSheet, useStyles } from '@rexlabs/styling';
import { Body, Heading } from '@rexlabs/text';
import React from 'react';

import PowerAimIcon from 'src/assets/icons/bullseye.svg';

const styles = StyleSheet({
  subheading: {
    color: ({ token }) => token('palette.brand.800')
  },

  icon: {
    width: '2.6rem',
    display: 'inline',
    verticalAlign: 'text-bottom',
    ...margin.styles({
      right: 'xs'
    })
  }
});

export function AdvancedMaximiseExposureGoalDescription() {
  const s = useStyles(styles);
  return (
    <Box>
      <Heading level={3} {...s('subheading')}>
        <PowerAimIcon {...s('icon')} />
        Improved with Intent Targeting
      </Heading>
      <Body grey>
        Leverage our <strong>Intent Targeting</strong> technology that tracks
        audience behaviour to determine those most likely to engage with your
        ads.
      </Body>
    </Box>
  );
}

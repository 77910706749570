import { Box } from '@rexlabs/box';
import { styled, StyleSheet } from '@rexlabs/styling';
import React, { PureComponent } from 'react';
import PlayIcon from 'src/assets/icons/play.svg';

import Container from 'shared/components/facebook-ads/shared/container';

const defaultStyles = StyleSheet({
  container: {
    width: '100%',
    position: 'relative',
    overflow: 'hidden',
    fontFamily: 'Helvetica, Arial, sans-serif',
    color: '#1d2129',
    fontWeight: 'normal'
  },

  wrapVideo: {
    width: '100%',
    background: 'none no-repeat center center',
    backgroundColor: ({ token }) => token('legacy.color.grey.light'),
    overflow: 'hidden'
  },

  video: {
    background: 'black',
    width: '100%',
    height: 'auto',
    maxWidth: '500px',
    maxHeight: '500px',
    minHeight: '20rem'
  },

  videoPlaceholder: {
    height: '25rem',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    background:
      'linear-gradient(to top, rgba(0,0,0,0.65) 0%,rgba(0,0,0,.2) 100%)'
  },

  playIcon: {
    color: 'rgba(255,255,255,.95)'
  },

  wrapText: {
    width: '100%',
    background: '#f6f7f9',
    borderBottom: '.1rem solid #d8d8d8',
    borderTop: '0 none',
    padding: '1rem 1.2rem',
    whiteSpace: 'normal'
  },

  wrapTextDetails: {
    maxWidth: '80%',
    maxHeight: '66px',
    overflow: 'hidden',
    marginRight: '1.2rem'
  },

  title: {
    fontSize: '1.6rem',
    lineHeight: '2rem',
    maxHeight: '11rem',
    wordWrap: 'break-word',
    fontWeight: 600,
    marginBottom: '.5rem',
    padding: 0,
    fontFamily:
      'system-ui, -apple-system, BlinkMacSystemFont, ".SFNSText-Regular", sans-serif'
  },

  headline: {
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    fontSize: '1.2rem',
    lineHeight: '1.8rem',
    maxHeight: '8rem',
    padding: 0
  },

  website: {
    color: '#606770',
    fontSize: '1.2rem',
    lineHeight: '1.1rem',
    textTransform: 'uppercase',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap'
  },

  learnMore: {
    border: '.1rem solid #ced0d4',
    background: '#f6f7f9',
    color: '#4b4f56',
    borderRadius: '.2rem',
    padding: '0 .8rem',
    fontSize: '1.2rem',
    lineHeight: '2.2rem',
    marginTop: '.2rem',
    whiteSpace: 'nowrap',
    textShadow: 'none',
    verticalAlign: 'middle',
    fontWeight: 'bold',
    boxSizing: 'content-box'
  }
});

@styled(defaultStyles)
class DesktopFeedVideo extends PureComponent {
  render() {
    const { styles: s, adContentSet } = this.props;

    const video = adContentSet?.prospecting?.video;
    const thumbSizes = video?.thumbnail?.sizes ?? {};
    const thumbnail = thumbSizes?.['original.thumbnail'] ?? '';
    const showVideo = video?.status?.id === 'active';
    const poster = thumbnail?.url;

    const website = adContentSet.website
      ? new URL(adContentSet.website).hostname.replace('www.', '')
      : null;

    return (
      <Container {...this.props} descriptionLimit={150}>
        <Box {...s('container')}>
          <Box {...s('wrapVideo')}>
            {showVideo && (
              <video
                {...s('video')}
                controls={true}
                playsInline={true}
                src={video.url}
                poster={poster}
                autoPlay={true}
                muted={true}
              />
            )}

            {!showVideo && (
              <div {...s('videoPlaceholder')}>
                <PlayIcon {...s('playIcon')} />
              </div>
            )}
          </Box>
          <Box
            flexDirection='row'
            justifyContent='space-between'
            {...s('wrapText')}
          >
            <Box {...s('wrapTextDetails')}>
              <p {...s('website')}>{website}</p>
              <h1 {...s('title')}>{adContentSet.title}</h1>
              <p {...s('headline')}>{adContentSet.headline}</p>
            </Box>
            <Box alignItems='center'>
              <button {...s('learnMore')}>Learn More</button>
            </Box>
          </Box>
        </Box>
      </Container>
    );
  }
}

export default DesktopFeedVideo;

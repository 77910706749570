import LoadingSpinner from '@rexlabs/loading-spinner';
import { border, StyleSheet, useStyles } from '@rexlabs/styling';
import React, {
  ChangeEventHandler,
  forwardRef,
  HTMLProps,
  useCallback,
  useMemo
} from 'react';
import { SubmitHandler } from 'react-hook-form';
import { Modal, ModalTrigger } from 'src/components/elements/modal';
import { FormControlOptions, useFormControl } from 'src/components/form';
import { Consumer } from 'src/types';
import { CircleIconButton } from 'src/view/components/button';
import { useCreateCustomAudience } from '../api/createCustomAudience';
import { useDeleteCustomAudience } from '../api/deleteCustomAudience';
import { useCustomAudiences } from '../api/getCustomAudiences';
import { CustomAudienceDTO } from '../types';
import { CustomAudienceItem } from './CustomAudienceItem';
import CustomAudienceUploadModal from './CustomAudienceUploadModal';

const styles = StyleSheet({
  container: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'stretch',

    '& > * + *': {
      ...border.styles({
        top: {
          width: 'thin',
          color: 'container.static.light'
        }
      })
    }
  }
});

export type CustomAudienceSelectorProps = Omit<
  HTMLProps<HTMLDivElement>,
  'role' | 'aria-rowcount' | 'aria-labelledby' | 'value' | 'onChange'
> &
  FormControlOptions & {
    value: string[];
    onChange: Consumer<string[]>;
  };

export const CustomAudienceSelector = forwardRef<
  HTMLDivElement,
  CustomAudienceSelectorProps
>((props, ref) => {
  const { value, onChange, className, style, readOnly, ...rest } =
    useFormControl(props);
  const s = useStyles(styles);

  const selectionSet = useMemo(() => new Set(value), [value]);
  const fireChange = useCallback(() => {
    onChange(Array.from(selectionSet));
  }, [onChange, selectionSet]);

  const { data, isLoading } = useCustomAudiences();
  const { mutateAsync: deleteCustomAudience } = useDeleteCustomAudience();
  const { mutateAsync: createCustomAudience } = useCreateCustomAudience();

  const handleCreate = useCallback<SubmitHandler<CustomAudienceDTO>>(
    (data) => {
      return createCustomAudience(data);
    },
    [createCustomAudience]
  );
  const handleSelect = useCallback<ChangeEventHandler<HTMLInputElement>>(
    (e) => {
      const id = e.target.name;
      if (!selectionSet.delete(id)) selectionSet.add(id);
      fireChange();
    },
    [fireChange, selectionSet]
  );
  const handleDelete = useCallback(
    (id: string) => {
      return deleteCustomAudience(id, {
        onSettled: () => {
          if (selectionSet.delete(id)) fireChange();
        }
      });
    },
    [deleteCustomAudience, fireChange, selectionSet]
  );

  if (isLoading) return <LoadingSpinner />;

  return (
    <div
      ref={ref}
      {...s.with('container')({ className, style })}
      role='grid'
      aria-rowcount={(data?.data.length ?? 0) + 1}
      {...rest}
    >
      <div role='row' aria-rowindex={1}>
        <Modal>
          <ModalTrigger asChild>
            <CircleIconButton role='gridcell'>
              Upload your contacts
            </CircleIconButton>
          </ModalTrigger>
          <CustomAudienceUploadModal onSubmit={handleCreate} />
        </Modal>
      </div>
      {data?.data.map((v, index) => (
        <CustomAudienceItem
          key={v.id}
          customAudience={v}
          rowIndex={index + 2}
          selected={selectionSet.has(v.id)}
          onSelect={handleSelect}
          onDelete={handleDelete}
          isReadOnly={readOnly}
        />
      ))}
    </div>
  );
});

import config from 'src/config';

export const initMocks = async () => {
  if (config.API_MOCKING === 'true') {
    if (typeof window === 'undefined') {
      const { server } = await import('./server');
      server.listen();
    } else {
      const { worker } = await import('./browser');
      worker.start({ onUnhandledRequest: 'bypass' });
    }
  }
};

import React, { PureComponent } from 'react';
import { facebookNetworkAdTypes } from '../utils/spoke-value-lists';

import FacebookSingleImage from './facebook-ads/listing/single-image';
import FacebookCarousel from './facebook-ads/listing/carousel';

class ListingFacebookAd extends PureComponent {
  render() {
    const { adType, adContentSet, disableWrapper, ad } = this.props;
    const [type, variation] = adType.split('.');
    switch (type) {
      case facebookNetworkAdTypes.CAROUSEL:
        return (
          <FacebookCarousel
            ad={ad}
            variation={variation}
            adContentSet={adContentSet}
            disableWrapper={disableWrapper}
          />
        );
      case facebookNetworkAdTypes.SINGLE_IMAGE:
      case facebookNetworkAdTypes.SINGLE_IMAGE_SECONDARY:
        return (
          <FacebookSingleImage
            ad={ad}
            adContentSet={adContentSet}
            disableWrapper={disableWrapper}
          />
        );
      default:
        return <span>Type {adType} not found</span>;
    }
  }
}

export default ListingFacebookAd;

import { query } from '@rexlabs/model-generator';
import adsModel from 'src/data/models/entities/ads';

export const adsQuery = (getId) => query`{
  ${adsModel} (campaignId: ${getId}) {
    id
    name
    attributes {
      slide_id
    }
    format {
      id
      label
    }
    network {
      id
      label
    }
    status {
      id
      label
    }
    matchNetwork {
      id
    }
    ad_content_set_type {
      id
      label
    }
  }
}`;

import { Box } from '@rexlabs/box';
import { PrimaryButton, SecondaryButton } from '@rexlabs/button';
import { query, withQuery } from '@rexlabs/model-generator';
import { autobind } from 'core-decorators';
import React, { PureComponent } from 'react';
import campaignsModel from 'src/data/models/entities/campaigns';
import { withToken } from 'src/theme';
import { RenderLoading } from 'src/view/components/loading';
import { Modal } from 'src/view/components/modal';
import { Body } from 'src/view/components/text';
import withError from 'src/view/containers/with-error';

const q = query`{
  ${campaignsModel} (id: ${(p) => p.match.params.campaignId}) {
    id
    name
    status {
      id
      label
    }
  }
}`;

@withToken
@withError()
@withQuery(q)
@autobind
class ResumeCampaignModal extends PureComponent {
  state = {
    updateStatus: null
  };

  updateStatus(statusId) {
    const {
      campaigns,
      error: { open },
      closeModal
    } = this.props;
    this.setState(
      {
        updateStatus: statusId
      },
      () => {
        campaigns
          .updateItem({
            data: { status: { id: statusId } }
          })
          .then(closeModal)
          .catch((e) => {
            this.setState({ updateStatus: null });
            open(e);
          });
      }
    );
  }

  render() {
    const {
      closeModal,
      campaigns,
      error: { Error },
      token
    } = this.props;
    return (
      <Modal
        title='Resume campaign'
        subtitle={campaigns?.item?.data?.name}
        width='50rem'
        onClose={closeModal}
      >
        <RenderLoading isLoading={campaigns.item.status === 'loading'}>
          <Box p={token('spacing.xxl')} style={{ textAlign: 'center' }}>
            <Body>Are you sure you want to resume this paused campaign?</Body>
            <Body grey>
              If you want to stop campaign permanently, you can archive it
              straight away.
            </Body>
            <Box justifyContent='center' mt={token('spacing.xxl')}>
              <SecondaryButton
                isLoading={this.state.updateStatus === 'archived'}
                onClick={() => this.updateStatus('archived')}
              >
                Archive
              </SecondaryButton>
              <Box ml={token('spacing.xs')}>
                <PrimaryButton
                  isLoading={this.state.updateStatus === 'active'}
                  onClick={() => this.updateStatus('active')}
                >
                  Resume
                </PrimaryButton>
              </Box>
            </Box>

            <Error />
          </Box>
        </RenderLoading>
      </Modal>
    );
  }
}

export default ResumeCampaignModal;

import { ComponentType, ReactNode } from 'react';
import { CommercialListingContentTypeSelector } from 'src/features/listings';
import { ResourceDownloadContentTypeSelector } from 'src/features/resource-downloads';
import {
  TestimonialContentTypeSelector,
  TestimonialDetailsEditor
} from 'src/features/testimonials';
import { formattedBrandName } from 'src/theme';
import { ContentTypeEditorProps, ContentTypeSelectorProps } from '../types';
import { CommercialListingDetailsEditor } from 'src/features/listings/components/CommercialListingDetailsEditor';
import { commercialListingEditDetailsLoader } from 'src/features/listings/utils/loaders';
import { QueryClient } from 'react-query';
import { ResourceDownloadDetailsEditor } from 'src/features/resource-downloads/components/ResourceDownloadDetailsEditor';
import {
    CatalogListingSetContentTypeSelector
} from "src/features/catalog-listing-sets/components/CatalogListingSetContentTypeSelector";
import {
    CatalogListingSetDetailsEditor
} from "src/features/catalog-listing-sets/components/CatalogListingSetDetailsEditor";

type ContentTypeDefinition = {
  name: string;
  title: ReactNode;
  subtitle: ReactNode;
  Selector: ComponentType<ContentTypeSelectorProps>;
  Editor: ComponentType<ContentTypeEditorProps>;
  Loader?: (
    queryClient: QueryClient,
    accountId: string,
    contentId: string
  ) => unknown;
};

const contentTypeDefinitions: Record<string, ContentTypeDefinition> = {
  ResourceDownload: {
    name: 'Resource Download',
    title: 'Choose the content for your campaign',
    subtitle: 'Select or upload your content',
    Selector: ResourceDownloadContentTypeSelector,
    Editor: ResourceDownloadDetailsEditor
  },
  Testimonial: {
    name: 'Testimonial',
    title: 'Choose a testimonial for your campaign',
    subtitle: 'Select an existing testimonial or create a new one',
    Selector: TestimonialContentTypeSelector,
    Editor: TestimonialDetailsEditor
  },
  CommercialListing: {
    name: 'Commercial Listings',
    title: "Choose a listing you'd like to advertise",
    subtitle: `${formattedBrandName} will build a marketing campaign for the listing you select`,
    Selector: CommercialListingContentTypeSelector,
    Editor: CommercialListingDetailsEditor,
    Loader: commercialListingEditDetailsLoader
  },
  ListingSet: {
    name: 'Catalogues',
    title: "Choose a catalogue you'd like to advertise",
    subtitle: `A catalogue is a group of listings filtered by certain criteria. For example, you could create a catalogue of recently listed residential properties, priced between $1 - $2 million. Every time a new listing matched this criteria it would be included in this group to be advertised.`,
    Selector: CatalogListingSetContentTypeSelector,
    Editor: CatalogListingSetDetailsEditor
  }
};

export const getContentTypeDefinition = (
  type: string
): ContentTypeDefinition => {
  if (!Object.keys(contentTypeDefinitions).includes(type))
    throw new Error(`Invalid content type '${type}'`);
  return contentTypeDefinitions[type];
};

import { createRestAPIModelGenerator } from 'src/data/models/generator';
import { api } from 'src/utils/api-client';

export const TYPE = 'notification-subscriptions';

function transformResponse(response) {
  const notificationSubscriptions = response.data.notification_subscriptions;
  return Object.keys(notificationSubscriptions).map((key) => ({
    id: key,
    ...notificationSubscriptions[key]
  }));
}

const config = {
  entities: {
    api: {
      fetchList: (type, args) => {
        const token = atob(decodeURIComponent(args.token));
        return api
          .get(`/notification-subscriptions/${token}`)
          .then((response) => {
            return { data: transformResponse(response) };
          });
      },
      updateItem: (type, args, id) => {
        const { token, ...restArgs } = args;
        const decodedToken = atob(decodeURIComponent(token));
        return api
          .patch(`/notification-subscriptions/${decodedToken}`, restArgs)
          .then((response) => {
            return {
              data: transformResponse(response).find((ns) => ns.id === id)
            };
          });
      }
    }
  }
};

const NotificationSubscriptionsGenerator = createRestAPIModelGenerator(
  TYPE,
  config
);
export default NotificationSubscriptionsGenerator.createEntityModel();

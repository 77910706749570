import { Body } from '@rexlabs/text';
import React, { FC } from 'react';
import { FormGuideCard, FormGuideLayout } from 'src/features/wizard';

export const ResourceDownloadFormGuide: FC = () => {
  return (
    <FormGuideLayout>
      <FormGuideCard title='Resource details'>
        <Body as='div'>
          Attract potential customers with an attention-grabbing resource cover
          image and ad title, as these will be featured on your ad templates.
        </Body>
        <Body as='div'>
          The tagline appears above your title on your ad template and landing
          page and gives an idea of the resource type. E.g. eBook download, free
          guide, suburb report, etc.
        </Body>
      </FormGuideCard>

      <FormGuideCard title='Social network content'>
        <Body as='div'>
          The description appears above your ad template in Facebook and
          Instagram and is typically a summary of why customers should click
          your ad - simply, it’s “the sell”.
        </Body>
        <Body as='div'>
          Similar to the tagline, the short headline is a concise title
          explaining what type of resource you’re giving away. You’ll find this
          on your social network ads.
        </Body>
        <Body as='div'>
          The long headline is a single sentence summarising the benefit of
          downloading your resource, visible on Google ads.
        </Body>
      </FormGuideCard>
    </FormGuideLayout>
  );
};

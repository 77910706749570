import React, { useRef, useEffect } from 'react';
import Pusher from 'pusher-js';
import config from 'src/config';

export const PusherContext = React.createContext({});

export function PusherProvider({ appID, cluster, children }) {
  const pusherClientRef = useRef();

  useEffect(() => {
    pusherClientRef.current = new Pusher(appID, {
      cluster,
      forceTLS: config.stage !== 'local'
    });
  }, [appID, cluster]);

  return (
    <PusherContext.Provider value={{ client: pusherClientRef }}>
      {children}
    </PusherContext.Provider>
  );
}

import { keyframes, StyleSheet, useStyles, useToken } from '@rexlabs/styling';
import React, { FC } from 'react';
import ScrollableContent from 'view/components/scrollable-content';
import { Body, Heading } from 'view/components/text';
import { Link } from 'utils/whereabouts';
import ROUTES from 'routes/admin';
import { Box } from '@rexlabs/box';
import ReviewSvg from 'assets/images/review.svg';
import { ButtonBar } from 'view/components/button';
import { GhostButton, PrimaryButton } from '@rexlabs/button';
import ChevronLeft from 'assets/icons/chevron-left.svg';
import { useWizardNavigationState } from 'src/features/wizard/hooks/useWizardNavigationState';
import { useNavigate } from 'react-router-dom';

const animation = keyframes({
  '0%': { transform: 'rotate(0deg) translateX(15px) rotate(0deg)' },
  '50%': { transform: 'rotate(360deg) translateX(15px) rotate(-360deg)' },
  '100%': { transform: 'rotate(360deg) translateX(15px) rotate(-360deg)' }
});

const styles = StyleSheet({
  svg: {
    height: '34rem',
    width: 'auto',
    marginBottom: ({ token }) => token('spacing.xl'),

    '& #Group': {
      animation: `${animation} 4s infinite`
    }
  }
});

export const AccountNotReadyScreenContent: FC = () => {
  const s = useStyles(styles);
  const token = useToken();
  return (
    <ScrollableContent>
      <Heading>Your account is in review</Heading>
      <Body grey>
        We are currently{' '}
        <Link to={ROUTES.ACCOUNT_REVIEW} target='_blank'>
          reviewing your account
        </Link>{' '}
        and will be activating it soon. Before that, your campaigns will be
        saved as drafts. Once activated, you can go back and launch your
        campaigns.
      </Body>

      <Box
        mt={token('spacing.xxxl')}
        mb={token('spacing.xxxl')}
        alignItems='center'
        justifyContent='center'
        style={{ width: '100%' }}
      >
        <ReviewSvg {...s('svg')} />
      </Box>
    </ScrollableContent>
  );
};

export const AccountNotReadyScreen: FC = () => {
  const navigate = useNavigate();
  const { prevStepId } = useWizardNavigationState();

  return (
    <Box flex={1} flexDirection='column' w='99.2rem'>
      <AccountNotReadyScreenContent />

      <ButtonBar>
        <GhostButton
          IconLeft={ChevronLeft}
          onClick={() => {
            navigate(`../${prevStepId}`);
          }}
        >
          Back
        </GhostButton>
        <Box flexDirection='row' alignItems='center'>
          <PrimaryButton isDisabled>Next</PrimaryButton>
        </Box>
      </ButtonBar>
    </Box>
  );
};

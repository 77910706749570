import { Box } from '@rexlabs/box';
import { margin, StyleSheet, useStyles, useToken } from '@rexlabs/styling';
import { Tag } from '@rexlabs/tag';
import { Body, Heading } from '@rexlabs/text';
import React from 'react';
import { joinSentence } from 'src/utils/text';
import Money from 'src/view/components/money';

import {
  AdvancedMaximiseExposureGoalDescription,
  LeadsAndExposureGoalDescription,
  MaximiseExposureGoalDescription,
  MaximiseLeadsGoalDescription
} from './descriptions';

const styles = StyleSheet({
  container: {
    borderLeftWidth: '0.3rem',
    borderLeftStyle: 'solid',
    borderLeftColor: ({ token }) => token('palette.brand.600'),
    padding: ({ token }) => token('spacing.xs'),
    paddingLeft: ({ token }) => token('spacing.m'),
    maxWidth: '50rem'
  },

  header: {
    width: 'fit-content'
  },

  headerHighlight: {
    color: ({ token }) => token('palette.brand.700')
  },

  tag: {
    backgroundColor: ({ token }) => token('palette.brand.300'),
    ...margin.styles({
      top: 'xs'
    })
  }
});

const GOAL_DESCRIPTIONS = {
  maximise_leads: MaximiseLeadsGoalDescription,
  maximise_exposure: MaximiseExposureGoalDescription,
  leads_and_exposure: LeadsAndExposureGoalDescription,
  advanced_maximise_exposure: AdvancedMaximiseExposureGoalDescription
};

interface GoalHelpBoxProps {
  id: string;
  label: string;
  highlightName?: boolean;
  isNewStrategy: boolean;
  minSpend: number;
  networks: string[];
}

export function GoalHelpBox({
  id,
  label,
  highlightName = false,
  isNewStrategy,
  minSpend,
  networks
}: GoalHelpBoxProps) {
  const token = useToken();
  const s = useStyles(styles);

  const Description = GOAL_DESCRIPTIONS[id];

  return (
    <Box {...s('container')}>
      <Box flex={0} alignItems='center' sx={token('spacing.m')}>
        <Heading
          level={2}
          {...s('header', {
            headerHighlight: highlightName
          })}
        >
          {label}
        </Heading>
        {isNewStrategy && <Tag {...s('tag')}>New</Tag>}
      </Box>
      {Description && <Description />}
      <Body grey>
        <strong>Networks: </strong>
        {joinSentence(networks)}
      </Body>
      <Body grey>
        <strong>Minimum spend: </strong>
        <Money amount={minSpend} integer />
      </Body>
    </Box>
  );
}

/* eslint-disable @typescript-eslint/no-use-before-define */
import { Box } from '@rexlabs/box';
import { useToken } from '@rexlabs/styling';
import get from 'lodash/get';
import memoize from 'lodash/memoize';
import React, { Suspense } from 'react';
import { LoadingSpinner } from 'src/view/components/loading';

/**
 * Used when a prop is a component or tree of components and thus
 * needs to be wrapped in a function. It needs to be memoized for
 * reference equality.
 */
const wrapperFunctionProp = memoize(
  (component, renderComponent) => (parentProps) =>
    renderComponent(component, 0, parentProps)
);

const ComponentRenderer = ({
  template,
  resource,
  section,
  components: componentsObject,
  extraPropsFn
}) => {
  const token = useToken();

  /**
   * Iterates the pathProps where each pathProps value is a string which
   * points to a location within the template for its value. This
   * will get spread onto the component.
   *
   * @param {object} pathProps
   *
   * @return {object}
   */
  const getPathProps = (pathProps) => {
    if (!pathProps) return;

    return Object.keys(pathProps).reduce((acc, key) => {
      const path = pathProps[key];
      const result = get(template?.[resource], path);

      acc[key] = result;

      return acc;
    }, {});
  };

  /**
   * Iterates the props and if the props starts with a capital letter
   * and its an object import the component.
   *
   * NOTE: can't use a component prop for components that depend on form values
   * as the form values aren't visible/available. Might need to double check
   * this since changing to wrapperFunctionProp.
   *
   * @param {object} props
   *
   * @return {object}
   */
  const parseProps = (props) => {
    if (!props) return;

    return Object.keys(props).reduce((acc, key) => {
      // If the prop starts with an uppercase letter and is an object assume its a component
      if (/^[A-Z]/.test(key) && typeof props[key] === 'object') {
        acc[key] = wrapperFunctionProp(props[key], renderComponent);
      } else {
        acc[key] = props[key];
      }

      return acc;
    }, {});
  };

  /**
   * Takes a component definition imports it and renders it and its children.
   *
   * @param {object} component
   * @param {integer} idx
   * @param {object} parentProps
   *
   * @return {Component}
   */
  const renderComponent = (component, idx, parentProps) => {
    const { type, props, pathProps, children } = component;
    const extraProps = extraPropsFn && extraPropsFn(component);

    // If the component starts with a lowercase letter assume html component eg 'div'
    const Component = /^[a-z]/.test(type)
      ? type
      : componentsObject[type] || (() => <></>);

    return (
      <Component
        key={type + idx}
        {...parentProps}
        {...parseProps(props)}
        {...getPathProps(pathProps)}
        {...extraProps}
      >
        {children && childToComponent(children)}
      </Component>
    );
  };

  /**
   * Renders the child component or continues to recurse
   *
   * @param {null|string|Component[]|Component} child
   * @param {number} idx
   *
   * @return {null|string|Component}
   */
  const childToComponent = (child, idx) => {
    if (!child) return null;

    if (typeof child === 'string') {
      return child;
    }

    if (Array.isArray(child)) {
      return child.map((c, i) => childToComponent(c, i));
    }

    if (typeof child === 'object') {
      return renderComponent(child, idx);
    }
  };

  /**
   * Traverse template tree recursively for the passed in resouce and
   * section.
   *
   * @return {Components[]}
   */
  const components = (
    template?.[resource]?.view.sections?.[section]?.components || []
  ).map((component, idx) => renderComponent(component, idx));

  return (
    <Suspense
      fallback={
        <Box
          flexDirection='row'
          justifyContent='center'
          mt={token('spacing.xxl')}
          mb={token('spacing.xxl')}
        >
          <LoadingSpinner size={25} strokeWidth={5} />
        </Box>
      }
    >
      {components}
    </Suspense>
  );
};

export default ComponentRenderer;

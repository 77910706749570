import { styled, StyleSheet } from '@rexlabs/styling';
import React from 'react';
import { Body } from 'src/view/components/text';
import OptionCore from './core';

const defaultStyles = StyleSheet({
  container: {
    paddingLeft: ({ token }) => token('spacing.m'),
    paddingRight: ({ token }) => token('spacing.m'),

    ':hover': {
      background: ({ token }) => token('legacy.color.grey.light')
    },

    '& > :first-child': {
      paddingBottom: '0'
    }
  },

  selected: {
    background: ({ token }) => token('legacy.color.grey.light')
  }
});

function OptionWithIconCore(props) {
  const { option, selected, styles: s } = props;
  return (
    <OptionCore {...props}>
      <div {...s('container', { selected })}>
        <Body>
          {option.Icon} {option.longLabel}
        </Body>
      </div>
    </OptionCore>
  );
}

const OptionWithIcon = styled(defaultStyles)(OptionWithIconCore);

function SelectedOptionWithIcon(props) {
  return <OptionWithIcon {...props} selected={true} />;
}

export { OptionWithIcon, SelectedOptionWithIcon };

import { Box } from '@rexlabs/box';
import { GhostButton, PrimaryButton } from '@rexlabs/button';
import { withModel } from '@rexlabs/model-generator';
import { autobind } from 'core-decorators';
import React, { PureComponent } from 'react';
import invitationsModel from 'src/data/models/entities/invitations';
import { withToken } from 'src/theme';
import { ModalStickyButtonGroup } from 'src/view/components/button';
import { TextInput } from 'src/view/components/input';
import { Modal } from 'src/view/components/modal';
import { Body } from 'src/view/components/text';
import withError from 'src/view/containers/with-error';
import withToast from 'src/view/containers/with-toast';

@withToken
@withToast
@withError()
@withModel(invitationsModel)
@autobind
class DeleteInvitationModal extends PureComponent {
  state = {
    loading: false,
    confirm: ''
  };

  handleSubmit() {
    const {
      invitations,
      error: { open },
      user,
      closeModal,
      toasts
    } = this.props;

    if (this.state.confirm !== user.email) {
      open({
        message:
          "The entered email address doesn't match the users email address."
      });
      return;
    }

    this.setState({ loading: true }, () => {
      invitations
        .trashItem({ id: user.id })
        .then(() => {
          toasts.addToast({
            title: 'Invitation has been removed successfully!'
          });
          closeModal();
        })
        .catch((e) => {
          open(e);
          this.setState({ loading: false });
        });
    });
  }

  render() {
    const {
      closeModal,
      user,
      error: { Error },
      token
    } = this.props;
    return (
      <Modal
        title='Remove invitation'
        subtitle={user.email}
        onClose={closeModal}
        width='45rem'
      >
        <Box mb={token('spacing.xl')}>
          <Body grey>
            Are you sure you want to delete the pending invitation for this
            user? Please type the account email address below to confirm.
          </Body>

          <Box mt={token('spacing.xs')}>
            <TextInput
              value={this.state.confirm}
              onChange={(e) => this.setState({ confirm: e.target.value })}
              placeholder={user.email}
            />
          </Box>
        </Box>

        <ModalStickyButtonGroup>
          <GhostButton onClick={closeModal}>Cancel</GhostButton>
          <PrimaryButton
            isLoading={this.state.loading}
            onClick={this.handleSubmit}
          >
            Remove invitation
          </PrimaryButton>
        </ModalStickyButtonGroup>

        <Error />
      </Modal>
    );
  }
}

export default DeleteInvitationModal;

import { Box } from '@rexlabs/box';
import { withQuery } from '@rexlabs/model-generator';
import React, { PureComponent } from 'react';
import { mapPropsToValues } from 'shared/utils/prepare-ad-data';
import { adsQuery } from 'src/data/queries/ads';
import { campaignAdQuery } from 'src/data/queries/campaigns';
import { facebookQuery } from 'src/data/queries/facebook';
import { Carousel } from 'src/view/components/ad-previews';
import { RenderLoading } from 'src/view/components/loading';
import { CATALOG_CAMPAIGN_NAME } from "src/features/wizard/utils/constants";
import { AdsPreview } from "src/features/ads";
import CatalogAdsPreview from "view/modals/admin/campaign/CatalogAdsPreview";

export function getCampaignId({ campaignId }) {
  return campaignId;
}

@withQuery(campaignAdQuery(getCampaignId))
@withQuery(adsQuery(getCampaignId))
@withQuery(facebookQuery)
class AdsPreviewModal extends PureComponent {
  render() {
    const { campaigns, ads } = this.props;

    const campaignNetworks =
      campaigns?.item?.data?.audience_spec?.networks ?? [];

    const campaignData = {
      ...mapPropsToValues(this.props),
      facebookPage: this.props.facebook?.item?.data?.pages?.[0]?.name,
      listing: {
        ...campaigns?.item?.data?.content_source?.listings?.items?.[0]
      }
    };
    const isCatalogCampaign = campaigns?.item?.data?.campaign_definition?.name === CATALOG_CAMPAIGN_NAME;

    return (
      <RenderLoading
        isLoading={campaigns?.item?.status === 'loading'}
        minHeight='60rem'
      >
        <Box w='62rem'>
          {isCatalogCampaign ? (
            <CatalogAdsPreview
              adContentSet={campaignData.flex_ad_content_set}
              adTypes={campaignData.ad_types}
              listingSetId={campaigns?.item?.data?.content_source?.entities?.[0]?.id}
            />
          ) : campaignData.campaign_type === 'flex' ? (
            <AdsPreview
              adContentSet={campaignData.flex_ad_content_set}
              adTypes={campaignData.ad_types}
            />
          ) : (
            <Carousel
              modal
              ads={ads}
              campaignId={getCampaignId(this.props)}
              campaignData={campaignData}
              networks={campaignNetworks}
            />
          )}
        </Box>
      </RenderLoading>
    );
  }
}

export default AdsPreviewModal;

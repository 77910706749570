import { useFeature } from 'flagged';
import React, { ComponentType, FC, ReactElement, useEffect } from 'react';
import { Bugsnag } from 'src/lib/bugsnag';

export { FlagsProvider, useFeature, useFeatures, withFeature } from 'flagged';
export type { FeatureFlags } from 'flagged';

export type FeatureGroup = {
  [featureName: string]: boolean | FeatureGroup;
};
export type FeatureRenderFunction = (
  hasFeature: boolean | FeatureGroup
) => ReactElement | null;

/**
 * An extension of the `Feature` Render Prop Component that allows for an inversion of the feature flag condition.
 * Useful for rendering something when a feature flag is not set.
 * @param name - Name of the feature flag.
 * @param invert - Whether or not to invert the condition.
 * @param render - The element or function to render when the condition is met.
 */
export const Feature: FC<{
  name: string;
  invert?: boolean;
  render?: ReactElement | FeatureRenderFunction;
  children?: ReactElement | FeatureRenderFunction;
}> = ({
  name,
  invert = false,
  children,
  render = children
}): ReactElement | null => {
  const hasFeature = useFeature(name);
  const value = (hasFeature && !invert) || (!hasFeature && invert);

  useEffect(
    () =>
      Bugsnag.leaveBreadcrumb(
        'Feature',
        {
          feature: name,
          invert,
          value,
          renderType: typeof render
        },
        'log'
      ),
    [invert, name, render, value]
  );

  if (typeof render === 'function') return render(value);
  if (!value) return null;
  return render ?? null;
};

export type WithFeatureFlagProps<T extends string> = Record<
  T,
  boolean | FeatureGroup
>;

export type WithFeatureFlagWrapper<
  T extends string,
  P extends WithFeatureFlagProps<T>
> = FC<Omit<P, T>>;

export const withFeatureFlag = <
  T extends string,
  P extends WithFeatureFlagProps<T>
>(
  feature: string,
  name: T
): ((Component: ComponentType<P>) => WithFeatureFlagWrapper<T, P>) => {
  return (Component) => {
    const wrapper = (props) => {
      // eslint-disable-next-line react-hooks/rules-of-hooks
      const flag = useFeature(feature);
      const newProps = {
        ...props,
        [name]: flag
      };
      return <Component {...newProps} />;
    };
    wrapper.displayName = 'FeatureFlagWrapper';
    return wrapper;
  };
};

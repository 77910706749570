/* eslint-disable @typescript-eslint/no-var-requires */
export default {
  CAMPAIGNS_LIST: {
    config: {
      path: '/embed/campaigns/list',
      Component: require('view/screens/embed/campaigns/list').default
    }
  },

  CAMPAIGNS_INTRO: {
    config: {
      path: '/embed/campaigns/intro',
      Component: require('view/screens/embed/campaigns/intro').default
    }
  }
};

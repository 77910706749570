/*
|-------------------------------------------------------------------------------
| Global Util Setup
|-------------------------------------------------------------------------------
|
| - Log app configuration at start
|
*/

if (!global) {
  // eslint-disable-next-line no-global-assign
  global = global || window;
}

if (global) {
  // hack to work in node
  global.__DEV__ =
    typeof global.__DEV__ !== 'undefined'
      ? global.__DEV__
      : process.env.NODE_ENV !== 'production';
}

import { Box } from '@rexlabs/box';
import { GhostButton, PrimaryButton } from '@rexlabs/button';
import { withModel } from '@rexlabs/model-generator';
import { Body } from '@rexlabs/text';
import { autobind } from 'core-decorators';
import _ from 'lodash';
import React, { PureComponent } from 'react';
import campaignsModel from 'src/data/models/entities/campaigns';
import imagesModel from 'src/data/models/entities/images';
import { withToken } from 'src/theme';
import ImageUpload from 'src/view/components/input/file-upload/image-upload';
import { Modal } from 'src/view/components/modal';
import { HelpIcon } from 'src/view/components/tooltip';

@withToken
@withModel(campaignsModel)
@withModel(imagesModel)
@autobind
class ChangeImageModal extends PureComponent {
  static defaultProps = {
    initialImages: []
  };

  state = {
    loading: false,
    selectedImages: [],
    campaignImages: []
  };

  handleChange(e, selectedImages) {
    this.setState({
      campaignImages: e.target.files,
      selectedImages: selectedImages || this.state.selectedImages
    });
  }

  handleSelectImage(selectedImages) {
    this.setState({ selectedImages });
  }

  handleChangeImage() {
    const { campaignImages, selectedImages } = this.state;
    const {
      type,
      initialImages,
      campaignId,
      campaigns,
      closeModal,
      changeImage
    } = this.props;
    const images = _.map(campaignImages, (image) => ({
      id: image.id,
      name: image.name
    }));
    if (
      initialImages.length !== campaignImages.length &&
      (type === 'primary' || type === 'secondary')
    ) {
      this.setState({ loading: true }, () => {
        campaigns
          .updateItem({
            id: campaignId,
            data: {
              images
            },
            args: {
              include: [
                'images',
                'images.sizes',
                'agency_logo',
                'agency_logo.sizes',
                'agent_images',
                'agent_images.sizes'
              ].join(',')
            }
          })
          .then(() => {
            changeImage(selectedImages);
            this.setState({ loading: false, selectedImages }, () => {
              closeModal();
            });
          });
      });
    } else if (
      initialImages.length !== campaignImages.length &&
      type === 'agent'
    ) {
      this.setState({ loading: true }, () => {
        campaigns
          .updateItem({
            id: campaignId,
            data: {
              agent_images: images
            },
            args: {
              include: [
                'images',
                'images.sizes',
                'agency_logo',
                'agency_logo.sizes',
                'agent_images',
                'agent_images.sizes'
              ].join(',')
            }
          })
          .then(() => {
            changeImage(selectedImages);
            this.setState({ loading: false, selectedImages }, () => {
              closeModal();
            });
          });
      });
    } else {
      changeImage(selectedImages);
      closeModal();
    }
  }

  render() {
    const { loading, campaignImages, selectedImages } = this.state;
    const {
      token,
      type,
      status,
      canCrop,
      campaignId,
      initialImages,
      selectedImageId,
      unremoveableImages,
      closeModal,
      ratio,
      subtitle = 'Make sure the images you upload match our recommendations.',
      subtitleTooltip = 'For best results, upload images with a resolution of at least 1200 x 628 pixels. You can always crop your images after uploading.'
    } = this.props;
    const isDisabled =
      _.some(campaignImages, (image) => image?.isUploading ?? true) ||
      selectedImages.length <= 0;

    return (
      <Modal
        title={`Change ${type} image`}
        subtitle={
          <>
            {subtitle}{' '}
            {subtitleTooltip && (
              <HelpIcon
                Content={() => (
                  <Box style={{ width: '20rem' }}>
                    <Body>{subtitleTooltip}</Body>
                  </Box>
                )}
              />
            )}
          </>
        }
        width='70rem'
        onClose={closeModal}
      >
        <Box
          justifyContent='flex-end'
          flexDirection='column'
          data-testid={'image-upload'}
        >
          <ImageUpload
            columns={3}
            status={status}
            campaignId={campaignId}
            initialImages={initialImages}
            selectedImageIds={[selectedImageId]}
            unremoveableImages={unremoveableImages}
            shouldAllowMultiple={type !== 'logo'}
            canCrop={canCrop}
            selectable
            sendImmediate
            onChange={this.handleChange}
            onSelected={this.handleSelectImage}
            ratio={ratio}
          />
        </Box>

        <Box pt={token('spacing.xxxl')} justifyContent='start'>
          <Box pr={token('spacing.m')}>
            <PrimaryButton
              isDisabled={isDisabled}
              isLoading={loading}
              onClick={this.handleChangeImage}
              data-testid={'image-update-btn'}
            >
              Update
            </PrimaryButton>
          </Box>
          <Box>
            <GhostButton onClick={closeModal}>Cancel</GhostButton>
          </Box>
        </Box>
      </Modal>
    );
  }
}

export default ChangeImageModal;

import React, { PureComponent } from 'react';
import _get from 'lodash/get';

import Container from '../shared/container';
import AdImage from '../../facebook-ads/multi-listing-sold/ad-image';

class InstagramSingleImage extends PureComponent {
  static defaultProps = {
    disableWrapper: false
  };

  constructor(props) {
    super(props);
    this.adContainer = React.createRef();
    this.state = {
      adWrapperDimensions: { width: 0, height: 0 }
    };
  }

  componentDidMount() {
    const parentElement = _get(this.adContainer, 'current.parentElement');
    if (!parentElement) return;
    const width = parentElement.offsetWidth;
    const borderWidth =
      (parseFloat(getComputedStyle(parentElement).borderWidth) || 0) * 2;
    this.setState({
      adWrapperDimensions: {
        width: width - borderWidth,
        height: width - borderWidth
      }
    });
  }

  render() {
    const { ad, adContentSet, disableWrapper } = this.props;
    const { adWrapperDimensions } = this.state;
    const slide =
      adContentSet.slides.find(
        (s) => s.id === _get(ad, 'attributes.slide_id')
      ) || {};

    if (disableWrapper) {
      return (
        <AdImage
          {...this.props}
          slide={slide}
          scaleTo={1000}
          scalePos='left top'
        />
      );
    }

    return (
      <Container
        {...this.props}
        shortHeadline={slide.short_headline}
        campaignDescription={adContentSet.description}
      >
        <div
          ref={this.adContainer}
          style={{ margin: 'auto', ...adWrapperDimensions }}
        >
          <AdImage
            {...this.props}
            slide={slide}
            scaleTo={adWrapperDimensions.width}
          />
        </div>
      </Container>
    );
  }
}

export default InstagramSingleImage;

/* eslint-disable max-lines */
import _ from 'lodash';
import { createRestAPIModelGenerator } from 'src/data/models/generator';
import { api } from 'src/utils/api-client';
import { cropEntityImages } from 'src/utils/images';

export const TYPE = 'campaigns';

const config = {
  entities: {
    related: {
      account: {
        include: 'account'
      },
      stats: {
        include: 'stats'
      },
      content_source: {
        include: 'content_source'
      },
      active_package: {
        include: 'active_package'
      },
      selected_package: {
        include: 'selected_package'
      },
      audience_spec: {
        include: 'audience_spec'
      },
      ad_content_sets: {
        include: 'ad_content_sets'
      },
      images: {
        include: 'images.sizes'
      },
      agency_logo: {
        include: 'agency_logo.sizes'
      },
      agent_images: {
        include: 'agent_images.sizes'
      },
      thumbnails: {
        include: 'thumbnails'
      },
      videos: {
        include: 'videos.thumbnail.sizes'
      },
      landing_pages: {
        include: 'landing_pages{logo.sizes,agent}'
      },
      vendor_report: {
        include: 'vendor_report'
      },
      custom_package_options: {
        include: 'custom_package_options'
      },
      campaign_template: {
        include: 'campaign_template'
      },
      statement_of_information: {
        include: 'statement_of_information'
      },
      campaign_definition: {
        include: 'campaign_definition'
      },
      ad_count: {
        include: 'ad_count'
      },
      selected_payment_option: {
        include: 'selected_payment_option'
      }
    },

    // Overriding fetch actions, since we want to crop images when fetching
    // campaign data (see hack note in util)
    api: {
      fetchList: (type, args) => {
        const { campaignToken, ...rest } = args;
        return api
          .get('/campaigns', { 'campaign-token': campaignToken, ...rest })
          .then(({ data, ...rest }) => {
            return Promise.all(
              data.length ? data.map((item) => cropEntityImages(item)) : []
            ).then((data) => {
              /**
               * HACK: with the introduction of the campaign engine not all existing campaigns will have a
               * campaign template so we won't be able to route to them so we determine the slug from the type.
               * This is just a temp fix to test the FE and a proper fix will be for BE to run a migration and
               * add a campaign template to existing campaigns.
               */
              const dataWithSlugs = data.map((item) => {
                const hasSlug = !!item?.campaign_template?.slug;
                const slugFromType =
                  item.type.id === 'single_listing'
                    ? 'listing'
                    : item.type.id.replace(/_/g, '-');

                if (hasSlug) {
                  return item;
                } else {
                  return {
                    ...item,
                    campaign_template: {
                      ...item.campaign_template,
                      slug: slugFromType
                    }
                  };
                }
              });

              return { ...rest, data: dataWithSlugs };
            });
          });
      },
      fetchItem: (type, args, id) =>
        api.get(`/campaigns/${id}`, args).then(({ data, ...rest }) => {
          return cropEntityImages(data).then((data) => ({ ...rest, data }));
        })
    }
  }
};

const actionCreators = {
  launch: {
    request: (payload) =>
      api.post(`/campaigns/${payload.id}/purchases`, payload.data),
    reduce: {
      initial: _.identity,
      success: _.identity,
      failure: _.identity
    }
  },

  duplicate: {
    request: (payload) => api.post('/replica-campaigns', payload.data),
    reduce: {
      initial: _.identity,
      success: _.identity,
      failure: _.identity
    }
  },

  updateAudienceSpec: {
    request: (payload) =>
      api.patch(`/campaigns/${payload.id}/audience-spec`, payload.data),
    reduce: {
      initial: _.identity,
      success: (state, action) => ({
        ...state,
        items: {
          ...state.items,
          [action.meta.originalPayload.id]: {
            ...(state?.items?.[action.meta.originalPayload.id] ?? {}),
            data: {
              ...(state?.items?.[action.meta.originalPayload.id]?.data ?? {}),
              audience_spec: action.payload.data
            },
            etag: new Date().getTime()
          }
        }
      }),
      failure: _.identity
    }
  },

  updateLandingPage: {
    request: (payload) =>
      api.patch(
        `/campaigns/${payload.id}/landing-pages/${payload.landing_page_id}`,
        payload.data
      ),
    reduce: {
      initial: _.identity,
      success: (state, action) => {
        return {
          ...state,
          items: {
            ...state.items,
            [action.meta.originalPayload.id]: {
              ...(state?.items?.[action.meta.originalPayload.id] ?? {}),
              data: {
                ...(state?.items?.[action.meta.originalPayload.id]?.data ?? {}),
                landing_pages: {
                  data: [action.payload.data]
                }
              },
              etag: new Date().getTime()
            }
          }
        };
      },
      failure: _.identity
    }
  },

  updateContentSet: {
    request: (payload) =>
      api.patch(
        `/campaigns/${payload.campaignId}/ad-content-sets/${payload.id}`,
        payload.data
      ),
    reduce: {
      initial: _.identity,
      success: (state, action) => ({
        ...state,
        items: {
          ...state.items,
          [action.meta.originalPayload.campaignId]: {
            ...(state?.items?.[action.meta.originalPayload.campaignId] ?? {}),
            data: {
              ...(state?.items?.[action.meta.originalPayload.campaignId]
                ?.data ?? {}),
              ad_content_sets: {
                data: (
                  state?.items?.[action.meta.originalPayload.campaignId]?.data
                    ?.ad_content_sets?.items ?? []
                ).map((i) =>
                  i.id === action.meta.originalPayload.id
                    ? action.payload.data
                    : i
                )
              }
            },
            etag: new Date().getTime()
          }
        }
      }),
      failure: _.identity
    }
  },

  markAsSold: {
    request: (payload, actions) =>
      api
        .patch(`/campaigns/${payload.id}`, {
          attributes: {
            is_sold: payload.sold === undefined ? true : payload.sold
          }
        })
        .catch((e) => {
          actions.refreshItem({ id: payload.id });
          throw e;
        }),
    reduce: {
      initial: (state, action) => ({
        ...state,
        items: {
          ...state.items,
          [action.payload.id]: {
            ...(state?.items?.[action.payload.id] ?? {}),
            data: {
              ...(state?.items?.[action.payload.id]?.data ?? {}),
              attributes: {
                is_sold:
                  action.payload.sold === undefined ? true : action.payload.sold
              }
            },
            etag: new Date().getTime()
          }
        }
      }),
      success: _.identity,
      failure: _.identity
    }
  },

  getReportToken: {
    request: (payload) =>
      api.post(`/campaigns/${payload.id}/vendor-reports`, {
        sections: payload.sections
      }),
    reduce: {
      initial: _.identity,
      success: _.identity,
      failure: _.identity
    }
  },

  getCampaignToken: {
    request: (payload) => api.post(`/campaigns/${payload.id}/linking-tokens`),
    reduce: {
      initial: _.identity,
      success: _.identity,
      failure: _.identity
    }
  },

  linkCampaignToken: {
    request: (payload) =>
      api.patch(
        `/campaigns/linking-tokens/${payload.token}?campaign-token=${payload.campaignToken}`
      ),
    reduce: {
      initial: _.identity,
      success: _.identity,
      failure: _.identity
    }
  },

  unlinkCampaignToken: {
    request: (payload) =>
      api.delete(
        `/campaign-tokens/campaigns/${payload.id}?campaign-token=${payload.campaignToken}`
      ),
    reduce: {
      initial: _.identity,
      success: (state, action) => {
        return {
          ...state,
          lists: {
            [Object.keys(state.lists)[0]]: {
              ...state.lists[Object.keys(state.lists)[0]],
              items: state.lists[Object.keys(state.lists)[0]].items.filter(
                (i) => i !== action.meta.originalPayload.id
              )
            }
          },
          items: Object.keys(state.items).reduce((acc, key) => {
            if (key !== action.meta.originalPayload.id) {
              acc[key] = state.items[key];
            }
            return acc;
          }, {})
        };
      },
      failure: _.identity
    }
  },

  sendReport: {
    request: (payload) =>
      api.post(`/campaigns/${payload.id}/vendor-reports/email`, {
        sections: payload.sections,
        email_address: payload.email,
        message: payload.message || '-'
      }),
    reduce: {
      initial: _.identity,
      success: _.identity,
      failure: _.identity
    }
  },

  validateUrl: {
    request: (payload) =>
      new Promise((resolve, reject) => {
        api
          .get('/validate-url', { url: payload.url })
          .then(({ data, ...rest }) => {
            if (data.response_code !== 200) {
              if (data.response_code === 0) {
                reject(
                  new Error(
                    `Given URL "${payload.url}" could not be accessed!` +
                      'Please make sure that you enter a valid campaign URL.'
                  )
                );
                return;
              } else if (data.redirects_to) {
                reject(
                  new Error(
                    `Given URL "${payload.url}" redirects to the following address: ` +
                      `"${data.redirects_to}". Please make sure that the URL does not ` +
                      'redirect to another URL.'
                  )
                );
                return;
              }
              reject(
                new Error(
                  `Given URL "${payload.url}" leads to invalid response ${data.response_code}. ` +
                    'Please make sure that the URL does not redirect to another URL ' +
                    '(e.g. http to https, non-www to www, etc.)'
                )
              );
              return;
            }
            resolve({ data, ...rest });
          })
          .catch(reject);
      }),
    reduce: {
      initial: _.identity,
      success: _.identity,
      failure: _.identity
    }
  }
};

const CampaignGenerator = createRestAPIModelGenerator(TYPE, config);
export default CampaignGenerator.createEntityModel({ actionCreators });

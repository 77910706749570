import { faker } from '@faker-js/faker';
import { Upload } from 'src/features/uploads';

export const uploadGenerator = (props: Partial<Upload> = {}): Upload => {
  const fake: Upload = {
    id: faker.datatype.uuid(),
    filename: faker.system.commonFileName('pdf'),
    original_file_name: faker.system.commonFileName('pdf'),
    public_url: faker.helpers.maybe(faker.internet.url),
    size: faker.datatype.number(),
    created_at: faker.date.recent(),
    updated_at: faker.date.recent()
  };

  return { ...fake, ...props };
};

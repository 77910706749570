import { RexApiError } from '@rexlabs/api-client';
import { useQuery } from 'react-query';
import { useAccountId } from 'src/features/accounts';
import { QueryConfig, QueryResult } from 'src/lib/react-query';
import { api } from 'src/utils/api-client';
import { Testimonial } from '../types';
import { transformTestimonialResponse } from '../utils/schemas';
import { testimonialsKeys } from './keys';

export type GetTestimonialParams = {
  accountId: string;
  testimonialId: string;
};

export const getTestimonial = async ({
  testimonialId
}: GetTestimonialParams): Promise<Testimonial> => {
  const { data } = await api.get(`/testimonials/${testimonialId}`, {
    include: 'agent.thumbnails'
  });
  return transformTestimonialResponse(data);
};

type QueryFnType = typeof getTestimonial;

export type UseTestimonialOptions = Omit<GetTestimonialParams, 'accountId'> & {
  config?: QueryConfig<QueryFnType>;
};

export const useTestimonial = ({
  config,
  ...params
}: UseTestimonialOptions): QueryResult<Testimonial> => {
  const accountId = useAccountId();

  return useQuery<Testimonial, RexApiError>({
    queryKey: testimonialsKeys.detail({ accountId, ...params }),
    queryFn: () => getTestimonial({ accountId, ...params }),
    ...config
  });
};

/* eslint-disable @typescript-eslint/no-var-requires */
export default {
  CREATE: {
    config: {
      path: '/:accountIndex([0-9]+)?/campaign/create',
      exact: true,
      Component: require('view/screens/wizard/start').default
    }
  },

  SUCCESS: {
    config: {
      path: '/:accountIndex([0-9]+)?/campaign/create/success',
      Component: require('view/screens/wizard/success').default
    }
  },

  CAMPAIGN: {
    config: {
      path: '/:accountIndex([0-9]+)?/campaign/create/:campaignType/:campaignId?',
      Component: require('view/screens/wizard/campaign').default
    },

    CREATE_LISTING: {
      config: {
        path: '(.*)',
        hash: '/create-listing',
        Component: require('view/modals/wizard/edit-listing').default
      }
    },

    EDIT_LISTING: {
      config: {
        path: '(.*)',
        hash: '/edit-listing/:listingId',
        Component: require('view/modals/wizard/edit-listing').default
      }
    },

    CREATE_AGENT: {
      config: {
        path: '(.*)',
        hash: '/create-agent',
        Component: require('view/modals/wizard/edit-agent').default
      }
    },

    EDIT_AGENT: {
      config: {
        path: '(.*)',
        hash: '/edit-agent/:agentId',
        Component: require('view/modals/wizard/edit-agent').default
      }
    }
  },

  EDIT_AUDIENCE: {
    config: {
      path: '(.*)',
      hash: '/edit-audience',
      Component: require('view/modals/wizard/edit-audience').default
    }
  },

  EDIT_ADS: {
    config: {
      path: '(.*)',
      hash: '/edit-ads',
      Component: require('view/modals/wizard/edit-ads').default
    }
  },

  EDIT_PAGE: {
    config: {
      path: '(.*)',
      hash: '/edit-page',
      Component: require('view/modals/wizard/edit-landing-page').default
    }
  }
};

import React, { CSSProperties } from 'react';
import { useStyles, StyleSheet } from '@rexlabs/styling';
import { TEXTS } from 'src/theme';

const defaultStyles = StyleSheet({
  tiny: TEXTS.TINY,

  grey: {
    color: ({ token }) => token('color.textStyle.body.subtext')
  },

  orange: {
    color: ({ token }) => token('legacy.color.orange.default')
  }
});

export interface AdditionalTagProps {
  className: string;
  style?: CSSProperties;
}

export interface TinyProps {
  Tag?: (props: React.PropsWithChildren<AdditionalTagProps>) => JSX.Element;
  grey?: boolean;
  orange?: boolean;
}

export type Props = React.PropsWithChildren<TinyProps>;

export default function Tiny({
  Tag = (props) => <p {...props} />,
  children,
  grey = false,
  orange = false,
  ...props
}: Props) {
  const s = useStyles(defaultStyles);
  return (
    <Tag {...s.with('tiny', { grey, orange })()} {...props}>
      {children}
    </Tag>
  );
}

import { Box } from '@rexlabs/box';
import { styled, StyleSheet, useToken } from '@rexlabs/styling';
import React from 'react';
import { BREAKPOINTS } from 'src/theme';
import { CampaignProgress, CampaignStatus } from 'src/view/components/campaign';
import DateRange from 'src/view/components/date-range';

const defaultStyles = StyleSheet({
  container: {
    marginBottom: ({ token }) => token('spacing.xl'),
    alignItems: 'center',
    justifyContent: 'space-between',

    [BREAKPOINTS.REPORT.TABLET]: {
      justifyContent: 'center'
    },

    '@media print': {
      marginBottom: 0
    }
  },

  dateRange: {
    [BREAKPOINTS.REPORT.TABLET]: {
      display: 'none'
    }
  }
});

const ReportStatus = ({
  campaign,
  status,
  plan,
  spent,
  duration,
  planType,
  dateFrom,
  dateTo,
  lastWeek,
  styles: s
}) => {
  const token = useToken();

  return (
    <Box flexDirection='row' {...s('container')}>
      <Box flexDirection='row' alignItems='center'>
        <CampaignStatus status={status?.id} label={status?.label} />
        <Box ml={token('spacing.xxxl')}>
          <CampaignProgress
            status={status?.id}
            plan={plan}
            spent={spent}
            duration={duration}
            recurring={planType === 'recurring'}
            lastWeek={lastWeek}
            campaign={campaign}
          />
        </Box>
      </Box>
      <Box {...s('dateRange')}>
        <DateRange start={dateFrom} end={dateTo} />
      </Box>
    </Box>
  );
};

export default styled(defaultStyles)(ReportStatus);

import { StyleSheet, useStyles } from '@rexlabs/styling';
import React, { ComponentPropsWithoutRef, forwardRef } from 'react';
import { brand, getBrandMeta, LogoVariants } from 'src/theme/brand';

const styles = StyleSheet({
  logo: {
    color: ({ token }) => token('palette.brand.500')
  }
});

const VARIANTS = getBrandMeta(brand).logos;

export type LogoProps = ComponentPropsWithoutRef<'svg'> & {
  type?: LogoVariants;
};

export const Logo = forwardRef<SVGSVGElement, LogoProps>(
  ({ type = 'primary', className, style, ...rest }, ref) => {
    const s = useStyles(styles, 'Logo');
    const LogoComponent = VARIANTS[type];

    return (
      <LogoComponent
        {...s.with('logo')({ className, style })}
        ref={ref}
        {...rest}
      />
    );
  }
);

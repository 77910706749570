import { Box } from '@rexlabs/box';
import { GhostButton, PrimaryButton } from '@rexlabs/button';
import { query, withQuery } from '@rexlabs/model-generator';
import { autobind } from 'core-decorators';
import React, { PureComponent } from 'react';
import campaignsModel from 'src/data/models/entities/campaigns';
import { withToken } from 'src/theme';
import { Modal } from 'src/view/components/modal';
import { Body } from 'src/view/components/text';
import withError from 'src/view/containers/with-error';
import withToast from 'src/view/containers/with-toast';

const q = query`{
  ${campaignsModel} (id: ${(p) => p.match.params.campaignId}) {
    id
    name
  }
}`;

@withToken
@withToast
@withError()
@withQuery(q)
@autobind
class StopCampaignModal extends PureComponent {
  state = {
    loading: false
  };

  stopCampaign() {
    const {
      campaigns,
      error: { open },
      closeModal,
      toasts
    } = this.props;
    this.setState({ loading: true }, () => {
      campaigns
        .updateItem({
          data: { renew: false }
        })
        .then(() => {
          toasts.addToast({
            title: 'Campaign renewal has been stopped successfully!'
          });
          closeModal();
        })
        .catch((e) => {
          this.setState({ loading: false });
          open(e);
        });
    });
  }

  render() {
    const { closeModal, campaigns, token } = this.props;
    return (
      <Modal
        title='Stop campaign renewal'
        subtitle={campaigns?.item?.data?.name ?? 'Loading...'}
        width='50rem'
        onClose={closeModal}
      >
        <Box p={token('spacing.xxl')} style={{ textAlign: 'center' }}>
          <Body>Are you sure you want to stop renewal for this campaign?</Body>
          <Body grey>
            The campaign will remain active, until the current payment period
            ends. It will then be stopped, instead of getting renewed.
          </Body>
          <Box justifyContent='center' mt={token('spacing.xxl')}>
            <GhostButton onClick={closeModal}>Cancel</GhostButton>
            <Box ml={token('spacing.xs')}>
              <PrimaryButton
                onClick={this.stopCampaign}
                isLoading={this.state.loading}
              >
                Stop Renewal
              </PrimaryButton>
            </Box>
          </Box>
        </Box>
      </Modal>
    );
  }
}

export default StopCampaignModal;

import { Box } from '@rexlabs/box';
import { ButtonGroup, DestructiveButton, OutlineButton } from '@rexlabs/button';
import { Checkbox } from '@rexlabs/checkbox';
import { styled, StyleSheet, useToken } from '@rexlabs/styling';
import { Body } from '@rexlabs/text';
import React from 'react';
import Avatar from 'src/view/components/avatar';

const defaultStyles = StyleSheet({
  container: {
    width: '100%',
    padding: ({ token }) => token('spacing.xs'),
    flexShrink: 0,
    cursor: 'pointer'
  },

  containerSelected: {
    background: ({ token }) => token('color.input.active')
  },

  wrapImage: {
    width: '7rem',
    height: '7rem',
    background: ({ token }) => token('legacy.color.blue.grey'),
    marginRight: ({ token }) => token('spacing.m'),
    borderRadius: '50%',
    overflow: 'hidden'
  },

  actions: {
    fontSize: 'unset'
  }
});

function AgentItem({ styles: s, selected, onClick, onEdit, onDelete, data }) {
  const token = useToken();
  return (
    <Box
      {...s('container', { containerSelected: selected })}
      flexDirection='row'
      alignItems='center'
      justifyContent='space-between'
      onClick={onClick}
    >
      <Avatar round src={data?.thumbnails?.['thumbnail.choose_agent']?.url} />
      <Box flex={1} flexDirection='column' justifyContent='center'>
        <Body as='span'>{data.full_name}</Body>
        <ButtonGroup mt={token('spacing.xxs')}>
          <OutlineButton {...s('actions')} size='s' onClick={onEdit}>
            Edit
          </OutlineButton>
            {onDelete ?
              <DestructiveButton {...s('actions')} size='s' onClick={onDelete}>
                Delete
              </DestructiveButton>
            : null}
        </ButtonGroup>
      </Box>
      <Box>
        <Checkbox id={data.id} name='agent' value={!!selected} round />
      </Box>
    </Box>
  );
}

export default styled(defaultStyles)(AgentItem);

import { CommercialCategory } from 'src/features/listings/types';

export const LISTING_TYPE_OPTIONS = [
  { label: 'For Lease', value: 'commercial_rental' },
  { label: 'For Sale', value: 'commercial_sale' },
  { label: 'For Sale & For Lease', value: 'commercial_sale_rental' }
];

export const SALE_OR_RENTAL_MAPPING = {
  commercial_rental: 'rental',
  commercial_sale: 'sale',
  commercial_sale_rental: 'sale or lease'
};

export const LISTING_CATEGORY_OPTIONS = [
  { label: 'Industrial', value: 'Industrial/Warehouse' },
  { label: 'Retail', value: 'Retail' },
  { label: 'Offices', value: 'Offices' },
  { label: 'Land', value: 'Land/Development' },
  { label: 'Showrooms', value: 'Showrooms/Bulky Goods' },
  { label: 'Farming & Rural', value: 'Commercial Farming' },
  { label: 'Hotel & Leisure', value: 'Hotel/Leisure' },
  { label: 'Consulting', value: 'Medical/Consulting' },
  { label: 'Other', value: 'Other' }
];
export const LISTING_CATEGORIES = LISTING_CATEGORY_OPTIONS.map((i) => i.value);

export const SQUARE_METERS_SYMBOL = String.fromCharCode(0x33a1);

export const LAND_AREA_UNIT_OPTIONS = [
  { label: SQUARE_METERS_SYMBOL, value: 'm2' },
  { label: 'ha', value: 'ha' },
  { label: 'ac', value: 'ac' },
  { label: 'sqft', value: 'sqft' }
];

export const DEFAULT_FORM_VALUES = {
  events: [],
  should_display_events: false,
  category: LISTING_TYPE_OPTIONS[0].value as CommercialCategory
};

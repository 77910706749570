import { Box } from '@rexlabs/box';
import { PrimaryButton } from '@rexlabs/button';
import { withModel } from '@rexlabs/model-generator';
import { useToast } from '@rexlabs/notifications';
import { StyleSheet, useStyles, useToken } from '@rexlabs/styling';
import React, { useEffect, useState } from 'react';
import { compose } from 'redux';
import campaignsModel from 'src/data/models/entities/campaigns';
import { copyTextToClipboard } from 'src/utils/copy-text';
import { TextInput } from 'src/view/components/input';
import { LoadingSpinner } from 'src/view/components/loading';
import { Body, Label } from 'src/view/components/text';

const defaultStyles = StyleSheet({
  wrapTokenLoadingSpinner: {
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,

    '&::before': {
      content: '" "',
      position: 'absolute',
      top: 0,
      left: 0,
      right: 0,
      bottom: 0,
      background: ({ token }) => token('legacy.color.grey.light'),
      opacity: 0.8
    }
  }
});

function CopyTokenModal({ campaignId, campaigns = undefined }) {
  const s = useStyles(defaultStyles);
  const [loading, setLoading] = useState(true);
  const [campaignToken, setCampaignToken] = useState(null);
  const token = useToken();
  const toasts = useToast();

  useEffect(() => {
    (async () => {
      try {
        setLoading(true);
        const { data } = await campaigns.getCampaignToken({ id: campaignId });

        setCampaignToken(data.id);
        setLoading(false);
      } catch (e) {
        toasts.addToast({
          target: 'modal',
          type: 'error',
          description: 'Could not fetch campaign token'
        });
        setLoading(false);
      }
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const copyToken = async () => {
    try {
      await copyTextToClipboard(campaignToken);
      toasts.addToast({
        target: 'modal',
        type: 'info',
        description: 'Copied token to clipboard',
        duration: 2000
      });
    } catch (e) {
      toasts.addToast({
        target: 'modal',
        type: 'error',
        description: 'Could not copy token to clipboard.'
      });
    }
  };

  return (
    <Box>
      <Box mb={token('spacing.xxxl')}>
        <Body grey={true}>
          Copy the campaign token to link it back with your CRM system.
        </Body>

        <Label>Campaign token</Label>
        <Box flexDirection='row' alignItems='center'>
          <Box
            flex={1}
            flexDirection='column'
            mr={token('spacing.xs')}
            style={{ position: 'relative' }}
          >
            <TextInput value={campaignToken} />
            {loading && (
              <Box
                {...s('wrapTokenLoadingSpinner')}
                alignItems='center'
                justifyContent='center'
              >
                <LoadingSpinner size={24} strokeWidth={4} />
              </Box>
            )}
          </Box>
          <PrimaryButton onClick={copyToken}>Copy</PrimaryButton>
        </Box>
      </Box>
    </Box>
  );
}

export default compose(withModel(campaignsModel))(CopyTokenModal);

import React from 'react';
import PropTypes from 'prop-types';
import { styled, StyleSheet } from '@rexlabs/styling';
import Box from '@rexlabs/box';

import _ from 'lodash';
import colorTheme from '../../../utils/color-theme';
import { LargeButton } from '../../button';
import ScaledText from '../../scaled-text';
import charLimit, { limits } from '../../../utils/char-limit';
import { COLORS } from 'src/theme';

const fontFamily = 'Lato, sans-serif';
const defaultStyles = StyleSheet({
  panorama: {
    width: '980px',
    height: '120px',
    border: '1px solid darkgray',
    overflow: 'hidden',
    display: 'flex'
  },
  primaryImage: {
    width: '275px',
    height: '100%',
    objectFit: 'cover'
  },
  main: {
    backgroundColor: 'white',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: '0 24px',
    flex: 1
  },
  content: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'flex-start'
  },
  smallText: {
    fontFamily,
    fontSize: '12px',
    fontWeight: 600,
    lineHeight: '15px',
    textTransform: 'uppercase'
  },
  shortHeadline: {
    fontFamily,
    fontWeight: 300,
    fontSize: '32px',
    lineHeight: '38px'
  },
  logoWrapper: {
    width: '146px',
    height: '100%',
    padding: '16px',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center'
  },
  logo: {
    maxWidth: 'calc(0.75 * 146px)',
    maxHeight: 'calc(0.4 * 120px)',
    objectFit: 'contain'
  }
});

Panorama.propTypes = {
  bannerAdText: PropTypes.string,
  shortHeadline: PropTypes.string,
  butonText: PropTypes.string,
  brandColor: PropTypes.string,
  backgroundColor: PropTypes.string,
  primaryImageUrls: PropTypes.object,
  logoUrls: PropTypes.object
};

function Panorama({
  buttonText = 'View Property',
  smallText,
  shortHeadline,
  primaryImageUrls,
  logoUrls,
  brandColor,
  backgroundColor,
  styles: s
}) {
  const { brandColorBorder } = colorTheme(brandColor);

  return (
    <Box {...s('panorama')}>
      <img
        {...s('primaryImage')}
        src={_.get(primaryImageUrls, ['crop.landscape', 'url'])}
      />
      <Box {...s('main')}>
        <Box {...s('content')}>
          <span {...s.with('smallText')({ color: COLORS.BLACK })}>
            {charLimit(smallText, limits.SMALL_TEXT)}
          </span>
          <ScaledText
            maxWidth={355}
            maxTextLines={2}
            maxFontSize={36}
            minFontSize={22}
            {...s.with('shortHeadline')({ color: COLORS.BLACK })}
          >
            {shortHeadline}
          </ScaledText>
        </Box>
        <Box width={124}>
          <LargeButton brandColor={brandColor}>{buttonText}</LargeButton>
        </Box>
      </Box>
      <Box
        {...s.with('logoWrapper')({
          backgroundColor: backgroundColor,
          borderLeft: brandColorBorder
        })}
      >
        <img src={_.get(logoUrls, 'original.url')} {...s('logo')} />
      </Box>
    </Box>
  );
}

export default styled(defaultStyles)(Panorama);

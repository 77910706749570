import { track } from '@rexlabs/analytics';
import { Box } from '@rexlabs/box';
import { GhostButton, PrimaryButton } from '@rexlabs/button';
import { query, withModel, withQuery } from '@rexlabs/model-generator';
import React, { useRef } from 'react';
import { compose } from 'redux';
import wizardModel from 'src/data/models/custom/wizard';
import campaignsModel from 'src/data/models/entities/campaigns';
import WIZARD from 'src/routes/wizard';
import { formattedBrandName } from 'src/theme';
import { interpolate, interpolateRegex } from 'src/utils/format';
import { Link } from 'src/utils/whereabouts';
import ComponentRenderer from 'src/view/components/component-renderer';
import LandingPagePreview from 'src/view/components/landing-page-preview';
import ScrollableContent from 'src/view/components/scrollable-content';
import { BodyWithHelpIcon, Heading } from 'src/view/components/text';
import { stepNames } from 'src/view/screens/wizard/multi-listing-sold/index';

import { useStyles } from '@rexlabs/styling';
import ChevronLeft from 'src/assets/icons/chevron-left.svg';
import ChevronRight from 'src/assets/icons/chevron-right.svg';
import { StickyButtonGroup } from 'src/view/components/button';
import { sharedStyles } from '.';

function getCampaignId(props) {
  return props?.match?.params?.campaignId;
}

const campaignsLandingPageQuery = (getId) => query`{
  ${campaignsModel} (id: ${getId}) {
    id
    type
    campaign_template {
      type
      campaign_template_configuration {
        id
        configuration
      }
    }
    agency_logo
    landing_pages {
      id
      is_external
      external_url
      lead_capture_email
      domain
      branding_overrides {
        brand_colour
        background_colour
        logo
      }
      virtual_tour_url
      template {
        id
      }
      template_variables {
        maximum_leads {
          lead_capture_form_heading
          lead_capture_form_sub_heading
          lead_capture_form_text
        }
        qualitative_leads {
          lead_capture_form_heading
          lead_capture_form_sub_heading
          lead_capture_form_text
        }
        multi_listing_sold {
          page_headline
          lead_capture_form_heading
          lead_capture_form_sub_heading
          lead_capture_form_button_text
          contact_name
          contact_email
          contact_phone
        }
      }
      agent {
        id
      }
    }
  }
}`;

const LandingPageScreen = ({ campaigns, wizard: { setStep, step } }) => {
  const s = useStyles({}, undefined, sharedStyles);
  const nextClicked = useRef(false);
  const campaignTemplate =
    campaigns.item.data?.campaign_template?.campaign_template_configuration
      ?.configuration;

  return (
    <Box flex={1} flexDirection='column'>
      <ScrollableContent {...s('content')}>
        <ComponentRenderer
          template={campaignTemplate}
          resource='landing_page'
          section='overview'
          components={{ Heading, BodyWithHelpIcon, Box, LandingPagePreview }}
          extraPropsFn={({ type, props }) => {
            let extraProps;

            if (type === 'BodyWithHelpIcon') {
              extraProps = Object.keys(props).reduce((acc, key) => {
                const prop = props[key];
                if (interpolateRegex.test(prop)) {
                  acc[key] = interpolate(prop, { formattedBrandName });
                }
                return acc;
              }, {});
            }

            if (type === 'LandingPagePreview') {
              const landingPageId =
                campaigns?.item?.data?.landing_pages?.items?.[0]?.id;

              extraProps = {
                landingPageId: landingPageId,
                values: campaigns?.item?.data?.landing_pages?.items?.[0]
              };
            }

            return extraProps;
          }}
        />
      </ScrollableContent>

      <StickyButtonGroup>
        <Box flex={1}>
          <GhostButton
            IconLeft={ChevronLeft}
            onClick={() => {
              track({
                event: 'Spoke campaign back',
                properties: {
                  action: 'back_clicked',
                  step_name: stepNames[step - 1].replace(/-/g, '_')
                }
              });
              setStep({ step: step - 1 });
            }}
          >
            Back
          </GhostButton>
        </Box>
        <Link to={WIZARD.EDIT_PAGE}>
          {({ onClick }) => (
            <GhostButton
              onClick={(e) => {
                track({
                  event: 'Spoke campaign landing page viewed',
                  properties: {
                    action: 'edit_landing_page_clicked',
                    step_name: stepNames[step - 1].replace(/-/g, '_')
                  }
                });
                onClick(e);
              }}
              data-intercom-target='editLandingPage'
            >
              Edit landing page
            </GhostButton>
          )}
        </Link>
        <PrimaryButton
          onClick={() => {
            if (nextClicked.current) return;
            nextClicked.current = true;

            track({
              event: 'Spoke campaign next',
              properties: {
                action: 'next_clicked',
                step_name: stepNames[step - 1].replace(/-/g, '_'),
                success: true
              }
            });
            setStep({ step: step + 1 });
          }}
          IconRight={ChevronRight}
        >
          Next
        </PrimaryButton>
      </StickyButtonGroup>
    </Box>
  );
};

export default compose(
  withQuery(campaignsLandingPageQuery(getCampaignId)),
  withModel(wizardModel)
)(LandingPageScreen);

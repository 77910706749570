import { Box } from '@rexlabs/box';
import { withWhereaboutsFilter } from '@rexlabs/filters';
import { query, withQuery } from '@rexlabs/model-generator';
import { autobind } from 'core-decorators';
import _ from 'lodash';
import React, { PureComponent } from 'react';
import accountFilters from 'src/data/filters/accounts';
import accountsModel from 'src/data/models/sysadmin/accounts';
import { withToken } from 'src/theme';
import { SearchInput } from 'src/view/components/filter';
import {
  AccountListHeader,
  AccountListItem,
  List
} from 'src/view/components/list';
import { Heading } from 'src/view/components/text';

function getQueryFilter(props) {
  const query = (props?.whereabouts?.query ?? {})['accounts.search'];
  return query ? `name.match(*${query}*)` : '';
}

const accountsQuery = query`{
  ${accountsModel} (q: ${getQueryFilter}, o: "created_at.desc") {
    id
    name
    ready
    provision {
      id
      status
    }
  }
}`;

@withToken
@withWhereaboutsFilter(accountFilters)
@withQuery(accountsQuery)
@autobind
class AccountListScreen extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      search: (props?.whereabouts?.query ?? {})['accounts.search']
    };
  }

  debounceSetQueryFilter = _.debounce(this.setQueryFilter, 400);

  setQueryFilter(value) {
    const { filters } = this.props;
    filters.setFilters({ search: value });
  }

  render() {
    const { accounts, token } = this.props;

    return (
      <Box flex={1} flexDirection='column'>
        <Heading
          style={{
            marginTop: token('spacing.xl'),
            marginBottom: token('spacing.xl')
          }}
        >
          Accounts
        </Heading>

        <Box mb={token('spacing.xl')} mt={token('spacing.xs')}>
          <SearchInput
            name='searchCampaign'
            placeholder='Search by name'
            onChange={({ target: { value } }) => {
              this.setState({ search: value });
              this.debounceSetQueryFilter(value);
            }}
            value={this.state.search}
            isLoading={accounts.list.status === 'loading'}
          />
        </Box>

        <Box mb={token('spacing.xxxl')}>
          <List
            items={accounts.list.items}
            isLoading={accounts.list.status === 'loading'}
            getItemKey={(account) => account.id}
            Header={AccountListHeader}
            Item={({ data: account }) => <AccountListItem data={account} />}
            endReached={accounts?.list?.pagination?.endReached}
            isFetching={accounts.list.status === 'fetching'}
            onLoadMore={() => accounts.fetchMore()}
            autoLoadMore={false}
          />
        </Box>
      </Box>
    );
  }
}

export default AccountListScreen;

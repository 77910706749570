import { track } from '@rexlabs/analytics';
import { Box } from '@rexlabs/box';
import { styled, StyleSheet } from '@rexlabs/styling';
import React from 'react';
import WIZARD from 'src/routes/wizard';
import { formattedBrandName } from 'src/theme';
import { Link } from 'src/utils/whereabouts';
import Avatar from 'src/view/components/avatar';
import { Checkbox } from 'src/view/components/input';
import { Body } from 'src/view/components/text';

const defaultStyles = StyleSheet({
  container: {
    width: '100%',
    padding: ({ token }) => token('spacing.m'),
    flexShrink: 0
  },

  containerClickable: {
    cursor: 'pointer'
  },

  containerSelected: {
    background: ({ token }) => token('legacy.color.grey.light')
  },

  wrapImage: {
    width: '8rem',
    height: '8rem',
    background: ({ token }) => token('legacy.color.blue.grey'),
    marginRight: ({ token }) => token('spacing.m'),
    borderRadius: '50%'
  },

  text: {
    margin: 0,
    padding: 0
  },

  textMeta: {
    color: ({ token }) => token('legacy.color.blue.grey')
  }
});

function AgentListItem({ styles: s, selected, onClick, data }) {
  return (
    <Box
      {...s('container', {
        containerSelected: selected,
        containerClickable: onClick
      })}
      flexDirection='row'
      alignItems='center'
      justifyContent='space-between'
      onClick={onClick}
      data-testid={`agent-${data?.id}`}
    >
      <Avatar round src={data?.thumbnails?.['thumbnail.choose_agent']?.url} />
      <Box flex={1} flexDirection='column'>
        <Body {...s('text')}>{data.full_name}</Body>
        <Body {...s('text', 'textMeta')}>{data.position}</Body>
        <Body {...s('text', 'textMeta')}>
          Created in {formattedBrandName} ∙{' '}
          <Link to={WIZARD.CAMPAIGN.EDIT_AGENT} params={{ agentId: data?.id }}>
            {({ onClick, target }) => (
              <a
                href={target}
                onClick={(e) => {
                  e.preventDefault();
                  track({
                    event: 'Spoke edit agent',
                    properties: {
                      action: 'edit_agent_clicked'
                    }
                  });
                  onClick(e);
                }}
              >
                Edit
              </a>
            )}
          </Link>
        </Body>
      </Box>
      <Box>
        <Checkbox id={data.id} name='agent' value={selected} round />
      </Box>
    </Box>
  );
}

export default styled(defaultStyles)(AgentListItem);

import { Schema } from '@data-driven-forms/react-form-renderer';
import { campaignDefinitionSchema } from 'src/features/campaign-definitions';
import {
  Date,
  Describe,
  Optional,
  Type,
  UUID,
  UnknownJSON,
  UnknownType,
  array,
  boolean,
  create,
  object,
  string,
  unknown,
  type,
  nonempty
} from 'src/lib/superstruct';
import { CAMPAIGN_STATUSES, Campaign, FormDefinition } from '../types';

export const formDefinitionSchema: Describe<FormDefinition> = object({
  id: UUID,
  schema: UnknownJSON as unknown as Describe<Schema>,
  type: string(),
  created_at: Date,
  updated_at: Date
});

export const campaignSchema = type({
  id: UUID,
  name: nonempty(string()),
  status: Type(CAMPAIGN_STATUSES),
  status_updated_at: Optional(Date),
  type: UnknownType,
  type_label: string(),
  campaign_strategy: Optional(unknown()),
  goal: Optional(string()),
  budget: unknown(),
  duration: unknown(),
  start_date: Optional(Date),
  attributes: unknown(),
  renewed_at: Optional(Date),
  renew: boolean(),
  limit_renewals: boolean(),
  renewals: unknown(),
  lead_generation_form_enabled: boolean(),
  campaign_template: Optional(unknown()),
  campaign_definition: campaignDefinitionSchema,
  statement_of_information: Optional(unknown()),
  form_definitions: Optional(
    object({
      data: array(formDefinitionSchema)
    })
  ),
  lead_capture_emails: unknown(),
  created_at: Date,
  updated_at: Date,
  content_source: type({
    entities: array(type({id: string()}))
  }),
  selected_payment_option: Optional(
    type({
      id: string()
    })
  )
});

export const createCampaign = (value: unknown): Campaign =>
  create(value, campaignSchema);

import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { styled, StyleSheet } from '@rexlabs/styling';
import Box from '@rexlabs/box';
import _ from 'lodash';
import charLimit, { limits } from '../../../utils/char-limit';
import colorTheme from '../../../utils/color-theme';
import GooText from '../../goo-text';
import { RegularButton } from '../../button';
import ScaledText from '../../scaled-text';
import { COLORS } from 'src/theme';

const fontFamily = 'Lato, sans-serif';

const defaultStyles = StyleSheet({
  billboard: {
    position: 'relative',
    border: 'solid 1px darkgray',
    overflow: 'hidden',
    flexGrow: 0,
    flexShrink: 0
  },
  shortHeadline: {
    fontSize: '36px',
    lineHeight: '40px',
    fontWeight: '300',
    fontFamily,
    marginBottom: '20px',
    maxWidth: '290px'
  },
  longHeadlineWrapper: {
    position: 'absolute',
    left: '25px',
    bottom: '25px',
    margin: '0',
    maxWidth: '225px',
    display: 'inline'
  },
  longHeadline: {
    fontSize: '20px',
    lineHeight: '28px',
    fontWeight: '600',
    fontFamily
  },
  img: {
    objectFit: 'cover',
    position: 'relative'
  },
  content: {
    backgroundColor: 'white'
  },
  logoWrapper: {},
  footerLogo: {
    maxWidth: 'calc(0.75 * 130px)',
    maxHeight: 'calc(0.4 * 250px)',
    objectFit: 'contain'
  }
});

const buttonStyles = StyleSheet({
  button: {
    width: '146px'
  }
});

@styled(defaultStyles)
class Billboard extends PureComponent {
  static propTypes = {
    brandColor: PropTypes.string,
    shortHeadline: PropTypes.string,
    longHeadline: PropTypes.string,
    primaryImageUrls: PropTypes.object,
    logoUrls: PropTypes.object
  };

  render() {
    const {
      brandColor,
      backgroundColor,
      shortHeadline,
      longHeadline,
      primaryImageUrls,
      logoUrls,
      styles: s
    } = this.props;

    const { brandColorBorder } = colorTheme(brandColor);

    return (
      <Box width={970} height={250} flexDirection='row' {...s('billboard')}>
        <Box>
          <img
            width={391}
            height={250}
            src={_.get(primaryImageUrls, ['crop.landscape', 'url'])}
            {...s('img')}
          />
          <Box {...s('longHeadlineWrapper')}>
            <GooText padding='10px 8px 6px' borderRadius={1.5}>
              <span {...s.with('longHeadline')({ color: COLORS.BLACK })}>
                {charLimit(longHeadline, limits.LONG_HEADLINE)}
              </span>
            </GooText>
          </Box>
        </Box>
        <Box
          flex={1}
          flexDirection='column'
          justifyContent='center'
          alignItems='flex-start'
          padding='0px 20px'
          {...s('content')}
        >
          <ScaledText
            maxWidth={355}
            maxTextLines={2}
            maxFontSize={36}
            minFontSize={24}
            {...s.with('shortHeadline')({ color: COLORS.BLACK })}
          >
            {shortHeadline}
          </ScaledText>
          <RegularButton brandColor={brandColor} styles={buttonStyles}>
            Find out now
          </RegularButton>
        </Box>
        <Box
          display='flex'
          flexDirection='column'
          padding='8px'
          alignItems='center'
          justifyContent='center'
          width={140}
          {...s.with('logoWrapper')({
            backgroundColor: backgroundColor,
            borderLeft: brandColorBorder
          })}
        >
          <img src={_.get(logoUrls, 'original.url')} {...s('footerLogo')} />
        </Box>
      </Box>
    );
  }
}

export default Billboard;

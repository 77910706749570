import { createRestAPIModelGenerator } from 'src/data/models/generator';
import { api } from 'src/utils/api-client';

export const TYPE = 'google';

const config = {
  entities: {
    api: {
      fetchItem: (type, args, id) => {
        return Promise.all([
          api.get('/website-integration', args),
          api.get('/integrations/google', args)
        ]).then(([integration, google]) => ({
          data: {
            ...integration.data,
            id,
            google: google.data
          }
        }));
      },
      updateItem: (type, args) => api.patch('/integrations/google', args)
    }
  }
};

const GoogleGenerator = createRestAPIModelGenerator(TYPE, config);
export default GoogleGenerator.createEntityModel();

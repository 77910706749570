import { StyleSheet } from '@rexlabs/styling';
import { TEXTS, BORDER_RADIUS, SHADOWS, FONT } from './index';

const inputClasses = {
  container: {
    borderRadius: BORDER_RADIUS.INPUT,
    border: ({ token }) => `1px solid ${token('legacy.color.blue.grey')}`,
    paddingLeft: ({ token }) => token('spacing.m'),
    paddingRight: ({ token }) => token('spacing.m')
  },

  inputContainer: {
    height: '4.5rem',
    paddingTop: 0,
    paddingBottom: 0
  },

  textAreaContainer: {
    paddingTop: ({ token }) => token('spacing.m'),
    paddingBottom: ({ token }) => token('spacing.m')
  }
};

const inputsStyleSheet = {
  input: {
    ...TEXTS.BODY,
    minWidth: '1rem'
  },

  wrapper: {
    position: 'relative',
    width: '100%'
  },

  cosmetic: {
    ...TEXTS.BODY
  },

  cosmeticWrapper: {
    display: 'flex',
    justifyContent: 'center',
    zIndex: 0
  },

  containerError: {
    borderColor: ({ token }) =>
      `${token('color.border.input.error')} !important`
  },

  containerValid: {
    borderColor: ({ token }) => token('legacy.color.blue.grey')
  },

  containerFocused: {
    borderColor: ({ token }) =>
      token('color.border.container.interactive.hover'),
    boxShadow: 'none',
    outline: 'none'
  },

  charLimitContainer: {
    position: 'absolute',
    top: '100%',
    padding: ({ token }) => token('spacing.xxs'),
    fontSize: '1.2rem',
    color: ({ token }) => token('legacy.color.blue.grey')
  },

  prefix: {
    color: ({ token }) => token('legacy.color.blue.grey')
  },

  suffix: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    color: ({ token }) => token('legacy.color.blue.grey')
  }
};

const COMPONENTS = {
  TextInput: StyleSheet({
    ...inputsStyleSheet,

    container: {
      ...inputClasses.container,
      height: '4.5rem',
      paddingTop: 0,
      paddingBottom: 0
    }
  }),

  TextArea: StyleSheet({
    ...inputsStyleSheet,

    container: {
      ...inputClasses.container,
      paddingTop: ({ token }) => token('spacing.m'),
      paddingBottom: ({ token }) => token('spacing.m')
    }
  }),

  InnerField: StyleSheet({
    field: {
      marginTop: ({ token }) => token('spacing.xs')
    },

    fieldLabel: TEXTS.LABEL,

    error: {
      position: 'relative',
      fontSize: '1.2rem',
      padding: ({ token }) => token('spacing.xxs'),
      fontWeight: FONT.WEIGHTS.SEMIBOLD,
      color: ({ token }) => token('legacy.color.red.default')
    },

    inlineField: {
      alignItems: 'flex-start'
    },

    inlineFieldLabel: {
      marginLeft: 0,
      marginTop: ({ token }) => token('spacing.xs')
    }
  }),

  SelectOption: StyleSheet({
    container: {
      padding: ({ token }) => token('spacing.xs'),
      fontWeight: FONT.WEIGHTS.LIGHT,
      fontSize: '1.5rem'
    },

    isPassive: {
      background: ({ token }) => token('color.primary.idle.default')
    },

    isActive: {
      background: ({ token }) => token('color.primary.hover.contrast'),
      color: ({ token }) => token('color.textStyle.body.default')
    },

    isSelected: {
      background: ({ token }) => token('color.primary.active.default'),
      color: ({ token }) => token('palette.white')
    }
  }),

  SelectMenu: StyleSheet({
    container: {
      marginTop: '-.3rem',
      borderWidth: '1px',
      borderStyle: 'solid',
      borderColor: ({ token }) =>
        token('color.border.container.interactive.hover'),
      zIndex: 200
    }
  }),

  Tether: StyleSheet({
    wrapContent: {
      zIndex: 10000
    }
  }),

  Tooltip: StyleSheet({
    arrow: {
      marginTop: '-.4rem',
      boxShadow: SHADOWS.TOOLTIP_ARROW
    },

    tooltip: {
      padding: ({ token }) => token('spacing.m'),
      boxShadow: SHADOWS.TOOLTIP,
      borderRadius: BORDER_RADIUS.INPUT,
      minWidth: '15rem',
      maxWidth: 'none',
      // fontSize: '1.4rem',
      maxHeight: '40rem',
      overflow: 'auto'
    }
  }),

  ActionMenu: StyleSheet({
    menu: {
      margin: ({ token }) => `-${token('spacing.xl')}`,
      marginTop: '-1.4rem',
      marginBottom: '-1.4rem',
      fontSize: '1.6rem'
    },

    menuItem: {
      '> a': {
        fontWeight: FONT.WEIGHTS.LIGHT,
        color: ({ token }) => token('legacy.color.blue.grey'),
        padding: ({ token }) => `${token('spacing.xs')} ${token('spacing.m')}`,
        fontSize: '1.6rem',
        letterSpacing: '-.03rem',
        lineHeight: '2.1rem',
        whiteSpace: 'nowrap',
        transition: 'color .3s, background .3s'
      },

      '> a:hover': {
        textDecoration: 'none',
        color: ({ token }) => token('legacy.color.blue.dark'),
        background: ({ token }) => token('legacy.color.grey.light')
      }
    },

    button: {
      width: '4rem',
      height: '4rem',
      borderRadius: '4rem',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      padding: 0,
      transition: 'background .2s, color .2s',
      color: ({ token }) => token('legacy.color.blue.grey'),

      ':hover': {
        background: ({ token }) => token('legacy.color.grey.light'),
        color: ({ token }) => token('legacy.color.blue.dark')
      }
    }
  }),

  Table: StyleSheet({
    header: {
      height: '4rem'
    },

    th: {
      color: ({ token }) => token('legacy.color.blue.grey'),
      paddingLeft: ({ token }) => token('spacing.xs'),
      paddingRight: ({ token }) => token('spacing.xs')
    },

    tr: {
      paddingTop: ({ token }) => token('spacing.m'),
      paddingBottom: ({ token }) => token('spacing.m'),
      borderTop: ({ token }) =>
        `.1rem solid ${token('legacy.color.blue.grey')}`,
      alignItems: 'center',
      justifyContent: 'space-between',
      flexGrow: 0,

      ':hover': {
        // background: ({ token }) => token('legacy.color.grey.light'),
      }
    },

    td: {
      paddingLeft: ({ token }) => token('spacing.xs'),
      paddingRight: ({ token }) => token('spacing.xs')
    },

    tbody: {
      flexWrap: 'nowrap'
    }
  }),

  TabBar: StyleSheet({
    container: {
      borderBottom: ({ token }) =>
        `.1rem solid ${token('legacy.color.grey.light')}`
    },

    indicator: {
      background: ({ token }) => token('legacy.color.blue.dark')
    }
  }),

  Tab: StyleSheet({
    container: {
      flex: 0,
      whiteSpace: 'nowrap',
      textTransform: 'none',
      fontSize: '1.6rem',
      padding: ({ token }) =>
        `${token('spacing.xxxl')} 0 ${token('spacing.xxl')} 0`,
      marginRight: ({ token }) => token('spacing.xxl'),
      color: ({ token }) => token('legacy.color.blue.grey'),

      ':hover': {
        color: ({ token }) => token('legacy.color.blue.dark')
      }
    },

    containerActive: {
      color: ({ token }) => token('legacy.color.blue.dark')
    },

    containerDisabled: {
      color: ({ token }) => token('legacy.color.blue.greyLight'),
      cursor: 'default',

      ':hover': {
        color: ({ token }) => token('legacy.color.blue.greyLight')
      }
    }
  }),

  Toast: StyleSheet({
    container: {
      borderRadius: 100,
      background: ({ token }) => token('palette.brand.600'),
      paddingLeft: ({ token }) => token('spacing.xl'),
      paddingRight: ({ token }) => token('spacing.xl')
    }
  }),

  DayPicker: StyleSheet({
    region: {
      fontFamily: 'Circular Pro !important'
    },
    container: {
      zIndex: 30
    }
  }),

  DefaultModal: StyleSheet({
    content: {
      ' form': {
        display: 'block'
        // marginTop: ({ token }) => `-${token('spacing.m')}`
      }
    }
  })
};

export default COMPONENTS;

import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { styled, StyleSheet } from '@rexlabs/styling';
import Box from '@rexlabs/box';
import _ from 'lodash';
import colorTheme from '../../../utils/color-theme';
import charLimit, { limits } from '../../../utils/char-limit';
import { LargeButton } from '../../button';
import GooText from '../../goo-text';
import ScaledText from '../../scaled-text';
import { COLORS } from 'src/theme';

const fontFamily = 'Lato, sans-serif';

const defaultStyles = StyleSheet({
  portrait: {
    border: 'solid 1px darkgray',
    flexGrow: 0,
    flexShrink: 0
  },
  header: {
    position: 'relative'
  },
  img: {
    objectFit: 'cover'
  },
  longHeadlineWrapper: {
    position: 'absolute',
    margin: '15px',
    maxWidth: '226px'
  },
  longHeadline: {
    fontSize: '20px',
    lineHeight: '25px',
    fontWeight: '300',
    fontFamily
  },
  content: {
    background: 'white'
  },
  shortHeadline: {
    marginTop: '26px',
    fontSize: '44px',
    lineHeight: '50px',
    fontWeight: '300',
    fontFamily
  },
  footer: {},
  footerLogo: {
    maxWidth: 'calc(0.6 * 300px)',
    maxHeight: 'calc(0.7 * 100px)',
    objectFit: 'contain'
  }
});

@styled(defaultStyles)
class Portrait extends PureComponent {
  static propTypes = {
    brandColor: PropTypes.string,
    longHeadline: PropTypes.string,
    shortHeadline: PropTypes.string,
    primaryImageUrls: PropTypes.object,
    secondaryImageUrls: PropTypes.object,
    logoUrls: PropTypes.object,
    buttonText: PropTypes.string
  };

  static defaultProps = {
    buttonText: 'Learn more'
  };

  render() {
    const {
      brandColor,
      backgroundColor,
      longHeadline,
      shortHeadline,
      primaryImageUrls,
      secondaryImageUrls,
      logoUrls,
      buttonText,
      styles: s
    } = this.props;

    const { brandColorBorder } = colorTheme(brandColor);

    return (
      <Box
        width='300px'
        height='1050px'
        display='flex'
        flexDirection='column'
        {...s('portrait')}
      >
        <Box height='326px' {...s('header')}>
          <div {...s('longHeadlineWrapper')}>
            <GooText padding='6px 8px 3px' borderRadius={1.5}>
              <span {...s.with('longHeadline')({ color: COLORS.BLACK })}>
                {charLimit(longHeadline, limits.LONG_HEADLINE)}
              </span>
            </GooText>
          </div>
          <img
            width='100%'
            height={326}
            src={_.get(primaryImageUrls, ['crop.square', 'url'])}
            {...s('img')}
          />
        </Box>
        <Box
          flex={1}
          display='flex'
          flexDirection='column'
          padding='40px 24px'
          {...s('content')}
        >
          <ScaledText
            maxWidth={243}
            maxTextLines={3}
            maxFontSize={38}
            minFontSize={22}
            {...s.with('shortHeadline')({ color: COLORS.BLACK })}
          >
            {shortHeadline}
          </ScaledText>
          <Box width='100%' marginTop={24}>
            <LargeButton brandColor={brandColor}>{buttonText}</LargeButton>
          </Box>
        </Box>
        <img
          width='100%'
          height={302}
          src={_.get(secondaryImageUrls, ['crop.square', 'url'])}
          {...s('img')}
        />
        <Box
          height='100px'
          display='flex'
          flexDirection='column'
          alignItems='center'
          justifyContent='center'
          padding='15px 40px'
          {...s.with('footer')({
            backgroundColor: backgroundColor,
            borderTop: brandColorBorder
          })}
        >
          <img src={_.get(logoUrls, 'original.url')} {...s('footerLogo')} />
        </Box>
      </Box>
    );
  }
}

export default Portrait;

/* eslint-disable max-lines */
import { track } from '@rexlabs/analytics';
import { Box } from '@rexlabs/box';
import { GhostButton, PrimaryButton } from '@rexlabs/button';
import {
  query,
  withModel,
  withQuery,
  withValueLists
} from '@rexlabs/model-generator';
import { Portal } from '@rexlabs/portal';
import { withWhereabouts } from '@rexlabs/react-whereabouts';
import { StyleSheet, margin, useStyles, useToken } from '@rexlabs/styling';
import { Heading } from '@rexlabs/text';
import { match } from '@rexlabs/whereabouts';
import React, {
  ChangeEventHandler,
  Children,
  FC,
  ReactNode,
  useCallback,
  useEffect,
  useMemo,
  useState
} from 'react';
import { compose } from 'redux';
import { Logo } from 'src/components/elements/logo';
import sessionModel from 'src/data/models/custom/session';
import wizardModel from 'src/data/models/custom/wizard';
import campaignTemplatesModel from 'src/data/models/entities/campaign-templates';
import campaignsModel from 'src/data/models/entities/campaigns';
import campaignGoalsModel, {
  CampaignGoalData
} from 'src/data/models/value-lists/campaign-goals';
import ROUTES from 'src/routes/admin';
import { GRID, formattedBrandName } from 'src/theme';
import { Link, push } from 'src/utils/whereabouts';
import { GridCell, GridRow } from 'src/view/components/grid';
import { WizardSelect } from 'src/view/components/input';
import { HelpIcon, TooltipStateful } from 'src/view/components/tooltip';

import { useNavigate } from 'react-router-dom';
import Warning from 'src/assets/icons/warning.svg';
import CreateCampaignBackground from 'src/assets/images/create-campaign.svg';
import networksModel from 'src/data/models/value-lists/networks';
import { useCampaignDefinitions } from 'src/features/campaign-definitions';
import { Feature, useFeature, useFeatures, FeatureGroup } from 'src/utils/feature-flags';
import { AdvancedStrategiesBanner } from 'src/view/components/banner';
import ScrollableContent from 'src/view/components/scrollable-content';
import { Body } from 'src/view/components/text';
import { GoalHelpBox } from './start/goals';

const styles = StyleSheet({
  container: {
    overflowX: 'hidden'
  },

  logo: {
    height: '4rem',
    marginBottom: ({ token }) => token('spacing.xl'),
    cursor: 'pointer'
  },

  typeText: {
    borderLeftWidth: '0.3rem',
    borderLeftStyle: 'solid',
    borderLeftColor: ({ token }) => token('palette.brand.600'),
    padding: ({ token }) => token('spacing.xs'),
    paddingLeft: ({ token }) => token('spacing.m'),
    maxWidth: '50rem'
  },

  goalHeader: {
    width: 'fit-content'
  },

  goalHeaderHighlight: {
    color: ({ token }) => token('palette.brand.700')
  },

  goalTag: {
    backgroundColor: ({ token }) => token('palette.brand.300'),
    ...margin.styles({
      top: 'xs'
    })
  },

  helpBox: {
    position: 'fixed',
    bottom: '4rem',
    right: '4rem',
    width: '40rem',
    height: 'auto'
  },

  background: {
    position: 'absolute',
    bottom: 0,
    right: 0,
    zIndex: '-10',
    height: '100%',
    width: 'auto'
  },

  warning: {
    color: ({ token }) => token('palette.yellow.700'),

    ':hover': {
      color: ({ token }) => token('palette.yellow.500')
    }
  }
});

const q = query`{
  ${campaignsModel} (q: "type.eq(appraisal)" ){id}
}`;

/**
 * NOTE: this query should be imported from shared but there's an issue
 * when changing routes from 1 page to another that has the same query
 * that the data gets garbage collected from the store.
 */
const campaignTemplatesQuery = query`{
  ${campaignTemplatesModel} {
    id
    label
    slug
    display_order
  }
}`;

// TODO: Inline this abstraction into the CreateStateScreen elements
const CampaignTypeHelp: FC<{ children: ReactNode }> = ({ children }) => {
  const [main, ...help] = Children.toArray(children);
  return (
    <Body grey>
      {main}{' '}
      {help.length > 0 && (
        <HelpIcon
          maxWidth='40rem'
          Content={() => (
            <Box w='30rem'>
              {help.map((t, i) => (
                <Body key={i}>{t}</Body>
              ))}
            </Box>
          )}
        />
      )}
    </Body>
  );
};

const TYPE_TEXT = {
  'rental-listing': [
    'Put your listing in front of more potential tenants and landlords wherever they go online.',
    `${formattedBrandName} will create eye-catching ads for your listings and show those ads to the right people, at the right time on the social media channels and websites they visit most.`
  ],
  listing: [
    'Put your listing in front of more potential buyers wherever they go online.',
    `${formattedBrandName} will create eye-catching ads for your listings and show those ads to the right people, at the right time on the social media channels and websites they visit most.`
  ],
  appraisal: [
    'Encourage more local homeowners to think about selling with your agency.',
    `${formattedBrandName} will build ads to grab homeowners interest and get you through more front doors.`,
    `Using an ad performance formula, ${formattedBrandName} can reach thousands of homeowners on the social media channels and websites they visit most - before they start searching for an agent to sell their property.`
  ],
  'agent-profile': [
    'Be the first agent locals think of when it comes time to sell.',
    `${formattedBrandName} will create ads that actively promote your personal brand as a real estate specialist in the areas you target.`,
    `Using artificial intelligence, ${formattedBrandName} can reach thousands of potential sellers on the social media channels and websites they visit most - it’s the digital alternative to the classic letterbox drop.`
  ],
  'multi-listing-sold': [
    'A digital equivalent of letterbox drops. Present your sold properties to your local community.'
  ]
};

const useInitialGoal = (wizard: any, features: FeatureGroup): string => {
  // If for some reason we have this set on our wizard model...
  if (wizard.goal) {
    return wizard.goal;
  }

  const useAdvancedStrategy = features.campaign_goal_advanced_strategy;
  const useAdvancedExposure = features.campaign_goal_advanced_exposure;

  if (features.campaign_goal_default_maximise_exposure) {
    return useAdvancedExposure ? 'advanced_maximise_exposure' : 'maximise_exposure';
  }

  if (features.campaign_goal_default_leads_and_exposure) {
    return 'leads_and_exposure';
  }

  // Regular logic...
  if (useAdvancedStrategy) {
    return 'maximise_leads';
  }

  return useAdvancedExposure ? 'advanced_maximise_exposure' : 'maximise_exposure';
}

const CreateStartScreen = ({
  campaignTemplates,
  session,
  wizard,
  whereabouts,
  valueLists: { campaignGoals, networks }
}) => {
  const s = useStyles(styles);
  const token = useToken();
  const useCampaignGoal = useFeature('campaign_goal');
  const navigate = useNavigate();

  const { data: campaignDefinitions, isLoading } = useCampaignDefinitions({
    config: {
      select: ({ data }) =>
        data.map(({ id, label, description, tooltip, display_order }) => ({
          label,
          value: id,
          description,
          tooltip: tooltip?.split('&newline;') ?? [],
          display_order
        })),
      placeholderData: {
        data: [],
        pagination: {
          count: 0,
          current_page: 0,
          per_page: 10,
          total: 0,
          total_pages: 0
        }
      }
    }
  });

  const campaignTemplateItems = campaignTemplates.list?.items;
  const campaignGoalItems = campaignGoals?.items ?? [];
  const initialGoal = useInitialGoal(wizard, useFeatures());

  const [goal, setGoal] = useState(initialGoal);
  const [slug, setSlug] = useState(
    whereabouts?.state?.initSelection ?? wizard.slug ?? campaignTemplateItems[0]?.slug
  );
  const selectedCampaignDefinition = useMemo(
    () => campaignDefinitions?.find(({ value }) => value === slug),
    [campaignDefinitions, slug]
  );

  const onGoalChange: ChangeEventHandler<HTMLInputElement> = ({
    target: { value }
  }) => {
    track({
      event: 'Spoke campaign goal selected',
      properties: {
        goal: value
      }
    });
    setGoal(value);
  };

  const isSaleListing = slug === 'listing';
  const goalData: CampaignGoalData | undefined = campaignGoalItems.find(
    (o) => o.id === goal
  );
  const goalNetworkNames = (goalData?.networks ?? []).map((o) => {
    const network = networks?.items.find((n) => n.id === o);
    const label = network?.label;
    if (network?.id === 'adwords') {
      return label.toLowerCase();
    }
    return label;
  });

  const handleExit = useCallback(() => {
    track({
      event: 'Spoke campaign exited',
      properties: {
        action: 'exit_campaign_clicked',
        step_name: 'select_campaign_type'
      }
    });
    push(ROUTES.CAMPAIGNS);
    wizard.flush();
  }, [wizard]);

  const getStarted = useCallback(() => {
    track({
      event: 'Spoke campaign started',
      properties: {
        action: 'start_campaign_clicked',
        campaign_type: wizard.type,
        step_name: 'select_campaign_type'
      }
    });
    if (useCampaignGoal && isSaleListing) {
      wizard.setGoal({ goal });
    }
    wizard.set({ slug });

    if (selectedCampaignDefinition) {
      const matched = match(whereabouts, {
        path: '/:accountIndex/campaign/create'
      });
      navigate(
        `${matched?.params?.accountIndex}/campaigns/create/d/${slug}/provide-details`
      );
    } else {
      push({
        config: { path: `/campaign/create/${slug}` }
      });
    }
  }, [
    goal,
    isSaleListing,
    navigate,
    selectedCampaignDefinition,
    slug,
    useCampaignGoal,
    whereabouts,
    wizard
  ]);

  const typeText = useMemo(() => {
    if (selectedCampaignDefinition) {
      return [
        selectedCampaignDefinition.description,
        ...selectedCampaignDefinition.tooltip
      ];
    } else {
      return TYPE_TEXT[slug];
    }
  }, [selectedCampaignDefinition, slug]);

  const campaignSelectorOptions = campaignTemplateItems
    .map(({ label, slug, display_order }) => ({
      label,
      value: slug,
      display_order
    }))
    .concat(campaignDefinitions)
    .sort((a, b) => a.display_order - b.display_order);

  const campaignGoalOptions = campaignGoalItems.map(({ id, label }) => ({
    value: id,
    label: label.toLowerCase()
  }));
  const goalIsAvailable = goalData?.is_available ?? true;

  const disableBtn = false

  useEffect(() => {
    /**
     * Reset the step incase the user went to a campaign type which would
     * set the step and then clicked back and went to another campaign type
     * as that new campaign type would then go to the wrong step
     */
    if (wizard.step) {
      wizard.set({ step: null });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <ScrollableContent {...s('container')} p={token('spacing.xxxl')}>
      <Portal target='wizardBackground'>
        <CreateCampaignBackground {...s('background')} />
      </Portal>

      <GridRow gutter={GRID.GUTTER_WIZARD}>
        <GridCell gutter={GRID.GUTTER_WIZARD} width={0.75}>
          <Link to={ROUTES.CAMPAIGNS}>
            {({ onClick }) => <Logo onClick={onClick} {...s('logo')} />}
          </Link>
        </GridCell>
      </GridRow>
      <GridRow gutter={GRID.GUTTER_WIZARD}>
        <GridCell gutter={GRID.GUTTER_WIZARD} width={1}>
          <Feature name='campaign_goal'>
            <Feature name='campaign_goal_advanced_strategy' invert>
              <AdvancedStrategiesBanner />
            </Feature>
          </Feature>
        </GridCell>
      </GridRow>
      <GridRow gutter={GRID.GUTTER_WIZARD}>
        <GridCell gutter={GRID.GUTTER_WIZARD} width={0.75}>
          <Box>
            <Heading level={1}>
              Hi {session?.user?.given_name}, let’s create a new marketing
              campaign
            </Heading>
            <div data-intercom-target='campaignIntro'>
              <Box mt={token('spacing.xxxl')} mb={token('spacing.xl')}>
                <GridRow w='100%'>
                  <GridCell width={2}>
                    <Body>I would like to</Body>
                  </GridCell>
                  <GridCell width={6}>
                    <WizardSelect
                      onChange={({ target: { value } }) => setSlug(value)}
                      options={campaignSelectorOptions}
                      isLoading={
                        campaignTemplates.list?.status === 'loading' ||
                        isLoading
                      }
                      value={slug}
                      selectProps={{
                        'data-testid': 'campaign-type-select'
                      }}
                    />
                  </GridCell>
                  <GridCell width={1} />
                </GridRow>
                <Feature name='campaign_goal'>
                  {isSaleListing ? (
                    <GridRow w='100%' mt={token('spacing.m')}>
                      <GridCell width={2}>
                        <Body>My goal is to</Body>
                      </GridCell>
                      <GridCell width={6}>
                        <WizardSelect
                          onChange={onGoalChange}
                          options={campaignGoalOptions}
                          isLoading={campaignGoals?.status === 'loading'}
                          value={goal}
                        />
                      </GridCell>
                      <GridCell width={1}>
                        {!goalIsAvailable && (
                          <Box
                            alignItems='center'
                            flex={1}
                            pl={token('spacing.s')}
                          >
                            <TooltipStateful
                              Content={() => (
                                <>
                                  <Body grey>
                                    To be able to use the new campaign types,
                                    you need to enable increased access to your
                                    Facebook page.
                                  </Body>
                                  <Body grey>
                                    Find out how by clicking &ldquo;Show me
                                    how&rdquo; in banner at the top.
                                  </Body>
                                </>
                              )}
                              placement='right'
                            >
                              <Warning {...s('warning')} />
                            </TooltipStateful>
                          </Box>
                        )}
                      </GridCell>
                    </GridRow>
                  ) : undefined}
                </Feature>
              </Box>
              <Feature name='campaign_goal'>
                {(hasFeature) =>
                  hasFeature ? (
                    <>
                      {isSaleListing && goalData ? (
                        <GoalHelpBox
                          {...s('typeText')}
                          id={goalData.id}
                          label={goalData.label}
                          highlightName={[
                            'maximise_leads',
                            'leads_and_exposure'
                          ].includes(goalData.id)}
                          isNewStrategy={goalData.newStrategy}
                          networks={goalNetworkNames}
                          minSpend={goalData.minSpend}
                        />
                      ) : null}
                      {!isSaleListing && typeText ? (
                        <Box {...s('typeText')}>
                          <CampaignTypeHelp>{typeText}</CampaignTypeHelp>
                        </Box>
                      ) : null}
                    </>
                  ) : typeText !== undefined ? (
                    <Box {...s('typeText')}>
                      <CampaignTypeHelp>{typeText}</CampaignTypeHelp>
                    </Box>
                  ) : null
                }
              </Feature>
              <Box mt={token('spacing.xxxl')}>
                <PrimaryButton
                  onClick={getStarted}
                  isDisabled={disableBtn}
                  data-testid={'get-started-btn'}
                >
                  Let’s get started
                </PrimaryButton>
              </Box>
            </div>
          </Box>
        </GridCell>
      </GridRow>

      <Portal target='headerButton'>
        <GhostButton onClick={handleExit}>Exit campaign creation</GhostButton>
      </Portal>
    </ScrollableContent>
  );
};

export default compose(
  withQuery(q),
  withModel(sessionModel),
  withModel(wizardModel),
  withQuery(campaignTemplatesQuery),
  withWhereabouts(),
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  withValueLists(campaignGoalsModel, networksModel)
)(CreateStartScreen);

/* eslint-disable max-lines */
import { track } from '@rexlabs/analytics';
import { Box } from '@rexlabs/box';
import { padding, styled, StyleSheet } from '@rexlabs/styling';
import React, { useEffect, useRef, useState, useCallback } from 'react';
import { compose } from 'redux';
import DesktopDevice from 'src/assets/icons/desktop.svg';
import MobileDevice from 'src/assets/icons/mobile.svg';
import MonitorStand from 'src/assets/icons/monitor-stand.svg';
import SadPage from 'src/assets/icons/sad-page.svg';
import config from 'src/config';
import { BORDER_RADIUS } from 'src/theme';
import { api } from 'src/utils/api-client';
import { LoadingSpinner } from 'src/view/components/loading';
import { Body } from 'src/view/components/text';
import withError from 'src/view/containers/with-error';

const transitionTime = 400;
const desktopWidth = 1280;
const desktopHeight = 720;

const defaultStyles = StyleSheet({
  container: {
    width: '100%',
    height: 'auto'
  },

  previewTitle: {
    paddingTop: ({ token }) => token('spacing.xs'),
    paddingBottom: ({ token }) => token('spacing.m'),
    justifyContent: 'space-between',
    display: 'flex',
    flexDirection: 'row',
    whiteSpace: 'nowrap',
    fontSize: '1.8rem'
  },

  previewTitleModal: {
    ...padding.styles({
      y: 'xs',
      x: 'xl'
    })
  },

  previewContainer: {
    width: '100%',
    padding: ({ token }) => `${token('spacing.xl')} 0`,
    background: ({ token }) => token('legacy.color.grey.light'),
    borderRadius: BORDER_RADIUS.LARGE,
    overflow: 'hidden'
  },

  truncate: {
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis'
  },

  wrapNav: {
    marginLeft: ({ token }) => token('spacing.xl'),
    position: 'relative'
  },

  navText: {
    paddingTop: '10px'
  },

  navPositioning: {
    display: 'inline-flex',
    paddingLeft: ({ token }) => token('spacing.xs')
  },

  navButton: {
    border: '1px solid',
    borderColor: ({ token }) => token('legacy.color.blue.grey'),
    background: 'transparent',
    color: ({ token }) => token('legacy.color.white'),
    width: '4.4rem',
    height: '4.4rem',
    display: 'inline-flex',
    alignItems: 'center',
    justifyContent: 'center',
    cursor: 'pointer',

    '> svg': {
      display: 'inline-flex',
      color: ({ token }) => token('legacy.color.blue.grey'),
      width: '2.2rem',
      height: '2.2rem',
      transition: 'transform .2s'
    },

    ':hover': {
      '> svg': {
        transform: 'scale(1.2)'
      }
    }
  },

  activeButton: {
    background: ({ token }) => token('legacy.color.blue.grey'),

    '> svg': {
      color: ({ token }) => token('legacy.color.white')
    }
  },

  navButtonMobile: {
    borderTopLeftRadius: BORDER_RADIUS.INPUT,
    borderBottomLeftRadius: BORDER_RADIUS.INPUT
  },

  navButtonDesktop: {
    borderTopRightRadius: BORDER_RADIUS.INPUT,
    borderBottomRightRadius: BORDER_RADIUS.INPUT
  },

  loading: {
    minHeight: '40rem',
    width: '100%'
  },

  deviceWrapper: {
    background:
      'linear-gradient(to bottom, rgba(254,254,254,1) 0%,rgba(240,240,240,1) 100%)',
    width: '90%',
    transition: `max-width ${transitionTime}ms`
  },

  deviceWrapperMobile: {
    borderRadius: '4.5rem',
    boxShadow: '0 5px 10px 0 rgba(23,58,79,0.25)',
    padding: '1.7rem',
    maxWidth: '297px'
  },

  deviceWrapperDesktop: {
    borderRadius: '1.6rem',
    boxShadow: '0 5px 10px 0 rgba(23,58,79,0.15)',
    padding: '1.2rem',
    maxWidth: '672px' // a fraction of of 1920 to aim for an aspect ratio of 1920 x 1080
  },

  deviceInner: {
    background: ({ token }) => token('legacy.color.white'),
    overflow: 'hidden',
    position: 'relative',
    width: '100%',
    transition: 'padding-bottom 400ms'
  },

  deviceInnerMobile: {
    borderRadius: '3.5rem',
    paddingBottom: '216.534%' // corresponds to the deviceWrapperMobile max-width to get an aspect ratio of an ~iPhone X
  },

  deviceInnerDesktop: {
    borderRadius: '1rem',
    paddingBottom: '56.5%' // corresponds to the devuceWrapperDesktop max-width to get an aspect ratio of ~1920 x 1080
  },

  deviceContentIframe: {
    position: 'absolute',
    transformOrigin: 'top left'
  },

  mobileRes: {
    width: '375px',
    height: '812px'
  },

  desktopRes: {
    width: `${desktopWidth}px`,
    height: `${desktopHeight}px`
  },

  monitorStand: {
    transition: 'opacity 800ms'
  },

  monitorStandMobile: {
    height: '0',
    opacity: '0'
  },

  monitorStandDesktop: {
    height: '100%',
    opacity: '1'
  },

  iframeSpinner: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    background: 'white',
    height: '100%',
    width: '100%',
    position: 'absolute',
    zIndex: 1
  },

  cantEmbedWrapper: {
    position: 'absolute',
    width: '100%',
    height: '100%'
  },

  cantEmbedInner: {
    height: '100%',
    padding: ({ token }) => token('spacing.xs'),
    textAlign: 'center'
  },

  cantEmbedMobile: {
    paddingTop: '20%'
  },

  cantEmbedDesktop: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center'
  },

  sadPage: {
    marginBottom: '2.6rem'
  }
});

const LandingPagePreview = ({
  landingPageId,
  listingId,
  values,
  modal,
  forceRefresh,
  initialViewMobile = true,
  fixedScale, // used for print version
  styles: s,
  error: { Error }
}) => {
  const [loading, setLoading] = useState(true);
  const [checkedExternalUrl, setCheckedExternalUrl] = useState({});
  const [preview, setPreview] = useState({
    isMobile: initialViewMobile,
    transitioning: false
  });
  const [scale, setScale] = useState(0.701333);
  const deviceInnerRef = useRef();

  const handleTransitionEnd = () => {
    const width = deviceInnerRef.current.offsetWidth;

    setScale(!preview.isMobile ? width / 375 : width / desktopWidth);
    setPreview((prevPreview) => ({
      isMobile: prevPreview.isMobile,
      transitioning: false
    }));
    deviceInnerRef.current.removeEventListener(
      'transitionend',
      handleTransitionEnd
    );
  };

  const toggleDevice = (e) => {
    e.preventDefault();

    deviceInnerRef.current.addEventListener(
      'transitionend',
      handleTransitionEnd
    );
    setPreview((prevPreview) => ({
      isMobile: !prevPreview.isMobile,
      transitioning: true
    }));
  };

  const isExternal = !!values?.is_external;
  const externalUrl = values?.external_url;

  const onLoad = useCallback(() => {
    setLoading(false);
  }, []);
  const previewData = {
    template: values?.template?.id
      ? values?.template
      : { id: values?.template },
    template_variables: values?.template_variables,
    branding_overrides: {
      ...values?.branding_overrides,
      ...{ logo: { id: values?.branding_overrides?.logo?.id } }
    },
    agent: { id: values?.agent?.id || values?.agent },
    virtual_tour_url: values?.virtual_tour_url
  }
  const urlParams = encodeURIComponent(
    JSON.stringify(listingId ? {...previewData, listing_id: listingId} : previewData)
  );

  const Iframe = () => {
    if (
      isExternal &&
      externalUrl === checkedExternalUrl.url &&
      !checkedExternalUrl.canEmbed
    ) {
      return (
        <div {...s('cantEmbedWrapper')}>
          <div
            {...s('cantEmbedInner', {
              cantEmbedMobile: preview.isMobile,
              cantEmbedDesktop: !preview.isMobile
            })}
          >
            <SadPage {...s('sadPage')} />
            <Body grey>
              Your page settings prevent us from displaying a preview.
            </Body>
            <Body>
              <a
                href={externalUrl}
                target='_blank'
                rel='noopener noreferrer'
                style={{ fontWeight: 300 }}
              >
                Click here to open your page in a new tab
              </a>
            </Body>
          </div>
        </div>
      );
    }

    return (
      <>
        {((isExternal && externalUrl !== checkedExternalUrl.url) ||
          loading) && (
          <div {...s('iframeSpinner')}>
            <LoadingSpinner />
          </div>
        )}
        {/* 
          Do NOT remove key from the iframe
          The iframe history is getting caught in whereabouts so when trying to "closeModal"
          whereabouts pops the browsers history from within the iframe and in affect doesn't 
          close the modal.
          Setting the key on the iframe gives a complete new iframe whenever the key changes.

          UPDATE! key has been removed due to it causing chrome 83 to crash. The workaround is 
          to use manually adjust the route instead of using closeModal
        */}
        <iframe
          // key={`${isExternal}${externalUrl}${urlParams}${forceRefresh}`}
          sandbox={`allow-scripts ${externalUrl ? 'allow-same-origin' : ''}`}
          {...s('deviceContentIframe', {
            mobileRes: preview.isMobile,
            desktopRes: !preview.isMobile
          })}
          src={
            isExternal
              ? externalUrl
              : `${config.LANDING_PAGE_PREVIEW}?landing_page_id=${landingPageId}&settings=${urlParams}&forceRefresh=${forceRefresh}`
          }
          onLoad={onLoad}
          style={{
            transform: `scale(${fixedScale || scale})`,
            opacity: preview.transitioning ? '0' : '1'
          }}
        />
      </>
    );
  };

  useEffect(() => {
    let cancelled = false;
    if (isExternal && externalUrl !== checkedExternalUrl.url) {
      const embeddedPageCheck = async () => {
        try {
          const { data } = await api.post('/embedded-page-check', {
            host: window.location.origin,
            embed: externalUrl
          });

          if (cancelled) return;
          setCheckedExternalUrl({ url: externalUrl, canEmbed: data[0] });
        } catch (e) {
          if (cancelled) return;
          setCheckedExternalUrl({ url: externalUrl, canEmbed: false });
        }
      };

      embeddedPageCheck();
    }

    return () => (cancelled = true);
  }, [isExternal, externalUrl, checkedExternalUrl.url]);

  return (
    <Box {...s('container')}>
      <Box {...s('previewTitle', { previewTitleModal: modal })}>
        <Box {...s('navText')}>
          {preview.isMobile ? 'Mobile' : 'Desktop'} page
        </Box>

        <Box {...s('navPositioning')}>
          <Box {...s('wrapNav')}>
            <button
              {...s('navButton', 'navButtonMobile', {
                activeButton: preview.isMobile
              })}
              onClick={(e) => {
                track({
                  event: 'Spoke landing page preview toggled',
                  properties: {
                    action: 'toggle_mobile_clicked'
                  }
                });
                toggleDevice(e);
              }}
              disabled={preview.isMobile}
            >
              <MobileDevice />
            </button>
            <button
              {...s('navButton', 'navButtonDesktop', {
                activeButton: !preview.isMobile
              })}
              onClick={(e) => {
                track({
                  event: 'Spoke landing page preview toggled',
                  properties: {
                    action: 'toggle_desktop_clicked'
                  }
                });
                toggleDevice(e);
              }}
              disabled={!preview.isMobile}
            >
              <DesktopDevice />
            </button>
          </Box>
        </Box>
      </Box>

      <Box
        flexDirection='column'
        justifyContent='flex-start'
        alignItems='center'
        {...s('previewContainer')}
      >
        <div
          {...s('deviceWrapper', {
            deviceWrapperMobile: preview.isMobile,
            deviceWrapperDesktop: !preview.isMobile
          })}
        >
          <div
            ref={deviceInnerRef}
            {...s('deviceInner', {
              deviceInnerMobile: preview.isMobile,
              deviceInnerDesktop: !preview.isMobile
            })}
          >
            <Iframe />
          </div>
        </div>
        <MonitorStand
          {...s('monitorStand', {
            monitorStandMobile: preview.isMobile,
            monitorStandDesktop: !preview.isMobile
          })}
        />
      </Box>

      <Error />
    </Box>
  );
};

export default compose(withError(), styled(defaultStyles))(LandingPagePreview);

import { useMutation, UseMutationResult, useQueryClient } from 'react-query';
import { api } from 'src/utils/api-client';
import { Upload } from '../types';
import { uploadSchema } from '../utils/schemas';

export type UploadFileParams = {
  file: File;
};

export const uploadCSVFile = async (
  params: UploadFileParams
): Promise<Upload> => {
  const data = new FormData();
  data.set('file', params.file);
  const res = await api.post('/uploads/csv', data, {
    headers: { 'Content-Type': 'multipart/form-data' }
  });
  return uploadSchema.create(res.data);
};

export const useUploadCSVFile = (): UseMutationResult<
  Upload,
  Error,
  UploadFileParams
> => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: (params) => uploadCSVFile(params),
    onSuccess: (data) => {
      queryClient.setQueryData(['test', 'uploads', data.id], data);
    }
  });
};

import { Box } from '@rexlabs/box';
import { OutlineButton, PrimaryButton } from '@rexlabs/button';
import { border, margin, styled, StyleSheet, useToken } from '@rexlabs/styling';
import { Body, Heading, Small, Tiny } from '@rexlabs/text';
import React from 'react';
import Oval from 'src/assets/icons/oval.svg';
import { BORDER_RADIUS, FONT, SHADOWS } from 'src/theme';
import { ariaAttr } from 'src/utils/dom';
import { abbreviateNumber } from 'src/utils/format';
import { LoadingSpinner } from 'src/view/components/loading';
import Money from 'src/view/components/money';

const defaultStyles = StyleSheet({
  wrapPlan: {
    border: ({ token }) => `.1rem solid ${token('legacy.color.grey.default')}`,
    borderRadius: BORDER_RADIUS.INPUT,
    width: '100%',
    padding: ({ token }) => token('spacing.xl'),
    textAlign: 'center',
    position: 'relative',
    boxShadow: SHADOWS.PLAN_NONE,
    transition: 'transform .2s, box-shadow .2s',
    height: '38rem',
    background: ({ token }) => token('legacy.color.white'),
    cursor: 'pointer',

    ':hover': {
      transform: 'translateY(-.6rem)',
      boxShadow: SHADOWS.PLAN
    }
  },

  notSelectable: {
    transform: 'translateY(-.6rem)',
    boxShadow: SHADOWS.PLAN,
    cursor: 'default'
  },

  wrapPopularPlan: {
    '&::before': {
      content: 'POPULAR',
      fontSize: '1.4rem',
      width: '10rem',
      position: 'absolute',
      top: '-1.2rem',
      height: '2.4rem',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      left: '50%',
      marginLeft: '-5rem',
      background: ({ token }) => token('legacy.color.blue.greyLight'),
      borderRadius: '2.4rem',
      color: ({ token }) => token('legacy.color.white'),
      transition: 'background .2s'
    }
  },

  wrapSelectedPlan: {
    transform: 'translateY(-.6rem)',
    boxShadow: SHADOWS.PLAN
  },

  wrapSelectedPopularPlan: {
    '&::before': {
      background: ({ token }) => token('legacy.color.primary.default')
    }
  },

  wrapPlanPrice: {
    overflow: 'hidden'
  },

  planPrice: {
    fontSize: '4.8rem',
    letterSpacing: '-.04',
    margin: 0,
    fontWeight: FONT.WEIGHTS.SEMIBOLD
  },

  div: {
    width: '100%',
    height: '0',
    ...border.styles({
      bottom: {
        width: 'thin',
        color: 'container.static.light'
      }
    }),
    ...margin.styles({
      top: 'xs'
    })
  },

  planViews: {
    fontWeight: FONT.WEIGHTS.SEMIBOLD,
    marginTop: '-.4rem',
    marginBottom: '-.4rem',
    color: ({ token }) => token('palette.brand.600'),
    fontSize: '2.4rem',
    letterSpacing: '-.04',
    lineHeight: '3rem'
  },

  oval: {
    marginLeft: ({ token }) => token('spacing.xs'),
    marginRight: ({ token }) => token('spacing.xs'),
    marginBottom: ({ token }) => token('spacing.xxs')
  }
});

function CustomPlan({
  styles: s,
  onClick,
  plan,
  selected,
  popular,
  onSelect,
  upgrade,
  downgrade,
  type,
  slug,
  estimate,
  currentDuration,
  currentPlan,
  scheduledPlan = null,
  isSold,
  canEdit,
  selectable = true
}) {
  const token = useToken();
  const planLabelId = `${plan?.id}-label`;

  const recurring = plan?.type?.id === 'recurring';
  const isFixedDateOfMonthRecurring = plan?.budget_type === 'fixed_date_of_month';
  const failures = estimate?.reach?.failures ?? [];
  const isCurrentPlan = plan.id === currentPlan?.id;
  const isScheduledPlan = plan.id === scheduledPlan?.id;

  return (
    <Box
      {...s('wrapPlan', {
        wrapSelectedPlan: selected,
        wrapPopularPlan: popular,
        wrapSelectedPopularPlan: selected && popular,
        notSelectable: !selectable
      })}
      flexDirection='column'
      role='radio'
      aria-checked={ariaAttr(selected)}
      aria-labelledby={planLabelId}
      onClick={onSelect}
    >
      <Box flex={1} flexDirection='column'>
        {(upgrade || downgrade) && (
          <Small grey as='span'>
            {upgrade && 'Upgrade to'}
            {downgrade && 'Downgrade to'}
          </Small>
        )}

        <Box mt='.2rem' sx='0.2rem'>
          <Small as='span' grey id={planLabelId}>
            {plan?.name ?? ''}
          </Small>
          {canEdit ? (
            <>
              <Oval aria-hidden {...s('oval')} />
              <span
                style={{
                  borderBottom: `0.2rem solid ${token(
                    'legacy.color.blue.grey'
                  )}`
                }}
                onClick={onClick}
              >
                edit
              </span>
            </>
          ) : null}
        </Box>
        <Box
          flexDirection='row'
          alignItems='center'
          justifyContent='center'
          {...s('wrapPlanPrice')}
        >
          <Body {...s('planPrice')}>
            <Money
              amount={
                plan?.price?.value ??
                0 -
                  (recurring || !currentPlan
                    ? currentPlan?.price?.value ?? 0
                    : 0)
              }
              currencyCode={plan?.price?.currency_code}
              integer={true}
              Tag={({ children }) => (
                <Heading as='span' level='display'>
                  {!(recurring || !currentPlan) && '+ '}
                  {children}
                </Heading>
              )}
            />
          </Body>
        </Box>

        {isFixedDateOfMonthRecurring ? (
          <Body as='span'>per month</Body>
        ): recurring ? (
          <Body as='span'>per week</Body>
        ) : currentDuration ? (
          <Small as='span'>
            + {(plan?.duration?.value ?? 0) - currentDuration.total} days
          </Small>
        ) : (
          <Small as='span'>{plan?.duration?.value ?? '-'} days</Small>
        )}

        <Box {...s('div')} />
        {estimate?.reach?.min === 0 ? (
          <Tiny grey>
            We couldn’t get estimates at this time, but your campaign will still
            run normally and reach as many people as possible with your budget.
          </Tiny>
        ) : (
          <Box flex={1} justifyContent='center' alignItems='center'>
            {!estimate ||
            estimate.status === 'loading' ||
            estimate.status === 'refreshing' ||
            !estimate?.reach?.min ? (
              <Box
                style={{ width: '100%' }}
                flexDirection='column'
                justifyContent='center'
                alignItems='center'
              >
                <LoadingSpinner size={25} strokeWidth={5} />
                <Small grey>Loading estimates...</Small>
              </Box>
            ) : failures.length === 2 ? (
              <Tiny grey>
                We couldn’t get estimates at this time, but your campaign will
                still run normally and reach as many people as possible with
                your budget.
              </Tiny>
            ) : (
              <div>
                <Small grey style={{ padding: 0 }}>
                  Estimated {recurring ? 'weekly ' : ''}ad views
                </Small>
                <Body {...s('planViews')}>
                  {abbreviateNumber(estimate?.reach?.min)} -{' '}
                  {abbreviateNumber(estimate?.reach?.max)}
                  {!!failures.length && <span>*</span>}
                </Body>
                {slug === 'rental-listing' ? (
                  <Small grey style={{ padding: 0 }}>
                    from potential tenants
                  </Small>
                ) : (
                  <Small grey style={{ padding: 0 }}>
                    from potential{' '}
                    {type === 'single_listing' && !isSold
                      ? ' buyers'
                      : 'sellers'}
                  </Small>
                )}
              </div>
            )}
          </Box>
        )}
      </Box>
        {selectable && (
          <Box flexDirection='column'>
            {isCurrentPlan ? (
              <PrimaryButton isDisabled={true}>Active</PrimaryButton>
            ) : isScheduledPlan ? (
              <PrimaryButton isDisabled={true}>Scheduled</PrimaryButton>
            ) : selected ? (
              <PrimaryButton>Selected</PrimaryButton>
            ) : (
              <OutlineButton>Select</OutlineButton>
            )}
          </Box>
        )}
    </Box>
  );
}

export default styled(defaultStyles)(CustomPlan);

import { useParams } from 'react-router-dom';
import { useModelState } from '@rexlabs/model-generator';
import session from 'data/models/custom/session';

export const useAccountId = (): string => {
  const { accountId } = useParams();
  const { currentAccountId, ready } = useModelState(session);

  if (accountId) return accountId;
  if (!ready) return '';
  if (!currentAccountId) throw new Error('Account ID not found in URL');
  return currentAccountId;
};

import { Box } from '@rexlabs/box';
import { StyleSheet, useStyles, useToken } from '@rexlabs/styling';
import React from 'react';
import { BORDER_RADIUS } from 'src/theme';
import HealthIndicator from 'src/view/components/health-indicator';
import { RenderLoading } from 'src/view/components/loading';
import { Map, MapInfo } from 'src/view/components/map';
import { Body } from 'src/view/components/text';

const defaultStyles = StyleSheet({
  container: {
    display: 'flex',
    width: '100%',
    flexDirection: 'column'
  },

  wrapInner: {
    width: '100%',
    borderRadius: BORDER_RADIUS.LARGE,
    overflow: 'hidden'
  },

  wrapSummary: {
    width: '38rem',
    background: ({ token }) => token('legacy.color.grey.light'),
    padding: ({ token }) => token('spacing.xl'),
    paddingTop: ({ token }) => token('spacing.xl'),
    textAlign: 'center',
    color: ({ token }) => token('color.textStyle.body.subtitle')
  },

  div: {
    marginTop: ({ token }) => token('spacing.xl'),
    marginBottom: ({ token }) => token('spacing.xl'),
    width: '100%',
    height: '.1rem',
    background: ({ token }) => token('color.container.static.contrast'),
    flexShrink: 0
  }
});

const AudienceMap = ({
  generalAudiences,
  retargetingAudiences,
  campaigns,
  slug
}) => {
  const s = useStyles(defaultStyles);
  const token = useToken();

  const type = campaigns?.item?.data?.type?.id;
  const audience = campaigns?.item?.data?.audience_spec || {};
  const location = (audience?.locations || []).find(
    (l) => l?.type?.id === 'radius'
  );
  const suburbs = (audience?.locations || []).filter(
    (l) => l?.type?.id === 'suburb'
  );
  const markers = (audience?.locations || []).map((l) =>
    l?.type?.id === 'radius'
      ? [l?.address?.longitude, l?.address?.latitude]
      : [l?.suburb?.longitude, l?.suburb?.latitude]
  );

  const mapCenter = location?.address?.latitude
    ? [
        parseFloat(location?.address?.longitude),
        parseFloat(location?.address?.latitude)
      ]
    : null;

  const ageText =
    `${audience?.age_range?.youngest} - ` +
    `${audience?.age_range?.oldest} year old`;

  return (
    <RenderLoading
      isLoading={campaigns.item.status !== 'loaded'}
      minHeight='45.9rem'
    >
      <Box {...s('container')} data-intercom-target='audiencePreview'>
        <Box {...s('wrapInner')} flexDirection='row'>
          <Box flex={1} flexDirection='column'>
            <Map
              center={mapCenter}
              show={markers?.length}
              radius={location?.radius?.value || 10}
              unit={location?.radius?.unit}
              markers={markers}
              fullHeight={true}
              dragPan={true}
              readonly
            />
          </Box>
          <Box {...s('wrapSummary')} flexDirection='column'>
            <HealthIndicator
              isLoading={campaigns.item.status === 'loading'}
              geoRange={location?.radius?.value}
              suburbs={suburbs}
              ageRange={audience?.age_range || {}}
              segments={audience?.segments || []}
              generalAudiences={generalAudiences}
              retargetingAudiences={retargetingAudiences}
            />
            <Box {...s('div')} />
            <Body>
              Your ads will be served to{' '}
              <span style={{ color: token('legacy.color.blue.dark') }}>
                {ageText}
              </span>{' '}
              people who are{' '}
              {(audience?.segments || []).map((s, i) => (
                <span key={i}>
                  {i === 0 ? (
                    ''
                  ) : i === (audience?.segments || []).length - 1 ? (
                    <span> and </span>
                  ) : (
                    <span style={{ color: token('legacy.color.blue.dark') }}>
                      ,{' '}
                    </span>
                  )}
                  <span style={{ color: token('legacy.color.blue.dark') }}>
                    {s.label.toLowerCase()}
                  </span>
                </span>
              ))}
              .
            </Body>
          </Box>
        </Box>
        {/* HACK: rental listings use the single listing type.
          Mapinfo should be refactored and handled via the campaign template */}
        <MapInfo type={slug === 'rental-listing' ? 'rental_listing' : type} />
      </Box>
    </RenderLoading>
  );
};

export default AudienceMap;

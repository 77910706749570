import React, { PureComponent } from 'react';
import Skyscraper, { types } from './skyscraper';

export default class WideSkyscraper extends PureComponent {
  static propTypes = Skyscraper.propTypes;

  render() {
    return <Skyscraper width='160px' {...this.props} type={types.WIDE} />;
  }
}

import { Checkbox } from '@rexlabs/checkbox';
import { StyleSheet, margin, useStyles } from '@rexlabs/styling';
import { StatusGoodTag } from '@rexlabs/tag';
import { Body, Tiny } from '@rexlabs/text';
import React, { FC, useCallback, useRef } from 'react';
import { ListItem, ListItemProps } from 'src/components/elements/listbox';
import { Separator } from 'src/components/elements/separator';
import { Consumer } from 'src/types';
import Avatar from 'src/view/components/avatar';
import { CommercialListing } from '../types';
import { listingSourceText, listingTypeText } from '../utils/helpers';

const styles = StyleSheet({
  container: {
    listStyle: 'none',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    cursor: 'pointer',
    transition: 'all .2s',

    ':hover': {
      backgroundColor: ({ token }) => token('color.container.interactive.hover')
    },

    ':active': {
      backgroundColor: ({ token }) =>
        token('color.container.interactive.active')
    },

    '&[aria-selected="true"]': {
      backgroundColor: ({ token }) =>
        token('color.container.interactive.selected')
    }
  },

  details: {
    display: 'flex',
    flexDirection: 'column',
    flex: '1 1 auto'
  },

  label: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    gap: ({ token }) => token('spacing.m')
  },

  text: {
    margin: '0',
    padding: '0',
    lineHeight: ({ token }) => token('typography.lineHeight.l'),
    fontWeight: ({ token }) => token('typography.weight.medium'),
    fontSize: ({ token }) => token('typography.size.xl'),
    letterSpacing: '0'
  },

  textMeta: {
    fontSize: ({ token }) => token('typography.size.l')
  },

  toolbar: {
    fontSize: '1.2rem !important',
    display: 'flex',
    alignItems: 'center',

    '& > * + *': {
      ...margin.styles({
        left: 'xxs'
      })
    }
  },

  separator: {
    width: '4px',
    height: '4px',
    borderRadius: '99999px',
    backgroundColor: ({ token }) => token('color.textStyle.body.subtext')
  }
});

export type CommercialListingItemProps = Pick<
  ListItemProps,
  'posinset' | 'selected' | 'className' | 'style' | 'children'
> & {
  item: CommercialListing;
  onChange: Consumer<boolean>;
};

export const CommercialListingItem: FC<CommercialListingItemProps> = ({
  item,
  onChange,
  selected,
  posinset,
  className,
  style,
  children
}) => {
  const s = useStyles(styles, 'CommercialListingItem');
  const itemId = `commercial-${item.id}`;
  const labelId = `${itemId}-label`;

  const handleChange = useCallback(
    () => onChange(!selected),
    [onChange, selected]
  );

  const checkbox = useRef<HTMLInputElement>(null);
  const toggleCheckbox = useCallback(() => checkbox.current?.click(), []);

  return (
    <ListItem
      id={itemId}
      posinset={posinset}
      selected={selected}
      aria-labelledby={labelId}
      {...s.with('container')({ className, style })}
    >
      <Avatar
        src={item.thumbnails['thumbnail.campaign']?.url}
        onClick={toggleCheckbox}
      />
      <div {...s('details')}>
        <div {...s('label')}>
          <Body {...s('text')} id={labelId} as='span' onClick={toggleCheckbox}>
            {item.formatted_address.short}
          </Body>
          <StatusGoodTag>{listingTypeText(item.sale_or_rental)}</StatusGoodTag>
        </div>
        <Body
          {...s('text', 'textMeta')}
          grey
          as='span'
          onClick={toggleCheckbox}
        >
          {item.marketing_headline}
        </Body>
        <div {...s('toolbar')}>
          <Tiny
            grey
            style={{
              fontWeight: '500'
            }}
            as='span'
          >
            {listingSourceText(item.data_source)}
          </Tiny>
          {children ? (
            <Separator orientation='vertical' {...s('separator')} />
          ) : null}
          {children}
        </div>
      </div>
      <Checkbox
        ref={checkbox}
        aria-labelledby={labelId}
        name={item.id}
        value={selected}
        onChange={handleChange}
      />
    </ListItem>
  );
};

import React, { FC } from 'react';
import { UseFieldApiConfig } from '@data-driven-forms/react-form-renderer';
import AgentSelection from 'src/features/agents/components/agent-selection';

type AgentSelectorInputProps = UseFieldApiConfig;
export const DynamicAgentSelectorInput: FC<AgentSelectorInputProps> = ({
  onChange,
  value
}) => {
  return (
    <AgentSelection
      borderless
      value={value}
      onChange={(data) => onChange(data.target.value)}
    />
  );
};

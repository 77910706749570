import { useInfiniteQuery, UseInfiniteQueryResult } from 'react-query';
import { InfiniteQueryConfig } from 'src/lib/react-query';
import { PaginatedResponse, Pagination, PaginationParams } from 'src/types';
import { api } from 'src/utils/api-client';
import { ResourceDownload } from '../types';
import { createResourceDownload } from '../utils/schemas';
import { resourceDownloadKeys } from './keys';
import { useAccountId } from 'src/features/accounts';

export type GetResourceDownloadsParams = PaginationParams & {
  includes?: string[];
  q?: string;
};

export const getResourceDownloads = async ({
  page = 1,
  includes,
  ...params
}: GetResourceDownloadsParams): Promise<
  PaginatedResponse<ResourceDownload>
> => {
  const { data, pagination } = await api.get('/resource-downloads', {
    ...params,
    includes: includes?.join(','),
    page
  });
  return {
    data: data.map(createResourceDownload),
    pagination: pagination as Pagination
  };
};

type QueryFnType = typeof getResourceDownloads;

export type UseResourceDownloadsOptions = Omit<
  GetResourceDownloadsParams,
  'page'
> & {
  config?: InfiniteQueryConfig<QueryFnType>;
};

export const useResourceDownloads = ({
  config,
  ...params
}: UseResourceDownloadsOptions = {}): UseInfiniteQueryResult<
  PaginatedResponse<ResourceDownload>,
  Error
> => {
  const accountId = useAccountId();
  return useInfiniteQuery<
    PaginatedResponse<ResourceDownload>,
    Error,
    PaginatedResponse<ResourceDownload>,
    ReturnType<typeof resourceDownloadKeys.list>
  >({
    queryKey: resourceDownloadKeys.list({ accountId, ...params }),
    queryFn: ({ pageParam }) =>
      getResourceDownloads({ ...params, page: pageParam }),
    getNextPageParam: ({ pagination }) =>
      pagination.current_page === pagination.total_pages
        ? undefined
        : pagination.current_page + 1
  });
};

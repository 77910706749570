import { Box } from '@rexlabs/box';
import { query, withQuery } from '@rexlabs/model-generator';
import { styled, StyleSheet } from '@rexlabs/styling';
import { Heading } from '@rexlabs/text';
import React, { useState } from 'react';
import { compose } from 'redux';
import config from 'src/config';
import campaignsModel from 'src/data/models/entities/campaigns';
import EMBED from 'src/routes/embed';
import domain from 'src/utils/domain';
import { Link } from 'src/utils/whereabouts';
import { GhostButtonSmall, LinkButton } from 'src/view/components/button';
import {
  CampaignListHeader,
  CampaignListItem,
  List
} from 'src/view/components/list';
import {
  PusherProvider,
  useChannel,
  useEvent
} from 'src/view/components/pusher';
import { Body, Small } from 'src/view/components/text';
import withError from 'src/view/containers/with-error';
import LinkCampaignModal from 'src/view/modals/admin/campaign/link';

import CircleLink from 'src/assets/icons/circle-link.svg';
import CirclePlus from 'src/assets/icons/circle-plus.svg';
import { formattedBrandName } from 'src/theme';

const defaultStyles = StyleSheet({
  container: {
    height: '100%'
  },

  footer: {
    margin: ({ token }) => `0 -${token('spacing.m')} -${token('spacing.m')}`,
    padding: ({ token }) => token('spacing.xl'),
    background: ({ token }) => token('legacy.color.grey.light')
  },

  viewResources: {
    margin: ({ token }) => `0 ${token('spacing.xl')}`
  },

  campaignLink: {
    color: 'inherit',
    fontWeight: 'inherit',

    ':hover': {
      textDecoration: 'inherit'
    }
  }
});

const emptyStateStyles = {
  container: {
    background: ({ token }) => token('legacy.color.white'),
    borderTop: ({ token }) => `0.1rem solid ${token('legacy.color.grey.light')}`
  }
};

export const getToken = () =>
  new URLSearchParams(window.location.search).get('token');

const campaignsQuery = query`{
  ${campaignsModel} (o: "created_at.desc", campaignToken: ${getToken} ) {
    id
    name
    attributes {
      is_sold
    }
    status {
      id
      label
    }
    type {
      id
      label
    }
    stats {
      totals {
        impressions
        link_clicks
      }
    }
    active_package {
      id
      name
      duration
      price
      is_custom
      type {
        id
      }
    }
    duration {
      spent
      total
      unit
    }
    thumbnails
    account {
      id
    }
    vendor_report {
      url
    }
  }
}`;

function ListScreen({ campaigns, errorModal: { Error }, styles: s }) {
  const [showLinkModal, setShowLinkModal] = useState(false);
  const token = getToken();

  const listStatus = campaigns.list.status;
  const fetchCampaigns = () =>
    campaigns.fetchList({
      args: {
        campaignToken: 'ee9e0396-012d-11ea-bac8-0242ac1b0006',
        include: 'account,stats,active_package,thumbnails,vendor_report',
        o: 'created_at.desc'
      }
    });

  const channel = useChannel(`embed_token.${token}`);

  useEvent(
    channel,
    'campaign_draft_created',
    () => {
      if (listStatus === 'loading') return;
      fetchCampaigns();
    },
    [listStatus]
  );

  useEvent(
    channel,
    'campaign_link_created',
    () => {
      if (listStatus === 'loading') return;
      fetchCampaigns();
    },
    [listStatus]
  );

  useEvent(
    channel,
    'campaign_status_changed',
    () => {
      if (listStatus === 'loading') return;
      fetchCampaigns();
    },
    [listStatus]
  );

  useEvent(
    channel,
    'campaign_link_removed',
    () => {
      if (listStatus === 'loading') return;
      fetchCampaigns();
    },
    [listStatus]
  );

  return (
    <Box flexDirection='column' {...s('container')}>
      <Box alignItems='flex-end'>
        <Heading
          level={2}
          style={{
            marginBottom: '1rem',
            marginRight: token('spacing.xxxl')
          }}
        >
          Campaigns
        </Heading>
        <Link to={EMBED.CAMPAIGNS_INTRO} query={{ token }}>
          {({ onClick }) => (
            <GhostButtonSmall
              black={true}
              onClick={onClick}
              IconLeft={CirclePlus}
              iconLeftHeight='2.5rem'
            >
              Create new campaign
            </GhostButtonSmall>
          )}
        </Link>
        <GhostButtonSmall
          black={true}
          onClick={() => setShowLinkModal(true)}
          IconLeft={CircleLink}
          iconLeftHeight='2.5rem'
        >
          Link existing campaign
        </GhostButtonSmall>
      </Box>

      <Box flex='1' mt='2.4rem'>
        <List
          items={campaigns.list.items}
          isLoading={campaigns.list.status === 'loading'}
          Header={CampaignListHeader}
          Item={({ data: campaign }) => {
            const coreUrl =
              campaign?.status?.id === 'draft'
                ? 'campaign/create/listing'
                : 'campaign/details';

            return (
              <a
                href={`${domain()}/${campaign.account.id}/${coreUrl}/${
                  campaign.id
                }`}
                target='_blank'
                rel='noopener noreferrer'
                {...s('campaignLink')}
              >
                <CampaignListItem data={campaign} embed={true} />
              </a>
            );
          }}
          emptyStateProps={{
            styles: emptyStateStyles,
            minHeight: '12rem',
            smallText: (
              <div>
                <div>No campaigns found.</div>
                Ready to{' '}
                <Link to={EMBED.CAMPAIGNS_INTRO}>create a new campaign</Link>?
              </div>
            )
          }}
          endReached={campaigns?.list?.pagination?.endReached}
          isFetching={campaigns.list.status === 'fetching'}
          onLoadMore={() => campaigns.fetchMore()}
          autoLoadMore={false}
        />
      </Box>

      {campaigns.list.status === 'loaded' && !campaigns.list.items.length && (
        <Box
          alignItems='center'
          justifyContent='space-between'
          {...s('footer')}
        >
          <Box>
            <Body>Introduce {formattedBrandName} to your vendors</Body>
            <Small grey={true}>
              Resources to help you explain {formattedBrandName} and the value
              of digital marketing to your sellers, make you look good and get
              more VPA
            </Small>
          </Box>
          <Box {...s('viewResources')}>
            <LinkButton
              href='https://spokeapp.io/resources'
              target='_blank'
              style={{
                background: token('legacy.color.grey.default'),
                color: token('legacy.color.blue.grey')
              }}
            >
              View resources
            </LinkButton>
          </Box>
        </Box>
      )}

      {showLinkModal && (
        <LinkCampaignModal closeModal={() => setShowLinkModal(false)} />
      )}

      <Error />
    </Box>
  );
}

const ListScreenWrapper = compose(
  withError('errorModal'),
  styled(defaultStyles),
  withQuery(campaignsQuery)
)(ListScreen);

function Core(props) {
  return (
    <PusherProvider appID={config.PUSHER_KEY} cluster={config.PUSHER_CLUSTER}>
      <ListScreenWrapper {...props} />
    </PusherProvider>
  );
}

export default Core;

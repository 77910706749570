export const SQUARE_WIDTH = '25rem';
// 10% - 2px for border / 2 of SQUARE_WIDTH
export const SQUARE_PADDING = '1.24rem';

export const getImageStyle = (columns, hasFiles, isSquare) => {
  // Make sure as many columns as needed can fit
  const width = hasFiles ? 100 / columns - 2 : 100 / columns;
  return {
    width: `${width}%`,
    marginTop: '0.64rem',
    marginRight: hasFiles ? '0' : '0.64rem',
    marginBottom: '0.64rem',
    ...(isSquare
      ? {
          width: SQUARE_WIDTH,
          height: !hasFiles ? '90%' : SQUARE_WIDTH,
          marginTop: SQUARE_PADDING,
          marginRight: hasFiles ? '0' : SQUARE_PADDING,
          marginBottom: SQUARE_PADDING
        }
      : {})
  };
};

import _ from 'lodash';
import { createRestAPIModelGenerator } from 'src/data/models/generator';
import { api } from 'src/utils/api-client';

export const TYPE = 'provisions';

const config = {
  entities: {
    api: {
      fetchItem: (type, { accountId, ...args }, id) =>
        api.get(`/accounts/${accountId}/provisions/${id}`, args),
      updateItem: (type, { accountId, ...args }, id) =>
        api.patch(`/accounts/${accountId}/provisions/${id}`, args)
    }
  }
};

const actionCreators = {
  updateProvisionStep: {
    request: ({ accountId, provisionId, stepId, ...args }) =>
      api.patch(
        `/accounts/${accountId}/provisions/${provisionId}/steps/${stepId}`,
        args
      ),
    reduce: {
      initial: _.identity,
      success: _.identity,
      failure: _.identity
    }
  }
};

const ProvisionsGenerator = createRestAPIModelGenerator(TYPE, config);
export default ProvisionsGenerator.createEntityModel({ actionCreators });

import React, { ComponentType, useState } from 'react';
import { HOCWrapper } from 'src/types';
import { ErrorModal } from 'src/view/components/modal';

type DefaultErrorProp = 'error';

export type WithErrorReturn = {
  isOpen: boolean;
  open(message: any, title?: string): void;
  close(): void;
  Error: ComponentType;
};

export type WithErrorProps<N extends string = DefaultErrorProp> = {
  [K in N]: WithErrorReturn;
};

function withError<N extends string = DefaultErrorProp>(
  propName: N = 'error' as N
): HOCWrapper<WithErrorProps<N>> {
  return (WrappedComponent) => (props) => {
    const [error, setError] = useState({
      open: false,
      title: 'Oops!',
      message: 'It looks like something went wrong. Please try again!'
    });

    const close = (): void => setError((state) => ({ ...state, open: false }));
    const errorProps: WithErrorReturn = {
      isOpen: error.open,
      open: (message: string | Error, title = 'Oops!') => {
        if (__DEV__) {
          console.warn('open error modal', message, title);
        }
        setError({
          open: true,
          title,
          message: message instanceof Error ? message.message : message
        });
      },
      close,
      Error: () =>
        error.open ? (
          <ErrorModal title={error.title} onClose={close}>
            {error.message}
          </ErrorModal>
        ) : null
    };

    const newProps = {
      ...props,
      [propName]: errorProps
    };

    // @ts-expect-error TS cannot handle JSX prop merging
    return <WrappedComponent {...newProps} />;
  };
}

export default withError;

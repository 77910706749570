import React, { CSSProperties, ReactNode } from 'react';
import * as TooltipPrimitive from '@radix-ui/react-tooltip';
import { border, padding, StyleSheet, useStyles } from '@rexlabs/styling';

const styles = StyleSheet({
  arrow: {
    fill: 'white',
    stroke: ({ token }) => token('color.border.container.static.light')
  },

  content: {
    ...padding.styles({
      all: 's'
    }),
    boxShadow:
      '0 10px 15px -3px rgb(0 0 0 / 0.1), 0 4px 6px -4px rgb(0 0 0 / 0.1)',
    fontWeight: `300 !important`,
    fontSize: '1.4rem !important',
    lineHeight: '1.8rem !important',
    color: ({ token }) => `${token('color.textStyle.body.subtext')} !important`,
    maxWidth: '23rem !important',
    ...border.styles({
      all: {
        width: 'thin',
        color: 'container.static.light'
      }
    }),
    backgroundColor: ({ token }) => token('color.container.static.default')
  }
});

export type TooltipProps = TooltipPrimitive.TooltipProps &
  TooltipPrimitive.TooltipContentProps & {
    content?: ReactNode;
    className?: string;
    style?: CSSProperties;
  };

export const Tooltip = ({
  className,
  style,
  content,
  children,
  open,
  defaultOpen,
  onOpenChange,
  side = 'top',
  align = 'center',
  ...props
}: TooltipProps) => {
  const s = useStyles(styles);
  return (
    <TooltipPrimitive.Root
      open={open}
      defaultOpen={defaultOpen}
      onOpenChange={onOpenChange}
    >
      <TooltipPrimitive.Trigger asChild>{children}</TooltipPrimitive.Trigger>
      <TooltipPrimitive.Content
        side={side}
        align={align}
        {...props}
        {...s.with('content')({ className, style })}
      >
        {content}
        <TooltipPrimitive.Arrow
          offset={5}
          width={11}
          height={5}
          {...s('arrow')}
        />
      </TooltipPrimitive.Content>
    </TooltipPrimitive.Root>
  );
};

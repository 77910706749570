import { Box } from '@rexlabs/box';
import { withWhereaboutsFilter } from '@rexlabs/filters';
import { query, withQuery } from '@rexlabs/model-generator';
import { autobind } from 'core-decorators';
import _ from 'lodash';
import React, { PureComponent } from 'react';
import campaignsFilters from 'src/data/filters/campaigns';
import campaignsModel from 'src/data/models/entities/campaigns';
import ROUTES from 'src/routes/sysadmin';
import { withToken } from 'src/theme';
import BackLink from 'src/view/components/back-link';
import { SearchInput } from 'src/view/components/filter';
import { AccountCampaignListItem, List } from 'src/view/components/list';
import { Heading } from 'src/view/components/text';

function getQueryFilter(props) {
  const query = (props?.whereabouts?.query ?? {})['accounts.search'];
  return query ? `name.match(*${query}*)` : '';
}

const campaignsQuery = query`{
  ${campaignsModel} (q: ${getQueryFilter}, o: "created_at.desc") {
    id
    name
    status {
      id
      label
    }
    type {
      id
      label
    }
    thumbnails
    images
  }
}`;

@withToken
@withWhereaboutsFilter(campaignsFilters)
@withQuery(campaignsQuery)
@autobind
class AccountAdsList extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      search: (props?.whereabouts?.query ?? {})['accounts.search']
    };
  }

  debounceSetQueryFilter = _.debounce(this.setQueryFilter, 400);

  setQueryFilter(value) {
    const { filters } = this.props;
    filters.setFilters({ search: value });
  }

  render() {
    const { campaigns, token } = this.props;

    return (
      <Box flex={1} flexDirection='column'>
        <BackLink to={ROUTES.ACCOUNTS}>Back to accounts</BackLink>
        <Heading
          style={{
            marginTop: token('spacing.xl'),
            marginBottom: token('spacing.xl')
          }}
        >
          Ads
        </Heading>

        <Box mb={token('spacing.xl')} mt={token('spacing.xs')}>
          <SearchInput
            name='searchCampaign'
            placeholder='Search by name'
            onChange={({ target: { value } }) => {
              this.setState({ search: value });
              this.debounceSetQueryFilter(value);
            }}
            value={this.state.search}
            isLoading={campaigns.list.status === 'loading'}
          />
        </Box>

        <Box mb={token('spacing.xxxl')}>
          <List
            items={campaigns.list.items}
            isLoading={campaigns.list.status === 'loading'}
            getItemKey={(account) => account.id}
            // Header={AccountListHeader}
            Item={({ data: account }) => (
              <AccountCampaignListItem data={account} />
            )}
            endReached={campaigns?.list?.pagination?.endReached}
            isFetching={campaigns.list.status === 'fetching'}
            onLoadMore={() => campaigns.fetchMore()}
            autoLoadMore={false}
          />
        </Box>
      </Box>
    );
  }
}

export default AccountAdsList;

/*
|-------------------------------------------------------------------------------
| Singleton App Store (Redux)
|-------------------------------------------------------------------------------
|
| It's inclusion in other modules in the App should ideally come from dependency
| injection techniques to keep those files testable.
|
*/

import { combineModels } from '@rexlabs/model-generator';
import { toastsReducer as toasts } from '@rexlabs/toast';
import {
  whereaboutsMiddleware,
  whereaboutsReducer as whereabouts
} from '@rexlabs/whereabouts';
import localForage from 'localforage';
import _debounce from 'lodash/debounce';
import { combineReducers } from 'redux';
import { batchedSubscribe } from 'redux-batched-subscribe';
import { persistReducer, persistStore } from 'redux-persist';
import { connectionReducer as connection } from 'src/utils/connection-status';
import { apiClientMiddleware, configureStore } from 'src/utils/redux';

/*
| Core Models
|------------------------
*/
import session from 'src/data/models/custom/session';
import setup from 'src/data/models/custom/setup';
import uploads from 'src/data/models/custom/uploads';
import wizard from 'src/data/models/custom/wizard';

/*
 | Entity Models (Model Generator)
 |------------------------
 */
import accountPreferences from 'src/data/models/entities/account-preferences';
import ads from 'src/data/models/entities/ads';
import agencies from 'src/data/models/entities/agencies';
import agents from 'src/data/models/entities/agents';
import billingDetails from 'src/data/models/entities/billing-details';
import campaignTemplates from 'src/data/models/entities/campaign-templates';
import campaigns from 'src/data/models/entities/campaigns';
import customAudiences from 'src/data/models/entities/custom-audiences';
import customPackages from 'src/data/models/entities/custom-packages';
import facebook from 'src/data/models/entities/facebook';
import facebookActivity from 'src/data/models/entities/facebook-activity';
import google from 'src/data/models/entities/google';
import images from 'src/data/models/entities/images';
import invitations from 'src/data/models/entities/invitations';
import listings from 'src/data/models/entities/listings';
import notificationSubscriptions from 'src/data/models/entities/notification-subscriptions';
import packageEstimates from 'src/data/models/entities/package-estimates';
import packages from 'src/data/models/entities/packages';
import paymentOptions from 'src/data/models/entities/payment-options';
import purchases from 'src/data/models/entities/purchases';
import rex from 'src/data/models/entities/rex';
import rexSync from 'src/data/models/entities/rex-sync';
import stats from 'src/data/models/entities/stats';
import users from 'src/data/models/entities/users';
import vendorReports from 'src/data/models/entities/vendor-reports';

import audienceSegments from 'src/data/models/value-lists/audience-segments';
import campaignGoals from 'src/data/models/value-lists/campaign-goals';
import feedbackTypes from 'src/data/models/value-lists/feedback-types';
import languages from 'src/data/models/value-lists/languages';
import australianStates from 'src/data/models/value-lists/locale/australian-states';
import countries from 'src/data/models/value-lists/locale/countries';
import newZealandRegions from 'src/data/models/value-lists/locale/new-zealand-regions';
import networkAdTypes from 'src/data/models/value-lists/network-ad-types';
import networks from 'src/data/models/value-lists/networks';
import timezones from 'src/data/models/value-lists/timezones';
import vendorReportSections from 'src/data/models/value-lists/vendor-report-sections';

import accounts from 'src/data/models/sysadmin/accounts';
import campaignPerformance from 'src/data/models/sysadmin/campaign-performance';
import provisionSteps from 'src/data/models/sysadmin/provision-steps';
import provisions from 'src/data/models/sysadmin/provisions';

/*
| Setup for Stores
|------------------------
*/
const entities = combineModels('entities', {
  listings,
  campaigns,
  campaignTemplates,
  agencies,
  agents,
  users,
  paymentOptions,
  billingDetails,
  purchases,
  packages,
  customPackages,
  packageEstimates,
  ads,
  images,
  facebook,
  facebookActivity,
  google,
  stats,
  invitations,
  rex,
  rexSync,
  vendorReports,
  accountPreferences,
  customAudiences,
  notificationSubscriptions,

  // Sysadmin
  accounts,
  provisions,
  provisionSteps,
  campaignPerformance
});

const valueLists = combineModels('valueLists', {
  audienceSegments,
  networks,
  languages,
  timezones,
  countries,
  australianStates,
  newZealandRegions,
  vendorReportSections,
  feedbackTypes,
  campaignGoals,
  networkAdTypes
});

// Persistors
const persistSession = persistReducer(
  {
    key: 'spoke.session',
    storage: localForage,
    blacklist: ['ready', 'accounts', 'status', 'maintenanceMode'],
    timeout: 10000
  },
  session
);

const persistWizard = persistReducer(
  {
    key: 'spoke.wizard',
    storage: localForage,
    timeout: 10000
  },
  wizard
);

// Combining all reducers
const reducers = combineReducers({
  connection,
  whereabouts,
  entities,
  valueLists,
  toasts,
  session: persistSession,
  wizard: persistWizard,
  setup,
  uploads
});

/**
 * Add an Entity reset action to our reducers.
 * This was added due to a bug when switching accounts where the
 * campaigns list would get stuck in a loading state. Calling this
 * action ensures all entities are reset.
 */
const rootReducer = (state, action) => {
  if (action.type === 'ENTITY_RESET') {
    state.entities = undefined;
  }

  return reducers(state, action);
};

const store = configureStore(
  rootReducer,
  [apiClientMiddleware, whereaboutsMiddleware],
  [batchedSubscribe(_debounce((notify) => notify(), 10, { leading: true }))]
);

const persistedStore = persistStore(store, null, () => {
  store.dispatch(session.actionCreators.init()).catch(console.warn);
});

if (__DEV__) {
  window.purgePersistedStore = persistedStore.purge;
}

export { store };

import React from 'react';
import Box from '@rexlabs/box';
import { styled, StyleSheet } from '@rexlabs/styling';
import charlimit, { limits } from '../../../utils/char-limit';
import getContrastColor from '../../../utils/contrast-color';
import { COLORS } from 'src/theme';
import { useFeature } from '../../../hooks';
import { injectGlobal } from 'styled-components';
import { fontFace } from 'utils/styling';
import config from 'config';
import { getBannerLabel } from './utils';

import Bed from '../../../assets/bed.svg';
import Bath from '../../../assets/bath.svg';
import Car from '../../../assets/car.svg';

const NATIVE_AD_WIDTH = 1125;
const NATIVE_AD_HEIGHT = NATIVE_AD_WIDTH;

injectGlobal`
  ${fontFace('Gordita', `${config.API_URL}/assets/gordita-regular.woff2`)}
`;

const defaultAdImageStyles = StyleSheet({
  container: {
    width: `${NATIVE_AD_WIDTH}px`,
    height: `${NATIVE_AD_HEIGHT}px`,
    fontFamily: 'Circular Pro, Helvetica, Arial, sans-serif'
  },

  backgroundImage: {
    height: '875px',
    background: 'none no-repeat top center',
    backgroundColor: COLORS.GREY_LIGHT,
    backgroundSize: 'cover',
    overflow: 'hidden',
    position: 'relative',
    '@media print': {
      '-webkit-print-color-adjust': 'exact'
    }
  },

  footer: {
    position: 'relative',
    height: '250px',
    paddingLeft: '44px',
    paddingRight: '44px',
    '@media print': {
      '-webkit-print-color-adjust': 'exact'
    }
  },

  contentWrapper: {
    padding: '58px 44px 44px 0'
  },

  smallText: {
    fontSize: '48px',
    fontWeight: '800',
    lineHeight: '60px',
    marginBottom: '12px'
  },

  listing: {
    fontSize: '45px',
    fontWeight: '800',
    lineHeight: '60px'
  },

  listing2: {
    fontWeight: '300'
  },

  bedBathCar: {
    opacity: 0.8,
    marginRight: '48px',
    '& svg': {
      marginRight: '25px'
    }
  },

  pipe: {
    whiteSpace: 'pre'
  },

  agentWrapper: {
    position: 'absolute',
    bottom: '44px',
    right: '44px'
  },

  agentImage: {
    width: '280px',
    height: '280px',
    objectFit: 'cover',
    borderRadius: '50%'
  },

  logoWrapper: {
    minWidth: '240px',
    maxWidth: '355px',
    textAlign: 'center',
    padding: '58px 0 58px 44px'
  },

  logo: {
    width: '100%',
    height: '100%',
    objectFit: 'contain'
  },

  banner: {
    position: 'absolute',
    top: '44px',
    left: '44px',
    fontSize: '32px',
    fontWeight: '900',
    fontFamily: 'Circular Pro, Helvetica, Arial, sans-serif',
    textTransform: 'uppercase',
    padding: '20px 48px'
  },

  gorditaFont: {
    fontFamily: 'Gordita, Circular Pro, Helvetica, Arial, sans-serif'
  }
});

const AdImage = ({
  styles: s,
  slide,
  adContentSet,
  scaleTo = 1125,
  scalePos = 'left top'
}) => {
  const style2 = useFeature('facebook_just_sold_style2', adContentSet.session);
  const mirage = useFeature('mirage_image_urls', adContentSet.session);
  const { beds, baths, cars } = slide;
  const image = adContentSet.images.find((i) => i.id === slide?.image?.id);
  const crop = Object.values(image?.crops?.square?.top_left || {}).join();
  const imageUrl =
    image?.sizes?.['crop.square']?.url + (!mirage ? `&key=${crop}` : '');
  const agencySlide = adContentSet.slides.find((s) => s.type === 'agency');
  const agencyLogoUrl = adContentSet.images.find(
    (i) => i.id === agencySlide?.image?.id
  )?.sizes?.['original.thumbnail']?.url;
  const agentSlide = adContentSet.slides.find((s) => s.type === 'agent');
  const agentImageUrl = adContentSet.images.find(
    (i) => i.id === agentSlide?.image?.id
  )?.sizes?.['crop.square']?.url;
  const { backgroundColor, brandColor } = adContentSet;
  const bannerLabel = getBannerLabel(adContentSet.session);

  const BottomBannerStyle1 = () => {
    return (
      <Box
        flexDirection='row'
        justifyContent='space-between'
        {...s.with('footer')({
          color: getContrastColor(backgroundColor),
          background: backgroundColor
        })}
      >
        <Box {...s('contentWrapper')}>
          <div {...s('smallText')}>
            {charlimit(slide.small_text, limits.SMALL_TEXT)}
          </div>
          <Box flexDirection='row' {...s('listing')}>
            {!!beds && (
              <Box {...s('bedBathCar')}>
                <Bed /> {beds}
              </Box>
            )}
            {!!baths && (
              <Box {...s('bedBathCar')}>
                <Bath /> {baths}
              </Box>
            )}
            {!!cars && (
              <Box {...s('bedBathCar')}>
                <Car /> {cars}
              </Box>
            )}
          </Box>
        </Box>

        {agentImageUrl ? (
          <Box {...s('agentWrapper')}>
            <img src={agentImageUrl} {...s('agentImage')} />
          </Box>
        ) : (
          <Box {...s('logoWrapper')}>
            <img src={agencyLogoUrl} {...s('logo')} />
          </Box>
        )}
      </Box>
    );
  };

  const BottomBannerStyle2 = () => {
    return (
      <Box
        flexDirection='row'
        justifyContent='space-between'
        {...s.with('footer')({
          color: getContrastColor(adContentSet.backgroundColor),
          background: adContentSet.backgroundColor
        })}
      >
        <Box {...s('contentWrapper')}>
          <div {...s('smallText')}>
            {charlimit(slide.small_text, limits.LONG_HEADLINE)}
          </div>
          <Box flexDirection='row' {...s('listing', 'listing2')}>
            {!!beds && <Box>{beds} Bed</Box>}
            {!!beds && (!!baths || !!cars) && <span {...s('pipe')}> | </span>}
            {!!baths && <Box>{baths} Bath</Box>}
            {!!baths && !!cars && <span {...s('pipe')}> | </span>}
            {!!cars && <Box>{cars} Car</Box>}
          </Box>
        </Box>
      </Box>
    );
  };

  return (
    <Box
      {...s.with('container', { gorditaFont: style2 })({
        transformOrigin: `${scalePos}`,
        transform: `scale(${scaleTo / NATIVE_AD_HEIGHT})`
      })}
    >
      <div
        key={crop}
        {...s.with('backgroundImage')({
          backgroundImage: imageUrl ? `url(${imageUrl})` : 'none'
        })}
      >
        {!style2 && (
          <div
            {...s.with('banner')({
              backgroundColor: brandColor,
              color: getContrastColor(brandColor)
            })}
          >
            {bannerLabel}
          </div>
        )}
      </div>
      {style2 ? <BottomBannerStyle2 /> : <BottomBannerStyle1 />}
    </Box>
  );
};

export default styled(defaultAdImageStyles)(AdImage);

import { Box } from '@rexlabs/box';
import { StyleSheet, useStyles } from '@rexlabs/styling';
import React from 'react';

const defaultStyles = StyleSheet({
  animationWrapper: {
    display: 'flex',
    flex: '1'
  }
});

function StepsContainer({ children }) {
  const s = useStyles(defaultStyles);
  return (
    <div {...s('animationWrapper')}>
      <Box
        flexDirection='row'
        flex={1}
        alignItems='center'
        justifyContent='center'
      >
        {children}
      </Box>
    </div>
  );
}

export default StepsContainer;

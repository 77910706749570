import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { styled, StyleSheet } from '@rexlabs/styling';
import Box from '@rexlabs/box';
import _ from 'lodash';
import colorTheme from '../../../utils/color-theme';
import Arrow from '../../../assets/arrow.svg';
import ScaledText from '../../scaled-text';
import { COLORS } from 'src/theme';

const fontFamily = 'Lato, sans-serif';
const bannerStyles = StyleSheet({
  banner: {
    border: 'solid 1px darkgray',
    overflow: 'hidden',
    flexGrow: 0,
    flexShrink: 0,
    backgroundColor: 'white'
  },
  shortHeadline: {
    fontFamily,
    fontSize: '20px',
    fontWeight: '300',
    lineHeight: '23px',
    marginLeft: '20px'
  },
  logoWrapper: {},
  logo: {
    objectFit: 'contain',
    height: '100%',
    width: '100%'
  },
  arrow: {
    margin: '20px 25px'
  }
});

@styled(bannerStyles)
class Banner extends PureComponent {
  static propTypes = {
    shortHeadline: PropTypes.string,
    logoUrls: PropTypes.object,
    brandColor: PropTypes.string
  };

  render() {
    const {
      shortHeadline,
      logoUrls,
      brandColor,
      backgroundColor,
      styles: s
    } = this.props;
    const { brandColorBorder } = colorTheme(brandColor);
    return (
      <Box width={468} height={60} alignItems='center' {...s('banner')}>
        <Box
          padding='9px 13px 8px 14px'
          width={171}
          height='100%'
          flexDirection='column'
          alignItems='center'
          justifyContent='center'
          {...s.with('logoWrapper')({
            backgroundColor: backgroundColor,
            borderRight: brandColorBorder
          })}
        >
          <img src={_.get(logoUrls, 'original.url')} {...s('logo')} />
        </Box>
        <Box justifyContent='space-between' alignItems='center'>
          <ScaledText
            maxWidth={220}
            maxTextLines={2}
            maxFontSize={20}
            minFontSize={14}
            {...s.with('shortHeadline')({
              color: COLORS.BLACK
            })}
          >
            {shortHeadline}
          </ScaledText>
          <Arrow width={20} height={20} {...s('arrow')} />
        </Box>
      </Box>
    );
  }
}

export default Banner;

import { styled, StyleSheet } from '@rexlabs/styling';
import React, { PureComponent } from 'react';
import { Small } from 'src/view/components/text';

const defaultStyles = StyleSheet({
  container: {
    display: 'flex-column',
    background: ({ token }) => token('legacy.color.white'),
    padding: ({ token }) => token('spacing.m'),
    boxShadow: '0 2px 10px rgba(0,0,0,0.1)'
  },
  text: {
    color: ({ token }) => token('legacy.color.blue.grey')
  },
  label: {
    marginBottom: '10px'
  }
});

@styled(defaultStyles)
class TooltipContent extends PureComponent {
  render() {
    const { active, styles: s } = this.props;

    if (active) {
      const { payload, label, lines, formatX } = this.props;
      return (
        <div {...s('container')}>
          <div {...s('label')}>{formatX ? formatX(label) : label}</div>
          {payload ? (
            payload.map((value) => {
              const line = lines.find((l) => l.dataKey === value.name) || {};
              return (
                <div key={value.name}>
                  <svg
                    width='18'
                    height='20'
                    viewBox='0 0 120 120'
                    xmlns='http://www.w3.org/2000/svg'
                  >
                    <line
                      x1='20'
                      y1='100'
                      x2='100'
                      y2='100'
                      strokeWidth='15'
                      stroke={line.color}
                    />
                  </svg>
                  <span {...s('text')}>
                    {line.format
                      ? line.format(value.payload[value.name])
                      : value.payload[value.name]}{' '}
                    {line.label}
                  </span>
                </div>
              );
            })
          ) : (
            <Small grey>No data found</Small>
          )}
        </div>
      );
    }
    return null;
  }
}

export default TooltipContent;

import _ from 'lodash';
import { createRestAPIModelGenerator } from 'src/data/models/generator';
import { api } from 'src/utils/api-client';

export const TYPE = 'provisionSteps';

const config = {
  entities: {
    api: {
      fetchList: (type, { accountId, provisionId, ...args }) =>
        api.get(`/accounts/${accountId}/provisions/${provisionId}/steps`, args)
    },
    related: {
      provision: {
        include: 'provision'
      }
    }
  }
};

const actionCreators = {
  updateProvisionStep: {
    request: ({ accountId, provisionId, stepId, ...args }) =>
      api.patch(
        `/accounts/${accountId}/provisions/${provisionId}/steps/${stepId}`,
        _.pickBy(args)
      ),
    reduce: {
      initial: _.identity,
      success: _.identity,
      failure: _.identity
    }
  }
};

const ProvisionsGenerator = createRestAPIModelGenerator(TYPE, config);
export default ProvisionsGenerator.createEntityModel({ actionCreators });

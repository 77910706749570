import { createRestAPIModelGenerator } from 'src/data/models/generator';
import { api } from 'src/utils/api-client';

export const TYPE = 'accountPreferences';

const config = {
  entities: {
    api: {
      fetchItem: (type, args, id) =>
        api.get('/account/preferences').then((response) => {
          return { data: { ...response.data, id } };
        }),
      updateItem: (type, args) => api.patch('/account/preferences', args)
    }
  }
};

const BillingDetailsGenerator = createRestAPIModelGenerator(TYPE, config);
export default BillingDetailsGenerator.createEntityModel();

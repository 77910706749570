import { GhostButton, LinkButton, PrimaryButton } from '@rexlabs/button';
import LoadingSpinner from '@rexlabs/loading-spinner';
import { StyleSheet, margin, useStyles } from '@rexlabs/styling';
import { Heading, Small } from '@rexlabs/text';
import React, { FC, useCallback } from 'react';
import { ContentTypeSelectorProps } from 'src/features/wizard';
import EmptyStateSection from 'src/view/components/empty-state-section';
import { Link } from 'react-router-dom';
import {useCatalogListingSets} from "src/features/catalog-listing-sets/api/getCatalogListingSets";
import {CatalogListingSetList, ItemProps} from "src/features/catalog-listing-sets/components/CatalogListingSetList";
import {CatalogListingSetItem} from "src/features/catalog-listing-sets/components/CatalogListingSetItem";

const styles = StyleSheet({
    container: {
        display: 'flex',
        flexDirection: 'column',
        minHeight: '0',
        width: '100%',

        '& > * + *': {
            ...margin.styles({
                top: 's'
            })
        }
    },

    toolbar: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        '& > * + *': {
            ...margin.styles({
                left: 'xs'
            })
        }
    },

    action: {
        minWidth: 0,
        fontSize: ({ token }) => token('typography.size.m'),
        fontWeight: ({ token }) => token('typography.weight.bold')
    },

    seperator: {
        color: ({ token }) => token('color.textStyle.body.subtext')
    },

    delete: {
        color: ({ token }) => token('color.textStyle.danger.idle.default'),

        ':hover': {
            color: ({ token }) => token('color.textStyle.danger.hover.default')
        }
    },

    loading: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center'
    },

    listContainer: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        flex: '1 1 auto',
        minHeight: '0'
    },

    list: {
        width: '100%'
    }
});

export const CatalogListingSetContentTypeSelector: FC<ContentTypeSelectorProps> = ({ selectedIds, onSelection }) => {
    const s = useStyles(styles, 'CatalogListingSetContentTypeSelector');

    const {
        data: catalogListingSets,
        isLoading,
        isError,
        hasNextPage,
        fetchNextPage,
        isFetchingNextPage,
        refetch
    } = useCatalogListingSets();

    const Item = useCallback<FC<ItemProps>>(
        ({ item, ...rest }) => {
            return (
                <CatalogListingSetItem item={item} {...rest} />
            );
        },
        [s]
    );

    const items = catalogListingSets?.pages.flatMap((r) => r.data);
    const totalItems = catalogListingSets?.pages[0].pagination.total ?? 0;

    return (
        <div {...s('container')}>
            <div {...s('toolbar')}>
                <Heading as={(p) => <h2 id='testimonial_header' {...p} />} level={2}>
                    Catalogues
                </Heading>
                <Link {...s('hideUnderline')} to='create'>
                    <PrimaryButton data-testid={'create-listing-button'}>
                        Create new catalogue
                    </PrimaryButton>
                </Link>
            </div>
            {isLoading ? (
                <div {...s('loading')}>
                    <LoadingSpinner />
                    <Small grey>Loading...</Small>
                </div>
            ) : isError ? (
                <EmptyStateSection>
                    Unable to fetch your listing sets.
                    <LinkButton {...s('refetch')} onClick={() => refetch()}>
                        Try again
                    </LinkButton>
                </EmptyStateSection>
            ) : items?.length === 0 ? (
                <EmptyStateSection>
                  <span>{"You don’t have any catalogues setup yet"}</span>
                  <span>{"To get started, "}</span>
                  <Link {...s('hideUnderline')} to='create'>create a new catalogue</Link>
                </EmptyStateSection>
            ) : items ? (
                <div {...s('listContainer')}>
                    <CatalogListingSetList
                        {...s('list')}
                        Item={Item}
                        items={items}
                        totalItems={totalItems}
                        selected={selectedIds}
                        onSelection={onSelection}
                    />
                    {hasNextPage ? (
                        <GhostButton
                            onClick={() => fetchNextPage()}
                            isLoading={isFetchingNextPage}
                        >
                            Load More
                        </GhostButton>
                    ) : null}
                </div>
            ) : null}
        </div>
    );
};

import type { RexApiError } from '@rexlabs/api-client';
import {
  DefaultOptions,
  QueryClient,
  UseInfiniteQueryOptions,
  UseInfiniteQueryResult,
  UseMutationResult,
  UseQueryOptions,
  UseQueryResult
} from 'react-query';
import { PaginatedResponse } from 'src/types';
import type { AsyncReturnType } from 'type-fest';
import { AsyncFunction } from 'type-fest/source/async-return-type';

const queryConfig: DefaultOptions = {
  queries: {
    useErrorBoundary: true,
    refetchOnWindowFocus: false,
    retry: false
  }
};

export const queryClient = new QueryClient({ defaultOptions: queryConfig });

export type QueryConfig<
  QueryFnType extends AsyncFunction,
  TData = AsyncReturnType<QueryFnType>
> = Omit<
  UseQueryOptions<AsyncReturnType<QueryFnType>, RexApiError, TData>,
  'queryKey' | 'queryFn'
>;

export type QueryResult<T> = UseQueryResult<T, RexApiError>;

export type InfiniteQueryConfig<
  QueryFnType extends AsyncFunction,
  TData = AsyncReturnType<QueryFnType>
> = Omit<
  UseInfiniteQueryOptions<AsyncReturnType<QueryFnType>, Error, TData>,
  'queryKey' | 'queryFn' | 'getNextPageParams' | 'getPreviousPageParam'
>;

export type InfiniteQueryResult<T> = UseInfiniteQueryResult<
  PaginatedResponse<T>,
  RexApiError
>;

export type MutationResult<
  TData,
  TVariables = unknown,
  TContext = unknown
> = UseMutationResult<TData, RexApiError, TVariables, TContext>;

import React from 'react';
import { StylesProvider } from '@rexlabs/styling';
import { PrimaryButton } from '@rexlabs/button';

const variants = {
  crmBlue: ({ token }) => token('legacy.color.integration.crm'),
  facebookBlue: ({ token }) => token('legacy.color.integration.facebook'),
  yellow: ({ token }) => token('legacy.color.integration.siteloft')
};

function PrimaryButtonVariant(props) {
  const { variant, ...restProps } = props;
  const color = variants[variant];
  const tokens = {
    button: {
      primary: {
        background: { color },

        border: { color },

        hover: {
          background: { color },
          border: { color }
        },

        active: {
          background: { color },
          border: { color }
        }
      }
    }
  };

  return (
    <StylesProvider tokens={tokens}>
      <PrimaryButton {...restProps} />
    </StylesProvider>
  );
}

export default PrimaryButtonVariant;

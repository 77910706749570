import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { styled, StyleSheet } from '@rexlabs/styling';
import { autobind } from 'core-decorators';
import Box from '@rexlabs/box';

import _ from 'lodash';
import colorTheme from '../../../utils/color-theme';
import { SmallButton, LargeButton } from '../../button';
import ScaledText from '../../scaled-text';
import charLimit, { limits } from '../../../utils/char-limit';
import { COLORS } from 'src/theme';

export const types = {
  DEFAULT: 'default',
  LARGE: 'large',
  SMALL: 'small'
};

const fontFamily = 'Lato, sans-serif';
const defaultStyles = StyleSheet({
  leaderboard: {
    border: '1px solid darkgray',
    overflow: 'hidden',
    display: 'flex'
  },
  primaryImage: {
    objectFit: 'cover'
  },
  main: {
    backgroundColor: 'white',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: '0 20px',
    flex: 1
  },
  content: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'flex-start'
  },
  smallText: {
    fontFamily,
    fontSize: '12px',
    fontWeight: 600,
    lineHeight: '15px',
    textTransform: 'uppercase'
  },
  shortHeadline: {
    fontFamily,
    fontWeight: 400
  },
  logoWrapper: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center'
  },
  logo: {
    width: '100%',
    height: '100%',
    objectFit: 'contain'
  }
});

@styled(defaultStyles)
@autobind
export default class Leaderboard extends PureComponent {
  static propTypes = {
    smallText: PropTypes.string,
    shortHeadline: PropTypes.string,
    butonText: PropTypes.string,
    brandColor: PropTypes.string,
    backgroundColor: PropTypes.string,
    primaryImageUrls: PropTypes.object,
    logoUrls: PropTypes.object,
    type: PropTypes.oneOf(Object.values(types))
  };

  static defaultProps = {
    buttonText: 'View Property',
    type: types.DEFAULT
  };

  button() {
    const { type, brandColor, buttonText } = this.props;
    switch (type) {
      case types.LARGE:
        return (
          <Box width={134}>
            <LargeButton brandColor={brandColor}>{buttonText}</LargeButton>
          </Box>
        );
      case types.SMALL:
      default:
        return (
          <Box width={113}>
            <SmallButton brandColor={brandColor}>{buttonText}</SmallButton>
          </Box>
        );
    }
  }

  leaderboardDimensions() {
    const { type } = this.props;
    switch (type) {
      case types.LARGE:
        return { width: '970px', height: '90px' };
      case types.DEFAULT:
      default:
        return { width: '728px', height: '90px' };
    }
  }

  primaryImageDimensions() {
    const { type } = this.props;
    switch (type) {
      case types.LARGE:
        return { width: '229px', height: '90px' };
      case types.DEFAULT:
      default:
        return { width: '205px', height: '90px' };
    }
  }

  logoWrapperDimensions() {
    const { type } = this.props;
    switch (type) {
      case types.LARGE:
        return { width: '190px', height: '90px' };
      case types.DEFAULT:
      default:
        return { width: '142px', height: '90px' };
    }
  }

  logoDimensions() {
    const { type } = this.props;
    return {
      maxWidth: `calc(0.6 * ${type === types.LARGE ? '190px' : '142px'})`,
      maxHeight: 'calc(0.7 * 90px)'
    };
  }

  shortHeadlineStyles() {
    const { type } = this.props;
    switch (type) {
      case types.LARGE:
        return {
          fontSize: '30px',
          lineHeight: '38px'
        };
      case types.DEFAUL:
      default:
        return {
          fontSize: '20px',
          lineHeight: '23px'
        };
    }
  }

  render() {
    const {
      shortHeadline,
      smallText,
      primaryImageUrls,
      logoUrls,
      backgroundColor,
      brandColor,
      type,
      styles: s
    } = this.props;

    const { brandColorBorder } = colorTheme(brandColor);
    const isLarge = type === types.LARGE;

    return (
      <Box {...s.with('leaderboard')({ ...this.leaderboardDimensions() })}>
        <img
          {...s.with('primaryImage')({
            ...this.primaryImageDimensions()
          })}
          src={_.get(primaryImageUrls, ['crop.landscape', 'url'])}
        />
        <Box {...s('main')}>
          <Box {...s('content')}>
            <span {...s.with('smallText')({ color: COLORS.BLACK })}>
              {charLimit(smallText, limits.SMALL_TEXT)}
            </span>
            <ScaledText
              maxWidth={isLarge ? 348 : 216}
              maxTextLines={2}
              maxFontSize={isLarge ? 32 : 22}
              minFontSize={isLarge ? 16 : 12}
              {...s.with('shortHeadline')({
                color: COLORS.BLACK,
                ...this.shortHeadlineStyles()
              })}
            >
              {shortHeadline}
            </ScaledText>
          </Box>
          {this.button()}
        </Box>
        <Box
          {...s.with('logoWrapper')({
            ...this.logoWrapperDimensions(),
            backgroundColor: backgroundColor,
            borderLeft: brandColorBorder
          })}
        >
          <img
            src={_.get(logoUrls, 'original.url')}
            {...s.with('logo')({ ...this.logoDimensions() })}
          />
        </Box>
      </Box>
    );
  }
}

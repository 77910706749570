import { Box } from '@rexlabs/box';
import { StyleSheet } from '@rexlabs/styling';
import React, { Component } from 'react';
import { Small } from 'src/view/components/text';
import { HelpIcon } from 'src/view/components/tooltip';

const iconStyles = StyleSheet({
  icon: {
    height: '1.4rem',
    width: '1.4rem'
  }
});

class Caption extends Component {
  render() {
    const { captionText, TooltipContent } = this.props;

    return (
      <Box alignItems='center'>
        <Small grey>{captionText}</Small>
        {TooltipContent && (
          <HelpIcon styles={iconStyles} Content={TooltipContent} />
        )}
      </Box>
    );
  }
}

export default Caption;

import React, { FC, useCallback } from 'react';
import { useLoaderData, useNavigate } from 'react-router-dom';
import { useToast } from '@rexlabs/notifications';
import { StepEditorLayout } from 'src/features/wizard/components/StepEditorLayout';
import { ContentTypeEditorProps } from 'src/features/wizard';
import { useUpdateCommercialListing } from 'src/features/listings/api/updateCommercialListing';
import { CommercialListing } from 'src/features/listings/types';
import {
  parseInitialData,
  parsePostData
} from 'src/features/listings/utils/helpers';
import {
  CommercialListingForm,
  CommercialListingSubmitHandler
} from './CommercialListingForm';
import { CommercialFormGuide } from './CommercialFormGuide';

export const CommercialListingEditForm: FC<ContentTypeEditorProps> = ({
  selectContent,
  contentId
}) => {
  const navigate = useNavigate();
  const toasts = useToast();

  const resource = useLoaderData() as CommercialListing;

  const { mutateAsync: updateCommercialListing } = useUpdateCommercialListing();

  const handleCreate = useCallback<CommercialListingSubmitHandler>(
    async (data) => {
      if (contentId) {
        try {
          await updateCommercialListing({
            listingId: contentId,
            listing: parsePostData(data)
          }).then((res) => {
            selectContent?.(res.id);
            navigate('../CommercialListing');
          });
        } catch (e) {
          toasts.addToast({
            type: 'error',
            title: "Couldn't create listing",
            description: e instanceof Error ? e.message : undefined
          });
        }
      }
    },
    [updateCommercialListing, toasts, navigate]
  );

  const exitEditor = useCallback(() => navigate('..'), [navigate]);

  return (
    <StepEditorLayout
      title='Update Commercial Listing'
      Preview={<CommercialFormGuide />}
    >
      <CommercialListingForm
        submitLabel='Update'
        resource={parseInitialData(resource)}
        onSubmit={handleCreate}
        onCancel={exitEditor}
      />
    </StepEditorLayout>
  );
};

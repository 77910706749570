import { Box } from '@rexlabs/box';
import { GhostButton, PrimaryButton } from '@rexlabs/button';
import { query, withModel, withQuery } from '@rexlabs/model-generator';
import map from 'lodash/map';
import React, { useEffect, useRef, useState } from 'react';
import { compose } from 'redux';
import { useFeature } from 'shared/hooks';
import sessionModel from 'src/data/models/custom/session';
import wizardModel from 'src/data/models/custom/wizard';
import campaignsModel from 'src/data/models/entities/campaigns';
import imagesModel from 'src/data/models/entities/images';
import { createValidationRules } from 'src/utils/form';
import { ButtonBar } from 'src/view/components/button';
import { Form, FormField, ReactForms } from 'src/view/components/form';
import HiddenField from 'src/view/components/form/hidden-field';
import { TextInput } from 'src/view/components/input';
import ImageUpload from 'src/view/components/input/file-upload/image-upload';
import VideoInput from 'src/view/components/input/video/upload-video';
import { RenderLoading } from 'src/view/components/loading';
import { NewTag } from 'src/view/components/new-tag';
import ScrollableContent from 'src/view/components/scrollable-content';
import { Body, Heading, Tiny } from 'src/view/components/text';
import { HelpIcon } from 'src/view/components/tooltip';
import withError from 'src/view/containers/with-error';

import { useToken } from '@rexlabs/styling';
import ChevronLeft from 'src/assets/icons/chevron-left.svg';
import ChevronRight from 'src/assets/icons/chevron-right.svg';

const getCampaignId = (props) => props?.match?.params?.campaignId;

const queryCampaign = query`{
  ${campaignsModel} (id: ${getCampaignId}) {
    id
    ad_content_sets {
      id
      attributes
      final_url
      type {
        id
      }
    }
    images
    videos
  }
}`;

const validate = createValidationRules({
  final_url: 'required|url'
});

const AppraisalDetailsScreen = (props) => {
  const token = useToken();

  const videoOnlyAdsEnabled = useFeature(
    'video_only_appraisal_campaigns',
    props.session
  );
  const hasSetInitialImages = useRef(false);
  const [selectedImages, setSelectedImages] = useState([]);

  const {
    campaigns,
    errorModal: { Error },
    wizard: { setStep }
  } = props;

  const status = campaigns?.item?.status;
  const initialImages = campaigns?.item?.data?.images ?? [];

  const handleSelectImage = (selectedImages) => {
    setSelectedImages(selectedImages);
  };

  const getInitialValues = () => {
    const sets = campaigns?.item?.data?.ad_content_sets?.items ?? [];
    const prospecting = sets.find(
      (i) => i?.type?.id === 'appraisal.prospecting'
    );
    const prospectingVideoId = prospecting?.attributes?.video?.id;
    return {
      final_url: prospecting?.final_url,
      images: [],
      selected_images: [],
      video: (campaigns?.item?.data?.videos ?? []).find(
        (video) => video.id === prospectingVideoId
      )
    };
  };

  const handleSubmit = async (values) => {
    const { errorModal, match } = props;

    const sets = campaigns?.item?.data?.ad_content_sets?.items ?? [];
    const prospecting = sets.find((i) => i.type.id === 'appraisal.prospecting');
    const retargeting = sets.find((i) => i.type.id === 'appraisal.retargeting');
    const initialImages = campaigns?.item?.data?.images ?? [];

    const images = map(values?.images ?? [], (image) => ({
      id: image.id,
      name: image.name
    }));
    const initialVideoId = campaigns?.item?.data?.videos?.[0]?.id;
    const videos = values.video ? [{ id: values.video.id }] : [];
    const primaryImageId = values?.selected_images?.[0]?.id;
    const secondaryImageId = values?.selected_images?.[1]?.id;

    try {
      if (
        initialImages.length !== images.length ||
        initialVideoId !== videos[0]?.id
      ) {
        await campaigns.updateItem({
          id: match.params.campaignId,
          data: {
            images,
            videos
          },
          args: {
            include: 'images,images.sizes'
          }
        });
      }

      const updateProspecting = campaigns.updateContentSet({
        id: prospecting?.id,
        campaignId: match?.params?.campaignId,
        data: {
          final_url: values.final_url,
          attributes: {
            primary_image: { id: primaryImageId || null },
            secondary_image: { id: secondaryImageId || null },
            video: { id: values?.video?.id }
          }
        }
      });

      const updateRetargeting = campaigns.updateContentSet({
        id: retargeting?.id,
        campaignId: match.params.campaignId,
        data: {
          final_url: values.final_url,
          attributes: {
            primary_image: { id: primaryImageId || null },
            secondary_image: { id: secondaryImageId || null }
          }
        }
      });

      await updateProspecting;
      await updateRetargeting;

      // Changes here can effect the campaign template so we need to refresh it
      await campaigns.refresh({
        args: { includes: 'campaign_template' }
      });

      setStep({ step: 3 });
    } catch (e) {
      errorModal.open(e.message);
    }
  };

  useEffect(() => {
    if (hasSetInitialImages.current) return;

    const primaryImage =
      campaigns?.item?.data?.ad_content_sets?.items?.[0]?.attributes
        ?.primary_image?.id;
    const secondaryImage =
      campaigns?.item?.data?.ad_content_sets?.items?.[0]?.attributes
        ?.secondary_image?.id;

    if (primaryImage || secondaryImage) {
      hasSetInitialImages.current = true;
      setSelectedImages([primaryImage, secondaryImage].filter(Boolean));
    }
  }, [campaigns]);

  return (
    <Box flex={1} flexDirection='column' style={{ minHeight: 0 }}>
      <ReactForms
        initialValues={getInitialValues()}
        handleSubmit={handleSubmit}
        validate={validate}
        asyncValuesReady={status === 'loaded'}
      >
        {({ values, submitForm, isSubmitting }) => (
          <>
            <ScrollableContent>
              <Heading>What should we put in your ad?</Heading>
              <Body grey>Let’s build your marketing campaign.</Body>
              {videoOnlyAdsEnabled && (
                <Body grey>
                  <span style={{ fontStyle: 'italic' }}>
                    <NewTag />{' '}
                    <Tiny Tag='span'>
                      You can now run campaigns using video only. Simply
                      deselect the images and upload your video below.
                    </Tiny>
                  </span>
                </Body>
              )}

              <RenderLoading minHeight='50rem' isLoading={status === 'loading'}>
                <Box style={{ width: '100%' }} mb={token('spacing.xxl')}>
                  <Form name='appraisalDetails'>
                    <Box style={{ width: '70%' }}>
                      <FormField
                        Input={TextInput}
                        inputProps={{
                          placeholder:
                            'e.g. http://example.com/request-appraisal'
                        }}
                        name='final_url'
                        label='Agency website URL'
                        subLabel={
                          <span>
                            When someone clicks your ad, this is where we’ll
                            take them.{' '}
                            <HelpIcon
                              Content={() => (
                                <Box style={{ width: '30rem' }}>
                                  <Body>
                                    We recommend you use an appraisal focussed
                                    landing page or the contact page on your
                                    agency’s website. Whatever page you use
                                    should have a contact form to capture
                                    enquiries.
                                  </Body>
                                </Box>
                              )}
                            />
                          </span>
                        }
                      />
                    </Box>

                    <FormField
                      Input={ImageUpload}
                      inputProps={{
                        status,
                        initialImages,
                        selectedImageIds: selectedImages,
                        columns: 3,
                        shouldAllowMultiple: true,
                        reverse: true,
                        canRemove: true,
                        canCrop: true,
                        selectable: true,
                        maxSelectable: 2,
                        isAppraisal: true,
                        onSelected: handleSelectImage
                      }}
                      name='images'
                      label='Select 2 Ad images'
                      subLabel={
                        <>
                          We recommend using images in a square format of
                          1000x1000px. You can use tools like{' '}
                          <a
                            href='https://canva.com'
                            target='_blank'
                            rel='noopener noreferrer'
                          >
                            canva.com
                          </a>{' '}
                          to produce your own visual assets.
                        </>
                      }
                      sendImmediate
                    />
                    <HiddenField
                      name='selected_images'
                      value={selectedImages}
                    />

                    <FormField
                      name='video'
                      label='Upload a video'
                      subLabel='We recommend using a video up to 60 seconds in length as this is the maximum length for videos on instagram. Max file size 4GB.'
                      Input={VideoInput}
                      sendImmediate
                    />
                  </Form>
                </Box>
              </RenderLoading>
            </ScrollableContent>

            <ButtonBar>
              <GhostButton
                IconLeft={ChevronLeft}
                onClick={() => setStep({ step: 1 })}
              >
                Back
              </GhostButton>
              <PrimaryButton
                form='appraisalDetails'
                IconRight={ChevronRight}
                onClick={submitForm}
                isLoading={isSubmitting}
                isDisabled={
                  videoOnlyAdsEnabled
                    ? !(selectedImages.length >= 2 || !!values.video?.id)
                    : selectedImages.length < 2
                }
              >
                Next
              </PrimaryButton>
            </ButtonBar>
          </>
        )}
      </ReactForms>
      <Error />
    </Box>
  );
};

export default compose(
  withError('errorModal'),
  withModel(sessionModel),
  withModel(wizardModel),
  withQuery(queryCampaign),
  withModel(imagesModel)
)(AppraisalDetailsScreen);

import React, { useEffect } from 'react';
import { api } from 'utils/api-client';
import { useModelState } from '@rexlabs/model-generator';
import sessionModel from 'data/models/custom/session';
import { getAccountRegion } from 'shared/utils/region';
import config from 'src/config';

interface ZendeskChatProps {
  apiKey: string;
}

declare global {
  interface Window {
    zE: (
      command: string,
      commandParams: string,
      callback?: ((cb: (...args: any[]) => void) => void) | string[]
    ) => void;
  }
}

export const ZendeskMessenger: React.FC<ZendeskChatProps> = ({ apiKey }) => {
  const session = useModelState(sessionModel);

  useEffect(() => {
    if (config.MODE === 'development') {
      return;
    }

    if (!session.user || !session.ready || !session.currentAccountId) {
      if (window.zE) {
        window.zE('messenger', 'hide');
        window.zE('messenger', 'logoutUser');
      }
      return;
    } else {
      if (window.zE) {
        const countryCode = getAccountRegion(session);
        window.zE(
          'messenger:set',
          'conversationTags',
          countryCode === 'GB' ? ['region:uk'] : ['region:apac']
        );
        window.zE('messenger', 'show');
      }
    }
    api.get('/zendesk/auth').then(({ data }) => {
      window.zE('messenger', 'loginUser', function (callback) {
        callback(data.token);
      });
    });
  }, [session.user, session.ready, window.zE, session.currentAccountId]);

  useEffect(() => {
    if (config.MODE === 'development') {
      return;
    }

    const existingScript = document.getElementById('ze-snippet');
    if (existingScript) return;
    const script = document.createElement('script');
    script.id = 'ze-snippet';
    script.src = `https://static.zdassets.com/ekr/snippet.js?key=${apiKey}`;
    document.body.appendChild(script);

    return () => {
      const existingScript = document.getElementById('ze-snippet');
      if (existingScript) document.body.removeChild(existingScript);
    };
  }, [apiKey]);

  return null;
};

import { Box } from '@rexlabs/box';
import { useToken } from '@rexlabs/styling';
import React from 'react';
import { LoadingSpinner } from 'src/view/components/loading';
import { Body } from 'src/view/components/text';

function SwitchingAccountScreen() {
  const token = useToken();

  return (
    <Box
      p={token('spacing.xl')}
      flexDirection='column'
      alignItems='center'
      justifyContent='center'
    >
      <LoadingSpinner />
      <Body grey>Switching account...</Body>
    </Box>
  );
}

export default SwitchingAccountScreen;

import { Box } from '@rexlabs/box';
import { StyleSheet, useStyles } from '@rexlabs/styling';
import React, { useState } from 'react';
import { FONT } from 'src/theme';
import { Small } from 'src/view/components/text';

import Triangle from 'src/assets/icons/triangle.svg';

const defaultStyles = StyleSheet({
  advancedSettings: {
    color: ({ token }) => token('color.textStyle.link.idle.default'),
    fontWeight: FONT.WEIGHTS.SEMIBOLD,

    '> svg': {
      verticalAlign: 'middle',
      transform: 'scale(1.3)'
    },

    ':hover': {
      cursor: 'pointer'
    }
  },

  hideSettings: {
    '> svg': {
      transform: 'scale(1,-1)'
    }
  },

  triangle: {
    color: ({ token }) => token('color.textStyle.link.idle.default')
  }
});

function AdvancedSettings({ children }) {
  const s = useStyles(defaultStyles);
  const [showAdvanced, setShowAdvanced] = useState(true);

  return (
    <>
      <Box style={!showAdvanced && { display: 'none' }}>{children}</Box>
      <Small
        onClick={() => setShowAdvanced(!showAdvanced)}
        {...s('advancedSettings', {
          hideSettings: showAdvanced
        })}
      >
        {showAdvanced ? 'Hide advanced settings' : 'Advanced settings'}{' '}
        <Triangle {...s('triangle')} />
      </Small>
    </>
  );
}

export default AdvancedSettings;

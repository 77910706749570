import { superstructResolver } from '@hookform/resolvers/superstruct';
import { GhostButton, PrimaryButton } from '@rexlabs/button';
import { margin, StyleSheet, useStyles } from '@rexlabs/styling';
import React, { FC, useCallback } from 'react';
import { Controller, SubmitHandler, useForm } from 'react-hook-form';
import { MegaModalContent } from 'src/components/elements/modal';
import {
  FormControl,
  FormErrorMessage,
  FormHelperText,
  FormInput,
  FormLabel
} from 'src/components/form';
import { useModalState } from 'src/hooks';
import { CustomAudienceDTO } from '../types';
import { customAudienceDTOSchema } from '../utils/schemas';
import { CustomAudienceUploadDescription } from './CustomAudienceUploadDescription';
import { TextInput } from '@rexlabs/text-input';
import { CSVUploadInput } from 'src/features/uploads';
import { withError } from 'view/containers';
import { compose } from 'redux';
import { WithErrorReturn } from 'view/containers/with-error';

const styles = StyleSheet({
  form: {
    '& > * + *': {
      ...margin.styles({
        top: 's'
      })
    }
  },

  buttons: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'flex-end',

    '& > * + *': {
      ...margin.styles({
        left: 'xs'
      })
    }
  }
});

export type CustomAudienceUploadModalProps = {
  onSubmit: SubmitHandler<CustomAudienceDTO>;
  error: WithErrorReturn;
};

const CustomAudienceUploadModal: FC<CustomAudienceUploadModalProps> = ({
  onSubmit,
  error: { Error, open: openError }
}) => {
  const s = useStyles(styles);
  const [, setOpen] = useModalState();
  const {
    control,
    handleSubmit,
    formState: { isValid, isSubmitting }
  } = useForm<CustomAudienceDTO>({
    mode: 'onChange',
    resolver: superstructResolver(customAudienceDTOSchema)
  });
  const submitHandler = useCallback<SubmitHandler<CustomAudienceDTO>>(
    async (data, e) => {
      e?.stopPropagation();
      // eslint-disable-next-line dot-notation
      data.source['file_id'] = data.source.id;
      try {
        await onSubmit(data, e);
        setOpen(false);
      } catch (e) {
        openError(e as string);
      }
    },
    [setOpen, onSubmit, openError]
  );
  const handleCancel = useCallback(() => {
    setOpen(false);
  }, [setOpen]);

  return (
    <MegaModalContent
      title='Upload your contacts'
      description={<CustomAudienceUploadDescription />}
    >
      <form
        id='upload-custom-audience-form'
        {...s('form')}
        noValidate
        onSubmit={handleSubmit(submitHandler)}
      >
        <Controller
          control={control}
          name='source'
          render={({ field, fieldState: { error, isTouched } }) => (
            <FormControl
              isRequired
              isInvalid={isTouched && error !== undefined}
            >
              <FormLabel>Contacts file</FormLabel>
              {/* eslint-disable-next-line @typescript-eslint/ban-ts-comment */}
              {/* @ts-ignore */}
              <FormInput Input={CSVUploadInput} {...field} />
              <FormHelperText>
                Your contact data will be used and stored for your use only. The
                data will be protected and anonymised once uploaded to our
                servers and Facebook and can be deleted at anytime. For more
                information about our measures taken to protect your data please
                refer to our{' '}
                <a
                  href='https://spokeapp.io/legal/privacy'
                  target='_blank'
                  rel='noopener noreferrer'
                >
                  Privacy Policy
                </a>
              </FormHelperText>
              <FormErrorMessage>{error?.message}</FormErrorMessage>
            </FormControl>
          )}
        />

        <Controller
          control={control}
          name='name'
          render={({ field, fieldState: { error, isTouched } }) => (
            <FormControl
              isRequired
              isInvalid={isTouched && error !== undefined}
            >
              <FormLabel>Name</FormLabel>
              <FormInput
                Input={TextInput}
                {...field}
                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                // @ts-ignore
                placeholder='E.g. Open Home Attendees - Last 3 Months'
              />
              <FormErrorMessage>{error?.message}</FormErrorMessage>
            </FormControl>
          )}
        />

        <div {...s('buttons')}>
          <GhostButton onClick={handleCancel}>Cancel</GhostButton>
          <PrimaryButton
            isDisabled={!isValid}
            isLoading={isSubmitting}
            type='submit'
            form='upload-custom-audience-form'
          >
            Create
          </PrimaryButton>
        </div>
      </form>
      <Error />
    </MegaModalContent>
  );
};

export default compose(withError())(CustomAudienceUploadModal);

import { Box } from '@rexlabs/box';
import { OutlineButton } from '@rexlabs/button';
import { margin, styled, StyleSheet, useToken } from '@rexlabs/styling';
import { isEqual } from 'lodash';
import React, { useState } from 'react';
import PlusIcon from 'src/assets/icons/plus.svg';
import EventItem from 'src/view/components/input/events/event-item';
import Toggle from 'src/view/components/input/toggle';
import Label from 'src/view/components/text/label';
import CreateEventModal from 'src/view/modals/admin/create-event';
import { List } from '../../list';

const defaultStyles = StyleSheet({
  container: {
    marginTop: ({ token }) => token('spacing.m'),
    padding: ({ token }) => token('spacing.m'),
    border: ({ token }) => `.1rem solid ${token('legacy.color.grey.default')}`,
    borderRadius: '0.5rem'
  },

  toggle: {
    float: 'right'
  },

  text: {
    margin: '0 .2rem',
    display: 'block'
  },

  textMeta: {
    color: ({ token }) => token('legacy.color.blue.grey')
  },

  newEvent: {
    ...margin.styles({
      y: 's'
    })
  },

  newEventBox: {
    width: '3.5rem',
    height: '3.5rem',
    border: '.1rem dashed currentColor'
  }
});

function EventToggle({ value, toggled, name, onToggle, onChange, styles: s }) {
  const [showModal, setShowModal] = useState(false);
  const token = useToken();

  const fakeEvent = (value) => {
    return {
      persist: () => null,
      target: {
        type: 'select',
        name,
        id: name,
        value
      }
    };
  };

  const formatEvent = (values) => {
    const { type_id, location, date, repeat_weekly, weekly_repeats } = values;

    return Object.assign(
      {
        type: { id: type_id },
        start_at: date
          .hour(values.start_at / 100)
          .minute(values.start_at % 100)
          .format()
      },
      values.end_at && {
        end_at: date
          .hour(values.end_at / 100)
          .minute(values.end_at % 100)
          .format()
      },
      location && { location },
      values.repeat_weekly && { repeat_weekly },
      values.weekly_repeats && { weekly_repeats }
    );
  };

  const handleCreateEvent = (values) => {
    if (values) {
      const event = formatEvent(values);
      const e = fakeEvent(value.concat(event));
      onChange(e);
    }

    setShowModal(false);
  };

  const handleDeleteEvent = (item) => {
    if (item) {
      const e = fakeEvent(value.filter((event) => !isEqual(event, item)));
      onChange(e);
    }
  };

  return (
    <Box {...s('container')}>
      <Box {...s('toggle')}>
        <Toggle
          id={name}
          toggled={toggled}
          onChange={(e) => onToggle(e.target.checked)}
        />
      </Box>
      <Label style={{ marginTop: token('spacing.xxs') }}>Events</Label>
      <span {...s('text', 'textMeta')}>
        Add inspection times or an auction time
      </span>

      <Box w='100%' style={{ display: `${toggled ? 'block' : 'none'}` }}>
        <OutlineButton
          onClick={() => setShowModal(true)}
          IconLeft={PlusIcon}
          {...s('newEvent')}
        >
          Add a new event
        </OutlineButton>
        <List
          Item={({ data }) => (
            <EventItem data={data} onDelete={() => handleDeleteEvent(data)} />
          )}
          items={value}
          emptyStateProps={{
            smallText: 'No events scheduled.',
            minHeight: '10rem'
          }}
        />
      </Box>

      {showModal && <CreateEventModal closeModal={handleCreateEvent} />}
    </Box>
  );
}

export default styled(defaultStyles)(EventToggle);

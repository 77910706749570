import { Box } from '@rexlabs/box';
import { GhostButton } from '@rexlabs/button';
import { StyleSheet, useToken } from '@rexlabs/styling';
import React, { useMemo } from 'react';
import { formattedBrandName } from 'src/theme';
import { ModalStickyButtonGroup } from 'src/view/components/button';
import { List, StatsListHeader, StatsListItem } from 'src/view/components/list';
import { Modal } from 'src/view/components/modal';

export function statsReducer(lpMeta, categoryId) {
  // groups the landing_page_stats based on categoryId and adds a label from lpMeta matched by id
  return (acc, stat) => {
    if (stat.category_id === categoryId) {
      const label = lpMeta.types.find((t) => t.id === stat.type_id).label;

      acc[stat.type_id] = { ...(acc[stat.type_id] || {}), label };
      acc[stat.type_id].data = [...(acc[stat.type_id].data || []), stat];
    }
    return acc;
  };
}

const modalStyles = StyleSheet({
  container: {
    height: '62rem'
  }
});

const listStyles = StyleSheet({
  container: {
    flexGrow: 1,

    '@media print': {
      flex: '1 1 50%'
    }
  },

  seperator: {
    width: ({ token }) => `calc(100% - (${token('spacing.xl')} * 2))`,
    margin: 'auto'
  }
});

function Conversions({ stats, landingPageType, webflowLandingPage }) {
  const token = useToken();

  const lpData = stats?.landing_page_stats?.data ?? [];
  const lpMeta = stats?.landing_page_stats?.meta;
  const lpStatsTotal = stats?.summary?.landing_page_conversion_events;

  const items = useMemo(
    () =>
      Object.values(
        lpData.reduce(statsReducer(lpMeta, 'conversions'), {})
      ).filter((i) => {
        if (webflowLandingPage) {
          return [
            'Enquiry submission',
            'Phone button clicks',
            'Email button clicks'
          ].includes(i.label);
        }

        // Only show these stats for landing pages using the maximise leads form
        if (i.label === 'Photo gallery downloads' ||
          i.label === 'Start filling the enquiry form') {
          return landingPageType === 'maximum_leads'
        }

        return true;
      }),
    [lpData, lpMeta, webflowLandingPage, landingPageType]
  );

  return (
    <Box mb={token('spacing.m')}>
      <List
        items={items}
        getItemKey={(campaign) => campaign.id}
        Header={() => (
          <StatsListHeader
            title='All conversions'
            description={`${formattedBrandName} conversions are the most direct interaction with your campaign landing page. Every ${formattedBrandName} conversion event is an indication of a warm lead.`}
            value={lpStatsTotal}
          />
        )}
        Item={({ data: stat }) => (
          <StatsListItem stat={stat} statTotal={lpStatsTotal} />
        )}
        animateRows={false}
        styles={listStyles}
      />
    </Box>
  );
}

function ConversionsModal({ subTitle, closeModal, ...props }) {
  return (
    <Modal
      title='Conversions'
      subtitle={subTitle}
      onClose={closeModal}
      styles={modalStyles}
      noPadding
    >
      <Conversions {...props} />

      <ModalStickyButtonGroup padded>
        <GhostButton onClick={closeModal}>Close</GhostButton>
      </ModalStickyButtonGroup>
    </Modal>
  );
}

export { ConversionsModal, Conversions };

import React, { FC, useState } from 'react';
import { AdPreviewProps } from '../../types';
import { Carousel } from './Carousel';
import { InstagramAdContainer } from './InstagramAdContainer';

export const InstagramCarouselAd: FC<AdPreviewProps> = ({
  children,
  agencyLogo,
  agencyName,
  description
}) => {
  const [slidePos, setSlidePos] = useState(0);
  const slideCount = React.Children.toArray(children).length;

  return (
    <InstagramAdContainer
      pageName={agencyName}
      pageProfilePictureUrl={agencyLogo}
      description={description}
      carouselData={{ slidePos, slideCount }}
    >
      <Carousel slidePos={slidePos} setSlidePos={setSlidePos}>
        {children}
      </Carousel>
    </InstagramAdContainer>
  );
};

import { Box } from '@rexlabs/box';
import { PrimaryButton } from '@rexlabs/button';
import { withModel } from '@rexlabs/model-generator';
import { margin, styled, StyleSheet, useToken } from '@rexlabs/styling';
import { Heading } from '@rexlabs/text';
import { match } from '@rexlabs/whereabouts';
import React, { useCallback, useState } from 'react';
import { Helmet } from 'react-helmet';
import { Controller, useForm } from 'react-hook-form';
import { compose } from 'redux';
import { FormControl, FormInput, FormLabel } from 'src/components/form';
import config from 'src/config';
import session from 'src/data/models/custom/session';
import { formattedBrandName, isSpoke } from 'src/theme';
import { AuthBox } from 'src/view/components/box';
import { TextInput } from 'src/view/components/input';
import LoginCountry from 'src/view/components/input/select/login-country';
import { Body } from 'src/view/components/text';
import withError from 'src/view/containers/with-error';
import ForgotPasswordModal from 'src/view/modals/auth/forgot-password';
import { identify } from '@rexlabs/analytics';

const defaultStyles = StyleSheet({
  form: {
    display: 'flex',
    flexDirection: 'column',

    '> * + *': {
      ...margin.styles({
        top: 'xl'
      })
    }
  },

  links: {
    color: ({ token }) => token('legacy.color.blue.greyLight'),
    margin: 'auto',
    marginTop: ({ token }) => token('spacing.m'),
    marginBottom: ({ token }) => token('spacing.xl'),

    '& a': {
      padding: ({ token }) => `0 ${token('spacing.xxs')}`,
      color: ({ token }) => token('legacy.color.blue.greyLight')
    }
  }
});

function LoginScreen({ session, styles: s, errorModal, whereabouts }) {
  const [showForgotPassword, setShowForgotPassword] = useState(false);
  const token = useToken();

  const invitedUser = localStorage ? localStorage.getItem('invited') : null;

  const defaultValues = {
    email: invitedUser ?? undefined,
    password: undefined
  };

  const {
    control,
    handleSubmit,
    watch,
    formState: { isValid, isSubmitting }
  } = useForm({
    defaultValues: __DEV__ ? { ...config.TEST_USER } : defaultValues,
    mode: 'onBlur'
  });
  const email = watch('email');
  const submitLogin = useCallback(
    async ({ email, password }) => {
      let matched;
      if (whereabouts.query.redirect) {
        const location = { path: whereabouts.query.redirect };

        matched = match(location, {
          path: '/:accountId([a-fA-F0-9]{8}-[a-fA-F0-9]{4}-[a-fA-F0-9]{4}-[a-fA-F0-9]{4}-[a-fA-F0-9]{12})?/:rest(.*)'
        });
      }

      try {
        await session.login({
          email,
          password,
          currentAccountId: matched?.params?.accountId
        });

        if (invitedUser) localStorage.removeItem('invited');
      } catch (e) {
        errorModal.open(e.message);
      }
    },
    [errorModal, session, whereabouts.query.redirect]
  );

  return (
    <Box flexDirection='column' h='100%'>
      <AuthBox flex={1}>
        <Helmet>
          <title>{formattedBrandName} - Login</title>
        </Helmet>
        <Heading level={2}>Sign in to your account</Heading>
        <Box alignItems='center' flexDirection='column'>
          {isSpoke && <LoginCountry />}
        </Box>

        <form {...s('form')} onSubmit={handleSubmit(submitLogin)}>
          <Controller
            control={control}
            name='email'
            render={({ field }) => (
              <FormControl isRequired>
                <FormLabel>Email</FormLabel>
                <FormInput
                  {...field}
                  Input={TextInput}
                  onBlur={(e) => {
                    identify({
                      properties: { user: { email: e.target.value } }
                    });
                  }}
                  type='email'
                  placeholder='Enter your email address'
                />
              </FormControl>
            )}
          />
          <Controller
            control={control}
            name='password'
            render={({ field }) => (
              <FormControl isRequired>
                <FormLabel>Password</FormLabel>
                <FormInput
                  {...field}
                  Input={TextInput}
                  type='password'
                  placeholder='Enter your password'
                />
              </FormControl>
            )}
          />
          <Box
            flexDirection='row'
            alignItems='center'
            justifyContent='flex-end'
            mt={token('spacing.m')}
            mb={token('spacing.m')}
          >
            <a
              onClick={(e) => {
                e.preventDefault();
                setShowForgotPassword(true);
              }}
              href='#forgot-password'
            >
              Forgot password?
            </a>
          </Box>

          <PrimaryButton
            type='submit'
            isLoading={session.status === 'loggingIn' || isSubmitting}
            isDisabled={!isValid}
          >
            Login
          </PrimaryButton>
        </form>

        {showForgotPassword && (
          <ForgotPasswordModal
            email={email}
            closeModal={() => setShowForgotPassword(false)}
          />
        )}

        {isSpoke && (
          <Body grey>
            Don&apos;t have a {formattedBrandName} account?{' '}
            <a
              href='https://spokeapp.io/#try-spoke'
              target='_blank'
              rel='noopener noreferrer'
              style={{ fontWeight: '300' }}
            >
              Sign up here!
            </a>
          </Body>
        )}

        <errorModal.Error />
      </AuthBox>

      {isSpoke && (
        <Box {...s('links')}>
          <a
            href='https://spokeapp.io/legal/terms'
            target='_blank'
            rel='noopener noreferrer'
          >
            Terms &amp; Conditions
          </a>{' '}
          |{' '}
          <a
            href='https://spokeapp.io/legal/privacy'
            target='_blank'
            rel='noopener noreferrer'
          >
            Privacy Policy
          </a>
        </Box>
      )}
    </Box>
  );
}

export default compose(
  styled(defaultStyles),
  withError('errorModal'),
  withModel(session)
)(LoginScreen);

import { Field, useFormApi } from '@data-driven-forms/react-form-renderer';
import { StyleSheet, useStyles } from '@rexlabs/styling';
import React, { FC } from 'react';

const styles = StyleSheet({
  container: {
    display: 'flex',
    flexDirection: 'column',
    flexGrow: '1',
    gap: ({ token }) => token('spacing.xs'),
    marginTop: ({ token }) => token('spacing.m'),
    padding: ({ token }) => token('spacing.m'),
    border: ({ token }) => `.1rem solid ${token('legacy.color.grey.default')}`,
    borderRadius: '0.5rem',
    overflow: 'visible'
  }
});

export type SubformFieldProps = {
  fields: Field[];
};

export const SubForm: FC<SubformFieldProps> = ({ fields }) => {
  const { renderForm } = useFormApi();
  const s = useStyles(styles, 'SubForm');

  return <div {...s('container')}>{renderForm(fields)}</div>;
};

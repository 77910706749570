import React, { lazy } from 'react';
import { redirect, RouteObject } from 'react-router-dom';
import { ContentTypeEditor } from 'src/features/wizard/components/ContentTypeEditor';
import { queryClient } from 'src/lib/react-query';
import ProvideDetailsCreateStep from '../components/steps/ProvideDetailsCreateStep';
import ProvideDetailsEditStep from '../components/steps/ProvideDetailsEditStep';
import { StepSelector } from '../components/StepSelector';
import {
  provideDetailsEditLoader,
  provideDetailsLoader,
  stepLoader
} from '../utils/loaders';
import { steps } from '../utils/steps';
import { WizardErrorBoundary } from './WizardErrorBoundary';

const LaunchConfirmationStep = lazy(
  () => import('../components/steps/LaunchConfirmationStep')
);
const ContentTypesCreateWrapper = lazy(
  () => import('./ContentTypesCreateWrapper')
);
const ContentTypesEditWrapper = lazy(() => import('./ContentTypesEditWrapper'));

export const CampaignEditorLayout = lazy(() => import('./CampaignEditorPage'));

export const wizardCreateRoutes = (accountId: string): RouteObject[] => [
  {
    id: 'provide-details-create',
    path: 'provide-details',
    element: <ContentTypesCreateWrapper />,
    errorElement: <WizardErrorBoundary />,
    children: [
      {
        index: true,
        loader: provideDetailsLoader(queryClient, '.', accountId)
      },
      {
        path: ':contentType',
        element: <ProvideDetailsCreateStep />,
        loader: provideDetailsLoader(queryClient, '.', accountId)
      },
      {
        path: ':contentType/create',
        element: <ContentTypeEditor />
      },
      {
        path: ':contentType/:contentId/edit',
        element: <ContentTypeEditor />,
        loader: provideDetailsEditLoader(queryClient, accountId)
      }
    ]
  }
];

export const wizardEditRoutes = (accountId: string): RouteObject[] => [
  {
    index: true,
    loader: () => {
      return redirect(`./${steps[0].id}`);
    }
  },
  {
    id: 'provide-details-edit',
    path: 'provide-details',
    element: <ContentTypesEditWrapper />,
    errorElement: <WizardErrorBoundary />,
    children: [
      {
        index: true,
        loader: provideDetailsLoader(queryClient, '.', accountId)
      },
      {
        path: ':contentType',
        element: <ProvideDetailsEditStep />,
        loader: provideDetailsLoader(queryClient, '.', accountId)
      },
      {
        path: ':contentType/create',
        element: <ContentTypeEditor />
      },
      {
        path: ':contentType/:contentId/edit',
        element: <ContentTypeEditor />,
        loader: provideDetailsEditLoader(queryClient, accountId)
      }
    ]
  },
  {
    id: 'confirm',
    path: 'confirm',
    element: <LaunchConfirmationStep />
  },
  {
    path: 'general'
  },
  {
    id: 'wizard-step',
    path: ':stepId',
    loader: stepLoader,
    children: [
      { index: true, element: <StepSelector type='preview' /> },
      {
        path: 'edit',
        element: <StepSelector type='editor' />
      }
    ]
  }
];

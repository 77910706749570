/* eslint-disable react-hooks/exhaustive-deps */

import { navigation, track } from '@rexlabs/analytics';
import { Box } from '@rexlabs/box';
import { GhostButton } from '@rexlabs/button';
import { query, withModel, withQuery } from '@rexlabs/model-generator';
import { Portal } from '@rexlabs/portal';
import React, { useEffect, useMemo, useState } from 'react';
import { compose } from 'redux';
import sessionModel from 'src/data/models/custom/session';
import wizardModel from 'src/data/models/custom/wizard';
import campaignTemplatesModel from 'src/data/models/entities/campaign-templates';
import campaignsModel from 'src/data/models/entities/campaigns';
import ROUTES from 'src/routes/admin';
import { urlToPageName } from 'src/utils/routing';
import { push } from 'src/utils/whereabouts';
import WizardScreenAnimationGroup from 'src/view/animation-groups/wizard-screen';
import HeaderProgress from 'src/view/components/header-progress';
import { StepItem, StepsContainer } from 'src/view/components/steps';
import { availableSteps, Step } from './step';

/**
 * TODO: this file currently holds a lot of logic for the campaign engine,
 * it's been left here to keep it in one place until it goes through beta
 * and more of the engine is built out to determine the best way to split/
 * structure things.
 */

export const getSteps = (templateData) => {
  /**
   * NOTE: this is pretty rough but we're not sure if the shape
   * of content_sources will stay like this. There can be 2 steps
   * the subjectStep and the contentStep. The subjectStep is the main
   * thing being advertised while contentStep is what goes into the ad.
   * It's possible that the subjectStep and contentStep are same.
   * It's also currently restricted to only 2 possible steps and
   * subjectStep comes first.
   */
  if (!templateData) return [[], [], []];

  const contentSources = () => {
    const { subject, content } = templateData.content_source[0];
    const { type, ...props } = content;

    // NOTE: assumes that if there's multiple it uses a plural component
    const contentStep = {
      ...availableSteps[`select_${type}${props.max > 1 ? 's' : ''}`],
      ...props
    };

    /**
     * NOTE: hardcoding in appraisal campaign as its UX is a little different to
     * the others will discuss with design team to see if this can be re-aligned.
     */
    const isAppraisalCampaign =
      subject.length === 1 && subject[0] === type && type === 'agency';
    if (isAppraisalCampaign) {
      return [availableSteps.appraisal_suburbs];
    }

    // NOTE: if the subject and the content are the same there's only 1 step
    if (subject.length === 1 && subject[0] === type) {
      return [contentStep];
    }

    /**
     * NOTE: my plan was to just join the subject array and prefix it with select
     * to define the component. This was based on the current constraints that there's
     * only ever 2 steps and that future subject steps would follow this pattern but
     * the array was changing order for some weird reason and it doesn't seem like
     * the greatest idea. But, it'll work for now :sweat_smile:
     */
    const subjectStep = subject.every((s) => s === 'agent' || s === 'agency')
      ? availableSteps.select_agency_agent
      : null;

    return [subjectStep, contentStep].filter(Boolean);
  };

  const resourceKeys = Object.keys(templateData || {});

  const resources = [
    resourceKeys.includes('audience') && availableSteps.audience,
    resourceKeys.includes('ads') && availableSteps.ads,
    resourceKeys.includes('landing_page') && availableSteps.landing_page,
    resourceKeys.includes('budget') && availableSteps.budget
  ].filter(Boolean);

  /**
   * NOTE: appraisal_suburbs is hard code into the steps here as its UX
   * flow is different to the other campaigns. Will discuss with design
   * to try and align this.
   */
  const steps = [
    ...contentSources(),
    contentSources()[0]?.name === 'appraisal_suburbs' &&
      availableSteps.appraisal_details,
    ...resources,
    availableSteps.confirm
  ].filter(Boolean);

  /**
   * NOTE: might need to add a check here that each step has a Component
   * and if not display an error? :thinking:
   */
  return [steps, contentSources(), resources];
};

const campaignQuery = query`{
  ${campaignsModel} (id: ${(p) => p.match.params.campaignId}) {
    id
    campaign_template {
      type
      campaign_template_configuration {
        id
        configuration
      }
    }
  }
}`;

const campaignTemplateQuery = query`{
  ${campaignTemplatesModel} (id: ${(p) => p.match.params.campaignType}) {
    id
    type
    campaign_template_configuration {
      id
      configuration
    }
  }
}`;

/**
 * NOTE: React.memo is used here to make sure the animations
 * work correctly, mainly the transition between steps.
 */
const CampaignWorkflow = React.memo((props) => {
  const {
    session,
    match,
    whereabouts,
    entityType,
    wizard,
    wizard: { set, step, setStep, flush }
  } = props;
  const entity = props[entityType]?.item;
  const template =
    entityType === 'campaigns' ? entity?.data?.campaign_template : entity?.data;
  const templateData = template?.campaign_template_configuration;

  const [steps, contentSources] = useMemo(
    () => getSteps(templateData?.configuration),
    [templateData?.id]
  );

  const stepName = steps[step - 1]?.name;

  const { path, hash } = whereabouts;

  const handleExit = () => {
    track({
      event: 'Spoke campaign exited',
      properties: {
        action: 'exit_campaign_clicked',
        step_name: steps[step - 1]?.name?.replace(/-/g, '_')
      }
    });
    push(ROUTES.CAMPAIGNS);
    flush();
  };

  useEffect(() => {
    if (entity?.status !== 'loaded') return;

    if (!step) {
      if (match.params.campaignId) {
        setStep({ step: contentSources.length + 1 });
      } else {
        setStep({ step: 1 });
      }
    } else {
      if (!match.params.campaignId && step > contentSources.length) {
        setStep({ step: contentSources.length + 1 });
      }
    }
  }, [entity?.status]);

  useEffect(() => {
    set({ type: template?.type, slug: match.params.campaignType });
  }, [template?.type, match.params.campaignType]);

  useEffect(() => {
    if (step === null) return;
    /**
     * NOTE steps is calculated after campaigns or campaigns template is loaded
     * which means the step name won't be known for the first step so as a quick
     * fix just put the step number. Could move this into the Step component as
     * it doesn't get rendered until we have a step and steps but then this won't
     * fire as soon as the page loads which means the user could navigate away and
     * this page visit wouldn't get tracked. Might change the way we track this
     * instead :thinking:
     */
    const page = urlToPageName(path, hash, steps[step - 1]?.name || step);

    navigation({
      page,
      properties: {
        path,
        hash
      }
    });
  }, [step, hash, path]);

  return (
    <Box flex={1} h='100%' w='100%'>
      <Portal target='headerSteps'>
        <WizardScreenAnimationGroup>
          {step > contentSources.length && step < steps.length && (
            <StepsContainer>
              {steps
                .filter(
                  (_value, idx) =>
                    idx >= contentSources.length && idx < steps.length - 1
                )
                .map(({ label }, idx) => {
                  return (
                    <StepItem
                      key={idx + 1}
                      number={idx + 1}
                      active={step - contentSources.length === idx + 1}
                      label={label}
                    />
                  );
                })}
            </StepsContainer>
          )}
        </WizardScreenAnimationGroup>
      </Portal>

      <Portal target='headerProgress'>
        <WizardScreenAnimationGroup>
          {(!step || step < steps.length) && (
            <HeaderProgress
              progress={step && steps.length !== 0 ? step / steps.length : 0}
            />
          )}
        </WizardScreenAnimationGroup>
      </Portal>

      <Portal target='headerButton'>
        <WizardScreenAnimationGroup>
          <GhostButton size='s' onClick={handleExit}>
            {step && !!steps.length && (
              <>{match.params.campaignId ? 'Save ' : 'Close '}and exit</>
            )}
          </GhostButton>
        </WizardScreenAnimationGroup>
      </Portal>

      {step && !!steps.length && (
        <Step
          key={stepName}
          match={match}
          whereabouts={whereabouts}
          session={session}
          stepName={stepName}
          templateId={templateData.id}
          template={template}
          wizard={wizard}
        />
      )}
    </Box>
  );
});

const CampaignWorkflowWrapper = (props) => {
  const { match } = props;
  const [{ Component, entityType }, setComponent] = useState({
    entityType: match.params.campaignId ? 'campaigns' : 'campaignTemplates',
    Component: match.params.campaignId
      ? withQuery(campaignQuery)(CampaignWorkflow)
      : withQuery(campaignTemplateQuery)(CampaignWorkflow)
  });

  useEffect(() => {
    setComponent({
      entityType: match.params.campaignId ? 'campaigns' : 'campaignTemplates',
      Component: match.params.campaignId
        ? withQuery(campaignQuery)(CampaignWorkflow)
        : withQuery(campaignTemplateQuery)(CampaignWorkflow)
    });
  }, []);

  if (match.params.campaignType === 'success') {
    return <CampaignWorkflow {...props} />;
  }

  return <Component entityType={entityType} {...props} />;
};

export default compose(
  withModel(sessionModel),
  withModel(wizardModel)
)(CampaignWorkflowWrapper);

import { Box } from '@rexlabs/box';
import { border, styled, StyleSheet } from '@rexlabs/styling';
import React, { PureComponent } from 'react';
import { FONT } from 'src/theme';

const defaultStyles = StyleSheet({
  container: {
    margin: '0 2rem',
    fontSize: '1.4rem'
  },

  numberContainer: {
    position: 'relative',
    width: '2rem',
    height: '2rem',
    minWidth: '2rem',
    borderRadius: '2rem',
    marginRight: ({ token }) => token('spacing.xs'),
    fontWeight: FONT.WEIGHTS.SEMIBOLD,
    display: 'flex',
    fontSize: '1.3rem',
    ...border.styles({
      all: {
        width: 'thin',
        color: 'neutral.idle.default'
      }
    }),

    '&::before': {
      content: '" "',
      position: 'absolute',
      zIndex: 1,
      top: '-.1rem',
      left: '-.1rem',
      bottom: '-.1rem',
      right: '-.1rem',
      background: ({ token }) => token('palette.brand.500'),
      borderRadius: '2rem',
      borderColor: ({ token }) => token('legacy.color.blue.dark'),
      color: ({ token }) => token('color.textStyle.body.contrast'),
      opacity: 0,
      transition: 'opacity .3s'
    }
  },

  activeNumberContainer: {
    '&::before': {
      opacity: 1
    }
  },

  number: {
    color: ({ token }) => token('palette.grey.600'),
    opacity: 1,
    transition: 'opacity .2s'
  },

  activeNumber: {
    position: 'absolute',
    zIndex: 1,
    opacity: 0,
    transition: 'opacity .2s'
  },

  labelContainer: {
    position: 'relative'
  },

  label: {
    color: ({ token }) => token('palette.grey.600'),
    opacity: 1,
    transition: 'opacity .3s'
  },

  activeLabel: {
    position: 'absolute',
    color: ({ token }) => token('color.textStyle.body.default'),
    opacity: 0,
    transition: 'opacity .3s'
  },

  visible: {
    opacity: 1
  },

  invisible: {
    opacity: 0
  }
});

@styled(defaultStyles)
class StepItem extends PureComponent {
  render() {
    const { styles: s, number, label, active } = this.props;
    return (
      <Box flexDirection='row' alignItems='center' {...s('container')}>
        <Box
          alignItems='center'
          justifyContent='center'
          {...s('numberContainer', { activeNumberContainer: active })}
        >
          <span {...s('number', { invisible: active })}>{number}</span>
          <span {...s('activeNumber', { visible: active })}>{number}</span>
        </Box>
        <Box
          alignItems='center'
          justifyContent='center'
          {...s('labelContainer')}
        >
          <span {...s('label', { invisible: active })}>{label}</span>
          <span {...s('activeLabel', { visible: active })}>{label}</span>
        </Box>
      </Box>
    );
  }
}

export default StepItem;

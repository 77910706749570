import React, { ComponentType, Key, ReactElement, useCallback } from 'react';
import { useAsyncRetry } from 'react-use';
import { BiConsumer, Consumer } from 'src/types';
import {
  FileDisplayProps,
  FileUploadFn,
  FileValue,
  PreviewDataTransformer
} from './types';

export type FileValueDisplayProps<T> = {
  fileValue: FileValue<T>;
  getPreviewData: PreviewDataTransformer<T>;
  Component: ComponentType<FileDisplayProps<T>>;
  uploadFile: FileUploadFn<T>;
  removeFile: Consumer<Key>;
  onComplete: BiConsumer<Key, T>;
};

export const FileValueDisplay = <T,>({
  fileValue,
  getPreviewData,
  Component,
  uploadFile,
  removeFile,
  onComplete
}: FileValueDisplayProps<T>): ReactElement => {
  const { type, id } = fileValue;

  const uploadAndComplete = useCallback(async () => {
    if (type === 'complete') {
      return fileValue.value;
    } else {
      const res = await uploadFile(
        fileValue.file,
        fileValue.abortController.signal
      );
      onComplete(id, res);
      return res;
    }
  }, [fileValue, id, type, uploadFile]);

  const { loading, retry, error, value } = useAsyncRetry(uploadAndComplete, [
    uploadAndComplete
  ]);

  const removeThisFile = useCallback(() => removeFile(id), [id, removeFile]);

  const state = loading
    ? 'uploading'
    : value === undefined
    ? 'failed'
    : 'completed';

  return (
    <Component
      state={state}
      error={error}
      retry={retry}
      value={value}
      removeFile={removeThisFile}
      file={fileValue.file}
      getPreviewData={getPreviewData}
    />
  );
};

/* eslint-disable max-lines */
import { Box } from '@rexlabs/box';
import { styled, StyleSheet } from '@rexlabs/styling';
import { autobind } from 'core-decorators';
import React, { PureComponent } from 'react';
import { withToken } from 'src/theme';
import { number as formatNumber } from 'src/utils/format';
import {
  TextInputStyledPrefix,
  TextInputStyledSuffix
} from 'src/view/components/input';
import { Body, Tiny } from 'src/view/components/text';

import Warning from 'src/assets/icons/error.svg';

const sliderThumbStyles = {
  marginTop: '-0.4rem',
  width: '1.6rem',
  height: '1.6rem',
  border: 'none',
  borderRadius: '50%',
  background: ({ token }) => token('color.primary.idle.default'),
  boxShadow: ({ token }) =>
    `0 5px 10px -2px ${token('color.primary.idle.contrast')}`,

  '&:hover': {
    boxShadow: ({ token }) =>
      `0 0 20px 5px ${token('color.primary.hover.contrast')}`
  }
};

const defaultStyles = StyleSheet({
  sliderWrapper: {
    marginBottom: ({ token }) => token('spacing.xl'),

    '& input': {
      width: '100%',
      flex: '0 0 auto',
      marginTop: ({ token }) => token('spacing.xs')
    },

    '> span': {
      color: ({ token }) => token('legacy.color.blue.grey'),
      fontSize: '1.3rem'
    }
  },

  slider: {
    WebkitAppearance: 'none !important',
    '--range': 'calc(var(--max) - var(--min))',
    '--ratio': 'calc((var(--val) - var(--min))/var(--range))',
    '--sx': 'calc(.5*1.5em + var(--ratio)*(100% - 1.5em))',
    height: '0.6rem',
    borderRadius: '0.4rem',
    paddingTop: '1.4rem',
    paddingBottom: '1.4rem',
    boxShadow: 'none',
    background: 'transparent',
    cursor: 'pointer',

    '&::-webkit-slider-runnable-track': {
      height: '0.6rem',
      borderRadius: '0.4rem',
      boxShadow: 'inset 0 1px 3px 0 rgba(0,0,0,0.10)',
      background: ({ token }) =>
        `linear-gradient(${token('color.primary.idle.default')}, ` +
        `${token('color.primary.idle.default')}) 0/ var(--sx) 100% ` +
        `no-repeat #DDE6EC`
    },

    '&::-moz-range-track': {
      height: '0.6rem',
      borderRadius: '0.4rem',
      boxShadow: 'inset 0 1px 3px 0 rgba(0,0,0,0.10)',
      background: '#DDE6EC'
    },

    '&::-moz-range-progress': {
      height: '0.6rem',
      borderRadius: '0.4rem',
      background: ({ token }) => token('color.primary.idle.default')
    },

    '&::-ms-track': {
      height: '0.6rem',
      borderRadius: '0.4rem',
      boxShadow: 'inset 0 1px 3px 0 rgba(0,0,0,0.10)',
      background: '#DDE6EC'
    },

    '&::-webkit-slider-thumb': {
      WebkitAppearance: 'none !important',
      ...sliderThumbStyles
    },

    '&::-moz-range-thumb': {
      ...sliderThumbStyles
    },

    '&::-ms-thumb': {
      ...sliderThumbStyles
    },

    '&::-ms-tooltip': {
      display: 'none'
    },

    '&:focus': {
      outline: 'none',

      '&::-webkit-slider-thumb': {
        boxShadow: ({ token }) =>
          `0 0 20px 5px ${token('color.primary.active.contrast')}`
      },

      '&::-moz-range-thumb': {
        boxShadow: ({ token }) =>
          `0 0 20px 5px ${token('color.primary.active.contrast')}`
      },

      '&::-ms-thumb': {
        boxShadow: ({ token }) =>
          `0 0 20px 5px ${token('color.primary.active.contrast')}`
      }
    }
  },

  sliderDisabled: {
    '&::-webkit-slider-runnable-track': {
      background: '#DDE6EC'
    },

    '&::-webkit-slider-thumb': {
      WebkitAppearance: 'none !important',
      display: 'none'
    },

    '&::-moz-range-thumb': {
      display: 'none',
      opacity: '0'
    },

    '&::-ms-thumb': {
      display: 'none',
      opacity: '0'
    }
  },

  sliderReadOnly: {
    WebkitAppearance: 'none !important',
    '--range': 'calc(var(--max) - var(--min))',
    '--ratio': 'calc((var(--val) - var(--min))/var(--range))',
    '--sx': 'calc(.5*1.5em + var(--ratio)*(100% - 1.5em))',
    '&::-webkit-slider-runnable-track': {
      background: ({ token }) =>
        `linear-gradient(${token('legacy.color.black')}, ` +
        `${token('legacy.color.black')}) 0/ var(--sx) 100% ` +
        `no-repeat #DDE6EC`
    },

    '&::-webkit-slider-thumb': {
      background: ({ token }) => token('legacy.color.black'),
      boxShadow: 'none',
      '&:hover': {
        boxShadow: 'none'
      }
    },

    '&::-moz-range-progress': {
      background: ({ token }) => token('legacy.color.black')
    },

    '&::-moz-range-thumb': {
      background: ({ token }) => token('legacy.color.black'),
      boxShadow: 'none',
      '&:hover': {
        boxShadow: 'none'
      }
    },

    '&::-ms-track': {
      background: ({ token }) => token('legacy.color.black'),
      boxShadow: 'none'
    },

    '&::-ms-thumb': {
      background: ({ token }) => token('legacy.color.black'),
      boxShadow: 'none',
      '&:hover': {
        boxShadow: 'none'
      }
    }
  },

  disabledTag: {
    padding: '0.2rem 0.6rem',
    background: ({ token }) => token('legacy.color.grey.default'),
    borderRadius: '0.3rem',
    color: ({ token }) => token('legacy.color.blue.grey'),
    marginLeft: '0.8rem',
    fontSize: '1.4rem'
  },

  warning: {
    verticalAlign: 'bottom',
    color: ({ token }) => token('legacy.color.orange.default')
  }
});

@withToken
@styled(defaultStyles)
@autobind
class Slider extends PureComponent {
  static defaultProps = {
    sliderStr: 3
  };

  state = {
    sliderVal: this.sliderValue(this.props.value)
  };

  fakeEvent(value) {
    const { name } = this.props;
    return {
      persist: () => null,
      target: {
        type: 'string',
        name,
        id: name,
        value
      }
    };
  }

  sliderValue(value) {
    // convert the value to the reverse of exponentiallyIncreaseInput function
    // used to set the slider position when entering a number in the input
    const { max, min, sliderStr } = this.props;
    return Math.pow((value - min) / (max - min), 1 / sliderStr) * max;
  }

  exponentiallyIncreaseInput(value) {
    // exponentially increase the value
    // used to make the slider non linear by setting the value exponentially
    const { max, min, sliderStr } = this.props;
    return Math.round((max - min) * Math.pow(value / max, sliderStr) + min);
  }

  handleInputBlur(e) {
    const { min, max } = this.props;
    const value = e.target.value;

    if (value < min || value > max) {
      this.handleInputChange(Math.max(min, Math.min(value, max)));
    }
  }

  handleInputChange(e) {
    const { onChange } = this.props;
    const value = e.target ? e.target.value : e;

    if (!(value === '' || /^[\d]+$/.test(value))) return;

    onChange(this.fakeEvent(value));
    this.setState({
      sliderVal: this.sliderValue(parseInt(value, 10))
    });
  }

  handleSliderChange(e) {
    const { onChange } = this.props;
    const inputValue = this.exponentiallyIncreaseInput(
      parseInt(e.target.value, 10)
    );

    onChange(this.fakeEvent(inputValue));
    this.setState({ sliderVal: e.target.value });
  }

  render() {
    const {
      value,
      min,
      max,
      prefix,
      suffix,
      title,
      subtitle,
      warning,
      isDisabled,
      readOnly,
      EnableLink,
      styles: s,
      token,
      testId
    } = this.props;
    const { sliderVal } = this.state;

    return (
      <>
        <Box justifyContent='space-between' style={{ minHeight: '6rem' }}>
          <div>
            <Body style={{ paddingBottom: '0' }}>
              {title}
              {isDisabled && <span {...s('disabledTag')}>Disabled</span>}
              {warning && <Warning {...s('warning')} />}
            </Body>
            <Tiny style={{ color: token('legacy.color.blue.grey') }}>
              {subtitle}
              {isDisabled && EnableLink && (
                <>
                  <br />
                  {title} are disabled. To re-enable {title}, {EnableLink}.
                </>
              )}
            </Tiny>
          </div>
          {!isDisabled && prefix && (
            <Box w='12rem'>
              <TextInputStyledPrefix
                data-testid={testId}
                value={value}
                prefix={prefix}
                disabled={readOnly}
                onChange={this.handleInputChange}
                onBlur={this.handleInputBlur}
              />
            </Box>
          )}

          {!isDisabled && suffix && (
            <Box w={'12rem'}>
              <TextInputStyledSuffix
                data-testid={testId}
                value={value}
                suffix={suffix}
                disabled={readOnly}
                onChange={this.handleInputChange}
                onBlur={this.handleInputBlur}
              />
            </Box>
          )}
        </Box>
        <Box
          flexDirection='row'
          flexWrap='wrap'
          justifyContent='space-between'
          {...s('sliderWrapper')}
        >
          <input
            type='range'
            value={sliderVal}
            min={min}
            max={max}
            step={1}
            onChange={this.handleSliderChange}
            {...s('slider', {
              sliderDisabled: isDisabled,
              sliderReadOnly: readOnly
            })}
            style={{ '--min': min, '--max': max, '--val': sliderVal }}
            disabled={isDisabled || readOnly}
          />
          {!isDisabled && (
            <>
              <span>
                {prefix}
                {formatNumber(min)} {suffix}
              </span>
              <span>
                {prefix}
                {formatNumber(max)} {suffix}
              </span>
            </>
          )}
        </Box>
      </>
    );
  }
}

export default Slider;

import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import Box from '@rexlabs/box';

import { styled, StyleSheet } from '@rexlabs/styling';
import colorTheme from '../../../utils/color-theme';
import charLimit, { limits } from '../../../utils/char-limit';
import ScaledText from '../../scaled-text';
import { SmallButton } from '../../button';
import { COLORS } from 'src/theme';
import _ from 'lodash';

export const types = {
  DEFAULT: 'default',
  WIDE: 'wide'
};

const fontFamily = 'Lato, sans-serif';
const defaultStyles = StyleSheet({
  skyscraper: {
    height: '600px',
    overflow: 'hidden',
    border: '1px solid darkgray',
    display: 'flex',
    flexDirection: 'column'
  },
  primaryImage: {
    width: '100%',
    objectFit: 'cover'
  },
  shortHeadlineWrapper: {
    backgroundColor: '#F8F8F8',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center'
  },
  shortHeadline: {
    fontFamily,
    fontWeight: 500
  },
  description: {
    fontFamily,
    fontSize: '36px',
    fontWeight: '300',
    lineHeight: '44px',
    marginBottom: '20px'
  },
  main: {
    flex: 1,
    backgroundColor: 'white',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center'
  },
  agentName: {
    fontFamily,
    fontWeight: 300,
    marginBottom: '20px'
  },
  agentTitle: {
    fontSize: '10px',
    fontFamily,
    fontWeight: 600,
    lineHeight: '12px',
    textTransform: 'uppercase',
    marginBottom: '20px'
  },
  logoWrapper: {
    height: '60px',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center'
  },
  logo: {
    height: '100%',
    width: '100%',
    objectFit: 'contain'
  }
});

@styled(defaultStyles)
class Skyscraper extends PureComponent {
  static propTypes = {
    shortHeadline: PropTypes.string,
    agentName: PropTypes.string,
    description: PropTypes.string,
    buttonText: PropTypes.string,
    brandColor: PropTypes.string,
    primaryImageUrls: PropTypes.object,
    logoUrls: PropTypes.object,
    type: PropTypes.oneOf(Object.values(types))
  };

  static defaultProps = {
    buttonText: 'Learn more',
    type: types.DEFAULT
  };

  scaledTextProps() {
    const { type } = this.props;
    switch (type) {
      case types.WIDE:
        return {
          maxWidth: 120,
          maxTextLines: 2,
          maxFontSize: 24,
          minFontSize: 12
        };
      case types.DEFAULT:
      default:
        return {
          maxWidth: 100,
          maxTextLines: 2,
          maxFontSize: 24,
          minFontSize: 12
        };
    }
  }

  shortHeadlineWrapperStyles() {
    const { type } = this.props;
    switch (type) {
      case types.WIDE:
        return { padding: '30px 20px 35px', height: '170px' };
      case types.DEFAULT:
      default:
        return { padding: '30px 12px 36px', height: '150px' };
    }
  }

  shortHeadlineStyles() {
    const { type } = this.props;
    switch (type) {
      case types.WIDE:
        return { fontSize: '24px', lineHeight: '26px' };
      case types.DEFAULT:
      default:
        return { fontSize: '20px', lineHeight: '21px' };
    }
  }

  render() {
    const {
      brandColor,
      backgroundColor,
      shortHeadline,
      description,
      primaryImageUrls,
      logoUrls,
      agentName,
      agentPosition,
      agencyName,
      buttonText,
      type,
      styles: s
    } = this.props;

    const { brandColorBorder } = colorTheme(brandColor);

    return (
      <Box
        {...s.with('skyscraper')({
          width: type === types.DEFAULT ? '120px' : '160px'
        })}
      >
        <img
          {...s.with('primaryImage')({
            height: type === types.DEFAULT ? '216' : '196'
          })}
          src={_.get(primaryImageUrls, ['crop.square', 'url'])}
        />
        <Box
          {...s.with('shortHeadlineWrapper')({
            ...this.shortHeadlineWrapperStyles()
          })}
        >
          <span
            {...s.with('shortHeadline')({
              ...this.shortHeadlineStyles(),
              color: COLORS.BLACK
            })}
          >
            {charLimit(shortHeadline, limits.SHORT_HEADLINE)}
          </span>
        </Box>
        <Box
          {...s.with('main')({
            padding: type === types.DEFAULT ? '27px 10px' : '24px 20px 22px'
          })}
        >
          <ScaledText
            {...this.scaledTextProps()}
            {...s.with('agentName')({ color: COLORS.BLACK })}
          >
            {agentName}
          </ScaledText>
          <ScaledText
            maxWidth={types.DEFAULT ? 100 : 140}
            maxTextLines={4}
            maxFontSize={24}
            minFontSize={12}
            {...s.with('description')({ color: COLORS.BLACK })}
          >
            {description}
          </ScaledText>
          <span {...s.with('agentTitle')({ color: COLORS.BLACK })}>
            {agentPosition} - {agencyName}
          </span>
          <SmallButton brandColor={brandColor}>{buttonText}</SmallButton>
        </Box>
        <Box
          {...s.with('logoWrapper')({
            padding: type === types.DEFAULT ? '13px 4px' : '10px 17px',
            backgroundColor: backgroundColor,
            borderTop: brandColorBorder
          })}
        >
          <img
            {...s.with('logo')({
              maxWidth: `calc(0.6 * ${
                type === types.WIDE ? '160px' : '120px'
              })`,
              maxHeight: 'calc(0.7 * 60px)'
            })}
            src={_.get(logoUrls, 'original.url')}
          />
        </Box>
      </Box>
    );
  }
}

export default Skyscraper;

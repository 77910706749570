export const campaignKeys = {
  all: (accountId: string) => [accountId, 'campaign'] as const,
  lists: (accountId: string) =>
    [...campaignKeys.all(accountId), 'list'] as const,
  list: (accountId: string, params: any) =>
    [...campaignKeys.lists(accountId), params] as const,
  details: (accountId: string) =>
    [...campaignKeys.all(accountId), 'detail'] as const,
  detail: (accountId: string, campaignId: string) =>
    [...campaignKeys.details(accountId), campaignId] as const
};

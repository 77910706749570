import React from 'react';
import { styled, StyleSheet } from '@rexlabs/styling';
import Box from '@rexlabs/box';
import charlimit, { limits } from '../../../utils/char-limit';
import getContrastColor from '../../../utils/contrast-color';
import { useFeature } from '../../../hooks';
import { injectGlobal } from 'styled-components';
import { fontFace } from 'utils/styling';
import config from 'config';
import { getBannerLabel } from './utils';

const NATIVE_AD_WIDTH = 1000;
const NATIVE_AD_HEIGHT = NATIVE_AD_WIDTH;

injectGlobal`
  ${fontFace('Gordita', `${config.API_URL}/assets/gordita-regular.woff2`)}
`;

const defaultStyles = StyleSheet({
  container: {
    width: `${NATIVE_AD_WIDTH}px`,
    height: `${NATIVE_AD_HEIGHT}px`,
    fontFamily: 'Circular Pro, Helvetica, Arial, sans-serif'
  },

  mainImage: {
    height: '870px',
    background: 'none no-repeat center center',
    backgroundSize: 'cover'
  },

  banner: {
    position: 'absolute',
    top: '44px',
    left: '44px',
    fontSize: '32px',
    fontWeight: '900',
    fontFamily: 'Circular Pro, Helvetica, Arial, sans-serif',
    textTransform: 'uppercase',
    padding: '20px 48px'
  },

  footer: {
    height: '130px',
    fontSize: '48px',
    fontWeight: '800'
  },

  gorditaFont: {
    fontFamily: 'Gordita, Circular Pro, Helvetica, Arial, sans-serif'
  }
});

const AdImage = ({
  styles: s,
  slide,
  adContentSet,
  scaleTo = 1000,
  scalePos = 'top left'
}) => {
  const style2 = useFeature('facebook_just_sold_style2', adContentSet.session);
  const mirage = useFeature('mirage_image_urls', adContentSet.session);
  const image = adContentSet.images.find((i) => i.id === slide?.image?.id);
  const crop = Object.values(image?.crops?.square?.top_left || {}).join();
  const imageUrl =
    image?.sizes?.['crop.square']?.url + (!mirage ? `&key=${crop}` : '');
  const { small_text: smallText } = slide;
  const { backgroundColor, brandColor } = adContentSet;
  const bannerLabel = getBannerLabel(adContentSet.session);

  return (
    <div
      {...s.with('container', { gorditaFont: style2 })({
        transformOrigin: `${scalePos}`,
        transform: `scale(${scaleTo / NATIVE_AD_HEIGHT})`
      })}
    >
      <div
        key={crop}
        {...s.with('mainImage')({
          backgroundImage: imageUrl ? `url(${imageUrl})` : 'none'
        })}
      >
        {!style2 && (
          <div
            {...s.with('banner')({
              backgroundColor: brandColor,
              color: getContrastColor(brandColor)
            })}
          >
            {bannerLabel}
          </div>
        )}
      </div>

      <Box
        justifyContent='center'
        alignItems='center'
        {...s.with('footer')({
          backgroundColor,
          color: getContrastColor(backgroundColor)
        })}
      >
        {charlimit(smallText, limits.SMALL_TEXT)}
      </Box>
    </div>
  );
};

export default styled(defaultStyles)(AdImage);

import { Box } from '@rexlabs/box';
import { GhostButton, PrimaryButton } from '@rexlabs/button';
import { withModel } from '@rexlabs/model-generator';
import { styled } from '@rexlabs/styling/lib/index';
import { autobind } from 'core-decorators';
import moment from 'moment';
import React, { Fragment, PureComponent } from 'react';
import campaignsModel from 'src/data/models/sysadmin/campaign-performance';
import { FONT, withToken } from 'src/theme';
import { createValidationRules } from 'src/utils/form';
import { ModalStickyButtonGroup } from 'src/view/components/button';
import { Form, FormField, withForm } from 'src/view/components/form';
import { TextArea } from 'src/view/components/input';
import { List } from 'src/view/components/list';
import { Modal } from 'src/view/components/modal';
import { Tiny } from 'src/view/components/text';
import withError from 'src/view/containers/with-error';

@withToken
@styled({
  container: {
    fontWeight: FONT.WEIGHTS.SEMIBOLD,
    color: ({ token }) => token('legacy.color.blue.grey'),
    borderBottom: ({ token }) =>
      `.1rem solid ${token('legacy.color.blue.greyLight')}`
  }
})
class NotesListHeader extends PureComponent {
  render() {
    const { token, styles: s } = this.props;
    return (
      <Box {...s('container')}>
        <Box p={token('spacing.m')}>Notes</Box>
      </Box>
    );
  }
}

@withToken
@styled({
  container: {
    width: '100%',
    padding: 0,
    flexShrink: 0,
    cursor: 'pointer',
    transition: 'background .2s'
  }
})
class NotesListItem extends PureComponent {
  render() {
    const { token, styles: s, note } = this.props;
    return (
      <Box
        p={0}
        pt={0}
        flexDirection='row'
        alignItems='center'
        justifyContent='space-between'
        {...s('container')}
      >
        <Box p={token('spacing.m')}>
          <span>{note.user.given_name}</span>
        </Box>
        <Box p={token('spacing.m')}>
          <Tiny>{moment(note.created_at).format('DD/MM/YYYY hh:mm a')}</Tiny>
        </Box>
        <Box p={token('spacing.m')} flex={1}>
          {note.note}
        </Box>
      </Box>
    );
  }
}

const validate = createValidationRules({
  note: ['required', 'Note']
});

function mapPropsToValues() {
  return {
    note: ''
  };
}

async function handleSubmit(values, { props }) {
  const { campaign, campaignPerformance, errorModal } = props;
  try {
    await campaignPerformance.storeNote({
      campaignId: campaign.id,
      data: {
        note: values.note
      }
    });
    campaignPerformance.refreshItem(
      { id: campaign.id },
      { include: 'campaign,notes' }
    );
  } catch (e) {
    errorModal.open(e);
  }
}

function asyncValuesReady() {
  return true;
}

const addCampaignNoteForm = {
  name: 'addCampaignNote',
  validate,
  mapPropsToValues,
  validateOnChange: true,
  handleSubmit,
  asyncValuesReady
};

const defaultStyles = {
  notesContainer: {
    maxHeight: '40vh',
    overflowY: 'auto'
  }
};

@withToken
@withError('errorModal')
@styled(defaultStyles)
@withModel(campaignsModel)
@withForm(addCampaignNoteForm)
@autobind
class Content extends PureComponent {
  render() {
    const {
      closeModal,
      addCampaignNote,
      notes,
      errorModal: { Error },
      styles: s,
      token
    } = this.props;
    return (
      <Fragment>
        <Box>
          <Form name='addCampaignNoteForm'>
            <FormField name='note' label='Add a Note' Input={TextArea} />
            <Box flexDirection='row' mt={token('spacing.xs')}>
              <PrimaryButton
                onClick={addCampaignNote.submitForm}
                isLoading={addCampaignNote.isSubmitting}
              >
                Save
              </PrimaryButton>
            </Box>
          </Form>
          <Error />
        </Box>
        {/* List Notes */}
        <Box {...s('notesContainer')}>
          <List
            items={notes}
            animateRows={false}
            isLoading={false}
            getItemKey={(data) => data.id}
            Header={NotesListHeader}
            Item={({ data }) => <NotesListItem note={data} />}
            endReached={true}
            isFetching={false}
          />
        </Box>

        <ModalStickyButtonGroup>
          <GhostButton onClick={closeModal}>Close</GhostButton>
        </ModalStickyButtonGroup>
      </Fragment>
    );
  }
}

class Core extends PureComponent {
  render() {
    const { closeModal } = this.props;
    return (
      <Modal title='Campaign Notes' width='75rem' onClose={closeModal}>
        <Content {...this.props} />
      </Modal>
    );
  }
}

export default Core;

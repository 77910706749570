import React, { PureComponent } from 'react';
import Box from '@rexlabs/box';
import { styled, StyleSheet } from '@rexlabs/styling';
import _ from 'lodash';
import { COLORS } from 'src/theme';

import Container from '../shared/container';

const defaultStyles = StyleSheet({
  container: {
    width: '100%',
    position: 'relative',
    overflow: 'hidden',
    fontFamily:
      'system-ui, -apple-system, BlinkMacSystemFont, ".SFNSText-Regular", sans-serif',
    color: '#1d2129',
    fontWeight: 'normal'
  },

  wrapPicture: {
    width: '100%',
    paddingBottom: '100%',
    background: 'none no-repeat center center',
    backgroundColor: COLORS.GREY_LIGHT,
    backgroundSize: 'cover',
    overflow: 'hidden',
    position: 'relative'
  },

  wrapText: {
    width: '100%',
    background: '#f6f7f9',
    borderBottom: '.1rem solid #d8d8d8',
    borderTop: '0 none',
    padding: '1rem 1.2rem',
    whiteSpace: 'normal'
  },

  wrapTextDetails: {
    maxWidth: '80%',
    maxHeight: '66px',
    overflow: 'hidden',
    marginRight: '1.2rem'
  },

  title: {
    fontSize: '1.6rem',
    lineHeight: '2rem',
    maxHeight: '11rem',
    wordWrap: 'break-word',
    fontWeight: 600,
    marginBottom: '.5rem',
    padding: 0,
    fontFamily:
      'system-ui, -apple-system, BlinkMacSystemFont, ".SFNSText-Regular", sans-serif'
  },

  headline: {
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    fontSize: '1.2rem',
    lineHeight: '1.8rem',
    maxHeight: '8rem',
    padding: 0
  },

  website: {
    color: '#606770',
    fontSize: '1.2rem',
    lineHeight: '1.1rem',
    textTransform: 'uppercase',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap'
  },

  learnMore: {
    border: '.1rem solid #ced0d4',
    background: '#f6f7f9',
    color: '#4b4f56',
    borderRadius: '.2rem',
    padding: '0 .8rem',
    fontSize: '1.2rem',
    lineHeight: '2.2rem',
    marginTop: '.2rem',
    whiteSpace: 'nowrap',
    textShadow: 'none',
    verticalAlign: 'middle',
    fontWeight: 'bold',
    boxSizing: 'content-box'
  }
});

@styled(defaultStyles)
class FacebookSingleImage extends PureComponent {
  render() {
    const { styles: s, adContentSet, ad, disableWrapper } = this.props;

    const format = _.get(ad, 'format.id', '');
    const isSecondary = format.endsWith('_secondary');

    const imageUrl = _.get(adContentSet, [
      isSecondary ? 'secondaryImage' : 'primaryImage',
      'square'
    ]);

    const website = adContentSet.website
      ? new URL(adContentSet.website).hostname.replace('www.', '')
      : null;

    if (disableWrapper) {
      return (
        <Box
          {...s.with('wrapPicture')({
            width: '1000px',
            height: '1000px',
            backgroundImage: imageUrl ? `url(${imageUrl})` : 'none'
          })}
        />
      );
    }

    return (
      <Container {...this.props} descriptionLimit={150}>
        <Box {...s('container')}>
          <Box
            {...s.with('wrapPicture')({
              backgroundImage: imageUrl ? `url(${imageUrl})` : 'none'
            })}
          />
          <Box
            flexDirection='row'
            justifyContent='space-between'
            {...s('wrapText')}
          >
            <Box {...s('wrapTextDetails')}>
              <span {...s('website')}>{website}</span>
              <h1 {...s('title')}>{adContentSet.title}</h1>
              <span {...s('headline')}>{adContentSet.headline}</span>
            </Box>
            <Box alignItems='center'>
              <button {...s('learnMore')}>Learn More</button>
            </Box>
          </Box>
        </Box>
      </Container>
    );
  }
}

export default FacebookSingleImage;

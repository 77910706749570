import React, { ComponentType, ReactElement } from 'react';
import {
  FormRenderer,
  FormRendererProps,
  FormTemplateRenderProps
} from '@data-driven-forms/react-form-renderer';
import { componentMapper } from './component-mapper';
import { DefaultFormTemplate } from './DefaultFormTemplate';

// We cannot use `Omit<FormRendererProps, 'componentMapper'>` here because Typescript will coerce the type into a generic mapped type.
// { [x: string]: any; [x: number]: any }
export type DynamicFormRendererProps<
  P extends FormTemplateRenderProps = FormTemplateRenderProps
> = Pick<
  FormRendererProps,
  | 'initialValues'
  | 'onCancel'
  | 'onReset'
  | 'onError'
  | 'onSubmit'
  | 'schema'
  | 'clearOnUnmount'
  | 'clearedValue'
  | 'validatorMapper'
  | 'actionMapper'
  | 'schemaValidatorMapper'
> & {
  FormTemplate?: ComponentType<P>;
  FormTemplateProps?: Partial<P>;
};

export const DynamicFormRenderer = <
  P extends FormTemplateRenderProps = FormTemplateRenderProps
>({
  FormTemplate = DefaultFormTemplate,
  ...props
}: DynamicFormRendererProps<P>): ReactElement | null => {
  return (
    <FormRenderer
      {...props}
      clearedValue={null}
      FormTemplate={FormTemplate as ComponentType<FormTemplateRenderProps>}
      componentMapper={componentMapper}
    />
  );
};

import { Box } from '@rexlabs/box';
import Icons from '@rexlabs/icons-next';
import { Item, Navigation, NavigationProvider } from '@rexlabs/navigation';
import { StyleSheet, useStyles } from '@rexlabs/styling';
import React, { FC } from 'react';
import ROUTES from 'routes/admin';
import { useFeature } from 'src/utils/feature-flags';
import { MenuLogo } from 'src/components/elements/logo/MenuLogo';
import { Link } from 'utils/whereabouts';
import {useModelState} from "@rexlabs/model-generator";
import sessionModel from "data/models/custom/session";
import { useFeature as useAccountFeature } from 'shared/hooks';

const defaultStyles = StyleSheet({
  logo: {
    height: '40px',
    color: ({ token }) => token('palette.brand.700')
  },

  container: {
    '& li a div': {
      fontSize: '16px !important'
    }
  },
  logoLink: {
    display: 'flex',
    width: 'fit-content'
  }
});

type NavItem = Item & { order: number };
const dashboardItem = {
  id: 'dashboard',
  label: 'Dashboard',
  Icon: () => <Icons.Dashboard />,
  to: ROUTES.DASHBOARD,
  order: 0
};

const campaignsItem = {
  id: 'campaigns',
  label: 'Campaigns',
  Icon: () => <Icons.Activity />,
  to: ROUTES.CAMPAIGNS,
  order: 1
};

const strategiesItem = {
  id: 'strategies',
  label: 'Strategies',
  Icon: () => <Icons.AppSwitcher />,
  to: ROUTES.STRATEGIES,
  order: 2
};

const listingsItem = {
  id: 'listings',
  label: 'Listings',
  Icon: () => <Icons.HouseSearch />,
  to: ROUTES.LISTINGS,
  order: 3
};

const allItems = [dashboardItem, campaignsItem, strategiesItem, listingsItem];

export const SideMenu: FC = () => {
  const s = useStyles(defaultStyles, 'SideMenu');
  const session = useModelState(sessionModel);
  const showCampaignStrategies = useFeature('campaign_strategies');
  const enableListingsPage = useAccountFeature('listing_set_campaigns', session);

  const navItems = allItems
    .reduce(
      (acc: NavItem[], curr: NavItem) => {
        if (curr.id === 'dashboard')
          return [...acc, dashboardItem];
        if (curr.id === 'listings' && enableListingsPage)
          return [...acc, listingsItem];
        if (curr.id === 'strategies' && showCampaignStrategies)
          return [...acc, strategiesItem];
        return acc;
      },
      [campaignsItem]
    )
    .sort((a, b) => a.order - b.order);

  return (
    <NavigationProvider>
      <Box {...s('container')}>
        <Navigation
          items={navItems}
          Title={() => (
            <Box
              paddingTop={'24px'}
              paddingLeft={'12px'}
              paddingBottom={'60px'}
            >
              <Link
                to={ROUTES.DASHBOARD}
                {...s('logoLink')}
              >
                <MenuLogo {...s('logo')} />
              </Link>
            </Box>
          )}
        />
      </Box>
    </NavigationProvider>
  );
};

/*
  NOTE: for more context, ATM we are using Segment (https://segment.com/docs/sources/website/analytics.js/)
  with the 'destinations': FullStory, Google Analytics and Intercom. We also download a Bugsnag client ourselves
  (v6) because Segment's version (v2) doesn't support setting a beforeSend after the client initialisation. We make
  direct calls to the bugsnagClient's notify where it makes sense below and because we have intertwined logic that
  allows us to send Bugsnag reports with a FullStory timestamp and also sends a FullStory event with a
  link to the related Bugsnag error report, we often don't include FullStory in the Segment track call so
  that we can avoid doubling up events to FullStory.
*/

/* eslint-disable no-case-declarations */
/* eslint-disable no-console */
import { noop } from 'lodash';
import config from 'src/config';

const TYPES = {
  INIT: 'analytics/INIT',
  IDENTIFY: 'analytics/IDENTIFY',
  CUSTOM: 'analytics/CUSTOM',
  ERROR: 'analytics/ERROR',
  PERFORMANCE: 'analytics/PERFORMANCE',
  NAVIGATION: 'analytics/NAVIGATION',
  INFORMATION: 'analytics/INFORMATION',
  TRACK: 'analytics/TRACK'
};

const EVENTS = {
  INFORMATION: 'Information',
  ERROR: 'Error',
  PERFORMANCE: 'Performance'
};

const logAnalyticsEvent = (message, ...params) => {
  if (config.DEBUG_ANALYTICS_EVENTS) console.log(message, ...params);
};

function analyticsMiddleware({ type, payload }) {
  let memoryCounter = 0;
  const sendGAMemory = (memory) => {
    // The counter indicates how often we measured memory without a refresh
    // Supposed to be an indicator for navigation to see how memory increases
    // over time
    memoryCounter = memoryCounter + 1;
    ['jsHeapSizeLimit', 'totalJSHeapSize', 'usedJSHeapSize'].forEach((name) => {
      window.ga?.('send', {
        hitType: 'event',
        eventCategory: `Memory ${name}`,
        eventAction: 'measure',
        eventLabel: `#${memoryCounter}`,
        eventValue: memory[name]
      });
    });
  };

  // Keys have been setup for Intercom as it has specific ones hardcoded
  // The custom keys we have added are in title case
  const userToSegment = ({ user, account, accounts = [] }) => {
    return {
      id: user?.id,
      firstName: user?.given_name,
      lastName: user?.family_name,
      email: user?.email,
      company: {
        id: account?.id,
        name: account?.name
      },
      companies: accounts.map((a) => ({ company_id: a.id, name: a.name })),
      appName: 'spoke'
    };
  };

  const {
    event,
    apiKey,
    userId,
    message,
    error,
    page,
    properties = {},
    options = {},
    callback = noop
  } = payload;

  switch (type) {
    case TYPES.INIT:
      // Analytics.init(apiKey)
      // See https://segment.com/docs/connections/sources/catalog/libraries/website/javascript/quickstart/

      // Create a queue, but don't obliterate an existing one!
      // eslint-disable-next-line no-var
      var analytics = (window.analytics = window.analytics || []);

      // If the real analytics.js is already on the page return.
      if (analytics.initialize) return;

      // If the snippet was invoked already show an error.
      if (analytics.invoked) {
        if (window.console && console.error) {
          console.error('Segment snippet included twice.');
        }
        return;
      }

      // Invoked flag, to make sure the snippet
      // is never invoked twice.
      analytics.invoked = true;

      // A list of the methods in Analytics.js to stub.
      analytics.methods = [
        'trackSubmit',
        'trackClick',
        'trackLink',
        'trackForm',
        'pageview',
        'identify',
        'reset',
        'group',
        'track',
        'ready',
        'alias',
        'debug',
        'page',
        'once',
        'off',
        'on'
      ];

      // Define a factory to create stubs. These are placeholders
      // for methods in Analytics.js so that you never have to wait
      // for it to load to actually record data. The `method` is
      // stored as the first argument, so we can replay the data.
      analytics.factory = function (method) {
        return function () {
          // eslint-disable-next-line prefer-rest-params
          const args = Array.prototype.slice.call(arguments);
          args.unshift(method);
          analytics.push(args);
          return analytics;
        };
      };

      // For each of our methods, generate a queueing stub.
      for (let i = 0; i < analytics.methods.length; i++) {
        const key = analytics.methods[i];
        analytics[key] = analytics.factory(key);
      }

      // Define a method to load Analytics.js from our CDN,
      // and that will be sure to only ever load it once.
      analytics.load = function (key, options) {
        // Create an async script element based on your key.
        const script = document.createElement('script');
        script.type = 'text/javascript';
        script.async = true;
        script.src =
          'https://cdn.segment.com/analytics.js/v1/' +
          key +
          '/analytics.min.js';

        // Insert our script next to the first script element.
        const first = document.getElementsByTagName('script')[0];
        first.parentNode.insertBefore(script, first);
        analytics._loadOptions = options;
      };

      // Add a version to keep track of what's in the wild.
      analytics.SNIPPET_VERSION = '4.1.0';

      // Load Analytics.js with your key, which will automatically
      // load the tools you've enabled for your account. Boosh!
      analytics.load(apiKey);
      break;

    case TYPES.IDENTIFY:
      const { user } = properties;
      const userProperties = userToSegment(properties);

      logAnalyticsEvent('Analytics[identify]', {
        userId,
        properties: userProperties
      });

      window.analytics?.identify(userId, userProperties, {
        Intercom: { user_hash: user?.intercom_identity_hash }
      });

      // This is to ensure bugsnag has the most up to date user data
      if (window.bugsnagClient) {
        window.bugsnagClient.user = user;
        window.bugsnagClient.metaData = {};
      }
      break;

    case TYPES.CUSTOM:
      // FullStory is not hit because we send it via the Bugsnag beforeSend
      window.analytics?.track(
        event,
        properties,
        {
          ...options,
          ...{
            integrations: {
              FullStory: false,
              ...options.integrations
            }
          }
        },
        callback
      );

      window.bugsnagClient?.notify(event, {
        severity: 'info',
        ...properties
      });
      break;

    case TYPES.INFORMATION:
      logAnalyticsEvent('Analytics[information]', { event, properties });

      // FullStory is not hit because we send it via the Bugsnag beforeSend
      window.analytics?.track(
        EVENTS.INFORMATION,
        { Message: message, ...properties },
        {
          integrations: { FullStory: false }
        }
      );

      window.bugsnagClient?.notify(`Info - ${message}`, {
        severity: 'info',
        ...properties
      });
      break;

    case TYPES.TRACK:
      logAnalyticsEvent('Analytics[track]', { event, properties });

      window.analytics?.track(event, properties);

      window.Intercom?.('trackEvent', event, properties);

      window.bugsnagClient?.leaveBreadcrumb(event, properties);
      break;

    case TYPES.ERROR:
      // FullStory is not hit because we send it via the Bugsnag beforeSend
      window.analytics?.track(
        EVENTS.ERROR,
        { Error: error?.message ?? error, ...properties },
        { integrations: { FullStory: false } }
      );

      window.ga?.('send', 'exception', {
        exDescription: error?.message ?? error,
        exFatal: (error?.message ?? error).includes('CATASTROPHIC: ')
      });
      window.bugsnagClient?.notify(error, { ...options, ...properties });
      break;

    case TYPES.PERFORMANCE:
      const { memory, category, variable, value, label, fieldsObject } =
        properties;

      window.analytics?.track(EVENTS.PERFORMANCE, properties, {
        integrations: { FullStory: false }
      });

      if (event === 'timing')
        window.ga?.(
          'send',
          'timing',
          category,
          variable,
          value,
          label,
          fieldsObject
        );
      if (memory) {
        sendGAMemory(memory);
        window.bugsnagClient?.leaveBreadcrumb('memory', {
          metaData: { memory }
        });
      }

      break;

    case TYPES.NAVIGATION:
      logAnalyticsEvent('Analytics[navigation]', { page, properties });
      // Intercom is not hit because we need to trigger an 'update' directly otherwise they don't update the recent pages
      window.analytics?.page(page, properties, {
        integrations: { Intercom: false }
      });

      window.Intercom?.('update', {
        last_request_at: parseInt(new Date().getTime() / 1000)
      });
      window.bugsnagClient?.leaveBreadcrumb('navigation', {
        metaData: { page, properties }
      });
      break;
  }
}

export default analyticsMiddleware;

import ActionMenu from '@rexlabs/action-menu';
import { Box } from '@rexlabs/box';
import { styled, StyleSheet, useToken } from '@rexlabs/styling';
import React, { forwardRef } from 'react';
import AUFlag from 'src/assets/icons/australia.svg';
import ChevronDownIcon from 'src/assets/icons/chevron-down.svg';
import NZFlag from 'src/assets/icons/new-zealand.svg';
import UKFlag from 'src/assets/icons/united-kingdom.svg';
import { Body } from 'src/view/components/text';

const actionMenuStyles = StyleSheet({
  menuItem: {
    '& a:hover': {
      opacity: 1
    }
  }
});

const defaultStyles = StyleSheet({
  button: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    background: 'none',
    border: 'none'
  },

  label: {
    width: '26rem'
  },

  auFlag: {
    marginRight: '0.2rem'
  },

  flagBeforeText: {
    marginRight: '0.6rem'
  }
});

function LoginCountry({ styles: s }) {
  const token = useToken();

  return (
    <ActionMenu
      placement='bottom'
      arrowMargin='2.5rem'
      Button={forwardRef((props, ref) => (
        <button ref={ref} {...s('button')} {...props}>
          {window.location.host.includes('uk') ? (
            <>
              <UKFlag {...s('flagBeforeText')} />
              <Body>UK</Body>
            </>
          ) : (
            <>
              <AUFlag {...s('auFlag')} />
              <NZFlag {...s('flagBeforeText')} />
              <Body>AUS/NZ</Body>
            </>
          )}
          <ChevronDownIcon style={{ marginLeft: token('spacing.xs') }} />
        </button>
      ))}
      items={[
        {
          label: (
            <Box {...s('label')} flexDirection='row' alignItems='center'>
              <AUFlag {...s('auFlag')} />
              <NZFlag {...s('flagBeforeText')} />
              <Body>Australia / New Zealand</Body>
            </Box>
          ),
          onClick: () => {
            if (window.location.host.includes('uk')) {
              window.location.href = 'https://app.spokeapp.io';
            }
          }
        },
        {
          label: (
            <Box {...s('label')} flexDirection='row' alignItems='center'>
              <UKFlag {...s('flagBeforeText')} />
              <Body>United Kingdom</Body>
            </Box>
          ),
          onClick: () => {
            if (!window.location.host.includes('uk')) {
              window.location.href = 'https://app.uk.spokeapp.io';
            }
          }
        }
      ]}
      styles={actionMenuStyles}
    />
  );
}

export default styled(defaultStyles)(LoginCountry);

import { Box } from '@rexlabs/box';
import { withModel } from '@rexlabs/model-generator';
import { styled, StyleSheet } from '@rexlabs/styling';
import { autobind } from 'core-decorators';
import React, { PureComponent } from 'react';
import { prepareAdData } from 'shared/utils/prepare-ad-data';
import sessionModel from 'src/data/models/custom/session';
import { RenderLoading } from 'src/view/components/loading';

import AdwordsAdPreview from '../adwords';
import FacebookAdPreview from '../facebook';
import InstagramAdPreview from '../instagram';

const defaultStyles = StyleSheet({
  wrapAds: {
    position: 'relative',
    transition: 'margin .4s',
    verticalAlign: 'top'
  },

  wrapAd: {
    display: 'inline-block',
    verticalAlign: 'top',
    opacity: 0,
    transition: 'opacity .4s'
  },

  wrapAdCurrent: {
    opacity: 1
  },

  scaleHelper: {
    display: 'inline-flex',
    flexShrink: 0,
    transition: 'opacity .2s'
  },

  scaleInner: {
    transformOrigin: 'top center',
    display: 'inline-flex',
    flexShrink: 0
  }
});

@withModel(sessionModel)
@styled(defaultStyles)
@autobind
class CarouselCore extends PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      previews: [],
      scaleCalculated: false,
      campaignData: null
    };

    if (!props.isLoading) {
      prepareAdData(props)
        .then((campaignData) => {
          this.setState({ campaignData });
        })
        .catch(console.warn);
    }

    this._refs = {
      previews: []
    };
  }

  componentWillReceiveProps(nextProps) {
    if (!this.state.scaleCalculated) {
      this.calcScale(nextProps);
    }
    if (
      !nextProps.isLoading ||
      this.props.campaignData !== nextProps.campaignData
    ) {
      prepareAdData(nextProps)
        .then((campaignData) => {
          this.setState({
            campaignData
          });
        })
        .catch(console.error);
    }
  }

  calcScale(props, forceCalc = false) {
    // Check if we can / need to re-calculate first!
    if (
      (this.state.scaleCalculated ||
        !props.carouselRef ||
        !this._refs.previews ||
        !this._refs.previews.length ||
        !this._refs.previews[0].previewRef.clientWidth) &&
      !forceCalc
    ) {
      return;
    }

    const previews = this._refs.previews.map(({ previewRef, ad }) => {
      const { carouselRef } = props;
      const carouselWidth = carouselRef.clientWidth - 32; // 32 is padding
      const carouselHeight = carouselRef.parentElement.clientHeight - 60 - 64; // 60 is the height carousel controls and 64 is the carousel padding

      // NOTE: facebook ads height is incorrect at this point due the adImage being scaled
      // within the facebook container. This means facebook ads will always be scaled based
      // on their width.
      const isFacebookSingleImage = ad
        .toLowerCase()
        .includes('facebook single');
      const isFacebookVideo = ad.toLowerCase().includes('facebook video');
      const adWidth =
        previewRef.clientWidth + (isFacebookSingleImage ? 100 : 0);
      let scale = 1;

      if (isFacebookVideo && previewRef.clientWidth >= carouselWidth) {
        scale = carouselWidth / previewRef.clientWidth;
      } else if (
        previewRef.clientWidth > previewRef.clientHeight &&
        adWidth > carouselWidth
      ) {
        scale = carouselWidth / adWidth;
      } else if (previewRef.clientHeight > carouselHeight) {
        scale = carouselHeight / previewRef.clientHeight;
      }

      return {
        scale,
        originalHeight: previewRef.clientHeight
      };
    });

    this.setState({
      scaleCalculated: true,
      previews
    });
  }

  handleOuterRef() {
    this.calcScale(this.props);
  }

  handlePreviewRef(e, i, ad) {
    /**
     * NOTE: this conditional checks if a video has just been uploaded
     * and if so it forces a recalc of the ad scale. This is to ensure
     * the facebook video ad is scaled properly. It feels dirty with the
     * setTimeout but without it it would error as the element ref wasn't
     * available.
     */
    if (
      ad.name === 'Facebook Video' &&
      !!this._refs.previews.length &&
      !this._refs.previews.find((p) => p.ad === 'Facebook Video')
    ) {
      this._refs.previews[i] = { previewRef: e, ad: ad.name };
      setTimeout(() => this.calcScale(this.props, true), 0);
    } else {
      this._refs.previews[i] = { previewRef: e, ad: ad.name };
    }
  }

  renderAdPreview(ad) {
    const { campaignData: originalCampaignData } = this.props;
    const { campaignData } = this.state;

    const isRetargeting = (ad?.ad_content_set_type?.id ?? '').endsWith(
      'retargeting'
    );

    const prospecting = campaignData?.prospecting ?? {};
    const retargeting = campaignData?.retargetting ?? {};
    const setData = {
      ...campaignData,
      facebookPage: campaignData?.facebookPage,
      ...(isRetargeting
        ? retargeting
        : {
            ...prospecting,
            slides: (prospecting?.slides ?? []).map((s) => ({ ...s }))
          })
    };

    const network = ad?.matchNetwork?.id ?? '';
    switch (network) {
      case 'facebook':
        return (
          <FacebookAdPreview
            ad={ad}
            campaignData={setData}
            originalCampaignData={originalCampaignData}
          />
        );
      case 'instagram':
        return (
          <InstagramAdPreview
            ad={ad}
            campaignData={setData}
            originalCampaignData={originalCampaignData}
          />
        );
      case 'adwords':
        return (
          <AdwordsAdPreview
            ad={ad}
            campaignData={setData}
            originalCampaignData={originalCampaignData}
          />
        );
      default:
        return () => <p>Preview not found</p>;
    }
  }

  render() {
    const { styles: s, ads, state, isLoading } = this.props;
    const { campaignData } = this.state;

    return (
      <RenderLoading isLoading={isLoading || !campaignData} minHeight='40rem'>
        <Box width='100%'>
          <div
            {...s.with('wrapAds')({
              width: `${ads.length * 100}%`,
              marginLeft: `-${state.current * 100}%`
            })}
            ref={this.handleOuterRef}
          >
            {ads.map((ad, i) => (
              <Box
                key={ad?.id}
                {...s.with('wrapAd', {
                  wrapAdCurrent: i === state.current
                })({
                  width: `${100 / ads.length}%`
                })}
              >
                <Box flex={1} justifyContent='center'>
                  <div
                    {...s.with('scaleHelper')({
                      opacity: this.state.scaleCalculated ? 1 : 0
                    })}
                  >
                    <div
                      {...s.with('scaleInner')({
                        ...(this.state?.previews?.[i]?.scale
                          ? {
                              transform: `scale(${this.state.previews[i].scale})`
                            }
                          : {})
                      })}
                    >
                      <div ref={(e) => this.handlePreviewRef(e, i, ad)}>
                        {this.renderAdPreview(ad)}
                      </div>
                    </div>
                  </div>
                </Box>
              </Box>
            ))}
          </div>
        </Box>
      </RenderLoading>
    );
  }
}

export default CarouselCore;

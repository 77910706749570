import { Box } from '@rexlabs/box';
import { GhostButton, PrimaryButton } from '@rexlabs/button';
import { withModel } from '@rexlabs/model-generator';
import { autobind } from 'core-decorators';
import _ from 'lodash';
import React, { PureComponent } from 'react';
import agentsModel from 'src/data/models/entities/agents';
import { withToken } from 'src/theme';
import { createValidationRules } from 'src/utils/form';
import { ModalStickyButtonGroup } from 'src/view/components/button';
import {
  Form,
  FormField,
  LabelOptionalField,
  withForm
} from 'src/view/components/form';
import { GridCell, GridRow } from 'src/view/components/grid';
import { TextInput, TextArea } from 'src/view/components/input';
import ImageUpload from 'src/view/components/input/file-upload/image-upload';
import { Modal } from 'src/view/components/modal';
import withError from 'src/view/containers/with-error';

export const validate = createValidationRules({
  full_name: ['required', 'full name'],
  description: ['required', 'description'],
  email: ['required|email', 'agent work email'],
  phone_number: ['required', 'phone number'],
  lead_capture_email: ['email', 'lead capture email'],
  images: ['required', 'agent photo']
});

const agentForm = {
  name: 'createAgent',

  validate,
  handleSubmit: (values, { props }) => {
    const { agents, closeModal, errorModal } = props;

    const agentId = props?.agent?.id;
    const action = agentId ? agents.updateItem : agents.createItem;

    return action({
      id: agentId,
      data: {
        full_name: values.full_name,
        description: values.description,
        email: values.email,
        lead_capture_email: values.lead_capture_email,
        phone_number: values.phone_number,
        images: _.map(values.images, (image) => ({
          id: image.id,
          name: image.name
        }))
      }
    })
      .then(({ data }) => closeModal({ id: data.id }))
      .catch((e) => {
        errorModal.open(e);
      });
  },

  mapPropsToValues: (props) => ({
    full_name: props?.agent?.full_name,
    description: props?.agent?.description,
    email: props?.agent?.email,
    lead_capture_email: props?.agent?.lead_capture_email,
    phone_number: props?.agent?.phone_number,
    images: props?.agent?.images
  }),
  validateOnMount: true,
  touchOnMount: true
};

@withToken
@withError('errorModal')
@withModel(agentsModel)
@withForm(agentForm)
@autobind
class CreateAgentModal extends PureComponent {
  render() {
    const {
      agent,
      agentErrors,
      closeModal,
      skipButton,
      createAgent,
      errorModal: { Error },
      token,
      readOnlyLeadCaptureEmail
    } = this.props;

    return (
      <Box>
        <Box pb={token('spacing.xxl')}>
          <Form name='createAgentForm'>
            <GridRow gutter={token('spacing.xl')}>
              <GridCell gutter={token('spacing.xl')} width={3 / 5}>
                <FormField
                  name='full_name'
                  label='Full name'
                  Input={TextInput}
                  inputProps={{
                    placeholder: 'e.g. Ryan Gosling'
                  }}
                />
                <FormField
                  name='description'
                  label='Description'
                  Input={TextArea}
                  inputProps={{
                    placeholder:
                      'e.g. Ryan has over 10 years experience in the real estate industry and...'
                  }}
                />
                <FormField
                  name='email'
                  label='Agent email'
                  Input={TextInput}
                  inputProps={{
                    placeholder: 'e.g. ryan@greenrealestate.com.au'
                  }}
                />
                <FormField
                  name='lead_capture_email'
                  label={
                    <LabelOptionalField text='Additional lead capture email' />
                  }
                  Input={TextInput}
                  inputProps={{
                    placeholder: 'e.g. 1412.17384@emaildrop.rexsoftware.com',
                    readOnly: readOnlyLeadCaptureEmail,
                    disabled: readOnlyLeadCaptureEmail
                  }}
                />
                <FormField
                  name='phone_number'
                  label='Agent phone number'
                  Input={TextInput}
                  inputProps={{
                    placeholder: '0449825342'
                  }}
                />
              </GridCell>
              <GridCell gutter={token('spacing.xl')} width={2 / 5}>
                <FormField
                  name='images'
                  label='Agent Photo'
                  Input={ImageUpload}
                  inputProps={{
                    columns: 1,
                    shouldAllowMultiple: true,
                    isSquare: true,
                    initialImages: agent?.images,
                    status: 'loaded',
                    sortable: true,
                    ratio: 'square'
                  }}
                  sendImmediate
                />
              </GridCell>
            </GridRow>
          </Form>
          <Error />
        </Box>
        <ModalStickyButtonGroup>
          <GhostButton
            onClick={() => closeModal({ id: null, skip: !!agentErrors })}
          >
            {skipButton ? 'Skip' : 'Cancel'}
          </GhostButton>
          <PrimaryButton
            form='createAgentForm'
            onClick={createAgent.submitForm}
            isLoading={createAgent.isSubmitting}
          >
            {agent ? 'Update' : 'Create'}
          </PrimaryButton>
        </ModalStickyButtonGroup>
      </Box>
    );
  }
}

class Core extends PureComponent {
  render() {
    const { closeModal, agent, agentErrors } = this.props;

    let title = 'Create a new agent profile';
    if (agent && !agentErrors) {
      title = 'Edit agent profile';
    }
    if (agentErrors) {
      title = 'Oops, we need some more information about the listing agent';
    }

    return (
      <Modal
        title={title}
        width='85rem'
        onClose={() => closeModal({ id: null, skip: !!agentErrors })}
      >
        <CreateAgentModal {...this.props} />
      </Modal>
    );
  }
}

export default Core;

import { Field, useFormApi } from '@data-driven-forms/react-form-renderer';
import { StyleSheet, margin, useStyles } from '@rexlabs/styling';
import React, { FC } from 'react';

const styles = StyleSheet({
  container: {
    display: 'flex',
    flexDirection: 'row',
    gap: ({ token }) => token('spacing.s')
  },

  column: {
    flex: '1',

    '> * + *': {
      ...margin.styles({
        top: 's'
      })
    }
  }
});

export type TwoColumnsProps = {
  leftColumn: Field[];
  rightColumn: Field[];
};

export const TwoColumns: FC<TwoColumnsProps> = ({
  leftColumn,
  rightColumn
}) => {
  const s = useStyles(styles, 'TwoColumns');
  const { renderForm } = useFormApi();

  return (
    <div {...s('container')}>
      <div {...s('column')}>{renderForm(leftColumn)}</div>
      <div {...s('column')}>{renderForm(rightColumn)}</div>
    </div>
  );
};

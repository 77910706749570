import { LinkButton, OutlineButton } from '@rexlabs/button';
import LoadingSpinner from '@rexlabs/loading-spinner';
import { margin, StyleSheet, useStyles } from '@rexlabs/styling';
import React, { ReactNode } from 'react';
import { Modal, ModalTrigger } from 'src/components/elements/modal';
import {
  LeadDestinationPreview,
  useRexIntegration,
  getDestinationName,
  LeadDestinations
} from 'src/features/rex-integration';
import { AdditionalLeadEmailModal } from './AdditionalLeadEmailModal';
import { useAddLeadCaptureEmail } from '../api/useAddLeadCaptureEmail';
import { useLeadCaptureEmails } from '../api/useLeadCaptureEmails';
import { useDeleteLeadCaptureEmail } from '../api/useDeleteLeadCaptureEmail';

const styles = StyleSheet({
  container: {
    '& > * + *': {
      ...margin.styles({
        top: 's'
      })
    }
  },

  destinations: {
    color: ({ token }) => token('color.textStyle.body.subtext')
  },

  removeButton: {
    fontSize: ({ token }) => token('typography.size.m'),
    color: ({ token }) => token('color.textStyle.body.default')
  },

  leadCaptureEmail: {
    '& > * + *': {
      ...margin.styles({
        left: 'xxs'
      })
    }
  }
});

export type LeadPreferenceSummaryProps = {
  campaignId: string;
};

export const LeadPreferenceSummary = ({
  campaignId
}: LeadPreferenceSummaryProps) => {
  const s = useStyles(styles);
  const { data: rexIntegration, ...rexIntegrationQuery } =
    useRexIntegration('test');
  const { data: leadCaptureEmail, ...leadCaptureEmailQuery } =
    useLeadCaptureEmails(campaignId, {
      select: (data) => data[0]
    });
  const addLeadCaptureEmail = useAddLeadCaptureEmail(campaignId);
  const removeLeadCaptureEmail = useDeleteLeadCaptureEmail(campaignId);

  if (rexIntegrationQuery.isLoading || leadCaptureEmailQuery.isLoading)
    return <LoadingSpinner />;

  const dests: ReactNode[] = (
    rexIntegration?.attributes.ready && rexIntegration?.attributes.is_rex_client
      ? rexIntegration?.attributes.lead_preferences ?? []
      : (['email_address'] as LeadDestinations[])
  ).map(getDestinationName);

  if (leadCaptureEmail) {
    dests.push(
      <span {...s('leadCaptureEmail')}>
        {addLeadCaptureEmail.isLoading && (
          <LoadingSpinner size={15} strokeWidth={3} />
        )}
        <span>{leadCaptureEmail.email}</span>
        <LinkButton
          {...s('removeButton')}
          onClick={() => removeLeadCaptureEmail.mutate(leadCaptureEmail.id)}
          isLoading={removeLeadCaptureEmail.isLoading}
          isDisabled={addLeadCaptureEmail.isLoading}
        >
          Remove
        </LinkButton>
      </span>
    );
  }

  return (
    <div {...s('container')}>
      <LeadDestinationPreview
        {...s('destinations')}
        destinations={dests.filter(Boolean)}
      />
      {!leadCaptureEmail && (
        <Modal>
          <ModalTrigger asChild>
            <OutlineButton>Add an additional email</OutlineButton>
          </ModalTrigger>
          <AdditionalLeadEmailModal
            onSubmit={(values) => addLeadCaptureEmail.mutate(values)}
          />
        </Modal>
      )}
    </div>
  );
};

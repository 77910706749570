import React from 'react';
import PropTypes from 'prop-types';
import { styled, StyleSheet } from '@rexlabs/styling';

import Box from '@rexlabs/box';
import _ from 'lodash';
import colorTheme from '../../../utils/color-theme';
import { LargeButton } from '../../button';
import GooText from '../../goo-text';
import AgentOverlay from '../../agent-overlay';
import ScaledText from '../../scaled-text';
import charLimit, { limits } from '../../../utils/char-limit';
import { COLORS } from 'src/theme';

const fontFamily = 'Lato, sans-serif';

const defaultStyles = StyleSheet({
  halfPage: {
    border: '1px solid darkgray',
    overflow: 'hidden'
  },
  header: {
    height: '292px',
    width: '100%',
    backgroundSize: 'cover',
    backgroundPosition: 'center',
    position: 'relative',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-end'
  },
  headlineWrapper: {
    maxWidth: '272px',
    position: 'absolute',
    top: '14px',
    left: '14px'
  },
  agentOverlayWrapper: {
    width: '100%',
    maxHeight: '88px',
    height: '100%',
    position: 'relative'
  },
  longHeadline: {
    fontFamily,
    fontSize: '20px',
    fontWeight: 500,
    lineHeight: 1.6,
    color: `${COLORS.BLACK}`
  },
  body: {
    backgroundColor: 'white',
    height: '209px',
    padding: '30px 24px 24px',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'flex-start'
  },
  smallText: {
    fontFamily,
    fontWeight: 600,
    fontSize: '12px',
    lineHeight: '15px',
    textTransform: 'uppercase',
    marginBottom: '7px'
  },
  shortHeadline: {
    fontFamily,
    fontWeight: 300,
    fontSize: '32px',
    lineHeight: '38px'
  },
  footerLogo: {
    width: 'auto',
    maxWidth: 'calc(0.6 * 300px)',
    maxHeight: 'calc(0.7 * 100px)',
    objectFit: 'contain'
  }
});

HalfPage.propTypes = {
  shortHeadline: PropTypes.string,
  longHeadline: PropTypes.string,
  bannerAdText: PropTypes.string,
  agentName: PropTypes.string,
  agencyName: PropTypes.string,
  buttonText: PropTypes.string,
  brandColor: PropTypes.string,
  primaryImageUrls: PropTypes.object,
  agentProfileUrls: PropTypes.object,
  logoUrls: PropTypes.object
};

function HalfPage({
  buttonText = 'View Property',
  shortHeadline,
  longHeadline,
  smallText,
  agentName,
  agencyName,
  brandColor,
  backgroundColor,
  primaryImageUrls,
  agentProfileUrls,
  logoUrls,
  styles: s
}) {
  const { brandColorBorder } = colorTheme(brandColor);

  return (
    <Box width={300} height={600} flexDirection='column' {...s('halfPage')}>
      <Box
        height={292}
        {...s.with('header')({
          backgroundImage: `url('${_.get(primaryImageUrls, [
            'crop.square',
            'url'
          ])}')`
        })}
      >
        <Box {...s('headlineWrapper')} width={270}>
          <GooText padding='10px 8px 8px' borderRadius={2}>
            <ScaledText
              maxWidth={255}
              maxTextLines={2}
              maxFontSize={20}
              minFontSize={14}
              {...s('longHeadline')}
            >
              {longHeadline}
            </ScaledText>
          </GooText>
        </Box>
        <Box {...s('agentOverlayWrapper')}>
          <AgentOverlay
            agentName={agentName}
            agencyName={agencyName}
            agentProfileUrl={_.get(agentProfileUrls, ['crop.square', 'url'])}
          />
        </Box>
      </Box>
      <Box {...s('body')}>
        <span {...s.with('smallText')({ color: COLORS.BLACK })}>
          {charLimit(smallText, limits.SMALL_TEXT)}
        </span>
        <ScaledText
          maxWidth={255}
          maxTextLines={3}
          maxFontSize={36}
          minFontSize={20}
          {...s.with('shortHeadline')({ color: COLORS.BLACK })}
        >
          {shortHeadline}
        </ScaledText>
        <Box marginTop={19}>
          <LargeButton brandColor={brandColor}>{buttonText}</LargeButton>
        </Box>
      </Box>
      <Box
        height='100px'
        display='flex'
        flexDirection='column'
        alignItems='center'
        justifyContent='center'
        padding='15px 40px'
        {...s.with('footer')({
          backgroundColor: backgroundColor,
          borderTop: brandColorBorder
        })}
      >
        <img src={_.get(logoUrls, 'original.url')} {...s('footerLogo')} />
      </Box>
    </Box>
  );
}

export default styled(defaultStyles)(HalfPage);

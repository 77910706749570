import { Box } from '@rexlabs/box';
import CSSAnimationGroup from '@rexlabs/css-animation-group';
import { withModel } from '@rexlabs/model-generator';
import { ToastTarget as NewToastTarget } from '@rexlabs/notifications';
import { PortalTarget } from '@rexlabs/portal';
import { withWhereabouts } from '@rexlabs/react-whereabouts';
import {
  StyleSheet,
  styled,
  useToken,
  StylesProvider,
  padding
} from '@rexlabs/styling';
import { ToastTarget } from '@rexlabs/toast';
import { match } from '@rexlabs/whereabouts';
import React, { useEffect } from 'react';
import { compose } from 'redux';
import { SideMenu } from 'src/components/elements/side-menu';
import { CampaignQuickLaunchMenu } from 'src/components/misc/CampaignQuickLaunchMenu';
import { HelpMenu } from 'src/components/misc/HelpMenu';
import { SettingsMenu } from 'src/components/misc/SettingsMenu';
import sessionModel from 'src/data/models/custom/session';
import wizardModel from 'src/data/models/custom/wizard';
import ROUTES from 'src/routes/admin';
import SYSADMIN from 'src/routes/sysadmin';
import { KEYFRAMES } from 'src/theme';
import { useFeature } from 'src/utils/feature-flags';
import { Router } from 'src/utils/routing';
import { push } from 'src/utils/whereabouts';
import AgencySelector from 'src/view/components/agency-selector';
import Authorize from 'src/view/components/authorize';
import ErrorBar from 'src/view/components/error-bar';
import { AccountSidebar, SettingsSidebar } from 'src/view/components/sidebar';
import SwitchingAccountScreen from 'src/view/screens/admin/switching-account';
import NotFoundScreen from 'src/view/screens/not-found';
import { AccountActionMenu } from '../components/action-menu';
import TopNavBar from '../components/top-nav';
import { BreadcrumbsNavBar } from 'src/components/elements/breadcrumb';

export const toastTargetTokens = {
  toast: {
    target: {
      ...padding.tokens({
        // Top Bar height + spacing medium
        top: ({ token }) => `calc(8rem + ${token('spacing.m')})`
      })
    }
  }
};

const defaultStyles = StyleSheet({
  wrapContent: {
    position: 'relative',
    width: '100%',
    height: '100%',
    overflow: 'auto'
  },

  wrapContentScroll: {
    overflow: 'auto'
  },

  content: {
    width: '100%',
    minWidth: '102.4rem',
    maxWidth: '102.4rem',
    padding: ({ token }) => token('spacing.xs'),
    paddingTop: ({ token }) => token('spacing.xxxl')
  },

  innerContent: {
    position: 'relative',
    width: '100%'
  },

  animationGroup: {
    display: 'flex',
    flex: 1,
    flexDirection: 'column',
    width: '100%',

    '& > div:nth-child(2)': {
      width: '100%',
      position: 'absolute'
    }
  },

  toasts: {
    position: 'absolute',
    left: 0,
    right: 0,
    zIndex: 11
  },

  topToasts: {
    bottom: '-2.5rem'
  },

  bottomToasts: {
    bottom: '2.5rem'
  },

  link: {
    color: ({ token }) => token('legacy.color.blue.grey'),
    fontWeight: '500',

    ':hover': {
      color: ({ token }) => token('legacy.color.blue.dark'),
      textDecoration: 'none'
    }
  }
});

const AdminLayout = ({
  styles: s,
  session,
  whereabouts,
  wizard: { flush }
}) => {
  const token = useToken();
  const showCampaignStrategies = useFeature('campaign_strategies');

  const isSetting = match(whereabouts, {
    path: '/:accountIndex([0-9]+)?/settings/(.*)'
  });
  const isProfile = match(whereabouts, {
    path: '/:accountIndex([0-9]+)?/profile/(.*)?'
  });

  useEffect(() => {
    // whenever the admin shell is rendered, we want to clear the wizard
    // state, just to make sure that even when the user leaves the campaign
    // creation process by manually typing in a url, we don't keep this information
    // hanging around!
    flush();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <Box flex={1} flexDirection='row'>
      <SideMenu />
      <Box flex={1} flexDirection='column'>
        <ErrorBar />

        <Box
          {...s('toasts', 'topToasts')}
          flexDirection='column'
          alignItems='center'
        >
          <StylesProvider tokens={toastTargetTokens}>
            <ToastTarget position='top-right' />
            <NewToastTarget placement='top-right' />
          </StylesProvider>
        </Box>

        <TopNavBar
          Left={<AgencySelector />}
          Right={
            <Box flexDirection='row' spacing={token('spacing.xs')}>
              <Authorize capability='create_campaigns'>
                <HelpMenu />
              </Authorize>
              <SettingsMenu />
              <AccountActionMenu
                userMenuItems={
                  session?.user?.is_sys_admin
                    ? [
                        {
                          label: 'Go to SysAdmin',
                          onClick: () => push(SYSADMIN.ACCOUNTS)
                        }
                      ]
                    : []
                }
              />
            </Box>
          }
          hideLogo
        />
        <BreadcrumbsNavBar />

        <Box flex='1' justifyContent='center' {...s('wrapContent')}>
          <PortalTarget name='fullscreenModals'>
            {({ children }) => (
              <CSSAnimationGroup
                enterAnimation={KEYFRAMES.WIZARD_MODAL_FADE_IN}
                enterDuration='200ms'
                enterFillMode='both'
                leaveAnimation={KEYFRAMES.WIZARD_MODAL_FADE_OUT}
                leaveDuration='150ms'
                leaveFillMode='both'
                style={{
                  position: 'absolute',
                  width: '100%',
                  height: '100%',
                  pointerEvents: children.length ? 'all' : 'none'
                }}
              >
                {children}
              </CSSAnimationGroup>
            )}
          </PortalTarget>

          {session.status === 'switching' ? (
            <SwitchingAccountScreen />
          ) : (
            <Box {...s('wrapContentScroll')} flex={1} justifyContent='center'>
              <Box {...s('content')} flexDirection='row' flex={1}>
                {isSetting && <SettingsSidebar />}
                {isProfile && <AccountSidebar />}

                <Box flex={1} {...s('innerContent')}>
                  <Router routes={ROUTES} NotFound={NotFoundScreen}>
                    {(matches) => (
                      <CSSAnimationGroup
                        leaveAnimation={KEYFRAMES.SCREEN_FADE_OUT}
                        leaveDuration='300ms'
                        leaveFillMode='both'
                        enterAnimation={KEYFRAMES.SCREEN_FADE_IN}
                        enterDuration='300ms'
                        enterFillMode='both'
                        {...s('animationGroup')}
                      >
                        {matches}
                      </CSSAnimationGroup>
                    )}
                  </Router>
                </Box>
              </Box>
            </Box>
          )}
        </Box>

        <Box
          {...s('toasts', 'bottomToasts')}
          flexDirection='column'
          alignItems='center'
        >
          <ToastTarget name='bottomToasts' />
        </Box>
      </Box>
    </Box>
  );
};

export default compose(
  withWhereabouts(),
  withModel(wizardModel),
  withModel(sessionModel),
  styled(defaultStyles)
)(AdminLayout);

import { useMutation, useQueryClient } from 'react-query';
import { useAccountId } from 'src/features/accounts';
import { MutationResult } from 'src/lib/react-query';
import { api } from 'src/utils/api-client';
import { Audience, AudienceDTO } from '../types';
import { transformAudienceSpecDTO } from '../utils/form';
import { transformAudienceResponse } from '../utils/schemas';
import { audienceKeys } from './keys';

export type UpdateCampaignAudienceParams = {
  accountId: string;
  campaignId: string;
  audience: Partial<AudienceDTO>;
};

export const updateCampaignAudience = async ({
  accountId,
  campaignId,
  audience
}: UpdateCampaignAudienceParams): Promise<Audience> => {
  const { data } = await api.patch(
    `/campaigns/${campaignId}/audience-spec`,
    transformAudienceSpecDTO(audience),
    {
      headers: {
        'X-Account-Id': accountId
      }
    }
  );
  return transformAudienceResponse(data);
};

export type UpdateCampaignAudienceVariables = Pick<
  UpdateCampaignAudienceParams,
  'audience'
>;

export type UseUpdateCampaignAudienceOptions = Omit<
  UpdateCampaignAudienceParams,
  'audience' | 'accountId'
>;

export const useUpdateCampaignAudience = ({
  campaignId
}: UseUpdateCampaignAudienceOptions): MutationResult<
  Audience,
  UpdateCampaignAudienceVariables
> => {
  const queryClient = useQueryClient();
  const accountId = useAccountId();
  return useMutation({
    mutationFn: ({ audience }) =>
      updateCampaignAudience({ accountId, campaignId, audience }),
    onSuccess: (data) => {
      queryClient.setQueryData(
        audienceKeys.detail(accountId, campaignId),
        data
      );
    },
    onSettled: () => {
      queryClient.invalidateQueries(audienceKeys.detail(accountId, campaignId));
    }
  });
};

import { Box } from '@rexlabs/box';
import { GhostButton, PrimaryButton } from '@rexlabs/button';
import { withModel } from '@rexlabs/model-generator';
import { useToken } from '@rexlabs/styling';
import React from 'react';
import { compose } from 'redux';
import usersModel from 'src/data/models/entities/users';
import { ModalStickyButtonGroup } from 'src/view/components/button';
import { Form, FormField, ReactForms } from 'src/view/components/form';
import UserCapabilitiesInput, {
  usersCapabilities
} from 'src/view/components/input/user-capabilities';
import { Modal } from 'src/view/components/modal';
import { Body, Small } from 'src/view/components/text';
import withError from 'src/view/containers/with-error';

function EditPermissionsModal({
  closeModal,
  user,
  users,
  error,
  error: { Error }
}) {
  const token = useToken();

  const handleSubmit = async (values) => {
    try {
      await users.updateItem({
        id: user.id,
        data: { capabilities: usersCapabilities(values.capability) }
      });

      closeModal();
    } catch (e) {
      error.open(e);
    }
  };

  return (
    <Modal title='Edit permissions' onClose={closeModal} width='45rem'>
      <ReactForms
        initialValues={{ capability: usersCapabilities(user.capabilities) }}
        handleSubmit={handleSubmit}
      >
        {({ submitForm, isSubmitting }) => (
          <Form name='editPermissions'>
            <Box mb={token('spacing.xl')}>
              <FormField
                name='capability'
                label='Permission level'
                Input={UserCapabilitiesInput}
              />
            </Box>

            <Box mb={token('spacing.m')}>
              <Small grey>Name</Small>
              <Body>{user.given_name}</Body>
            </Box>

            <Box mb={token('spacing.m')}>
              <Small grey>Last name</Small>
              <Body>{user.family_name}</Body>
            </Box>

            <Box mb={token('spacing.m')}>
              <Small grey>Email</Small>
              <Body>{user.email}</Body>
            </Box>

            <ModalStickyButtonGroup>
              <GhostButton grey onClick={closeModal}>
                Cancel
              </GhostButton>
              <PrimaryButton
                form='editPermissions'
                onClick={submitForm}
                isLoading={isSubmitting}
              >
                Save
              </PrimaryButton>
            </ModalStickyButtonGroup>
          </Form>
        )}
      </ReactForms>

      <Error />
    </Modal>
  );
}

export default compose(
  withError(),
  withModel(usersModel)
)(EditPermissionsModal);

import { Box } from '@rexlabs/box';
import { useToken } from '@rexlabs/styling';
import React, { useState } from 'react';

function StatsPanel({ children }) {
  const [showModal, setShowModal] = useState(null);
  const token = useToken();

  const openModal = (modal) => {
    setShowModal(modal);
  };

  const closeModal = () => {
    setShowModal(null);
  };

  return (
    <Box flexDirection='row' flexWrap='wrap' sx={token('spacing.xxxs')}>
      {children(openModal, closeModal, showModal)}
    </Box>
  );
}

export default StatsPanel;

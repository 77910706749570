import getContrastColor from './contrast-color';

/**
 * Given a brand color return the theme colors
 * @param  {string} brandColor
 * @return {object}
 */
export default function colorTheme(brandColor) {
  const isBright = getContrastColor(brandColor) === 'black';

  return {
    fontColor: isBright ? 'black' : brandColor,
    brandColorBorder: isBright ? 'solid 1px #ACACAC' : undefined
  };
}

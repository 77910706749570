import {
  Popover as RadixPopover,
  PopoverPortalProps,
  PopoverProps as RadixPopoverProps,
  PopoverTrigger,
  Portal
} from '@radix-ui/react-popover';
import React, { FC } from 'react';
import { extractFirstInChildren } from 'src/utils/react';

export type PopoverProps = RadixPopoverProps &
  Omit<PopoverPortalProps, 'children'> & {
    usePortal?: boolean;
  };

export const Popover: FC<PopoverProps> = ({
  children,
  usePortal = false,
  forceMount,
  container,
  ...rest
}) => {
  const [Trigger, content] = extractFirstInChildren(children, PopoverTrigger);
  return (
    <RadixPopover {...rest}>
      {Trigger}
      {usePortal ? (
        <Portal forceMount={forceMount} container={container}>
          {content?.[0]}
        </Portal>
      ) : (
        content
      )}
    </RadixPopover>
  );
};

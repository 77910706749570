import { createRestAPIModelGenerator } from 'src/data/models/generator';
import { api } from 'src/utils/api-client';

export const TYPE = 'vendor-reports';

const config = {
  entities: {
    api: {
      fetchItem: (type, args, id) => {
        // id is a base64 encoded jwt
        // previously id was jwt with . replaced with -- but this had issues as jwt can also contain --
        // this conditional is to handle backwards compatibility with the old approach
        const token = id.includes('--')
          ? id.replace(/--/g, '.')
          : atob(decodeURIComponent(id));

        return api
          .get(`/vendor-reports/${token}`)
          .then(({ data }) => ({ data: { id, ...data } }));
      }
    }
  }
};

const VendorReportsGenerator = createRestAPIModelGenerator(TYPE, config);
export default VendorReportsGenerator.createEntityModel();

import Heading from './heading';
import SubHeading from './subheading';
import Body from './body';
import BodyWithHelpIcon from './body-with-help-icon';
import Small from './small';
import Tiny from './tiny';
import Capitalized from './capitalized';
import Pre from './pre';
import Text from './text';

import Placeholder from './placeholder';
import Label from './label';
import ErrorMessage from './error';

import AutoScale from './auto-scale';

export {
  Heading,
  SubHeading,
  Body,
  BodyWithHelpIcon,
  Small,
  Tiny,
  Capitalized,
  Placeholder,
  Label,
  ErrorMessage,
  Pre,
  AutoScale,
  Text
};

import { Box } from '@rexlabs/box';
import { Field } from '@rexlabs/form';
import { autobind } from 'core-decorators';
import noop from 'lodash/noop';
import React, { PureComponent } from 'react';

@autobind
class HiddenField extends PureComponent {
  onChange = noop;

  componentWillReceiveProps(nextProps) {
    if (this.props.value !== nextProps.value) {
      this.changeValue(nextProps);
    }
  }

  changeValue(props) {
    const e = {
      persist: noop,
      target: {
        id: props.name,
        name: props.name,
        type: 'hidden',
        value: props.value
      }
    };
    this.onChange(e);
  }

  render() {
    const { name } = this.props;
    return (
      <Box style={{ display: 'hidden' }}>
        <Field
          name={name}
          Input={({ value: v, onChange }) => {
            this.onChange = onChange;
            return <input name={name} type='hidden' value={v} />;
          }}
          sendImmediate
        />
      </Box>
    );
  }
}

export default HiddenField;

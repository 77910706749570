import { useMutation, useQueryClient } from 'react-query';
import { useAccountId } from 'src/features/accounts';
import { MutationResult } from 'src/lib/react-query';
import { api } from 'src/utils/api-client';
import { CustomAudience } from '../types';
import { customAudienceKeys } from './keys';

export type DeleteCustomAudience = {
  accountId: string;
  customAudienceId: string;
};

export const deleteCustomAudience = async ({
  accountId,
  customAudienceId
}: DeleteCustomAudience): Promise<void> => {
  await api.delete(`user-lists/${customAudienceId}`, {
    headers: {
      'X-Account-Id': accountId
    }
  });
};

type Context = {
  previousValue?: CustomAudience;
};

export const useDeleteCustomAudience = (): MutationResult<
  void,
  string,
  Context
> => {
  const accountId = useAccountId();
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: (customAudienceId) =>
      deleteCustomAudience({ accountId, customAudienceId }),
    onMutate: async (customAudienceId) => {
      await queryClient.cancelQueries(
        customAudienceKeys.detail(accountId, customAudienceId)
      );

      const previousValue = queryClient.getQueryData<CustomAudience>(
        customAudienceKeys.detail(accountId, customAudienceId)
      );

      queryClient.setQueryData(
        customAudienceKeys.detail(accountId, customAudienceId),
        undefined
      );

      return { previousValue };
    },
    onError: (_e, customAudienceId, ctx) => {
      queryClient.setQueryData(
        customAudienceKeys.detail(accountId, customAudienceId),
        ctx?.previousValue
      );
    },
    onSuccess: () => {
      queryClient.invalidateQueries(customAudienceKeys.lists(accountId));
    },
    onSettled: (_data, _error, customAudienceId) => {
      queryClient.invalidateQueries(
        customAudienceKeys.detail(accountId, customAudienceId)
      );
    }
  });
};

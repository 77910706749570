import React from "react";
import {MegaModalContent, Modal} from "src/components/elements/modal";
import {PrimaryButton} from "@rexlabs/button";

type EmptyListingSetWarningModalProps = {
  open: boolean;
  close: () => void;
}
export function CatalogProvideDetailsStepWarningModal({open, close}: EmptyListingSetWarningModalProps) {
  return (
    <Modal open={open} onOpenChange={close}>
      <MegaModalContent title={'Selected catalogue is empty'} style={{maxWidth: '600px'}}>
        <div style={{paddingBottom: '12px'}}>
          <p>{
            'The catalogue you have selected does not currently contain any listings. ' +
            'Listings will be synced from Rex CRM as they become available. '
          }</p>
          <p>{
            'Please note: If you create a campaign with this catalogue you will not ' +
            'be able to preview your landing page or ads until a listing has been synced ' +
            'that matches the criteria in your catalogue.'
          }</p>
        </div>
        <footer>
          <PrimaryButton onClick={close}>Acknowledge</PrimaryButton>
        </footer>
      </MegaModalContent>
    </Modal>
  )
}

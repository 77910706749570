import {
  CatalogListingSetFormDTO,
  CatalogListingSetPostDTO,
  ListingStatus
} from "src/features/catalog-listing-sets/types";

export function parsePostData(
    data: CatalogListingSetFormDTO
): CatalogListingSetPostDTO {
    return {
        ...data,
        agents: data.agents,
        sort: data.sort?.[0].id,
        listing_categories: data.listing_categories[0].map((category: any) => category.id),
        listing_sold: data.listing_status === ListingStatus.Sold
    } as CatalogListingSetPostDTO;
}

import React, {FC, useEffect, useState} from 'react';
import { useCatalogListingSetAdPreview } from 'src/features/catalog-listing-sets/api/getCatalogListingSetPreview';
import { StyleSheet, useStyles } from '@rexlabs/styling';
import { Select } from 'view/components/input';
import {
  AdContentSet,
  AdsPreview,
} from 'src/features/ads';
import { Box } from '@rexlabs/box';
import {
  parseAdContentSet,
} from "src/features/wizard/components/steps/utils/parseAdContentSet";
import {AdType} from "src/features/campaign-definitions";
import {useCatalogListingSetListings} from "src/features/catalog-listing-sets/api/getCatalogListingSetListings";
import {PreviewPlaceholder} from "src/features/wizard/components/PreviewPlaceholder";
import {useAgencies} from "src/features/agencies/api/getAgencies";
import {RenderLoading} from "view/components/loading";

const styles = StyleSheet({
  selectContainer: {
    position: 'relative',
    paddingBottom: '0.8rem',
  }
});

type CatalogAdsPreviewProps = {
  listingSetId?: string;
  adTypes: AdType[];
  adContentSet: AdContentSet;
}
const CatalogAdsPreview: FC<CatalogAdsPreviewProps> = ({
  adContentSet, adTypes, listingSetId
}) => {
  const s = useStyles(styles, 'SelectStyles');
  const [selectedListing, setSelectedListing] = useState<string>();
  const [adContentSet2, setAdContentSet] = useState<AdContentSet>()

  const {
    data: agenciesData
  } = useAgencies({
    includes: ['logo.sizes']
  })
  const agencyData = agenciesData?.[0]

  const {
    data: listingSetListingsData,
    hasNextPage,
    fetchNextPage } = useCatalogListingSetListings({
    listingSetId: listingSetId ?? '',
    config: {
      enabled: listingSetId !== undefined
    }
  });

  const {
    data: listingSetPreviewData
  } = useCatalogListingSetAdPreview({
    listingSetId: listingSetId ?? '',
    listing_id: selectedListing,
    config: { enabled: listingSetId !== undefined }
  });

  const listingSelectOptions: Array<{label: string, value: string}> = listingSetListingsData?.pages
    ?.flatMap(i => i.data)
    ?.map((item) => (
      {
        label: item.address.full_address,
        value: item.id
      }
    )) ?? []

  const selectedListingPreviewData = listingSetPreviewData?.data?.[0];
  const displayCatalogPlaceholder = listingSelectOptions?.length === 0;

  const handleChange = (selectedOption) => {
    setSelectedListing(selectedOption.value);
  };

  useEffect(() => {
    if (listingSetListingsData) setSelectedListing(listingSetListingsData?.pages?.[0]?.data?.[0]?.id)
  }, [listingSetListingsData])

  useEffect(() => {
    if (adContentSet && selectedListingPreviewData && agencyData) {
      setAdContentSet(parseAdContentSet(adContentSet, selectedListingPreviewData, agencyData))
    }
  }, [selectedListingPreviewData?.attributes?.listing?.listing_id, adContentSet.id, agencyData?.id])

  useEffect(() => {
    if (hasNextPage) {
      fetchNextPage();
    }
  }, [hasNextPage, listingSetListingsData?.pageParams])

  return (
    <RenderLoading
      isLoading={!adContentSet2}
      minHeight='60rem'
    >
      <Box>
        <Box {...s('selectContainer')}>
          <Select
            options={listingSelectOptions}
            value={selectedListing}
            onSelect={handleChange}
          />
        </Box>

        {displayCatalogPlaceholder ? (
          <PreviewPlaceholder
            title={'Preview not available yet...'}
            subtitle={
              "Don’t sweat it, once your catalogue contains matching listings " +
              "from your CRM your ads will be created automatically."
            }
          />
        ) : (
          <AdsPreview
            adContentSet={adContentSet2 as AdContentSet}
            adTypes={adTypes}
          />
        )}
      </Box>
    </RenderLoading>
  );
};

export default CatalogAdsPreview;

import React, { PureComponent } from 'react';
import { autobind } from 'core-decorators';
import { styled, StyleSheet } from '@rexlabs/styling';

const defaultStyles = StyleSheet({
  container: {
    width: '100%'
  },

  scale: {
    width: '100%',
    transformOrigin: 'top left'
  },

  inner: {
    width: '100%',
    position: 'relative'
  },

  text: {
    position: 'absolute',
    top: 0,
    left: 0,
    width: '100%',
    textAlign: 'center',
    minHeight: '100%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',

    '> span': {
      overflowWrap: 'break-word',
      width: '100%'
    }
  }
});

@styled(defaultStyles)
@autobind
class AutoScaleText extends PureComponent {
  _refs = {};
  state = {
    scale: 1
  };

  handleRef(name) {
    return (e) => (this._refs[name] = e);
  }

  calcScale() {
    try {
      this._refs.scale.style.width = '100%';

      let i = 0;
      while (
        i < 10 &&
        this._refs.inner.clientHeight < this._refs.text.clientHeight
      ) {
        i++;
        this._refs.scale.style.width = this._refs.scale.clientWidth + 20 + 'px';
      }

      this.setState({
        scale: this._refs.container.clientWidth / this._refs.scale.clientWidth
      });
    } catch (e) {
      if (__DEV__) {
        console.warn('Could not auto scale text', e);
      }
    }
  }

  componentDidMount() {
    this.calcScale();
  }

  componentWillReceiveProps(nextProps) {
    if (this.props.children !== nextProps.children) {
      setTimeout(this.calcScale, 0);
    }
  }

  render() {
    const { styles: s, children, ratio } = this.props;
    const { scale } = this.state;
    return (
      <div ref={this.handleRef('container')} {...s('container')}>
        <div
          ref={this.handleRef('scale')}
          {...s.with('scale')({ transform: `scale(${scale})` })}
        >
          <div
            ref={this.handleRef('inner')}
            {...s.with('inner')({ paddingBottom: `${ratio * 100}%` })}
          >
            <div ref={this.handleRef('text')} {...s('text')}>
              {children}
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default AutoScaleText;

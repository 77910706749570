/* eslint-disable max-lines */
import { Box } from '@rexlabs/box';
import FileUploadInput, {
  getBase64,
  isFileImage
} from '@rexlabs/file-upload-input';
import { withModel } from '@rexlabs/model-generator';
import { styled, StyleSheet } from '@rexlabs/styling';
import { autobind } from 'core-decorators';
import _ from 'lodash';
import React, { PureComponent } from 'react';
import {
  arrayMove,
  SortableContainer as SortableHOCContainer,
  SortableElement as SortableHOCElement
} from 'react-sortable-hoc';
import PlusIcon from 'src/assets/icons/plus.svg';
import uploadsModel from 'src/data/models/custom/uploads';
import imagesModel from 'src/data/models/entities/images';
import libraryImagesModel from 'src/data/models/entities/library-images';
import { BORDER_RADIUS, FONT } from 'src/theme';
import { cropSrc, getDefaultCrop, getImageFromFile } from 'src/utils/images';
import {
  getImageStyle,
  Image as SpokeImage,
  SQUARE_WIDTH
} from 'src/view/components/image';
import { SQUARE_PADDING } from 'src/view/components/image/utils';
import { RenderLoading } from 'src/view/components/loading';
import { Body, Small } from 'src/view/components/text';
import withError from 'src/view/containers/with-error';

const MAX_FILE_SIZE = 20480; // 20MB in KB
const CAPTION_TEXTS = ['Primary', 'Secondary', 'Retargeting'];
const ACCEPT_TYPES = [
  'image/png',
  'image/svg+xml',
  'image/jpg',
  'image/jpeg',
  'image/bmp'
];
const ACCEPT_EXTENSIONS = [
  '.png',
  '.PNG',
  '.svg',
  '.SVG',
  '.jpg',
  '.JPG',
  '.jpeg',
  '.JPEG',
  '.bmp',
  '.BMP'
];

@SortableHOCElement
@styled(
  StyleSheet({
    container: {
      marginTop: '2%',
      marginLeft: '2%'
    }
  })
)
class SortableElement extends PureComponent {
  render() {
    const { styles: s, columns, isSquare, noFileSelected } = this.props;
    return (
      <div
        {...s('container')}
        style={{
          ...(!noFileSelected
            ? getImageStyle(columns, true, isSquare)
            : {
                ...(isSquare
                  ? { width: SQUARE_WIDTH, height: SQUARE_WIDTH }
                  : { width: '100%' })
              })
        }}
      >
        {this.props.children}
      </div>
    );
  }
}

@SortableHOCContainer
@styled(
  StyleSheet({
    container: {
      position: 'relative',
      height: '100%',
      width: '102%',
      marginLeft: '-2%',
      userSelect: 'none'
    }
  })
)
class SortableContainer extends PureComponent {
  render() {
    const {
      styles: s,
      columns,
      isSquare,
      disabled,
      noFileSelected,
      children
    } = this.props;
    return (
      <Box
        {...s('container')}
        alignItems='flex-start'
        justifyContent='flex-start'
        flexWrap='wrap'
      >
        {React.Children.toArray(children)
          .filter(Boolean)
          .map((child, index) => (
            <SortableElement
              key={index}
              index={index}
              disabled={disabled || child?.type.disableSortable}
              columns={columns}
              isSquare={isSquare}
              noFileSelected={noFileSelected}
            >
              {child}
            </SortableElement>
          ))}
      </Box>
    );
  }
}

const inputContainerStylesLandscape = {
  container: {
    display: 'flex !important',
    alignItems: 'center !important',
    justifyContent: 'space-evenly !important',
    paddingBottom: `calc(${(1 / 1.9) * 100}% - 2px) !important`,
    border: ({ token }) =>
      `1px dashed ${token('legacy.color.blue.grey')} !important`,
    borderRadius: `${BORDER_RADIUS.INPUT} !important`,
    cursor: 'pointer !important'
  }
};

const inputContainerStylesSquare = {
  container: {
    display: 'flex !important',
    alignItems: 'center !important',
    justifyContent: 'space-evenly !important',
    paddingBottom: 'calc(100% - 2px) !important',
    border: ({ token }) =>
      `1px dashed ${token('legacy.color.blue.grey')} !important`,
    borderRadius: `${BORDER_RADIUS.INPUT} !important`,
    cursor: 'pointer !important'
  }
};

const inputContainerStylesNoFiles = {
  container: {
    display: 'flex !important',
    alignItems: 'center !important',
    justifyContent: 'space-evenly !important',
    border: ({ token }) =>
      `1px dashed ${token('legacy.color.blue.grey')} !important`,
    borderRadius: `${BORDER_RADIUS.INPUT} !important`,
    cursor: 'pointer !important'
  }
};

const inputContainerStylesNoFilesError = {
  container: {
    ...inputContainerStylesNoFiles.container,
    border: ({ token }) =>
      `1px dashed ${token('legacy.color.red.default')} !important`
  }
};

const defaultStyles = StyleSheet({
  container: {
    width: '100%',
    maxHeight: '50vh',
    overflow: 'auto'
  },

  button: {
    background: ({ token }) => token('color.primary.idle.default'),
    color: ({ token }) => token('color.textStyle.primary.idle.contrast'),
    fontWeight: FONT.WEIGHTS.SEMIBOLD,
    height: '4.4rem',
    paddingLeft: ({ token }) => token('spacing.m'),
    paddingRight: ({ token }) => token('spacing.m'),
    display: 'inline-flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
    width: 'auto',
    flexShrink: 0,
    border: '0 none',
    ':hover': {
      background: ({ token }) => token('color.primary.hover.default')
    }
  },

  inner: {
    position: 'absolute',
    top: '0',
    left: '0',
    right: '0',
    bottom: '0',
    transition: 'background-color 100ms ease-out'
  },

  innerEmpty: {},

  isDragging: {
    cursor: 'copy',
    backgroundColor: 'rgba(0, 0, 0, 0.5)'
  },

  noFileSelected: {
    backgroundColor: ({ token }) => token('color.container.static.contrast'),
    borderRadius: BORDER_RADIUS.INPUT,

    '&::before': {
      content: '" "',
      width: '100%',
      paddingBottom: `${(1 / 1.9) * 100}%`,
      display: 'inline-block'
    }
  },

  firstChild: {
    marginLeft: '0.64rem'
  },

  squareFirstChild: {
    marginLeft: SQUARE_PADDING,

    '&::before': {
      paddingBottom: '100%'
    }
  },

  noFileSelectedSingle: {
    maxHeight: '18rem',
    overflow: 'hidden'
  },

  image: {
    height: '100%'
  },

  icon: {
    width: '1.4rem',
    height: '1.4rem',
    color: ({ token }) => token('legacy.color.blue.grey'),
    marginRight: ({ token }) => token('spacing.xs')
  },

  helper: {
    zIndex: 10000
  }
});

@withError()
@withModel(imagesModel)
@withModel(libraryImagesModel)
@withModel(uploadsModel)
@styled(defaultStyles)
@autobind
class ImageUpload extends PureComponent {
  static defaultProps = {
    columns: 1,
    reverse: false,
    sortable: false,
    selectable: false,
    isCaptioned: false,
    canRemove: true,
    canCrop: true,
    maxSelectable: 1,
    isAppraisal: false,
    initialImages: [],
    selectedImageIds: [],
    unremoveableImages: [],
    onSelected: _.noop,
    onImageClick: _.noop,
    ratio: 'landscape'
  };

  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      initialImageFiles: [],
      images: [],
      selected: []
    };

    this.removeFiles = _.noop;
    this.imagesToRemove = [];
  }

  componentDidMount() {
    if (this.props.status === 'loaded') {
      this.getImages(this.props);
    }
  }

  componentWillReceiveProps(nextProps) {
    const { status } = this.props;
    const { status: nextStatus } = nextProps;
    if (status !== 'loaded' && nextStatus === 'loaded') {
      this.getImages(nextProps);
    }
  }

  getImages(props) {
    const {
      images,
      initialImages,
      selectedImageIds,
      unremoveableImages,
      error,
      ratio
    } = props;
    if (initialImages.length > 0) {
      this.setState({ loading: true }, () => {
        const imagePromises = initialImages.map((image) =>
          images.getImageFromURL({
            url: image.sizes['original.thumbnail'].url,
            config: { meta: image, cache: true }
          })
        );
        Promise.all(imagePromises)
          .then((responses) => {
            const initialImageFiles = responses.map((res) => {
              const imageFile = {};
              imageFile.file = new Blob([res.data], {
                type: res.data.type
              });
              imageFile.file.name = res.config.meta.name;
              imageFile.image = res.config.meta;
              imageFile.selected = selectedImageIds.includes(
                res.config.meta.id
              );
              imageFile.removable = !_.some(unremoveableImages, (id) => {
                return res.config.meta.id === id;
              });
              return imageFile;
            });
            const images = [];
            if (initialImageFiles.length > 0) {
              _.forEach(initialImageFiles, (imageFile, i) => {
                images.push({
                  index: i,
                  name: imageFile.file.name,
                  file: imageFile.file,
                  id: imageFile.image.id,
                  crops: imageFile.image.crops,
                  sizes: imageFile.image.sizes,
                  selected: imageFile.selected,
                  removable: imageFile.removable,
                  isUploading: false,
                  render: initialImages[i].crops[ratio].blob,
                  original: initialImages[i].sizes.original.url
                });
              });
            }
            this.setState({ images, initialImageFiles, loading: false }, () => {
              const selectedImages = this.state.images
                .filter(Boolean)
                .filter((image) => image.selected);
              if (selectedImages.length > 0) {
                _.forEach(selectedImages, this.handleSelectImage);
              }
            });
          })
          .catch((e) => {
            this.setState({ loading: false });
            error.open(e.message);
          });
      });
    } else {
      this.setState({ loading: false });
    }
  }

  handleImageClick(image) {
    const { onImageClick } = this.props;
    this.handleSelectImage(image);
    onImageClick(image);
  }

  handleSelectImage(image, keepSelection = false) {
    const { images, selected } = this.state;
    const { selectable, maxSelectable, onSelected } = this.props;
    if (selectable && !image?.isUploading) {
      let newSelected = selected;
      const imageIndex = images.findIndex((img) => img.file === image.file);
      const selectedIndex = newSelected.indexOf(imageIndex);
      if (selectedIndex > -1 && !keepSelection) {
        newSelected = [
          ...newSelected.slice(0, selectedIndex),
          ...newSelected.slice(selectedIndex + 1, newSelected.length)
        ];
      } else if (newSelected.length >= maxSelectable) {
        newSelected.pop();
        newSelected.unshift(imageIndex);
      } else {
        newSelected.unshift(imageIndex);
      }
      this.setState({ selected: newSelected });
      const selectedImages = newSelected.map((index) => images[index]);
      onSelected(selectedImages);
    }
  }

  handleCloseModal() {
    this.setState({ cropOpen: null });
  }

  async setImageState(
    {
      event,
      index,
      name,
      file,
      uploadId,
      id,
      src,
      crops,
      sizes,
      isUploading,
      original
    },
    cb = _.noop
  ) {
    const { onChange, ratio, canCrop } = this.props;
    const stateImage = this.state.images[index];

    const imageCrop = crops ? crops?.[ratio] : stateImage?.crops?.[ratio];

    // Next we will try to determine the image crop
    // In the core this will be cached by `cropSrc`, so we can call this
    // on every setImage State
    let render = null;
    if (stateImage && imageCrop && canCrop) {
      // Image and crop found and canCrop is set
      render = await cropSrc(
        stateImage?.sizes?.['original.thumbnail'] || stateImage?.original,
        stateImage?.sizes?.original,
        imageCrop
      ).catch((e) => {
        if (__DEV__) {
          console.warn('Could not crop image', { index, e });
        }
      });
    } else if (canCrop && !imageCrop && (!stateImage || isUploading)) {
      // Can crop is set, and the image is a new image that has not yet been uploaded
      // This is mainly for the image shown during the upload process
      const image = await getImageFromFile(file);
      const defaultCrop = getDefaultCrop(image, ratio);
      render = await cropSrc(file, null, defaultCrop?.crop).catch((e) => {
        if (__DEV__) {
          console.warn('Could not crop image', { index, e });
        }
      });
    }

    this.setState(
      (state) => {
        const newState = { ...state };
        newState.images = [...state.images];
        newState.images[index] = {
          ...state.images[index],
          index,
          name: name || state.images[index]?.name,
          file: file || state.images[index]?.file,
          uploadId: uploadId || state.images[index]?.uploadId,
          id: id || state.images[index]?.id,
          src: src || state.images[index]?.src,
          crops: crops || state.images[index]?.crops,
          sizes: sizes || state.images[index]?.sizes,
          isUploading:
            isUploading !== undefined
              ? isUploading
              : state.images[index]?.isUploading,
          render: render || state.images[index]?.render,
          original:
            original ||
            state.images[index]?.original ||
            file ||
            state.images[index]?.file
        };
        return newState;
      },
      () => {
        event.target.files = this.state.images;
        const selectedImages = this.state.selected.map(
          (index) => this.state.images[index]
        );
        onChange(event, selectedImages);
        cb();
      }
    );
  }

  handleChange(e) {
    const { images } = this.state;
    const { error, shouldAllowMultiple, maxSelectable } = this.props;
    const selectImagesFromIndex =
      shouldAllowMultiple && e.target.files.length > 0
        ? Math.max(0, e.target.files.length - maxSelectable)
        : 0;

    _.forEach(e.target.files, (val, i) => {
      ((file, index) => {
        if (isFileImage(file)) {
          const image = images.find((image) => image.file === file);
          if (!image) {
            this.setImageState(
              {
                event: e,
                index,
                file,
                name: file.name,
                isUploading: true
              },
              () => {
                this.uploadFile(file)
                  .then((uploadRes) => {
                    if (file.uploadCancelled) return;

                    const uploadId = uploadRes.data.id;
                    return this.createImageFile({
                      uploadId,
                      name: file.name
                    }).then((imageRes) => {
                      if (file.uploadCancelled) return;

                      this.setImageState(
                        {
                          event: e,
                          index,
                          uploadId,
                          id: imageRes.data.id,
                          crops: imageRes.data.crops,
                          sizes: imageRes.data.sizes,
                          isUploading: false,
                          original: imageRes.data.sizes.original.url
                        },
                        () => {
                          this.clearInvalidFiles();
                          if (i >= selectImagesFromIndex) {
                            this.handleSelectImage(
                              this.state.images[i],
                              !shouldAllowMultiple
                            );
                          }
                        }
                      );
                    });
                  })
                  .catch((err) => {
                    if (file.uploadCancelled) return;

                    const imageToRemove = this.state.images.find(
                      (image) => image.file === file
                    );
                    this.imagesToRemove.push(imageToRemove);
                    this.setImageState(
                      {
                        event: e,
                        index,
                        isUploading: false
                      },
                      this.clearInvalidFiles
                    );
                    error.open(err.message);
                  });
              }
            );
          }
          if (!image || !image.src) {
            getBase64(file).then((src) => {
              this.setImageState({
                event: e,
                index,
                file,
                src
              });
            });
          }
        }
      })(val, i);
    });
  }

  clearInvalidFiles() {
    const { images } = this.state;
    const anyUploading = _.some(images, (image) => image?.isUploading ?? true);
    if (!anyUploading) {
      this.removeImages(this.imagesToRemove);
      this.removeFiles(this.imagesToRemove.map((image) => image.index));
      this.imagesToRemove = [];
    }
  }

  uploadFile(file) {
    const {
      uploads: { uploadFiles }
    } = this.props;
    const formData = new FormData();
    formData.append('file', file);
    return uploadFiles(formData);
  }

  createImageFile({ uploadId, name }) {
    const {
      images: { createItem },
      libraryImages: { createItem: createLibraryItem },
      isAppraisal
    } = this.props;
    if (isAppraisal) {
      return createLibraryItem({
        data: {
          upload: {
            id: uploadId
          },
          name
        }
      }).then(() =>
        createItem({
          data: {
            upload: {
              id: uploadId
            },
            name
          }
        })
      );
    } else {
      return createItem({
        data: {
          upload: {
            id: uploadId
          },
          name
        }
      });
    }
  }

  removeImages(images) {
    const { name, onChange } = this.props;
    this.setState(
      (state) => ({
        ...state,
        images: [
          ...state.images.filter((image) =>
            _.isArray(images)
              ? !images.find((img) => img.file === image.file)
              : images.file === image.file
          )
        ]
      }),
      () => {
        const e = {
          persist: _.noop,
          target: {
            type: 'file',
            name,
            id: name,
            files: this.state.images
          }
        };
        onChange(e);
      }
    );
  }

  handleInvalidFileSize() {
    const { error } = this.props;
    error.open(`File is too large, max file size: ${MAX_FILE_SIZE}KB`);
  }

  handleInvalidFileType() {
    const { error } = this.props;
    error.open(
      `File is the wrong type, accepted extensions: ${_.uniq(
        ACCEPT_EXTENSIONS.map((extension) =>
          extension.substr(1, extension.length).toLowerCase()
        )
      ).join(', ')}`
    );
  }

  handleCropImage(image, crops) {
    const { images } = this.state;
    const { name } = this.props;
    this.setImageState({
      event: {
        persist: _.noop,
        target: {
          type: 'file',
          name: name || image.name,
          id: name || image.name
        }
      },
      index: images.findIndex((img) => img.file === image.file),
      crops
    });
  }

  handleSortEnd({ oldIndex, newIndex }) {
    const { name, reverse, onChange } = this.props;
    const offset = reverse ? 1 : 0;
    this.setState(
      (state) => ({
        images: arrayMove(state.images, oldIndex + offset, newIndex + offset)
      }),
      () => {
        const e = {
          persist: _.noop,
          target: {
            type: 'file',
            name,
            id: name,
            files: this.state.images
          }
        };
        onChange(e);
      }
    );
  }

  handleCancelStart(e) {
    return e.target.tagName.toLowerCase() !== 'div';
  }

  renderInputContainer({
    InputContainer,
    inputContainerStyles,
    inputProps,
    dragEvents,
    isDragging,
    noFileSelected
  }) {
    const { styles: s, isSquare, shouldAllowMultiple, columns } = this.props;
    InputContainer.disableSortable = true;
    return (
      <InputContainer
        {...inputProps}
        {...dragEvents}
        styles={StyleSheet({
          ...(isSquare
            ? {
                ...inputContainerStyles,
                container: {
                  ...inputContainerStyles.container,
                  height: SQUARE_WIDTH,
                  width: SQUARE_WIDTH
                }
              }
            : inputContainerStyles)
        })}
      >
        {noFileSelected && (
          <Box
            key='inner'
            {...s('inner', 'innerEmpty', { isDragging })}
            flexDirection='column'
            alignItems='center'
            justifyContent='center'
          >
            <div {...s('button')}>
              Upload {shouldAllowMultiple ? 'Photos' : 'Photo'}
            </div>
            <Small grey>
              {!isDragging
                ? `or drag ${shouldAllowMultiple ? 'them' : 'it'} in`
                : 'drop here'}
            </Small>
          </Box>
        )}
        {noFileSelected &&
          _.times(shouldAllowMultiple ? columns : 1, (i) => (
            <div
              key={i}
              {...s('noFileSelected', {
                noFileSelectedSingle: !shouldAllowMultiple,
                firstChild: i === 0,
                squareFirstChild: i === 0 && isSquare
              })}
              style={getImageStyle(
                shouldAllowMultiple ? columns : 1,
                false,
                isSquare
              )}
            />
          ))}
        {!noFileSelected && (
          <Box
            {...s('inner', { isDragging })}
            flexDirection='row'
            alignItems='center'
            justifyContent='center'
          >
            <PlusIcon {...s('icon')} />
            <Body grey>
              {shouldAllowMultiple ? 'Add another' : 'Change image'}
            </Body>
          </Box>
        )}
      </InputContainer>
    );
  }

  render() {
    const { loading, initialImageFiles, images, selected } = this.state;
    const {
      styles: s,
      campaignId,
      agentId,
      listingId,
      columns,
      reverse,
      sortable,
      selectable,
      maxSelectable,
      shouldAllowMultiple,
      overlayText,
      isCaptioned,
      isSquare,
      canRemove,
      canCrop,
      onCropClick,
      ratio,
      error: { Error },
      children,
      ...rest
    } = this.props;
    const anyUploading = _.some(images, (image) => image?.isUploading ?? true);
    const initialFiles = initialImageFiles.map((imageFile) => imageFile.file);

    return (
      <RenderLoading isLoading={loading} minHeight='15rem'>
        <FileUploadInput
          {...rest}
          shouldAllowMultiple={shouldAllowMultiple}
          acceptTypes={ACCEPT_TYPES}
          acceptExtensions={ACCEPT_EXTENSIONS}
          initialFiles={initialFiles}
          maxFileSize={MAX_FILE_SIZE}
          onChange={this.handleChange}
          onInvalidFileSize={this.handleInvalidFileSize}
          onInvalidType={this.handleInvalidFileType}
        >
          {({
            InputContainer,
            inputProps,
            dragEvents,
            files,
            removeFiles,
            isDragging
          }) => {
            const noFileSelected = images.length <= 0;
            const inputContainerProps = {
              InputContainer,
              inputContainerStyles: noFileSelected
                ? this.props?.meta?.error
                  ? inputContainerStylesNoFilesError
                  : inputContainerStylesNoFiles
                : ratio === 'landscape'
                ? inputContainerStylesLandscape
                : inputContainerStylesSquare,
              inputProps,
              dragEvents,
              isDragging,
              noFileSelected
            };
            this.removeFiles = removeFiles;

            return (
              <Box
                {...s('container')}
                alignItems='flex-start'
                justifyContent='flex-start'
                flexWrap='wrap'
                data-testid='images-loaded'
              >
                <SortableContainer
                  axis='xy'
                  columns={shouldAllowMultiple ? columns : 2}
                  isSquare={isSquare}
                  disabled={!sortable || anyUploading}
                  helperClass={defaultStyles.helper}
                  noFileSelected={noFileSelected}
                  onSortEnd={this.handleSortEnd}
                  shouldCancelStart={this.handleCancelStart}
                >
                  {reverse && this.renderInputContainer(inputContainerProps)}
                  {images.length > 0 &&
                    images
                      .filter(Boolean)
                      .filter((image) => isFileImage(image.file))
                      .map((image, index) => {
                        return (
                          <SpokeImage
                            key={`${image.file.name}_${image.file.size}`}
                            ratio={ratio}
                            image={image}
                            fileIndex={files.indexOf(image.file)}
                            campaignId={campaignId}
                            agentId={agentId}
                            listingId={listingId}
                            selected={!!selected.includes(index)}
                            maxSelected={selected.length >= maxSelectable}
                            selectable={selectable}
                            overlayText={overlayText}
                            isSquare={isSquare}
                            canRemove={canRemove && (image?.removable ?? true)}
                            canCrop={canCrop}
                            captionText={isCaptioned && CAPTION_TEXTS[index]}
                            removeFiles={removeFiles}
                            removeImages={this.removeImages}
                            onClick={this.handleImageClick}
                            onCropImage={this.handleCropImage}
                          />
                        );
                      })}
                  {!reverse && this.renderInputContainer(inputContainerProps)}
                </SortableContainer>
                <Error />

                {children}
              </Box>
            );
          }}
        </FileUploadInput>
      </RenderLoading>
    );
  }
}

export default ImageUpload;

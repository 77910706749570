import { Box } from '@rexlabs/box';
import { styled, StyleSheet } from '@rexlabs/styling';
import { autobind } from 'core-decorators';
import React, { PureComponent } from 'react';
import { withToken } from 'src/theme';
import { number } from 'src/utils/format';
import { Small } from 'src/view/components/text';

import ChevronIcon from 'src/assets/icons/chevron-right.svg';

const defaultStyles = StyleSheet({
  container: {
    marginLeft: ({ token }) => token('spacing.m'),
    marginRight: ({ token }) => token('spacing.m')
  },

  wrapper: {
    width: '100%',
    padding: ({ token }) => token('spacing.xl'),
    paddingTop: ({ token }) => token('spacing.m'),
    paddingBottom: ({ token }) => token('spacing.m'),
    paddingLeft: ({ token }) => token('spacing.xs'),
    flexShrink: 0,

    '> *': {
      flexShrink: 0
    },

    '@media print': {
      padding: ({ token }) => `${token('spacing.xxs')} 0`
    }
  },

  chevronIcon: {
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'center',
    width: '5rem',
    ':hover': {
      cursor: 'pointer'
    },
    '> svg': {
      color: ({ token }) => token('legacy.color.blue.grey'),
      transform: 'rotate(90deg)'
    },

    '@media print': {
      display: 'none'
    }
  },

  chevronIconExpand: {
    '> svg': {
      transform: 'rotate(90deg) scale(-1, 1)'
    }
  },

  itemsContainer: {
    maxHeight: '0',
    overflow: 'hidden',
    transition: 'max-height 100ms ease-out',

    '@media print': {
      display: 'none'
    }
  },

  total: {
    paddingRight: ({ token }) => token('spacing.xl'),
    width: '12rem',

    '@media print': {
      width: '14rem',
      paddingRight: 0
    }
  },

  totalPercent: {
    display: 'none',

    '@media print': {
      display: 'inline-block',
      whiteSpace: 'pre',
      color: ({ token }) => token('legacy.color.blue.grey')
    }
  }
});

const networkLabel = {
  adwords: 'Google',
  facebook: 'Facebook',
  instagram: 'Instagram',
  messenger: 'Messenger',
  other: 'Other'
};

@withToken
@styled(defaultStyles)
@autobind
class StatsListItem extends PureComponent {
  constructor(props) {
    super(props);
    this.itemEl = React.createRef();
    this.state = {
      showBreakdown: false
    };
  }

  onClick() {
    this.setState({ showBreakdown: !this.state.showBreakdown }, () => {
      const itemsContainer =
        this.itemEl.current && this.itemEl.current.children[1];

      if (itemsContainer && itemsContainer.style.maxHeight) {
        itemsContainer.style.maxHeight = null;
      } else {
        itemsContainer.style.maxHeight = `${itemsContainer.scrollHeight}px`;
      }
    });
  }

  render() {
    const {
      stat,
      renderFunc = number,
      showPercentage = true,
      aggregateFunc = (acc, stat) => (acc += stat.value),
      token,
      styles: s
    } = this.props;

    const total = stat.data.reduce(aggregateFunc, 0);

    return (
      <div {...s('container')} ref={this.itemEl}>
        <Box
          {...s('wrapper')}
          flexDirection='row'
          alignItems='center'
          justifyContent='space-between'
          onClick={this.onClick}
        >
          <Box
            flexDirection='row'
            alignItems='center'
            flex={1}
            pr={token('spacing.xl')}
          >
            <Small>{stat.label}</Small>
          </Box>

          <Box {...s('total')}>
            <Small>{renderFunc(total)} </Small>
          </Box>

          <Box
            {...s('chevronIcon', {
              chevronIconExpand: this.state.showBreakdown
            })}
          >
            <ChevronIcon />
          </Box>
        </Box>
        <Box {...s('itemsContainer')}>
          {stat.data.map((network) => (
            <Box
              key={`${network.type_id}${network.network_id}`}
              {...s('container')}
              flexDirection='row'
              alignItems='center'
              justifyContent='space-between'
              pt={token('spacing.xs')}
              pb={token('spacing.m')}
            >
              <Box
                flexDirection='row'
                alignItems='center'
                flex={1}
                pr={token('spacing.xl')}
                ml={token('spacing.xxl')}
              >
                <Small style={{ color: token('legacy.color.blue.grey') }}>
                  {networkLabel[network.network_id]}
                </Small>
              </Box>
              <Box style={{ width: '17rem' }} pr={token('spacing.xl')}>
                <Small>
                  {renderFunc(network.value)}{' '}
                  {showPercentage && (
                    <span style={{ color: token('legacy.color.blue.grey') }}>
                      (
                      {parseFloat(Math.round((network.value / total) * 100)) ||
                        0}
                      %)
                    </span>
                  )}
                </Small>
              </Box>
            </Box>
          ))}
        </Box>
      </div>
    );
  }
}

export default StatsListItem;

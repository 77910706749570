import type { Coord, Feature, LineString, Polygon } from 'src/lib/turf';
import { FillPaint, LinePaint, LngLatLike } from 'mapbox-gl';
import type { Coordinate, Units } from 'src/types';
import { circle, polygonToLine } from 'src/lib/turf';

export const normaliseLngLat = (coord: LngLatLike): Coordinate => {
  if (Array.isArray(coord)) return { longitude: coord[0], latitude: coord[1] };

  const longitude = 'lng' in coord ? coord.lng : coord.lon;
  return {
    longitude,
    latitude: coord.lat
  };
};

export type CircleFeaturesOptions = {
  center: Coord;
  radius: number;
  unit: Units;
  steps?: number;
  style: {
    fillColor: string;
    fillOpacity: number;
    strokeColor: string;
    strokeWidth: number;
    strokeOpacity: number;
  };
};

type FillProperties = Required<Pick<FillPaint, 'fill-color' | 'fill-opacity'>>;
type StrokeProperties = Required<
  Pick<LinePaint, 'line-color' | 'line-opacity' | 'line-width'>
>;

export type CircleFeatures = {
  fill: Feature<Polygon, FillProperties>;
  stroke: Feature<LineString, StrokeProperties>;
};

/**
 * Create the fill and stroke features for a circle. Supports styling using MapboxGL style properties.
 * @param CircleFeaturesOptions options
 */
export const createCircleFeatures = ({
  center,
  radius,
  unit,
  steps,
  style
}: CircleFeaturesOptions): CircleFeatures => {
  const fill = circle(center, radius, {
    units: unit,
    steps,
    properties: {
      'fill-color': style.fillColor,
      'fill-opacity': style.fillOpacity
    }
  }) as Feature<Polygon, FillProperties>;

  const stroke = polygonToLine<Polygon>(fill, {
    properties: {
      'line-color': style.strokeColor,
      'line-width': style.strokeWidth,
      'line-opacity': style.strokeOpacity
    }
  }) as Feature<LineString, StrokeProperties>;

  return {
    fill,
    stroke
  };
};

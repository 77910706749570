import { getAccountRegion } from '../../../../utils/region';

export function getBannerLabel(session) {
  const countryCode = getAccountRegion(session);

  if (countryCode === 'GB') {
    return 'SOLD STC';
  }

  return 'JUST SOLD';
}

import { useMutation } from 'react-query';
import { useAccountId } from 'src/features/accounts';
import { MutationResult } from 'src/lib/react-query';
import { api } from 'src/utils/api-client';
import { Image, ImageDTO } from '../types';
import { createImage } from '../utils/schemas';

export type UpdateImageParams = {
  accountId: string;
  imageId: string;
  image: Partial<ImageDTO>;
};

export const updateImage = async ({
  image,
  imageId
}: UpdateImageParams): Promise<Image> => {
  const { data } = await api.patch(`images/${imageId}`, image);
  return createImage(data);
};

export type UseUpdateImageVariables = Omit<UpdateImageParams, 'accountId'>;

export const useUpdateImage = (): MutationResult<
  Image,
  UseUpdateImageVariables
> => {
  const accountId = useAccountId();
  return useMutation({
    mutationFn: (params) => updateImage({ accountId, ...params })
  });
};

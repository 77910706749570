/* eslint-disable max-lines */
import { Box } from '@rexlabs/box';
import { NeutralBanner } from '@rexlabs/notifications';
import { Body } from '@rexlabs/text';
import isEqual from 'lodash/isEqual';
import pick from 'lodash/pick';
import React, { Component } from 'react';
import InfoIcon from 'src/assets/icons/info.svg';
import config from 'src/config';
import WIZARD from 'src/routes/wizard';
import { Link } from 'src/utils/whereabouts';
import ComponentRenderer from 'src/view/components/component-renderer';
import {
  FieldCell,
  FieldRow,
  FormField,
  HiddenField
} from 'src/view/components/form';
import {
  ColorPicker,
  SoftCharLimitTextArea as TextArea,
  SoftCharLimitTextInput as TextInput
} from 'src/view/components/input';
import BannerEdit from 'src/view/components/input/banner-edit';
import EditListingAgent from 'src/view/components/input/edit-listing-agent';
import AgentImageInput from 'src/view/components/input/image/agent-image-input';
import LogoImageInput from 'src/view/components/input/image/logo-image-input';
import PropertyImageInput from 'src/view/components/input/image/property-image-input';
import PropertyDetailsInput from 'src/view/components/input/property-details';
import VideoInput from 'src/view/components/input/video/upload-video';
import RenderChildren from 'src/view/components/render-children';
import { Small } from 'src/view/components/text';
import { HelpIcon } from 'src/view/components/tooltip';
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import { withToken } from 'src/theme';

const validateCore = {
  'prospecting.short_headline': [
    'required',
    'short headline',
    ['single_listing', 'appraisal', 'agent_profile']
  ],
  'prospecting.long_headline': [
    'required',
    'long headline',
    ['single_listing', 'appraisal', 'agent_profile']
  ],
  'prospecting.banner_text': [
    'required',
    'banner text',
    ['single_listing', 'banner_text_toggled']
  ],
  'prospecting.small_text': ['required', 'ad label text', ['single_listing']],
  'prospecting.description': [
    'required',
    'description',
    ['single_listing', 'appraisal', 'agent_profile']
  ],
  'prospecting.agent_name': ['required', 'agent name', ['agent_profile']],
  'prospecting.agent_position': [
    'required',
    'agent position',
    ['agent_profile']
  ],
  'prospecting.agency_logo': [
    'required',
    'agency logo',
    ['single_listing', 'appraisal', 'agent_profile']
  ],
  'prospecting.brand_color': [
    'required',
    'brand color',
    ['single_listing', 'appraisal', 'agent_profile']
  ],
  'prospecting.background_color': [
    'required',
    'backgroundf color',
    ['single_listing', 'appraisal', 'agent_profile']
  ],
  'prospecting.final_url': [
    'required|url',
    'URL',
    ['appraisal', 'agent_profile']
  ],
  'prospecting.agent_image': ['required', 'agent photo', ['agent_profile']],
  'prospecting.agent.agent_image': [
    'required',
    'agent photo',
    ['single_listing', 'agent_listing_toggled']
  ],
  'prospecting.agent.agent_name': [
    'required',
    'agent name',
    ['single_listing', 'agent_listing_toggled']
  ]
};

const validateImages = {
  'prospecting.primary_image': [
    'required',
    'primary image',
    ['single_listing', 'appraisal']
  ],
  'prospecting.secondary_image': [
    'required',
    'secondary image',
    ['single_listing', 'appraisal']
  ]
};

const validateVideo = {
  'prospecting.video': ['required', 'video']
};

const validateFinalUrl = {
  'prospecting.final_url': [
    'required|url',
    'URL',
    ['appraisal', 'agent_profile']
  ]
};

export function validationRules(values) {
  if (values.campaign_type === 'appraisal') {
    if (values.prospecting.video_only) {
      return {
        ...validateCore,
        ...validateVideo,
        ...validateFinalUrl
      };
    }
    return {
      ...validateCore,
      ...validateImages,
      ...validateFinalUrl
    };
  }

  if (values.campaign_type === 'agent_profile') {
    return {
      ...validateCore,
      ...validateImages,
      ...validateFinalUrl
    };
  }

  if (values.campaign_type === 'multi_listing_sold') {
    // Dyanmically build up the slide vaalidations based on the number of slides and
    // what fields each slide has values for
    const validateSlides = (values.prospecting?.slides || []).reduce(
      (acc, slide, idx) => {
        Object.keys(slide).forEach((key) => {
          if (['short_headline', 'long_headline', 'small_text'].includes(key)) {
            acc[`prospecting.slides.${idx}.${key}`] = [
              'required',
              key.replace('_', ' ')
            ];
          }
        });

        return acc;
      },
      {}
    );

    return { ...validateCore, ...validateSlides };
  }

  return { ...validateCore, ...validateImages };
}

export function prepareData(values, { props }) {
  const { campaigns } = props;

  let prospectingPicks = [];
  let retargetingPicks = [];

  const campaignType = campaigns?.item?.data?.type?.id;
  const sets = campaigns?.item?.data?.ad_content_sets?.items ?? [];

  const prospecting = sets.find((i) => i.type.id.endsWith('.prospecting'));
  const retargeting = sets.find((i) => i.type.id.endsWith('.retargeting'));

  const primaryImage = {
    id: values?.prospecting?.primary_image?.id
  };
  const secondaryImage = {
    id: values?.prospecting?.secondary_image?.id
  };
  const agencyLogo = {
    id: values?.prospecting?.agency_logo?.id
  };
  const videoId = values?.prospecting?.video?.id;
  const video = videoId
    ? {
        id: videoId
      }
    : null;
  const agentImageId =
    campaignType === 'single_listing'
      ? values?.prospecting?.agent?.agent_image?.id
      : values?.prospecting?.agent_image?.id;
  const agentImage = agentImageId ? { id: agentImageId } : { id: null };

  const retargetingPrimaryImage = {
    id: values?.retargeting?.primary_image?.id || null
  };
  const retargetingSecondaryImage = {
    id: values?.retargeting?.secondary_image?.id || null
  };
  const retargetingAgentImage = {
    id: values?.retargeting?.agent_image?.id
  };

  const prospectingSlides = (values?.prospecting?.slides ?? []).map((s) => ({
    ...s,
    image: { id: s.image.id }
  }));

  const common = ['short_headline', 'long_headline', 'description'];
  if (campaignType === 'single_listing') {
    prospectingPicks = [
      ...common,
      'banner_text',
      'small_text',
      'brand_color',
      'background_color',
      'primary_image',
      'secondary_image',
      'video',
      'agency_logo',
      'agency_name',
      'agent_image',
      'agent_name'
    ];
  } else if (campaignType === 'appraisal') {
    prospectingPicks = [
      ...common,
      'brand_color',
      'background_color',
      'primary_image',
      'secondary_image',
      'agency_logo',
      'video'
    ];
  } else if (campaignType === 'agent_profile') {
    prospectingPicks = [
      ...common,
      'agent_name',
      'agent_position',
      'brand_color',
      'background_color',
      'logo_image',
      'agent_image',
      'video'
    ];
  } else if (campaignType === 'multi_listing_sold') {
    prospectingPicks = ['description', 'slides'];
  }

  const prospectingAttributes = {
    primary_image: primaryImage,
    secondary_image: secondaryImage,
    video: video,
    small_text: values?.prospecting?.small_text ?? '',
    short_headline: values?.prospecting?.short_headline ?? '',
    long_headline: values?.prospecting?.long_headline ?? '',
    banner_text: values?.prospecting?.banner_text?.value ?? null,
    description: values?.prospecting?.description ?? '',
    agency_logo: agencyLogo,
    agency_name: values?.prospecting?.agent?.agency_name ?? '',
    brand_color: values?.prospecting?.brand_color ?? '',
    background_color: values?.prospecting?.background_color ?? '',
    agent_image: agentImage,
    agent_name:
      campaignType === 'single_listing'
        ? values?.prospecting?.agent?.agent_name
        : values?.prospecting?.agent_name ?? '',
    agent_position: values?.prospecting?.agent_position ?? '',
    suburbs: values?.prospecting?.suburbs ?? '',
    slides: prospectingSlides.length > 0 ? prospectingSlides : ''
  };

  if (campaignType === 'single_listing') {
    retargetingPicks = [...common, 'primary_image', 'secondary_image'];
  } else if (campaignType === 'appraisal') {
    retargetingPicks = [...common, 'primary_image', 'secondary_image'];
  } else if (campaignType === 'agent_profile') {
    retargetingPicks = [...common, 'agent_image'];
  }

  const retargetingAttributes = {
    primary_image: retargetingPrimaryImage,
    secondary_image: retargetingSecondaryImage,
    agent_image: retargetingAgentImage,
    short_headline: values?.retargeting?.short_headline ?? '',
    long_headline: values?.retargeting?.long_headline ?? '',
    description: values?.retargeting?.description ?? ''
  };

  return {
    prospecting: {
      ...prospecting,
      final_url: values?.prospecting?.final_url ?? '',
      attributes: pick(prospectingAttributes, prospectingPicks)
    },
    retargeting: {
      // NOTE, we want to send all the prospecting values to retargeting
      // as well, even though just a few of them are actually editable
      // for retargeting specifically!
      ...retargeting,
      final_url: values?.prospecting?.final_url ?? '',
      attributes: {
        ...pick(prospectingAttributes, prospectingPicks),
        ...pick(retargetingAttributes, retargetingPicks)
      }
    }
  };
}

export function handleSubmit(values, { props }) {
  const { campaigns } = props;
  const actions = [];
  const data = prepareData(values, { props });

  // Prospecting
  actions.push(
    campaigns.updateContentSet({
      id: data?.prospecting?.id,
      campaignId: campaigns?.item?.data?.id,
      data: {
        ...data.prospecting,
        attributes: {
          ...data.prospecting.attributes,
          agency_logo: {
            id: values?.prospecting?.agency_logo?.id
          }
        }
      }
    })
  );

  actions.push(
    campaigns.updateItem({
      data: {
        agency_logo: {
          id: values?.prospecting?.agency_logo?.id,
          name: values?.prospecting?.agency_logo?.name
        }
      }
    })
  );

  return Promise.all(actions).then(() =>
    campaigns.refresh({
      args: {
        include:
          'audience_spec,ad_content_sets,images.sizes,agency_logo.sizes,agent_images.sizes,videos.thumbnail.sizes'
      }
    })
  );
}

@withToken
class EditAdsForm extends Component {
  shouldComponentUpdate(nextProps) {
    if (isEqual(this.props, nextProps)) {
      return false;
    }
    return true;
  }

  updatePreviewOnEnter(e) {
    const key = e.which || e.keyCode;
    if (key === 13) {
      this.props.change(e.target.name, e.target.value, true);
    }
  }

  renderLogoInput(props) {
    return (
      <Box style={{ width: '12rem' }}>
        <LogoImageInput {...props} />
      </Box>
    );
  }

  items = [
    {
      name: 'prospecting',
      label: 'Prospecting',
      Tab: () => {
        const {
          campaignId,
          contentSource,
          adContentSets,
          propertyImages,
          status,
          agentImages,
          change,
          values,
          slides,
          campaignTemplate,
          token,
          campaignNetworks,
          leadGenerationFormEnabled
        } = this.props;

        const prospecting = adContentSets.find((i) =>
          i.type.id.endsWith('.prospecting')
        );
        const retargeting = adContentSets.find((i) =>
          i.type.id.endsWith('.retargeting')
        );
        const primaryImageId = prospecting?.attributes?.primary_image?.id;
        const secondaryImageId = prospecting?.attributes?.secondary_image?.id;
        const logoImageId = prospecting?.attributes?.agency_logo?.id;
        const agentImageId = prospecting?.attributes?.agent_image?.id;

        const retargetingPrimaryImageId =
          retargeting?.attributes?.primary_image?.id;
        const retargetingSecondaryImageId =
          retargeting?.attributes?.secondary_image?.id;
        const retargetingAgentImageId =
          retargeting?.attributes?.agent_image?.id;

        const unremoveableImages = {
          primary: primaryImageId,
          secondary: secondaryImageId,
          retargetingPrimary: retargetingPrimaryImageId,
          retargetingSecondary: retargetingSecondaryImageId,
          logo: logoImageId,
          agent: agentImageId,
          retargetingAgent: retargetingAgentImageId
        };

        const agentHasValues =
          !!values?.prospecting?.agent?.agent_image ||
          !!values?.prospecting?.agent?.agent_name ||
          !!values?.prospecting?.agent?.agency_name;

        const bannerTextHasValues = !!values?.banner_text_toggled;
        const backgroundColor = values?.prospecting?.background_color;
        const facebookNetworkActive =
          campaignNetworks?.find((o) => o.id === 'facebook')?.enabled &&
          leadGenerationFormEnabled;

        return (
          <Box
            mt={token('spacing.xs')}
            mb={token('spacing.xxl')}
            style={{ width: '100%' }}
          >
            {facebookNetworkActive && (
              <Box mb={token('spacing.l')}>
                <NeutralBanner
                  Icon={InfoIcon}
                  title='Facebook lead maximiser active'
                >
                  Lead maximiser will increase your lead volume. You can change
                  your selection on the{' '}
                  <Link
                    to={WIZARD.EDIT_AUDIENCE}
                    styles={{ anchor: { fontWeight: '500' } }}
                  >
                    edit audience
                  </Link>{' '}
                  page.
                </NeutralBanner>
              </Box>
            )}
            <ComponentRenderer
              template={campaignTemplate}
              resource='ads'
              section='edit'
              components={{
                RenderChildren,
                HiddenField,
                FieldRow,
                FieldCell,
                FormField,
                PropertyImageInput,
                AgentImageInput,
                VideoInput,
                TextInput,
                TextArea,
                ColorPicker,
                BannerEdit,
                EditListingAgent,
                PropertyDetailsInput // only just sold should lazy load it
              }}
              extraPropsFn={({ type, props: componentProps }) => {
                let extraProps;

                if (
                  type === 'RenderChildren' &&
                  (componentProps.id === 'prospecting.primary_image' ||
                    componentProps.id === 'prospecting.secondary_image')
                ) {
                  extraProps = {
                    renderChildren: !values.prospecting?.video_only ?? false
                  };
                }

                if (
                  type === 'FormField' &&
                  componentProps.name === 'prospecting.primary_image'
                ) {
                  extraProps = {
                    inputProps: {
                      ...componentProps.inputProps,
                      initialImages: propertyImages,
                      status,
                      campaignId,
                      unremoveableImages
                    }
                  };
                }

                if (
                  type === 'FormField' &&
                  componentProps.name === 'prospecting.secondary_image'
                ) {
                  extraProps = {
                    label: <span style={{ whiteSpace: 'pre' }}> </span>,
                    inputProps: {
                      ...componentProps.inputProps,
                      initialImages: propertyImages,
                      status,
                      campaignId,
                      unremoveableImages
                    }
                  };
                }

                if (
                  type === 'FormField' &&
                  componentProps.name === 'prospecting.video' &&
                  values?.prospecting?.video_only
                ) {
                  extraProps = {
                    inputProps: {
                      canDelete: false
                    }
                  };
                }

                if (
                  type === 'FormField' &&
                  componentProps.name === 'prospecting.agent_image'
                ) {
                  extraProps = {
                    inputProps: {
                      ...componentProps.inputProps,
                      initialImages: agentImages,
                      status,
                      campaignId,
                      unremoveableImages
                    }
                  };
                }

                if (
                  type === 'FormField' &&
                  componentProps.name === 'prospecting.short_headline'
                ) {
                  extraProps = {
                    inputProps: {
                      ...componentProps.inputProps
                    },
                    label: (
                      <span>
                        Short Headline{' '}
                        <HelpIcon
                          Content={() => (
                            <Box style={{ width: '30rem' }}>
                              <Body>
                                Add a brief headline to let people know what
                                your ad is about.
                              </Body>
                              <Body>
                                We don’t strictly enforce the character limits
                                so type away. The gotcha here is that titles and
                                descriptions may be cut off in some cases -
                                especially on mobile. Be extra careful with the
                                marketing slogan.
                              </Body>
                              <Body>
                                Headlines aren’t allowed to have excessive or
                                unnecessary punctuation or symbols such as the
                                following: “!”, “~” and “*”.
                              </Body>
                            </Box>
                          )}
                        />
                      </span>
                    )
                  };
                }

                if (
                  type === 'FormField' &&
                  componentProps.name === 'prospecting.long_headline'
                ) {
                  extraProps = {
                    inputProps: {
                      ...componentProps.inputProps
                    },
                    label: (
                      <span>
                        Long Headline{' '}
                        <HelpIcon
                          Content={() => (
                            <Box style={{ width: '30rem' }}>
                              <Body>
                                Add a long headline to let people know what your
                                ad is about.
                              </Body>
                              <Body>
                                We don’t strictly enforce the character limits
                                so type away. The gotcha here is that titles and
                                descriptions may be cut off in some cases -
                                especially on mobile. Be extra careful with the
                                marketing slogan.
                              </Body>
                              <Body>
                                Headlines aren’t allowed to have excessive or
                                unnecessary punctuation or symbols such as the
                                following: “!”, “~” and “*”.
                              </Body>
                            </Box>
                          )}
                        />
                      </span>
                    )
                  };
                }

                if (
                  type === 'FormField' &&
                  componentProps.name === 'prospecting.small_text'
                ) {
                  extraProps = {
                    inputProps: {
                      ...componentProps.inputProps
                    },
                    label: (
                      <span>
                        Ad label text{' '}
                        <HelpIcon
                          Content={() => (
                            <Box style={{ width: '30rem' }}>
                              <Body>
                                Add a small snippet of text to give more context
                                to your ads. This will appear on Facebook,
                                Instagram and Google Display Banner ads.
                              </Body>
                            </Box>
                          )}
                        />
                      </span>
                    )
                  };
                }

                if (
                  type === 'FormField' &&
                  componentProps.name === 'prospecting.description'
                ) {
                  extraProps = {
                    inputProps: {
                      ...componentProps.inputProps
                    },
                    label: (
                      <span>
                        Description{' '}
                        <HelpIcon
                          Content={() => (
                            <Box style={{ width: '30rem' }}>
                              <Body>
                                Add a description to give more details about
                                your ad. This won’t appear in all ad variations.
                              </Body>
                              <Body>
                                We don’t strictly enforce the character limits
                                so type away. The gotcha here is that titles and
                                descriptions may be cut off in some cases -
                                especially on mobile. Be extra careful with the
                                marketing slogan.
                              </Body>
                            </Box>
                          )}
                        />
                      </span>
                    )
                  };
                }

                if (
                  type === 'FormField' &&
                  componentProps.name === 'prospecting.agency_logo'
                ) {
                  extraProps = {
                    Input: this.renderLogoInput,
                    inputProps: {
                      ...componentProps.inputProps,
                      status,
                      campaignId,
                      unremoveableImages,
                      backgroundColor
                    }
                  };
                }

                if (type === 'BannerEdit') {
                  extraProps = {
                    hasValues: bannerTextHasValues,
                    change
                  };
                }

                if (type === 'EditListingAgent') {
                  extraProps = {
                    agentValues: values?.prospecting?.agent ?? {},
                    hasValues: agentHasValues,
                    initialImages: agentImages,
                    status: status,
                    campaignId: campaignId,
                    unremoveableImages: unremoveableImages,
                    change: change
                  };
                }

                if (type === 'PropertyDetailsInput') {
                  extraProps = {
                    values: values,
                    initialSlides: slides,
                    campaignId: campaignId,
                    contentSource: contentSource,
                    images: propertyImages,
                    backgroundColor: backgroundColor
                  };
                }

                return extraProps;
              }}
            />
          </Box>
        );
      }
    },
    {
      name: 'retargeting',
      label: config.SHOW_RETARGETING
        ? 'Retargeting'
        : 'Retargeting (Coming Soon)',
      isDisabled: !config.SHOW_RETARGETING,
      Tab: () => {
        if (!config.SHOW_RETARGETING) {
          return null;
        }

        const {
          campaignId,
          adContentSets,
          propertyImages,
          agentImages,
          status,
          campaignType,
          token
        } = this.props;

        const retargeting = adContentSets.find((i) =>
          i.type.id.endsWith('.retargeting')
        );
        const primaryImageId = retargeting?.attributes?.primary_image?.id;
        const secondaryImageId = retargeting?.attributes?.secondary_image?.id;
        const agentImageId = retargeting?.attributes?.agent_image?.id;

        const unremoveableImages = {
          primary: primaryImageId,
          secondary: secondaryImageId,
          agent: agentImageId
        };

        return (
          <Box mt={token('spacing.xxxl')} mb={token('spacing.xxl')}>
            <HiddenField name='campaign_type' />
            <HiddenField name='slides' />

            {campaignType === 'single_listing' ? (
              <Small grey>
                Ads displayed to people who have visited your website. Bring
                them back to your site by highlighting property features they
                might’ve missed.
              </Small>
            ) : campaignType === 'appraisal' ? (
              <Small grey>
                Ads displayed to people who have visited your website.
              </Small>
            ) : (
              <Small grey>
                Ads displayed to people who have visited your website.
              </Small>
            )}

            {campaignType !== 'agent_profile' ? (
              <Box style={{ width: '100%' }}>
                <FieldRow>
                  <FieldCell width={1 / 2}>
                    <FormField
                      name='retargeting.primary_image'
                      Input={PropertyImageInput}
                      inputProps={{
                        label: 'Primary',
                        type: 'primary',
                        initialImages: propertyImages,
                        status,
                        campaignId,
                        unremoveableImages
                      }}
                      label={
                        campaignType === 'appraisal'
                          ? 'Marketing Images'
                          : 'Property Images'
                      }
                      sendImmediate
                    />
                  </FieldCell>
                  <FieldCell width={1 / 2}>
                    <FormField
                      name='retargeting.secondary_image'
                      Input={PropertyImageInput}
                      inputProps={{
                        label: 'Secondary',
                        type: 'secondary',
                        initialImages: propertyImages,
                        status,
                        campaignId,
                        unremoveableImages
                      }}
                      label='&nbsp;'
                      sendImmediate
                    />
                  </FieldCell>
                </FieldRow>
              </Box>
            ) : (
              <FieldRow>
                <Box style={{ width: '100%' }}>
                  <FieldCell width={1 / 2}>
                    <FormField
                      name='retargeting.agent_image'
                      Input={AgentImageInput}
                      inputProps={{
                        type: 'agent',
                        initialImages: agentImages,
                        status,
                        campaignId,
                        unremoveableImages,
                        noBorder: true,
                        canCrop: true
                      }}
                      label='Agent Photo'
                      sendImmediate
                    />
                  </FieldCell>
                </Box>
              </FieldRow>
            )}

            <FormField
              name='retargeting.short_headline'
              label={
                <span>
                  Short Headline{' '}
                  <HelpIcon
                    Content={() => (
                      <Box style={{ width: '30rem' }}>
                        <Body>
                          Add a brief headline to let people know what your ad
                          is about.
                        </Body>
                        <Body>
                          We don’t strictly enforce the character limits so type
                          away. The gotcha here is that titles and descriptions
                          may be cut off in some cases - especially on mobile.
                          Be extra careful with the marketing slogan.
                        </Body>
                        <Body>
                          Headlines aren’t allowed to have excessive or
                          unnecessary punctuation or symbols such as the
                          following: “!”, “~” and “*”.
                        </Body>
                      </Box>
                    )}
                  />
                </span>
              }
              Input={TextInput}
              inputProps={{
                charLimit: 25,
                showCharLimit: true,
                isSoftLimit: true,
                placeholder: 'e.g. An agent you can trust'
              }}
            />

            <FormField
              name='retargeting.long_headline'
              label={
                <span>
                  Long Headline{' '}
                  <HelpIcon
                    Content={() => (
                      <Box style={{ width: '30rem' }}>
                        <Body>
                          Add a long headline to let people know what your ad is
                          about.
                        </Body>
                        <Body>
                          We don’t strictly enforce the character limits so type
                          away. The gotcha here is that titles and descriptions
                          may be cut off in some cases - especially on mobile.
                          Be extra careful with the marketing slogan.
                        </Body>
                        <Body>
                          Headlines aren’t allowed to have excessive or
                          unnecessary punctuation or symbols such as the
                          following: “!”, “~” and “*”.
                        </Body>
                      </Box>
                    )}
                  />
                </span>
              }
              Input={TextInput}
              inputProps={{
                charLimit: 40,
                isSoftLimit: true,
                showCharLimit: true
              }}
            />

            <FormField
              name='retargeting.description'
              label={
                <span>
                  Description{' '}
                  <HelpIcon
                    Content={() => (
                      <Box style={{ width: '30rem' }}>
                        <Body>
                          Add a small snippet of text to give more context to
                          your banner ads. This will appear only on Google
                          Display Banners.
                        </Body>
                        <Body>
                          We don’t strictly enforce the character limits so type
                          away. The gotcha here is that titles and descriptions
                          may be cut off in some cases - especially on mobile.
                          Be extra careful with the marketing slogan.
                        </Body>
                      </Box>
                    )}
                  />
                </span>
              }
              Input={TextArea}
              inputProps={{
                charLimit: 90,
                isSoftLimit: true,
                showCharLimit: true
              }}
            />
          </Box>
        );
      }
    }
  ];

  render() {
    return this.items[0].Tab();
  }
}

export default EditAdsForm;

import React, { FC, useState } from 'react';
import { useUpdateEffect } from 'react-use';
import { AdTemplate } from 'src/features/campaign-definitions';
import { hb } from 'src/lib/handlebars';

const renderTemplate = (
  template: string,
  data: Record<string, unknown>
): string => {
  const compiledTemplate = hb.compile(template);
  return compiledTemplate(data);
};

export type TemplateRendererProps = JSX.IntrinsicElements['iframe'] & {
  template: AdTemplate;
  adFormatId: string;
  data?: Record<string, unknown>;
  scaleAmount?: number;
};

export const TemplateRenderer: FC<TemplateRendererProps> = ({
  template,
  adFormatId,
  data = {},
  scaleAmount = 1,
  ...props
}) => {
  const [html, setHtml] = useState(() =>
    renderTemplate(template.template, data)
  );

  useUpdateEffect(() => {
    setHtml(renderTemplate(template.template, data));
  }, [template, data]);

  const rest: JSX.IntrinsicElements['iframe'] = {
    ...props,
    src: '',
    referrerPolicy: 'no-referrer'
  };

  return (
    <iframe
      style={{
        width: `${template.width}px`,
        height: `${template.height}px`,
        transform: `scale(${scaleAmount})`,
        transformOrigin: '0 0'
      }}
      srcDoc={html}
      {...rest}
    />
  );
};

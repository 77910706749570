import { Box } from '@rexlabs/box';
import { GhostButton, PrimaryButton } from '@rexlabs/button';
import { withModel } from '@rexlabs/model-generator';
import { autobind } from 'core-decorators';
import React, { PureComponent } from 'react';
import usersModel from 'src/data/models/entities/users';
import { withToken } from 'src/theme';
import { ModalStickyButtonGroup } from 'src/view/components/button';
import { TextInput } from 'src/view/components/input';
import { Modal } from 'src/view/components/modal';
import { Body } from 'src/view/components/text';
import withError from 'src/view/containers/with-error';
import withToast from 'src/view/containers/with-toast';

@withToken
@withToast
@withError()
@withModel(usersModel)
@autobind
class DeleteUserModal extends PureComponent {
  state = {
    loading: false,
    confirm: ''
  };

  handleSubmit() {
    const {
      users,
      error: { open },
      user,
      closeModal,
      toasts
    } = this.props;

    if (this.state.confirm !== user.email) {
      open("The entered email address doesn't match the users email address.");
      return;
    }

    this.setState({ loading: true }, () => {
      users
        .trashItem({ id: user.id })
        .then(() => {
          toasts.addToast({ title: 'User has been removed successfully!' });
          closeModal();
        })
        .catch((e) => {
          open(e);
          this.setState({ loading: false });
        });
    });
  }

  render() {
    const {
      closeModal,
      user,
      error: { Error },
      token
    } = this.props;
    return (
      <Modal
        title='Remove user'
        subtitle={user.email}
        onClose={closeModal}
        width='45rem'
      >
        <Box mb={token('spacing.xl')}>
          <Body grey>
            Are you sure you want to delete{' '}
            <span style={{ color: token('legacy.color.blue.dark') }}>
              {user.given_name} {user.family_name}
            </span>{' '}
            from this account? The user will lose access to all campaign
            details.
          </Body>

          <Body grey>
            Please type the account email address below to confirm.
          </Body>

          <Box mt={token('spacing.xs')}>
            <TextInput
              value={this.state.confirm}
              onChange={(e) => this.setState({ confirm: e.target.value })}
              placeholder={user.email}
            />
          </Box>
        </Box>

        <ModalStickyButtonGroup>
          <GhostButton onClick={closeModal}>Cancel</GhostButton>
          <PrimaryButton
            isLoading={this.state.loading}
            onClick={this.handleSubmit}
          >
            Remove user
          </PrimaryButton>
        </ModalStickyButtonGroup>

        <Error />
      </Modal>
    );
  }
}

export default DeleteUserModal;

import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import MobileBanner, { types } from './mobile-banner';

export default class LargeMobileBanner extends PureComponent {
  static propTypes = {
    agentName: PropTypes.string,
    suburb: PropTypes.string,
    brandColor: PropTypes.string,
    primaryImageUrls: PropTypes.object
  };

  render() {
    return <MobileBanner {...this.props} type={types.LARGE} />;
  }
}

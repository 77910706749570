import React from 'react';
import { StylesProvider } from '@rexlabs/styling';
import { GhostButton } from '@rexlabs/button';

const tokens = {
  button: {
    ghost: {
      font: {
        size: ({ token }) => token('typography.size.m')
      }
    }
  }
};

function GhostButtonSmall(props) {
  return (
    <StylesProvider tokens={tokens}>
      <GhostButton {...props} />
    </StylesProvider>
  );
}

export default GhostButtonSmall;

/* eslint-disable max-lines */
import { Box } from '@rexlabs/box';
import { GhostButton, PrimaryButton } from '@rexlabs/button';
import CropSelector, { makeAspectCrop } from '@rexlabs/crop-selector';
import { withModel } from '@rexlabs/model-generator';
import { styled, StyleSheet } from '@rexlabs/styling';
import { Heading } from '@rexlabs/text';
import { autobind } from 'core-decorators';
import _ from 'lodash';
import React, { PureComponent } from 'react';
import agentsModel from 'src/data/models/entities/agents';
import campaignsModel from 'src/data/models/entities/campaigns';
import imagesModel from 'src/data/models/entities/images';
import listingsModel from 'src/data/models/entities/listings';
import { TEXTS, withToken } from 'src/theme';
import { getPixelCrop } from 'src/utils/images';
import { ModalStickyButtonGroup } from 'src/view/components/button';
import { RenderLoading } from 'src/view/components/loading';
import { Modal } from 'src/view/components/modal';
import { Tiny } from 'src/view/components/text';
import withError from 'src/view/containers/with-error';

const defaultStyles = StyleSheet({
  imageContainer: {
    maxWidth: '48%'
  },
  image: {
    width: '100%'
  },
  subHeading: {
    ...TEXTS.SUBHEADING,
    marginBottom: 0
  },
  description: {
    ...TEXTS.TINY,
    color: ({ token }) => token('legacy.color.blue.grey'),
    marginBottom: '1rem'
  }
});

const getCropSelectorStyles = _.memoize((height) => {
  if (height === 0) return {};
  return {
    drawSurface: {
      height
    }
  };
});

@withToken
@withError()
@withModel(campaignsModel)
@withModel(agentsModel)
@withModel(listingsModel)
@withModel(imagesModel)
@styled(defaultStyles)
@autobind
class CropImageModal extends PureComponent {
  state = {
    landscapeCrop: null,
    landscapePixelCrop: null,
    squareCrop: null,
    squarePixelCrop: null,
    loading: false,
    landscapeImageLoading: true,
    squareImageLoading: true
  };

  image = null;

  componentDidMount() {
    this.loadImages();
  }

  loadImages() {
    // This is to make sure the image is cached by the browser,
    // so we can render it instead of the loading spinner
    const { image } = this.props;
    const src =
      typeof image?.original === 'string' ? image?.original : image?.src;

    const imageObj = new Image();
    imageObj.onload = () => {
      this.setState({
        imageSrc: src,
        squareImageLoading: false,
        landscapeImageLoading: false
      });
    };
    imageObj.src = src;
  }

  getPixelCrop(image, crop) {
    return {
      x: Math.round(image.naturalWidth * (crop.x / 100)),
      y: Math.round(image.naturalHeight * (crop.y / 100)),
      width: Math.round(image.naturalWidth * (crop.width / 100)),
      height: Math.round(image.naturalHeight * (crop.height / 100))
    };
  }

  makePercentageCropFromPixelCrop(image, crop) {
    const imageRatio = image.naturalHeight / image.height;
    const height = Math.round(crop.height / imageRatio);
    const x = Math.round(crop.top_left.x / imageRatio);
    const y = Math.round(crop.top_left.y / imageRatio);
    return {
      height: (height / image.height) * 100,
      x: (x / image.width) * 100,
      y: (y / image.height) * 100
    };
  }

  makeLandscapeCrop(image) {
    const {
      image: { crops }
    } = this.props;
    const crop = { aspect: 1.9 };
    const imageAspect = image.width / image.height;

    if (crops?.landscape?.height) {
      const newCrop = this.makePercentageCropFromPixelCrop(
        image,
        crops.landscape
      );
      crop.height = newCrop.height;
      crop.x = newCrop.x;
      crop.y = newCrop.y;
    } else {
      if (imageAspect > crop.aspect) {
        const heightRatio = 100 / imageAspect;
        const widthPercent = heightRatio * crop.aspect;
        crop.height = 100;
        crop.x = (100 - widthPercent) / 2;
        crop.y = 0;
      } else {
        const widthRatio = 100 * imageAspect;
        const heightPercent = widthRatio / crop.aspect;
        crop.width = 100;
        crop.x = 0;
        crop.y = (100 - heightPercent) / 2;
      }
    }

    const aspectCrop = makeAspectCrop(crop, imageAspect);
    const pixelCrop = getPixelCrop(image, aspectCrop);
    this.setState({
      landscapeCrop: aspectCrop,
      landscapePixelCrop: pixelCrop,
      landscapeHeight: this.image.clientHeight
    });
  }

  makeSquareCrop(image) {
    const {
      image: { crops }
    } = this.props;
    const crop = { aspect: 1 };
    const imageAspect = image.width / image.height;

    if (crops?.square?.height) {
      const newCrop = this.makePercentageCropFromPixelCrop(image, crops.square);
      crop.height = newCrop.height;
      crop.x = newCrop.x;
      crop.y = newCrop.y;
    } else {
      if (imageAspect > crop.aspect) {
        const heightRatio = 100 / imageAspect;
        const widthPercent = heightRatio * crop.aspect;
        crop.height = 100;
        crop.x = (100 - widthPercent) / 2;
        crop.y = 0;
      } else {
        const widthRatio = 100 * imageAspect;
        const heightPercent = widthRatio / crop.aspect;
        crop.width = 100;
        crop.x = 0;
        crop.y = (100 - heightPercent) / 2;
      }
    }

    const aspectCrop = makeAspectCrop(crop, imageAspect);
    const pixelCrop = getPixelCrop(image, aspectCrop);

    this.setState({
      squareCrop: aspectCrop,
      squarePixelCrop: pixelCrop,
      squareHeight: image.height
    });
  }

  handleLandscapeImageLoaded() {
    this.setState({
      landscapeImageLoading: false
    });
  }

  handleSquareImageLoaded() {
    this.setState({
      squareImageLoading: false
    });
  }

  handleLandscapeCropChange(crop) {
    this.setState({
      landscapeCrop: crop,
      landscapePixelCrop: getPixelCrop(this.image, crop)
    });
  }

  handleSquareCropChange(crop) {
    this.setState({
      squareCrop: crop,
      squarePixelCrop: getPixelCrop(this.image, crop)
    });
  }

  handleCropImage() {
    const { squarePixelCrop, landscapePixelCrop } = this.state;
    const {
      image,
      images,
      campaignId,
      agentId,
      listingId,
      campaigns,
      agents,
      listings,
      onCropImage,
      error,
      closeModal
    } = this.props;
    const crops = {
      square: {
        height: squarePixelCrop.height,
        width: squarePixelCrop.width,
        top_left: { x: squarePixelCrop.x, y: squarePixelCrop.y }
      },
      landscape: {
        height: landscapePixelCrop.height,
        width: landscapePixelCrop.width,
        top_left: { x: landscapePixelCrop.x, y: landscapePixelCrop.y }
      }
    };
    this.setState({ loading: true }, () => {
      images
        .updateItem({
          id: image.id,
          data: {
            name: image.name,
            crops
          }
        })
        .then(() => {
          let action = null;

          // If this crop is related to any entity, refresh that entity
          // TODO: need to find a more generic way for this!
          // NOTE: added !image.uploadId as we don't want to refresh if an
          // image was just uploaded and hasn't been attached to any entity yet
          if (campaignId && !image.uploadId) {
            action = campaigns.refreshItem({
              id: campaignId,
              args: {
                include: 'images.sizes,agent_images.sizes,agency_logo.sizes'
              }
            });
          } else if (agentId) {
            action = agents.refreshItem({
              id: agentId,
              args: { include: 'images.sizes' }
            });
          } else if (listingId) {
            action = listings.refreshItem({
              id: listingId,
              args: { include: 'images.sizes' }
            });
          }

          if (action) {
            action.then(() => {
              onCropImage(crops);
              closeModal();
            });
          } else {
            onCropImage(crops);
            closeModal();
          }
        })
        .catch((err) => {
          error.open(err.message);
          this.setState({ loading: false });
        });
    });
  }

  setLandscapeRef(ref) {
    this.image = ref;
    if (ref) {
      this.makeLandscapeCrop(ref);
    }
  }

  setSquareRef(ref) {
    if (ref) {
      this.makeSquareCrop(ref);
    }
  }

  render() {
    const {
      styles: s,
      error: { Error },
      closeModal,
      token
    } = this.props;
    const {
      landscapeCrop,
      landscapeHeight,
      squareCrop,
      squareHeight,
      loading,
      imageSrc,
      landscapeImageLoading,
      squareImageLoading
    } = this.state;

    const isLoading = landscapeImageLoading || squareImageLoading;
    const isDisabled =
      !landscapeCrop ||
      !squareCrop ||
      (landscapeCrop.width === 0 && landscapeCrop.height === 0) ||
      (squareCrop.width === 0 && squareCrop.height === 0);

    return (
      <Modal
        title='Crop image'
        subtitle='Crop the image to fit landscape and square ad formats'
        width='100rem'
        onClose={closeModal}
      >
        <Box justifyContent='flex-start' flexDirection='column'>
          <RenderLoading isLoading={isLoading} alwaysRenderChildren={false}>
            <Box
              width='100%'
              justifyContent='space-around'
              pb={token('spacing.xxl')}
            >
              <Box {...s('imageContainer')} flexDirection='column'>
                <Heading level={2} {...s('subHeading')}>
                  Landscape
                </Heading>
                <Tiny {...s('description')} grey>
                  Used with selected Google ads.
                </Tiny>
                <CropSelector
                  styles={getCropSelectorStyles(landscapeHeight)}
                  crop={landscapeCrop}
                  onChange={this.handleLandscapeCropChange}
                >
                  <img
                    {...s('image')}
                    onLoad={(e) => this.setLandscapeRef(e.target)}
                    src={imageSrc}
                  />
                </CropSelector>
              </Box>
              <Box {...s('imageContainer')} flexDirection='column'>
                <Heading level={2} {...s('subHeading')}>
                  Square
                </Heading>
                <Tiny grey {...s('description')}>
                  Used with Facebook, Instagram and selected Google ads.
                </Tiny>
                <CropSelector
                  styles={getCropSelectorStyles(squareHeight)}
                  crop={squareCrop}
                  onChange={this.handleSquareCropChange}
                >
                  <img
                    {...s('image')}
                    onLoad={(e) => this.setSquareRef(e.target)}
                    src={imageSrc}
                  />
                </CropSelector>
              </Box>
            </Box>
          </RenderLoading>
          <ModalStickyButtonGroup>
            <GhostButton onClick={closeModal}>Cancel</GhostButton>
            <PrimaryButton
              isLoading={loading}
              isDisabled={isDisabled}
              onClick={this.handleCropImage}
            >
              Update
            </PrimaryButton>
          </ModalStickyButtonGroup>
        </Box>
        <Error />
      </Modal>
    );
  }
}

export default CropImageModal;

import { StyleSheet, useStyles } from '@rexlabs/styling';
import React, { FC } from 'react';

const styles = StyleSheet({
  progressBar: {
    width: '100%',
    height: '.2rem',
    background: ({ token }) => token('color.neutral.idle.default')
  },

  progress: {
    width: '100%',
    height: '100%',
    background: ({ token }) => token('palette.brand.600'),
    transition: 'transform .3s',
    transform: 'translateX(-100%)',
    transformOrigin: 'top left',
    borderRadius: '.2rem'
  }
});

export type ProgressBarProps = {
  progress?: number;
};

export const ProgressBar: FC<ProgressBarProps> = ({ progress = 0 }) => {
  const s = useStyles(styles);

  return (
    <div {...s('progressBar')}>
      <div
        {...s.with('progress')({
          transform: `translateX(${Math.min(progress * 100, 100) - 100}%)`
        })}
      />
    </div>
  );
};

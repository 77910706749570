import { Box } from '@rexlabs/box';
import { withQuery } from '@rexlabs/model-generator/lib/index';
import { styled, StyleSheet } from '@rexlabs/styling';
import { autobind } from 'core-decorators';
import React, { PureComponent } from 'react';
import { queryAgents } from 'src/data/queries/agents';
import { FONT, withToken } from 'src/theme';
import getFakeEvent from 'src/utils/fake-event';
import AgentItem from 'src/view/components/input/agent/agent-item';
import Toggle from 'src/view/components/input/toggle';
import { RenderLoading } from 'src/view/components/loading';
import Label from 'src/view/components/text/label';
import withError from 'src/view/containers/with-error';
import CreateAgentModal, { validate } from 'src/view/modals/admin/create-agent';
import DeleteAgentModal from 'src/view/modals/admin/delete-agent';
import { CircleIconButton } from '../../button';
import { List } from '../../list';

const defaultStyles = StyleSheet({
  title: {
    fontWeight: FONT.WEIGHTS.BOLD
  },

  text: {
    margin: '0 .2rem',
    display: 'block'
  },

  textMeta: {
    color: ({ token }) => token('legacy.color.blue.grey')
  },

  container: {
    marginTop: ({ token }) => token('spacing.m'),
    padding: ({ token }) => token('spacing.m'),
    border: ({ token }) => `.1rem solid ${token('legacy.color.grey.default')}`,
    borderRadius: '0.5rem',
    overflow: 'hidden'
  },

  separator: {
    marginTop: ({ token }) => token('spacing.m'),
    width: '100%',
    height: '.1rem',
    background: ({ token }) => token('legacy.color.blue.greyLight')
  },

  toggle: {
    float: 'right'
  },

  wrapList: {
    width: '100%',
    marginTop: ({ token }) => token('spacing.xs')
  }
});

@withToken
@withError()
@withQuery(queryAgents)
@styled(defaultStyles)
@autobind
class AgentSelection extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      toggled: !!props.value,
      showModal: false,
      modalData: null
    };
  }

  showDeleteAgent(e, item) {
    e.preventDefault();
    e.stopPropagation();
    this.setState({
      modalData: item,
      showModal: 'delete'
    });
  }

  showEditAgent(e, item) {
    e.preventDefault();
    e.stopPropagation();
    this.setState({
      modalData: item,
      showModal: 'edit'
    });
  }

  handleChange(newValue) {
    const { onChange, name } = this.props;
    const e = getFakeEvent('select', name, name, newValue);

    const selectedAgent = this.props.agents.list.items.find(
      (agent) => agent.id === newValue
    );

    const validationErrors = validate(selectedAgent);
    if (selectedAgent && Object.keys(validationErrors).length > 0) {
      this.setState({
        modalData: selectedAgent,
        showModal: 'edit',
        validationErrors: validationErrors
      });
    }
    onChange(e);
  }

  handleDeleteAgent({ id }) {
    const { agents, error, onChange, name } = this.props;
    const { selectedAgent } = this.state;
    if (id) {
      agents
        .refreshList()
        .then(() => {
          this.setState({
            showModal: false,
            modalData: null
          });
          const e = getFakeEvent(
            'select',
            name,
            name,
            selectedAgent === id ? null : selectedAgent
          );
          onChange(e);
        })
        .catch((e) => error.open(e.message));
    } else {
      this.setState({
        showModal: false,
        modalData: null
      });
    }
  }

  handleCreateAgent({ id, skip }) {
    const { agents, error, onChange, name } = this.props;
    const { selectedAgent } = this.state;
    if (id) {
      agents
        .refreshList()
        .then(() => {
          this.setState({
            showModal: false,
            modalData: null
          });
          const e = getFakeEvent('select', name, name, id || selectedAgent);
          onChange(e);
        })
        .catch((e) => error.open(e.message));
    } else if (skip) {
      this.setState({
        showModal: false,
        modalData: null,
        agentErrors: null
      });
      const e = getFakeEvent('select', name, name, null);
      onChange(e);
    } else {
      this.setState({ showModal: false, modalData: null, agentErrors: null });
    }
  }

  render() {
    const {
      agents,
      name,
      styles: s,
      onChange,
      error,
      value,
      token,
      readOnlyLeadCaptureEmail
    } = this.props;
    const { toggled } = this.state;

    const { Error } = error;

    return (
      <Box {...s('container')}>
        <Box {...s('toggle')}>
          <Toggle
            id={name}
            toggled={toggled}
            onChange={(e) => {
              this.setState({
                toggled: e.target.checked
              });
              const ev = getFakeEvent('select', name, name, null);
              onChange(ev);
            }}
          />
        </Box>
        <Label style={{ marginTop: token('spacing.xxs') }}>Listing agent</Label>
        <span {...s('text', 'textMeta')}>
          Show agent details on listing ads.
        </span>

        {toggled && (
          <RenderLoading isLoading={agents.list.status === 'loading'}>
            <Box {...s('wrapList')} flexDirection='column'>
              <List
                Header={({ isLoading }) =>
                  isLoading || (
                    <CircleIconButton
                      onClick={() => this.setState({ showModal: 'edit' })}
                    >
                      Add a new agent
                    </CircleIconButton>
                  )
                }
                items={agents.list.items}
                getItemKey={(item) => item.id}
                Item={({ data: item }) => {
                  const selected = value === item.id;
                  return (
                    <AgentItem
                      selected={selected}
                      onClick={() => this.handleChange(selected ? '' : item.id)}
                      onEdit={(e) => this.showEditAgent(e, item)}
                      onDelete={(e) => this.showDeleteAgent(e, item)}
                      data={item}
                    />
                  );
                }}
                onLoadMore={() => agents.fetchMore()}
                isLoading={
                  agents.list.status === 'loading' ||
                  agents.list.status === 'refreshing'
                }
                isFetching={agents.list.status === 'fetching'}
                endReached={agents?.list?.pagination?.endReached}
              />
            </Box>
          </RenderLoading>
        )}

        <Error />

        {this.state.showModal === 'edit' && (
          <CreateAgentModal
            agent={this.state.modalData}
            closeModal={this.handleCreateAgent}
            agentErrors={this.state.validationErrors}
            readOnlyLeadCaptureEmail={readOnlyLeadCaptureEmail}
          />
        )}

        {this.state.showModal === 'delete' && (
          <DeleteAgentModal
            agentId={this.state.modalData.id}
            closeModal={this.handleDeleteAgent}
          />
        )}
      </Box>
    );
  }
}

export default AgentSelection;

import { Box } from '@rexlabs/box';
import { styled, StyleSheet } from '@rexlabs/styling';
import React, { PureComponent } from 'react';
import PlayIcon from 'src/assets/icons/play.svg';

import Container from 'shared/components/instagram-ads/shared/container';

const defaultStyles = StyleSheet({
  videoContainer: {
    width: '100%',
    backgroundColor: ({ token }) => token('legacy.color.grey.light'),
    overflow: 'hidden',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center'
  },

  video: {
    background: 'black',
    width: '100%',
    height: 'auto'
  },

  videoPlaceholder: {
    width: '100%',
    height: '25rem',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    background:
      'linear-gradient(to top, rgba(0,0,0,0.65) 0%,rgba(0,0,0,.2) 100%)'
  },

  playIcon: {
    color: 'rgba(255,255,255,.95)'
  }
});

@styled(defaultStyles)
class InstagramVideo extends PureComponent {
  render() {
    const { styles: s, adContentSet } = this.props;

    const video = adContentSet?.prospecting?.video;
    const showVideo = video?.status?.id === 'active';
    const poster = video?.thumbnail?.sizes?.['original.thumbnail']?.url;

    return (
      <Container {...this.props} campaignDescription={adContentSet.description}>
        <Box {...s('videoContainer')}>
          {showVideo && (
            <video
              {...s('video')}
              controls={true}
              playsInline={true}
              src={video.url}
              poster={poster}
              autoPlay={true}
              muted={true}
            />
          )}

          {!showVideo && (
            <div {...s('videoPlaceholder')}>
              <PlayIcon {...s('playIcon')} />
            </div>
          )}
        </Box>
      </Container>
    );
  }
}

export default InstagramVideo;

import { useMutation, useQueryClient } from 'react-query';
import { useAccountId } from 'src/features/accounts';
import { MutationResult } from 'src/lib/react-query';
import { api } from 'src/utils/api-client';
import { CommercialListing, CommercialListingPostDTO } from '../types';
import { commercialListingKeys } from 'src/features/listings/api/keys';

export type CreateCommercialListingParams = {
  accountId: string;
  listing: CommercialListingPostDTO;
};

export const createCommercialListing = async ({
  listing
}: CreateCommercialListingParams): Promise<CommercialListing> => {
  const { data } = await api.post('/commercial-listings', listing);
  return data;
};

export type UseCreateCommercialListingVariables = CommercialListingPostDTO;

export const useCreateCommercialListing = (): MutationResult<
  CommercialListing,
  UseCreateCommercialListingVariables
> => {
  const queryClient = useQueryClient();
  const accountId = useAccountId();

  return useMutation({
    mutationFn: (listing) => createCommercialListing({ accountId, listing }),
    onSuccess: (data) => {
      queryClient.setQueryData(
        commercialListingKeys.detail({ accountId, listingId: data.id }),
        data
      );
      queryClient.invalidateQueries(commercialListingKeys.lists({ accountId }));
    }
  });
};

import React, { PureComponent } from 'react';
import InstagramSingleImage from './instagram-ads/single-image';

class InstagramAd extends PureComponent {
  render() {
    const { adContentSet, disableWrapper, ad } = this.props;
    return (
      <InstagramSingleImage
        ad={ad}
        adContentSet={adContentSet}
        disableWrapper={disableWrapper}
      />
    );
  }
}

export default InstagramAd;

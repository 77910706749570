import { PaginationParams } from 'src/types';

export type ListParams = PaginationParams & {
  accountId: string;
  includes?: string[];
};

export type DetailParams = {
  accountId: string;
  id: string;
};

export const resourceDownloadKeys = {
  all: (accountId: string) => [accountId, 'resource-downloads'] as const,
  lists: (accountId: string) =>
    [...resourceDownloadKeys.all(accountId), 'list'] as const,
  list: ({ accountId, ...params }) =>
    [...resourceDownloadKeys.lists(accountId), { ...params }] as const,
  details: (accountId: string) =>
    [...resourceDownloadKeys.all(accountId), 'detail'] as const,
  detail: ({ accountId, id }: DetailParams) =>
    [...resourceDownloadKeys.details(accountId), id] as const
};

import React, { PureComponent } from 'react';
import { styled, StyleSheet } from '@rexlabs/styling';

const defaultStyles = StyleSheet({
  text: {
    fontWeight: 100,
    fill: ({ token }) => token('legacy.color.blue.grey')
  }
});

@styled(defaultStyles)
class YAxisTick extends PureComponent {
  render() {
    const { x, y, payload, styles: s, format } = this.props;
    return (
      <g>
        <text {...s('text')} x={x} y={y} textAnchor='middle' dy={'.5rem'}>
          {format ? format(payload.value) : payload.value}
        </text>
      </g>
    );
  }
}

export default YAxisTick;

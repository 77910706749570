import { Box } from '@rexlabs/box';
import { withModel } from '@rexlabs/model-generator';
import { styled, StyleSheet } from '@rexlabs/styling';
import { autobind, decorate } from 'core-decorators';
import _ from 'lodash';
import React, { PureComponent } from 'react';
import campaignsModel from 'src/data/models/entities/campaigns';
import { withToken } from 'src/theme';
import { cropSrc } from 'src/utils/images';
import { ScalableImage, Image as SpokeImage } from 'src/view/components/image';
import { LoadingSpinner } from 'src/view/components/loading';
import { Label } from 'src/view/components/text';
import ChangeImageModal from 'src/view/modals/change-image';

const defaultStyles = StyleSheet({
  container: {
    position: 'relative',
    width: '100%',
    height: '100%'
  },

  label: {
    position: 'absolute',
    bottom: 0,
    left: 0,
    paddingLeft: ({ token }) => token('spacing.xs'),
    paddingRight: ({ token }) => token('spacing.xs')
  },

  isCropping: {
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0
  }
});

@withToken
@withModel(campaignsModel)
@styled(defaultStyles)
@autobind
class SingleImage extends PureComponent {
  static defaultProps = {
    canChange: true
  };

  state = {
    changeImageOpen: false,
    loading: true,
    image: {}
  };

  componentDidMount() {
    this.cropImage(this.props);
  }

  componentWillReceiveProps(nextProps) {
    if (this.props.value !== nextProps.value) {
      this.cropImage(nextProps);
    }
  }

  async cropImage(props) {
    this.setState({ isCropping: true });
    const { value, ratio, canCrop } = props;

    const original = value?.sizes?.original?.url;
    let render = original;
    if (canCrop) {
      render = value?.crops?.[ratio]?.blob;
      if (!render) {
        const crops = value?.crops?.[ratio];
        if (crops) {
          render = await cropSrc(
            value?.sizes?.['original.thumbnail'],
            value?.sizes?.original,
            crops
          );
        }
      }
    }

    const image = {
      src: original,
      id: value?.id,
      name: value?.name,
      crops: value?.crops,
      sizes: value?.sizes,
      render: render || original,
      original
    };

    this.setState({ image, isCropping: false });
  }

  createFakeEvent(value) {
    const { name } = this.props;
    return {
      persist: _.noop,
      target: {
        type: 'text',
        name,
        id: name,
        value
      }
    };
  }

  handleClick() {
    this.setState({ changeImageOpen: true });
  }

  handleCloseChangeImage() {
    this.setState({ changeImageOpen: false });
  }

  handleCropImage(image, crops) {
    const { onChange } = this.props;
    onChange(this.createFakeEvent({ ...image, crops }));
  }

  handleChangeImage(selectedImages) {
    const { onChange } = this.props;
    onChange(this.createFakeEvent(selectedImages[0]));
  }

  @decorate(_.memoize)
  getImageStyles(status, isSmall, noBorder, isSquare) {
    return StyleSheet({
      container: {
        ...(isSquare ? { width: '19.2rem !important' } : {}),
        ...(isSmall
          ? {
              height: '9.2rem',
              width: '14.2rem !important'
            }
          : {}),
        margin: 0,
        border: ({ token }) =>
          noBorder ? 'none' : `1px solid ${token('legacy.color.blue.grey')}`,
        borderRadius: '0.7rem'
      },
      imageContainer: {
        // ...(isSquare ? { height: '17rem', width: '17rem' } : {}),
        ...(status !== 'loaded' ? { pointerEvents: 'none' } : {}),
        overflow: 'hidden',
        margin: 0,
        cursor: status !== 'loaded' ? 'default' : 'pointer'
      }
    });
  }

  render() {
    const { changeImageOpen } = this.state;
    const {
      styles: s,
      value,
      label,
      isSmall,
      noBorder,
      overlayText,
      canCrop,
      canChange,
      isSquare,
      type,
      initialImages,
      status,
      campaignId,
      unremoveableImages,
      ratio,
      height,
      padding,
      backgroundColor,
      changeModalSubtitle,
      changeModalSubtitleTooltip,
      token,
      scale,
      canScale = false
    } = this.props;

    const { image, isCropping } = this.state;
    const ImageComponent = scale && canScale ? ScalableImage : SpokeImage;

    return (
      <div
        {...s.with('container')({
          paddingBottom: label ? '2rem' : 0,
          width: canScale ? '160px' : '100%',
          height: canScale ? '160px' : '100%'
        })}
      >
        <ImageComponent
          styles={this.getImageStyles(status, isSmall, noBorder, isSquare)}
          image={image}
          overlayText={overlayText}
          campaignId={campaignId}
          canCrop={canCrop}
          onClick={canChange ? this.handleClick : undefined}
          onCropImage={this.handleCropImage}
          ratio={ratio}
          height={height}
          padding={padding}
          backgroundColor={backgroundColor}
          scale={scale}
        />
        {label && <Label {...s('label')}>{label}</Label>}
        {isCropping && (
          <Box justifyContent='center' alignItems='center' {...s('isCropping')}>
            <LoadingSpinner colors={[token('legacy.color.grey.light')]} />
          </Box>
        )}
        {changeImageOpen && (
          <ChangeImageModal
            subtitle={changeModalSubtitle}
            subtitleTooltip={changeModalSubtitleTooltip}
            canCrop={canCrop}
            selectedImageId={value?.id}
            type={type}
            initialImages={initialImages}
            status={status}
            campaignId={campaignId}
            unremoveableImages={unremoveableImages}
            changeImage={this.handleChangeImage}
            closeModal={this.handleCloseChangeImage}
            ratio={ratio}
          />
        )}
      </div>
    );
  }
}

export default SingleImage;

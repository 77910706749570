import { GhostButton, PrimaryButton } from '@rexlabs/button';
import { withModel } from '@rexlabs/model-generator';
import { useToast } from '@rexlabs/notifications';
import { useToken } from '@rexlabs/styling';
import React, { useState } from 'react';
import { compose } from 'redux';
import campaignsModel from 'src/data/models/entities/campaigns';
import { ModalStickyButtonGroup } from 'src/view/components/button';
import { Modal } from 'src/view/components/modal';
import { Body } from 'src/view/components/text';
import withError from 'src/view/containers/with-error';

function UnlinkCampaignModal({
  closeModal,
  campaignId,
  campaignName,
  campaigns,
  errorModal,
  errorModal: { Error }
}) {
  const token = useToken();
  const [loading, setLoading] = useState(false);
  const toasts = useToast();

  const handleUnlink = async () => {
    try {
      setLoading(true);
      const token = new URLSearchParams(window.location.search).get('token');
      if (!token) {
        throw new Error('No token found');
      }
      await campaigns.unlinkCampaignToken({
        id: campaignId,
        campaignToken: token
      });
      closeModal();
      toasts.addToast({ title: 'Your campaign has been linked' });
    } catch (e) {
      setLoading(false);
      errorModal.open(e);
    }
  };

  return (
    <Modal title='Unlink campaign' onClose={closeModal}>
      <Body grey={true}>
        You are about to unlink{' '}
        <span style={{ color: token('legacy.color.black') }}>
          {campaignName}
        </span>{' '}
        campaign from your listing. You will lose the ability to see this
        campaign report in your CRM. Are you sure you want to unlink this
        campaign?
      </Body>

      <ModalStickyButtonGroup>
        <GhostButton onClick={closeModal}>Cancel</GhostButton>
        <PrimaryButton onClick={handleUnlink} isLoading={loading}>
          Unlink
        </PrimaryButton>
      </ModalStickyButtonGroup>

      <Error />
    </Modal>
  );
}

export default compose(
  withError('errorModal'),
  withModel(campaignsModel)
)(UnlinkCampaignModal);

import { Box } from '@rexlabs/box';
import { keyframes, styled, StyleSheet } from '@rexlabs/styling';
import { Body } from '@rexlabs/text';
import React, { PureComponent } from 'react';
import { FONT } from 'src/theme';
import calcAudienceHealth from 'src/utils/health-indicator';
import { Tiny } from 'src/view/components/text';
import { HelpIcon } from 'src/view/components/tooltip';

import MeterFace from 'src/assets/icons/meter-face.svg';
import MeterNeedle from 'src/assets/icons/meter-needle.svg';

const meterNeedleAnimation = (prevNeedleValue, needleValue) => ({
  animation: `${keyframes({
    '0%': { opacity: '1', transform: `rotate(${prevNeedleValue || -4}deg)` },
    '100%': { opacity: '1', transform: `rotate(${needleValue}deg)` }
  })} 1s ease 1 both`
});

const isLoadingAnimation = () => ({
  animation: `
  ${keyframes({
    '0%': { opacity: '0.05', transform: 'rotate(-4deg)' },
    '50%': { opacity: '0.1', transform: 'rotate(-4deg)' },
    '100%': { opacity: '0.05', transform: 'rotate(-4deg)' }
  })} 1s infinite`
});

const defaultStyles = StyleSheet({
  meter: {
    position: 'relative',
    display: 'flex',
    justifyContent: 'center'
  },

  needleWrapper: {
    position: 'absolute',
    top: 0,
    width: '100%',
    height: '100%'
  },

  needle: {
    position: 'absolute',
    bottom: 0,
    right: '50%',
    transformOrigin: 'right',
    color: '#002842',
    opacity: '0.05'
  },

  title: {
    fontSize: '1.8rem',
    lineHeight: '2.4rem',
    fontWeight: FONT.WEIGHTS.LIGHT,
    letterSpacing: '-.04rem',
    marginBottom: '11px',
    textAlign: 'center',
    color: ({ token }) => token('color.textStyle.body.default')
  },

  tinyTitle: {
    fontSize: '1.4rem',
    lineHeight: '1.8rem',
    fontWeight: FONT.WEIGHTS.LIGHT,
    letterSpacing: '-.02rem',
    color: ({ token }) => token('legacy.color.black'),
    paddingBottom: 0
  },

  titleSpecific: {
    marginRight: '73.5px',
    justifyContent: 'center'
  },

  titleBroad: {
    justifyContent: 'center'
  },

  tinyDescription: {
    fontSize: '1.4rem',
    lineHeight: '1.8rem',
    fontWeight: FONT.WEIGHTS.LIGHT,
    letterSpacing: '-.02rem',
    paddingTop: 0,
    color: ({ token }) => token('legacy.color.blue.grey')
  }
});

@styled(defaultStyles)
class HealthIndicator extends PureComponent {
  state = {
    prevNeedleValue: 0,
    needleValue: 0
  };

  componentDidMount() {
    const { degrees: needleValue } = calcAudienceHealth(this.props);
    if (needleValue !== this.state.needleValue) {
      // set a timeout so the animation doesn't run immediately
      setTimeout(() => {
        this.setState({ prevNeedleValue: this.state.needleValue, needleValue });
      }, 500);
    }
  }

  componentDidUpdate() {
    const { degrees: needleValue } = calcAudienceHealth(this.props);
    if (needleValue !== this.state.needleValue) {
      this.setState({ prevNeedleValue: this.state.needleValue, needleValue });
    }
  }

  render() {
    const { styles: s, isLoading } = this.props;
    const { prevNeedleValue, needleValue } = this.state;

    return (
      <>
        <Body {...s('title')}>
          Audience Size
          <HelpIcon
            placement='top-end'
            Content={() => (
              <div style={{ width: '24rem' }}>
                <Body>
                  Depending on your audience settings your audience may be too
                  small or large to reach effectively. The audience is small if
                  your settings are too specific and large if they’re too broad.
                  Edit your audience settings by clicking “Edit audience.”
                </Body>
              </div>
            )}
          />
        </Body>
        <div {...s('meter')}>
          <MeterFace />
          <div {...s('needleWrapper')}>
            <MeterNeedle
              {...s.with('needle')(
                isLoading
                  ? isLoadingAnimation()
                  : meterNeedleAnimation(prevNeedleValue, needleValue)
              )}
            />
          </div>
        </div>
        <Box justifyContent='center'>
          <Box
            flexDirection='column'
            alignItems='center'
            {...s('titleSpecific')}
          >
            <Tiny {...s('tinyTitle')}>Specific</Tiny>
            <Tiny grey {...s('tinyDescription')}>
              Too small
            </Tiny>
          </Box>
          <Box flexDirection='column' alignItems='center' {...s('titleBroad')}>
            <Tiny {...s('tinyTitle')}>Broad</Tiny>
            <Tiny grey {...s('tinyDescription')}>
              Too large
            </Tiny>
          </Box>
        </Box>
      </>
    );
  }
}

export default HealthIndicator;

import { navigation } from '@rexlabs/analytics';
import { Box } from '@rexlabs/box';
import CSSAnimationGroup from '@rexlabs/css-animation-group';
import { withModel } from '@rexlabs/model-generator';
import { ToastProvider, ToastTarget } from '@rexlabs/notifications';
import { PortalTarget } from '@rexlabs/portal';
import {
  RenderMatchedRoutes,
  withWhereabouts
} from '@rexlabs/react-whereabouts';
import { styled, StyleSheet, StylesProvider } from '@rexlabs/styling';
import { match, replace } from '@rexlabs/whereabouts';
import React, { useEffect } from 'react';
import { Helmet } from 'react-helmet';
import { compose } from 'redux';
import sessionModel from 'src/data/models/custom/session';
import setupModel from 'src/data/models/custom/setup';
import ROUTES from 'src/routes/admin';
import AUTH from 'src/routes/auth';
import WIZARD from 'src/routes/wizard';
import { formattedBrandName, KEYFRAMES } from 'src/theme';
import { urlToPageName } from 'src/utils/routing';
import ErrorBar from 'src/view/components/error-bar';
import NotFoundScreen from 'src/view/screens/not-found';
import TopNavBar from '../components/top-nav';
import { useFeature } from 'utils/feature-flags';
import { toastTargetTokens } from 'src/view/layouts/admin';

const defaultStyles = StyleSheet({
  container: {
    overflow: 'hidden'
  },

  topNav: {
    width: '100%',
    padding: ({ token }) => `0 ${token('spacing.xl')}`,
    height: '8rem',
    position: 'relative'
  },

  logo: {
    color: ({ token }) => token('legacy.color.primary.default'),
    height: '6rem',
    cursor: 'pointer'
  },

  wrapContent: {
    position: 'relative',
    maxHeight: ({ token }) =>
      `calc(100vh - ${token('topNavBar.height', '8rem')})`
  },

  showScrollbar: {
    '::-webkit-scrollbar': {
      WebkitAppearance: 'none',
      width: '0.7rem'
    },

    '::-webkit-scrollbar-thumb': {
      borderRadius: '0.4rem',
      backgroundColor: ({ token }) => token('legacy.color.blue.greyLight'),
      WebkitBoxShadow: '0 0 1px rgba(255,255,255,.5)',

      '&:hover': {
        backgroundColor: ({ token }) => token('legacy.color.blue.grey')
      }
    }
  },

  content: {
    width: '99.2rem',
    maxHeight: '100%',
    maxWidth: '100vw',
    padding: ({ token }) => token('spacing.xl'),
    paddingTop: 0,
    position: 'relative',
    justifyContent: 'center',
    flex: '1 1 0%',
    display: 'flex'
  },

  wrapLogo: {
    width: '13rem'
  },

  wrapHeaderButton: {
    width: '13rem'
  }
});

function WizardLayout({ whereabouts, styles: s, session, setup }) {
  const fullscreenModalOpen = !!whereabouts.hash;
  const isStartScreen = match(whereabouts, WIZARD.CREATE.config, {
    exact: true
  });

  useEffect(() => {
    /**
     * Only track start and success pages all other pages are tracked
     * from within their index file so as to capture the wizard step
     */
    const isSuccessScreen = match(whereabouts, WIZARD.SUCCESS.config, {
      exact: true
    });

    if (isStartScreen || isSuccessScreen) {
      const { path, hash } = whereabouts;
      const page = urlToPageName(path, hash);

      navigation({
        page,
        properties: {
          path,
          hash
        }
      });
    }
  }, [whereabouts, isStartScreen]);

  return (
    <ToastProvider>
      <Box flexDirection='column' flex={1} {...s('container')}>
        <Helmet>
          <title>{formattedBrandName} - Campaign Creation</title>
        </Helmet>
        {!!session.currentAccountId && !setup.step && <ErrorBar />}

        <TopNavBar
          hideLogo={isStartScreen}
          Left={
            <PortalTarget name='headerSteps'>
              {({ children }) => children}
            </PortalTarget>
          }
          Right={
            <Box
              {...s('wrapHeaderButton')}
              flexDirection='row'
              justifyContent='flex-end'
            >
              <PortalTarget name='headerButton' />
            </Box>
          }
          logoRoute={session.currentAccountId && ROUTES.DASHBOARD}
        />

        {/* Portal for progress bar under the header */}
        <PortalTarget name='headerProgress' />

        <Box
          {...s('wrapContent', { showScrollbar: !fullscreenModalOpen })}
          flex={1}
          justifyContent='center'
        >
          <PortalTarget name='fullscreenModals'>
            {({ children }) => (
              <CSSAnimationGroup
                enterAnimation={KEYFRAMES.WIZARD_MODAL_FADE_IN}
                enterDuration='200ms'
                enterFillMode='both'
                leaveAnimation={KEYFRAMES.WIZARD_MODAL_FADE_OUT}
                leaveDuration='150ms'
                leaveFillMode='both'
                style={{
                  position: 'absolute',
                  width: '100%',
                  height: '100%'
                }}
              >
                {children}
              </CSSAnimationGroup>
            )}
          </PortalTarget>

          <Box id='content' flex='0'>
            <PortalTarget name='wizardBackground' />
            <Box {...s('content')}>
              <StylesProvider tokens={toastTargetTokens}>
                <ToastTarget position='top-right' />
              </StylesProvider>
              {!session.currentAccountId || setup.step ? (
                <RenderMatchedRoutes
                  routes={AUTH}
                  NotFound={() => {
                    replace(AUTH.LOGIN);
                    return null;
                  }}
                >
                  {(matches) => matches}
                </RenderMatchedRoutes>
              ) : (
                <RenderMatchedRoutes routes={WIZARD} NotFound={NotFoundScreen}>
                  {(matches) => matches}
                </RenderMatchedRoutes>
              )}
            </Box>
          </Box>
        </Box>
      </Box>
    </ToastProvider>
  );
}

export default compose(
  withModel(setupModel),
  withModel(sessionModel),
  withWhereabouts(),
  styled(defaultStyles)
)(WizardLayout);

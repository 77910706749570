import React, { FC, useCallback } from 'react';
import {
  TestimonialForm,
  TestimonialSubmitHandler
} from 'src/features/testimonials/components/TestimonialForm';
import { TestimonialFormGuide } from 'src/features/testimonials/components/TestimonialFormGuide';
import { useNavigate } from 'react-router-dom';
import { useCreateTestimonial } from 'src/features/testimonials/api/createTestimonial';
import { useToast } from '@rexlabs/notifications';
import { StepEditorLayout } from 'src/features/wizard/components/StepEditorLayout';
import { ContentTypeEditorProps } from 'src/features/wizard';

export type TestimonialCreateFormProps = {
  resourceId?: string;
};

export const TestimonialCreateForm: FC<ContentTypeEditorProps> = ({
  selectContent
}) => {
  const navigate = useNavigate();
  const toasts = useToast();

  const { mutateAsync: createTestimonial } = useCreateTestimonial();

  const handleCreate = useCallback<TestimonialSubmitHandler>(
    async (data) => {
      try {
        await createTestimonial(data).then((res) => {
          selectContent?.(res.id);
          navigate('../Testimonial');
        });
      } catch (e) {
        toasts.addToast({
          type: 'error',
          title: "Couldn't create testimonial",
          description: e instanceof Error ? e.message : undefined
        });
      }
    },
    [createTestimonial, selectContent, navigate, toasts]
  );

  const exitEditor = useCallback(() => navigate('..'), [navigate]);

  return (
    <StepEditorLayout
      title='Create New Testimonial'
      Preview={<TestimonialFormGuide />}
    >
      <TestimonialForm
        submitLabel='Save'
        onSubmit={handleCreate}
        onCancel={exitEditor}
      />
    </StepEditorLayout>
  );
};

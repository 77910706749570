/* eslint-disable max-lines */
import { Box } from '@rexlabs/box';
import { GhostButton, PrimaryButton } from '@rexlabs/button';
import { StyleSheet, useToken } from '@rexlabs/styling';
import React, { useEffect, useRef, useState } from 'react';
import { StickyButtonGroup } from 'src/view/components/button';
import {
  FieldCell,
  FieldRow,
  FormField,
  HiddenField,
  LabelOptionalField
} from 'src/view/components/form';
import { Checkbox, TextArea, TextInput } from 'src/view/components/input';
import { Tiny } from 'src/view/components/text';

import { withModel } from '@rexlabs/model-generator';
import { Portal } from '@rexlabs/portal';
import find from 'lodash/find';
import session from 'src/data/models/custom/session';
import { formattedBrandName } from 'src/theme';
import HelpBox from 'src/view/components/help-box';
import AgentSelection from 'src/view/components/input/agent/agent-selection';
import { EventToggle } from 'src/view/components/input/events';
import ImageUpload from 'src/view/components/input/file-upload/image-upload';
import MapDraggablePin from 'src/view/components/input/map-draggable-pin';
import AddressSelect from 'src/view/components/input/select/address';
import VideoInput from 'src/view/components/input/video/upload-video';
import ManualAddress from 'src/view/components/manual-address';
import { validate as validateAddress } from 'src/view/modals/manual-address';
import { RadioGroupInput } from "@rexlabs/radio-input";

const subLabelStyles = StyleSheet({
  container: {
    display: 'block !important',
    margin: '0 !important'
  }
});

export const LISTING_TYPE_OPTIONS = [
  { label: 'For Lease', value: 'residential_rental' },
  { label: 'For Sale', value: 'residential_sale' },
];

const EditListingForm = ({
  slug,
  values,
  onCancel,
  onSubmit,
  isSubmitting,
  listingId,
  session,
  setFieldValue,
  initialImages,
  setFieldError
}) => {
  const token = useToken();
  const [loaded, setLoaded] = useState(false);
  const [showManualAddress, setShowManualAddress] = useState(false);
  const invalidAddress = useRef();

  const { images, video } = values;
  const videoUploading = video?.isUploading ?? false;
  const anyUploading =
    find(images, (image) => image?.isUploading ?? true) || videoUploading;
  const { accounts, currentAccountId } = session || {};
  const account = accounts.find((account) => account.id === currentAccountId);
  const accountAddress = account?.agencies?.data?.[0]?.address?.full_address;

  useEffect(() => {
    setLoaded(true);
  }, []);

  return (
    <Box>
      <FormField
        name='address'
        label={'Address'}
        Input={AddressSelect}
        inputProps={{
          placeholder: `e.g. ${accountAddress}`
        }}
        onChange={(e) => {
          // If the user selected an address we need to validate it as sometimes the BE
          // which uses google for address lookup is missing some address fields (happens most often for NZ)
          if (e.target?.value?.value) {
            const address = e.target.value.value;
            const addressErrors = validateAddress(address);

            if (Object.values(addressErrors).length > 0) {
              invalidAddress.current = { address, addressErrors };
              setShowManualAddress(true);
            }
          }

          const lngLat = e.target?.value?.data?.center;
          if (lngLat) {
            setFieldValue('map_pin_location', lngLat);
          }
        }}
        sendImmediate
      />
      <ManualAddress
        subtitle={
          invalidAddress.current
            ? 'Google maps is unable to find the address. Please enter the missing information below.'
            : ''
        }
        show={showManualAddress}
        initialValues={invalidAddress.current?.address}
        initialErrors={invalidAddress.current?.addressErrors}
        onClose={() => {
          if (showManualAddress) {
            invalidAddress.current = null;
            setShowManualAddress(false);
            setFieldValue('address', '');
          }
        }}
        onSave={(address) => {
          invalidAddress.current = null;
          setShowManualAddress(false);
          setFieldValue('address', address);
          setFieldValue('map_pin_location', address.data.center);
          setFieldError('address', null);
        }}
      />

      <FormField
        name='hide_address'
        isInlineLabel
        Input={Checkbox}
        inputProps={{ grey: true, label: 'Hide address' }}
        sendImmediate
      />

      {slug === 'residential-listing' ?
        <FormField
          name='category'
          label={'Listing Type'}
          Input={RadioGroupInput}
          inputProps={{
            orientation: 'horizontal',
            options: LISTING_TYPE_OPTIONS,
          }}
        /> : null}

      <FormField
        name='map_pin_location'
        label={
          <Box flexDirection='column'>
            <span>Map pin location</span>
            <Tiny grey>
              If the pin location is incorrect, drag the pin to set the correct
              location.
            </Tiny>
          </Box>
        }
        // subLabel='If the pin location is incorrect, drag the pin to set the correct location.'
        subLabelStyles={subLabelStyles}
        Input={MapDraggablePin}
      />

      <FieldRow>
        <FieldCell width={1 / 4}>
          <FormField
            name='beds'
            label='Beds'
            Input={TextInput}
            inputProps={{ type: 'number', step: 1 }}
          />
        </FieldCell>
        <FieldCell width={1 / 4}>
          <FormField
            name='baths'
            label='Baths'
            Input={TextInput}
            inputProps={{ type: 'number', step: 1 }}
          />
        </FieldCell>
        <FieldCell width={1 / 4}>
          <FormField
            name='cars'
            label='Cars'
            Input={TextInput}
            inputProps={{ type: 'number', step: 1 }}
          />
        </FieldCell>
      </FieldRow>

      <FormField
        name='price'
        label='Price'
        Input={TextInput}
        inputProps={{
          placeholder:
            slug === 'rental-listing'
              ? 'e.g. $250 per week'
              : 'e.g. Price by Negotiation'
        }}
      />
      <FormField
        name='is_premium'
        Input={Checkbox}
        inputProps={{ grey: true, label: 'Premium property' }}
        sendImmediate
      />
      <FormField
        name='marketing_headline'
        label='Marketing slogan'
        Input={TextInput}
        inputProps={{
          placeholder: 'e.g. Beautiful Seaside Home'
        }}
      />
      <FormField
        name='description'
        label='Description'
        Input={TextArea}
        inputProps={{
          placeholder: 'A sentence or two describing your listing...'
        }}
      />
      <FormField
        name='marketing_url'
        label='Listing URL'
        Input={TextInput}
        inputProps={{
          placeholder: 'e.g. http://example.com/your-listing'
        }}
      />
      <FormField
        name='images'
        label='Property images'
        Input={ImageUpload}
        inputProps={{
          shouldAllowMultiple: true,
          columns: 2,
          initialImages,
          status: 'loaded',
          sortable: true,
          listingId: values.id
        }}
        sendImmediate
      />
      <FormField
        name='video'
        label={<LabelOptionalField text='Video' />}
        Input={VideoInput}
        sendImmediate
      />

      <FormField name='agent' Input={AgentSelection} sendImmediate />

      <HiddenField name='should_display_events' sendImmediate />

      <FormField
        name='events'
        Input={EventToggle}
        inputProps={{
          toggled: values?.should_display_events,
          onToggle: (toggled) => {
            setFieldValue('should_display_events', toggled);
          }
        }}
      />

      {loaded && (
        <Portal target='contentRight'>
          <HelpBox>
            {({ Title }) => (
              <div>
                <Title>
                  How to write a good marketing slogan and description
                </Title>
                <p>
                  Aim for something memorable and attention-grabbing - your
                  slogan will appear on almost every ad. Bonus points for
                  creating an emotional connection with potential buyers.
                </p>
                <p>
                  We don
                  {"'"}t strictly enforce the character limits so type away. The
                  gotcha here is that titles and descriptions may be cut off in
                  some cases - especially on mobile. Be extra careful with the
                  marketing slogan
                </p>
              </div>
            )}
          </HelpBox>
          <HelpBox>
            {({ Title }) => (
              <div>
                <Title>Listing URL</Title>
                <p>
                  People that click your ads will land on this page. So we can
                  keep track of who’s clicking, make sure this is the listing
                  page on your website - not the portals.
                </p>
              </div>
            )}
          </HelpBox>
          <HelpBox>
            {({ Title }) => (
              <div>
                <Title>Property images</Title>
                <p>
                  {formattedBrandName} needs at least one property image to
                  create a new listing campaign. For best results, upload three
                  or more landscape images with a resolution of at least 1200 x
                  628 pixels. You can always crop your images after uploading.
                </p>
                <p>
                  You can drag and drop your images to rearrange the order they
                  {"'"}
                  re shown in your ads. Make sure to put your best photo in the
                  top left rectangle
                </p>
              </div>
            )}
          </HelpBox>
          <HelpBox>
            {({ Title }) => (
              <div>
                <Title>Property videos</Title>
                <p>Please check the video requirements below:</p>

                <p>
                  Video length: Up to 10 minutes, but we recomend to use a 5-15
                  second video for the best results. If you want to advertise on
                  Instagram the video can’t be longer than 60 secounds.
                </p>

                <p>
                  Supported formats: .mp4, .mov, .avi, .mkv, .ogv, .gif
                  <br />
                  Resolution: 600px minimum width
                  <br />
                  File size: Up to 4 GB max
                  <br />
                </p>
              </div>
            )}
          </HelpBox>
        </Portal>
      )}

      <StickyButtonGroup mt={token('spacing.s')} end>
        <GhostButton isDisabled={!!videoUploading} onClick={onCancel}>
          Cancel
        </GhostButton>
        <PrimaryButton
          form='editListingForm'
          isDisabled={!!anyUploading}
          isLoading={isSubmitting}
          onClick={onSubmit}
        >
          {listingId ? 'Update' : 'Create'}
        </PrimaryButton>
      </StickyButtonGroup>
    </Box>
  );
};

export default withModel(session)(EditListingForm);

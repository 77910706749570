import React, { PureComponent } from 'react';
import { styled, StyleSheet } from '@rexlabs/styling';
import { TEXTS } from 'src/theme';

const defaultStyles = StyleSheet({
  placeholder: TEXTS.PLACEHOLDER
});

@styled(defaultStyles)
class Placeholder extends PureComponent {
  static defaultProps = {
    Tag: 'span'
  };

  render() {
    const { Tag, styles: s, children, ...rest } = this.props;
    return (
      <Tag {...s.with('placeholder')(this.props)} {...rest}>
        {children}
      </Tag>
    );
  }
}

export default Placeholder;

import { useMutation, UseMutationResult, useQueryClient } from 'react-query';
import { api } from 'src/utils/api-client';
import { Upload } from '../types';
import { uploadSchema } from '../utils/schemas';

export type UploadFileParams =
  | {
      file: File;
    }
  | {
      url: string;
    };

export const uploadFile = async (params: UploadFileParams): Promise<Upload> => {
  let res;
  if ('file' in params) {
    const data = new FormData();
    data.set('file', params.file);
    res = await api.post('/uploads', data, {
      headers: { 'Content-Type': 'multipart/form-data' }
    });
  } else {
    res = await api.post('/uploads', { url: params.url });
  }
  return uploadSchema.create(res.data);
};

export const useUploadFile = (): UseMutationResult<
  Upload,
  Error,
  UploadFileParams
> => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: (params) => uploadFile(params),
    onSuccess: (data) => {
      queryClient.setQueryData(['test', 'uploads', data.id], data);
    }
  });
};

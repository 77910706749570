import ActionMenu from '@rexlabs/action-menu';
import { styled, StyleSheet } from '@rexlabs/styling';
import React, { Fragment, useState } from 'react';
import getDomain from 'src/utils/domain';
import { STATUS } from 'src/view/components/campaign';

import { GhostCircleButton } from '@rexlabs/button';
import Icons from '@rexlabs/icons-next';
import UnlinkCampaignModal from 'src/view/modals/admin/campaign/unlink';

const actionMenuStyles = StyleSheet({
  menuItem: {
    cursor: 'pointer',
    display: 'block',
    width: '100%',

    '& a': {
      display: 'block',
      width: '100%'
    },

    '& a:hover': {
      opacity: 1
    },

    '& a:active:focus': {
      outline: 'none'
    }
  }
});

const defaultStyles = StyleSheet({
  link: {
    color: ({ token }) => token('legacy.color.blue.grey'),
    fontWeight: '300',

    ':hover': {
      color: ({ token }) => token('legacy.color.blue.dark'),
      textDecoration: 'none'
    }
  }
});

function EmbedCampaignActionMenu({ campaign, styles: s }) {
  const [openModal, setOpenModal] = useState(null);
  const campaignStatus = campaign?.status?.id;

  const domain = getDomain();

  const items = [
    {
      label: 'Unlink campaign',
      onClick: () => setOpenModal('unlink')
    }
  ];

  if (campaignStatus === STATUS.DRAFT) {
    /**
     * NOTE: setting label to an <a /> tag is a hack that will cause action menu
     * to render an <a /> tag within an <a /> tag but it's needed as action menu doesn't
     * give full control over the item it renders which is needed here to create a
     * link that opens in a new tab.
     */
    items.push({
      label: (
        <a
          href={`${domain}/${campaign.account.id}/campaign/create/listing/${campaign.id}`}
          target='_blank'
          rel='noopener noreferrer'
          {...s('link')}
        >
          Finish campaign setup
        </a>
      )
    });
  }

  if (campaignStatus !== STATUS.DRAFT) {
    // NOTE: see note above this <a /> tag is a hack.
    items.push(
      {
        label: (
          <a
            href={`${domain}/${campaign.account.id}/campaign/edit/${campaign.id}`}
            target='_blank'
            rel='noopener noreferrer'
            {...s('link')}
          >
            Edit
          </a>
        )
      },
      {
        label: (
          <a
            href={campaign.vendor_report.url}
            target='_blank'
            rel='noopener noreferrer'
            {...s('link')}
          >
            View report
          </a>
        )
      }
    );
  }

  return (
    <Fragment>
      <ActionMenu
        Button={GhostCircleButton}
        Icon={Icons.Kebab}
        items={items}
        styles={actionMenuStyles}
      />

      {openModal === 'unlink' && (
        <UnlinkCampaignModal
          closeModal={() => setOpenModal(null)}
          campaignId={campaign.id}
          campaignName={campaign.name}
        />
      )}
    </Fragment>
  );
}

export default styled(defaultStyles)(EmbedCampaignActionMenu);

import { Box } from '@rexlabs/box';
import CSSAnimationGroup from '@rexlabs/css-animation-group';
import { StyleSheet, useStyles } from '@rexlabs/styling';
import React from 'react';
import { EASINGS, KEYFRAMES } from 'src/theme';
import { LoadingSpinner } from 'src/view/components/loading';

import { TextInput } from '@rexlabs/text-input';
import SearchIcon from 'src/assets/icons/search.svg';

const defaultStyles = StyleSheet({
  container: {
    position: 'relative'
  },

  loadingSpinner: {
    position: 'absolute',
    top: 0,
    right: 0,
    bottom: 0,
    width: '5rem',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
  }
});

const SearchInput = ({ isLoading, ...rest }) => {
  const s = useStyles(defaultStyles);
  return (
    <Box flexDirection='column' flex={1} {...s.with('container')(rest)}>
      {/* <SearchIcon {...s('icon')} /> */}
      <TextInput
        prefix={
          <Box alignItems='center'>
            <SearchIcon {...s('icon')} />
          </Box>
        }
        {...rest}
      />
      <CSSAnimationGroup
        enterOnAppear
        enterAnimation={KEYFRAMES.MODAL_FADE_IN}
        enterDuration={'300ms'}
        enterTimingFunction={EASINGS.MODAL_FADE_IN}
        leaveAnimation={KEYFRAMES.MODAL_FADE_OUT}
        leaveDuration={'300ms'}
        leaveTimingFunction={EASINGS.MODAL_FADE_OUT}
      >
        {isLoading && (
          <Box {...s('loadingSpinner')}>
            <LoadingSpinner />
          </Box>
        )}
      </CSSAnimationGroup>
    </Box>
  );
};

export default SearchInput;

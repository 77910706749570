import { DialogContent, DialogOverlay } from '@reach/dialog';
import {
  border,
  margin,
  padding,
  StyleSheet,
  useStyles
} from '@rexlabs/styling';
import React, { PropsWithChildren, CSSProperties } from 'react';

const styles = StyleSheet({
  overlay: {
    background: ({ token }) => token('color.overlay.default'),
    position: 'fixed',
    top: 0,
    right: 0,
    bottom: 0,
    left: 0,
    overflow: 'auto',
    display: 'flex',
    alignItems: 'center'
  },

  content: {
    width: '50vw',
    background: ({ token }) =>
      token('modal.background', token('color.container.static.default')),
    outline: 'none',
    ...padding.styles({
      target: 'modal',
      all: 'l'
    }),
    ...margin.styles({
      target: 'modal',
      x: () => 'auto'
    }),
    ...border.styles({
      target: 'modal',
      all: {
        radius: 'm'
      }
    })
  }
});

export interface ModalProps {
  isOpen: boolean;
  onDismiss(): void;
}

export interface StyleProps {
  className?: string;
  style?: CSSProperties;
}

export function ModalBase({
  className = '',
  style,
  children,
  ...props
}: PropsWithChildren<ModalProps & StyleProps>) {
  const s = useStyles(styles);

  return (
    <DialogOverlay {...s('overlay')} {...props}>
      <DialogContent {...s.with('content')({ className, style })}>
        {children}
      </DialogContent>
    </DialogOverlay>
  );
}

import { imageSchema, imageSizeSchema } from 'src/features/uploads';
import {
  Date,
  Describe,
  Optional,
  UUID,
  array,
  create,
  record,
  string,
  type
} from 'src/lib/superstruct';
import { Agent } from '../types';

export const agentSchema: Describe<Agent> = type({
  id: UUID,
  full_name: string(),
  position: Optional(string()),
  description: Optional(string()),
  slogan: Optional(string()),
  email: Optional(string()),
  url: Optional(string()),
  lead_capture_email: Optional(string()),
  phone_number: Optional(string()),
  created_at: Date,
  updated_at: Date,
  images: Optional(array(imageSchema)),
  thumbnails: record(string(), imageSizeSchema)
});

export const transformAgentResponse = (value: unknown): Agent =>
  create(value, agentSchema);

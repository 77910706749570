import { StyleSheet, margin, useStyles } from '@rexlabs/styling';
import { Large } from '@rexlabs/text';
import React, { ReactNode } from 'react';
import {
  Card,
  CardBody,
  CardBodyProps,
  CardHeader,
  CardProps
} from 'src/components/elements/card';
import { forwardRef } from 'src/lib/polymorphic-factory';

const styles = StyleSheet({
  header: {
    paddingBottom: '1rem'
  },
  body: {
    paddingTop: 0,
    '> * + *': {
      ...margin.styles({
        top: 's'
      })
    },
    '> *': {
      fontSize: ({ token }) => token('typography.size.m'),
      fontWeight: ({ token }) => token('typography.weight.light'),
      lineHeight: ({ token }) => token('typography.lineHeight.m')
    }
  }
});

export type FormGuideCardProps = CardProps & {
  title: ReactNode;
  bodyProps?: CardBodyProps;
};

export const FormGuideCard = forwardRef<'div', FormGuideCardProps>(
  ({ title, bodyProps = {}, children, ...rest }, ref) => {
    const s = useStyles(styles, 'FormGuidanceCard');

    const { className, style, ...restProps } = bodyProps;

    return (
      <Card ref={ref} {...rest}>
        <CardHeader {...s('header')} as={Large}>
          {title}
        </CardHeader>
        <CardBody {...s.with('body')({ className, style })} {...restProps}>
          {children}
        </CardBody>
      </Card>
    );
  }
);

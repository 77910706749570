import React from 'react';
import { ResponsiveContainer, AreaChart, Area } from 'recharts';
import { set } from 'lodash';
import { useToken } from '@rexlabs/styling';

function SparkChart({ data }) {
  const token = useToken();

  /**
   * HACK: this fixes a bug where the chart doesn't render properly caused by
   * a sharp drop from the first and second items in the array and the strokeWidth.
   * Setting the second item to have a value greater than 0 and adjusting the
   * strokeWidth fixes it.
   */
  const sharpDropOff =
    data?.[0]?.impressions > 2000 && data?.[1]?.impressions === 0;

  if (sharpDropOff) {
    set(data, '1.impressions', 20);
  }

  return (
    <ResponsiveContainer height={60} width='100%'>
      <AreaChart data={data} margin={{ top: 10, right: 0, left: 0, bottom: 0 }}>
        <Area
          type='monotone'
          dataKey='impressions'
          stroke={token('palette.brand.500')}
          strokeWidth={sharpDropOff ? 1.2 : 2}
          fill={token('palette.brand.100')}
        />
      </AreaChart>
    </ResponsiveContainer>
  );
}

export default SparkChart;

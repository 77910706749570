import { Checkbox } from '@rexlabs/checkbox';
import { StyleSheet, margin, useStyles } from '@rexlabs/styling';
import { Body } from '@rexlabs/text';
import { formatRFC7231 } from 'date-fns';
import React, { FC, ReactNode, useCallback, useRef } from 'react';
import { ListItem, ListItemProps } from 'src/components/elements/listbox';
import { Consumer } from 'src/types';
import Avatar from 'src/view/components/avatar';
import { Testimonial } from '../types';

const styles = StyleSheet({
  container: {
    listStyle: 'none',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    cursor: 'pointer',
    transition: 'all .2s',

    ':hover': {
      backgroundColor: ({ token }) => token('color.container.interactive.hover')
    },

    ':active': {
      backgroundColor: ({ token }) =>
        token('color.container.interactive.active')
    },

    '&[aria-selected="true"]': {
      backgroundColor: ({ token }) =>
        token('color.container.interactive.selected')
    }
  },

  details: {
    display: 'flex',
    flexDirection: 'column',
    flex: '1 1 auto'
  },

  text: {
    margin: '0',
    padding: '0',
    lineHeight: ({ token }) => token('typography.lineHeight.l'),
    fontWeight: ({ token }) => token('typography.weight.medium'),
    fontSize: ({ token }) => token('typography.size.xl'),
    letterSpacing: '0'
  },

  textMeta: {
    fontSize: ({ token }) => token('typography.size.l')
  },

  toolbar: {
    fontSize: '1.2rem !important',
    display: 'flex',
    alignItems: 'center',

    '& > * + *': {
      ...margin.styles({
        left: 'xxs'
      })
    }
  },

  action: {
    border: 'none',
    background: 'none',
    padding: 0,
    fontSize: ({ token }) => token('typography.size.l'),
    fontWeight: ({ token }) => token('typography.weight.medium'),
    color: ({ token }) => token('color.textStyle.primary.idle.default'),
    cursor: 'pointer',

    ':hover': {
      color: ({ token }) => token('color.textStyle.primary.hover.default')
    }
  },

  delete: {
    color: ({ token }) => token('color.textStyle.danger.idle.default'),

    ':hover': {
      color: ({ token }) => token('color.textStyle.danger.hover.default')
    }
  }
});

export type TestimonialItemProps = Pick<
  ListItemProps,
  'posinset' | 'selected' | 'className' | 'style'
> & {
  item: Testimonial;
  onChange: Consumer<boolean>;
  children?: ReactNode;
};

export const TestimonialItem: FC<TestimonialItemProps> = ({
  item,
  selected = false,
  onChange,
  children,
  className,
  style,
  ...rest
}) => {
  const s = useStyles(styles, 'TestimonialItem');
  const labelId = `${item.id}-label`;

  const handleChange = useCallback(
    () => onChange(!selected),
    [onChange, selected]
  );

  const checkbox = useRef<HTMLInputElement>(null);
  const toggleCheckbox = useCallback(() => checkbox.current?.click(), []);

  return (
    <ListItem
      {...s.with('container')({ className, style })}
      id={item.id}
      aria-labelledby={labelId}
      selected={selected}
      data-testid={'rd-list-item'}
      {...rest}
    >
      <Avatar
        round='full'
        src={item.agent.thumbnails['thumbnail.campaign']?.url}
        onClick={toggleCheckbox}
      />
      <div {...s('details')}>
        <Body
          {...s('text')}
          as={(p) => <span id={labelId} {...p} />}
          onClick={toggleCheckbox}
        >
          {item.author}
        </Body>
        <Body
          {...s('text', 'textMeta')}
          grey
          as='span'
          onClick={toggleCheckbox}
        >
          {formatRFC7231(item.updated_at)}
        </Body>
        <div {...s('toolbar')}>{children}</div>
      </div>
      <Checkbox
        ref={checkbox}
        aria-labelledby={labelId}
        name={item.id}
        value={selected}
        onChange={handleChange}
      />
    </ListItem>
  );
};

import { faker } from '@faker-js/faker';
import { merge } from 'lodash';
import { Image, ImageCrop } from 'src/features/uploads';
import { PartialDeep } from 'type-fest';

export const imageCropGenerator = (
  rest: PartialDeep<ImageCrop> = {}
): ImageCrop => {
  const [x, y] = faker.helpers.uniqueArray(
    () => faker.datatype.number(1024),
    2
  );
  const fake: ImageCrop = {
    height: faker.datatype.number(1024 - y),
    width: faker.datatype.number(1024 - x),
    top_left: {
      x,
      y
    }
  };
  return merge({}, fake, rest);
};

export const libraryImageGenerator = ({
  crops,
  sizes,
  ...rest
}: PartialDeep<Image> = {}): Image => {
  const fake: Image = {
    id: faker.datatype.uuid(),
    name: faker.system.commonFileName('png'),
    crops: {
      landscape: imageCropGenerator(crops?.landscape),
      square: imageCropGenerator(crops?.square)
    },
    sizes: {
      'thumbnail.campaign': {
        height: 0,
        width: 0,
        url: faker.internet.avatar()
      }
    },
    created_at: faker.date.recent(),
    updated_at: faker.date.recent()
  };

  return { ...fake, ...rest };
};

import { GetCommercialListingsParams } from './getCommercialListings';

type AllVariables = {
  accountId: string;
};

export type DetailVariables = AllVariables & {
  listingId: string;
};

export const commercialListingKeys = {
  all: ({ accountId }: AllVariables) =>
    [accountId, 'commercial-listings'] as const,
  lists: (params: AllVariables) =>
    [...commercialListingKeys.all(params), 'list'] as const,
  list: ({ accountId, ...params }: Omit<GetCommercialListingsParams, 'page'>) =>
    [...commercialListingKeys.lists({ accountId }), params] as const,
  details: (params: AllVariables) =>
    [...commercialListingKeys.all(params), 'detail'] as const,
  detail: ({ accountId, listingId }: DetailVariables) => [
    ...commercialListingKeys.details({ accountId }),
    listingId
  ]
};

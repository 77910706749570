import { Box } from '@rexlabs/box';
import { withModel } from '@rexlabs/model-generator';
import { styled, StyleSheet } from '@rexlabs/styling';
import { TooltipStateful } from '@rexlabs/tooltip';
import { autobind } from 'core-decorators';
import moment from 'moment/moment';
import React, { Component, PureComponent } from 'react';
import campaignPerformanceModel from 'src/data/models/sysadmin/campaign-performance';
import { formattedBrandName, withToken } from 'src/theme';
import { currency, percent } from 'src/utils/format';
import { ActionMenu } from 'src/view/components/action-menu';
import Money from 'src/view/components/money';
import { Label } from 'src/view/components/text';
import AddCampaignNoteModel from 'src/view/modals/sysadmin/campaign-notes';
import { Ads } from './ads';
import { Stat } from './stat';

@withModel(campaignPerformanceModel)
@autobind
class CampaignActionMenu extends Component {
  setStatus(status) {
    const { data, campaignPerformance } = this.props;

    campaignPerformance.updateItem({
      id: data.id,
      data: {
        performance_status: { id: status }
      }
    });
  }

  openLink(link, type) {
    window.open(link, 'spoke.dashboard_link.' + type);
  }

  render() {
    const { data, clickAddNote } = this.props;

    let items = [
      {
        label: 'Add Note',
        onClick: clickAddNote
      }
    ];

    if (
      data.performance_status.id !== 'ok' &&
      data.performance_status.id !== 'ok_fixed'
    ) {
      items.push({
        label: 'Mark as OK (fixed)',
        onClick: () => this.setStatus('ok_fixed')
      });
    }

    if (data.performance_status.id !== 'fixing') {
      items.push({
        label: 'Mark as Fixing',
        onClick: () => this.setStatus('fixing')
      });
    }

    if (data.performance_status.id !== 'warning') {
      items.push({
        label: 'Mark as Warning',
        onClick: () => this.setStatus('warning')
      });
    }

    items = [
      ...items,
      {
        label: `Open in ${formattedBrandName}`,
        onClick: () => this.openLink(data.links.spoke, 'spoke')
      },
      {
        label: 'Open in Business Manager',
        onClick: () => this.openLink(data.links.facebook, 'facebook')
      }
    ];

    return <ActionMenu items={items} />;
  }
}

class NetworkStatus extends PureComponent {
  render() {
    const { data, width, warning } = this.props;

    const styles = { width };

    if (typeof warning !== 'undefined') {
      styles.color = 'red';
    }

    if (!data?.errors?.length) {
      return (
        <Box style={{ width }}>
          <span style={styles}>{data.status.label}</span>
        </Box>
      );
    }

    let errI = 0;
    const content = (
      <Box style={{ minWidth: 400 }}>
        <Label>Delivery Issues</Label>
        {data.errors.map((err) => {
          errI++;
          return <div key={errI}>{err}</div>;
        })}
      </Box>
    );

    return (
      <Box style={{ width }}>
        <TooltipStateful
          placement='bottom'
          offset='0px'
          openOn={'HOVER'}
          closeOn={'HOVER'}
          hoverTimeout={250}
          closeDuration={250}
          Content={() => content}
        >
          <span style={styles}>{data.status.label}</span>
        </TooltipStateful>
      </Box>
    );
  }
}

const defaultStyles = StyleSheet({
  container: {
    width: '100%',
    padding: ({ token }) => token('spacing.m'),
    flexShrink: 0,
    cursor: 'pointer',
    transition: 'background .2s',

    ':hover': {
      background: ({ token }) => token('legacy.color.grey.lighter')
    },

    '> *': {},

    '& > :first-child': {}
  },

  section: {
    '> *': {
      flexShrink: 0
    }
  }
});

@withToken
@styled(defaultStyles)
@autobind
class CampaignListItem extends Component {
  state = {
    showNotesModal: false
  };

  handleOpenModal() {
    this.setState({ showNotesModal: true });
  }

  handleCloseModal() {
    this.setState({ showNotesModal: false });
  }

  render() {
    const { token, styles: s, data } = this.props;
    const { showNotesModal } = this.state;

    const statWidth = '3.8em';
    const labelWidth = '6em';

    const { facebook: fbStats, google: googleStats, campaign, warnings } = data;

    const numNotes = data?.notes?.data?.length || 0;

    const daysLeft = campaign?.duration?.total - campaign?.duration?.spent;

    const statusColor =
      data.performance_status.id === 'warning'
        ? token('legacy.color.red.default')
        : data.performance_status.id === 'fixing'
        ? token('legacy.color.yellow.default')
        : token('legacy.color.green.dark');

    return (
      <Box
        p={token('spacing.m')}
        pt={0}
        flexDirection='row'
        alignItems='center'
        justifyContent='space-between'
        {...s('container')}
      >
        <Box style={{ width: labelWidth, color: statusColor }}>
          {data.performance_status.label}
        </Box>
        <Box style={{ width: labelWidth }} onClick={this.handleOpenModal}>
          <span>{numNotes} Notes</span>
        </Box>

        <Box flex={1} onClick={this.handleOpenModal}>
          {campaign.name}
        </Box>
        <Box
          style={{ width: '10em', textOverflow: 'ellipsis' }}
          onClick={this.handleOpenModal}
        >
          {campaign.account.name}
        </Box>
        <Box style={{ width: labelWidth }}>
          {moment(campaign.start_date).format('YYYY-MM-DD')}
        </Box>
        <Box style={{ width: labelWidth }}>{daysLeft}</Box>
        <Box style={{ width: labelWidth }}>{campaign.status.label}</Box>
        <Box style={{ width: labelWidth }}>
          <Money
            amount={campaign.budget.total.value}
            currencyCode={campaign.budget.total.currency_code}
          />
        </Box>
        <Box style={{ width: labelWidth }}>
          <Money
            amount={campaign.budget.total.value - campaign.budget.spent.value}
            currencyCode={campaign.budget.total.currency_code}
          />
        </Box>

        <NetworkStatus
          data={fbStats}
          width={labelWidth}
          warning={warnings?.facebook?.status}
        />
        <Ads width={statWidth} ads={fbStats.ads} />
        <Stat
          value={fbStats.link_clicks}
          warning={warnings?.facebook?.link_clicks}
          width={statWidth}
        />
        <Stat
          value={fbStats.impressions}
          warning={warnings?.facebook?.impressions}
          width={statWidth}
        />
        <Stat
          value={fbStats.click_through_rate}
          warning={warnings?.facebook?.click_through_rate}
          width={statWidth}
          displayWith={percent}
        />
        <Stat
          value={fbStats.cost_per_click}
          warning={warnings?.facebook?.cost_per_click}
          width={statWidth}
          displayWith={currency}
        />
        <Stat
          value={fbStats.budget_spent}
          warning={warnings?.facebook?.budget_spent}
          width={statWidth}
          displayWith={currency}
        />

        <NetworkStatus
          data={googleStats}
          width={labelWidth}
          warning={warnings?.google?.status}
        />
        <Ads width={statWidth} ads={googleStats.ads} />
        <Stat
          value={googleStats.link_clicks}
          warning={warnings?.google?.link_clicks}
          width={statWidth}
        />
        <Stat
          value={googleStats.impressions}
          warning={warnings?.google?.impressions}
          width={statWidth}
        />
        <Stat
          value={googleStats.click_through_rate}
          warning={warnings?.google?.click_through_rate}
          width={statWidth}
          displayWith={percent}
        />
        <Stat
          value={googleStats.cost_per_click}
          warning={warnings?.google?.cost_per_click}
          width={statWidth}
          displayWith={currency}
        />
        <Stat
          value={googleStats.budget_spent}
          warning={warnings?.google?.budget_spent}
          width={statWidth}
          displayWith={currency}
        />
        <Box style={{ width: '3rem' }}>
          <CampaignActionMenu data={data} clickAddNote={this.handleOpenModal} />
        </Box>

        {showNotesModal && (
          <AddCampaignNoteModel
            campaign={campaign}
            notes={data.notes.data}
            closeModal={this.handleCloseModal}
          />
        )}
      </Box>
    );
  }
}

export default CampaignListItem;

import { ActionMenu } from '@rexlabs/action-menu';
import { PrimaryCircleButton } from '@rexlabs/button';
import Icons from '@rexlabs/icons-next';
import React, { FC, forwardRef } from 'react';
import ROUTES from 'src/routes/admin';
import { push } from 'src/utils/whereabouts';

export const SettingsMenu: FC = () => {
  return (
    <ActionMenu
      Icon={Icons.Settings}
      Button={forwardRef((props, ref) => (
        <PrimaryCircleButton ref={ref} title='Settings' {...props} />
      ))}
      items={[
        {
          label: 'Office Profile',
          onClick: () => push(ROUTES.SETTINGS_AGENCY)
        },
        {
          label: 'Landing pages',
          onClick: () => push(ROUTES.SETTINGS_LANDING_PAGES)
        },
        {
          label: 'User Manager',
          onClick: () => push(ROUTES.SETTINGS_USERS)
        },
        {
          label: 'Integrations',
          onClick: () => push(ROUTES.SETTINGS_INTEGRATIONS)
        },
        {
          label: 'Payments',
          onClick: () => push(ROUTES.SETTINGS_PAYMENTS)
        }
      ]}
      placement='bottom'
    />
  );
};

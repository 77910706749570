import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import Box from '@rexlabs/box';
import { styled, StyleSheet } from '@rexlabs/styling';
import _ from 'lodash';
import GooText from '../../goo-text';
import AgentOverlay from '../../agent-overlay';
import charLimit, { limits } from '../../../utils/char-limit';
import { LargeButton } from '../../button';
import ScaledText from '../../scaled-text';
import colorTheme from '../../../utils/color-theme';
import { COLORS } from 'src/theme';

const fontFamily = 'Lato, sans-serif';
const defaultStyles = StyleSheet({
  netboard: {
    width: '580px',
    height: '400px',
    overflow: 'hidden',
    display: 'flex',
    flexDirection: 'column',
    border: '1px solid darkgray'
  },
  primaryImage: {
    height: '330px',
    width: '100%',
    backgroundSize: 'cover',
    backgroundPosition: 'center',
    position: 'relative',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    alignItems: 'flex-start',
    padding: '14px'
  },
  textWrapper: {
    position: 'absolute',
    top: '18px',
    left: '12px'
  },
  smallText: {
    fontFamily,
    fontSize: '14px',
    fontWeight: 600,
    lineHeight: '1.2',
    color: `${COLORS.BLACK}`,
    backgroundColor: 'white',
    textTransform: 'uppercase'
  },
  shortHeadline: {
    fontFamily,
    fontWeight: 400,
    fontSize: '28px',
    lineHeight: 1.2,
    color: `${COLORS.BLACK}`,
    backgroundColor: 'white',
    boxDecorationBreak: 'clone'
  },
  longHeadline: {
    fontSize: '20px',
    fontFamily,
    fontWeight: 500,
    lineHeight: 1.7,
    color: `${COLORS.BLACK}`
  },
  footer: {
    height: '70px',
    width: '100%',
    display: 'flex',
    justifyContent: 'space-between',
    padding: '14px 17px'
  },
  logo: {
    maxWidth: '160px',
    maxHeight: '42px',
    objectFit: 'contain'
  }
});

Netboard.propTypes = {
  shortHeadline: PropTypes.string,
  longHeadline: PropTypes.string,
  bannerAdText: PropTypes.string,
  agentName: PropTypes.string,
  agencyName: PropTypes.string,
  agentProfileUrls: PropTypes.object,
  primaryImageUrls: PropTypes.object,
  logoUrls: PropTypes.object,
  buttonText: PropTypes.string,
  brandColor: PropTypes.string,
  backgroundColor: PropTypes.string
};

function Netboard({
  buttonText = 'View Property',
  shortHeadline,
  longHeadline,
  smallText,
  agencyName,
  agentName,
  agentProfileUrls,
  primaryImageUrls,
  logoUrls,
  brandColor,
  backgroundColor,
  styles: s
}) {
  const { brandColorBorder } = colorTheme(brandColor);

  return (
    <Box {...s('netboard')}>
      <Box
        {...s.with('primaryImage')({
          backgroundImage: `url('${_.get(primaryImageUrls, [
            'crop.landscape',
            'url'
          ])}')`
        })}
      >
        <Box {...s('textWrapper')} width={540}>
          <GooText borderRadius={2} padding='12px 8px 6px'>
            <Fragment>
              <strong {...s('smallText')}>
                {charLimit(smallText, limits.BANNER_AD_TEXT)}
              </strong>
              {smallText && shortHeadline ? <br /> : undefined}
              <ScaledText
                maxWidth={540}
                maxTextLines={2}
                maxFontSize={36}
                minFontSize={28}
                {...s('shortHeadline')}
              >
                {shortHeadline}
              </ScaledText>
            </Fragment>
          </GooText>
          <Box marginBottom={25} />
          <Box width={190}>
            <GooText padding='6px 8px 4px' borderRadius={2}>
              <ScaledText
                maxWidth={190}
                maxTextLines={2}
                maxFontSize={20}
                minFontSize={14}
                {...s('longHeadline')}
              >
                {longHeadline}
              </ScaledText>
            </GooText>
          </Box>
        </Box>
        <Box {...s('agentOverlayWrapper')}>
          <AgentOverlay
            agentName={agentName}
            agencyName={agencyName}
            agentProfileUrl={_.get(agentProfileUrls, ['crop.square', 'url'])}
          />
        </Box>
      </Box>
      <Box
        {...s.with('footer')({
          backgroundColor: backgroundColor,
          borderTop: brandColorBorder
        })}
      >
        <img {...s('logo')} src={_.get(logoUrls, 'original.url')} />
        <Box width={135}>
          <LargeButton brandColor={brandColor} invert={false}>
            {buttonText}
          </LargeButton>
        </Box>
      </Box>
    </Box>
  );
}

export default styled(defaultStyles)(Netboard);

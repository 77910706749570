import { Box } from '@rexlabs/box';
import { styled, StyleSheet, useToken } from '@rexlabs/styling';
import { TooltipStateful } from '@rexlabs/tooltip';
import React from 'react';
import { BORDER_RADIUS } from 'src/theme';
import { Tiny } from 'src/view/components/text';

import CrossIcon from 'src/assets/icons/cross.svg';
import SpokeIcon from 'src/assets/logo-icon.svg';

const defaultStyles = StyleSheet({
  box: {
    width: '4.3rem',
    height: '4.3rem',
    color: ({ token }) => token('color.textStyle.primary.idle.default'),
    border: ({ token }) =>
      `.1rem solid ${token('color.border.primary.idle.default')}`,
    borderRadius: BORDER_RADIUS.INPUT,
    marginLeft: ({ token }) => token('spacing.xxs'),
    marginRight: ({ token }) => token('spacing.xxs'),
    userSelect: 'none'
  },

  optionalBox: {
    color: ({ token }) => token('legacy.color.blue.grey'),
    border: ({ token }) => `.1rem dashed ${token('legacy.color.blue.grey')}`
  },

  filledBox: {
    border: ({ token }) =>
      `.1rem solid ${token('color.border.primary.active.default')}`,
    backgroundColor: ({ token }) => token('color.primary.active.default'),
    color: ({ token }) => token('palette.white'),

    ':hover': {
      background: ({ token }) => token('color.danger.hover.default'),
      borderColor: ({ token }) => token('color.border.danger.hover.default'),
      color: ({ token }) => token('palette.white')
    },

    ':hover > svg': {
      display: 'block'
    }
  },

  cross: {
    display: 'none',
    position: 'absolute',
    color: ({ token }) => token('palette.white'),
    background: ({ token }) => token('color.danger.hover.default'),
    width: '1.2rem',
    height: '1.2rem'
  },

  image: {
    width: '5rem',
    height: '5rem',
    background: 'transparent no-repeat center center',
    backgroundSize: 'cover',
    marginRight: ({ token }) => token('spacing.xs')
  },

  imagePlaceholder: {
    width: '5rem',
    height: '5rem',
    marginRight: ({ token }) => token('spacing.xs'),
    background: ({ token }) => token('legacy.color.grey.medium'),
    color: ({ token }) => token('palette.white')
  }
});

const tooltipStyles = StyleSheet({
  tooltip: {
    padding: ({ token }) => `${token('spacing.xs')} !important`
  }
});

function SelectedListingsBoxes({
  total,
  required,
  items = [],
  removeItem,
  styles: s
}) {
  const token = useToken();

  const Item = (idx) => {
    const hasItem = !!items[idx];

    return (
      <Box
        alignItems='center'
        justifyContent='center'
        {...s('box', {
          optionalBox: idx + 1 > required,
          filledBox: hasItem
        })}
        onClick={
          hasItem
            ? (e) => {
                e.preventDefault();
                removeItem(items[idx].id);
              }
            : undefined
        }
      >
        {idx + 1}
        {hasItem && <CrossIcon {...s('cross')} />}
      </Box>
    );
  };

  const ToolTip = (idx) => {
    const imageSrc = items[idx]?.thumbnails?.['original.thumbnail']?.url;

    return (
      <Box flexDirection='row'>
        {imageSrc ? (
          <Box
            {...s.with('image')({
              backgroundImage: `url(${imageSrc})`
            })}
          />
        ) : (
          <Box
            justifyContent='center'
            alignItems='center'
            {...s('imagePlaceholder')}
          >
            <SpokeIcon />
          </Box>
        )}
        <Tiny grey>{items[idx].formatted_address.short}</Tiny>
      </Box>
    );
  };

  return (
    <Box flexDirection='row' alignItems='center' mr={token('spacing.xl')}>
      {!items[0] && (
        <Box mr={token('spacing.xs')}>
          <Tiny grey>Please select a listing</Tiny>
        </Box>
      )}

      {Array.from({ length: total }, (_, idx) =>
        items[idx] ? (
          <TooltipStateful
            styles={tooltipStyles}
            Content={() => ToolTip(idx)}
            placement={'top'}
            openOn='HOVER'
            closeOn='HOVER'
            hoverTimeout={50}
          >
            {Item(idx)}
          </TooltipStateful>
        ) : (
          Item(idx)
        )
      )}
    </Box>
  );
}

export default styled(defaultStyles)(SelectedListingsBoxes);

import { TOKENS as SPOKE_TOKENS } from './tokens';
import { merge } from 'lodash';

export const FLINK_TOKENS = merge(
  {},
  SPOKE_TOKENS,

  /**
   * BASE TOKENS
   */
  {
    palette: {
      brand: {
        // '50': 'hsl(157, 100%, 81%)',
        100: 'hsl(273, 64%, 78%)',
        // '200': 'hsl(157, 100%, 68%)',
        300: 'hsl(273, 64%, 36%)', // flink primary_light converted to HSL
        400: 'hsl(273, 64%, 48%)',
        500: 'hsl(273, 64%, 24%)', // flink primary converted to HSL
        600: 'hsl(273, 64%, 18%)' // flink primary_dark converted to HSL
        // '700': 'hsl(157, 100%, 18%)',
        // '800': 'hsl(157, 100%, 08%)',
        // '900': 'hsl(157, 100%, 8%)',
      }
    }
  },

  {
    color: {
      textStyle: {
        link: {
          idle: ({ token }) => token('palette.brand.500'),
          hover: ({ token }) => token('palette.brand.500'),
          active: ({ token }) => token('palette.brand.500')
        },
        primary: {
          idle: {
            default: ({ token }) => token('palette.brand.500')
          },
          hover: {
            default: ({ token }) => token('palette.brand.500')
          },
          active: {
            default: ({ token }) => token('palette.brand.500')
          }
        }
      },
      primary: {
        idle: {
          default: ({ token }) => token('palette.brand.500')
        },
        hover: {
          default: ({ token }) => token('palette.brand.600'),
          contrast: ({ token }) => token('color.container.interactive.hover')
        },
        active: {
          default: ({ token }) => token('palette.brand.600')
        }
      },
      input: {
        selected: ({ token }) => token('palette.brand.600')
      },
      container: {
        interactive: {
          hover: 'rgb(250, 252, 253)',
          active: 'rgb(250, 252, 253)'
        }
      },
      border: {
        input: {
          hover: ({ token }) => token('palette.brand.600')
        },
        primary: {
          idle: {
            default: ({ token }) => token('palette.brand.500')
          },
          hover: {
            default: ({ token }) => token('palette.brand.600')
          },
          active: {
            default: ({ token }) => token('palette.brand.600')
          },
          selected: {
            default: ({ token }) => token('palette.brand.600')
          }
        }
      }
    },

    textStyle: {
      link: {
        default: {
          idle: {
            color: ({ token }) => token('palette.brand.500')
          }
        }
      }
    }
  },

  {
    button: {
      primary: {
        background: {
          color: ({ token }) => token('palette.brand.600')
        }
      }
    }
  },

  /**
   * SPOKE CUSTOM COMPONENTS
   * These are tokens for custom Spoke components
   **/
  {
    campaignStatus: {
      active: ({ token }) => token('palette.brand.600'),
      paused: ({ token }) => token('palette.brand.600')
    },
    topNavBar: {
      height: '7rem',
      background: ({ token }) => token('palette.brand.500'),
      logo: {
        height: '3.4rem',
        color: ({ token }) => token('palette.white')
      }
    },

    agencySelector: {
      color: ({ token }) => token('palette.white')
    },

    toggle: {
      background: {
        selected: ({ token }) => token('palette.brand.500')
      }
    },

    campaignReport: {
      deviceStats: {
        color: 'rgba(64, 22, 99, 0.8)'
      },
      demographicStats: {
        color: 'rgba(64, 22, 99, 0.8)'
      }
    },

    campaignWizard: {
      progressBar: {
        color: 'rgb(236, 0, 140)'
      }
    }
  },

  /**
   * LEGACY TOKENS
   * see base tokens file for details
   */
  {
    legacy: {
      color: {
        primary: {
          default: '#401663',
          light: '#612196',
          dark: '#30104A',
          opacity10: 'rgba(64, 22, 99, 0.1)',
          opacity80: 'rgba(64, 22, 99, 0.8)'
        },
        secondary: {
          default: '#EC008C',
          light: '#FF33AC',
          lighter: '#FF66C1',
          lightest: '#FF99D6',
          opacity10: 'rgba(236, 0, 140, 0.1)',
          opacity50: 'rgba(236, 0, 140, 0.5)',
          opacity80: 'rgba(236, 0, 140, 0.8)'
        }
      }
    }
  }
);

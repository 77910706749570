import { Box } from '@rexlabs/box';
import { styled, StyleSheet } from '@rexlabs/styling';
import React, { Component } from 'react';
import TickIcon from 'src/assets/icons/tick.svg';
import { Capitalized } from 'src/view/components/text';

const defaultStyles = StyleSheet({
  badge: {
    height: '2rem',
    borderRadius: '50rem',
    backgroundColor: ({ token }) => token('color.primary.idle.default'),
    paddingLeft: ({ token }) => token('spacing.xs'),
    paddingRight: ({ token }) => token('spacing.xs')
  },
  icon: {
    marginLeft: ({ token }) => token('spacing.xs'),
    color: ({ token }) => token('color.input.idle'),
    width: '0.9rem',
    height: '0.9rem',
    flexShrink: '0'
  }
});

@styled(defaultStyles)
class Pill extends Component {
  render() {
    const { styles: s, text } = this.props;
    return (
      <Box {...s('badge')} alignItems='center' justifyContent='space-around'>
        <Capitalized white>{text}</Capitalized>
        <TickIcon {...s('icon')} />
      </Box>
    );
  }
}

export default Pill;

import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { styled, StyleSheet } from '@rexlabs/styling';
import Box from '@rexlabs/box';
import _ from 'lodash';
import colorTheme from '../../../utils/color-theme';
import { SmallButton } from '../../button';
import ScaledText from '../../scaled-text';
import { COLORS } from 'src/theme';

export const types = {
  DEFAULT: 'default',
  WIDE: 'wide'
};

const fontFamily = 'Lato, sans-serif';

const defaultStyles = StyleSheet({
  skyscraper: {
    border: 'solid 1px darkgray',
    flexGrow: 0,
    flexShrink: 0
  },
  header: {
    position: 'relative'
  },
  img: {
    objectFit: 'cover'
  },
  content: {
    background: 'white'
  },
  shortHeadline: {
    fontSize: '20px',
    lineHeight: '26px',
    fontWeight: '300',
    fontFamily,
    textAlign: 'center',
    letterSpacing: '-0.15px'
  },
  footer: {},
  footerLogo: {
    objectFit: 'contain'
  }
});

@styled(defaultStyles)
class Skyscraper extends PureComponent {
  static propTypes = {
    brandColor: PropTypes.string,
    backgroundColor: PropTypes.string,
    shortHeadline: PropTypes.string,
    primaryImageUrls: PropTypes.object,
    secondaryImageUrls: PropTypes.object,
    logoUrls: PropTypes.object,
    buttonText: PropTypes.string,
    width: PropTypes.string
  };

  static defaultProps = {
    width: '120px',
    buttonText: 'Learn more'
  };

  logoDimensions() {
    const { type } = this.props;
    return {
      maxWidth: `calc(0.6 * ${type === types.WIDE ? '160px' : '120px'})`,
      maxHeight: 'calc(0.7 * 60px)'
    };
  }

  render() {
    const {
      brandColor,
      backgroundColor,
      shortHeadline,
      primaryImageUrls,
      secondaryImageUrls,
      logoUrls,
      buttonText,
      width,
      styles: s
    } = this.props;

    const { brandColorBorder } = colorTheme(brandColor);

    return (
      <Box
        width={width}
        height='600px'
        display='flex'
        flexDirection='column'
        {...s('skyscraper')}
      >
        <Box height='166px' {...s('header')}>
          <img
            width='100%'
            height={166}
            src={_.get(primaryImageUrls, ['crop.square', 'url'])}
            {...s('img')}
          />
        </Box>
        <Box
          flex={1}
          display='flex'
          flexDirection='column'
          padding='24px 14px'
          alignItems='center'
          {...s('content')}
        >
          <ScaledText
            maxWidth={92}
            maxTextLines={4}
            maxFontSize={24}
            minFontSize={12}
            {...s('shortHeadline')}
            color={COLORS.BLACK}
          >
            {shortHeadline}
          </ScaledText>
          <Box marginTop={24} width='100%'>
            <SmallButton brandColor={brandColor}>{buttonText}</SmallButton>
          </Box>
        </Box>
        <img
          width='100%'
          height={169}
          src={_.get(secondaryImageUrls, ['crop.square', 'url'])}
          {...s('img')}
        />
        <Box
          height='60px'
          display='flex'
          flexDirection='column'
          alignItems='center'
          justifyContent='center'
          padding='15px 40px'
          {...s.with('footer')({
            backgroundColor: backgroundColor,
            borderTop: brandColorBorder
          })}
        >
          <img
            src={_.get(logoUrls, 'original.url')}
            {...s.with('footerLogo')({ ...this.logoDimensions() })}
          />
        </Box>
      </Box>
    );
  }
}

export default Skyscraper;

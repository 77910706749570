import {Box} from "@rexlabs/box";
import LoadingSpinner from "@rexlabs/loading-spinner";
import {StatusGoodTag} from "@rexlabs/tag";
import React from "react";

type CatalogListingsCountTagProps = {
  isLoading: boolean;
  count?: number;
  label: string;
}
export function CatalogListingsCountTag({count, label, isLoading}: CatalogListingsCountTagProps) {
  return (
    <StatusGoodTag>
      <Box flexDirection={"row"} alignItems={'center'} gap={'4px'}>
        <span>{isLoading ? <LoadingSpinner size={12} strokeWidth={3}/> : count}</span>
        <span>{` ${label}`}</span>
      </Box>
    </StatusGoodTag>
  )
}

import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { styled, StyleSheet } from '@rexlabs/styling';
import Box from '@rexlabs/box';
import _ from 'lodash';
import colorTheme from '../../../utils/color-theme';
import { RegularButton } from '../../button';
import GooText from '../../goo-text';
import ScaledText from '../../scaled-text';
import charLimit, { limits } from '../../../utils/char-limit';
import { COLORS } from 'src/theme';

const fontFamily = 'Lato, sans-serif';

const defaultStyles = StyleSheet({
  inlineRectangle: {
    width: '300px',
    height: '250px',
    border: '1px solid darkgray',
    overflow: 'hidden'
  },
  header: {
    height: '190px',
    width: '100%',
    backgroundSize: 'cover',
    backgroundPosition: 'center',
    position: 'relative'
  },
  textWrapper: {
    position: 'absolute',
    top: '14px',
    left: '12px'
  },
  smallText: {
    fontFamily,
    fontSize: '12px',
    fontWeight: 600,
    lineHeight: '23px',
    color: `${COLORS.BLACK}`,
    backgroundColor: 'white',
    textTransform: 'uppercase'
  },
  shortHeadline: {
    fontFamily,
    fontWeight: 400,
    fontSize: '20px',
    lineHeight: 1.4,
    color: `${COLORS.BLACK}`,
    backgroundColor: 'white'
  },
  footer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: '14px',
    height: '60px'
  },
  footerLogo: {
    display: 'block',
    maxWidth: '80%',
    maxHeight: 'calc(0.66 * 60px)',
    objectFit: 'contain'
  }
});

InlineRectangle.propTypes = {
  shortHeadline: PropTypes.string,
  bannerAdText: PropTypes.string,
  primaryImageUrls: PropTypes.object,
  logoUrls: PropTypes.object,
  brandColor: PropTypes.string,
  buttonText: PropTypes.string
};

function InlineRectangle({
  buttonText = 'View Property',
  shortHeadline,
  smallText,
  primaryImageUrls,
  logoUrls,
  brandColor,
  backgroundColor,
  styles: s
}) {
  const { brandColorBorder } = colorTheme(brandColor);

  return (
    <Box {...s('inlineRectangle')}>
      <Box
        {...s.with('header')({
          backgroundImage: `url('${_.get(primaryImageUrls, [
            'crop.square',
            'url'
          ])}')`
        })}
      >
        <Box {...s('textWrapper')} width={255}>
          <GooText borderRadius={3} padding='5px 10px 7px'>
            <Fragment>
              <strong {...s('smallText')}>
                {charLimit(smallText, limits.SMALL_TEXT)}
              </strong>
              {smallText ? <br /> : undefined}
              <ScaledText
                maxWidth={235}
                maxTextLines={2}
                maxFontSize={24}
                minFontSize={16}
                {...s('shortHeadline')}
              >
                {shortHeadline}
              </ScaledText>
            </Fragment>
          </GooText>
        </Box>
      </Box>
      <Box
        {...s.with('footer')({
          backgroundColor: backgroundColor,
          borderTop: brandColorBorder
        })}
      >
        <Box alignItems='center' style={{ marginRight: '14px' }}>
          <img src={_.get(logoUrls, 'original.url')} {...s('footerLogo')} />
        </Box>
        <Box style={{ minWidth: '121px' }}>
          <RegularButton brandColor={brandColor} invert={false}>
            {buttonText}
          </RegularButton>
        </Box>
      </Box>
    </Box>
  );
}

export default styled(defaultStyles)(InlineRectangle);

import { useMutation, UseMutationResult, useQueryClient } from 'react-query';
import { api } from 'src/utils/api-client';
import { ResourceDownload, ResourceDownloadDTO } from '../types';
import { resourceDownloadKeys } from './keys';
import { useAccountId } from 'src/features/accounts';

export type CreateResourceDownloadParams = {
  data: ResourceDownloadDTO;
};

export const createResourceDownload = async ({
  data
}: CreateResourceDownloadParams): Promise<ResourceDownload> => {
  const res = await api.post('/resource-downloads', data);
  return res.data;
};

export const useCreateResourceDownload = (): UseMutationResult<
  ResourceDownload,
  Error,
  ResourceDownloadDTO
> => {
  const queryClient = useQueryClient();
  const accountId = useAccountId();
  return useMutation({
    mutationFn: (data) => createResourceDownload({ data }),
    onSuccess: () => {
      queryClient.invalidateQueries(resourceDownloadKeys.lists(accountId));
    }
  });
};

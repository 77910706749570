import { useQuery } from 'react-query';
import { QueryConfig } from 'src/lib/react-query';
import { api } from 'src/utils/api-client';
import { agenciesKeys } from './keys';
import {Agency} from "src/features/agencies/types";

export type GetAgenciesParams = {
  includes?: string[];
};

export const getAgencies = async ({
 includes
}: GetAgenciesParams): Promise<Agency[]> => {
  const { data } = await api.get(`/agencies`, {
    include: includes?.join(',')
  });
  return data;
};

type QueryFnType = typeof getAgencies;

export type UseAgenciesOptions = {
  includes?: string[];
  config?: QueryConfig<QueryFnType>;
};

export const useAgencies = ({
  includes,
 config
}: UseAgenciesOptions) => {
  return useQuery<Agency[], Error>({
    queryFn: () => getAgencies({includes}),
    queryKey: agenciesKeys.all,
    ...config
  });
};

import { useInfiniteQuery } from 'react-query';
import { useAccountId } from 'src/features/accounts';
import { InfiniteQueryConfig, InfiniteQueryResult } from 'src/lib/react-query';
import { PaginatedResponse, Pagination, PaginationParams } from 'src/types';
import { api } from 'src/utils/api-client';
import { CatalogListingSet } from '../types';
import { transformCatalogListingSetResponse } from '../utils/schemas';
import { catalogListingSetKeys } from './keys';

export type GetCatalogListingSetParams = PaginationParams & {
    accountId: string;
    q?: string;
};

export const getCatalogListingSets = async ({
    accountId,
    q,
    ...params
}: GetCatalogListingSetParams): Promise<PaginatedResponse<CatalogListingSet>> => {
    const { data, pagination } = await api.get('/listing-sets', {
        ...params,
    });
    return {
        data: data.map(transformCatalogListingSetResponse),
        pagination: pagination as Pagination
    };
};

type QueryFnType = typeof getCatalogListingSets;

export type UseCatalogListingSetOptions = Omit<
    GetCatalogListingSetParams,
    'accountId' | 'page'
> & {
    config?: InfiniteQueryConfig<QueryFnType>;
};

export const useCatalogListingSets = ({
  config,
  ...params
}: UseCatalogListingSetOptions = {}): InfiniteQueryResult<CatalogListingSet> => {
    const accountId = useAccountId();

    return useInfiniteQuery<
        PaginatedResponse<CatalogListingSet>,
        Error,
        PaginatedResponse<CatalogListingSet>
    >({
        queryKey: catalogListingSetKeys.all({ accountId, ...params }),
        queryFn: ({ pageParam }) =>
            getCatalogListingSets({ ...params, accountId, page: pageParam }),
        getNextPageParam: ({ pagination }) =>
            pagination.current_page === pagination.total_pages
                ? undefined
                : pagination.current_page + 1,
        ...config
    });
};

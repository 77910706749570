import React, { FC, useCallback, useMemo } from 'react';
import {
  TestimonialForm,
  TestimonialSubmitHandler
} from 'src/features/testimonials/components/TestimonialForm';
import { TestimonialFormGuide } from 'src/features/testimonials/components/TestimonialFormGuide';
import { useNavigate } from 'react-router-dom';
import { useToast } from '@rexlabs/notifications';
import { useTestimonial } from 'src/features/testimonials/api/getTestimonial';
import { useUpdateTestimonial } from 'src/features/testimonials/api/updateTestimonial';

import { StepEditorLayout } from 'src/features/wizard/components/StepEditorLayout';

export type TestimonialEditFormProps = {
  contentId: string;
  selectContent: (id: string) => void;
};

export const TestimonialEditForm: FC<TestimonialEditFormProps> = ({
  contentId,
  selectContent
}) => {
  const navigate = useNavigate();
  const toasts = useToast();

  const { mutateAsync: updateTestimonial } = useUpdateTestimonial();

  const { data } = useTestimonial({ testimonialId: contentId });

  const handleUpdate = useCallback<TestimonialSubmitHandler>(
    async (data) => {
      if (contentId) {
        try {
          await updateTestimonial({
            testimonialId: contentId,
            testimonial: data
          }).then((res) => {
            selectContent?.(res.id);
            navigate('../Testimonial');
          });
        } catch (e) {
          toasts.addToast({
            type: 'error',
            title: "Couldn't update testimonial",
            description: e instanceof Error ? e.message : undefined
          });
        }
      }
    },
    [contentId, updateTestimonial, selectContent, navigate, toasts]
  );

  const resource = useMemo(
    () =>
      data
        ? {
            author: data.author,
            testimonial: data.testimonial,
            excerpt: data.excerpt,
            agent_id: !data.agent.deleted_at ? data.agent.id : ''
          }
        : undefined,
    [data]
  );

  const exitEditor = useCallback(() => navigate('..'), [navigate]);

  return (
    <StepEditorLayout
      title='Edit testimonial'
      Preview={<TestimonialFormGuide />}
    >
      <TestimonialForm
        submitLabel='Update'
        resource={resource}
        onSubmit={handleUpdate}
        onCancel={exitEditor}
      />
    </StepEditorLayout>
  );
};

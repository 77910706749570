import { Box } from '@rexlabs/box';
import { styled, StyleSheet } from '@rexlabs/styling';
import React from 'react';

import { cropText } from './shared/utils';

import GoogleIconChevron from 'src/assets/ad-previews/google/icon-chevron.svg';
import GoogleIconInfo from 'src/assets/ad-previews/google/icon-info.svg';

const defaultStyles = StyleSheet({
  container: {
    width: '32rem',
    background: 'white',
    position: 'relative',
    border: '.1rem solid #e5e5e5',
    fontFamily: 'Roboto, Arial'
  },

  info: {
    position: 'absolute',
    top: 1,
    right: 1,
    background: 'white',
    color: '#00aecd',
    width: '1.8rem',
    height: '1.8rem'
  },

  image: {
    width: '17.2rem',
    height: '5rem',
    background: 'grey no-repeat center center',
    backgroundSize: 'cover',
    position: 'relative',
    overflow: 'hidden'
  },

  wrapHeading: {
    padding: '0 .8rem',
    height: '5rem',
    textAlign: 'center'
  },

  heading: {
    padding: '.2rem 0',
    fontWeight: 700,
    fontSize: '1.6rem',
    lineHeight: '1.1'
  },

  wrapButton: {
    height: '5rem',
    padding: '1.2rem'
  },

  wrapChevron: {
    width: '3rem',
    height: '3rem',
    boxShadow:
      '0 0 .2rem 0 rgba(0, 0, 0, 0.12), 0 .2rem .2rem 0 rgba(0, 0, 0, 0.24)',
    borderRadius: '3rem',
    marginRight: '1.2rem',
    color: '#919191',

    '> svg': {
      height: '1.4rem',
      width: 'auto'
    }
  }
});

const AdWordsSmallPreview = (props) => {
  const { styles: s, campaignData, ad } = props;

  const isMultiListingSold = campaignData.type === 'multi_listing_sold';
  const slide =
    campaignData.slides.find((s) => s.id === ad?.attributes?.slide_id) || {};

  let imageUrl;
  let imageCrop;
  let title;
  const titleLength = 30;
  if (isMultiListingSold) {
    const image = campaignData.images.find((i) => i.id === slide?.image?.id);
    imageUrl = image?.sizes?.['crop.landscape']?.url;
    imageCrop = Object.values(image?.crops?.landscape?.top_left ?? {}).join();
    title = cropText(slide.small_text, titleLength);
  } else {
    const isAgent = campaignData.type === 'agent_profile';

    const format = ad?.format?.id ?? '';
    const isSecondary = format.includes('responsive_secondary');

    const imageKey = isAgent
      ? 'agentImage'
      : isSecondary
      ? 'secondaryImage'
      : 'primaryImage';

    imageUrl = campaignData?.[imageKey]?.landscape;
    title = cropText(campaignData.title, titleLength);
  }

  return (
    <Box {...s('container')} flexDirection='row'>
      <Box {...s('info')} justifyContent='center' alignItems='center'>
        <GoogleIconInfo {...s('infoSvg')} />
      </Box>

      <Box
        key={imageCrop}
        {...s.with('image')({
          backgroundImage: imageUrl ? `url(${imageUrl})` : 'none'
        })}
      />

      <Box {...s('wrapHeading')} justifyContent='center' alignItems='center'>
        <span {...s('heading')}>{title}</span>
      </Box>

      <Box {...s('wrapButton')} alignItems='center'>
        <Box {...s('wrapChevron')} justifyContent='center' alignItems='center'>
          <GoogleIconChevron />
        </Box>
      </Box>
    </Box>
  );
};

export default styled(defaultStyles)(AdWordsSmallPreview);

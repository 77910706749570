import _ from 'lodash';
import sessionModel from 'src/data/models/custom/session';
import { createRestAPIModelGenerator } from 'src/data/models/generator';
import { api } from 'src/utils/api-client';

export const TYPE = 'accounts';

const config = {
  entities: {
    api: {
      fetchList: (type, args) => api.get('/admin/accounts', args),
      updateItem: (type, args, id) => api.patch(`/accounts/${id}`, args)
    },
    related: {
      provision: {
        include: 'provision'
      }
    }
  }
};

const actionCreators = {
  activate: {
    request: (payload, actions, dispatch) =>
      api
        .patch(`/accounts/${payload.id}`, { ready: true })
        .then(() => dispatch(sessionModel.actionCreators.init()))
        .catch((e) => {
          actions.refreshItem({ id: payload.id });
          throw e;
        }),
    reduce: {
      initial: (state, action) => ({
        ...state,
        items: {
          ...state.items,
          [action.payload.id]: {
            ...(state?.items?.[action.payload.id] ?? {}),
            data: {
              ...(state?.items?.[action.payload.id]?.data ?? {}),
              ready: true
            },
            etag: new Date().getTime()
          }
        }
      }),
      success: _.identity,
      failure: _.identity
    }
  },

  deactivate: {
    request: (payload, actions, dispatch) =>
      api
        .patch(`/accounts/${payload.id}`, { ready: false })
        .then(() => dispatch(sessionModel.actionCreators.init()))
        .catch((e) => {
          actions.refreshItem({ id: payload.id });
          throw e;
        }),
    reduce: {
      initial: (state, action) => ({
        ...state,
        items: {
          ...state.items,
          [action.payload.id]: {
            ...(state?.items?.[action.payload.id] ?? {}),
            data: {
              ...(state?.items?.[action.payload.id]?.data ?? {}),
              ready: false
            },
            etag: new Date().getTime()
          }
        }
      }),
      success: _.identity,
      failure: _.identity
    }
  }
};

const AccountsGenerator = createRestAPIModelGenerator(TYPE, config);
export default AccountsGenerator.createEntityModel({ actionCreators });

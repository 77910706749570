import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import InlineRectangle, { types } from './inline-rectangle';

export default class LargeRectangle extends PureComponent {
  static propTypes = {
    shortHeadline: PropTypes.string,
    agentName: PropTypes.string,
    suburb: PropTypes.string,
    buttonText: PropTypes.string,
    brandColor: PropTypes.string,
    primaryImageUrls: PropTypes.object,
    logoUrls: PropTypes.object
  };

  static defaultProps = {
    buttonText: 'Learn more'
  };

  render() {
    return <InlineRectangle {...this.props} type={types.LARGE} />;
  }
}

import { StyleSheet, useStyles } from '@rexlabs/styling';
import { iteratee, uniqueId } from 'lodash';
import React, {
  ChangeEventHandler,
  ComponentType,
  ForwardedRef,
  forwardRef,
  Key,
  MouseEventHandler,
  ReactElement,
  RefAttributes,
  useCallback,
  useMemo,
  useState
} from 'react';
import { FormControlOptions, useFormControl } from 'src/components/form';
import { useEnsuredForwardedRef } from 'src/hooks';
import { Consumer, Runnable, UnaryFunction } from 'src/types';
import { findAndRemove, findAndUpdate } from 'src/utils/collections';
import { matcher } from 'src/utils/file';
import { FileValueDisplay } from './FileValueDisplay';
import {
  EmptyInputProps,
  FileDisplayProps,
  FileUploadFn,
  FileValue,
  InvalidFileTypeError,
  MaxFileSizeExceededError,
  PendingFileValue,
  PreviewDataTransformer,
  TooManyFilesError
} from './types';

const styles = StyleSheet({
  container: {
    width: '100%'
  },
  input: {
    border: '0',
    clip: 'rect(0, 0, 0, 0)',
    height: '1px',
    width: '1px',
    margin: '-1px',
    padding: '0',
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    position: 'absolute'
  }
});

type FileValueProps<T> =
  | {
      multiple?: false;
      value?: T;
      onChange?: Consumer<T | undefined>;
    }
  | {
      multiple: true;
      value?: T[];
      onChange?: Consumer<T[] | undefined>;
    };

/**
 * Wrap the value and onChange props adapt them to work with single or multiple file uploads.
 * @param props The multiple, value, and onChange props
 * @returns Props where value is an array and onChange takes an array
 */
const fileValuePropsAdapter = <T,>(
  props: FileValueProps<T>
): {
  multiple: boolean;
  value?: T[];
  onChange?: Consumer<T[] | undefined>;
} => {
  if (props.multiple) {
    return {
      ...props,
      multiple: true,
      value: props.value,
      onChange: props.onChange
    };
  } else {
    return {
      ...props,
      multiple: false,
      value: props.value ? [props.value] : undefined,
      onChange: (values) => props.onChange?.(values?.[0])
    };
  }
};

export type FileUploadInputProps<T> = Omit<
  JSX.IntrinsicElements['input'],
  'value' | 'onChange' | 'ref' | 'type' | 'multiple' | 'onBlur'
> &
  FormControlOptions & {
    onBlur?: Runnable;
    onFileSelect: FileUploadFn<T>;
    onSelectError?: Consumer<Error>;
    getFileKey: UnaryFunction<T, Key>;
    getPreviewData: PreviewDataTransformer<T>;
    maxFileSize?: number;
    EmptyInput: ComponentType<EmptyInputProps>;
    FileDisplay: ComponentType<FileDisplayProps<T>>;
  } & FileValueProps<T>;

export const FileUploadInput = forwardRef(
  <T,>(
    { onBlur, ...props }: FileUploadInputProps<T>,
    ref: ForwardedRef<HTMLInputElement>
  ) => {
    const s = useStyles(styles, 'FileUploadInput');
    const {
      id,
      multiple,
      value,
      onChange,
      onFileSelect,
      getFileKey,
      onSelectError,
      getPreviewData,
      EmptyInput,
      FileDisplay,
      className,
      style,
      accept = '*/*',
      maxFileSize = Infinity,
      ...rest
    } = useFormControl(props);

    const {
      multiple: adaptedMultiple,
      value: adaptedValue = [],
      onChange: adaptedOnChange
    } = useMemo(
      () =>
        fileValuePropsAdapter({
          multiple,
          value,
          onChange
        } as FileValueProps<T>),
      // We don't care about changes to the value since this is an uncontrolled input.
      // eslint-disable-next-line react-hooks/exhaustive-deps
      [multiple, onChange]
    );

    // TODO: Extract the file state management logic into a hook.
    const [files, setFiles] = useState<FileValue<T>[]>(
      adaptedValue.map((value) => ({
        type: 'complete',
        id: getFileKey(value),
        value
      }))
    );
    const inputRef = useEnsuredForwardedRef(ref);

    // Wrap the onChange callback to only send completed uploads
    const onCompletedChange = useCallback(
      (values: FileValue<T>[]) => {
        const completed = values
          .filter(iteratee({ type: 'complete' }))
          .map(iteratee('value'));
        adaptedOnChange?.(completed);
      },
      [adaptedOnChange]
    );

    const mimeChecker = useMemo(
      () => matcher(...accept.split(',').filter((v) => !v.startsWith('.'))),
      [accept]
    );

    // Logic to validate and add files to the input
    const addFiles = useCallback(
      (files: File[]): void => {
        onBlur?.();
        if (!multiple && files.length > 1) {
          onSelectError?.(new TooManyFilesError());
          return;
        }

        if (!files.every(({ type }) => mimeChecker(type))) {
          onSelectError?.(new InvalidFileTypeError());
          return;
        }

        if (files.some((f) => f.size > maxFileSize)) {
          onSelectError?.(new MaxFileSizeExceededError(maxFileSize));
          return;
        }

        const fileValues: PendingFileValue[] = files.map((file) => ({
          type: 'pending',
          id: uniqueId(),
          file,
          abortController: new AbortController()
        }));
        setFiles((oldFileValues) => [...oldFileValues, ...fileValues]);
      },
      [maxFileSize, mimeChecker, multiple, onBlur, onSelectError]
    );

    // Function removes files by their id
    const removeFile = useCallback(
      (id: Key): void => {
        setFiles((oldFileValues) => {
          const newValues = ([] as FileValue<T>[]).concat(oldFileValues);
          const fileValue = findAndRemove(newValues, (v) => v.id === id);
          if (fileValue?.type === 'pending') fileValue.abortController.abort();
          onCompletedChange(newValues);
          return newValues;
        });
      },
      [onCompletedChange]
    );

    // Updates the state of a file value when it completes uploading
    const updateStateOnCompletedUpload = useCallback(
      async (id: Key, value: T) => {
        setFiles((oldValues) => {
          const newValues = findAndUpdate(
            oldValues,
            (v) => v.id === id,
            ({ id, file }) =>
              ({
                type: 'complete',
                id,
                value,
                file
              } as FileValue<T>)
          );
          onCompletedChange(newValues);
          return newValues;
        });
      },
      [onCompletedChange]
    );

    // Adapts the underlying input element to work with the upload logic
    const handleInputChange = useCallback<ChangeEventHandler<HTMLInputElement>>(
      (e) => {
        addFiles(Array.from(e.target.files ?? []));
      },
      [addFiles]
    );
    const openNativeFileDialog: MouseEventHandler = () => {
      inputRef.current.click();
    };

    const isEmpty = files.length === 0;
    return (
      <>
        <div {...s.with('container')({ className, style })}>
          {multiple || isEmpty ? (
            <EmptyInput
              onClick={openNativeFileDialog}
              addFiles={addFiles}
              isEmpty={isEmpty}
              multiple={adaptedMultiple}
              accept={accept}
            />
          ) : null}
          {files.map((fileValue) => (
            <FileValueDisplay
              key={fileValue.id}
              getPreviewData={getPreviewData}
              Component={FileDisplay}
              uploadFile={onFileSelect}
              fileValue={fileValue}
              removeFile={removeFile}
              onComplete={updateStateOnCompletedUpload}
            />
          ))}
        </div>
        <input
          {...s('input')}
          id={id}
          ref={inputRef}
          type='file'
          onChange={handleInputChange}
          multiple={adaptedMultiple}
          accept={accept}
          tabIndex={-1}
          {...rest}
        />
      </>
    );
  }
) as <T>(
  props: FileUploadInputProps<T> & RefAttributes<HTMLInputElement>
) => ReactElement | null;

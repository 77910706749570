import { useMutation } from 'react-query';
import { useAccountId } from 'src/features/accounts';
import { MutationResult } from 'src/lib/react-query';
import { api } from 'src/utils/api-client';
import { Image, ImageDTO } from '../types';
import { createImage as imageTransformer } from '../utils/schemas';

export type CreateImageParams = {
  accountId: string;
  image: ImageDTO;
};

export const createImage = async ({
  image
}: CreateImageParams): Promise<Image> => {
  const { data } = await api.post('images', image, {
    // TODO: Use the accountId instead of the implicit X-Account-ID header
    params: {
      include: 'sizes'
    }
  });
  return imageTransformer(data);
};

export const useCreateImage = (): MutationResult<Image, ImageDTO> => {
  const accountId = useAccountId();

  return useMutation({
    mutationFn: (data) => createImage({ accountId, image: data })
  });
};

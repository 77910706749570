export default {
  namespace: 'accounts',

  // Sorting options
  sorting: {},

  // Filter options
  filters: {
    search: {
      label: 'Search'
    }
  }
};

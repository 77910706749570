import { Box } from '@rexlabs/box';
import { styled, StyleSheet } from '@rexlabs/styling';
import { TooltipStateful } from '@rexlabs/tooltip';
import { autobind } from 'core-decorators';
import types from 'prop-types';
import React, { PureComponent } from 'react';
import { BORDER_RADIUS, withToken } from 'src/theme';
import { getContrastColor } from 'src/utils/color';
import { SmallTextInput } from 'src/view/components/input';
import tinycolor from 'tinycolor2';

import EyeDropper from 'src/assets/icons/color-picker.svg';

const defaultStyles = StyleSheet({
  button: {
    borderRadius: BORDER_RADIUS.INPUT,
    borderTopRightRadius: 0,
    borderBottomRightRadius: 0,
    width: '4.8rem',
    height: '4.8rem',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    cursor: 'pointer',
    transition: 'background .2s, color .2s',
    border: ({ token }) => `.1rem solid ${token('legacy.color.blue.grey')}`,

    '> svg': {
      transition: 'transform .2s'
    },

    ':hover > svg': {
      transform: 'scale(1.1)'
    }
  },

  container: {
    display: 'inline-flex'
  },

  hexValue: {
    border: ({ token }) => `.1rem solid ${token('legacy.color.blue.grey')}`,
    borderLeft: '0 none',
    borderTopRightRadius: BORDER_RADIUS.INPUT,
    borderBottomRightRadius: BORDER_RADIUS.INPUT,
    paddingLeft: ({ token }) => token('spacing.m'),
    paddingRight: ({ token }) => token('spacing.m'),
    height: '4.8rem',
    background: ({ token }) => token('legacy.color.grey.light')
  },

  value: {
    fontSize: '1.8rem',
    color: ({ token }) => token('color.textStyle.body.contrast')
  },

  valueDark: {
    color: ({ token }) => token('color.textStyle.body.default')
  },

  colorButton: {
    float: 'left',
    width: '3rem',
    height: '3rem',
    margin: ({ token }) => token('spacing.xxs'),
    border: '0 none',
    borderRadius: BORDER_RADIUS.INPUT,
    cursor: 'pointer'
  },

  wrapColors: {
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
    width: '28rem'
  },

  wrapInput: {
    width: `${4 * 3 + 6 * 0.4}rem`,
    margin: ({ token }) => `${token('spacing.xxs')}`
  }
});

@withToken
@styled(defaultStyles)
@autobind
class ColorPicker extends PureComponent {
  static propTypes = {
    colors: types.array
  };

  static defaultProps = {
    colors: [
      '#000000',
      '#ffffff',
      '#7adcb6',
      '#00d084',
      '#8ed1fc',
      '#0a92e3',
      '#abb8c3',
      '#eb244d',
      '#f78ca7',
      '#9833f0'
    ],
    placement: 'bottom-start'
  };

  _refs = {};

  constructor(props) {
    super(props);
    this.state = {
      value: props.value,
      focused: false
    };

    this.tooltipStyles = StyleSheet({
      tooltip: {
        float: 'left !important',
        padding: ({ token }) => `${token('spacing.xs')} !important`
      }
    });
  }

  componentWillReceiveProps(nextProps) {
    if (!this.state.focused && this.props.value !== nextProps.value) {
      this.setState({ value: nextProps.value });
    }
  }

  fakeEvent(value) {
    const { name } = this.props;
    return {
      persist: () => null,
      target: {
        type: 'string',
        name,
        id: name,
        value
      }
    };
  }

  handleCustomChange(e) {
    const { onChange } = this.props;
    this.setState({ value: e.target.value });
    const color = tinycolor(e.target.value);
    if (color.isValid()) {
      onChange(this.fakeEvent(`#${color.toHex()}`));
    }
  }

  renderOverlay() {
    const { styles: s, colors, onChange, token } = this.props;
    return (
      <Box {...s('wrapColors')} data-testid={'color-picker-overlay'}>
        {colors.map((color) => (
          <button
            type='button'
            key={color}
            {...s.with('colorButton')({
              background: color,
              border: `1px solid ${
                color === '#ffffff'
                  ? token('legacy.color.grey.default')
                  : 'none'
              }`
            })}
            onClick={(e) => {
              e.preventDefault();
              onChange(this.fakeEvent(color));
            }}
          />
        ))}
        <Box {...s('wrapInput')}>
          <SmallTextInput
            name='custom'
            value={this.state.value}
            onChange={this.handleCustomChange}
            onFocus={() => this.setState({ focused: true })}
            onBlur={() => this.setState({ focused: false })}
            onKeyDown={(e) => {
              if ((e.keyCode || e.which) === 13) {
                e.preventDefault();
                this._refs.button.click();
                return false;
              }
            }}
          />
        </Box>
      </Box>
    );
  }

  render() {
    const { styles: s, value, placeholder, placement } = this.props;

    // Make sure the picker icon has always a good color contrast!
    const textColor = getContrastColor(value);

    return (
      <Box alignItems='center' flexDirection='row' {...s('container')}>
        <TooltipStateful
          placement={placement}
          offset='0'
          openOn='CLICK'
          closeOn='CLICK'
          styles={this.tooltipStyles}
          Content={this.renderOverlay}
        >
          <button
            {...s.with('button')({
              background: value,
              color: textColor
            })}
            onClick={(e) => e.preventDefault()}
            ref={(e) => (this._refs.button = e)}
            title='Change color'
            type='button'
            data-testid='colorPicker.button'
          >
            <EyeDropper />
          </button>
        </TooltipStateful>
        <Box flexDirection='column' justifyContent='center' {...s('hexValue')}>
          <span
            {...s('value', { valueDark: !!value })}
            data-testid='colorPicker.value'
          >
            {value || placeholder || 'No color selected'}
          </span>
        </Box>
      </Box>
    );
  }
}

export default ColorPicker;

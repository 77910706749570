import { Box } from '@rexlabs/box';
import { styled, StyleSheet } from '@rexlabs/styling';
import React, { PureComponent } from 'react';
import { FONT, formattedBrandName, withToken } from 'src/theme';

const defaultStyles = StyleSheet({
  container: {
    fontWeight: FONT.WEIGHTS.SEMIBOLD,
    color: ({ token }) => token('legacy.color.blue.grey'),
    borderBottom: ({ token }) =>
      `.1rem solid ${token('legacy.color.blue.greyLight')}`
  },

  section: {},

  sectionHeader: {
    width: '100%',
    paddingBottom: ({ token }) => token('spacing.m'),
    textTransform: 'uppercase',
    '& > span': {
      fontWeight: FONT.WEIGHTS.SEMIBOLD,
      color: ({ token }) => token('legacy.color.blue.grey'),
      fontSize: '1.6rem'
    }
  },

  admin: {
    width: '12em'
  },
  facebook: {
    width: '28.8em'
  },
  google: {
    width: '28.8em'
  }
});

@withToken
@styled(defaultStyles)
class CampaignListHeader extends PureComponent {
  render() {
    const { token, styles: s } = this.props;
    const statWidth = '3.8em';
    const labelWidth = '6em';
    return (
      <Box {...s('container')} p={token('spacing.m')}>
        <Box
          pt={0}
          flexDirection='row'
          alignItems='center'
          justifyContent='space-between'
        >
          <Box {...s('sectionHeader', 'admin')}>
            <span>Admin</span>
          </Box>
          <Box {...s('sectionHeader')} flex={1}>
            <span>{formattedBrandName}</span>
          </Box>
          <Box {...s('sectionHeader', 'facebook')}>
            <span>Facebook</span>
          </Box>
          <Box {...s('sectionHeader', 'google')}>
            <span>Google</span>
          </Box>
          <Box style={{ width: '3rem' }} />
        </Box>
        <Box
          pt={0}
          flexDirection='row'
          alignItems='center'
          justifyContent='space-between'
        >
          <Box style={{ width: labelWidth }}>Status</Box>
          <Box style={{ width: labelWidth }}>Notes</Box>

          <Box flex={1}>Name</Box>
          <Box style={{ width: '10em' }}>Account</Box>
          <Box style={{ width: labelWidth }}>Start Date</Box>
          <Box style={{ width: labelWidth }}>Days Left</Box>
          <Box style={{ width: labelWidth }}>Status</Box>
          <Box style={{ width: labelWidth }}>Budget</Box>
          <Box style={{ width: labelWidth }}>$ Left</Box>

          <Box style={{ width: labelWidth }}>Status</Box>
          <Box style={{ width: statWidth }}>Ads</Box>
          <Box style={{ width: statWidth }}>Clicks</Box>
          <Box style={{ width: statWidth }}>Impr.</Box>
          <Box style={{ width: statWidth }}>CTR&apos;</Box>
          <Box style={{ width: statWidth }}>CPC</Box>
          <Box style={{ width: statWidth }}>Spent</Box>

          <Box style={{ width: labelWidth }}>Status</Box>
          <Box style={{ width: statWidth }}>Ads</Box>
          <Box style={{ width: statWidth }}>Clicks</Box>
          <Box style={{ width: statWidth }}>Impr.</Box>
          <Box style={{ width: statWidth }}>CTR&apos;</Box>
          <Box style={{ width: statWidth }}>CPC</Box>
          <Box style={{ width: statWidth }}>Spent</Box>
          <Box style={{ width: '3rem' }} />
        </Box>
      </Box>
    );
  }
}

export default CampaignListHeader;

import { Box } from '@rexlabs/box';
import { ToastTarget } from '@rexlabs/notifications';
import { Portal } from '@rexlabs/portal';
import { styled, StyleSheet } from '@rexlabs/styling';
import { Heading } from '@rexlabs/text';
import React, { PureComponent } from 'react';
import { BORDER_RADIUS, FONT, SHADOWS } from 'src/theme';
import uuid from 'src/utils/uuid';

const defaultStyles = StyleSheet({
  container: {
    maxHeight: '98vh',
    maxWidth: '98vw',
    overflow: 'hidden',
    background: ({ token }) => token('legacy.color.white'),
    borderRadius: BORDER_RADIUS.INPUT,
    boxShadow: SHADOWS.MODAL,
    zIndex: 10
  },

  header: {
    padding: ({ token }) => `${token('spacing.m')} ${token('spacing.xl')}`,
    borderBottom: ({ token }) =>
      `.1rem solid ${token('legacy.color.grey.light')}`,
    position: 'relative',
    flexShrink: 0
  },

  headerTitle: {
    fontSize: '2.6rem',
    fontWeight: FONT.WEIGHTS.NORMAL
  },

  headerSubtitle: {
    fontSize: '1.4rem',
    color: ({ token }) => token('legacy.color.blue.grey')
  },

  headerCloseButton: {
    fontSize: '3.5rem',
    fontWeight: FONT.WEIGHTS.LIGHT,
    color: ({ token }) => token('legacy.color.grey.default'),
    border: '0 none',
    background: 'transparent',
    cursor: 'pointer',

    ':hover': {
      color: ({ token }) => token('legacy.color.blue.dark')
    },
    alignSelf: 'flex-start'
  },

  content: {
    padding: ({ token }) => token('spacing.xl'),
    overflow: 'auto',
    flexShrink: 0,
    position: 'relative',

    '::-webkit-scrollbar': {
      width: '10px'
    },
    '::-webkit-scrollbar-thumb': {
      background: ({ token }) => token('legacy.color.grey.light'),
      borderRadius: '10px'
    }
  },

  contentNoPadding: {
    padding: 0
  },

  toastTarget: {
    position: 'absolute',
    top: ({ token }) => token('spacing.xs'),
    left: 0,
    right: 0,
    flexDirection: 'column',
    alignItems: 'center',
    display: 'flex',
    zIndex: 500
  }
});

@styled(defaultStyles)
class DefaultModal extends PureComponent {
  static defaultProps = {
    target: 'modals',
    width: '62rem'
  };

  constructor(props) {
    super(props);
    this.uuid = uuid();
  }

  render() {
    const {
      title,
      subtitle,
      children,
      Footer,
      target,
      noPadding,
      styles: s,
      onClose,
      width
    } = this.props;

    return (
      <Portal target={target}>
        <Box
          id={this.uuid}
          {...s('container')}
          flexDirection='column'
          style={{ width }}
        >
          <Box flexDirection='row' {...s('header')}>
            <Box flex='1' flexDirection='column'>
              {!!title && (
                <Heading level={1} {...s('headerTitle')}>
                  {title}
                </Heading>
              )}
              {!!subtitle && (
                <Heading level={2} {...s('headerSubtitle')}>
                  {subtitle}
                </Heading>
              )}
            </Box>
            <button onClick={onClose} {...s('headerCloseButton')}>
              &times;
            </button>
          </Box>
          <Box
            flex={1}
            flexDirection='column'
            {...s('content', { contentNoPadding: noPadding })}
          >
            <ToastTarget name='modal' position='top' />
            {children}
          </Box>
          {Footer}
        </Box>
      </Portal>
    );
  }
}

export default DefaultModal;

import { agentSchema } from 'src/features/agents';
import {
  Date,
  Describe,
  String,
  UUID,
  create,
  type
} from 'src/lib/superstruct';
import { Testimonial, TestimonialDTO } from '../types';
import { nonempty } from 'superstruct';

export const testimonialSchema: Describe<Testimonial> = type({
  id: UUID,
  author: String(),
  testimonial: String(),
  excerpt: String(),
  created_at: Date,
  updated_at: Date,
  agent: agentSchema
});

export const editableTestimonialSchema: Describe<TestimonialDTO> = type({
  author: String(),
  testimonial: String(),
  excerpt: String(),
  agent_id: nonempty(String())
});

export const transformTestimonialResponse = (value: unknown): Testimonial =>
  create(value, testimonialSchema);

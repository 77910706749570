import { query } from '@rexlabs/model-generator';
import campaignTemplatesModel from 'src/data/models/entities/campaign-templates';

export const campaignTemplatesQuery = query`{
  ${campaignTemplatesModel} {
    id
    name
    label
    slug
  }
}`;

import React, { ComponentType } from 'react';
import AgentSelection from 'src/features/agents/components/agent-selection';
import { AnyObject } from '@data-driven-forms/react-form-renderer';

export const AgentSelectorInput: ComponentType<AnyObject> = (props) => {
  return (
    <AgentSelection
      value={{ id: props.value }}
      onChange={(data) => props.onChange(data.target.value?.id ?? null)}
    />
  );
};

export const MultiAgentSelectorInput: ComponentType<AnyObject> = (props) => {
    return (
        <AgentSelection
            name={props.name}
            value={{ id: props.value }}
            onChange={(data) => props.onChange(data.target.value ?? null)}
            multi
            disableAddAgent
            disableDeleteAgent
            borderless
        />
    );
};

import { createRestAPIModelGenerator } from 'src/data/models/generator';
import { api } from 'src/utils/api-client';

export const TYPE = 'purchases';

const config = {
  entities: {
    api: {
      fetchList: (type, args) => api.get('/billing/purchases', args)
    }
  }
};

const PurchasesGenerator = createRestAPIModelGenerator(TYPE, config);
export default PurchasesGenerator.createEntityModel();

import React, { PureComponent } from 'react';
import { Portal } from '@rexlabs/portal';

class PortalDelayed extends PureComponent {
  state = {
    ready: false
  };

  componentDidMount() {
    this.setState({ ready: true });
  }

  render() {
    const { target, children } = this.props;
    const { ready } = this.state;
    return ready && <Portal target={target}>{children}</Portal>;
  }
}

export default PortalDelayed;

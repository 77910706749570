/* eslint-disable max-lines */
import { track } from '@rexlabs/analytics';
import { Box } from '@rexlabs/box';
import { GhostButton, LinkButton, PrimaryButton } from '@rexlabs/button';
import { Checkbox } from '@rexlabs/checkbox';
import { query, withModel, withQuery } from '@rexlabs/model-generator';
import { RadioGroupInput } from '@rexlabs/radio-input';
import { StyleSheet, margin, useStyles, useToken } from '@rexlabs/styling';
import { Small } from '@rexlabs/text';
import moment from 'moment';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { compose } from 'redux';
import ChevronLeft from 'src/assets/icons/chevron-left.svg';
import { FormControl, FormLabel } from 'src/components/form';
import sessionModel from 'src/data/models/custom/session';
import wizardModel from 'src/data/models/custom/wizard';
import adsModel from 'src/data/models/entities/ads';
import campaignsModel from 'src/data/models/entities/campaigns';
import { LeadPreferenceSummary } from 'src/features/lead-capture-emails';
import { StrategyCreditPoolPaymentMethod } from 'src/features/payments';
import { StrategySelectInput } from 'src/features/strategies';
import WIZARD from 'src/routes/wizard';
import { TEXTS, formattedBrandName } from 'src/theme';
import { Feature } from 'src/utils/feature-flags';
import { abbreviateNumber } from 'src/utils/format';
import calcAudienceHealth from 'src/utils/health-indicator';
import { push } from 'src/utils/whereabouts';
import { StickyButtonGroup } from 'src/view/components/button';
import { CampaignPreview } from 'src/view/components/campaign';
import { CampaignDurationSelect } from 'src/view/components/input';
import { LoadingSpinner, RenderLoading } from 'src/view/components/loading';
import Money from 'src/view/components/money';
import OverviewTable from 'src/view/components/overview-table';
import { PaymentOptions } from 'src/view/components/payment';
import ScrollableContent from 'src/view/components/scrollable-content';
import { Body, Heading, Label as InputLabel } from 'src/view/components/text';
import { TooltipStateful } from 'src/view/components/tooltip';
import withError from 'src/view/containers/with-error';
import { useCapability } from 'src/view/hooks';
import { stepNames } from 'src/view/screens/wizard/multi-listing-sold/index';
import { sharedStyles } from '.';

const styles = StyleSheet({
  wrapListingPreview: {
    marginTop: ({ token }) => token('spacing.xxxl'),
    marginBottom: ({ token }) => token('spacing.xl'),
    width: '28.6rem',
    paddingRight: ({ token }) => token('spacing.xxl'),
    borderRight: ({ token }) =>
      `.1rem solid ${token('legacy.color.grey.light')}`,
    boxSizing: 'content-box'
  },

  wrapOverview: {
    paddingLeft: ({ token }) => token('spacing.xxl')
  },

  editLink: {
    minWidth: 0,
    ...TEXTS.BODY
  },

  strategySelect: {
    ...margin.styles({
      top: 'l'
    })
  }
});

function getCampaignId(props) {
  return props?.match?.params?.campaignId;
}

const campaignQuery = query`{
  ${campaignsModel} (id: ${getCampaignId}) {
    id
    type {
      id
      label
    }
    name
    content_source
    selected_package {
      id
      price {
        value
        currency_code
      }
      price
      duration {
        value
        unit
      }
      name
      type {
        id
        label
      }
    }
    start_date
    audience_spec {
      id
      networks
      segments
      age_range {
        youngest
        oldest
      }
      locations
    }
    ad_content_sets {
      type
      attributes
      final_url
    }
    images
    landing_pages {
      id
      is_external
      external_url
      virtual_tour_url
    }
    limit_renewals
    renewals {
      total
      left
    }
    videos
    campaign_strategy
  }
}`;

const adsQuery = query`{
  ${adsModel} (campaignId: ${getCampaignId}) {
    id
    format {
      id
      label
    }
    network {
      id
      label
    }
    status {
      id
      label
    }
  }
}`;

function CreateConfirmScreen(props) {
  const {
    campaigns,
    ads,
    match,
    wizard: { flush, setStep, step, packageEstimates, slug },
    error,
    error: { Error },
    session
  } = props;
  const s = useStyles(styles, undefined, sharedStyles);
  const token = useToken();

  const [loading, setLoading] = useState(false);
  const [recurringType, setRecurringType] = useState('auto'); // auto | timed
  const [recurringDuration, setRecurringDuration] = useState(1);
  const [selectedCard, setSelectedCard] = useState(null);
  const canPublishCampaigns = useCapability('publish_campaigns');

  const campaignData = campaigns?.item?.data;

  const [strategyAttached, toggleStrategyAttached] = useState(false);
  const handleStrategyAttachedInput = (e) => {
    toggleStrategyAttached(e.target.checked);
  };
  useEffect(() => {
    toggleStrategyAttached(
      session?.user?.is_sys_admin && !!campaignData?.campaign_strategy
    );
  }, [campaignData?.campaign_strategy, session?.user?.is_sys_admin]);
  const [attachedStrategy, setAttachedStrategy] = useState();
  useEffect(() => {
    setAttachedStrategy(campaignData?.campaign_strategy);
  }, [campaignData?.campaign_strategy]);
  const canSubmitWithStrategy = useMemo(
    () => !strategyAttached || !!attachedStrategy,
    [attachedStrategy, strategyAttached]
  );

  const handleSubmit = useCallback(() => {
    const campaignData = campaigns?.item?.data;
    const recurring = campaignData?.selected_package?.type?.id === 'recurring';
    const recurringData = {
      limit_renewals: recurringType === 'timed',
      renewals: {
        total: recurringType === 'timed' ? recurringDuration - 1 : 0
      }
    };

    const getAudienceHealth = () => {
      const audience = campaigns?.item?.data?.audience_spec;
      const location = (audience?.locations || []).find(
        (l) => l?.type?.id === 'radius'
      );
      const suburbs = (audience?.locations || []).filter(
        (l) => l?.type?.id === 'suburb'
      );
      const geoRange = location?.radius?.value;
      const segments = audience?.segments;
      const ageRange = audience?.age_range || {};
      const { value, version } = calcAudienceHealth({
        geoRange,
        suburbs,
        segments,
        ageRange
      });
      return { value, version };
    };

    setLoading(true);
    campaigns
      .updateItem({
        data: {
          ...(recurring && recurringType === 'timed' ? recurringData : {}),
          audience_health_indicator: getAudienceHealth(),
          ...(strategyAttached
            ? {
                campaign_strategy: {
                  id: attachedStrategy?.id
                }
              }
            : {
                selected_payment_option: { id: selectedCard?.id }
              })
        }
      })
      .then(() =>
        campaigns.launch({
          id: match.params.campaignId,
          data: {
            type: { id: 'publish' }
          }
        })
      )
      .then(() => {
        const totalAds = (ads?.list?.items || []).filter(
          (a) => a?.status?.id !== 'disabled'
        ).length;
        const networks = campaignData?.audience_spec?.networks || [];
        const now = moment();
        const launchDate = moment(campaignData?.start_date);
        const launchDateFromCreation = launchDate.isSame(now, 'days')
          ? 0
          : launchDate.diff(now, 'days');
        const plan = campaignData?.selected_package;
        const campaignLabel = campaignData?.type?.label;
        const virtualTourUrl =
          campaignData?.landing_pages?.items?.[0]?.virtual_tour_url;

        track({
          event: `Spoke campaign ${campaignLabel} launched`,
          properties: {
            action: 'launch_campaign_clicked',
            campaign_type: campaignLabel,
            step_name: stepNames[step - 1].replace(/-/g, '_'),
            success: true,
            ads: totalAds,
            networks: networks.map((n) => n.id),
            launch_date: launchDate.format('MMMM Do YYYY'),
            launch_date_from_creation: launchDateFromCreation,
            value: plan?.price?.value,
            currency: plan?.price?.currency_code,
            budget_name: plan?.name,
            duration_days: plan?.duration?.value,
            payment_type: selectedCard?.type?.id,
            video_uploaded: !!campaignData?.videos?.length,
            virtual_tour_url: virtualTourUrl
              ? new URL(virtualTourUrl)?.hostname
              : ''
          }
        });

        push(WIZARD.SUCCESS);
        flush();
      })
      .catch((e) => {
        error.open(e);
        setLoading(false);
      });
  }, [
    ads?.list?.items,
    attachedStrategy?.id,
    campaigns,
    error,
    flush,
    match.params.campaignId,
    recurringDuration,
    recurringType,
    selectedCard?.id,
    selectedCard?.type?.id,
    step,
    strategyAttached
  ]);

  const renderEditLink = (toStep) => {
    return (
      <span {...s('edit')}>
        {' '}
        ∙{' '}
        <LinkButton
          onClick={(e) => {
            e.preventDefault();
            setStep({
              step: toStep
            });
          }}
          {...s('editLink')}
        >
          Edit
        </LinkButton>
      </span>
    );
  };

  const startsIn = moment(campaignData?.start_date).diff(
    moment().startOf('day'),
    'days'
  );

  const packageQuote = (packageEstimates?.quotes || []).find(
    (p) => p.id === campaignData?.selected_package?.id
  );

  const recurring = campaignData?.selected_package?.type?.id === 'recurring';
  const packagePrice = campaignData?.selected_package?.price;
  const type = campaignData?.type?.id;

  const images = campaignData?.images || [];
  const prospecting = (campaignData?.ad_content_sets?.items, []).find((set) =>
    (set?.type?.id || '').endsWith('prospecting')
  );
  const slide =
    (prospecting?.attributes?.slides || []).find(
      (s) => s.type.toLowerCase() !== 'listing'
    ) || {};
  const primaryImageId =
    type === 'multi_listing_sold'
      ? slide?.image?.id
      : prospecting?.attributes?.primary_image?.id ||
        prospecting?.attributes?.agent_image?.id;
  const primaryImageObj = images.find((i) => i.id === primaryImageId);
  const thumbnailUrl =
    type === 'multi_listing_sold'
      ? primaryImageObj?.sizes?.[
          slide.type === 'agency' ? 'original.thumbnail' : 'crop.square'
        ]?.url
      : primaryImageObj?.crops?.square?.blob;
  const bgColor = prospecting?.attributes?.background_color;
  const externalLandingPage =
    campaigns?.item?.data?.landing_pages?.items?.[0]?.is_external;
  const externalLandingPageUrl =
    campaigns?.item?.data?.landing_pages?.items?.[0]?.external_url;

  const totalAds = (ads?.list?.items || []).filter(
    (a) => a?.status?.id !== 'disabled'
  ).length;

  if (campaigns.status === 'loading') return <LoadingSpinner />;

  return (
    <Box flex={1} flexDirection='column'>
      <ScrollableContent {...s('content')}>
        <Heading>Your campaign is ready to launch</Heading>
        <Body grey>Please confirm your details and make it live.</Body>

        <RenderLoading isLoading={campaigns.item.status === 'loading'}>
          <Box style={{ width: '100%' }}>
            <Box flexDirection='row'>
              <Box {...s('wrapListingPreview')}>
                <CampaignPreview
                  type={type}
                  src={thumbnailUrl}
                  title={campaignData?.name}
                  campaignId={campaignData?.id}
                  bgColor={bgColor}
                  imageContain={slide?.type === 'agency'}
                />
              </Box>

              <Box flex={1} pt={token('spacing.xl')} {...s('wrapOverview')}>
                <OverviewTable>
                  {({ Container, Row, Label, Cell }) => (
                    <Container>
                      <div data-intercom-target='campaignSummary'>
                        <Row>
                          <Label>Launch Date</Label>
                          <Cell>
                            <Body Tag='span' data-test-id='launch-date'>
                              {moment(campaignData?.start_date).format(
                                'DD/MM/YYYY'
                              )}
                              {renderEditLink(step - 1)}
                            </Body>
                            <Body
                              grey
                              Tag='span'
                              data-test-id='launch-date-relative'
                            >
                              {startsIn === 0
                                ? 'Today'
                                : startsIn === 1
                                ? 'Tomorrow'
                                : `In ${startsIn} days`}
                              {campaignData?.selected_package?.type?.id !==
                                'recurring' && (
                                <span>
                                  , End date:{' '}
                                  {moment(campaignData?.start_date)
                                    .add(
                                      campaignData?.selected_package?.duration
                                        ?.value,
                                      'days'
                                    )
                                    .format('DD/MM/YYYY')}
                                </span>
                              )}
                            </Body>
                          </Cell>
                        </Row>

                        {recurring && (
                          <>
                            <Row>
                              <Label>Duration</Label>
                              <Cell>
                                <RadioGroupInput
                                  name='duration'
                                  value={recurringType}
                                  options={[
                                    {
                                      label: 'Auto-renewing campaign',
                                      props: {
                                        description: (
                                          <>
                                            <Body grey>
                                              Your campaign will auto-renew each
                                              week until you end this campaign.
                                              <br />
                                              Next renewal date:{' '}
                                              <span>
                                                {moment(
                                                  campaignData?.start_date
                                                )
                                                  .add(7, 'days')
                                                  .format('DD/MM/YYYY')}
                                              </span>
                                            </Body>
                                          </>
                                        )
                                      },
                                      value: 'auto'
                                    },
                                    {
                                      label: 'Fixed duration campaign',
                                      props: {
                                        description:
                                          recurringType === 'timed' ? (
                                            <Body grey>
                                              Your campaign will run for{' '}
                                              {recurringDuration} week
                                              {recurringDuration > 1
                                                ? 's'
                                                : ''}{' '}
                                              with a total cost of{' '}
                                              <Money
                                                amount={
                                                  packagePrice.value *
                                                  recurringDuration
                                                }
                                                currencyCode={
                                                  packagePrice.currency_code
                                                }
                                              />
                                              {recurringDuration > 1 ? (
                                                <>
                                                  , billed in weekly increments
                                                  of{' '}
                                                  <Money
                                                    amount={packagePrice.value}
                                                    currencyCode={
                                                      packagePrice.currency_code
                                                    }
                                                  />
                                                  .
                                                </>
                                              ) : (
                                                '.'
                                              )}
                                              <br />
                                              Last day of campaign:{' '}
                                              <span>
                                                {moment(
                                                  campaignData?.start_date
                                                )
                                                  .add(
                                                    recurringDuration,
                                                    'weeks'
                                                  )
                                                  .format('DD/MM/YYYY')}
                                              </span>
                                            </Body>
                                          ) : (
                                            <Body grey>
                                              Your campaign will only run for a
                                              chosen period of time.
                                            </Body>
                                          )
                                      },
                                      value: 'timed'
                                    }
                                  ]}
                                  onChange={(e) =>
                                    setRecurringType(e.target.value)
                                  }
                                />

                                {recurringType === 'timed' && (
                                  <Box
                                    flexDirection='column'
                                    style={{ marginLeft: '3.1rem' }}
                                  >
                                    <InputLabel>Campaign Duration</InputLabel>
                                    <CampaignDurationSelect
                                      onChange={(e) =>
                                        setRecurringDuration(e.target.value)
                                      }
                                      value={recurringDuration}
                                    />
                                  </Box>
                                )}
                              </Cell>
                            </Row>
                          </>
                        )}

                        <Row>
                          <Label>Package</Label>
                          <Cell>
                            <Body Tag='span'>
                              <Money
                                amount={packagePrice?.value}
                                currencyCode={packagePrice?.currency_code}
                                integer
                              />
                              {campaignData?.selected_package?.type?.id ===
                              'recurring'
                                ? ' / week '
                                : ' '}
                              - {campaignData?.selected_package?.name}
                              {renderEditLink(step - 1)}
                            </Body>
                            <Body grey Tag='span'>
                              {recurring ? (
                                'Weekly renewal, '
                              ) : (
                                <span>
                                  Duration:{' '}
                                  {
                                    campaignData?.selected_package?.duration
                                      ?.value
                                  }{' '}
                                  days,{' '}
                                </span>
                              )}
                              Estimated {recurring ? 'weekly ' : ''}ad views:{' '}
                              {abbreviateNumber(packageQuote?.reach?.min)} -{' '}
                              {abbreviateNumber(packageQuote?.reach?.max)}
                            </Body>
                          </Cell>
                        </Row>

                        <Row>
                          <Label>Audience</Label>
                          <Cell>
                            <Body Tag='span'>
                              Potential{' '}
                              {slug === 'rental-listing'
                                ? 'tenants'
                                : campaigns?.item?.data?.type?.id ===
                                  'single_listing'
                                ? 'buyers'
                                : 'sellers'}
                              {renderEditLink(step - 4)}
                            </Body>
                            <Body grey Tag='span'>
                              {(campaignData?.audience_spec?.segments || [])
                                .map((s) => s.label)
                                .join(', ')}
                            </Body>
                          </Cell>
                        </Row>

                        <Row>
                          <Label>Ads</Label>
                          <Cell>
                            {ads?.list?.status === 'loading' ? (
                              <Box flexDirection='row' alignItems='center'>
                                <Box mr={token('spacing.xs')}>
                                  <LoadingSpinner
                                    size={12}
                                    strokeWidth={3}
                                    colors={[
                                      token('legacy.color.grey.default')
                                    ]}
                                  />
                                </Box>
                                <Body grey>Loading ads...</Body>
                              </Box>
                            ) : (
                              <Body Tag='span'>
                                {totalAds}{' '}
                                {(ads?.list?.items || []).length === 1
                                  ? 'ad'
                                  : 'ads'}
                                {renderEditLink(step - 3)}
                              </Body>
                            )}
                            <Body grey Tag='span'>
                              {(campaignData?.audience_spec?.networks || [])
                                .map((s) =>
                                  s.id === 'adwords'
                                    ? 'Google formats'
                                    : s.label
                                )
                                .join(', ')}
                            </Body>
                          </Cell>
                        </Row>

                        {type === 'single_listing' && (
                          <Row>
                            <Label>Landing page</Label>
                            <Cell>
                              <Body Tag='span'>
                                {externalLandingPage
                                  ? 'External'
                                  : formattedBrandName}{' '}
                                landing page
                                {renderEditLink(step - 2)}
                              </Body>
                              <Body grey Tag='span'>
                                {externalLandingPage
                                  ? externalLandingPageUrl
                                  : 'Get qualified leads'}
                              </Body>
                            </Cell>
                          </Row>
                        )}
                      </div>
                      <Feature name='rex_integration_lead_preference'>
                        <div>
                          <Row>
                            <Label>Leads</Label>
                            <Cell>
                              <LeadPreferenceSummary
                                campaignId={campaignData.id}
                              />
                            </Cell>
                          </Row>
                        </div>
                      </Feature>
                      <Feature name='campaign_strategies'>
                        {session?.user?.is_sys_admin ? (
                          <Row>
                            <Label>Campaign Strategy</Label>
                            <Cell>
                              <Checkbox
                                label='Attach to a strategy'
                                value={strategyAttached}
                                onChange={handleStrategyAttachedInput}
                              />
                              {strategyAttached ? (
                                <>
                                  {campaignData?.campaign_strategy ? (
                                    <Small as='span' grey>
                                      This campaign is part of an existing
                                      campaign strategy
                                    </Small>
                                  ) : null}
                                  <FormControl {...s('strategySelect')}>
                                    <FormLabel>Attach to strategy</FormLabel>
                                    <StrategySelectInput
                                      initialValue={attachedStrategy}
                                      onChange={setAttachedStrategy}
                                    />
                                  </FormControl>
                                </>
                              ) : null}
                            </Cell>
                          </Row>
                        ) : undefined}
                      </Feature>
                      <div data-intercom-target='addPayment'>
                        <Row>
                          <Label>Payment</Label>
                          <Cell>
                            {strategyAttached ? (
                              <StrategyCreditPoolPaymentMethod />
                            ) : (
                              <PaymentOptions
                                campaignName={campaignData?.name}
                                selectedCard={selectedCard}
                                onChange={(selectedCard) => {
                                  if (selectedCard) {
                                    setSelectedCard(selectedCard);
                                  }
                                }}
                              />
                            )}
                          </Cell>
                        </Row>
                      </div>
                    </Container>
                  )}
                </OverviewTable>
              </Box>
            </Box>
          </Box>
        </RenderLoading>
      </ScrollableContent>

      <StickyButtonGroup>
        <Box flex={1}>
          <GhostButton
            IconLeft={ChevronLeft}
            onClick={() => setStep({ step: step - 1 })}
          >
            Back
          </GhostButton>
        </Box>
        <TooltipStateful
          show={!canPublishCampaigns || !selectedCard}
          Content={() => (
            <Box style={{ width: '20rem' }}>
              {!canPublishCampaigns ? (
                <p>
                  Your account doesn&apos;t have permission to perform this
                  action
                </p>
              ) : (
                <p>
                  You have to select a valid payment method before you can
                  launch the campaign
                </p>
              )}
            </Box>
          )}
        >
          <PrimaryButton
            // red
            isLoading={loading}
            onClick={canPublishCampaigns && selectedCard && handleSubmit}
            isDisabled={
              !canPublishCampaigns || !selectedCard || !canSubmitWithStrategy
            }
          >
            Launch campaign
          </PrimaryButton>
        </TooltipStateful>
      </StickyButtonGroup>

      <Error />
    </Box>
  );
}

export default compose(
  withError(),
  withQuery(campaignQuery),
  withQuery(adsQuery),
  withModel(wizardModel),
  withModel(sessionModel)
)(CreateConfirmScreen);

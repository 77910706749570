import { Box } from '@rexlabs/box';
import { styled, StyleSheet } from '@rexlabs/styling';
import React, { PureComponent } from 'react';
import { FONT } from 'src/theme';
import { BubbleSpinner } from 'src/view/components/loading';

const defaultStyles = StyleSheet({
  container: {
    position: 'absolute',
    width: '100%',
    height: '100%',
    top: 0,
    left: 0
  },

  text: {
    color: ({ token }) => token('palette.brand.600'),
    fontWeight: FONT.WEIGHTS.BOLD,
    fontSize: '1.6rem',
    padding: '2rem'
  }
});

@styled(defaultStyles)
class LoadingLayout extends PureComponent {
  render() {
    const { styles: s } = this.props;
    return (
      <Box
        {...s('container')}
        flexDirection='column'
        alignItems='center'
        justifyContent='center'
      >
        <BubbleSpinner />
        <span {...s('text')}>Getting started...</span>
      </Box>
    );
  }
}

export default LoadingLayout;

import { useQuery } from 'react-query';
import { useAccountId } from 'src/features/accounts';
import { QueryConfig, QueryResult } from 'src/lib/react-query';
import { api } from 'src/utils/api-client';
import { ResourceDownload } from '../types';
import { createResourceDownload } from '../utils/schemas';
import { resourceDownloadKeys } from './keys';

export type GetResourceDownloadParams = {
  accountId: string;
  id: string;
};

export const getResourceDownload = async ({
  id
}: GetResourceDownloadParams): Promise<ResourceDownload> => {
  const { data } = await api.get(`/resource-downloads/${id}`);
  return createResourceDownload(data);
};

type QueryFnType = typeof getResourceDownload;

export type UseResourceDownloadOptions = Omit<
  GetResourceDownloadParams,
  'accountId'
> & {
  config?: QueryConfig<QueryFnType>;
};

export const useResourceDownload = ({
  config,
  ...params
}: UseResourceDownloadOptions): QueryResult<ResourceDownload> => {
  const accountId = useAccountId();
  return useQuery<ResourceDownload, Error>({
    queryKey: resourceDownloadKeys.detail({ accountId, ...params }),
    queryFn: () => getResourceDownload({ accountId, ...params }),
    ...config
  });
};

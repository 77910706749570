import { createRestAPIModelGenerator } from 'src/data/models/generator';
import { api } from 'src/utils/api-client';

export const TYPE = 'campaign-goals';

type RemoteKeys =
  | 'id'
  | 'label'
  | 'is_advanced_strategy'
  | 'networks'
  | 'min_daily_spend'
  | 'is_available';

export interface CampaignGoalData {
  id: string;
  label: string;
  is_advanced_strategy: boolean;
  newStrategy: boolean;
  networks: string[];
  adTypes: Record<string, number>;
  minSpend: number;
  min_daily_spend: number;
  is_available: boolean;
}

const ADDITIONAL_DATA: Record<string, Omit<CampaignGoalData, RemoteKeys>> = {
  maximise_leads: {
    newStrategy: true,
    minSpend: 200,
    adTypes: {
      facebook_lead_ads: 1.0
    }
  },
  maximise_exposure: {
    newStrategy: false,
    minSpend: 150,
    adTypes: {}
  },
  leads_and_exposure: {
    newStrategy: true,
    minSpend: 300,
    adTypes: {
      facebook_lead_ads: 0.5,
      facebook_ads: 0.25,
      google: 0.25
    }
  },
  advanced_maximise_exposure: {
    newStrategy: false,
    minSpend: 200,
    adTypes: {
      facebook_ads: 0.45,
      google: 0.55
    }
  }
};

const config = {
  valueLists: {
    api: {
      fetch: async () => {
        const res = await api.get('/lists/campaign-goals');
        res.data = res.data.items.map((goal) => ({
          ...goal,
          ...ADDITIONAL_DATA[goal.id]
        }));
        return res;
      }
    }
  }
};

const CampaignGoalsGenerator = createRestAPIModelGenerator(TYPE, config);
export default CampaignGoalsGenerator.createValueListModel(undefined);

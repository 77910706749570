import { createRestAPIModelGenerator } from 'src/data/models/generator';
export const TYPE = 'networks';

export interface Network {
  id: string;
  label: string;
  sublabel: string | null;
  tooltip: string;
  enabled: boolean;
  locked: boolean;
}

const NetworksGenerator = createRestAPIModelGenerator(TYPE);
export default NetworksGenerator.createValueListModel(undefined);

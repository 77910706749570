import { Box } from '@rexlabs/box';
import { GhostButton } from '@rexlabs/button';
import { styled, StyleSheet } from '@rexlabs/styling';
import React, { Component } from 'react';
import { FONT, withToken } from 'src/theme';
import { FieldArray, FormField } from 'src/view/components/form';
import SuburbSelect from 'src/view/components/input/select/suburb';
import { Label, Tiny } from 'src/view/components/text';

import PlusIcon from 'src/assets/icons/plus.svg';

const defaultStyles = StyleSheet({
  wrapRepeaterFields: {
    paddingBottom: ({ token }) => token('spacing.xs')
  },

  wrapRepeaterField: {
    position: 'relative',
    width: '100%'
  },

  removeRepeaterFieldButton: {
    height: '3rem',
    width: '1.5rem',
    marginLeft: '1.5rem',
    background: 'transparent',
    border: '0 none',
    fontSize: '2.4rem',
    transition: 'color .2s',
    color: ({ token }) => token('legacy.color.blue.grey'),
    cursor: 'pointer',
    fontWeight: FONT.WEIGHTS.NORMAL,

    ':hover': {
      color: ({ token }) => token('legacy.color.blue.dark'),
      textDecoration: 'none'
    }
  }
});

// NOTE: this component extends `Component`, not `PureComponent`, on purpose,
// since it needs to re-render whenever the form re-renders to retreive
// the new values ... this is not ideal!

@withToken
@styled(defaultStyles)
class RepeatSuburbSelect extends Component {
  static defaultProps = {
    min: 0
  };

  render() {
    const {
      min,
      name,
      label,
      subLabel,
      buttonLabel,
      styles: s,
      token,
      placeholder
    } = this.props;

    return (
      <FieldArray
        name={name}
        Input={SuburbSelect}
        inputProps={{ placeholder }}
        sendImmediate
      >
        {({ fields, push, remove, getFieldProps }) => {
          if (!fields.length && !!min) fields.push({});

          return (
            <div {...s('wrapRepeaterFields')}>
              <Box mb={token('spacing.xxs')}>
                <Label style={{ marginBottom: 0 }}>{label}</Label>
                {!!subLabel && <Tiny grey>{subLabel}</Tiny>}
              </Box>
              {fields.map((field, i) => {
                const fieldProps = getFieldProps(field, i);
                return (
                  <Box
                    key={fieldProps.key}
                    {...s('wrapRepeaterField')}
                    alignItems='center'
                  >
                    <FormField {...fieldProps} label={false} />
                    {fields.length > min && (
                      <a
                        onClick={(e) => {
                          e.preventDefault();

                          remove(i);
                        }}
                        {...s('removeRepeaterFieldButton')}
                      >
                        &times;
                      </a>
                    )}
                  </Box>
                );
              })}

              <Box mt={token('spacing.xs')}>
                <GhostButton IconLeft={PlusIcon} onClick={() => push('')}>
                  {buttonLabel}
                </GhostButton>
              </Box>
            </div>
          );
        }}
      </FieldArray>
    );
  }
}

export default RepeatSuburbSelect;

import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';

export default class Pin extends PureComponent {
  static propTypes = {
    backgroundColor: PropTypes.string
  };

  static defaultProps = {
    backgroundColor: '#000000'
  };

  render() {
    const { backgroundColor, ...rest } = this.props;
    return (
      <svg {...rest} xmlns='http://www.w3.org/2000/svg' viewBox='0 0 14 20'>
        <path
          fill={backgroundColor}
          fillRule='evenodd'
          d='M6.9951923 9.471154c.4487202 0 .8653827-.112179 1.25-.336539.3846173-.22436.6891014-.524837.9134615-.901442.2243601-.376604.3365385-.78926.3365385-1.237981 0-.44872-.1121784-.865382-.3365385-1.25-.2243601-.384617-.5288442-.689101-.9134615-.913461-.3846173-.22436-.8012798-.336539-1.25-.336539s-.8613763.112179-1.2379808.336539c-.3766044.22436-.6770822.528844-.9014423.913461-.2243601.384618-.3365384.80128-.3365384 1.25 0 .689106.2403822 1.274036.7211538 1.754808.4807716.480772 1.0657017.721154 1.7548077.721154zm0-9.471154C8.27725 0 9.4551228.312497 10.5288462.9375c1.0576975.625003 1.8990353 1.466341 2.5240384 2.524038.6250031 1.073724.9375 2.251597.9375 3.533654 0 .977569-.2403822 2.099353-.7211538 3.365385-.4166688 1.073723-.9935861 2.235571-1.7307693 3.485577-.6089774.993595-1.3060858 2.027238-2.0913461 3.100961-.5448745.753209-1.1217918 1.490381-1.7307692 2.211539L6.9951923 20l-.7211538-.841346c-.6089774-.721158-1.1858948-1.45833-1.7307693-2.211539-.7852603-1.073723-1.4823687-2.107366-2.0913461-3.100961-.7371832-1.250006-1.3141005-2.411854-1.7307693-3.485577C.2403822 9.094545 0 7.972761 0 6.995192c0-1.282057.3124969-2.45993.9375-3.533654C1.5625031 2.403841 2.4038409 1.562503 3.4615385.9375 4.5352618.312497 5.7131346 0 6.9951923 0z'
        />
      </svg>
    );
  }
}

import {
  audienceSpecDefinitionSchema,
  networkSchema
} from 'src/features/audiences';
import {
  Date,
  Describe,
  Optional,
  array,
  create,
  integer,
  min,
  nonempty,
  number,
  object,
  string,
  type,
  unknown
} from 'src/lib/superstruct';
import { AdTemplate, AdType, CampaignDefinition } from '../types';

export const contentTypeSpecSchema = object({
  min: min(integer(), 1),
  max: min(integer(), 1),
  type: string()
});

export const adTemplateSchema: Describe<AdTemplate> = object({
  template: string(),
  height: integer(),
  width: integer(),
  type: Optional(string())
});

export const adTypeSchema: Describe<AdType> = object({
  format_id: string(),
  templates: array(adTemplateSchema)
});

export const campaignDefinitionSchema: Describe<CampaignDefinition> = type({
  id: string(),
  name: string(),
  description: string(),
  tooltip: Optional(string()),
  label: string(),
  funnel: string(),
  min_daily_spend: integer(),
  goals: unknown(),
  lead_forms: unknown(),
  display_order: number(),
  content: nonempty(array(contentTypeSpecSchema)),
  audience_spec_definition: audienceSpecDefinitionSchema,
  ad_types: array(adTypeSchema),
  networks: array(networkSchema),
  created_at: Date,
  updated_at: Date
});

export const createCampaignDefinition = (value: unknown): CampaignDefinition =>
  create(value, campaignDefinitionSchema);

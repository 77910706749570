import { Box } from '@rexlabs/box';
import { ButtonGroup, PrimaryButton, SecondaryButton } from '@rexlabs/button';
import { StyleSheet, useStyles, useToken } from '@rexlabs/styling';
import React from 'react';
import ROUTES from 'src/routes/admin';
import WIZARD from 'src/routes/wizard';
import { Link } from 'src/utils/whereabouts';
import { Body, Heading } from 'src/view/components/text';

const defaultStyles = StyleSheet({
  container: {
    textAlign: 'center',
    display: 'flex'
  },

  inner: {
    width: '100%',
    maxWidth: '80rem'
  }
});

export function NotFoundScreenCore({ children }) {
  const s = useStyles(defaultStyles);
  const token = useToken();

  return (
    <Box {...s('container')} flexDirection='column' alignItems='center'>
      <Box {...s('inner')}>
        <Heading>Oops, something went wrong</Heading>
        <Box mt={token('spacing.xxxl')}>
          <Body grey>
            We couldn’t find the page you were looking for. This is awkward.
            Maybe the page got moved to a new URL or you misspelled something?!
          </Body>

          {children}
        </Box>
      </Box>
    </Box>
  );
}

function NotFoundScreen() {
  const token = useToken();

  return (
    <NotFoundScreenCore>
      <Body grey>
        Try to get to safe waters throug one of the following links:
      </Body>

      <Box flex={1} alignItems='center' justifyContent='center'>
        <ButtonGroup mt={token('spacing.xxxl')}>
          <Link to={ROUTES.CAMPAIGNS}>
            {({ onClick }) => (
              <PrimaryButton onClick={onClick}>Campaign manager</PrimaryButton>
            )}
          </Link>
          <Link to={WIZARD.START}>
            {({ onClick }) => (
              <SecondaryButton onClick={onClick}>
                Create new campaign
              </SecondaryButton>
            )}
          </Link>
        </ButtonGroup>
      </Box>
    </NotFoundScreenCore>
  );
}

export default NotFoundScreen;

import { styled, StyleSheet } from '@rexlabs/styling';
import { TooltipStateful } from '@rexlabs/tooltip';
import React from 'react';
import { FONT } from 'src/theme';

import InfoIconSvg from 'src/assets/icons/info.svg';
import QuestionIcon from 'src/assets/icons/question.svg';

const defaultStyles = StyleSheet({
  container: {
    display: 'inline-block',
    marginLeft: ({ token }) => token('spacing.xxs'),

    '@media print': {
      display: 'none'
    }
  },

  icon: {
    width: '1.5rem',
    height: '1.5rem',
    display: 'inline-flex',
    color: ({ token }) => token('legacy.color.blue.grey'),
    transition: 'color .2s',
    transform: 'translateY(.2rem)',

    ':hover': {
      color: ({ token }) => token('legacy.color.blue.dark')
    }
  }
});

const tooltipStyles = StyleSheet({
  tooltip: {
    fontWeight: FONT.WEIGHTS.LIGHT,
    // fontSize: '1.4rem',
    lineHeight: '1.8rem',
    color: ({ token }) => token('legacy.color.blue.grey')
  },

  clickable: {
    paddingTop: '.2rem'
  }
});

const HelpIcon = ({
  Content,
  questionIcon,
  styles: s,
  className,
  style,
  ...rest
}) => {
  return (
    <span {...s.with('container')({ className, style })}>
      <TooltipStateful
        styles={tooltipStyles}
        Content={Content}
        placement='bottom'
        {...rest}
      >
        {questionIcon ? (
          <QuestionIcon {...s('icon')} onClick={(e) => e.preventDefault()} />
        ) : (
          <InfoIconSvg {...s('icon')} onClick={(e) => e.preventDefault()} />
        )}
      </TooltipStateful>
    </span>
  );
};

export default styled(defaultStyles)(HelpIcon);

import { useMutation, UseMutationResult, useQueryClient } from 'react-query';
import { useAccountId } from 'src/features/accounts';
import { Campaign, campaignKeys } from 'src/features/campaigns';
import { api } from 'src/utils/api-client';
import { ContentSource } from '../../campaign-definitions/types';

export type CreateCampaignFromDefinitionParams = {
  accountId: string;
  campaignDefinitionId: string;
  contentSources: ContentSource[];
};

export const createCampaignFromDefinition = async ({
  accountId,
  campaignDefinitionId,
  contentSources
}: CreateCampaignFromDefinitionParams): Promise<Campaign> => {
  const { data } = await api.post(
    `/campaign-from-definition/${campaignDefinitionId}`,
    {
      content_source: {
        entities: contentSources
      }
    },
    {
      headers: {
        'X-Account-Id': accountId
      }
    }
  );
  return data;
};

export type UseCreateCampaignFromDefinitionVariables = Omit<
  CreateCampaignFromDefinitionParams,
  'accountId'
>;

export const useCreateCampaignFromDefinition = (): UseMutationResult<
  Campaign,
  Error,
  UseCreateCampaignFromDefinitionVariables
> => {
  const accountId = useAccountId();
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: (params) =>
      createCampaignFromDefinition({ ...params, accountId }),
    onSuccess: (data) => {
      queryClient.setQueryData(campaignKeys.detail(accountId, data.id), data);
    }
  });
};

import React, {
  ForwardedRef,
  forwardRef,
  ReactElement,
  RefAttributes
} from 'react';
import { FileUploadInput, FileUploadInputProps } from '../FileUploadInput';
import { PreviewEmptyInput } from './PreviewEmptyInput';
import { PreviewFileDisplay } from './PreviewFileDisplay';

export type PreviewFileUploadInputProps<T> = Omit<
  FileUploadInputProps<T>,
  'FileDisplay' | 'EmptyInput'
>;

export const PreviewFileUploadInput = forwardRef(
  <T,>(
    props: PreviewFileUploadInputProps<T>,
    ref: ForwardedRef<HTMLInputElement>
  ) => {
    return (
      // @ts-expect-error Typescript doesn't correctly infer union of multiple prop
      <FileUploadInput
        ref={ref}
        EmptyInput={PreviewEmptyInput}
        FileDisplay={PreviewFileDisplay}
        {...props}
      />
    );
  }
) as <T>(
  props: PreviewFileUploadInputProps<T> & RefAttributes<HTMLInputElement>
) => ReactElement | null;

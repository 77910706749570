/*
|-------------------------------------------------------------------------------
| App Config Loader
|-------------------------------------------------------------------------------
|
| Exposes app environment variables, resolving dev variables when available.
|
| ```js
| import config from 'src/config'
| config.API_URL
| ```
|
*/

// eslint-disable-next-line @typescript-eslint/no-var-requires
import * as userConfig from '../env';

const isDev = process.env.NODE_ENV !== 'production';
// eslint-disable-next-line no-console
console.log(`Config loading in ${isDev ? 'development' : 'production'} mode.`);

const devUserConfig = isDev ? userConfig.dev : {};
const flattenedUserConfig = Object.assign({}, userConfig, devUserConfig);
delete flattenedUserConfig.dev;

export default flattenedUserConfig;

/* eslint-disable @typescript-eslint/no-var-requires */
export default {
  LOGIN: {
    config: {
      path: '/login',
      Component: require('view/screens/auth/login').default
    }
  },

  RESET_PASSWORD: {
    config: {
      path: '/reset-password/:token',
      Component: require('view/screens/auth/reset-password').default
    }
  },

  INVITATION: {
    config: {
      path: '/invitation/:invitationId',
      Component: require('view/screens/auth/invitation').default
    }
  }

  // REGISTER: {
  //   config: {
  //     path: '/register',
  //     Component: require('view/screens/auth/register').default
  //   }
  // }
};

import { Box } from '@rexlabs/box';
import CSSAnimationGroup from '@rexlabs/css-animation-group';
import { PortalTarget } from '@rexlabs/portal';
import { styled, StyleSheet } from '@rexlabs/styling';
import React, { PureComponent } from 'react';
import { EASINGS, KEYFRAMES } from 'src/theme';

const defaultStyles = StyleSheet({
  container: {
    position: 'fixed',
    top: 0,
    left: 0,
    bottom: 0,
    right: 0,
    zIndex: 10000,
    pointerEvents: 'all'
  },

  containerDisabled: {
    pointerEvents: 'none'
  },

  modalsBackdrop: {
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    background: ({ token }) => token('legacy.color.blue.dark'),
    opacity: 0.2
  },

  modalsContainer: {
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    display: 'flex'
  }
});

@styled(defaultStyles)
class ModalTarget extends PureComponent {
  render() {
    const { styles: s } = this.props;
    return (
      <PortalTarget name='modals'>
        {({ children }) => (
          <Box
            {...s('container', { containerDisabled: children.length === 0 })}
          >
            <CSSAnimationGroup
              enterAnimation={KEYFRAMES.BACKDROP_FADE_IN}
              enterDuration='200ms'
              leaveAnimation={KEYFRAMES.BACKDROP_FADE_OUT}
              leaveDuration='150ms'
            >
              {children.length > 0 ? (
                <Box key={children?.[0]?.props?.id} {...s('modalsBackdrop')}>
                  Backdrop
                </Box>
              ) : null}
            </CSSAnimationGroup>
            <Box
              {...s('modalsContainer')}
              flexDirection='column'
              alignItems='center'
              justifyContent='center'
            >
              <CSSAnimationGroup
                enterAnimation={KEYFRAMES.MODAL_FADE_IN}
                enterDuration='200ms'
                enterTimingFunction={EASINGS.MODAL_FADE_IN}
                leaveAnimation={KEYFRAMES.MODAL_FADE_OUT}
                leaveDuration='150ms'
                leaveTimingFunction={EASINGS.MODAL_FADE_OUT}
                style={{ width: '100%', height: '100%' }}
              >
                {React.Children.map(children, (c) => {
                  /**
                   * NOTE: a key is passed through from Modal due to an issue where
                   * multiple modals on a page weren't being removed
                   */
                  return (
                    <Box
                      key={c?.props?.id}
                      {...s('modalsContainer')}
                      flexDirection='column'
                      alignItems='center'
                      justifyContent='center'
                    >
                      {c}
                    </Box>
                  );
                })}
              </CSSAnimationGroup>
            </Box>
          </Box>
        )}
      </PortalTarget>
    );
  }
}

export default ModalTarget;

import React, { PureComponent } from 'react';
import { styled, StyleSheet } from '@rexlabs/styling';
import { TEXTS } from 'src/theme';

const defaultStyles = StyleSheet({
  small: TEXTS.SMALL,
  grey: {
    color: ({ token }) => token('legacy.color.blue.grey')
  },
  green: {
    color: ({ token }) => token('legacy.color.spoke')
  }
});

@styled(defaultStyles)
class Small extends PureComponent {
  static defaultProps = {
    Tag: 'p'
  };

  render() {
    const { Tag, styles: s, children, grey, green, ...rest } = this.props;
    return (
      <Tag {...s.with('small', { grey, green })(this.props)} {...rest}>
        {children}
      </Tag>
    );
  }
}

export default Small;

import { UseMutationResult, useMutation, useQueryClient } from 'react-query';
import { useAccountId } from 'src/features/accounts';
import { Campaign, campaignKeys } from 'src/features/campaigns';
import { api } from 'utils/api-client';
import { createCampaign } from '../utils/schemas';

export type UpdateCampaignParams = {
  accountId: string;
  campaignId: string;
  data: Partial<Campaign>;
};

export const updateCampaign = async ({
  accountId,
  campaignId,
  data: newData
}: UpdateCampaignParams): Promise<Campaign> => {
  const { data } = await api.patch(`/campaigns/${campaignId}`, newData, {
    params: {
      include: 'selected_payment_option,campaign_definition,form_definitions'
    },
    headers: {
      'X-Account-Id': accountId
    }
  });
  return createCampaign(data);
};

export type UseUpdateCampaignVariables = Omit<
  UpdateCampaignParams,
  'accountId'
>;

export const useUpdateCampaign = (): UseMutationResult<
  Campaign,
  Error,
  UseUpdateCampaignVariables
> => {
  const accountId = useAccountId();
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: (params) => updateCampaign({ ...params, accountId }),
    onSuccess: (data) => {
      queryClient.setQueryData(campaignKeys.detail(accountId, data.id), data);
    }
  });
};

import React, { PureComponent } from 'react';
import { styled, StyleSheet } from '@rexlabs/styling';

const defaultStyles = StyleSheet({
  container: {
    display: 'flex-column',
    background: ({ token }) => token('legacy.color.white'),
    padding: ({ token }) => token('spacing.m'),
    boxShadow: '0 2px 10px rgba(0,0,0,0.1)'
  },
  text: {
    color: ({ token }) => token('legacy.color.blue.grey')
  },
  label: {
    marginBottom: '10px'
  }
});

@styled(defaultStyles)
class TooltipContent extends PureComponent {
  render() {
    const { active, styles: s } = this.props;

    if (active) {
      const { payload, label } = this.props;
      return (
        <div {...s('container')}>
          <div {...s('label')}>{label}</div>
          {payload.map((value) => {
            return (
              <div key={value.name}>
                <span {...s('text')}>{value.payload[value.name]}</span>
              </div>
            );
          })}
        </div>
      );
    }
    return null;
  }
}

export default TooltipContent;

import {ListingStatus} from "src/features/catalog-listing-sets/types";

export const DEFAULT_FORM_VALUES = {
  listing_min_price: undefined,
  listing_max_price: undefined,
  limit: undefined,
  sort: undefined,
  show_agent_image: false,
  listing_status: ListingStatus.Current,
};

export const LISTING_STATUSES = [
  { label: 'Current listings', value: 'current' },
  { label: 'Sold listings', value: 'sold' },
]
export const AU_LISTING_CATEGORY_OPTIONS = [
  { label: 'Residential sale', id: 'residential_sale' },
  { label: 'Residential rental', id: 'residential_rental' },
  { label: 'Holiday rental', id: 'holiday_rental' },
  { label: 'Rural sale', id: 'rural_sale' },
  { label: 'Land sale', id: 'land_sale' },
  { label: 'Business sale', id: 'business_sale' },
  { label: 'Commercial sale', id: 'commercial_sale' },
  { label: 'Commercial rental', id: 'commercial_rental' },
  { label: 'Commercial sale or rental', id: 'commercial_sale_rental' },
];

export const NZ_LISTING_CATEGORY_OPTIONS = [
  { label: 'Residential sale', id: 'residential_sale' },
  { label: 'Residential rental', id: 'residential_rental' },
  { label: 'Holiday rental', id: 'holiday_rental' },
  { label: 'Rural sale', id: 'rural_sale' },
  { label: 'Land sale', id: 'land_sale' },
  { label: 'Business sale', id: 'business_sale' },
  { label: 'Commercial sale', id: 'commercial_sale' },
  { label: 'Commercial rental', id: 'commercial_rental' },
];

export const UK_LISTING_CATEGORY_OPTIONS = [
  { label: 'Residential sale', id: 'residential_sale' },
  { label: 'Residential rental', id: 'residential_rental' },
  { label: 'Land sale', id: 'land_sale' },
  { label: 'Commercial sale', id: 'commercial_sale' },
  { label: 'Commercial rental', id: 'commercial_rental' },
  { label: 'Commercial sale or rental', id: 'commercial_sale_rental' },
];

export const LISTING_SORT_OPTIONS = [
  { label: 'Date created', id: 'created_at' },
  { label: 'Date sold', id: 'sold_at' },
];

import { useMutation, useQueryClient } from 'react-query';
import { useAccountId } from 'src/features/accounts';
import { MutationResult } from 'src/lib/react-query';
import { api } from 'src/utils/api-client';
import { Testimonial } from '../types';
import { testimonialsKeys } from './keys';

export type DeleteTestimonialParams = {
  accountId: string;
  testimonialId: string;
};

export const deleteTestimonial = async ({
  testimonialId
}: DeleteTestimonialParams): Promise<void> => {
  await api.delete(`/testimonials/${testimonialId}`);
};

export type UseDeleteTestimonialVariables = Omit<
  DeleteTestimonialParams,
  'accountId'
>;

export const useDeleteTestimonial = (): MutationResult<
  void,
  UseDeleteTestimonialVariables,
  { previousValue?: Testimonial }
> => {
  const queryClient = useQueryClient();
  const accountId = useAccountId();

  return useMutation({
    mutationFn: (vars) => deleteTestimonial({ accountId, ...vars }),
    onMutate: async ({ testimonialId }) => {
      const key = testimonialsKeys.detail({ accountId, testimonialId });
      await queryClient.cancelQueries(key);

      const previousValue = queryClient.getQueryData<Testimonial>(key);
      queryClient.removeQueries(key, { exact: true });

      return { previousValue };
    },
    onError: (_e, vars, ctx) => {
      queryClient.setQueryData(
        testimonialsKeys.detail({ accountId, ...vars }),
        ctx?.previousValue
      );
    },
    onSettled: () => {
      queryClient.invalidateQueries(testimonialsKeys.lists({ accountId }));
    }
  });
};

import React, { PureComponent } from 'react';
import { PropTypes } from 'prop-types';
import { styled, StyleSheet } from '@rexlabs/styling';
import getContrastColor from '../../utils/contrast-color';

const fontFamily = 'Lato, sans-serif';

const defaultStyles = StyleSheet({
  button: {
    fontSize: '14px',
    lineHeight: '14px',
    fontWeight: '400',
    padding: '10px 16px 12px',
    borderRadius: '3px',
    borderWidth: 0,
    fontFamily,
    width: '100%',

    // Default
    backgroundColor: 'black',
    color: 'white',
    '@media print': {
      '-webkit-print-color-adjust': 'exact'
    }
  }
});

@styled(defaultStyles)
export default class DefaultButton extends PureComponent {
  static propTypes = {
    brandColor: PropTypes.string,
    invert: PropTypes.bool
  };

  static defaultProps = {
    brandColor: '#000000',
    invert: false
  };

  render() {
    const { brandColor, invert, children, styles: s } = this.props;

    // This was added for light brand colors (contrast color will be black)
    if (getContrastColor(brandColor, 250) === 'black') {
      return <button {...s('button')}>{children}</button>;
    }

    return (
      <button
        {...s.with('button')({
          color: invert ? brandColor : getContrastColor(brandColor),
          // backgroundColor: brandColor
          backgroundColor: invert ? getContrastColor(brandColor) : brandColor
        })}
      >
        {children}
      </button>
    );
  }
}

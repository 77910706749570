import React from 'react';
import { useToken } from '@rexlabs/styling';
import VividLoadingSpinner from '@rexlabs/loading-spinner';

function LoadingSpinner(props) {
  const token = useToken();
  return (
    <VividLoadingSpinner colors={[token('palette.brand.600')]} {...props} />
  );
}

export default LoadingSpinner;

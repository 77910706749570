import {
  CommercialListing,
  CommercialListingFormDTO,
  CommercialListingPostDTO,
  DataSource,
  ListingType
} from '../types';
import { serializeAddress } from 'utils/address';
import {
  LISTING_CATEGORIES,
  SALE_OR_RENTAL_MAPPING
} from 'src/features/listings/components/details-form/constants';

export function listingTypeText(status: ListingType): string {
  switch (status) {
    case 'sale':
      return 'For Sale';
    case 'rental':
      return 'For Lease';
    case 'sale or lease':
      return 'For Sale & For Lease';
  }
}

export function listingSourceText(source: DataSource): string {
  switch (source) {
    case 'rex':
      return 'Imported from Rex CRM';
    case 'spoke':
      return 'Created in Reach';
  }
}

export function parseInitialData(
  data: CommercialListing
): CommercialListingFormDTO {
  const subCategory = LISTING_CATEGORIES.includes(data.sub_categories[0])
    ? data.sub_categories
    : ['Other'];
  return {
    ...data,
    videos: data?.videos?.[0],
    events: data?.events?.data,
    agents: data?.agents?.data,
    agent_id: data?.agents?.data[0]?.id,
    mapPinLocation: data ? [data.address.longitude, data.address.latitude] : [],
    sub_categories: subCategory,
    otherSubCategory: subCategory.includes('Other')
      ? data.sub_categories[0]
      : undefined
  };
}

export function parsePostData(
  data: CommercialListingFormDTO
): CommercialListingPostDTO {
  return {
    ...data,
    address: {
      ...serializeAddress(data.address),
      longitude: data.mapPinLocation[0],
      latitude: data.mapPinLocation[1]
    },
    sub_categories: data.otherSubCategory
      ? [data.otherSubCategory]
      : data.sub_categories,
    images: data.images?.map((i) => ({ id: i.id, name: i.name })) ?? [],
    agents: data.agent_id ? [{ id: data.agent_id }] : [],
    videos: data.videos ? [{ id: data.videos.id }] : [],
    sale_or_rental: SALE_OR_RENTAL_MAPPING[data.category ?? '']
  } as CommercialListingPostDTO;
}

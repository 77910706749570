import { styled, StyleSheet } from '@rexlabs/styling';
import React, { PureComponent } from 'react';
import { Body, Tiny } from 'src/view/components/text';
import OptionCore from './core';

const defaultStyles = StyleSheet({
  container: {
    paddingLeft: ({ token }) => token('spacing.m'),
    paddingRight: ({ token }) => token('spacing.m'),

    ':hover': {
      background: ({ token }) => token('color.container.interactive.hover')
    },

    '& > :first-child': {
      paddingBottom: '0'
    }
  },

  description: {
    color: ({ token }) => token('legacy.color.blue.grey'),
    paddingTop: '0',
    paddingBottom: ({ token }) => token('spacing.m')
  },

  selected: {
    background: ({ token }) => token('color.container.interactive.selected')
  }
});

@styled(defaultStyles)
class OptionWithDescription extends PureComponent {
  render() {
    const { option, selected, styles: s } = this.props;

    return (
      <OptionCore {...this.props}>
        <div {...s('container', { selected })}>
          <Body>{option.label}</Body>
          <Tiny {...s('description')}>{option.description}</Tiny>
        </div>
      </OptionCore>
    );
  }
}

class SelectedOptionWithDescription extends PureComponent {
  render() {
    return <OptionWithDescription {...this.props} selected={true} />;
  }
}

export { OptionWithDescription, SelectedOptionWithDescription };

import React from 'react';
import { TextInputStyledSuffix } from 'src/view/components/input';

const DomainInput = (props) => {
  const isUKDomain = window.location.host.includes('uk', 'flink');

  return (
    <TextInputStyledSuffix
      {...props}
      suffix={(isUKDomain ? '.lp.uk' : '.lp') + '.spokeapp.io'}
    />
  );
};

export default DomainInput;

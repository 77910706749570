import { styled, StyleSheet } from '@rexlabs/styling';
import { autobind } from 'core-decorators';
import localforage from 'localforage';
import React, { PureComponent } from 'react';
import CrossIcon from 'src/assets/icons/cross.svg';
import HelpIcon from 'src/assets/icons/help.svg';
import { BORDER_RADIUS, FONT, SHADOWS } from 'src/theme';

const defaultStyles = StyleSheet({
  noteContainer: {
    background: ({ token }) => token('legacy.color.white'),
    padding: ({ token }) => token('spacing.xl'),
    marginTop: ({ token }) => token('spacing.xs'),
    borderRadius: BORDER_RADIUS.INPUT,
    boxShadow: SHADOWS.MAP,
    fontSize: '1.4rem',
    color: ({ token }) => token('color.textStyle.body.subtext'),
    position: 'relative'
  },

  modalShadow: {
    boxShadow: `${SHADOWS.MODAL}!important`
  },

  closeButton: {
    position: 'absolute',
    top: '.85rem',
    right: '.85rem',
    color: ({ token }) => token('legacy.color.grey.default'),
    cursor: 'pointer'
  },

  closeIcon: {
    height: '1.45rem',
    width: 'auto'
  },

  title: {
    fontSize: '1.6rem',
    fontWeight: FONT.WEIGHTS.SEMIBOLD,
    color: ({ token }) => token('legacy.color.blue.dark'),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    justifyContent: 'flex-start',
    paddingBottom: ({ token }) => token('spacing.xs')
  },

  icon: {
    color: ({ token }) => token('legacy.color.blue.dark'),
    height: '2.3rem',
    width: 'auto',
    marginBottom: ({ token }) => token('spacing.xs')
  }
});

@styled(defaultStyles)
@autobind
class HelpBox extends PureComponent {
  constructor(props) {
    super(props);

    this.Title = ({ children, ...otherProps }) => (
      <h3 {...props.styles('title')} {...otherProps}>
        <span>{children}</span>
      </h3>
    );

    const { helpBoxId, showCloseButton, onClose } = props;

    if (!showCloseButton) {
      this.state = { loaded: false, closed: false };
      return;
    }

    this.state = { loaded: false, closed: true };

    if (typeof onClose === 'function') {
      return;
    }

    if (!helpBoxId) {
      throw Error('You must provide helpBoxId or a custom onClose handler');
    }

    localforage.getItem('helpbox-close-state.' + helpBoxId).then((closed) =>
      this.setState({
        ...this.state,
        closed: !!closed
      })
    );
  }

  componentDidMount() {
    this.setState({ ...this.state, loaded: true });
  }

  defaultOnClose() {
    const { helpBoxId } = this.props;

    localforage.setItem('helpbox-close-state.' + helpBoxId, true).then(() =>
      this.setState({
        ...this.state,
        closed: true
      })
    );
  }

  render() {
    const {
      children,
      marginTop,
      maxWidth,
      modalShadow,
      styles: s,
      showCloseButton = false,
      onClose = this.defaultOnClose
    } = this.props;

    return this.state.loaded && !this.state.closed ? (
      <div
        {...s.with('noteContainer', { modalShadow })({ marginTop, maxWidth })}
      >
        {showCloseButton && (
          <span onClick={onClose} {...s('closeButton')}>
            <CrossIcon {...s('closeIcon')} />
          </span>
        )}
        {children({ Title: this.Title })}
      </div>
    ) : null;
  }
}

export default HelpBox;

import { useMutation, useQueryClient } from 'react-query';
import { useAccountId } from 'src/features/accounts';
import { MutationResult } from 'src/lib/react-query';
import { api } from 'src/utils/api-client';
import { CommercialListing, CommercialListingPostDTO } from '../types';
import { commercialListingKeys } from 'src/features/listings/api/keys';

export type UpdateCommercialListingParams = {
  accountId: string;
  listingId: string;
  listing: CommercialListingPostDTO;
};

export const updateCommercialListing = async ({
  listing,
  listingId
}: UpdateCommercialListingParams): Promise<CommercialListing> => {
  const { data } = await api.patch(
    `/commercial-listings/${listingId}`,
    listing
  );
  return data;
};

export type UseUpdateCommercialListingVariables = Omit<
  UpdateCommercialListingParams,
  'accountId'
>;

export const useUpdateCommercialListing = (): MutationResult<
  CommercialListing,
  UseUpdateCommercialListingVariables
> => {
  const queryClient = useQueryClient();
  const accountId = useAccountId();

  return useMutation({
    mutationFn: ({ listing, listingId }) =>
      updateCommercialListing({ accountId, listingId, listing }),
    onSuccess: () => {
      queryClient.invalidateQueries(commercialListingKeys.lists({ accountId }));
    }
  });
};

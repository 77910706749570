import { Box, BoxProps } from '@rexlabs/box';
import { StyleSheet, useStyles } from '@rexlabs/styling';
import React, { PropsWithChildren } from 'react';

const defaultStyles = StyleSheet({
  container: {
    paddingTop: ({ token }) => token('spacing.xxxl'),
    overflowY: 'auto'
  }
});

export default ({
  children,
  className,
  style,
  ...props
}: PropsWithChildren<BoxProps>) => {
  const s = useStyles(defaultStyles);
  return (
    <Box
      flexDirection='column'
      alignItems='flex-start'
      justifyContent='flex-start'
      {...s.with('container')({ className, style })}
      {...props}
    >
      {children}
    </Box>
  );
};

export const campaignDefinitionKeys = {
  all: (accountId: string) => [accountId, 'campaign-definition'] as const,
  lists: (accountId: string) =>
    [...campaignDefinitionKeys.all(accountId), 'list'] as const,
  list: (accountId: string, page: number) =>
    [...campaignDefinitionKeys.lists(accountId), { page }] as const,
  details: (accountId: string) =>
    [...campaignDefinitionKeys.all(accountId), 'detail'] as const,
  detail: (accountId: string, id: string) =>
    [...campaignDefinitionKeys.details(accountId), id] as const
};

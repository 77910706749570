import { Box } from '@rexlabs/box';
import { styled, StyleSheet } from '@rexlabs/styling';
import React, { PureComponent } from 'react';
import { FONT, withToken } from 'src/theme';

const defaultStyles = StyleSheet({
  container: {
    fontSize: '1.4rem',
    fontWeight: FONT.WEIGHTS.SEMIBOLD,
    color: ({ token }) => token('legacy.color.blue.grey'),
    borderBottom: ({ token }) =>
      `.1rem solid ${token('legacy.color.blue.greyLight')}`
  }
});

@withToken
@styled(defaultStyles)
class AccountListHeader extends PureComponent {
  render() {
    const { token, styles: s } = this.props;
    return (
      <Box
        p={token('spacing.m')}
        pt={0}
        flexDirection='row'
        alignItems='center'
        justifyContent='space-between'
        {...s('container')}
      >
        <Box flex={1}>Name</Box>
        <Box style={{ width: '22rem' }}>Provisioning Status</Box>
        <Box style={{ width: '14rem' }}>Is Ready?</Box>
        <Box style={{ width: '7rem' }} />
      </Box>
    );
  }
}

export default AccountListHeader;

import { Box } from '@rexlabs/box';
import { styled, StyleSheet } from '@rexlabs/styling';
import { Small } from '@rexlabs/text';
import React from 'react';
import { BORDER_RADIUS, isSpoke } from 'src/theme';
import uuid from 'src/utils/uuid';
import { Checkbox } from 'src/view/components/input';
import Money from 'src/view/components/money';

import MasterCardLogo from 'src/assets/icons/credit-cards/master-card.svg';
import PrepaidCredit from 'src/assets/icons/credit-cards/prepaid-credit.svg';
import UnknownLogo from 'src/assets/icons/credit-cards/unknown.svg';
import VisaLogo from 'src/assets/icons/credit-cards/visa.svg';

const defaultStyles = StyleSheet({
  container: {
    padding: ({ token }) => token('spacing.m'),
    background: ({ token }) => token('color.container.static.contrast'),
    borderRadius: BORDER_RADIUS.INPUT,
    display: 'inline-flex !important',
    height: '7.2rem',
    width: '36rem',
    flexShrink: 0
  },

  containerFullWidth: {
    width: '100%'
  },

  containerSelectable: {
    width: '100%',
    marginTop: ({ token }) => token('spacing.m'),
    border: ({ token }) =>
      `.1rem solid ${token('color.border.container.interactive.idle')}`,
    cursor: 'pointer'
  },

  containerSelected: {
    borderColor: ({ token }) =>
      token('color.border.container.interactive.hover')
  },

  provider: {
    width: '6rem',
    height: '4rem',
    marginRight: ({ token }) => token('spacing.m'),

    '> svg': {
      width: '100%',
      height: '100%'
    }
  },

  primary: {
    color: ({ token }) => token('color.primary.default')
  },

  private: {
    color: ({ token }) => token('legacy.color.blue.grey')
  }
});

function CreditCardPreview({
  type,
  availableFunds = {},
  provider,
  number,
  name,
  expiryDate,
  primary,
  selectable,
  selected,
  fullWidth,
  privateUserId,
  styles: s,
  ...rest
}) {
  const getLogo = () => {
    if (type === 'credit_pool') {
      return <PrepaidCredit />;
    }
    switch (provider) {
      case 'MasterCard':
        return <MasterCardLogo />;
      case 'Visa':
        return <VisaLogo />;
      default:
        return <UnknownLogo />;
    }
  };

  return (
    <Box
      {...s('container', {
        containerFullWidth: fullWidth,
        containerSelectable: selectable,
        containerSelected: selected
      })}
      flexDirection='row'
      alignItems='center'
      justifyContent='space-between'
      {...rest}
    >
      <Box flexDirection='row' alignItems='center'>
        <Box {...s('provider')}>{getLogo()}</Box>
        <Box flexDirection='column'>
          <Small as='span'>
            {type === 'credit_pool'
              ? 'Your credits'
              : `${provider} *${number} `}
            {privateUserId && <span {...s('private')}> ∙ Private</span>}
            {primary && <span {...s('primary')}> ∙ Primary</span>}
          </Small>
          <Small grey as='span'>
            {type === 'credit_pool'
              ? isSpoke && (
                  <span>
                    Balance:{' '}
                    <Money
                      amount={availableFunds.value}
                      currencyCode={availableFunds.currency_code}
                      integer={true}
                    />
                  </span>
                )
              : `${name} ∙ Exp. ${expiryDate}`}
          </Small>
        </Box>
      </Box>
      {selectable && (
        <Box>
          <Checkbox id={uuid()} name='cc' value={selected} round />
        </Box>
      )}
    </Box>
  );
}

export default styled(defaultStyles)(CreditCardPreview);

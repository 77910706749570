import { track } from '@rexlabs/analytics';
import { Box } from '@rexlabs/box';
import { GhostButton, PrimaryButton } from '@rexlabs/button';
import { useModelState, withModel, withQuery } from '@rexlabs/model-generator';
import { useToken } from '@rexlabs/styling';
import { match } from '@rexlabs/whereabouts';
import React, { useCallback, useState } from 'react';
import { Helmet } from 'react-helmet';
import { compose } from 'redux';
import { useFeature } from 'shared/hooks';
import { mapPropsToValues } from 'shared/utils/prepare-ad-data';
import sessionModel from 'src/data/models/custom/session';
import wizardModel from 'src/data/models/custom/wizard';
import { adsQuery } from 'src/data/queries/ads';
import { campaignAdQuery } from 'src/data/queries/campaigns';
import { facebookQuery } from 'src/data/queries/facebook';
import { formattedBrandName } from 'src/theme';
import { createValidationRules } from 'src/utils/form';
import { Carousel } from 'src/view/components/ad-previews';
import { StickyButtonGroup } from 'src/view/components/button';
import ComponentRenderer from 'src/view/components/component-renderer';
import { Form, withForm } from 'src/view/components/form';
import { RenderLoading } from 'src/view/components/loading';
import { FullscreenModal } from 'src/view/components/modal';
import { PortalDelayed } from 'src/view/components/portal';
import { Heading } from 'src/view/components/text';
import withError from 'src/view/containers/with-error';
import EditAdsForm, {
  handleSubmit,
  validationRules
} from 'src/view/forms/edit-ads';

const carouselStyles = {
  container: {
    position: 'sticky',
    top: 0
  }
};

const fullScreenModalStyles = {
  inner: {
    '&::before': {
      width: `${102.4 * 0.5}rem`
    }
  },

  wrapLeft: {
    width: '50%'
  },

  wrapRight: {
    width: '50%'
  }
};

function getId(props) {
  const { whereabouts } = props;
  const matched = match(whereabouts, {
    path: '/:accountId?/campaign/create/:type/:campaignId'
  });
  return matched ? matched.params.campaignId : null;
}

async function handleFormSubmit(values, { props, ...rest }) {
  const { closeModal, errorModal, campaigns } = props;
  const campaignType = props?.campaigns?.item?.data?.type?.id;

  try {
    if (!['single_listing', 'multi_listing_sold'].includes(campaignType)) {
      const url = values?.prospecting?.final_url;
      await campaigns.validateUrl({ url });
    }
    await handleSubmit(values, { props, ...rest });

    track({
      event: 'Spoke campaign ads edited',
      properties: {
        action: 'clicked_save',
        section: 'edit_ads',
        video_uploaded: !!values?.prospecting?.video
      }
    });

    closeModal();
  } catch (e) {
    errorModal.open(e);
  }
}

function asyncValuesReady(props) {
  return props.campaigns.item.status === 'loaded';
}

function validate(values) {
  return createValidationRules(validationRules(values))(values);
}

const editAdsForm = {
  name: 'editAds',
  validate,
  mapPropsToValues,
  validateOnChange: true,
  handleSubmit: handleFormSubmit,
  asyncValuesReady,
  safeMode: false
};

const EditAdsModal = (props) => {
  const {
    closeModal,
    whereabouts,
    editAds,
    campaigns,
    facebook,
    errorModal: { Error },
    wizard: { type },
    ads
  } = props;
  const session = useModelState(sessionModel);
  const useCampaignGoals = useFeature('campaign_goal', session);
  const token = useToken();

  const [activeTab, setActiveTab] = useState('prospecting');

  const campaignTemplate =
    campaigns.item.data?.campaign_template?.campaign_template_configuration
      ?.configuration;
  const campaign = campaigns?.item?.data ?? {};
  const matched = match(whereabouts, {
    path: '/:accountIndex?/campaign/create/:type/:campaignId?'
  });
  const campaignId = matched?.params?.campaignId;
  const adContentSets = campaigns?.item?.data?.ad_content_sets?.items ?? [];
  const propsToValues = mapPropsToValues(props);
  const { prospecting } = propsToValues;
  const slides = prospecting.slides;
  const campaignStatus = campaigns?.item?.data?.status?.id;
  const campaignNetworks = campaign?.audience_spec?.networks;

  const onChangeActiveTab = useCallback(({ name }) => setActiveTab(name), []);

  // Retargetting currently disabled
  // focusErroneousAdsTab (errors) {
  //   if (this.state.activeTab === 'prospecting' && !errors.prospecting) {
  //     // No errors on current Ads sub tab, swap
  //     this.setState({ activeTab: 'retargeting' });
  //   } else if (this.state.activeTab === 'retargeting' && !errors.retargeting) {
  //     // No errors on current Ads sub tab, swap
  //     this.setState({ activeTab: 'prospecting' });
  //   }
  // }

  // Retargetting currently disabled
  // componentWillReceiveProps (nextProps) {
  //   const { editAds, submitCount } = nextProps;
  //   if (
  //     this.props.submitCount !== submitCount &&
  //     Object.keys(editAds.errors).length
  //   ) {
  //     // Only check on submit && there are errors found
  //     this.focusErroneousAdsTab(editAds.errors);
  //   }
  // }

  return (
    <Box flex={1} flexDirection='column'>
      <Helmet>
        <title>{formattedBrandName} - Edit Ads</title>
      </Helmet>

      <Heading>Edit ads</Heading>

      <RenderLoading isLoading={campaigns.item.status === 'loading'}>
        {/*
          NOTE: this conditional fixes an cypress e2e test issue where you can't click
          elements because they've become detached due to a rerender
        */}
        {campaigns.item.status !== 'loading' && (
          <Box flex={1} flexDirection='column'>
            <Form
              name='editAdsForm'
              data-testid={
                Object.values(editAds.values).length > 0 && 'edit-ads-form'
              }
            >
              <EditAdsForm
                activeTab={activeTab}
                onChange={onChangeActiveTab}
                values={editAds.values}
                errors={editAds.errors}
                touched={editAds.touched}
                contentSource={campaign.content_source || {}}
                adContentSets={adContentSets}
                campaignId={campaignId}
                campaignType={type}
                propertyImages={campaign.images || []}
                agentImages={campaign.agent_images || []}
                status={campaigns?.item?.status}
                change={editAds.setFieldValue}
                slides={slides}
                campaignTemplate={campaignTemplate}
                campaignStatus={campaignStatus}
                campaignNetworks={campaignNetworks}
                leadGenerationFormEnabled={
                  // eslint-disable-next-line react-hooks/rules-of-hooks
                  !useCampaignGoals && campaign?.lead_generation_form_enabled
                }
              />
            </Form>

            <StickyButtonGroup mt={token('spacing.s')} end>
              <GhostButton onClick={closeModal}>Cancel</GhostButton>
              <PrimaryButton
                isDisabled={!!editAds.values?.prospecting?.video?.isUploading}
                onClick={editAds.submitForm}
                isLoading={editAds.isSubmitting}
              >
                Save
              </PrimaryButton>
            </StickyButtonGroup>

            <PortalDelayed target='contentRight'>
              <ComponentRenderer
                template={campaignTemplate}
                components={{ Box, Heading, Carousel }}
                resource='ads'
                section='preview'
                extraPropsFn={({ type }) => {
                  let extraProps;

                  if (type === 'Heading') {
                    extraProps = {
                      color: token('legacy.color.blue.grey')
                    };
                  }

                  if (type === 'Carousel') {
                    const listing =
                      campaigns?.item?.data?.content_source?.listings
                        ?.items?.[0];
                    const campaignNetworks =
                      campaigns?.item?.data?.audience_spec?.networks ?? [];

                    extraProps = {
                      isLoading: !Object.keys(editAds.values).length,
                      ads,
                      campaignId,
                      campaignData: {
                        ...propsToValues,
                        ...editAds.values,
                        prospecting: {
                          ...propsToValues.prospecting,
                          ...editAds.values.prospecting
                        },
                        facebookPage: facebook?.item?.data?.pages?.[0]?.name,
                        listing
                      },
                      networks: campaignNetworks,
                      styles: carouselStyles,
                      contentSource: campaign.content_source || {}
                    };
                  }

                  return extraProps;
                }}
              />
            </PortalDelayed>

            <Error />
          </Box>
        )}
      </RenderLoading>
    </Box>
  );
};

const EditAdsModalWrapper = compose(
  withError('errorModal'),
  withQuery(campaignAdQuery(getId)),
  withQuery(adsQuery(getId)),
  withQuery(facebookQuery),
  withModel(wizardModel),
  withForm(editAdsForm)
)(EditAdsModal);

const Core = (props) => {
  return (
    <FullscreenModal styles={fullScreenModalStyles} onClose={props.closeModal}>
      <EditAdsModalWrapper {...props} />
    </FullscreenModal>
  );
};

export default Core;

import { withModel } from '@rexlabs/model-generator';
import { StyleSheet } from '@rexlabs/styling';
import { autobind } from 'core-decorators';
import React, { PureComponent } from 'react';
import session from 'src/data/models/custom/session';
import fakeEvent from 'src/utils/fake-event';
import { Map, rexOfficeLngLat } from 'src/view/components/map';

const mapStyles = StyleSheet({
  map: {
    height: '16rem',
    width: '100%',
    background: ({ token }) => token('legacy.color.blue.greyLight'),
    borderRadius: ({ token }) => token('spacing.xs'),
    overflow: 'hidden',
    position: 'relative',
    zIndex: 1,
    marginBottom: ({ token }) => token('spacing.xs')
  }
});

@withModel(session)
@autobind
class MapDraggablePin extends PureComponent {
  handleMapDragEnd({ target }) {
    const { onChange, name } = this.props;
    const value = [target._lngLat.lng, target._lngLat.lat];
    onChange(fakeEvent('string', name, name, value));
  }

  render() {
    const { value, session } = this.props;

    let fallbackLngLat = rexOfficeLngLat;
    if (!value) {
      const { accounts, currentAccountId } = session;
      const account = accounts.find(
        (account) => account.id === currentAccountId
      );
      const accountAddress = account?.agencies?.data?.[0]?.address;
      fallbackLngLat = [accountAddress.longitude, accountAddress.latitude];
    }

    return (
      <Map
        center={value || fallbackLngLat}
        markers={[value || fallbackLngLat]}
        onDragEnd={this.handleMapDragEnd}
        hideRadius={true}
        zoom={14}
        styles={mapStyles}
      />
    );
  }
}

export default MapDraggablePin;

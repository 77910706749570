import { withValueLists } from '@rexlabs/model-generator';
import { autobind } from 'core-decorators';
import React, { PureComponent } from 'react';
import Select from './default';
import { filterOptionsByValue } from './utils';
import SelectPillsValue from './values/pills';

import languagesModel from 'src/data/models/value-lists/languages';
import australianStates from 'src/data/models/value-lists/locale/australian-states';
import countryModel from 'src/data/models/value-lists/locale/countries';
import newZealandRegions from 'src/data/models/value-lists/locale/new-zealand-regions';
import timezonesModel from 'src/data/models/value-lists/timezones';

export function createValueListSelect({
  model,
  Option,
  OptionSelected,
  Value,
  placeholder
}) {
  @withValueLists(model)
  @autobind
  class ValueListSelect extends PureComponent {
    static defaultProps = {
      pluckValue: (datum) => datum.id,
      pluckLabel: (datum) => datum.label
    };

    getOptions() {
      const { valueLists, pluckLabel, pluckValue } = this.props;
      const items = valueLists?.[model.modelName]?.items ?? [];
      return items.map((item) => ({
        value: pluckValue(item),
        label: pluckLabel(item),
        data: item
      }));
    }

    handleSelect(selected) {
      const { onChange, onBlur, name, multi } = this.props;
      const fakeEvent = {
        persist: () => null,
        target: {
          type: 'select',
          name,
          id: name,
          value: multi
            ? selected
                .map((s) => s.value)
                .filter(
                  (s) => selected.filter((sel) => sel.value === s).length === 1
                )
            : selected?.value
        }
      };
      if (onChange) {
        onChange(fakeEvent);
      }

      if (onBlur) {
        // Hack: onBlur needs to wait for the onChange to change the local
        // state of the form field, so it passes it up to the form correctly
        // The timeout ensures we run onBlur on the next tick, at which point
        // the value should be stored locally!
        setTimeout(() => {
          window.document.activeElement.blur();
          onBlur(fakeEvent);
        });
      }
    }

    render() {
      const { valueLists, pluckValue, pluckLabel, value, ...props } =
        this.props;
      const isLoading = valueLists?.[model.modelName]?.status === 'loading';
      const options = this.getOptions();
      return (
        <Select
          isLoading={isLoading}
          options={options}
          Option={Option}
          OptionSelected={OptionSelected}
          Value={
            Value
              ? (props) => (
                  <Value
                    placeholder={placeholder}
                    value={value}
                    {...props}
                    selected={filterOptionsByValue(options, value)}
                  />
                )
              : undefined
          }
          value={value}
          placeholder={placeholder}
          {...props}
          onSelect={this.handleSelect}
        />
      );
    }
  }

  class ValueListSelectContainer extends PureComponent {
    render() {
      return <ValueListSelect {...this.props} />;
    }
  }

  return ValueListSelectContainer;
}

const LanguagesSelect = createValueListSelect({
  model: languagesModel,
  Value: SelectPillsValue,
  placeholder: 'Select languages...'
});

const TimezoneSelect = createValueListSelect({
  model: timezonesModel,
  placeholder: 'Select timezone...'
});

const CountrySelect = createValueListSelect({
  model: countryModel
});

const AustralianStatesSelect = createValueListSelect({
  model: australianStates
});

const NewZealandRegionsSelect = createValueListSelect({
  model: newZealandRegions
});

export {
  LanguagesSelect,
  TimezoneSelect,
  CountrySelect,
  AustralianStatesSelect,
  NewZealandRegionsSelect
};

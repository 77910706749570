import { useInfiniteQuery } from 'react-query';
import { useAccountId } from 'src/features/accounts';
import { InfiniteQueryConfig, InfiniteQueryResult } from 'src/lib/react-query';
import { PaginatedResponse, Pagination, PaginationParams } from 'src/types';
import { api } from 'src/utils/api-client';
import { CommercialListing } from '../types';
import { transformCommercialListingResponse } from '../utils/schemas';
import { commercialListingKeys } from './keys';

export type GetCommercialListingsParams = PaginationParams & {
  accountId: string;
  q?: string;
};

export const getCommercialListings = async ({
  accountId,
  q,
  ...params
}: GetCommercialListingsParams): Promise<
  PaginatedResponse<CommercialListing>
> => {
  const { data, pagination } = await api.get('/commercial-listings', {
    ...params,
    include: 'address,thumbnails',
    q: q ? `address.match(*${q}*)` : undefined
  });
  return {
    data: data.map(transformCommercialListingResponse),
    pagination: pagination as Pagination
  };
};

type QueryFnType = typeof getCommercialListings;

export type UseCommercialListingsOptions = Omit<
  GetCommercialListingsParams,
  'accountId' | 'page'
> & {
  config?: InfiniteQueryConfig<QueryFnType>;
};

export const useCommercialListings = ({
  config,
  ...params
}: UseCommercialListingsOptions = {}): InfiniteQueryResult<CommercialListing> => {
  const accountId = useAccountId();

  return useInfiniteQuery<
    PaginatedResponse<CommercialListing>,
    Error,
    PaginatedResponse<CommercialListing>
  >({
    queryKey: commercialListingKeys.list({ accountId, ...params }),
    queryFn: ({ pageParam }) =>
      getCommercialListings({ ...params, accountId, page: pageParam }),
    getNextPageParam: ({ pagination }) =>
      pagination.current_page === pagination.total_pages
        ? undefined
        : pagination.current_page + 1,
    ...config
  });
};

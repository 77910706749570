import { Box } from '@rexlabs/box';
import { GhostButton, PrimaryButton } from '@rexlabs/button';
import { useToken } from '@rexlabs/styling';
import { Body, Small } from '@rexlabs/text';
import React, { useState } from 'react';
import { ModalStickyButtonGroup } from 'src/view/components/button';
import { GridCell, GridRow } from 'src/view/components/grid';
import Money from 'src/view/components/money';
import { PaymentOptions } from 'src/view/components/payment';

export function ChangePlanConfirmation({
  campaignName,
  currentPlan,
  newPlan,
  duration,
  selectPlan,
  confirmNewPlan,
  isSubmitting
}) {
  const token = useToken();
  const [payMethod, setPayMethod] = useState();

  return (
    <Box flex={1} flexDirection='column'>
      <Box
        p={token('spacing.m')}
        style={{
          width: '100%',
          background: token('legacy.color.white')
        }}
      >
        <GridRow>
          <GridCell gutter={token('spacing.xl')} width={1 / 6}>
            <Body grey>Package</Body>
          </GridCell>
          <GridCell gutter={token('spacing.xl')} width={5 / 6}>
            <Box mb={token('spacing.m')}>
              <Body>
                {newPlan.name} (
                {parseFloat(currentPlan.price.value) <
                parseFloat(newPlan.price.value)
                  ? 'upgrade'
                  : 'downgrade'}{' '}
                from {currentPlan?.name ?? 'current package'})
              </Body>

              {newPlan.duration.value > duration.total && (
                <Small grey>
                  + {newPlan.duration.value - duration.total} days
                </Small>
              )}
            </Box>
          </GridCell>
        </GridRow>
        <GridRow>
          <GridCell gutter={token('spacing.xl')} width={1 / 6}>
            <Box mb={token('spacing.m')}>
              <Body grey>Cost</Body>
            </Box>
          </GridCell>
          <GridCell gutter={token('spacing.xl')} width={5 / 6}>
            <Box mb={token('spacing.m')}>
              <Body>
                {currentPlan?.type.id === 'recurring' ? (
                  <Money
                    amount={newPlan.price.value}
                    currencyCode={newPlan.price.currency_code}
                  />
                ) : (
                  <span>
                    +{' '}
                    <Money
                      amount={newPlan.price.value - newPlan.price.value}
                      currencyCode={newPlan.price.currency_code}
                    />
                  </span>
                )}
              </Body>
            </Box>
          </GridCell>
        </GridRow>
        <GridRow>
          <GridCell gutter={token('spacing.xl')} width={1 / 6}>
            <Box mb={token('spacing.m')}>
              <Body grey>Payment</Body>
            </Box>
          </GridCell>
          <GridCell gutter={token('spacing.xl')}>
            <PaymentOptions
              campaignName={campaignName}
              selectedCard={payMethod}
              onChange={(selectedCard) => {
                if (selectedCard) {
                  setPayMethod(selectedCard);
                }
              }}
              paymentOptions={undefined}
            />
          </GridCell>
        </GridRow>

        <ModalStickyButtonGroup padded>
          <GhostButton onClick={() => selectPlan()}>Back</GhostButton>
          <Box mr={token('spacing.m')}>
            <PrimaryButton
              onClick={() => confirmNewPlan((payMethod as any)?.id)}
              disabled={payMethod === undefined}
              isLoading={isSubmitting}
            >
              Change Plan
            </PrimaryButton>
          </Box>
        </ModalStickyButtonGroup>
      </Box>
    </Box>
  );
}

import { StyleSheet, useStyles } from '@rexlabs/styling';
import { LngLat } from 'mapbox-gl';
import React, { CSSProperties, FC, useEffect, useMemo, useRef } from 'react';
import type { LngLatLike, MapProps, MapRef } from 'react-map-gl';
import MapPin from 'src/assets/icons/map-pin.svg';
import {
  Layer,
  Map,
  Marker,
  NavigationControl,
  Source
} from 'src/components/elements/map';
import {
  Geometry,
  bbox,
  destination,
  featureCollection,
  point
} from 'src/lib/turf';
import type { Units } from 'src/types';
import { createCircleFeatures, normaliseLngLat } from 'src/utils/map';
import { useRadiusResizingState } from '../hooks/useRadiusResizingState';

const styles = StyleSheet({
  marker: {
    color: ({ token }) => token('palette.brand.600'),
    transform: 'translateY(5%)',
    height: '4.5rem',
    width: 'auto'
  }
});

// Top and Bottom padding set to 50 to ensure markers are fully visible on map (pin height 45px)
const mapBoundsOptions = {
  padding: {
    bottom: 50,
    left: 10,
    right: 10,
    top: 50
  }
};

export type AudienceMapProps = {
  mainPoint?: LngLatLike;
  radius?: number;
  unit?: Units;
  additionalPoints?: LngLatLike[];
  interactive?: boolean;
  style?: CSSProperties;
};

export const AudienceMap: FC<AudienceMapProps> = ({
  mainPoint = [0, 0],
  radius = 0,
  unit = 'kilometers',
  additionalPoints = [],
  interactive = false,
  style
}) => {
  const s = useStyles(styles, 'AudienceMap');
  const mapRef = useRef<MapRef>(null);
  const mainPointLngLat = LngLat.convert(mainPoint);
  const [resizingState, dispatch] = useRadiusResizingState(
    mainPointLngLat.toArray(),
    unit
  );
  const markers = [mainPoint, ...additionalPoints].map(normaliseLngLat);

  const mainPointRadius = createCircleFeatures({
    center: mainPointLngLat.toArray(),
    radius,
    unit,
    style: {
      fillColor: '#FB6A4A',
      fillOpacity: 0.25,
      strokeColor: '#000000',
      strokeOpacity: 0.75,
      strokeWidth: 0.5
    }
  });

  const nsRadiusHandles = featureCollection(
    [0, 2].map((rad) =>
      destination(mainPointLngLat.toArray(), radius, rad * 90, {
        units: unit
      })
    )
  );

  const ewRadiusHandles = featureCollection(
    [-1, 1].map((rad) =>
      destination(mainPointLngLat.toArray(), radius, rad * 90, {
        units: unit
      })
    )
  );

  const handlePaint = {
    'circle-radius': 3.75,
    'circle-color': resizingState.isResizing ? '#FB6A4A' : '#ffffff',
    'circle-stroke-color': '#000000',
    'circle-stroke-opacity': 0.75,
    'circle-stroke-width': 0.5
  };

  const mapBoundingBox = useMemo(
    () =>
      bbox(
        featureCollection<Geometry>([
          ...markers.map(({ latitude, longitude }) =>
            point([longitude, latitude])
          ),
          mainPointRadius.fill
        ])
      ) as [number, number, number, number],
    [markers, mainPointRadius.fill]
  );

  const initialViewState = useMemo<MapProps['initialViewState']>(
    () => ({
      bounds: mapBoundingBox,
      fitBoundsOptions: mapBoundsOptions
    }),
    [mapBoundingBox]
  );

  useEffect(() => {
    mapRef.current?.fitBounds(mapBoundingBox, mapBoundsOptions);
  }, [mapBoundingBox, mapRef]);

  return (
    <Map
      ref={mapRef}
      mapStyle='mapbox://styles/mapbox/streets-v11'
      initialViewState={initialViewState}
      interactive={interactive}
      antialias
      style={style}
      interactiveLayerIds={interactive ? ['ns_handles', 'ew_handles'] : []}
      onMouseDown={dispatch}
      onMouseUp={dispatch}
      onMouseMove={dispatch}
    >
      {interactive ? <NavigationControl /> : null}
      {markers.map((point) => (
        <Marker
          anchor='bottom'
          key={`${point.longitude},${point.latitude}`}
          {...point}
        >
          <MapPin {...s('marker')} />
        </Marker>
      ))}
      <Source type='geojson' data={mainPointRadius.fill}>
        <Layer type='fill' paint={mainPointRadius.fill.properties} />
      </Source>
      <Source type='geojson' data={mainPointRadius.stroke}>
        <Layer type='line' paint={mainPointRadius.stroke.properties} />
      </Source>
      {interactive ? (
        <>
          <Source type='geojson' data={nsRadiusHandles}>
            <Layer id='ns_handles' type='circle' paint={handlePaint} />
          </Source>
          <Source type='geojson' data={ewRadiusHandles}>
            <Layer id='ew_handles' type='circle' paint={handlePaint} />
          </Source>
        </>
      ) : null}
    </Map>
  );
};

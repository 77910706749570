import { Field, useFormApi } from '@data-driven-forms/react-form-renderer';
import { EventFn, GhostButton, GhostIconButton } from '@rexlabs/button';
import Icons from '@rexlabs/icons-next';
import { margin, StyleSheet, useStyles } from '@rexlabs/styling';
import { isEqual } from 'lodash';
import React, { memo, ReactNode } from 'react';
import { SortableHandle } from 'react-sortable-hoc';
import { Consumer } from 'src/types';

const DragHandle = SortableHandle(Icons.DragHandle);

const styles = StyleSheet({
  container: {
    display: 'flex',
    flexDirection: 'row',
    gap: ({ token }) => token('spacing.xxs')
  },

  handle: {
    ...margin.styles({
      top: 'xxs'
    })
  },

  controls: {
    display: 'flex',
    gap: ({ token }) => token('spacing.xxs')
  },

  controlsInline: {
    flexDirection: 'row',
    alignItems: 'center',
    flexGrow: '1'
  },

  controlsStacked: {
    flexDirection: 'column',
    alignItems: 'flex-start'
  },

  fields: {
    display: 'flex',
    flexDirection: 'column',
    flexGrow: '1',
    gap: ({ token }) => token('spacing.xxs')
  }
});

export type DynamicFieldArrayItemProps = {
  name: string;
  fields: Field[];
  fieldIndex: number;
  remove: Consumer<number>;
  disableRemove: boolean;
  removeLabel?: ReactNode;
  reorderable?: boolean;
};

export const DynamicFieldArrayItem = memo<DynamicFieldArrayItemProps>(
  ({
    name,
    fields,
    fieldIndex: index,
    remove,
    disableRemove,
    removeLabel,
    reorderable = true
  }) => {
    const s = useStyles(styles, 'DynamicFieldArrayItem');
    const { renderForm } = useFormApi();

    const absoluteFields: Field[] = fields.map((field, i) => {
      const fieldName = field.name ? `${name}.${field.name}` : name;
      return { ...field, name: fieldName, key: `${fieldName}-${i}` };
    });
    const removeItem: EventFn = () => remove(index);

    return (
      <div {...s('container')}>
        {reorderable ? <DragHandle {...s('handle')} /> : null}
        <div
          {...s('controls', {
            controlsInline: removeLabel === undefined,
            controlsStacked: removeLabel !== undefined
          })}
        >
          <div {...s('fields')}>{renderForm(absoluteFields)}</div>
          {removeLabel !== undefined ? (
            <GhostButton
              IconLeft={Icons.CrossSmall}
              onClick={removeItem}
              disabled={disableRemove}
            >
              {removeLabel}
            </GhostButton>
          ) : (
            <GhostIconButton
              Icon={Icons.CrossSmall}
              onClick={removeItem}
              isDisabled={disableRemove}
            />
          )}
        </div>
      </div>
    );
  },
  ({ remove: _prevRemove, ...prev }, { remove: _nextRemove, ...next }) =>
    isEqual(prev, next)
);

import { query } from '@rexlabs/model-generator';
import paymentOptionsModel from 'src/data/models/entities/payment-options';

export const paymentOptionsQuery = query`{
  ${paymentOptionsModel} {
    id
    is_primary
    private_to_user
    name
    type {
      id
      label
    }
    available_funds
    attributes {
      card {
        expires_at {
          year
          month
        }
        last_4_digits
        brand
        stripe_id
      }
    }
  }
}`;

import { Box } from '@rexlabs/box';
import { styled, StyleSheet } from '@rexlabs/styling';
import React, { PureComponent } from 'react';
import { FONT, formattedBrandName } from 'src/theme';
import { number } from 'src/utils/format';
import { Body } from 'src/view/components/text';

const defaultStyles = StyleSheet({
  container: {
    background: ({ token }) => token('legacy.color.grey.light'),

    '@media print': {
      background: 'none'
    }
  },

  wrapper: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    marginLeft: ({ token }) => token('spacing.m'),
    marginRight: ({ token }) => token('spacing.m'),
    padding: ({ token }) => token('spacing.xl'),
    paddingLeft: ({ token }) => token('spacing.xs'),

    '@media print': {
      flexDirection: 'column',
      alignItems: 'flex-start',
      padding: 0
    }
  },

  title: {
    fontWeight: FONT.WEIGHTS.BOLD,

    '@media print': {
      display: 'none'
    }
  },

  titlePrint: {
    display: 'none',

    '@media print': {
      fontSize: '2.4rem',
      fontWeight: FONT.WEIGHTS.NORMAL,
      display: 'block'
    }
  },

  description: {
    display: 'none',

    '@media print': {
      fontSize: '1.6rem',
      display: 'block',
      padding: ({ token }) => token('spacing.xs'),
      background: ({ token }) => token('legacy.color.grey.light'),
      color: ({ token }) => token('legacy.color.blue.grey')
    }
  },

  valueWrapper: {
    width: '12rem',

    '@media print': {
      order: -1
    }
  },

  value: {
    '@media print': {
      fontSize: '4.2rem',
      fontWeight: FONT.WEIGHTS.NORMAL
    }
  }
});

@styled(defaultStyles)
class StatsListHeader extends PureComponent {
  render() {
    const { title, description, value, styles: s } = this.props;
    return (
      <div {...s('container')}>
        <Box {...s('wrapper')}>
          <Box flex={1}>
            <Body {...s('title')}>{title}</Body>
            <Body {...s('titlePrint')}>
              {title.includes('conversions')
                ? `${formattedBrandName} conversions`
                : title}
            </Body>
          </Box>
          <p {...s('description')}>{description}</p>
          <Box {...s('valueWrapper')}>
            <Body style={{ fontWeight: FONT.WEIGHTS.BOLD }}>
              <span {...s('value')}>{number(value)}</span>
            </Body>
          </Box>
          <Box style={{ width: '5rem' }} />
        </Box>
      </div>
    );
  }
}

export default StatsListHeader;

import React, { PureComponent } from 'react';
import { styled, StyleSheet } from '@rexlabs/styling';

const defaultStyles = StyleSheet({
  pre: {
    padding: ({ token }) => token('spacing.m'),
    background: ({ token }) => token('legacy.color.grey.light'),
    margin: '.8rem 0',
    fontFamily: 'monospace',
    fontSize: '1.8rem',
    overflow: 'scroll'
  }
});

@styled(defaultStyles)
class Pre extends PureComponent {
  static defaultProps = {
    Tag: 'pre'
  };

  render() {
    const { Tag, styles: s, children, ...rest } = this.props;
    return (
      <Tag {...s.with('pre')(this.props)} {...rest}>
        {children}
      </Tag>
    );
  }
}

export default Pre;

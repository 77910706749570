import React from 'react';
import Box from '@rexlabs/box';
import { styled, StyleSheet } from '@rexlabs/styling';
import { COLORS } from 'src/theme';

const NATIVE_AD_WIDTH = 1000;
const NATIVE_AD_HEIGHT = NATIVE_AD_WIDTH;

const defaultStyles = StyleSheet({
  container: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: `${NATIVE_AD_WIDTH}px`,
    height: `${NATIVE_AD_HEIGHT}px`,
    background: 'none no-repeat center center',
    backgroundColor: COLORS.GREY_LIGHT,
    backgroundSize: 'cover',
    overflow: 'hidden',
    position: 'relative'
  },

  logoWrapper: {
    position: 'relative',
    width: '450px',
    height: '450px',
    padding: '20px'
  },

  logoWrapperWithAgent: {
    position: 'absolute',
    width: '250px',
    height: '250px',
    bottom: '48px',
    right: '48px'
  },

  logo: {
    width: '100%',
    height: '100%',
    objectFit: 'contain',
    objectPosition: 'center'
  }
});

function CarouselBrand({
  styles: s,
  backgroundColor,
  agentImageUrl,
  agencyLogoUrl,
  scaleTo = 1000
}) {
  return (
    <Box
      {...s.with('container')({
        transformOrigin: 'top left',
        transform: `scale(${scaleTo / NATIVE_AD_HEIGHT})`,
        backgroundColor: !agentImageUrl && backgroundColor,
        backgroundImage: agentImageUrl && `url(${agentImageUrl})`
      })}
    >
      <Box
        {...s.with('logoWrapper', { logoWrapperWithAgent: agentImageUrl })({
          background: backgroundColor
        })}
      >
        <img src={agencyLogoUrl} {...s('logo')} />
      </Box>
    </Box>
  );
}

export default styled(defaultStyles)(CarouselBrand);

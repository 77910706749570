import React, { PureComponent } from 'react';
import Box from '@rexlabs/box';
import { styled, StyleSheet } from '@rexlabs/styling';
import Container from '../shared/container';
import { get } from 'lodash';
import { COLORS } from 'src/theme';
import AdImage, { NATIVE_AD_RATIO } from './ad-image';

const defaultStyles = StyleSheet({
  container: {
    width: '100%',
    position: 'relative',
    overflow: 'hidden',
    fontFamily:
      'system-ui, -apple-system, BlinkMacSystemFont, ".SFNSText-Regular", sans-serif',
    color: '#1d2129',
    fontWeight: 'normal'
  },

  split: {
    width: '50%',
    color: 'white'
  },

  inner: {
    width: '100%',
    height: '100%',
    padding: '1.2rem'
  },

  agentPosition: {
    fontSize: '1.2rem'
  },

  image: {
    height: '25rem',
    width: '100%',
    position: 'relative',
    overflow: 'hidden',
    background: 'none center center no-repeat',
    backgroundColor: COLORS.GREY_LIGHT,
    backgroundSize: 'cover'
  },

  agencyLogo: {
    width: '16rem',
    height: '6rem',
    background: 'transparent top left no-repeat',
    backgroundSize: 'contain'
  },

  wrapText: {
    width: '100%',
    background: '#f6f7f9',
    borderBottom: '.1rem solid #d8d8d8',
    borderTop: '0 none',
    padding: '1rem 1.2rem',
    whiteSpace: 'normal'
  },

  wrapTextDetails: {
    maxWidth: '80%',
    maxHeight: '66px',
    overflow: 'hidden',
    marginRight: '1.2rem'
  },

  title: {
    fontSize: '1.6rem',
    lineHeight: '2rem',
    maxHeight: '11rem',
    wordWrap: 'break-word',
    fontWeight: 600,
    marginBottom: '.5rem',
    padding: 0,
    fontFamily:
      'system-ui, -apple-system, BlinkMacSystemFont, ".SFNSText-Regular", sans-serif'
  },

  headline: {
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    fontSize: '1.2rem',
    lineHeight: '1.8rem',
    maxHeight: '8rem',
    padding: 0
  },

  website: {
    color: '#606770',
    fontSize: '1.2rem',
    lineHeight: '1.1rem',
    textTransform: 'uppercase',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap'
  },

  learnMore: {
    border: '.1rem solid #ced0d4',
    background: '#f6f7f9',
    color: '#4b4f56',
    borderRadius: '.2rem',
    padding: '0 .8rem',
    fontSize: '1.2rem',
    lineHeight: '2.2rem',
    marginTop: '.2rem',
    whiteSpace: 'nowrap',
    textShadow: 'none',
    verticalAlign: 'middle',
    fontWeight: 'bold',
    boxSizing: 'content-box'
  }
});

@styled(defaultStyles)
class FacebookSplit extends PureComponent {
  static defaultProps = {
    disableWrapper: false
  };

  constructor(props) {
    super(props);
    this.adContainer = React.createRef();
    this.state = {
      adWrapperDimensions: { width: 0, height: 0 }
    };
  }

  componentDidMount() {
    const width = get(this.adContainer, 'current.parentElement.offsetWidth');
    if (!width) return;
    this.setState({
      adWrapperDimensions: { width, height: width / NATIVE_AD_RATIO }
    });
  }

  render() {
    const { styles: s, adContentSet, disableWrapper } = this.props;
    const { adWrapperDimensions } = this.state;

    const website = adContentSet.website
      ? new URL(adContentSet.website).hostname.replace('www.', '')
      : null;

    if (disableWrapper) {
      return <AdImage adContentSet={adContentSet} />;
    }

    return (
      <Container adContentSet={adContentSet} descriptionLimit={150}>
        <Box {...s('container')}>
          <div
            ref={this.adContainer}
            style={{ margin: 'auto', ...adWrapperDimensions }}
          >
            <AdImage
              adContentSet={adContentSet}
              scaleTo={adWrapperDimensions.width}
            />
          </div>
          <Box
            flexDirection='row'
            justifyContent='space-between'
            {...s('wrapText')}
          >
            <Box {...s('wrapTextDetails')}>
              <span {...s('website')}>{website}</span>
              <h1 {...s('title')}>{adContentSet.title}</h1>
              <span {...s('headline')}>{adContentSet.headline}</span>
            </Box>
            <Box alignItems='center'>
              <button {...s('learnMore')}>Learn More</button>
            </Box>
          </Box>
        </Box>
      </Container>
    );
  }
}

export default FacebookSplit;

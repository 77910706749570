import { margin, StyleSheet, useStyles } from '@rexlabs/styling';
import React, { FC } from 'react';
import { steps } from '../utils/steps';
import { WizardStepItem } from './WizardStepItem';

const styles = StyleSheet({
  container: {
    display: 'flex',
    flex: '1'
  },

  content: {
    display: 'flex',
    flex: '1 1 0%',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',

    '& > * + *': {
      ...margin.styles({
        left: 'xl'
      })
    }
  }
});

export type WizardStepListProps = {
  currentStep: string;
};

export const WizardStepList: FC<WizardStepListProps> = ({ currentStep }) => {
  const s = useStyles(styles);

  return (
    <div {...s('container')}>
      <div {...s('content')}>
        <WizardStepItem number={1} active={currentStep === 'provide-details'}>
          Provide details
        </WizardStepItem>
        {steps.map(({ id, name }, i) => (
          <WizardStepItem number={i + 2} key={id} active={currentStep === id}>
            {name}
          </WizardStepItem>
        ))}
      </div>
    </div>
  );
};

/* eslint-disable max-lines */
import { superstructResolver } from '@hookform/resolvers/superstruct';
import { Box } from '@rexlabs/box';
import { GhostButton, GhostIconButton, PrimaryButton } from '@rexlabs/button';
import { CheckboxGroup } from '@rexlabs/checkbox';
import Icons from '@rexlabs/icons-next';
import { StyleSheet, margin, useStyles } from '@rexlabs/styling';
import { Body, Heading, Tiny } from '@rexlabs/text';
import { NumberInput, TextInput } from '@rexlabs/text-input';
import React, {
  forwardRef,
  useCallback,
  useEffect,
  useImperativeHandle
} from 'react';
import {
  Controller,
  DeepPartial,
  SubmitHandler,
  useFieldArray,
  useForm
} from 'react-hook-form';
import { ButtonGroup, Spacer } from 'src/components/elements/button-group';
import { LocationSelect } from 'src/components/elements/location-select';
import {
  FormControl,
  FormErrorMessage,
  FormHelperText,
  FormInput,
  FormLabel
} from 'src/components/form';
import config from 'src/config';
import { Units } from 'src/types';
import { ariaAttr } from 'src/utils/dom';
import { isUK } from 'src/utils/region';
import {
  Address,
  Audience,
  AudienceDTO,
  AudienceDefinition,
  AudienceForm,
  Network
} from '../types';
import { retargettingAudienceDefinitions } from '../utils/audienceGroups';
import {
  audienceDefToCheckbox,
  getAudienceDTOFromFormValues,
  getAudienceFromFormValues,
  getFormValuesFromAudience
} from '../utils/form';
import { audienceFormSchema } from '../utils/schemas';
import { CustomAudienceSelector } from './CustomAudienceSelector';
import { NetworkSelector } from './NetworkSelection';

const DEFAULT_RADIUS_UNIT: Units = isUK ? 'miles' : 'kilometers';

const styles = StyleSheet({
  form: {
    display: 'flex',
    flexDirection: 'column',

    '& > * + *': {
      ...margin.styles({
        top: 'm'
      })
    }
  },

  mainLocation: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'start',

    '& > * + *': {
      ...margin.styles({
        left: 'xs'
      })
    }
  },

  address: {
    flex: '1'
  },

  radius: {
    maxWidth: '135px'
  },

  ageRange: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',

    '& > * + *': {
      ...margin.styles({
        left: 'xs'
      })
    },

    '& > *': {
      maxWidth: '6rem'
    }
  },

  suburbs: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'stretch',

    '& > * + *': {
      ...margin.styles({
        top: 'xs'
      })
    }
  },

  suburbItem: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',

    '& > * + *': {
      ...margin.styles({
        left: 'xxs'
      })
    }
  },

  audiences: {
    '> :first-child' : {
      flexDirection: 'row !important',
      flexWrap: 'wrap'
    },
  },

  checkboxGroup: {
    width: '45%',
    display: 'flex',
    justifyContent: 'center'
  },

  userLists: {
    maxHeight: '30rem',
    overflowY: 'scroll',
    ...margin.styles({
      top: 's'
    })
  },

  footer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-end',

    '& > * + *': {
      ...margin.styles({
        left: 's'
      })
    }
  },

  errorMessage: {
    color: ({ token }) => token('color.textStyle.danger.idle.default'),
    fontSize: 12
  }
});

export type AudienceEditorFormChangeHandler = (
  value: DeepPartial<Audience>
) => void;

export type AudienceEditorFormSubmitHandler = SubmitHandler<
  Partial<AudienceDTO>
>;

export type AudienceEditorFormProps = {
  audience?: Audience;
  generalAudienceDefinition: AudienceDefinition[];
  isUpdating?: boolean;
  onSubmit: AudienceEditorFormSubmitHandler;
  onCancel: () => void;
  onChange?: AudienceEditorFormChangeHandler;
  networks: Network[];
  onDirty?: (x: boolean) => void;
};
export type HandleReset = {
  handleReset: () => void;
};
export const AudienceEditorForm = forwardRef<
  HandleReset,
  AudienceEditorFormProps
>(
  (
    {
      audience,
      generalAudienceDefinition,
      isUpdating = false,
      onSubmit,
      onCancel,
      onChange,
      onDirty,
      networks
    },
    ref
  ) => {
    if (generalAudienceDefinition.length < 1)
      throw new Error(
        ' general audience definition must contain at least one audience option'
      );

    const s = useStyles(styles, 'AudienceEditor');
    const audienceForm = audience
      ? getFormValuesFromAudience(audience)
      : undefined;
    const {
      control,
      handleSubmit,
      watch,
      reset,
      formState: { isValid, isSubmitting, errors, isDirty }
    } = useForm<AudienceForm>({
      mode: 'onChange',
      resolver: superstructResolver(audienceFormSchema),
      defaultValues: audienceForm
    });
    const { fields, append, remove } = useFieldArray({
      control,
      name: 'additionalSuburbs'
    });

    const addAdditionalSuburb = useCallback(
      (suburb: Address | undefined) => {
        if (suburb) append({ type: 'suburb', suburb });
      },
      [append]
    );
    const transformAudience = useCallback<SubmitHandler<AudienceForm>>(
      (v, e) => {
        if (e?.target.id === 'campaign-audience-editor') {
          onSubmit(getAudienceDTOFromFormValues(v));
        }
      },
      [onSubmit]
    );

    // this allows resetting the form state from a parent component
    useImperativeHandle(ref, () => ({
      handleReset() {
        reset({}, { keepValues: true });
      }
    }));

    useEffect(() => {
      const subscription = watch((value) => {
        onChange?.(getAudienceFromFormValues(value));
      });
      return () => subscription.unsubscribe();
    }, [onChange, watch]);

    useEffect(() => {
      onDirty?.(isDirty);
    }, [isDirty, onDirty]);

    return (
      <form
        id='campaign-audience-editor'
        {...s('form')}
        onSubmit={handleSubmit(transformAudience)}
      >
        <FormControl isRequired>
          <FormLabel>Audience Location</FormLabel>
          <div {...s('mainLocation')}>
            <div {...s('address')}>
              <Tiny grey>Address</Tiny>
              <Controller
                control={control}
                name='mainLocation.address'
                render={({ field }) => (
                  // @ts-expect-error Prop inference is currently broken
                  <FormInput Input={LocationSelect} {...field} type='address' />
                )}
              />
            </div>
            <div {...s('radius')} data-testid={'radius-input-container'}>
              <Tiny grey>Radius</Tiny>
              <Controller
                control={control}
                name='mainLocation.radius.value'
                render={({
                  field: { onChange: onFieldChange, ...field },
                  fieldState: { error }
                }) => (
                  <Box flex={1} flexDirection='column'>
                    <NumberInput
                      {...field}
                      onChange={(e) =>
                        onFieldChange(
                          e.target.value ? parseInt(e.target.value) : ''
                        )
                      }
                      meta={{
                        error: error?.message ?? null
                      }}
                      style={{ width: '2.5rem' }}
                      step={1}
                      Suffix={() => (
                        <Tiny grey>
                          {audienceForm?.mainLocation?.radius?.unit ??
                            DEFAULT_RADIUS_UNIT}
                        </Tiny>
                      )}
                    />
                    {error && (
                      <Body as='span' {...s('errorMessage')}>
                        {error.type === 'number'
                          ? `Select between ${config.MIN_AUDIENCE_RADIUS} - 80`
                          : ''}
                      </Body>
                    )}
                  </Box>
                )}
              />
            </div>
          </div>
        </FormControl>

        <FormControl>
          <FormLabel>Additional suburbs</FormLabel>
          <LocationSelect
            type='suburb'
            clearOnSelection
            onChange={addAdditionalSuburb}
          />
          <FormHelperText>
            Add other suburbs to display your ads to a wider audience
          </FormHelperText>
          <div {...s('suburbs')}>
            {fields.map(({ id, suburb }, i) => (
              <div {...s('suburbItem')} key={id}>
                <TextInput value={suburb.full_address} readOnly />
                <GhostIconButton
                  Icon={Icons.CrossSmall}
                  onClick={() => remove(i)}
                />
              </div>
            ))}
          </div>
        </FormControl>

        <FormControl data-testid={'age-range-fields'}>
          <FormLabel>Age range</FormLabel>
          <div {...s('ageRange')}>
            <Controller
              control={control}
              name='ageRange.youngest'
              render={({
                field: { onChange: onFieldChange, ...field },
                fieldState: { error }
              }) => (
                <NumberInput
                  step={1}
                  {...field}
                  meta={{
                    error: error?.message ?? null
                  }}
                  onChange={(e) =>
                    onFieldChange(
                      e.target.value ? parseInt(e.target.value) : ''
                    )
                  }
                />
              )}
            />
            <span {...s('ageRangeSep')}>-</span>
            <Controller
              control={control}
              name='ageRange.oldest'
              render={({
                field: { onChange: onFieldChange, ...field },
                fieldState: { error }
              }) => (
                <NumberInput
                  step={1}
                  {...field}
                  meta={{
                    error: error?.message ?? null
                  }}
                  onChange={(e) =>
                    onFieldChange(
                      e.target.value ? parseInt(e.target.value) : ''
                    )
                  }
                />
              )}
            />
          </div>
          {errors.ageRange && (
            <Body as='span' {...s('errorMessage')}>
              {errors.ageRange.oldest?.message ??
                errors.ageRange.youngest?.message}
            </Body>
          )}
        </FormControl>

        <Controller
          control={control}
          name='generalAudiences'
          render={({ field, fieldState: { error } }) => (
            <FormControl isInvalid={Boolean(error)}>
              <FormLabel>
                <Heading level={2}>General Audiences</Heading>
              </FormLabel>
              <div {...s('audiences')}>
                <CheckboxGroup
                  {...s('checkboxGroup')}
                  options={generalAudienceDefinition.map(audienceDefToCheckbox)}
                  {...field}
                />
              </div>
              <FormErrorMessage>
                Please select at least one audience group
              </FormErrorMessage>
            </FormControl>
          )}
        />

        <Controller
          control={control}
          name='retargettingAudiences'
          render={({ field, fieldState: { error } }) => (
            <FormControl isInvalid={Boolean(error)}>
              <FormLabel>
                <Heading level={2}>Your retargeting audiences</Heading>
              </FormLabel>
              <div {...s('audiences')}>
                <CheckboxGroup
                  options={retargettingAudienceDefinitions.map(
                    audienceDefToCheckbox
                  )}
                  {...field}
                />
              </div>
            </FormControl>
          )}
        />

        <FormControl isReadOnly={isUpdating}>
          <FormLabel>
            <Heading level={2}>Your database audiences</Heading>
          </FormLabel>
          <Controller
            control={control}
            name='userLists'
            render={({ field }) => (
              <CustomAudienceSelector {...s('userLists')} {...field} />
            )}
          />
          <FormHelperText>
            Show ads on Facebook and Instagram directly to contacts in your CRM
            database who are potential buyers, sellers or landlords
          </FormHelperText>
        </FormControl>

        {networks.length === 0 ? null : (
          <Controller
            control={control}
            name='networks'
            render={({ field, fieldState: { error } }) => (
              <FormControl
                isInvalid={error !== undefined}
                isReadOnly={isUpdating}
              >
                <FormLabel>
                  <Heading level={2}>Networks</Heading>
                </FormLabel>
                <NetworkSelector
                  {...field}
                  isReadOnly={ariaAttr(isUpdating)}
                  networks={networks}
                />
                <FormErrorMessage>
                  Please select at least one network
                </FormErrorMessage>
              </FormControl>
            )}
          />
        )}

        <ButtonGroup spacing='s'>
          <Spacer />
          <GhostButton onClick={onCancel}>Cancel</GhostButton>
          <PrimaryButton
            isDisabled={!isValid}
            isLoading={isSubmitting}
            type='submit'
            form='campaign-audience-editor'
          >
            Save
          </PrimaryButton>
        </ButtonGroup>
      </form>
    );
  }
);

import React from 'react';
import PropTypes from 'prop-types';
import { styled, StyleSheet } from '@rexlabs/styling';
import Box from '@rexlabs/box';
import _ from 'lodash';
import colorTheme from '../../../utils/color-theme';
import { SmallButton } from '../../button';
import ScaledText from '../../scaled-text';
import charLimit, { limits } from '../../../utils/char-limit';
import { COLORS } from 'src/theme';

const fontFamily = 'Lato, sans-serif';

const defaultStyles = StyleSheet({
  tripleWidescreen: {
    border: '1px solid darkgray',
    overflow: 'hidden'
  },
  primaryImage: {
    height: '146px',
    width: '100%',
    objectFit: 'cover'
  },
  body: {
    backgroundColor: 'white'
  },
  smallText: {
    fontSize: '12px',
    fontWeight: 600,
    lineHeight: '15px',
    fontFamily,
    marginBottom: '3px',
    textTransform: 'uppercase'
  },
  shortHeadline: {
    fontSize: '24px',
    fontWeight: 300,
    lineHeight: '30px',
    fontFamily,
    marginBottom: '12px'
  },
  footer: {
    '@media print': {
      '-webkit-print-color-adjust': 'exact'
    }
  },
  footerLogo: {
    maxWidth: 'calc(0.6 * 250px)',
    maxHeight: 'calc(0.7 * 60px)',
    objectFit: 'contain'
  }
});

TripleWidescreen.propTypes = {
  shortHeadline: PropTypes.string,
  bannerAdText: PropTypes.string,
  buttonText: PropTypes.string,
  primaryImageUrls: PropTypes.object,
  logoUrls: PropTypes.object,
  brandColor: PropTypes.string,
  backgroundColor: PropTypes.string
};

function TripleWidescreen({
  buttonText = 'View Property',
  shortHeadline,
  smallText,
  primaryImageUrls,
  logoUrls,
  brandColor,
  backgroundColor,
  styles: s
}) {
  const { brandColorBorder } = colorTheme(brandColor);

  return (
    <Box
      width={250}
      height={360}
      flexDirection='column'
      {...s('tripleWidescreen')}
    >
      <img
        src={_.get(primaryImageUrls, ['crop.square', 'url'])}
        {...s('primaryImage')}
      />
      <Box
        flexDirection='column'
        width='100%'
        height={154}
        padding='15px 20px'
        justifyContent='center'
        {...s('body')}
      >
        <span {...s.with('smallText')({ color: COLORS.BLACK })}>
          {charLimit(smallText, limits.SMALL_TEXT)}
        </span>
        <ScaledText
          maxWidth={200}
          maxTextLines={3}
          maxFontSize={32}
          minFontSize={16}
          {...s.with('shortHeadline')({ color: COLORS.BLACK })}
        >
          {shortHeadline}
        </ScaledText>
        <Box>
          <SmallButton brandColor={brandColor}>{buttonText}</SmallButton>
        </Box>
      </Box>
      <Box
        height={60}
        flexDirection='column'
        alignItems='center'
        justifyContent='center'
        padding='5px 40px'
        {...s.with('footer')({
          backgroundColor: backgroundColor,
          borderTop: brandColorBorder
        })}
      >
        <img src={_.get(logoUrls, 'original.url')} {...s('footerLogo')} />
      </Box>
    </Box>
  );
}

export default styled(defaultStyles)(TripleWidescreen);

import { NormalisedItem } from '@rexlabs/select';
import {
  differenceInMonths,
  differenceInWeeks,
  formatDuration,
  intlFormat
} from 'date-fns';
import { capitalize } from 'lodash';
import { Strategy, StrategyStatus } from '../types';

export function strategyStatusLabel(status: StrategyStatus): string {
  switch (status) {
    case 'finished':
      return 'Just Finished';
    default:
      return capitalize(status);
  }
}

export function formatStrategyDate(
  date: Date,
  weekday: 'short' | 'narrow' | 'long' = 'short',
  month: 'short' | 'narrow' | 'long' = 'short'
): string {
  return intlFormat(date, {
    weekday,
    month,
    day: 'numeric'
  });
}

export function normaliseDatePercentage(
  startDate: Date,
  finishDate: Date
): string {
  const currentDate = new Date();
  const duration = finishDate.getTime() - startDate.getTime();
  const elapsed = currentDate.getTime() - startDate.getTime();
  const percentage = (elapsed / duration) * 100;
  let normalisedPct = percentage.toFixed(0);

  if (percentage < 0) {
    normalisedPct = '0';
  } else if (percentage > 100) {
    normalisedPct = '100';
  }

  return normalisedPct;
}

export function formatStrategyDurationStatus(
  startDate: Date,
  finishDate: Date
): string {
  const monthsTotal = differenceInMonths(finishDate, startDate);
  const weeksTotal = differenceInWeeks(finishDate, startDate);

  /*
    Formatting rules:
    - If campaign duration is 3 or more months display months & weeks
    - If campaign duration is less than 3 months only display weeks
   */
  const monthsDuration = monthsTotal >= 3 ? monthsTotal : 0;
  const weeksDuration = monthsTotal >= 3 ? weeksTotal % 4 : weeksTotal;

  return formatDuration(
    { months: monthsDuration, weeks: weeksDuration },
    {
      format: ['months', 'weeks']
    }
  );
}

export function getCampaignDuration(startDate: Date, finishDate: Date): string {
  const displayDuration = formatStrategyDurationStatus(startDate, finishDate);
  const displayPct = normaliseDatePercentage(startDate, finishDate);

  return `${displayDuration} (${displayPct}% complete)`;
}

export function strategyItemNormaliser(item: Strategy): NormalisedItem {
  return {
    id: item.id,
    label: item.name
  };
}

import React from 'react';
import { compose } from 'redux';

import { Box } from '@rexlabs/box';
import { GhostButton, PrimaryButton } from '@rexlabs/button';
import { query, withQuery } from '@rexlabs/model-generator';
import { useToken } from '@rexlabs/styling';

import campaignsModel from 'src/data/models/entities/campaigns';
import { createValidationRules } from 'src/utils/form';

import { ModalStickyButtonGroup } from 'src/view/components/button';
import { Form, FormField, ReactForms } from 'src/view/components/form';
import { TextInput } from 'src/view/components/input';
import { RenderLoading } from 'src/view/components/loading';
import { Modal } from 'src/view/components/modal';
import withError from 'src/view/containers/with-error';

const campaignQuery = query`{
    ${campaignsModel} (id: ${(p) => p?.campaignId}) {
      id
      name
    }
  }
`;

const validate = createValidationRules({
  name: ['required|max:250', 'campaign name']
});

function EditCampaignName({ campaignName, closeModal, campaigns, error }) {
  const { Error } = error;
  const token = useToken();
  const initialValues = {
    name: campaignName
  };

  async function handleSubmit({ name }) {
    try {
      await campaigns.updateItem({
        data: {
          name
        }
      });

      closeModal();
    } catch (e) {
      error.open(error);
    }
  }

  return (
    <Modal
      title='Edit Campaign Name'
      subtitle={campaignName}
      onClose={closeModal}
    >
      <RenderLoading isLoading={campaigns.item.status === 'loading'}>
        <ReactForms
          initialValues={initialValues}
          handleSubmit={handleSubmit}
          validate={validate}
        >
          {({ submitForm, isSubmitting }) => (
            <Form name='editCampaignNameForm'>
              <FormField
                name='name'
                label='Campaign name'
                sendImmediate={true}
                Input={TextInput}
                inputProps={{
                  placeholder: 'E.g. 123 Listing st - For Sale'
                }}
              />

              <Box mt={token('spacing.xxl')}>
                <ModalStickyButtonGroup>
                  <GhostButton onClick={closeModal}>Cancel</GhostButton>
                  <PrimaryButton onClick={submitForm} isLoading={isSubmitting}>
                    Save
                  </PrimaryButton>
                </ModalStickyButtonGroup>
              </Box>
            </Form>
          )}
        </ReactForms>
      </RenderLoading>
      <Error />
    </Modal>
  );
}

export const EditCampaignNameModal = compose(
  withQuery(campaignQuery),
  withError()
)(EditCampaignName);

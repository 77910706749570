import { Box } from '@rexlabs/box';
import { styled, StyleSheet } from '@rexlabs/styling';
import { autobind } from 'core-decorators';
import _ from 'lodash';
import React, { PureComponent } from 'react';
import { BORDER_RADIUS, withToken } from 'src/theme';
import { DeleteButton } from 'src/view/components/button';
import {
  Caption,
  ImageOverlay,
  ProgressIndicatorOverlay,
  SelectedImageOverlay,
  WashedImageOverlay
} from 'src/view/components/image';
import CropImageModal from 'src/view/modals/crop-image';

const defaultStyles = StyleSheet({
  imageContainer: {
    position: 'relative',
    borderRadius: BORDER_RADIUS.INPUT,
    border: '0.1rem solid #8aa0ae',
    boxSizing: 'border-box',
    width: '100%',
    height: '100%',
    zIndex: 0
  },

  wrapImage: {
    position: 'relative',
    width: '100%',
    height: '100%',
    border: '0 solid transparent',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
  },

  image: {
    position: 'absolute',
    objectFit: 'contain',
    zIndex: 0
  },

  circleCropIndicator: {
    position: 'absolute',
    width: '100%',
    height: '100%',
    zIndex: 1,
    borderRadius: '100%',
    border: `1px solid white`,
    outline: 'dashed 1px',
    outlineOffset: '-1px',
    boxSizing: 'border-box'
  },

  selectable: {
    cursor: 'pointer'
  },

  inCircle: {
    borderRadius: '100%'
  }
});

@withToken
@styled(defaultStyles)
@autobind
class ScalableImage extends PureComponent {
  static defaultProps = {
    onClick: _.noop,
    removeFiles: _.noop,
    onCropClick: _.noop,
    canRemove: false,
    canCrop: false,
    selectable: false,
    selected: false,
    inCircle: false
  };

  state = {
    cropOpen: false
  };

  handleClick() {
    const { image, onClick } = this.props;
    onClick(image);
  }

  handleRemoveClick(e) {
    const { fileIndex, image, removeFiles, removeImages } = this.props;
    e.stopPropagation();
    removeFiles(fileIndex);
    removeImages([image]);
  }

  handleCropClick(e) {
    const { onCropClick } = this.props;
    e.stopPropagation();
    this.setState({ cropOpen: true });
    onCropClick();
  }

  handleCloseCrop() {
    this.setState({ cropOpen: false });
  }

  handleCropImage(crops) {
    const { image, onCropImage } = this.props;
    onCropImage(image, crops);
  }

  render() {
    const { cropOpen } = this.state;
    const {
      styles: s,
      src,
      image,
      captionText,
      TooltipContent,
      isSquare,
      selectable,
      selected,
      maxSelected,
      overlayText,
      campaignId,
      agentId,
      listingId,
      canRemove,
      canCrop,
      token,
      backgroundColor = token('legacy.color.grey.light'),
      scale
    } = this.props;

    if (__DEV__) {
      // NOTE: this is left here to help identify a performance issue with image rendering
      console.warn(
        '`onImageLoaded` has been removed from the image component, since we are now using background images'
      );
    }

    const isUploading = image?.isUploading;
    const isWashed = selectable && maxSelected && !selected;
    const hasOverlay = overlayText || canRemove || canCrop;

    const imgSrc = image?.render || image.src || src;

    return (
      <Box
        flexDirection='column'
        justifyContent='space-between'
        w={'100%'}
        height={'100%'}
        data-testid={'scalable-image-input-container'}
      >
        <Box
          {...s.with('imageContainer', { selectable, isSquare })({
            backgroundColor
          })}
          alignItems='center'
          justifyContent='center'
          onClick={this.handleClick}
        >
          <div {...s('circleCropIndicator')} />
          <div {...s('wrapImage')}>
            <img
              src={imgSrc}
              {...s.with('image')({
                width: `${scale}%`
              })}
            />
          </div>
          {hasOverlay && (
            <ImageOverlay
              text={overlayText}
              canRemove={canRemove}
              canCrop={canCrop}
              onRemoveClick={this.handleRemoveClick}
              onCropClick={this.handleCropClick}
            />
          )}
          {isWashed && !isUploading && <WashedImageOverlay />}
          {selectable && selected && !isUploading && (
            <SelectedImageOverlay
              canCrop={canCrop}
              onCropClick={this.handleCropClick}
            />
          )}
          {isUploading && (
            <DeleteButton
              onClick={(e) => {
                image.file.uploadCancelled = true;
                this.handleRemoveClick(e);
              }}
            />
          )}
          {isUploading && <ProgressIndicatorOverlay />}
        </Box>
        {captionText && (
          <Caption captionText={captionText} TooltipContent={TooltipContent} />
        )}
        {cropOpen && (
          <CropImageModal
            image={image}
            isSquare={isSquare}
            campaignId={campaignId}
            agentId={agentId}
            listingId={listingId}
            closeModal={this.handleCloseCrop}
            onCropImage={this.handleCropImage}
          />
        )}
      </Box>
    );
  }
}

export default ScalableImage;

import { withModel } from '@rexlabs/model-generator/lib/connectors';
import React from 'react';
import session from 'src/data/models/custom/session';
import { currency } from 'src/utils/format';

export const currencyCodes = {
  AU: 'AUD',
  NZ: 'NZD',
  GB: 'GBP',
  US: 'USD'
};

function Money({ amount = 0, currencyCode, integer, session, Tag = 'span' }) {
  const agency = session.accounts.find((a) => a.id === session.currentAccountId)
    ?.agencies?.data?.[0];

  const countryCode = agency
    ? agency.address.country.id
    : Object.keys(currencyCodes).find((k) => currencyCodes[k] === currencyCode);

  const locale = `en-${countryCode}`;
  const money = currency(amount, integer, locale, currencyCode);

  return <Tag>{Number.isNaN(amount) ? '-' : money}</Tag>;
}

export default withModel(session)(Money);

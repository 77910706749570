import * as DialogPrimitive from '@radix-ui/react-dialog';
import { GhostButton, PrimaryButton } from '@rexlabs/button';
import { StyleSheet, margin, useStyles } from '@rexlabs/styling';
import { Heading } from '@rexlabs/text';
import React, {
  CSSProperties,
  ComponentType,
  Fragment,
  MouseEventHandler,
  PropsWithChildren,
  ReactElement,
  ReactNode,
  Ref,
  cloneElement,
  forwardRef
} from 'react';
import { ModalContent } from './ModalContent';
import { ModalTitle } from './ModalTitle';

const styles = StyleSheet({
  actions: {
    ...margin.styles({
      top: 's'
    }),

    '& > * + *': {
      ...margin.styles({
        left: 'xs'
      })
    }
  }
});

export type ActionType = 'primary' | 'secondary';

const actionTypeComponents: Record<ActionType, ComponentType<any>> = {
  primary: PrimaryButton,
  secondary: GhostButton
};

export type ModalAction = {
  label: string;
  Component?: ActionType | ReactElement | ComponentType<any>;
  onClick?: MouseEventHandler;
  closes?: boolean;
};

export type MegaModalContentProps = {
  title: ReactNode;
  description?: ReactNode;
  actions?: ModalAction[];
  children?: ReactNode;
  className?: string;
  style?: CSSProperties;
};

const ElementComponent = (Component: ReactElement) =>
  forwardRef(({ children, ...props }: PropsWithChildren<any>, ref) =>
    cloneElement(
      Component,
      {
        ...props,
        ref
      },
      children
    )
  );

export const MegaModalContent = forwardRef(
  (
    {
      children,
      title,
      description,
      actions,
      className,
      style
    }: MegaModalContentProps,
    ref: Ref<HTMLDivElement>
  ) => {
    const s = useStyles(styles);
    return (
      <ModalContent ref={ref} className={className} style={style}>
        <ModalTitle asChild>
          <Heading level={2}>{title}</Heading>
        </ModalTitle>
        {description && (
          <DialogPrimitive.Description>
            {description}
          </DialogPrimitive.Description>
        )}
        {children}
        {actions && (
          <div {...s('actions')}>
            {actions.map(
              (
                { label, onClick, Component = 'secondary', closes = true },
                i
              ) => {
                const Action: ComponentType<any> =
                  typeof Component === 'string'
                    ? actionTypeComponents[Component]
                    : 'type' in Component
                    ? ElementComponent(Component)
                    : Component;
                const Wrapper = closes
                  ? (props) => <DialogPrimitive.Close asChild {...props} />
                  : (props) => <Fragment {...props} />;
                return (
                  <Wrapper key={i}>
                    <Action {...(onClick ? { onClick } : {})}>{label}</Action>
                  </Wrapper>
                );
              }
            )}
          </div>
        )}
      </ModalContent>
    );
  }
);

import { campaignKeys } from 'src/features/campaigns';

export const campaignAdsKeys = {
  all: (accountId: string, campaignId: string) =>
    [...campaignKeys.detail(accountId, campaignId), 'ad-content-sets'] as const,
  lists: (accountId: string, campaignId: string) =>
    [...campaignAdsKeys.all(accountId, campaignId), 'list'] as const,
  list: (accountId: string, campaignId: string, params: unknown) =>
    [...campaignAdsKeys.lists(accountId, campaignId), params] as const,
  details: (accountId: string, campaignId: string) =>
    [...campaignAdsKeys.all(accountId, campaignId), 'detail'] as const,
  detail: (accountId: string, campaignId: string, adContentSetId: string) =>
    [...campaignAdsKeys.details(accountId, campaignId), adContentSetId] as const
};

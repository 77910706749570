import { Box } from '@rexlabs/box';
import { RenderMatchedRoutes } from '@rexlabs/react-whereabouts';
import { styled, StyleSheet } from '@rexlabs/styling';
import React from 'react';
import { Logo } from 'src/components/elements/logo';
import ROUTES from 'src/routes/select-agency';
import NotFoundScreen from 'src/view/screens/not-found';

const defaultStyles = StyleSheet({
  container: {
    position: 'absolute',
    width: '100%',
    height: '100%',
    top: 0,
    left: 0,
    overflow: 'auto'
  },

  logo: {
    color: ({ token }) => token('legacy.color.primary.default'),
    height: '4.8rem',
    marginTop: '10rem',
    marginBottom: ({ token }) => token('spacing.xxl')
  }
});

function SelectAgencyLayout({ styles: s }) {
  return (
    <Box
      {...s('container')}
      flexDirection='column'
      alignItems='center'
      justifyContent='flex-start'
    >
      <Logo type='rex_master' {...s('logo')} />
      <Box flexDirection='column' flex='1'>
        <RenderMatchedRoutes routes={ROUTES} NotFound={NotFoundScreen}>
          {(matches) => <Box h='100%'>{matches}</Box>}
        </RenderMatchedRoutes>
      </Box>
    </Box>
  );
}

export default styled(defaultStyles)(SelectAgencyLayout);

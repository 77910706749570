import React, { PureComponent } from 'react';
import InlineRectangle, { types } from './inline-rectangle';
import { StyleSheet } from '@rexlabs/styling';

const inlineRectangleStyles = StyleSheet({
  shortHeadline: {
    fontSize: '20px',
    lineHeight: '24px',
    flexGrow: 0,
    flexShrink: 0
  },
  footer: {
    justifyContent: 'center',
    padding: '6px 0px'
  },
  footerButton: {
    display: 'none'
  }
});

export default class SmallSquare extends PureComponent {
  static propTypes = InlineRectangle.propTypes;
  render() {
    return (
      <InlineRectangle
        width={200}
        height={200}
        imageHeight={150}
        textWidth={170}
        maxFontSize={22}
        minFontSize={12}
        type={types.SMALL}
        styles={inlineRectangleStyles}
        {...this.props}
      />
    );
  }
}

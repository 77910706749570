import { query } from '@rexlabs/model-generator';
import rexModel from 'src/data/models/entities/rex';

const rexQuery = query`{
  ${rexModel} (id: "fake") {
    id
    attributes {
      account_id_to_sync
      email
      error
      locations
      ready
      lead_preferences
    }
    accounts
    locations
  }
}`;

export { rexQuery };

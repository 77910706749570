import { superstructResolver } from '@hookform/resolvers/superstruct';
import { Box } from '@rexlabs/box';
import { GhostButton, PrimaryButton } from '@rexlabs/button';
import { StyleSheet, margin, useStyles } from '@rexlabs/styling';
import { Body } from '@rexlabs/text';
import { TextInput } from '@rexlabs/text-input';
import React, { FC, ReactNode, useEffect } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { ButtonGroup, Spacer } from 'src/components/elements/button-group';
import {
  FormControl,
  FormErrorMessage,
  FormInput,
  FormLabel
} from 'src/components/form';
import { AgentSelectorInput } from 'src/features/agents/components/AgentSelectorInput';
import { TestimonialDTO } from 'src/features/testimonials/types';
import { editableTestimonialSchema } from 'src/features/testimonials/utils/schemas';
import { TextArea } from 'src/view/components/input';
import { HelpIcon } from 'src/view/components/tooltip';

const styles = StyleSheet({
  form: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    width: '100%',
    '& > * + *': {
      ...margin.styles({
        top: 's'
      })
    }
  },
  controls: {
    display: 'flex',
    flexDirection: 'column',
    '& > * + *': {
      ...margin.styles({
        top: 's'
      })
    }
  }
});

export type TestimonialSubmitHandler = (data: TestimonialDTO) => Promise<void>;

export type TestimonialFormProps = Omit<
  JSX.IntrinsicElements['form'],
  'onSubmit' | 'resource'
> & {
  resource?: TestimonialDTO;
  onSubmit: TestimonialSubmitHandler;
  onCancel: () => void;
  submitLabel?: ReactNode;
};

export const TestimonialForm: FC<TestimonialFormProps> = ({
  resource,
  onSubmit,
  onCancel,
  submitLabel = 'Save',
  className,
  style,
  ...rest
}) => {
  const s = useStyles(styles);

  const {
    control,
    handleSubmit,
    formState: { isValid, isSubmitting },
    reset
  } = useForm<TestimonialDTO>({
    mode: 'onChange',
    resolver: superstructResolver(editableTestimonialSchema),
    defaultValues: resource
  });

  useEffect(() => {
    reset(resource);
  }, [reset, resource]);

  return (
    <form
      {...s.with('form')({ className, style })}
      {...rest}
      noValidate
      onSubmit={handleSubmit(onSubmit)}
    >
      <div {...s('controls')}>
        <Controller
          control={control}
          name='author'
          render={({ field, fieldState: { error } }) => (
            <FormControl isRequired isInvalid={error !== undefined}>
              <FormLabel>Testimonial Author</FormLabel>
              <FormInput
                Input={TextInput}
                {...field} // @ts-expect-error Limitation in prop type inference
                placeholder={'E.g. Lucy - Seller, Newstead'}
                isSoftLimit={false}
                charLimit={55}
              />
              <FormErrorMessage>Author is required</FormErrorMessage>
            </FormControl>
          )}
        />

        <Controller
          control={control}
          name='testimonial'
          render={({ field }) => (
            <FormControl isRequired>
              <FormLabel>Customer Testimonial</FormLabel>
              <FormInput
                Input={TextArea}
                {...field} // @ts-expect-error Limitation in prop type inference
                placeholder={
                  'Tom was a fantastic agent to deal with. Throughout the whole process we felt at ease as there was constant updates. We were able to sell our home in a matter of months. It was truly exceptional service right from the beginning.'
                }
                isSoftLimit={false}
                charLimit={180}
              />
            </FormControl>
          )}
        />

        <Controller
          control={control}
          name='excerpt'
          render={({ field, fieldState: { error } }) => (
            <FormControl isRequired isInvalid={error !== undefined}>
              <FormLabel>Testimonial Excerpt</FormLabel>
              <FormInput
                Input={TextInput}
                {...field} // @ts-expect-error Limitation in prop type inference
                placeholder={'...exceptional service right from the beginning'}
                charLimit={55}
                isSoftLimit={false}
              />
              <FormErrorMessage>
                Must be shorter than 55 characters
              </FormErrorMessage>
            </FormControl>
          )}
        />

        <Controller
          control={control}
          name='agent_id'
          render={({ field, fieldState: { error } }) => (
            <FormControl isRequired isInvalid={error !== undefined}>
              <FormLabel>
                Listing Agent{' '}
                <HelpIcon
                  Content={() => (
                    <Box style={{ width: '20rem' }}>
                      <Body>
                        A testimonial campaign is tied to an agent, choose which
                        agent you’d like to attach to this testimonial even if
                        someone else will be handling the leads
                      </Body>
                    </Box>
                  )}
                />
              </FormLabel>
              <FormInput Input={AgentSelectorInput} {...field} />
              <FormErrorMessage>{error?.message}</FormErrorMessage>
            </FormControl>
          )}
        />
      </div>

      <ButtonGroup {...s('footer')} spacing='l'>
        <Spacer />
        <GhostButton onClick={onCancel}>Cancel</GhostButton>
        <PrimaryButton
          isLoading={isSubmitting}
          isDisabled={!isValid}
          type='submit'
        >
          {submitLabel}
        </PrimaryButton>
      </ButtonGroup>
    </form>
  );
};

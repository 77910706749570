import { Box } from '@rexlabs/box';
import { styled, StyleSheet } from '@rexlabs/styling';
import { autobind } from 'core-decorators';
import _ from 'lodash';
import React, { PureComponent } from 'react';
import { Body } from 'src/view/components/text';
import ChangeProvisionStepModal from 'src/view/modals/sysadmin/change-provision-step';

const defaultStyles = StyleSheet({
  container: {
    width: '100%',
    padding: ({ token }) => token('spacing.m'),
    flexShrink: 0,
    transition: 'background .2s',
    cursor: 'pointer',

    ':hover': {
      background: ({ token }) => token('legacy.color.grey.lighter')
    },

    '> *': {
      flexShrink: 0
    }
  },

  name: {
    width: '29rem'
  }
});

@styled(defaultStyles)
@autobind
class ProvisionStepsListItem extends PureComponent {
  state = {
    modalOpen: false
  };

  handleOpenModal() {
    this.setState({ modalOpen: true });
  }

  handleCloseModal() {
    this.setState({ modalOpen: false });
  }

  async handleSubmit(values, error) {
    const {
      accountId,
      provisionId,
      data: { id: stepId },
      updateProvisionStep,
      refreshList
    } = this.props;
    try {
      await updateProvisionStep({
        accountId,
        provisionId,
        stepId,
        value: values.value,
        status: values.status
      });
      await refreshList();
      this.handleCloseModal();
    } catch (e) {
      error.open();
    }
  }

  render() {
    const { modalOpen } = this.state;
    const { styles: s, data } = this.props;
    return (
      <Box
        {...s('container')}
        flexDirection='row'
        alignItems='center'
        justifyContent='space-between'
        onClick={this.handleOpenModal}
      >
        <Box flexDirection='row' alignItems='center' flex={1}>
          <Body {...s('name')}>{data.label}</Body>
        </Box>

        <Box style={{ width: '22rem' }}>
          <Body>{_.capitalize(data.status)}</Body>
        </Box>

        <Box style={{ width: '14rem' }}>
          <Body>{data.value}</Body>
        </Box>

        {modalOpen && (
          <ChangeProvisionStepModal
            status={data.status}
            name={data.label}
            description={data.description}
            value={data.value}
            handleSubmit={this.handleSubmit}
            closeModal={this.handleCloseModal}
          />
        )}
      </Box>
    );
  }
}

export default ProvisionStepsListItem;

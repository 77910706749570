import {
  create,
  Date,
  Describe,
  integer,
  number,
  object,
  Optional,
  record,
  string,
  Type,
  type,
  UUID,
  FloatString
} from 'src/lib/superstruct';
import {
  Image,
  ImageCrop,
  ImageCrops,
  ImageSize,
  Upload,
  Video
} from '../types';
import { omit, union } from 'superstruct';

export const VIDEO_STATUSES = ['uploading', 'active', 'inactive'] as const;

export const uploadSchema: Describe<Upload> = object({
  id: UUID,
  public_url: Optional(string()),
  original_file_name: Optional(string()),
  filename: string(),
  size: integer(),
  created_at: Date,
  updated_at: Date
});

const imageCropSchema: Describe<ImageCrop> = type({
  width: integer(),
  height: integer(),
  top_left: type({
    x: integer(),
    y: integer()
  })
});

const imageCropsSchema: Describe<ImageCrops> = type({
  landscape: imageCropSchema,
  square: imageCropSchema
});

export const imageSizeSchema: Describe<ImageSize> = type({
  height: union([number(), FloatString]),
  width: union([number(), FloatString]),
  url: string()
});

export const imageSchema: Describe<Image> = type({
  id: UUID,
  name: string(),
  crops: imageCropsSchema,
  sizes: record(string(), imageSizeSchema),
  created_at: Date,
  updated_at: Date
});

export const createImage = (value: unknown): Image =>
  create(value, imageSchema);

const metaSchema = type({
  dimensions: type({
    aspect_ratio: number(),
    height: integer(),
    width: integer()
  }),
  duration: union([string(), number()])
});

const videoSchema: Describe<Video> = type({
  id: UUID,
  status: Type(VIDEO_STATUSES),
  name: string(),
  url: string(),
  meta: metaSchema,
  thumbnail: omit(imageSchema, ['created_at', 'updated_at'])
});

export const transformVideoResponse = (value: unknown): Video =>
  create(value, videoSchema);

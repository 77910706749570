import { Body } from '@rexlabs/text';
import React from 'react';

export function LeadsAndExposureGoalDescription() {
  return (
    <Body grey>
      Capture the details of buyers and sellers with lead ads, while also
      expanding your brand’s exposure across multiple networks.
    </Body>
  );
}

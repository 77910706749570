/* eslint-disable max-lines */
import React from 'react';

export default {
  DASHBOARD: {
    config: {
      path: '/:accountIndex([0-9]+)?/dashboard',
      exact: true,
      Component: React.lazy(
        async () => await import('src/view/screens/admin/dashboard')
      )
    }
  },

  LISTINGS: {
    config: {
      path: '/:accountIndex([0-9]+)?/listings',
      exact: true,
      Component: React.lazy(
        async () => await import('src/view/screens/admin/listings')
      )
    },

    CREATE_LISTING: {
      config: {
        path: '(.*)',
        hash: '/create-listing',
        Component: React.lazy(
          async () => await import('view/modals/wizard/edit-listing')
        )
      }
    },

    EDIT_LISTING: {
      config: {
        path: '(.*)',
        hash: '/edit-listing/:listingId',
        Component: React.lazy(
          async () => await import('view/modals/wizard/edit-listing')
        )
      }
    },

    CREATE_COMMERCIAL_LISTING: {
      config: {
        path: '(.*)',
        hash: '/create-commercial-listing',
        Component: React.lazy(
          async () => await import('src/features/listings/components/CommercialListingDetailsEditorModal')
        )
      }
    },

    EDIT_COMMERCIAL_LISTING: {
      config: {
        path: '(.*)',
        hash: '/edit-commercial-listing/:listingId',
        Component: React.lazy(
          async () => await import('src/features/listings/components/CommercialListingDetailsEditorModal')
        )
      }
    },
  },

  CAMPAIGNS: {
    config: {
      path: '/:accountIndex([0-9]+)?/campaigns',
      Component: React.lazy(() =>
        import('src/view/screens/admin/campaign/list')
      )
    }
  },

  CAMPAIGN: {
    config: {
      path: '/:accountIndex([0-9]+)?/campaign/details/:campaignId',
      Component: React.lazy(() =>
        import('src/view/screens/admin/campaign/details')
      )
    },

    ADS_PREVIEW: {
      config: {
        path: '/:accountIndex([0-9]+)?/campaign/details/:campaignId/ads-preview',
        Component: React.lazy(() =>
          import('src/view/modals/admin/campaign/ads-preview')
        )
      }
    },

    LANDING_PAGE_PREVIEW: {
      config: {
        path: '/:accountIndex([0-9]+)?/campaign/details/:campaignId/landing-page-preview',
        Component: React.lazy(() =>
          import('src/view/modals/admin/campaign/landing-page-preview')
        )
      }
    },

    CHANGE_PLAN: {
      config: {
        path: '/:accountIndex([0-9]+)?/campaign/details/:campaignId/change-plan',
        Component: React.lazy(() =>
          import('src/view/modals/admin/campaign/change-plan')
        )
      }
    },

    PAUSE: {
      config: {
        path: '/:accountIndex([0-9]+)?/campaign/details/:campaignId/pause',
        Component: React.lazy(() =>
          import('src/view/modals/admin/campaign/pause')
        )
      }
    },

    RESUME: {
      config: {
        path: '/:accountIndex([0-9]+)?/campaign/details/:campaignId/resume',
        Component: React.lazy(() =>
          import('src/view/modals/admin/campaign/resume')
        )
      }
    },

    SHARE: {
      config: {
        path: '/:accountIndex([0-9]+)?/campaign/details/:campaignId/share',
        Component: React.lazy(() =>
          import('src/view/modals/admin/campaign/share')
        )
      }
    },

    ARCHIVE: {
      config: {
        path: '/:accountIndex([0-9]+)?/campaign/details/:campaignId/archive',
        Component: React.lazy(() =>
          import('src/view/modals/admin/campaign/archive')
        )
      }
    },

    STOP: {
      config: {
        path: '/:accountIndex([0-9]+)?/campaign/details/:campaignId/stop',
        Component: React.lazy(() =>
          import('src/view/modals/admin/campaign/stop')
        )
      }
    },

    CONVERSIONS: {
      config: {
        path: '/:accountIndex([0-9]+)?/campaign/details/:campaignId/conversions',
        Component: React.lazy(() =>
          import('src/view/modals/admin/campaign/conversions')
        )
      }
    },

    LANDING_PAGE_ENGAGEMENT: {
      config: {
        path: '/:accountIndex([0-9]+)?/campaign/details/:campaignId/landing-page-engagement',
        Component: React.lazy(() =>
          import('src/view/modals/admin/campaign/landing-page-engagement')
        )
      }
    },

    FACEBOOK_ENGAGEMENT: {
      config: {
        path: '/:accountIndex([0-9]+)?/campaign/details/:campaignId/facebook-engagement',
        Component: React.lazy(() =>
          import('src/view/modals/admin/campaign/facebook-engagement')
        )
      }
    },

    VIDEO_AD_PLAYS: {
      config: {
        path: '/:accountIndex([0-9]+)?/campaign/details/:campaignId/video-ad-plays',
        Component: React.lazy(() =>
          import('src/view/modals/admin/campaign/video-ad-plays')
        )
      }
    }
  },

  CAMPAIGN_EDIT: {
    config: {
      path: '/:accountIndex([0-9]+)?/campaign/edit/:campaignId',
      Component: React.lazy(() =>
        import('src/view/screens/admin/campaign/edit')
      )
    },

    EDIT_LISTING: {
      config: {
        path: '(.*)',
        hash: '/edit-listing/:listingId',
        Component: React.lazy(() =>
          import('src/view/modals/wizard/edit-listing')
        )
      }
    }
  },

  STRATEGIES: {
    config: {
      path: '/:accountIndex([0-9]+)?/strategies',
      exact: true,
      Component: React.lazy(
        async () => (await import('src/features/strategies')).StrategiesPage
      )
    }
  },

  STRATEGY_DETAILS: {
    config: {
      path: '/:accountIndex([0-9]+)?/strategies/:strategyId',
      exact: true,
      Component: React.lazy(
        async () =>
          (await import('src/features/strategies')).StrategyDetailsPage
      )
    }
  },

  STRATEGY_CAMPAIGN_REPORT: {
    config: {
      path: '/:accountIndex([0-9]+)?/strategies/:strategyId/campaign/:campaignId',
      Component: React.lazy(
        async () => await import('src/view/screens/admin/campaign/details')
      )
    }
  },

  SETTINGS_AGENCY: {
    config: {
      path: '/:accountIndex([0-9]+)?/settings/agency',
      Component: React.lazy(() =>
        import('src/view/screens/admin/settings/agency')
      )
    },

    EDIT: {
      config: {
        path: '/:accountIndex([0-9]+)?/settings/agency/edit',
        Component: React.lazy(() =>
          import('src/view/modals/admin/settings/edit-agency')
        )
      }
    }
  },

  SETTINGS_LANDING_PAGES: {
    config: {
      path: '/:accountIndex([0-9]+)?/settings/landing-pages',
      Component: React.lazy(() =>
        import('src/view/screens/admin/settings/landing-pages')
      )
    },

    EDIT: {
      config: {
        path: '/:accountIndex([0-9]+)?/settings/landing-pages/edit',
        Component: React.lazy(() =>
          import('src/view/modals/admin/settings/edit-landing-pages')
        )
      }
    }
  },

  SETTINGS_USERS: {
    config: {
      path: '/:accountIndex([0-9]+)?/settings/user-manager',
      Component: React.lazy(() =>
        import('src/view/screens/admin/settings/user-manager')
      )
    },

    INVITE: {
      config: {
        path: '/:accountIndex([0-9]+)?/settings/user-manager/invite',
        Component: React.lazy(() =>
          import('src/view/modals/admin/settings/user-manager/invite')
        )
      }
    },

    CREATE: {
      config: {
        path: '/:accountIndex([0-9]+)?/settings/user-manager/create-user',
        Component: React.lazy(() =>
          import('src/view/modals/admin/settings/user-manager/create-user')
        )
      }
    }
  },

  SETTINGS_INTEGRATIONS: {
    config: {
      path: '/:accountIndex([0-9]+)?/settings/integrations',
      Component: React.lazy(() =>
        import('src/view/screens/admin/settings/integrations')
      ),
      exact: true
    },

    CONNECT_FACEBOOK: {
      config: {
        path: '/:accountIndex([0-9]+)?/settings/integrations/facebook/connect',
        Component: React.lazy(() =>
          import('src/view/modals/admin/settings/integrations/connect-facebook')
        )
      }
    },

    REMOVE_FACEBOOK: {
      config: {
        path: '/:accountIndex([0-9]+)?/settings/integrations/facebook/remove',
        Component: React.lazy(() =>
          import('src/view/modals/admin/settings/integrations/remove-facebook')
        )
      }
    },

    CONNECT_WEBSITE: {
      config: {
        path: '/:accountIndex([0-9]+)?/settings/integrations/website/connect',
        Component: React.lazy(() =>
          import('src/view/modals/admin/settings/integrations/connect-website')
        )
      }
    }
  },

  SETTINGS_INTEGRATIONS_CRM: {
    config: {
      path: '/:accountIndex([0-9]+)?/settings/integrations/crm',
      Component: React.lazy(() =>
        import('src/view/screens/admin/settings/integrations/crm')
      )
    }
  },

  SETTINGS_PAYMENTS: {
    config: {
      path: '/:accountIndex([0-9]+)?/settings/payments',
      Component: React.lazy(() =>
        import('src/view/screens/admin/settings/payments')
      )
    },

    BILLING: {
      config: {
        path: '/:accountIndex([0-9]+)?/settings/payments/billing-details',
        Component: React.lazy(() =>
          import('src/view/modals/admin/settings/payments/edit-billing')
        )
      }
    },

    BILLING_CONTACT: {
      config: {
        path: '/:accountIndex([0-9]+)?/settings/payments/billing-contact',
        Component: React.lazy(() =>
          import('src/view/modals/admin/settings/payments/edit-billing-contact')
        )
      }
    },

    ADD_CREDIT_CARD: {
      config: {
        path: '/:accountIndex([0-9]+)?/settings/payments/credit-card/create',
        Component: React.lazy(() =>
          import('src/view/modals/admin/settings/payments/create-credit-card')
        )
      }
    },

    EDIT_CREDIT_CARD: {
      config: {
        path: '/:accountIndex([0-9]+)?/settings/payments/credit-card/:creditCardId/edit',
        Component: React.lazy(() =>
          import('src/view/modals/admin/settings/payments/edit-credit-card')
        )
      }
    },

    REMOVE_CREDIT_CARD: {
      config: {
        path: '/:accountIndex([0-9]+)?/settings/payments/credit-card/:creditCardId/remove',
        Component: React.lazy(() =>
          import('src/view/modals/admin/settings/payments/remove-credit-card')
        )
      }
    },

    MAKE_PRIMARY_CREDIT_CARD: {
      config: {
        path: '/:accountIndex([0-9]+)?/settings/payments/:creditCardId/make-primary',
        Component: React.lazy(() =>
          import(
            'src/view/modals/admin/settings/payments/make-primary-credit-card'
          )
        )
      }
    }
  },

  ACCOUNT_REVIEW: {
    config: {
      path: '/:accountIndex([0-9]+)?/settings/account-review',
      Component: React.lazy(() =>
        import('src/view/screens/admin/settings/account-review')
      )
    }
  },

  PROFILE: {
    config: {
      path: '/:accountIndex([0-9]+)?/profile',
      Component: React.lazy(() => import('src/view/screens/admin/profile'))
    },

    EDIT: {
      config: {
        path: '/:accountIndex([0-9]+)?/profile/edit',
        Component: React.lazy(() =>
          import('src/view/modals/admin/profile/edit')
        )
      }
    },

    DELETE: {
      config: {
        path: '/:accountIndex([0-9]+)?/profile/delete',
        Component: React.lazy(() =>
          import('src/view/modals/admin/profile/delete')
        )
      }
    },

    CHANGE_PASSWORD: {
      config: {
        path: '/:accountIndex([0-9]+)?/profile/change-password',
        Component: React.lazy(() =>
          import('src/view/modals/admin/profile/change-password')
        )
      }
    }
  },

  CREATE_ACCOUNT: {
    config: {
      path: '(.*)',
      hash: '/create-account',
      Component: React.lazy(() =>
        import('src/view/modals/admin/create-account')
      )
    }
  },

  REPORT: {
    config: {
      path: '/report/:reportToken',
      Component: () => null
    }
  },

  // Redirects
  HOME: {
    config: {
      path: '/:accountIndex([0-9]+)?/',
      exact: true,
      redirect: {
        path: '/campaigns'
      }
    }
  },

  SETTINGS: {
    config: {
      path: '/:accountIndex([0-9]+)?/settings',
      exact: true,
      redirect: {
        path: '/settings/agency'
      }
    }
  },

  LOGIN: {
    config: {
      path: '/:accountIndex([0-9]+)?/login',
      redirect: {
        path: '/campaigns'
      }
    }
  },

  SETUP_AGENCY: {
    config: {
      path: '/:accountIndex([0-9]+)?/setup/agency',
      redirect: {
        path: '/settings/agency'
      }
    }
  },

  SETUP_INTEGRATION: {
    config: {
      path: '/:accountIndex([0-9]+)?/setup/integration',
      redirect: {
        path: '/settings/integrations'
      }
    }
  }
};

import { AccountScopedQueryParams } from 'src/types';
import { GetStrategiesParams } from './getStrategies';

type StrategiesListParams = Omit<GetStrategiesParams, 'page'>;

export type AllVariables = {
  accountId: string;
};

export type DetailVariables = AllVariables & {
  strategyId: string;
};

export const strategiesKeys = {
  all: ({ accountId }: AccountScopedQueryParams) =>
    [accountId, 'strategies'] as const,
  lists: (params: AccountScopedQueryParams) =>
    [...strategiesKeys.all(params), 'list'] as const,
  list: ({ accountId, ...params }: StrategiesListParams) => [
    ...strategiesKeys.lists({ accountId }),
    params
  ],
  details: (vars: AllVariables) =>
      [...strategiesKeys.all(vars), 'detail'] as const,
  detail: ({ accountId, strategyId }: DetailVariables) => [
    ...strategiesKeys.details({ accountId }),
    strategyId
  ]
};

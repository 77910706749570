import { Box } from '@rexlabs/box';
import { styled, StyleSheet } from '@rexlabs/styling';
import React, { PureComponent } from 'react';

const defaultStyles = StyleSheet({
  container: {
    padding: ({ token }) => token('spacing.xl'),
    paddingLeft: 0,
    paddingRight: 0,
    position: 'relative'
  },

  borderTop: {
    borderTop: ({ token }) =>
      `.1rem solid ${token('legacy.color.grey.default')}`
  },

  paddingLeft: {
    paddingLeft: ({ token }) => token('spacing.xl')
  },

  overlay: {
    position: 'absolute',
    top: 1,
    left: 1,
    right: 1,
    bottom: 1,
    background: ({ token }) => token('legacy.color.grey.light'),
    opacity: 0.95,
    zIndex: 1
  }
});

@styled(defaultStyles)
class StatsSection extends PureComponent {
  render() {
    const {
      hasBorder,
      hasPaddingLeft,
      styles: s,
      disabled,
      children,
      ...rest
    } = this.props;
    return (
      <Box
        {...rest}
        {...s('container', {
          borderTop: hasBorder,
          paddingLeft: hasPaddingLeft
        })}
      >
        {disabled && <Box {...s('overlay')} />}
        {children}
      </Box>
    );
  }
}

export default StatsSection;

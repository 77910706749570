import {GetCatalogListingSetParams} from "src/features/catalog-listing-sets/api/getCatalogListingSets";

type AllVariables = {
    accountId: string;
};

export type DetailVariables = AllVariables & {
    listing_id?: string;
    listingSetId: string;
};

export const catalogListingSetKeys = {
    all: ({ accountId }: AllVariables) =>
        [accountId, 'catalog-listing-sets'] as const,
    preview: ({ accountId, listingSetId, listing_id }: Omit<GetCatalogListingSetParams, 'page'> & DetailVariables) =>
        [...catalogListingSetKeys.all({ accountId }), listingSetId, listing_id, 'preview'] as const,
    listings: ({ accountId, listingSetId, listing_id }: Omit<GetCatalogListingSetParams, 'page'> & DetailVariables) =>
        [...catalogListingSetKeys.all({ accountId }), listingSetId, listing_id, 'listings'] as const,
};

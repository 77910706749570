import { StyleSheet, useStyles } from '@rexlabs/styling';
import React, { ComponentPropsWithRef, forwardRef } from 'react';
import { Merge } from 'type-fest';

const ORIENTATIONS = ['vertical', 'horizontal'] as const;
const DEFAULT_ORIENTATION = 'horizontal';

const styles = StyleSheet({
  separator: {
    backgroundColor: ({ token }) =>
      token('separator.color', token('palette.grey.500'))
  },

  horizontal: {
    height: '1px',
    width: '100%'
  },

  vertical: {
    width: '1px',
    height: '100%'
  }
});

function isValidOrientation(orientation: any): orientation is Orientation {
  return ORIENTATIONS.includes(orientation);
}

export type Orientation = typeof ORIENTATIONS[number];

export type SeparatorProps = Merge<
  Omit<ComponentPropsWithRef<'div'>, 'inlist'>,
  {
    /**
     * Either `vertical` or `horizontal`. Defaults to `horizontal`.
     */
    orientation?: Orientation;
    /**
     * Whether or not the component is purely decorative. When true, accessibility-related attributes
     * are updated so that that the rendered element is removed from the accessibility tree.
     */
    decorative?: boolean;
  }
>;

export const Separator = forwardRef<HTMLDivElement, SeparatorProps>(
  (
    {
      orientation: orientationProp = DEFAULT_ORIENTATION,
      decorative,
      className,
      style,
      ...domProps
    },
    ref
  ) => {
    const s = useStyles(styles, 'Separator');
    const orientation = isValidOrientation(orientationProp)
      ? orientationProp
      : DEFAULT_ORIENTATION;

    const ariaOrientation =
      orientation === 'vertical' ? orientation : undefined;
    const semanticProps = decorative
      ? { role: 'none' }
      : { 'aria-orientation': ariaOrientation, role: 'separator' };

    return (
      <div
        {...semanticProps}
        {...domProps}
        {...s.with('separator', { [orientation]: true })({ className, style })}
        ref={ref}
      />
    );
  }
);

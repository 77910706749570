import {
  UseFieldApiConfig,
  UseFieldApiProps,
  useFieldApi
} from '@data-driven-forms/react-form-renderer';
import React, { FC } from 'react';
import {
  FormControl,
  FormControlAriaAttributes,
  FormHelperText,
  UseFormControlProps,
  useFormControl,
  FormLabel
} from '../form';
import { Toggle } from 'view/components/input';
import { StyleSheet, useStyles } from '@rexlabs/styling';

const styles = StyleSheet({
  container: {
    display: 'flex',
    flexGrow: '1',
    justifyContent: 'space-between'
  },
  text: {
    fontSize: '15px'
  }
});

export type SwitchFieldProps = UseFieldApiConfig &
  UseFormControlProps<HTMLElement> & {
    onText?: string;
    offText?: string;
  };

export const SwitchField: FC<SwitchFieldProps> = (props) => {
  const s = useStyles(styles, 'SwitchField');
  const fieldProps: UseFieldApiProps<any> &
    UseFormControlProps<HTMLElement> &
    FormControlAriaAttributes = useFieldApi(props);

  const {
    input,
    meta,
    isRequired,
    isDisabled,
    isReadOnly,
    helperText,
    label,
    onText,
    offText
  } = useFormControl(fieldProps);

  const labelText =
    input.value && onText ? onText : !input.value && offText ? offText : label;

  return (
    <FormControl
      isInvalid={Boolean(meta.error)}
      isRequired={isRequired}
      isDisabled={isDisabled}
      isReadOnly={isReadOnly}
    >
      <div {...s('container')}>
        <div>
          <FormLabel>{labelText}</FormLabel>
          {helperText && (
            <FormHelperText {...s('text')}>{helperText}</FormHelperText>
          )}
        </div>
        <div>
          <Toggle
            toggled={input.value}
            onClick={() => input.onChange(!input.value)}
          />
        </div>
      </div>
    </FormControl>
  );
};

/* eslint-disable max-lines */
import { track } from '@rexlabs/analytics';
import { Box } from '@rexlabs/box';
import { GhostButton, PrimaryButton } from '@rexlabs/button';
import DateInput from '@rexlabs/date-input';
import {
  query,
  withModel,
  withQuery,
  withValueLists
} from '@rexlabs/model-generator';
import { styled, StyleSheet } from '@rexlabs/styling';
import { autobind } from 'core-decorators';
import _capitalize from 'lodash/capitalize';
import _uniq from 'lodash/uniq';
import moment from 'moment';
import React, { PureComponent } from 'react';
import sessionModel from 'src/data/models/custom/session';
import wizardModel from 'src/data/models/custom/wizard';
import campaignsModel from 'src/data/models/entities/campaigns';
import customPackagesModel from 'src/data/models/entities/custom-packages';
import packageEstimatesModel from 'src/data/models/entities/package-estimates';
import campaignGoals from 'src/data/models/value-lists/campaign-goals';
import { packageQuery } from 'src/data/queries/packages';
import { formattedBrandName, withToken } from 'src/theme';
import { maybeFetchEstimates } from 'src/utils/estimates';
import { GridCell, GridRow } from 'src/view/components/grid';
import { RenderLoading } from 'src/view/components/loading';
import { NewTag } from 'src/view/components/new-tag';
import { EmptyCustomPlanItem, PlanItem } from 'src/view/components/plan';
import ScrollableContent from 'src/view/components/scrollable-content';
import { Body, Heading, Label } from 'src/view/components/text';
import withError from 'src/view/containers/with-error';
import EditBudgetModal from 'src/view/modals/wizard/edit-budget';
import { stepNames } from 'src/view/screens/wizard/multi-listing-sold';

import CalendarIcon from 'src/assets/icons/calendar.svg';
import ChevronLeft from 'src/assets/icons/chevron-left.svg';
import ChevronRight from 'src/assets/icons/chevron-right.svg';
import { withModal } from 'src/utils/modal';
import { StickyButtonGroup } from 'src/view/components/button';
import { withToast } from 'src/view/containers';
import LimitedBudgetEditorModal from 'src/view/modals/wizard/edit-limited-budget';

const styles = StyleSheet({
  content: {
    maxHeight: ({ token }) =>
      `calc(100vh - ${token('topNavBar.height', '8rem')})`
  },

  wrapPackageContainer: {
    width: '100%',
    padding: ({ token }) => token('spacing.xxxl'),
    paddingLeft: ({ token }) => token('spacing.xxs'),
    paddingRight: ({ token }) => token('spacing.xxs')
  },

  datePickerPrefix: {
    color: ({ token }) => token('legacy.color.blue.grey'),
    borderRight: ({ token }) =>
      `.1rem solid ${token('legacy.color.blue.greyLight')}`,
    height: '4.3rem',
    display: 'inline-flex',
    justifyContent: 'center',
    alignItems: 'center',
    paddingRight: ({ token }) => token('spacing.m'),
    marginRight: ({ token }) => token('spacing.m')
  },

  wrapError: {
    width: '100%',
    padding: '0 12rem',
    textAlign: 'center',
    height: '47.6rem'
  },

  wrapDateInput: {
    position: 'relative'
  },

  wrapCalendarIcon: {
    position: 'absolute',
    top: 0,
    right: 0,
    width: '4rem',
    height: '100%',
    color: ({ token }) => token('legacy.color.blue.grey'),
    pointerEvents: 'none',

    '> svg': {
      height: '1.6rem',
      width: 'auto'
    }
  }
});

const getCampaignId = (props) => props?.match?.params?.campaignId;

const campaignQuery = query`{
  ${campaignsModel} (id: ${getCampaignId}) {
    id
    name
    goal {
      id
      is_advanced_strategy
    }
    type {
      id
    }
    selected_package {
      id
    }
    start_date
    audience_spec
    custom_package_options {
      is_available
    }
    campaign_template {
      slug
    }
  }
}`;

const customPackagesQuery = query`{
  ${customPackagesModel} (campaignId: ${getCampaignId}) {
    id
    price
    duration
    name
    type
    is_custom
    is_default
    budget
    updated_at
  }
}`;

@withToken
@withError()
@styled(styles)
@withQuery(campaignQuery)
@withQuery(packageQuery(getCampaignId))
@withQuery(customPackagesQuery)
@withModel(packageEstimatesModel)
@withModel(wizardModel)
@withModel(sessionModel)
@withValueLists(campaignGoals)
@withModal
@withToast
@autobind
class BudgetScreen extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      plan: props?.campaigns?.item?.data?.selected_package?.id,
      loading: false,
      estimatesLoading: !props.wizard.packageEstimates.quotes,
      showBudgetModal: false
    };
    this.initialLoadDone = false;
  }

  setPlan(plan) {
    const {
      wizard: { packageEstimates },
      packages,
      campaigns,
      customPackages
    } = this.props;

    this.setState({ plan });
    const campaignType = campaigns.item.data.type.id;
    const selectedDefaultPackage = packages.list.items.find(
      (packageItem) => packageItem.id === plan
    );
    const selectedCustomPackage = customPackages.list.items.find(
      (packageItem) => packageItem.id === plan
    );
    const selectedPackage = selectedDefaultPackage || selectedCustomPackage;
    const packageEstimatedViews = packageEstimates?.quotes?.find(
      (quote) => quote.id === plan
    );

    track({
      event: 'spoke_frontend.v1.campaign_budget_package_selected',
      properties: {
        campaign_type: campaignType,
        package_type: selectedPackage?.name,
        package_amount: selectedPackage?.price?.value,
        package_amount_currency: selectedPackage?.price?.currency_code,
        package_duration_value: selectedPackage?.duration?.value,
        package_duration_unit: selectedPackage?.duration?.unit,
        package_estimated_ad_views_max:
          packageEstimatedViews?.reach.max || 'Estimates not loaded',
        package_estimated_ad_views_min:
          packageEstimatedViews?.reach.min || 'Estimates not loaded'
      }
    });
  }

  componentDidMount() {
    maybeFetchEstimates(this.props);
  }

  componentDidUpdate(prevProps) {
    const { campaigns } = this.props;

    const prevSelectedPackage =
      prevProps?.campaigns?.item?.data?.selected_package?.id;
    const campaignData = campaigns?.item?.data || {};
    const selectedPackage = campaignData?.selected_package?.id;

    if (!this.state.plan && !prevSelectedPackage && selectedPackage) {
      this.setPlan(selectedPackage);
    }

    if (!this.state.startDate && campaignData.id) {
      const date = campaignData.start_date
        ? moment(campaignData.start_date)
        : moment();
      this.setState({ startDate: date });
    }

    maybeFetchEstimates(this.props, prevProps);
  }

  isSelected(id) {
    if (this.state.plan) {
      return this.state.plan === id;
    }

    const campaignPackage =
      this?.props?.campaigns?.item?.data?.selected_package?.id;
    if (campaignPackage) {
      return campaignPackage === id;
    }

    return false;
  }

  handleSubmit() {
    const {
      wizard: { setStep, step },
      match,
      error,
      campaigns,
      packages
    } = this.props;
    const { startDate } = this.state;
    this.setState({ loading: true }, () =>
      campaigns
        .updateItem({
          id: match.params.campaignId,
          data: {
            selected_package: { id: this.state.plan },
            start_date:
              startDate && startDate.format ? startDate.format() : null
          }
        })
        .then(() => {
          const now = moment();
          const launchDateFromCreation = startDate.isSame(now, 'days')
            ? 0
            : startDate.diff(now, 'days');
          const plan = (packages?.list?.items || []).find(
            (p) => p.id === this.state.plan
          );

          track({
            event: 'Spoke campaign next',
            properties: {
              action: 'next_clicked',
              step_name: stepNames[step - 1].replace(/-/g, '_'),
              success: true,
              launch_date: startDate.format('MMMM Do YYYY'),
              launch_date_from_creation: launchDateFromCreation,
              value: plan?.price?.value,
              currency: plan?.price?.currency_code,
              budget_name: plan?.name,
              duration_days: plan?.duration?.value
            }
          });
          setStep({ step: step + 1 });
        })
        .catch((e) => {
          this.setState({ loading: false });
          error.open(e);
        })
    );
  }

  getHelpText() {
    const { campaigns, session } = this.props;

    const typeId = campaigns.item.data?.type?.id;
    const typeSlug = campaigns.item.data?.campaign_template?.slug;
    const isDynamicPackagesEnabled = session.accounts
      .filter(Boolean)
      .find((a) => a.id === session.currentAccountId)
      ?.feature_flags?.includes('dynamic_packages_for_sale_listing');

    if (typeSlug === 'listing' && isDynamicPackagesEnabled) {
      return (
        <span>
          <NewTag /> {formattedBrandName} now automatically recommends budget
          packages for you based on the value of the listing you’re advertising,
          historical campaign performance data, and the budgets that other
          agencies in your area are using.
        </span>
      );
    }

    if (typeId === 'single_listing') {
      return 'Pick the package that works best for you. Keep in mind that you’re likely to reach more people with a higher budget.';
    }

    return 'Pick the plan that works best for you. You can always change or stop your campaign.';
  }

  openCustomPackageModal() {
    this.setState({ showBudgetModal: true });
  }

  openEditLimitedBudgetModal() {
    const { modal, toasts, campaigns, customPackages } = this.props;
    modal.openModal({
      title: 'Create Custom Package',
      subtitle: campaigns?.item?.data?.name,
      contents: (
        <LimitedBudgetEditorModal
          campaignId={campaigns?.item?.data?.id}
          onSave={async () => {
            modal.closeModal();
            toasts.addToast({
              type: 'success',
              title: 'Custom budget has been successfully saved'
            });
            await customPackages.refreshList();
            this.setPlan(customPackages?.list?.items?.[0]?.id);
          }}
          closeModal={modal.closeModal}
        />
      )
    });
  }

  getCampaignGoal() {
    const {
      valueLists: { campaignGoals },
      campaigns
    } = this.props;
    const goal = campaigns.item?.data?.goal?.id;
    return (campaignGoals?.items ?? []).find((g) => g.id === goal);
  }

  render() {
    const {
      wizard: { setStep, step, packageEstimates, set, slug },
      styles: s,
      session,
      packages,
      customPackages,
      campaigns,
      error: { Error },
      token
    } = this.props;
    const { plan, loading, startDate } = this.state;
    const type = campaigns?.item?.data?.type?.id;
    const customPackageEnabled =
      campaigns?.item?.data?.custom_package_options?.is_available ?? true;
    const useCampaignGoal = session.accounts
      .filter(Boolean)
      .find((a) => a.id === session.currentAccountId)
      .feature_flags?.includes('campaign_goal');

    const failures = _uniq([
      ...(packageEstimates?.quotes?.[0]?.reach?.failures || []),
      ...(packageEstimates?.quotes?.[1]?.reach?.failures || []),
      ...(packageEstimates?.quotes?.[2]?.reach?.failures || [])
    ]);

    const failureText = failures.length
      ? failures
          .map((failure) =>
            failure === 'adwords' ? 'Google' : _capitalize(failure)
          )
          .join(' or ')
      : null;

    const customPackage = customPackages?.list?.items?.[0];

    const openCustomPackageModal =
      useCampaignGoal && campaigns?.item?.data?.goal?.is_advanced_strategy
        ? this.openEditLimitedBudgetModal.bind(this)
        : this.openCustomPackageModal.bind(this);

    return (
      <Box flex={1} flexDirection='column'>
        <ScrollableContent {...s('content')}>
          <Heading>Select a budget package that works for you</Heading>
          <Body grey>{this.getHelpText()}</Body>

          <RenderLoading
            minHeight='47.6rem'
            isLoading={
              packages?.list?.status === 'loading' ||
              campaigns?.item?.status === 'loading'
            }
          >
            {packages?.list?.status === 'error' ? (
              <Box
                {...s('wrapError')}
                justifyContent='center'
                alignItems='center'
              >
                <Body grey>
                  Something went wrong while trying to fetch the available
                  packages from 3rd party services. Please try refreshing this
                  page or contact the Spoke support if the error persists.
                </Body>
              </Box>
            ) : (
              <Box {...s('wrapPackageContainer')}>
                <div data-intercom-target='budgetOptions'>
                  <GridRow>
                    {(packages?.list?.items || []).map((item, index) => {
                      return (
                        <GridCell
                          key={item.id}
                          gutter={token('spacing.xs')}
                          width={1 / ((packages?.list?.items || []).length + 1)}
                          maxWidth={
                            1 / ((packages?.list?.items || []).length + 1)
                          }
                        >
                          <PlanItem
                            type={type}
                            slug={slug}
                            plan={item}
                            estimate={
                              !!packageEstimates.quotes &&
                              packageEstimates.quotes.find(
                                (e) => e.id === item.id
                              )
                            }
                            selected={this.isSelected(item.id, index)}
                            onSelect={() => this.setPlan(item.id)}
                            popular={index === 1}
                          />
                        </GridCell>
                      );
                    })}
                    {customPackageEnabled && (
                      <GridCell
                        gutter={token('spacing.xs')}
                        width={1 / ((packages?.list?.items || []).length + 1)}
                        maxWidth={
                          1 / ((packages?.list?.items || []).length + 1)
                        }
                      >
                        {!customPackage && (
                          <EmptyCustomPlanItem
                            onClick={(e) => {
                              e.stopPropagation();
                              openCustomPackageModal();
                            }}
                          />
                        )}
                        {customPackage && (
                          <PlanItem
                            plan={customPackage}
                            type={type}
                            slug={slug}
                            estimate={
                              !!packageEstimates.quotes &&
                              packageEstimates.quotes.find(
                                (e) => e.id === customPackage?.id
                              )
                            }
                            selected={this.isSelected(customPackage?.id)}
                            onSelect={() => this.setPlan(customPackage?.id)}
                            onClick={(e) => {
                              e.stopPropagation();
                              openCustomPackageModal();
                            }}
                            canEdit
                          />
                        )}
                        {this.state.showBudgetModal && (
                          <EditBudgetModal
                            subTitle={campaigns?.item?.data?.name}
                            customPackage={customPackage}
                            limitedPackage={
                              campaigns?.item?.data?.goal
                                ?.is_advanced_strategy ?? false
                            }
                            estimate={
                              !!packageEstimates.quotes &&
                              packageEstimates.quotes.find(
                                (e) => e.id === customPackage?.id
                              )
                            }
                            createPackage={customPackages.createItem}
                            updatePackage={customPackages.updateItem}
                            refreshPackages={customPackages.refreshList}
                            packageEstimates={packageEstimates}
                            packageEstimatesModel={this.props.packageEstimates}
                            setPackageEstimates={set}
                            campaigns={campaigns} // might only need to pass ID here
                            closeModal={() => {
                              if (
                                this.props?.customPackages?.list?.items?.[0]?.id
                              ) {
                                this.setPlan(
                                  this.props.customPackages.list.items[0].id
                                );
                              }
                              this.setState({ showBudgetModal: false });
                            }}
                          />
                        )}
                      </GridCell>
                    )}
                  </GridRow>
                </div>
                {!!failures.length && (
                  <Box mt={token('spacing.xl')}>
                    <Body grey>
                      * Oops, we couldn’t get estimates from {failureText}, the
                      actual number may be higher
                    </Body>
                  </Box>
                )}
              </Box>
            )}
          </RenderLoading>
        </ScrollableContent>

        <StickyButtonGroup>
          <Box flex={1}>
            <GhostButton
              IconLeft={ChevronLeft}
              onClick={() => {
                track({
                  event: 'Spoke campaign back',
                  properties: {
                    action: 'back_clicked',
                    step_name: stepNames[step - 1].replace(/-/g, '_')
                  }
                });
                setStep({ step: step - 1 });
              }}
            >
              Back
            </GhostButton>
          </Box>
          <div data-intercom-target='budgetOptionsNext'>
            <Box flexDirection='row' alignItems='center'>
              {campaigns?.item?.status !== 'fetching' && (
                <>
                  <Label
                    style={{
                      margin: 0,
                      marginRight: token('spacing.m'),
                      whiteSpace: 'nowrap'
                    }}
                  >
                    Start Date
                  </Label>

                  <Box {...s('wrapDateInput')}>
                    <DateInput
                      prefix={
                        this.state.startDate ? (
                          <Box {...s('datePickerPrefix')}>
                            {moment(this.state.startDate).format('dddd')}
                          </Box>
                        ) : null
                      }
                      colorPrimaryLight={token('palette.indigo.300')}
                      colorPrimary={token('palette.indigo.500')}
                      colorPrimaryDark={token('palette.indigo.700')}
                      isOutsideRange={(d) => d.isBefore(moment(), 'day')}
                      position='top'
                      onChange={(e) => {
                        this.setState({
                          startDate: e.target.value
                        });
                      }}
                      value={startDate}
                      data-testid={'date-input'}
                    />
                    <Box
                      {...s('wrapCalendarIcon')}
                      justifyContent='center'
                      alignItems='center'
                    >
                      <CalendarIcon />
                    </Box>
                  </Box>
                </>
              )}
              <Box ml={token('spacing.xs')}>
                <PrimaryButton
                  onClick={this.handleSubmit}
                  IconRight={ChevronRight}
                  isDisabled={
                    !plan || !startDate || startDate.isBefore(moment(), 'day')
                  }
                  isLoading={loading}
                >
                  Next
                </PrimaryButton>
              </Box>
            </Box>
          </div>
        </StickyButtonGroup>

        <Error />
      </Box>
    );
  }
}

export default BudgetScreen;

import { Box } from '@rexlabs/box';
import { useToken } from '@rexlabs/styling';
import { noop } from 'lodash';
import React, { useCallback, useState } from 'react';
import { useAsyncFn } from 'react-use';
import { CampaignGoalData } from 'src/data/models/value-lists/campaign-goals';
import { api } from 'src/utils/api-client';
import { PlanItem } from '../plan/new-item';
import { calcAllocationTotals, LimitedBudgetEditorForm } from './form';
import { useModelState } from '@rexlabs/model-generator';
import session from 'data/models/custom/session';
import { currencyCodes } from 'view/components/money';
import { getAccountRegion } from 'shared/utils/region';

async function PackagePerformanceEstimator(
  campaignId: string,
  allocation: Record<string, number>,
  duration: number
): Promise<PlanEstimate> {
  const google = allocation.google ?? 0;
  const facebook =
    allocation.facebook_ads ?? 0 + allocation.facebook_lead_ads ?? 0;
  const { data } = await api.get(
    `/campaigns/${campaignId}/quotes/custom?budget_facebook=${facebook}&budget_google=${google}&duration=${duration}`
  );
  if (data.estimate.reach.failures.length === 2) throw new Error();
  return {
    minReach: data.estimate.reach.min,
    maxReach: data.estimate.reach.max
  };
}

export interface LimitedCustomPackage {
  total: number;
  duration: number;
}

export interface PlanEstimate {
  minReach: number;
  maxReach: number;
  approximate?: boolean;
}

export interface LimitedBudgetEditorProps {
  campaign: any;
  campaignGoal: CampaignGoalData;
  customPackage: any;
  readOnly?: boolean;
  setCustomPackage(data: any): Promise<any>;
  onSave(data: any): void;
  cancelEdit(): void;
}

export function LimitedBudgetEditor({
  campaign,
  campaignGoal,
  customPackage,
  readOnly = false,
  setCustomPackage,
  onSave = noop,
  cancelEdit
}: LimitedBudgetEditorProps) {
  const token = useToken();
  const sessionState = useModelState(session);

  let initialBudget;
  if (customPackage) {
    initialBudget = {
      total: customPackage.budget.total,
      duration: customPackage.duration.value
    };
  }
  const [budget, setBudget] = useState(initialBudget);

  const [estimate, getEstimate] = useAsyncFn(({ total, duration }) => {
    const totals = calcAllocationTotals(campaignGoal.adTypes, total);
    return PackagePerformanceEstimator(campaign.id, totals, duration);
  }, []);

  const saveBudget = useCallback(
    async ({ total, duration }) => {
      const data = {
        campaignId: campaign.id,
        budget: {
          total
        },
        duration: {
          value: duration
        }
      };
      const res = await setCustomPackage(data);
      onSave(res);
    },
    [campaign.id, onSave, setCustomPackage]
  );

  const onBudgetChange = useCallback(
    (budget) => {
      setBudget(budget);
      getEstimate(budget);
    },
    [getEstimate]
  );

  return (
    <Box flexDirection='column' sy={token('spacing.m')} w='80rem'>
      <Box flex={1} sx={token('spacing.m')}>
        <Box flex={8}>
          <LimitedBudgetEditorForm
            readOnly={readOnly}
            campaignGoal={campaignGoal}
            budget={budget}
            onChange={onBudgetChange}
            onSubmit={saveBudget}
            onCancel={cancelEdit}
          />
        </Box>
        <Box flex={4} justifyContent='stretch'>
          <PlanItem
            name='Custom'
            price={{
              value: budget?.total,
              currency_code: currencyCodes[getAccountRegion(sessionState)]
            }}
            duration={budget?.duration}
            estimateAudience='buyers'
            estimate={estimate}
          />
        </Box>
      </Box>
    </Box>
  );
}

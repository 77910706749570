import React from 'react';
import { StyleSheet, styled } from '@rexlabs/styling';
import { FONT, BORDER_RADIUS } from 'src/theme';

const defaultStyles = StyleSheet({
  a: {
    color: ({ token }) => token('legacy.color.white'),
    background: ({ token }) => token('legacy.color.primary.default'),
    whiteSpace: 'nowrap',
    fontWeight: FONT.WEIGHTS.NORMAL,
    borderRadius: BORDER_RADIUS.INPUT,
    cursor: 'pointer',
    position: 'relative',
    paddingTop: '1px',
    paddingBottom: '2px',
    paddingLeft: ({ token }) => token('spacing.xl'),
    paddingRight: ({ token }) => token('spacing.xl'),
    height: '4.4rem',
    display: 'inline-flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: 'auto',
    flexShrink: 0,
    border: '0 none',
    transition: 'background .2s, opacity .3s',
    fontSize: '1.8rem',

    ':hover': {
      opacity: 0.9,
      textDecoration: 'none'
    }
  }
});

function LinkButton({
  href,
  target,
  children,
  style,
  styles: s,
  rel = 'noopener noreferrer'
}) {
  return (
    <a href={href} target={target} rel={rel} {...s('a')} style={style}>
      {children}
    </a>
  );
}

export default styled(defaultStyles)(LinkButton);

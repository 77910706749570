import React, { PureComponent } from 'react';
import { styled, StyleSheet } from '@rexlabs/styling';
import types from 'prop-types';
import { withToken } from 'src/theme';
import {
  ResponsiveContainer,
  BarChart as RechartsBarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip
} from 'recharts';
import TooltipContent from './bar-chart-tooltip';

const defaultStyles = StyleSheet({
  container: {
    '> .recharts-wrapper .recharts-cartesian-grid-vertical line:first-child': {
      strokeOpacity: 0
    },
    '> .recharts-wrapper .xAxis .recharts-cartesian-axis-tick:nth-child(1) text':
      {
        transform: 'translate(-2.5px)'
      },
    '> .recharts-wrapper .recharts-tooltip-cursor': {
      fill: 'none'
    }
  }
});

@withToken
@styled(defaultStyles)
class BarChart extends PureComponent {
  static propTypes = {
    data: types.arrayOf(
      types.shape({
        name: types.string.isRequired,
        value: types.number.isRequired
      })
    ).isRequired,
    maxBarSize: types.number.isRequired
  };

  static defaultProps = {
    data: [],
    barHeight: 24,
    barGap: 12,
    width: 600
  };

  render() {
    const {
      data,
      maxBarSize,
      barGap,
      barHeight,
      width,
      token,
      color, // NOTE: was previously a default prop but I need to dynamic change this via report
      styles: s
    } = this.props;
    const barLength = data.length;
    const height = barLength * barHeight + barLength * barGap + 40;

    return (
      <div {...s('container')}>
        <ResponsiveContainer width={width} height={height}>
          <RechartsBarChart
            data={data}
            layout='vertical'
            margin={{ top: 5, right: 40, left: 40, bottom: 5 }}
            maxBarSize={maxBarSize}
            barGap={barGap}
          >
            <XAxis
              type='number'
              interval={1}
              axisLine={false}
              tickLine={false}
              tickMargin={10}
              width={10}
              padding={{ left: 3 }}
              height={40}
              stroke={token('legacy.color.blue.grey')}
            />
            <YAxis
              type='category'
              interval={0}
              dataKey='name'
              tickLine={false}
              tickMargin={10}
              stroke={token('legacy.color.blue.grey')}
            />
            <Tooltip
              cursor={{ display: 'none' }}
              content={(props) => <TooltipContent {...props} />}
            />
            <CartesianGrid horizontal={false} />
            <Bar dataKey='value' fill={color} barSize={barHeight} />
          </RechartsBarChart>
        </ResponsiveContainer>
      </div>
    );
  }
}

export default BarChart;

import { UseFieldApiConfig } from '@data-driven-forms/react-form-renderer';
import React, { FC } from 'react';
import { Video } from 'src/features/uploads';
import { VideoInput } from 'view/components/input/video';

type VideoUploadInputProps = UseFieldApiConfig & {
  value: Video;
};
export const VideoUploadInput: FC<VideoUploadInputProps> = ({
  onChange,
  value
}) => {
  const transformedValue = value === null ? undefined : value;
  return (
    <VideoInput
      value={transformedValue}
      onChange={(data) => onChange(data.target.files)}
    />
  );
};

import React, { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';

ScaledText.propTypes = {
  maxWidth: PropTypes.number.isRequired,
  maxFontSize: PropTypes.number.isRequired,
  maxTextLines: PropTypes.number,
  minFontSize: PropTypes.number,
  maxHeight: PropTypes.number,
  children: PropTypes.string
};

function ScaledText({
  children,
  maxWidth,
  maxFontSize,
  minFontSize = 4,
  maxTextLines = 1,
  maxHeight,
  style,
  ...rest
}) {
  const [opacity, setOpacity] = useState(0);
  const refTextEl = useRef(null);

  useEffect(() => {
    const textEl = refTextEl.current;
    const { paddingTop, paddingBottom } = getComputedStyle(textEl);
    const padding = parseInt(paddingTop) + parseInt(paddingBottom);
    const decimalLineHeight =
      parseInt(getComputedStyle(textEl).lineHeight) /
      parseInt(getComputedStyle(textEl).fontSize);
    // 0.05 is a buffer as sometimes offsetHeight for 1 line of text is greater than fontSize * lineHeight even when there's no padding, margin etc...
    const _maxHeight =
      maxHeight || minFontSize * (decimalLineHeight + 0.05) * maxTextLines;

    // set/reset styles
    textEl.style.fontSize = `${maxFontSize}px`;
    textEl.style.lineHeight = `${maxFontSize * decimalLineHeight}px`;

    // shrink font until it fits within the maxHeight or has reached the minFontSize
    while (
      textEl.offsetHeight > _maxHeight + padding &&
      parseInt(textEl.style.fontSize) > minFontSize
    ) {
      textEl.style.fontSize = `${parseInt(textEl.style.fontSize) - 1}px`;
      textEl.style.lineHeight = `${
        parseInt(textEl.style.fontSize) * decimalLineHeight
      }px`;
    }

    // chop full words until the text fits within the maxHeight
    while (
      textEl.offsetHeight > _maxHeight + padding &&
      textEl.innerText.length > 0
    ) {
      const text = textEl.innerText;
      textEl.innerText = text.substring(0, text.lastIndexOf(' ')) + '...';
    }

    setOpacity(1);
  }, [children, maxFontSize, minFontSize, maxTextLines, maxHeight]);

  return (
    <span ref={refTextEl} style={{ ...style, opacity, maxWidth }} {...rest}>
      {children}
    </span>
  );
}

export default ScaledText;

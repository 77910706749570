import React, { PureComponent } from 'react';
import Box from '@rexlabs/box';
import { styled, StyleSheet } from '@rexlabs/styling';
import getContrastColor from '../../../utils/contrast-color';

const defaultStyles = StyleSheet({
  container: {
    position: 'absolute',
    zIndex: 2,
    bottom: '40px',
    left: '40px',
    maxWidth: 'calc(100% - 80px)',
    filter: 'drop-shadow(0 .2rem 1rem rgba(0, 0, 0, .24))'
  },

  agentName: {
    padding: '20px',
    background: 'white',
    fontSize: '82px',
    fontFamily: 'Circular Pro, Helvetica, Arial, sans-serif',
    lineHeight: '82px',
    borderRadius: '5px',
    borderBottomLeftRadius: 0,
    display: 'inline-flex'
  },

  agentPosition: {
    marginTop: '-.2rem',
    padding: '10px 20px',
    background: 'white',
    fontSize: '42px',
    fontFamily: 'Circular Pro, Helvetica, Arial, sans-serif',
    borderRadius: '5px',
    borderTopLeftRadius: 0,
    borderTopRightRadius: 0,
    display: 'inline-flex'
  }
});

@styled(defaultStyles)
class InstagramAgentNameOverlay extends PureComponent {
  render() {
    const { adContentSet, styles: s } = this.props;
    return (
      <Box
        {...s('container')}
        flexDirection='column'
        justifyContent='flex-start'
        alignItems='flex-start'
      >
        <div
          {...s.with('agentName')({
            background: adContentSet.backgroundColor,
            color: getContrastColor(adContentSet.backgroundColor)
          })}
        >
          {adContentSet.agentName}
        </div>
        <div
          {...s.with('agentPosition')({
            background: adContentSet.backgroundColor,
            color: getContrastColor(adContentSet.backgroundColor)
          })}
        >
          {adContentSet.agentPosition}
        </div>
      </Box>
    );
  }
}

export default InstagramAgentNameOverlay;

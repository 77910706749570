import type { ComponentType } from 'react';
import { AdType } from 'src/features/campaign-definitions';
import { transformVideoResponse } from 'src/features/uploads';
import { FacebookSingleImageAd } from '../components/facebook/FacebookSingleImageAd';
import { FacebookVideoAd } from '../components/facebook/FacebookVideoAd';
import { GoogleBannerAd } from '../components/google/GoogleBannerAd';
import { InstagramCarouselAd } from '../components/instagram/InstagramCarouselAd';
import { InstagramSingleImageAd } from '../components/instagram/InstagramSingleImageAd';
import { InstagramVideoAd } from '../components/instagram/InstagramVideoAd';
import type { AdPreviewProps, TemplateData } from '../types';
import { videoAdTemplate } from './helpers';

export type AdPreviewDefinition = {
  label: string;
  Preview: ComponentType<AdPreviewProps>;
};

export const adPreviews: Record<string, AdPreviewDefinition> = {
  'facebook.single_image': {
    label: 'Facebook Single Image',
    Preview: FacebookSingleImageAd
  },
  'facebook.video': {
    label: 'Facebook Video',
    Preview: FacebookVideoAd
  },
  'instagram.single_image': {
    label: 'Instagram Single Image',
    Preview: InstagramSingleImageAd
  },
  'instagram.carousel': {
    label: 'Instagram Casousel',
    Preview: InstagramCarouselAd
  },
  'instagram.video': {
    label: 'Instagram Video',
    Preview: InstagramVideoAd
  },
  'google_ads.banner': {
    label: 'Google Banner',
    Preview: GoogleBannerAd
  },
  'google_ads.responsive': {
    label: 'Google Responsive Display Ad',
    Preview: GoogleBannerAd
  },
  'facebook.catalog_image': {
    label: 'Facebook Single Image',
    Preview: FacebookSingleImageAd
  },
};

export const transformAdPreviews = (
  adTypes: AdType[],
  attributes: TemplateData
): AdType[] => {
  let transformedAdTypes = [...adTypes];
  const marketingVideo =
    attributes.marketing_video &&
    !(attributes.marketing_video as any).isUploading
      ? transformVideoResponse(attributes.marketing_video)
      : undefined;

  // If a video is present in the attributes, add the video previews to the list.
  if (marketingVideo && marketingVideo.status === 'active') {
    transformedAdTypes = [
      {
        format_id: 'facebook.video',
        templates: [videoAdTemplate(marketingVideo)]
      },
      {
        format_id: 'instagram.video',
        templates: [videoAdTemplate(marketingVideo)]
      }
    ].concat(transformedAdTypes);
  }

  return transformedAdTypes;
};

import { useMutation, useQueryClient } from 'react-query';
import { useAccountId } from 'src/features/accounts';
import { MutationResult } from 'src/lib/react-query';
import { api } from 'src/utils/api-client';
import { CustomAudience, CustomAudienceDTO } from '../types';
import { customAudienceSchema } from '../utils/schemas';
import { customAudienceKeys } from './keys';

export type CreateCustomAudienceParams = {
  accountId: string;
  data: CustomAudienceDTO;
};

export const createCustomAudience = async ({
  accountId,
  data
}: CreateCustomAudienceParams): Promise<CustomAudience> => {
  const res = await api.post('user-lists', data, {
    headers: {
      'X-Account-Id': accountId
    }
  });
  return customAudienceSchema.create(res.data);
};

export const useCreateCustomAudience = (): MutationResult<
  CustomAudience,
  CustomAudienceDTO
> => {
  const accountId = useAccountId();
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: (data) => createCustomAudience({ accountId, data }),
    onSuccess: (data) => {
      queryClient.setQueryData(
        customAudienceKeys.detail(accountId, data.id),
        data
      );
      queryClient.invalidateQueries(customAudienceKeys.lists(accountId));
    }
  });
};

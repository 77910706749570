import { Box } from '@rexlabs/box';
import { styled, StyleSheet } from '@rexlabs/styling';
import { autobind } from 'core-decorators';
import React, { Component } from 'react';
import { BORDER_RADIUS, FONT, withToken } from 'src/theme';
import { FormField, HiddenField } from 'src/view/components/form';
import { TextInput } from 'src/view/components/input';
import AgentImageInput from 'src/view/components/input/image/agent-image-input';
import Toggle from 'src/view/components/input/toggle';
import Label from 'src/view/components/text/label';

const defaultStyles = StyleSheet({
  title: {
    fontWeight: FONT.WEIGHTS.BOLD
  },

  text: {
    margin: '0 .2rem',
    display: 'block'
  },

  textMeta: {
    color: ({ token }) => token('legacy.color.blue.grey')
  },

  container: {
    padding: ({ token }) => token('spacing.m'),
    marginTop: ({ token }) => token('spacing.xl'),
    border: ({ token }) => `.1rem solid ${token('legacy.color.blue.grey')}`,
    borderRadius: BORDER_RADIUS.INPUT
  },

  separator: {
    margin: '15px 0px 15px 0px',
    width: '100%',
    height: '.1rem',
    background: ({ token }) => token('legacy.color.blue.greyLight')
  },

  wrapImage: {
    width: '22rem',
    height: '22rem',
    background: ({ token }) => token('legacy.color.blue.grey'),
    marginTop: ({ token }) => token('spacing.m'),
    borderRadius: '2%'
  },

  label: {
    display: 'inline'
  },

  toggle: {
    float: 'right'
  }
});

@withToken
@styled(defaultStyles)
@autobind
class EditListingAgent extends Component {
  constructor(props) {
    super(props);
    this.state = {
      toggled: props.hasValues,
      agent_image: props.agentValues.agent_image || null,
      agent_name: props.agentValues.agent_name || '',
      agency_name: props.agentValues.agency_name || ''
    };
  }

  componentWillReceiveProps(nextProps) {
    if (!!nextProps.hasValues !== !!this.props.hasValues) {
      this.setState({
        toggled: !!nextProps.hasValues
      });
    }
  }

  handleToggle() {
    const { toggled } = this.state;
    const { change, agentValues } = this.props;
    this.setState({ toggled: !toggled }, () => {
      if (!this.state.toggled) {
        this.setState({
          agent_image: agentValues.agent_image,
          agent_name: agentValues.agent_name,
          agency_name: agentValues.agency_name
        });
        change('prospecting.agent.agent_image', null);
        change('prospecting.agent.agent_name', '');
        change('prospecting.agent.agency_name', '');
      } else {
        change('prospecting.agent.agent_image', this.state.agent_image);
        change('prospecting.agent.agent_name', this.state.agent_name);
        change('prospecting.agent.agency_name', this.state.agency_name);
      }
    });
  }

  render() {
    const { token, styles: s, status, campaignId, initialImages } = this.props;
    return (
      <Box {...s('container')}>
        <Box {...s('toggle')}>
          <Toggle
            uniqueId={'edit_agent_toggle'}
            toggled={this.state.toggled}
            onClick={this.handleToggle}
          />
        </Box>
        <Label {...s('label')}>Listing Agent</Label>
        <span {...s('text', 'textMeta')}>
          Agent details will appear on the listing ads
        </span>

        <Box style={{ display: `${this.state.toggled ? 'block' : 'none'}` }}>
          <Box {...s('separator')} />
          <Box justifyContent='space-around'>
            <Box mr={token('spacing.m')} style={{ width: '17rem' }}>
              <FormField
                name='prospecting.agent.agent_image'
                label='Agent photo'
                Input={AgentImageInput}
                inputProps={{
                  type: 'agent',
                  status,
                  campaignId,
                  initialImages,
                  noBorder: true,
                  canCrop: true
                }}
                sendImmediate
              />
            </Box>
            <Box flexDirection='column' alignItems='center'>
              <FormField
                name='prospecting.agent.agent_name'
                label='Full name'
                Input={TextInput}
                inputProps={{
                  placeholder: 'e.g John Doe'
                }}
              />
              <FormField
                name='prospecting.agent.agency_name'
                label='Agency name'
                Input={TextInput}
                inputProps={{
                  placeholder: 'e.g Loft Properties'
                }}
              />
            </Box>
          </Box>
        </Box>
        <HiddenField name='agent_listing_toggled' value={this.state.toggled} />
      </Box>
    );
  }
}

export default EditListingAgent;

import { Box } from '@rexlabs/box';
import { ButtonGroup, GhostButton, PrimaryButton } from '@rexlabs/button';
import {
  border,
  padding,
  StyleSheet,
  useStyles,
  useToken
} from '@rexlabs/styling';
import { Body } from '@rexlabs/text';
import React, { FC, useCallback, useEffect, useMemo } from 'react';
import { useForm } from 'react-hook-form';
import { FormControl, FormErrorMessage, FormLabel } from 'src/components/form';
import { NumberInput } from 'src/lib/text-input';
import Money, { currencyCodes } from 'src/view/components/money';
import { LimitedCustomPackage } from '../editor';
import {
  PackageAllocation,
  PackageAllocationTable
} from '../package-allocation';
import { currency } from 'utils/format';
import { getAccountRegion } from 'shared/utils/region';
import session from 'data/models/custom/session';
import { useModelState } from '@rexlabs/model-generator';

const styles = StyleSheet({
  form: {
    display: 'flex',
    flex: '1 1 0%',
    flexDirection: 'column',
    justifyContent: 'stretch'
  },

  fields: {
    gap: ({ token }) => token('spacing.m')
  },

  label: {
    flex: 4
  },

  input: {
    flex: 1,
    marginRight: '0.4rem'
  },

  packageAllocation: {
    ...border.styles({
      all: {
        width: 'thin',
        radius: 'none',
        color: 'container.static.medium'
      }
    }),
    ...padding.styles({
      all: 'm'
    }),
    gap: ({ token }) => token('spacing.m')
  }
});

const MIN_DURATION = 3;
export type ValidationErrors =
  | 'min_duration'
  | 'max_duration'
  | 'total_min_spend';

const calculateMaxDuration = (total: number, minDailySpend: number): number =>
  Math.max(Math.floor(total / minDailySpend), MIN_DURATION);
const calculateMinSpend = (duration: number, minDailySpend: number): number =>
  duration * minDailySpend;

type FormProps = {
  readOnly?: boolean;
  initialBudget: LimitedCustomPackage;
  minDailySpend: number;
  onValidation?(name: ValidationErrors, value: number);
  calculateNetworkAllocation(total: number): PackageAllocation[];
  onChange?(budget: Partial<LimitedCustomPackage>);
  onSubmit(budget: LimitedCustomPackage);
  onCancel();
  showButtons?: boolean;
};

export const Form: FC<FormProps> = ({
  readOnly = false,
  initialBudget,
  minDailySpend,
  calculateNetworkAllocation,
  onChange,
  onSubmit,
  onCancel,
  showButtons = true
}) => {
  const s = useStyles(styles);
  const token = useToken();
  const sessionState = useModelState(session);

  const {
    register,
    watch,
    handleSubmit,
    formState: { isValid, errors }
  } = useForm({
    mode: 'onChange',
    defaultValues: initialBudget
  });

  const duration = watch('duration');
  const total = watch('total');

  const minSpend = useMemo(
    () => calculateMinSpend(duration, minDailySpend),
    [duration, minDailySpend]
  );
  const maxDuration = useMemo(
    () => calculateMaxDuration(total, minDailySpend),
    [minDailySpend, total]
  );
  const updateParent = useCallback(
    (values: LimitedCustomPackage) => {
      onChange?.(values);
    },
    [onChange]
  );

  useEffect(() => {
    const subscription = watch((values) => {
      const budget = { ...initialBudget, ...values };
      updateParent(budget);
    });
    return subscription.unsubscribe;
  }, [initialBudget, updateParent, watch]);

  const countryCode = getAccountRegion(sessionState);
  const locale = `en-${countryCode}`;
  const currencyCode = currencyCodes[countryCode];
  const currencySymbol = currency(0, true, locale, currencyCode).replace(
    /\d/g,
    ''
  );
  return (
    <form {...s('form')} onSubmit={handleSubmit(onSubmit)}>
      <Box flex={1} flexDirection='column' {...s('fields')}>
        <FormControl
          isRequired
          isInvalid={errors.total !== undefined}
          isReadOnly={readOnly}
        >
          <Box alignItems='center'>
            <FormLabel {...s('label')}>Total package amount</FormLabel>
            <div {...s('input')}>
              <NumberInput
                {...register('total', {
                  required: true,
                  min: minSpend,
                  deps: ['duration'],
                  valueAsNumber: true
                })}
                defaultValue={initialBudget.total}
                steps={1}
                Prefix={() => (
                  <Box mr={token('spacing.xs')}>{currencySymbol}</Box>
                )}
              />
            </div>
          </Box>
          <FormErrorMessage>
            {errors.total?.type === 'required' ? (
              'You must provide a package total'
            ) : errors.total?.type === 'min' ? (
              <>
                Minimum package amount for a{' '}
                <strong> {duration ?? 0} day</strong> campaign is{' '}
                <Money amount={minSpend} integer Tag='strong' />
              </>
            ) : null}
          </FormErrorMessage>
        </FormControl>

        <Box flexDirection='column' {...s('packageAllocation')}>
          <Body as='span'>Package allocation per network</Body>
          <PackageAllocationTable
            allocation={calculateNetworkAllocation(total)}
            currencyCode={currencyCode}
          />
        </Box>

        <FormControl
          isRequired
          isInvalid={errors.duration !== undefined}
          isReadOnly={readOnly}
        >
          <Box alignItems='center'>
            <FormLabel {...s('label')}>Campaign duration</FormLabel>
            <div {...s('input')}>
              <NumberInput
                {...register('duration', {
                  required: true,
                  min: MIN_DURATION,
                  max: maxDuration,
                  deps: ['total'],
                  valueAsNumber: true
                })}
                defaultValue={initialBudget.duration}
                steps={1}
                Suffix={() => <Box mr={token('spacing.xs')}>days</Box>}
              />
            </div>
          </Box>
          <FormErrorMessage>
            {errors.duration?.type === 'required' ? (
              'You must provide a campaign duration'
            ) : errors.duration?.type === 'min' ? (
              <>
                Minimum campaign duration is{' '}
                <strong>{MIN_DURATION} days</strong>
              </>
            ) : errors.duration?.type === 'max' ? (
              <>
                Maximum duration for a{' '}
                <Money amount={total ?? 0} integer Tag='strong' /> campaign is{' '}
                <strong>{maxDuration} days</strong>
              </>
            ) : null}
          </FormErrorMessage>
        </FormControl>
      </Box>

      {showButtons ? (
        <ButtonGroup justifyContent='flex-end' flex={0} mt={token('spacing.m')}>
          <GhostButton onClick={onCancel}>Cancel</GhostButton>
          <PrimaryButton type='submit' isDisabled={!isValid}>
            Save
          </PrimaryButton>
        </ButtonGroup>
      ) : null}
    </form>
  );
};

import 'intersection-observer';
import 'utils/globals';

import Analytics from '@rexlabs/analytics';
import { ToastProvider } from '@rexlabs/toast';
import React, { useEffect } from 'react';
import { render } from 'react-dom';
import { Helmet } from 'react-helmet';
import config from 'src/config';
import analyticsMiddleware from 'src/data/models/custom/analytics-middleware';
import { startBugsnag } from 'src/lib/bugsnag';
import { AppProviders } from 'src/providers/app';
import { store } from 'src/store';
import { initMocks } from 'src/test/server';
import { initTheme } from 'src/theme';
import { brand, getBrandMeta } from 'src/theme/brand';
import { FLINK_TOKENS } from 'src/theme/flink-tokens';
import { TOKENS } from 'src/theme/tokens';
import { api } from 'src/utils/api-client';
import SpokeApp from 'src/view/app';
import { ModalTarget } from 'view/components/portal';

Analytics.setHandlers([analyticsMiddleware]);
Analytics.init({ apiKey: config.SEGMENT_ID });

if (__DEV__) {
  window.app = {
    store,
    api
  };
}

const THEME_TOKENS = brand === 'flink' ? FLINK_TOKENS : TOKENS;

// Setup global parts of theme
initTheme(THEME_TOKENS);

startBugsnag();

const FAVICONS = getBrandMeta(brand).favicons;

function SpokeMount() {
  useEffect(() => {
    window.document.body.classList.add('ready');
  }, []);

  return (
    <AppProviders>
      <Helmet>
        <link rel='icon' href={FAVICONS.icon} sizes='any' />
        <link rel='icon' type='image/png' sizes='16x16' href={FAVICONS.png16} />
        <link rel='icon' type='image/png' sizes='32x32' href={FAVICONS.png32} />
        <link rel='apple-touch-icon' sizes='180x180' href={FAVICONS.apple} />
      </Helmet>

      <ModalTarget />

      <SpokeApp />
      <ToastProvider />
    </AppProviders>
  );
}

initMocks().then(() => {
  render(<SpokeMount />, document.querySelector('#app'));
});

/* eslint-disable max-lines */
import { Box } from '@rexlabs/box';
import { withModel } from '@rexlabs/model-generator';
import { useToken } from '@rexlabs/styling';
import isEmpty from 'lodash/isEmpty';
import set from 'lodash/set';
import uniq from 'lodash/uniq';
import React from 'react';
import { compose } from 'redux';
import config from 'src/config';
import session from 'src/data/models/custom/session';
import { serializeAddress, serializeSuburb } from 'src/utils/address';
import { useFeature } from 'src/utils/feature-flags';
import { interpolate, interpolateRegex } from 'src/utils/format';
import { addressToMapbox, suburbToMapbox } from 'src/utils/mapbox';
import * as Audience from 'src/view/components/audience';
import ComponentRenderer from 'src/view/components/component-renderer';

const RADIUS_UNIT = window.location.host.includes('uk', 'flink')
  ? 'mile'
  : 'kilometer';

export const addressRequiredValidation = {
  address: ['required', 'address'],
  radius: ['required|integer|min:1|max:80', 'radius']
};

export const validationRules = {
  address: ['required_without:suburbs.0', 'address'],
  'suburbs.*': ['required_without:address', 'suburbs'],
  radius: [
    `required_with:address|integer|min:${config.MIN_AUDIENCE_RADIUS}|max:80`,
    'radius'
  ],
  user_lists: 'fb_network_required',
  'segments.active_buyers': [
    'required_checkboxes:segments:website_visitors,facebook_page_engagement',
    'detailed targeting group'
  ],
  'segments.first_time_buyers': [
    'required_checkboxes:segments:website_visitors,facebook_page_engagement',
    'detailed targeting group'
  ],
  'segments.investors': [
    'required_checkboxes:segments:website_visitors,facebook_page_engagement',
    'detailed targeting group'
  ],
  'segments.premium_buyers': [
    'required_checkboxes:segments:website_visitors,facebook_page_engagement',
    'detailed targeting group'
  ],
  'segments.upgraders': [
    'required_checkboxes:segments:website_visitors,facebook_page_engagement',
    'detailed targeting group'
  ],
  'segments.house_owners': [
    'required_checkboxes:segments:website_visitors,facebook_page_engagement',
    'detailed targeting group'
  ],
  'segments.apartment_owners': [
    'required_checkboxes:segments:website_visitors,facebook_page_engagement',
    'detailed targeting group'
  ],
  'segments.buying_and_selling_real_estate': [
    'required_checkboxes:segments:website_visitors,facebook_page_engagement',
    'detailed targeting group'
  ],
  'segments.moving_and_relocating': [
    'required_checkboxes:segments:website_visitors,facebook_page_engagement',
    'detailed targeting group'
  ],
  'segments.luxury_property_owners': [
    'required_checkboxes:segments:website_visitors,facebook_page_engagement',
    'detailed targeting group'
  ],
  'networks.adwords': ['required_checkboxes:networks', 'network'],
  'networks.facebook': [
    'required_checkboxes:networks|fb_network_required',
    'network'
  ],
  'networks.instagram': [
    'required_checkboxes:networks|fb_network_required',
    'network'
  ]
};

export function mapPropsToValues({ campaigns, valueLists }) {
  const campaign = campaigns?.item?.data;
  const audience = campaign?.audience_spec || {};
  const location = (audience?.locations ?? []).find(
    (l) => l.type.id === 'radius'
  );

  // We need to reduce some server values to get them into the right format!
  let segments = audience?.segments ?? [];
  segments = segments.reduce((all, audience) => {
    set(all, audience.id, true);
    return all;
  }, {});

  const campaignTemplateNetworks =
    campaigns.item.data?.campaign_template?.campaign_template_configuration
      ?.configuration?.audience?.value_lists?.networks;

  const campaignGoal = campaigns.item.data?.goal;
  const goal = {
    ...campaignGoal,
    ...valueLists?.campaignGoals?.items.find((o) => o.id === campaignGoal?.id)
  };

  const networks = (audience?.networks ?? []).reduce(
    (acc, network) => ({
      ...acc,
      [network.id]:
        campaignTemplateNetworks.find((n) => n.id === network.id)?.enabled ??
        true
    }),
    {}
  );

  const networkAdTypeItems = valueLists?.networkAdTypes.items ?? [];
  const goalAdTypes = Object.keys(goal?.adTypes ?? {});
  const goalNetworkAdTypes = networkAdTypeItems.filter((o) =>
    goalAdTypes.includes(o.id)
  );
  const isAdvancedStrategy = goal?.is_advanced_strategy ?? false;

  const suburbs = (audience?.locations ?? [])
    .filter((l) => l.type.id === 'suburb')
    .map((s) => suburbToMapbox(s.suburb));

  // TODO: remove this once google ads are globally re-enabled
  // It's used to remove the google ads yellow badge and learn more which was used
  // specifically when we disabled google ads for all users
  const prospecting = (campaign?.ad_content_sets?.items || []).find((acs) =>
    (acs.type?.id || '').endsWith('.prospecting')
  )?.attributes;
  const videoOnly =
    prospecting?.video?.id &&
    !prospecting?.primary_image?.id &&
    !prospecting?.secondary_image?.id;

  return {
    type: campaign?.type?.id,
    goal,
    address: addressToMapbox(location?.address),
    radius: location?.radius?.value ?? undefined,
    _radius: location?.radius ?? {},
    age_range: audience?.age_range,
    suburbs,
    languages: (audience?.languages ?? [])
      .map(({ id }) => id)
      .filter((val) => !isEmpty(val)),
    segments,
    networks,
    networkAdTypes: isAdvancedStrategy ? goalNetworkAdTypes : undefined,
    lead_generation_form_enabled:
      campaign?.lead_generation_form_enabled ?? true,
    user_lists: (audience.user_lists || []).filter(Boolean),
    video_only: videoOnly
  };
}

export function getApiData(values, props) {
  const { campaigns } = props;
  const sendNetworks =
    campaigns.item.data?.goal === null ||
    campaigns.item.data?.goal === undefined ||
    campaigns.item.data?.goal.id === 'maximise_exposure';
  const campaignTemplate =
    campaigns.item.data?.campaign_template?.campaign_template_configuration
      ?.configuration;
  const suburbs = (values?.suburbs ?? []).filter(Boolean).map((suburb) => ({
    type: { id: 'suburb' },
    suburb: serializeSuburb(suburb.value)
  }));

  const networks = (campaignTemplate.audience.value_lists.networks || [])
    .filter(({ id }) => values?.networks?.[id])
    .map(({ id }) => ({ id }));

  const segments = [
    ...campaignTemplate.audience.value_lists.general_segments,
    ...campaignTemplate.audience.value_lists.retargeting_segments
  ]
    .filter(({ id }) => values?.segments?.[id])
    .map(({ id }) => ({ id }));

  const address = serializeAddress(values?.address?.value);
  return {
    locations: address
      ? [
          {
            type: {
              id: 'radius'
            },
            address,
            radius: {
              ...values._radius,
              value: values.radius,
              unit: values._radius.unit ?? RADIUS_UNIT
            }
          },
          ...suburbs
        ]
      : suburbs,
    segments,
    age_range: values.age_range,
    languages: uniq(values.languages).map((id) => ({ id })),
    user_lists: (values.user_lists || []).map(({ id }) => ({ id })),
    ...(sendNetworks ? { networks } : {})
  };
}

export function handleSubmit(values, { props }) {
  const { campaigns } = props;
  const actions = [
    campaigns.updateAudienceSpec({
      id: campaigns?.item?.data?.id,
      data: getApiData(values, props)
    }),
    campaigns.updateItem({
      data: {
        lead_generation_form_enabled: values?.lead_generation_form_enabled
      }
    })
  ];
  return Promise.all(actions).then(() =>
    campaigns.refresh({
      args: {
        include:
          'audience_spec,ad_content_sets,images.sizes,agency_logo.sizes,agent_images.sizes,videos.thumbnail.sizes'
      }
    })
  );
}

const EditAudienceForm = ({
  campaignType,
  campaignTemplate,
  setFieldValue,
  setFieldError,
  validate,
  setErrors,
  values,
  errors,
  session,
  suburbs
}) => {
  const token = useToken();
  const useLeadAds = useFeature('facebook_lead_ads');
  const useCampaignGoal = useFeature('campaign_goal');

  const extraPropsFn = ({ type, props: componentProps }) => {
    let extraProps;

    if (type === 'Locations') {
      const agency = session.accounts.find(
        (a) => a.id === session.currentAccountId
      ).agencies.data[0];
      const countryCode = agency.address.country.id;
      const localiseSuburb = countryCode === 'GB' ? 'town/city' : 'suburb';
      const localiseSuburbs = countryCode === 'GB' ? 'towns/cities' : 'suburbs';
      const localiseRadius = countryCode === 'GB' ? 'mi' : 'km';

      const interpolatedProps = Object.keys(componentProps).reduce(
        (acc, key) => {
          const prop = componentProps[key];
          if (interpolateRegex.test(prop)) {
            acc[key] = interpolate(prop, {
              localiseSuburb,
              localiseSuburbs,
              localiseRadius
            });
          }
          return acc;
        },
        {}
      );

      extraProps = {
        ...interpolatedProps,
        suburbs,
        values,
        setFieldValue
      };
    }

    if (type === 'AgeRange') {
      extraProps = {
        setFieldValue,
        values
      };
    }

    if (type === 'AudienceSegments') {
      extraProps = {
        validate,
        errors,
        setErrors,
        values
      };
    }

    if (type === 'CustomAudiences') {
      extraProps = {
        values,
        validate,
        setFieldError
      };
    }

    if (type === 'Networks') {
      extraProps = {
        values,
        networks: campaignTemplate.audience.value_lists.networks,
        errors,
        showLeadAds:
          campaignType === 'listing' && useLeadAds && !useCampaignGoal
      };
    }

    return extraProps;
  };

  return (
    <Box flex={1} flexDirection='column'>
      <Box pb={token('spacing.xxl')}>
        <ComponentRenderer
          template={campaignTemplate}
          resource='audience'
          section='edit'
          components={Audience}
          extraPropsFn={extraPropsFn}
        />
      </Box>
    </Box>
  );
};

export default compose(withModel(session))(EditAudienceForm);

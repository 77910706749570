export function findAndUpdate<T>(
  collection: ArrayLike<T>,
  searcher: (value: T, index: number) => boolean,
  updater: (value: T) => T
): T[] {
  const arr = Array.from(collection);
  const idx = arr.findIndex(searcher);
  if (idx !== -1) {
    const newValue = updater(arr[idx]);
    arr.splice(idx, 1, newValue);
  }
  return arr;
}

export function findAndRemove<T>(
  collection: T[],
  searcher: (value: T, index: number) => boolean
): T | undefined {
  const idx = collection.findIndex(searcher);
  return idx !== -1 ? collection.splice(idx, 1)[0] : undefined;
}

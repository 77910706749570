import { border, margin, StyleSheet, text, useStyles } from '@rexlabs/styling';
import React, { CSSProperties, FC } from 'react';
import { useDropArea } from 'react-use';
import FileUploadIcon from 'src/assets/icons/file-upload.svg';
import { EmptyInputProps as BaseEmptyInputProps } from './types';

const styles = StyleSheet({
  container: {
    width: '100%',
    cursor: 'pointer',
    position: 'relative',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    background: ({ token }) => token('color.container.interactive.idle'),
    transition:
      'color .2s ease-out, background-color .2s ease-out, border-color .2s ease-out',
    ...text.styles({
      color: ({ token }) => token('color.textStyle.body.subtext')
    }),
    ...border.styles({
      all: {
        width: 'thin',
        radius: 's',
        color: 'input.idle',
        style: () => 'dashed'
      }
    }),

    '&:hover': {
      ...text.styles({
        color: ({ token }) => token('color.textStyle.body.default')
      }),
      ...border.styles({
        all: {
          color: 'input.hover',
          style: () => 'dashed'
        }
      })
    },

    '&:active': {
      background: ({ token }) => token('color.input.active')
    }
  },
  containerDropping: {
    ...border.styles({
      all: {
        color: 'input.hover'
      }
    }),

    color: ({ token }) => token('color.textStyle.body.default'),
    backgroundColor: ({ token }) => token('color.input.active')
  },
  content: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    textAlign: 'center'
  },
  buttonLabel: {
    ...text.styles({
      fallback: 'normal.semibold',
      textDecoration: () => 'underline',
      color: ({ token }) => token('color.textStyle.link.idle.default')
    }),

    ...margin.styles({
      left: 'xxs'
    }),

    '&:hover': {
      ...text.styles({
        color: ({ token }) => token('color.textStyle.link.hover.default')
      })
    }
  },
  dropZoneButtonLabel: {
    ...text.styles({
      fallback: 'normal.bold'
    }),
    display: 'flex',
    flexDirection: 'row'
  }
});

export type EmptyInputProps = BaseEmptyInputProps & {
  className?: string;
  style?: CSSProperties;
};

export const EmptyInput: FC<EmptyInputProps> = ({
  multiple,
  addFiles,
  onClick,
  isLarge = false,
  className,
  style
}) => {
  const s = useStyles(styles, 'EmptyInput');
  const [dropzoneProps, dropzoneState] = useDropArea({
    onFiles: addFiles
  });
  const filesText = multiple ? 'files' : 'file';

  return (
    <div
      role='button'
      tabIndex={0}
      {...s.with('container', {
        containerDropping: dropzoneState.over
      })({ className, style })}
      {...dropzoneProps}
      onClick={onClick}
    >
      <div
        {...s.with('content')({
          flexDirection: isLarge ? 'column' : 'row'
        })}
      >
        {isLarge ? <FileUploadIcon /> : null}
        {dropzoneState.over ? (
          <span {...s('dropZoneButtonLabel')}>Drop to upload</span>
        ) : (
          <div
            {...s.with('dropZoneButtonLabel')({
              flexDirection: isLarge ? 'column' : 'row'
            })}
          >
            <span>Drag & drop {filesText} or</span>
            <span {...s('buttonLabel')}>choose {filesText}</span>
          </div>
        )}
      </div>
    </div>
  );
};

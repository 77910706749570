import {
  Date,
  Describe,
  UUID,
  array,
  create,
  enums,
  number,
  string,
  Optional,
  object,
  type,
  omit
} from 'src/lib/superstruct';
import { Strategy } from '../types';
import { campaignSchema } from 'src/features/campaigns/utils/schemas';

export const strategyCampaignSchema = omit(campaignSchema, [
  'status',
  'campaign_definition',
  'type'
]);
const impressionsSchema = type({ label: string(), impressions: number() });

export const statsSchema = type({
  social_engagement: Optional(array(impressionsSchema)),
  leads: Optional(array(impressionsSchema)),
  impressions: Optional(array(impressionsSchema)),
  clicks: Optional(array(impressionsSchema)),
  clickthrough: Optional(array(impressionsSchema)),
  lp_engagement: Optional(array(impressionsSchema))
});

const strategySchema: Describe<Strategy> = type({
  id: UUID,
  name: string(),
  status: enums(['pending', 'active', 'finished', 'draft']),
  objective: string(),
  summary: string(),
  budget: string(),
  start_date: Date,
  finish_date: Date,
  progress: number(),
  campaign_type_titles: array(string()),
  campaigns: Optional(object({ data: array(strategyCampaignSchema) })),
  created_at: Date,
  updated_at: Date,
  stats: Optional(statsSchema)
});

export const transformStrategyResponse = (value: unknown): Strategy =>
  create(value, strategySchema);

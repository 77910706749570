import { FB_AD_CONTAINER_WIDTH } from 'src/features/ads/components/facebook/FacebookAdContainer';
import { INSTA_AD_CONTAINER_WIDTH } from 'src/features/ads/components/instagram/InstagramAdContainer';
import { AdTemplate, AdType } from 'src/features/campaign-definitions';
import { Video } from 'src/features/uploads';

function facebookAndInstagramAds(fbAdType: AdType): AdType[] {
  return [
    fbAdType,
    {
      format_id: 'instagram.single_image',
      templates: fbAdType.templates
    }
  ];
}

/*
  Note: There are two templates (landscape/square) that we need to filter out because they are only
  used on the backend when creating the ads for google. Any template without a type is safe to show in the preview
*/
function flattenGoogleResponsiveAds(googleAdType: AdType): AdType[] {
  return googleAdType.templates
    .filter((t) => !t.type)
    .map((i) => ({
      format_id: googleAdType.format_id,
      templates: [i]
    }));
}

const adTypesMap = {
  'facebook.single_image': facebookAndInstagramAds,
  'facebook.catalog_image': facebookAndInstagramAds,
  'google_ads.responsive': flattenGoogleResponsiveAds
};

export function parseAdTypes(adTypes: AdType[]): AdType[] {
  return adTypes.flatMap((i: AdType) => {
    return adTypesMap[i.format_id]?.(i) ?? i;
  });
}

function scaleGoogleResponsiveAds(templateWidth: number): number {
  if (templateWidth <= 500) return 1;
  return 500 / templateWidth;
}

const templateScaleMap = {
  'facebook.single_image': (templateWidth) =>
    FB_AD_CONTAINER_WIDTH / templateWidth,
  'facebook.catalog_image': (templateWidth) =>
    FB_AD_CONTAINER_WIDTH / templateWidth,
  'instagram.single_image': (templateWidth) =>
    INSTA_AD_CONTAINER_WIDTH / templateWidth,
  'google_ads.banner': scaleGoogleResponsiveAds,
  'google_ads.responsive': scaleGoogleResponsiveAds,
  'facebook.video': (templateWidth) => FB_AD_CONTAINER_WIDTH / templateWidth,
  'instagram.video': (templateWidth) => INSTA_AD_CONTAINER_WIDTH / templateWidth
};

export function scaleTemplate({ templates, format_id }: AdType): number {
  const templateWidth = templates[0].width;
  return templateScaleMap[format_id]?.(templateWidth) ?? 1;
}

export function videoAdTemplate({ url, thumbnail }: Video): AdTemplate {
  return {
    template: `
      <body style='margin: 0; overflow: hidden; display: flex; flex-direction: column; justify-content: center; height: 100vh;'>
        <video
          poster="${thumbnail.sizes['original.thumbnail'].url}"
          style="width: 100%; height: auto;"
          autoplay
          muted
          controls
          loop
        >
          <source src="${url}" />
        </video>
      </body>
    `,
    width: FB_AD_CONTAINER_WIDTH,
    height: FB_AD_CONTAINER_WIDTH
  };
}

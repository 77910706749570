import React, { PureComponent } from 'react';
import ErrorLayout from 'src/view/layouts/error';

class ErrorBoundary extends PureComponent {
  state = {
    error: null,
    info: null
  };

  static defaultProps = {
    ErrorView: ErrorLayout
  };

  componentDidCatch(error, info) {
    this.setState({ error, info });
    // TODO: log to bugsnag etc.
  }

  render() {
    const { ErrorView, children } = this.props;
    const { error, info } = this.state;

    if (error) {
      return <ErrorView error={error} info={info} />;
    }

    return children;
  }
}

export default ErrorBoundary;

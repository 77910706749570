import { StyleSheet, useStyles } from '@rexlabs/styling';
import React, { forwardRef } from 'react';
import { useFormControlContext } from './FormControl';

const styles = StyleSheet({
  message: {
    color: ({ token }) => token('color.textStyle.body.error'),
    marginTop: ({ token }) => token('spacing.s'),
    fontSize: ({ token }) => token('typography.size.s'),
    lineHeight: ({ token }) => token('typography.lineHeight.s')
  }
});

export type FormErrorMessageProps = JSX.IntrinsicElements['div'];

export const FormErrorMessage = forwardRef<
  HTMLDivElement,
  FormErrorMessageProps
>(({ className, style, ...rest }, ref) => {
  const s = useStyles(styles);
  const field = useFormControlContext();

  if (!field?.isInvalid) return null;

  return (
    <div
      {...field?.getErrorMessageProps(rest, ref)}
      {...s.with('message')({ className, style })}
    />
  );
});

import { StyleSheet, useStyles } from '@rexlabs/styling';
import React from 'react';
import {
  HTMLPolymorphicProps,
  forwardRef,
  poly
} from 'src/lib/polymorphic-factory';

const styles = StyleSheet({
  link: {
    textDecoration: 'none',
    color: ({ token }) => token('color.textStyle.body.subtext'),

    ':hover': {
      textDecoration: 'underline'
    }
  }
});

export type BreadcrumbLinkProps = HTMLPolymorphicProps<'a'> & {
  /**
   * @default false
   */
  isCurrentPage?: boolean;
};

/**
 * Breadcrumb link.
 *
 * It renders a `span` when it matches the current link. Otherwise,
 * it renders an anchor tag.
 */
export const BreadcrumbLink = forwardRef<'a', BreadcrumbLinkProps>(
  ({ as, isCurrentPage, href, className, style, ...rest }, ref) => {
    const s = useStyles(styles, 'BreadcrumbLink');
    const sharedProps = {
      as,
      ref,
      ...rest
    };

    if (isCurrentPage) {
      return (
        <poly.span
          aria-current='page'
          className={className}
          style={style}
          {...sharedProps}
        />
      );
    }

    return (
      <poly.a
        {...s.with('link')({ className, style })}
        href={href}
        {...sharedProps}
      />
    );
  }
);

import { useQuery } from 'react-query';
import { QueryResult } from 'src/lib/react-query';
import { api } from 'src/utils/api-client';
import { vendorReportKeys } from '../keys';
import { ReportSection, VendorReportShareTokenResponse } from '../schemas';

export default function useVendorReportLink(
  campaignId: string,
  sections: ReportSection[],
  enabled = true
): QueryResult<string> {
  return useQuery(
    vendorReportKeys.shareLink(campaignId, sections),
    async ({ queryKey }): Promise<VendorReportShareTokenResponse> => {
      const [, , campaignId, , { sections }] = queryKey;
      const { data } = await api.post(
        `/campaigns/${campaignId}/vendor-reports`,
        {
          sections: sections.map((id) => ({ id }))
        }
      );
      return data;
    },
    {
      enabled,
      select: ({ token }) =>
        `${window.location.origin}/report/${encodeURIComponent(
          btoa(token) // HACK: the server seems to choke on urls with `.` in the path, so we base64 encode the token
        )}`
    }
  );
}

import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import Box from '@rexlabs/box';

import ScaledText from '../../scaled-text';
import { styled, StyleSheet } from '@rexlabs/styling';
import charLimit, { limits } from '../../../utils/char-limit';
import colorTheme from '../../../utils/color-theme';
import { SmallButton, LargeButton } from '../../button';
import { COLORS } from 'src/theme';
import _ from 'lodash';

export const types = {
  DEFAULT: 'default',
  LARGE: 'large'
};

const fontFamily = 'Lato, sans-serif';
const defaultStyles = StyleSheet({
  leaderboard: {
    overflow: 'hidden',
    border: '1px solid darkgray',
    display: 'flex'
  },
  primaryImage: {
    width: '103px',
    height: '100%',
    objectFit: 'cover'
  },
  shortHeadlineWrapper: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'flex-start',
    padding: '0 30px',
    backgroundColor: '#F8F8F8'
  },
  shortHeadline: {
    fontFamily,
    fontSize: '18px',
    fontWeight: 500,
    lineHeight: '18px'
  },
  agentDetails: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    padding: '0 20px',
    backgroundColor: 'white',
    flex: 1
  },
  agentName: {
    fontFamily,
    fontWeight: 300
  },
  suburb: {
    fontFamily,
    fontWeight: 600,
    textTransform: 'uppercase'
  },
  buttonWrapper: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    padding: '0 20px',
    backgroundColor: 'white'
  },
  logoWrapper: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center'
  },
  logo: {
    width: '100%',
    height: '100%',
    objectFit: 'contain'
  }
});

@styled(defaultStyles)
class Leaderboard extends PureComponent {
  static propTypes = {
    shortHeadline: PropTypes.string,
    agentName: PropTypes.string,
    suburb: PropTypes.string,
    buttonText: PropTypes.string,
    brandColor: PropTypes.string,
    primaryImageUrls: PropTypes.object,
    logoUrls: PropTypes.object,
    type: PropTypes.oneOf(Object.values(types))
  };

  static defaultProps = {
    buttonText: 'Learn more',
    type: types.DEFAULT
  };

  dimensions() {
    const { type } = this.props;
    switch (type) {
      case types.LARGE:
        return { width: '970px', height: '90px' };
      case types.DEFAULT:
      default:
        return { width: '728px', height: '90px' };
    }
  }

  logoDimensions() {
    const { type } = this.props;
    return {
      maxWidth: `calc(0.6 * ${type === types.LARGE ? '190px' : '142px'})`,
      maxHeight: 'calc(0.7 * 90px)'
    };
  }

  button() {
    const { type, buttonText, brandColor } = this.props;
    switch (type) {
      case types.LARGE:
        return (
          <Box width={134}>
            <LargeButton brandColor={brandColor}>{buttonText}</LargeButton>
          </Box>
        );
      case types.SMALL:
      default:
        return (
          <Box width={93}>
            <SmallButton brandColor={brandColor}>{buttonText}</SmallButton>
          </Box>
        );
    }
  }

  agentName() {
    const { type, agentName, styles: s } = this.props;

    switch (type) {
      case types.LARGE:
        return (
          <ScaledText
            maxWidth={300}
            maxTextLines={2}
            maxFontSize={30}
            minFontSize={18}
            {...s.with('agentName')({ color: COLORS.BLACK })}
          >
            {agentName}
          </ScaledText>
        );
      case types.DEFAULT:
      default:
        return (
          <ScaledText
            maxWidth={134}
            maxTextLines={2}
            maxFontSize={20}
            minFontSize={12}
            {...s.with('agentName')({ color: COLORS.BLACK })}
          >
            {agentName}
          </ScaledText>
        );
    }
  }

  suburbStyles() {
    const { type } = this.props;
    switch (type) {
      case types.LARGE:
        return { fontSize: '12px', lineHeight: '15px', marginTop: '8px' };
      case types.DEFAULT:
      default:
        return { fontSize: '10px', lineHeight: '12px', marginTop: '5px' };
    }
  }

  render() {
    const {
      agentPosition,
      agencyName,
      shortHeadline,
      brandColor,
      backgroundColor,
      primaryImageUrls,
      logoUrls,
      type,
      styles: s
    } = this.props;

    const { brandColorBorder } = colorTheme(brandColor);

    return (
      <Box {...s.with('leaderboard')({ ...this.dimensions() })}>
        <img
          {...s.with('primaryImage')({
            width: type === types.DEFAULT ? '103px' : '120px'
          })}
          src={_.get(primaryImageUrls, ['crop.square', 'url'])}
        />
        <Box
          {...s.with('shortHeadlineWrapper')({
            width: type === types.DEFAULT ? '185px' : '245px'
          })}
        >
          <span {...s.with('shortHeadline')({ color: COLORS.BLACK })}>
            {charLimit(shortHeadline, limits.SHORT_HEADLINE)}
          </span>
        </Box>
        <Box {...s('agentDetails')}>
          {this.agentName()}
          <span
            {...s.with('suburb')({
              color: COLORS.BLACK,
              ...this.suburbStyles()
            })}
          >
            {agentPosition} - {agencyName}
          </span>
        </Box>
        <Box {...s('buttonWrapper')}>{this.button()}</Box>
        <Box
          {...s.with('logoWrapper')({
            backgroundColor: backgroundColor,
            borderLeft: brandColorBorder,
            width: type === types.DEFAULT ? '134px' : '198px'
          })}
        >
          <img
            src={_.get(logoUrls, 'original.url')}
            {...s.with('logo')({ ...this.logoDimensions() })}
          />
        </Box>
      </Box>
    );
  }
}

export default Leaderboard;

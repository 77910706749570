import { useMutation, useQueryClient } from 'react-query';
import { api } from 'src/utils/api-client';
import { EmailRequest, LeadCaptureEmail } from '../types';
import { leadCaptureEmailsKeys } from './keys';

export const addLeadCaptureEmail = async (
  campaignId: string,
  email: EmailRequest
): Promise<LeadCaptureEmail> => {
  const res = await api.post(
    `/campaigns/${campaignId}/lead_capture_emails`,
    email
  );
  return res.data;
};

export const useAddLeadCaptureEmail = (campaignId: string) => {
  const queryClient = useQueryClient();

  return useMutation<
    LeadCaptureEmail,
    Error,
    EmailRequest,
    { previousValues: LeadCaptureEmail[] | undefined }
  >((data) => addLeadCaptureEmail(campaignId, data), {
    onMutate: async (newEmail) => {
      const previousValues = queryClient.getQueryData<LeadCaptureEmail[]>(
        leadCaptureEmailsKeys.list(campaignId)
      );

      queryClient.setQueryData<LeadCaptureEmail[]>(
        leadCaptureEmailsKeys.list(campaignId),
        (old) => [...(old ?? []), { ...newEmail, id: '' }]
      );

      return { previousValues };
    },
    onError: (_err, _newTodo, context) => {
      queryClient.setQueryData(
        leadCaptureEmailsKeys.list(campaignId),
        context?.previousValues
      );
    },
    onSettled: () => {
      queryClient.invalidateQueries(leadCaptureEmailsKeys.list(campaignId));
    }
  });
};

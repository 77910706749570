import {
  Date,
  Describe,
  String,
  UUID,
  create,
  number,
  type,
  boolean,
  nullable,
  array,
  object,
  any,
  optional,
  size,
  enums
} from 'src/lib/superstruct';
import {
  CatalogListingSet,
  ListingStatus
} from '../types';

const catalogListingSetSchema: Describe<CatalogListingSet> = type({
    id: UUID,
    name: String(),
    show_agent_image: boolean(),
    listing_min_price: nullable(number()),
    listing_max_price: nullable(number()),
    listing_sold: boolean(),
    listing_categories: array(String()),
    sort: nullable(String()),
    limit: nullable(number()),
    agents: object({
        data: array(any())
    }),
    created_at: Date,
    updated_at: Date
});

export const catalogListingSetFormSchema = type({
    name: String(),
    show_agent_image: boolean(),
    listing_min_price: optional(number()),
    listing_max_price: optional(number()),
    listing_status: enums([ListingStatus.Current, ListingStatus.Sold]),
    listing_categories: array(array(any())),
    sort: optional(any()),
    limit: optional(size(number(), 1, Infinity)),
    agents: optional(array(type({id: String()}))),
});

export const transformCatalogListingSetResponse = (
    value: unknown
): CatalogListingSet => create(value, catalogListingSetSchema);

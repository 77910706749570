import { margin, StyleSheet, useStyles } from '@rexlabs/styling';
import React, { Children, forwardRef, ReactElement } from 'react';
import { useFormControlContext } from './FormControl';
import { RequiredIndicator } from './RequiredIndicator';

const styles = StyleSheet({
  label: {
    display: 'block',
    textAlign: 'start',
    fontSize: ({ token }) => token('typography.size.m'),
    fontWeight: ({ token }) => token('typography.weight.medium'),
    marginInlineEnd: ({ token }) => token('spacing.s'),
    ...margin.styles({
      bottom: 'xxxs'
    }),
    transition: 'all 0.2s',
    opacity: 1
  }
});

export type FormLabelProps = JSX.IntrinsicElements['label'] &
  Record<string, unknown> & {
    requiredIndicator?: ReactElement;
    optionalIndicator?: ReactElement;
  };

export const FormLabel = forwardRef<HTMLLabelElement, FormLabelProps>(
  (props, ref) => {
    const s = useStyles(styles);
    const field = useFormControlContext();
    const {
      className,
      style,
      children,
      requiredIndicator = <RequiredIndicator />,
      optionalIndicator = null,
      ...rest
    } = field?.getLabelProps(props, ref) ?? { ref, ...props };

    const [label, ...otherChildren] = Children.toArray(children);
    return (
      <label {...s.with('label')({ className, style })} {...rest}>
        {label}
        {field?.isRequired ? requiredIndicator : optionalIndicator}
        {otherChildren}
      </label>
    );
  }
);

import { Box } from '@rexlabs/box';
import { PrimaryButton, SecondaryButton } from '@rexlabs/button';
import { query, withQuery } from '@rexlabs/model-generator';
import { autobind } from 'core-decorators';
import React, { PureComponent } from 'react';
import campaignsModel from 'src/data/models/entities/campaigns';
import { withToken } from 'src/theme';
import { RenderLoading } from 'src/view/components/loading';
import { Modal } from 'src/view/components/modal';
import { Body } from 'src/view/components/text';
import withError from 'src/view/containers/with-error';
import withToast from 'src/view/containers/with-toast';

const q = query`{
  ${campaignsModel} (id: ${(p) => p.match.params.campaignId}) {
    id
    name
    status {
      id
      label
    }
  }
}`;

@withToken
@withToast
@withError()
@withQuery(q)
@autobind
class PauseCampaignModal extends PureComponent {
  state = {
    loading: null
  };

  pauseCampaign() {
    const {
      campaigns,
      error: { open },
      closeModal,
      toasts
    } = this.props;
    this.setState({ loading: 'paused' }, () => {
      campaigns
        .updateItem({
          data: { status: { id: 'paused' } }
        })
        .then(() => {
          toasts.addToast({ title: 'Campaign has been paused successfully' });
          closeModal();
        })
        .catch((e) => {
          this.setState({ loading: null });
          open(e);
        });
    });
  }

  archiveCampaign() {
    const {
      campaigns,
      error: { open },
      closeModal,
      toasts
    } = this.props;
    this.setState({ loading: 'archived' }, () => {
      campaigns
        .updateItem({
          data: { status: { id: 'archived' } }
        })
        .then(() => {
          toasts.addToast({ title: 'Campaign has been archived successfully' });
          closeModal();
        })
        .catch((e) => {
          this.setState({ loading: null });
          open(e);
        });
    });
  }

  render() {
    const {
      closeModal,
      campaigns,
      error: { Error },
      token
    } = this.props;
    return (
      <Modal
        title='Pause campaign'
        subtitle={campaigns?.item?.data?.name}
        width='50rem'
        onClose={closeModal}
      >
        <RenderLoading isLoading={campaigns.item.status === 'loading'}>
          <Box p={token('spacing.xxl')} style={{ textAlign: 'center' }}>
            <Body>Are you sure you want to pause this campaign?</Body>
            <Body grey>
              If you want to stop campaign permanently, you can Archive Campaign
              straight away.
            </Body>
            <Box justifyContent='center' mt={token('spacing.xxl')}>
              <SecondaryButton
                isLoading={this.state.loading === 'archived'}
                onClick={this.archiveCampaign}
              >
                Archive
              </SecondaryButton>
              <Box ml={token('spacing.xs')}>
                <PrimaryButton
                  isLoading={this.state.loading === 'paused'}
                  onClick={this.pauseCampaign}
                >
                  Pause
                </PrimaryButton>
              </Box>
            </Box>

            <Error />
          </Box>
        </RenderLoading>
      </Modal>
    );
  }
}

export default PauseCampaignModal;

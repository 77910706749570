import React from 'react';
import { styled, StyleSheet } from '@rexlabs/styling';
import Box from '@rexlabs/box';
import colorTheme from '../../../utils/color-theme';
import { RegularButton } from '../../button';
import GooText from '../../goo-text';
import charLimit, { limits } from '../../../utils/char-limit';
import ScaledText from '../../scaled-text';
import { COLORS } from 'src/theme';

const fontFamily = 'Lato, sans-serif';

const defaultStyles = StyleSheet({
  largeRectangle: {
    height: '280px',
    width: '336px',
    border: '1px solid darkgray',
    overflow: 'hidden'
  },
  header: {
    height: '210px',
    width: '100%',
    backgroundSize: 'cover',
    backgroundPosition: 'center',
    position: 'relative'
  },
  textWrapper: {
    position: 'absolute',
    top: '14px',
    left: '12px'
  },
  smallText: {
    fontFamily,
    fontSize: '13px',
    fontWeight: 600,
    lineHeight: '26px',
    color: `${COLORS.BLACK}`,
    backgroundColor: 'white',
    textTransform: 'uppercase'
  },
  shortHeadline: {
    fontFamily,
    fontWeight: 400,
    fontSize: '22px',
    lineHeight: 1.3,
    color: `${COLORS.BLACK}`,
    backgroundColor: 'white'
  },
  footer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: '17px 16px',
    height: '70px'
  },
  footerLogo: {
    display: 'block',
    maxWidth: '80%',
    maxHeight: 'calc(0.66 * 70px)',
    objectFit: 'contain'
  }
});

const LargeRectangle = ({
  buttonText = 'View Property',
  shortHeadline,
  smallText,
  primaryImageUrls,
  logoUrls,
  brandColor,
  backgroundColor,
  styles: s
}) => {
  const { brandColorBorder } = colorTheme(brandColor);

  return (
    <Box {...s('largeRectangle')}>
      <Box
        {...s.with('header')({
          backgroundImage: `url('${primaryImageUrls?.['crop.square']?.url}')`
        })}
      >
        <Box {...s('textWrapper')} width={298}>
          <GooText borderRadius={3} padding='5px 10px 7px'>
            <>
              <strong {...s('smallText')}>
                {charLimit(smallText, limits.BANNER_AD_TEXT)}
              </strong>
              {smallText && shortHeadline ? <br /> : undefined}
              <ScaledText
                maxWidth={298}
                maxTextLines={2}
                maxFontSize={28}
                minFontSize={18}
                {...s('shortHeadline')}
              >
                {shortHeadline}
              </ScaledText>
            </>
          </GooText>
        </Box>
      </Box>
      <Box
        {...s.with('footer')({
          backgroundColor: backgroundColor,
          borderTop: brandColorBorder
        })}
      >
        <Box alignItems='center' style={{ marginRight: '16px' }}>
          <img src={logoUrls?.original?.url} {...s('footerLogo')} />
        </Box>
        <Box style={{ minWidth: '121px' }}>
          <RegularButton brandColor={brandColor} invert={false}>
            {buttonText}
          </RegularButton>
        </Box>
      </Box>
    </Box>
  );
};

export default styled(defaultStyles)(LargeRectangle);

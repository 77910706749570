import { styled, StyleSheet } from '@rexlabs/styling';
import React, { Component } from 'react';
import CropIcon from 'src/assets/icons/crop.svg';
import TrashIcon from 'src/assets/icons/trash.svg';
import { Capitalized } from 'src/view/components/text';

const BUTTON_POSITIONS = {
  topLeft: {
    top: '1rem',
    left: '1rem'
  },
  topRight: {
    top: '1rem',
    right: '1rem'
  },
  bottomLeft: {
    bottom: '1rem',
    left: '1rem'
  },
  bottomRight: {
    bottom: '1rem',
    right: '1rem'
  }
};

const ICON_POSITIONS = {
  left: 'row',
  right: 'row-reverse'
};

const defaultStyles = StyleSheet({
  button: {
    position: 'absolute',
    zIndex: 100,
    display: 'flex',
    alignItems: 'center',
    height: '2.5rem',
    width: '2.5rem',
    padding: '0',
    color: ({ token }) => token('legacy.color.black'),
    borderRadius: '50rem',
    backgroundColor: ({ token }) => token('color.container.static.default'),
    cursor: 'pointer',
    overflow: 'hidden',
    border: 'none',
    transition:
      'width 100ms ease-out, ' +
      'color 100ms ease-out, ' +
      'background-color 100ms ease-out',

    '&:hover': {
      width: '7.5rem',
      '& > :nth-child(2)': {
        display: 'flex'
      }
    }
  },

  icon: {
    width: '2.5rem',
    height: '2.5rem',
    transform: 'scale(0.8)'
  },

  text: {
    position: 'absolute',
    display: 'none',
    alignItems: 'center',
    marginTop: '0.1rem',
    transition: 'opacity 100ms ease-out 25ms'
  }
});

const deleteStyles = StyleSheet({
  button: {
    '&:hover': {
      width: '7.2rem',
      color: ({ token }) => token('legacy.color.white'),
      backgroundColor: ({ token }) => token('color.danger.idle.default')
    }
  }
});

const cropStyles = StyleSheet({
  button: {
    '&:hover': {
      width: '6.2rem',
      color: ({ token }) => token('legacy.color.white'),
      backgroundColor: ({ token }) =>
        token('color.container.static.primary.default')
    }
  },
  text: {
    marginRight: '0.3rem'
  }
});

@styled(defaultStyles)
class IconButton extends Component {
  render() {
    const {
      styles: s,
      Icon,
      position,
      iconPosition,
      text,
      ...rest
    } = this.props;
    return (
      <button
        {...s('button')}
        {...rest}
        style={{
          ...BUTTON_POSITIONS[position],
          flexDirection: ICON_POSITIONS[iconPosition]
        }}
        type='button'
      >
        <Icon {...s('icon')} />
        <div {...s('text')} style={{ [iconPosition]: '38%' }}>
          <Capitalized white>{text}</Capitalized>
        </div>
      </button>
    );
  }
}

class DeleteButton extends Component {
  render() {
    return (
      <IconButton
        {...this.props}
        styles={deleteStyles}
        Icon={TrashIcon}
        position='topLeft'
        iconPosition='left'
        text='delete'
      />
    );
  }
}

class CropButton extends Component {
  render() {
    return (
      <IconButton
        {...this.props}
        styles={cropStyles}
        Icon={CropIcon}
        position='topRight'
        iconPosition='right'
        text='crop'
      />
    );
  }
}

export { IconButton, DeleteButton, CropButton };

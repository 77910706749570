import { createRestAPIModelGenerator } from 'src/data/models/generator';
import { api } from 'src/utils/api-client';

export const TYPE = 'paymentOptions';

const config = {
  entities: {
    api: {
      fetchList: () => api.get('/billing/payment-options'),
      fetchItem: (type, args, id) =>
        api.get(`/billing/payment-options/${id}`, args),
      updateItem: (type, args, id) =>
        api.patch(`/billing/payment-options/${id}`, args),
      createItem: (type, args) => api.post('/billing/payment-options', args),
      deleteItem: (type, args, id) =>
        api.delete(`/billing/payment-options/${id}`),
      trashItem: (type, args, id) =>
        api.delete(`/billing/payment-options/${id}`)
    }
  }
};

const PaymentOptionsGenerator = createRestAPIModelGenerator(TYPE, config);
export default PaymentOptionsGenerator.createEntityModel();

import { lazy } from 'react';
import { HiddenField } from 'src/view/components/form';

const Locations = lazy(() => import('view/components/input/locations'));
const AgeRange = lazy(() => import('view/components/input/age-range'));
const AudienceSegments = lazy(() =>
  import('view/components/input/audience-segments')
);
const CustomAudiences = lazy(() =>
  import('view/components/input/custom-audiences')
);
const Networks = lazy(() => import('view/components/input/networks'));

export {
  Locations,
  AgeRange,
  AudienceSegments,
  Networks,
  CustomAudiences,
  HiddenField
};

import React, { FC } from 'react';
import { AdPreviewProps } from '../../types';
import { FacebookAdContainer } from './FacebookAdContainer';

export const FacebookSingleImageAd: FC<AdPreviewProps> = ({
  children,
  agencyLogo,
  logoBgColor,
  agencyName,
  description,
  websiteUrl,
  longHeadline,
  shortHeadline
}) => {
  return (
    <FacebookAdContainer
      pageName={agencyName}
      agencyLogo={agencyLogo}
      logoBgColor={logoBgColor}
      description={description}
      website={websiteUrl}
      title={shortHeadline}
      headline={longHeadline}
    >
      {children}
    </FacebookAdContainer>
  );
};

import { property } from 'lodash';
import React, { forwardRef, Key, useCallback } from 'react';
import {
  FileUploadFn,
  PreviewDataTransformer,
  SimpleFileUploadInput as BaseFileUploadInput,
  SimpleFileUploadInputProps as BaseFileUploadInputProps
} from 'src/components/elements/file-upload-input';
import { useUploadFile } from '../api/createUpload';
import { Upload } from '../types';

// 10MiB limit on uploads
const MAX_FILE_SIZE = 1024 * 10 ** 4;

const getUploadKey = property<Upload, Key>('id');
export const getUploadPreviewData: PreviewDataTransformer<Upload> = ({
  filename,
  original_file_name,
  public_url
}) => ({
  name: original_file_name ?? filename,
  url: public_url
});

export type SimpleFileUploadInputProps = Omit<
  BaseFileUploadInputProps<Upload>,
  'onFileSelect' | 'getFileKey' | 'getPreviewData'
>;

export const SimpleFileUploadInput = forwardRef<
  HTMLInputElement,
  SimpleFileUploadInputProps
>(({ maxFileSize = MAX_FILE_SIZE, ...props }, ref) => {
  const { mutateAsync } = useUploadFile();
  const fileUploader = useCallback<FileUploadFn<Upload>>(
    (file) => mutateAsync({ file }),
    [mutateAsync]
  );

  return (
    <BaseFileUploadInput
      ref={ref}
      onFileSelect={fileUploader}
      getFileKey={getUploadKey}
      getPreviewData={getUploadPreviewData}
      maxFileSize={Math.min(maxFileSize, MAX_FILE_SIZE)}
      {...props}
    />
  );
});

import PropTypes from 'prop-types';
import { bannerAdTypes, adContentSetTypes } from './spoke-value-lists';

const spokePropTypes = {
  adContentSetType: PropTypes.oneOf(Object.values(adContentSetTypes))
    .isRequired,
  adType: PropTypes.oneOf(Object.values(bannerAdTypes)).isRequired
};

export default spokePropTypes;

import { Box } from '@rexlabs/box';
import { margin, styled, StyleSheet } from '@rexlabs/styling';
import { mapValues } from 'lodash';
import React, { useMemo } from 'react';
import { Small } from 'src/view/components/text';
import { STATUS_STYLES } from '.';

const statusBackground = (status: string) => ({
  backgroundColor: ({ token }) => token(`campaignStatus.${status}`)
});

const defaultStyles = StyleSheet({
  container: {},

  statusIcon: {
    height: '1rem',
    width: '1rem',
    borderRadius: '1rem',
    ...margin.styles({
      right: 'xs'
    })
  },

  statusActive: statusBackground('active'),
  statusArchived: statusBackground('archived'),
  statusExpiring: statusBackground('expiring'),
  statusPaused: statusBackground('paused'),
  statusDraft: statusBackground('draft'),
  statusError: statusBackground('error'),
  statusInReview: statusBackground('inReview'),
  statusCompleted: statusBackground('completed'),
  statusScheduled: statusBackground('scheduled'),
  statusProcessing: statusBackground('processing')
});

function CampaignStatus({ styles: s, status, label }) {
  const statusStylesSelector = useMemo(
    () => mapValues(STATUS_STYLES, (s) => status === s),
    [status]
  );

  const newLabel = label === 'Completed' ? 'Just finished' : label;
  return (
    <Box flexDirection='row' alignItems='center' {...s('container')}>
      <Box {...s('statusIcon', statusStylesSelector)} />
      <Small grey>{newLabel || 'n/a'}</Small>
    </Box>
  );
}

export default styled(defaultStyles)(CampaignStatus);

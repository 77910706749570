import { Box } from '@rexlabs/box';
import { GhostButton } from '@rexlabs/button';
import { styled } from '@rexlabs/styling';
import React, { PureComponent } from 'react';
import { withToken } from 'src/theme';

@withToken
@styled({})
class Button extends PureComponent {
  render() {
    const { children, token, styles: s, ...props } = this.props;
    return (
      <Box ml={token('spacing.m')} {...s.with('container')(this.props)}>
        <GhostButton {...props}>{children}</GhostButton>
      </Box>
    );
  }
}

export default Button;

import { Box } from '@rexlabs/box';
import { GhostButton, PrimaryButton } from '@rexlabs/button';
import { StyleSheet } from '@rexlabs/styling';
import { autobind } from 'core-decorators';
import React, { PureComponent } from 'react';
import { withToken } from 'src/theme';
import { ModalStickyButtonGroup } from 'src/view/components/button';
import { Form, FormField, withForm } from 'src/view/components/form';
import { Select, TextInput } from 'src/view/components/input';
import { Modal } from 'src/view/components/modal';
import withError from 'src/view/containers/with-error';

import { createValidationRules } from 'src/utils/form';
import { Body } from 'src/view/components/text';

const modalStyles = StyleSheet({
  content: {
    paddingTop: '0'
  }
});

const validate = createValidationRules({
  status: ['string', 'status'],
  value: ['string', 'value']
});

const changeProvisionStepForm = {
  name: 'changeProvisionStep',
  handleSubmit: (values, { props }) =>
    props.handleSubmit(values, props.errorModal),
  mapPropsToValues: (props) => ({
    value: props.value,
    status: 'complete'
  }),
  validate
};

@withToken
@withError('errorModal')
@withForm(changeProvisionStepForm)
@autobind
class ChangeProvisionStepModal extends PureComponent {
  render() {
    const {
      errorModal: { Error },
      changeProvisionStep,
      closeModal,
      description,
      token
    } = this.props;
    return (
      <Box>
        <Box pb={token('spacing.xxl')}>
          <Body grey>{description}</Body>
          <Form name='changeProvisionStepForm'>
            <FormField
              name='status'
              label='Status'
              Input={Select}
              inputProps={{
                options: [
                  { label: 'Completed', value: 'complete' },
                  { label: 'Pending', value: 'pending' },
                  { label: 'Failed', value: 'failed' }
                ],
                isSearchable: false
              }}
            />
            <FormField
              name='value'
              label='Value'
              Input={TextInput}
              inputProps={
                {
                  // placeholder: 'e.g. N1231142DFG6'
                }
              }
            />
          </Form>
        </Box>
        <ModalStickyButtonGroup>
          <GhostButton onClick={closeModal}>Cancel</GhostButton>
          <PrimaryButton
            form='changeProvisionStepForm'
            onClick={changeProvisionStep.submitForm}
            isLoading={changeProvisionStep.isSubmitting}
          >
            Change Provision Step
          </PrimaryButton>
        </ModalStickyButtonGroup>
        <Error />
      </Box>
    );
  }
}

class Core extends PureComponent {
  render() {
    const { closeModal } = this.props;
    return (
      <Modal
        styles={modalStyles}
        title='Update Provision Step'
        subtitle={this.props.name}
        width='40rem'
        onClose={closeModal}
      >
        <ChangeProvisionStepModal {...this.props} />
      </Modal>
    );
  }
}

export default Core;

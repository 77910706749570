const adContentSetTypes = {
  SINGLE_LISTING_RETARGETING: 'single_listing.retargeting',
  SINGLE_LISTING_PROSPECTING: 'single_listing.prospecting',
  APPRAISAL_RETARGETING: 'appraisal.retargeting',
  APPRAISAL_PROSPECTING: 'appraisal.prospecting',
  AGENT_PROFILE_RETARGETING: 'agent_profile.retargeting',
  AGENT_PROFILE_PROSPECTING: 'agent_profile.prospecting',
  MULTI_LISTING_SOLD_RETARGETING: 'multi_listing.sold.retargeting',
  MULTI_LISTING_SOLD_PROSPECTING: 'multi_listing.sold.prospecting'
};

const bannerAdTypes = {
  LEADERBOARD: 'leaderboard',
  PROTRAIT: 'portrait',
  HALF_PAGE: 'half_page',
  INLINE_RECTANGLE: 'inline_rectangle',
  LARGE_RECTANGLE: 'large_rectangle',
  SQUARE: 'square',
  SMALL_SQUARE: 'small_square',
  TRIPLE_WIDESCREEN: 'triple_widescreen',
  VERTICAL_RECTANGLE: 'vertical_rectangle',
  NETBAORD: 'netboard',
  SKYSCRAPER: 'skyscraper',
  WIDE_SKYSCRAPER: 'wide_skyscraper',
  BANNER: 'banner',
  TOP_BANNER: 'top_banner',
  LARGE_LEADERBOARD: 'large_leaderboard',
  BILLBOARD: 'billboard',
  PANORAMA: 'panorama',
  MOBILE_BANNER_LONG: 'mobile_banner_long',
  MOBILE_BANNER_SHORT: 'mobile_banner_short'
};

const facebookNetworkAdTypes = {
  CAROUSEL: 'carousel',
  SINGLE_IMAGE: 'single_image',
  SINGLE_IMAGE_SECONDARY: 'single_image_secondary'
};

export { adContentSetTypes, bannerAdTypes, facebookNetworkAdTypes };

import React, { FC, useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import { useToast } from '@rexlabs/notifications';
import { StepEditorLayout } from 'src/features/wizard/components/StepEditorLayout';
import { ContentTypeEditorProps } from 'src/features/wizard';
import {
  ResourceDownloadForm,
  ResourceDownloadSubmitHandler
} from './ResourceDownloadForm';
import { useResourceDownload } from 'src/features/resource-downloads';
import { LoadingSpinner } from 'view/components/loading';
import { updateResourceDownload } from 'src/features/resource-downloads/api/updateResourceDownload';
import { ResourceDownloadFormGuide } from './ResourceDownloadFormGuide';
import { StyleSheet, useStyles } from '@rexlabs/styling';

const styles = StyleSheet({
  loadingContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: '100%',
    height: '100%'
  }
});

export const ResourceDownloadEditForm: FC<ContentTypeEditorProps> = ({
  selectContent,
  contentId
}) => {
  const navigate = useNavigate();
  const toasts = useToast();
  const s = useStyles(styles);
  const { data, isFetching } = useResourceDownload({
    id: contentId ?? ''
  });

  const handleUpdate = useCallback<ResourceDownloadSubmitHandler>(
    async (data) => {
      if (contentId) {
        try {
          await updateResourceDownload({ id: contentId, data }).then((res) => {
            selectContent?.(res.id);
            navigate('../ResourceDownload');
          });
        } catch (e) {
          toasts.addToast({
            type: 'error',
            title: `Unable to edit resource download ${data.title}`,
            description: e instanceof Error ? e.message : undefined
          });
        }
      }
    },
    [contentId, navigate, selectContent, toasts]
  );

  const exitEditor = useCallback(() => navigate('..'), [navigate]);

  if (!data || isFetching) {
    return (
      <div {...s('loadingContainer')}>
        <LoadingSpinner />
      </div>
    );
  }

  return (
    <StepEditorLayout
      title='Upload Resource'
      Preview={<ResourceDownloadFormGuide />}
    >
      <ResourceDownloadForm
        submitLabel='Save'
        onSubmit={handleUpdate}
        onCancel={exitEditor}
        resource={data}
      />
    </StepEditorLayout>
  );
};

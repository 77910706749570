import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { styled, StyleSheet } from '@rexlabs/styling';
import Box from '@rexlabs/box';

import { SmallButton } from '../../button';
import _ from 'lodash';
import { autobind } from 'core-decorators';
import colorTheme from '../../../utils/color-theme';
import ScaledText from '../../scaled-text';
import charLimit, { limits } from '../../../utils/char-limit';
import { COLORS } from 'src/theme';

export const types = {
  DEFAULT: 'default',
  WIDE: 'wide'
};

const fontFamily = 'Lato, sans-serif';
const defaultStyles = StyleSheet({
  skyscraper: {
    border: '1px solid darkgray',
    overflow: 'hidden'
  },
  primaryImage: {
    objectFit: 'cover'
  },
  body: {
    padding: '22px 10px',
    backgroundColor: 'white',
    textAlign: 'center'
  },
  smallText: {
    fontSize: '12px',
    lineHeight: '16px',
    fontWeight: 600,
    fontFamily,
    textAlign: 'center',
    marginBottom: '13px',
    textTransform: 'uppercase'
  },
  hr: {
    border: 'solid 0.5px',
    width: 32,
    marginBottom: '8px'
  },
  shortHeadline: {
    fontSize: '18px',
    lineHeight: 1.3,
    fontFamily,
    fontWeight: 300,
    textAlign: 'center',
    marginBottom: '22px'
  },
  secondaryImage: {
    objectFit: 'cover'
  },
  footer: {},
  footerLogo: {
    objectFit: 'contain'
  }
});

@styled(defaultStyles)
@autobind
export default class Skyscrapper extends PureComponent {
  static propTypes = {
    bannerAdText: PropTypes.string,
    shortHeadline: PropTypes.string,
    primaryImageUrls: PropTypes.object,
    secondaryImageUrls: PropTypes.object,
    logoUrls: PropTypes.object,
    brandColor: PropTypes.string,
    backgroundColor: PropTypes.string,
    buttonText: PropTypes.string,
    type: PropTypes.oneOf(Object.values(types))
  };

  static defaultProps = {
    buttonText: 'View Property',
    type: types.DEFAULT
  };

  /**
   * Return the width for the given type of component
   * @return number
   */
  width() {
    const { type } = this.props;
    switch (type) {
      case types.WIDE:
        return 160;
      default:
        return 120;
    }
  }

  logoDimensions() {
    const { type } = this.props;
    return {
      maxWidth: `calc(0.6 * ${type === types.WIDE ? '160px' : '120px'})`,
      maxHeight: 'calc(0.7 * 60px)'
    };
  }

  /**
   * Get the value for the footer padding depending on the type of the component
   * @return string
   */
  footerPadding() {
    const { type } = this.props;
    switch (type) {
      case types.WIDE:
        return '10px 19px';
      default:
        return '12px 6px';
    }
  }

  render() {
    const {
      styles: s,
      primaryImageUrls,
      smallText,
      shortHeadline,
      secondaryImageUrls,
      logoUrls,
      brandColor,
      backgroundColor,
      buttonText,
      type
    } = this.props;

    const { brandColorBorder } = colorTheme(brandColor);

    return (
      <Box
        width={this.width()}
        height={600}
        flexDirection='column'
        {...s('skyscraper')}
      >
        <img
          src={_.get(primaryImageUrls, ['crop.square', 'url'])}
          width='100%'
          height={150}
          {...s('primaryImage')}
        />
        <Box
          width='100%'
          flexDirection='column'
          height={232}
          {...s('body')}
          alignItems='center'
          justifyContent='center'
        >
          <span {...s.with('smallText')({ color: COLORS.BLACK })}>
            {charLimit(smallText, limits.SMALL_TEXT)}
          </span>
          <hr {...s.with('hr')({ color: COLORS.BLACK })} />
          <ScaledText
            maxWidth={type === types.WIDE ? 138 : 100}
            maxTextLines={4}
            maxFontSize={24}
            minFontSize={12}
            {...s.with('shortHeadline')({ color: COLORS.BLACK })}
          >
            {shortHeadline}
          </ScaledText>
          <SmallButton brandColor={brandColor}>{buttonText}</SmallButton>
        </Box>
        <img
          src={_.get(secondaryImageUrls, ['crop.square', 'url'])}
          height={160}
          width='100%'
          {...s('secondaryImage')}
        />
        <Box
          height={58}
          display='flex'
          flexDirection='column'
          alignItems='center'
          justifyContent='center'
          padding={this.footerPadding()}
          {...s.with('footer')({
            backgroundColor: backgroundColor,
            borderTop: brandColorBorder
          })}
        >
          <img
            src={_.get(logoUrls, 'original.url')}
            {...s.with('footerLogo')({ ...this.logoDimensions() })}
          />
        </Box>
      </Box>
    );
  }
}

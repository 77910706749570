import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import Box from '@rexlabs/box';

import { styled, StyleSheet } from '@rexlabs/styling';
import ScaledText from '../../scaled-text';
import { COLORS } from 'src/theme';
import _ from 'lodash';

export const types = {
  DEFAULT: 'default',
  LARGE: 'large'
};

const fontFamily = 'Lato, sans-serif';
const defaultStyles = StyleSheet({
  mobileBanner: {
    display: 'flex',
    overflow: 'hidden',
    height: 50,
    border: '1px solid darkgray'
  },
  primaryImage: {
    height: '100%',
    width: '50px',
    objectFit: 'cover'
  },
  shortHeadlineWrapper: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    width: '75px',
    backgroundColor: '#F8F8F8',
    padding: '0 14px'
  },
  shortHeadline: {
    fontFamily,
    fontSize: '10px',
    fontWeight: 600,
    lineHeight: '12px',
    textTransform: 'uppercase'
  },
  agentDetails: {
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    padding: '0 14px',
    backgroundColor: 'white'
  },
  agentName: {
    fontFamily,
    fontWeight: 300
  },
  suburb: {
    fontFamily,
    fontSize: '10px',
    fontWeight: 600,
    lineHeight: '12px',
    textTransform: 'uppercase'
  }
});

@styled(defaultStyles)
class MobileBanner extends PureComponent {
  static propTypes = {
    agentName: PropTypes.string,
    suburb: PropTypes.string,
    brandColor: PropTypes.string,
    primaryImageUrls: PropTypes.object,
    type: PropTypes.oneOf(Object.values(types))
  };

  static defaultProps = {
    type: types.DEFAULT
  };

  render() {
    const {
      agentName,
      agentPosition,
      agencyName,
      primaryImageUrls,
      type,
      styles: s
    } = this.props;

    return (
      <Box
        {...s.with('mobileBanner')({
          width: type === types.DEFAULT ? 300 : 320
        })}
      >
        <img
          {...s('primaryImage')}
          src={_.get(primaryImageUrls, ['crop.square', 'url'])}
        />
        <Box {...s('shortHeadlineWrapper')}>
          <span {...s.with('shortHeadline')({ color: COLORS.BLACK })}>
            selling soon?
          </span>
        </Box>
        <Box {...s('agentDetails')}>
          <ScaledText
            maxWidth={134}
            maxTextLines={2}
            maxFontSize={18}
            minFontSize={12}
            {...s.with('agentName')({ color: COLORS.BLACK })}
          >
            {agentName}
          </ScaledText>
          <span {...s.with('suburb')({ color: COLORS.BLACK })}>
            {agentPosition} - {agencyName}
          </span>
        </Box>
      </Box>
    );
  }
}

export default MobileBanner;

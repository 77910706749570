import VividActionMenu from '@rexlabs/action-menu';
import React from 'react';

import ActionMenuIcon from 'src/assets/icons/action-menu.svg';

const ActionMenu = ({ buttonAriaLabel, ...props }) => {
  return (
    <VividActionMenu
      arrowMargin='3rem'
      Button={(props) => (
        <div {...props} aria-label={buttonAriaLabel}>
          <ActionMenuIcon />
        </div>
      )}
      {...props}
    />
  );
};

export default ActionMenu;

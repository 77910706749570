import { Box } from '@rexlabs/box';
import { styled, StyleSheet } from '@rexlabs/styling';
import { mapValues } from 'lodash';
import React, { useMemo } from 'react';
import Money from 'src/view/components/money';
import { Tiny } from 'src/view/components/text';
import { STATUS, STATUS_STYLES } from '.';

const statusColor = (status) => ({
  color: ({ token }) => token(`campaignStatus.${status}`)
});

const defaultStyles = StyleSheet({
  container: {
    width: '18rem',
    paddingTop: '.4rem'
  },

  wrapProgress: {
    position: 'relative',
    width: '100%',
    color: ({ token }) => token('color.neutral.idle.default')
  },

  svgProgress: {
    height: 'auto',
    width: '100%',
    marginBottom: '-.4rem'
  },

  progressBar: {
    width: '100%',
    height: '.8rem',
    borderRadius: '.8rem',
    background: ({ token }) => token('color.neutral.idle.default')
  },

  progress: {
    height: '.8rem',
    borderRadius: '.8rem',
    background: 'currentColor'
  },

  recurringActive: {
    '&::before': {
      content: '" "',
      position: 'absolute',
      top: '.8rem',
      left: '1.8rem',
      borderStyle: 'solid',
      borderWidth: '.4rem 0 .4rem .6rem',
      borderColor: 'transparent transparent transparent currentColor'
    }
  },

  recurringPause: {
    '&::after': {
      content: '" "',
      position: 'absolute',
      top: '.8rem',
      left: '2.5rem',
      width: '.2rem',
      height: '.6rem',
      borderLeft: '.2rem solid currentColor',
      borderRight: '.2rem solid currentColor',
      boxSizing: 'content-box'
    }
  },

  recurringExpiring: {
    '&::after': {
      content: '" "',
      position: 'absolute',
      top: '.8rem',
      left: '2.8rem',
      width: '.8rem',
      height: '.8rem',
      background: 'currentColor'
    }
  },

  statusActive: statusColor('active'),
  statusArchived: statusColor('archived'),
  statusExpiring: statusColor('expiring'),
  statusPaused: statusColor('paused'),
  statusDraft: statusColor('draft'),
  statusError: statusColor('error'),
  statusInReview: statusColor('inReview'),
  statusCompleted: statusColor('completed'),
  statusScheduled: statusColor('scheduled'),
  statusProcessing: statusColor('processing')
});

const CampaignProgress = ({
  status,
  plan,
  recurring,
  lastWeek,
  styles: s,
  duration,
  spent,
  campaign
}) => {
  const statusStyleSelector = useMemo(
    () => mapValues(STATUS_STYLES, (s) => status === s),
    [status]
  );

  const customPlan = plan?.is_custom;
  let dashArray = '15 20 90000';
  if (status === STATUS.ACTIVE) {
    dashArray = '15 12 90000';
  } else if (status === STATUS.EXPIRING) {
    dashArray = '15 25 90000';
  }

  // Determine progress
  let progress = [STATUS.ARCHIVED, STATUS.COMPLETED].includes(status) ? 1 : 0;

  if (status === STATUS.IN_REVIEW) {
    progress = 0;
  }

  if (duration && spent) {
    progress = spent / duration;
  }

  // Determine progress string
  let progressString = ' ∙ Not started';

  if (progress === 1) {
    progressString = '';
  }

  if (spent && duration) {
    progressString = ` ∙ ${duration - spent + 1} days left`;

    if (recurring && campaign?.renewals?.total) {
      progressString = (
        <span>
          <br /> Week {campaign?.renewals?.total - campaign?.renewals?.left + 1}{' '}
          of {campaign?.renewals?.total + 1}
        </span>
      );
    }

    if (recurring && campaign?.renewals?.total === null) {
      progressString = (
        <span>
          <br /> Recurring
        </span>
      );
    }
  }

  return (
    <Box {...s('container')}>
      <Box
        {...s('wrapProgress', {
          recurringActive:
            recurring && [STATUS.ACTIVE, STATUS.EXPIRING].includes(status),
          recurringPause:
            recurring && [STATUS.PAUSED, STATUS.ERROR].includes(status),
          // HACK: well seems like a hack because the a recurring campaign
          // never gets an expiring status so if its active and its in the last week.
          recurringExpiring:
            recurring &&
            (status === STATUS.EXPIRING ||
              (status === STATUS.ACTIVE && lastWeek)),
          ...statusStyleSelector
        })}
      >
        {recurring &&
        ![
          STATUS.DRAFT,
          STATUS.IN_REVIEW,
          STATUS.ARCHIVED,
          STATUS.COMPLETED,
          STATUS.SCHEDULED
        ].includes(status) ? (
          // outline progress bar
          <svg viewBox='0 0 194 10' {...s('svgProgress')}>
            <g stroke='none' fill='none'>
              <rect
                id='rect'
                stroke='currentColor'
                strokeDasharray={dashArray}
                strokeWidth='2'
                width='192'
                height='8'
                x='1'
                y='1'
                rx='4'
              />
            </g>
          </svg>
        ) : (
          // solid progress bar
          <Box {...s('progressBar')}>
            <Box
              {...s('progress')}
              style={{
                width: `${progress * 100}%`
              }}
            />
          </Box>
        )}
      </Box>
      <Tiny grey Tag='span'>
        {plan ? `${plan.name} plan ` : 'No plan selected'}
        {customPlan && (
          <Money
            amount={plan.price.value}
            currencyCode={plan.price.currency_code}
            integer={true}
          />
        )}
        {progressString}
      </Tiny>
    </Box>
  );
};

export default styled(defaultStyles)(CampaignProgress);

import {
  AnyObject,
  ComponentMapper,
  componentTypes,
  FormSpy,
  UseFieldApiConfig
} from '@data-driven-forms/react-form-renderer';
import { TextInput } from '@rexlabs/text-input';
import React, { ComponentType, FC } from 'react';
import { ImageUploadInput } from 'src/features/uploads';
import { Select, TextArea } from 'src/view/components/input';
import { ColorPickerInput } from '../elements/color-picker-input';
import { Separator } from '../elements/separator';
import { CheckboxField } from './CheckboxField';
import { DynamicFieldArray } from './DynamicFieldArray';
import { DynamicFormField } from './DynamicFormField';
import { TwoColumns } from './TwoColumns';
import { SubForm } from 'src/components/dynamic-forms/SubForm';
import { SwitchField } from 'src/components/dynamic-forms/SwitchField';
import { VideoUploadInput } from 'src/features/uploads/components/VideoUploadInput';
import { DynamicAgentSelectorInput } from 'src/features/agents/components/DynamicAgentSelectorInput';
import LogoImageInput from 'view/components/input/image/logo-image-input';
import RangeInput from 'view/components/input/range-slider/range-input';

const createFormFieldComponent =
  (Input: ComponentType<AnyObject>): FC<UseFieldApiConfig> =>
  (props) =>
    <DynamicFormField Input={Input} {...props} />;

const LogoWrapped = (props) => (
  <FormSpy subscription={{ values: true }}>
    {(formState) => (
      <LogoImageInput
        {...props}
        scale={formState.values.logo_scale ?? formState.values.logo_scale_lp}
        backgroundColor={
          formState.values.logo_bg_color ?? formState.values.logo_bg_color_lp
        }
      />
    )}
  </FormSpy>
);

export const componentMapper: ComponentMapper = {
  [componentTypes.TEXT_FIELD]: createFormFieldComponent(TextInput),
  [componentTypes.SELECT]: createFormFieldComponent(Select),
  color: createFormFieldComponent(ColorPickerInput),
  image: createFormFieldComponent(ImageUploadInput),
  [componentTypes.TEXTAREA]: createFormFieldComponent(TextArea),
  [componentTypes.FIELD_ARRAY]: createFormFieldComponent(
    DynamicFieldArray as ComponentType<AnyObject>
  ),
  [componentTypes.CHECKBOX]: CheckboxField,
  separator: Separator,
  'two-columns': TwoColumns,
  [componentTypes.SUB_FORM]: SubForm,
  [componentTypes.SWITCH]: SwitchField,
  video: createFormFieldComponent(VideoUploadInput as ComponentType<AnyObject>),
  'agent-selector': createFormFieldComponent(
    DynamicAgentSelectorInput as ComponentType<AnyObject>
  ),
  logo: createFormFieldComponent(LogoWrapped),
  range: createFormFieldComponent(RangeInput)
};

import { Box } from '@rexlabs/box';
import { DestructiveButton } from '@rexlabs/button';
import { Portal } from '@rexlabs/portal';
import { styled, StyleSheet } from '@rexlabs/styling';
import React, { PureComponent } from 'react';
import { BORDER_RADIUS, FONT, SHADOWS, withToken } from 'src/theme';
import { Heading } from 'src/view/components/text';

const defaultStyles = StyleSheet({
  container: {
    maxHeight: '90vh',
    width: '90vw',
    overflow: 'hidden',
    background: ({ token }) => token('legacy.color.red.light'),
    color: ({ token }) => token('legacy.color.white'),
    borderRadius: BORDER_RADIUS.INPUT,
    boxShadow: SHADOWS.MODAL,
    textAlign: 'center',
    zIndex: 10
  },

  title: {
    marginBottom: '2rem'
  },

  content: {
    padding: ({ token }) => token('spacing.xl'),
    overflow: 'auto',
    flexShrink: 0,
    position: 'relative',
    overflowWrap: 'break-word',
    hyphens: 'auto'
  }
});

@withToken
@styled(defaultStyles)
class ErrorModal extends PureComponent {
  static defaultProps = {
    target: 'modals',
    width: '35rem'
  };

  render() {
    const {
      title,
      children,
      target,
      styles: s,
      onClose,
      width,
      token
    } = this.props;
    return (
      <Portal target={target}>
        <Box {...s('container')} flexDirection='column' style={{ width }}>
          <Box flex={1} flexDirection='column' {...s('content')}>
            {!!title && <Heading {...s('title')}>{title}</Heading>}
            {children}
            <Box mt={token('spacing.xxxl')}>
              <DestructiveButton onClick={onClose}>Got it</DestructiveButton>
            </Box>
          </Box>
        </Box>
      </Portal>
    );
  }
}

export default ErrorModal;

import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { styled, StyleSheet } from '@rexlabs/styling';
import Box from '@rexlabs/box';
import _ from 'lodash';
import { RegularButton } from '../../button';
import GooText from '../../goo-text';
import colorTheme from '../../../utils/color-theme';
import ScaledText from '../../scaled-text';
import { COLORS } from 'src/theme';

export const types = {
  DEFAULT: 'default',
  LARGE: 'large',
  SQUARE: 'square',
  SMALL: 'small'
};

const fontFamily = 'Lato, sans-serif';

const defaultStyles = StyleSheet({
  inlineRectangle: {
    border: 'solid 1px darkgray',
    flexGrow: 0,
    flexShrink: 0
  },
  header: {
    position: 'relative'
  },
  primaryImg: {
    objectFit: 'cover'
  },
  overlay: {
    position: 'absolute',
    top: '14px',
    left: '12px'
  },
  content: {
    background: 'white'
  },
  shortHeadline: {
    fontSize: '24px',
    lineHeight: '28px',
    fontWeight: '400',
    padding: '6px 10px',
    backgroundColor: 'white',
    fontFamily
  },
  footer: {
    justifyContent: 'space-between',
    padding: '15px 17px'
  },
  logo: {
    display: 'block',
    maxWidth: '100%',
    maxHeight: 'calc(0.66 * 60px)',
    objectFit: 'contain'
  }
});

@styled(defaultStyles)
class InlineRectangle extends PureComponent {
  static propTypes = {
    brandColor: PropTypes.string,
    shortHeadline: PropTypes.string,
    primaryImageUrls: PropTypes.object,
    logoUrls: PropTypes.object,
    buttonText: PropTypes.string,

    width: PropTypes.number,
    height: PropTypes.number,
    imageHeight: PropTypes.number,
    textWidth: PropTypes.number
  };

  static defaultProps = {
    buttonText: 'Learn more',
    width: 300,
    height: 250,
    imageHeight: 194,
    textWidth: 204,
    maxFontSize: 24,
    minFontSize: 16,
    type: types.DEFAULT
  };

  logoWrapperDimensions() {
    const { type } = this.props;
    switch (type) {
      case types.LARGE:
        return {
          maxWidth: '65%',
          maxHeight: 'calc(0.7 * 66px)'
        };
      case types.SQUARE:
        return {
          maxWidth: '46%',
          maxHeight: 'calc(0.7 * 80px)'
        };
      case types.SMALL:
        return {
          maxWidth: 'calc(40% - 10px)',
          marginRight: '10px',
          maxHeight: 'calc(0.7 * 62px)'
        };
      case types.DEFAULT:
      default:
        return {
          maxWidth: '65%',
          maxHeight: 'calc(0.7 * 66px)'
        };
    }
  }

  render() {
    const {
      brandColor,
      backgroundColor,
      shortHeadline,
      primaryImageUrls,
      logoUrls,
      buttonText,
      width,
      height,
      imageHeight,
      maxFontSize,
      minFontSize,
      styles: s
    } = this.props;

    const { brandColorBorder } = colorTheme(brandColor);

    return (
      <Box
        width={width}
        height={height}
        display='flex'
        flexDirection='column'
        {...'inlineRectangle'}
      >
        <Box height={imageHeight} {...s('header')}>
          <Box {...s('overlay')}>
            <GooText padding='0' borderRadius={1.5}>
              <ScaledText
                maxWidth={width - 50}
                maxTextLines={2}
                maxFontSize={maxFontSize}
                minFontSize={minFontSize}
                {...s.with('shortHeadline')({ color: COLORS.BLACK })}
              >
                {shortHeadline}
              </ScaledText>
            </GooText>
          </Box>
          <img
            width='100%'
            height={imageHeight}
            src={_.get(primaryImageUrls, ['crop.landscape', 'url'])}
            {...s('primaryImg')}
          />
        </Box>
        <Box
          flex={1}
          display='flex'
          flexDirection='row'
          alignItems='center'
          {...s.with('footer')({
            backgroundColor: backgroundColor,
            borderTop: brandColorBorder
          })}
        >
          <Box alignItems='center' style={{ ...this.logoWrapperDimensions() }}>
            <img src={_.get(logoUrls, 'original.url')} {...s('logo')} />
          </Box>
          <Box>
            <RegularButton brandColor={brandColor} invert={false}>
              {buttonText}
            </RegularButton>
          </Box>
        </Box>
      </Box>
    );
  }
}

export default InlineRectangle;

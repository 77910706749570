import React from 'react';
import getFakeEvent from 'src/utils/fake-event';
import {
  OptionWithDescription,
  Select,
  SelectedOptionWithDescription
} from 'src/view/components/input';

const capabilities = {
  standard: { create_campaigns: true, publish_campaigns: true },
  publish_only: { create_campaigns: false, publish_campaigns: true },
  create_only: { create_campaigns: true, publish_campaigns: false }
};

export const usersCapabilities = (capability) => {
  if (!capability) return;

  if (typeof capability === 'string') {
    return capabilities[capability];
  }

  /* eslint-disable camelcase */
  const { create_campaigns, publish_campaigns } = capability;

  if (create_campaigns && publish_campaigns) {
    return 'standard';
  } else if (create_campaigns && !publish_campaigns) {
    return 'create_only';
  } else {
    return 'publish_only';
  }
  /* eslint-enable camelcase */
};

function UserCapabilitiesInput({ value, name, onChange }) {
  const handleSelect = (selected) => {
    const fakeEvent = getFakeEvent('select', name, name, selected.value);
    if (onChange) {
      onChange(fakeEvent);
    }
  };

  return (
    <Select
      value={value}
      onSelect={handleSelect}
      options={[
        {
          value: 'standard',
          label: 'Standard',
          description:
            'The user has full access to campaign creation and launching.'
        },
        {
          value: 'publish_only',
          label: 'Launch Only',
          description:
            'The user can only launch draft campaigns but can not create campaigns'
        },
        {
          value: 'create_only',
          label: 'Create Only',
          description:
            'The user can only create draft campaigns but can not launch campaigns'
        }
      ]}
      Option={OptionWithDescription}
      OptionSelected={SelectedOptionWithDescription}
    />
  );
}

export default UserCapabilitiesInput;

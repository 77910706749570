import { Box } from '@rexlabs/box';
import { GhostButton, PrimaryButton } from '@rexlabs/button';
import {
  margin,
  padding,
  StyleSheet,
  useStyles,
  useToken
} from '@rexlabs/styling';
import { Body, Small } from '@rexlabs/text';
import React, { useMemo, useState } from 'react';
import { ModalStickyButtonGroup } from 'src/view/components/button';
import { GridCell, GridRow } from 'src/view/components/grid';
import Money from 'src/view/components/money';
import { PlanItem } from 'src/view/components/plan';

const defaulStyles = StyleSheet({
  highestPlanWrapper: {
    textAlign: 'center',
    ...padding.styles({
      all: 'xxxl'
    })
  },
  estimatesError: {
    color: ({ token }) => token('color.textStyle.warning.idle'),
    ...margin.styles({
      top: () => '1rem'
    })
  }
});

export function ChangePlanSelector({
  currentPlan,
  newPlanId,
  plans,
  estimates,
  campaignType,
  duration,
  willRenew,
  cancelChange,
  confirmNewPlan,
  failureText
}) {
  const token = useToken();
  const s = useStyles(defaulStyles);
  const [selectedPlanId, setSelectedPlanId] = useState(newPlanId);
  const selectedPlanData = useMemo(
    () => plans.find((p) => p.id === selectedPlanId),
    [plans, selectedPlanId]
  );

  return (
    <Box flex={1} flexDirection='column'>
      <Box
        p={token('spacing.m')}
        style={{
          width: '100%',
          background: token('legacy.color.grey.light')
        }}
      >
        <GridRow gutter={token('spacing.xxs')}>
          <GridCell gutter={token('spacing.xxs')} width={1 / 4}>
            <Box mb={token('spacing.m')}>
              <Small grey>Current plan</Small>
              <Body>
                {currentPlan?.name}
                <br />
                <Money
                  amount={currentPlan?.price?.value}
                  currencyCode={currentPlan?.price?.currency_code}
                />
              </Body>
            </Box>
            <Box mb={token('spacing.m')}>
              <Small grey>Days remaining</Small>
              <Body>{duration?.total - duration?.spent + 1}</Body>
            </Box>

            {willRenew &&
              selectedPlanId &&
              currentPlan.id !== selectedPlanId && (
                <Box mb={token('spacing.m')}>
                  <Small grey>Next plan</Small>
                  <Body>
                    {selectedPlanData?.name}
                    <br />
                    <Money
                      amount={selectedPlanData?.price.value}
                      currencyCode={selectedPlanData?.price.currency_code}
                    />
                  </Body>
                </Box>
              )}
          </GridCell>
          <GridCell gutter={token('spacing.xxs')} width={4 / 5}>
            <GridRow gutter={token('spacing.xxs')}>
              {plans.length ? (
                plans.map((plan) => {
                  const id = plan.id;
                  const price = plan.price.value;
                  const isUpgrade = currentPlan?.price.value < price;

                  return (
                    <GridCell
                      gutter={token('spacing.xxs')}
                      width={1 / 2}
                      key={id}
                    >
                      <PlanItem
                        plan={plan}
                        type={campaignType}
                        estimate={estimates.find((e) => e.id === plan.id)}
                        onSelect={() => setSelectedPlanId(plan.id)}
                        selected={selectedPlanId === id}
                        upgrade={isUpgrade}
                        downgrade={!isUpgrade}
                        currentDuration={duration}
                        currentPlan={currentPlan}
                      />
                    </GridCell>
                  );
                })
              ) : (
                <Box {...s('highestPlanWrapper')}>
                  <Body grey>
                    You are already on the highest plan for this campaign. There
                    are no upgrades available.
                  </Body>
                </Box>
              )}
            </GridRow>
            {!!estimates?.[0]?.reach?.min && (
              <Body {...s('estimatesError')}>
                * Oops, we couldn{'’'}t get estimates from {failureText}, the
                actual number may be higher
              </Body>
            )}
          </GridCell>
        </GridRow>

        <ModalStickyButtonGroup padded>
          <GhostButton onClick={() => cancelChange()}>Cancel</GhostButton>
          <PrimaryButton
            onClick={() => confirmNewPlan(selectedPlanId)}
            isDisabled={!selectedPlanData}
          >
            Next
          </PrimaryButton>
        </ModalStickyButtonGroup>
      </Box>
    </Box>
  );
}

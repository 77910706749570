/* eslint-disable @typescript-eslint/no-var-requires */
export default {
  ACCOUNTS: {
    config: {
      exact: true,
      path: '/sysadmin/accounts',
      Component: require('view/screens/sysadmin/account/list').default
    }
  },

  PROVISION_STEPS: {
    config: {
      path: '/sysadmin/accounts/:accountId/provision-steps/:provisionId',
      Component: require('view/screens/sysadmin/provision-steps/list').default
    }
  },

  ADS: {
    config: {
      path: '/sysadmin/accounts/:accountId/ads',
      Component: require('view/screens/sysadmin/ads/list').default
    }
  },

  CAMPAIGN_PERFORMANCE: {
    config: {
      path: '/sysadmin/campaign-performance',
      Component: require('view/screens/sysadmin/campaign-performance/list')
        .default
    }
  }
};

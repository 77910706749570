import React, { PureComponent } from 'react';
import { Body, Placeholder } from 'src/view/components/text';

class SelectPillsValue extends PureComponent {
  render() {
    const { selected, placeholder } = this.props;
    return selected.length > 1 ? (
      <Body>{selected.length} selected</Body>
    ) : selected.length === 1 ? (
      <Body>{selected[0].label}</Body>
    ) : (
      <Placeholder>{placeholder}</Placeholder>
    );
  }
}

export default SelectPillsValue;

import { StyleSheet, useStyles } from '@rexlabs/styling';
import { TextArea as VividTextArea } from '@rexlabs/text-input';
import React from 'react';
import { standardStyles } from '../text/text';

export const standardTextAreaStyles = {
  ...standardStyles,

  container: {
    ...standardStyles.container,
    flex: 1,
    height: 'auto !important',
    paddingTop: ({ token }) => `${token('spacing.m')} !important`,
    paddingBottom: ({ token }) => `${token('spacing.m')} !important`
  },

  textArea: {
    border: 'none',
    paddingLeft: 0,
    paddingRight: 0,
    minHeight: '60px',
    height: 'auto'
  }
};

const textInputStyles = StyleSheet(standardTextAreaStyles);

const TextArea = (props) => {
  const s = useStyles(textInputStyles);
  return (
    <VividTextArea {...s('textArea')} containerProps={{ flex: 1 }} {...props} />
  );
};

export default TextArea;

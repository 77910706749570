import { Portal } from '@rexlabs/portal';
import { styled, StyleSheet } from '@rexlabs/styling';
import { autobind } from 'core-decorators';
import React, { PureComponent } from 'react';
import { BORDER_RADIUS, FONT, SHADOWS } from 'src/theme';

import HelpIcon from 'src/assets/icons/help.svg';

const defaultStyles = StyleSheet({
  noteContainer: {
    background: ({ token }) => token('legacy.color.white'),
    padding: ({ token }) => token('spacing.xl'),
    marginTop: ({ token }) => token('spacing.xs'),
    borderRadius: BORDER_RADIUS.INPUT,
    boxShadow: SHADOWS.MAP,
    fontSize: '1.4rem',
    color: ({ token }) => token('legacy.color.blue.grey')
  },

  title: {
    fontSize: '1.6rem',
    fontWeight: FONT.WEIGHTS.SEMIBOLD,
    color: ({ token }) => token('legacy.color.blue.dark'),
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    paddingBottom: ({ token }) => token('spacing.xs')
  },

  icon: {
    color: ({ token }) => token('legacy.color.blue.dark'),
    marginRight: ({ token }) => token('spacing.m')
  }
});

@styled(defaultStyles)
@autobind
class PortalInfo extends PureComponent {
  constructor(props) {
    super(props);
    this.state = { loaded: false };

    this.Title = ({ children, ...otherProps }) => (
      <h3 {...props.styles('title')} {...otherProps}>
        <HelpIcon {...props.styles('icon')} />
        <span>{children}</span>
      </h3>
    );
  }

  componentDidMount() {
    this.setState({ loaded: true });
  }

  render() {
    const { Info, children, marginTop, styles: s } = this.props;
    return (
      <div>
        {children}
        {this.state.loaded && (
          <Portal target='contentRight'>
            <div {...s.with('noteContainer')({ marginTop })}>
              <Info Title={this.Title} />
            </div>
          </Portal>
        )}
      </div>
    );
  }
}

export default PortalInfo;

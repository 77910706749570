import { Box } from '@rexlabs/box';
import { styled, StyleSheet, useToken } from '@rexlabs/styling';
import moment from 'moment';
import React from 'react';
import Money from 'src/view/components/money';
import { Small } from 'src/view/components/text';

import AttachmentIcon from 'src/assets/icons/attachment.svg';

const defaultStyles = StyleSheet({
  container: {
    width: '100%',
    padding: ({ token }) => token('spacing.m'),
    flexShrink: 0,

    '> *': {
      flexShrink: 0
    }
  }
});

function TransactionListItem({ styles: s, data }) {
  const token = useToken();

  return (
    <Box
      {...s('container')}
      flexDirection='row'
      alignItems='center'
      justifyContent='space-between'
    >
      <Box style={{ width: '12rem' }} pr={token('spacing.xl')}>
        <Small grey>{moment(data.effective_date).format('DD/MM/YYYY')}</Small>
      </Box>

      <Box style={{ width: '12rem' }} pr={token('spacing.xl')}>
        <Small grey>
          SDK-
          {data?.invoice?.number || '-'}
        </Small>
      </Box>
      <Box style={{ width: '24rem' }} pr={token('spacing.xl')}>
        <Small grey>{data?.description || '-'}</Small>
      </Box>

      <Box flex={1} style={{ textAlign: 'right' }} pr={token('spacing.xl')}>
        <Small grey style={{ width: '100%' }}>
          <Money
            amount={data?.invoice?.billed_amount?.value}
            currencyCode={data?.invoice?.billed_amount?.currency_code}
          />
        </Small>
      </Box>

      <Box
        style={{ width: '7rem' }}
        justifyContent='center'
        alignItems='center'
      >
        <a
          href={data?.invoice?.pdf_uri}
          target='_blank'
          rel='noopener noreferrer'
        >
          <AttachmentIcon />
        </a>
      </Box>
    </Box>
  );
}

export default styled(defaultStyles)(TransactionListItem);

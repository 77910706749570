import { PrimaryButton } from '@rexlabs/button';
import React, { FC, useCallback } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useCreateCampaignFromDefinition } from 'src/features/campaigns';
import { useContentTypeSelections } from '../../stores/contentTypes';
import { getContentTypeDefinition } from '../../utils/contentTypes';
import { ContentTypeSelector } from '../ContentTypeSelector';
import { StepPreviewFooter } from '../StepPreviewFooter';
import { StepPreviewLayout } from '../StepPreviewLayout';

const ProvideDetailsCreateStep: FC = () => {
  const {
    campaignDefinitionId = '',
    accountIndex,
    contentType = ''
  } = useParams();
  const navigate = useNavigate();
  const { mutateAsync } = useCreateCampaignFromDefinition();
  const { title, subtitle } = getContentTypeDefinition(contentType);
  const { contentSources, isValid } = useContentTypeSelections();

  const handleCreate = useCallback(async () => {
    const campaign = await mutateAsync({
      campaignDefinitionId,
      contentSources: Object.entries(contentSources).flatMap(([type, ids]) =>
        ids.map((id) => ({ type, id }))
      )
    });
    navigate(`/${accountIndex}/campaigns/${campaign.id}/edit/d`);
  }, [
    accountIndex,
    campaignDefinitionId,
    contentSources,
    mutateAsync,
    navigate
  ]);

  return (
    <StepPreviewLayout title={title} subtitle={subtitle}>
      <ContentTypeSelector />
      <StepPreviewFooter backTo={`/${accountIndex}/campaigns/create`}>
        <PrimaryButton isDisabled={!isValid} onClick={handleCreate}>
          Create
        </PrimaryButton>
      </StepPreviewFooter>
    </StepPreviewLayout>
  );
};

export default ProvideDetailsCreateStep;

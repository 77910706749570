import { Cancel } from '@radix-ui/react-alert-dialog';
import { ButtonProps, GhostButton } from '@rexlabs/button';
import React, { FC, ReactNode } from 'react';

export type AlertCancelProps = ButtonProps & {
  children?: ReactNode;
};

export const AlertCancel: FC<AlertCancelProps> = ({
  children = 'Cancel',
  ...rest
}) => {
  return (
    <Cancel asChild>
      <GhostButton {...rest}>{children}</GhostButton>
    </Cancel>
  );
};

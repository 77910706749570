import { property } from 'lodash';
import React, { FC, forwardRef, Key, useCallback } from 'react';
import {
  FileDisplayProps,
  FileUploadFn,
  FileUploadInput,
  PreviewData,
  PreviewFileUploadInputProps
} from 'src/components/elements/file-upload-input';
import { PreviewEmptyInput } from 'src/components/elements/file-upload-input/preview/PreviewEmptyInput';
import { useCreateImage } from '../api/createImage';
import { useUploadFile } from '../api/createUpload';
import { Image } from '../types';
import { ImageFileDisplay } from './ImageFileDisplay';
import { Consumer } from 'src/types';

const LIBRARY_IMAGE_ACCEPT = 'image/*';

const getLibraryImageKey = property<Image, Key>('id');
const getLibraryImagePreview = ({ name, sizes }: Image): PreviewData => ({
  name,
  url: sizes?.['original.thumbnail'].url
});

export type ImageUploadInputProps = Omit<
  PreviewFileUploadInputProps<Image>,
  'onFileSelect' | 'getFileKey' | 'getPreviewData' | 'accept'
> & {
  croppable?: boolean;
  fullWidth?: boolean;
};

export const ImageUploadInput = forwardRef<
  HTMLInputElement,
  ImageUploadInputProps
>(({ croppable = true, ...props }, ref) => {
  const { mutateAsync: uploadFile } = useUploadFile();
  const { mutateAsync: createImage } = useCreateImage();
  const { onChange, fullWidth = false } = props;

  const fileUploader = useCallback<FileUploadFn<Image>>(
    async (file) => {
      const { id, original_file_name, filename } = await uploadFile({ file });
      return await createImage({
        name: original_file_name ?? filename,
        upload: { id }
      });
    },
    [createImage, uploadFile]
  );

  const FileDisplay = useCallback<FC<FileDisplayProps<Image>>>(
    (props) => (
      <ImageFileDisplay
        croppable={croppable}
        fullWidth={fullWidth}
        onChange={onChange as Consumer<Image | undefined>}
        {...props}
      />
    ),
    [croppable, fullWidth]
  );

  return (
    // @ts-expect-error TS cannot infer discriminated union props
    <FileUploadInput
      ref={ref}
      accept={LIBRARY_IMAGE_ACCEPT}
      onFileSelect={fileUploader}
      getFileKey={getLibraryImageKey}
      getPreviewData={getLibraryImagePreview}
      EmptyInput={PreviewEmptyInput}
      FileDisplay={FileDisplay}
      {...props}
    />
  );
});

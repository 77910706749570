import { Box } from '@rexlabs/box';
import { margin, padding, styled, StyleSheet } from '@rexlabs/styling';
import React from 'react';
import PlusIcon from 'src/assets/icons/plus.svg';
import { Body } from 'src/view/components/text';

const defaultStyles = StyleSheet({
  container: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    width: '100%',
    flexShrink: 0,
    color: ({ token }) => token('legacy.color.blue.grey'),
    cursor: 'pointer',
    ...padding.styles({
      all: 'xs'
    }),

    '& svg': {
      transition: 'transform .2s'
    },

    ':hover': {
      color: ({ token }) => token('legacy.color.blue.greyDark'),

      '& svg': {
        transform: 'scale(1.2)'
      }
    }
  },

  containerSelected: {
    background: ({ token }) => token('legacy.color.grey.light')
  },

  circle: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: '7rem',
    height: '7rem',
    background: ({ token }) => token('legacy.color.white'),
    borderRadius: '50%',
    overflow: 'hidden',
    border: '.1rem dashed currentColor',
    lineHeight: '7rem',
    transition: 'color .2s, border-color .2s',
    ...margin.styles({
      right: 'm'
    })
  },

  text: {
    color: 'currentColor',
    transition: 'color .2s'
  }
});

const CircleIconButton = ({ onClick, children, styles: s, ...props }) => {
  return (
    <Box {...s('container')} onClick={onClick} {...props}>
      <Box {...s('circle')}>
        <PlusIcon />
      </Box>
      <Box flex={1} flexDirection='column'>
        <Body {...s('text')}>{children}</Body>
      </Box>
    </Box>
  );
};

export default styled(defaultStyles)(CircleIconButton);

import { createRestAPIModelGenerator } from 'src/data/models/generator';
import { api } from 'src/utils/api-client';

export const TYPE = 'libraryImages';

const config = {
  entities: {
    api: {
      fetchList: () => api.get('/library-images'),
      fetchItem: (type, args, id) => api.get(`/library-images/${id}`, args),
      updateItem: (type, args, id) => api.patch(`/library-images/${id}`, args),
      createItem: (type, args) => api.post('/library-images', args),
      deleteItem: (type, args, id) => api.delete(`/library-images/${id}`),
      trashItem: (type, args, id) => api.delete(`/library-images/${id}`)
    }
  }
};

const LibraryImagesModel = createRestAPIModelGenerator(TYPE, config);
export default LibraryImagesModel.createEntityModel();

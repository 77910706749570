import { track } from '@rexlabs/analytics';
import { Box } from '@rexlabs/box';
import { GhostButton, PrimaryButton } from '@rexlabs/button';
import { withWhereaboutsFilter } from '@rexlabs/filters';
import { query, withQuery } from '@rexlabs/model-generator';
import { Link } from '@rexlabs/react-whereabouts';
import { Heading } from '@rexlabs/text';
import _isEmpty from 'lodash/isEmpty';
import React, { useState } from 'react';
import { compose } from 'redux';
import agentsFilter from 'src/data/filters/agents';
import agenciesModel from 'src/data/models/entities/agencies';
import campaignsModel from 'src/data/models/entities/campaigns';
import { queryAgents } from 'src/data/queries/agents';
import WIZARD from 'src/routes/wizard';
import { ButtonBar } from 'src/view/components/button';
import { Button, Filters, SearchInput } from 'src/view/components/filter';
import { AgencyListItem, AgentListItem, List } from 'src/view/components/list';
import ScrollableContent from 'src/view/components/scrollable-content';
import { Body } from 'src/view/components/text';
import withError from 'src/view/containers/with-error';
import { useDebounce } from 'src/view/hooks';
import EditAgentModal, {
  mapPropsToValues as mapPropsToAgentValues,
  validate as validateAgent
} from 'src/view/modals/wizard/edit-agent';
import { stepNames } from 'src/view/screens/wizard/multi-listing-sold/index';

import ChevronLeft from 'src/assets/icons/chevron-left.svg';
import ChevronRight from 'src/assets/icons/chevron-right.svg';
import IconPlus from 'src/assets/icons/plus.svg';
import { TooltipStateful } from 'src/view/components/tooltip';

const getCampaignId = (props) => props?.match?.params?.campaignId;

// NOTE: requesting all of the agents fields to be able to validate on click
// on the next button if any data is missing using `mapPropsToValues` and
// `validate` from the edit agent form itself
const queryCampaign = query`{
  ${campaignsModel} (id: ${getCampaignId}) {
    id
    name
    content_source {
      agents {
        id
        full_name
        updated_at
      }
    }
  }
}`;

const queryAgencies = query`{
  ${agenciesModel} {
    id
    business_name
    logo
    brand_colours {
      background
    }
  }
}`;

function SelectAgentScreen({
  agents,
  agencies,
  whereabouts,
  filters,
  error: { Error },
  wizard: {
    step,
    setStep,
    setSelectedAgents,
    setSelectedAgencies,
    selectedEntities
  }
}) {
  const [search, setSearch] = useState(
    (whereabouts?.query || {})['agents.search'] || ''
  );
  const [showEditAgent, setShowEditAgent] = useState(false);
  const selectedAgentId = selectedEntities?.agents?.[0]?.id;
  const selectedEntityId =
    selectedEntities?.agents?.[0]?.id || selectedEntities?.agencies?.[0]?.id;

  const isAgentInvalid = (selectedAgentId) => {
    if (!selectedAgentId) return true;

    const agentData = (agents?.list?.items || []).find(
      (a) => a.id === selectedAgentId
    );

    if (!agentData) return true;

    const fakeData = mapPropsToAgentValues({
      id: selectedAgentId,
      agents: { item: { data: agentData } }
    });

    const check = validateAgent(fakeData);
    if (check && !_isEmpty(check)) {
      return check;
    }

    return false;
  };

  const handleNext = () => {
    if (selectedAgentId && isAgentInvalid(selectedAgentId)) {
      track({
        event: 'Spoke campaign next',
        properties: {
          action: 'next_clicked',
          step_name: stepNames[step - 1].replace(/-/g, '_'),
          success: false,
          error_message: 'missing_agent_info'
        }
      });
      setShowEditAgent(true);
      return;
    }

    track({
      event: 'Spoke campaign next',
      properties: {
        action: 'next_clicked',
        step_name: stepNames[step - 1].replace(/-/g, '_'),
        success: true
      }
    });
    setStep({ step: step + 1 });
  };

  const handleCloseModal = () => {
    setShowEditAgent(false);

    if (selectedAgentId && !isAgentInvalid(selectedAgentId)) {
      setStep({ step: step + 1 });
    }
  };

  const isLoading = ['loading', 'refreshing'].includes(
    agents.list.status || agencies.list.status
  );

  useDebounce(search, (search) => filters.setFilters({ search }), 400);

  return (
    <Box flex={1} flexDirection='column'>
      <ScrollableContent>
        <Heading level={1}>Choose who you want to promote</Heading>
        <Body grey>Select an agent or agency profile.</Body>

        {!isLoading && (
          <Box style={{ width: '100%' }}>
            <Heading level={2}>Assign to an Agent or Office</Heading>
            <Filters>
              <SearchInput
                name='searchAgent'
                placeholder='Search by name'
                onChange={({ target: { value } }) => {
                  setSearch(value);
                }}
                value={search}
                isLoading={agents.list.status === 'loading'}
              />
              <Link to={WIZARD.CAMPAIGN.CREATE_AGENT}>
                {({ onClick }) => (
                  <Button
                    IconLeft={IconPlus}
                    onClick={(e) => {
                      track({
                        event: 'Spoke add agent',
                        properties: {
                          action: 'add_agent_clicked'
                        }
                      });
                      onClick(e);
                    }}
                  >
                    Add Agent
                  </Button>
                )}
              </Link>
            </Filters>
          </Box>
        )}

        <List
          isLoading={isLoading}
          items={agencies.list.items.concat(agents.list.items)}
          emptyStateProps={{
            largeText: <div>You don’t have any agents yet</div>,
            smallText: (
              <div>
                To get started,{' '}
                <Link to={WIZARD.CAMPAIGN.CREATE_AGENT}>
                  create a new agent profile.
                </Link>
              </div>
            )
          }}
          Item={({ data: entity }) => {
            const isAgency = entity.hasOwnProperty('business_name');
            const isSelected = selectedEntityId === entity.id;

            if (isAgency) {
              return (
                <AgencyListItem
                  selected={isSelected}
                  onClick={() => {
                    if (!isSelected)
                      track({
                        event: 'Spoke contact selected',
                        properties: {
                          action: 'contact_clicked',
                          contact_type: isAgency ? 'agency' : 'agent',
                          step_name: stepNames[step - 1].replace(/-/g, '_')
                        }
                      });
                    setSelectedAgencies(isSelected ? [] : [{ id: entity.id }]);
                    setSelectedAgents([]);
                  }}
                  data={entity}
                />
              );
            }

            return (
              <AgentListItem
                selected={isSelected}
                onClick={() => {
                  setSelectedAgents(isSelected ? [] : [{ id: entity.id }]);
                  setSelectedAgencies([]);
                }}
                data={entity}
              />
            );
          }}
          onLoadMore={() => agents.fetchMore()}
          isFetching={agents.list.status === 'fetching'}
          endReached={agents?.list?.pagination?.endReached}
        />
      </ScrollableContent>

      <ButtonBar>
        <Link to={WIZARD.CREATE}>
          {({ onClick }) => (
            <GhostButton
              IconLeft={ChevronLeft}
              onClick={(e) => {
                track({
                  event: 'Spoke campaign back',
                  properties: {
                    action: 'back_clicked',
                    step_name: stepNames[step - 1].replace(/-/g, '_')
                  }
                });
                onClick(e);
              }}
            >
              Back
            </GhostButton>
          )}
        </Link>
        <TooltipStateful
          show={!selectedEntityId}
          Content={() => (
            <Box style={{ width: '20rem' }}>
              <p>Please select an agent or agency first.</p>
            </Box>
          )}
        >
          <PrimaryButton
            IconRight={ChevronRight}
            onClick={selectedEntityId && handleNext}
            isDisabled={!selectedEntityId}
          >
            Next
          </PrimaryButton>
        </TooltipStateful>
      </ButtonBar>

      {selectedAgentId && showEditAgent && (
        <EditAgentModal
          id={selectedAgentId}
          enforceCampaignValidation
          closeModal={handleCloseModal}
        />
      )}

      <Error />
    </Box>
  );
}

export default compose(
  withError(),
  withWhereaboutsFilter(agentsFilter),
  withQuery(queryCampaign),
  withQuery(queryAgents),
  withQuery(queryAgencies)
)(SelectAgentScreen);

import { useState } from 'react';
import { useCallbackRef } from './useCallbackRef';

// Either use the callback `setState` signature or just a "void"
export type OnChange<T> = (v: React.SetStateAction<T>) => T | void;
export type StateUpdater<T> = (v: T) => T;

export type UseControllableStateOptions<
  TState,
  TOnChange extends (...args: any[]) => any = OnChange<TState>
> = {
  value?: TState;
  onChange?: TOnChange;
  defaultValue?: TState | (() => TState);
};

export function useControllableState<
  TState,
  TOnChange extends (...args: any[]) => any = OnChange<TState>
>({
  value: valueProp,
  onChange,
  defaultValue
}: UseControllableStateOptions<TState, TOnChange> = {}): [
  TState,
  OnChange<TState>
] {
  const onChangeProp = useCallbackRef(onChange);

  const [uncontrolledState, setUncontrolledState] = useState(
    defaultValue as TState
  );
  const controlled = valueProp !== undefined;
  const value = controlled ? valueProp : uncontrolledState;

  const setValue = useCallbackRef(
    (next: React.SetStateAction<TState>) => {
      const setter = next as (prevState?: TState) => TState;
      const nextValue = typeof next === 'function' ? setter(value) : next;

      if (!controlled) {
        setUncontrolledState(nextValue);
      }

      onChangeProp(nextValue);
    },
    [controlled, onChangeProp, value]
  );

  return [value, setValue] as [
    TState,
    React.Dispatch<React.SetStateAction<TState>>
  ];
}

import React, { FC } from 'react';
import { useParams } from 'react-router-dom';
import { getContentTypeDefinition } from '../utils/contentTypes';
import { useContentTypeSelect } from 'src/features/wizard/stores/contentTypes';

export const ContentTypeEditor: FC = () => {
  const { contentType = '', contentId } = useParams();
  const { Editor } = getContentTypeDefinition(contentType);
  const onSelection = useContentTypeSelect(contentType);

  return <Editor contentId={contentId} selectContent={onSelection} />;
};

import React from 'react';
import Box from '@rexlabs/box';
import { styled, StyleSheet } from '@rexlabs/styling';
import getContrastColor from '../../../utils/contrast-color';
import { COLORS } from 'src/theme';
import { injectGlobal } from 'styled-components';
import { fontFace } from 'utils/styling';
import { useFeature } from '../../../hooks';
import config from 'config';

const NATIVE_AD_WIDTH = 1200;
const NATIVE_AD_HEIGHT = 628;
export const NATIVE_AD_RATIO = NATIVE_AD_WIDTH / NATIVE_AD_HEIGHT;

injectGlobal`
  ${fontFace('Gordita', `${config.API_URL}/assets/gordita-regular.woff2`)}
`;

const defaultStyles = StyleSheet({
  container: {
    width: `${NATIVE_AD_WIDTH}px`,
    height: `${NATIVE_AD_HEIGHT}px`,
    fontFamily: 'Helvetica, Arial, sans-serif'
  },

  split: {
    width: '50%',
    color: 'white',
    '@media print': {
      '-webkit-print-color-adjust': 'exact'
    }
  },

  inner: {
    width: '100%',
    height: '100%',
    padding: '40px'
  },

  agentName: {
    fontSize: '72px',
    fontFamily: 'Circular Pro, Helvetica, Arial, sans-serif',
    lineHeight: '1.1',
    margin: 0,
    padding: 0,
    fontWeight: 100
  },

  agentPosition: {
    fontSize: '32px',
    fontFamily: 'Circular Pro, Helvetica, Arial, sans-serif'
  },

  image: {
    height: '100%',
    width: '100%',
    position: 'relative',
    overflow: 'hidden',
    background: 'none center center no-repeat',
    backgroundColor: COLORS.GREY_LIGHT,
    backgroundSize: 'cover'
  },

  agencyLogo: {
    width: '250px',
    height: '250px',
    background: 'transparent top left no-repeat',
    backgroundSize: 'contain'
  },

  gorditaFont: {
    fontFamily: 'Gordita, Circular Pro, Helvetica, Arial, sans-serifa'
  }
});

const AdImage = (props) => {
  const { styles: s, adContentSet, scaleTo } = props;
  const bannerStyle2 = useFeature(
    'facebook_agent_banner_style_2',
    adContentSet.session
  );
  const agentImageUrl = adContentSet?.agentImage?.square;

  return (
    <Box
      {...s.with('container', { gorditaFont: bannerStyle2 })({
        transformOrigin: 'top left',
        transform: `scale(${scaleTo / NATIVE_AD_WIDTH})`
      })}
      flexDirection='row'
    >
      <Box
        {...s.with('split')({
          background: adContentSet.backgroundColor,
          color: getContrastColor(adContentSet.backgroundColor)
        })}
      >
        <Box
          {...s('inner')}
          flexDirection='column'
          justifyContent='space-between'
          flex={1}
        >
          <Box
            {...s.with('agencyLogo')({
              backgroundImage:
                !bannerStyle2 && adContentSet.agencyLogo
                  ? `url(${adContentSet.agencyLogo})`
                  : 'none'
            })}
          />
          <Box>
            <h2 {...s('agentName', { gorditaFont: bannerStyle2 })}>
              {adContentSet.agentName}
            </h2>
            <span {...s('agentPosition', { gorditaFont: bannerStyle2 })}>
              {adContentSet.agentPosition}
            </span>
          </Box>
        </Box>
      </Box>
      <Box {...s('split')}>
        <Box
          {...s.with('image')({
            backgroundImage: agentImageUrl ? `url(${agentImageUrl})` : 'none'
          })}
        />
      </Box>
    </Box>
  );
};

export default styled(defaultStyles)(AdImage);

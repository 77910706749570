import { Box } from '@rexlabs/box';
import { GhostButton, PrimaryButton } from '@rexlabs/button';
import { query, withModel, withQuery } from '@rexlabs/model-generator';
import { autobind } from 'core-decorators';
import React, { PureComponent } from 'react';
import sessionModel from 'src/data/models/custom/session';
import wizardModel from 'src/data/models/custom/wizard';
import agenciesModel from 'src/data/models/entities/agencies';
import campaignsModel from 'src/data/models/entities/campaigns';
import WIZARD from 'src/routes/wizard';
import { formattedBrandName, withToken } from 'src/theme';
import { serializeSuburb } from 'src/utils/address';
import { suburbToMapbox } from 'src/utils/mapbox';
import { Link, replace } from 'src/utils/whereabouts';
import { ButtonBar } from 'src/view/components/button';
import { Form, ReactForms } from 'src/view/components/form';
import RepeatSuburbSelect from 'src/view/components/input/repeat-suburb-select';
import { RenderLoading } from 'src/view/components/loading';
import ScrollableContent from 'src/view/components/scrollable-content';
import { Body, Heading } from 'src/view/components/text';
import withError from 'src/view/containers/with-error';

import ChevronLeft from 'src/assets/icons/chevron-left.svg';
import ChevronRight from 'src/assets/icons/chevron-right.svg';
import { TooltipStateful } from 'src/view/components/tooltip';

const getCampaignId = (props) => props?.match?.params?.campaignId;

const queryCampaign = query`{
  ${campaignsModel} (id: ${getCampaignId}) {
    id
    name
    audience_spec {
      id
      locations
    }
  }
}`;

const queryAgencies = query`{
  ${agenciesModel} {
    id
    address
  }
}`;

@withToken
@withError('errorModal')
@withModel(sessionModel)
@withModel(wizardModel)
@withQuery(queryCampaign)
@withQuery(queryAgencies)
@autobind
class ChooseSuburbsAppraisalScreen extends PureComponent {
  getInitialValues() {
    const { campaigns, agencies } = this.props;
    const campaignSuburbs = (
      campaigns?.item?.data?.audience_spec?.locations ?? []
    ).filter((l) => l.type.id === 'suburb');
    const agencySuburb = agencies?.list?.items?.[0]?.address;
    const mappedSuburbs =
      campaignSuburbs.length > 0
        ? campaignSuburbs.map((s) => suburbToMapbox(s.suburb))
        : [suburbToMapbox(agencySuburb)];
    return { suburbs: mappedSuburbs };
  }

  handleSubmit(values) {
    const {
      wizard: { setStep, type, slug },
      campaignTemplateId,
      campaigns,
      agencies,
      errorModal,
      match
    } = this.props;

    const action = match.params.campaignId
      ? ({ id }) => Promise.resolve({ data: { id } })
      : campaigns.createItem;

    // Create or update campaign
    return action({
      id: match.params.campaignId,
      data: {
        type: { id: type },
        content_source: {
          agencies: [{ id: agencies.list.items[0].id }]
        },
        campaign_template_configuration: { id: campaignTemplateId }
      }
    })
      .then(({ data }) => {
        if (!data.id) {
          throw new Error('No ID found in API response!');
        }

        // Update suburbs on campaign audience spec
        // NOTE: this would delete possible existing locations potentially
        const suburbs = (values?.suburbs ?? [])
          .filter(Boolean)
          .map((suburb) => ({
            type: {
              id: 'suburb'
            },
            suburb: serializeSuburb(suburb.value)
          }));

        return campaigns
          .updateAudienceSpec({
            id: data.id,
            data: {
              locations: suburbs
            }
          })
          .then(() => {
            setStep({ step: 2 });
            // Redirect to put the campaignId in the url
            replace(WIZARD.CAMPAIGN, {
              params: { campaignType: slug, campaignId: data.id }
            });
          });
      })
      .catch((e) => {
        errorModal.open(e);
      });
  }

  render() {
    const { match, campaigns, agencies, errorModal, token } = this.props;
    const { Error } = errorModal;

    const isLoading =
      (match.params.campaignId && campaigns.item.status === 'loading') ||
      agencies.list.status === 'loading';

    const initialValues = this.getInitialValues();
    // debugger;
    return (
      <RenderLoading isLoading={isLoading}>
        <ReactForms
          initialValues={initialValues}
          handleSubmit={this.handleSubmit}
        >
          {({ values, submitForm, isSubmitting }) => (
            <Box flex={1} flexDirection='column'>
              <ScrollableContent>
                <Heading>Choose your target suburbs</Heading>
                <Body grey>
                  {formattedBrandName} will create tailored ads for each suburb
                  you select.
                </Body>

                <Box style={{ width: '50%' }} mb={token('spacing.xxxl')}>
                  <Form
                    name='chooseSuburbs'
                    data-testid={
                      Object.keys(values).length > 0 && 'choose-suburbs-form'
                    }
                  >
                    <RepeatSuburbSelect
                      initialSuburbs={initialValues.suburbs}
                      name='suburbs'
                      buttonLabel='Add another location'
                      label='Suburbs'
                      subLabel='Start typing postcode or suburb name'
                      min={1}
                      placeholder='Start typing to search for suburb...'
                    />
                  </Form>
                </Box>
              </ScrollableContent>

              <ButtonBar>
                <Link to={WIZARD.CREATE}>
                  {({ onClick }) => (
                    <GhostButton IconLeft={ChevronLeft} onClick={onClick}>
                      Back
                    </GhostButton>
                  )}
                </Link>
                <TooltipStateful
                  show={!(values?.suburbs || []).length}
                  Content={() => (
                    <Box style={{ width: '20rem' }}>
                      <p>Please select a suburb first</p>
                    </Box>
                  )}
                >
                  <PrimaryButton
                    form='chooseSuburbs'
                    IconRight={ChevronRight}
                    onClick={submitForm}
                    isDisabled={!(values?.suburbs || []).length}
                    isLoading={isSubmitting}
                  >
                    Next
                  </PrimaryButton>
                </TooltipStateful>
              </ButtonBar>

              <Error />
            </Box>
          )}
        </ReactForms>
      </RenderLoading>
    );
  }
}

export default ChooseSuburbsAppraisalScreen;

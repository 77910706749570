export default {
  namespace: 'campaigns',

  // Sorting options
  sorting: {},

  // Filter options
  filters: {
    search: {
      label: 'Search'
    },
    status: {
      label: 'Status'
    },
    type_label: {
      label: 'Type'
    }
  }
};

import React, { PureComponent } from 'react';
import ROUTES from 'src/routes/admin';
import Sidebar from './sidebar';
import SidebarItem from './item';

class SettingsSidebar extends PureComponent {
  render() {
    return (
      <Sidebar title='Settings'>
        <SidebarItem route={ROUTES.SETTINGS_AGENCY}>Office profile</SidebarItem>
        <SidebarItem route={ROUTES.SETTINGS_LANDING_PAGES}>
          Landing pages
        </SidebarItem>
        <SidebarItem route={ROUTES.SETTINGS_USERS}>User manager</SidebarItem>
        <SidebarItem route={ROUTES.SETTINGS_INTEGRATIONS}>
          Integrations
        </SidebarItem>
        <SidebarItem route={ROUTES.SETTINGS_PAYMENTS}>Payments</SidebarItem>
      </Sidebar>
    );
  }
}

export default SettingsSidebar;

import { Box } from '@rexlabs/box';
import { StyleSheet, useStyles } from '@rexlabs/styling';
import React, { FC } from 'react';
import { Breadcrumb } from 'src/components/elements/breadcrumb/breadcrumb';
import { BreadcrumbItem } from 'src/components/elements/breadcrumb/item';
import { BreadcrumbLink } from 'src/components/elements/breadcrumb/link';
import { BreadcrumbSeparator } from 'src/components/elements/breadcrumb/separator';
import { useBreadcrumbs } from 'src/hooks';
import { Link } from 'utils/whereabouts';

const defaultStyles = StyleSheet({
  container: {
    display: 'flex',
    justifyContent: 'center',
    width: '100%',
    backgroundColor: '#F1F5F7'
  },

  breadcrumb: {
    width: '102.4rem',
    paddingLeft: ({ token }) => token('spacing.xs'),
    paddingTop: ({ token }) => token('spacing.m'),
    paddingBottom: ({ token }) => token('spacing.m')
  },

  item: {
    textDecoration: 'none',
    ':hover': {
      textDecoration: 'underline'
    }
  },

  isCurrentPage: {
    color: ({ token }) => token('color.textStyle.body.default')
  }
});

export type BreadcrumbsNavBarProps = {
  withLastSeparator?: boolean;
};

export const BreadcrumbsNavBar: FC<BreadcrumbsNavBarProps> = ({
  withLastSeparator = false
}) => {
  const s = useStyles(defaultStyles, 'breadcrumbsNavBar');
  const { breadcrumbs } = useBreadcrumbs();

  if (!breadcrumbs.length) return null;

  return (
    <Box {...s('container')} data-testid='breadcrumbs-container'>
      <Breadcrumb {...s('breadcrumb')}>
        {breadcrumbs.map(({ children, href, isCurrentPage }, index) => (
          <BreadcrumbItem key={index} isCurrentPage={isCurrentPage}>
            <BreadcrumbLink
              to={href}
              as={Link}
              {...s('item', { isCurrentPage })}
            >
              {children}
            </BreadcrumbLink>

            {withLastSeparator && breadcrumbs.length === index + 1 ? (
              <BreadcrumbSeparator />
            ) : null}
          </BreadcrumbItem>
        ))}
      </Breadcrumb>
    </Box>
  );
};

import { withModel } from '@rexlabs/model-generator';
import { autobind } from 'core-decorators';
import { debounce } from 'lodash';
import React, { PureComponent } from 'react';
import session from 'src/data/models/custom/session';
import { getSuburbDetails } from 'src/utils/address';
import { api } from 'src/utils/api-client';
import getFakeEvent from 'src/utils/fake-event';
import Select from './default';

const DEFAULT_COUNTRIES = ['au', 'gb', 'nz', 'ie', 'us', 'ca'];

@withModel(session)
@autobind
class SuburbSelect extends PureComponent {
  static defaultProps = {
    pluckValue: (datum) => (datum ? datum.id || datum.value : null),
    pluckLabel: (datum) => (datum ? datum.label : null),
    placeholder: 'Select suburb...'
  };

  state = {
    search: '',
    suggestions: [],
    loading: false
  };

  debouceGetSuggestions = debounce(this.getSuggestions, 400);

  getCountries() {
    const { givenCountries, session } = this.props;

    if (typeof givenCountries !== 'undefined') {
      return givenCountries;
    }

    if (!session.ready) {
      return DEFAULT_COUNTRIES;
    }

    const { accounts, currentAccountId } = session;
    const account = accounts.find((account) => account.id === currentAccountId);
    const accountCountry = account?.agencies?.data?.[0]?.address?.country?.id;

    return typeof accountCountry !== 'undefined'
      ? [accountCountry]
      : DEFAULT_COUNTRIES;
  }

  getSuggestions(searchValue) {
    if (!searchValue) {
      this.setState({
        loading: false,
        suggestions: []
      });
      return;
    }

    const countries = this.getCountries();

    api
      .get(
        `/location/autocomplete/predict?type=suburb&countries=${countries}&input=${searchValue}`
      )
      .then((result) => {
        this.setState({
          loading: false,
          suggestions: (result?.data ?? []).map((locData) => ({
            value: locData.id,
            label: locData.label,
            data: locData
          }))
        });
      })
      .catch(() =>
        this.setState({
          loading: false,
          suggestions: []
        })
      );
  }

  filterSuggestions({ target: { value } }) {
    this.setState({ loading: true, search: value });
    this.debouceGetSuggestions(value);
  }

  async handleSelect(selected) {
    const { onChange, onBlur, name } = this.props;

    if (
      selected &&
      selected.value === (this.props.value && this.props.value.value)
    ) {
      return;
    }

    let addressDetails = null;
    if (selected) {
      this.setState({ loading: true });
      addressDetails = await getSuburbDetails(selected.value);
    }

    this.setState({ search: '', suggestions: [], loading: false }, () => {
      const fakeEvent = getFakeEvent('select', name, name, addressDetails);
      if (onChange) {
        onChange(fakeEvent);
      }
      // We force the blur the input on select, cause otherwise
      // it will keep showing the search term until the user blurs
      // manually
      setTimeout(() => {
        if (addressDetails === undefined) {
          onBlur(fakeEvent);
        } else {
          window.document.activeElement.blur();
        }
      }, 0);
    });
  }

  handleBlur(e) {
    const { onBlur } = this.props;
    this.setState({ search: '', suggestions: [] });
    if (onBlur) {
      onBlur(e);
    }
  }

  render() {
    const { onChange, onBlur, value, ...props } = this.props;
    const { suggestions, loading } = this.state;

    const options = suggestions.filter((o) => o.value);
    if (value?.value && !options.find((o) => o.value === value.value)) {
      options.push(value);
    }

    return (
      <Select
        isLoading={loading}
        options={options}
        onFieldChange={this.filterSuggestions}
        filter={(s) => s}
        value={value ? value.value : null}
        selected={value ? value.value || { label: value, value } : null}
        DropdownIndicator={() => <span />}
        onSelect={this.handleSelect}
        onBlur={this.handleBlur}
        {...props}
      />
    );
  }
}

export default SuburbSelect;

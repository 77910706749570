import { Box } from '@rexlabs/box';
import { DestructiveButton } from '@rexlabs/button';
import {
  query,
  useEntityQuery,
  useModelActions
} from '@rexlabs/model-generator';
import { useToast } from '@rexlabs/notifications';
import { useToken } from '@rexlabs/styling';
import React from 'react';
import campaignsModel from 'src/data/models/entities/campaigns';
import { RenderLoading } from 'src/view/components/loading';
import { Body } from 'src/view/components/text';

const q = (id) => query`{
  ${campaignsModel} (id: ${id}) {
    id
    name
    status {
      id
      label
    }
  }
}`;

function DeleteCampaignModal({ campaignId, closeModal }) {
  const token = useToken();
  const toasts = useToast();
  const campaign = useEntityQuery(q(campaignId));
  const { trashItem } = useModelActions(campaignsModel);

  const deleteCampaign = async () => {
    if (campaign.data.status.id !== 'draft') {
      toasts.addToast({
        target: 'modal',
        type: 'error',
        description: 'You can only delete campaign drafts!'
      });
      return;
    }

    try {
      await trashItem({
        id: campaignId
      });
      toasts.addToast({
        type: 'success',
        description: 'Draft successfully deleted'
      });
      closeModal();
    } catch (e) {
      toasts.addToast({
        target: 'modal',
        type: 'error',
        title: 'Unable to delete draft',
        description: e.message
      });
    }
  };

  return (
    <RenderLoading isLoading={campaign.status === 'loading'}>
      <Box w='50rem' p={token('spacing.xxl')} style={{ textAlign: 'center' }}>
        <Body>
          Are you sure you want to delete this campaign draft? This action is
          permanent and can not be undone!
        </Body>
        <Box justifyContent='center' mt={token('spacing.xxl')}>
          <DestructiveButton onClick={deleteCampaign}>
            Yes, delete draft
          </DestructiveButton>
        </Box>
      </Box>
    </RenderLoading>
  );
}

export default DeleteCampaignModal;

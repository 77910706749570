import { withModel } from '@rexlabs/model-generator';
import sessionModel from 'src/data/models/custom/session';

function Authorize({ session, capability, children }) {
  const { accounts, currentAccountId } = session;
  const account = accounts.find((account) => account.id === currentAccountId);
  const notAuthorized = !account.capabilities[capability];

  if (notAuthorized) {
    return null;
  }

  return children;
}

export default withModel(sessionModel)(Authorize);

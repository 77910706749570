import { Box } from '@rexlabs/box';
import {
  ButtonGroup,
  GhostButton,
  LinkButton,
  PrimaryButton
} from '@rexlabs/button';
import { Body, Small } from '@rexlabs/text';
import React, { useState } from 'react';
import { GridCell, GridRow } from 'src/view/components/grid';
import { Label } from 'src/view/components/text';

import { zodResolver } from '@hookform/resolvers/zod';
import { CheckboxGroup } from '@rexlabs/checkbox';
import { useToast } from '@rexlabs/notifications';
import {
  border,
  margin,
  padding,
  StyleSheet,
  useStyles,
  useToken
} from '@rexlabs/styling';
import { TextInput } from '@rexlabs/text-input';
import { get } from 'lodash';
import { Controller, useForm } from 'react-hook-form';
import { useCopyToClipboard } from 'react-use';
import {
  ReportSection,
  ShareReportOptions,
  shareReportSchema,
  useSendReportEmail,
  useVendorReportLink
} from 'src/data/vendor-report';
import { VENDOR_REPORT_SECTIONS } from 'src/data/vendor-report/schemas';
import { TextArea } from 'src/view/components/input';

const defaultStyles = StyleSheet({
  wrapUrlLoadingSpinner: {
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,

    '&::before': {
      content: '" "',
      position: 'absolute',
      top: 0,
      left: 0,
      right: 0,
      bottom: 0,
      background: ({ token }) => token('legacy.color.grey.light'),
      opacity: 0.8
    }
  },

  shareAction: {
    background: ({ token }) => token('color.container.static.contrast'),
    ...border.styles({
      all: {
        radius: 's'
      }
    }),
    ...padding.styles({
      all: 'm',
      top: () => '0.1rem'
    })
  },

  personalMessage: {
    ...margin.styles({
      y: 'm'
    })
  },

  successMessage: {
    color: ({ token }) => token('color.textStyle.success.idle.default')
  },

  errorMessage: {
    color: ({ token }) => token('color.textStyle.danger.idle.default')
  }
});

const SECTION_LABELS: Readonly<Record<ReportSection, string>> = {
  ads_preview: 'Ads Preview',
  audience_details: 'Audience Details',
  campaign_performance: 'Campaign Performance',
  status_and_package: 'Status & Package'
} as const;

const ERROR_MESSAGES: Readonly<
  Record<keyof ShareReportOptions, Record<string, string>>
> = {
  email: {
    invalid_type: 'Please enter an email recipient',
    invalid_string: 'Value must be an email address'
  },
  sections: {
    too_small: 'At least one section is required.'
  },
  message: {}
} as const;

export default function ShareCampaignModal({
  campaignId,
  closeModal
}: {
  campaignId: string;
  closeModal: () => void;
}) {
  const token = useToken();
  const s = useStyles(defaultStyles);
  const { addToast } = useToast();
  const {
    control,
    handleSubmit,
    watch,
    formState: { errors }
  } = useForm<ShareReportOptions>({
    mode: 'onBlur',
    resolver: zodResolver(shareReportSchema),
    defaultValues: {
      sections: [...VENDOR_REPORT_SECTIONS]
    }
  });
  const [includeMessage, setIncludeMessage] = useState(false);
  const sections = watch('sections');
  const sectionError = typeof errors.sections !== 'undefined';
  const errorMessage = (
    name: keyof ShareReportOptions,
    type: string,
    fallback?: string
  ) => get(ERROR_MESSAGES, [name, type], fallback);

  const { data: reportUrl, isLoading: isReportLinkLoading } =
    useVendorReportLink(campaignId, sections, !sectionError);
  const [{ value, error: copyError }, copyToClipboard] = useCopyToClipboard();

  const { mutate, isLoading: isSendEmailLoading } =
    useSendReportEmail(campaignId);
  const sendEmail = (options: ShareReportOptions) => {
    mutate(options, {
      onSuccess: (_data, { email }) =>
        addToast({
          target: 'modal',
          type: 'success',
          title: 'Report email has been sent.',
          description: `Report email has been sent to ${email}`
        }),
      onError: (error) =>
        addToast({
          target: 'modal',
          type: 'error',
          title: 'Unable to send report email',
          description: error.message
        })
    });
  };

  return (
    <form onSubmit={handleSubmit(sendEmail)}>
      <Box flexDirection='column' width='100%' spacing={token('spacing.xl')}>
        <GridRow>
          <GridCell width={1 / 4}>
            <Box mt={token('spacing.xxs')}>
              <Small grey as='span'>
                Report includes:
              </Small>
            </Box>
          </GridCell>
          <GridCell gutter={token('spacing.xl')} width={3 / 4}>
            <Controller
              control={control}
              name='sections'
              render={({ field, fieldState: { error } }) => (
                <Box flex={1} flexDirection='column' sx={token('spacing.s')}>
                  <CheckboxGroup
                    {...field}
                    {...(error && { meta: { error: error.type } })}
                    options={VENDOR_REPORT_SECTIONS.map((value) => ({
                      value,
                      label: SECTION_LABELS[value]
                    }))}
                  />
                  {error && (
                    <Body as='span' {...s('errorMessage')}>
                      {errorMessage('sections', error.type)}
                    </Body>
                  )}
                </Box>
              )}
            />
          </GridCell>
        </GridRow>

        <Box {...s('shareAction')}>
          <Label>Copy report link</Label>
          <Box flexDirection='row' alignItems='center'>
            <Box
              flex={1}
              flexDirection='column'
              mr={token('spacing.xs')}
              style={{ position: 'relative' }}
            >
              <TextInput
                value={reportUrl}
                readOnly
                disabled={sectionError}
                placeholder=''
              />
            </Box>
            <PrimaryButton
              onClick={() => copyToClipboard(reportUrl ?? '')}
              isLoading={isReportLinkLoading}
              isDisabled={sectionError}
            >
              Copy link
            </PrimaryButton>
          </Box>
          {value && (
            <Body as='span' {...s('successMessage')}>
              Report link copied!
            </Body>
          )}
          {copyError && (
            <Body as='span' {...s('successMessage')}>
              Could not copy link.
            </Body>
          )}
        </Box>

        <Box {...s('shareAction')} sy={token('spacing.xs')}>
          <Label>Send report via email</Label>
          <Box flex={1} sx={token('spacing.s')}>
            <Controller
              control={control}
              name='email'
              render={({ field, fieldState: { error } }) => (
                <Box flex={1} flexDirection='column'>
                  <TextInput
                    {...field}
                    {...(error && { meta: { error: error.type } })}
                    placeholder='Enter an email address'
                  />
                  {error && (
                    <Body as='span' {...s('errorMessage')}>
                      {errorMessage('email', error.type)}
                    </Body>
                  )}
                </Box>
              )}
            />
            <PrimaryButton type='submit' isLoading={isSendEmailLoading}>
              Send report
            </PrimaryButton>
          </Box>

          <Box>
            {includeMessage ? (
              <Controller
                control={control}
                name='message'
                render={({ field }) => (
                  <TextArea
                    {...field}
                    placeholder='Personal message (optional)'
                  />
                )}
              />
            ) : (
              <LinkButton
                onClick={() => setIncludeMessage(true)}
                style={{ fontSize: '1.6rem' }}
              >
                Include a personal message?
              </LinkButton>
            )}
          </Box>
        </Box>
        <ButtonGroup justifyContent='flex-end' mt={token('spacing.m')}>
          <GhostButton onClick={closeModal}>Close</GhostButton>
          <PrimaryButton
            isLoading={isReportLinkLoading}
            as='a'
            href={reportUrl}
            target='_blank'
          >
            Preview
          </PrimaryButton>
        </ButtonGroup>
      </Box>
    </form>
  );
}

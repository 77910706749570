import { useInfiniteQuery } from 'react-query';
import { useAccountId } from 'src/features/accounts';
import { InfiniteQueryConfig, InfiniteQueryResult } from 'src/lib/react-query';
import { PaginatedResponse, Pagination, PaginationParams } from 'src/types';
import { api } from 'src/utils/api-client';
import { Testimonial } from '../types';
import { transformTestimonialResponse } from '../utils/schemas';
import { testimonialsKeys } from './keys';

export type GetTestimonialsParams = PaginationParams & {
  accountId: string;
  q?: string;
};

export const getTestimonials = async ({
  page = 1,
  q = '',
  accountId,
  ...params
}: GetTestimonialsParams): Promise<PaginatedResponse<Testimonial>> => {
  const { data, pagination } = await api.get('/testimonials', {
    ...params,
    include: 'agent.thumbnails',
    q: `author.match(*${q}*)`,
    page
  });
  return {
    data: data.map(transformTestimonialResponse),
    pagination: pagination as Pagination
  };
};

type QueryFnType = typeof getTestimonials;

export type UseTestimonialsOptions = Omit<
  GetTestimonialsParams,
  'accountId' | 'page'
> & {
  config?: InfiniteQueryConfig<QueryFnType>;
};

export const useTestimonials = ({
  config,
  ...params
}: UseTestimonialsOptions = {}): InfiniteQueryResult<Testimonial> => {
  const accountId = useAccountId();

  return useInfiniteQuery<
    PaginatedResponse<Testimonial>,
    Error,
    PaginatedResponse<Testimonial>
  >({
    queryKey: testimonialsKeys.list({ accountId, ...params }),
    queryFn: ({ pageParam }) =>
      getTestimonials({ ...params, accountId, page: pageParam }),
    getNextPageParam: ({ pagination }) =>
      pagination.current_page === pagination.total_pages
        ? undefined
        : pagination.current_page + 1,
    ...config
  });
};

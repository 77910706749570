import React, { FC } from 'react';
import { Navigate, useLocation, useRouteError } from 'react-router-dom';
import { isRexApiResponse } from 'src/utils/api-client';

export const AuthenticationErrorBoundary: FC = () => {
  const error = useRouteError();
  const location = useLocation();

  if (
    error instanceof Error &&
    isRexApiResponse(error.cause) &&
    error.cause.status === 401
  ) {
    return <Navigate to='/login' replace state={{ from: location.pathname }} />;
  }

  throw error;
};

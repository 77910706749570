/* eslint-disable @typescript-eslint/no-explicit-any */
import { Box } from '@rexlabs/box';
import { Field } from '@rexlabs/form';
import { margin, StyleSheet } from '@rexlabs/styling';
import React, { ReactNode } from 'react';
import { FONT, TEXTS } from 'src/theme';
import { Tiny } from 'src/view/components/text';

const defaultStyles = {
  container: {
    borderColor: ({ token }) => token('legacy.color.grey.default'),
    flexShrink: 0
  },

  error: {
    padding: ({ token }) => token('spacing.xxs'),
    fontSize: '1.2rem',
    fontWeight: FONT.WEIGHTS.SEMIBOLD,
    color: ({ token }) => token('legacy.color.red.default')
  },

  field: {
    position: 'relative',
    ...margin.styles({
      all: 'none'
    })
  },

  label: {
    ...TEXTS.LABEL
  }
};

const fieldStyles = StyleSheet(defaultStyles);

// Field no longer supports the old FieldError prop which could be used to hide
// errors eg FieldError={() => null} so this is the alternative way to do this.
// This was added for use with validating at least 1 checkbox is selected out of
// a group. TODO: look into new checkbox group component.
export const hiddenErrorStyles = StyleSheet({
  ...defaultStyles,
  error: {
    display: 'none'
  }
});

export interface FormFieldProps {
  name: string;
  label: string | React.ReactElement;
  subLabel?: ReactNode;
  Input: React.ComponentType<any>;
  [x: string]: any;
}

export default ({ label, subLabel, ...rest }: FormFieldProps) => {
  return (
    <Field
      fullWidth
      styles={fieldStyles}
      label={
        subLabel ? (
          <Box>
            {label}
            <Box style={{ cursor: 'default', marginTop: '-.3rem' }}>
              <Tiny grey>{subLabel}</Tiny>
            </Box>
          </Box>
        ) : (
          label
        )
      }
      {...rest}
    />
  );
};

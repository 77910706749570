import React, { FC, Suspense } from 'react';
import { useRouteLoaderData } from 'react-router-dom';
import { StepDefinition } from '../types';

export type StepSelectorProps = {
  type: 'editor' | 'preview';
};

export const StepSelector: FC<StepSelectorProps> = ({ type }) => {
  const step = useRouteLoaderData('wizard-step') as StepDefinition;

  return <Suspense>{step[type]}</Suspense>;
};

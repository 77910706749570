import { DependencyList, useEffect, useMemo } from 'react';
import { usePrevious } from 'react-use';
import { Consumer, Supplier } from 'src/types';

/**
 * Hook that computes a memoized value. When that value changes, the hook will execute the clean-up function on the previous value.
 * @param factory Factory to compute the value to memoize
 * @param cleanup Function to cleanup the previous value
 * @param deps List of dependencies of the factory
 * @returns The memoized value
 */
export function useMemoCleanup<T>(
  factory: Supplier<T>,
  cleanup: Consumer<T>,
  deps: DependencyList = []
): T {
  const value = useMemo<T>(factory, [factory, ...deps]);
  const prev = usePrevious(value);

  useEffect(() => {
    if (prev) cleanup(prev);
  }, [cleanup, prev]);

  return value;
}

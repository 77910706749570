import { Box } from '@rexlabs/box';
import { styled, StyleSheet } from '@rexlabs/styling';
import { autobind } from 'core-decorators';
import React, { Component } from 'react';
import { CropButton, DeleteButton } from 'src/view/components/button';
import ImageUploadProgress from 'src/view/components/image-upload-progress';
import Pill from 'src/view/components/pill';
import { Capitalized } from 'src/view/components/text';

const sharedStyles = StyleSheet({
  overlay: {
    position: 'absolute',
    zIndex: 2,
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    transition: 'opacity 100ms ease-out',
    pointerEvents: 'auto'
  },

  background: {
    opacity: 0,
    zIndex: 1,
    backgroundColor: ({ token }) => token('legacy.color.black'),
    '&:hover': {
      opacity: '0.5'
    }
  },

  visible: {
    opacity: 0,

    '&:hover': {
      opacity: 1,
      '& > :first-child': {
        opacity: 0.5
      }
    }
  },

  text: {
    zIndex: 2
  },

  washed: {
    opacity: 0.5,
    backgroundColor: ({ token }) => token('legacy.color.white')
  },

  border: {
    border: ({ token }) => `2px solid ${token('color.border.input.selected')}`
  },

  imageUpload: {
    opacity: 0.5,
    backgroundColor: ({ token }) => token('legacy.color.black')
  }
});

const pillStyles = StyleSheet({
  badge: {
    position: 'absolute',
    bottom: '1rem',
    left: '1rem'
  }
});

@styled(sharedStyles)
class ImageOverlay extends Component {
  render() {
    const {
      styles: s,
      text,
      canRemove,
      canCrop,
      onRemoveClick,
      onCropClick
    } = this.props;
    return (
      <Box
        {...s('overlay', 'visible')}
        alignItems='center'
        justifyContent='center'
      >
        <div {...s('overlay', 'background')} />
        {canRemove && <DeleteButton onClick={onRemoveClick} />}
        {canCrop && <CropButton onClick={onCropClick} />}
        {text && (
          <div {...s('text')}>
            <Capitalized white>{text}</Capitalized>
          </div>
        )}
      </Box>
    );
  }
}

@styled(sharedStyles)
class WashedImageOverlay extends Component {
  render() {
    const { styles: s } = this.props;
    return <Box {...s('overlay', 'washed')} />;
  }
}

@styled(sharedStyles)
class SelectedImageOverlay extends Component {
  render() {
    const { styles: s, canCrop, onCropClick } = this.props;
    return (
      <Box {...s('overlay', 'border')}>
        {canCrop && <CropButton onClick={onCropClick} />}
        <Pill styles={pillStyles} text='selected' />
      </Box>
    );
  }
}

@styled(sharedStyles)
@autobind
class ProgressIndicatorOverlay extends Component {
  render() {
    const { styles: s } = this.props;
    return (
      <Box
        {...s('overlay', 'imageUpload')}
        alignItems='center'
        justifyContent='center'
      >
        <ImageUploadProgress />
      </Box>
    );
  }
}

export {
  ImageOverlay,
  WashedImageOverlay,
  SelectedImageOverlay,
  ProgressIndicatorOverlay
};

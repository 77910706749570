import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import Skyscraper, { types } from './skyscraper';

export default class WideSkyscraper extends PureComponent {
  static propTypes = {
    shortHeadline: PropTypes.string,
    agentName: PropTypes.string,
    description: PropTypes.string,
    buttonText: PropTypes.string,
    brandColor: PropTypes.string,
    primaryImageUrls: PropTypes.object,
    logoUrls: PropTypes.object
  };

  render() {
    return <Skyscraper {...this.props} type={types.WIDE} />;
  }
}

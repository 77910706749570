/* eslint-disable max-lines */
import { Box } from '@rexlabs/box';
import { styled, StyleSheet } from '@rexlabs/styling';
import { autobind } from 'core-decorators';
import _ from 'lodash';
import MapboxCircle from 'mapbox-gl-circle';
import React, { PureComponent } from 'react';
import ReactDOM from 'react-dom';
import config from 'src/config';
import { withToken } from 'src/theme';
import { LoadingSpinner } from 'src/view/components/loading';
import { Small } from 'src/view/components/text';

import MapPin from 'src/assets/icons/map-pin.svg';

let id = 0;
const getId = () => ++id;

let mapboxGl = null;

const defaultStyles = StyleSheet({
  map: {
    height: '35rem',
    width: '100%',
    background: ({ token }) => token('legacy.color.blue.greyLight'),
    overflow: 'hidden',
    position: 'relative',
    zIndex: 1
  },

  mapFullHeight: {
    height: '100%'
  },

  mapOverlay: {
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    background: ({ token }) => token('legacy.color.blue.greyLight'),
    opacity: 0.5,
    zIndex: 10
  },

  loadingOverlay: {
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    pointerEvents: 'none',
    background: ({ token }) => token('legacy.color.blue.greyLight'),
    color: ({ token }) => token('legacy.color.white'),
    transition: 'opacity .3s',
    opacity: 0,
    zIndex: 11
  },

  activeLoadingOverlay: {
    pointerEvents: 'auto',
    opacity: 1
  }
});

@withToken
@styled(defaultStyles)
@autobind
class Map extends PureComponent {
  static defaultProps = {
    style: 'mapbox://styles/mapbox/streets-v11',
    zoom: 8,
    show: true,
    showZoom: true,
    readonly: false,
    scrollZoom: true
  };

  id = `map-${getId()}`;
  map = null;
  markers = [];
  mapPinElements = [];
  radius = null;
  radiusMultiplier = 1000;
  initialised = false;
  markerArray = [];
  circleBounds = null;
  zoomToCircle = true;

  constructor(props) {
    super(props);
    this.state = {
      ready: false,
      isLoading: true,
      error: null
    };
  }

  componentDidMount() {
    import(/* webpackChunkName: "mapbox-gl" */ 'mapbox-gl')
      .then((mb) => {
        mapboxGl = mb.default;
        mapboxGl.accessToken = config.MAPBOX_TOKEN;
        this.setState({ ready: true }, this.init);
      })
      .catch((e) => console.error('dynamic import failed', e));
  }

  componentWillUnmount() {
    if (this.mapPinElements.length > 0) {
      Array.from(this.mapPinElements).forEach((el) =>
        ReactDOM.unmountComponentAtNode(el)
      );
    }
  }

  async init() {
    this.radiusMultiplier = this.props.unit === 'mile' ? 1609 : 1000;
    this.markerArray = this.props.markers.filter(Boolean);
    try {
      this.map = new mapboxGl.Map(this.getInitOptions());
    } catch (e) {
      this.setState({ error: e });
      return;
    }
    this.map.id = this.id;
    if (this.props.showZoom) {
      this.map.addControl(new mapboxGl.NavigationControl());
    }

    this.map.on('data', (e) => {
      if (this.state.isLoading) {
        // If all required tiles are loaded and the current event has been fired by this map, and there is a marker currently set (makes sure that it hasn't just loaded all the tiles over the ocean)
        if (
          e.isSourceLoaded &&
          e.target.id === this.map.id &&
          _.first(this.markerArray)?.[0] &&
          _.first(this.markerArray)?.[1]
        ) {
          this.setState({ isLoading: false });
        }
      }
    });

    this.map.on('render', () => {
      if (!this.initialised && this.map.loaded()) {
        const { hideRadius, center, radius } = this.props;
        this.initialised = true;
        if (!hideRadius && center) {
          this.setMapRadius(center, radius || 20);
        }
        if (this.props.markers) {
          this.setMarkers(this.props);
        }
      }
    });
  }

  componentDidUpdate(prevProps) {
    if (!this.map) return;

    const { show, hideRadius, center, radius, markers } = this.props;
    if (!mapboxGl || !show) {
      return;
    }

    if (!hideRadius && center !== prevProps.center) {
      this.setMapRadius(center, radius || 20);
    } else if (!hideRadius && center && radius !== prevProps.radius) {
      this.setMapRadius(center, radius || 20);
    }

    if (!center) {
      this.circleBounds = null;
      this.radius = null;
    }

    if (!_.isEqual(markers, prevProps.markers)) {
      this.setMarkers(this.props);
      this.markerArray = markers.filter(Boolean);
    }
  }

  // Checks if a center should be passed in the initial construction of the map
  getInitOptions() {
    const options = {
      container: this.id,
      style: this.props.style,
      zoom: this.props.zoom,
      scrollZoom: this.props.scrollZoom,
      dragPan: this.props.dragPan
    };
    if (this.props.center) {
      options.center = this.props.center;
    } else if (
      _.first(this.markerArray)?.[0] &&
      _.first(this.markerArray)?.[1]
    ) {
      options.center = [
        _.first(this.markerArray)[0],
        _.first(this.markerArray)[1]
      ];
    }
    return options;
  }

  setMarkers(props) {
    this.markers.forEach((marker) => marker.remove());
    this.markers = [];
    if (props.markers) {
      props.markers.forEach((marker) => {
        if (marker && marker[0] !== undefined && marker[1] !== undefined) {
          const { onDragEnd } = props;
          const mapPinElement = document.createElement('div');
          this.mapPinElements.push(mapPinElement);
          ReactDOM.render(
            <MapPin
              style={{
                color: props.token('palette.brand.600'),
                transform: 'translateY(-50%)',
                height: '4.5rem',
                width: 'auto'
              }}
            />,
            mapPinElement
          );

          this.markers.push(
            onDragEnd
              ? new mapboxGl.Marker({
                  element: mapPinElement,
                  draggable: true
                })
                  .setLngLat(new mapboxGl.LngLat(marker[0], marker[1]))
                  .addTo(this.map)
                  .on('dragend', onDragEnd)
              : new mapboxGl.Marker(mapPinElement)
                  .setLngLat(new mapboxGl.LngLat(marker[0], marker[1]))
                  .addTo(this.map)
          );
        }
      });

      // if we have a onDragEnd function it means we're expecting the marker to be dragged around
      // in this case we want to use the maps zoom and just re-center on the marker
      if (this.props.onDragEnd) {
        this.map.flyTo({ center: props.markers[0] });
        return;
      }
      this.setCurrentView();
    }
  }

  setCurrentView() {
    // Setting zoomToCircle to true initially (or false if there is no circle to zoom to anyway)
    this.zoomToCircle = !!this.circleBounds;
    const bounds = new mapboxGl.LngLatBounds();

    // If any marker is located outside of the circleBounds, set zoomToCircle to false
    if (this.circleBounds) {
      this.markers.forEach((marker) => {
        if (
          !(
            marker.lng > this.circleBounds.getWest() &&
            marker.lng < this.circleBounds.getEast() &&
            marker.lat > this.circleBounds.getSouth() &&
            marker.lat < this.circleBounds.getNorth()
          )
        ) {
          this.zoomToCircle = false;
        }
      });

      // If a circle exists, always show it in it's entirety
      bounds.extend(this.circleBounds);
    }

    // if we are not zooming to circle, add markers
    if (!this.zoomToCircle) {
      this.markers.forEach((marker) => {
        bounds.extend(marker.getLngLat().toBounds(5000));
      });
    }

    // Set the bounds
    if (!bounds.isEmpty())
      this.map.fitBounds(bounds, {
        padding: {
          top: 10,
          left: 10,
          right: 10,
          bottom: 10
        }
      });
  }

  radiusChange(radius) {
    if (this.props.onRadiusChange) {
      this.props.onRadiusChange(parseInt(radius));
    }
  }

  setMapRadius(center, radius) {
    if (!center || !radius) {
      if (this.radius && this.radius.remove) {
        this.radius.remove();
      }
      return;
    }

    if (!this.radius) {
      this.radius = new MapboxCircle(center, radius * this.radiusMultiplier, {
        editable: !this.props.readonly,
        minRadius: this.radiusMultiplier
      }).addTo(this.map);
      this.circleBounds = new mapboxGl.LngLat(
        this.radius.getCenter().lng,
        this.radius.getCenter().lat
      ).toBounds(this.radius.radius);
      this.setCurrentView();

      this.radius.on('radiuschanged', (obj) => {
        // On radius change, update the circleBounds and update current view with setCurrentView
        this.circleBounds = new mapboxGl.LngLat(
          obj.getCenter().lng,
          obj.getCenter().lat
        ).toBounds(obj.radius);
        this.setCurrentView();
        const newRadius = obj.getRadius();
        if (
          this.props.onRadiusChange &&
          newRadius !== radius * this.radiusMultiplier
        ) {
          this.radiusChange(newRadius / this.radiusMultiplier);
        }
      });

      return;
    }

    this.radius.setCenter({ lng: center[0], lat: center[1] });
    this.radius.setRadius(radius * this.radiusMultiplier);
  }

  render() {
    const { styles: s, show, fullHeight, token } = this.props;
    const { ready, isLoading, error } = this.state;

    if (error) {
      return (
        <Box
          flexDirection='column'
          justifyContent='center'
          alignItems='center'
          {...s('map', { mapFullHeight: fullHeight })}
        >
          <Small grey>
            Oops! Sorry, the map can&apos;t be shown right now.
          </Small>
        </Box>
      );
    }

    return (
      <Box
        style={{
          position: 'relative',
          width: '100%',
          height: fullHeight ? '100%' : 'auto'
        }}
      >
        <Box
          {...s('container')}
          style={{
            visibility: isLoading ? 'hidden' : 'visible',
            height: fullHeight ? '100%' : 'auto'
          }}
        >
          <Box {...s('map', { mapFullHeight: fullHeight })} id={this.id}>
            {ready
              ? !show && (
                  <Box
                    {...s('mapOverlay')}
                    alignItems='center'
                    justifyContent='center'
                  >
                    <Small>Select address to show map</Small>
                  </Box>
                )
              : null}
          </Box>
        </Box>
        {isLoading && (
          <Box
            {...s('loadingOverlay', { activeLoadingOverlay: isLoading })}
            flexDirection='column'
            justifyContent='center'
            alignItems='center'
          >
            <LoadingSpinner colors={[token('legacy.color.blue.grey')]} />
          </Box>
        )}
      </Box>
    );
  }
}

export default Map;

import { Box } from '@rexlabs/box';
import { GhostButton, PrimaryButton } from '@rexlabs/button';
import { withModel } from '@rexlabs/model-generator';
import { autobind } from 'core-decorators';
import React, { PureComponent } from 'react';
import sessionModel from 'src/data/models/custom/session';
import usersModel from 'src/data/models/entities/users';
import { withToken } from 'src/theme';
import { ModalStickyButtonGroup } from 'src/view/components/button';
import { Modal } from 'src/view/components/modal';
import { Body } from 'src/view/components/text';
import withError from 'src/view/containers/with-error';
import withToast from 'src/view/containers/with-toast';

@withToken
@withToast
@withError()
@withModel(usersModel)
@withModel(sessionModel)
@autobind
class TransferOwnershipModal extends PureComponent {
  state = {
    loading: false
  };

  handleSubmit() {
    const {
      users,
      error: { open },
      toUser,
      closeModal,
      toasts
    } = this.props;
    this.setState({ loading: true }, () => {
      users
        .updateItem({ id: toUser.id, data: { is_owner: true } })
        .then(() => {
          toasts.addToast({ title: 'Ownership has been transfered!' });
          closeModal();
        })
        .catch((e) => {
          open(e);
          this.setState({ loading: false });
        });
    });
  }

  render() {
    const {
      closeModal,
      session,
      toUser,
      error: { Error },
      token
    } = this.props;
    return (
      <Modal
        title='Transfer ownership'
        subtitle={toUser.email}
        onClose={closeModal}
        width='45rem'
      >
        <Box mb={token('spacing.xl')}>
          <Body grey>
            Are you sure you want to transfer ownership of this account to{' '}
            <b>
              {toUser.given_name} {toUser.family_name}
            </b>
            ?
          </Body>

          {!session.user.is_sys_admin && (
            <Body grey>
              After the transfer you will be still able to use your account, but
              you will be no longer account administrator. You can not undone
              this action. Choose wisely.
            </Body>
          )}
        </Box>

        <ModalStickyButtonGroup>
          <GhostButton onClick={closeModal}>Cancel</GhostButton>
          <PrimaryButton
            isLoading={this.state.loading}
            onClick={this.handleSubmit}
          >
            Transfer ownership
          </PrimaryButton>
        </ModalStickyButtonGroup>

        <Error />
      </Modal>
    );
  }
}

export default TransferOwnershipModal;

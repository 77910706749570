import { createRestAPIModelGenerator } from 'src/data/models/generator';
import { api } from 'src/utils/api-client';

export const TYPE = 'uploads';

const initialState = {};

const selectors = {
  uploads: (state) => state.uploads,
  images: (state) => state.images
};

const actionCreators = {
  uploadFiles: {
    request: (formData) =>
      api.post('/uploads', formData, {
        headers: { 'Content-Type': 'multipart/form-data' }
      }),
    reduce: {
      initial: (state) => ({ ...state, status: 'uploading' }),
      success: (state, action) => ({
        ...state,
        uploads: {
          ...state.uploads,
          ...action?.payload?.data,
          status: 'uploaded'
        }
      }),
      failure: (state) => ({ ...state, status: 'error' })
    }
  },
  uploadCSVFiles: {
    request: (formData) =>
      api.post('/uploads/csv', formData, {
        headers: { 'Content-Type': 'multipart/form-data' }
      }),
    reduce: {
      initial: (state) => ({ ...state, status: 'uploading' }),
      success: (state, action) => ({
        ...state,
        uploads: {
          ...state.uploads,
          ...action?.payload?.data,
          status: 'uploaded'
        }
      }),
      failure: (state) => ({ ...state, status: 'error' })
    }
  }
};

const UploadsModel = createRestAPIModelGenerator(TYPE);
const uploads = UploadsModel.createModel({
  initialState,
  selectors,
  actionCreators
});

export default uploads;

import React, { Component, lazy } from 'react';
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import sessionModel from 'src/data/models/custom/session';
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import { withModel } from '@rexlabs/model-generator';
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import { withWhereabouts } from '@rexlabs/react-whereabouts';
import { match } from '@rexlabs/whereabouts';
import config from 'src/config';
import ADMIN_ROUTES from 'src/routes/admin';
import SELECT_AGENCY_ROUTES from 'src/routes/select-agency';
import { setAuthToken } from 'src/utils/api-client';
import { ensureAccountIndex } from 'src/utils/router';
import { replace } from 'src/utils/whereabouts';

import AdminLayout from 'src/view/layouts/admin';
import AuthLayout from 'src/view/layouts/auth';
import DashboardLayout from 'src/view/layouts/embed/campaigns/dashboard';
import IntroLayout from 'src/view/layouts/embed/campaigns/intro';
import LoadingLayout from 'src/view/layouts/loading';
import NotificationsLayout from 'src/view/layouts/notifications';
import ReportLayout from 'src/view/layouts/report';
import SelectAgencyLayout from 'src/view/layouts/select-agency';
import SysadminLayout from 'src/view/layouts/sysadmin';
import WizardLayout from 'src/view/layouts/wizard';

import {
  createBrowserRouter,
  redirect,
  RouterProvider
} from 'react-router-dom';
import {
  accountIndexLoader,
  SelectedAccountLayout
} from 'src/features/accounts';
import { AuthenticationErrorBoundary } from 'src/features/auth';
import { CampaignErrorBoundary } from 'src/features/campaigns';
import {
  CampaignEditorLayout,
  wizardCreateLoader,
  wizardCreateRoutes,
  wizardEditLoader,
  wizardEditRoutes,
  WizardErrorBoundary
} from 'src/features/wizard';
import { BreadcrumbsProvider } from 'src/hooks';
import { queryClient } from 'src/lib/react-query';
import { isAuthenticated } from 'src/stores/auth';
import { FlagsProvider } from 'src/utils/feature-flags';
import MaintenanceScreen from 'src/view/screens/maintenance';
import { ZendeskMessenger } from 'view/components/zendesk-messenger';

const NewWizardLayout = lazy(() => import('src/features/wizard'));

@withWhereabouts()
@withModel(sessionModel)
class SpokeApp extends Component {
  layout = null;

  constructor(props) {
    super(props);
    this.layout = this.newLayout(props);
  }

  shouldComponentUpdate(nextProps) {
    this.layout = this.newLayout(nextProps);
    return this.layout !== 'REDIRECTING';
  }

  newLayout({ session, whereabouts, setup }) {
    // Session is still initializing or errored during init

    if (session.maintenanceMode) {
      return <MaintenanceScreen />;
    }

    if (!session.ready) {
      return <LoadingLayout />;
    }

    if (session.user && session.user.is_sys_admin) {
      const isSysadmin = match(whereabouts, {
        path: '/sysadmin/(.*)'
      });

      if (isSysadmin) {
        return <SysadminLayout />;
      }
    }

    // Even guest users should be able to access reports!?!
    const isReport = match(whereabouts, {
      path: '/report/(.*)'
    });

    if (isReport) {
      return <ReportLayout />;
    }

    const isEmbedCampaignsIntro = match(whereabouts, {
      path: '/embed/campaigns/intro'
    });

    if (isEmbedCampaignsIntro) {
      return <IntroLayout />;
    }

    const isEmbedCampaigns = match(whereabouts, {
      path: '/embed/campaigns/(.*)'
    });

    if (isEmbedCampaigns) {
      setAuthToken(config.EMBED_API);
      return <DashboardLayout />;
    }

    // External notifications are visited by clicking unsubscribe
    // in an email and will include a token in the url
    const isExternalNotifications = match(whereabouts, {
      path: '/notifications/(.*)'
    });

    if (isExternalNotifications) {
      return <NotificationsLayout />;
    }

    // Check if its the invitation flow
    const isInvitation = match(whereabouts, {
      path: '/invitation/([^/]+)'
    });

    // If it is or user is not authenticated show auth shell
    if (isInvitation || !session.apiToken) {
      return <AuthLayout />;
    }

    // User logged in, but not attached to any account yet
    // = show setup flow
    if (!session.accounts.length && (!session.currentAccountId || setup.step)) {
      return <WizardLayout />;
    }

    // TODO: does the user need to set up his/her account?
    // -> if so, redirect to `/setup/account`

    const isSelectAgency = match(whereabouts, {
      path: '/select-agency'
    });

    // Redirect user if `?redirect=x` is set
    if (session.currentAccountId && whereabouts.query.redirect) {
      replace({ config: { path: whereabouts.query.redirect } });
      return 'REDIRECTING';
    }

    const matchAccountId = match(whereabouts, {
      path: '/:accountId([a-fA-F0-9]{8}-[a-fA-F0-9]{4}-[a-fA-F0-9]{4}-[a-fA-F0-9]{4}-[a-fA-F0-9]{12})?/:rest(.*)'
    });

    // If we have an accountId in the url set the account to that acount
    // and then remove the accountId from the url
    if (matchAccountId.params.accountId) {
      session.setAccount({ id: matchAccountId.params.accountId, session });
      replace({ config: { path: `/${matchAccountId.params.rest}` } });
      return 'REDIRECTING';
    }

    // Ensure `:accountIndex` for URL here!
    ensureAccountIndex(whereabouts, session);

    const isLogin = match(whereabouts, {
      path: '/login'
    });

    // select-agency query param is checked here as this is used as a way
    // to force redirect to the select-agency page
    if (
      !isSelectAgency &&
      (!session.currentAccountId || whereabouts.query['select-agency'])
    ) {
      const matched = match(whereabouts, {
        path: '/:accountIndex([0-9]+)?/:rest(.*)'
      });

      const redirect =
        !isLogin && matched.params.rest
          ? {
              query: {
                redirect: `/${matched.params.rest}${
                  whereabouts.query.token
                    ? `?token=${whereabouts.query.token}`
                    : ''
                }`
              }
            }
          : whereabouts.query.redirect
          ? { query: { redirect: whereabouts.query.redirect } }
          : {};

      replace(SELECT_AGENCY_ROUTES.SELECT_AGENCY, redirect);
      return 'REDIRECTING';
    }

    if (!session.currentAccountId) {
      return <SelectAgencyLayout />;
    }

    // Check if the url matches the create flow
    const isCampaignStart = match(whereabouts, {
      path: '/:accountIndex([0-9]+)?/campaign/create'
    });
    const isCampaignCreate = match(whereabouts, {
      path: '/:accountIndex([0-9]+)?/campaign/create/(.*)'
    });

    if (isCampaignStart || isCampaignCreate) {
      return <WizardLayout />;
    }

    // Changed accounts so we need to check we're not on a campaign route
    // currently the only routes are campaign/details/:id and campaign/edit/:id
    if (this.props.session.currentAccountId !== session.currentAccountId) {
      const matched = match(whereabouts, {
        path: '/:accountIndex([0-9]+)?/campaign/:rest(.*)'
      });

      if (matched) {
        replace(ADMIN_ROUTES.CAMPAIGNS);
        return 'REDIRECTING';
      }
    }

    // ... otherwise we can assume that we need to display the admin area!
    return (
      <BreadcrumbsProvider>
        <AdminLayout />
      </BreadcrumbsProvider>
    );
  }

  render() {
    const { session } = this.props;

    // Need this function's side-effect to hydrate the authStore before router loaders are executed to avoid data-races
    isAuthenticated();

    const router = createBrowserRouter([
      {
        path: '/login',
        element: this.layout
      },
      {
        path: '/select-agency',
        element: this.layout
      },
      {
        path: '/reset-password/:token',
        element: this.layout
      },
      {
        path: '/:accountIndex',
        element: <SelectedAccountLayout />,
        loader: accountIndexLoader(queryClient),
        errorElement: <AuthenticationErrorBoundary />,
        children: [
          {
            index: true,
            loader: () => {
              return redirect(`./campaigns`);
            }
          },
          {
            path: 'campaigns/create/d/:campaignDefinitionId',
            element: <NewWizardLayout mode='create' />,
            errorElement: <WizardErrorBoundary />,
            loader: wizardCreateLoader(queryClient, session.currentAccountId),
            children: wizardCreateRoutes(session.currentAccountId)
          },
          {
            path: 'campaigns/:campaignId/edit/d',
            id: 'campaign',
            element: <CampaignEditorLayout />,
            errorElement: <CampaignErrorBoundary />,
            loader: wizardEditLoader(queryClient, session.currentAccountId),
            children: wizardEditRoutes(session.currentAccountId)
          }
        ]
      },
      {
        path: '*',
        element: this.layout
      }
    ]);

    return (
      <FlagsProvider features={session.currentAccount?.feature_flags ?? []}>
        <RouterProvider router={router} />
        <ZendeskMessenger apiKey={config.ZENDESK_MESSENGER_API_KEY} />
      </FlagsProvider>
    );
  }
}

export default SpokeApp;

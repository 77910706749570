/* eslint-disable max-lines */
import React from 'react';
import Box from '@rexlabs/box';
import { styled, StyleSheet } from '@rexlabs/styling';
import charlimit, { limits } from '../../../utils/char-limit';
import getContrastColor from '../../../utils/contrast-color';
import { useFeature } from '../../../hooks';
import { COLORS } from 'src/theme';
import { injectGlobal } from 'styled-components';
import { fontFace } from 'utils/styling';
import config from 'config';

import Banner from './banner';
import Bed from '../../../assets/bed.svg';
import Bath from '../../../assets/bath.svg';
import Car from '../../../assets/car.svg';

const NATIVE_AD_WIDTH = 1125;
const NATIVE_AD_HEIGHT = NATIVE_AD_WIDTH;

injectGlobal`
  ${fontFace('Gordita', `${config.API_URL}/assets/gordita-regular.woff2`)}
  ${fontFace('Lato', `${config.ASSETS_URL}/fonts/lato/Lato-Regular.ttf`)}
`;

const defaultAdImageStyles = StyleSheet({
  container: {
    width: `${NATIVE_AD_WIDTH}px`,
    height: `${NATIVE_AD_HEIGHT}px`,
    fontFamily: 'Circular Pro, Helvetica, Arial, sans-serif'
  },

  gorditaFont: {
    fontFamily: 'Gordita, Circular Pro, Helvetica, Arial, sans-serif'
  },

  latoFont: {
    fontFamily: 'Lato, Circular Pro, Helvetica, Arial, sans-serif'
  },

  backgroundImage: {
    height: '875px',
    background: 'none no-repeat top center',
    backgroundColor: COLORS.GREY_LIGHT,
    backgroundSize: 'cover',
    overflow: 'hidden',
    position: 'relative',
    '@media print': {
      '-webkit-print-color-adjust': 'exact'
    }
  },

  footer: {
    position: 'relative',
    height: '250px',
    paddingLeft: '44px',
    paddingRight: '44px',
    '@media print': {
      '-webkit-print-color-adjust': 'exact'
    }
  },

  contentWrapper: {
    padding: '58px 44px 44px 0'
  },

  smallText: {
    fontSize: '48px',
    fontWeight: '800',
    lineHeight: '60px',
    marginBottom: '12px'
  },

  fontWeight600: {
    fontWeight: '600'
  },

  listing: {
    fontSize: '45px',
    fontWeight: '800',
    lineHeight: '60px'
  },

  listing2: {
    fontWeight: '300'
  },

  bedBathCar: {
    opacity: 0.8,
    marginRight: '48px',
    '& svg': {
      marginRight: '25px'
    }
  },

  pipe: {
    whiteSpace: 'pre'
  },

  agentWrapper: {
    position: 'absolute',
    bottom: '44px',
    right: '44px',
    minWidth: '240px',
    maxWidth: '355px',
    textAlign: 'center'
  },

  agentImage: {
    width: '240px',
    height: '240px',
    margin: '0 auto 8px auto',
    objectFit: 'cover',
    borderRadius: '50%'
  },

  agentName: {
    fontSize: '36px',
    fontWeight: '800',
    lineHeight: 1.1,
    marginBottom: '8px'
  },

  agency: {
    fontSize: '30px',
    fontWeight: '400',
    lineHeight: 1.1,
    opacity: 0.8
  },

  logoWrapper: {
    minWidth: '240px',
    maxWidth: '355px',
    textAlign: 'center',
    padding: '58px 0 58px 44px'
  },
  logo: {
    width: '100%',
    height: '100%',
    objectFit: 'contain'
  }
});

/**
 * NOTE/TODO: this is getting messy with mixing various styles based on feature flags
 * and needs a rethink/refactor.
 */
function AdImage(props) {
  const { styles: s, adContentSet, ad, scaleTo, scalePos = 'left top' } = props;
  const bannerStyle2 = useFeature(
    'facebook_listing_banner_style_2',
    adContentSet.session
  );
  const bannerStyle3 = useFeature(
    'facebook_listing_banner_style_3',
    adContentSet.session
  );
  const latoFont = useFeature(
    'facebook_listing_banner_lato_font',
    adContentSet.session
  );
  const format = ad?.format?.id ?? '';
  const isSecondary = format.endsWith('_secondary');
  const imageUrl =
    adContentSet?.[isSecondary ? 'secondaryImage' : 'primaryImage']?.square;
  const listing = adContentSet?.listing ?? {};
  const { beds, baths, cars } = listing;
  const agentImageUrl = adContentSet?.agentImage?.square;
  const agencyLogoUrl = adContentSet?.agencyLogo;

  const BedBathCarNoIcons = () => {
    return (
      <Box flexDirection='row' {...s('listing', 'listing2')}>
        {!!beds && <Box>{beds} Bed</Box>}
        {!!beds && (!!baths || !!cars) && <span {...s('pipe')}> | </span>}
        {!!baths && <Box>{baths} Bath</Box>}
        {!!baths && !!cars && <span {...s('pipe')}> | </span>}
        {!!cars && <Box>{cars} Car</Box>}
      </Box>
    );
  };

  /**
   * Standard banner style
   * - bed, bath, car are icons
   * - standard font which should be Circular Pro
   */
  const BottomBannerStyle1 = () => {
    return (
      <Box
        flexDirection='row'
        justifyContent='space-between'
        {...s.with('footer')({
          color: getContrastColor(adContentSet.backgroundColor),
          background: adContentSet.backgroundColor
        })}
      >
        <Box {...s('contentWrapper')}>
          <div {...s('smallText')}>
            {charlimit(adContentSet.smallText, limits.SMALL_TEXT)}
          </div>
          <Box flexDirection='row' {...s('listing')}>
            {!!beds && (
              <Box {...s('bedBathCar')}>
                <Bed /> {beds}
              </Box>
            )}
            {!!baths && (
              <Box {...s('bedBathCar')}>
                <Bath /> {baths}
              </Box>
            )}
            {!!cars && (
              <Box {...s('bedBathCar')}>
                <Car /> {cars}
              </Box>
            )}
          </Box>
        </Box>

        {adContentSet.agentName ? (
          <Box {...s('agentWrapper')}>
            <img src={agentImageUrl} {...s('agentImage')} />
            <Box>
              <div {...s('agentName')}>{adContentSet.agentName}</div>
              <div {...s('agency')}>{adContentSet.agency}</div>
            </Box>
          </Box>
        ) : (
          <Box {...s('logoWrapper')}>
            <img src={agencyLogoUrl} {...s('logo')} />
          </Box>
        )}
      </Box>
    );
  };

  /**
   * Avnu style
   * - bed, bath, car words instead of icons
   * - no use of agent image or logo
   * - Gordita font
   */
  const BottomBannerStyle2 = () => {
    return (
      <Box
        flexDirection='row'
        justifyContent='space-between'
        {...s.with('footer')({
          color: getContrastColor(adContentSet.backgroundColor),
          background: adContentSet.backgroundColor
        })}
      >
        <Box {...s('contentWrapper')}>
          <div {...s('smallText')}>
            {charlimit(adContentSet.smallText, limits.LONG_HEADLINE)}
          </div>

          <BedBathCarNoIcons />
        </Box>
      </Box>
    );
  };

  /**
   * Ray White Prestige style
   * - bed, bath, car words intead of icons
   * - uses agent image or logo
   * - Lato font
   */
  const BottomBannerStyle3 = () => {
    return (
      <Box
        flexDirection='row'
        justifyContent='space-between'
        {...s.with('footer')({
          color: getContrastColor(adContentSet.backgroundColor),
          background: adContentSet.backgroundColor
        })}
      >
        <Box {...s('contentWrapper')}>
          <div {...s('smallText', 'fontWeight600')}>
            {charlimit(adContentSet.smallText, limits.LONG_HEADLINE)}
          </div>

          <BedBathCarNoIcons />
        </Box>

        {adContentSet.agentName ? (
          <Box {...s('agentWrapper')}>
            <img src={agentImageUrl} {...s('agentImage')} />
            <Box>
              <div {...s('agentName', 'fontWeight600')}>
                {adContentSet.agentName}
              </div>
              <div {...s('agency')}>{adContentSet.agency}</div>
            </Box>
          </Box>
        ) : (
          <Box {...s('logoWrapper')}>
            <img src={agencyLogoUrl} {...s('logo')} />
          </Box>
        )}
      </Box>
    );
  };

  return (
    <Box
      {...s.with('container', {
        gorditaFont: bannerStyle2,
        latoFont: latoFont || bannerStyle3
      })({
        transformOrigin: `${scalePos}`,
        transform: `scale(${scaleTo / NATIVE_AD_HEIGHT})`
      })}
    >
      <Box
        {...s.with('backgroundImage', { latoFont })({
          backgroundImage: imageUrl ? `url(${imageUrl})` : 'none'
        })}
      >
        {adContentSet.banner && (
          <Banner
            adContentSet={adContentSet}
            gorditaFont={bannerStyle2}
            latoFont={latoFont || bannerStyle3}
          />
        )}
      </Box>

      {bannerStyle2 ? (
        <BottomBannerStyle2 />
      ) : bannerStyle3 ? (
        <BottomBannerStyle3 />
      ) : (
        <BottomBannerStyle1 />
      )}
    </Box>
  );
}

export default styled(defaultAdImageStyles)(AdImage);

// *********************** IMPORTANT *********************** //
// Changes to this function are versioned and saved in the
// db when a campaign is launched.
//
// NOTE: If you make a change to this function update the
// version in the return object.
// *********************** IMPORTANT *********************** //
const calcAudienceHealth = ({
  geoRange = 10,
  suburbs = [],
  segments = [],
  generalAudiences = [],
  retargetingAudiences = [],
  ageRange = {}
} = {}) => {
  const formattedSegments = Array.isArray(segments)
    ? segments.reduce((acc, audience) => {
        if (audience.id === 'my_database') {
          return acc;
        }

        acc[audience.id] = true;
        return acc;
      }, {})
    : delete segments.my_database && segments;

  const selectedSegments =
    Object.values(formattedSegments).filter(Boolean).length;
  const totalSegments = Object.keys([
    ...generalAudiences,
    ...retargetingAudiences
  ]).length;

  const filteredSuburbs = suburbs.filter((s) => typeof s === 'object');
  // red range 1 - 2 ticks (0 - 35deg)
  // green range 2 - 8 ticks (38 - 141deg)
  // yellow range 8 - 10 ticks (145 - 180deg)
  let value = 0;
  const tickValue = 180 / 10;

  const startingPoint =
    geoRange <= 9
      ? ((tickValue * 2) / 8) * (geoRange - 1)
      : geoRange <= 20
      ? tickValue * 4 + ((tickValue * 2) / 10) * (geoRange - 10)
      : Math.min(180, tickValue * 8 + ((tickValue * 2) / 20) * (geoRange - 20));

  const suburbsTicks = filteredSuburbs.length * 0.5;
  const exceedsGreenZone =
    startingPoint + tickValue * suburbsTicks > tickValue * 7.5;

  if (exceedsGreenZone) {
    if (filteredSuburbs.length <= 5) {
      value = tickValue * 7.5;
    } else {
      const suburbTicksAtGreenLimit =
        (tickValue * 7.5 - startingPoint) / tickValue;
      value =
        tickValue * 7.5 +
        tickValue * (suburbsTicks - Math.max(2.5, suburbTicksAtGreenLimit));
    }
  } else {
    value = startingPoint + tickValue * suburbsTicks;
  }

  const ageRangeDiff = (ageRange.oldest || 0) - (ageRange.youngest || 0);
  const ageRangeTicks = ageRangeDiff < 5 ? -1 : ageRangeDiff < 15 ? 0 : 1;

  const segmentTicks =
    selectedSegments <= 1 ? -1 : selectedSegments === totalSegments ? 1 : 0;

  value += tickValue * (ageRangeTicks + segmentTicks);
  value = Math.max(0, Math.min(180, value));

  return { value: value / tickValue, degrees: value, version: '0.0.1' };
};

export default calcAudienceHealth;

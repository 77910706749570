import React, { FC } from 'react';
import { ContentTypeEditorProps } from 'src/features/wizard';
import { CatalogListingSetCreateForm } from './details-form/CatalogListingSetCreateForm';

export const CatalogListingSetDetailsEditor: FC<ContentTypeEditorProps> = ({
       selectContent
   }) => {
    return (
            <CatalogListingSetCreateForm selectContent={selectContent} />
        )
};

import { Box } from '@rexlabs/box';
import { GhostButton, PrimaryButton } from '@rexlabs/button';
import DateInput from '@rexlabs/date-input';
import { styled, StyleSheet } from '@rexlabs/styling';
import { autobind } from 'core-decorators';
import moment from 'moment';
import React, { PureComponent } from 'react';
import { withToken } from 'src/theme';
import { createValidationRules } from 'src/utils/form';
import { ModalStickyButtonGroup } from 'src/view/components/button';
import {
  FieldCell,
  FieldRow,
  Form,
  FormField,
  withForm
} from 'src/view/components/form';
import HiddenField from 'src/view/components/form/hidden-field';
import { TextInput } from 'src/view/components/input';
import Select from 'src/view/components/input/select/default';
import { Modal } from 'src/view/components/modal';
import { Label } from 'src/view/components/text';

import CalendarIcon from 'src/assets/icons/calendar.svg';

const defaultStyles = StyleSheet({
  datePickerPrefix: {
    color: ({ token }) => token('legacy.color.blue.grey'),
    borderRight: ({ token }) =>
      `.1rem solid ${token('legacy.color.blue.greyLight')}`,
    height: '4.3rem',
    display: 'inline-flex',
    justifyContent: 'center',
    alignItems: 'center',
    paddingRight: ({ token }) => token('spacing.m'),
    marginRight: ({ token }) => token('spacing.m')
  },

  wrapDateInput: {
    position: 'relative'
  },

  wrapCalendarIcon: {
    position: 'absolute',
    top: 0,
    right: 0,
    width: '4rem',
    height: '100%',
    color: ({ token }) => token('legacy.color.blue.grey'),
    pointerEvents: 'none',

    '> svg': {
      height: '1.6rem',
      width: 'auto'
    }
  }
});

const menuStyles = StyleSheet({
  container: {
    maxHeight: '15rem'
  }
});

const validate = createValidationRules({
  type_id: ['required', 'event type'],
  date: ['required', 'date'],
  start_at: ['required', 'start at'],
  end_at: ['required_if:type_id,open_home', 'end at'],
  location: ['required_if:type_id,auction', 'location']
});

const eventForm = {
  name: 'createEvent',
  validate,
  handleSubmit: (values, { props }) => {
    props.closeModal(values);
  }
};

function timeSlots() {
  const momentStartTime = moment().hour(6).minute(0);
  const timeSlots = [];

  while (parseInt(momentStartTime.format('Hmm')) <= 2000) {
    timeSlots.push({
      label: momentStartTime.format('h:mm a'),
      value: parseInt(momentStartTime.format('Hmm'))
    });
    momentStartTime.add(5, 'minutes');
  }

  return timeSlots;
}

@withToken
@withForm(eventForm)
@styled(defaultStyles)
@autobind
class CreateEventModal extends PureComponent {
  state = {
    startDate: null
  };

  render() {
    const { createEvent, closeModal, styles: s, token } = this.props;

    return (
      <Box>
        <Box mb={'17rem'}>
          <Form name='createEventForm'>
            <FormField
              name='type_id'
              label='Event type'
              Input={Select}
              inputProps={{
                options: [
                  { label: 'Open home', value: 'open_home' },
                  { label: 'Auction', value: 'auction' },
                  { label: 'Inspection', value: 'inspection' }
                ]
              }}
            />

            <Box mt={'0.8rem'}>
              <Label
                style={{
                  margin: 0,
                  marginRight: token('spacing.m'),
                  whiteSpace: 'nowrap'
                }}
              >
                Date
              </Label>
              <Box {...s('wrapDateInput')}>
                <DateInput
                  prefix={
                    this.state.startDate ? (
                      <Box {...s('datePickerPrefix')}>
                        {moment(this.state.startDate).format('dddd')}
                      </Box>
                    ) : null
                  }
                  colorPrimary={token('palette.brand.500')}
                  colorPrimaryLight={token('palette.brand.400')}
                  colorPrimaryDark={token('palette.brand.60')}
                  isOutsideRange={(d) => d.diff(moment(), 'days') < 0}
                  position='bottom'
                  onChange={(e) => {
                    this.setState({
                      startDate: e.target.value
                    });
                  }}
                  value={this.state.startDate}
                />
                <Box
                  {...s('wrapCalendarIcon')}
                  justifyContent='center'
                  alignItems='center'
                >
                  <CalendarIcon />
                </Box>
              </Box>
            </Box>

            <HiddenField name='date' value={this.state.startDate} />

            <FieldRow>
              <FieldCell width={1 / 2}>
                <FormField
                  name='start_at'
                  label='Start time'
                  Input={Select}
                  inputProps={{
                    options: timeSlots(),
                    menuStyles
                  }}
                />
              </FieldCell>

              {['open_home', 'inspection'].includes(
                createEvent.values.type_id
              ) && (
                <FieldCell width={1 / 2}>
                  <FormField
                    name='end_at'
                    label='End time'
                    Input={Select}
                    inputProps={{
                      options: timeSlots(),
                      menuStyles
                    }}
                  />
                </FieldCell>
              )}

              {createEvent.values.type_id === 'auction' && (
                <FieldCell width={1 / 2}>
                  <FormField
                    name='location'
                    label='Location'
                    Input={TextInput}
                    inputProps={{
                      placeholder: 'e.g. Auction on Site'
                    }}
                  />
                </FieldCell>
              )}
            </FieldRow>
          </Form>
        </Box>
        <ModalStickyButtonGroup>
          <GhostButton onClick={() => closeModal(null)}>Cancel</GhostButton>
          <PrimaryButton
            form='createEventForm'
            onClick={createEvent.submitForm}
            isLoading={createEvent.isSubmitting}
          >
            Create
          </PrimaryButton>
        </ModalStickyButtonGroup>
      </Box>
    );
  }
}

function Core(props) {
  return (
    <Modal
      title='Add a new event'
      width='56rem'
      onClose={() => props.closeModal(null)}
    >
      <CreateEventModal {...props} />
    </Modal>
  );
}

export default Core;

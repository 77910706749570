import React from 'react';
import { TextArea as VividTextArea } from '@rexlabs/text-input';
import { StyleSheet } from '@rexlabs/styling';
import { FONT } from 'src/theme';
import { standardTextAreaStyles } from './text-area';

const textInputStyles = StyleSheet({
  ...standardTextAreaStyles,

  charLimitExceeded: {
    color: ({ token }) => token('legacy.color.orange.default'),
    fontWeight: FONT.WEIGHTS.SEMIBOLD
  }
});

const SoftCharLimitTextArea = (props) => {
  return (
    <VividTextArea
      containerProps={{ flex: 1 }}
      styles={textInputStyles}
      {...props}
    />
  );
};

export default SoftCharLimitTextArea;

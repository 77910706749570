import { Box } from '@rexlabs/box';
import { styled, StyleSheet } from '@rexlabs/styling';
import React, { PureComponent } from 'react';
import { FONT } from 'src/theme';

const defaultStyles = StyleSheet({
  container: {
    fontSize: '1.4rem',
    fontWeight: FONT.WEIGHTS.LIGHT,
    color: ({ token }) => token('legacy.color.blue.grey'),
    borderBottom: ({ token }) =>
      `.1rem solid ${token('legacy.color.blue.greyLight')}`,
    padding: ({ token }) => token('spacing.xs'),
    paddingLeft: 0
  }
});

@styled(defaultStyles)
class LocationListHeader extends PureComponent {
  render() {
    const { styles: s } = this.props;
    return (
      <Box
        flexDirection='row'
        alignItems='center'
        justifyContent='space-between'
        {...s('container')}
      >
        <Box flex={1}>City</Box>
        <Box style={{ width: '13rem' }}>Visitors</Box>
      </Box>
    );
  }
}

export default LocationListHeader;

import {useQuery} from 'react-query';
import { useAccountId } from 'src/features/accounts';
import {InfiniteQueryConfig, QueryResult} from 'src/lib/react-query';
import { api } from 'src/utils/api-client';
import { catalogListingSetKeys } from './keys';
import {RexApiError} from "@rexlabs/api-client";
import {TemplateImage} from "src/features/ads";

export type GetCatalogListingSetAdPreviewParams = {
  accountId: string;
  listing_id?: string;
  listingSetId: string;
};

export const getCatalogListingSetAdPreview = async ({
  accountId,
  listingSetId,
  listing_id,
  ...params
}: GetCatalogListingSetAdPreviewParams): Promise<any> => {
  const { data } = await api.get(`/listing-sets/${listingSetId}/preview`, {
    listing_id,
    ...params,
  });
  return {
    data,
  };
};

type QueryFnType = typeof getCatalogListingSetAdPreview;

export type UseCatalogListingSetAdPreviewOptions = Omit<GetCatalogListingSetAdPreviewParams, 'accountId'> & {
  config?: InfiniteQueryConfig<QueryFnType>;
};


export type ListingSetPreviewData = {
    attributes: {
      listing: {
        show_banner: boolean;
        banner_text: string;
        suburb: string;
        category: string;
        car_ports: number;
        listing_id: string;
        listing_location: {
          full_address: string;
        }
        agent_image: any;
        agent_full_name: string;
        agency_name: string;
        agency_logo: string;
        final_url: string;
        long_headline: string;
        short_headline: string;
        description: string;
        primary_image?: TemplateImage;
      };
      show_agent_image: boolean;
      listing_sold: boolean;
      final_url: string;
    };
}
export const useCatalogListingSetAdPreview = ({
  config,
  listingSetId,
  listing_id,
  ...params
}: UseCatalogListingSetAdPreviewOptions): QueryResult<{data: ListingSetPreviewData[]}> => {
  const accountId = useAccountId();

  return useQuery<any, RexApiError>({
    queryKey: catalogListingSetKeys.preview({ accountId, listingSetId, listing_id, ...params }),
    queryFn: () => getCatalogListingSetAdPreview({accountId, listingSetId, listing_id, ...params }),
    ...config
  });
};

import { useQuery } from 'react-query';
import { api } from 'src/utils/api-client';
import { rexIntegrationKeys } from './keys';
import { RexAccount, RexIntegrationResponse } from './types';

export const getRexIntegration = async (): Promise<RexIntegrationResponse> => {
  const res = await api.get('/integrations/rex');
  return res.data;
};

export const getRexIntegrationAccounts = async (): Promise<RexAccount[]> => {
  const res = await api.get('/integrations/rex/accounts');
  return res.data;
};

export const useRexIntegration = (accountId: string) => {
  return useQuery(rexIntegrationKeys.rex(accountId), () => getRexIntegration());
};

export const useRexIntegrationAccounts = (accountId: string) => {
  return useQuery(rexIntegrationKeys.accounts(accountId), () =>
    getRexIntegrationAccounts()
  );
};

import React from 'react';
import { StyleSheet, useStyles } from '@rexlabs/styling';

const defaultStyles = StyleSheet({
  tag: {
    fontWeight: ({ token }) => token('typography.weight.bold'),
    color: ({ token }) => token('palette.brand.600')
  }
});

export function NewTag() {
  const s = useStyles(defaultStyles);

  return <span {...s('tag')}>NEW!</span>;
}

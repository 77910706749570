import { FormOptions, Schema } from '@data-driven-forms/react-form-renderer';
import React, { FC } from 'react';
import {
  DynamicFormRenderer,
  DynamicFormRendererProps
} from 'src/components/dynamic-forms';
import { WizardFormTemplate } from 'src/features/wizard';
import { TemplateData } from '../types';

type FormState = ReturnType<FormOptions['getState']>;

export type AdsEditorFormProps = {
  schema: Schema;
  onSubmit: Required<DynamicFormRendererProps>['onSubmit'];
  onCancel: (values: TemplateData, state: FormState, event: MouseEvent) => void;
  onChange: (data: TemplateData) => void;
  onDirty?: (x: boolean) => void;
};

export const AdsEditorForm: FC<AdsEditorFormProps> = ({
  schema,
  onSubmit,
  onCancel,
  onChange,
  onDirty
}) => {
  return (
    <DynamicFormRenderer
      schema={schema}
      FormTemplate={WizardFormTemplate}
      onSubmit={onSubmit}
      onCancel={onCancel}
      FormTemplateProps={{
        onValuesChange: onChange,
        onDirty
      }}
    />
  );
};

import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import Box from '@rexlabs/box';

import { styled, StyleSheet } from '@rexlabs/styling';
import colorTheme from '../../../utils/color-theme';
import charLimit, { limits } from '../../../utils/char-limit';
import { RegularButton } from '../../button';
import { COLORS } from 'src/theme';
import _ from 'lodash';

export const types = {
  DEFAULT: 'default',
  LARGE: 'large',
  SQUARE: 'square',
  SMALL: 'small'
};

const fontFamily = 'Lato, sans-serif';
const defaultStyles = StyleSheet({
  inlineRectangle: {
    overflow: 'hidden',
    border: '1px solid darkgray',
    display: 'flex',
    flexDirection: 'column'
  },
  main: {
    width: '100%',
    display: 'flex'
  },
  content: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    height: '100%',
    maxWidth: '50%',
    background: 'white'
  },
  shortHeadlineWrapper: {
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'flex-start',
    backgroundColor: '#F8F8F8'
  },
  shortHeadline: {
    fontFamily,
    fontSize: '18px',
    fontWeight: 500,
    lineHeight: '18px'
  },
  agentDetails: {
    display: 'flex',
    flexDirection: 'column',
    backgroundColor: 'white'
  },
  agentName: {
    fontSize: '22px',
    fontFamily,
    fontWeight: 300,
    lineHeight: '24px',
    marginBottom: '5px'
  },
  suburb: {
    fontSize: '10px',
    fontWeight: 600,
    lineHeight: '12px',
    fontFamily,
    textTransform: 'uppercase'
  },
  primaryImage: {
    maxWidth: '50%',
    objectFit: 'cover'
  },
  footer: {
    display: 'flex',
    justifyContent: 'space-between'
  },
  logo: {
    height: '100%',
    width: 'auto',
    objectFit: 'contain'
  }
});

@styled(defaultStyles)
class InlineRectangle extends PureComponent {
  static propTypes = {
    shortHeadline: PropTypes.string,
    agentName: PropTypes.string,
    suburb: PropTypes.string,
    buttonText: PropTypes.string,
    brandColor: PropTypes.string,
    primaryImageUrls: PropTypes.object,
    logoUrls: PropTypes.object,
    type: PropTypes.oneOf(Object.values(types))
  };

  static defaultProps = {
    buttonText: 'Learn more',
    type: types.DEFAULT
  };

  dimensions() {
    const { type } = this.props;
    switch (type) {
      case types.LARGE:
        return { width: '336px', height: '280px' };
      case types.SQUARE:
        return { width: '250px', height: '250px' };
      case types.SMALL:
        return { width: '200px', height: '200px' };
      case types.DEFAULT:
      default:
        return { width: '300px', height: '250px' };
    }
  }

  contentPadding() {
    const { type } = this.props;
    switch (type) {
      case types.LARGE:
        return { padding: '11px 16px' };
      case types.DEFAULT:
      default:
        return { padding: '16px 18px' };
    }
  }

  mainHeight() {
    const { type } = this.props;
    switch (type) {
      case types.LARGE:
        return { height: '212px' };
      case types.DEFAULT:
      default:
        return { height: '191px' };
    }
  }

  footerDimensions() {
    const { type } = this.props;
    switch (type) {
      case types.LARGE:
        return {
          height: '70px',
          padding: '17px 10px'
        };
      case types.SQUARE:
        return {
          height: '60px',
          padding: '12px 9px'
        };
      case types.DEFAULT:
      default:
        return {
          height: '60px',
          padding: '12px 17px'
        };
    }
  }

  logoDimensions() {
    const { type } = this.props;
    switch (type) {
      case types.LARGE:
        return {
          maxWidth: 'calc(0.8 * (336px - 100px - 14px))',
          maxHeight: 'calc(0.66 * 70px)'
        };
      case types.SQUARE:
        return {
          maxWidth: 'calc(0.8 * (250px - 100px - 14px))',
          maxHeight: 'calc(0.66 * 60px)'
        };
      case types.SMALL:
        return {
          maxWidth: '80%',
          margin: 'auto',
          maxHeight: 'calc(0.66 * 60px)'
        };
      case types.DEFAULT:
      default:
        return {
          maxWidth: 'calc(0.8 * (300px - 100px - 14px))',
          maxHeight: 'calc(0.66 * 60px)'
        };
    }
  }

  render() {
    const {
      shortHeadline,
      agentName,
      agentPosition,
      agencyName,
      buttonText,
      brandColor,
      backgroundColor,
      primaryImageUrls,
      logoUrls,
      type,
      styles: s
    } = this.props;

    const { brandColorBorder } = colorTheme(brandColor);

    return (
      <Box {...s.with('inlineRectangle')({ ...this.dimensions() })}>
        <Box {...s.with('main')({ ...this.mainHeight() })}>
          <Box {...s('content')}>
            {type !== types.SMALL && (
              <Box
                {...s.with('shortHeadlineWrapper')({
                  ...this.contentPadding()
                })}
              >
                <span {...s.with('shortHeadline')({ color: COLORS.BLACK })}>
                  {charLimit(shortHeadline, limits.SHORT_HEADLINE)}
                </span>
              </Box>
            )}
            <Box {...s.with('agentDetails')({ ...this.contentPadding() })}>
              <span {...s.with('agentName')({ color: COLORS.BLACK })}>
                {agentName}
              </span>
              <span {...s.with('suburb')({ color: COLORS.BLACK })}>
                {agentPosition} - {agencyName}
              </span>
            </Box>
          </Box>
          <img
            {...s('primaryImage')}
            src={_.get(primaryImageUrls, ['crop.square', 'url'])}
          />
        </Box>
        <Box
          {...s.with('footer')({
            ...this.footerDimensions(),
            backgroundColor: backgroundColor,
            borderTop: brandColorBorder
          })}
        >
          <img
            src={_.get(logoUrls, 'original.url')}
            {...s.with('logo')({ ...this.logoDimensions() })}
          />
          {type !== types.SMALL && (
            <Box>
              <RegularButton brandColor={brandColor} invert={false}>
                {buttonText}
              </RegularButton>
            </Box>
          )}
        </Box>
      </Box>
    );
  }
}

export default InlineRectangle;

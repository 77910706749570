/* eslint-disable max-lines */
import {Box} from '@rexlabs/box';
import {GhostButton, PrimaryButton} from '@rexlabs/button';
import {StyleSheet, margin, useStyles} from '@rexlabs/styling';
import React, {FC, ReactNode, useMemo} from 'react';
import {Controller, useForm} from 'react-hook-form';
import {ButtonGroup, Spacer} from 'src/components/elements/button-group';
import {
  FormControl,
  FormErrorMessage,
  FormInput,
  FormLabel
} from 'src/components/form';
import {Checkbox} from 'view/components/input';
import {NumberInput, TextInput} from '@rexlabs/text-input';
import {superstructResolver} from '@hookform/resolvers/superstruct';
import {CatalogListingSetFormDTO} from "src/features/catalog-listing-sets/types";
import {catalogListingSetFormSchema} from "src/features/catalog-listing-sets/utils/schemas";
import {NormalisedItem, Select} from "@rexlabs/select";
import {MultiAgentSelectorInput} from "src/features/agents/components/AgentSelectorInput";
import {
  AU_LISTING_CATEGORY_OPTIONS,
  DEFAULT_FORM_VALUES,
  LISTING_SORT_OPTIONS,
  LISTING_STATUSES,
  NZ_LISTING_CATEGORY_OPTIONS,
  UK_LISTING_CATEGORY_OPTIONS
} from "src/features/catalog-listing-sets/components/details-form/constants";
import {Body} from "@rexlabs/text";
import {useModelState} from "@rexlabs/model-generator";
import sessionModel from "data/models/custom/session";
import {RadioGroupInput} from "@rexlabs/radio-input";
import {getAccountRegion} from "shared/utils/region";

const styles = StyleSheet({
  form: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    width: '100%',
    '& > * + *': {
      ...margin.styles({
        top: 's'
      })
    },
    p: {
      marginTop: 0
    }
  },
  controls: {
    display: 'flex',
    flexDirection: 'column',
    gap: '18px',
    '& > * + *': {
      ...margin.styles({
        top: 's'
      })
    }
  },
  text: {
    fontSize: '15px'
  },
  agentContainer: {
    padding: ({token}) => token('spacing.m'),
    paddingTop: 0,
    border: ({token}) => `.1rem solid ${token('legacy.color.grey.default')}`,
    borderRadius: '0.5rem',
    overflow: 'hidden'
  },
  separator: {
    width: '100%',
    height: '.1rem',
    background: ({token}) => token('legacy.color.blue.greyLight')
  },
});

const LISTING_TYPE_OPTIONS = {
  'GB': UK_LISTING_CATEGORY_OPTIONS,
  'NZ': NZ_LISTING_CATEGORY_OPTIONS,
  'AU': AU_LISTING_CATEGORY_OPTIONS
}

export type CatalogListingSetSubmitHandler = (
  data: CatalogListingSetFormDTO
) => Promise<void>;

export type CatalogListingSetFormProps = Omit<
  JSX.IntrinsicElements['form'],
  'onSubmit' | 'resource'
> & {
  resource?: CatalogListingSetFormDTO;
  onSubmit: CatalogListingSetSubmitHandler;
  onCancel: () => void;
  submitLabel?: ReactNode;
};

export const CatalogListingSetForm: FC<CatalogListingSetFormProps> = ({
  resource,
  onSubmit,
  onCancel,
  submitLabel = 'Save',
  className,
  style,
  ...rest
}) => {
  const s = useStyles(styles);

  const {
    control,
    handleSubmit,
    watch,
    formState: {isSubmitting, isValid}
  } = useForm<CatalogListingSetFormDTO>({
    mode: 'onChange',
    resolver: superstructResolver(catalogListingSetFormSchema),
    defaultValues: DEFAULT_FORM_VALUES
  });
  const session = useModelState(sessionModel);
  const countryCode = getAccountRegion(session);

  const [listingMinPrice, listingMaxPrice] = watch([
    'listing_min_price',
    'listing_max_price'
  ]);

  const maxPriceHasError = useMemo(() => !!(listingMinPrice && listingMaxPrice && listingMinPrice > listingMaxPrice), [listingMinPrice, listingMaxPrice]);

  return (
    <form
      id='catalog-listing-set-form'
      {...s.with('form')({className, style})}
      {...rest}
      noValidate
      onSubmit={handleSubmit(onSubmit)}
    >
      <div {...s('controls')}>
        <Controller
          control={control}
          name='name'
          render={({field, fieldState: {error}}) => {
            return (
              <FormControl isRequired isInvalid={error !== undefined}>
                <FormLabel>Catalogue Name</FormLabel>
                <FormInput
                  Input={TextInput}
                  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                  // @ts-ignore
                  placeholder={'E.g. - Residential Sales Just Solds'}
                  {...field}
                />
                <FormErrorMessage>
                  The name field is required.
                </FormErrorMessage>
              </FormControl>
            );
          }}
        />

        <Controller
          control={control}
          name='listing_categories'
          render={({field, fieldState: {error}}) => {
            return (
              <FormControl isRequired isInvalid={error !== undefined}>
                <FormLabel>Listing Types to Include</FormLabel>
                <Select
                  items={LISTING_TYPE_OPTIONS[countryCode] ?? AU_LISTING_CATEGORY_OPTIONS}
                  onChange={(e) => {
                    field.onChange([e.target.value]);
                  }}
                  multi
                  searchable={false}
                  deselectable
                  normaliser={(item: NormalisedItem) => {
                    return item
                  }}
                />
              </FormControl>
            );
          }}
        />

        <Controller
          control={control}
          name='listing_status'
          render={({ field, fieldState: { error } }) => {
            return (
              <FormControl isRequired isInvalid={error !== undefined}>
                <FormLabel>Listing Status</FormLabel>
                <RadioGroupInput
                  orientation='horizontal'
                  options={LISTING_STATUSES}
                  {...field}
                />
              </FormControl>
            );
          }}
        />

        <Box flexDirection={'row'} gap={'20px'}>
          <Controller
            control={control}
            name='listing_min_price'
            render={({field, fieldState: {error}}) => {
              return (
                <FormControl isInvalid={error !== undefined}>
                  <FormLabel>Listing Min Price</FormLabel>
                  <NumberInput
                    {...field}
                    onChange={(e) =>
                      field.onChange(
                        e.target.value ? parseInt(e.target.value) : undefined
                      )
                    }
                    meta={{error: error?.message ?? null}}
                  />
                </FormControl>
              );
            }}
          />

          <Controller
            control={control}
            name='listing_max_price'
            render={({field, fieldState: {error}}) => {
              return (
                <FormControl isInvalid={error !== undefined || maxPriceHasError}>
                  <FormLabel>Listing Max Price</FormLabel>
                  <NumberInput
                    {...field}
                    onChange={(e) =>
                      field.onChange(
                        e.target.value ? parseInt(e.target.value) : undefined
                      )
                    }
                    meta={{error: error?.message ?? maxPriceHasError ? 'max price > min price' : null}}
                  />
                  <FormErrorMessage>
                    Max price must be greater than min price
                  </FormErrorMessage>
                </FormControl>
              );
            }}
          />
        </Box>

        <Controller
          control={control}
          name='limit'
          render={({field, fieldState: {error}}) => {
            return (
              <FormControl isInvalid={error !== undefined}>
                <FormLabel>Listing Limit</FormLabel>
                <NumberInput
                  {...field}
                  onChange={(e) =>
                    field.onChange(
                      e.target.value ? parseInt(e.target.value) : undefined
                    )
                  }
                  meta={{error: error?.message ?? null}}
                />
                <FormErrorMessage>
                  Limit must be greater than or equal to 1
                </FormErrorMessage>
              </FormControl>
            );
          }}
        />

        <Controller
          control={control}
          name='sort'
          render={({field, fieldState: {error}}) => {
            return (
              <FormControl isInvalid={error !== undefined}>
                <FormLabel data-testid={'sort-listings-label'}>Sort Listings by</FormLabel>
                <Select
                  items={LISTING_SORT_OPTIONS}
                  onChange={(e) => {
                    field.onChange([e.target.value]);
                  }}
                  multi={false}
                  searchable={false}
                  normaliser={(item: NormalisedItem) => {
                    return item
                  }}
                />
              </FormControl>
            );
          }}
        />

        <Controller
          control={control}
          name='show_agent_image'
          render={({field}) => {
            return (
              <FormControl>
                <FormLabel>Ad Branding</FormLabel>
                <Box flexDirection={'row'} alignItems={'center'} spacing={'8px'}>
                  <Checkbox {...field} inputRef={field.ref}/>
                  <div>{'Include listing agent(s) details on ads.'}</div>
                </Box>
              </FormControl>
            );
          }}
        />

        <Controller
          control={control}
          name='agents'
          render={({field, fieldState: {error}}) => (
            <FormControl style={{marginTop: 0}} isInvalid={error !== undefined}>
              <FormLabel>Listing Agent(s)</FormLabel>
              <Box {...s('agentContainer')}>
                <>
                  <Body>{'Only include listings from these agents:'}</Body>
                  <Box {...s('separator')} />
                </>
                <FormInput Input={MultiAgentSelectorInput} {...field} />
              </Box>
            </FormControl>
          )}
        />
      </div>

      <ButtonGroup {...s('footer')} spacing='l'>
        <Spacer/>
        <GhostButton onClick={onCancel}>Cancel</GhostButton>
        <PrimaryButton
          isLoading={isSubmitting}
          isDisabled={!isValid || maxPriceHasError}
          type='submit'
        >
          {submitLabel}
        </PrimaryButton>
      </ButtonGroup>
    </form>
  );
};

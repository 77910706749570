import { Select } from '@rexlabs/select';
import { SingleSelectProps } from '@rexlabs/select/lib/types/core';
import React, { FC, useCallback } from 'react';
import { Consumer } from 'src/types';
import { useStrategies } from '../api/getStrategies';
import { Strategy } from '../types';
import { strategyItemNormaliser } from '../utils/helpers';

export type StrategySelectInputProps = {
  initialValue?: Strategy;
  onChange?: Consumer<Strategy | undefined>;
};

export const StrategySelectInput: FC<StrategySelectInputProps> = ({
  initialValue,
  onChange
}) => {
  const { data: strategies } = useStrategies({
    type: 'current'
  });
  const items = strategies?.pages.flatMap((v) => v.data);
  const transformOnChange = useCallback<
    Required<SingleSelectProps<Strategy>>['onChange']
  >(
    (e) => {
      onChange?.(e.target.value ?? undefined);
    },
    [onChange]
  );

  return (
    <Select
      value={undefined}
      multi={false}
      items={items}
      normaliser={strategyItemNormaliser}
      initialSelectedItem={initialValue}
      onChange={transformOnChange}
    />
  );
};

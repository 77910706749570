import {useInfiniteQuery} from 'react-query';
import {useAccountId} from 'src/features/accounts';
import {InfiniteQueryConfig, InfiniteQueryResult} from 'src/lib/react-query';
import {PaginatedResponse, Pagination, PaginationParams} from 'src/types';
import {api} from 'src/utils/api-client';
import {CatalogListingSetListing} from "src/features/catalog-listing-sets/types";
import {catalogListingSetKeys} from "src/features/catalog-listing-sets/api/keys";

export type GetCatalogListingSetListingsParams = PaginationParams & {
  listingSetId: string;
  accountId: string;
  q?: string;
};

export const getCatalogListingSetListings = async ({
  accountId,
  listingSetId,
  q,
  ...params
}: GetCatalogListingSetListingsParams): Promise<PaginatedResponse<CatalogListingSetListing>> => {
  const {data, pagination} = await api.get(`/listing-sets/${listingSetId}/listings`, {
    include: 'thumbnails,address',
    ...params,
  });
  return {
    data,
    pagination: pagination as Pagination
  };
};

type QueryFnType = typeof getCatalogListingSetListings;

export type UseCatalogListingSetListingOptions = Omit<
  GetCatalogListingSetListingsParams,
  'accountId' | 'page'
> & {
  config?: InfiniteQueryConfig<QueryFnType>;
};

export const useCatalogListingSetListings = ({
  listingSetId,
  config,
  ...params
}: UseCatalogListingSetListingOptions): InfiniteQueryResult<CatalogListingSetListing> => {
  const accountId = useAccountId();

  return useInfiniteQuery<
    PaginatedResponse<CatalogListingSetListing>,
    Error,
    PaginatedResponse<CatalogListingSetListing>
  >({
    queryKey: catalogListingSetKeys.listings({accountId, listingSetId, ...params}),
    queryFn: ({pageParam}) =>
      getCatalogListingSetListings({...params, listingSetId, accountId, page: pageParam}),
    getNextPageParam: ({pagination}) =>
      pagination.current_page === pagination.total_pages
        ? undefined
        : pagination.current_page + 1,
    ...config
  });
};

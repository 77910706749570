import { partial } from 'lodash';
import { createContext, useContext } from 'react';
import { ContentTypeSpec } from 'src/features/campaign-definitions';
import { Consumer } from 'src/types';
import { StoreApi, createStore, useStore } from 'zustand';
import shallow from 'zustand/shallow';

export type ContentTypesState = {
  selectedContent: Record<string, string[]>;
  specs: ContentTypeSpec[];
  setContent: (type: string, ids: string[]) => void;
  selectContent: (type: string, id: string) => void;
};

export type ContentTypesValidSelector = (state: ContentTypesState) => boolean;

export const contentTypesStoreCreator = (
  types: ContentTypeSpec[],
  initialSelections?: Record<string, string[]>
): StoreApi<ContentTypesState> =>
  createStore<ContentTypesState>((set, get) => ({
    specs: types,
    selectedContent: types.reduce(
      (acc, { type }) => ({
        ...acc,
        [type]: initialSelections?.[type] ?? []
      }),
      {}
    ),
    setContent: (type, ids) => {
      set(({ selectedContent }) => ({
        selectedContent: {
          ...selectedContent,
          [type]: ids
        }
      }));
    },
    selectContent: (type, id) => {
      const selected = get().selectedContent[type];
      if (!selected.includes(id)) {
        set(({ selectedContent }) => ({
          selectedContent: {
            ...selectedContent,
            [type]: [...selectedContent[type], id]
          }
        }));
      }
    }
  }));

export const contentTypesValidSelector =
  (types: ContentTypeSpec[]) =>
  (state: ContentTypesState): boolean =>
    types.every(({ type, max, min }) => {
      const num = state.selectedContent[type].length;
      return num >= min && num <= max;
    });

export const ContentTypesStoreContext = createContext<
  StoreApi<ContentTypesState> | undefined
>(undefined);

export const ContentTypesStoreProvider = ContentTypesStoreContext.Provider;

export const useContentTypeSelection = (
  type: string
): [string[], (ids: string[]) => void] => {
  const store = useContext(ContentTypesStoreContext);
  if (store === undefined)
    throw new Error(
      'useContentTypesSelection must be called when mounted under the ContentTypesStoreProvider component.'
    );
  return useStore(store, ({ selectedContent, setContent }) => [
    selectedContent[type],
    (ids) => setContent(type, ids)
  ]);
};

export type UseContentTypeSelectionsReturn = {
  isValid: boolean;
  contentSources: Record<string, string[]>;
};

export const useContentTypeSelections = (): UseContentTypeSelectionsReturn => {
  const store = useContext(ContentTypesStoreContext);
  if (store === undefined)
    throw new Error(
      'useContentTypeSelections must be called when mounted under the ContentTypesStoreProvider component.'
    );
  return useStore(
    store,
    (state) => ({
      contentSources: state.selectedContent,
      isValid: contentTypesValidSelector(state.specs)(state)
    }),
    shallow
  );
};

export const useContentTypeSelect = (type: string): Consumer<string> => {
  const store = useContext(ContentTypesStoreContext);
  if (store === undefined)
    throw new Error(
      'useContentTypeSelect must be called when mounted under the ContentTypesStoreProvider component.'
    );

  return useStore(store, (state) => partial(state.selectContent, type));
};

import React, { FC } from 'react';
import { AdPreviewProps } from '../../types';

/*
 Note: the wide google responsive ad gets scaled down to ensure it fits in the preview editor,
 scaling the iframe does not actually affect the size of the container rendered in the DOM thus
 below we are setting the maxWidth to match the editor preview panes maxWidth to ensure the
 iframe does not overflow the container since the origin for the template is transformed to '0 0'
 which would be outside the frame if the container remained at its 970px width
 */
export const GoogleBannerAd: FC<AdPreviewProps> = ({ children }) => {
  return (
    <div
      style={{
        maxWidth: '50rem'
      }}
    >
      {children}
    </div>
  );
};

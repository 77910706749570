import React from 'react';
import { forwardRef } from 'src/lib/polymorphic-factory';
import StrategyCreditPool from '../assets/strategy-credit-pool.svg';
import { PaymentMethodCard, PaymentMethodCardProps } from './PaymentMethodCard';

export type StrategyCreditPoolPaymentMethodProps = Omit<
  PaymentMethodCardProps,
  'logo' | 'heading' | 'subtitle'
>;

export const StrategyCreditPoolPaymentMethod = forwardRef<
  'div',
  StrategyCreditPoolPaymentMethodProps
>((props, ref) => {
  return (
    <PaymentMethodCard
      ref={ref}
      logo={<StrategyCreditPool />}
      heading='Campaign Strategy'
      subtitle='Credit pool'
      {...props}
    />
  );
});

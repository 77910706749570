import React, { Children, FC, isValidElement, ReactNode } from 'react';
import {
  Root,
  Portal,
  Overlay,
  Trigger,
  DialogProps,
  DialogPortalProps
} from '@radix-ui/react-dialog';
import { StyleSheet, useStyles } from '@rexlabs/styling';
import { ModalStateProvider, useControllableState } from 'src/hooks';

const styles = StyleSheet({
  overlay: {
    background: ({ token }) => token('color.overlay.default'),
    position: 'fixed',
    zIndex: 99,
    top: 0,
    right: 0,
    bottom: 0,
    left: 0
  }
});

export type ModalProps = DialogProps &
  DialogPortalProps & {
    children?: ReactNode | ReactNode[];
  };

export const Modal: FC<ModalProps> = ({
  children,
  container,
  forceMount,
  open: openValue,
  defaultOpen,
  onOpenChange,
  ...rest
}) => {
  const s = useStyles(styles);

  const state = useControllableState({
    value: openValue,
    onChange: onOpenChange,
    defaultValue: defaultOpen
  });
  const [open, setOpen] = state;

  const content = Children.toArray(children);
  const triggerIndex = content.findIndex(
    (e) => isValidElement(e) && e.type === Trigger
  );

  const trigger =
    triggerIndex !== -1 ? content.splice(triggerIndex, 1)[0] : null;

  return (
    <Root open={open} onOpenChange={(v) => setOpen(v)} {...rest}>
      {trigger}
      <Portal container={container} forceMount={forceMount}>
        <Overlay {...s('overlay')} />
        <ModalStateProvider value={state}>{content}</ModalStateProvider>
      </Portal>
    </Root>
  );
};

import { Box } from '@rexlabs/box';
import { styled, StyleSheet } from '@rexlabs/styling';
import { Heading } from '@rexlabs/text';
import React, { PureComponent } from 'react';
import { BREAKPOINTS } from 'src/theme';

const defaultStyles = StyleSheet({
  container: {
    display: 'flex',
    flexDirection: 'column',

    [BREAKPOINTS.TABLET_PORTRAIT_UP]: {
      marginBottom: ({ token }) => token('spacing.m'),
      flexDirection: 'row',
      alignItems: 'center',
      justifyContent: 'space-between'
    }
  }
});

const subHeadingStyles = StyleSheet({
  subHeading: {
    color: ({ token }) => token('legacy.color.blue.grey'),

    [BREAKPOINTS.TABLET_PORTRAIT_UP]: {
      marginTop: 0,
      marginBottom: 0,
      maxWidth: '35rem'
    }
  }
});

@styled(defaultStyles)
class StatsHeading extends PureComponent {
  render() {
    const { title, Filters, styles: s, ...rest } = this.props;
    return (
      <Box {...s('container')}>
        <Heading level={2} {...rest} styles={subHeadingStyles}>
          {title}
        </Heading>
        {!!Filters && <Filters />}
      </Box>
    );
  }
}

export default StatsHeading;

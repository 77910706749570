import { Box } from '@rexlabs/box';
import { withWhereaboutsFilter } from '@rexlabs/filters';
import { withModel } from '@rexlabs/model-generator';
import { withWhereabouts } from '@rexlabs/react-whereabouts';
import { Heading } from '@rexlabs/text';
import React, { Fragment, useState } from 'react';
import { compose } from 'redux';
import listingFilters from 'src/data/filters/listings';
import wizardModel from 'src/data/models/custom/wizard';
import WIZARD from 'src/routes/wizard';
import { Link } from 'src/utils/whereabouts';
import { Button, Filters, SearchInput } from 'src/view/components/filter';
import { List, ListingListItem } from 'src/view/components/list';
import withError from 'src/view/containers/with-error';
import { useDebounce } from 'src/view/hooks';

import { StyleSheet, useStyles } from '@rexlabs/styling';
import IconPlus from 'src/assets/icons/plus.svg';

const defaultStyles = StyleSheet({
  filters: {
    columnGap: ({ token }) => token('spacing.xs'),
    alignItems: 'center'
  }
});

function SearchListings({
  filters,
  listings,
  updatingListing,
  loading,
  whereabouts,
  error: { Error },
  wizard: { selectedEntities, setSelectedListings },
  multiSelectLimit = 1
}) {
  const s = useStyles(defaultStyles);
  const [search, setSearch] = useState(
    (whereabouts?.query ?? {})['listings.search'] || ''
  );

  const getListingId = (listing) => listing.id;

  const handleSearchChange = ({ target: { value } }) => {
    setSearch(value);
  };

  useDebounce(search, (search) => filters.setFilters({ search }), 400);

  const renderListingItem = ({ data: listing }) => {
    const isSelected = selectedEntities.listings.find(
      (l) => l.id === listing.id
    );
    return (
      <ListingListItem
        selected={isSelected}
        onClick={() => {
          if (loading) return;

          if (multiSelectLimit === 1) {
            setSelectedListings(isSelected ? [] : [listing]);
            return;
          }

          if (isSelected) {
            setSelectedListings(
              selectedEntities.listings.filter((l) => l.id !== listing.id)
            );
          } else if (selectedEntities.listings.length < multiSelectLimit) {
            setSelectedListings(selectedEntities.listings.concat(listing));
          }
        }}
        disabled={
          !isSelected &&
          multiSelectLimit > 1 &&
          selectedEntities.listings.length === multiSelectLimit
        }
        data={listing}
      />
    );
  };

  /**
   * NOTE: when updating a listing because it has no address it will trigger a refresh
   * in this case I don't want to show the loading spinners for the listings list
   * as it makes it feel clunky and is not good UX
   */
  const isListingsLoading =
    listings.list.status === 'loading' ||
    (!updatingListing && !loading && listings.list.status === 'refreshing');

  return (
    <Fragment>
      <Box style={{ width: '100%' }}>
        <Heading level={2}>Listings</Heading>
        <Filters {...s('filters')}>
          <SearchInput
            name='searchListing'
            placeholder='Search by list ad title or address'
            onChange={handleSearchChange}
            value={search}
            isLoading={listings.list.status === 'loading'}
          />
          <Link to={WIZARD.CAMPAIGN.CREATE_LISTING}>
            {(props) => (
              <Button IconLeft={IconPlus} {...props}>
                Add new listing
              </Button>
            )}
          </Link>
        </Filters>
      </Box>

      <List
        items={listings?.list?.items ?? []}
        emptyStateProps={
          filters?.filterValues?.search
            ? {
                largeText: 'No listings found!',
                smallText: 'Try making your search more generic...'
              }
            : {
                largeText: 'You don’t have any listings yet',
                smallText: (
                  <div>
                    To get started,{' '}
                    <Link to={WIZARD.CAMPAIGN.CREATE_LISTING}>
                      add a new listing.
                    </Link>
                  </div>
                )
              }
        }
        isLoading={isListingsLoading}
        getItemKey={getListingId}
        Item={renderListingItem}
        endReached={listings?.list?.pagination?.endReached}
        isFetching={listings.list.status === 'fetching'}
        onLoadMore={() => listings.fetchMore()}
        autoLoadMore={false}
      />

      <Error />
    </Fragment>
  );
}

export default compose(
  withError(),
  withWhereabouts(),
  withWhereaboutsFilter(listingFilters),
  withModel(wizardModel)
)(SearchListings);

import React from 'react';
import { StyleSheet, keyframes, useStyles } from '@rexlabs/styling';

const dropFillOffset = keyframes({
  '0%': {
    strokeDashoffset: 708,
    animationTimingFunction: 'cubic-bezier(0.42, 0, 1, 0.82)'
  },
  '21.95122%': {
    strokeDashoffset: 650,
    animationTimingFunction: 'cubic-bezier(0.615, -0.005, 1, 1)'
  },
  '29.268293%': {
    strokeDashoffset: 570
  },
  '31.707317%': {
    strokeDashoffset: 500,
    animationTimingFunction: 'cubic-bezier(0, 0, 0.58, 1)'
  },
  '34.146341%': {
    strokeDashoffset: 550
  },
  '36.585366%': {
    strokeDashoffset: 550
  },
  '41.463415%': {
    strokeDashoffset: 530
  },
  '60.97561%': {
    strokeDashoffset: 530
  },
  '70.731707%': {
    strokeDashoffset: 530,
    animationTimingFunction: 'cubic-bezier(0.42, 0, 1, 1)'
  },
  '100%': {
    strokeDashoffset: 178
  }
});

const dropFillArray = keyframes({
  '0%': {
    strokeDasharray: '0, 530.62'
  },
  '9.756098%': {
    strokeDasharray: '3, 527.62'
  },
  '21.95122%': {
    strokeDasharray: '1, 529.62',
    animationTimingFunction: 'cubic-bezier(0.42, 0, 1, 1)'
  },
  '29.268293%': {
    strokeDasharray: '24, 506.62'
  },
  '31.707317%': {
    strokeDasharray: '0, 530.62'
  },
  '34.146341%': {
    strokeDasharray: '10, 520.62'
  },
  '36.585366%': {
    strokeDasharray: '0, 530.62'
  },
  '39.02439%': {
    strokeDasharray: '2, 528.62'
  },
  '41.463415%': {
    strokeDasharray: '0, 530.62'
  },
  '60.97561%': {
    strokeDasharray: '485, 45.62'
  },
  '70.731707%': {
    strokeDasharray: '485, 45.62',
    animationTimingFunction: 'cubic-bezier(0.42, 0, 1, 1)'
  },
  '100%': {
    strokeDasharray: '0, 530.62'
  }
});

const defaultStyles = StyleSheet({
  spinner: {
    alignSelf: 'center'
  },
  logo: {},
  logoPath: {
    animationName: `${dropFillOffset}, ${dropFillArray}`,
    animationDuration: '4.1s',
    animationFillMode: 'forwards',
    animationTimingFunction: 'linear',
    animationDirection: 'normal',
    animationIterationCount: 'infinite'
  }
});

export interface BubbleSpinnerProps {
  size?: string | number;
  strokeWidth?: number;
}

export function BubbleSpinner({
  size = '150',
  strokeWidth = 26
}: BubbleSpinnerProps) {
  const s = useStyles(defaultStyles);

  return (
    <div {...s('spinner')}>
      <svg
        {...s('logo')}
        height={size}
        viewBox='0 0 183.03 190.78'
        shapeRendering='geometricPrecision'
        textRendering='geometricPrecision'
      >
        <path
          {...s('logoPath')}
          d='M21.27,149.644807L21.27,177.6C21.321806,179.202687,22.202703,180.663316,23.59608,181.456909C24.989457,182.250501,26.695112,182.263033,28.1,181.49C36.436779,177.086015,45.320937,173.807417,54.52,171.74C70.69,168.19,99.78,168.19,99.78,168.19C143.15,168.19,178.3,134.41,178.3,92.73C178.3,52.59,145.7,19.78,104.57,17.4C59.28,14.79,21.27,50,21.27,93.55C21.27,93.55,21.27,98.230955,21.27,104.804342C21.27,117.299642,21.27,137.297396,21.27,149.644807L21.27,149.644807Z'
          transform='matrix(1 0 0 1 -8.27 -4.26)'
          fill='none'
          fillRule='evenodd'
          stroke='rgb(41,191,201)'
          strokeWidth={strokeWidth}
          strokeLinecap='round'
          strokeLinejoin='round'
          strokeDashoffset='708'
          strokeDasharray='0,530.62'
        />
      </svg>
    </div>
  );
}

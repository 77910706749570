import { Box } from '@rexlabs/box';
import { GhostButton, PrimaryButton } from '@rexlabs/button';
import { withModel } from '@rexlabs/model-generator/lib/connectors';
import { StyleSheet } from '@rexlabs/styling';
import { debounce, isEqual } from 'lodash';
import React, { PureComponent } from 'react';
import session from 'src/data/models/custom/session';
import { withToken } from 'src/theme';
import { api } from 'src/utils/api-client';
import { ModalStickyButtonGroup } from 'src/view/components/button';
import { Form, FormField, withForm } from 'src/view/components/form';
import { GridCell, GridRow } from 'src/view/components/grid';
import { Checkbox } from 'src/view/components/input';
import { Modal } from 'src/view/components/modal';
import { currencyCodes } from 'src/view/components/money';
import { PlanItem } from 'src/view/components/plan';
import withError from 'src/view/containers/with-error';
import withToast from 'src/view/containers/with-toast';
import EditBudgetForm, {
  handleSubmit,
  mapPropsToValues
} from 'src/view/forms/edit-budget';

const checkboxInputStyles = StyleSheet({
  inlineFieldLabel: {
    fontSize: '1.8rem !important'
  }
});

const checkboxStyles = StyleSheet({
  container: {
    '& label:first-of-type': {
      border: ({ token }) => `0.2rem solid ${token('legacy.color.blue.grey')}`
    }
  },

  input: {
    '&:checked + label': {
      border: ({ token }) =>
        `0.2rem solid ${token('legacy.color.secondary.default')}`
    }
  }
});

async function handleFormSubmit(values, { props, ...rest }) {
  return handleSubmit(values, {
    props,
    ...rest
  }).then(() => props.refreshPackages());
}

const editBudgetForm = {
  name: 'editBudget',
  mapPropsToValues,
  handleSubmit: handleFormSubmit
};

@withToken
@withToast
@withError('errorModal')
@withForm(editBudgetForm)
@withModel(session)
class EditBudgetModal extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      estimate: this.props.estimate
    };
    this.getEstimate = debounce(this.getEstimate, 500);
  }

  getEstimate(values) {
    const { campaigns } = this.props;
    this.setState({ estimate: { status: 'loading' } });

    api
      .get(
        `/campaigns/${campaigns.item.data.id}/quotes/custom?budget_facebook=${
          values.facebook
        }&budget_google=${values.google}&duration=${values.duration || 7}`
      )
      .then(({ data }) => {
        this.setState({ estimate: data.estimate });
      })
      .catch(() => {
        this.setState({ estimate: { reach: { min: 0 } } });
      });
  }

  componentDidMount() {
    if (!this.props.estimate) {
      this.getEstimate(this.props.editBudget.values);
    }
  }

  componentDidUpdate(prevProps) {
    // eslint-disable-next-line camelcase
    const removeIsDefault = ({ is_default, ...rest }) => rest;
    const editBudgetValues = removeIsDefault(this.props.editBudget.values);
    const prevBudgetValues = removeIsDefault(
      prevProps?.editBudget?.values ?? {}
    );

    if (!isEqual(editBudgetValues, prevBudgetValues)) {
      this.getEstimate(editBudgetValues);
    }
  }

  async submitForm() {
    const { editBudget, toasts, closeModal, errorModal } = this.props;
    try {
      await editBudget.submitForm();
      closeModal();
      toasts.addToast({ title: 'Custom budget has been successfully saved' });
    } catch (e) {
      errorModal.open(e);
    }
  }

  render() {
    const {
      closeModal,
      editBudget,
      campaigns,
      session,
      errorModal: { Error },
      token
    } = this.props;

    const networks = (campaigns?.item?.data?.audience_spec?.networks ?? []).map(
      (n) => n.id
    );
    const fbNetworkEnabled = networks.some(
      (n) => n === 'facebook' || n === 'instagram'
    );
    const gNetworkEnabled = networks.includes('adwords');
    const type = campaigns?.item?.data?.type?.id;
    const agencyCountryCode = session.accounts.find(
      (a) => a.id === session.currentAccountId
    )?.agencies?.data?.[0]?.address?.country?.id;

    const newPackage = {
      name: 'Custom',
      price: {
        value:
          ((fbNetworkEnabled && parseInt(editBudget.values.facebook || 0)) ||
            0) +
          ((gNetworkEnabled && parseInt(editBudget.values.google || 0)) || 0),
        currency_code: currencyCodes[agencyCountryCode]
      },
      budget: {
        facebook: editBudget.values.facebook,
        google: editBudget.values.google
      },
      duration: {
        value: editBudget.values.duration,
        unit: 'day'
      },
      type: {
        id: ['agent_profile', 'appraisal'].includes(type)
          ? 'recurring'
          : 'standard'
      }
    };

    return (
      <Box>
        <Form name='editBudget'>
          <GridRow
            gutter={token('spacing.xl')}
            mt={token('spacing.xl')}
            mb={token('spacing.xl')}
          >
            <GridCell gutter={token('spacing.xl')} width={2 / 3}>
              <EditBudgetForm
                values={editBudget.values}
                errors={editBudget.errors}
                networks={networks}
                fbNetworkEnabled={fbNetworkEnabled}
                gNetworkEnabled={gNetworkEnabled}
                durationEnabled={[
                  'single_listing',
                  'multi_listing_sold'
                ].includes(type)}
                closeModal={closeModal}
              />
            </GridCell>
            <GridCell gutter={token('spacing.xl')} width={1 / 3}>
              <PlanItem
                plan={newPackage}
                type={type}
                estimate={this.state.estimate}
                selectable={false}
              />
            </GridCell>
          </GridRow>

          <ModalStickyButtonGroup>
            <Box flex={1}>
              <FormField
                name='is_default'
                isInlineLabel
                Input={Checkbox}
                inputProps={{
                  label: 'Use as default for future campaigns',
                  grey: true,
                  styles: checkboxStyles
                }}
                styles={checkboxInputStyles}
                sendImmediate
              />
            </Box>
            <GhostButton onClick={closeModal}>Cancel</GhostButton>
            <PrimaryButton
              onClick={this.submitForm.bind(this)}
              isLoading={editBudget.isSubmitting}
            >
              Save
            </PrimaryButton>
          </ModalStickyButtonGroup>

          <Error />
        </Form>
      </Box>
    );
  }
}

class Core extends PureComponent {
  render() {
    const { subTitle, closeModal } = this.props;

    return (
      <Modal
        title='Create Custom Package'
        subtitle={subTitle}
        onClose={closeModal}
        width='85rem'
      >
        <EditBudgetModal {...this.props} />
      </Modal>
    );
  }
}

export default Core;

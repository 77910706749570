import * as DialogPrimitive from '@radix-ui/react-dialog';
import { margin, StyleSheet, useStyles } from '@rexlabs/styling';
import React, { forwardRef, Ref } from 'react';

const styles = StyleSheet({
  title: {
    display: 'flex',
    flex: 1,
    flexDirection: 'column',
    '& > * + *': {
      ...margin.styles({
        top: 'xs'
      })
    }
  }
});

export const ModalTitle = forwardRef(
  (
    { className, style, ...props }: DialogPrimitive.DialogTitleProps,
    ref: Ref<HTMLHeadingElement>
  ) => {
    const s = useStyles(styles);
    return (
      <DialogPrimitive.Title
        {...s.with('title')({ className, style })}
        ref={ref}
        {...props}
      />
    );
  }
);

import { Box } from '@rexlabs/box';
import { PrimaryButton, SecondaryButton } from '@rexlabs/button';
import { query, withQuery, withValueLists } from '@rexlabs/model-generator';
import { StyleSheet, useStyles } from '@rexlabs/styling';
import { Heading } from '@rexlabs/text';
import { match } from '@rexlabs/whereabouts';
import React, { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import { compose } from 'redux';
import Envelopes from 'src/assets/envelopes.svg';
import notificationSubscriptionsModel from 'src/data/models/entities/notification-subscriptions';
import feedbackTypesModel from 'src/data/models/value-lists/feedback-types';
import AUTH from 'src/routes/auth';
import ROUTES from 'src/routes/notifications';
import { BREAKPOINTS, formattedBrandName, TEXTS } from 'src/theme';
import { Link } from 'src/utils/whereabouts';
import { Form, FormField, ReactForms } from 'src/view/components/form';
import { Checkbox, RadioGroup, TextArea } from 'src/view/components/input';
import { RenderLoading } from 'src/view/components/loading';
import { Body } from 'src/view/components/text';
import withError from 'src/view/containers/with-error';
import { NotFoundScreenCore } from 'src/view/screens/not-found';

function getToken({ whereabouts }) {
  const matched = match(whereabouts, ROUTES.UNSUBSCRIBE.config);
  return matched?.params?.token;
}

const notificationsQuery = query`{
  ${notificationSubscriptionsModel} (token: ${getToken}) {
    id
    label
    allowed
  }
}`;

const defaultStyles = StyleSheet({
  loadingWrapper: {
    position: 'absolute'
  },

  wrapper: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',

    [BREAKPOINTS.TABLET_LANDSCAPE_UP]: {
      flexDirection: 'row'
    }
  },

  detailsWrapper: {
    display: 'flex',
    flexDirection: 'column',
    flex: 1,
    alignItems: 'center',

    [BREAKPOINTS.TABLET_LANDSCAPE_UP]: {
      alignItems: 'flex-start',
      paddingLeft: '3.8rem'
    }
  },

  maxWidth: {
    maxWidth: '53.2rem'
  },

  imageWrapper: {
    display: 'flex',
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
    marginBottom: '3.2rem',
    maxHeight: '72.6rem',

    [BREAKPOINTS.TABLET_LANDSCAPE_UP]: {
      justifyContent: 'flex-end',
      paddingRight: '3.8rem'
    }
  },

  heading: {
    ...TEXTS.HEADING,
    fontSize: '4.2rem'
  },

  envelopes: {
    position: 'static',
    width: '100%',
    maxWidth: '49rem'
  },

  radioGroupLabel: {
    ...TEXTS.BODY,
    color: ({ token }) => token('legacy.color.blue.grey'),
    paddingBottom: 0
  },

  radioLabel: {
    color: ({ token }) => token('legacy.color.blue.grey'),
    margin: '.8rem 0'
  },

  textAreaWrapper: {
    paddingLeft: '3.6rem'
  },

  feedbackSubmitted: {
    ...TEXTS.SUBHEADING,
    color: ({ token }) => token('legacy.color.primary.default')
  },

  linkToApp: {
    width: '100%',
    maxWidth: '53.2rem'
  }
});

function UnsubscribeScreen({
  notificationSubscriptions,
  valueLists: { feedbackTypes },
  whereabouts,
  error,
  error: { Error }
}) {
  const s = useStyles(defaultStyles);
  const [unsubscribed, setUnsubscribed] = useState(false);
  const [feedbackSubmitted, setfeedbackSubmitted] = useState(false);
  const type = new URLSearchParams(window.location.search).get('type');
  const notification = notificationSubscriptions.list.items.find(
    (n) => n.id === type
  );
  const isLoading = !unsubscribed && feedbackTypes.status !== 'loaded';

  const handleSubmit = async ({ feedback_type, feedback_reason }) => {
    try {
      await notificationSubscriptions.updateItem({
        id: type,
        data: {
          token: getToken({ whereabouts }),
          notification_subscriptions: {
            [type]: {
              feedback_type,
              feedback_reason:
                feedback_type.toLowerCase() === 'other'
                  ? feedback_reason
                  : feedbackTypes.items.find((ft) => ft.id === feedback_type)
                      ?.label,
              allowed: false
            }
          }
        }
      });

      setfeedbackSubmitted(true);
    } catch (e) {
      error.open(e);
    }
  };

  useEffect(() => {
    // When the page loads we want to immediately unsubscribe the user
    if (!!type && notificationSubscriptions.list.status === 'loaded') {
      notificationSubscriptions
        .updateItem({
          id: type,
          data: {
            token: getToken({ whereabouts }),
            notification_subscriptions: {
              [type]: {
                allowed: false,
                feedback_type: null,
                feedback_reason: null
              }
            }
          }
        })
        .then(() => setUnsubscribed(true))
        .catch(error.open);
    }
  }, [
    error.open,
    notificationSubscriptions,
    notificationSubscriptions.list.status,
    type,
    whereabouts
  ]);

  if (!type) {
    return <NotFoundScreenCore />;
  }

  return (
    <>
      <Helmet>
        <title>{formattedBrandName} - Unsubscribe</title>
      </Helmet>

      {isLoading ? (
        <Box
          h='100%'
          w='100%'
          justifyContent='center'
          alignItems='center'
          {...s('loadingWrapper')}
        >
          <RenderLoading isLoading={isLoading} />
        </Box>
      ) : (
        <Box Container='section' {...s('wrapper')}>
          <Box {...s('imageWrapper')}>
            <Envelopes {...s('envelopes')} />
          </Box>

          <Box {...s('detailsWrapper')}>
            <Box sy='2.4rem' {...s('maxWidth')}>
              <Heading level={1} {...s('heading')}>
                Sorry to see you go!
              </Heading>
              <Body grey>
                You will no longer receive &quot;
                {notification?.label?.toLowerCase()}
                &quot; updates. This won’t affect lead notifications and system
                emails (such as password reset etc.)
              </Body>

              <Box>
                <Body grey {...s('radioGroupLabel')}>
                  If you have a moment please let us know why you unsubscribed.
                </Body>

                {feedbackSubmitted ? (
                  <Box flexDirection='row' alignItems='center' mt='2.4rem'>
                    <Box mr='1.6rem'>
                      <Checkbox value={true} round={true} />
                    </Box>
                    <Heading level={2} {...s('feedbackSubmitted')}>
                      Thanks for your feedback!
                    </Heading>
                  </Box>
                ) : (
                  <ReactForms handleSubmit={handleSubmit}>
                    {({ values, setFieldValue, submitForm, isSubmitting }) => {
                      return (
                        <Form name='usubscribeFeedback'>
                          <FormField
                            name='feedback_type'
                            Input={RadioGroup}
                            inputProps={{
                              options: feedbackTypes.items.map(
                                ({ id, label }) => ({
                                  label: (
                                    <div {...s('radioLabel')}>{label}</div>
                                  ),
                                  value: id
                                })
                              )
                            }}
                          />

                          <div
                            {...s.with('textAreaWrapper')({
                              display:
                                values.feedback_type === 'other'
                                  ? 'block'
                                  : 'none'
                            })}
                          >
                            <FormField
                              name='feedback_reason'
                              Input={TextArea}
                              onFocus={() =>
                                setFieldValue('feedback_type', 'other')
                              }
                            />
                          </div>

                          <Box mt='2.4rem'>
                            <PrimaryButton
                              form='usubscribeFeedback'
                              onClick={submitForm}
                              isLoading={isSubmitting}
                              isDisabled={
                                !Object.values(values).filter(Boolean).length
                              }
                            >
                              Submit
                            </PrimaryButton>
                          </Box>
                        </Form>
                      );
                    }}
                  </ReactForms>
                )}
              </Box>

              <Box mt='4.8rem' mb='0'>
                <Body grey>Did you unsubscribe by accident?</Body>
              </Box>

              <Box>
                <Link
                  to={ROUTES.RESUBSCRIBE}
                  params={{ token: getToken({ whereabouts }) }}
                  query={{ type }}
                >
                  {({ onClick }) => (
                    <SecondaryButton onClick={onClick}>
                      Resubscribe
                    </SecondaryButton>
                  )}
                </Link>
              </Box>
            </Box>

            <Box mt='3.2rem' mb='3.2rem' {...s('linkToApp')}>
              <Link to={AUTH.LOGIN}>
                <Body>Take me to the {formattedBrandName} App →</Body>
              </Link>
            </Box>
          </Box>
        </Box>
      )}

      <Error />
    </>
  );
}

export default compose(
  withError(),
  withValueLists(feedbackTypesModel),
  withQuery(notificationsQuery)
)(UnsubscribeScreen);

import { StyleSheet, useStyles } from '@rexlabs/styling';
import React from 'react';
import {
  HTMLPolymorphicProps,
  forwardRef,
  poly
} from 'src/lib/polymorphic-factory';

const styles = StyleSheet({
  container: {
    color: ({ token }) => token('color.textStyle.body.subtext'),
    '> svg': {
      display: 'block'
    }
  },

  isCurrentPage: {
    color: ({ token }) => token('color.textStyle.body.default')
  }
});

export type BreadcrumbSeparatorProps = HTMLPolymorphicProps<'span'> & {
  spacing?: string;
  isCurrentPage?: boolean;
};

/**
 * React component that separates each breadcrumb link
 */
export const BreadcrumbSeparator = forwardRef<'span', BreadcrumbSeparatorProps>(
  ({ spacing, isCurrentPage, className, style, ...rest }, ref) => {
    const s = useStyles(styles, 'BreadcrumbSeparator');
    return (
      <poly.span
        ref={ref}
        role='presentation'
        {...s.with('container', {
          isCurrentPage
        })({
          className,
          style: {
            ...style,
            marginLeft: spacing,
            marginRight: spacing
          }
        })}
        {...rest}
      ></poly.span>
    );
  }
);

import React, { ComponentProps, forwardRef } from 'react';
import type { MapRef } from 'react-map-gl';
import { Map as ReactMap } from 'react-map-gl';
import config from 'src/config';
import 'mapbox-gl/dist/mapbox-gl.css';

export type MapProps = Omit<
  ComponentProps<typeof ReactMap>,
  'mapboxAccessToken'
>;

export const Map = forwardRef<MapRef, MapProps>((props, ref) => (
  <ReactMap mapboxAccessToken={config.MAPBOX_TOKEN} ref={ref} {...props} />
));

import { GhostButton } from '@rexlabs/button';
import React, { FC, ReactNode } from 'react';
import { Link, To } from 'react-router-dom';
import { ButtonGroup, Spacer } from 'src/components/elements/button-group';

export type StepPreviewFooterProps = {
  showBack?: boolean;
  backTo: To;
  children?: ReactNode;
};

export const StepPreviewFooter: FC<StepPreviewFooterProps> = ({
  showBack = true,
  backTo,
  children
}) => {
  return (
    <ButtonGroup sticky spacing='s'>
      {showBack ? (
        <Link style={{ textDecoration: 'none' }} to={backTo}>
          <GhostButton>Back</GhostButton>
        </Link>
      ) : null}
      <Spacer />
      {children}
    </ButtonGroup>
  );
};

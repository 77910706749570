import { styled, StyleSheet } from '@rexlabs/styling';
import React, { PureComponent } from 'react';
import { TEXTS } from 'src/theme';

const defaultStyles = StyleSheet({
  body: TEXTS.BODY,
  grey: {
    color: ({ token }) => token('palette.grey.600')
  },
  primary: {
    color: ({ token }) => token('legacy.color.primary.default')
  },
  red: {
    color: ({ token }) => token('legacy.color.red.default')
  }
});

@styled(defaultStyles)
class Body extends PureComponent {
  static defaultProps = {
    Tag: 'p'
  };

  render() {
    const {
      Tag,
      styles: s,
      children,
      grey,
      red,
      primary,
      ...rest
    } = this.props;
    return (
      <Tag {...s.with('body', { grey, red, primary })(this.props)} {...rest}>
        {children}
      </Tag>
    );
  }
}

export default Body;

import React, { ReactNode } from 'react';
import {
  FormTemplateRenderProps,
  useFormApi
} from '@data-driven-forms/react-form-renderer';
import { GhostButton, PrimaryButton } from '@rexlabs/button';
import { margin, StyleSheet, useStyles } from '@rexlabs/styling';

const styles = StyleSheet({
  form: {
    '& > * + *': {
      ...margin.styles({
        top: 's'
      })
    }
  },

  buttons: {
    '& > * + *': {
      ...margin.styles({
        left: 'xs'
      })
    }
  }
});

export const DefaultFormTemplate = ({
  formFields
}: FormTemplateRenderProps) => {
  const s = useStyles(styles);
  const { handleSubmit, onCancel, getState } = useFormApi();
  const { submitting, valid } = getState();

  return (
    <form
      {...s('form')}
      onSubmit={(e) => {
        e.preventDefault();
        handleSubmit();
      }}
    >
      {formFields as ReactNode[]}

      <div {...s('buttons')}>
        <PrimaryButton type='submit' isDisabled={submitting || !valid}>
          Save
        </PrimaryButton>
        <GhostButton onClick={onCancel}>Cancel</GhostButton>
      </div>
    </form>
  );
};

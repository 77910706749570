import React, { FC } from 'react';
import { StyleSheet, useStyles } from '@rexlabs/styling';

const styles = StyleSheet({
  wrapCarouselDots: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center'
  },

  carouselDot: {
    width: '.6rem',
    height: '.6rem',
    borderRadius: '.6rem',
    background: '#e9eaeb',
    margin: '.2rem',
    transition: 'background .3s'
  },

  carouselDotActive: {
    background: '#3397f0'
  }
});

export type CarouselDotsProps = {
  slideCount: number;
  slidePos: number;
};

export const CarouselDots: FC<CarouselDotsProps> = ({
  slideCount,
  slidePos
}) => {
  const s = useStyles(styles);
  return (
    <div {...s('wrapCarouselDots')}>
      {Array.from({ length: slideCount }, (v, i) => (
        <div
          key={i}
          {...s('carouselDot', {
            carouselDotActive: slidePos === i
          })}
        />
      ))}
    </div>
  );
};

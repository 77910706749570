import { margin, StyleSheet, useStyles } from '@rexlabs/styling';
import React, {
  ForwardedRef,
  forwardRef,
  ReactElement,
  RefAttributes
} from 'react';
import { FileUploadInput, FileUploadInputProps } from '../FileUploadInput';
import { SimpleEmptyInput } from './SimpleEmptyInput';
import { SimpleFileDisplay } from './SimpleFileDisplay';

const styles = StyleSheet({
  container: {
    '& > * + *': {
      ...margin.styles({
        top: 'xs'
      })
    }
  }
});

export type SimpleFileUploadInputProps<T> = Omit<
  FileUploadInputProps<T>,
  'EmptyInput' | 'FileDisplay'
>;

export const SimpleFileUploadInput = forwardRef(
  <T,>(
    { className, style, ...rest }: SimpleFileUploadInputProps<T>,
    ref: ForwardedRef<HTMLInputElement>
  ) => {
    const s = useStyles(styles, 'SimpleFileUploadInput');

    return (
      // @ts-expect-error Typescript doesn't correctly infer union of multiple prop
      <FileUploadInput
        {...s.with('container')({ className, style })}
        ref={ref}
        EmptyInput={SimpleEmptyInput}
        FileDisplay={SimpleFileDisplay}
        {...rest}
      />
    );
  }
) as <T>(
  props: SimpleFileUploadInputProps<T> & RefAttributes<HTMLInputElement>
) => ReactElement | null;

import { Box } from '@rexlabs/box';
import { withWhereabouts } from '@rexlabs/react-whereabouts';
import { StyleSheet, useStyles, useToken } from '@rexlabs/styling';
import { match } from '@rexlabs/whereabouts';
import React from 'react';
import ROUTES from 'src/routes/admin';
import { FormField, HiddenField } from 'src/view/components/form';
import { GridCell, GridRow } from 'src/view/components/grid';
import { ColorPicker, TextInput } from 'src/view/components/input';
import LogoImageInput from 'src/view/components/input/image/logo-image-input';
import AddressSelect from 'src/view/components/input/select/address';
import { TimezoneSelect } from 'src/view/components/input/select/value-list';
import ManualAddress from 'src/view/components/manual-address';
import { useFeature } from 'shared/hooks';
import { useModelState } from '@rexlabs/model-generator';
import sessionModel from 'data/models/custom/session';
import RangeInput from 'view/components/input/range-slider/range-input';
import { ScaledLogo } from 'view/components/logo-preview/ScaledLogo';

const DEFAULT_COUNTRIES = ['au', 'gb', 'nz', 'ie', 'us', 'ca'];

const timezoneStyles = StyleSheet({
  container: {
    maxHeight: '12rem'
  }
});

const logoPreviewStyles = StyleSheet({
  logoPreviewContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    height: '100%',
    width: '100%',
    marginTop: '16px'
  }
});

function LogoImage(props) {
  return (
    <Box style={{ width: '16rem', height: '16rem' }}>
      <LogoImageInput {...props} />
    </Box>
  );
}

function EditAgencyForm({
  status,
  values,
  editTimezone,
  setFieldValue,
  whereabouts,
  showPrivacyPolicy
}) {
  const token = useToken();
  const s = useStyles(logoPreviewStyles);

  const countries = values?.id ? null : DEFAULT_COUNTRIES;
  const isEditAgency = match(whereabouts, ROUTES.SETTINGS_AGENCY.EDIT.config);

  return (
    <Box style={{ width: '100%' }}>
      <GridRow gutter={token('spacing.xl')}>
        <GridCell gutter={token('spacing.xl')} width={3 / 4}>
          <HiddenField name='id' />
          <FormField
            name='business_name'
            label='Office name'
            Input={TextInput}
          />
          <FormField
            name='address'
            label='Office address'
            Input={AddressSelect}
            inputProps={{
              givenCountries: countries
            }}
          />
          <ManualAddress
            disableCountry={isEditAgency}
            onSave={(address) => setFieldValue('address', address)}
          />

          <FormField
            name='office_email'
            label='Office email'
            Input={TextInput}
          />
          <FormField
            name='office_phone_number'
            label='Office phone number'
            Input={TextInput}
          />
          <FormField name='website' label='Office website' Input={TextInput} />

          {showPrivacyPolicy && (
            <FormField
              name='website_privacy_url'
              label='Privacy Policy URL'
              Input={TextInput}
            />
          )}

          {editTimezone && (
            <FormField
              Input={TimezoneSelect}
              name='timezone'
              label='Timezone'
              inputProps={{ menuStyles: timezoneStyles }}
            />
          )}
        </GridCell>

        <GridCell gutter={token('spacing.xl')} width={1 / 4}>
          <FormField
            sendImmediate
            name='brand_colour'
            label='Brand colour'
            Input={ColorPicker}
          />
          <FormField
            sendImmediate
            name='background_colour'
            label='Background colour'
            Input={ColorPicker}
          />
          <FormField
            sendImmediate
            name='logo'
            label='Logo'
            Input={LogoImage}
            inputProps={{
              type: 'logo',
              status,
              backgroundColor:
                values.background_colour || token('legacy.color.grey.light'),
              isSmall: true,
              scale: values.logo_scale,
              canScale: true,
              changeModalSubtitleTooltip:
                'For the best results, upload an image with a resolution of at least 1000 x 1000 pixels. Your logo should be clearly visible and not too small on the image.'
            }}
          />

          <FormField
            sendImmediate
            name='logo_scale'
            label='Logo Scale'
            Input={RangeInput}
            inputProps={{
              min: 0,
              max: 200
            }}
          />

          <Box {...s('logoPreviewContainer')}>
            <Box w={'7rem'} h={'7rem'}>
              <ScaledLogo
                url={values.logo?.sizes?.original?.url ?? ''}
                bgColor={
                  values.background_colour || token('legacy.color.grey.light')
                }
                scale={values.logo_scale}
                displayCircle={true}
              />
            </Box>
            <Box w={'7rem'} h={'7rem'}>
              <ScaledLogo
                url={values.logo?.sizes?.original?.url ?? ''}
                bgColor={
                  values.background_colour || token('legacy.color.grey.light')
                }
                scale={values.logo_scale}
              />
            </Box>
          </Box>
        </GridCell>
      </GridRow>
    </Box>
  );
}

export default withWhereabouts()(EditAgencyForm);

/* eslint-disable max-lines */
import ActionMenu from '@rexlabs/action-menu';
import { track } from '@rexlabs/analytics';
import { GhostCircleButton, OutlineButton } from '@rexlabs/button';
import { withModel } from '@rexlabs/model-generator';
import { ToastContext, useToast } from '@rexlabs/notifications';
import { withWhereabouts } from '@rexlabs/react-whereabouts';
import { match } from '@rexlabs/whereabouts';
import React, { ReactElement, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { compose } from 'redux';
import ActionMenuIcon from 'src/assets/icons/action-menu.svg';
import campaignsModel from 'src/data/models/entities/campaigns';
import ROUTES from 'src/routes/admin';
import WIZARD from 'src/routes/wizard';
import { Consumer, Runnable } from 'src/types';
import { useFeature } from 'src/utils/feature-flags';
import { useModal } from 'src/utils/modal';
import { push } from 'src/utils/whereabouts';
import { STATUS } from 'src/view/components/campaign';
import AdsPreviewModal from 'src/view/modals/admin/campaign/ads-preview';
import ArchiveCampaignModal from 'src/view/modals/admin/campaign/archive';
import ChangePlanModal from 'src/view/modals/admin/campaign/change-plan';
import CopyTokenModal from 'src/view/modals/admin/campaign/copy-token';
import DeleteCampaignModal from 'src/view/modals/admin/campaign/delete';
import PreviewLandingPageModal from 'src/view/modals/admin/campaign/landing-page-preview';
import PauseCampaignModal from 'src/view/modals/admin/campaign/pause';
import ResumeCampaignModal from 'src/view/modals/admin/campaign/resume';
import ShareCampaignModal from 'src/view/modals/admin/campaign/share';
import StopCampaignModal from 'src/view/modals/admin/campaign/stop';
import {CATALOG_CAMPAIGN_NAME} from "src/features/wizard/utils/constants";

interface ModalProps {
  campaignId: string;
  closeModal(): void;
  match: { params: { campaignId: string } };
  whereabouts: any;
}

interface ModalData {
  component?: (props: ModalProps) => JSX.Element;
  route?: {
    config: {
      path: string;
      Component: any;
    };
  };
  routeParams?: Record<string, any>;
}

interface ActionMenuItem {
  label: string;
  onClick(): void;
}

const NO_MODAL: ModalData = {
  component: undefined,
  route: undefined,
  routeParams: {}
};

function CampaignDuplicator(
  setForceLoading,
  toasts: ToastContext,
  campaigns,
  refreshList,
  whereabouts,
  accountIndex,
  navigate
): (campaignId, campaignSlug) => Promise<void> {
  return async (campaignId, campaignSlug) => {
    setForceLoading?.(true);
    toasts.addToast({
      type: 'info',
      description: 'A copy of your campaign is being created as a new draft.'
    });
    try {
      const { data: newCampaign } = await campaigns.duplicate({
        data: { campaign: { id: campaignId } }
      });
      await refreshList?.();

      if (whereabouts.query?.['campaigns.status'] === 'archived') {
        push(ROUTES.CAMPAIGNS);
        /**
         * Needed to use a timeout here otherwise switching tabs caused the
         * list of items to look jittery with the transition effects of
         * the archived items coming in/going out with the current tabs
         * items coming in.
         */
        setTimeout(() => setForceLoading?.(false), 500);
      } else {
        setForceLoading?.(false);
      }

      track({
        event: 'Spoke campaign duplicated',
        properties: {
          action: 'duplicate_campaign_clicked',
          section: 'Campaign list',
          success: true,
          duplicated_from_id: campaignId,
          duplicated_to_id: newCampaign?.id
        }
      });

      toasts.addToast({
        duration: 40000,
        type: 'success',
        description: 'A copy of your campaign has been created as a new draft.',
        actions: [
          {
            label: 'Open copy',
            onClick: () => {
              if (newCampaign?.campaign_definition) {
                navigate(
                  `/${accountIndex}/campaigns/${newCampaign?.id}/edit/d`
                );
              } else {
                push(WIZARD.CAMPAIGN, {
                  params: {
                    campaignType: campaignSlug,
                    campaignId: newCampaign.id
                  }
                });
              }
            },
            Button: (props) => <OutlineButton size='s' {...props} />
          }
        ]
      });
    } catch (e) {
      setForceLoading?.(false);
      toasts.addToast({
        type: 'error',
        title: 'Unable to copy your campaign',
        description: (e as Error).message
      });
    }
  };
}

function CampaignActionMenu({
  campaigns,
  campaign,
  whereabouts,
  refreshList,
  setForceLoading
}): ReactElement | null {
  const [modal, setModal] = useState(NO_MODAL);
  const toasts = useToast();
  const modalController = useModal();
  const canDuplicate = useFeature('campaign_replication');
  const flexCampaignEditable = useFeature('flex_campaign_editor');
  const navigate = useNavigate();

  const {
    // @ts-expect-error incorrect typing on match function - it does return params
    params: { accountIndex }
  } = match(whereabouts, {
    path: '/:accountIndex/:rest(.*)'
  });

  useEffect(() => {
    if (
      match(whereabouts, ROUTES.CAMPAIGN.config) &&
      modal.route &&
      !match(whereabouts, modal.route.config)
    ) {
      push(modal.route, {
        params: { campaignId: campaign.id, ...modal.routeParams }
      });
    }
  });
  const closeModal: Runnable = () =>
    setModal({
      ...NO_MODAL,
      route: ROUTES.CAMPAIGN
    });
  const openModal: Consumer<ModalData> = (modal) => setModal(modal);

  const duplicateCampaign = CampaignDuplicator(
    setForceLoading,
    toasts,
    campaigns,
    refreshList,
    whereabouts,
    accountIndex,
    navigate
  );

  const status = campaign?.status?.id;
  const isFinished = [STATUS.ARCHIVED, STATUS.COMPLETED].includes(status);
  const type = campaign?.type?.id;
  const commercialListing = campaign?.type_label === 'Commercial Listing';
  const isCatalogCampaign = campaign?.type_label === CATALOG_CAMPAIGN_NAME;
  const isListing =
    ['single_listing', 'multi_listing_sold'].includes(type) ||
    commercialListing;
  const campaignSlug = campaign?.campaign_template?.slug;
  // small HACK: using the packages name because the backend doesn't seem to change the active_package.type.id from standard
  const canChangePlan = !(
    (type === 'single_listing' && campaign?.active_package?.name === 'Premium') ||
    campaign?.active_package?.is_custom ||
    isCatalogCampaign
  );
  const isFlexCampaign = campaign.type.id === 'flex';

  const items: ActionMenuItem[] = [];
  if (status === STATUS.DRAFT) {
    items.push(
      {
        label: 'Finish campaign setup',
        onClick: () => {
          if (campaign?.campaign_definition) {
            navigate(`/${accountIndex}/campaigns/${campaign?.id}/edit/d`);
          } else {
            push(WIZARD.CAMPAIGN, {
              params: { campaignType: campaignSlug, campaignId: campaign.id }
            });
          }
        }
      },
      {
        label: 'Delete draft',
        onClick: () =>
          modalController.openModal({
            title: 'Delete campaign draft',
            subtitle: campaign.name,
            contents: (
              <DeleteCampaignModal
                campaignId={campaign.id}
                closeModal={modalController.closeModal}
              />
            )
          })
      }
    );
  } else if (!isFinished) {
    if (!isFlexCampaign) {
      items.push({
        label: 'Edit',
        onClick: () =>
          push(ROUTES.CAMPAIGN_EDIT, {
            params: { campaignId: campaign.id }
          })
      });
    }

    if (isFlexCampaign && flexCampaignEditable) {
      items.push({
        label: 'Edit',
        onClick: () =>
          navigate(`/${accountIndex}/campaigns/${campaign?.id}/edit/d`)
      });
    }

    if (canChangePlan) {
      const title = isListing ? 'Upgrade Package' : 'Change plan';
      items.push({
        label: title,
        onClick: () =>
          modalController.openModal({
            title,
            subtitle: campaign.name,
            contents: (
              <ChangePlanModal
                closeModal={modalController.closeModal}
                campaignId={campaign.id}
              />
            )
          })
      });
    }
  }

  if (canDuplicate) {
    items.push({
      label: 'Make a copy',
      onClick: () => duplicateCampaign(campaign.id, campaignSlug)
    });
  }

  items.push({
    label: 'Preview Ads',
    onClick: () =>
      modalController.openModal({
        title: 'Ads Preview',
        subtitle: campaign.name,
        contents: <AdsPreviewModal campaignId={campaign.id} />
      })
  });

  if (campaign?.managed_by === 'local') {
    let modal;
    if (status === STATUS.PAUSED) {
      modal = {
        component: (props) => <ResumeCampaignModal {...props} />,
        route: ROUTES.CAMPAIGN.RESUME
      };
    } else {
      modal = {
        component: (props) => <PauseCampaignModal {...props} />,
        route: ROUTES.CAMPAIGN.PAUSE
      };
    }
    items.push({
      label: status === STATUS.PAUSED ? 'Resume' : 'Pause',
      onClick: () => openModal(modal)
    });
  }

  if (isFlexCampaign || isListing) {
    items.push({
      label: 'Preview Landing Page',
      onClick: () =>
        modalController.openModal({
          title: 'Landing Page Preview',
          subtitle: campaign.name,
          contents: (
            <PreviewLandingPageModal
              campaignId={campaign.id}
              campaigns={undefined}
            />
          )
        })
    });
  }

  if (isListing) {
    items.push({
      label: 'Copy Token',
      onClick: () =>
        modalController.openModal({
          title: 'Campaign Token',
          subtitle: campaign.name,
          contents: <CopyTokenModal campaignId={campaign.id} />
        })
    });
  } else {
    if (status === STATUS.EXPIRING) {
      items.push({
        // TODO: double check functionality after backend supports stop renewal
        label: 'Activate renewal',
        onClick: async () => {
          await campaigns.updateItem({
            id: campaign.id,
            data: { renew: true }
          });
          toasts.addToast({
            type: 'success',
            description: 'Automatic campaign renewal has been activated!'
          });
        }
      });
    } else if (status === STATUS.ACTIVE) {
      items.push({
        // TODO: double check functionality after backend supports stop renewal
        label: 'Stop renewal',
        onClick: () =>
          openModal({
            component: (props) => <StopCampaignModal {...props} />,
            route: ROUTES.CAMPAIGN.STOP
          })
      });
    }
  }
  if (status !== STATUS.ARCHIVED) {
    items.push({
      // TODO: double check functionality after backend supports archive
      label: 'Archive',
      onClick: () =>
        modalController.openModal({
          title: 'Archive campaign',
          subtitle: campaign.name,
          contents: (
            <ArchiveCampaignModal
              campaignId={campaign.id}
              closeModal={modalController.closeModal}
            />
          )
        })
    });
  }

  if (status !== STATUS.DRAFT && !isCatalogCampaign) {
    items.push({
      label: 'Share report',
      onClick: () =>
        modalController.openModal({
          title: 'Share Campaign Report',
          subtitle: campaign.name,
          contents: (
            <ShareCampaignModal
              campaignId={campaign.id}
              closeModal={modalController.closeModal}
            />
          )
        })
    });
  }

  const modalProps = {
    match: { params: { campaignId: campaign.id } },
    closeModal,
    whereabouts,
    campaignId: campaign.id
  };
  const Modal = modal.component;

  return (
    <span>
      <ActionMenu
        Icon={ActionMenuIcon}
        Button={GhostCircleButton}
        items={items}
        minWidth='190px'
      />

      {Modal && <Modal {...modalProps} />}
    </span>
  );
}

export default compose(
  withWhereabouts,
  withModel(campaignsModel)
)(CampaignActionMenu);

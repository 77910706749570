import { EventFn, TintIconButton } from '@rexlabs/button';
import Icons from '@rexlabs/icons-next';
import LoadingSpinner from '@rexlabs/loading-spinner';
import {
  border,
  keyframes,
  margin,
  padding,
  StyleSheet,
  TokenGetter,
  useStyles
} from '@rexlabs/styling';
import React, { AllHTMLAttributes, FC, ReactElement } from 'react';
import { FileDisplayProps } from '../types';
import { useFileDisplayState } from '../useFileDisplayState';

const successAnimation = (token: TokenGetter): string =>
  keyframes({
    '25%': {
      backgroundColor: token('color.container.static.success.contrast')
    },
    '70%': {
      backgroundColor: token('color.container.static.success.contrast')
    }
  });

const styles = StyleSheet({
  container: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    height: '4rem',
    width: '100%',
    backgroundColor: ({ token }) => token('color.neutral.idle.contrast'),
    ...padding.styles({
      all: 's'
    }),
    ...border.styles({
      all: {
        radius: 'm',
        width: 'none'
      }
    }),

    '& > * + *': {
      ...margin.styles({
        left: 's'
      })
    }
  },

  failed: {
    backgroundColor: ({ token }) =>
      token('color.container.static.danger.default')
  },

  completed: {
    animation: ({ token }) => `${successAnimation(token)} 2s ease-in forwards`
  },

  content: {
    flex: '1 1 auto',
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
    fontSize: ({ token }) => token('typography.size.m'),
    fontWeight: ({ token }) => token('typography.weight.medium'),
    lineHeight: ({ token }) => token('typography.lineHeight.m')
  },

  fileLink: {
    fontSize: 'inherit',
    fontWeight: 'inherit',
    lineHeight: 'inherit',
    textDecoration: 'underline',
    color: 'inherit'
  }
});

export type SimpleFileDisplayProps<T> = FileDisplayProps<T>;

export const SimpleFileDisplay: <T>(
  props: SimpleFileDisplayProps<T>
) => ReactElement | null = (props) => {
  const s = useStyles(styles, 'SimpleFileDisplay');
  const {
    isLoading,
    isComplete,
    isError,
    previewData,
    state,
    error,
    file,
    removeFile,
    retry
  } = useFileDisplayState(props);
  const LabelElement: FC<AllHTMLAttributes<HTMLElement>> = (props) =>
    previewData?.url ? (
      <a
        {...s('fileLink')}
        target='_blank'
        rel='noreferrer'
        href={previewData.url}
        {...props}
      />
    ) : (
      <span {...props} />
    );

  return (
    <div
      {...s('container', {
        [state]: true
      })}
    >
      {isLoading ? (
        <LoadingSpinner size={16} strokeWidth={2} />
      ) : isError ? (
        <Icons.WarningCircle />
      ) : (
        <Icons.PaperClip />
      )}
      <span {...s('content')}>
        {isComplete ? (
          <LabelElement>{previewData?.name}</LabelElement>
        ) : isError ? (
          error?.message ?? 'Unsuccessful'
        ) : (
          file?.name ?? 'Loading...'
        )}
      </span>
      {isError && retry ? (
        <TintIconButton
          size='xs'
          Icon={Icons.Refresh}
          onClick={retry as unknown as EventFn}
        />
      ) : null}
      <TintIconButton
        size='xs'
        Icon={Icons.CrossSmall}
        onClick={removeFile as unknown as EventFn}
      />
    </div>
  );
};

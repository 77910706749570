import { useMutation, useQueryClient } from 'react-query';
import { api } from 'src/utils/api-client';
import { LeadCaptureEmail } from '../types';
import { leadCaptureEmailsKeys } from './keys';

export const deleteLeadCaptureEmail = async (
  campaignId: string,
  id: string
): Promise<void> => {
  await api.delete(`/campaigns/${campaignId}/lead_capture_emails/${id}`);
};

export const useDeleteLeadCaptureEmail = (campaignId: string) => {
  const queryClient = useQueryClient();

  return useMutation<void, Error, string>(
    (id) => deleteLeadCaptureEmail(campaignId, id),
    {
      onSuccess: (_data, id) => {
        queryClient.setQueryData<LeadCaptureEmail[]>(
          leadCaptureEmailsKeys.list(campaignId),
          (old = []) => old.filter((v) => v.id !== id)
        );
      },
      onSettled: () => {
        queryClient.invalidateQueries(leadCaptureEmailsKeys.list(campaignId));
      }
    }
  );
};

import { Box } from '@rexlabs/box';
import VividRenderLoading from '@rexlabs/render-loading';
import React, { Component } from 'react';
import { Small } from 'src/view/components/text';
import LoadingSpinner from './spinner';

class RenderLoading extends Component {
  shouldComponentUpdate(nextProps) {
    return (
      nextProps.alwaysRenderChildren ||
      !nextProps.isLoading ||
      !this.props.isLoading
    );
  }

  render() {
    const { label, ...props } = this.props;
    return (
      <VividRenderLoading
        LoadingView={() => (
          <Box flexDirection='column' alignItems='center'>
            <LoadingSpinner />
            <Small grey>{label || 'Loading...'}</Small>
          </Box>
        )}
        minHeight='20rem'
        alwaysRenderChildren={false}
        {...props}
      />
    );
  }
}

export default RenderLoading;

import BugsnagClient from '@bugsnag/js';
import BugsnagPluginReact from '@bugsnag/plugin-react';
import { noop } from 'lodash';
import React from 'react';
import config from 'src/config';

export const startBugsnag = (): void => {
  if (config.BUGSNAG_ID) {
    BugsnagClient.start({
      onError: (error) => {
        // We want to ignore `ResizeObserver loop limit exceeded` errors, since this is
        // just a browser warning that is unfortunately handled through `window.onerror`
        // and is expected behaviour :/
        // See https://github.com/WICG/resize-observer/issues/38#issuecomment-334816361
        if (
          error.errors[0].errorMessage === 'ResizeObserver loop limit exceeded'
        ) {
          return false;
        }

        // Attach the Fullstory timestamped URL so we can review the bug easily
        // @ts-expect-error Declaration merging not working for Fullstory
        if (window.FS && window.FS.getCurrentSessionURL) {
          error.addMetadata('fullstory', {
            // @ts-expect-error Declaration merging not working for Fullstory

            urlAtTime: window.FS.getCurrentSessionURL(true)
          });
        }
      },
      plugins: [new BugsnagPluginReact(React)],
      apiKey: config.BUGSNAG_ID,
      appVersion: process.env.APP_VERSION,
      releaseStage: config.STAGE
    });
  }
};

export const Bugsnag = new Proxy(BugsnagClient, {
  get: (target, prop, ...rest) => {
    if (['Breadcrumb', 'Event', 'Session'].includes(prop.toString())) {
      return Reflect.get(target, prop, ...rest);
    }
    return config.BUGSNAG_ID ? Reflect.get(target, prop, ...rest) : noop;
  }
});

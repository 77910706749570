import React, { PureComponent } from 'react';
import InlineRectangle, { types } from './inline-rectangle';

export default class LargeRectangle extends PureComponent {
  static propTypes = InlineRectangle.propTypes;
  render() {
    return (
      <InlineRectangle
        width={336}
        height={280}
        imageHeight={215}
        maxFontSize={28}
        minFontSize={18}
        type={types.LARGE}
        {...this.props}
      />
    );
  }
}

import React from 'react';
import { styled, StyleSheet } from '@rexlabs/styling';

import {
  withForm,
  Form as RexlabsForm,
  FieldArray,
  ReactForms
} from '@rexlabs/form';

import FieldRow from './row';
import FieldCell from './cell';
import FormField from './field';
import HiddenField from './hidden-field';
import LabelOptionalField from './label-optional-field';

const defaultStyles = StyleSheet({
  form: {
    display: 'flex',
    flexDirection: 'column',
    flex: 1
  }
});

const Form = styled(defaultStyles)(({ styles: s, name, ...props }) => {
  return <RexlabsForm id={name} name={name} {...s('form')} {...props} />;
});

export {
  withForm,
  Form,
  FormField,
  HiddenField,
  FieldArray,
  FieldRow,
  FieldCell,
  LabelOptionalField,
  ReactForms
};

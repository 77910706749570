/* eslint-disable max-lines */
import { Box } from '@rexlabs/box';
import { GhostButton, PrimaryButton } from '@rexlabs/button';
import { StyleSheet, margin, useStyles } from '@rexlabs/styling';
import { Body } from '@rexlabs/text';
import React, { FC, ReactNode, useRef, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { ButtonGroup, Spacer } from 'src/components/elements/button-group';
import {
  FormControl,
  FormErrorMessage,
  FormHelperText,
  FormInput,
  FormLabel
} from 'src/components/form';
import { AgentSelectorInput } from 'src/features/agents/components/AgentSelectorInput';
import { HelpIcon } from 'src/view/components/tooltip';
import { LocationSelect } from 'src/components/elements/location-select';
import MapDraggablePin from 'view/components/input/map-draggable-pin';
import { Checkbox, Select, TextArea } from 'view/components/input';
import { RadioGroupInput } from '@rexlabs/radio-input';
import { TextInput, NumberInput } from '@rexlabs/text-input';
import { VideoUploadInput } from 'src/features/uploads/components/VideoUploadInput';
import { EventToggle } from 'view/components/input/events';
import ManualAddress from 'src/view/components/manual-address';
import { CommercialListingFormDTO } from 'src/features/listings/types';
import { Address } from 'src/features/audiences';
import { superstructResolver } from '@hookform/resolvers/superstruct';
import { commercialListingFormSchema } from '../../utils/schemas';
import {
  DEFAULT_FORM_VALUES,
  LAND_AREA_UNIT_OPTIONS,
  LISTING_CATEGORY_OPTIONS,
  LISTING_TYPE_OPTIONS,
  SQUARE_METERS_SYMBOL
} from 'src/features/listings/components/details-form/constants';
import ImageUpload from 'view/components/input/file-upload/image-upload';

const styles = StyleSheet({
  form: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    width: '100%',
    '& > * + *': {
      ...margin.styles({
        top: 's'
      })
    },
    p: {
      marginTop: 0
    }
  },
  controls: {
    display: 'flex',
    flexDirection: 'column',
    '& > * + *': {
      ...margin.styles({
        top: 's'
      })
    }
  },
  text: {
    fontSize: '15px'
  }
});

export type CommercialListingSubmitHandler = (
  data: CommercialListingFormDTO
) => Promise<void>;

export type CommercialListingFormProps = Omit<
  JSX.IntrinsicElements['form'],
  'onSubmit' | 'resource'
> & {
  resource?: CommercialListingFormDTO;
  onSubmit: CommercialListingSubmitHandler;
  onCancel: () => void;
  submitLabel?: ReactNode;
};

export const CommercialListingForm: FC<CommercialListingFormProps> = ({
  resource,
  onSubmit,
  onCancel,
  submitLabel = 'Save',
  className,
  style,
  ...rest
}) => {
  const s = useStyles(styles);
  const [showManualAddress, setShowManualAddress] = useState(false);
  const invalidAddress = useRef();

  const {
    control,
    handleSubmit,
    setValue,
    watch,
    formState: { isSubmitting, isValid }
  } = useForm<CommercialListingFormDTO>({
    mode: 'onChange',
    resolver: superstructResolver(commercialListingFormSchema),
    defaultValues: resource ?? DEFAULT_FORM_VALUES
  });

  const [shouldDisplayEvents, selectedSubCategory] = watch([
    'should_display_events',
    'sub_categories'
  ]);

  return (
    <form
      id='commercial-listing-form'
      {...s.with('form')({ className, style })}
      {...rest}
      noValidate
      onSubmit={handleSubmit(onSubmit)}
    >
      <div {...s('controls')}>
        <Controller
          control={control}
          name='address'
          render={({ field }) => (
            <FormControl isRequired>
              <FormLabel>Address</FormLabel>
              <FormInput // @ts-expect-error Prop inference is currently broken
                Input={LocationSelect}
                {...field}
                onChange={(address: Address) => {
                  setValue('mapPinLocation', [
                    address.longitude,
                    address.latitude
                  ]);
                  field.onChange(address);
                }}
                type={'address'}
              />
            </FormControl>
          )}
        />

        <ManualAddress
          disableCountry={false}
          subtitle={
            invalidAddress.current
              ? 'Google maps is unable to find the address. Please enter the missing information below.'
              : ''
          }
          show={showManualAddress}
          initialValues={invalidAddress.current}
          initialErrors={invalidAddress.current}
          onClose={() => {
            if (showManualAddress) {
              setShowManualAddress(false);
            }
          }}
          onSave={(address) => {
            setShowManualAddress(false);

            setValue('address', {
              ...address.value,
              full_address: address.label
            });

            setValue('mapPinLocation', [
              address.value.longitude,
              address.value.latitude
            ]);
          }}
        />

        <Controller
          control={control}
          name='hide_address'
          render={({ field }) => {
            return (
              <Box flexDirection={'row'} alignItems={'center'} spacing={'8px'}>
                <Checkbox {...field} inputRef={field.ref} />
                <div>{'Hide address'}</div>
              </Box>
            );
          }}
        />

        <Controller
          control={control}
          name='category'
          render={({ field, fieldState: { error } }) => {
            return (
              <FormControl isRequired isInvalid={error !== undefined}>
                <FormLabel>Listing Type</FormLabel>
                <RadioGroupInput
                  orientation='horizontal'
                  options={LISTING_TYPE_OPTIONS}
                  {...field}
                />
              </FormControl>
            );
          }}
        />

        <Controller
          control={control}
          name='sub_categories'
          render={({ field, fieldState: { error } }) => (
            <FormControl isRequired isInvalid={error !== undefined}>
              <FormLabel>Category</FormLabel>
              <Select
                options={LISTING_CATEGORY_OPTIONS}
                {...field}
                onChange={(e) => {
                  field.onChange([e.target.value]);
                  setValue('otherSubCategory', '');
                }}
                isSearchable={false}
                isClearable={false}
                value={field.value?.[0]}
                selectProps={{
                  'data-testid': 'sub-categories-select'
                }}
              />
              {selectedSubCategory?.includes('Other') ? (
                <Box mt={'4px'}>
                  <Controller
                    control={control}
                    name='otherSubCategory'
                    render={({ field }) => (
                      <FormControl>
                        <FormInput Input={TextInput} {...field} />
                      </FormControl>
                    )}
                  />
                </Box>
              ) : null}
            </FormControl>
          )}
        />

        <Box flexDirection={'row'} gap={'20px'} mt={'1.2rem'}>
          <Box flexDirection={'row'} gap={'5px'} alignItems={'flex-end'}>
            <Controller
              control={control}
              name='landarea'
              render={({ field, fieldState: { error } }) => (
                <FormControl isRequired isInvalid={error !== undefined}>
                  <FormLabel>Land Size</FormLabel>
                  <NumberInput
                    {...field}
                    onChange={(e) =>
                      field.onChange(
                        e.target.value ? parseInt(e.target.value) : ''
                      )
                    }
                    meta={{
                      error: error?.message ?? null
                    }}
                  />
                </FormControl>
              )}
            />
            <Controller
              control={control}
              name='landarea_unit'
              render={({ field, fieldState: { error } }) => (
                <FormControl isRequired isInvalid={error !== undefined}>
                  <Select
                    options={LAND_AREA_UNIT_OPTIONS}
                    onChange={field.onChange}
                    isSearchable={false}
                    isClearable={false}
                    value={field.value}
                    selectProps={{
                      'data-testid': 'landarea-unit-select'
                    }}
                  />
                </FormControl>
              )}
            />
          </Box>
          <Box flexDirection={'row'} alignItems={'center'}>
            <Controller
              control={control}
              name='buildarea_max'
              render={({ field, fieldState: { error } }) => (
                <FormControl isRequired isInvalid={error !== undefined}>
                  <FormLabel>Floor area</FormLabel>
                  <Box flexDirection={'row'} alignItems={'center'} gap={'4px'}>
                    <NumberInput
                      {...field}
                      onChange={(e) =>
                        field.onChange(
                          e.target.value ? parseInt(e.target.value) : ''
                        )
                      }
                      meta={{ error: error?.message ?? null }}
                    />
                    <div>{SQUARE_METERS_SYMBOL}</div>
                  </Box>
                </FormControl>
              )}
            />
          </Box>
          <Box>
            <Controller
              control={control}
              name='cars'
              render={({ field, fieldState: { error } }) => (
                <FormControl isInvalid={error !== undefined}>
                  <FormLabel>Parking Spaces</FormLabel>
                  <NumberInput
                    {...field}
                    onChange={(e) =>
                      field.onChange(
                        e.target.value ? parseInt(e.target.value) : undefined
                      )
                    }
                    meta={{ error: error?.message ?? null }}
                  />
                </FormControl>
              )}
            />
          </Box>
        </Box>

        <Controller
          control={control}
          name='mapPinLocation'
          render={({ field, fieldState: { error } }) => {
            return (
              <FormControl isRequired isInvalid={error !== undefined}>
                <FormLabel>Map pin location</FormLabel>
                <FormHelperText {...s('text')}>
                  {
                    'If the pin location is incorrect, drap the pin to set the correct location.'
                  }
                </FormHelperText>
                <FormInput Input={MapDraggablePin} {...field} />
              </FormControl>
            );
          }}
        />

        <Controller
          control={control}
          name='price'
          render={({ field, fieldState: { error } }) => {
            return (
              <FormControl isInvalid={error !== undefined}>
                <FormLabel>Price</FormLabel>
                <FormInput Input={TextInput} {...field} />
              </FormControl>
            );
          }}
        />

        <Controller
          control={control}
          name='marketing_headline'
          render={({ field, fieldState: { error } }) => {
            return (
              <FormControl isRequired isInvalid={error !== undefined}>
                <FormLabel>Marketing Slogan</FormLabel>
                <FormInput Input={TextInput} {...field} />
                <FormErrorMessage>
                  The marketing slogan field is required.
                </FormErrorMessage>
              </FormControl>
            );
          }}
        />

        <Controller
          control={control}
          name='description'
          render={({ field, fieldState: { error } }) => {
            return (
              <FormControl isRequired isInvalid={error !== undefined}>
                <FormLabel>Description</FormLabel>
                <FormInput
                  Input={TextArea}
                  {...field} // @ts-expect-error Prop inference is currently broken
                  isSoftLimit={false}
                  charLimit={90}
                />
                <FormErrorMessage>
                  The description field is required.
                </FormErrorMessage>
              </FormControl>
            );
          }}
        />

        <Controller
          control={control}
          name='marketing_url'
          render={({ field, fieldState: { error } }) => {
            return (
              <FormControl isRequired isInvalid={error !== undefined}>
                <FormLabel>Listing URL</FormLabel>
                <FormInput Input={TextInput} {...field} />
                <FormErrorMessage>
                  The listing URL field is required.
                </FormErrorMessage>
              </FormControl>
            );
          }}
        />

        <Controller
          control={control}
          name='images'
          render={({ field, fieldState: { error } }) => {
            return (
              <FormControl isRequired isInvalid={error !== undefined}>
                <FormLabel>Property Images</FormLabel>
                <ImageUpload
                  {...field}
                  onChange={(e) => field.onChange(e.target.files)}
                  initialImages={field.value ?? []}
                  columns={2}
                  sortable
                  status={'loaded'}
                  shouldAllowMultiple
                />
                <FormErrorMessage>
                  The property image field is required. Rex Reach needs at least
                  two property images to create a new listing campaign.
                </FormErrorMessage>
              </FormControl>
            );
          }}
        />

        <Controller
          control={control}
          name='videos'
          render={({ field, fieldState: { error } }) => {
            return (
              <FormControl>
                <FormLabel>Marketing Video</FormLabel>
                {/* @ts-expect-error Prop inference is currently broken */}
                <FormInput Input={VideoUploadInput} {...field} />
                <FormErrorMessage>{error?.message}</FormErrorMessage>
              </FormControl>
            );
          }}
        />

        <Controller
          control={control}
          name='agent_id'
          render={({ field, fieldState: { error } }) => (
            <FormControl isInvalid={error !== undefined}>
              <FormLabel>
                Listing Agent
                <HelpIcon
                  Content={() => (
                    <Box style={{ width: '20rem' }}>
                      <Body>
                        A commercial listing campaign is tied to an agent,
                        choose which agent you’d like to attach to this
                        commercial listing even if someone else will be handling
                        the leads
                      </Body>
                    </Box>
                  )}
                />
              </FormLabel>
              <FormInput Input={AgentSelectorInput} {...field} />
            </FormControl>
          )}
        />

        <Controller
          control={control}
          name='events'
          render={({ field, fieldState: { error } }) => {
            return (
              <FormControl>
                <FormInput
                  Input={EventToggle}
                  {...field} // @ts-expect-error Prop inference is currently broken
                  toggled={shouldDisplayEvents}
                  onChange={(e) => {
                    setValue('events', e.target.value);
                  }}
                  onToggle={(toggled: boolean) => {
                    setValue('should_display_events', toggled);
                  }}
                />
                <FormErrorMessage>{error?.message}</FormErrorMessage>
              </FormControl>
            );
          }}
        />
      </div>

      <ButtonGroup {...s('footer')} spacing='l'>
        <Spacer />
        <GhostButton onClick={onCancel}>Cancel</GhostButton>
        <PrimaryButton
          isLoading={isSubmitting}
          isDisabled={!isValid}
          type='submit'
        >
          {submitLabel}
        </PrimaryButton>
      </ButtonGroup>
    </form>
  );
};

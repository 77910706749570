import { Box } from '@rexlabs/box';
import { styled, StyleSheet } from '@rexlabs/styling';
import React from 'react';
import TidyingUp from 'src/assets/images/tidying-up.png';
import { Logo } from 'src/components/elements/logo';
import { formattedBrandName } from 'src/theme';
import { Body, Heading } from 'src/view/components/text';

const defaultStyles = StyleSheet({
  logo: {
    color: ({ token }) => token('legacy.color.primary.default'),
    height: '4.8rem',
    marginTop: '4.3rem',
    marginBottom: ({ token }) => token('spacing.xxl')
  }
});

function MaintenanceScreen({ styles: s }) {
  return (
    <Box flexDirection='column' alignItems='center' w='100%' mt='5rem'>
      <img src={TidyingUp} />
      <Heading>Tidying up in progress</Heading>
      <Body grey>
        We’re making improvements to {formattedBrandName} and will be up and
        running again soon. Please check back later.
      </Body>
      <Logo type='rex_master' {...s('logo')} />
    </Box>
  );
}

export default styled(defaultStyles)(MaintenanceScreen);

import { Key, MouseEventHandler } from 'react';
import { BiFunction, Consumer, Runnable, UnaryFunction } from 'src/types';
import { humanFileSize } from 'src/utils/file';

export type CompletedFileValue<T> = {
  type: 'complete';
  id: Key;
  value: T;
  file?: File;
};

export type PendingFileValue = {
  type: 'pending';
  id: Key;
  file: File;
  abortController: AbortController;
};

export type FileValue<T> = CompletedFileValue<T> | PendingFileValue;

export type PreviewData = {
  name: string;
  url?: string;
};

export type PreviewDataTransformer<T> = UnaryFunction<T, PreviewData>;

export type EmptyInputProps = {
  addFiles: Consumer<File[]>;
  multiple: boolean;
  accept?: string;
  isEmpty: boolean;
  onClick: MouseEventHandler;
  isLarge?: boolean;
};

export type FileDisplayProps<T> = {
  state: 'uploading' | 'failed' | 'completed';
  value?: T;
  error?: Error;
  removeFile: Runnable;
  file?: File;
  retry?: Runnable;
  getPreviewData: PreviewDataTransformer<T>;
};

export type FileUploadFn<T> = BiFunction<File, AbortSignal, Promise<T>>;

export class InvalidFileTypeError extends Error {
  constructor() {
    super('Invalid file type');
  }
}

export class TooManyFilesError extends Error {
  constructor() {
    super('Uploader only accepts 1 file');
  }
}

export class MaxFileSizeExceededError extends Error {
  constructor(maxFileSize: number) {
    super(`File size exceeds maximum size of ${humanFileSize(maxFileSize)}`);
  }
}

import { useMatches, useParams } from 'react-router-dom';
import { steps } from '../utils/steps';

export type WizardNavigationState = {
  progress: number;
  currentStepId: string;
  nextStepId?: string;
  prevStepId?: string;
};

export const useWizardNavigationState = (): WizardNavigationState => {
  const { stepId } = useParams();
  const matches = useMatches();

  const isConfirmStep = matches.find((m) => m.id === 'confirm');
  const isProvideDetails = matches.find((m) =>
    m.id.startsWith('provide-details')
  );

  let currentIndex: number;
  let currentStepId: string;
  let prevStepId: string | undefined;
  let nextStepId: string | undefined;
  if (isConfirmStep) {
    currentIndex = steps.length + 2;
    currentStepId = 'confirm';
    prevStepId = steps.at(-1)?.id;
  } else if (isProvideDetails) {
    currentIndex = 0;
    currentStepId = 'provide-details';
    nextStepId = steps.at(0)?.id;
  } else {
    currentIndex = steps.findIndex(({ id }) => id === stepId);
    currentStepId = currentIndex < 0 ? '' : steps[currentIndex].id;
    nextStepId =
      currentIndex + 1 === steps.length
        ? 'confirm'
        : steps.at(currentIndex + 1)?.id;
    prevStepId =
      currentIndex > 0 ? steps[currentIndex - 1].id : 'provide-details';

    currentIndex += 1;
  }

  const progress = currentIndex / steps.length + 2;

  return {
    progress,
    currentStepId,
    nextStepId,
    prevStepId
  };
};

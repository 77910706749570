import React, { PureComponent } from 'react';
import { styled, StyleSheet } from '@rexlabs/styling';
import Box from '@rexlabs/box';

import facebookIconLike from '../../../assets/facebook/icon-like.png';
import facebookIconComment from '../../../assets/facebook/icon-comment.png';
import facebookIconShare from '../../../assets/facebook/icon-share.png';
import facebookIconLikeThumb from '../../../assets/facebook/icon-like-thumb.png';
import facebookIconLikeHeart from '../../../assets/facebook/icon-like-heart.png';
import facebookIconActionMenu from '../../../assets/facebook/icon-action-menu.png';
import facebookIconPublic from '../../../assets/facebook/icon-public.png';

const defaultStyles = StyleSheet({
  container: {
    width: '50rem',
    background: 'white',
    border: '.1rem solid #dddfe2',
    borderRadius: '.3rem',
    flexShrink: 0,
    fontFamily:
      'system-ui, -apple-system, BlinkMacSystemFont, ".SFNSText-Regular", sans-serif'
  },

  header: {
    width: '100%',
    padding: '1.2rem',
    paddingBottom: '0.6rem'
  },

  wrapAccount: {},

  logo: {
    width: '4rem',
    height: '4rem',
    marginRight: '.8rem',
    background: 'transparent none center center no-repeat',
    backgroundSize: 'contain',
    border: '.4rem solid transparent'
  },

  wrapAccountName: {},

  accountName: {
    fontSize: '1.3rem',
    fontWeight: 700
  },

  sponsored: {
    color: 'grey',
    fontSize: '1.2rem'
  },

  public: {
    display: 'inline-block',
    width: '1rem',
    height: '1rem',
    background: `url(${facebookIconPublic}) transparent no-repeat center center`,
    backgroundSize: 'contain',
    marginLeft: '.2rem'
  },

  actionMenu: {
    width: '1.6rem',
    height: '1.6rem',
    background: `url(${facebookIconActionMenu}) transparent no-repeat center center`,
    backgroundSize: 'contain'
  },

  wrapDescription: {
    padding: '1.2rem',
    paddingTop: '0.6rem'
  },

  description: {
    fontSize: '1.4rem',
    lineHeight: '1.38',
    fontWeight: 'normal',
    margin: 0,
    padding: 0,
    whiteSpace: 'pre-wrap'
  },

  more: {
    color: '#365899'
  },

  wrapSocial: {
    padding: '1.2rem 0 1.2rem .4rem',
    borderBottom: '.1rem solid #d8d8d8'
  },

  like: {
    width: '2rem',
    height: '2rem',
    borderRadius: '2rem',
    border: '.2rem solid white',
    background: 'none transparent no-repeat center center',
    backgroundSize: 'contain',
    marginLeft: '-.4rem',
    zIndex: 1
  },

  likeThumb: {
    zIndex: 2,
    backgroundImage: `url(${facebookIconLikeThumb})`
  },

  likeHeart: {
    backgroundImage: `url(${facebookIconLikeHeart})`
  },

  likeCount: {
    color: 'grey',
    fontSize: '1.2rem',
    marginLeft: '.8rem'
  },

  commentCount: {
    color: 'grey',
    fontSize: '1.2rem'
  },

  viewCount: {
    color: 'grey',
    fontSize: '1.2rem',
    marginLeft: '1.2rem'
  },

  wrapActions: {
    padding: '1.2rem'
  },

  action: {
    color: 'grey',
    fontSize: '1.3rem',
    fontWeight: 700,
    border: '0 none',
    background: 'transparent',
    padding: 0,
    margin: '0 .8rem',
    position: 'relative',
    paddingLeft: '2rem',

    ':before': {
      content: ' ',
      position: 'absolute',
      width: '1.6rem',
      height: '1.6rem',
      top: '50%',
      left: 0,
      marginTop: '-.8rem',
      background: 'none center center no-repeat transparent',
      backgroundSize: 'contain'
    }
  },

  actionLike: {
    ':before': {
      backgroundImage: `url(${facebookIconLike})`
    }
  },

  actionComment: {
    ':before': {
      backgroundImage: `url(${facebookIconComment})`
    }
  },

  actionShare: {
    ':before': {
      backgroundImage: `url(${facebookIconShare})`
    }
  }
});

@styled(defaultStyles)
class FacebookAdContainer extends PureComponent {
  render() {
    const { styles: s, adContentSet, children, descriptionLimit } = this.props;
    let description = adContentSet.description.replace(/[\n]+/g, '\n');
    if (
      descriptionLimit &&
      description &&
      description.length > descriptionLimit
    ) {
      description = (
        <span>
          {description.substr(0, descriptionLimit)}…{' '}
          <span {...s('more')}>See More</span>
        </span>
      );
    }

    return (
      <Box {...s('container')}>
        <Box
          {...s('header')}
          flexDirection='row'
          justifyContent='space-between'
        >
          <Box {...s('wrapAccount')} flexDirection='row' alignItems='center'>
            <Box
              {...s.with('logo')({
                backgroundImage: adContentSet.agencyLogo
                  ? `url(${adContentSet.agencyLogo})`
                  : 'none',
                backgroundColor: adContentSet.backgroundColor
                  ? adContentSet.backgroundColor
                  : 'transparent'
              })}
            />
            <Box {...s('wrapAccountName')} flexDirection='column'>
              <span {...s('accountName')}>{adContentSet.facebookPage}</span>
              <Box {...s('sponsored')} flexDirection='row' alignItems='center'>
                <span>Sponsored ∙ </span>
                <span {...s('public')} />
              </Box>
            </Box>
          </Box>
          <Box {...s('actionMenu')} />
        </Box>

        {adContentSet.description && (
          <Box {...s('wrapDescription')}>
            <h2 {...s('description')}>{description}</h2>
          </Box>
        )}

        {children}

        <Box
          {...s('wrapActions')}
          flexDirection='row'
          alignItems='center'
          justifyContent='space-around'
        >
          <button {...s('action', 'actionLike')}>Like</button>
          <button {...s('action', 'actionComment')}>Comment</button>
          <button {...s('action', 'actionShare')}>Share</button>
        </Box>
      </Box>
    );
  }
}

export default FacebookAdContainer;

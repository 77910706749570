import { Box } from '@rexlabs/box';
import { StyleSheet, useStyles } from '@rexlabs/styling';
import FacebookIcon from 'assets/ad-previews/facebook/facebook.svg';
import GoogleAdsIcon from 'assets/ad-previews/google/google-ads.png';
import InstagramIcon from 'assets/ad-previews/instagram/instagram.svg';
import React, { FC } from 'react';
import { TemplateRenderer } from 'src/components/elements/template-renderer';
import { AdContentSet, CommonData, TemplateImage } from 'src/features/ads';
import { FacebookSingleImageAd } from 'src/features/ads/components/facebook/FacebookSingleImageAd';
import { GoogleBannerAd } from 'src/features/ads/components/google/GoogleBannerAd';
import { InstagramSingleImageAd } from 'src/features/ads/components/instagram/InstagramSingleImageAd';
import { parseAdTypes, scaleTemplate } from 'src/features/ads/utils/helpers';
import { AdType } from 'src/features/campaign-definitions';
import { StatsHeading } from 'src/view/components/stats';

const styles = StyleSheet({
  /**
   * HACK: can't use display: none as the ads get scaled and when printing
   * the it renders while its still in the process of scaling /shrug
   */
  container: {
    height: 0,
    overflow: 'hidden',
    visibility: 'hidden',
    position: 'absolute',

    '@media print': {
      height: 'auto',
      overflow: 'auto',
      visibility: 'visible',
      position: 'relative',
      pageBreakBefore: 'always'
    }
  },

  description: {
    color: ({ token }) => token('legacy.color.blue.grey'),
    marginBottom: '4mm'
  },

  icons: {
    marginBottom: '4mm'
  },

  adsWrapper: {
    display: 'flex',
    height: '120mm'
  },

  adWrapper: {
    position: 'relative'
  },

  adWrapperInner: {
    position: 'absolute',
    transformOrigin: 'top left'
  }
});

type AdsPrintPreviewProps = {
  adContentSet: AdContentSet;
  adTypes: AdType[];
  adCount: number;
};
export const AdsPrintPreview: FC<AdsPrintPreviewProps> = ({
  adContentSet,
  adTypes,
  adCount
}) => {
  const s = useStyles(styles);
  const { attributes } = adContentSet;

  const logo = attributes.agency_logo as TemplateImage;

  const commonData: CommonData = {
    description: attributes.description as string,
    shortHeadline: attributes.short_headline as string,
    longHeadline: attributes.long_headline as string,
    websiteUrl: adContentSet.final_url,
    logoBgColor: attributes.accent_color as string,
    agencyLogo: logo?.sizes.original.url ?? '',
    agencyName: attributes.agency_name as string
  };

  const AdWrapper = ({ width, scale, mr, children }) => (
    <div {...s('adWrapper')} style={{ width, marginRight: mr }}>
      <div {...s('adWrapperInner')} style={{ transform: `scale(${scale})` }}>
        {children}
      </div>
    </div>
  );

  const parsedAdTypes = parseAdTypes(adTypes);

  const adsPrint = parsedAdTypes.reduce((acc, ad) => {
    if (['facebook.single_image', 'facebook.catalog_image'].includes(ad.format_id) ) {
      acc.facebook = ad;
    }

    if (ad.format_id === 'instagram.single_image') {
      acc.instagram = ad;
    }

    if (ad.format_id === 'google_ads.responsive' && !acc.google) {
      acc.google = ad;
    }

    return acc;
  }, {} as Record<string, AdType>);

  const { facebook, instagram, google } = adsPrint;

  return (
    <div {...s('container')}>
      <StatsHeading title='Ads Preview' />
      <p {...s('description')}>
        {`Below are some sample ads being used in your campaign across each ad
        network. Note: there are ${adCount} variations on these ads being used in this
        campaign.`}
      </p>

      <Box flexDirection='row' h='110mm'>
        {facebook && (
          <Box flexDirection='column' alignItems='center'>
            <FacebookIcon {...s('icons')} />
            <AdWrapper width='66mm' scale='0.5' mr='4mm'>
              <FacebookSingleImageAd {...commonData}>
                <TemplateRenderer
                  adFormatId={facebook.format_id}
                  template={facebook.templates[0]}
                  data={{
                    ...attributes,
                    width: facebook.templates[0].width,
                    height: facebook.templates[0].height
                  }}
                  scaleAmount={scaleTemplate(facebook)}
                />
              </FacebookSingleImageAd>
            </AdWrapper>
          </Box>
        )}
        {instagram && (
          <Box flexDirection='column' alignItems='center'>
            <InstagramIcon {...s('icons')} />
            <AdWrapper width='57mm' scale='0.67' mr='4mm'>
              <InstagramSingleImageAd {...commonData}>
                <TemplateRenderer
                  adFormatId={instagram.format_id}
                  template={instagram.templates[0]}
                  data={{
                    ...attributes,
                    width: instagram.templates[0].width,
                    height: instagram.templates[0].height
                  }}
                  scaleAmount={scaleTemplate(instagram)}
                />
              </InstagramSingleImageAd>
            </AdWrapper>
          </Box>
        )}
        {google && (
          <Box flexDirection='column' alignItems='center'>
            <img src={GoogleAdsIcon} {...s('icons')} />
            <AdWrapper width='67mm' scale='1' mr='0'>
              <GoogleBannerAd {...commonData}>
                <TemplateRenderer
                  adFormatId={google.format_id}
                  template={google.templates[0]}
                  data={{
                    ...attributes,
                    width: google.templates[0].width,
                    height: google.templates[0].height
                  }}
                  scaleAmount={scaleTemplate(google)}
                />
              </GoogleBannerAd>
            </AdWrapper>
          </Box>
        )}
      </Box>
    </div>
  );
};

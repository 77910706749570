import { Box } from '@rexlabs/box';
import { Draggable, DraggableProvider, Droppable } from '@rexlabs/draggable';
import { styled, StyleSheet, useToken } from '@rexlabs/styling';
import React, { useState } from 'react';
import { BORDER_RADIUS } from 'src/theme';
import { Label, Tiny } from 'src/view/components/text';

import Item from './item';

const defaultStyles = StyleSheet({
  container: {
    padding: ({ token }) => token('spacing.m'),
    paddingTop: 0,
    border: ({ token }) => `.1rem solid ${token('legacy.color.grey.default')}`,
    borderRadius: BORDER_RADIUS.INPUT,
    marginTop: ({ token }) => token('spacing.xl')
  },

  item: {
    borderTop: ({ token }) =>
      `.1rem solid ${token('legacy.color.grey.default')}`
  }
});

// a little function to help us with reordering the result
const reorder = (list, startIndex, endIndex) => {
  const result = Array.from(list);
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);

  return result;
};

function PropertyDetails({
  values,
  initialSlides,
  campaignId,
  backgroundColor,
  images,
  styles: s
}) {
  const [items, setItems] = useState(initialSlides);
  const token = useToken();

  const onDragEnd = (result) => {
    // dropped outside the list
    if (!result.destination) {
      return;
    }

    const reorderedItems = reorder(
      items,
      result.source.index,
      result.destination.index
    );

    setItems(reorderedItems);
  };

  return (
    <Box {...s('container')}>
      <Label>Property details</Label>
      <Tiny grey style={{ padding: 0, marginBottom: token('spacing.xl') }}>
        Update marketing texts and position in carousel.
      </Tiny>

      <DraggableProvider onDragEnd={onDragEnd}>
        <Droppable droppableId='droppable'>
          {(provided) => (
            <div {...provided.droppableProps} ref={provided.innerRef}>
              {items.map((item, index) => {
                const key =
                  item?.listing?.id || item?.agent?.id || item?.agency?.id;
                const image = images.find((i) => i.id === item.image.id);

                return (
                  <Draggable key={key} draggableId={key} index={index}>
                    {(provided, snapshot) => (
                      <div
                        ref={provided.innerRef}
                        {...provided.draggableProps}
                        style={{
                          ...provided.draggableProps.style,
                          background: 'white'
                        }}
                      >
                        <Item
                          values={values}
                          dragHandleProps={provided.dragHandleProps}
                          data={item}
                          index={index}
                          campaignId={campaignId}
                          backgroundColor={backgroundColor}
                          isDragging={snapshot.isDragging}
                          image={image}
                        />
                      </div>
                    )}
                  </Draggable>
                );
              })}
              {provided.placeholder}
            </div>
          )}
        </Droppable>
      </DraggableProvider>
    </Box>
  );
}

export default styled(defaultStyles)(PropertyDetails);

import { superstructResolver } from '@hookform/resolvers/superstruct';
import { PrimaryButton } from '@rexlabs/button';
import { TextInput } from '@rexlabs/text-input';
import React, { useRef } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { MegaModalContent } from 'src/components/elements/modal/MegaModalContent';
import { FormControl, FormInput, FormLabel } from 'src/components/form';
import { EmailRequest } from '../types';
import { leadCaptureEmailSchema } from '../utils/schemas';

export type AdditionalLeadEmailModalProps = {
  onSubmit: (values: EmailRequest) => Promise<void> | void;
};

export const AdditionalLeadEmailModal = ({
  onSubmit
}: AdditionalLeadEmailModalProps) => {
  const {
    control,
    handleSubmit,
    formState: { isValid }
  } = useForm<EmailRequest>({
    mode: 'onChange',
    resolver: superstructResolver(leadCaptureEmailSchema),
    shouldUnregister: true
  });
  const form = useRef<HTMLFormElement>(null);

  return (
    <MegaModalContent
      title='Additional email address'
      actions={[
        {
          label: 'Save',
          Component: <PrimaryButton type='submit' isDisabled={!isValid} />,
          onClick: () => form.current?.requestSubmit()
        },
        {
          label: 'Cancel'
        }
      ]}
    >
      <form ref={form} onSubmit={handleSubmit((value) => onSubmit(value))}>
        <Controller
          control={control}
          name='email'
          render={({ field, fieldState: { error } }) => (
            <FormControl isInvalid={error !== undefined}>
              <FormLabel>Email address</FormLabel>
              <FormInput
                Input={TextInput}
                {...field}
                // @ts-expect-error FormInput doesn't have good generics
                placeholder='joe.citizen@example.com'
              />
            </FormControl>
          )}
        />
      </form>
    </MegaModalContent>
  );
};

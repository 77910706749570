import { createRestAPIModelGenerator } from 'src/data/models/generator';
import { api } from 'src/utils/api-client';

export const TYPE = 'billingDetails';

const config = {
  entities: {
    api: {
      fetchList: () => api.get('/billing/details'),
      updateItem: (type, args, id) => api.patch(`/billing/details/${id}`, args)
    }
  }
};

const BillingDetailsGenerator = createRestAPIModelGenerator(TYPE, config);
export default BillingDetailsGenerator.createEntityModel();

import _ from 'lodash';
import { createRestAPIModelGenerator } from 'src/data/models/generator';
import { api } from 'src/utils/api-client';

export const TYPE = 'images';

const config = {
  entities: {
    args: {
      include: 'sizes'
    }
  }
};

const actionCreators = {
  getImageFromURL: {
    request: (payload) =>
      api.get(payload.url, null, {
        responseType: 'blob',
        headers: {
          'Cache-Control': payload.config.cache
            ? 'maxage=600, public, s-maxage=600'
            : 'no-cache',
          pragma: 'cache'
        },
        meta: payload.config.meta
      }),
    reduce: {
      initial: _.identity,
      success: _.identity,
      failure: _.identity
    }
  }
};

const ImagesGenerator = createRestAPIModelGenerator(TYPE, config);
export default ImagesGenerator.createEntityModel({ actionCreators });

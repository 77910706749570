import {
  useFieldApi,
  UseFieldApiConfig
} from '@data-driven-forms/react-form-renderer';
import { VisuallyHidden } from '@radix-ui/react-visually-hidden';
import React, { FC } from 'react';
import {
  FormControl,
  FormErrorMessage,
  FormHelperText,
  FormInput,
  FormLabel
} from 'src/components/form';
import { Box } from '@rexlabs/box';
import { Body } from '@rexlabs/text';
import { HelpIcon } from 'view/components/tooltip';

export const DynamicFormField: FC<UseFieldApiConfig> = (props) => {
  const {
    input,
    meta,
    isRequired,
    isDisabled,
    isReadOnly,
    helperText,
    tooltip,
    hideLabel,
    label,
    Input,
    ...componentProps
  } = useFieldApi(props);

  return (
    <FormControl
      isInvalid={meta.invalid}
      isRequired={isRequired}
      isDisabled={isDisabled}
      isReadOnly={isReadOnly}
    >
      <Box flexDirection='row' flex={1} alignItems={'baseline'}>
        {hideLabel ? (
          <VisuallyHidden asChild>
            <FormLabel>{label}</FormLabel>
          </VisuallyHidden>
        ) : (
          <FormLabel>{label}</FormLabel>
        )}
        {tooltip && (
          <HelpIcon
            Content={() => (
              <Box style={{ width: '30rem' }}>
                <Body>{tooltip}</Body>
              </Box>
            )}
          />
        )}
      </Box>
      <FormInput Input={Input} {...input} {...componentProps} />
      <FormErrorMessage>{meta.error}</FormErrorMessage>
      {helperText && <FormHelperText>{helperText}</FormHelperText>}
    </FormControl>
  );
};

/* eslint-disable max-lines */
import { superstructResolver } from '@hookform/resolvers/superstruct';
import { Box } from '@rexlabs/box';
import { GhostButton, PrimaryButton } from '@rexlabs/button';
import { StyleSheet, margin, useStyles } from '@rexlabs/styling';
import { Body, Large } from '@rexlabs/text';
import { TextInput } from '@rexlabs/text-input';
import { omit, pick } from 'lodash';
import React, { FC, ReactNode, useCallback, useEffect } from 'react';
import {
  Controller,
  ErrorOption,
  SubmitHandler,
  useForm
} from 'react-hook-form';
import {
  InvalidFileTypeError,
  MaxFileSizeExceededError,
  TooManyFilesError
} from 'src/components/elements/file-upload-input';
import {
  FormControl,
  FormErrorMessage,
  FormHelperText,
  FormInput,
  FormLabel
} from 'src/components/form';
import { ImageUploadInput, SimpleFileUploadInput } from 'src/features/uploads';
import { TextArea } from 'src/view/components/input';
import { HelpIcon } from 'src/view/components/tooltip';
import {
  EditableResourceDownload,
  ResourceDownload,
  ResourceDownloadDTO
} from '../types';
import { editableResourceDownloadSchema } from '../utils/schemas';

const styles = StyleSheet({
  form: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    width: '100%',
    '& > * + *': {
      ...margin.styles({
        top: 's'
      })
    }
  },
  controls: {
    display: 'flex',
    flexDirection: 'column',
    '& > * + *': {
      ...margin.styles({
        top: 's'
      })
    }
  },
  footer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    '& > * + *': {
      ...margin.styles({
        left: 'xs'
      })
    }
  },
  heading: {
    fontSize: ({ token }) => token('typography.size.2xl')
  }
});

const fileUploadErrorConvertor = (e: Error): ErrorOption => {
  const errorType =
    e instanceof InvalidFileTypeError
      ? 'file_type'
      : e instanceof TooManyFilesError
      ? 'amount'
      : e instanceof MaxFileSizeExceededError
      ? 'file_size'
      : undefined;
  return {
    type: errorType,
    message: e.message
  };
};
export const RESOURCE_FILE_ACCEPTS = '.pdf,application/pdf';

export type ResourceDownloadSubmitHandler = (
  data: ResourceDownloadDTO
) => Promise<void>;

export type ResourceDownloadFormProps = Omit<
  JSX.IntrinsicElements['form'],
  'onSubmit' | 'resource'
> & {
  resource?: ResourceDownload;
  onSubmit: ResourceDownloadSubmitHandler;
  onCancel: () => void;
  submitLabel?: ReactNode;
};

export const ResourceDownloadForm: FC<ResourceDownloadFormProps> = ({
  resource,
  onSubmit,
  onCancel,
  submitLabel = 'Save',
  className,
  style,
  ...rest
}) => {
  const s = useStyles(styles);
  const {
    control,
    handleSubmit,
    reset,
    formState: { isValid, isSubmitting, errors },
    setError
  } = useForm<EditableResourceDownload>({
    mode: 'onChange',
    resolver: superstructResolver(editableResourceDownloadSchema),
    defaultValues: resource
  });

  useEffect(() => {
    reset(resource);
  }, [reset, resource]);

  const transformAndSubmit = useCallback<
    SubmitHandler<EditableResourceDownload>
  >(
    async (values) => {
      const dto: ResourceDownloadDTO = {
        ...omit(values, 'upload', 'image'),
        upload_id: values.upload.id,
        image: pick(values.image, ['id', 'name'])
      };
      await onSubmit(dto);
    },
    [onSubmit]
  );
  const uploadErrorHandler = useCallback(
    (e: Error) => {
      const errOpt = fileUploadErrorConvertor(e);
      setError('upload', errOpt);
    },
    [setError]
  );
  const imageErrorHandler = useCallback(
    (e: Error) => {
      const errOpt = fileUploadErrorConvertor(e);
      setError('image', errOpt);
    },
    [setError]
  );

  return (
    <form
      {...s.with('form')({ className, style })}
      {...rest}
      noValidate
      onSubmit={handleSubmit(transformAndSubmit)}
    >
      <div {...s('controls')}>
        <Controller
          control={control}
          name='upload'
          render={({ field, fieldState: { error } }) => {
            return (
              <FormControl isRequired isInvalid={errors.upload !== undefined}>
                <Large {...s('heading')}>Resource Details</Large>
                <FormLabel>
                  Resource File
                  <HelpIcon
                    Content={() => (
                      <Box style={{ width: '30rem' }}>
                        <Body>
                          {'This is the file your customers will download'}
                        </Body>
                      </Box>
                    )}
                  />
                </FormLabel>
                <SimpleFileUploadInput
                  multiple={false}
                  accept={RESOURCE_FILE_ACCEPTS}
                  onSelectError={uploadErrorHandler}
                  {...field}
                />
                <FormHelperText>Supports PDF files</FormHelperText>
                <FormErrorMessage>
                  {error?.type === 'file_size'
                    ? error.message
                    : error?.type === 'file_type'
                    ? 'Only PDF documents are supported'
                    : error?.type === 'amount'
                    ? 'Only one file can be uploaded'
                    : 'Resource is required'}
                </FormErrorMessage>
              </FormControl>
            );
          }}
        />

        <Controller
          control={control}
          name='image'
          render={({ field, fieldState: { error } }) => {
            return (
              <FormControl isRequired isInvalid={error !== undefined}>
                <FormLabel>
                  Cover image
                  <HelpIcon
                    Content={() => (
                      <Box style={{ width: '30rem' }}>
                        <Body>
                          {
                            'Large feature image used on ad template and landing page'
                          }
                        </Body>
                      </Box>
                    )}
                  />
                </FormLabel>
                <ImageUploadInput
                  multiple={false}
                  onSelectError={imageErrorHandler}
                  {...field}
                />
                <FormHelperText>Supports PNG and JPEG files</FormHelperText>
                <FormErrorMessage>
                  {error?.type === 'file_size'
                    ? error.message
                    : error?.type === 'file_type'
                    ? 'Only images are supported'
                    : error?.type === 'amount'
                    ? 'Only one file can be uploaded'
                    : 'Cover image is required'}
                </FormErrorMessage>
              </FormControl>
            );
          }}
        />

        <Controller
          control={control}
          name='title'
          render={({ field, fieldState: { error } }) => (
            <FormControl isRequired isInvalid={error !== undefined}>
              <FormLabel>
                Ad Title
                <HelpIcon
                  Content={() => (
                    <Box style={{ width: '30rem' }}>
                      <Body>
                        {'The main text that will appear on your ad-template'}
                      </Body>
                    </Box>
                  )}
                />
              </FormLabel>
              <FormInput
                Input={TextInput}
                {...field} // @ts-expect-error Limitation in prop type inference
                placeholder={'E.g. Guide to buying your first home'}
                isSoftLimit={false}
                charLimit={60}
              />
              <FormErrorMessage>Title is required</FormErrorMessage>
            </FormControl>
          )}
        />
        <Controller
          control={control}
          name='description'
          render={({ field }) => (
            <FormControl isRequired>
              <Large {...s('heading')}>Social Network Content</Large>
              <FormLabel>
                Description
                <HelpIcon
                  Content={() => (
                    <Box style={{ width: '30rem' }}>
                      <Body>
                        {'Short paragraph that appears on some of the ad templates ' +
                          '- e.g. space above ad design on facebook ad'}
                      </Body>
                    </Box>
                  )}
                />
              </FormLabel>
              <FormInput
                Input={TextArea}
                {...field} // @ts-expect-error Limitation in prop type inference
                placeholder={
                  'E.g. Wanting to invest in property this year but have no idea where to start? ' +
                  'Or maybe you are just looking for the latest advice on the current market. This guide has it all!'
                }
                isSoftLimit={false}
                charLimit={125}
              />
            </FormControl>
          )}
        />

        <Controller
          control={control}
          name='short_headline'
          render={({ field, fieldState: { error } }) => (
            <FormControl isRequired isInvalid={error !== undefined}>
              <FormLabel>
                Short Headline
                <HelpIcon
                  Content={() => (
                    <Box style={{ width: '30rem' }}>
                      <Body>
                        {'One line headline that is used on the ad templates ' +
                          '- e.g. above description on socials'}
                      </Body>
                    </Box>
                  )}
                />
              </FormLabel>
              <FormInput
                Input={TextInput}
                {...field} // @ts-expect-error Limitation in prop type inference
                placeholder={'E.g. Selling, Free eBook, Guide, Free Download'}
                charLimit={30}
                isSoftLimit={false}
              />
              <FormErrorMessage>
                Must be shorter than 255 characters
              </FormErrorMessage>
            </FormControl>
          )}
        />

        <Controller
          control={control}
          name='long_headline'
          render={({ field, fieldState: { error } }) => (
            <FormControl isRequired isInvalid={error !== undefined}>
              <FormLabel>
                Long Headline
                <HelpIcon
                  Content={() => (
                    <Box style={{ width: '30rem' }}>
                      <Body>
                        {'Predominantly used on the Google ads templates'}
                      </Body>
                    </Box>
                  )}
                />
              </FormLabel>
              <FormInput
                Input={TextArea}
                {...field} // @ts-expect-error Limitation in prop type inference
                placeholder={
                  'E.g. Everything you need to know for investing in Brisbane real estate this year'
                }
                isSoftLimit={false}
                charLimit={90}
              />
              <FormErrorMessage>
                Must be shorter than 255 characters
              </FormErrorMessage>
            </FormControl>
          )}
        />
      </div>

      <footer {...s('footer')}>
        <GhostButton onClick={() => onCancel()}>Cancel</GhostButton>
        <PrimaryButton
          isLoading={isSubmitting}
          isDisabled={!isValid || isSubmitting}
          type='submit'
        >
          {submitLabel}
        </PrimaryButton>
      </footer>
    </form>
  );
};

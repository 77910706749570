import {
  UseFieldApiConfig,
  UseFieldApiProps,
  useFieldApi
} from '@data-driven-forms/react-form-renderer';
import { VisuallyHidden } from '@radix-ui/react-visually-hidden';
import { Checkbox, CheckboxGroup } from '@rexlabs/checkbox';
import React, { FC, Fragment } from 'react';
import {
  FormControl,
  FormControlAriaAttributes,
  FormErrorMessage,
  FormHelperText,
  FormLabel,
  UseFormControlProps,
  useFormControl
} from '../form';
import { HelpIcon } from 'view/components/tooltip';
import { Box } from '@rexlabs/box';
import { Body } from '@rexlabs/text';

export type CheckboxFieldProps = UseFieldApiConfig &
  UseFormControlProps<HTMLElement>;

export const CheckboxField: FC<CheckboxFieldProps> = (props) => {
  const fieldProps: UseFieldApiProps<any> &
    UseFormControlProps<HTMLElement> &
    FormControlAriaAttributes = useFieldApi(props);
  const {
    input,
    meta,
    isRequired,
    isDisabled,
    isReadOnly,
    description,
    helperText,
    hideLabel,
    label,
    options,
    tooltip,
    ...componentProps
  } = useFormControl(fieldProps);
  const multiple = options !== undefined && options.length > 0;
  const LabelWrapper = !multiple || hideLabel ? VisuallyHidden : Fragment;

  return (
    <FormControl
      isInvalid={Boolean(meta.error)}
      isRequired={isRequired}
      isDisabled={isDisabled}
      isReadOnly={isReadOnly}
    >
      <LabelWrapper asChild>
        <FormLabel>{label}</FormLabel>
      </LabelWrapper>

      {multiple ? (
        // TODO: Fix the form state for multiple checkboxes
        // @ts-expect-error focus events don't use arguments
        <CheckboxGroup
          options={options}
          {...input}
          type='checkbox'
          {...componentProps}
        />
      ) : (
        <Box
          flexDirection='row'
          flex={1}
          alignItems={'center'}
          columnGap={'1.2rem'}
        >
          <Checkbox
            label={hideLabel ? undefined : label}
            {...input}
            value={input.checked}
            type='checkbox'
            {...componentProps}
          />
          {tooltip && (
            <HelpIcon
              Content={() => (
                <Box style={{ width: '30rem' }}>
                  <Body>{tooltip}</Body>
                </Box>
              )}
            />
          )}
        </Box>
      )}
      <FormErrorMessage>{meta.error}</FormErrorMessage>
      {helperText && <FormHelperText>{helperText}</FormHelperText>}
    </FormControl>
  );
};

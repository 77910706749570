import React, { PureComponent } from 'react';
import spokePropTypes from '../utils/spoke-prop-types';
import { bannerAdTypes } from '../utils/spoke-value-lists';

// Appraisal Ads
import {
  Portrait,
  HalfPage,
  TripleWideScreen,
  VerticalRectangle,
  Skyscraper,
  WideSkyscraper,
  Netboard,
  InlineRectangle,
  LargeRectangle,
  Square,
  SmallSquare,
  Leaderboard,
  LargeLeaderboard,
  Panorama,
  TopBanner,
  Banner,
  MobileBanner,
  Billboard,
  MOBILE_BANNER_SIZES
} from './banner-ads/appraisal';

export default class AppraisalBannerAd extends PureComponent {
  static propTypes = {
    ...spokePropTypes
  };

  render() {
    const { adContentSet, adType } = this.props;
    switch (adType) {
      case bannerAdTypes.LEADERBOARD:
        return <Leaderboard {...adContentSet} />;
      case bannerAdTypes.PROTRAIT:
        return <Portrait {...adContentSet} />;
      case bannerAdTypes.HALF_PAGE:
        return <HalfPage {...adContentSet} />;
      case bannerAdTypes.INLINE_RECTANGLE:
        return <InlineRectangle {...adContentSet} />;
      case bannerAdTypes.LARGE_RECTANGLE:
        return <LargeRectangle {...adContentSet} />;
      case bannerAdTypes.SQUARE:
        return <Square {...adContentSet} />;
      case bannerAdTypes.SMALL_SQUARE:
        return <SmallSquare {...adContentSet} />;
      case bannerAdTypes.TRIPLE_WIDESCREEN:
        return <TripleWideScreen {...adContentSet} />;
      case bannerAdTypes.VERTICAL_RECTANGLE:
        return <VerticalRectangle {...adContentSet} />;
      case bannerAdTypes.NETBAORD:
        return <Netboard {...adContentSet} />;
      case bannerAdTypes.SKYSCRAPER:
        return <Skyscraper {...adContentSet} />;
      case bannerAdTypes.WIDE_SKYSCRAPER:
        return <WideSkyscraper {...adContentSet} />;
      case bannerAdTypes.BANNER:
        return <Banner {...adContentSet} />;
      case bannerAdTypes.TOP_BANNER:
        return <TopBanner {...adContentSet} />;
      case bannerAdTypes.LARGE_LEADERBOARD:
        return <LargeLeaderboard {...adContentSet} />;
      case bannerAdTypes.BILLBOARD:
        return <Billboard {...adContentSet} />;
      case bannerAdTypes.PANORAMA:
        return <Panorama {...adContentSet} />;
      case bannerAdTypes.MOBILE_BANNER_LONG:
        return (
          <MobileBanner size={MOBILE_BANNER_SIZES.LARGE} {...adContentSet} />
        );
      case bannerAdTypes.MOBILE_BANNER_SHORT:
        return (
          <MobileBanner size={MOBILE_BANNER_SIZES.SMALL} {...adContentSet} />
        );
      default:
        return <span>Type {adType} not found</span>;
    }
  }
}

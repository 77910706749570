/* eslint-disable max-lines */
import { Box } from '@rexlabs/box';
import { GhostButton, PrimaryButton } from '@rexlabs/button';
import { withWhereaboutsFilter } from '@rexlabs/filters';
import { query, withModel, withQuery } from '@rexlabs/model-generator';
import { Link } from '@rexlabs/react-whereabouts';
import { Heading } from '@rexlabs/text';
import { autobind } from 'core-decorators';
import debounce from 'lodash/debounce';
import isEmpty from 'lodash/isEmpty';
import React, { PureComponent } from 'react';
import agentsFilter from 'src/data/filters/agents';
import wizardModel from 'src/data/models/custom/wizard';
import campaignsModel from 'src/data/models/entities/campaigns';
import { queryAgents } from 'src/data/queries/agents';
import WIZARD from 'src/routes/wizard';
import { formattedBrandName } from 'src/theme';
import { replace } from 'src/utils/whereabouts';
import { ButtonBar } from 'src/view/components/button';
import { Button, Filters, SearchInput } from 'src/view/components/filter';
import { AgentListItem, List } from 'src/view/components/list';
import ScrollableContent from 'src/view/components/scrollable-content';
import { Body } from 'src/view/components/text';
import { HelpIcon, TooltipStateful } from 'src/view/components/tooltip';
import withError from 'src/view/containers/with-error';
import ConfirmFlushModal from 'src/view/modals/admin/confirm-flush';
import EditAgentModal, {
  mapPropsToValues as mapPropsToAgentValues,
  validate as validateAgent
} from 'src/view/modals/wizard/edit-agent';

import ChevronLeft from 'src/assets/icons/chevron-left.svg';
import ChevronRight from 'src/assets/icons/chevron-right.svg';
import IconPlus from 'src/assets/icons/plus.svg';

const getCampaignId = (props) => props?.match?.params?.campaignId;

// NOTE: requesting all of the agents fields to be able to validate on click
// on the next button if any data is missing using `mapPropsToValues` and
// `validate` from the edit agent form itself
const queryCampaign = query`{
  ${campaignsModel} (id: ${getCampaignId}) {
    id
    name
    content_source {
      agents {
        id
        full_name
        updated_at
      }
    }
  }
}`;

@withWhereaboutsFilter(agentsFilter)
@withError()
@withModel(wizardModel)
@withQuery(queryCampaign)
@withQuery(queryAgents)
@autobind
class SelectAgentScreen extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      selectedAgent: null,
      loading: false,
      search: (props?.whereabouts?.query ?? {})['agents.search'] || '',
      showEditAgent: false,
      showFlushConfirmModal: false,
      isNewEntity: null
    };
  }

  validateAgentData() {
    const {
      agents,
      wizard: { selectedEntityId }
    } = this.props;

    if (!selectedEntityId) {
      return true;
    }

    const agentData = (agents?.list?.items ?? []).find(
      (a) => a.id === selectedEntityId
    );

    if (!agentData) {
      return true;
    }

    const fakeData = mapPropsToAgentValues({
      id: selectedEntityId,
      agents: { item: { data: agentData } }
    });
    const check = validateAgent(fakeData);
    if (check && !isEmpty(check)) {
      return check;
    }

    return undefined;
  }

  handleNext() {
    const {
      wizard: { selectedEntityId }
    } = this.props;

    if (this.validateAgentData()) {
      if (selectedEntityId) {
        this.setState({
          showEditAgent: true
        });
      }
      return;
    }
    this.handleSave({});
  }

  handleSave({ isFlushing }) {
    const {
      wizard: { set, type, slug, selectedEntityId },
      campaignTemplateId,
      campaigns,
      agents,
      error,
      match
    } = this.props;

    const campaignId = match.params.campaignId;
    const currentAgent =
      campaigns?.item?.data?.content_source?.agents?.items?.[0] ?? {};
    const selectedAgent = agents.list.items.find((agent) => {
      return agent.id === selectedEntityId;
    });
    if (isFlushing === true) {
      return this.setState({ loading: true }, () =>
        campaigns
          .updateItem({
            id: campaignId,
            data: {
              type: { id: type },
              content_source: {
                agents: [{ id: selectedEntityId }]
              }
            }
          })
          .then(() =>
            campaigns.refreshItem({
              id: campaignId,
              args: {
                include:
                  'ad_content_sets,audience_specs,images.sizes,thumbnails'
              }
            })
          )
          .then(() => {
            set({ step: 2 });
          })
          .catch((e) => {
            this.setState({ loading: false });
            error.open(e.message);
          })
      );
    } else if (isFlushing === false) {
      return set({ step: 2 });
    }

    if (campaignId) {
      if (selectedEntityId !== currentAgent.id) {
        this.setState({ showFlushConfirmModal: true, isNewEntity: true });
      } else if (selectedAgent.updated_at !== currentAgent.updated_at) {
        this.setState({ showFlushConfirmModal: true, isNewEntity: false });
      } else {
        set({ step: 2 });
      }
    } else {
      this.setState({ loading: true }, () =>
        campaigns
          .createItem({
            id: campaignId,
            data: {
              type: { id: type },
              content_source: {
                agents: [{ id: selectedEntityId }]
              },
              campaign_template_configuration: { id: campaignTemplateId }
            }
          })
          .then((response) => {
            if (!response.data.id) {
              throw new Error('No ID found in API response!');
            }
            set({ step: 2 });
            // Redirect to put the campaignId in the url
            replace(WIZARD.CAMPAIGN, {
              params: { campaignType: slug, campaignId: response.data.id }
            });
          })
          .catch((e) => {
            this.setState({ loading: false });
            error.open(e.message);
          })
      );
    }
  }

  handleCloseModal() {
    this.setState({ showEditAgent: false }, () => {
      const errors = this.validateAgentData();
      if (!errors || isEmpty(errors)) {
        this.handleSave({});
      }
    });
  }

  getCampaignAgent(props) {
    return props?.campaigns?.item?.data?.content_source?.agents?.items?.[0]?.id;
  }

  componentDidMount() {
    const {
      wizard: { set }
    } = this.props;
    // If we have an agent in the campaign data, pre-select that one
    const selId = this.getCampaignAgent(this.props);
    if (selId) {
      set({
        selectedEntityId: selId
      });
    }
  }

  componentWillReceiveProps(nextProps) {
    const {
      wizard: { set }
    } = this.props;
    const hasId = !!nextProps?.match?.params?.campaignId;
    const currId = this.getCampaignAgent(this.props);
    const nextId = this.getCampaignAgent(nextProps);
    if (hasId && currId !== nextId) {
      set({
        selectedEntityId: nextId
      });
    }
  }

  debounceSetFilter = debounce(this.setFilter, 400);

  setFilter(search) {
    const { filters } = this.props;
    filters.setFilters({ search });
  }

  render() {
    const {
      campaigns,
      agents,
      error,
      wizard: { set, selectedEntityId, type }
    } = this.props;
    const { Error } = error;
    const { loading, showEditAgent, showFlushConfirmModal, isNewEntity } =
      this.state;

    return (
      <Box flex={1} flexDirection='column'>
        <ScrollableContent>
          <Heading level={1}>Choose an agent to promote</Heading>
          <Body grey>
            {formattedBrandName} will create a new marketing campaign for the
            agent you select.{' '}
            <HelpIcon
              maxWidth='40rem'
              Content={() => (
                <Box style={{ width: '30rem' }}>
                  <Body>
                    Agent information imported from your CRM can’t be changed -
                    but if you need to tweak an existing agent profile, you can
                    always create a new one in {formattedBrandName}.
                  </Body>
                </Box>
              )}
            />
          </Body>

          {agents.list.status === 'loading' ||
          agents.list.status === 'refreshing' ||
          agents.list.items.length ? (
            <Box w='100%'>
              <Heading level={2}>Agents</Heading>
              <Filters>
                <SearchInput
                  name='searchAgent'
                  placeholder='Search by name'
                  onChange={({ target: { value } }) => {
                    this.setState({ search: value });
                    this.debounceSetFilter(value);
                  }}
                  value={this.state.search}
                  isLoading={agents.list.status === 'loading'}
                />
                <Link to={WIZARD.CAMPAIGN.CREATE_AGENT}>
                  {({ onClick }) => (
                    <Button IconLeft={IconPlus} onClick={onClick}>
                      Add Agent
                    </Button>
                  )}
                </Link>
              </Filters>
            </Box>
          ) : (
            <Box
              flexDirection='row'
              justifyContent='space-between'
              style={{ width: '100%' }}
            >
              <Heading level={2}>Agents</Heading>
              <Link to={WIZARD.CAMPAIGN.CREATE_AGENT}>
                {({ onClick }) => (
                  <Button
                    style={{ marginTop: '1rem' }}
                    IconLeft={IconPlus}
                    onClick={onClick}
                  >
                    Add Agent
                  </Button>
                )}
              </Link>
            </Box>
          )}

          <List
            isLoading={
              agents.list.status === 'loading' ||
              agents.list.status === 'refreshing'
            }
            items={agents.list.items}
            emptyStateProps={{
              largeText: <div>You don’t have any agents yet</div>,
              smallText: (
                <div>
                  To get started,{' '}
                  <Link to={WIZARD.CAMPAIGN.CREATE_AGENT}>
                    create a new agent profile.
                  </Link>
                </div>
              )
            }}
            Item={({ data: agent }) => (
              <AgentListItem
                selected={selectedEntityId === agent.id}
                onClick={() =>
                  set({
                    selectedEntityId:
                      selectedEntityId === agent.id ? null : agent.id
                  })
                }
                data={agent}
                getIndex={(agent) => agent.id}
              />
            )}
            onLoadMore={() => agents.fetchMore()}
            isFetching={agents.list.status === 'fetching'}
            endReached={agents?.list?.pagination?.endReached}
          />
        </ScrollableContent>

        <ButtonBar>
          <Link to={WIZARD.CREATE}>
            {({ onClick }) => (
              <GhostButton grey IconLeft={ChevronLeft} onClick={onClick}>
                Back
              </GhostButton>
            )}
          </Link>
          <TooltipStateful
            show={!selectedEntityId}
            Content={() => (
              <Box style={{ width: '20rem' }}>
                <Body>Please select an agent first.</Body>
              </Box>
            )}
          >
            <PrimaryButton
              IconRight={ChevronRight}
              onClick={this.handleNext}
              isDisabled={!selectedEntityId}
              isLoading={loading}
            >
              Next
            </PrimaryButton>
          </TooltipStateful>
        </ButtonBar>

        {selectedEntityId && showEditAgent && (
          <EditAgentModal
            id={selectedEntityId}
            enforceCampaignValidation
            closeModal={this.handleCloseModal}
          />
        )}

        {showFlushConfirmModal && (
          <ConfirmFlushModal
            closeModal={(isFlushing) => {
              this.setState({ showFlushConfirmModal: false }, () => {
                if (isFlushing !== undefined) {
                  this.handleSave({ isFlushing: isFlushing });
                }
              });
            }}
            campaigns={campaigns}
            type={type}
            isNewEntity={isNewEntity}
          />
        )}
        <Error />
      </Box>
    );
  }
}

export default SelectAgentScreen;

import React, { Component } from 'react';
import { SingleImage } from 'src/view/components/image';

class AgentImageInput extends Component {
  render() {
    const { canCrop } = this.props;
    return (
      <SingleImage
        {...this.props}
        overlayText='Change'
        isSquare
        canCrop={canCrop}
        ratio='square'
      />
    );
  }
}

export default AgentImageInput;

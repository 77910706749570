/**
 * Util function that ensures some text is below a specific length, if it exceeds that length it is
 * cur off and an ellipsis is inserted.
 * @param  {string} text
 * @param  {number} limit
 * @param  {String} elipsis
 * @return {string}
 */
export default function charLimit(text, limit, elipsis = '...') {
  return !text || text.length <= limit
    ? text
    : text.slice(0, limit - elipsis.length) + elipsis;
}

export const limits = {
  BANNER_AD_TEXT: 25,
  SMALL_TEXT: 25,
  SHORT_HEADLINE: 25,
  LONG_HEADLINE: 40
};

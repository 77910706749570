import { Box } from '@rexlabs/box';
import { styled, StyleSheet } from '@rexlabs/styling';
import React, { PureComponent } from 'react';

const defaultStyles = StyleSheet({
  container: {
    width: 'calc(100% + 3rem)',
    marginLeft: '-1.5rem',
    marginRight: '-1.5rem',
    flexShrink: 0
  }
});

@styled(defaultStyles)
class FieldRow extends PureComponent {
  render() {
    const { styles: s, children } = this.props;
    return (
      <Box flexDirection='row' {...s.with('container')(this.props)}>
        {children}
      </Box>
    );
  }
}

export default FieldRow;

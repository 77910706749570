import { createRestAPIModelGenerator } from 'src/data/models/generator';

export const TYPE = 'setup';

const initialState = {
  step: null
};

const selectors = {
  step: (state) => state?.setup?.step
};

const actionCreators = {
  setStep: {
    reduce: (state, action) => ({
      step: action?.payload?.step
    })
  }
};

const SetupModelGenerator = createRestAPIModelGenerator(TYPE);
export default SetupModelGenerator.createModel({
  initialState,
  actionCreators,
  selectors
});

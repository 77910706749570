import React, { forwardRef, ComponentType, PropsWithRef } from 'react';
import { ToastContext, useToast } from '@rexlabs/notifications';
import { connect } from 'react-redux';
import { show, hide } from '@rexlabs/toast';

function WithToast<P>(
  WrappedComponent: ComponentType<PropsWithRef<P & { toasts: ToastContext }>>
) {
  const Wrapper = (props) => {
    const toasts = useToast();
    return (
      <WrappedComponent
        {...{
          ...props,
          toasts
        }}
      />
    );
  };
  Wrapper.displayName = 'WithToast';

  return forwardRef((props: P, ref) => <Wrapper ref={ref} {...props} />);
}

export const withOldToast = connect(null, (dispatch) => ({
  showToast: (...args) => dispatch(show(...args)),
  hideToast: (...args) => dispatch(hide(...args))
}));

export default WithToast;

import { useMutation, useQueryClient } from 'react-query';
import { api } from 'src/utils/api-client';
import { RexIntegration } from '../types/integration';
import { rexIntegrationKeys } from './keys';

export const updateRexIntegration = async (
  attributes: Partial<RexIntegration>
): Promise<RexIntegration> => {
  const res = await api.patch('/integrations/rex', {
    attributes
  });
  return res.data;
};

export const useUpdateRexIntegration = (accountId: string) => {
  const queryClient = useQueryClient();

  return useMutation(updateRexIntegration, {
    onSettled: () => {
      queryClient.invalidateQueries(rexIntegrationKeys.rex(accountId));
    }
  });
};

export const deleteRexIntegration = async (): Promise<void> => {
  await api.delete('/integrations/rex');
};

export const useDeleteRexIntegration = (accountId: string) => {
  const queryClient = useQueryClient();

  return useMutation(deleteRexIntegration, {
    onSettled: () => {
      queryClient.invalidateQueries(rexIntegrationKeys.rex(accountId));
    }
  });
};

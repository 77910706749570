import { useParams } from 'react-router-dom';

export function useCampaignId(): string {
  const { campaignId } = useParams();
  if (campaignId === undefined)
    throw new Error(
      'Could not find campaignID parameter in current URL. Check your routing tree.'
    );
  return campaignId;
}

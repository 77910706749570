/*
 * A wrapper around the `@rexlabs/notifications` Banner component that adjusts the styling as follows.
 *
 * - Text inside the button is smaller
 * - Outline buttons have a darker outline
 *
 * Also includes an extension to the `BannerAction` type introduces the `href` and `external` properties.
 * These allow for the easy creation of Buttons that use `<a>` elements that link to internal or external pages
 */

import { GhostButton, OutlineButton } from '@rexlabs/button';
import {
  BannerProps,
  Banner as VividBanner,
  InfoBanner as VividInfoBanner,
  WarningBanner as VividWarningBanner,
  ErrorBanner as VividErrorBanner,
  SuccessBanner as VividSuccessBanner,
  NeutralBanner as VividNeutralBanner
} from '@rexlabs/notifications';
import { border, StylesProvider, text } from '@rexlabs/styling';
import { noop } from 'lodash';
import React, { ComponentType, forwardRef } from 'react';

const buttonTokens = {
  button: {
    outline: {
      ...border.tokens({
        all: {
          color: ({ token }) => token('palette.slate.900')
        }
      }),
      ...text.tokens({
        font: {
          size: ({ token }) => token('typography.size.m')
        }
      })
    }
  }
};

interface BannerBaseAction {
  label: string;
  Button?: ComponentType<any>;
}

interface BannerHrefAction extends BannerBaseAction {
  href: string;
  external?: boolean;
  onClick?: (...args: any[]) => void;
}

interface BannerClickAction extends BannerBaseAction {
  onClick: (...args: any[]) => void;
}

type BannerAction = BannerClickAction | BannerHrefAction;

export interface Props extends Omit<BannerProps, 'actions'> {
  Banner: ComponentType<BannerProps>;
  actions?: BannerAction[];
  overrideTokens?: any;
}

const BannerWrapper = forwardRef<HTMLElement, Props>(
  ({ Banner, actions, overrideTokens = {}, ...props }, ref) => {
    const origActions = actions?.map((action, i) => {
      if ('href' in action) {
        const {
          label,
          Button = i === 0 ? OutlineButton : GhostButton,
          href,
          external = false,
          onClick
        } = action;
        return {
          label,
          onClick: onClick ?? noop,
          Button: forwardRef((props, ref) => (
            <Button
              {...props}
              as='a'
              ref={ref}
              href={href}
              target={external ? '_blank' : undefined}
            />
          ))
        };
      } else {
        return action;
      }
    });
    return (
      <StylesProvider tokens={{ ...buttonTokens, ...overrideTokens }}>
        <Banner actions={origActions} {...props} ref={ref} />
      </StylesProvider>
    );
  }
);

export const Banner = forwardRef<HTMLElement, Omit<Props, 'Banner'>>(
  (props, ref) => <BannerWrapper Banner={VividBanner} ref={ref} {...props} />
);

export const InfoBanner = forwardRef<HTMLElement, Omit<Props, 'Banner'>>(
  (props, ref) => (
    <BannerWrapper Banner={VividInfoBanner} ref={ref} {...props} />
  )
);

export const WarningBanner = forwardRef<HTMLElement, Omit<Props, 'Banner'>>(
  (props, ref) => (
    <BannerWrapper Banner={VividWarningBanner} ref={ref} {...props} />
  )
);

export const ErrorBanner = forwardRef<HTMLElement, Omit<Props, 'Banner'>>(
  (props, ref) => (
    <BannerWrapper Banner={VividErrorBanner} ref={ref} {...props} />
  )
);

export const SuccessBanner = forwardRef<HTMLElement, Omit<Props, 'Banner'>>(
  (props, ref) => (
    <BannerWrapper Banner={VividSuccessBanner} ref={ref} {...props} />
  )
);

export const NeutralBanner = forwardRef<HTMLElement, Omit<Props, 'Banner'>>(
  (props, ref) => (
    <BannerWrapper Banner={VividNeutralBanner} ref={ref} {...props} />
  )
);

import { GhostButton, LinkButton, PrimaryButton } from '@rexlabs/button';
import Icons from '@rexlabs/icons-next';
import LoadingSpinner from '@rexlabs/loading-spinner';
import { useToast } from '@rexlabs/notifications';
import { StyleSheet, margin, useStyles } from '@rexlabs/styling';
import { Heading, Small } from '@rexlabs/text';
import { TextInput } from '@rexlabs/text-input';
import { partial } from 'lodash';
import React, { ChangeEventHandler, FC, useCallback, useState } from 'react';
import { Link } from 'react-router-dom';
import {
  AlertDescription,
  AlertTitle,
  ConfirmActionAlert
} from 'src/components/elements/alert';
import { ContentTypeSelectorProps } from 'src/features/wizard';
import EmptyStateSection from 'src/view/components/empty-state-section';
import { useDeleteTestimonial } from '../api/deleteTestimonial';
import { useTestimonials } from '../api/getTestimonials';
import { Testimonial } from '../types';
import { TestimonialItem } from './TestimonialItem';
import { ItemProps, TestimonialList } from './TestimonialList';

const styles = StyleSheet({
  container: {
    display: 'flex',
    flexDirection: 'column',
    minHeight: '0',
    width: '100%',

    '& > * + *': {
      ...margin.styles({
        top: 's'
      })
    }
  },

  toolbar: {
    display: 'flex',
    alignItems: 'center',

    '& > * + *': {
      ...margin.styles({
        left: 'xs'
      })
    }
  },

  action: {
    minWidth: 0,
    fontSize: ({ token }) => token('typography.size.m'),
    fontWeight: ({ token }) => token('typography.weight.bold')
  },

  seperator: {
    color: ({ token }) => token('color.textStyle.body.subtext')
  },

  delete: {
    color: ({ token }) => token('color.textStyle.danger.idle.default'),

    ':hover': {
      color: ({ token }) => token('color.textStyle.danger.hover.default')
    }
  },

  loading: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center'
  },

  listContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    flex: '1 1 auto',
    minHeight: '0'
  },

  list: {
    width: '100%'
  },

  hideUnderline: {
    textDecoration: 'none'
  }
});

export const TestimonialContentTypeSelector: FC<ContentTypeSelectorProps> = ({
  selectedIds,
  onSelection
}) => {
  const s = useStyles(styles, 'TestimonialContentTypeSelector');
  const toasts = useToast();

  const [search, setSearch] = useState('');
  const updateSearch = useCallback<ChangeEventHandler<HTMLInputElement>>(
    (e) => setSearch(e.target.value),
    []
  );

  const { mutateAsync } = useDeleteTestimonial();
  const deleteTestimonial = useCallback(
    async (item: Testimonial) => {
      try {
        await mutateAsync({ testimonialId: item.id });
      } catch (e) {
        toasts.addToast({
          type: 'error',
          title: `Unable to delete testimonal '${item.author}'`,
          description: e instanceof Error ? e.message : undefined
        });
      }
    },
    [mutateAsync, toasts]
  );

  const {
    data: testimonials,
    isLoading,
    isError,
    hasNextPage,
    fetchNextPage,
    isFetchingNextPage,
    refetch
  } = useTestimonials({
    q: search
  });

  const Item = useCallback<FC<ItemProps>>(
    ({ item, ...rest }) => (
      <TestimonialItem item={item} {...rest}>
        <Link {...s('hideUnderline')} to={`${item.id}/edit`}>
          <LinkButton {...s('action')}>Edit</LinkButton>
        </Link>
        <Icons.CircleFilled width={4} height={4} {...s('seperator')} />
        <ConfirmActionAlert
          openButton={
            <LinkButton {...s('action', 'delete')}>Delete</LinkButton>
          }
          destructive
          confirmLabel='Delete'
          onConfirm={partial(deleteTestimonial, item)}
        >
          <AlertTitle>
            Are you sure you want to delete this testimonial?
          </AlertTitle>
          <AlertDescription>
            Your testimonial will be permanently deleted. Any active campaigns
            using this testimonial will continue to work as expected.
          </AlertDescription>
        </ConfirmActionAlert>
      </TestimonialItem>
    ),
    [s]
  );

  const items = testimonials?.pages.flatMap((r) => r.data);
  const totalItems = testimonials?.pages[0].pagination.total ?? 0;

  return (
    <div {...s('container')}>
      <Heading as={(p) => <h2 id='testimonial_header' {...p} />} level={2}>
        Testimonial
      </Heading>
      <div {...s('toolbar')}>
        <TextInput
          {...s('search')}
          type='search'
          value={search}
          onChange={updateSearch}
          placeholder='Search'
        />
        <Link {...s('hideUnderline')} to='create'>
          <PrimaryButton>Create new testimonial</PrimaryButton>
        </Link>
      </div>
      {isLoading ? (
        <div {...s('loading')}>
          <LoadingSpinner />
          <Small grey>Loading...</Small>
        </div>
      ) : isError ? (
        <EmptyStateSection>
          Unable to fetch your testimonials.
          <LinkButton {...s('refetch')} onClick={() => refetch()}>
            Try again
          </LinkButton>
        </EmptyStateSection>
      ) : items?.length === 0 ? (
        <EmptyStateSection>
          You don&apos;t have any testimonials yet
          <div>
            To get started,{' '}
            <Link {...s('hideUnderline')} to='create'>
              <LinkButton>create a new testimonial</LinkButton>
            </Link>
          </div>
        </EmptyStateSection>
      ) : items ? (
        <div {...s('listContainer')}>
          <TestimonialList
            {...s('list')}
            Item={Item}
            items={items}
            totalItems={totalItems}
            selected={selectedIds}
            onSelection={onSelection}
          />
          {hasNextPage ? (
            <GhostButton
              onClick={() => fetchNextPage()}
              isLoading={isFetchingNextPage}
            >
              Load More
            </GhostButton>
          ) : null}
        </div>
      ) : null}
    </div>
  );
};

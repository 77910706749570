import { GhostButton, LinkButton, PrimaryButton } from '@rexlabs/button';
import LoadingSpinner from '@rexlabs/loading-spinner';
import { StyleSheet, margin, useStyles } from '@rexlabs/styling';
import { Heading, Small } from '@rexlabs/text';
import { TextInput } from '@rexlabs/text-input';
import React, { ChangeEventHandler, FC, useCallback, useState } from 'react';
import { ContentTypeSelectorProps } from 'src/features/wizard';
import EmptyStateSection from 'src/view/components/empty-state-section';
import { useCommercialListings } from '../api/getCommercialListings';
import { CommercialListingItem } from './CommercialListingItem';
import { CommercialListingList, ItemProps } from './CommercialListingList';
import { Link } from 'react-router-dom';
import Icons from '@rexlabs/icons-next';
import {
  AlertDescription,
  AlertTitle,
  ConfirmActionAlert
} from 'src/components/elements/alert';
import { useToast } from '@rexlabs/notifications';
import { useDeleteCommercialListing } from 'src/features/listings/api/deleteCommercialListing';
import { CommercialListing } from 'src/features/listings/types';

const styles = StyleSheet({
  container: {
    display: 'flex',
    flexDirection: 'column',
    minHeight: '0',
    width: '100%',

    '& > * + *': {
      ...margin.styles({
        top: 's'
      })
    }
  },

  toolbar: {
    display: 'flex',
    alignItems: 'center',

    '& > * + *': {
      ...margin.styles({
        left: 'xs'
      })
    }
  },

  action: {
    minWidth: 0,
    fontSize: ({ token }) => token('typography.size.m'),
    fontWeight: ({ token }) => token('typography.weight.bold')
  },

  seperator: {
    color: ({ token }) => token('color.textStyle.body.subtext')
  },

  delete: {
    color: ({ token }) => token('color.textStyle.danger.idle.default'),

    ':hover': {
      color: ({ token }) => token('color.textStyle.danger.hover.default')
    }
  },

  loading: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center'
  },

  listContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    flex: '1 1 auto',
    minHeight: '0'
  },

  list: {
    width: '100%'
  }
});

export const CommercialListingContentTypeSelector: FC<
  ContentTypeSelectorProps
> = ({ selectedIds, onSelection }) => {
  const s = useStyles(styles, 'CommercialListingContentTypeSelector');
  const toasts = useToast();

  const [search, setSearch] = useState('');
  const updateSearch = useCallback<ChangeEventHandler<HTMLInputElement>>(
    (e) => setSearch(e.target.value),
    []
  );

  const { mutateAsync } = useDeleteCommercialListing();
  const deleteCommercialListing = useCallback(
    async (item: CommercialListing) => {
      try {
        await mutateAsync({ listingId: item.id });
        onSelection([]);
      } catch (e) {
        toasts.addToast({
          type: 'error',
          title: `Unable to delete listing '${item.formatted_address}'`,
          description: e instanceof Error ? e.message : undefined
        });
      }
    },
    [mutateAsync, toasts]
  );

  const {
    data: listings,
    isLoading,
    isError,
    hasNextPage,
    fetchNextPage,
    isFetchingNextPage,
    refetch
  } = useCommercialListings({
    q: search
  });

  const Item = useCallback<FC<ItemProps>>(
    ({ item, ...rest }) => {
      return (
        <CommercialListingItem item={item} {...rest}>
          {item.data_source === 'spoke' ? (
            <>
              <Link {...s('hideUnderline')} to={`${item.id}/edit`}>
                <LinkButton {...s('action')}>Edit</LinkButton>
              </Link>
              <Icons.CircleFilled width={4} height={4} {...s('seperator')} />
              <ConfirmActionAlert
                openButton={
                  <LinkButton {...s('action', 'delete')}>Delete</LinkButton>
                }
                destructive
                confirmLabel='Delete'
                onConfirm={() => deleteCommercialListing(item)}
              >
                <AlertTitle>
                  Are you sure you want to delete this listing?
                </AlertTitle>
                <AlertDescription>
                  Your listing will be permanently deleted. Any active campaigns
                  using this listing will continue to work as expected.
                </AlertDescription>
              </ConfirmActionAlert>
            </>
          ) : null}
        </CommercialListingItem>
      );
    },
    [s]
  );

  const items = listings?.pages.flatMap((r) => r.data);
  const totalItems = listings?.pages[0].pagination.total ?? 0;

  return (
    <div {...s('container')}>
      <Heading as={(p) => <h2 id='testimonial_header' {...p} />} level={2}>
        Commercial Listing
      </Heading>
      <div {...s('toolbar')}>
        <TextInput
          {...s('search')}
          type='search'
          value={search}
          onChange={updateSearch}
          placeholder='Search'
        />
        <Link {...s('hideUnderline')} to='create'>
          <PrimaryButton data-testid={'create-listing-button'}>
            Create new listing
          </PrimaryButton>
        </Link>
      </div>
      {isLoading ? (
        <div {...s('loading')}>
          <LoadingSpinner />
          <Small grey>Loading...</Small>
        </div>
      ) : isError ? (
        <EmptyStateSection>
          Unable to fetch your property listings.
          <LinkButton {...s('refetch')} onClick={() => refetch()}>
            Try again
          </LinkButton>
        </EmptyStateSection>
      ) : items?.length === 0 ? (
        <EmptyStateSection>
          You don&apos;t have any property listings yet
        </EmptyStateSection>
      ) : items ? (
        <div {...s('listContainer')}>
          <CommercialListingList
            {...s('list')}
            Item={Item}
            items={items}
            totalItems={totalItems}
            selected={selectedIds}
            onSelection={onSelection}
          />
          {hasNextPage ? (
            <GhostButton
              onClick={() => fetchNextPage()}
              isLoading={isFetchingNextPage}
            >
              Load More
            </GhostButton>
          ) : null}
        </div>
      ) : null}
    </div>
  );
};

import React, { useState, useCallback } from 'react';
import { styled, StyleSheet } from '@rexlabs/styling';
import Box from '@rexlabs/box';
import { COLORS } from 'src/theme';

import Container from './shared/container';
import AgentAdImage from './agent/ad-image';

const defaultStyles = StyleSheet({
  image: {
    width: '1080px',
    height: '1080px',
    background: 'none no-repeat top center',
    backgroundColor: COLORS.GREY_LIGHT,
    backgroundSize: 'cover',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    justifyContent: 'flex-end',
    position: 'relative',
    overflow: 'hidden'
  }
});

const InstagramSingleImage = (props) => {
  const { styles: s, adContentSet, ad, disableWrapper = false } = props;

  const isAgent = adContentSet.type === 'agent_profile';
  const format = ad?.format?.id || '';
  const type = adContentSet?.type;
  const isSecondary = format.endsWith('_secondary');
  const imageKey = isAgent
    ? 'agentImage'
    : isSecondary
    ? 'secondaryImage'
    : 'primaryImage';
  const imageUrl = adContentSet?.[imageKey]?.square;

  const [adWrapperDimensions, setAdWrapperDimensions] = useState({
    width: 0,
    height: 0
  });

  const containerEl = useCallback((el) => {
    if (el) {
      const width = el.parentElement.offsetWidth;
      setAdWrapperDimensions({ width, height: width });
    }
  }, []);

  if (disableWrapper) {
    if (type === 'agent_profile') {
      return <AgentAdImage {...props} imageUrl={imageUrl} />;
    }

    return (
      <Box
        {...s.with('image')({
          backgroundImage: imageUrl ? `url(${imageUrl})` : 'none'
        })}
      />
    );
  }

  return (
    <Container {...props} campaignDescription={adContentSet.description}>
      <div ref={containerEl} style={{ margin: 'auto', ...adWrapperDimensions }}>
        {type === 'agent_profile' ? (
          <AgentAdImage
            {...props}
            imageUrl={imageUrl}
            adWrapperDimensions={adWrapperDimensions}
          />
        ) : (
          <Box
            {...s.with('image')({
              transformOrigin: 'top left',
              transform: `scale(${adWrapperDimensions.width / 1080})`,
              backgroundImage: imageUrl ? `url(${imageUrl})` : 'none'
            })}
          />
        )}
      </div>
    </Container>
  );
};

export default styled(defaultStyles)(InstagramSingleImage);

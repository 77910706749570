import React from 'react';
import PropTypes from 'prop-types';
import { styled, StyleSheet } from '@rexlabs/styling';

import Box from '@rexlabs/box';
import _ from 'lodash';
import colorTheme from '../../../utils/color-theme';
import { LargeButton } from '../../button';
import Pin from '../../pin';
import AgentOverlay from '../../agent-overlay';
import ScaledText from '../../scaled-text';
import charLimit, { limits } from '../../../utils/char-limit';
import { COLORS } from 'src/theme';

const fontFamily = 'Lato, sans-serif';
const defaultStyles = StyleSheet({
  portrait: {
    border: '1px solid darkgray',
    overflow: 'hidden'
  },
  primaryImage: {
    objectFit: 'cover'
  },
  body: {
    padding: '30px 24px',
    backgroundColor: 'white'
  },
  smallText: {
    fontFamily,
    fontSize: '14px',
    fontWeight: '600',
    lineHeight: '26px',
    marginBottom: '6px',
    textTransform: 'uppercase'
  },
  shortHeadline: {
    fontFamily,
    fontSize: '36px',
    fontWeight: '300',
    lineHeight: '44px',
    marginBottom: '30px'
  },
  longHeadline: {
    fontFamily,
    fontSize: '18px',
    fontWeight: '500',
    lineHeight: '24px',
    marginLeft: '7px'
  },
  secondaryImage: {
    objectFit: 'cover'
  },
  agentOverlayWrapper: {
    position: 'relative'
  },
  footer: {},
  footerLogo: {
    maxWidth: 'calc(0.6 * 300px)',
    maxHeight: 'calc(0.7 * 100px)',
    objectFit: 'contain'
  }
});

Portrait.propTypes = {
  shortHeadline: PropTypes.string,
  longHeadline: PropTypes.string,
  bannerAdText: PropTypes.string,
  agentName: PropTypes.string,
  agencyName: PropTypes.string,
  agentProfileUrls: PropTypes.object,
  primaryImageUrls: PropTypes.object,
  secondaryImageUrls: PropTypes.object,
  logoUrls: PropTypes.object,
  buttonText: PropTypes.string,
  brandColor: PropTypes.string,
  backgroundColor: PropTypes.string
};

function Portrait({
  buttonText = 'View Property',
  shortHeadline,
  longHeadline,
  smallText,
  agentName,
  agencyName,
  agentProfileUrls,
  primaryImageUrls,
  secondaryImageUrls,
  logoUrls,
  brandColor,
  backgroundColor,
  styles: s
}) {
  const { brandColorBorder } = colorTheme(brandColor);

  return (
    <Box width={300} height={1050} flexDirection='column' {...s('portrait')}>
      <img
        width='100%'
        height={317}
        {...s('primaryImage')}
        src={_.get(primaryImageUrls, ['crop.square', 'url'])}
      />
      <Box
        width='100%'
        height={332}
        flexDirection='column'
        justifyContent='center'
        {...s('body')}
      >
        <span {...s.with('smallText')({ color: COLORS.BLACK })}>
          {charLimit(smallText, limits.SMALL_TEXT)}
        </span>
        <ScaledText
          maxWidth={255}
          maxTextLines={3}
          maxFontSize={38}
          minFontSize={22}
          {...s.with('shortHeadline')({ color: COLORS.BLACK })}
        >
          {shortHeadline}
        </ScaledText>
        <LargeButton brandColor={brandColor}>{buttonText}</LargeButton>
        <Box justifyContent='flex-start' alignItems='flex-start' marginTop={30}>
          <Pin width={14} backgroundColor={COLORS.BLACK} />
          <span
            {...s.with('longHeadline')({
              color: COLORS.BLACK
            })}
          >
            {charLimit(longHeadline, limits.LONG_HEADLINE)}
          </span>
        </Box>
      </Box>
      <Box {...s('agentOverlayWrapper')}>
        <img
          height={300}
          width='100%'
          src={_.get(secondaryImageUrls, ['crop.square', 'url'])}
          {...s('secondaryImage')}
        />
        <AgentOverlay
          agentName={agentName}
          agencyName={agencyName}
          agentProfileUrl={_.get(agentProfileUrls, ['crop.square', 'url'])}
        />
      </Box>
      <Box
        height='100px'
        display='flex'
        flexDirection='column'
        alignItems='center'
        justifyContent='center'
        padding='15px 40px'
        {...s.with('footer')({
          backgroundColor: backgroundColor,
          borderTop: brandColorBorder
        })}
      >
        <img src={_.get(logoUrls, 'original.url')} {...s('footerLogo')} />
      </Box>
    </Box>
  );
}

export default styled(defaultStyles)(Portrait);

import { navigation, track } from '@rexlabs/analytics';
import { Box } from '@rexlabs/box';
import { withModel } from '@rexlabs/model-generator';
import { Portal } from '@rexlabs/portal';
import React, { useEffect } from 'react';
import { compose } from 'redux';
import sessionModel from 'src/data/models/custom/session';
import wizardModel from 'src/data/models/custom/wizard';
import ROUTES from 'src/routes/admin';
import { urlToPageName } from 'src/utils/routing';
import { push } from 'src/utils/whereabouts';
import WizardScreenAnimationGroup from 'src/view/animation-groups/wizard-screen';
import HeaderProgress from 'src/view/components/header-progress';
import { StepItem, StepsContainer } from 'src/view/components/steps';

import { GhostButton } from '@rexlabs/button';
import AccountNotReadyScreen from '../shared/account-not-ready';
import AdsScreen from '../shared/ads';
import AudienceScreen from '../shared/audience';
import BudgetScreen from '../shared/budget';
import ConfirmScreen from '../shared/confirm';
import LandingPageScreen from '../shared/landing-page'; // TODO: move this to wizard/shared
import SelectAgencyAgentScreen from './select-agency-agent';
import SelectListingsScreen from './select-listings';

export const stepNames = [
  'select-contact',
  'select-multiple-listings',
  'audience',
  'ads',
  'landing-page',
  'budget',
  'launch'
];

function CreateMultiListingWorkflow({
  session,
  match,
  whereabouts,
  wizard,
  wizard: { step, setStep, setType, flush }
}) {
  const { path, hash } = whereabouts;
  const currAccount = session.accounts.find(
    (a) => a.id === session.currentAccountId
  );
  const accountReady = currAccount.ready;

  const handleExit = () => {
    track({
      event: 'Spoke campaign exited',
      properties: {
        action: 'exit_campaign_clicked',
        step_name: stepNames[step - 1].replace(/-/g, '_')
      }
    });
    push(ROUTES.CAMPAIGNS);
    flush();
  };

  useEffect(() => {
    setType({ type: 'multi_listing_sold' });
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (!step) {
      if (match.params.campaignId) {
        setStep({ step: 3 });
      } else {
        setStep({ step: 1 });
      }
    } else {
      if (step > 3 && !match.params.campaignId) {
        setStep({ step: 1 });
      }
    }
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (step === null) return;
    const page = urlToPageName(path, hash, stepNames[step - 1]);

    navigation({
      page,
      properties: {
        path,
        hash
      }
    });
  }, [step, hash, path]);

  return (
    <Box flex={1}>
      <Portal target='headerSteps'>
        <WizardScreenAnimationGroup>
          {step > 2 && step < 7 && (
            <StepsContainer>
              <StepItem
                number={1}
                active={step === 3}
                label='Review audience'
              />
              <StepItem number={2} active={step === 4} label='Review ads' />
              <StepItem number={3} active={step === 5} label='Review page' />
              <StepItem number={4} active={step === 6} label='Set a budget' />
            </StepsContainer>
          )}
        </WizardScreenAnimationGroup>
      </Portal>

      <Portal target='headerProgress'>
        <WizardScreenAnimationGroup>
          {(!step || step < 7) && (
            <HeaderProgress progress={step ? step / 7 : 0} />
          )}
        </WizardScreenAnimationGroup>
      </Portal>

      <Portal target='headerButton'>
        <WizardScreenAnimationGroup>
          <GhostButton size='s' onClick={handleExit}>
            {match.params.campaignId ? 'Save ' : 'Close '}and exit
          </GhostButton>
        </WizardScreenAnimationGroup>
      </Portal>

      <WizardScreenAnimationGroup currStep={step}>
        {step === 1 && (
          <SelectAgencyAgentScreen
            wizard={wizard}
            session={session}
            whereabouts={whereabouts}
            match={match}
          />
        )}
        {step === 2 && (
          <SelectListingsScreen
            wizard={wizard}
            session={session}
            whereabouts={whereabouts}
          />
        )}
        {step === 3 && (
          <AudienceScreen
            wizard={wizard}
            whereabouts={whereabouts}
            match={match}
          />
        )}
        {step === 4 && (
          <AdsScreen wizard={wizard} whereabouts={whereabouts} match={match} />
        )}
        {step === 5 && <LandingPageScreen wizard={wizard} match={match} />}
        {step === 6 &&
          (accountReady ? (
            <BudgetScreen wizard={wizard} match={match} />
          ) : (
            <AccountNotReadyScreen />
          ))}
        {step === 7 &&
          (accountReady ? (
            <ConfirmScreen wizard={wizard} match={match} />
          ) : (
            <AccountNotReadyScreen />
          ))}
      </WizardScreenAnimationGroup>
    </Box>
  );
}

export default compose(
  withModel(wizardModel),
  withModel(sessionModel)
)(CreateMultiListingWorkflow);

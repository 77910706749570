import { track } from '@rexlabs/analytics';
import { Box } from '@rexlabs/box';
import { withValueLists } from '@rexlabs/model-generator';
import { mapValues } from 'lodash';
import React, { ComponentType, useCallback } from 'react';
import { CampaignGoalData } from 'src/data/models/value-lists/campaign-goals';
import networkAdTypesModel from 'src/data/models/value-lists/network-ad-types';
import { LoadingSpinner } from 'src/view/components/loading';
import { LimitedCustomPackage } from '../editor';
import { Form, ValidationErrors } from './form';

const DEFAULT_DURATION = 7;

export interface LimitedBudgetEditorFormProps {
  budget?: LimitedCustomPackage;
  campaignGoal: CampaignGoalData;
  readOnly?: boolean;
  showButtons?: boolean;
  onValidation?(name: ValidationErrors, value: number);
  onChange?(budget: Partial<LimitedCustomPackage>);
  onSubmit(budget: LimitedCustomPackage);
  onCancel();
}

export function calcAllocationTotals(
  adSpread: Record<string, number>,
  total: number
) {
  return mapValues(adSpread, (part) => Math.floor(total * part));
}

const Container = ({
  readOnly = false,
  showButtons = true,
  budget,
  campaignGoal,
  valueLists,
  ...eventHandlers
}: // eslint-disable-next-line @typescript-eslint/no-explicit-any
LimitedBudgetEditorFormProps & { valueLists: any }) => {
  let initialBudget = budget;
  if (typeof initialBudget === 'undefined') {
    initialBudget = {
      total: DEFAULT_DURATION * campaignGoal.min_daily_spend,
      duration: DEFAULT_DURATION
    };
  }
  const { items: networkAdTypes, status } = valueLists.networkAdTypes;
  const calculateNetworkAllocation = useCallback(
    (total) =>
      Object.entries(calcAllocationTotals(campaignGoal.adTypes, total)).map(
        ([id, amount]) => ({
          id,
          label: networkAdTypes.find((o) => o.id === id)?.label,
          amount
        })
      ),
    [campaignGoal.adTypes, networkAdTypes]
  );
  const onValidation = useCallback(
    (name, value) => {
      track({
        event: 'Spoke campaign limited custom package validation',
        properties: {
          goal: campaignGoal.id,
          validation: name,
          value
        }
      });
      if (typeof onValidation === 'function') {
        onValidation(name, value);
      }
    },
    [campaignGoal.id]
  );

  if (status !== 'loaded') {
    return (
      <Box alignItems='center' justifyContent='center'>
        <LoadingSpinner />
      </Box>
    );
  }

  return (
    <Form
      readOnly={readOnly}
      showButtons={showButtons}
      minDailySpend={campaignGoal.min_daily_spend}
      initialBudget={initialBudget}
      calculateNetworkAllocation={calculateNetworkAllocation}
      onValidation={onValidation}
      {...eventHandlers}
    />
  );
};

export const LimitedBudgetEditorForm = withValueLists(networkAdTypesModel)(
  Container
) as unknown as ComponentType<Omit<LimitedBudgetEditorFormProps, 'valueLists'>>;

import React, { PureComponent } from 'react';
import { styled, StyleSheet } from '@rexlabs/styling';
import Box from '@rexlabs/box';
import { autobind } from 'core-decorators';
import _ from 'lodash';
import { COLORS } from 'src/theme';

import Container from '../shared/container';
import CarouselImage from '../../facebook-ads/listing/carousel-image';
import CarouselBrand from '../../facebook-ads/listing/carousel-brand';

import instagramIconChevronRight from '../../../assets/instagram/icon-chevron-right.png';
import instagramIconChevronLeft from '../../../assets/instagram/icon-chevron-left.png';

const defaultStyles = StyleSheet({
  image: {
    height: '32rem',
    width: '100%',
    background: 'none no-repeat center center',
    backgroundColor: COLORS.GREY_LIGHT,
    backgroundSize: 'cover',
    padding: '1.2rem',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    justifyContent: 'flex-end',
    position: 'relative',
    overflow: 'hidden'
  },

  wrapCarousel: {
    width: '100%',
    position: 'relative',
    overflow: 'hidden'
  },

  carousel: {
    display: 'inline-block',
    width: '400%',
    transition: 'transform .4s',
    whiteSpace: 'nowrap',
    position: 'relative'
  },

  carouselItem: {
    display: 'inline-block',
    width: '25%'
  },

  carouselPrev: {
    zIndex: 20,
    position: 'absolute',
    top: '50%',
    left: '.8rem',
    marginTop: '-1.5rem',
    width: '3rem',
    height: '3rem',
    background: `url(${instagramIconChevronLeft}) transparent no-repeat center center`,
    backgroundSize: 'contain',
    opacity: 0.8,
    transition: 'opacity .3s',
    border: '0 none',
    cursor: 'pointer',

    ':hover': {
      opacity: 1
    }
  },

  carouselNext: {
    zIndex: 20,
    position: 'absolute',
    top: '50%',
    right: '.8rem',
    marginTop: '-1.5rem',
    width: '3rem',
    height: '3rem',
    background: `url(${instagramIconChevronRight}) transparent no-repeat center center`,
    backgroundSize: 'contain',
    opacity: 0.8,
    transition: 'opacity .3s',
    border: '0 none',
    cursor: 'pointer',

    ':hover': {
      opacity: 1
    }
  },

  disabled: {
    opacity: '0 !important'
  },

  carouselDot: {
    width: '.6rem',
    height: '.6rem',
    borderRadius: '.6rem',
    background: '#e9eaeb',
    margin: '.2rem',
    transition: 'background .3s'
  },

  carouselDotActive: {
    background: '#3397f0'
  },

  carouselAgencyLogo: {
    width: '17rem',
    height: '4.8rem',
    background: 'transparent no-repeat center center',
    backgroundSize: 'contain',
    marginBottom: '2rem'
  },

  carouselAgentImage: {
    width: '8.8rem',
    height: '8.8rem',
    background: 'grey no-repeat center center',
    backgroundSize: 'cover',
    borderRadius: '8.8rem'
  },

  agentCarouselItem: {
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0
  }
});

@styled(defaultStyles)
@autobind
class InstagramCarousel extends PureComponent {
  constructor(props) {
    super(props);
    this.adContainer = React.createRef();
    this.state = {
      curr: 0,
      adWrapperDimensions: { width: 0, height: 0 }
    };
  }

  componentDidMount() {
    const parentElement = _.get(this.adContainer, 'current.parentElement');
    if (!parentElement) return;
    const width = parentElement.offsetWidth;
    const borderWidth =
      (parseFloat(getComputedStyle(parentElement).borderWidth) || 0) * 2;
    this.setState({
      adWrapperDimensions: {
        width: width - borderWidth,
        height: width - borderWidth
      }
    });
  }

  slidePrev() {
    const { curr } = this.state;
    this.setState({ curr: curr > 0 ? curr - 1 : 0 });
  }

  slideNext() {
    const { curr } = this.state;
    this.setState({ curr: curr < 2 ? curr + 1 : 2 });
  }

  getCarouselPosition() {
    return -318 * this.state.curr;
  }

  renderNav() {
    const { styles: s } = this.props;
    const { curr } = this.state;
    return (
      <Box
        flex={1}
        flexDirection='row'
        alignItems='center'
        justifyContent='center'
      >
        <Box {...s('carouselDot', { carouselDotActive: curr === 0 })} />
        <Box {...s('carouselDot', { carouselDotActive: curr === 1 })} />
        <Box {...s('carouselDot', { carouselDotActive: curr === 2 })} />
      </Box>
    );
  }

  render() {
    const { styles: s, adContentSet, disableWrapper, variation } = this.props;
    const { curr, adWrapperDimensions } = this.state;

    const primaryUrl = _.get(adContentSet, 'primaryImage.square');
    const secondaryUrl = _.get(adContentSet, 'secondaryImage.square');
    const agentImageUrl = _.get(adContentSet, 'agentImage.square');

    const descriptions = [
      adContentSet.title,
      adContentSet.headline,
      `More Information - Contact ${adContentSet.agency}`
    ];

    if (disableWrapper) {
      if (variation === 'primary') {
        return (
          <CarouselImage
            imageUrl={primaryUrl}
            adContentSet={adContentSet}
            scaleTo={1000}
          />
        );
      } else if (variation === 'secondary') {
        return (
          <CarouselImage
            imageUrl={secondaryUrl}
            adContentSet={adContentSet}
            scaleTo={1000}
          />
        );
      } else {
        return (
          <CarouselBrand
            agentImageUrl={agentImageUrl}
            adContentSet={adContentSet}
            backgroundColor={adContentSet.backgroundColor}
            scaleTo={1000}
          />
        );
      }
    }

    return (
      <Container
        {...this.props}
        Nav={this.renderNav}
        campaignDescription={descriptions[curr]}
      >
        <Box {...s('wrapCarousel')}>
          <button
            {...s('carouselPrev', { disabled: curr === 0 })}
            onClick={this.slidePrev}
            type='button'
          />
          <button
            {...s('carouselNext', { disabled: curr === 2 })}
            onClick={this.slideNext}
            type='button'
          />
          <Box
            {...s.with('carousel')({
              transform: `translateX(${this.getCarouselPosition()}px)`
            })}
          >
            <Box {...s('carouselItem')}>
              <div
                ref={this.adContainer}
                style={{ overflow: 'hidden', ...adWrapperDimensions }}
              >
                <CarouselImage
                  imageUrl={primaryUrl}
                  adContentSet={adContentSet}
                  scaleTo={adWrapperDimensions.width}
                />
              </div>
            </Box>
            <Box {...s('carouselItem')}>
              <div style={{ overflow: 'hidden', ...adWrapperDimensions }}>
                <CarouselImage
                  imageUrl={secondaryUrl}
                  adContentSet={adContentSet}
                  scaleTo={adWrapperDimensions.width}
                />
              </div>
            </Box>
            <Box {...s('carouselItem')}>
              <div style={{ overflow: 'hidden', ...adWrapperDimensions }}>
                <CarouselBrand
                  backgroundColor={adContentSet.backgroundColor}
                  agencyLogoUrl={adContentSet.agencyLogo}
                  agentImageUrl={agentImageUrl}
                  adContentSet={adContentSet}
                  scaleTo={adWrapperDimensions.width}
                />
              </div>
            </Box>
          </Box>
        </Box>
      </Container>
    );
  }
}

export default InstagramCarousel;

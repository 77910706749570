import React from 'react';
import { SingleImage } from 'src/view/components/image';

function SlideImageInput(props) {
  return (
    <SingleImage canCrop={true} canChange={false} ratio='square' {...props} />
  );
}

export default SlideImageInput;

import React, { PureComponent } from 'react';
import { styled, StyleSheet } from '@rexlabs/styling';
import { FONT } from 'src/theme';

const defaultStyles = StyleSheet({
  body: {
    position: 'relative',
    fontSize: '1.2rem',
    padding: ({ token }) => token('spacing.xxs'),
    paddingLeft: 0,
    fontWeight: FONT.WEIGHTS.SEMIBOLD,
    color: ({ token }) => token('legacy.color.red.default')
  }
});

@styled(defaultStyles)
class Error extends PureComponent {
  static defaultProps = {
    Tag: 'p'
  };

  render() {
    const { Tag, styles: s, children, grey, ...rest } = this.props;
    return (
      <Tag {...s.with('body')(this.props)} {...rest}>
        {children}
      </Tag>
    );
  }
}

export default Error;

export const AVNU_TOKENS = {
  palette: {
    brand: {
      300: '#2d3b42',
      500: '#304550',
      600: '#304550'
    }
  },

  legacy: {
    color: {
      spoke: '#304550',

      primary: {
        default: '#304550',
        light: '#2d3b42',
        opacity80: 'rgba(48, 69, 80, 0.8)'
      }
    }
  }
};

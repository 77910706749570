import { GhostButton, PrimaryButton } from '@rexlabs/button';
import { StyleSheet, margin, useStyles } from '@rexlabs/styling';
import React, { FC } from 'react';
import { useNavigate } from 'react-router-dom';
import { MegaModalContent } from 'src/components/elements/modal';
import { useModalState } from 'src/hooks';

const styles = StyleSheet({
  modal: {
    width: '500px'
  },

  description: {
    fontWeight: 'normal'
  },
  footer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    marginTop: '12px',
    '& > * + *': {
      ...margin.styles({
        left: 'xs'
      })
    }
  }
});

type MissingAgentModalProps = {
  testimonialId?: string;
};

export const MissingAgentModal: FC<MissingAgentModalProps> = ({
  testimonialId
}) => {
  const navigate = useNavigate();
  const s = useStyles(styles, 'MissingAgentModal');
  const [, setOpen] = useModalState();

  return (
    <MegaModalContent
      title='Oops, this testimonial needs an agent assigned'
      {...s('modal')}
    >
      <div>
        <p {...s('description')}>
          To continue, please edit the testimonial and select or create a new
          agent.
        </p>
      </div>
      <footer {...s('footer')}>
        <GhostButton
          onClick={() => {
            setOpen(false);
          }}
        >
          Cancel
        </GhostButton>
        <PrimaryButton onClick={() => navigate(`./${testimonialId}/edit`)}>
          Edit Testimonial
        </PrimaryButton>
      </footer>
    </MegaModalContent>
  );
};

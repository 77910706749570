import { Box } from '@rexlabs/box';
import { GhostButton, PrimaryButton } from '@rexlabs/button';
import { Portal } from '@rexlabs/portal';
import React, { MouseEventHandler, useState } from 'react';
import { useEffectOnce } from 'react-use';
import { StickyButtonGroup } from 'view/components/button';
import { FormField, LabelOptionalField } from 'view/components/form';
import HelpBox from 'view/components/help-box';
import { TextArea, TextInput } from 'view/components/input';
import ImageUpload from 'view/components/input/file-upload/image-upload';
import RepeatSuburbSelect from 'view/components/input/repeat-suburb-select';
import VideoInput from 'view/components/input/video/upload-video';

export type EditAgentFormProps = {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  images: any[];
  onSubmit: MouseEventHandler;
  onCancel: MouseEventHandler;
  isSubmitting: boolean;
  agentId: string;
  initialImages: unknown[];
  readOnlyLeadCaptureEmail: boolean;
};

const EditAgentForm = ({
  images,
  onSubmit,
  onCancel,
  isSubmitting,
  agentId,
  initialImages,
  readOnlyLeadCaptureEmail
}: EditAgentFormProps) => {
  const [loaded, setLoaded] = useState(false);

  useEffectOnce(() => setLoaded(true));

  const anyUploading = images.find((image) => image?.isUploading ?? true);

  return (
    <Box>
      <FormField
        name='full_name'
        label='Full name'
        Input={TextInput}
        inputProps={{ placeholder: 'e.g. Ryan Gosling' }}
      />
      <FormField
        name='position'
        label='Position / Title'
        Input={TextInput}
        inputProps={{
          placeholder: 'e.g. Property Expert'
        }}
      />
      <FormField
        name='slogan'
        label='Agent slogan'
        Input={TextInput}
        inputProps={{
          placeholder: 'e.g. Find an agent you can trust'
        }}
      />
      <FormField
        name='description'
        label='Agent description'
        Input={TextArea}
        inputProps={{
          placeholder:
            'e.g. Eddie Wardale has a reputation as an absolute professional within the...'
        }}
      />
      <FormField
        name='url'
        label='Agent page URL'
        Input={TextInput}
        inputProps={{ placeholder: 'e.g. http://example.com/agent-name' }}
      />
      <FormField
        name='email'
        label='Agent email'
        Input={TextInput}
        inputProps={{ placeholder: 'e.g. agent-name@example.com' }}
      />
      <FormField
        name='lead_capture_email'
        label={<LabelOptionalField text='Additional lead capture email' />}
        Input={TextInput}
        inputProps={{
          placeholder: 'e.g. 1412.17384@emaildrop.rexsoftware.com',
          readOnly: readOnlyLeadCaptureEmail,
          disabled: readOnlyLeadCaptureEmail
        }}
      />
      <FormField
        name='phone_number'
        label='Agent phone number'
        Input={TextInput}
        inputProps={{
          placeholder: '0449825342'
        }}
      />
      <RepeatSuburbSelect
        min={1}
        name='suburbs'
        buttonLabel='Add additional suburb'
        label='Suburbs'
        subLabel='Postcode or suburb name'
        placeholder='Start typing suburb name...'
      />
      <FormField
        name='images'
        label='Agent photo'
        Input={ImageUpload}
        inputProps={{
          shouldAllowMultiple: true,
          columns: 1,
          isSquare: true,
          initialImages,
          status: 'loaded',
          sortable: true,
          ratio: 'square'
        }}
        sendImmediate
      />

      <FormField
        name='video'
        label={<LabelOptionalField text='Agent Video' />}
        Input={VideoInput}
        sendImmediate
      />

      {loaded && (
        <Portal target='contentRight'>
          <HelpBox>
            {({ Title }) => (
              <div>
                <Title>Agent slogan</Title>
                <p>
                  Your slogan says a lot about you - so make sure it’s saying
                  the right things. Aim for something confident, charismatic and
                  authentic. It should also be memorable and attention-grabbing.
                  It’s a lot to ask, we know - but it will appear somewhere on
                  almost every ad. It’s got to stand out.
                </p>
              </div>
            )}
          </HelpBox>
          <HelpBox>
            {({ Title }) => (
              <div>
                <Title>Agent description</Title>
                <p>
                  41% of sellers say choosing the right agent is the most
                  stressful step in the selling process.
                  <sup>1</sup> Use your Agent Description to build your personal
                  brand and demonstrate your skills to poential sellers to make
                  you their first (and only) choice.
                </p>
                <p>
                  <sup>1</sup> Consumer Omnibus September 2017
                </p>
              </div>
            )}
          </HelpBox>
          <HelpBox>
            {({ Title }) => (
              <div>
                <Title>Agent page URL</Title>
                <p>
                  When a potential seller clicks on your ad, they’ll be directed
                  to this URL. So we can keep track of who’s clicking, make sure
                  this is your agent profile page on your website - not a third
                  party website. We need to know who’s interactng with your ads
                  to keep your campaign as effective as possible.
                </p>
              </div>
            )}
          </HelpBox>
          <HelpBox>
            {({ Title }) => (
              <div>
                <Title>Agent photo</Title>
                <p>
                  The first thing anyone who sees your ad will notice is you -
                  smile! Your profile photo must capture your personality and
                  help you appeal to a seller. For best results, use square
                  images with a resolution of at least 1200 x 1200 pixels. You
                  can also upload a short video to introduce yourself and
                  highlight your unique selling points with visuals, sounds and
                  movement. Keep it short and sweet - two minutes or less is
                  best.
                </p>
              </div>
            )}
          </HelpBox>
        </Portal>
      )}

      <StickyButtonGroup>
        <GhostButton onClick={onCancel}>Cancel</GhostButton>
        <PrimaryButton
          form='editAgentForm'
          isDisabled={!!anyUploading}
          isLoading={isSubmitting}
          onClick={onSubmit}
        >
          {agentId ? 'Update' : 'Create'}
        </PrimaryButton>
      </StickyButtonGroup>
    </Box>
  );
};

export default EditAgentForm;

import { Box } from '@rexlabs/box';
import { query, withQuery } from '@rexlabs/model-generator';
import { styled, StyleSheet } from '@rexlabs/styling';
import { match } from '@rexlabs/whereabouts';
import React, { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import { compose } from 'redux';
import Envelopes from 'src/assets/envelopes.svg';
import notificationSubscriptionsModel from 'src/data/models/entities/notification-subscriptions';
import AUTH from 'src/routes/auth';
import ROUTES from 'src/routes/notifications';
import { BREAKPOINTS, formattedBrandName, TEXTS } from 'src/theme';
import { Link } from 'src/utils/whereabouts';
import { RenderLoading } from 'src/view/components/loading';
import { Body, Heading } from 'src/view/components/text';
import withError from 'src/view/containers/with-error';
import { NotFoundScreenCore } from 'src/view/screens/not-found';

function getToken({ whereabouts }) {
  const matched = match(whereabouts, ROUTES.RESUBSCRIBE.config);
  return matched?.params?.token;
}

const notificationsQuery = query`{
  ${notificationSubscriptionsModel} (token: ${getToken}) {
    id
    label
    allowed
  }
}`;

const defaultStyles = StyleSheet({
  loadingWrapper: {
    position: 'absolute'
  },

  wrapper: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',

    [BREAKPOINTS.TABLET_LANDSCAPE_UP]: {
      flexDirection: 'row'
    }
  },

  detailsWrapper: {
    display: 'flex',
    flexDirection: 'column',
    flex: 1,
    alignItems: 'center',
    paddingLeft: '3.8rem',
    paddingRight: '3.8rem',

    [BREAKPOINTS.TABLET_LANDSCAPE_UP]: {
      alignItems: 'flex-start'
    }
  },

  maxWidth: {
    maxWidth: '53.2rem'
  },

  imageWrapper: {
    display: 'flex',
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
    paddingLeft: '3.8rem',
    paddingRight: '3.8rem',
    marginBottom: '3.2rem',
    maxHeight: '72.6rem',

    [BREAKPOINTS.TABLET_LANDSCAPE_UP]: {
      justifyContent: 'flex-end'
    }
  },

  heading: {
    ...TEXTS.HEADING,
    fontSize: '4.2rem'
  },

  envelopes: {
    position: 'static',
    width: '100%',
    maxWidth: '49rem'
  },

  linkToApp: {
    width: '100%',
    maxWidth: '53.2rem'
  }
});

function ResubscribeScreen(props) {
  const [isLoading, setIsLoading] = useState(true);
  const {
    styles: s,
    notificationSubscriptions,
    whereabouts,
    error,
    error: { Error }
  } = props;
  const type = new URLSearchParams(window.location.search).get('type');
  const notification = notificationSubscriptions.list.items.find(
    (n) => n.id === type
  );

  useEffect(() => {
    // When the page loads we want to immediately re-subscribe the user
    if (!!type && notificationSubscriptions.list.status === 'loaded') {
      notificationSubscriptions
        .updateItem({
          id: type,
          data: {
            token: getToken({ whereabouts }),
            notification_subscriptions: {
              [type]: {
                allowed: true,
                feedback_type: null,
                feedback_reason: null
              }
            }
          }
        })
        .then(() => setIsLoading(false))
        .catch(error.open);
    }
  }, [
    error.open,
    notificationSubscriptions,
    notificationSubscriptions.list.status,
    type,
    whereabouts
  ]);

  if (!type) {
    return <NotFoundScreenCore />;
  }

  return (
    <>
      <Helmet>
        <title>{formattedBrandName} - Unsubscribe</title>
      </Helmet>

      {isLoading ? (
        <Box
          h='100%'
          w='100%'
          justifyContent='center'
          alignItems='center'
          {...s('loadingWrapper')}
        >
          <RenderLoading isLoading={isLoading} />
        </Box>
      ) : (
        <Box Container='section' {...s('wrapper')}>
          <Box {...s('imageWrapper')}>
            <Envelopes {...s('envelopes')} />
          </Box>

          <Box {...s('detailsWrapper')}>
            <div {...s('maxWidth')}>
              <Heading {...s('heading')}>Hurrah! You are back.</Heading>
              <Body grey>
                You’ve successfully resubscribed to &quot;
                {notification?.label?.toLowerCase()}&quot; updates. You will
                also receive lead notifications and system emails (such as
                password reset etc.) as usual.
              </Body>
            </div>

            <Box mt='3.2rem' mb='3.2rem' {...s('linkToApp')}>
              <Link to={AUTH.LOGIN}>
                <Body>Take me to the {formattedBrandName} App →</Body>
              </Link>
            </Box>
          </Box>
        </Box>
      )}

      <Error />
    </>
  );
}

export default compose(
  styled(defaultStyles),
  withError(),
  withQuery(notificationsQuery)
)(ResubscribeScreen);

import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import InlineRectangle, { types } from './inline-rectangle';

export default class SmallSquare extends PureComponent {
  static proptypes = {
    shortHeadline: PropTypes.string,
    agentName: PropTypes.string,
    suburb: PropTypes.string,
    brandColor: PropTypes.string,
    buttonText: PropTypes.string,
    primaryImageUrls: PropTypes.object,
    logoUrls: PropTypes.object
  };

  render() {
    return <InlineRectangle {...this.props} type={types.SMALL} />;
  }
}

import { autobind } from 'core-decorators';
import React, { PureComponent } from 'react';
import { api } from 'src/utils/api-client';
import { Modal } from 'src/view/components/modal';
import ChangePasswordForm from 'src/view/forms/profile/change-password';

@autobind
class ChangePasswordModal extends PureComponent {
  handleSubmit(values) {
    const { user, closeModal } = this.props;
    return api
      .patch(`/users/${user.id}`, { password: values.new })
      .then(closeModal);
  }

  render() {
    const { closeModal, user } = this.props;
    return (
      <Modal
        title='Change password'
        subtitle={user.email}
        onClose={closeModal}
        width='45rem'
      >
        <ChangePasswordForm
          onCancel={closeModal}
          onSubmit={this.handleSubmit}
        />
      </Modal>
    );
  }
}

export default ChangePasswordModal;

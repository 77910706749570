import React, { useMemo } from 'react';
import Select from './default';

import { getAccountRegion } from 'shared/utils/region';
import { store } from 'src/store';

function getRegionalOptions() {
  const state = store.getState();

  const countryCode = getAccountRegion(state?.session);

  if (['AU', 'NZ'].includes(countryCode)) {
    return [
      { value: 'For Lease', label: 'For Lease' },
      { value: 'Just Leased', label: 'Just Leased' }
    ];
  }

  if (countryCode === 'GB') {
    return [
      { value: 'To Let', label: 'To Let' },
      { value: 'Just Let', label: 'Just Let' },
      { value: 'Let Agreed', label: 'Let Agreed' }
    ];
  }

  return [];
}

const options = [
  { value: 'Just Listed', label: 'Just Listed' },
  { value: 'For Sale', label: 'For Sale' },
  { value: 'Auction', label: 'Auction' },
  { value: 'Under Contract', label: 'Under Contract' },
  { value: 'Just Sold', label: 'Just Sold' },
  { value: 'See 3D Virtual Tour', label: 'See 3D Virtual Tour' },
  { value: 'See Video Inspection', label: 'See Video Inspection' },
  { value: 'Download High-res Images', label: 'Download High-res Images' },
  { value: 'For Rent', label: 'For Rent' },
  { value: 'Price Drop', label: 'Price Drop' }
];

const BannerSelect = ({ value, name, onChange, ...props }) => {
  const handleSelect = (selected) => {
    const fakeEvent = {
      persist: () => null,
      target: {
        type: 'select',
        name,
        id: name,
        value: selected
      }
    };
    if (onChange) {
      onChange(fakeEvent);
    }
  };

  const selectOptions = useMemo(() => {
    const regionalOptions = getRegionalOptions();

    return [...regionalOptions, ...options];
  }, []);

  return (
    <Select
      options={selectOptions}
      onSelect={handleSelect}
      isSearchable={false}
      value={value ? value.value : null}
      {...props}
    />
  );
};

export default BannerSelect;

import { Box } from '@rexlabs/box';
import { DestructiveButton } from '@rexlabs/button';
import { query, withModel, withQuery } from '@rexlabs/model-generator';
import { useToast } from '@rexlabs/notifications';
import { useToken } from '@rexlabs/styling';
import map from 'lodash/map';
import React, { useState } from 'react';
import { Helmet } from 'react-helmet';
import { compose } from 'redux';
import wizardModel from 'src/data/models/custom/wizard';
import listingsModel from 'src/data/models/entities/listings';
import { formattedBrandName } from 'src/theme';
import { serializeAddress } from 'src/utils/address';
import { createValidationRules } from 'src/utils/form';
import { addressToMapbox } from 'src/utils/mapbox';
import { Form, ReactForms } from 'src/view/components/form';
import { RenderLoading } from 'src/view/components/loading';
import { FullscreenModal } from 'src/view/components/modal';
import { Heading } from 'src/view/components/text';
import withError from 'src/view/containers/with-error';
import withToast from 'src/view/containers/with-toast';
import EditListingForm from 'src/view/forms/edit-listing';
import DeleteListingModal from 'src/view/modals/admin/delete-listing';
import { queryListings } from 'src/view/screens/wizard/listing/select';

const validate = createValidationRules({
  address: 'required',
  beds: 'required|integer',
  baths: 'required|integer',
  cars: 'required|integer',
  price: 'required',
  marketing_headline: ['required', 'marketing slogan'],
  description: ['required', 'description'],
  marketing_url: ['required|url', 'listing URL'],
  images: ['required', 'property image']
});

function getId(p) {
  return p?.match?.params?.listingId;
}

const q = query`{
  ${listingsModel} (id: ${getId}) {
    id
    is_premium
    hide_address
    category
    beds
    baths
    cars
    price
    address
    agents
    marketing_headline
    marketing_url
    description
    images
    videos
    floor_plans
    should_display_events
    events {
      type {
        id
      }
      location
      repeat_weekly
      weekly_repeats
      start_at
      end_at
    }
  }
}`;

const EditListingModal = (props) => {
  const {
    listings,
    closeModal,
    wizard: { set, slug },
    errorModal: { Error }
  } = props;
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const token = useToken();
  const { addToast } = useToast();

  const getInitialValues = () => {
    if (getId(props)) {
      const data = listings?.item?.data;
      const agents = data?.agents?.data ?? [];
      return {
        ...data,
        marketing_headline: data?.marketing_headline || '',
        description: data?.description || '',
        marketing_url: data?.marketing_url || '',
        agent: agents.length ? agents[0].id : null,
        address: addressToMapbox(data?.address),
        images: [],
        floor_plans: data?.floor_plans || null,
        video: data?.videos?.[0] || null,
        is_premium: !!data.is_premium,
        hide_address: !!data.hide_address,
        should_display_events: data?.should_display_events,
        events: data?.events?.items,
        map_pin_location: [data?.address?.longitude, data?.address?.latitude],
        category: data?.category || ''
      };
    }

    return {
      marketing_headline: '',
      description: '',
      marketing_url: '',
      agent: null,
      address: undefined,
      images: [],
      is_premium: false,
      hide_address: false,
      should_display_events: false,
      events: [],
      category: slug === 'rental-listing' ? 'residential_rental' : 'residential_sale'
    };
  };

  const handleSubmit = (values) => {
    const { listingsList, errorModal } = props;

    const isUpdate = !!getId(props);
    const action = isUpdate ? listings.updateItem : listings.createItem;
    const saleOrRental = (slug === 'rental-listing' || values.category === 'residential_rental')
      ? 'rental'
      : 'sale';

    return action({
      data: {
        sale_or_rental: saleOrRental,
        address: {
          ...serializeAddress(values.address.value),
          longitude: values.map_pin_location[0],
          latitude: values.map_pin_location[1]
        },
        is_premium: values.is_premium,
        hide_address: values.hide_address,
        category: saleOrRental === 'rental' ? 'residential_rental' : 'residential_sale',
        beds: values.beds,
        baths: values.baths,
        cars: values.cars,
        price: values.price,
        marketing_headline: values.marketing_headline,
        description: values.description,
        marketing_url: values.marketing_url,
        images: map(values.images, (image) => ({
          id: image.id,
          name: image.name
        })),
        floor_plans: map(values.floor_plans, (floorplan) => ({
          id: floorplan.id,
          name: floorplan.name
        })),
        videos: values.video ? [{ id: values.video.id }] : [],
        agents: values.agent ? [{ id: values.agent }] : [],
        should_display_events: values.should_display_events,
        ...(values.should_display_events && { events: values.events })
      }
    })
      .then(({ data }) =>
        listingsList.refreshList().then(() => {
          if (!isUpdate) {
            // Automatically select the created entity
            set({ selectedEntityId: data.id });
            addToast({
              type: 'success',
              title: 'Your listing has been successfully created'
            });
          }
          closeModal();
        })
      )
      .catch((e) => {
        errorModal.open(e);
      });
  };

  const handleDeleteListing = async ({ id }) => {
    const { listingsList, closeModal, errorModal } = props;
    if (id) {
      try {
        await listingsList.refreshList();
        addToast({
          type: 'success',
          title: 'Listing successfully deleted'
        });
        closeModal();
      } catch (e) {
        errorModal.open(e);
        setShowDeleteModal(false);
      }
    } else {
      setShowDeleteModal(false);
    }
  };

  const listingId = getId(props);

  return (
    <FullscreenModal onClose={closeModal}>
      <Box>
        <Helmet>
          <title>
            {formattedBrandName} -{' '}
            {listingId ? 'Edit Listing' : 'Create a New Listing'}
          </title>
        </Helmet>
        <Box justifyContent='space-between'>
          <Heading>
            {(listingId ? 'Edit ' : 'Create a new ') +
              `${slug === 'rental-listing' ? 'rental' : ''} listing`}
          </Heading>
          {listingId && (
            <Box mt={token('spacing.m')} mb={token('spacing.m')}>
              <DestructiveButton onClick={() => setShowDeleteModal(true)}>
                Delete
              </DestructiveButton>
            </Box>
          )}
        </Box>
        <RenderLoading
          isLoading={
            listingId &&
            (listings?.item?.status === 'loading' ||
              listings?.item?.status === 'fetching')
          }
        >
          <ReactForms
            initialValues={getInitialValues()}
            handleSubmit={handleSubmit}
            validate={validate}
          >
            {({
              values,
              submitForm,
              isSubmitting,
              setFieldValue,
              setValues,
              setFieldError
            }) => (
              <Form name='editListingForm'>
                <EditListingForm
                  listingId={listingId}
                  values={values}
                  setFieldValue={setFieldValue}
                  setValues={setValues}
                  setFieldError={setFieldError}
                  initialImages={listings?.item?.data?.images ?? []}
                  initialFloorPlans={listings?.item?.data?.floor_plans}
                  onSubmit={submitForm}
                  isSubmitting={isSubmitting}
                  onCancel={closeModal}
                  slug={slug}
                />
              </Form>
            )}
          </ReactForms>
        </RenderLoading>

        {listingId && showDeleteModal && (
          <DeleteListingModal
            listingId={listingId}
            closeModal={handleDeleteListing}
          />
        )}

        <Error />
      </Box>
    </FullscreenModal>
  );
};

export default compose(
  withToast,
  withModel(wizardModel),
  withError('errorModal'),
  withQuery(q),
  withQuery({ ...queryListings, alias: 'listingsList' })
)(EditListingModal);

import { Body } from '@rexlabs/text';
import React from 'react';

export function MaximiseLeadsGoalDescription() {
  return (
    <Body grey>
      Get up to 15 times more leads than in a standard listing campaign, whilst
      also capturing seller leads through sales listings.
    </Body>
  );
}

import {LineChart} from "view/components/charts";
import {Box} from "@rexlabs/box";
import React from "react";
import {StyleSheet, useStyles, useToken} from "@rexlabs/styling";

const defaultStyles = StyleSheet({
  graph: {
    width: '100%',
    '@media print': {
      display: 'none'
    }
  },

  // HACK: recharts fail to print if they're changing from display none
  graphPrint: {
    width: '20cm',
    visibility: 'hidden',
    position: 'absolute',

    '@media print': {
      width: '20cm',
      visibility: 'visible',
      position: 'relative',
      marginBottom: '12mm'
    }
  },
});

interface CampaignPerformanceChartProps {
  data: Array<{ clicks: number, impressions: number, x: string }>
}

export function CampaignPerformanceChart({data}: CampaignPerformanceChartProps) {
  const token = useToken();
  const s = useStyles(defaultStyles)
  return (
    <>
      <Box {...s('graph')}>
        <LineChart
          lines={[
            {
              dataKey: 'clicks',
              label: 'Link Clicks',
              color: token('palette.magenta.600')
            },
            {
              dataKey: 'impressions',
              label: 'Impressions',
              color: token('palette.indigo.600'),
              format: (value) =>
                value > 1000
                  ? `${Math.round((value / 1000) * 10) / 10}k`
                  : value
            }
          ]}
          data={data}
          width='100%'
          height={250}
          margin={{top: 0, left: -20, right: -20, bottom: 0}}
        />
      </Box>
      <Box {...s('graphPrint')}>
        <LineChart
          lines={[
            {
              dataKey: 'clicks',
              label: 'Link Clicks',
              color: token('legacy.color.stats.red')
            },
            {
              dataKey: 'impressions',
              label: 'Impressions',
              color: token('legacy.color.stats.blue'),
              format: (value) =>
                value > 1000
                  ? `${Math.round((value / 1000) * 10) / 10}k`
                  : value
            }
          ]}
          data={data}
          width='100%'
          height={250}
          margin={{top: 0, left: -20, right: -20, bottom: 0}}
        />
      </Box>
    </>
  )
}

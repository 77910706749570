import { useMutation, useQueryClient } from 'react-query';
import { useAccountId } from 'src/features/accounts';
import { MutationResult } from 'src/lib/react-query';
import { api } from 'src/utils/api-client';
import { ResourceDownload } from '../types';
import { resourceDownloadKeys } from './keys';

export type DeleteResourceDownloadParams = {
  accountId: string;
  id: string;
};

export const deleteResourceDownload = async ({
  id
}: DeleteResourceDownloadParams): Promise<void> => {
  await api.delete(`/resource-downloads/${id}`);
};

export type UseDeleteResourceDownloadVariables = Omit<
  DeleteResourceDownloadParams,
  'accountId'
>;

type Context = {
  previousValue?: ResourceDownload;
};

export const useDeleteResourceDownload = (): MutationResult<
  void,
  UseDeleteResourceDownloadVariables,
  Context
> => {
  const accountId = useAccountId();
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: (vars) => deleteResourceDownload({ accountId, ...vars }),
    onMutate: async (vars) => {
      const key = resourceDownloadKeys.detail({ accountId, ...vars });

      await queryClient.cancelQueries(key);

      const previousValue = queryClient.getQueryData<ResourceDownload>(key);

      queryClient.removeQueries(key, { exact: true });

      return { previousValue };
    },
    onError: (_e, vars, ctx) => {
      queryClient.setQueryData(
        resourceDownloadKeys.detail({ accountId, ...vars }),
        ctx?.previousValue
      );
    },
    onSettled: () => {
      queryClient.invalidateQueries(resourceDownloadKeys.lists(accountId));
    }
  });
};

import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import Box from '@rexlabs/box';

import { styled, StyleSheet } from '@rexlabs/styling';
import colorTheme from '../../../utils/color-theme';
import charLimit, { limits } from '../../../utils/char-limit';
import ScaledText from '../../scaled-text';
import { COLORS } from 'src/theme';
import _ from 'lodash';

const fontFamily = 'Lato, sans-serif';
const defaultStyles = StyleSheet({
  tripleWidescreen: {
    width: '250px',
    height: '360px',
    overflow: 'hidden',
    border: '1px solid darkgray',
    display: 'flex',
    flexDirection: 'column'
  },
  primaryImage: {
    height: '177px',
    width: '100%',
    objectFit: 'cover'
  },
  shortHeadline: {
    fontSize: '16px',
    fontFamily,
    fontWeight: 500,
    lineHeight: '18px',
    backgroundColor: '#F8F8F8',
    padding: '18px 20px 12px'
  },
  main: {
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    backgroundColor: 'white',
    padding: '14px 24px 19px'
  },
  agentName: {
    fontSize: '24px',
    fontWeight: 300,
    fontFamily,
    lineHeight: '24px'
  },
  suburb: {
    fontSize: '10px',
    fontFamily,
    fontWeight: 600,
    lineHeight: '12px',
    textTransform: 'uppercase'
  },
  logoWrapper: {
    height: '60px',
    padding: '5px 45px',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center'
  },
  logo: {
    width: '100%',
    height: '100%',
    objectFit: 'contain'
  }
});

@styled(defaultStyles)
class TripleWidescreen extends PureComponent {
  static propTypes = {
    shortHeadline: PropTypes.string,
    agentName: PropTypes.string,
    description: PropTypes.string,
    brandColor: PropTypes.string,
    primaryImageUrls: PropTypes.object,
    logoUrls: PropTypes.object
  };

  render() {
    const {
      shortHeadline,
      agentName,
      agentPosition,
      agencyName,
      brandColor,
      backgroundColor,
      primaryImageUrls,
      logoUrls,
      styles: s
    } = this.props;

    const { brandColorBorder } = colorTheme(brandColor);

    return (
      <Box {...s('tripleWidescreen')}>
        <img
          {...s('primaryImage')}
          src={_.get(primaryImageUrls, ['crop.square', 'url'])}
        />
        <span {...s.with('shortHeadline')({ color: COLORS.BLACK })}>
          {charLimit(shortHeadline, limits.SHORT_HEADLINE)}
        </span>
        <Box {...s('main')}>
          <ScaledText
            maxWidth={204}
            maxTextLines={3}
            maxFontSize={32}
            minFontSize={16}
            {...s.with('agentName')({ color: COLORS.BLACK })}
          >
            {agentName}
          </ScaledText>
          <span {...s.with('suburb')({ color: COLORS.BLACK })}>
            {agentPosition} - {agencyName}
          </span>
        </Box>
        <Box
          {...s.with('logoWrapper')({
            backgroundColor: backgroundColor,
            borderTop: brandColorBorder
          })}
        >
          <img {...s('logo')} src={_.get(logoUrls, 'original.url')} />
        </Box>
      </Box>
    );
  }
}

export default TripleWidescreen;

import React, { PureComponent } from 'react';

class Wistia extends PureComponent {
  constructor(props) {
    super(props);

    window._wq = window._wq || [];
    window._wq.push({
      id: props.id,
      options: props.options
    });

    this.embedScript = document.createElement('script');
    this.assetsScript = document.createElement('script');
    const scriptEl = document.getElementsByTagName('script')[0];

    this.embedScript.type = 'text/javascript';
    this.embedScript.async = true;
    this.embedScript.src = props.src;

    this.assetsScript.type = 'text/javascript';
    this.assetsScript.async = true;
    this.assetsScript.src = 'https://fast.wistia.com/assets/external/E-v1.js';

    scriptEl.parentNode.insertBefore(this.embedScript, scriptEl);
    scriptEl.parentNode.insertBefore(this.assetsScript, scriptEl);
  }

  componentWillUnmount() {
    this.embedScript.parentNode.removeChild(this.embedScript);
    this.assetsScript.parentNode.removeChild(this.assetsScript);
  }

  render() {
    return (
      <div className='wistia_responsive_padding'>
        <div className='wistia_responsive_wrapper'>
          <div className='wistia_embed wistia_async_s4hsy3ob4q videoFoam=true'>
            <div className='wistia_swatch' />
          </div>
        </div>
      </div>
    );
  }
}

export default Wistia;

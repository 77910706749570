import React, { PureComponent } from 'react';
import ROUTES from 'src/routes/admin';
import Sidebar from './sidebar';
import SidebarItem from './item';

class AccountSidebar extends PureComponent {
  render() {
    return (
      <Sidebar title='Account'>
        <SidebarItem route={ROUTES.PROFILE}>My Profile</SidebarItem>
      </Sidebar>
    );
  }
}

export default AccountSidebar;

import { faker } from '@faker-js/faker';
import { merge } from 'lodash';
import { libraryImageGenerator } from 'src/test/fakes/uploads';
import { PartialDeep } from 'type-fest';
import { Agent } from '../types';

export const agentGenerator = ({
  images,
  thumbnails,
  ...rest
}: PartialDeep<Agent> = {}): Agent => {
  const fake: Agent = {
    id: faker.datatype.uuid(),
    full_name: faker.name.fullName(),
    position: faker.helpers.maybe(faker.name.jobTitle),
    description: faker.helpers.maybe(faker.lorem.paragraph),
    slogan: faker.helpers.maybe(faker.lorem.sentence),
    email: faker.helpers.maybe(faker.internet.email),
    url: faker.helpers.maybe(faker.internet.url),
    lead_capture_email: faker.helpers.maybe(faker.internet.email),
    phone_number: faker.helpers.maybe(faker.phone.number),
    created_at: faker.date.past(),
    updated_at: faker.date.recent(),
    images: (images ?? [{}]).map(libraryImageGenerator),
    thumbnails: {
      ...thumbnails,
      'thumbnail.campaign': {
        height: 0,
        width: 0,
        url: faker.internet.avatar()
      }
    }
  };

  return merge({}, fake, rest);
};

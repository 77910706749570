export default function fakeEvent(type, name, id, value) {
  return {
    persist: () => null,
    target: {
      type,
      name,
      id,
      value
    }
  };
}

import { StyleSheet, useStyles } from '@rexlabs/styling';
import React, { ComponentProps, FC } from 'react';
import { TemplateRenderer } from 'src/components/elements/template-renderer';
import { scaleTemplate } from 'src/features/ads/utils/helpers';
import { AdType } from 'src/features/campaign-definitions';
import { CommonData, TemplateData } from '../types';
import { adPreviews } from '../utils/ad-previews';

const styles = StyleSheet({
  wrapAds: {
    backgroundColor: ({ token }) =>
      token('color.container.static.preview.default'),
    transition: 'margin .4s',
    verticalAlign: 'top'
  },

  wrapAd: {
    display: 'inline-block',
    verticalAlign: 'top',
    opacity: 0,
    transition: 'opacity .4s'
  },

  wrapAdCurrent: {
    opacity: 1
  },

  scaleHelper: {
    display: 'inline-flex',
    flexShrink: 0,
    transition: 'opacity .2s'
  },

  scaleInner: {
    transformOrigin: 'top center',
    display: 'inline-flex',
    flexShrink: 0
  }
});

type AdPreviewProps = ComponentProps<'div'> & {
  slidePos: number;
  adTypes: AdType[];
  commonData: CommonData;
  templateData: TemplateData;
};

export const AdPreview: FC<AdPreviewProps> = ({
  adTypes,
  commonData,
  templateData,
  slidePos,
  className,
  style,
  ...rest
}) => {
  const s = useStyles(styles);

  return (
    <div
      {...s.with('wrapAds')({
        className,
        style: {
          ...style,
          width: `${adTypes.length * 100}%`,
          marginLeft: `-${slidePos * 100}%`
        }
      })}
      {...rest}
    >
      {adTypes.map((ad, i) => {
        const preview = adPreviews[ad.format_id];

        const template = ad.templates[0];

        // Note: the width/height that we are passing in below are only used for the previews
        // and are not used in the templates (square/landscape) that get rendered on the backend
        const data = {
          ...templateData,
          width: template.width,
          height: template.height
        };
        return (
          <div
            key={`${ad?.format_id}-${i}`}
            {...s.with('wrapAd', {
              wrapAdCurrent: i === slidePos
            })({
              width: `${100 / adTypes.length}%`
            })}
          >
            <div
              style={{
                display: 'flex',
                justifyContent: 'center'
              }}
            >
              <div {...s('scaleHelper')}>
                <div {...s('scaleInner')}>
                  <preview.Preview {...commonData}>
                    <TemplateRenderer
                      adFormatId={ad.format_id}
                      template={template}
                      data={data}
                      scaleAmount={scaleTemplate(ad)}
                    />
                  </preview.Preview>
                </div>
              </div>
            </div>
          </div>
        );
      })}
    </div>
  );
};

import { CheckboxOption } from '@rexlabs/checkbox';
import { omit, property } from 'lodash';
import { DeepPartial } from 'react-hook-form';
import { Units } from 'src/types';
import {
  Address,
  Audience,
  AudienceDTO,
  AudienceDefinition,
  AudienceForm
} from '../types';
import { GENERAL_AUDIENCES, RETARGETTING_AUDIENCES } from './audienceGroups';
import { isRadiusLocation, isSuburbLocation } from './helpers';

export const getFormValuesFromAudience = ({
  locations,
  age_range,
  segments,
  user_lists,
  networks
}: Audience): DeepPartial<AudienceForm> => {
  return {
    mainLocation: locations.find(isRadiusLocation),
    additionalSuburbs: locations.filter(isSuburbLocation),
    ageRange: age_range,
    generalAudiences: segments
      .filter((v) => GENERAL_AUDIENCES.includes(v.id))
      .map(property('id')),
    retargettingAudiences: segments
      .filter((v) => RETARGETTING_AUDIENCES.includes(v.id))
      .map(property('id')),
    userLists: user_lists.map(property('id')),
    // Note: the networks object contains an 'enabled' property but for flex campaigns all we are checking for
    // is the existence of the network in the payload from the audience-spec endpoint
    networks: networks.map(property('id'))
  };
};

export const getAudienceFromFormValues = ({
  mainLocation,
  additionalSuburbs = [],
  ageRange,
  generalAudiences = [],
  retargettingAudiences = [],
  userLists,
  networks
}: DeepPartial<AudienceForm>): DeepPartial<Audience> => {
  const generalAudienceSegments = generalAudiences.map((id) => ({ id }));
  const retargettingAudienceSegments = retargettingAudiences.map((id) => ({
    id
  }));
  return {
    locations: [mainLocation, ...additionalSuburbs],
    age_range: ageRange,
    segments: [...generalAudienceSegments, ...retargettingAudienceSegments],
    user_lists: userLists?.map((id) => ({ id })),
    networks: networks?.map((id) => ({ id }))
  };
};

export const getAudienceDTOFromFormValues = ({
  mainLocation,
  additionalSuburbs = [],
  ageRange,
  generalAudiences = [],
  retargettingAudiences = [],
  userLists,
  networks
}: AudienceForm): Partial<AudienceDTO> => {
  const generalAudienceSegments = generalAudiences.map((id) => ({ id }));
  const retargettingAudienceSegments = retargettingAudiences.map((id) => ({
    id
  }));
  return {
    locations: [mainLocation, ...additionalSuburbs],
    age_range: ageRange,
    segments: [...generalAudienceSegments, ...retargettingAudienceSegments],
    user_lists: userLists?.map((id) => ({ id })),
    networks: networks?.map((id) => ({ id }))
  };
};

export const transformAudienceSpecDTO = ({
  networks,
  locations,
  languages,
  ...rest
}: Partial<AudienceDTO>): Partial<AudienceDTO> => {
  return {
    networks: networks?.map(({ id }) => ({ id })),
    locations: locations?.map((loc) => {
      const res = JSON.parse(JSON.stringify(loc));
      if (res.type === 'radius') {
        res.radius.unit = res.radius.unit.slice(0, -1) as Units;
        res.address = {
          ...omit(res.address, ['created_at', 'updated_at']),
          admin_area_1: { id: res.address.admin_area_1.id },
          country: { id: res.address.country.id }
        } as Address;
      } else if (res.type === 'suburb') {
        res.suburb = {
          ...omit(res.suburb, ['created_at', 'updated_at']),
          admin_area_1: { id: res.suburb.admin_area_1.id },
          country: { id: res.suburb.country.id }
        } as Address;
      }
      res.type = { id: loc.type };
      return res;
    }),
    ...rest
  };
};

export function audienceDefToCheckbox({
  label,
  value,
  ...rest
}: AudienceDefinition): CheckboxOption {
  return {
    label,
    value,
    props: rest
  };
}

import React from 'react';
import WarningIcon from 'src/assets/icons/error.svg';
import { WarningBanner } from './banner';

export function GoogleAdsDisabledBanner({ hide }) {
  if (hide) return null;

  return (
    <WarningBanner Icon={WarningIcon}>
      We are currently experiencing issues with campaigns on Google. We have
      therefore disabled Google ads until this issue has been resolved. In the
      meantime, your ad budget will be entirely allocated to Facebook and
      Instagram.
    </WarningBanner>
  );
}

import { StyleSheet, padding, useStyles } from '@rexlabs/styling';
import React, { CSSProperties } from 'react';
import {
  HTMLPolymorphicProps,
  forwardRef,
  poly
} from 'src/lib/polymorphic-factory';

const styles = StyleSheet({
  footer: {
    display: 'flex',
    color: ({ token }) => token('color.textStyle.body.default'),
    ...padding.styles({
      all: () => '1.8rem'
    })
  }
});

export type CardFooterProps = HTMLPolymorphicProps<'div'> & {
  justify?: CSSProperties['justifyContent'];
};

export const CardFooter = forwardRef<'div', CardFooterProps>(
  ({ justify, className, style, ...rest }, ref) => {
    const s = useStyles(styles, 'CardFooter');
    return (
      <poly.div
        ref={ref}
        {...s.with('footer')({
          className,
          style: {
            ...style,
            justifyContent: justify
          }
        })}
        {...rest}
      />
    );
  }
);

import tinycolor, { ColorInput, mostReadable } from 'tinycolor2';

export function getContrastColor(color: string): string {
  // Functionality copied from BE, so it mimiks the actual colors used in the
  // image generator
  let hexColor = tinycolor(color).toHexString();
  if (hexColor.startsWith('#')) {
    hexColor = hexColor.substr(1, 6);
  }

  const r = parseInt(hexColor.substr(0, 2), 16);
  const g = parseInt(hexColor.substr(2, 2), 16);
  const b = parseInt(hexColor.substr(4, 2), 16);

  const yiq = (r * 299 + g * 587 + b * 114) / 1000;
  return yiq >= 128 ? '#000' : '#fff';
}

export function getReadableColorFromHexString(
  hexColor: string,
  palette: string[] = []
): string | undefined {
  const color = mostReadable(hexColor, palette, {
    includeFallbackColors: true,
    level: 'AAA',
    size: 'small'
  });
  return color?.toRgbString();
}

export function isValidColor(value: ColorInput | undefined): boolean {
  return tinycolor(value).isValid();
}

import { Box } from '@rexlabs/box';
import { ButtonGroup, PrimaryButton, SecondaryButton } from '@rexlabs/button';
import {
  border,
  focus,
  padding,
  StyleSheet,
  useStyles,
  useToken
} from '@rexlabs/styling';
import { Body, Heading } from '@rexlabs/text';
import React from 'react';
import { Logo } from 'src/components/elements/logo';
import ROUTES from 'src/routes/admin';
import { Link } from 'src/utils/whereabouts';
import { push } from '@rexlabs/whereabouts';
import { useModelState } from '@rexlabs/model-generator';
import sessionModel from 'src/data/models/custom/session';

const defaultStyles = StyleSheet({
  container: {
    width: '100vw',
    height: '100vh'
  },
  content: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    height: '100%',
    marginTop: '-7rem'
  },
  topBar: {
    position: 'relative',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    width: '100%',
    height: ({ token }) => token('topNavBar.height', '7rem'),
    backgroundColor: ({ token }) =>
      token('topNavBar.background', token('color.container.static.default')),
    ...border.styles({
      target: 'topNavBar.border',
      bottom: {
        style: 'solid',
        width: 'thin',
        color: ({ token }) => token('color.border.container.static.light')
      }
    }),
    ...focus.styles({
      target: 'topNavBar.shadow',
      shadow: 'light'
    }),
    ...padding.styles({
      target: 'topNavBar.padding',
      y: 'none',
      x: 'xl'
    })
  },
  logo: {
    height: ({ token }) => token('topNavBar.logo.height', '4rem')
  }
});

function ErrorLayout({ clearError }) {
  const s = useStyles(defaultStyles);
  const token = useToken();
  const session = useModelState(sessionModel);
  const dashboardEnabled =
    session.currentAccount?.feature_flags?.includes('dashboard');
  const backBtnText = dashboardEnabled ? 'dashboard' : 'campaign manager';
  const homeRoute = dashboardEnabled ? ROUTES.DASHBOARD : ROUTES.CAMPAIGNS;

  return (
    <Box {...s('container')}>
      <Box {...s('topBar')}>
        <Link to={homeRoute}>
          <Logo {...s('logo')} onClick={() => clearError()} />
        </Link>
      </Box>
      <Box {...s('content')}>
        <Box flexDirection='column' alignItems='center' justifyContent='center'>
          <Heading>Oops, this should not happen</Heading>
          <Body>
            An error occured and we couldn’t properly recover from it. Please
            try again. If the error persists, please contact our support team.
          </Body>
          <Box flex={1} alignItems='center' justifyContent='center'>
            <ButtonGroup mt={token('spacing.xxxl')}>
              <PrimaryButton onClick={clearError}>
                Refresh current page
              </PrimaryButton>
              <SecondaryButton
                onClick={() => {
                  push(homeRoute);
                  clearError();
                }}
              >
                {`Back to ${backBtnText}`}
              </SecondaryButton>
            </ButtonGroup>
          </Box>
        </Box>
      </Box>
    </Box>
  );
}

export default ErrorLayout;

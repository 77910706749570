import { GhostButton, PrimaryButton } from '@rexlabs/button';
import { StyleSheet, useStyles } from '@rexlabs/styling';
import { Heading, Tiny } from '@rexlabs/text';
import { mapValues } from 'lodash';
import React, { FC, useCallback } from 'react';
import { Controller, SubmitHandler, useForm } from 'react-hook-form';
import {
  convertToPercentCrop,
  convertToPixelCrop,
  PercentCrop
} from 'react-image-crop';
import { ButtonGroup, Spacer } from 'src/components/elements/button-group';
import { ImageCropInput } from 'src/components/elements/image-crop-input';
import { ImageCrops } from 'src/features/uploads';
import { Runnable, UnaryFunction } from 'src/types';
import { Promisable } from 'type-fest';

const styles = StyleSheet({
  container: {
    display: 'flex',
    flexDirection: 'column'
  },
  selectors: {
    display: 'flex',
    justifyContent: 'space-around',
    width: '100%'
  },
  cropContainer: {
    display: 'flex',
    flexDirection: 'column',
    maxWidth: '48%'
  }
});

type Crops = {
  landscape: PercentCrop;
  square: PercentCrop;
};

/**
 * @var originalSize Tuple of the original image width and height
 */
export type ImageCropSelectorProps = {
  imageSrc: string;
  originalSize: [number, number];
  crops?: ImageCrops;
  onSubmit: UnaryFunction<ImageCrops, Promisable<void>>;
  onCancel: Runnable;
};

export const ImageCropSelector: FC<ImageCropSelectorProps> = ({
  imageSrc,
  crops,
  originalSize,
  onSubmit,
  onCancel
}) => {
  const s = useStyles(styles, 'ImageCropSelector');
  const percentCrops = mapValues(
    crops,
    ({ width, height, top_left: { x, y } }): PercentCrop =>
      convertToPercentCrop(
        {
          unit: 'px',
          width,
          height,
          x,
          y
        },
        ...originalSize
      )
  );
  const { control, handleSubmit } = useForm<Crops>({
    defaultValues: percentCrops
  });

  const callOnCancel = useCallback(() => onCancel(), [onCancel]);
  const transformCrops = useCallback<SubmitHandler<Crops>>(
    async (data) => {
      const imageCrops = mapValues(data, (percentCrop) => {
        const { height, width, x, y } = convertToPixelCrop(
          percentCrop,
          ...originalSize
        );
        return {
          width: Math.round(width),
          height: Math.round(height),
          top_left: { x: Math.round(x), y: Math.round(y) }
        };
      });
      await onSubmit(imageCrops);
    },
    [onSubmit, originalSize]
  );

  return (
    <div {...s('container')}>
      <Tiny grey>
        {
          "If your image includes text (we don't recommend it), it may be cropped and appear incomplete on some ad formats."
        }
      </Tiny>
      <div {...s('selectors')}>
        <div {...s('cropContainer')}>
          <Heading level={2}>Landscape</Heading>
          <Controller
            control={control}
            name='landscape'
            render={({ field }) => (
              <ImageCropInput src={imageSrc} aspect={1.91 / 1} {...field} />
            )}
          />
        </div>
        <div {...s('cropContainer')}>
          <Heading level={2}>Square</Heading>
          <Controller
            control={control}
            name='square'
            render={({ field }) => (
              <ImageCropInput src={imageSrc} aspect={1} {...field} />
            )}
          />
        </div>
      </div>
      <ButtonGroup>
        <Spacer />
        <GhostButton onClick={callOnCancel}>Cancel</GhostButton>
        <PrimaryButton onClick={handleSubmit(transformCrops)}>
          Save
        </PrimaryButton>
      </ButtonGroup>
    </div>
  );
};

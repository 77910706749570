import { Body } from '@rexlabs/text';
import React, { FC } from 'react';
import { FormGuideCard, FormGuideLayout } from 'src/features/wizard';

export const CommercialFormGuide: FC = () => {
  return (
    <FormGuideLayout>
      <FormGuideCard title='Listing type'>
        <Body as='div'>
          You&rsquo;ve seen them before when creating a new campaign - the
          listing type will specify what audience you target for your ads.
        </Body>
      </FormGuideCard>

      <FormGuideCard title='Categories'>
        <Body as='div'>
          Select your property category from the dropdown menu, or choose
          &ldquo;other&rdquo; and input your own category name (maximum of 20
          characters).
        </Body>
        <Body as='div'>
          Please note that the information displayed in your ads may vary
          depending on the category you select, as there is limited space
          available.
        </Body>
      </FormGuideCard>

      <FormGuideCard title='Marketing slogan and description'>
        <Body as='div'>
          Aim for something memorable and attention-grabbing - your slogan will
          appear on almost every ad. Bonus points for creating an emotional
          connection with potential buyers.
        </Body>
        <Body as='div'>
          We don&rsquo;t strictly enforce the character limits so type away. The
          gotcha here is that titles and descriptions may be cut off in some
          cases - especially on mobile.
        </Body>
      </FormGuideCard>

      <FormGuideCard title='Property images'>
        <Body as='div'>
          Reach needs at least one property image to create a new listing
          campaign. For best results, upload three or more landscape images with
          a resolution of at least 1200 x 628 pixels. You can always crop your
          images after uploading.
        </Body>
        <Body as='div'>
          You can drag and drop your images to rearrange the order they&rsquo;re
          shown in your ads. Make sure to put your best photo in the top left
          rectangle
        </Body>
      </FormGuideCard>

      <FormGuideCard title='Property videos'>
        <Body as='div'>Please check the video requirements below:</Body>
        <Body as='div'>
          Video length: Up to 10 minutes, but we recommend to use a 5-15 second
          video for the best results. If you want to advertise on Instagram the
          video can&rsquo;t be longer than 60 seconds.
        </Body>
        <Body as='div'>
          Supported formats: .mp4, .mov, .avi, .mkv, .ogv, .gif Resolution:
          600px minimum width
        </Body>
        <Body as='div'>File size: Up to 4 GB max</Body>
      </FormGuideCard>
    </FormGuideLayout>
  );
};

import React, { PureComponent } from 'react';

import FacebookSingleImage from './facebook-ads/appraisal/single-image';

class FacebookAd extends PureComponent {
  render() {
    const { adContentSet, disableWrapper, ad } = this.props;
    return (
      <FacebookSingleImage
        ad={ad}
        adContentSet={adContentSet}
        disableWrapper={disableWrapper}
      />
    );
  }
}

export default FacebookAd;

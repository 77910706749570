import { createContext, useContext } from 'react';
import { OnChange } from './useControllableState';

export type ModalState = readonly [boolean, OnChange<boolean>];

const modalState = createContext<ModalState | undefined>(undefined);

export const useModalState = (): ModalState => {
  const context = useContext(modalState);
  if (context === undefined)
    throw Error('You must use this hook within a Modal component');
  return context;
};

export const ModalStateProvider = modalState.Provider;

import { match, replace } from '@rexlabs/whereabouts';

export function setAccountIndex(whereabouts, session, newIndex, urlConfig) {
  const showDashboard =
    session.currentAccount?.feature_flags?.includes('dashboard');
  const home = showDashboard ? 'dashboard' : 'campaigns';

  if (session.status === 'switching') {
    // Don't do anything while the session model is working!
    return;
  }

  const matched = match(whereabouts, {
    path: '/:accountIndex([0-9]+)?/:rest(.*)'
  });

  if (matched && parseInt(matched.params.accountIndex) === parseInt(newIndex)) {
    // Invalid request, just skip it!
    return;
  }

  const isSelectAgency = match(whereabouts, {
    path: '/select-agency'
  });

  const shouldDirectToHome = isSelectAgency || !matched.params.rest;

  replace(
    {
      config: urlConfig || {
        path: `/:accountIndex([0-9]+)/${
          shouldDirectToHome ? home : matched.params.rest
        }`,
        query: whereabouts.query,
        hash: whereabouts.hash
      }
    },
    { params: { accountIndex: `${newIndex}` } }
  );
}

export function ensureAccountIndex(whereabouts, session) {
  if (!session.currentAccountId) {
    return;
  }

  if (session.accounts.length > 1 && whereabouts.query['select-agency']) {
    // when we have the query param select-agency and we want to force the display
    // of the select-agency screen by clearing the current acccount
    return session.clearAccount();
  }

  if (session.status === 'switching') {
    // Don't do anything while the session model is working!
    return;
  }

  // Get the current URL account index (if available)
  // This is the source of truth!! If this index is different from
  // the store index, we change the store index to the one in the URL
  // This means if you want to switch accounts in the application,
  // you should always do it via `setAccountIndex`, never calling
  // the sessions `switchAccount` action directly!
  const matched = match(whereabouts, {
    path: '/:accountIndex([0-9]+)/:rest(.*)'
  });

  // Get the current account index from the store
  const currIndex = session.accounts.findIndex(
    (a) => a.id === session.currentAccountId
  );

  if (!matched || matched.params.accountIndex === undefined) {
    // No account id found, so just redirect to the current account id
    setAccountIndex(whereabouts, session, currIndex);
  } else if (parseInt(matched.params.accountIndex) !== parseInt(currIndex)) {
    if (parseInt(matched.params.accountIndex) >= session.accounts.length) {
      // invalid index!
      setAccountIndex(whereabouts, session, currIndex);
    } else {
      // valid index, so switch the account to it
      session.switchAccount({
        id: session.accounts[matched.params.accountIndex].id
      });
    }
  }
}

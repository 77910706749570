import { useMemo } from 'react';
import { FileDisplayProps, PreviewData } from './types';

export type FileDisplayState<T> = FileDisplayProps<T> & {
  isLoading: boolean;
  isError: boolean;
  isComplete: boolean;
  previewData?: PreviewData;
};

export function useFileDisplayState<T, P>({
  state,
  removeFile,
  file,
  error,
  retry,
  value,
  getPreviewData,
  ...rest
}: P & FileDisplayProps<T>): P & FileDisplayState<T> {
  const previewData = useMemo(
    () => (value !== undefined ? getPreviewData(value) : undefined),
    [value, getPreviewData]
  );

  return {
    state,
    removeFile,
    file,
    error,
    retry,
    value,
    isComplete: state === 'completed',
    isLoading: state === 'uploading',
    isError: state === 'failed',
    previewData,
    ...rest
  } as P & FileDisplayState<T>;
}

import { ActionMenu, RegularActionMenuItem } from '@rexlabs/action-menu';
import { PrimaryCircleButton } from '@rexlabs/button';
import { StyleSheet, useStyles } from '@rexlabs/styling';
import React, { FC, forwardRef, useMemo } from 'react';
import QuestionIcon from 'src/assets/icons/question-mark.svg';
import config from 'src/config';

const styles = StyleSheet({
  link: {
    color: ({ token }) => token('legacy.color.blue.grey'),
    fontWeight: '500',

    ':hover': {
      color: ({ token }) => token('legacy.color.blue.dark'),
      textDecoration: 'none'
    }
  }
});

export const HelpMenu: FC = () => {
  const s = useStyles(styles, 'HelpActionMenu');
  const isAu =
    __DEV__ ||
    window.location.host.startsWith('app.dev.spokeapp') ||
    window.location.host.startsWith('app.spokeapp') ||
    window.location.host.startsWith('app.beta.spokeapp') ||
    window.location.host.endsWith('spoke.pr.rexlabs.io');
  const items = useMemo(() => {
    const items: RegularActionMenuItem[] = [
      {
        label: (
          <a
            href='https://intercom.help/spokeapp/en/'
            target='_blank'
            rel='noopener noreferrer'
            {...s('link')}
          >
            Help Center
          </a>
        )
      }
    ];

    // Australia only resources
    if (isAu) {
      items.push({
        label: (
          <a
            href='https://www.rexsoftware.com/updates/sell-the-new-listing-campaigns-to-vendors'
            target='_blank'
            rel='noopener noreferrer'
            {...s('link')}
          >
            Vendor Resources
          </a>
        )
      });

      items.push({
        label: (
          <a
            href='https://www.rexsoftware.com/series/reach-bites'
            target='_blank'
            rel='noopener noreferrer'
            {...s('link')}
          >
            Reach Bites
          </a>
        )
      });
    }

    if (config.STAGE !== 'production' || window.zE) {
      items.push({
        label: 'Contact Us',
        onClick: () => window.zE('messenger', 'open')
      });
    }

    return items;
  }, [s, isAu]);

  return (
    <ActionMenu
      Icon={QuestionIcon}
      Button={forwardRef((props, ref) => (
        <PrimaryCircleButton ref={ref} title='Help center' {...props} />
      ))}
      items={items}
    />
  );
};

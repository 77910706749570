import { Box } from '@rexlabs/box';
import { GhostButton } from '@rexlabs/button';
import { withWhereaboutsFilter } from '@rexlabs/filters';
import { query, withQuery } from '@rexlabs/model-generator';
import { autobind } from 'core-decorators';
import _ from 'lodash';
import React, { PureComponent } from 'react';
import provisionStepsFilters from 'src/data/filters/provision-steps';
import provisionStepsModel from 'src/data/models/sysadmin/provision-steps';
import provisionsModel from 'src/data/models/sysadmin/provisions';
import SYSADMIN from 'src/routes/sysadmin';
import { withToken } from 'src/theme';
import BackLink from 'src/view/components/back-link';
import { SearchInput } from 'src/view/components/filter';
import {
  List,
  ProvisionStepsListHeader,
  ProvisionStepsListItem
} from 'src/view/components/list';
import { RenderLoading } from 'src/view/components/loading';
import { Heading } from 'src/view/components/text';
import withError from 'src/view/containers/with-error';

function getQueryFilter(props) {
  const query = (props?.whereabouts?.query ?? {})['provisionSteps.search'];
  return query ? `name.match(*${query}*)` : '';
}

function getAccountId(props) {
  return props.match.params.accountId;
}

function getProvisionId(props) {
  return props.match.params.provisionId;
}

const provisionsQuery = query`{
  ${provisionsModel} (id: ${getProvisionId}, accountId: ${getAccountId}) {
    id
    status
  }
}`;

const provisionStepsQuery = query`{
  ${provisionStepsModel} (accountId: ${getAccountId}, provisionId: ${getProvisionId}, q: ${getQueryFilter}, o: "created_at.desc") {
    id
    status
    value
    type_id
    label
    description
  }
}`;

@withToken
@withError()
@withWhereaboutsFilter(provisionStepsFilters)
@withQuery(provisionsQuery)
@withQuery(provisionStepsQuery)
@autobind
class ProvisionStepsScreen extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      search: (props?.whereabouts?.query ?? {})['provisionSteps.search']
    };
  }

  debounceSetQueryFilter = _.debounce(this.setQueryFilter, 400);

  setQueryFilter(value) {
    const { filters } = this.props;
    filters.setFilters({ search: value });
  }

  handleMarkPending() {
    const {
      provisions,
      error: { open }
    } = this.props;
    provisions
      .updateItem({
        data: {
          accountId: getAccountId(this.props),
          status: 'pending'
        }
      })
      .catch(open);
  }

  handleMarkComplete() {
    const {
      provisions,
      error: { open }
    } = this.props;
    provisions
      .updateItem({
        data: {
          accountId: getAccountId(this.props),
          status: 'complete'
        }
      })
      .catch(open);
  }

  render() {
    const {
      provisions,
      provisionSteps,
      match: {
        params: { accountId, provisionId }
      },
      token
    } = this.props;

    return (
      <RenderLoading isLoading={provisions?.item?.status === 'loading'}>
        <Box flex={1} flexDirection='column' style={{ width: '100%' }}>
          <BackLink to={SYSADMIN.ACCOUNTS}>Back to accounts</BackLink>
          <Box
            flexDirection='row'
            justifyContent='space-between'
            alignItems='center'
          >
            <Heading
              style={{
                marginTop: token('spacing.xl'),
                marginBottom: token('spacing.xl')
              }}
            >
              Provisioning Steps
            </Heading>
            {provisions?.item?.data?.status === 'complete' ? (
              <GhostButton onClick={this.handleMarkPending}>
                Mark as Pending
              </GhostButton>
            ) : (
              <GhostButton onClick={this.handleMarkComplete}>
                Mark as Complete
              </GhostButton>
            )}
          </Box>

          <Box mb={token('spacing.xl')} mt={token('spacing.xs')}>
            <SearchInput
              name='searchProvisionSteps'
              placeholder='Search by name'
              onChange={({ target: { value } }) => {
                this.setState({ search: value });
                this.debounceSetQueryFilter(value);
              }}
              value={this.state.search}
              isLoading={provisionSteps.list.status === 'loading'}
            />
          </Box>

          <Box mb={token('spacing.xxxl')}>
            <List
              animateRows={false}
              items={provisionSteps.list.items}
              isLoading={provisionSteps.list.status === 'loading'}
              getItemKey={(provisionStep) => provisionStep.id}
              Header={ProvisionStepsListHeader}
              Item={({ data: provisionStep }) => (
                <ProvisionStepsListItem
                  data={provisionStep}
                  accountId={accountId}
                  provisionId={provisionId}
                  updateProvisionStep={provisionSteps.updateProvisionStep}
                  refreshList={provisionSteps.refreshList}
                />
              )}
              endReached={provisionSteps?.list?.pagination?.endReached}
              isFetching={provisionSteps.list.status === 'fetching'}
              onLoadMore={() => provisionSteps.fetchMore()}
              autoLoadMore={false}
            />
          </Box>
        </Box>
      </RenderLoading>
    );
  }
}

export default ProvisionStepsScreen;

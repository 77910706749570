import { Box } from '@rexlabs/box';
import { styled, StyleSheet } from '@rexlabs/styling';
import React, { PureComponent } from 'react';
import {
  CardCvcElement,
  CardExpiryElement,
  CardNumberElement
} from 'react-stripe-elements';
import { COLORS, withToken } from 'src/theme';
import { FieldCell, FieldRow, FormField } from 'src/view/components/form';
import { TextInput } from 'src/view/components/input';
import { Label, Small, Tiny } from 'src/view/components/text';

import { autobind } from 'core-decorators';
import Checkbox from 'src/view/components/input/checkbox';

import LockIcon from 'src/assets/icons/lock.svg';

const stripeElementStyles = {
  base: {
    fontSize: '18px',
    fontFamily: 'Circular Pro, Effra',

    '::placeholder': {
      fontSize: '18px',
      color: COLORS.BLUE_GREY_LIGHT
    }
  }
};

// NOTE: Using tokens here breaks in input fields!
const defaultStyles = StyleSheet({
  element: {
    padding: '1.1rem 1.6rem',
    backgroundColor: 'white',
    border: `1px solid ${COLORS.BLUE_GREY}`,
    borderRadius: '.3rem',
    minWidth: '4em',
    width: '100%',
    height: '4.5rem',
    color: COLORS.BLUE_DARK,

    '.StripeElement--focus': {
      borderColor: COLORS.PRIMARY
    },

    '.StripeElement--invalid': {
      borderColor: COLORS.RED,
      color: COLORS.RED
    }
  },

  label: {
    marginTop: '0.8rem'
  },

  secure: {
    color: COLORS.BLUE_GREY,

    '> svg': {
      marginRight: ({ token }) => token('spacing.xxs'),
      height: '1.5rem',
      width: 'auto'
    }
  }
});

@withToken
@styled(defaultStyles)
@autobind
class EditPaymentMethodForm extends PureComponent {
  // 'name' field error controller
  nameValidator(text) {
    const { setFieldError } = this.props;
    if (text === '') {
      setFieldError('name', 'Name is required');
    } else {
      setFieldError('name', null);
    }
  }

  render() {
    const { token, styles: s } = this.props;
    return (
      <Box>
        <Box
          flexDirection='row'
          alignItems='center'
          mb={token('spacing.m')}
          mt={token('spacing.m')}
          {...s('secure')}
        >
          <LockIcon />
          <Small grey>Your payment info is stored securely.</Small>
        </Box>
        <FieldRow>
          <FieldCell width={1 / 2}>
            <div {...s('label')} data-testid={'card-number'}>
              <Label>Card number</Label>
              <CardNumberElement
                {...s('element')}
                placeholder={'**** **** **** ****'}
                style={stripeElementStyles}
              />
            </div>
          </FieldCell>
          <FieldCell width={1 / 4}>
            <div {...s('label')} data-testid={'expiry-date'}>
              <Label>Expiration Date</Label>
              <CardExpiryElement
                {...s('element')}
                placeholder={'mm / yy'}
                style={stripeElementStyles}
              />
            </div>
          </FieldCell>
          <FieldCell width={1 / 4}>
            <div {...s('label')} data-testid={'cvc-number'}>
              <Label>Security code</Label>
              <CardCvcElement
                {...s('element')}
                placeholder={'***'}
                style={stripeElementStyles}
              />
            </div>
          </FieldCell>
        </FieldRow>
        <FieldRow>
          <FieldCell width={1}>
            <div {...s('label')} data-testid={'card-name'}>
              <FormField
                name='name'
                label="Cardholder's name"
                Input={TextInput}
                onChange={this.nameValidator}
                inputProps={{
                  placeholder: 'Name on the card'
                }}
              />
            </div>
          </FieldCell>
        </FieldRow>

        <Box mt={token('spacing.m')}>
          <FormField
            name='private'
            Input={Checkbox}
            inputProps={{
              label: (
                <>
                  <span>Private Card</span>
                  <br />
                  <Tiny grey style={{ padding: 0 }}>
                    This card will only be available to you
                  </Tiny>
                </>
              )
            }}
            subLabel='This card will only be available to you'
            sendImmediate
          />
        </Box>
      </Box>
    );
  }
}

export default EditPaymentMethodForm;

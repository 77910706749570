import React, { FC } from 'react';
import { ContentTypeSelectorProps } from 'src/features/wizard';
import { ResourceDownloadList } from './ResourceDownloadList';

export const ResourceDownloadContentTypeSelector: FC<
  ContentTypeSelectorProps
> = ({ selectedIds, onSelection }) => {
  return (
    <ResourceDownloadList selectedIds={selectedIds} onSelection={onSelection} />
  );
};

import React, { FC } from 'react';
import { ContentTypeEditorProps } from 'src/features/wizard';
import { TestimonialEditForm } from 'src/features/testimonials/components/TestimonialEditForm';
import { TestimonialCreateForm } from 'src/features/testimonials/components/TestimonialCreateForm';

export const TestimonialDetailsEditor: FC<ContentTypeEditorProps> = ({
  contentId,
  selectContent
}) => {
  return contentId ? (
    <TestimonialEditForm contentId={contentId} selectContent={selectContent} />
  ) : (
    <TestimonialCreateForm selectContent={selectContent} />
  );
};

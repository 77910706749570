import {StyleSheet, useStyles} from "@rexlabs/styling";
import {BORDER_RADIUS} from "src/theme";
import CatalogCommercialIcon from "assets/icons/catalog-commercial.svg";
import CatalogResidentialIcon from "assets/icons/catalog-residential.svg";
import CatalogApartmentsIcon from "assets/icons/catalog-apartments.svg";
import CatalogHouseIcon from "assets/icons/catalog-houses.svg";

import {Box} from "@rexlabs/box";
import React from "react";

const defaultStyles = StyleSheet({
  imageContainer: {
    display: 'grid',
    gridTemplateColumns: 'repeat(2, 1fr)',
    gridTemplateRows: 'repeat(2, 1fr)',
    width: '100%',
    height: '100%',
    background: ({token}) => token('legacy.color.grey.medium'),
    backgroundSize: 'cover',
    borderRadius: BORDER_RADIUS.INPUT,
    color: ({token}) => token('legacy.color.white'),
    overflow: 'hidden',
    gap: '2px'
  },

  image: {
    width: '100%',
    height: '100%',
    background: 'transparent no-repeat center center',
    backgroundSize: 'cover'
  },

  imagePlaceholderContainer: {
    width: '100%',
    height: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    padding: '4px',
    backgroundColor: 'rgba(18, 170, 180, 0.35)',
  },

  placeholderIcon: {
    width: '100%',
    height: '100%',
  }
});

type CatalogListItemImageProps = {
  imageUrls: string[];
}

export function CatalogListItemImage({imageUrls}: CatalogListItemImageProps) {
  const s = useStyles(defaultStyles);
  const placeholderIcons = [
    <CatalogApartmentsIcon key={'apartments-icon'} {...s('placeholderIcon')} />,
    <CatalogHouseIcon key={'house-icon'} {...s('placeholderIcon')} />,
    <CatalogResidentialIcon key={'residential-icon'} {...s('placeholderIcon')} />,
    <CatalogCommercialIcon key={'commercial-icon'} {...s('placeholderIcon')} />,
  ];
  return (
    <Box {...s('imageContainer')}>
      {Array.from({length: 4}).map((_, idx) =>
        imageUrls?.[idx] ? (
          <div key={idx}
            {...s.with('image')({
              backgroundImage: imageUrls?.[idx] ? `url(${imageUrls?.[idx]})` : 'none',
              backgroundColor: 'transparent'
            })}
          />
        ) : (
          <Box key={idx} {...s('imagePlaceholderContainer')}>
            {placeholderIcons[idx]}
          </Box>
        )
      )
      }
    </Box>
  )
}

import { Title } from '@radix-ui/react-alert-dialog';
import { Heading, HeadingProps } from '@rexlabs/text';
import React, { FC, ReactNode } from 'react';

export type AlertTitleProps = HeadingProps & {
  children?: ReactNode;
};

export const AlertTitle: FC<AlertTitleProps> = ({ children, ...rest }) => {
  return (
    <Title asChild>
      <Heading {...rest}>{children}</Heading>
    </Title>
  );
};

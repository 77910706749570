import React, { PureComponent } from 'react';
import { styled, StyleSheet } from '@rexlabs/styling';
import { TEXTS } from 'src/theme';
import isString from 'lodash/isString';

const defaultStyles = StyleSheet({
  capitalized: TEXTS.CAPITALIZED,
  white: {
    color: ({ token }) => token('legacy.color.white')
  },
  grey: {
    color: ({ token }) => token('legacy.color.blue.grey')
  }
});

@styled(defaultStyles)
class Capitalized extends PureComponent {
  static defaultProps = {
    Tag: 'span'
  };

  render() {
    const { styles: s, Tag, children, grey, white, ...rest } = this.props;
    return (
      <Tag {...s.with('capitalized', { grey, white })(this.props)} {...rest}>
        {isString(children) ? children.toUpperCase() : children}
      </Tag>
    );
  }
}

export default Capitalized;

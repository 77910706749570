import React, {
  ChangeEventHandler,
  ReactEventHandler,
  forwardRef,
  useRef,
  ForwardedRef,
  PropsWithoutRef
} from 'react';
import { border, StyleSheet, useStyles } from '@rexlabs/styling';
import uuid from 'src/utils/uuid';

const defaultStyles = StyleSheet({
  toggle: {
    position: 'relative',
    width: '4.1rem',
    userSelect: 'none',

    ':focus': {
      ...border.styles({
        target: 'checkbox.focus',
        all: {
          radius: () => '5rem'
        }
      })
    }
  },
  toggleCheckbox: {
    display: 'none'
  },
  toggleLabel: {
    display: 'block',
    overflow: 'hidden',
    cursor: 'pointer',
    borderRadius: '5rem'
  },
  toggleInner: {
    display: 'block',
    width: '100%',
    height: '2.4rem',
    transition: 'all 0.3s ease-in 0s',
    boxSizing: 'border-box',
    paddingRight: '1rem',
    backgroundColor: ({ token }) => token('toggle.background.idle'),
    padding: '0'
  },
  toggleInnerActive: {
    paddingLeft: '1rem',
    backgroundColor: ({ token }) => token('toggle.background.selected'),
    padding: '0'
  },
  toggleInnerDisabled: {
    backgroundColor: ({ token }) => token('color.input.disabled.dark')
  },
  toggleSwitch: {
    display: 'block',
    width: '2.2rem',
    margin: '0.1rem',
    backgroundColor: ({ token }) => token('toggle.color'),
    position: 'absolute',
    top: '0',
    bottom: '0',
    borderRadius: '5rem',
    transition: 'all 0.3s ease-in 0s'
  },
  toggleSwitchActive: {
    right: '0'
  },
  toggleSwitchDisabled: {
    backgroundColor: ({ token }) => token('color.input.disabled.default')
  }
});

export interface ToggleProps {
  id?: string;
  size?: 's' | 'm' | 'l';
  disabled?: boolean;
  toggled?: boolean;
  onChange?: ChangeEventHandler<HTMLInputElement>;
  onClick?: ReactEventHandler<HTMLLabelElement>;
  ref: ForwardedRef<HTMLInputElement>;
}

function Toggle({
  id: idValue,
  toggled = false,
  onChange,
  onClick,
  disabled = false,
  ref
}: ToggleProps) {
  const id = useRef(idValue ?? uuid());
  const s = useStyles(defaultStyles);

  return (
    <div {...s('toggle')}>
      <input
        {...s('toggleCheckbox')}
        ref={ref}
        disabled={disabled}
        onChange={onChange}
        checked={toggled}
        type='checkbox'
        name='toggle'
        id={id.current}
      />
      <label {...s('toggleLabel')} htmlFor={id.current} onClick={onClick}>
        <span
          {...s('toggleInner', {
            toggleInnerActive: toggled,
            toggleInnerDisabled: disabled
          })}
        />
        <span
          {...s('toggleSwitch', {
            toggleSwitchActive: toggled,
            toggleSwitchDisabled: disabled
          })}
        />
      </label>
    </div>
  );
}

export default forwardRef<HTMLInputElement, PropsWithoutRef<ToggleProps>>(
  (props, ref) => <Toggle ref={ref} {...props} />
);

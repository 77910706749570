import {
  AlertDialogPortalProps,
  AlertDialogProps,
  Overlay,
  Portal,
  Root,
  Trigger as AlertTrigger
} from '@radix-ui/react-alert-dialog';
import { StyleSheet, useStyles } from '@rexlabs/styling';
import React, { FC, ReactNode } from 'react';
import { ModalStateProvider, useControllableState } from 'src/hooks';
import { extractFirstInChildren } from 'src/utils/react';

const styles = StyleSheet({
  overlay: {
    background: ({ token }) => token('color.overlay.default'),
    position: 'fixed',
    top: 0,
    right: 0,
    bottom: 0,
    left: 0
  }
});

export type AlertProps = AlertDialogProps &
  AlertDialogPortalProps & {
    children?: ReactNode;
  };

export const Alert: FC<AlertProps> = ({
  children,
  container,
  forceMount,
  open: openValue,
  defaultOpen,
  onOpenChange,
  ...rest
}) => {
  const s = useStyles(styles);

  const state = useControllableState({
    value: openValue,
    onChange: onOpenChange,
    defaultValue: defaultOpen
  });
  const [open, setOpen] = state;

  const [Trigger, content] = extractFirstInChildren(children, AlertTrigger);

  return (
    <Root open={open} onOpenChange={(v) => setOpen(v)} {...rest}>
      {Trigger}
      <Portal container={container} forceMount={forceMount}>
        <Overlay {...s('overlay')} />
        <ModalStateProvider value={state}>{content}</ModalStateProvider>
      </Portal>
    </Root>
  );
};

import config from 'src/config';
import { uploadSchema } from 'src/features/uploads';
import {
  any,
  array,
  boolean,
  coerce,
  create,
  Date,
  Describe,
  enums,
  FloatString,
  integer,
  literal,
  LiteralType,
  max,
  min,
  nonempty,
  number,
  object,
  Optional,
  string,
  String,
  type,
  union,
  unknown,
  unknownTypeSchema,
  UUID
} from 'src/lib/superstruct';
import type {
  Address,
  Audience,
  AudienceDefinition,
  AudienceForm,
  AudienceSegment,
  AudienceSpecAttributes,
  AudienceSpecDefinition,
  CustomAudience,
  CustomAudienceDTO,
  CustomAudiences,
  KilometerRadius,
  MileRadius,
  Network,
  NetworkType,
  Radius,
  RadiusLocation,
  SuburbLocation
} from '../types';

export const audienceSegmentSchema: Describe<AudienceSegment> = object({
  id: string(),
  label: string(),
  sublabel: Optional(string()),
  tooltip: Optional(string())
});

export const addressSchema: Describe<Address> = type({
  name: Optional(string()),
  latitude: FloatString,
  longitude: FloatString,
  full_address: Optional(string()),
  unit_number: Optional(string()),
  street_number: Optional(string()),
  street_name: Optional(string()),
  address_line_2: Optional(string()),
  address_line_3: Optional(string()),
  admin_area_1: object({
    id: Optional(string()),
    label: Optional(string())
  }),
  admin_area_2: Optional(string()),
  admin_area_3: Optional(string()),
  admin_area_4: Optional(string()),
  postal_code: Optional(string()),
  country: unknownTypeSchema
});

export const kilometerRadiusSchema: Describe<KilometerRadius> = object({
  unit: coerce(literal('kilometers'), literal('kilometer'), (v) => `${v}s`),
  value: max(min(number(), config.MIN_AUDIENCE_RADIUS as number), 80)
});

export const mileRadiusSchema: Describe<MileRadius> = object({
  unit: coerce(literal('miles'), literal('mile'), (v) => `${v}s`),
  value: max(min(number(), 9), 80)
});

export const radiusSchema = union([
  kilometerRadiusSchema,
  mileRadiusSchema
]) as unknown as Describe<Radius>;

export const radiusLocationSchema: Describe<RadiusLocation> = object({
  type: LiteralType('radius'),
  radius: radiusSchema,
  address: addressSchema
});

export const suburbLocationSchema: Describe<SuburbLocation> = object({
  type: LiteralType('suburb'),
  suburb: addressSchema
});

const networkTypeSchema: Describe<NetworkType> = enums([
  'facebook',
  'instagram',
  'adwords'
]);

export const networkSchema: Describe<Network> = object({
  id: networkTypeSchema,
  label: string(),
  sublabel: Optional(string()),
  tooltip: Optional(string()),
  enabled: boolean(),
  locked: boolean()
});

export const transformNetworkResponse = (value: unknown): Network =>
  create(value, networkSchema);

export const customAudiencesSchema: Describe<CustomAudiences> = object({
  facebook_page_audience: Optional(union([string(), number()])),
  adwords_website_audience: Optional(union([string(), number()])),
  facebook_website_audience: Optional(union([string(), number()])),
  adwords_similar_website_audience: Optional(union([string(), number()])),
  facebook_page_audience_lookalike: Optional(union([string(), number()])),
  facebook_lookalike_website_audience: Optional(union([string(), number()]))
});

export const customAudienceSchema: Describe<CustomAudience> = object({
  id: UUID,
  name: String(),
  created_at: Date,
  updated_at: Date,
  results: Optional(any())
});

export const customAudienceDTOSchema: Describe<CustomAudienceDTO> = object({
  name: String(),
  source: uploadSchema
});

export const audienceSchema = object({
  id: UUID,
  networks: array(networkSchema),
  segments: array(audienceSegmentSchema),
  age_range: object({
    oldest: max(min(integer(), 18), 99),
    youngest: max(min(integer(), 18), 99)
  }),
  languages: array(string()),
  locations: array(union([radiusLocationSchema, suburbLocationSchema])),
  user_list: Optional(unknown()),
  user_lists: array(customAudienceSchema),
  custom_audiences: customAudiencesSchema,
  created_at: Date,
  updated_at: Date
});

export const transformAudienceResponse = (value: unknown): Audience =>
  create(value, audienceSchema);

export const audienceFormSchema: Describe<AudienceForm> = object({
  mainLocation: radiusLocationSchema,
  additionalSuburbs: array(suburbLocationSchema),
  ageRange: object({
    oldest: max(min(integer(), 18), 65),
    youngest: max(min(integer(), 18), 65)
  }),
  generalAudiences: nonempty(array(string())),
  retargettingAudiences: array(string()),
  userLists: array(string()),
  networks: nonempty(array(networkTypeSchema))
});

export const audienceDefinitionSchema: Describe<AudienceDefinition> = object({
  value: string(),
  label: string(),
  description: Optional(string())
});

export const audienceSpecAttributesSchema: Describe<AudienceSpecAttributes> =
  object({
    age_range: object({
      oldest: integer(),
      youngest: integer()
    }),
    languages: array(string()),
    segments: array(object({ id: string() }))
  });

export const audienceSpecDefinitionSchema: Describe<AudienceSpecDefinition> =
  object({
    id: string(),
    name: string(),
    description: string(),
    attributes: audienceSpecAttributesSchema,
    available_audiences: array(audienceDefinitionSchema),
    created_at: Date,
    updated_at: Date
  });

import { Box } from '@rexlabs/box';
import { withWhereaboutsFilter } from '@rexlabs/filters/lib/index';
import { query, withQuery } from '@rexlabs/model-generator';
import { styled, StyleSheet } from '@rexlabs/styling';
import { autobind } from 'core-decorators';
import _ from 'lodash';
import React, { Component } from 'react';
import campaignFilters from 'src/data/filters/campaign-performance';
import campaignsModel from 'src/data/models/sysadmin/campaign-performance';
import { withToken } from 'src/theme';
import { SearchInput } from 'src/view/components/filter';
import { Select } from 'src/view/components/input';
import {
  AdminCampaignListHeader,
  AdminCampaignListItem,
  List
} from 'src/view/components/list';
import { Heading } from 'src/view/components/text';

function getQueryFilter(props) {
  const query = (props?.whereabouts?.query ?? {})[
    'campaign-performance.search'
  ];
  return query || '';
}

function getStatusFilter(props) {
  const status = (props?.whereabouts?.query ?? {})[
    'campaign-performance.status'
  ];
  return status || 'all_current';
}

const campaignsQuery = query`{
  ${campaignsModel} (search: ${getQueryFilter}, filter: ${getStatusFilter}) {
    id
    performance_status
    campaign {
      id
      name
      account {
        id
        name
      }
      status
      budget
      start_date
      duration
    }
    facebook
    google
    warnings
    notes
    links
  }
}`;

@withToken
@withWhereaboutsFilter(campaignFilters)
@withQuery(campaignsQuery)
@styled(StyleSheet({}))
@autobind
class CampaignPerformanceList extends Component {
  debounceSetQueryFilter = _.debounce(this.setQueryFilter, 400);

  constructor(props) {
    super(props);

    const qFilter = getStatusFilter(props);
    const defaultFilter = {
      value: 'all_current',
      label: 'All current'
    };
    let filter;

    if (typeof qFilter !== 'undefined') {
      filter = _.find(this.filterOptions(), { value: qFilter });
    }

    this.state = {
      filter: filter || defaultFilter,
      search: (props?.whereabouts?.query ?? {})['campaign-performance.search']
    };
  }

  setQueryFilter(value) {
    const { filters } = this.props;
    filters.setFilters({ search: value });
  }

  setStatusFilter(option) {
    const { filters } = this.props;
    this.setState({
      filter: option
    });
    filters.setFilters({
      status: option.value
    });
  }

  filterOptions() {
    return [
      {
        value: 'all_current',
        label: 'All current'
      },
      {
        value: 'current_with_warnings',
        label: 'Current with warnings'
      },
      {
        value: 'current_fixing',
        label: 'Current fixing'
      },
      {
        value: 'current_ok',
        label: 'Current ok'
      },
      {
        value: 'current_ok_fixed',
        label: 'Current ok (fixed)'
      },
      {
        value: 'all_finished',
        label: 'All finished'
      },
      {
        value: 'finished_with_warnings',
        label: 'Finished with warnings'
      },
      {
        value: 'finished_fixing',
        label: 'Finished fixing'
      },
      {
        value: 'finished_ok',
        label: 'Finished ok'
      },
      {
        value: 'finished_ok_fixed',
        label: 'Finished ok (fixed)'
      }
    ];
  }

  render() {
    const {
      styles: s,
      campaignPerformance: performanceQuery,
      token
    } = this.props;

    return (
      <Box>
        <Box
          {...s('headingRow')}
          flexDirection={'row'}
          flexWrap={'nowrap'}
          mb={token('spacing.xl')}
        >
          <Box flex={1}>
            <Heading>Campaign Performance</Heading>
          </Box>

          <Box width={400} alignItems={'center'}>
            <SearchInput
              name='searchCampaign'
              placeholder='Search by campaign title or address'
              onChange={({ target: { value } }) => {
                this.setState({ search: value });
                this.debounceSetQueryFilter(value);
              }}
              value={this.state.search}
              // isLoading={performanceQuery.list.status === 'loading'}
            />
          </Box>

          <Box ml={token('spacing.xl')} alignItems={'center'}>
            <Select
              options={this.filterOptions()}
              multi={false}
              onSelect={this.setStatusFilter}
              isSearchable={false}
              selected={this.state.filter}
              ignoreCase
              ignoreAccents
            />
          </Box>
        </Box>

        <Box
          style={{
            width: '100%',
            overflowX: 'auto',
            overflowY: 'visible',
            minHeight: '50vh'
          }}
        >
          <Box style={{ width: '190rem' }}>
            <Box flex={1} flexDirection='column'>
              <Box mb={token('spacing.xxxl')}>
                <List
                  items={performanceQuery.list.items}
                  isLoading={performanceQuery.list.status === 'loading'}
                  getItemKey={(data) => data.campaign.id}
                  Header={AdminCampaignListHeader}
                  Item={({ data }) => <AdminCampaignListItem data={data} />}
                  endReached={performanceQuery?.list?.pagination?.endReached}
                  isFetching={performanceQuery.list.status === 'fetching'}
                  onLoadMore={() => performanceQuery.fetchMore()}
                  autoLoadMore={false}
                />
              </Box>
            </Box>
          </Box>
        </Box>
      </Box>
    );
  }
}

export default CampaignPerformanceList;

import { margin, styled, StyleSheet } from '@rexlabs/styling';
import React, { PureComponent } from 'react';
import { withToken } from 'src/theme';
import { GridCell, GridRow } from 'src/view/components/grid';
import { Body } from 'src/view/components/text';

const defaultStyles = StyleSheet({
  gridRow: {
    ...margin.styles({
      y: 'xxl'
    })
  }
});

@withToken
@styled(defaultStyles)
class OverviewTable extends PureComponent {
  constructor(props) {
    super(props);

    const { token, styles: s } = props;

    this.Row = ({ children, ...props }) => (
      <GridRow gutter={token('spacing.m')} {...s('gridRow')} {...props}>
        {children}
      </GridRow>
    );

    this.Label = ({ children }) => (
      <GridCell gutter={token('spacing.m')} width={1 / 4}>
        <Body grey Tag='span'>
          {children}
        </Body>
      </GridCell>
    );

    this.Cell = ({ ...props }) => (
      <GridCell gutter={token('spacing.m')} width={3 / 4} {...props} />
    );

    this.Container = ({ children }) => <div>{children}</div>;
  }

  render() {
    const { Container, Row, Label, Cell } = this;
    const render = this.props.children({
      Container,
      Row,
      Label,
      Cell
    });
    return render;
  }
}

export default OverviewTable;

import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import Box from '@rexlabs/box';

import { styled, StyleSheet } from '@rexlabs/styling';
import colorTheme from '../../../utils/color-theme';
import ScaledText from '../../scaled-text';
import charLimit, { limits } from '../../../utils/char-limit';
import { COLORS } from 'src/theme';
import _ from 'lodash';

const fontFamily = 'Lato, sans-serif';
const defaultStyles = StyleSheet({
  billboard: {
    width: 970,
    height: 250,
    display: 'flex',
    overflow: 'hidden',
    border: '1px solid darkgray'
  },
  primaryImage: {
    width: 247,
    height: '100%',
    objectFit: 'cover'
  },
  shortHeadlineWrapper: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    backgroundColor: '#F8F8F8',
    padding: '0 40px',
    width: 230
  },
  shortHeadline: {
    fontFamily,
    fontSize: 24,
    fontWeight: 500,
    lineHeight: '24px'
  },
  agentDetails: {
    display: 'flex',
    flex: 1,
    flexDirection: 'column',
    justifyContent: 'center',
    backgroundColor: 'white',
    padding: '0 40px'
  },
  agentName: {
    fontFamily,
    fontWeight: 300
  },
  suburb: {
    fontFamily,
    fontWeight: 600,
    fontSize: 12,
    lineHeight: '15px',
    textTransform: 'uppercase',
    marginTop: 5
  },
  logoWrapper: {
    width: 180,
    height: '100%',
    padding: '0 11px',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center'
  },
  logo: {
    maxWidth: 'calc(0.75 * 180px)',
    maxHeight: 'calc(0.4 * 250px)',
    objectFit: 'contain'
  }
});

@styled(defaultStyles)
class Billboard extends PureComponent {
  static propTypes = {
    shortHeadline: PropTypes.string,
    agentName: PropTypes.string,
    suburb: PropTypes.string,
    brandColor: PropTypes.string,
    logoUrls: PropTypes.object,
    primaryImageUrls: PropTypes.object
  };

  render() {
    const {
      shortHeadline,
      agentName,
      agentPosition,
      agencyName,
      brandColor,
      backgroundColor,
      logoUrls,
      primaryImageUrls,
      styles: s
    } = this.props;

    const { brandColorBorder } = colorTheme(brandColor);

    return (
      <Box {...s('billboard')}>
        <img
          {...s('primaryImage')}
          src={_.get(primaryImageUrls, ['crop.square', 'url'])}
        />
        <Box {...s('shortHeadlineWrapper')}>
          <span {...s.with('shortHeadline')({ color: COLORS.BLACK })}>
            {charLimit(shortHeadline, limits.SHORT_HEADLINE)}
          </span>
        </Box>
        <Box {...s('agentDetails')}>
          <ScaledText
            maxWidth={230}
            maxTextLines={2}
            maxFontSize={30}
            minFontSize={18}
            {...s.with('agentName')({ color: COLORS.BLACK })}
          >
            {agentName}
          </ScaledText>
          <span {...s.with('suburb')({ color: COLORS.BLACK })}>
            {agentPosition} - {agencyName}
          </span>
        </Box>
        <Box
          {...s.with('logoWrapper')({
            backgroundColor: backgroundColor,
            borderLeft: brandColorBorder
          })}
        >
          <img {...s('logo')} src={_.get(logoUrls, 'original.url')} />
        </Box>
      </Box>
    );
  }
}

export default Billboard;

import { Body } from '@rexlabs/text';
import React, { FC } from 'react';
import { FormGuideCard, FormGuideLayout } from 'src/features/wizard';

export const TestimonialFormGuide: FC = () => {
  return (
    <FormGuideLayout>
      <FormGuideCard title='Author'>
        <Body as='div'>
          To create a more relatable ad for potential prospects we recommend
          including the authors first name, what capacity they left it in
          (buyer/seller etc), and the rough location. For example; John - Buyer,
          Suburb.
        </Body>
        <Body as='div'>
          Always ensure you have permission of the author to use their
          testimonial for marketing purposes.
        </Body>
      </FormGuideCard>

      <FormGuideCard title='Testimonial text and excerpt'>
        <Body as='div'>
          To ensure your testimonial works well across a range of ad-types and
          sizes, we need both the full text as-well-as an excerpt: a short
          extract from the main text.
        </Body>
        <Body as='div'>
          Don’t forget to use ellipsis [...] to indicate if either your full
          text or excerpt omits part of the complete testimonial left by the
          author.
        </Body>
        <Body as='div'>
          The full testimonial text is displayed on the landing page
        </Body>
      </FormGuideCard>
    </FormGuideLayout>
  );
};

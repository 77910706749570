import {
  FormTemplateRenderProps,
  useFormApi
} from '@data-driven-forms/react-form-renderer';
import { GhostButton, PrimaryButton } from '@rexlabs/button';
import { StyleSheet, margin, useStyles } from '@rexlabs/styling';
import React, { FC, useCallback, useEffect, useState } from 'react';
import { ButtonGroup, Spacer } from 'src/components/elements/button-group';

const styles = StyleSheet({
  form: {
    '& > * + *': {
      ...margin.styles({
        top: 's'
      })
    }
  },

  buttons: {
    width: '100%'
  }
});

export type WizardFormTemplateProps = FormTemplateRenderProps & {
  onValuesChange: (data: Record<string, unknown>) => void;
  onDirty?: (pristine: boolean) => void;
};

export const WizardFormTemplate: FC<WizardFormTemplateProps> = ({
  formFields,
  onValuesChange,
  onDirty
}) => {
  const s = useStyles(styles, 'AdsEditorFormTemplate');
  const { handleSubmit, subscribe, onCancel, getState, reset } = useFormApi();
  const [isInvalid, setIsInvalid] = useState(true);
  const [isSubmitting, setIsSubmitting] = useState(false);

  const handleCancel = useCallback(
    (e) => {
      onCancel?.(getState(), e);
    },
    [getState, onCancel]
  );

  useEffect(() => {
    return subscribe(
      ({ values, invalid, submitting, submitSucceeded, dirty }) => {
        setIsInvalid(invalid);
        setIsSubmitting(submitting);
        onValuesChange(values);
        onDirty?.(dirty);
        if (submitSucceeded) reset();
      },
      {
        values: true,
        invalid: true,
        submitting: true,
        touched: true,
        dirty: true,
        submitSucceeded: true
      }
    );
  }, [onValuesChange, subscribe, reset, onDirty]);

  return (
    <form {...s('form')} noValidate>
      <>{formFields}</>

      <ButtonGroup sticky {...s('buttons')} spacing='s'>
        <Spacer />
        <GhostButton onClick={handleCancel}>Cancel</GhostButton>
        <PrimaryButton
          isDisabled={isInvalid}
          isLoading={isSubmitting}
          onClick={handleSubmit}
          type='submit'
        >
          Save
        </PrimaryButton>
      </ButtonGroup>
    </form>
  );
};

import { Action } from '@radix-ui/react-alert-dialog';
import { ButtonProps, DestructiveButton, PrimaryButton } from '@rexlabs/button';
import React, { FC, ReactNode } from 'react';

export type AlertActionProps = ButtonProps & {
  destructive?: boolean;
  children?: ReactNode;
};

export const AlertAction: FC<AlertActionProps> = ({
  destructive = false,
  children = 'Confirm',
  ...rest
}) => {
  const Button = destructive ? DestructiveButton : PrimaryButton;

  return (
    <Action asChild>
      <Button {...rest}>{children}</Button>
    </Action>
  );
};

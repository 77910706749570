import {StyleSheet, border, padding, useStyles} from '@rexlabs/styling';
import { partial } from 'lodash';
import React, {
  ComponentProps,
  ComponentType,
  FC,
  useCallback,
  useMemo,
  useState
} from 'react';
import { ListItemProps } from 'src/components/elements/listbox';
import { Consumer } from 'src/types';
import {CatalogListingSet} from '../types';
import {
  CatalogProvideDetailsStepWarningModal
} from "src/features/catalog-listing-sets/components/modals/CatalogProvideDetailsStepWarningModal";
import {useCatalogListingSets} from "src/features/catalog-listing-sets/api/getCatalogListingSets";

const styles = StyleSheet({
    list: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'stretch',
        overflowY: 'auto',
        width: '100%',

        '> * + *': {
            ...border.styles({
                top: {
                    width: 'thin',
                    color: 'container.static.medium'
                },
                bottom: {
                    width: 'none'
                }
            })
        },

        '> *': {
            ...padding.styles({
                y: 'xs',
                x: 'm'
            })
        }
    }
});

export type ItemProps = Pick<ListItemProps, 'posinset' | 'selected'> & {
    item: CatalogListingSet;
    onChange: Consumer<boolean>;
    isRefetching: boolean;
    displayWarning: () => void;
};

export type CatalogListingSetListProps = Omit<
    ComponentProps<'div'>,
    'children' | 'role' | 'aria-setsize'
> & {
    items: CatalogListingSet[];
    Item: ComponentType<ItemProps>;
    totalItems: number;
    selected: string[];
    onSelection: Consumer<string[]>;
};

export const CatalogListingSetList: FC<CatalogListingSetListProps> = ({
  items,
  totalItems,
  Item,
  selected,
  onSelection,
  className,
  style,
  ...rest
}) => {
    const s = useStyles(styles, 'CatalogListingSetList');
    const [showWarning, setShowWarning] = useState(false);

    const selectionSet = useMemo(() => new Set(selected), [selected]);
    const fireChange = useCallback(() => {
        onSelection?.(Array.from(selectionSet.values()));
    }, [onSelection, selectionSet]);

    const updateSelection = useCallback((state: boolean, id: string) => {
        if (state) {
            selectionSet.add(id);
        } else {
            selectionSet.delete(id);
        }
        fireChange();
    }, [fireChange, selectionSet]);

    const {isRefetching} = useCatalogListingSets()

    return (
        <div
            {...s.with('list')({ className, style })}
            {...rest}
            role='listbox'
            aria-setsize={totalItems}
        >
            {items.map((item, i) => (
                <Item
                    key={item.id}
                    item={item}
                    onChange={partial(updateSelection, partial.placeholder, item.id)}
                    posinset={i}
                    selected={selectionSet.has(item.id)}
                    isRefetching={isRefetching}
                    displayWarning={() => setShowWarning(true)}
                />
            ))}
          <CatalogProvideDetailsStepWarningModal open={showWarning} close={() => setShowWarning(false)}/>
        </div>
    );
};

import { StyleSheet, padding, useStyles } from '@rexlabs/styling';
import React from 'react';
import {
  HTMLPolymorphicProps,
  forwardRef,
  poly
} from 'src/lib/polymorphic-factory';

const styles = StyleSheet({
  body: {
    color: ({ token }) => token('color.textStyle.body.default'),
    ...padding.styles({
      all: () => '1.8rem'
    })
  }
});

export type CardBodyProps = HTMLPolymorphicProps<'div'>;

export const CardBody = forwardRef<'div', CardBodyProps>(
  ({ className, style, ...rest }, ref) => {
    const s = useStyles(styles, 'CardBody');
    return (
      <poly.div ref={ref} {...s.with('body')({ className, style })} {...rest} />
    );
  }
);

import { Box } from '@rexlabs/box';
import { Portal, PortalTarget } from '@rexlabs/portal';
import { styled, StyleSheet } from '@rexlabs/styling';
import React from 'react';

import CrossIcon from 'src/assets/icons/cross.svg';

const WIDTH_RIGHT_CONTENT = 0.5;

const defaultStyles = StyleSheet({
  container: {
    position: 'absolute',
    zIndex: 200,
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    background: ({ token }) => token('legacy.color.white'),
    overflowX: 'hidden'
  },

  inner: {
    overflow: 'auto',
    width: '100%',
    maxHeight: '100%',
    minHeight: '100%',

    '&::before': {
      content: "' '",
      position: 'absolute',
      // width: `${102.4 * WIDTH_RIGHT_CONTENT}rem`,
      width: '51.2rem',
      height: '100%',
      top: 0,
      right: '50%',
      marginRight: '-51.2rem',
      zIndex: 1,
      background: ({ token }) => token('legacy.color.grey.light')
    },

    '&::after': {
      content: "' '",
      position: 'absolute',
      width: '50%',
      height: '100%',
      top: 0,
      right: '-51.2rem',
      zIndex: -1,
      background: ({ token }) => token('legacy.color.grey.light')
    },

    '::-webkit-scrollbar': {
      WebkitAppearance: 'none',
      width: '0.7rem'
    },

    '::-webkit-scrollbar-thumb': {
      borderRadius: '0.4rem',
      backgroundColor: ({ token }) => token('legacy.color.blue.greyLight'),
      WebkitBoxShadow: '0 0 1px rgba(255,255,255,.5)',

      '&:hover': {
        backgroundColor: ({ token }) => token('legacy.color.blue.grey')
      }
    }
  },

  wrapContent: {
    width: '100%',
    maxWidth: '102.4rem',
    position: 'relative',
    zIndex: 2
  },

  wrapLeft: {
    background: ({ token }) => token('legacy.color.white'),
    width: '60%',
    minHeight: '100%',
    padding: ({ token }) => token('spacing.xl'),
    paddingTop: ({ token }) => token('spacing.xxxl'),
    paddingRight: ({ token }) => token('spacing.xxxl')
  },

  wrapRight: {
    background: ({ token }) => token('legacy.color.grey.light'),
    width: `${100 * WIDTH_RIGHT_CONTENT}%`,
    padding: ({ token }) => token('spacing.xl'),
    paddingTop: ({ token }) => token('spacing.xxxl'),

    // NOTE: this is to set styles on the div within PortalTarget
    '> div': {
      height: '100%'
    }
  },

  closeButton: {
    position: 'absolute',
    top: ({ token }) => token('spacing.xl'),
    right: ({ token }) => token('spacing.xxl'),
    zIndex: 3,
    border: '0 none',
    background: 'transparent',
    color: ({ token }) => token('legacy.color.blue.grey'),
    cursor: 'pointer',
    transition: 'color .2s',

    '> svg': {
      width: '2.4rem',
      height: 'auto'
    },

    ':hover': {
      color: ({ token }) => token('legacy.color.blue.dark')
    }
  }
});

const FullscreenModal = ({
  children,
  onClose,
  styles: s,
  target = 'fullscreenModals'
}) => {
  return (
    <Portal target={target}>
      <div {...s('container')}>
        <button {...s('closeButton')} onClick={onClose}>
          <CrossIcon />
        </button>
        <Box {...s('inner')}>
          <Box flex={1} justifyContent='center' style={{ height: '100%' }}>
            <Box flexDirection='row' {...s('wrapContent')}>
              <Box {...s('wrapLeft')}>{children}</Box>
              <Box {...s('wrapRight')}>
                <PortalTarget name='contentRight' />
              </Box>
            </Box>
          </Box>
        </Box>
      </div>
    </Portal>
  );
};

export default styled(defaultStyles)(FullscreenModal);

/* eslint-disable max-lines */
import { Box } from '@rexlabs/box';
import { styled, StyleSheet } from '@rexlabs/styling';
import { autobind } from 'core-decorators';
import React, { PureComponent } from 'react';
import { withToken } from 'src/theme';
import { number as formatNumber } from 'src/utils/format';

const sliderThumbStyles = {
  marginTop: '-0.4rem',
  width: '1.6rem',
  height: '1.6rem',
  border: 'none',
  borderRadius: '50%',
  background: ({ token }) => token('color.primary.idle.default'),
  boxShadow: ({ token }) =>
    `0 5px 10px -2px ${token('color.primary.idle.contrast')}`,

  '&:hover': {
    boxShadow: ({ token }) =>
      `0 0 20px 5px ${token('color.primary.hover.contrast')}`
  }
};

const defaultStyles = StyleSheet({
  sliderWrapper: {
    marginBottom: ({ token }) => token('spacing.xl'),

    '& input': {
      width: '100%',
      flex: '0 0 auto',
      marginTop: ({ token }) => token('spacing.xs')
    },

    '> span': {
      color: ({ token }) => token('legacy.color.blue.grey'),
      fontSize: '1.3rem'
    }
  },

  slider: {
    WebkitAppearance: 'none !important',
    '--range': 'calc(var(--max) - var(--min))',
    '--ratio': 'calc((var(--val) - var(--min))/var(--range))',
    '--sx': 'calc(.5*1.5em + var(--ratio)*(100% - 1.5em))',
    height: '0.6rem',
    borderRadius: '0.4rem',
    paddingTop: '1.4rem',
    paddingBottom: '1.4rem',
    boxShadow: 'none',
    background: 'transparent',
    cursor: 'pointer',

    '&::-webkit-slider-runnable-track': {
      height: '0.6rem',
      borderRadius: '0.4rem',
      boxShadow: 'inset 0 1px 3px 0 rgba(0,0,0,0.10)',
      background: ({ token }) =>
        `linear-gradient(${token('color.primary.idle.default')}, ` +
        `${token('color.primary.idle.default')}) 0/ var(--sx) 100% ` +
        `no-repeat #DDE6EC`
    },

    '&::-moz-range-track': {
      height: '0.6rem',
      borderRadius: '0.4rem',
      boxShadow: 'inset 0 1px 3px 0 rgba(0,0,0,0.10)',
      background: '#DDE6EC'
    },

    '&::-moz-range-progress': {
      height: '0.6rem',
      borderRadius: '0.4rem',
      background: ({ token }) => token('color.primary.idle.default')
    },

    '&::-ms-track': {
      height: '0.6rem',
      borderRadius: '0.4rem',
      boxShadow: 'inset 0 1px 3px 0 rgba(0,0,0,0.10)',
      background: '#DDE6EC'
    },

    '&::-webkit-slider-thumb': {
      WebkitAppearance: 'none !important',
      ...sliderThumbStyles
    },

    '&::-moz-range-thumb': {
      ...sliderThumbStyles
    },

    '&::-ms-thumb': {
      ...sliderThumbStyles
    },

    '&::-ms-tooltip': {
      display: 'none'
    },

    '&:focus': {
      outline: 'none',

      '&::-webkit-slider-thumb': {
        boxShadow: ({ token }) =>
          `0 0 20px 5px ${token('color.primary.active.contrast')}`
      },

      '&::-moz-range-thumb': {
        boxShadow: ({ token }) =>
          `0 0 20px 5px ${token('color.primary.active.contrast')}`
      },

      '&::-ms-thumb': {
        boxShadow: ({ token }) =>
          `0 0 20px 5px ${token('color.primary.active.contrast')}`
      }
    }
  }
});

@withToken
@styled(defaultStyles)
@autobind
class RangeInput extends PureComponent {
  state = {
    sliderVal: this.props.value
  };

  fakeEvent(value) {
    const { name } = this.props;
    return {
      persist: () => null,
      target: {
        type: 'string',
        name,
        id: name,
        value
      }
    };
  }

  handleSliderChange(e) {
    const { onChange } = this.props;

    onChange(this.fakeEvent(e.target.value));
    this.setState({ sliderVal: e.target.value });
  }

  render() {
    const {
      min,
      max,
      prefix,
      suffix,
      isDisabled,
      readOnly,
      styles: s
    } = this.props;
    const { sliderVal } = this.state;

    return (
      <Box
        flexDirection='row'
        flexWrap='wrap'
        justifyContent='space-between'
        {...s('sliderWrapper')}
      >
        <input
          type='range'
          value={sliderVal}
          min={min}
          max={max}
          step={1}
          onChange={this.handleSliderChange}
          {...s('slider', {
            sliderDisabled: isDisabled,
            sliderReadOnly: readOnly
          })}
          style={{ '--min': min, '--max': max, '--val': sliderVal }}
          disabled={isDisabled || readOnly}
        />
        {!isDisabled && (
          <>
            <span>
              {prefix}
              {formatNumber(min)} {suffix}
            </span>
            <span>
              {prefix}
              {formatNumber(max)} {suffix}
            </span>
          </>
        )}
      </Box>
    );
  }
}

export default RangeInput;

import _ from 'lodash';
import { createRestAPIModelGenerator } from 'src/data/models/generator';
import { api } from 'src/utils/api-client';

export const TYPE = 'multipart-uploads';
export const MESSAGE_CANCELLED = 'cancelled';

async function uploadChunk(multipartUploadId, chunkNumber, chunk) {
  const formData = new FormData();
  formData.append('file', chunk);

  return api.post(
    `/multipart-uploads/${multipartUploadId}/chunks/${chunkNumber}`,
    formData,
    {
      headers: { 'Content-Type': 'multipart/form-data' }
    }
  );
}

const actionCreators = {
  uploadChunks: {
    request: async ({
      multipartUploadId,
      file,
      chunkSize,
      onChunk = _.noop,
      onComplete = _.noop
    }) => {
      let offset = 0;
      let chunkNumber = 1;

      do {
        if (onChunk(chunkNumber, offset) === false) {
          // eslint-disable-next-line no-throw-literal
          throw {
            problem: 'CANCEL_ERROR'
          };
        }

        await uploadChunk(
          multipartUploadId,
          chunkNumber,
          file.slice(offset, offset + chunkSize)
        );

        offset += chunkSize;
        chunkNumber++;
      } while (offset < file.size);

      onComplete();
    },
    reduce: {
      initial: _.identity,
      success: _.identity,
      failure: _.identity
    }
  }
};

const MultipartUploadsGenerator = createRestAPIModelGenerator(TYPE);
export default MultipartUploadsGenerator.createEntityModel({ actionCreators });

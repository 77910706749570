import React from 'react';
import { Tiny } from 'src/view/components/text';

export interface LabelOptionalFieldProps {
  text: string;
}

export type Props = React.PropsWithChildren<LabelOptionalFieldProps>;

export default function LabelOptionalField({ text, children }: Props) {
  return (
    <span>
      {text}{' '}
      <Tiny
        Tag={(props) => <p {...props} style={{ display: 'inline' }} />}
        grey
      >
        (optional)
      </Tiny>
      {children}
    </span>
  );
}

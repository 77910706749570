import { omit } from 'lodash';
import { Image, imageSchema, uploadSchema } from 'src/features/uploads';
import { create, Date, Describe, string, type } from 'src/lib/superstruct';
import { EditableResourceDownload, ResourceDownload } from '../types';

export const resourceDownloadSchema: Describe<ResourceDownload> = type({
  id: string(),
  title: string(),
  description: string(),
  short_headline: string(),
  long_headline: string(),
  created_at: Date,
  updated_at: Date,
  upload: uploadSchema,
  image: imageSchema
});

type RawResourceDownload = Omit<ResourceDownload, 'image'> & {
  images: {
    data: Image[];
  };
};

export const createResourceDownload = (value: unknown): ResourceDownload => {
  const raw = value as RawResourceDownload;
  const rd: ResourceDownload = {
    ...omit(raw, 'images'),
    image: raw.images.data[0]
  };
  return create(rd, resourceDownloadSchema);
};

export const editableResourceDownloadSchema: Describe<EditableResourceDownload> =
  type({
    title: string(),
    description: string(),
    short_headline: string(),
    long_headline: string(),
    upload: uploadSchema,
    image: imageSchema
  });

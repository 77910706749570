import { navigation } from '@rexlabs/analytics';
import {
  RenderMatchedRoutes,
  withWhereabouts
} from '@rexlabs/react-whereabouts';
import React, { useEffect } from 'react';
import { urlToPageName } from 'src/utils/routing';

function Router({ children, whereabouts, ...rest }) {
  const { path, hash } = whereabouts;

  useEffect(() => {
    const page = urlToPageName(path, hash);

    navigation({
      page,
      properties: {
        path,
        hash
      }
    });
  }, [path, hash]);

  return <RenderMatchedRoutes {...rest}>{children}</RenderMatchedRoutes>;
}

export default withWhereabouts(Router);

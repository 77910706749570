import {
  Date,
  Describe,
  object,
  record,
  string,
  unknown,
  unknownTypeSchema,
  URL,
  UUID
} from 'src/lib/superstruct';
import { AdContentSet } from '../types';

export const adContentSetSchema: Describe<AdContentSet> = object({
  id: UUID,
  type: unknownTypeSchema,
  attributes: record(string(), unknown()),
  final_url: URL,
  created_at: Date,
  updated_at: Date
});

import { padding, styled, StyleSheet } from '@rexlabs/styling';
import { TooltipStateful } from '@rexlabs/tooltip';
import { autobind } from 'core-decorators';
import React, { PureComponent } from 'react';
import { BORDER_RADIUS, FONT, withToken } from 'src/theme';
import { LoadingSpinner } from 'src/view/components/loading';

const defaultStyles = StyleSheet({
  container: {
    whiteSpace: 'nowrap',
    fontWeight: FONT.WEIGHTS.NORMAL,
    borderRadius: BORDER_RADIUS.INPUT,
    cursor: 'pointer',
    position: 'relative',
    height: '4.4rem',
    display: 'inline-flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: 'auto',
    flexShrink: 0,
    border: '0 none',
    transition: 'background .2s, opacity .3s',
    fontSize: '1.8rem',
    ...padding.styles({
      x: 'xl'
    }),

    ':hover': {
      opacity: 0.9
    }
  },

  containerWithLeftIcon: {
    paddingLeft: '1.1rem',
    paddingRight: '2.2rem'
  },

  containerWithRightIcon: {
    paddingRight: '1.1rem',
    paddingLeft: '2.2rem'
  },

  disabled: {
    cursor: 'not-allowed',
    opacity: 0.5,
    ':hover': {
      opacity: 0.5
    }
  },

  icon: {
    transition: 'opacity .2s',
    display: 'inline-flex',
    width: '2.3rem',
    height: '2.3rem',
    justifyContent: 'center',
    alignItems: 'center',

    '> svg': {
      width: 'auto'
    }
  },

  iconLoading: {
    opacity: 0
  },

  iconLeft: {
    marginRight: ({ token }) => token('spacing.xxs')
  },

  iconRight: {
    marginLeft: ({ token }) => token('spacing.xxs')
  },

  loading: {
    position: 'absolute',
    top: 0,
    right: 0,
    left: 0,
    bottom: 0,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    zIndex: 1,
    paddingTop: 2
  },

  animationGroup: {
    width: '100%',
    height: '100%',
    display: 'flex'
  },

  label: {
    transition: 'opacity .2s'
  },

  labelLoading: {
    opacity: 0
  }
});

const tooltipStyles = StyleSheet({
  tooltip: {
    fontWeight: `${FONT.WEIGHTS.LIGHT} !important`,
    fontSize: '1.4rem !important',
    lineHeight: '1.8rem !important',
    color: ({ token }) => `${token('legacy.color.blue.grey')} !important`,
    maxWidth: '23rem !important'
  }
});

@withToken
@styled(defaultStyles)
@autobind
class DefaultButton extends PureComponent {
  static defaultProps = {
    iconLeftHeight: '1.2rem'
  };

  _refs = {};

  handleRef(e) {
    this._refs.me = e;
  }

  handleClick(e) {
    const { isLoading, isDisabled, onClick } = this.props;

    e.preventDefault();

    if (!onClick || isLoading || isDisabled) {
      return;
    }

    // Hack: fast fields don't blur when submitting the form via pressing enter,
    // which basically just triggers a click on the forms submit button, so we blur
    // the currently focused element here, if its not the button itself!
    if (
      window.document.activeElement &&
      window.document.activeElement !== this._refs.me
    ) {
      window.document.activeElement.blur();
    }

    // Hack #2: the blur just triggers the field to pass up its data, so we need to
    // wait 1 tick to wait for the data to actually being updated in the form store
    setTimeout(() => onClick(e));
  }

  renderButton() {
    const {
      IconLeft,
      iconLeftHeight,
      IconRight,
      children,
      isLoading,
      onClick,
      isDisabled,
      color,
      styles: s,
      token,
      ...props
    } = this.props;

    return (
      <button
        {...props}
        type={props.form ? 'submit' : 'button'}
        disabled={isDisabled}
        onClick={this.handleClick}
        ref={this.handleRef}
        {...s.with('container', {
          disabled: isDisabled,
          containerWithLeftIcon: !!IconLeft,
          containerWithRightIcon: !!IconRight
        })(props)}
      >
        <span {...s('content')}>
          {IconLeft && (
            <span {...s('icon', 'iconLeft', { iconLoading: isLoading })}>
              <IconLeft height={iconLeftHeight} />
            </span>
          )}

          <span {...s('label', { labelLoading: isLoading })}>{children}</span>

          {IconRight && (
            <span {...s('icon', 'iconRight', { iconLoading: isLoading })}>
              <IconRight />
            </span>
          )}

          {isLoading && (
            <span {...s('loading')}>
              <LoadingSpinner
                Tag='span'
                size={25}
                strokeWidth={4}
                colors={[color || token('legacy.color.white')]}
              />
            </span>
          )}
        </span>
      </button>
    );
  }

  render() {
    const { isDisabled, disabledTooltip } = this.props;
    if (isDisabled && disabledTooltip) {
      return (
        <TooltipStateful
          styles={tooltipStyles}
          Content={disabledTooltip}
          placement={this.props.placement || 'bottom'}
          openOn='HOVER'
          closeOn='HOVER'
        >
          {this.renderButton()}
        </TooltipStateful>
      );
    }
    return this.renderButton();
  }
}

export default DefaultButton;

import { TransitionSingle } from '@rexlabs/animation';
import { GhostIconButton } from '@rexlabs/button';
import Icons from '@rexlabs/icons-next';
import { ToastTarget } from '@rexlabs/notifications';
import {
  border,
  focus,
  padding,
  StyleSheet,
  useStyles
} from '@rexlabs/styling';
import { Heading, Small } from '@rexlabs/text';
import { noop } from 'lodash';
import React, {
  ComponentType,
  createContext,
  ReactNode,
  useContext,
  useState
} from 'react';

export interface ModalConfig {
  title?: ReactNode;
  subtitle?: ReactNode;
  contents: ReactNode;
  hideClose?: boolean;
}

export interface ModalController {
  openModal(modal: ModalConfig): void;
  closeModal(): void;
}

const ModalContext = createContext<ModalController>({
  openModal: noop,
  closeModal: noop
});

const defaultStyles = StyleSheet({
  container: {
    position: 'fixed',
    display: 'none',
    alignItems: 'center',
    justifyContent: 'center',
    top: '0',
    left: '0',
    bottom: '0',
    right: '0',
    zIndex: 99
  },
  containerShow: {
    display: 'flex'
  },
  backdrop: {
    position: 'absolute',
    top: '0',
    left: '0',
    bottom: '0',
    right: '0',
    cursor: 'pointer',
    backgroundColor: ({ token }) =>
      token('modal.backdrop.color', token('color.container.static.dark')),
    opacity: ({ token }) =>
      `${token('modal.backdrop.opacity', '0.7')} !important`,
    zIndex: 10
  },
  content: {
    display: 'flex',
    flexDirection: 'column',
    position: 'relative',
    minWidth: '500px',
    maxWidth: '100%',
    minHeight: '0px',
    maxHeight: 'calc(100vh - 4rem)',
    margin: 'auto',
    overflow: 'hidden',
    backgroundColor: ({ token }) => token('color.container.static.default'),
    zIndex: 11,
    ...border.styles({
      target: 'modal.border',
      all: {
        radius: 'm'
      }
    }),
    ...focus.styles({
      target: 'modal.shadow',
      shadow: 'medium'
    })
  },
  header: {
    display: 'flex',
    position: 'relative',
    flexDirection: 'row',
    justifyContent: 'space-between',
    flexShrink: '0',
    ...padding.styles({
      target: 'modal.header.padding',
      top: 'xl',
      bottom: 's',
      left: 'xl',
      right: 'xl'
    }),
    ...border.styles({
      target: 'modal.header.separator',
      bottom: {
        width: 'thin',
        style: 'solid',
        color: 'container.static.medium'
      }
    })
  },
  details: {
    display: 'flex',
    flexDirection: 'column'
  },
  modalTitle: {
    margin: 0
  },
  modalDescription: {
    paddingBottom: 0
  },
  body: {
    overflow: 'auto',
    ...padding.styles({
      target: 'modal.body.padding',
      all: 'xl'
    })
  }
});

export function useModal() {
  return useContext(ModalContext);
}

export function withModal<P>(WrappedComponent: ComponentType<P>) {
  return function ModalWrapper(props: P) {
    const modal = useModal();
    const newProps = {
      ...props,
      modal
    };
    return <WrappedComponent {...newProps} />;
  };
}

export function ModalProvider({ children }: { children: ReactNode }) {
  const s = useStyles(defaultStyles);
  const [modal, setModal] = useState<ModalConfig | undefined>();

  const controller: ModalController = {
    openModal: (modal) =>
      setModal({
        ...modal,
        hideClose: false
      }),
    closeModal: () => setModal(undefined)
  };

  const onBackdropClick = controller.closeModal;
  const show = modal !== undefined;

  return (
    <ModalContext.Provider value={controller}>
      {children}
      <div
        role='dialog'
        aria-modal
        aria-labelledby='modalTitle'
        aria-describedby='modalDescription'
        id='modalTarget'
        {...s('container', {
          containerShow: show
        })}
      >
        <TransitionSingle show={show} configs={['fade']}>
          <div {...s('backdrop')} onClick={onBackdropClick}></div>
        </TransitionSingle>
        <TransitionSingle show={show} configs={['scale']}>
          <div {...s('content')}>
            <ToastTarget name='modal' position='top' />
            <div {...s('header')}>
              <div {...s('details')}>
                {modal?.title && (
                  <Heading id='modalTitle' level={2} {...s('modalTitle')}>
                    {modal.title}
                  </Heading>
                )}
                {modal?.subtitle && (
                  <Small id='modalDescription' grey {...s('modalDescription')}>
                    {modal.subtitle}
                  </Small>
                )}
              </div>
              {!modal?.hideClose && (
                <GhostIconButton
                  Icon={Icons.CrossLarge}
                  onClick={controller.closeModal}
                />
              )}
            </div>
            <div {...s('body')}>{modal?.contents}</div>
          </div>
        </TransitionSingle>
      </div>
    </ModalContext.Provider>
  );
}

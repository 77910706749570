import { Box } from '@rexlabs/box';
import { styled, StyleSheet } from '@rexlabs/styling';
import React, { PureComponent } from 'react';
import { BREAKPOINTS, FONT } from 'src/theme';
import { Body, Small } from 'src/view/components/text';

const defaultStyles = StyleSheet({
  container: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    paddingTop: ({ token }) => token('spacing.m'),
    position: 'relative',

    [BREAKPOINTS.PHONE_ONLY]: {
      flexDirection: 'column',
      alignItems: 'flex-start'
    }
  },

  wrapIcon: {
    borderBottom: '.2rem solid currentColor',
    width: '4rem',
    height: '4rem',
    marginRight: ({ token }) => token('spacing.m'),

    [BREAKPOINTS.PHONE_ONLY]: {
      alignSelf: 'flex-start',
      marginRight: '0',
      marginBottom: ({ token }) => token('spacing.xs')
    }
  },

  icon: {
    width: '2.4rem',
    height: 'auto'
  },

  small: {
    color: ({ token }) => token('legacy.color.grey.default')
  },

  value: {
    fontSize: '1.8rem',
    lineHeight: '2.4rem',
    fontWeight: FONT.WEIGHTS.NORMAL,
    color: ({ token }) => token('legacy.color.blue.dark')
  },

  largeValue: {
    fontSize: '2.4rem',
    lineHeight: '2.8rem'
  }
});

const smallStyles = StyleSheet({
  small: {
    [BREAKPOINTS.PHONE_ONLY]: {
      fontSize: '1.2rem',
      lineHeight: '1.8rem'
    }
  }
});

@styled(defaultStyles)
class Stat extends PureComponent {
  render() {
    const { value, large, Icon, sup, sub, borderColor, styles: s } = this.props;
    return (
      <Box {...s.with('container')(this.props)}>
        {Icon && (
          <Box
            {...s.with('wrapIcon')({ color: borderColor })}
            flexDirection='column'
            justifyContent='center'
            alignItems='center'
          >
            <Icon {...s('icon')} />
          </Box>
        )}
        <Box flexDirection='column'>
          {sup && (
            <Small
              Tag='span'
              grey
              style={{ marginBottom: '-.3rem' }}
              styles={smallStyles}
            >
              {sup}
            </Small>
          )}
          <Body {...s('value', { largeValue: large })}>{value}</Body>
          {sub && (
            <Small
              Tag='span'
              grey
              style={{ marginTop: '-.3rem' }}
              styles={smallStyles}
            >
              {sub}
            </Small>
          )}
        </Box>
      </Box>
    );
  }
}

export default Stat;

import { Box } from '@rexlabs/box';
import { PrimaryButton, SecondaryButton } from '@rexlabs/button';
import { query, withQuery } from '@rexlabs/model-generator';
import { autobind } from 'core-decorators';
import React, { PureComponent } from 'react';
import listingsModel from 'src/data/models/entities/listings';
import { withToken } from 'src/theme';
import { addressToMapbox } from 'src/utils/mapbox';
import { RenderLoading } from 'src/view/components/loading';
import { Modal } from 'src/view/components/modal';
import { Body } from 'src/view/components/text';
import withError from 'src/view/containers/with-error';

const q = query`{
  ${listingsModel} (id: ${(p) => p?.listingId}) {
    id
    address
  }
}`;

@withToken
@withError('errorModal')
@withQuery(q)
@autobind
class DeleteListingModal extends PureComponent {
  state = {
    loading: false
  };

  handleSubmit() {
    const { listings, closeModal, errorModal, listingId } = this.props;
    this.setState({ loading: true }, () => {
      listings
        .trashItem({ id: listingId })
        .then(() => {
          closeModal({ id: listingId });
        })
        .catch((e) => {
          errorModal.open(e);
          this.setState({ loading: false });
        });
    });
  }

  render() {
    const {
      listings,
      closeModal,
      errorModal: { Error },
      token
    } = this.props;
    const address = addressToMapbox(listings?.item?.data?.address)?.label;

    return (
      <Modal
        title='Delete Listing'
        subtitle={address}
        width='50rem'
        onClose={() => closeModal({ id: null })}
      >
        <RenderLoading isLoading={listings?.item?.status === 'loading'}>
          <Box p={token('spacing.xxl')} style={{ textAlign: 'center' }}>
            <Body>
              Are you sure you want to delete the listing for <b>{address}</b>?
            </Body>
            <Body>
              This will not remove the listing from existing campaigns, but it
              will not appear as an option for future campaigns anymore.
            </Body>
            <Box justifyContent='center' mt={token('spacing.xxl')}>
              <SecondaryButton onClick={() => closeModal({ id: null })}>
                Cancel
              </SecondaryButton>
              <Box ml={token('spacing.xs')}>
                <PrimaryButton
                  onClick={this.handleSubmit}
                  isLoading={this.state.loading}
                >
                  Delete
                </PrimaryButton>
              </Box>
            </Box>
          </Box>
        </RenderLoading>

        <Error />
      </Modal>
    );
  }
}

export default DeleteListingModal;

export function fontFace(name, src, weight, style) {
  return `
    @font-face {
      font-family: '${name}';
      src: url(${src});
      font-weight: ${weight};
      font-style: ${style};
    }
  `;
}

export function placeholders(style) {
  return `
    ::-webkit-input-placeholder { /* Chrome/Opera/Safari */
      ${style}
    }
    ::-moz-placeholder { /* Firefox 19+ */
      ${style}
    }
    :-ms-input-placeholder { /* IE 10+ */
      ${style}
    }
    :-moz-placeholder { /* Firefox 18- */
      ${style}
    }
  `;
}

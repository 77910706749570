import { Schema } from '@data-driven-forms/react-form-renderer';
import { CampaignDefinition } from 'src/features/campaign-definitions';

export const CAMPAIGN_STATUSES = [
  'processing',
  'active',
  'archived',
  'paused',
  'draft',
  'completed',
  'in_review',
  'failed',
  'pending',
  'blocked',
  'expiring',
  'scheduled',
  'renewing'
] as const;

export type CampaignStatus = typeof CAMPAIGN_STATUSES[number];

export type FormDefinition = {
  readonly id: string;
  schema: Schema;
  type: string;
  readonly created_at: Date;
  readonly updated_at: Date;
};

export type Campaign = {
  readonly id: string;
  name: string;
  status: CampaignStatus;
  status_updated_at?: Date;
  type: string;
  type_label: string;
  campaign_strategy?: unknown;
  goal?: string;
  budget: unknown;
  duration: unknown;
  start_date?: Date;
  attributes: unknown;
  renewed_at?: Date;
  renew: boolean;
  limit_renewals: boolean;
  renewals: unknown;
  lead_generation_form_enabled: boolean;
  campaign_definition?: CampaignDefinition;
  campaign_template?: unknown;
  statement_of_information?: unknown;
  lead_capture_emails: unknown;
  form_definitions?: {
    data: FormDefinition[];
  };
  content_source: {
    entities: {
      id: string;
    }[];
  };
  selected_payment_option?: {
    id: string;
  };
  readonly created_at: Date;
  readonly updated_at: Date;
};

import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { styled, StyleSheet } from '@rexlabs/styling';
import Box from '@rexlabs/box';
import _ from 'lodash';
import colorTheme from '../../../utils/color-theme';
import Arrow from '../../../assets/arrow.svg';
import ScaledText from '../../scaled-text';
import { COLORS } from 'src/theme';

const fontFamily = 'Lato, sans-serif';
const bannerStyles = StyleSheet({
  banner: {
    width: '320px',
    height: '50px',
    border: 'solid 1px darkgray',
    borderLeft: 'none',
    flexGrow: 0,
    flexShrink: 0,
    backgroundColor: 'white'
  },
  shortHeadline: {
    fontFamily,
    fontSize: '15px',
    fontWeight: '300',
    lineHeight: '18px',
    marginLeft: '14px'
  },
  logoWrapper: {
    width: '111px',
    height: '50px'
  },
  logo: {
    objectFit: 'contain',
    height: '100%',
    width: '100%'
  },
  arrow: {
    margin: '20px 25px'
  }
});

export const SIZES = {
  SMALL: 'small',
  LARGE: 'large'
};

class MobileBanner extends PureComponent {
  static propTypes = {
    shortHeadline: PropTypes.string,
    logoUrls: PropTypes.object,
    brandColor: PropTypes.string,
    size: PropTypes.oneOf(Object.values(SIZES))
  };

  static SIZES = SIZES;

  dimensions() {
    const { size } = this.props;
    switch (size) {
      case SIZES.LARGE:
        return {
          width: '320px',
          imageWidth: '111px'
        };
      case SIZES.SMALL:
        return {
          width: '300px',
          imageWidth: '101px'
        };
    }
  }

  render() {
    const {
      shortHeadline,
      logoUrls,
      brandColor,
      backgroundColor,
      styles: s
    } = this.props;
    const { width, imageWidth } = this.dimensions();
    const { brandColorBorder } = colorTheme(brandColor);

    return (
      <Box alignItems='center' width={width} {...s('banner')}>
        <Box
          padding='8px'
          flexDirection='column'
          alignItems='center'
          justifyContent='center'
          width={imageWidth}
          {...s.with('logoWrapper')({
            backgroundColor: backgroundColor,
            borderRight: brandColorBorder
          })}
        >
          <img src={_.get(logoUrls, 'original.url')} {...s('logo')} />
        </Box>
        <ScaledText
          maxWidth={140}
          maxTextLines={2}
          maxFontSize={15}
          minFontSize={12}
          flex={1}
          {...s.with('shortHeadline')({ color: COLORS.BLACK })}
        >
          {shortHeadline}
        </ScaledText>
        <Arrow width={20} height={20} {...s('arrow')} />
      </Box>
    );
  }
}

export default styled(bannerStyles)(MobileBanner);

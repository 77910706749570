import { LinkButton } from '@rexlabs/button';
import { StyleSheet, useStyles } from '@rexlabs/styling';
import React, { useCallback, useState } from 'react';
import { Tiny } from 'src/view/components/text';
import ManualAddressModal from 'src/view/modals/manual-address';

const styles = StyleSheet({
  manualButton: {
    fontSize: ({ token }) => token('typography.size.m')
  }
});

function ManualAddress({
  initialValues,
  initialErrors,
  show,
  onClose: onCloseProp,
  onSave,
  disableCountry,
  subtitle
}) {
  const s = useStyles(styles, 'ManualAddress');
  const [showModal, setShowModal] = useState(false);
  const onClose = useCallback(() => setShowModal(false), []);

  return (
    <>
      <Tiny grey>
        Can&apos;t find the exact address?{' '}
        <LinkButton {...s('manualButton')} onClick={() => setShowModal(true)}>
          Enter manually
        </LinkButton>
      </Tiny>

      {(show || showModal) && (
        <ManualAddressModal
          subtitle={subtitle}
          initialValues={initialValues}
          initialErrors={initialErrors}
          disableCountry={disableCountry}
          onClose={() => {
            onClose();
            onCloseProp?.();
          }}
          onSave={(address) => {
            onClose();
            onSave(address);
          }}
        />
      )}
    </>
  );
}

export default ManualAddress;

import { useCallback, useEffect } from 'react';
import debounce from 'lodash/debounce';

function useDebounce(value, callback, rate, deps = []) {
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const debounceCallback = useCallback(debounce(callback, rate), deps);

  useEffect(() => {
    debounceCallback(value);
    return debounceCallback.cancel;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [value]);
}

export default useDebounce;

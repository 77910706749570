import React, { PureComponent } from 'react';

import FacebookSplit from './facebook-ads/agent/split';

class AgentFacebookAd extends PureComponent {
  render() {
    const { adContentSet, disableWrapper } = this.props;
    return (
      <FacebookSplit
        adContentSet={adContentSet}
        disableWrapper={disableWrapper}
      />
    );
  }
}

export default AgentFacebookAd;

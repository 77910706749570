import {StatsPanel, StatTile} from "view/components/stats";
import {number} from "utils/format";
import {HelpIcon} from "view/components/tooltip";
import {Box} from "@rexlabs/box";
import {Body} from "view/components/text";
import {ConversionsModal} from "view/modals/admin/campaign/conversions";
import {LpEngagementModal} from "view/modals/admin/campaign/landing-page-engagement";
import {FacebookEngagementModal} from "view/modals/admin/campaign/facebook-engagement";
import {VideoAdPlaysModal} from "view/modals/admin/campaign/video-ad-plays";
import React from "react";

export function CampaignStatTiles({
  spokeLandingPage,
  webflowLandingPage,
  hasLeadGenForm,
  statsData,
  campaignData,
  facebookActivityData = {},
}) {

  return (
    <StatsPanel>
      {(openModal, closeModal, showModal) => (
        <>
          {(spokeLandingPage ||
            webflowLandingPage ||
            hasLeadGenForm) && (
            <>
              <StatTile
                title='Landing page conversions'
                value={number(
                  statsData?.summary
                    ?.landing_page_conversion_events
                )}
                sub={
                  <HelpIcon
                    Content={() => (
                      <Box w='30rem'>
                        <Body>
                          Landing page conversions are the most
                          direct interaction with your campaign.
                          Every landing page conversion is an
                          indication of a warm lead.
                        </Body>
                      </Box>
                    )}
                  />
                }
                onClick={() => openModal('conversions')}
              />
              {showModal === 'conversions' && (
                <ConversionsModal
                  subTitle={campaignData?.name}
                  closeModal={closeModal}
                  stats={statsData}
                  landingPageType={
                    campaignData?.landing_pages
                      ?.items?.[0]?.template?.id
                  }
                  webflowLandingPage={webflowLandingPage}
                />
              )}
            </>
          )}

          {spokeLandingPage && (
            <>
              <StatTile
                title='Landing page engagement'
                value={number(
                  statsData?.summary
                    ?.landing_page_other_events
                )}
                sub={
                  <HelpIcon
                    Content={() => (
                      <Box style={{width: '30rem'}}>
                        <Body>
                          This data shows how interested your
                          visitors are, and provides meaningful
                          insights into how they&apos;re engaging
                          with your landing page.
                        </Body>
                      </Box>
                    )}
                  />
                }
                onClick={() => openModal('landing-page')}
              />
              {showModal === 'landing-page' && (
                <LpEngagementModal
                  subTitle={campaignData?.name}
                  closeModal={closeModal}
                  stats={statsData}
                />
              )}
            </>
          )}

          {(statsData?.summary?.facebook_engagement ??
            0) > 0 && (
            <>
              <StatTile
                title='Facebook engagement'
                value={number(
                  statsData?.summary?.facebook_engagement
                )}
                sub={
                  <HelpIcon
                    Content={() => (
                      <Box style={{width: '30rem'}}>
                        <Body>
                          See how many people engaged with your
                          content on Facebook and Instagram. Like,
                          Love, Haha, Wow, Sad or Angry - see how
                          people respond to your ads. Don&apos;t
                          forget to contact those who comment or
                          share your ad!
                        </Body>
                      </Box>
                    )}
                  />
                }
                onClick={() => openModal('facebook-engagement')}
              />
              {showModal === 'facebook-engagement' && (
                <FacebookEngagementModal
                  subTitle={campaignData?.name}
                  closeModal={closeModal}
                  facebookActivity={facebookActivityData}
                />
              )}
            </>
          )}

          {(statsData?.video_stats?.data ?? []).length > 0 && (
            <>
              <StatTile
                title='Video ad plays'
                value={number(
                  statsData?.summary?.video_plays
                )}
                sub={
                  <HelpIcon
                    Content={() => (
                      <Box style={{width: '30rem'}}>
                        <Body>
                          Check how much of your video people are
                          watching, and how many stay till the
                          end on Facebook and Instagram.
                        </Body>
                      </Box>
                    )}
                  />
                }
                onClick={() => openModal('video-ads')}
              />
              {showModal === 'video-ads' && (
                <VideoAdPlaysModal
                  subTitle={campaignData?.name}
                  closeModal={closeModal}
                  stats={statsData}
                />
              )}
            </>
          )}
        </>
      )}
    </StatsPanel>
  )
}

import { Box } from '@rexlabs/box';
import { useToken } from '@rexlabs/styling';
import React from 'react';
import { Small } from 'src/view/components/text';

function OptionNotFound(props) {
  const { term } = props;
  const token = useToken();

  return (
    <Box
      p={token('spacing.xs')}
      pl={token('spacing.m')}
      pr={token('spacing.m')}
    >
      <Small grey>
        {term.isLoading ? (
          <span>Loading...</span>
        ) : term.value ? (
          <span>
            Could not find anything for <b>{term.value}</b>.
          </span>
        ) : (
          <span>Start typing to trigger search...</span>
        )}
      </Small>
    </Box>
  );
}

export default OptionNotFound;

import { QueryClient } from 'react-query';
import { defer, LoaderFunction, redirect } from 'react-router-dom';
import type { CampaignDefinition } from 'src/features/campaign-definitions';
import { campaignKeys, getCampaign } from 'src/features/campaigns';
import { getCampaignDefinition } from '../../campaign-definitions/api/getCampaignDefinition';
import { campaignDefinitionKeys } from '../../campaign-definitions/api/keys';
import { steps } from './steps';
import { getContentTypeDefinition } from 'src/features/wizard/utils/contentTypes';

export const getCampaignDefinitionFromParams = async (
  queryClient: QueryClient,
  accountId: string,
  ids: { campaignId?: string; campaignDefinitionId?: string }
): Promise<CampaignDefinition> => {
  const { campaignDefinitionId, campaignId } = ids;
  let campaignDefinition: CampaignDefinition | undefined;

  if (campaignDefinitionId) {
    campaignDefinition = await queryClient.fetchQuery({
      queryKey: campaignDefinitionKeys.detail(accountId, campaignDefinitionId),
      queryFn: () => getCampaignDefinition({ accountId, campaignDefinitionId })
    });
  } else if (campaignId) {
    const campaign = await queryClient.fetchQuery({
      queryKey: campaignKeys.detail(accountId, campaignId),
      queryFn: () =>
        getCampaign({
          campaignId,
          accountId,
          includes: ['campaign_definition']
        })
    });
    campaignDefinition = queryClient.setQueryData(
      campaignDefinitionKeys.detail(
        accountId,
        campaign.campaign_definition?.id ?? ''
      ),
      campaign.campaign_definition
    );
  }
  if (campaignDefinition === undefined)
    throw new Error('Failed to load campaign definition');
  return campaignDefinition;
};

export const provideDetailsLoader =
  (
    queryClient: QueryClient,
    relative: string,
    accountId: string
  ): LoaderFunction =>
  async ({ params }) => {
    const { campaignDefinitionId, campaignId, contentType = '' } = params;

    const campaignDefinition = await getCampaignDefinitionFromParams(
      queryClient,
      accountId,
      { campaignId, campaignDefinitionId }
    );

    const contentTypes: string[] = campaignDefinition.content.map(
      ({ type }) => type
    );
    if (!contentTypes.includes(contentType)) {
      return redirect(`${relative}/${contentTypes[0]}`);
    }

    return null;
  };

export const provideDetailsEditLoader =
  (queryClient: QueryClient, accountId: string): LoaderFunction =>
  async ({ params }) => {
    const { contentId = '', contentType = '' } = params;

    const definition = getContentTypeDefinition(contentType);

    if (!definition.Loader) return null;

    return await definition.Loader(queryClient, accountId, contentId);
  };

export const stepLoader: LoaderFunction = ({ params }) => {
  const { stepId } = params;
  const step = steps.find(({ id }) => id === stepId);

  return step ?? redirect(`../${steps[0].id}`);
};

export const wizardCreateLoader =
  (queryClient: QueryClient, accountId: string): LoaderFunction =>
  async ({ params }) => {
    const { campaignDefinitionId = '' } = params;

    const campaignDefinition = queryClient.fetchQuery({
      queryKey: campaignDefinitionKeys.detail(accountId, campaignDefinitionId),
      queryFn: () => getCampaignDefinition({ accountId, campaignDefinitionId })
    });
    return defer({
      campaignDefinition
    });
  };

export const wizardEditLoader =
  (queryClient: QueryClient, accountId: string): LoaderFunction =>
  async ({ params }) => {
    const { campaignId = '' } = params;

    const campaign = await queryClient.fetchQuery({
      queryKey: campaignKeys.detail(accountId, campaignId),
      queryFn: () => getCampaign({ campaignId, accountId })
    });

    return campaign;
  };

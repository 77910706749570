import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { styled, StyleSheet } from '@rexlabs/styling';
import Box from '@rexlabs/box';
import _ from 'lodash';
import { LargeButton } from '../../button';
import GooText from '../../goo-text';
import colorTheme from '../../../utils/color-theme';
import ScaledText from '../../scaled-text';
import { COLORS } from 'src/theme';

const fontFamily = 'Lato, sans-serif';

const defaultStyles = StyleSheet({
  netboard: {
    border: 'solid 1px darkgray',
    flexGrow: 0,
    flexShrink: 0
  },
  header: {
    position: 'relative'
  },
  img: {
    objectFit: 'cover'
  },
  overlay: {
    position: 'absolute',
    left: '10px',
    top: '15px',
    display: 'flex',
    flexDirection: 'column'
  },
  longHeadline: {
    fontSize: '20px',
    lineHeight: '25px',
    fontWeight: 500,
    fontFamily
  },
  content: {
    background: 'white'
  },
  shortHeadline: {
    fontSize: '42px',
    lineHeight: '48px',
    fontWeight: 400,
    backgroundColor: 'white',
    padding: '0px 10px 7px',
    fontFamily
  },
  footer: {},
  footerLogo: {
    maxWidth: '160px',
    maxHeight: '42px',
    objectFit: 'contain'
  },
  bannerAdText: {
    fontSize: '14px',
    fontFamily,
    fontWeight: 600,
    lineHeight: '30px',
    padding: '6px 10px 6px',
    backgroundColor: 'white',
    textTransform: 'uppercase'
  }
});

@styled(defaultStyles)
class Netboard extends PureComponent {
  static propTypes = {
    brandColor: PropTypes.string,
    longHeadline: PropTypes.string,
    shortHeadline: PropTypes.string,
    primaryImageUrls: PropTypes.object,
    logoUrls: PropTypes.object,
    buttonText: PropTypes.string
  };

  static defaultProps = {
    buttonText: 'Learn more'
  };

  render() {
    const {
      brandColor,
      backgroundColor,
      longHeadline,
      shortHeadline,
      primaryImageUrls,
      logoUrls,
      buttonText,
      styles: s
    } = this.props;

    const { brandColorBorder } = colorTheme(brandColor);

    return (
      <Box
        width='580px'
        height='400px'
        display='flex'
        flexDirection='column'
        {...s('netboard')}
      >
        <Box height='330px' {...s('header')}>
          <Box {...s('overlay')}>
            <Box width={357} marginBottom={24}>
              <GooText padding='0' borderRadius={2}>
                <span>
                  <ScaledText
                    maxWidth={340}
                    maxTextLines={2}
                    maxFontSize={36}
                    minFontSize={28}
                    {...s.with('shortHeadline')({ color: COLORS.BLACK })}
                  >
                    {shortHeadline}
                  </ScaledText>
                </span>
              </GooText>
            </Box>
            <Box width={226}>
              <GooText padding='7px 8px 6px' borderRadius={2}>
                <span {...s.with('longHeadline')({ color: COLORS.BLACK })}>
                  {longHeadline}
                </span>
              </GooText>
            </Box>
          </Box>
          <img
            width='100%'
            height={330}
            src={_.get(primaryImageUrls, ['crop.landscape', 'url'])}
            {...s('img')}
          />
        </Box>
        <Box
          height='70px'
          display='flex'
          flexDirection='row'
          alignItems='center'
          justifyContent='space-between'
          padding='15px 17px'
          {...s.with('footer')({
            backgroundColor: backgroundColor,
            borderTop: brandColorBorder
          })}
        >
          <img src={_.get(logoUrls, 'original.url')} {...s('footerLogo')} />
          <Box width={126}>
            <LargeButton brandColor={brandColor} invert={false}>
              {buttonText}
            </LargeButton>
          </Box>
        </Box>
      </Box>
    );
  }
}

export default Netboard;

import { useMutation } from 'react-query';
import { api } from 'src/utils/api-client';
import { ShareReportOptions } from '../schemas';

export default function useSendReportEmail(campaignId: string) {
  return useMutation<void, Error, ShareReportOptions>(
    ({ email, sections, message }): Promise<void> =>
      api.post(`/campaigns/${campaignId}/vendor-reports/email`, {
        email_address: email,
        sections: sections.map((id) => ({ id })),
        message
      })
  );
}

/* eslint-disable max-lines */
import { border, margin, text } from '@rexlabs/styling';
import { TOKENS as DEFAULTS, Tokens } from '@rexlabs/theme-luna';
import { merge } from 'lodash';

export const TOKENS: Tokens = merge(
  DEFAULTS,

  /**
   * BASE TOKENS
   */
  {
    palette: {
      // I converted "Spoke green" into hsl and made that the 500 color, and then I
      // just increased/decreased lightness
      brand: {
        50: ({ token }) => token('palette.teal.50'),
        100: ({ token }) => token('palette.teal.100'),
        200: ({ token }) => token('palette.teal.200'),
        300: ({ token }) => token('palette.teal.300'),
        400: ({ token }) => token('palette.teal.400'),
        500: ({ token }) => token('palette.teal.500'), // spoke primary converted to HSL
        600: ({ token }) => token('palette.teal.600'),
        700: ({ token }) => token('palette.teal.700'),
        800: ({ token }) => token('palette.teal.800'),
        900: ({ token }) => token('palette.teal.900')
      }
    },

    // Change success color to teal
    color: {
      textStyle: {
        success: {
          idle: {
            default: ({ token }) => token('palette.teal.900'),
            contrast: ({ token }) => token('palette.teal.50')
          },
          hover: {
            default: ({ token }) => token('palette.teal.800'),
            contrast: ({ token }) => token('palette.teal.100')
          },
          active: {
            default: ({ token }) => token('palette.teal.700'),
            contrast: ({ token }) => token('palette.teal.200')
          }
        },
        strategyChip: {
          default: ({ token }) => token('palette.green.900')
        },
        body: {
          blueGrey: '#8aa0ae',
          lightGrey: '#4F4F4F',
          blueDark: '#002842'
        },
        heading: {
          blueDark: '#002842',
          blue: '#0F2C65'
        }
      },
      success: {
        idle: {
          default: ({ token }) => token('palette.teal.900'),
          contrast: ({ token }) => token('palette.teal.300')
        },
        hover: {
          default: ({ token }) => token('palette.teal.800'),
          contrast: ({ token }) => token('palette.teal.400')
        },
        active: {
          default: ({ token }) => token('palette.teal.700'),
          contrast: ({ token }) => token('palette.teal.500')
        },
        selected: {
          default: ({ token }) => token('palette.teal.700'),
          contrast: ({ token }) => token('palette.teal.500')
        }
      },
      container: {
        static: {
          success: {
            default: ({ token }) => token('palette.teal.100'),
            contrast: ({ token }) => token('palette.teal.300')
          },
          // Used to match new preview container background colours with the legacy.color.grey.light
          preview: {
            default: '#F0F5F7'
          }
        }
      },
      border: {
        success: {
          idle: {
            default: ({ token }) => token('palette.teal.900'),
            contrast: ({ token }) => token('palette.teal.300')
          },
          hover: {
            default: ({ token }) => token('palette.teal.800'),
            contrast: ({ token }) => token('palette.teal.400')
          },
          active: {
            default: ({ token }) => token('palette.teal.700'),
            contrast: ({ token }) => token('palette.teal.500')
          },
          selected: {
            default: ({ token }) => token('palette.teal.700'),
            contrast: ({ token }) => token('palette.teal.500')
          }
        }
      }
    },

    // Typography copied from luna theme for easy reference
    // Only change is family.normal
    typography: {
      size: {
        xs: '1rem',
        s: '1.2rem',
        m: '1.4rem',
        l: '1.6rem',
        xl: '1.8rem',
        '2xl': '2rem',
        '3xl': '2.4rem',
        '4xl': '2.8rem',
        '5xl': '3.2rem'
      },

      weight: {
        thin: 200,
        light: 300,
        normal: 400,
        semiMedium: 450,
        medium: 500,
        semibold: 600,
        bold: 700,
        heavy: 800,
        black: 900
      },

      family: {
        normal: 'Circular Pro, serif',
        mono: 'Monaco, monospace'
      },

      lineHeight: {
        xs: '1.4rem',
        s: '1.6rem',
        m: '2rem',
        l: '2.4rem',
        xl: '2.6rem',
        '2xl': '2.8rem',
        '3xl': '3rem',
        '4xl': '3.6rem',
        '5xl': '4rem'
      }
    },

    textStyle: {
      body: {
        default: {
          font: {
            size: ({ token }) => token('typography.size.m'),
            weight: ({ token }) => token('typography.weight.light')
          },
          lineHeight: ({ token }) => token('typography.lineHeight.l'),
          letterSpacing: ({ token }) =>
            token('typography.letterSpacing.compact')
        }
      },
      // Reset <a> styles to have a primary style with no hover effects.
      link: {
        default: {
          idle: text.tokens({
            color: 'primary.idle.default',
            textDecoration: 'primary.idle.textDecoration'
          }),
          hover: text.tokens({
            color: 'primary.hover.default',
            textDecoration: 'primary.hover.textDecoration'
          })
        }
      },
      heading: {
        display: {
          font: {
            size: () => '42px',
            weight: ({ token }) => token('typography.weight.black')
          },
          color: ({ token }) => token('color.textStyle.body.default')
        },
        1: {
          font: {
            size: ({ token }) => token('typography.size.5xl')
          },
          letterSpacing: ({ token }) =>
            token('typography.letterSpacing.compact')
        },
        2: {
          letterSpacing: ({ token }) =>
            token('typography.letterSpacing.compact')
        }
      },
      large: {
        default: {
          font: {
            size: ({ token }) => token('typography.size.xl'),
            weight: ({ token }) => token('typography.weight.medium')
          },
          lineHeight: ({ token }) => token('typography.lineHeight.xl'),
          letterSpacing: ({ token }) =>
            token('typography.letterSpacing.compact')
        }
      },
      small: {
        default: {
          font: {
            size: ({ token }) => token('typography.size.l'),
            weight: ({ token }) => token('typography.weight.light')
          },
          lineHeight: ({ token }) => token('typography.lineHeight.m'),
          letterSpacing: ({ token }) =>
            token('typography.letterSpacing.compact')
        }
      },
      tiny: {
        default: {
          font: {
            size: ({ token }) => token('typography.size.xs'),
            weight: ({ token }) => token('typography.weight.light')
          },
          lineHeight: ({ token }) => token('typography.lineHeight.xl'),
          letterSpacing: ({ token }) =>
            token('typography.letterSpacing.compact')
        }
      }
    },

    shadow: {
      buttonGroup: 'rgba(0, 0, 0, 0.3) 0 -1rem 2rem -2rem'
    },

    transition: {
      enterLeave: {
        expand: {
          from: {
            height: 0,
            opacity: 0
          },
          enter: () => async (next, ref) => {
            await next({ height: ref?.offsetHeight, opacity: 1 });
          },
          leave: () => async (next) => {
            await next({ opacity: 0 });
            await next({ height: 0 });
          }
        }
      }
    }
  },

  /**
   * COMPONENT TOKENS
   **/
  {
    // Button notes:
    // How to set focus/outline? I can't see any reference to it in core.tsx or primary.tsx
    // TODO: move colours from Spoke theme consts to palette and use tokens - can easily update this later to point to a different palette.
    button: {
      loading: {
        icon: {
          size: ({ token }) => token('icon.size.m')
        }
      },

      primary: {
        font: {
          size: ({ token }) => token('typography.size.xl'),
          weight: ({ token }) => token('typography.weight.normal')
        }
      },

      outline: {
        font: {
          size: ({ token }) => token('typography.size.xl'),
          weight: ({ token }) => token('typography.weight.normal')
        }
      },

      secondary: {
        font: {
          size: ({ token }) => token('typography.size.xl'),
          weight: ({ token }) => token('typography.weight.normal')
        }
      },

      ghost: {
        font: {
          size: ({ token }) => token('typography.size.xl'),
          weight: ({ token }) => token('typography.weight.normal')
        },
        hover: {
          background: {
            color: ({ token }) => token('color.container.interactive.active')
          }
        }
      },

      link: {
        font: {
          size: ({ token }) => token('typography.size.xl'),
          weight: ({ token }) => token('typography.weight.normal')
        }
      },

      danger: {
        font: {
          size: ({ token }) => token('typography.size.xl'),
          weight: ({ token }) => token('typography.weight.normal')
        }
      }
    },

    field: {
      margin: {
        top: '.8rem',
        bottom: '0'
      }
    },

    // NOTE: Disabled hover and focus states to align with current Spoke.
    // Eventually we'll want to use these hover and focus states to improve
    // the ux and accessibility.
    textInput: {
      focus: {
        shadow: 'none',
        border: { color: '#8aa0ae' } // blue_grey
      },

      hintText: {
        color: '#ccd6de', // blue_grey_light

        font: {
          size: '1.8rem',
          weight: ({ token }) => token('typography.weight.light')
        }
      },

      charLimit: {
        color: ({ token }) => token('color.textStyle.body.subtext'),
        font: {
          weight: ({ token }) => token('typography.weight.light')
        }
      }
    },

    checkbox: {
      label: {
        font: {
          size: '1.5rem',
          weight: ({ token }) => token('typography.weight.light')
        }
      },
      size: '2.4rem',
      tick: {
        height: '1rem',
        width: '0.5rem'
      }
    },

    // This doesn't work for setting the size in side a button. Not sure if this works else where.
    loadingSpinner: {
      size: 35,
      colors: ({ token }) => [token('color.primary.idle.default')]
    },

    banner: {
      title: {
        ...margin.tokens({
          bottom: 'xs'
        })
      },
      minHeight: '6.6rem',
      compact: {
        minHeight: '8.2rem'
      },
      ...border.tokens({
        all: {
          radius: 'xs'
        }
      })
    },

    toast: {
      target: {
        padding: {
          top: () => '6rem'
        }
      }
    }
  },

  /**
   * SPOKE CUSTOM COMPONENTS
   * These are tokens for custom Spoke components
   **/
  {
    campaignStatus: {
      active: ({ token }) => token('palette.brand.700'),
      error: ({ token }) => token('color.danger.idle.default'),
      archived: ({ token }) => token('color.textStyle.body.default'),
      expiring: ({ token }) => token('color.warning.idle.default'),
      paused: ({ token }) => token('palette.brand.700'),
      draft: ({ token }) => token('color.neutral.idle.default'),
      inReview: ({ token }) => token('color.neutral.idle.default'),
      completed: ({ token }) => token('color.textStyle.body.default'),
      scheduled: ({ token }) => token('color.textStyle.body.default'),
      processing: ({ token }) => token('color.neutral.idle.default')
    },

    toggle: {
      color: ({ token }) => token('color.input.idle'),

      background: {
        idle: ({ token }) => token('color.neutral.idle.default'),
        selected: ({ token }) => token('color.input.selected')
      }
    },

    campaignReport: {
      deviceStats: {
        color: ({ token }) => token('palette.indigo.600')
      },
      demographicStats: {
        color: ({ token }) => token('palette.brand.700')
      }
    }
  },

  /**
   * CUSTOM LEGACY TOKENS
   * The purpose of these tokens is just to replace the old constants to then more easily
   * gradually replace them with proper luna semantic tokens instead
   **/
  {
    legacy: {
      color: {
        primary: {
          default: '#00c278',
          light: '#00d176',
          dark: '#00b570',
          opacity10: 'rgba(0, 194, 120, 0.1)',
          opacity80: 'rgba(0, 194, 120, 0.8)'
        },
        secondary: {
          default: '#00c278',
          light: '#00C278',
          lighter: '#00DB88',
          lightest: '#00EB91',
          opacity10: 'rgba(0, 194, 120, 0.1)',
          opacity50: 'rgba(0, 194, 120, 0.5)',
          opacity80: 'rgba(0, 194, 120, 0.8)'
        },
        background: '#f0f5f7',
        integration: {
          facebook: '#486bb3',
          siteloft: '#e9bb00',
          crm: '#008dfc'
        },
        stats: {
          blue: '#4a90e2',
          red: '#f84c4c'
        },

        spoke: '#00c278',
        white: '#fff',
        black: '#000',

        green: {
          dark: '#00b570'
        },
        red: {
          default: '#f65c5c',
          light: '#ff7878',
          pale: '#ffe0e0',
          dark: '#cf4040'
        },
        yellow: {
          default: '#f5e593'
        },
        orange: {
          default: '#f5b300',
          light: '#fff3c6'
        },
        brown: {
          default: '#825F00'
        },
        blue: {
          default: '#0080ca',
          dark: '#002842',
          grey: '#8aa0ae',
          greyLight: '#ccd6de'
        },
        purple: {
          default: '#d894e6',
          light: '#eac5f1',
          dark: '#ab7bb4'
        },
        grey: {
          default: '#dee6ec',
          light: '#f0f5f7',
          lighter: '#fafcfd',
          medium: '#e0e5e7',
          dark: '#93969A'
        }
      }
    }
  }
);

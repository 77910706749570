import { PaginationParams } from 'src/types';

export type AllVariables = {
  accountId: string;
};

export type ListVariables = AllVariables &
  PaginationParams & {
    q?: string;
  };

export type DetailVariables = AllVariables & {
  testimonialId: string;
};

export const testimonialsKeys = {
  all: ({ accountId }: AllVariables) => [accountId, 'testimonials'] as const,
  lists: (vars: AllVariables) =>
    [...testimonialsKeys.all(vars), 'list'] as const,
  list: ({ accountId, ...vars }: ListVariables) =>
    [...testimonialsKeys.lists({ accountId }), vars] as const,
  details: (vars: AllVariables) =>
    [...testimonialsKeys.all(vars), 'detail'] as const,
  detail: ({ accountId, testimonialId }: DetailVariables) => [
    ...testimonialsKeys.details({ accountId }),
    testimonialId
  ]
};

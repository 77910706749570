import { GhostIconButton } from '@rexlabs/button';
import Icons from '@rexlabs/icons-next';
import { ToastTarget } from '@rexlabs/notifications';
import {
  border,
  margin,
  padding,
  StyleSheet,
  useStyles
} from '@rexlabs/styling';
import { Heading, Small } from '@rexlabs/text';
import React, { PropsWithChildren, ReactNode } from 'react';
import { StyleProps } from './base';

const styles = StyleSheet({
  container: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'stretch',

    '& > * + *': {
      ...border.styles({
        top: {
          width: 'thin',
          color: 'container.static.medium'
        }
      })
    }
  },

  header: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    ...padding.styles({
      bottom: 'xl'
    }),

    '& > * + *': {
      ...margin.styles({
        top: 'xs'
      })
    }
  },

  title: {
    display: 'flex',
    flex: 1,
    flexDirection: 'column',
    '& > * + *': {
      ...margin.styles({
        top: 'xs'
      })
    }
  },

  content: {
    ...padding.styles({
      top: 'xl'
    })
  }
});

export interface ModalContentProps {
  title: ReactNode;
  subtitle?: ReactNode;
  close?(): void;
}

export type Props = PropsWithChildren<ModalContentProps & StyleProps>;

export function ModalContent({
  title,
  subtitle,
  close,
  className,
  style,
  children
}: Props) {
  const s = useStyles(styles);
  return (
    <div {...s('container')}>
      <header {...s('header')}>
        <div {...s('title')}>
          <Heading level={2} as='span'>
            {title}
          </Heading>
          {subtitle && (
            <Small grey as='span'>
              {subtitle}
            </Small>
          )}
        </div>
        {close && <GhostIconButton Icon={Icons.CrossLarge} onClick={close} />}
      </header>
      <div {...s.with('content')({ className, style })}>
        <ToastTarget name='modal' position='top' />
        {children}
      </div>
    </div>
  );
}

import { Box } from '@rexlabs/box';
import { border, padding, StyleSheet, useStyles } from '@rexlabs/styling';
import React from 'react';
import Money from '../money';

const styles = StyleSheet({
  row: {
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'row',
    ...padding.styles({
      x: 'm',
      y: 's'
    })
  },

  header: {
    fontWeight: ({ token }) => token('typography.weight.bold'),
    ...border.styles({
      bottom: {
        width: 'medium',
        color: 'primary.idle.default'
      }
    }),
    ...padding.styles({
      bottom: ({ token }) => `${token('spacing.xs')} !important`,
      top: ({ token }) => `${token('spacing.l')} !important`
    }),

    '& > *': {
      fontWeight: 'inherit'
    }
  },

  network: {
    fontWeight: ({ token }) => token('typography.weight.bold'),
    flex: 10
  },

  amount: {
    flex: 2
  },

  body: {
    '& > * + *': {
      ...border.styles({
        top: {
          width: 'thin',
          color: 'container.static.light'
        }
      })
    }
  }
});

export interface PackageAllocation {
  id: string;
  label: string;
  amount: number;
}

export interface PackageAllocationTableProps {
  allocation: PackageAllocation[];
  currencyCode: string;
}

export function PackageAllocationTable({
  allocation,
  currencyCode
}: PackageAllocationTableProps) {
  const s = useStyles(styles);
  return (
    <Box flexDirection='column'>
      <Box {...s('header', 'row')}>
        <span {...s('network')}>Ad networks</span>
        <span {...s('amount')}>Amount</span>
      </Box>
      <Box {...s('body')}>
        {allocation.map(({ id, label, amount }) => (
          <Box key={id} {...s('row')}>
            <span {...s('network')}>{label}</span>
            <span {...s('amount')}>
              <Money amount={amount} integer currencyCode={currencyCode} />
            </span>
          </Box>
        ))}
      </Box>
    </Box>
  );
}

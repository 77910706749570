export default {
  namespace: 'listings',

  // Sorting options
  sorting: {},

  // Filter options
  filters: {
    search: {
      label: 'Search'
    },
    current_tab: {
      label: 'Tabs'
    }
  }
};

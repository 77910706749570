import { Box } from '@rexlabs/box';
import { GhostButton, PrimaryButton } from '@rexlabs/button';
import { query, withQuery, withValueLists } from '@rexlabs/model-generator';
import { useToast } from '@rexlabs/notifications';
import { withWhereabouts } from '@rexlabs/react-whereabouts';
import { useToken } from '@rexlabs/styling';
import { match } from '@rexlabs/whereabouts';
import React, { useCallback } from 'react';
import { Helmet } from 'react-helmet';
import { compose } from 'redux';
import campaignsModel from 'src/data/models/entities/campaigns';
import campaignGoalsModel from 'src/data/models/value-lists/campaign-goals';
import networkAdTypesModel from 'src/data/models/value-lists/network-ad-types';
import { formattedBrandName } from 'src/theme';
import { createValidationRules } from 'src/utils/form';
import { StickyButtonGroup } from 'src/view/components/button';
import ComponentRenderer from 'src/view/components/component-renderer';
import { Form, ReactForms } from 'src/view/components/form';
import HealthIndicator from 'src/view/components/health-indicator';
import { RenderLoading } from 'src/view/components/loading';
import { Map, MapInfo } from 'src/view/components/map';
import { FullscreenModal } from 'src/view/components/modal';
import { PortalDelayed } from 'src/view/components/portal';
import { Heading } from 'src/view/components/text';
import withError from 'src/view/containers/with-error';
import EditAudienceForm, {
  addressRequiredValidation,
  handleSubmit,
  mapPropsToValues,
  validationRules
} from 'src/view/forms/edit-audience';

const fullScreenModalStyles = {
  inner: {
    '&::before': {
      width: `${102.4 * 0.5}rem`
    }
  },

  wrapLeft: {
    width: '50%'
  },

  wrapRight: {
    width: '50%'
  }
};

const getId = (props) => {
  const { whereabouts } = props;
  const matched = match(whereabouts, {
    path: '/:accountId?/campaign/create/:type/:campaignId'
  });
  return matched ? matched.params.campaignId : null;
};

const campaignsQuery = query`{
  ${campaignsModel} (id: ${getId}) {
    id
    type
    goal
    lead_generation_form_enabled
    campaign_template {
      type
      slug
      campaign_template_configuration {
        id
        configuration
      }
    }
    audience_spec {
      id
      networks
      segments
      age_range {
        youngest
        oldest
      }
      languages
      locations
      user_lists
    }
    ad_content_sets {
      attributes
      type {
        id
      }
    }
  }
}`;

const validate = (values) => {
  return createValidationRules(
    Object.assign(
      {},
      validationRules,
      values.type === 'single_listing' ? addressRequiredValidation : {}
    )
  )(values);
};

const EditAudienceModal = (props) => {
  const {
    campaigns,
    closeModal,
    errorModal,
    errorModal: { Error }
  } = props;

  const token = useToken();
  const { addToast } = useToast();

  const campaignTemplate =
    campaigns.item.data?.campaign_template?.campaign_template_configuration
      ?.configuration;

  const onSubmit = useCallback(
    async (values) => {
      try {
        await handleSubmit(values, {
          props: { ...props }
        });

        closeModal();
        addToast({ title: 'Your audience has been successfully saved' });
      } catch (e) {
        errorModal.open(e);
      }
    },
    [addToast, closeModal, errorModal, props]
  );

  const isLoading = campaigns?.item?.status === 'loading';

  const initialValues = mapPropsToValues(props);
  const campaignType = campaigns?.item?.data?.campaign_template?.slug;
  return (
    <Box flex={1} flexDirection='column'>
      <Helmet>
        <title>{formattedBrandName} - Edit Audience Details</title>
      </Helmet>
      <Box pb={token('spacing.xl')}>
        <Heading>Edit audience details</Heading>
      </Box>
      <RenderLoading minHeight='50rem' isLoading={isLoading}>
        <ReactForms
          initialValues={initialValues}
          handleSubmit={onSubmit}
          validate={validate}
        >
          {({
            values,
            errors,
            touched,
            setFieldValue,
            setFieldError,
            submitForm,
            isSubmitting,
            setErrors
          }) => {
            const markers = [
              values?.address?.data?.center,
              ...(values?.suburbs ?? []).map((s) => s?.data?.center)
            ];

            return (
              <>
                <Form
                  name='editAudienceForm'
                  data-testid={
                    Object.values(values).length > 0 && 'edit-audience-form'
                  }
                >
                  <EditAudienceForm
                    validate={validate}
                    campaignType={campaignType}
                    values={values}
                    errors={errors}
                    touched={touched}
                    setFieldValue={setFieldValue}
                    setFieldError={setFieldError}
                    setErrors={setErrors}
                    campaigns={campaigns}
                    campaignTemplate={campaignTemplate}
                  />

                  <StickyButtonGroup mt={token('spacing.s')} end>
                    <GhostButton onClick={closeModal}>Cancel</GhostButton>
                    <PrimaryButton
                      onClick={submitForm}
                      isLoading={isSubmitting}
                    >
                      Save
                    </PrimaryButton>
                  </StickyButtonGroup>
                </Form>

                <PortalDelayed target='contentRight'>
                  <div style={{ position: 'sticky', top: 0 }}>
                    <ComponentRenderer
                      template={campaignTemplate}
                      resource='audience'
                      section='preview'
                      components={{ Box, HealthIndicator, Map, MapInfo }}
                      extraPropsFn={({ type }) => {
                        let extraProps;

                        if (type === 'HealthIndicator') {
                          extraProps = {
                            isLoading,
                            geoRange: values?.radius,
                            suburbs: values?.suburbs || [],
                            ageRange: values?.age_range,
                            segments: values?.segments || []
                          };
                        }

                        if (type === 'Map') {
                          extraProps = {
                            center: values?.address?.data?.center,
                            markers: markers,
                            show: markers?.length,
                            radius: values?.radius,
                            unit: values?._radius?.unit,
                            dragPan: true,
                            onRadiusChange: (newRadius) => {
                              setFieldValue('radius', newRadius);
                            }
                          };
                        }

                        if (type === 'MapInfo') {
                          extraProps = {
                            type: campaigns?.item?.data?.type?.id
                          };
                        }

                        return extraProps;
                      }}
                    />
                  </div>
                </PortalDelayed>

                <Error />
              </>
            );
          }}
        </ReactForms>
      </RenderLoading>
    </Box>
  );
};

// NOTE: forms use context, which gets lost through portals, so we do the modal
// portaling here first and then render the form inside!
const Core = (props) => {
  return (
    <FullscreenModal styles={fullScreenModalStyles} onClose={props.closeModal}>
      <EditAudienceModal {...props} />
    </FullscreenModal>
  );
};

export default compose(
  withError('errorModal'),
  withQuery(campaignsQuery),
  withValueLists(campaignGoalsModel, networkAdTypesModel),
  withWhereabouts()
)(Core);

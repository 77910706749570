import { GhostButton, PrimaryButton } from '@rexlabs/button';
import React, { FC, useCallback } from 'react';
import { useQueryClient } from 'react-query';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { ButtonGroup, Spacer } from 'src/components/elements/button-group';
import { useAccountId } from 'src/features/accounts';
import { campaignAdsKeys } from 'src/features/ads/api/keys';
import { useCampaignId } from 'src/features/campaigns';
import { useUpdateCampaign } from 'src/features/campaigns/api/updateCampaign';
import { useContentTypeSelections } from '../../stores/contentTypes';
import { getContentTypeDefinition } from '../../utils/contentTypes';
import { ContentTypeSelector } from '../ContentTypeSelector';
import { StepPreviewLayout } from '../StepPreviewLayout';

const ProvideDetailsEditStep: FC = () => {
  const { accountIndex, contentType = '' } = useParams();

  const accountId = useAccountId();
  const campaignId = useCampaignId();
  const queryClient = useQueryClient();
  const navigate = useNavigate();

  const { contentSources, isValid } = useContentTypeSelections();
  const { title, subtitle } = getContentTypeDefinition(contentType);

  const { mutateAsync } = useUpdateCampaign();
  const handleCreate = useCallback(async () => {
    const campaign = await mutateAsync(
      {
        campaignId,
        data: {
          content_source: {
            entities: Object.entries(contentSources).flatMap(([type, ids]) =>
              ids.map((id) => ({ type, id }))
            )
          }
        }
      },
      {
        onSuccess: () =>
          queryClient.removeQueries(campaignAdsKeys.all(accountId, campaignId))
      }
    );
    navigate(`/${accountIndex}/campaigns/${campaign.id}/edit/d`);
  }, [
    mutateAsync,
    campaignId,
    contentSources,
    navigate,
    accountIndex,
    queryClient,
    accountId
  ]);

  return (
    <StepPreviewLayout title={title} subtitle={subtitle}>
      <ContentTypeSelector />
      <ButtonGroup sticky spacing='s'>
        <Link to={`/${accountIndex}/campaigns/create`} reloadDocument>
          <GhostButton>Back</GhostButton>
        </Link>
        <Spacer />
        <PrimaryButton isDisabled={!isValid} onClick={handleCreate}>
          Update
        </PrimaryButton>
      </ButtonGroup>
    </StepPreviewLayout>
  );
};

export default ProvideDetailsEditStep;

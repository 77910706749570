import { track } from '@rexlabs/analytics';
import { Box } from '@rexlabs/box';
import { styled, StyleSheet } from '@rexlabs/styling';
import React, { useState } from 'react';
import { formattedBrandName } from 'src/theme';
import EditAgencyModal from 'src/view/modals/admin/settings/edit-agency';
import Avatar from 'view/components/avatar';
import { Checkbox } from 'view/components/input';
import { Body } from 'view/components/text';

const defaultStyles = StyleSheet({
  container: {
    width: '100%',
    padding: ({ token }) => token('spacing.m'),
    flexShrink: 0
  },

  containerClickable: {
    cursor: 'pointer'
  },

  containerSelected: {
    background: ({ token }) => token('legacy.color.grey.light')
  },

  wrapImage: {
    width: '8rem',
    height: '8rem',
    background: ({ token }) => token('legacy.color.blue.grey'),
    marginRight: ({ token }) => token('spacing.m'),
    borderRadius: '50%'
  },

  text: {
    margin: 0,
    padding: 0
  },

  textMeta: {
    color: ({ token }) => token('legacy.color.blue.grey')
  }
});

function AgencyListItem({ styles: s, selected, onClick, data }) {
  const [showEditModal, setShowEditModal] = useState(false);

  return (
    <Box
      {...s('container', {
        containerSelected: selected,
        containerClickable: onClick
      })}
      flexDirection='row'
      alignItems='center'
      justifyContent='space-between'
      onClick={onClick}
      data-testid={`agency-${data?.id}`}
    >
      <Avatar
        round
        imageContain
        bgColor={data?.brand_colours?.background}
        src={data?.logo?.sizes?.['original.thumbnail']?.url}
      />
      <Box flex={1} flexDirection='column'>
        <Body {...s('text')}>{data.business_name}</Body>
        <Body {...s('text', 'textMeta')}>Office profile</Body>
        <Body {...s('text', 'textMeta')}>
          Created in {formattedBrandName} ∙{' '}
          <a
            onClick={() => {
              track({
                event: 'Spoke edit agency',
                properties: {
                  action: 'edit_agency_clicked'
                }
              });
              setShowEditModal(true);
            }}
          >
            Edit
          </a>
        </Body>
        {showEditModal && (
          <EditAgencyModal closeModal={() => setShowEditModal(false)} />
        )}
      </Box>
      <Box>
        <Checkbox id={data.id} name='agent' value={selected} round />
      </Box>
    </Box>
  );
}

export default styled(defaultStyles)(AgencyListItem);

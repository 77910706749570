import React, { FC, ReactNode } from 'react';
import { StyleSheet, useStyles } from '@rexlabs/styling';
import instagramIconChevronLeft from 'shared/assets/instagram/icon-chevron-left.png';
import instagramIconChevronRight from 'shared/assets/instagram/icon-chevron-right.png';

const styles = StyleSheet({
  wrapCarousel: {
    width: '100%',
    height: 318,
    position: 'relative',
    overflow: 'hidden'
  },

  carousel: {
    display: 'flex',
    flexDirection: 'row',
    width: '400%',
    transition: 'transform .4s',
    whiteSpace: 'nowrap',
    position: 'relative'
  },

  carouselItem: {
    width: 318,
    height: 318,
    overflow: 'hidden'
  },

  carouselPrev: {
    zIndex: 20,
    position: 'absolute',
    top: '50%',
    left: '.8rem',
    marginTop: '-1.5rem',
    width: '3rem',
    height: '3rem',
    background: `url(${instagramIconChevronLeft}) transparent no-repeat center center`,
    backgroundSize: 'contain',
    opacity: 0.8,
    transition: 'opacity .3s',
    border: '0 none',
    cursor: 'pointer',

    ':hover': {
      opacity: 1
    }
  },

  carouselNext: {
    zIndex: 20,
    position: 'absolute',
    top: '50%',
    right: '.8rem',
    marginTop: '-1.5rem',
    width: '3rem',
    height: '3rem',
    background: `url(${instagramIconChevronRight}) transparent no-repeat center center`,
    backgroundSize: 'contain',
    opacity: 0.8,
    transition: 'opacity .3s',
    border: '0 none',
    cursor: 'pointer',

    ':hover': {
      opacity: 1
    }
  },

  disabled: {
    opacity: '0 !important'
  }
});

export type CarouselProps = {
  slidePos: number;
  setSlidePos: (x: number) => void;
  children: ReactNode;
};

export const Carousel: FC<CarouselProps> = ({
  slidePos,
  setSlidePos,
  children
}) => {
  const s = useStyles(styles);
  const slideCount = React.Children.toArray(children).length - 1;

  const getCarouselPrev = () => setSlidePos(slidePos > 0 ? slidePos - 1 : 0);

  const getCarouselNext = () =>
    setSlidePos(slidePos < slideCount ? slidePos + 1 : slideCount);

  const carouselPosition = -318 * slidePos;

  return (
    <div {...s('wrapCarousel')}>
      <button
        {...s('carouselPrev', { disabled: slidePos === 0 })}
        onClick={getCarouselPrev}
        type='button'
      />
      <button
        {...s('carouselNext', { disabled: slidePos === slideCount })}
        onClick={getCarouselNext}
        type='button'
      />
      <div
        {...s.with('carousel')({
          transform: `translateX(${carouselPosition}px)`
        })}
      >
        {React.Children.map(children, (child) => (
          <div {...s('carouselItem')}>{child}</div>
        ))}
      </div>
    </div>
  );
};

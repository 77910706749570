import { Box } from '@rexlabs/box';
import { OutlineButton } from '@rexlabs/button';
import CSSAnimationGroup from '@rexlabs/css-animation-group';
import { StyleSheet, useStyles, useToken } from '@rexlabs/styling';
import React, { ComponentType, ReactNode, useState } from 'react';
import { KEYFRAMES } from 'src/theme';
import EmptyStateSection from 'src/view/components/empty-state-section';
import { LoadingSpinner } from 'src/view/components/loading';
import { Body, Small } from 'src/view/components/text';

const defaultStyles = StyleSheet({
  container: {
    width: '100%'
  },

  wrapItem: {
    width: '100%',
    opacity: 1
  },

  seperator: {
    width: '100%',
    height: '.1rem',
    background: ({ token }) => token('legacy.color.blue.greyLight'),

    '@media print': {
      display: 'none'
    }
  },

  wrapEmpty: {
    width: '100%',
    padding: ({ token }) => token('spacing.xxxl')
  },

  wrapLoading: {
    width: '100%',
    padding: ({ token }) => token('spacing.xxxl'),
    minHeight: '40rem'
  },

  animationGroup: {
    width: '100%'
  }
});

export interface ListProps<T> {
  Header: ComponentType<{ isLoading: boolean }>;
  isFetching?: boolean;
  isLoading?: boolean;
  endReached?: boolean;
  onLoadMore?: () => void;
  Item: ComponentType<{
    data: T;
    index: number;
  }>;
  items: T[];
  animateRows?: boolean;
  getItemKey: (data: T, index: number) => string;
  emptyStateProps?: {
    largeText: ReactNode;
    smallText: ReactNode;
    minHeight?: string;
  };
}

function List<T>({
  Header,
  isFetching = false,
  isLoading = false,
  endReached = true,
  Item,
  onLoadMore,
  items = [],
  animateRows = true,
  getItemKey = (_, i) => i.toString(),
  emptyStateProps
}: ListProps<T>) {
  const s = useStyles(defaultStyles);
  const token = useToken();

  const [lastIndex, setLastIndex] = useState(0);

  const loadMore = () => {
    setLastIndex(items.length);
    onLoadMore?.();
  };

  if (!(isLoading || items.length)) {
    return emptyStateProps ? (
      <Box {...s('container')}>
        <EmptyStateSection key='EmptyStateSection' {...emptyStateProps} />
      </Box>
    ) : (
      <Box
        {...s('container', 'wrapEmpty')}
        alignItems='center'
        justifyContent='center'
        flexDirection='column'
        key='EmptyDefault'
      >
        <Body>No results found!</Body>
      </Box>
    );
  }

  const ListItemWrapper = animateRows
    ? ({ children }) => (
        <CSSAnimationGroup
          enterAnimation={KEYFRAMES.LIST_ITEM_ENTER}
          enterFillMode='both'
          enterDuration='800ms'
          enterTimingFunction='ease-out'
          {...s('animationGroup')}
        >
          {children}
        </CSSAnimationGroup>
      )
    : ({ children }) => <>{children}</>;

  return (
    <Box {...s('container', 'wrapItems')}>
      {Header && (
        <>
          <Header isLoading={isLoading} />
          <Box {...s('seperator')} />
        </>
      )}
      {isLoading && (
        <div key='Loading'>
          <Box
            {...s('wrapLoading')}
            alignItems='center'
            justifyContent='center'
            flexDirection='column'
          >
            <Box
              alignItems='center'
              justifyContent='center'
              flexDirection='column'
            >
              <LoadingSpinner />
              <Small grey>Loading...</Small>
            </Box>
          </Box>
        </div>
      )}

      {!isLoading && (
        <ListItemWrapper>
          {items.map((data, index) => {
            const itemStyles = s.with('wrapItem')(
              animateRows
                ? {
                    animationDelay: `${(index - lastIndex) * 100}ms`
                  }
                : { opacity: 1 }
            );

            return (
              <div key={getItemKey(data, index)} {...itemStyles}>
                <Item data={data} index={index} />
                {index < items.length - 1 && <Box {...s('seperator')} />}
              </div>
            );
          })}
        </ListItemWrapper>
      )}

      {!isLoading && !endReached && onLoadMore && (
        <Box
          mb={token('spacing.s')}
          style={{ width: '100%' }}
          justifyContent='center'
          alignItems='center'
        >
          <OutlineButton onClick={loadMore} isLoading={isFetching}>
            Load more
          </OutlineButton>
        </Box>
      )}
    </Box>
  );
}

export default List;

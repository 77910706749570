import { useQuery, UseQueryResult } from 'react-query';
import { useAccountId } from 'src/features/accounts';
import { QueryConfig } from 'src/lib/react-query';
import { api } from 'src/utils/api-client';
import { Campaign } from '../types';
import { createCampaign } from '../utils/schemas';
import { campaignKeys } from './keys';

export type GetCampaignParams = {
  accountId: string;
  campaignId: string;
  includes?: string[];
};

export const getCampaign = async ({
  campaignId,
  accountId,
  includes = []
}: GetCampaignParams): Promise<Campaign> => {
  const { data } = await api.get(`/campaigns/${campaignId}`, {
      include: includes.join(',')
    },
    {
      headers: {
        'X-Account-Id': accountId
      }
    }
  );
  return createCampaign(data);
};

type QueryFn = typeof getCampaign;

export type UseCampaignOptions = Omit<GetCampaignParams, 'accountId'> & {
  campaignId: string;
  config?: QueryConfig<QueryFn>;
};

export const useCampaign = ({
  campaignId,
  config = {},
  ...params
}: UseCampaignOptions): UseQueryResult<Campaign, Error> => {
  const accountId = useAccountId();

  return useQuery<Campaign, Error>({
    queryKey: campaignKeys.detail(accountId, campaignId),
    queryFn: () => getCampaign({ accountId, campaignId, ...params }),
    ...config
  });
};

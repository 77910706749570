import {
  border,
  margin,
  padding,
  StyleSheet,
  useStyles
} from '@rexlabs/styling';
import { Body, Heading } from '@rexlabs/text';
import React, { FC } from 'react';
import { Audience } from '../types';
import { GENERAL_AUDIENCES } from '../utils/audienceGroups';
import { calcAudienceHealth } from '../utils/audienceHealth';
import { isRadiusLocation, isSuburbLocation } from '../utils/helpers';
import { AudienceMap } from './AudienceMap';
import { AudienceSizeGauge } from './AudienceSizeGauge';

const styles = StyleSheet({
  container: {
    display: 'flex',
    alignItems: 'stretch',
    width: '100%',
    minHeight: '45.9rem',
    overflow: 'hidden',
    backgroundColor: ({ token }) =>
      token('color.container.static.preview.default'),
    ...border.styles({
      all: {
        radius: 'l'
      }
    })
  },

  containerVertical: {
    flexDirection: 'column',
    alignItems: 'center'
  },

  map: {
    display: 'flex',
    flex: '1 1 0%',
    flexDirection: 'column',
    position: 'relative'
  },

  mapVertical: {
    width: '100%'
  },

  size: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    width: '38rem',
    ...padding.styles({
      all: 'xl'
    }),

    '& > * + *': {
      ...margin.styles({
        top: 's'
      })
    }
  },

  sizeVertical: {
    order: -1
  },

  title: {
    fontWeight: ({ token }) => token('typography.weight.light'),
    textAlign: 'center'
  },

  description: {
    textAlign: 'center'
  },

  highlight: {
    color: ({ token }) => token('color.textStyle.information.idle.default'),
    fontWeight: ({ token }) => token('typography.weight.bold')
  }
});

export type AudiencePreviewProps = {
  editable?: boolean;
  audience: Audience;
};

export const AudiencePreview: FC<AudiencePreviewProps> = ({
  audience,
  editable = false
}) => {
  const s = useStyles(styles, 'AudiencePreview');

  const ageRange: [number, number] = [
    audience.age_range.youngest,
    audience.age_range.oldest
  ];
  const ageRangeText = ageRange.join(' - ');
  const segments = audience.segments;
  const mainLocation = audience.locations.find(isRadiusLocation);
  const mainLocationRadius = mainLocation?.radius.value;
  const mainLocationCoordinates = mainLocation
    ? {
        lat: mainLocation.address.latitude,
        lng: mainLocation.address.longitude
      }
    : undefined;
  const additionalLocations = audience.locations
    .filter(isSuburbLocation)
    .map((i) => ({ lat: i.suburb.latitude, lng: i.suburb.longitude }));

  const generalAudiences = segments
    .filter((v) => GENERAL_AUDIENCES.includes(v.id))
    .map((v) => v.id);
  const retargetingAudiences = segments
    .filter((v) => !GENERAL_AUDIENCES.includes(v.id))
    .map((v) => v.id);

  const health = calcAudienceHealth({
    geoRange: mainLocationRadius,
    ageRange,
    generalAudiences,
    retargetingAudiences,
    segments: segments.map((v) => v.id)
  });

  return (
    <div
      {...s('container', {
        containerVertical: editable
      })}
    >
      <div
        {...s('map', {
          mapVertical: editable
        })}
      >
        <AudienceMap
          mainPoint={mainLocationCoordinates}
          radius={mainLocationRadius}
          unit={mainLocation?.radius.unit}
          interactive={editable}
          additionalPoints={additionalLocations}
          style={editable ? { height: '35rem' } : undefined}
        />
      </div>
      <div {...s('size', { sizeVertical: editable })}>
        <Heading level={3} {...s('title')}>
          Audience Size
        </Heading>

        <AudienceSizeGauge value={health.degrees} />

        {!editable ? (
          <Body {...s('description')}>
            Your ads will be served to{' '}
            <span {...s('highlight')}>{ageRangeText} year old</span> people who
            are{' '}
            {segments.map(({ label }, i) => (
              <span key={i}>
                {i === 0 ? (
                  ''
                ) : i === segments.length - 1 ? (
                  <span> and </span>
                ) : (
                  <span {...s('highlight')}>, </span>
                )}
                <span {...s('highlight')}>{label.toLowerCase()}</span>
              </span>
            ))}
            .
          </Body>
        ) : null}
      </div>
    </div>
  );
};

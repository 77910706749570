import { RexApiError } from '@rexlabs/api-client';
import { useQuery } from 'react-query';
import { useAccountId } from 'src/features/accounts';
import { QueryConfig, QueryResult } from 'src/lib/react-query';
import { api } from 'src/utils/api-client';
import { AdContentSet } from '../types';
import { adContentSetSchema } from '../utils/schemas';
import { campaignAdsKeys } from './keys';

export type GetCampaignAdContentSetParams = {
  accountId: string;
  campaignId: string;
  adContentSetId: string;
};

export const getCampaignAdContentSet = async ({
  accountId,
  campaignId,
  adContentSetId
}: GetCampaignAdContentSetParams): Promise<AdContentSet> => {
  const { data } = await api.get(
    `/campaigns/${campaignId}/ad-content-sets/${adContentSetId}`,
    {},
    {
      headers: {
        'X-Account-Id': accountId
      }
    }
  );
  return adContentSetSchema.create(data);
};

type QueryFn = typeof getCampaignAdContentSet;

export type UseCampaignAdContentSetOptions = Omit<
  GetCampaignAdContentSetParams,
  'accountId'
> & {
  config?: QueryConfig<QueryFn>;
};

export const useCampaignAdContentSet = ({
  config,
  adContentSetId,
  campaignId
}: UseCampaignAdContentSetOptions): QueryResult<AdContentSet> => {
  const accountId = useAccountId();

  return useQuery<AdContentSet, RexApiError>({
    queryKey: campaignAdsKeys.detail(accountId, campaignId, adContentSetId),
    queryFn: () =>
      getCampaignAdContentSet({ accountId, campaignId, adContentSetId }),
    ...config
  });
};

import React, { CSSProperties, ReactNode } from 'react';
import { Body } from '@rexlabs/text';
import { margin, StyleSheet, useStyles } from '@rexlabs/styling';

export type LeadDestinationPreviewProps = {
  destinations: ReactNode[];
  className?: string;
  style?: CSSProperties;
};

const styles = StyleSheet({
  container: {
    '& > * + *': {
      ...margin.styles({
        top: 's'
      })
    }
  },
  destinations: {
    listStyle: 'disc inside'
  }
});

export const LeadDestinationPreview = ({
  destinations,
  className,
  style
}: LeadDestinationPreviewProps) => {
  const s = useStyles(styles);

  return (
    <div {...s('container')}>
      <Body as='span'>Lead emails will be sent to:</Body>
      <ul {...s.with('destinations')({ className, style })}>
        {destinations.map((dest, i) => (
          <li key={i}>{dest}</li>
        ))}
      </ul>
    </div>
  );
};

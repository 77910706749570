import { Body } from '@rexlabs/text';
import React, { FC } from 'react';
import { FormGuideCard, FormGuideLayout } from 'src/features/wizard';

export const CatalogListingSetFormGuide: FC = () => {
    return (
        <FormGuideLayout>
            <FormGuideCard title='Catalogue Name'>
                <Body as='div'>
                  {'Name the catalogue for simple identification in Reach and as a reminder of what of the catalogue settings are, ‘Tom’s Residential Just Solds’, for example.'}
                </Body>
            </FormGuideCard>

            <FormGuideCard title='Listing Types'>
              <Body as='div'>
                {'Select the types of listings you would like to include in this catalogue. ‘Only include sold listings’ is great for advertising your most recent Just Sold’s of each listing type.'}
              </Body>
            </FormGuideCard>

            <FormGuideCard title='Listing Min & Max Price'>
              <Body as='div'>
                {'If provided Reach will only use listings that are above the specified min price and below the specified max price. Both fields are optional and can be used independently of each other.'}
              </Body>
            </FormGuideCard>

            <FormGuideCard title='Limit'>
              <Body as='div'>
                {'Limit the maximum number of listings included in your catalogue. Setting a lower limit will mean a higher spend per listing ad, while a higher limit means more listings are advertised overall but with less spend.'}
              </Body>
            </FormGuideCard>

            <FormGuideCard title='Sort Listings By'>
              <Body as='div'>
                {"By ‘most recently created’ will advertise the newest listings that match your catalogue first. By ‘most recently sold’ will advertise the most recently sold listings in the catalogue first."}
              </Body>
            </FormGuideCard>

            <FormGuideCard title='Listing Agent'>
              <Body as='div'>
                {"If a specific agent is chosen, only their listings will be advertised in the  catalogue. Otherwise Reach will display the agency logo instead."}
              </Body>
            </FormGuideCard>
        </FormGuideLayout>
    );
};

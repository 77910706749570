import { Box } from '@rexlabs/box';
import { styled, StyleSheet } from '@rexlabs/styling';
import React from 'react';
import WIZARD from 'src/routes/wizard';
import { formattedBrandName } from 'src/theme';
import { Link } from 'src/utils/whereabouts';
import { Label, Tiny } from 'src/view/components/text';

const defaultStyles = StyleSheet({
  content: {
    '& svg': {
      margin: '0.8rem'
    },

    '& p': {
      flex: '1 1 90%',
      color: ({ token }) => token('color.textStyle.body.subtext')
    }
  }
});

const ListingDataSource = ({ dataSource, listingId, styles: s }) => {
  return (
    <>
      <Label>Listing Data</Label>
      <Box flexDirection='row' {...s('content')}>
        {dataSource === 'spoke' ? (
          <Tiny>
            Listing data comes from the listing you&apos;ve created in{' '}
            {formattedBrandName}. You can{' '}
            <Link to={WIZARD.CAMPAIGN.EDIT_LISTING} params={{ listingId }}>
              {({ onClick, target }) => (
                <a href={target} onClick={onClick}>
                  change listing details
                </a>
              )}
            </Link>{' '}
            and {formattedBrandName} will reflect them on the landing page.
          </Tiny>
        ) : (
          <Tiny>
            Listing data comes from your CRM system. You can make changes in
            your CRM and {formattedBrandName} will reflect them on the landing
            page within a few hours.
          </Tiny>
        )}
      </Box>
    </>
  );
};

export default styled(defaultStyles)(ListingDataSource);

import {AdContentSet} from "src/features/ads";
import {ListingSetPreviewData} from "src/features/catalog-listing-sets/api/getCatalogListingSetPreview";
import {Agency} from "src/features/agencies/types";

export function parseAdContentSet(
  adContentSet: AdContentSet | undefined,
  listingSetPreviewData: ListingSetPreviewData | undefined,
  agency: Agency | undefined = undefined
): AdContentSet | undefined {
  if (!adContentSet || !listingSetPreviewData || !agency) return undefined

  return {
    ...adContentSet,
    attributes: {
      ...adContentSet.attributes,
      long_headline: listingSetPreviewData.attributes.listing.long_headline,
      short_headline: listingSetPreviewData.attributes.listing.short_headline,
      description: listingSetPreviewData.attributes.listing.description,
      listing: listingSetPreviewData.attributes.listing,
      brand_color: agency?.brand_colours?.primary,
      logo_bg_color: agency?.brand_colours?.background,
      logo_scale: agency?.logo_scale ?? 100,
      accent_color: agency?.brand_colours?.background,
    },
  }
}

export function parseLandingPageAdContentSet(
  adContentSet: AdContentSet | undefined,
  listingSetPreviewData: ListingSetPreviewData | undefined
): AdContentSet | undefined {

  if (!listingSetPreviewData)  return adContentSet;

  if (adContentSet && listingSetPreviewData) {
    return {
      ...adContentSet,
      attributes: {
        ...adContentSet.attributes,
        long_headline: listingSetPreviewData.attributes.listing.long_headline,
        short_headline: listingSetPreviewData.attributes.listing.short_headline,
        description: listingSetPreviewData.attributes.listing.description,
        listing: listingSetPreviewData.attributes.listing,
      },
    }
  }
  return undefined;
}

import React, { PureComponent } from 'react';
import { facebookNetworkAdTypes } from '../utils/spoke-value-lists';

import InstagramSingleImage from './instagram-ads/listing/single-image';
import InstagramCarousel from './instagram-ads/listing/carousel';

class ListingInstagramAd extends PureComponent {
  render() {
    const { adType, adContentSet, disableWrapper, ad } = this.props;
    const [type, variation] = adType.split('.');
    switch (type) {
      case facebookNetworkAdTypes.CAROUSEL:
        // variation is used to render each slide in the carousel
        return (
          <InstagramCarousel
            ad={ad}
            variation={variation}
            adContentSet={adContentSet}
            disableWrapper={disableWrapper}
          />
        );
      case facebookNetworkAdTypes.SINGLE_IMAGE:
      case facebookNetworkAdTypes.SINGLE_IMAGE_SECONDARY:
        return (
          <InstagramSingleImage
            ad={ad}
            adContentSet={adContentSet}
            disableWrapper={disableWrapper}
          />
        );
      default:
        return <span>Type {adType} not found</span>;
    }
  }
}

export default ListingInstagramAd;

/* eslint-disable camelcase */
import { addressLabel } from 'src/utils/address';

export function addressToMapbox(address) {
  const longitude = parseFloat(address?.longitude);
  if (!address || !longitude) return;

  const fullAddressFallback = addressLabel(address);

  const mb = {
    value: address,
    label: address?.full_address ?? fullAddressFallback,
    data: {
      id: address?.full_address,
      full: address?.full_address,
      context: [
        {
          // this field is not actually used by Mapbox its set here so it can be used when calling mapboxToAddress
          id: 'unit_number',
          text: address?.unit_number
        },
        {
          // this field is not actually used by Mapbox its set here so it can be used when calling mapboxToAddress
          id: 'street_number',
          text: address?.street_number
        },
        {
          // this field is not actually used by Mapbox its set here so it can be used when calling mapboxToAddress
          id: 'street_name',
          text: address?.street_name
        },
        {
          id: 'region',
          short_code:
            `${address?.country?.id}-` + `${address?.admin_area_1?.id}`
        },
        {
          id: 'place',
          text: address?.admin_area_2
        },
        {
          id: 'sublocality',
          text: address?.admin_area_4
        },
        {
          id: 'locality',
          text: address?.admin_area_3
        },
        {
          id: 'postcode',
          text: address?.postal_code
        }
      ],
      center: address?.latitude
        ? [longitude, parseFloat(address?.latitude)]
        : null
    }
  };
  return mb;
}

export function suburbToMapbox(address) {
  return {
    value: address,
    label: address?.full_address,
    data: {
      id: address?.full_address,
      text: address?.admin_area_3,
      context: [
        {
          id: 'region',
          short_code:
            `${address?.country?.id}-` + `${address?.admin_area_1?.id}`
        },
        {
          id: 'place',
          text: address?.admin_area_2
        },
        {
          id: 'locality',
          text: address?.admin_area_3
        },
        {
          id: 'postcode',
          text: address?.postal_code
        }
      ],
      center: address?.longitude
        ? [parseFloat(address?.longitude), parseFloat(address?.latitude)]
        : null
    }
  };
}

import {
  Children,
  JSXElementConstructor,
  ReactElement,
  ReactNode,
  RefCallback,
  isValidElement
} from 'react';
import { ReactRef } from 'src/types';

export function createKey(keyToBe: any): string {
  return keyToBe.toString().toLowerCase().replace(/\s/g, '-');
}

export function assignRef<T = any>(
  ref: ReactRef<T> | null | undefined,
  value: T
): void {
  if (ref == null) return;

  if (typeof ref === 'function') {
    ref(value);
    return;
  }

  try {
    ref.current = value;
  } catch (error) {
    throw new Error(`Cannot assign value '${value}' to ref '${ref}'`);
  }
}

export function mergeRefs<T>(
  ...refs: (ReactRef<T> | null | undefined)[]
): RefCallback<T> {
  return (node: T | null) => {
    refs.forEach((ref) => {
      assignRef(ref, node);
    });
  };
}

/**
 * Find and extract the first Element found from a set of ReactNodes.
 * @param children A set of React children to search in
 * @param element The element string or component constructor to find.
 * @returns A tuple with the potentially found element and the rest of the children.
 */
export function extractFirstInChildren(
  children: ReactNode | ReactNode[],
  element: string | JSXElementConstructor<any>
): [ReactElement | null, ReactNode] {
  const kids = Children.toArray(children);
  const idx = kids.findIndex((e) => isValidElement(e) && e.type === element);
  const found = idx !== -1 ? (kids.splice(idx, 1)[0] as ReactElement) : null;
  return [found, kids];
}

/**
 * Gets only the valid children of a component,
 * and ignores any nullish or falsy child.
 *
 * @param children the children
 */
export function getValidChildren(children: React.ReactNode): ReactElement[] {
  return Children.toArray(children).filter((child) =>
    isValidElement(child)
  ) as ReactElement[];
}

import { useQuery, UseQueryResult } from 'react-query';
import { useAccountId } from 'src/features/accounts';
import { QueryConfig } from 'src/lib/react-query';
import { api } from 'src/utils/api-client';
import { CampaignDefinition } from '../types';
import { campaignDefinitionKeys } from './keys';

export type GetCampaignDefinitionParams = {
  campaignDefinitionId: string;
  accountId: string;
};

export const getCampaignDefinition = async ({
  campaignDefinitionId,
  accountId
}: GetCampaignDefinitionParams): Promise<CampaignDefinition> => {
  const { data } = await api.get(
    `/campaign-definition/${campaignDefinitionId}`,
    {},
    {
      headers: {
        'X-Account-Id': accountId
      }
    }
  );
  return data;
};

type QueryFnType = typeof getCampaignDefinition;

export type UseCampaignDefinitionOptions = {
  campaignDefinitionId: string;
  config?: QueryConfig<QueryFnType>;
};

export const useCampaignDefinition = ({
  campaignDefinitionId,
  config
}: UseCampaignDefinitionOptions): UseQueryResult<CampaignDefinition, Error> => {
  const accountId = useAccountId();

  return useQuery<CampaignDefinition, Error>({
    ...config,
    queryKey: campaignDefinitionKeys.detail(accountId, campaignDefinitionId),
    queryFn: () => getCampaignDefinition({ campaignDefinitionId, accountId })
  });
};

import { createRestAPIModelGenerator } from 'src/data/models/generator';
import { api } from 'src/utils/api-client';

export const TYPE = 'audience-segments';

const config = {
  valueLists: {
    api: {
      fetch: () =>
        new Promise((resolve, reject) => {
          // HACK: we have different value lists depending on the campaign type, so what we
          // do here is loading all of them + using the campaign type as subkey for the lists
          Promise.all([
            api.get('/lists/audience-segments/single_listing'),
            api.get('/lists/audience-segments/appraisal'),
            api.get('/lists/audience-segments/agent_profile'),
            api.get('/lists/audience-segments/multi_listing_sold')
          ])
            .then(
              ([
                listingSegments,
                appraisalSegments,
                agentSegments,
                multiListingSoldSegments
              ]) => {
                resolve({
                  data: {
                    single_listing: listingSegments?.data?.items?.data ?? [],
                    appraisal: appraisalSegments?.data?.items?.data ?? [],
                    agent_profile: agentSegments?.data?.items?.data ?? [],
                    multi_listing_sold:
                      multiListingSoldSegments?.data?.items?.data ?? []
                  }
                });
              }
            )
            .catch(reject);
        })
    }
  }
};

const AudienceSegmentsGenerator = createRestAPIModelGenerator(TYPE, config);
export default AudienceSegmentsGenerator.createValueListModel();

import { track } from '@rexlabs/analytics';
import { Box } from '@rexlabs/box';
import { GhostButton, PrimaryButton } from '@rexlabs/button';
import { query, withModel, withQuery } from '@rexlabs/model-generator';
import React, { useEffect, useRef } from 'react';
import { compose } from 'redux';
import { mapPropsToValues } from 'shared/utils/prepare-ad-data';
import wizardModel from 'src/data/models/custom/wizard';
import accountPreferencesModel from 'src/data/models/entities/account-preferences';
import packageEstimatesModel from 'src/data/models/entities/package-estimates';
import { adsQuery } from 'src/data/queries/ads';
import { campaignAdQuery } from 'src/data/queries/campaigns';
import { facebookQuery } from 'src/data/queries/facebook';
import { packageQuery } from 'src/data/queries/packages';
import WIZARD from 'src/routes/wizard';
import { formattedBrandName } from 'src/theme';
import { maybeFetchEstimates } from 'src/utils/estimates';
import { interpolate, interpolateRegex } from 'src/utils/format';
import { Link } from 'src/utils/whereabouts';
import { Carousel } from 'src/view/components/ad-previews';
import ComponentRenderer from 'src/view/components/component-renderer';
import HelpBox from 'src/view/components/help-box';
import ScrollableContent from 'src/view/components/scrollable-content';
import { BodyWithHelpIcon, Heading } from 'src/view/components/text';
import { stepNames } from 'src/view/screens/wizard/multi-listing-sold/index';

import { useStyles } from '@rexlabs/styling';
import ChevronLeft from 'src/assets/icons/chevron-left.svg';
import ChevronRight from 'src/assets/icons/chevron-right.svg';
import { StickyButtonGroup } from 'src/view/components/button';
import { sharedStyles } from '.';

function getCampaignId(props) {
  return props?.match?.params?.campaignId;
}

const videoHelpStyles = {
  position: 'fixed',
  top: '60%',
  right: '4rem',
  width: '30rem',
  height: 'auto',
  zIndex: 15
};

const accountPreferences = query`{
  ${accountPreferencesModel} (id: "fakeId") {
    landing_pages_default_use_external
  }
}`;

const ReviewScreen = (props) => {
  const s = useStyles({}, undefined, sharedStyles);
  const previewedCount = useRef(1);
  const totalAdPreviews = useRef();
  const {
    campaigns,
    ads,
    facebook,
    wizard: { setStep, step }
  } = props;
  const nextClicked = useRef(false);
  const campaignTemplate =
    campaigns.item.data?.campaign_template?.campaign_template_configuration
      ?.configuration;
  const isLoading =
    campaigns?.item?.status === 'loading' ||
    campaigns?.item?.status === 'fetching' ||
    (campaigns?.item?.data?.ad_content_sets?.items || []).length <= 0;
  const type = campaigns?.item?.data?.type?.id;
  const showVideoMessage =
    !isLoading &&
    (campaigns?.item?.data?.videos || []).length === 0 &&
    type !== 'multi_listing_sold';

  useEffect(() => {
    // fetching estimates here to help speed up
    // estimates displaying on the budget page
    maybeFetchEstimates(props);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Box flex={1} flexDirection='column'>
      <ScrollableContent {...s('content')}>
        <ComponentRenderer
          template={campaignTemplate}
          resource='ads'
          section='overview'
          components={{ Heading, BodyWithHelpIcon, Box, Carousel }}
          extraPropsFn={({ type, props: componentProps }) => {
            let extraProps;

            if (type === 'BodyWithHelpIcon') {
              extraProps = Object.keys(componentProps).reduce((acc, key) => {
                const prop = componentProps[key];
                if (interpolateRegex.test(prop)) {
                  acc[key] = interpolate(prop, { formattedBrandName });
                }
                return acc;
              }, {});
            }

            if (type === 'Carousel') {
              const campaignData = {
                ...mapPropsToValues(props),
                facebookPage: facebook?.item?.data?.pages?.[0]?.name,
                listing: {
                  ...campaigns?.item?.data?.content_source?.listings?.items?.[0]
                }
              };

              const campaignNetworks =
                campaigns?.item?.data?.audience_spec?.networks || [];
              const contentSource = campaigns?.item?.data?.content_source;

              extraProps = {
                isLoading,
                ads,
                campaignId: campaigns?.item?.data?.id,
                campaignData: campaignData,
                networks: campaignNetworks,
                contentSource: contentSource,
                facebook: props.facebook,
                previewedCount: previewedCount,
                totalAdPreviews: totalAdPreviews
              };
            }

            return extraProps;
          }}
        />
      </ScrollableContent>

      <StickyButtonGroup>
        <Box flex={1}>
          <GhostButton
            IconLeft={ChevronLeft}
            onClick={() => {
              track({
                event: 'Spoke campaign back',
                properties: {
                  action: 'back_clicked',
                  step_name: stepNames[step - 1].replace(/-/g, '_')
                }
              });
              setStep({ step: step - 1 });
            }}
          >
            Back
          </GhostButton>
        </Box>
        <Link to={WIZARD.EDIT_ADS}>
          {({ onClick }) => (
            <GhostButton
              onClick={(e) => {
                track({
                  event: 'Spoke campaign ads viewed',
                  properties: {
                    action: 'edit_ads_clicked',
                    step_name: stepNames[step - 1].replace(/-/g, '_')
                  }
                });
                onClick(e);
              }}
              data-target-intercom='editAds'
            >
              Edit ads
            </GhostButton>
          )}
        </Link>
        <PrimaryButton
          onClick={() => {
            if (nextClicked.current) return;
            nextClicked.current = true;

            track({
              event: 'Spoke campaign next',
              properties: {
                action: 'next_clicked',
                step_name: stepNames[step - 1].replace(/-/g, '_'),
                success: true,
                number_of_ads_previewed: previewedCount.current,
                number_of_ads: totalAdPreviews.current
              }
            });
            setStep({ step: step + 1 });
          }}
          IconRight={ChevronRight}
        >
          Next
        </PrimaryButton>
      </StickyButtonGroup>

      {showVideoMessage && (
        <Box style={videoHelpStyles}>
          <HelpBox helpBoxId='video-prompt-tooltip' showCloseButton={true}>
            {({ Title }) => (
              <div>
                <Title>
                  {type === 'single_listing'
                    ? 'Got a property video?'
                    : 'Got a video?'}
                  <br />
                  Turn it into a video ad!
                </Title>
                <p>
                  {type === 'single_listing'
                    ? 'Video is the quickest way to highlight the features of your listing. Click '
                    : 'Video is the quickest way to highlight your success and the reasons someone should list with you. You could showcase your recent sales, testimonials or a local market update. Click '}
                  <Link to={WIZARD.EDIT_ADS}>
                    {({ onClick }) => (
                      <a
                        style={{ cursor: 'pointer' }}
                        onClick={(e) => {
                          track({
                            event: 'Spoke campaign ads viewed',
                            properties: {
                              action: 'edit_ads_clicked',
                              step_name: stepNames[step - 1].replace(/-/g, '_')
                            }
                          });
                          onClick(e);
                        }}
                      >
                        Edit Ads
                      </a>
                    )}
                  </Link>{' '}
                  to upload your video file.
                </p>
              </div>
            )}
          </HelpBox>
        </Box>
      )}
    </Box>
  );
};

export default compose(
  withQuery(accountPreferences),
  withQuery(campaignAdQuery(getCampaignId)),
  withQuery(adsQuery(getCampaignId)),
  withModel(wizardModel),
  withModel(packageEstimatesModel),
  withQuery(packageQuery(getCampaignId)),
  withQuery(facebookQuery)
)(ReviewScreen);

import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import Box from '@rexlabs/box';

import { styled, StyleSheet } from '@rexlabs/styling';
import colorTheme from '../../../utils/color-theme';
import charLimit, { limits } from '../../../utils/char-limit';
import ScaledText from '../../scaled-text';
import { LargeButton } from '../../button';
import { COLORS } from 'src/theme';
import _ from 'lodash';

const fontFamily = 'Lato, sans-serif';
const defaultStyles = StyleSheet({
  portrait: {
    width: 300,
    height: 1050,
    overflow: 'hidden',
    border: '1px solid darkgray',
    display: 'flex',
    flexDirection: 'column'
  },
  primaryImage: {
    height: 333,
    width: '100%',
    objectFit: 'cover'
  },
  main: {
    backgroundColor: 'white',
    padding: '30px 30px',
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center'
  },
  shortHeadline: {
    backgroundColor: '#F8F8F8',
    height: 237,
    width: '100%',
    padding: '68px 30px 45px',
    fontSize: 42,
    fontFamily,
    lineHeight: '42px',
    marginBottom: '20px'
  },
  description: {
    fontFamily,
    fontSize: '36px',
    fontWeight: '300',
    lineHeight: '44px',
    marginBottom: '20px'
  },
  agentName: {
    fontFamily,
    marginBottom: '20px'
  },
  agentDetails: {
    fontFamily,
    fontSize: 18,
    lineHeight: '22px',
    fontWeight: 600,
    marginBottom: '20px'
  },
  logoWrapper: {
    padding: '15px 40px',
    height: 100,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center'
  },
  logo: {
    width: '100%',
    height: '100%',
    objectFit: 'contain'
  }
});

@styled(defaultStyles)
class Portrait extends PureComponent {
  static propTypes = {
    shortHeadline: PropTypes.string,
    position: PropTypes.string,
    agentName: PropTypes.string,
    description: PropTypes.string,
    buttonText: PropTypes.string,
    brandColor: PropTypes.string,
    primaryImageUrls: PropTypes.object,
    logoUrls: PropTypes.object
  };

  static defaultProps = {
    buttonText: 'Learn more'
  };

  render() {
    const {
      shortHeadline,
      description,
      agentName,
      agentPosition,
      agencyName,
      buttonText,
      brandColor,
      backgroundColor,
      primaryImageUrls,
      logoUrls,
      styles: s
    } = this.props;

    const { brandColorBorder } = colorTheme(brandColor);

    return (
      <Box {...s('portrait')}>
        <img
          {...s('primaryImage')}
          src={_.get(primaryImageUrls, ['crop.square', 'url'])}
        />
        <span {...s.with('shortHeadline')({ color: COLORS.BLACK })}>
          {charLimit(shortHeadline, limits.SHORT_HEADLINE)}
        </span>
        <Box {...s('main')}>
          <ScaledText
            maxWidth={224}
            maxTextLines={2}
            maxFontSize={38}
            minFontSize={22}
            {...s.with('agentName')({ color: COLORS.BLACK })}
          >
            {agentName}
          </ScaledText>
          <ScaledText
            maxWidth={224}
            maxTextLines={2}
            maxFontSize={38}
            minFontSize={22}
            {...s.with('description')({ color: COLORS.BLACK })}
          >
            {description}
          </ScaledText>
          <span {...s.with('agentDetails')({ color: COLORS.BLACK })}>
            {agentPosition} - {agencyName}
          </span>

          <LargeButton brandColor={brandColor}>{buttonText}</LargeButton>
        </Box>
        <Box
          {...s.with('logoWrapper')({
            backgroundColor: backgroundColor,
            borderTop: brandColorBorder
          })}
        >
          <img {...s('logo')} src={_.get(logoUrls, 'original.url')} />
        </Box>
      </Box>
    );
  }
}

export default Portrait;

import { GhostButton, PrimaryButton } from '@rexlabs/button';
import { useModelActions } from '@rexlabs/model-generator';
import { useToast } from '@rexlabs/notifications';
import { margin, StyleSheet, useStyles } from '@rexlabs/styling';
import { Body, Small } from '@rexlabs/text';
import { TextInput } from '@rexlabs/text-input';
import { first } from 'lodash';
import React, { ComponentType, useCallback } from 'react';
import { Controller, useForm } from 'react-hook-form';
import agenciesModel from 'src/data/models/entities/agencies';
import { FormField } from 'src/view/components/form/form-field';
import { ModalContent } from 'src/view/components/modal';
import { HelpIcon } from 'src/view/components/tooltip';
import { useModal } from 'src/view/hooks';
import { single } from 'validate.js';

const styles = StyleSheet({
  form: {
    '& > * + *': {
      ...margin.styles({
        top: 'xl'
      })
    }
  },

  buttonBar: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'flex-end',

    '& > * + *': {
      ...margin.styles({
        left: 's'
      })
    }
  },

  error: {
    color: ({ token }) => token('color.textStyle.danger.idle.default')
  }
});

function formValidator(value: string): string | undefined {
  return first(
    single(value, {
      presence: {
        allowEmpty: false,
        message: 'Please enter a privacy policy URL.'
      },
      url: {
        message: 'Please enter URL in the format: https://example.com'
      }
    }) ?? []
  );
}

export interface ProvidePrivacyPolicyModalProps {
  agencyId: string;
  close(): void;
}

function ProvidePrivacyPolicyModal({
  agencyId,
  close
}: ProvidePrivacyPolicyModalProps) {
  const {
    control,
    formState: { errors, isSubmitting },
    handleSubmit
  } = useForm({
    mode: 'onBlur'
  });
  const s = useStyles(styles);
  const { updateItem } = useModelActions(agenciesModel);
  const toasts = useToast();

  const setPrivacyPolicyUrl = useCallback(
    async (data) => {
      try {
        await updateItem({
          id: agencyId,
          data: {
            website_privacy_url: data.url
          }
        });
        close();
        toasts.addToast({
          type: 'success',
          description: 'Thank you! We have now updated your privacy policy URL.'
        });
      } catch (e) {
        toasts.addToast({
          target: 'modal',
          type: 'error',
          title: 'Could not save privacy policy URL',
          description: (e as Error).message
        });
      }
    },
    [agencyId, close, toasts, updateItem]
  );

  return (
    <ModalContent title='Privacy policy URL' close={close}>
      <Body grey>
        To adhere to the advertising policies of Facebook and Google please
        provide your website’s privacy policy URL.
      </Body>
      <form {...s('form')} onSubmit={handleSubmit(setPrivacyPolicyUrl)}>
        <FormField
          name='url'
          label={
            <>
              Privacy policy URL{' '}
              <HelpIcon
                Content={() => (
                  <>
                    <Body>
                      The privacy policy URL should be a link to a web page, not
                      a document.
                    </Body>
                    <Body>
                      If you don’t have a privacy policy URL you can simply
                      enter your agency’s home page URL.
                    </Body>
                  </>
                )}
              />
            </>
          }
          Input={
            <Controller
              name='url'
              control={control}
              rules={{
                validate: formValidator
              }}
              render={({ field, fieldState }) => (
                <TextInput
                  type='url'
                  {...field}
                  meta={{ error: fieldState.error?.message ?? null }}
                />
              )}
            />
          }
          errors={
            <Small {...s('error')} as='span'>
              {errors.url?.message as unknown as string}
            </Small>
          }
        />
        <div {...s('buttonBar')}>
          <GhostButton onClick={close}>Cancel</GhostButton>
          <PrimaryButton type='submit' isLoading={isSubmitting}>
            Save
          </PrimaryButton>
        </div>
      </form>
    </ModalContent>
  );
}

export type ModalReturn = [
  ComponentType<Omit<ProvidePrivacyPolicyModalProps, 'close'>>,
  () => void
];

export function useProvidePrivacyPolicyModal(
  initialState = false
): ModalReturn {
  const { Modal, open, close } = useModal(initialState);

  const modal = (props) => (
    <Modal>
      <ProvidePrivacyPolicyModal close={close} {...props} />
    </Modal>
  );

  return [modal, open];
}

import { StyleSheet, useStyles } from '@rexlabs/styling';
import React, { FC, ReactNode } from 'react';
import instagramHeader from '../../assets/instagram/header.png';
import instagramActionMenu from '../../assets/instagram/icon-action-menu.png';
import instagramIconBookmark from '../../assets/instagram/icon-bookmark.png';
import instagramIconChevron from '../../assets/instagram/icon-chevron.png';
import instagramIconComment from '../../assets/instagram/icon-comment.png';
import instagramIconLike from '../../assets/instagram/icon-like.png';
import instagramIconShare from '../../assets/instagram/icon-share.png';
import { CarouselDots } from './CarouselDots';

const DESC_MAX_LENGTH = 135;

export const INSTA_AD_CONTAINER_WIDTH = 320;

const styles = StyleSheet({
  container: {
    width: `${INSTA_AD_CONTAINER_WIDTH}px`,
    border: '.1rem solid rgba(0, 0, 0, .3)',
    background: 'white'
  },

  header: {
    width: '100%',
    height: '4rem',
    background: `url(${instagramHeader}) transparent no-repeat center center`,
    backgroundSize: 'contain'
  },

  meta: {
    padding: '1.2rem',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between'
  },

  logo: {
    width: '3rem',
    height: '3rem',
    marginRight: '.8rem',
    background: 'transparent none center center no-repeat',
    backgroundSize: 'contain',
    border: '.3rem solid transparent'
  },

  wrapAccount: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center'
  },

  wrapAccountName: {
    display: 'flex',
    flexDirection: 'column'
  },

  accountName: {
    fontSize: '1.2rem',
    lineHeight: '1.1',
    fontWeight: 700
  },

  sponsored: {
    fontSize: '1.1rem',
    lineHeight: '1.1'
  },

  actionMenu: {
    width: '1.4rem',
    height: '1.4rem',
    background: `url(${instagramActionMenu}) transparent no-repeat center center`,
    backgroundSize: 'contain'
  },

  wrapLearnMore: {
    padding: '0 1.2rem'
  },

  learnMore: {
    border: '0 none',
    borderBottom: '.1rem solid #e9eaeb',
    padding: '1.2rem 0',
    background: 'transparent',
    width: '100%',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    color: '#3397f0',
    fontWeight: 700
  },

  chevron: {
    width: '1.4rem',
    height: '1.4rem',
    background: `url(${instagramIconChevron}) transparent no-repeat 0 0`,
    backgroundSize: 'contain'
  },

  actions: {
    padding: '1.2rem',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between'
  },

  wrapActions: {
    width: '10rem',
    display: 'flex',
    flexDirection: 'row'
  },

  wrapBookmark: {
    width: '10rem',
    display: 'flex',
    justifyContent: 'flex-end'
  },

  action: {
    width: '2rem',
    height: '2rem',
    background: 'none transparent no-repeat center center',
    backgroundSize: 'contain',
    marginRight: '.8rem',
    border: '0 none',
    flexShrink: 0
  },

  actionLike: {
    backgroundImage: `url(${instagramIconLike})`
  },

  actionComment: {
    backgroundImage: `url(${instagramIconComment})`
  },

  actionShare: {
    backgroundImage: `url(${instagramIconShare})`
  },

  actionBookmark: {
    backgroundImage: `url(${instagramIconBookmark})`
  },

  description: {
    padding: '.4rem 1rem 1rem',
    fontSize: '1.1rem',
    color: '#282828',
    lineHeight: '1.5rem',
    letterSpacing: '.5px',
    minHeight: '8rem'
  },

  more: {
    color: '#929598'
  },

  iframeContainer: {
    width: `100%`,
    height: `${INSTA_AD_CONTAINER_WIDTH}px`,
    overflow: 'hidden'
  }
});

export type InstagramAdContainerProps = {
  pageProfilePictureUrl?: string;
  logoBgColor?: string;
  pageName: string;
  shortHeadline?: string;
  description?: string;
  carouselData?: {
    slidePos: number;
    slideCount: number;
  };
  children: ReactNode;
};

export const InstagramAdContainer: FC<InstagramAdContainerProps> = ({
  pageProfilePictureUrl,
  logoBgColor,
  pageName,
  shortHeadline,
  description = '',
  children,
  carouselData
}) => {
  const s = useStyles(styles);

  const truncateDescription =
    description && description.length > DESC_MAX_LENGTH;
  const descriptionText = truncateDescription
    ? description.slice(0, DESC_MAX_LENGTH)
    : description;

  return (
    <div {...s('container')} data-testid={'insta-ad-container'}>
      <div {...s('header')} />
      <div {...s('meta')}>
        <div {...s('wrapAccount')}>
          <div
            {...s.with('logo')({
              backgroundImage: pageProfilePictureUrl
                ? `url(${pageProfilePictureUrl})`
                : 'none',
              backgroundColor: logoBgColor ?? 'transparent'
            })}
          />
          <div {...s('wrapAccountName')}>
            <span {...s('accountName')}>{pageName}</span>
            <span {...s('sponsored')}>Sponsored</span>
          </div>
        </div>
        <div {...s('actionMenu')} />
      </div>

      <div {...s('iframeContainer')}>{children}</div>

      <div {...s('wrapLearnMore')}>
        <button {...s('learnMore')}>
          <span>Learn more</span>
          <span {...s('chevron')} />
        </button>
      </div>
      <div {...s('actions')}>
        <div {...s('wrapActions')}>
          <button {...s('action', 'actionLike')} />
          <button {...s('action', 'actionComment')} />
          <button {...s('action', 'actionShare')} />
        </div>

        {carouselData ? (
          <CarouselDots
            slideCount={carouselData.slideCount}
            slidePos={carouselData.slidePos}
          />
        ) : null}

        <div {...s('wrapBookmark')}>
          <button {...s('action', 'actionBookmark')} />
        </div>
      </div>
      <div {...s('description')}>
        {shortHeadline}
        {shortHeadline && <br />}
        {descriptionText}
        {truncateDescription ? <span {...s('more')}>... more</span> : null}
      </div>
    </div>
  );
};

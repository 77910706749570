import React, { ComponentType, PropsWithChildren, useState } from 'react';
import { ModalBase } from '../components/modal/base';

export type ModalElement = ComponentType<
  PropsWithChildren<{ onDismiss?(): void }>
>;

export interface ModalController {
  Modal: ModalElement;
  open(): void;
  close(): void;
}

export function useModal(initialState = false) {
  const [showModal, setShowModal] = useState(initialState);
  const open = () => setShowModal(true);
  const close = () => setShowModal(false);

  const onDismissDefault = () => close();

  const Modal: ModalElement = ({ onDismiss = onDismissDefault, children }) => (
    <ModalBase isOpen={showModal} onDismiss={onDismiss}>
      {children}
    </ModalBase>
  );

  return {
    Modal,
    open,
    close
  };
}

import { Box } from '@rexlabs/box';
import React, { CSSProperties, ReactNode } from 'react';
import { number } from 'src/utils/format';

export type StatProps<T> = {
  value: T;
  warning?: unknown;
  width: CSSProperties['width'];
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  displayWith?: (value: T) => ReactNode;
};

export const Stat = <T,>({
  value,
  warning,
  width,
  displayWith = number
}: StatProps<T>) => {
  const styles: CSSProperties = { width };

  if (typeof warning !== 'undefined') {
    styles.color = 'red';
  }

  return (
    <Box style={styles}>
      <span>{displayWith(value)}</span>
    </Box>
  );
};

import { createRestAPIModelGenerator } from 'src/data/models/generator';
import { api } from 'src/utils/api-client';

export const TYPE = 'customPackages';

const config = {
  entities: {
    api: {
      fetchList: (type, args) =>
        api.get(`/campaigns/${args.campaignId}/quotes/custom-packages`),
      createItem: (type, { campaignId, ...args }) =>
        api.post(`/campaigns/${campaignId}/quotes/custom-packages`, args),
      updateItem: (type, { campaignId, ...args }, id) =>
        api.patch(`/campaigns/${campaignId}/quotes/custom-packages/${id}`, args)
    }
  }
};

const CustomPackageGenerator = createRestAPIModelGenerator(TYPE, config);
export default CustomPackageGenerator.createEntityModel();

import React, { PureComponent } from 'react';
import { styled, StyleSheet } from '@rexlabs/styling';
import { TEXTS } from 'src/theme';

const defaultStyles = StyleSheet({
  subHeading: {
    ...TEXTS.SUBHEADING,
    marginTop: ({ token }) => token('spacing.xxl'),
    marginBottom: ({ token }) => token('spacing.m')
  }
});

@styled(defaultStyles)
class SubHeading extends PureComponent {
  static defaultProps = {
    Tag: 'h2'
  };

  render() {
    const { Tag, styles: s, children, ...rest } = this.props;
    return (
      <Tag {...s.with('subHeading')(this.props)} {...rest}>
        {children}
      </Tag>
    );
  }
}

export default SubHeading;

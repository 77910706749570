/**
 * NOTE: this was copied from the edit-landing-page form and
 * is basically a tab component and should probably be replaced
 * using @rexlabs/tabs.
 */
import { Box } from '@rexlabs/box';
import { styled, StyleSheet, useToken } from '@rexlabs/styling';
import React from 'react';
import { BORDER_RADIUS, FONT, formattedBrandName } from 'src/theme';
import { HelpIcon } from 'view/components/tooltip';
import { Body } from '@rexlabs/text';

const defaultStyles = StyleSheet({
  pageTypeButton: {
    background: ({ token }) => token('color.neutral.idle.default'),
    border: 'none',
    color: ({ token }) => token('color.textStyle.body.default'),
    flex: 'auto',
    height: '4.4rem',
    fontWeight: FONT.WEIGHTS.SEMIBOLD
  },

  pageTypeButtonLeft: {
    borderTopLeftRadius: BORDER_RADIUS.INPUT,
    borderBottomLeftRadius: BORDER_RADIUS.INPUT
  },

  pageTypeButtonRight: {
    borderTopRightRadius: BORDER_RADIUS.INPUT,
    borderBottomRightRadius: BORDER_RADIUS.INPUT
  },

  pageTypeButtonActive: {
    background: ({ token }) => token('color.primary.active.default'),
    color: ({ token }) => token('color.textStyle.body.contrast')
  },

  infoText: {
    fontSize: '1.4rem',
    lineHeight: '1.8rem',
    fontWeight: 300,
    color: ({ token }) => token('legacy.color.blue.grey')
  }
});

const LandingPageFields = ({
  isExternal,
  toggleIsExternal,
  disableExternalUrl = false,
  children,
  styles: s
}) => {
  const token = useToken();

  if (disableExternalUrl) return (
    <Box flexDirection='column' flex={1}>
      {children[0]}
    </Box>
  );

  return (
    <Box flexDirection='column' flex={1}>
      <span {...s('infoText')}>
        We highly recommend that you use a Rex Reach Landing page. It&apos;s
        built with{' '}
        <span
          style={{
            color: token('legacy.color.black')
          }}
        >
          AMP
        </span>{' '}
        technology to maximise site speed and lead capture capabilities.
        <HelpIcon
          Content={() => (
            <Box style={{ width: '30rem' }}>
              <Body>
                {formattedBrandName} landing pages are fast and designed for
                mobile first. They collect interaction data and enable detailed
                reporting.
              </Body>
            </Box>
          )}
        />
      </span>
      <Box
        flexDirection='row'
        style={{
          marginTop: token('spacing.m'),
          marginBottom: token('spacing.m')
        }}
      >
        <button
          {...s('pageTypeButton', 'pageTypeButtonLeft', {
            pageTypeButtonActive: !isExternal
          })}
          onClick={toggleIsExternal}
          disabled={!isExternal}
        >
          {formattedBrandName} landing page
        </button>
        <button
          {...s('pageTypeButton', 'pageTypeButtonRight', {
            pageTypeButtonActive: isExternal
          })}
          onClick={toggleIsExternal}
          disabled={isExternal}
        >
          Page on my website
        </button>
      </Box>

      {isExternal ? children[1] : children[0]}
    </Box>
  );
};

export default styled(defaultStyles)(LandingPageFields);

import { StyleSheet, useStyles } from '@rexlabs/styling';
import React, { FC } from 'react';

const styles = StyleSheet({
  spacer: {
    flex: '1 1 0%',
    placeSelf: 'stretch'
  }
});

export const Spacer: FC = () => {
  const s = useStyles(styles, 'Spacer');
  return <div {...s('spacer')} />;
};

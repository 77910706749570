import { useMutation, useQueryClient } from 'react-query';
import { useAccountId } from 'src/features/accounts';
import { MutationResult } from 'src/lib/react-query';
import { api } from 'src/utils/api-client';
import {CatalogListingSet, CatalogListingSetPostDTO} from '../types';
import {catalogListingSetKeys} from "src/features/catalog-listing-sets/api/keys";

export type CreateCatalogListingSetParams = {
    accountId: string;
    listingSet: CatalogListingSetPostDTO;
};

export const createCatalogListingSet = async ({
  listingSet
}: CreateCatalogListingSetParams): Promise<CatalogListingSet> => {
    const { data } = await api.post('/listing-sets', listingSet);
    return data;
};

export type UseCreateCatalogListingSetVariables = CatalogListingSetPostDTO;

export const useCreateCatalogListingSet = (): MutationResult<
    CatalogListingSet,
    UseCreateCatalogListingSetVariables
> => {
    const queryClient = useQueryClient();
    const accountId = useAccountId();

    return useMutation({
        mutationFn: (listingSet) => createCatalogListingSet({ accountId, listingSet }),
        onSuccess: () => {
            queryClient.invalidateQueries(catalogListingSetKeys.all({ accountId }));
        }
    });
};

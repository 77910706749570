import { withModel } from '@rexlabs/model-generator';
import {
  Link as WhereaboutsLink,
  withWhereabouts
} from '@rexlabs/react-whereabouts';
import {
  push as whereaboutsPush,
  replace as whereaboutsReplace
} from '@rexlabs/whereabouts';
import React, { PureComponent } from 'react';
import sessionModel from 'src/data/models/custom/session';
import { store } from 'src/store';

@withModel(sessionModel)
@withWhereabouts()
class Link extends PureComponent {
  render() {
    const accountIndex = (this.props?.session?.accounts ?? []).findIndex(
      (a) => a.id === this.props?.session?.currentAccountId
    );
    return (
      <WhereaboutsLink
        {...this.props}
        params={{ accountIndex, ...(this.props.params || {}) }}
      >
        {this.props.children}
      </WhereaboutsLink>
    );
  }
}

function push(route, options = {}) {
  const { params, ...rest } = options;
  const state = store.getState();
  const accountIndex = (state?.session?.accounts ?? []).findIndex(
    (a) => a.id === state?.session?.currentAccountId
  );
  whereaboutsPush(route, {
    params: { accountIndex, ...(params || {}) },
    ...rest
  });
}

function replace(route, options = {}) {
  const { params, ...rest } = options;
  const state = store.getState();
  const accountIndex = (state?.session?.accounts ?? []).findIndex(
    (a) => a.id === state?.session?.currentAccountId
  );
  whereaboutsReplace(route, {
    params: { ...(params || {}), accountIndex },
    ...rest
  });
}

export { Link, push, replace };

import React from 'react';
import SelectListingsScreen from './multi-listing-sold/select-listings';
import SelectListingScreen from './listing/select';
import SelectAgentScreen from './agent-profile/select';
import SelectAgencyAgentScreen from './multi-listing-sold/select-agency-agent';
import AppraisalSuburbsScreen from './appraisal/suburbs';
import AppraisalDetailsScreen from './appraisal/details';
import AudienceScreen from './shared/audience';
import AdsScreen from './shared/ads';
import LandingPageScreen from './shared/landing-page'; // TODO: move this to wizard/shared
import BudgetScreen from './shared/budget';
import ConfirmScreen from './shared/confirm';
import AccountNotReadyScreen from './shared/account-not-ready';

/**
 * NOTE: name must match the key
 * I was original using React.lazy for the Component but removed it until I
 * had anmiations working, so it might be worth trying again instead of
 * loading all the screens for all campaign types.
 */
export const availableSteps = {
  select_agency_agent: {
    name: 'select_agency_agent',
    Component: SelectAgencyAgentScreen
  },
  select_listing: { name: 'select_listing', Component: SelectListingScreen },
  select_listings: { name: 'select_listings', Component: SelectListingsScreen },
  select_agent: { name: 'select_agent', Component: SelectAgentScreen },
  appraisal_suburbs: {
    name: 'appraisal_suburbs',
    Component: AppraisalSuburbsScreen
  },
  appraisal_details: {
    name: 'appraisal_details',
    Component: AppraisalDetailsScreen,
    label: 'Provide details'
  },
  audience: {
    name: 'audience',
    Component: AudienceScreen,
    label: 'Review audience'
  },
  ads: { name: 'ads', Component: AdsScreen, label: 'Review ads' },
  landing_page: {
    name: 'landing_page',
    Component: LandingPageScreen,
    label: 'Review page'
  },
  budget: { name: 'budget', Component: BudgetScreen, label: 'Set a budget' },
  confirm: { name: 'confirm', Component: ConfirmScreen },
  account_not_ready: {
    name: 'account_not_ready',
    Component: AccountNotReadyScreen
  }
};

export type StepProps = {
  stepName: string;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  session: any;
  wizard: unknown;
  whereabouts: unknown;
  match: unknown;
  templateId: string;
  template: unknown;
};

/**
 * NOTE: React.memo is used here to make sure the animations
 * work correctly, mainly the transition between steps.
 */
export const Step = React.memo(
  ({
    stepName,
    session,
    wizard,
    whereabouts,
    match,
    templateId,
    template
  }: StepProps) => {
    const currAccount = session.accounts.find(
      (a) => a.id === session.currentAccountId
    );
    const showAccountNotReady =
      (stepName === 'budget' || stepName === 'confirm') && !currAccount.ready;
    const Component = showAccountNotReady
      ? availableSteps.account_not_ready.Component
      : availableSteps[stepName].Component;

    return (
      <Component
        key={stepName}
        campaignTemplateId={templateId}
        template={template}
        wizard={wizard}
        session={session}
        whereabouts={whereabouts}
        match={match}
      />
    );
  }
);

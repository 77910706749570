import { Box } from '@rexlabs/box';
import { GhostButton, PrimaryButton } from '@rexlabs/button';
import { withModel } from '@rexlabs/model-generator';
import React, { PureComponent } from 'react';
import sessionModel from 'src/data/models/custom/session';
import { createValidationRules } from 'src/utils/form';
import { ModalStickyButtonGroup } from 'src/view/components/button';
import { Form, FormField, withForm } from 'src/view/components/form';
import { TextInput } from 'src/view/components/input';
import withError from 'src/view/containers/with-error';

const changePasswordForm = {
  name: 'changePassword',
  handleSubmit: (values, { props, ...rest }) =>
    props
      .onSubmit(values, { props, ...rest })
      .then(props.onClose)
      .catch((e) => {
        props.errorModal.open(e);
      }),
  validate: createValidationRules({
    new: 'required|min:8',
    confirm: 'required|same:new'
  }),
  mapPropsToValues: () => ({
    new: '',
    confirm: ''
  })
};

@withError('errorModal')
@withModel(sessionModel)
@withForm(changePasswordForm)
class ChangePasswordForm extends PureComponent {
  render() {
    const {
      onCancel,
      changePassword,
      errorModal: { Error }
    } = this.props;
    return (
      <Box>
        <Form name='changePasswordForm'>
          <FormField
            name='new'
            label='New password'
            Input={TextInput}
            inputProps={{ type: 'password' }}
            sendImmediate
          />
          <FormField
            name='confirm'
            label='Confirm new password'
            Input={TextInput}
            inputProps={{ type: 'password' }}
            sendImmediate
          />
          <ModalStickyButtonGroup>
            <GhostButton onClick={onCancel}>Cancel</GhostButton>
            <PrimaryButton
              form='changePasswordForm'
              isLoading={changePassword.isSubmitting}
              onClick={changePassword.submitForm}
            >
              Update
            </PrimaryButton>
          </ModalStickyButtonGroup>
        </Form>
        <Error />
      </Box>
    );
  }
}

export default ChangePasswordForm;

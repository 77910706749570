import React, { PureComponent } from 'react';
import { styled, StyleSheet } from '@rexlabs/styling';
import { TEXTS } from 'src/theme';

const defaultStyles = StyleSheet({
  label: TEXTS.LABEL
});

@styled(defaultStyles)
class Label extends PureComponent {
  static defaultProps = {
    Tag: 'label'
  };

  render() {
    const { Tag, styles: s, children, ...rest } = this.props;
    return (
      <Tag {...s.with('label')(this.props)} {...rest}>
        {children}
      </Tag>
    );
  }
}

export default Label;

/* eslint-disable max-lines */
import { Box } from '@rexlabs/box';
import { styled, StyleSheet, useToken } from '@rexlabs/styling';
import { Body } from '@rexlabs/text';
import moment from 'moment';
import React from 'react';
import ClicksIcon from 'src/assets/icons/clicks.svg';
import ImpressionsIcon from 'src/assets/icons/impressions.svg';
import { BREAKPOINTS, SHADOWS } from 'src/theme';
import { number } from 'src/utils/format';
import Alert from 'src/view/components/alert';
import { LineChart } from 'src/view/components/charts';
import {
  Stat,
  StatsHeading,
} from 'src/view/components/stats';
import { HelpIcon } from 'src/view/components/tooltip';
import {
  Conversions,
} from 'src/view/modals/admin/campaign/conversions';
import {
  FacebookEngagement,
} from 'src/view/modals/admin/campaign/facebook-engagement';
import {
  LpEngagement,
} from 'src/view/modals/admin/campaign/landing-page-engagement';
import {
  VideoAdPlays,
} from 'src/view/modals/admin/campaign/video-ad-plays';
import { CampaignStatTiles } from "view/screens/admin/campaign/catalog-stats/campaign-stat-tiles";
import { CampaignPerformanceChart } from "view/screens/admin/campaign/catalog-stats/campaign-performance-chart";

const defaultStyles = StyleSheet({
  container: {
    width: '100%',
    background: ({ token }) => token('legacy.color.white'),
    boxShadow: SHADOWS.REPORT,
    borderRadius: '.2rem',
    flexDirection: 'row',
    display: 'flex',
    marginBottom: ({ token }) => token('spacing.m'),
    padding: ({ token }) => token('spacing.xxl'),
    position: 'relative',

    [BREAKPOINTS.PHONE_ONLY]: {
      padding: ({ token }) => token('spacing.xl')
    },

    '@media print': {
      boxShadow: 'none',
      padding: 0
    }
  },

  inner: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',

    '@media print': {
      display: 'block'
    }
  },

  statsWrapper: {
    flex: 1,

    [BREAKPOINTS.TABLET_LANDSCAPE_UP]: {
      flex: 'inherit'
    },

    '@media print': {
      flex: '0 1 auto'
    }
  },

  clickStats: {
    marginLeft: ({ token }) => token('spacing.xxxl'),

    [BREAKPOINTS.PHONE_ONLY]: {
      marginLeft: ({ token }) => token('spacing.m')
    }
  },

  pageBreak: {
    display: 'none',

    '@media print': {
      float: 'none',
      position: 'static !important',
      display: 'block',
      boxSizing: 'content-box',
      pageBreakBefore: 'always'
    }
  },

  printStatsPanel: {
    display: 'none',

    '@media print': {
      display: 'flex',
      flexDirection: 'row',
      flexWrap: 'wrap'
    }
  },

  screenOnly: {
    '@media print': {
      display: 'none !important'
    }
  }
});

function ReportCampaignPerformance(props) {
  const {
    googleAdsEnabled,
    spokeLandingPage,
    webflowLandingPage,
    statsData,
    campaignData,
    styles: s
  } = props;

  const hasLeadGenForm = campaignData?.lead_generation_form_enabled;
  const landingPage = campaignData?.landing_pages?.data?.[0];
  const landingPageType = landingPage?.template?.id;
  const facebookActivityData = campaignData?.facebook_activity?.data;

  const showFbEngagementStats = (statsData?.summary?.facebook_engagement ?? 0) >= 1;
  const showVideoAdStats = (statsData?.video_stats?.data ?? []).length > 0;

  const token = useToken();

  return (
    <Box {...s('container')}>
      <Box {...s('inner')}>
        <StatsHeading title='Campaign Performance' Filters={() => null} />
        <Box flexDirection='row'>
          <Box {...s('statsWrapper')}>
            <Stat
              large
              value={number(statsData?.summary?.impressions, 0)}
              Icon={ImpressionsIcon}
              sup='Your ads were shown'
              sub={
                <span>
                  times (Impressions){' '}
                  <HelpIcon
                    Content={() => (
                      <Box style={{ width: '30rem' }}>
                        <Body>
                          The total number of times your ads are viewed on a
                          screen. This gives you an idea of how much exposure
                          you got from your ad.
                        </Body>
                      </Box>
                    )}
                  />
                </span>
              }
              borderColor={token('palette.indigo.600')}
            />
          </Box>
          <Box {...s('statsWrapper', 'clickStats')}>
            <Stat
              large
              value={number(statsData?.summary?.clicks ?? 0)}
              Icon={ClicksIcon}
              sup='Which delivered'
              sub={
                <span>
                  clicks to the campaign page (Link Clicks){' '}
                  <HelpIcon
                    Content={() => (
                      <Box style={{ width: '30rem' }}>
                        <Body>
                          The number of times your ad was clicked on. This tells
                          you how many times people came to your website thanks
                          to your ad.
                        </Body>
                      </Box>
                    )}
                  />
                </span>
              }
              borderColor={token('palette.magenta.600')}
            />
          </Box>
        </Box>

        <Box mt={token('spacing.xl')} mb={token('spacing.m')}>
          <CampaignPerformanceChart
            data={
              (statsData?.performances?.data ?? []).map((d) => ({
                clicks: d.clicks,
                impressions: d.impressions,
                x: d?.time ? moment(d.time).format('D MMM') : ''
              }))}
          />
        </Box>

        {!googleAdsEnabled && (
          <Alert color='transparent'>
            We’re temporarily disabling Google Ads, due to ad approval issues
            caused by recent policy changes from Google. Your campaign budget is
            fully allocated to Facebook and Instagram and the performance
            metrics might look different as a result.{' '}
            <a
              href='https://intercom.help/spokeapp/en/articles/4361613-why-are-google-ads-currently-disabled'
              target='_blank'
              rel='noopener noreferrer'
              style={{
                textDecoration: 'underline',
                color: token('legacy.color.blue.grey')
              }}
            >
              Learn more
            </a>
          </Alert>
        )}

        {/* NOTE: this box and its contents are only visible for print */}
        <Box {...s('printStatsPanel')}>
          {(spokeLandingPage || webflowLandingPage || hasLeadGenForm) && (
            <Conversions stats={statsData} landingPageType={landingPageType} />
          )}
          {spokeLandingPage && <LpEngagement stats={statsData} />}
        </Box>
        <div {...s('pageBreak')}></div>
        <Box {...s('printStatsPanel')}>
          {showFbEngagementStats && (
            <FacebookEngagement facebookActivity={facebookActivityData} />
          )}
          {showVideoAdStats && <VideoAdPlays stats={statsData} />}
        </Box>

        {/* NOTE: statsPanel is not visible for print */}
        <Box {...s('screenOnly')}>
          <CampaignStatTiles
            statsData={statsData}
            campaignData={campaignData}
            spokeLandingPage={spokeLandingPage}
            webflowLandingPage={webflowLandingPage}
            hasLeadGenForm={hasLeadGenForm}
            facebookActivityData={facebookActivityData}
          />
        </Box>
      </Box>
    </Box>
  );
}

export default styled(defaultStyles)(ReportCampaignPerformance);

import { Box } from '@rexlabs/box';
import {
  border,
  focus,
  margin,
  padding,
  StyleSheet,
  useStyles
} from '@rexlabs/styling';
import React, { ReactNode } from 'react';
import { Logo } from 'src/components/elements/logo';
import { Link } from 'src/utils/whereabouts';

const defaultStyles = StyleSheet({
  container: {
    position: 'relative',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    width: '100%',
    height: ({ token }) => token('topNavBar.height', '8rem'),
    minHeight: ({ token }) => token('topNavBar.height', '8rem'),
    backgroundColor: ({ token }) =>
      token('topNavBar.background', token('color.container.static.default')),
    ...border.styles({
      target: 'topNavBar.border',
      bottom: {
        style: 'solid',
        width: 'thin',
        color: ({ token }) => token('color.border.container.static.light')
      }
    }),
    ...focus.styles({
      target: 'topNavBar.shadow',
      shadow: 'light'
    }),
    ...padding.styles({
      target: 'topNavBar.padding',
      y: 'none',
      x: 'xl'
    })
  },
  contents: {
    display: 'flex',
    alignItems: 'center',
    ...margin.styles({
      target: 'topNavBar.contents.margin',
      x: 'xs'
    })
  },
  logoLink: {
    display: 'flex',
    alignItems: 'center',
    marginRight: ({ token }) =>
      token('topNavBar.logo.spacing', token('spacing.xxxl'))
  },
  logo: {
    height: ({ token }) => token('topNavBar.logo.height', '4rem'),
    color: ({ token }) => token('topNavBar.logo.color', 'unset')
  }
});

export interface TopNavBarProps {
  hideLogo?: boolean;
  Left?: ReactNode;
  Right?: ReactNode;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  logoRoute?: any;
}

function TopNavBar({
  hideLogo = false,
  logoRoute,
  Left,
  Right
}: TopNavBarProps) {
  const s = useStyles(defaultStyles);
  let LogoWrapper = ({ children }) => children;

  if (logoRoute !== undefined) {
    LogoWrapper = ({ children }) => (
      <Link to={logoRoute} {...s('logoLink')}>
        {children}
      </Link>
    );
  }

  return (
    <Box {...s('container')}>
      {!hideLogo && (
        <LogoWrapper>
          <Logo {...s('logo')} />
        </LogoWrapper>
      )}
      <Box flex={1} {...s('contents')}>
        {Left}
      </Box>
      <Box flex={0} {...s('contents')}>
        {Right}
      </Box>
    </Box>
  );
}

export default TopNavBar;

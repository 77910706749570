import React, { PureComponent } from 'react';
import { styled, StyleSheet, keyframes } from '@rexlabs/styling';

const animation = keyframes({
  from: {
    strokeDasharray: '0 100'
  },
  to: {
    strokeDasharray: '100 100'
  }
});

const defaultStyles = StyleSheet({
  svg: {
    height: '4.5rem',
    width: '4.5rem',
    transform: 'rotate(-90deg)',
    background: ({ token }) => token('legacy.color.blue.grey'),
    border: ({ token }) => `1px solid ${token('legacy.color.blue.dark')}`,
    borderRadius: '50%'
  },
  circle: {
    fill: ({ token }) => token('legacy.color.blue.grey'),
    stroke: ({ token }) => token('legacy.color.blue.light'),
    strokeWidth: '32'
  }
});

@styled(defaultStyles)
class ImageUploadProgress extends PureComponent {
  static defaultProps = {
    fileSize: '2048'
  };

  render() {
    const { styles: s } = this.props;
    return (
      <svg {...s('svg')} viewBox='0 0 32 32'>
        <circle
          {...s('circle')}
          style={{
            animation: `${animation} 8000ms cubic-bezier(0.19, 1, 0.22, 1)`
          }}
          r='16'
          cx='16'
          cy='16'
        />
      </svg>
    );
  }
}

export default ImageUploadProgress;

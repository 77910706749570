import {
  AnyObject,
  Field,
  FieldArray,
  ValidatorFunction
} from '@data-driven-forms/react-form-renderer';
import { EventFn, GhostButton } from '@rexlabs/button';
import Icons from '@rexlabs/icons-next';
import { margin, StyleSheet, useStyles } from '@rexlabs/styling';
import { Tiny } from '@rexlabs/text';
import React, { ComponentProps, FC, ReactNode } from 'react';
import {
  SortableContainer,
  SortableElement,
  SortEndHandler
} from 'react-sortable-hoc';
import { Consumer } from 'src/types';
import { FormControlAriaAttributes, UseFormControlProps } from '../form';
import {
  DynamicFieldArrayItem,
  DynamicFieldArrayItemProps
} from './DynamicFieldArrayItem';

const ArrayItem = SortableElement<DynamicFieldArrayItemProps>(
  DynamicFieldArrayItem
);

const ArrayContainer = SortableContainer<ComponentProps<'div'>>((props) => (
  <div {...props} />
));

const styles = StyleSheet({
  container: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    '> * + *': {
      ...margin.styles({
        top: 'xs'
      })
    }
  },

  items: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    gap: ({ token }) => token('spacing.xs')
  }
});

export type DynamicFieldArrayProps = UseFormControlProps<HTMLElement> &
  FormControlAriaAttributes & {
    name: string;
    fields: Field[];
    arrayValidator: ValidatorFunction;
    defaultItem?: AnyObject;
    minItems?: number;
    maxItems?: number;
    reorderable?: boolean;
    noItemsMessage?: ReactNode;
    addButtonText?: ReactNode;
    removeButtonText?: ReactNode;
  };

export const DynamicFieldArray: FC<DynamicFieldArrayProps> = ({
  name,
  fields,
  arrayValidator,
  defaultItem,
  minItems = 0,
  maxItems = Infinity,
  reorderable = true,
  noItemsMessage,
  addButtonText,
  removeButtonText
}) => {
  const s = useStyles(styles, 'DynamicFieldArray');
  return (
    <FieldArray key={name} name={name} validate={arrayValidator}>
      {({ fields: { map, value = [], push, remove, move } }) => {
        const addItem: EventFn = () => push(defaultItem);
        const removeItem: Consumer<number> = (index) => remove(index);
        const reorderItems: SortEndHandler = ({ oldIndex, newIndex }) =>
          move(oldIndex, newIndex);

        return (
          <div {...s('container')}>
            {value.length <= 0 ? (
              typeof noItemsMessage === 'string' ? (
                <Tiny grey>{noItemsMessage}</Tiny>
              ) : (
                noItemsMessage
              )
            ) : (
              <ArrayContainer
                {...s('items')}
                onSortEnd={reorderItems}
                useDragHandle
              >
                {map((name, index) => (
                  <ArrayItem
                    key={`${name}-${index}`}
                    index={index}
                    name={name}
                    fields={fields}
                    fieldIndex={index}
                    remove={removeItem}
                    disableRemove={value.length <= minItems}
                    removeLabel={removeButtonText}
                    reorderable={reorderable}
                  />
                ))}
              </ArrayContainer>
            )}
            <GhostButton
              IconLeft={Icons.Add}
              onClick={addItem}
              isDisabled={value.length >= maxItems}
            >
              {addButtonText}
            </GhostButton>
          </div>
        );
      }}
    </FieldArray>
  );
};

import { Box } from '@rexlabs/box';
import { styled, StyleSheet } from '@rexlabs/styling';
import React from 'react';
import { BREAKPOINTS, SHADOWS } from 'src/theme';
import { addressToMapbox, suburbToMapbox } from 'src/utils/mapbox';
import { Map } from 'src/view/components/map';
import { StatsHeading } from 'src/view/components/stats';
import { Body } from 'src/view/components/text';

const defaultStyles = StyleSheet({
  content: {
    width: '100%',
    background: ({ token }) => token('legacy.color.white'),
    boxShadow: SHADOWS.REPORT,
    borderRadius: '.2rem',
    flexDirection: 'row',
    display: 'flex',
    marginBottom: ({ token }) => token('spacing.m'),
    padding: ({ token }) => token('spacing.xxl'),
    position: 'relative',

    '& ul': {
      paddingLeft: '3.2rem',
      listStyle: 'disc',
      marginBottom: ({ token }) => token('spacing.xs')
    },

    '& ul li': {
      marginBottom: '.2rem'
    },

    [BREAKPOINTS.REPORT.TABLET]: {
      flexDirection: 'column'
    },

    '@media print': {
      display: 'none'
    }
  },

  wrapAudience: {
    width: '40%',

    [BREAKPOINTS.REPORT.TABLET]: {
      width: '100%',
      paddingBottom: '42rem'
    }
  },

  wrapMap: {
    position: 'absolute',
    top: 0,
    right: 0,
    bottom: 0,
    width: '50%',

    [BREAKPOINTS.REPORT.TABLET]: {
      width: '100%',
      left: 0,
      top: 'auto',
      height: '40rem'
    }
  }
});

function ReportAudienceDetails({ audienceSpec, styles: s }) {
  const location = (audienceSpec?.locations ?? []).find(
    (l) => l?.type?.id === 'radius'
  );

  const mapCenter = location?.address?.latitude
    ? [
        parseFloat(location?.address?.longitude),
        parseFloat(location?.address?.latitude)
      ]
    : null;

  const markers = (audienceSpec?.locations ?? []).map((l) =>
    l?.type?.id === 'radius'
      ? [l?.address?.longitude, l?.address?.latitude]
      : [l?.suburb?.longitude, l?.suburb?.latitude]
  );

  return (
    <Box {...s('content')}>
      <Box {...s('wrapAudience')}>
        <StatsHeading title='Campaign Audience' Filters={() => null} />

        <Body grey>Locations</Body>
        <ul>
          {(audienceSpec?.locations ?? []).map((location, i) => (
            <li key={i}>
              {location?.type?.id === 'suburb'
                ? suburbToMapbox(location.suburb).label
                : addressToMapbox(location.address).label}
            </li>
          ))}
        </ul>

        <Body grey>Detailed Targeting</Body>
        <ul>
          {(audienceSpec?.segments ?? []).map((segment) => (
            <li key={segment.id}>{segment.label}</li>
          ))}
        </ul>

        <Body grey>Networks</Body>
        <ul>
          {(audienceSpec?.networks ?? []).map((network) => (
            <li key={network.id}>{network.label}</li>
          ))}
        </ul>
      </Box>

      <Box {...s('wrapMap')}>
        <Map
          center={mapCenter}
          show
          radius={location?.radius?.value || 10}
          unit={location?.radius?.unit}
          markers={markers}
          scrollZoom={false}
          dragPan={false}
          readonly
          fullHeight
        />
      </Box>
    </Box>
  );
}

export default styled(defaultStyles)(ReportAudienceDetails);

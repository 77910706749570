import React from 'react';
import Box from '@rexlabs/box';
import { styled, StyleSheet } from '@rexlabs/styling';
import { useFeature } from '../../../hooks';
import AgentNameOverlay from '../shared/agent-name-overlay';
import getContrastColor from '../../../utils/contrast-color';
import charlimit, { limits } from '../../../utils/char-limit';
import { injectGlobal } from 'styled-components';
import { fontFace } from 'utils/styling';
import { COLORS } from 'src/theme';
import config from 'config';

injectGlobal`
  ${fontFace('Gordita', `${config.API_URL}/assets/gordita-regular.woff2`)}
`;

const defaultStyles = StyleSheet({
  container: {
    width: '1080px',
    height: '1080px'
  },

  image: {
    height: '1080px',
    width: '1080px',
    background: 'none no-repeat top center',
    backgroundColor: COLORS.GREY_LIGHT,
    backgroundSize: 'cover',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    justifyContent: 'flex-end',
    position: 'relative',
    overflow: 'hidden'
  },

  imageStyle2: {
    height: '880px',
    width: '100%'
  },

  banner: {
    height: '200px'
  },

  agentName: {
    padding: '20px',
    fontSize: '82px',
    fontFamily: 'Circular Pro, Helvetica, Arial, sans-serif',
    lineHeight: '82px'
  },

  agentName2: {
    padding: '20px',
    paddingBottom: '4px',
    fontSize: '54px',
    fontWeight: '700',
    fontFamily: 'Circular Pro, Helvetica, Arial, sans-serif',
    lineHeight: '82px'
  },

  agentPosition: {
    padding: '10px 20px',
    fontSize: '42px',
    fontFamily: 'Circular Pro, Helvetica, Arial, sans-serif'
  },

  agentPosition2: {
    paddingTop: '0',
    paddingBottom: '20px'
  },

  gorditaFont: {
    fontFamily: 'Gordita, Circular Pro, Helvetica, Arial, sans-serif'
  }
});

const AdImage = (props) => {
  const { styles: s, imageUrl, adWrapperDimensions, adContentSet } = props;
  const bannerStyle2 = useFeature(
    'instagram_agent_banner_style_2',
    adContentSet.session
  );

  const BannerStyle1 = () => (
    <Box
      {...s.with('image')({
        transformOrigin: 'top left',
        transform:
          adWrapperDimensions?.width > 0
            ? `scale(${adWrapperDimensions.width / 1080})`
            : 1,
        backgroundImage: imageUrl ? `url(${imageUrl})` : 'none'
      })}
    >
      <AgentNameOverlay {...props} />
    </Box>
  );

  const BannerStyle2 = () => (
    <Box
      {...s.with('container')({
        transformOrigin: 'top left',
        transform:
          adWrapperDimensions?.width > 0
            ? `scale(${adWrapperDimensions.width / 1080})`
            : 1
      })}
    >
      <Box
        {...s.with(
          'image',
          'imageStyle2'
        )({
          backgroundImage: imageUrl ? `url(${imageUrl})` : 'none'
        })}
      />
      <Box
        flexDirection='column'
        justifyContent='center'
        {...s.with('banner')({
          background: adContentSet.backgroundColor
        })}
      >
        <div
          {...s.with('agentName2', { gorditaFont: bannerStyle2 })({
            color: getContrastColor(adContentSet.backgroundColor)
          })}
        >
          {charlimit(adContentSet.agentName, limits.SMALL_TEXT)}
        </div>
        <div
          {...s.with('agentPosition', 'agentPosition2', {
            gorditaFont: bannerStyle2
          })({
            color: getContrastColor(adContentSet.backgroundColor)
          })}
        >
          {adContentSet.agentPosition}
        </div>
      </Box>
    </Box>
  );

  if (bannerStyle2) {
    return <BannerStyle2 />;
  }

  return <BannerStyle1 />;
};

export default styled(defaultStyles)(AdImage);

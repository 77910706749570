import type { AudienceDefinition } from '../types';

export const GENERAL_AUDIENCES = [
  'house_owners',
  'apartment_owners',
  'buying_and_selling_real_estate',
  'moving_and_relocating',
  'luxury_property_owners',
  'landlords_and_investors',
  'rural_owners',
  'rural_buyers',
  'premium_tenants',
  'premium_buyers',
  'commercial_tenants',
  'commercial_buyers',
  'investors',
  'first_time_buyers',
  'active_buyers',
  'upgraders',

];

export const RETARGETTING_AUDIENCES = [
  'facebook_page_engagement',
  'website_visitors'
];

export type GeneralAudiences = typeof GENERAL_AUDIENCES[number];

export const retargettingAudienceDefinitions: AudienceDefinition[] = [
  {
    value: 'facebook_page_engagement',
    label: 'Ad and post engagement on Facebook and Instagram',
    description: 'People who have liked or commented on your Facebook page'
  },
  {
    value: 'website_visitors',
    label: 'Your web traffic',
    description: 'People who have visited your website in the last 90 days'
  }
];

import { Box } from '@rexlabs/box';
import { styled, StyleSheet, useToken } from '@rexlabs/styling';
import { Heading } from '@rexlabs/text';
import React from 'react';
import EMBED from 'src/routes/embed';
import domain from 'src/utils/domain';
import { push } from 'src/utils/whereabouts';
import { LinkButton } from 'src/view/components/button';
import { Body, Tiny } from 'src/view/components/text';
import Wistia from 'src/view/components/wistia';

import { Logo } from 'src/components/elements/logo';
import { formattedBrandName } from 'src/theme';

const defaultStyles = StyleSheet({
  container: {
    height: '100%'
  },

  logo: {
    color: ({ token }) => token('legacy.color.primary.default')
  },

  rightPane: {
    minWidth: '40rem',
    background: ({ token }) => token('legacy.color.grey.light')
  },

  resourcesLink: {
    display: 'block',
    marginTop: ({ token }) => token('spacing.xs'),
    fontWeight: '400'
  },

  buttonWrapper: {
    display: 'inline-block'
  }
});

function IntroScreen({ styles: s }) {
  const urlParams = new URLSearchParams(window.location.search);
  const authToken = urlParams.get('token');
  const userEmail = urlParams.get('email');
  const token = useToken();

  return (
    <Box flexDirection='row' alignItems='stretch' {...s('container')}>
      <Box p={token('spacing.xxl')} pr='7rem'>
        <Logo type='secondary' {...s('logo')} />
        <Heading level={2}>
          Put your listing in front of thousands of potential buyers wherever
          they go online
        </Heading>
        <Body grey style={{ marginBottom: token('spacing.xl') }}>
          Advertise your listing across Facebook, Instagram and the Google
          Display Network, which has over two million sites and reaches over 90%
          of people on the internet. Using artificial intelligence,{' '}
          {formattedBrandName} can reach thousands of potential buyers online -
          often before they even begin their property search.
        </Body>

        {/* HACK: this wrapper div is used to redirect while also opening a link in a new tab */}
        <div
          onClick={() => {
            push({
              config: {
                path: `${EMBED.CAMPAIGNS_LIST.config.path}?token=${authToken}`
              }
            });
          }}
          {...s('buttonWrapper')}
        >
          <LinkButton
            href={`${domain()}/campaign/create/listing?token=${authToken}&select-agency=true`}
            target='_blank'
          >
            Create Listing Campaign
          </LinkButton>
        </div>
      </Box>

      <Box p={token('spacing.xxl')} {...s('rightPane')}>
        <Wistia
          id='s4hsy3ob4q'
          src={`https://fast.wistia.com/embed/medias/s4hsy3ob4q.jsonp`}
          options={{ trackEmail: userEmail, fullscreenButton: true }}
        />
        <Body>Introduce {formattedBrandName} to your vendors</Body>
        <Tiny grey>
          We’ve created resources to help you explain {formattedBrandName}. Use
          them to impress vendors with your knowledge of digital marketing and
          get more VPA.
        </Tiny>
        <a
          href='https://spokeapp.io/resources'
          target='_blank'
          rel='noopener noreferrer'
          {...s('resourcesLink')}
        >
          View resources
        </a>
      </Box>
    </Box>
  );
}

export default styled(defaultStyles)(IntroScreen);

import { Box } from '@rexlabs/box';
import { OutlineButton } from '@rexlabs/button';
import {
  border,
  focus,
  padding,
  StyleSheet,
  useStyles
} from '@rexlabs/styling';
import { Small } from '@rexlabs/text';
import React from 'react';
import CustomBudgetIcon from 'src/assets/icons/custom-budget.svg';

const styles = StyleSheet({
  container: {
    ...border.styles({
      all: {
        width: 'thin',
        radius: 'm',
        color: 'container.static.medium'
      }
    }),
    width: '100%',
    ...padding.styles({
      all: 'xl'
    }),
    textAlign: 'center',
    position: 'relative',
    ...focus.styles({
      shadow: 'focus'
    }),
    transition: 'transform .2s, box-shadow .2s',
    height: '38rem',
    background: ({ token }) => token('color.container.static.contrast'),
    cursor: 'pointer',

    ':hover': {
      transform: 'translateY(-.6rem)',
      ...focus.styles({
        shadow: 'medium'
      })
    }
  },

  icon: {
    marginBottom: '2rem'
  }
});

export function EmptyCustomPlanItem({ onClick }) {
  const s = useStyles(styles);

  return (
    <Box {...s('container')} flexDirection='column' onClick={onClick}>
      <Box
        flex={1}
        flexDirection='column'
        alignItems='center'
        justifyContent='center'
      >
        <CustomBudgetIcon {...s('icon')} />
        <Small grey as='span'>
          Custom Package
        </Small>
      </Box>
      <Box flexDirection='column'>
        <OutlineButton>Create</OutlineButton>
      </Box>
    </Box>
  );
}

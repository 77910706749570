import React, { PureComponent } from 'react';
import { get } from 'lodash';

import Container from '../shared/container';
import AdImage from '../../facebook-ads/listing/ad-image';

class InstagramSingleImage extends PureComponent {
  static defaultProps = {
    disableWrapper: false
  };

  constructor(props) {
    super(props);
    this.adContainer = React.createRef();
    this.state = {
      adWrapperDimensions: { width: 0, height: 0 }
    };
  }

  componentDidMount() {
    const parentElement = get(this.adContainer, 'current.parentElement');
    if (!parentElement) return;
    const width = parentElement.offsetWidth;
    const borderWidth =
      (parseFloat(getComputedStyle(parentElement).borderWidth) || 0) * 2;
    this.setState({
      adWrapperDimensions: {
        width: width - borderWidth,
        height: width - borderWidth
      }
    });
  }

  render() {
    const { adContentSet, disableWrapper } = this.props;
    const { adWrapperDimensions } = this.state;

    if (disableWrapper) {
      return <AdImage {...this.props} scaleTo={1000} scalePos='left top' />;
    }

    return (
      <Container {...this.props} campaignDescription={adContentSet.description}>
        <div
          ref={this.adContainer}
          style={{ margin: 'auto', ...adWrapperDimensions }}
        >
          <AdImage {...this.props} scaleTo={adWrapperDimensions.width} />
        </div>
      </Container>
    );
  }
}

export default InstagramSingleImage;

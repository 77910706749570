import React, { FC, useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import { useToast } from '@rexlabs/notifications';
import { StepEditorLayout } from 'src/features/wizard/components/StepEditorLayout';
import { ContentTypeEditorProps } from 'src/features/wizard';
import {
  CommercialListingForm,
  CommercialListingSubmitHandler
} from './CommercialListingForm';
import { useCreateCommercialListing } from 'src/features/listings/api/createCommercialListing';
import { CommercialFormGuide } from './CommercialFormGuide';
import { parsePostData } from 'src/features/listings/utils/helpers';

export const CommercialListingCreateForm: FC<ContentTypeEditorProps> = ({
  selectContent
}) => {
  const navigate = useNavigate();
  const toasts = useToast();

  const { mutateAsync: createCommercialListing } = useCreateCommercialListing();

  const handleCreate = useCallback<CommercialListingSubmitHandler>(
    async (data) => {
      try {
        await createCommercialListing(parsePostData(data)).then((res) => {
          selectContent?.(res.id);
          navigate('../CommercialListing');
        });
      } catch (e) {
        toasts.addToast({
          type: 'error',
          title: "Couldn't create listing",
          description: e instanceof Error ? e.message : undefined
        });
      }
    },
    [createCommercialListing, toasts, navigate]
  );

  const exitEditor = useCallback(() => navigate('..'), [navigate]);

  return (
    <StepEditorLayout
      title='Create New Commercial Listing'
      Preview={<CommercialFormGuide />}
    >
      <CommercialListingForm
        submitLabel='Save'
        onSubmit={handleCreate}
        onCancel={exitEditor}
      />
    </StepEditorLayout>
  );
};

import React, { forwardRef, Ref } from 'react';
import * as DialogPrimitive from '@radix-ui/react-dialog';
import { GhostButton } from '@rexlabs/button';
import {
  border,
  margin,
  padding,
  StyleSheet,
  useStyles
} from '@rexlabs/styling';

const styles = StyleSheet({
  content: {
    position: 'fixed',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    zIndex: 99,
    width: '50vw',
    background: ({ token }) =>
      token('modal.background', token('color.container.static.default')),
    outline: 'none',
    ...padding.styles({
      target: 'modal',
      all: 'l'
    }),
    ...margin.styles({
      target: 'modal',
      x: () => 'auto'
    }),
    ...border.styles({
      target: 'modal',
      all: {
        radius: 'm'
      }
    }),
    '&:focus': { outline: 'none' }
  },

  close: {
    position: 'absolute',
    top: 10,
    right: 10
  }
});

export type ModalContentProps = DialogPrimitive.DialogContentProps;

export const ModalContent = forwardRef(
  (
    { children, className, style, ...props }: ModalContentProps,
    ref: Ref<HTMLDivElement>
  ) => {
    const s = useStyles(styles);
    return (
      <DialogPrimitive.Content
        {...s.with('content')({ className, style })}
        {...props}
        ref={ref}
      >
        {children}
        <DialogPrimitive.Close asChild>
          <GhostButton {...s('close')} size='2xs' aria-label='Close'>
            x
          </GhostButton>
        </DialogPrimitive.Close>
      </DialogPrimitive.Content>
    );
  }
);

import { Box } from '@rexlabs/box';
import { styled, StyleSheet } from '@rexlabs/styling';
import React, { PureComponent } from 'react';
import { withToken } from 'src/theme';
import { number } from 'src/utils/format';
import { Small } from 'src/view/components/text';

const defaultStyles = StyleSheet({
  container: {
    width: '100%',
    padding: ({ token }) => token('spacing.xs'),
    paddingLeft: 0,
    flexShrink: 0,

    '> *': {
      flexShrink: 0
    }
  }
});

@withToken
@styled(defaultStyles)
class LocationListItem extends PureComponent {
  render() {
    const { styles: s, data, token } = this.props;

    return (
      <Box
        {...s('container')}
        flexDirection='row'
        alignItems='center'
        justifyContent='space-between'
      >
        <Box
          flexDirection='row'
          alignItems='center'
          flex={1}
          pr={token('spacing.xl')}
        >
          <Small>{data.name}</Small>
        </Box>

        <Box style={{ width: '13rem' }} pr={token('spacing.xs')}>
          <Small>
            {number(data.views)}{' '}
            <span style={{ color: token('legacy.color.blue.grey') }}>
              ({data.percentage.toFixed(2)}%)
            </span>
          </Small>
        </Box>
      </Box>
    );
  }
}

export default LocationListItem;

import React from "react";
import {COLORS} from "src/theme";
import {Body} from "@rexlabs/text";
import {Box} from "@rexlabs/box";
import MagnifyingGlassIcon from 'src/assets/icons/magnifying-glass.svg';

type PreviewPlaceholderProps = {
  title: string;
  subtitle: string;
}
export function PreviewPlaceholder({title, subtitle}: PreviewPlaceholderProps) {
  return (
    <Box
      width={'100%'}
      height={'550px'}
      alignItems={'center'}
      justifyContent={'center'}
      style={{backgroundColor: COLORS.GREY_LIGHT}}
    >
      <Box
        width={'330px'}
        alignItems={'center'}
        justifyContent={'center'}
        flexDirection={'column'}
      >
        <MagnifyingGlassIcon />
        <Body style={{fontWeight: 500, fontSize: '16px'}}>{title}</Body>
        <Body grey style={{fontWeight: 450, fontSize: '14px', lineHeight: '16px', textAlign: 'center'}}>{subtitle}</Body>
      </Box>
    </Box>
  )
}

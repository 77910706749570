import { Box } from '@rexlabs/box';
import { withModel } from '@rexlabs/model-generator';
import { styled, StyleSheet, useToken } from '@rexlabs/styling';
import React from 'react';
import { compose } from 'redux';
import sessionModel from 'src/data/models/custom/session';
import { UTILS } from 'src/theme';
import { UserActionMenu } from 'src/view/components/action-menu';
import { usersCapabilities } from 'src/view/components/input/user-capabilities';
import { Small } from 'src/view/components/text';

const defaultStyles = StyleSheet({
  container: {
    width: '100%',
    padding: ({ token }) => token('spacing.m'),
    flexShrink: 0,

    '> *': {
      flexShrink: 0
    }
  }
});

function UserListItem(props) {
  const { styles: s, data, session, ownerRights, isInvitation } = props;
  const userStatus =
    usersCapabilities(data.capabilities || data.extra?.capabilities) ||
    'Standard';
  const formattedUserStatus = (
    userStatus.charAt(0).toUpperCase() + userStatus.slice(1)
  ).replace('_', ' ');
  const token = useToken();

  return (
    <Box
      {...s('container')}
      flexDirection='row'
      alignItems='center'
      justifyContent='space-between'
    >
      <Box
        flexDirection='row'
        alignItems='center'
        flex={1}
        pr={token('spacing.xl')}
      >
        <Small>
          {data.given_name || data.family_name
            ? `${data.given_name} ${data.family_name}`
            : '-'}
          {session?.user?.id === data.id && ' (You)'}
        </Small>
      </Box>

      <Box style={{ width: '28rem' }} pr={token('spacing.xl')}>
        <Small grey style={UTILS.TRUNCATE} title={data.email}>
          {data.email}
        </Small>
      </Box>

      <Box style={{ width: '16rem' }} pr={token('spacing.xl')}>
        <Small>
          {data.status
            ? data.status.label
            : data.is_owner
            ? 'Owner'
            : formattedUserStatus}
        </Small>
      </Box>

      <Box
        style={{ width: '7rem' }}
        justifyContent='flex-end'
        alignItems='center'
        onClick={(e) => e.stopPropagation()}
      >
        <UserActionMenu
          user={data}
          ownerRights={ownerRights}
          isInvitation={isInvitation}
          {...props}
        />
      </Box>
    </Box>
  );
}

export default compose(
  withModel(sessionModel),
  styled(defaultStyles)
)(UserListItem);

import { Box } from '@rexlabs/box';
import { SecondaryButton } from '@rexlabs/button';
import { withModel } from '@rexlabs/model-generator';
import { styled, StyleSheet, useToken } from '@rexlabs/styling';
import React from 'react';
import { compose } from 'redux';
import campaignsModel from 'src/data/models/entities/campaigns';
import { createValidationRules } from 'src/utils/form';
import { Form, FormField, ReactForms } from 'src/view/components/form';
import { TextInput } from 'src/view/components/input';
import { Modal } from 'src/view/components/modal';
import { Small } from 'src/view/components/text';
import withError from 'src/view/containers/with-error';
import { getToken } from 'src/view/screens/embed/campaigns/list';

import { useToast } from '@rexlabs/notifications';
import CopyToken from 'src/assets/images/copy-token.png';
import { formattedBrandName } from 'src/theme';

const defaultStyles = StyleSheet({
  seperator: {
    width: '100%',
    height: '.1rem',
    background: ({ token }) => token('legacy.color.blue.greyLight')
  },

  steps: {
    flexGrow: 1,
    flexBasis: 0
  },

  copyTokenImg: {
    width: '100%',
    marginTop: '1.2rem'
  }
});

const validate = createValidationRules({
  linking_token: ['required', 'campaign token']
});

function LinkCampaignModal({
  closeModal,
  campaigns,
  errorModal,
  errorModal: { Error },
  styles: s
}) {
  const token = useToken();
  const toasts = useToast();
  const handleSubmit = async (values) => {
    try {
      const token = getToken();
      if (!token) {
        throw new Error('No url token found');
      }
      await campaigns.linkCampaignToken({
        token: values.linking_token,
        campaignToken: token
      });
      closeModal();
      toasts.addToast({ title: 'Your campaign has been linked' });
    } catch (e) {
      errorModal.open(e);
    }
  };

  return (
    <Modal title='Link existing campaign' width='800rem' onClose={closeModal}>
      <Box>
        <Small grey>
          Link an existing {formattedBrandName} campaign to include advertising
          performance on your vendor report.
        </Small>
        <Box
          mt={token('spacing.xs')}
          mb={token('spacing.xs')}
          {...s('seperator')}
        />

        <Box flexDirection='row'>
          <Box {...s('steps')} pr={token('spacing.xl')}>
            <Small grey>
              <span style={{ color: token('legacy.color.black') }}>Step I</span>
              <br />
              Go to the{' '}
              <a
                href='https://app.beta.spokeapp.io'
                target='_blank'
                rel='noopener noreferrer'
              >
                {formattedBrandName} app
              </a>{' '}
              and copy the campaign token.
            </Small>

            <img src={CopyToken} alt='copy-token' {...s('copyTokenImg')} />
          </Box>
          <Box {...s('steps')} pl={token('spacing.xl')}>
            <Small grey>
              <span style={{ color: token('legacy.color.black') }}>
                Step II
              </span>
              <br />
              Paste the campaign token below:
            </Small>
            <ReactForms handleSubmit={handleSubmit} validate={validate}>
              {({ submitForm, isSubmitting }) => (
                <Form name='linkCampaign'>
                  <FormField
                    name='linking_token'
                    label='Campaign token'
                    Input={TextInput}
                  />
                  <Box flex={1} mt={token('spacing.m')}>
                    <SecondaryButton
                      onClick={submitForm}
                      isLoading={isSubmitting}
                    >
                      Link the campaign
                    </SecondaryButton>
                  </Box>
                </Form>
              )}
            </ReactForms>
          </Box>
        </Box>

        <Error />
      </Box>
    </Modal>
  );
}

export default compose(
  styled(defaultStyles),
  withError('errorModal'),
  withModel(campaignsModel)
)(LinkCampaignModal);

import React from 'react';
import { RadioButton as VividRadioButton } from '@rexlabs/radio-input';
import { StyleSheet } from '@rexlabs/styling';
import { COLORS } from 'src/theme';

// NOTE: tokens don't work here as this is being passed to RadioButton as
// a style prop. Temporarily using the theme constants until upgrading
// to the latest RadioButton
const radioButtonStyles = StyleSheet({
  label: {
    fontSize: '1.8rem'
  },

  input: {
    '& + label': {
      width: '2.4rem',
      height: '2.4rem',
      // borderColor: ({ token }) => token('legacy.color.blue.grey'),
      borderColor: COLORS.BLUE_GREY,
      borderWidth: '.1rem',
      borderRadius: '2.4rem',
      marginLeft: '0',
      marginRight: '1.2rem',

      '&::before': {
        // borderBottomColor: ({ token }) => token('legacy.color.white'),
        borderBottomColor: COLORS.WHITE,
        // borderRightColor: ({ token }) => token('legacy.color.white'),
        borderRightColor: COLORS.WHITE,
        height: '1rem',
        width: '0.5rem',
        borderWidth: '.2rem'
      },

      '&::after': {
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        width: 'auto',
        height: 'auto'
      }
    },

    '&:checked + label': {
      // borderColor: ({ token }) => token('legacy.color.secondary.default'),
      borderColor: COLORS.SECONDARY,
      // backgroundColor: ({ token }) => token('legacy.color.secondary.default'),
      backgroundColor: COLORS.SECONDARY
    }
  }
});

function RadioGroup({ name, value, options, onChange }) {
  const handleChange = (e, value) => {
    e.target.value = value;
    onChange(e);
  };

  return options.map((option) => {
    return (
      <>
        <VividRadioButton
          key={option.value}
          id={`${name}_${option.value}`}
          name={name}
          value={option.value.toString() === value?.toString()}
          label={option.label}
          hint={option.hint}
          onChange={(e) => handleChange(e, option.value)}
          buttonStyles={radioButtonStyles}
        />
        {option.sublabel}
      </>
    );
  });
}

export default RadioGroup;

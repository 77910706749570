import _ from 'lodash';
import { createRestAPIModelGenerator } from 'src/data/models/generator';
import { api } from 'src/utils/api-client';

const TYPE = 'campaign-performance';

const config = {
  entities: {
    api: {
      fetchList: (type, args) => api.get('/admin/campaign-performance', args),
      fetchItem: (type, args, id) =>
        api.get(`/admin/campaign-performance/${id}`, args),
      updateItem: (type, args, id) =>
        api.patch('/admin/campaign-performance/' + id, args),
      refreshItem: (type, args, id) =>
        api.get(`/admin/campaign-performance/${id}`, args)
    },
    related: {
      campaign: {
        include: 'campaign{account,ads}'
      },
      notes: {
        include: 'notes'
      }
    }
  }
};

const actionCreators = {
  storeNote: {
    request: (payload) =>
      api.post(
        `/admin/campaign-performance/${payload.campaignId}/notes`,
        payload.data
      ),
    reduce: {
      initial: _.identity,
      success: _.identity,
      failure: _.identity
    }
  }
};

const CampaignsGenerator = createRestAPIModelGenerator(TYPE, config);
export default CampaignsGenerator.createEntityModel({ actionCreators });

import { margin, StyleSheet, useStyles } from '@rexlabs/styling';
import React, { forwardRef } from 'react';
import { useFormControlContext } from './FormControl';

const styles = StyleSheet({
  helper: {
    ...margin.styles({
      top: 'xs'
    }),
    color: ({ token }) => token('color.textStyle.body.hint'),
    fontSize: ({ token }) => token('typography.size.s')
  }
});

export type FormHelperTextProps = JSX.IntrinsicElements['div'] &
  Record<string, unknown>;

export const FormHelperText = forwardRef<HTMLDivElement, FormHelperTextProps>(
  (props, ref) => {
    const s = useStyles(styles);
    const field = useFormControlContext();
    const { className, style, ...rest } = field?.getHelpTextProps(
      props,
      ref
    ) ?? { ...props, ref };

    return <div {...s.with('helper')({ className, style })} {...rest} />;
  }
);

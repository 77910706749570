import { Box } from '@rexlabs/box';
import { query, withQuery } from '@rexlabs/model-generator';
import capitalize from 'lodash/capitalize';
import React, { ComponentType, useMemo } from 'react';
import { compose } from 'redux';
import campaignsModel from 'src/data/models/entities/campaigns';
import packageEstimatesModel from 'src/data/models/entities/package-estimates';
import packagesModel from 'src/data/models/entities/packages';
import { RenderLoading } from 'src/view/components/loading';
import withError from 'src/view/containers/with-error';
import { ChangePlanConfirmation } from './confirm';
import { ChangePlanSelector } from './select';
import { useChangePlanStore } from './state';

function getCampaignId({ campaignId }) {
  return campaignId;
}

const queryCampaign = query`{
  ${campaignsModel} (id: ${getCampaignId}) {
    id
    name
    type {
      id
    }
    duration {
      total
      spent
      unit
    }
    active_package {
      id
      name
      price {
        value
        currency_code
      }
      type {
        id
      }
    }
    renew
    selected_package
  }
}`;

const queryPackages = query`{
  ${packagesModel} (campaignId: ${getCampaignId}) {
    id
    budget
    price
    duration
    name
    admin_name
    type {
      id
    }
  }
}`;

const queryPackageEstimates = query`{
  ${packageEstimatesModel} (campaignId: ${getCampaignId}) {
    id
    reach {
      min
      max
      failures
    }
  }
}`;

function ChangePlanModal({
  campaigns,
  campaignId,
  packages,
  packageEstimates,
  error,
  closeModal
}) {
  const { state, actions } = useChangePlanStore();

  const Error = error.Error;
  const currentPlan = campaigns?.item?.data?.selected_package;
  const willRenew = campaigns?.item?.data?.renew;
  const duration = campaigns?.item?.data?.duration;
  const campaignName = campaigns?.item?.data?.name;
  const campaignType = campaigns?.item?.data?.type?.id;
  const newPlan = useMemo(
    () => (packages?.list?.items ?? []).find(({ id }) => id === state.newPlan),
    [packages?.list?.items, state.newPlan]
  );
  const estimates = packageEstimates?.list?.items ?? [];
  const failures = packageEstimates?.list?.items?.[0]?.reach?.failures ?? [];
  const failureText = failures.length
    ? failures
        .map((failure) =>
          failure === 'adwords' ? 'Google' : capitalize(failure)
        )
        .join(' or ')
    : null;

  const submitNewPlan = async (paymentMethod) => {
    actions.submitChange(paymentMethod);

    try {
      await campaigns.launch({
        data: {
          type: {
            id: 'upgrade'
          },
          package: { id: newPlan.id },
          payment_option: { id: paymentMethod }
        },
        id: campaignId
      });

      await campaigns.refresh();

      actions.submitChangeSuccess();
      closeModal();
    } catch (e) {
      actions.submitChangeError(e as Error);
      error.open((e as Error).message);
    }
  };

  return (
    <Box w='90rem'>
      <RenderLoading
        isLoading={
          campaigns.item.status === 'loading' ||
          packages.list.status === 'loading'
        }
      >
        {state.stage === 'select' && (
          <ChangePlanSelector
            currentPlan={currentPlan}
            newPlanId={state.newPlan}
            plans={packages?.list?.items ?? []}
            estimates={estimates}
            willRenew={willRenew}
            duration={duration}
            campaignType={campaignType}
            failureText={failureText}
            confirmNewPlan={actions.confirmPlan}
            cancelChange={closeModal}
          />
        )}
        {state.stage === 'confirm' && (
          <ChangePlanConfirmation
            campaignName={campaignName}
            currentPlan={currentPlan}
            newPlan={newPlan}
            duration={duration}
            selectPlan={actions.changePlan}
            confirmNewPlan={submitNewPlan}
            isSubmitting={state.submitting}
          />
        )}
      </RenderLoading>
      <Error />
    </Box>
  );
}

const ChangePlanModalWrapper = compose(
  withQuery(queryPackageEstimates),
  withQuery(queryPackages),
  withQuery(queryCampaign),
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  withError<any>()
)(ChangePlanModal) as ComponentType<ChangePlanModalProps>;

export interface ChangePlanModalProps {
  campaignId: string;
  closeModal: () => void;
}

export default function (props: ChangePlanModalProps) {
  return <ChangePlanModalWrapper {...props} />;
}

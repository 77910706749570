import { createRestAPIModelGenerator } from 'src/data/models/generator';
import { api } from 'src/utils/api-client';

export const TYPE = 'custom-audiences';

const config = {
  entities: {
    api: {
      fetchList: () => api.get('/user-lists'),
      fetchItem: (type, args, id) => api.get(`/user-lists/${id}`),
      createItem: (type, args) => api.post('/user-lists', args),
      trashItem: (type, args, id) => api.delete(`/user-lists/${id}`)
    }
  }
};

const PackagesGenerator = createRestAPIModelGenerator(TYPE, config);
export default PackagesGenerator.createEntityModel();

import { Box, BoxProps } from '@rexlabs/box';
import React, { PropsWithChildren } from 'react';

export interface GridRowProps extends BoxProps {
  gutter?: string;
}

export function GridRow({
  gutter,
  children,
  ...props
}: PropsWithChildren<GridRowProps>) {
  const boxProps: BoxProps = {
    ...props,
    flexDirection: 'row',
    ...(gutter && {
      ml: `-${gutter}`,
      mr: `-${gutter}`
    })
  };

  return <Box {...boxProps}>{children}</Box>;
}

export interface GridCellProps extends BoxProps {
  gutter?: string;
  width?: number;
  maxWidth?: number;
  alignSelf?: string;
}

export function GridCell({
  gutter,
  children,
  width = 1,
  maxWidth = 1,
  alignSelf = 'auto',
  ...props
}: PropsWithChildren<GridCellProps>) {
  const boxProps: BoxProps = {
    ...props,
    flexDirection: 'column',
    w: `${width * 100}%`,
    pl: gutter,
    pr: gutter,
    alignSelf
  };

  return (
    <Box
      {...boxProps}
      style={{
        maxWidth: `${maxWidth * 100}%`
      }}
    >
      {children}
    </Box>
  );
}

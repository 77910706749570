import { Box } from '@rexlabs/box';
import { withModel } from '@rexlabs/model-generator';
import { padding, StyleSheet, useStyles, useToken } from '@rexlabs/styling';
import { Body } from '@rexlabs/text';
import React from 'react';
import { compose } from 'redux';
import session from 'src/data/models/custom/session';
import { HelpIcon } from 'src/view/components/tooltip';

import MapPin from 'src/assets/icons/map-pin.svg';

const defaultStyles = StyleSheet({
  container: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    width: '100%',
    ...padding.styles({
      x: 'xxs',
      top: 'xs'
    })
  },

  targetText: {
    fontSize: '1.4rem',
    lineHeight: '1.8rem',
    color: ({ token }) => token('color.textStyle.body.subtext')
  },

  mapPin: {
    color: ({ token }) => token('palette.brand.500'),
    height: '2rem',
    width: 'auto',
    position: 'relative'
  }
});

function MapInfo({ type, session }) {
  const s = useStyles(defaultStyles);
  const token = useToken();
  const agency = session.accounts.find((a) => a.id === session.currentAccountId)
    .agencies.data[0];
  const countryCode = agency.address.country.id;

  return (
    <Box {...s('container')} spacing={token('spacing.xs')}>
      <MapPin {...s('mapPin')} />
      {type === 'single_listing' || type === 'rental_listing' ? (
        <Body {...s('targetText')}>
          Target people who live near the property you’re advertising.
          {type === 'single_listing' && (
            <HelpIcon
              Content={() => (
                <Box style={{ width: '30rem' }}>
                  {countryCode === 'GB' ? (
                    <Body>
                      Studies show that 51% of UK buyers in 2018 moved within 10
                      miles of the previous address. Almost 70% move within 20
                      miles.
                    </Body>
                  ) : (
                    <Body>
                      Studies show 58% of Australian buyers are looking to move
                      within 5km of their current address. 91% look within 40km.
                    </Body>
                  )}
                </Box>
              )}
              placement='top'
            />
          )}
        </Body>
      ) : type === 'multi_listing_sold' ? (
        <Body {...s('targetText')}>Target people in your local community</Body>
      ) : (
        <Body {...s('targetText')}>Your suburbs</Body>
      )}
    </Box>
  );
}

export default compose(withModel(session))(MapInfo);

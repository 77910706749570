/* eslint-disable max-lines */
import React, { PureComponent } from 'react';
import Box from '@rexlabs/box';
import { styled, StyleSheet } from '@rexlabs/styling';
import _ from 'lodash';
import { autobind } from 'core-decorators';
import { COLORS } from 'src/theme';

import Container from '../shared/container';
import CarouselImage from './carousel-image';
import CarouselBrand from './carousel-brand';

const CONTAINER_WIDTH = 476;
const SLIDE_WIDTH = 300;
const SLIDE_PADDING = 8;

const defaultStyles = StyleSheet({
  container: {
    width: '100%',
    marginRight: '-1.2rem',
    padding: '0 1.2rem',
    position: 'relative',
    overflow: 'hidden',
    fontFamily: 'Helvetica, Arial, sans-serif',
    color: '#1d2129',
    fontWeight: 'normal'
  },

  wrapPicture: {
    width: '100%',
    paddingBottom: '100%',
    background: 'none no-repeat center center',
    backgroundColor: COLORS.GREY_LIGHT,
    backgroundSize: 'cover',
    overflow: 'hidden',
    position: 'relative'
  },

  wrapText: {
    width: '100%',
    background: '#f6f7f9',
    border: '.1rem solid #d8d8d8',
    borderTop: '0 none',
    padding: '1.2rem',
    whiteSpace: 'normal'
  },

  headline: {
    fontSize: '1.8rem',
    fontWeight: 500,
    margin: '.4rem 0',
    padding: 0,
    fontFamily: 'Georgia,"lucida grande",tahoma,verdana,arial,sans-serif'
  },

  description: {
    fontSize: '1.2rem',
    lineHeight: '1.8rem',
    fontWeight: 'bold',
    margin: '.1rem 0',
    height: '3.6rem',
    color: '#000',
    padding: 0,
    WebkitLineClamp: 2,
    overflow: 'hidden',
    paddingRight: '.4rem'
  },

  learnMore: {
    border: '.1rem solid #ced0d4',
    background: '#f6f7f9',
    color: '#4b4f56',
    borderRadius: '.2rem',
    padding: '0 .8rem',
    fontSize: '1.2rem',
    lineHeight: '2.2rem',
    marginTop: '.2rem',
    whiteSpace: 'nowrap',
    textShadow: 'none',
    verticalAlign: 'middle',
    fontWeight: 'bold',
    boxSizing: 'content-box'
  },

  carousel: {
    width: '1000000px',
    position: 'relative',
    transition: 'transform .4s',
    display: 'inline-block',
    height: '100%',
    marginLeft: '-.8rem',
    whiteSpace: 'nowrap'
  },

  carouselItem: {
    // height: '47rem',
    position: 'relative',
    display: 'inline-block',
    paddingLeft: '.8rem',
    paddingBottom: '.8rem',
    transition: 'opacity .3s',
    opacity: 1
  },

  carouselItemInner: {
    width: SLIDE_WIDTH
  },

  carouselPrev: {
    position: 'absolute',
    top: '50%',
    left: 0,
    width: '5rem',
    height: '5rem',
    marginTop: '-2.5rem',
    zIndex: 40,
    transition: 'opacity .3s',
    opacity: 0.8,
    border: '.1rem solid #d8d8d8',
    background: 'white',
    color: '#d8d8d8',
    fontSize: '2.4rem',
    cursor: 'pointer',

    ':hover': {
      opacity: 1
    }
  },

  carouselNext: {
    position: 'absolute',
    top: '50%',
    right: 0,
    width: '5rem',
    height: '5rem',
    marginTop: '-2.5rem',
    zIndex: 40,
    transition: 'opacity .3s',
    opacity: 0.8,
    border: '.1rem solid #d8d8d8',
    background: 'white',
    color: '#d8d8d8',
    fontSize: '2.4rem',
    cursor: 'pointer',

    ':hover': {
      opacity: 1
    }
  },

  disabled: {
    opacity: '0 !important'
  }
});

@styled(defaultStyles)
@autobind
class FacebookCarousel extends PureComponent {
  constructor(props) {
    super(props);
    this.adContainer = React.createRef();
    this.state = {
      curr: 0,
      adWrapperDimensions: { width: 0, height: 0 }
    };
  }

  componentDidMount() {
    const width = _.get(this.adContainer, 'current.parentElement.offsetWidth');
    if (!width) return;
    this.setState({ adWrapperDimensions: { width, height: width } });
  }

  slidePrev() {
    const { curr } = this.state;
    this.setState({
      curr: curr > 0 ? curr - 1 : 0
    });
  }

  slideNext() {
    const { curr } = this.state;
    this.setState({
      curr: curr < 2 ? curr + 1 : 2
    });
  }

  getCarouselPosition() {
    const { curr } = this.state;
    const slideCnt = 3;

    let currX =
      (CONTAINER_WIDTH - SLIDE_WIDTH - 8) / 2 -
      curr * (SLIDE_WIDTH + SLIDE_PADDING);

    const min = 0;
    const max =
      CONTAINER_WIDTH -
      (SLIDE_WIDTH * slideCnt + SLIDE_PADDING * (slideCnt - 1));

    if (currX > min) currX = min;
    if (currX < max) currX = max;

    return currX;
  }

  render() {
    const { styles: s, adContentSet, disableWrapper, variation } = this.props;
    const { curr, adWrapperDimensions } = this.state;

    const primaryUrl = _.get(adContentSet, 'primaryImage.square');
    const secondaryUrl = _.get(adContentSet, 'secondaryImage.square');
    const agentImageUrl = _.get(adContentSet, 'agentImage.square');

    if (disableWrapper) {
      if (variation === 'primary') {
        return (
          <CarouselImage
            imageUrl={primaryUrl}
            adContentSet={adContentSet}
            scaleTo={1000}
          />
        );
      } else if (variation === 'secondary') {
        return (
          <CarouselImage
            imageUrl={secondaryUrl}
            adContentSet={adContentSet}
            scaleTo={1000}
          />
        );
      } else {
        return (
          <CarouselBrand
            agentImageUrl={agentImageUrl}
            agencyLogoUrl={adContentSet.agencyLogo}
            backgroundColor={adContentSet.backgroundColor}
            adContentSet={adContentSet}
            scaleTo={1000}
          />
        );
      }
    }

    return (
      <Container {...this.props} descriptionLimit={155}>
        <Box {...s('container')}>
          <button
            type='button'
            onClick={this.slidePrev}
            {...s('carouselPrev', { disabled: curr === 0 })}
          >
            &lsaquo;
          </button>
          <button
            type='button'
            onClick={this.slideNext}
            {...s('carouselNext', { disabled: curr === 2 })}
          >
            &rsaquo;
          </button>
          <Box
            {...s.with('carousel')({
              transform: `translateX(${this.getCarouselPosition()}px)`
            })}
          >
            <Box {...s('carouselItem')}>
              <Box {...s('carouselItemInner')}>
                <div ref={this.adContainer} style={adWrapperDimensions}>
                  <CarouselImage
                    imageUrl={primaryUrl}
                    adContentSet={adContentSet}
                    scaleTo={adWrapperDimensions.width}
                  />
                </div>

                <Box {...s('wrapText', 'carouselWrapText')}>
                  <Box
                    {...s('textFooter')}
                    flexDirection='row'
                    justifyContent='space-between'
                    alignItems='flex-end'
                  >
                    <span {...s('description')}>{adContentSet.title}</span>
                    <button type='button' {...s('learnMore')}>
                      Learn More
                    </button>
                  </Box>
                </Box>
              </Box>
            </Box>
            <Box {...s('carouselItem')}>
              <Box {...s('carouselItemInner')}>
                <div style={adWrapperDimensions}>
                  <CarouselImage
                    imageUrl={secondaryUrl}
                    adContentSet={adContentSet}
                    scaleTo={adWrapperDimensions.width}
                  />
                </div>
                <Box {...s('wrapText', 'carouselWrapText')}>
                  <Box
                    {...s('textFooter')}
                    flexDirection='row'
                    justifyContent='space-between'
                    alignItems='flex-end'
                  >
                    <span {...s('description')}>{adContentSet.headline}</span>
                    <button type='button' {...s('learnMore')}>
                      Learn More
                    </button>
                  </Box>
                </Box>
              </Box>
            </Box>
            <Box {...s('carouselItem')}>
              <Box {...s('carouselItemInner')}>
                <div style={adWrapperDimensions}>
                  <CarouselBrand
                    backgroundColor={adContentSet.backgroundColor}
                    agentImageUrl={agentImageUrl}
                    agencyLogoUrl={adContentSet.agencyLogo}
                    adContentSet={adContentSet}
                    scaleTo={adWrapperDimensions.width}
                  />
                </div>
                <Box {...s('wrapText', 'carouselWrapText')}>
                  <Box
                    {...s('textFooter')}
                    flexDirection='row'
                    justifyContent='space-between'
                    alignItems='flex-end'
                  >
                    <span {...s('description')}>
                      More Information - Contact{' '}
                      {adContentSet.agentName || adContentSet.agency}
                    </span>
                    <button type='button' {...s('learnMore')}>
                      Learn More
                    </button>
                  </Box>
                </Box>
              </Box>
            </Box>
          </Box>
        </Box>
      </Container>
    );
  }
}

export default FacebookCarousel;

/* eslint-disable max-lines */
import { Box } from '@rexlabs/box';
import { query, withQuery } from '@rexlabs/model-generator';
import { withWhereabouts } from '@rexlabs/react-whereabouts';
import { styled, StyleSheet, StylesProvider, useToken } from '@rexlabs/styling';
import { Body, Heading, Small } from '@rexlabs/text';
import { match } from '@rexlabs/whereabouts';
import React, { useEffect, useMemo } from 'react';
import { Helmet } from 'react-helmet';
import { compose } from 'redux';
import { useFeature } from 'shared/hooks';
import { Logo } from 'src/components/elements/logo';
import vendorReportsModel from 'src/data/models/entities/vendor-reports';
import ROUTES from 'src/routes/admin';
import { BREAKPOINTS, SHADOWS } from 'src/theme';
import { AVNU_TOKENS } from 'src/theme/avnu-tokens';
import { Carousel } from 'src/view/components/ad-previews';
import ErrorBoundary from 'src/view/components/error-boundary';
import { RenderLoading } from 'src/view/components/loading';
import { ModalTarget } from 'src/view/components/portal';
import {
  AdsPrintPreview,
  // LandingPagePrintPreview
  AudienceDetails,
  CampaignPerformance,
  Header,
  Status
} from 'src/view/components/report';
import {
  DemographicStats,
  DeviceStats,
  StatsHeading
} from 'src/view/components/stats';

import { navigation } from '@rexlabs/analytics';
import GoogleTagManager from '@rexlabs/analytics/lib/providers/google-tag-manager';
import { brand } from 'src/theme/brand';
import {
  AdsPreview,
  AdsPrintPreview as FlexAdsPrintPreview
} from 'src/features/ads';

function getToken({ whereabouts }) {
  const matched = match(whereabouts, ROUTES.REPORT.config);
  return matched?.params?.reportToken;
}

const reportQuery = query`{
  ${vendorReportsModel} (id: ${getToken}) {
    id
    campaign
    account
    agency
    stats
    sections
    gtm_container_id
  }
}`;

const defaultStyles = StyleSheet({
  container: {
    background: ({ token }) => token('legacy.color.background'),
    width: '100%',

    '@media print': {
      width: '210mm !important',
      background: '#fff',
      '-webkit-print-color-adjust': 'exact !important',

      '@page': {
        size: 'A4',
        margin: '10mm 10mm 10mm 10mm'
      }
    }
  },

  inner: {
    width: '100%',
    maxWidth: '108.3rem',
    paddingLeft: ({ token }) => token('spacing.m'),
    paddingRight: ({ token }) => token('spacing.m'),

    [BREAKPOINTS.PHONE_ONLY]: {
      paddingLeft: 0,
      paddingRight: 0
    }
  },

  footer: {
    marginBottom: ({ token }) => token('spacing.xxl'),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',

    '@media print': {
      display: 'none'
    }
  },

  wrapContent: {
    background: ({ token }) => token('legacy.color.background'),
    marginTop: ({ token }) => token('spacing.xl'),
    marginBottom: ({ token }) => token('spacing.xxl'),
    overflowX: 'hidden',

    '@media print': {
      background: '#fff'
    }
  },

  content: {
    width: '100%',
    background: ({ token }) => token('legacy.color.white'),
    boxShadow: SHADOWS.REPORT,
    borderRadius: '.2rem',
    flexDirection: 'row',
    display: 'flex',
    marginBottom: ({ token }) => token('spacing.m'),
    padding: ({ token }) => token('spacing.xxl'),
    position: 'relative',

    '& ul': {
      paddingLeft: '3.2rem',
      listStyle: 'disc',
      marginBottom: ({ token }) => token('spacing.xs')
    },

    '& ul li': {
      marginBottom: '.2rem'
    },

    [BREAKPOINTS.PHONE_ONLY]: {
      padding: ({ token }) => token('spacing.xl')
    },

    '@media print': {
      boxShadow: 'none',
      padding: 0
    }
  },

  noPadding: {
    padding: 0
  },

  wrapAdPreviews: {
    height: '70rem',
    overflow: 'auto',

    [BREAKPOINTS.REPORT.TABLET]: {
      height: '64rem'
    },

    [BREAKPOINTS.PHONE_ONLY]: {
      overflow: 'hidden'
    }
  },

  flexAdPreviewsContainer: {
    padding: '0.8rem 3.2rem'
  },

  infoIcon: {
    width: '1.5rem',
    height: '1.5rem',
    display: 'inline-flex',
    color: ({ token }) => token('legacy.color.blue.grey'),
    transition: 'color .2s',
    transform: 'translateY(.2rem)',
    marginTop: '.8rem',
    marginRight: ({ token }) => token('spacing.m'),
    flexShrink: 0
  },

  seperator: {
    marginTop: ({ token }) => token('spacing.m'),
    marginBottom: ({ token }) => token('spacing.m'),
    height: '.1rem',
    background: ({ token }) => token('legacy.color.grey.default')
  },

  poweredByLogo: {
    height: '1.4rem',
    marginLeft: ({ token }) => token('spacing.xs'),
    color: ({ token }) => token('legacy.color.primary.default')
  },

  poweredBySpoke: {
    height: '6rem'
  },

  screenOnly: {
    '@media print': {
      display: 'none !important'
    }
  }
});

function ReportLayoutCore({ vendorReports, styles: s }) {
  const token = useToken();
  const isLoading = vendorReports?.item?.status === 'loading';

  const mapAdsData = useMemo(() => {
    if (isLoading) return;

    const reportData = vendorReports?.item?.data ?? {};
    const instagramAds = !!(
      reportData?.campaign?.audience_spec?.networks ?? []
    ).find((i) => i.id === 'instagram');
    const ads = (reportData?.campaign?.ads?.data ?? []).reduce((all, ad) => {
      if (
        ad?.format?.id.includes('.adwords.responsive') ||
        ad?.format?.id.includes('.google.responsive_display_ad')
      ) {
        // Split adwords responsive ad into the differnt format variations
        // it generates on Google side
        ['Native', 'Standard', 'Wide', 'High', 'Small'].forEach((variation) => {
          all.push({
            ...ad,
            id: `${ad.id}::adwords:${variation.toLowerCase()}`,
            format: {
              id: `${ad.format.id}.${variation.toLowerCase()}`,
              label: `${ad.format.label} (${variation})`
            },
            matchNetwork: {
              id: 'adwords'
            }
          });
        });
      } else if (ad?.network?.id === 'facebook') {
        // Each facebok ad has an equivalent instagram ad
        // However, we treat those ads as 1 in the BE, so we need to
        // manually split them here
        all.push({
          ...ad,
          matchNetwork: {
            id: 'facebook'
          }
        });
        if (instagramAds) {
          all.push({
            ...ad,
            id: `${ad.id}::instagram`,
            name: ad.name.replace('Facebook ', 'Instagram '),
            format: {
              id: ad.format.id.replace('.facebook.', '.instagram.'),
              label: ad.format.label.replace('Facebook ', 'Instagram ')
            },
            matchNetwork: {
              id: 'instagram'
            }
          });
        }
      } else {
        all.push({
          ...ad,
          matchNetwork: {
            id: 'adwords'
          }
        });
      }
      return all;
    }, []);

    const campaignId = reportData?.campaign?.id;
    const account = reportData?.account;
    const networks = reportData?.campaign?.audience_spec?.networks;

    const prospecting =
      (reportData?.campaign?.ad_content_sets?.data ?? []).find((set) =>
        set.type.id.endsWith('.prospecting')
      ) || {};

    const retargeting =
      (reportData?.campaign?.ad_content_sets?.data ?? []).find((set) =>
        set.type.id.endsWith('.retargeting')
      ) || {};
    const { beds, baths, cars } = prospecting?.attributes || {};

    const campaignData = {
      listing: { beds, baths, cars },
      facebookPage: reportData?.agency?.business_name,
      landing_pages: reportData?.campaign?.landing_pages?.data?.[0],
      address:
        reportData?.campaign?.type?.id === 'single_listing'
          ? (reportData?.campaign?.audience_spec?.locations ?? []).find(
              (location) => location.type.id === 'address'
            )
          : undefined,
      suburbs: (reportData?.campaign?.audience_spec?.locations ?? []).filter(
        (location) => location.type.id === 'suburb'
      ),
      youngest: reportData?.campaign?.audience_spec?.age_range?.youngest,
      oldest: reportData?.campaign?.audience_spec?.age_range?.oldest,
      languages: reportData?.campaign?.audience_spec?.languages,
      images: reportData?.campaign?.images,
      segments: {
        house_owners: true,
        apartment_owners: true,
        buying_and_selling_real_estate: true,
        moving_and_relocating: true,
        luxury_property_owners: true,
        retargeting: {
          website_visitors: true,
          database: true
        }
      },
      networks: {
        facebook: true,
        instagram: true,
        adwords: true
      },
      type: reportData?.campaign?.type?.id,
      name: reportData?.agency?.name,
      suburb:
        reportData?.campaign?.type?.id !== 'single_listing'
          ? (reportData?.campaign?.audience_spec?.locations ?? []).find(
              (location) => location.type.id === 'address'
            )
          : undefined,
      prospecting: {
        ...prospecting,
        ...(prospecting?.attributes ?? {}),
        primary_image: (reportData?.campaign?.images ?? []).find(
          (image) => image.id === prospecting?.attributes?.primary_image?.id
        ),
        secondary_image: (reportData?.campaign?.images ?? []).find(
          (image) => image.id === prospecting?.attributes?.secondary_image?.id
        ),
        agent_image: (reportData?.campaign?.images ?? []).find(
          (image) => image.id === prospecting?.attributes?.agent_image?.id
        ),
        agency_logo: reportData?.agency?.logo,
        slides: prospecting?.attributes?.slides,
        video: reportData?.campaign?.videos.find(
          (video) => video?.id === prospecting?.attributes?.video?.id
        )
      },
      retargeting: {
        ...retargeting,
        ...(retargeting?.attributes ?? {}),
        primary_image: (reportData?.campaign?.images ?? []).find(
          (image) => image.id === retargeting?.attributes?.primary_image?.id
        ),
        secondary_image: (reportData?.campaign?.images ?? []).find(
          (image) => image.id === retargeting?.attributes?.secondary_image?.id
        ),
        agent_image: (reportData?.campaign?.images ?? []).find(
          (image) => image.id === retargeting?.attributes?.agent_image?.id
        ),
        agency_logo: reportData?.agency?.logo,
        slides: retargeting?.attributes?.slides
      },
      campaign_type: reportData?.campaign?.type?.id
    };

    // adTypes and adCount are only needed for the flex ad previews
    const adTypes = reportData?.campaign?.campaign_definition?.ad_types;
    const adCount = reportData?.campaign?.ad_count?.count;

    return {
      account,
      campaignData,
      ads: { list: { items: ads } },
      campaignId,
      networks,
      adTypes,
      adCount
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLoading]);

  const reportData = vendorReports?.item?.data ?? {};
  const sections = (reportData?.sections ?? []).reduce(
    (all, section) => ({
      ...all,
      [section.id]: true
    }),
    {}
  );

  const prospecting =
    (reportData?.campaign?.ad_content_sets?.data ?? []).find((set) =>
      set.type.id.endsWith('.prospecting')
    ) || {};
  const isAgency = (prospecting?.attributes?.slides ?? []).some(
    (s) => s.type === 'agency'
  );

  const gtmId = reportData?.gtm_container_id;
  const thumbUrl =
    reportData?.campaign?.thumbnails?.[
      isAgency ? 'original.thumbnail' : 'crop.landscape'
    ]?.url;

  const statsData = reportData?.stats || {};

  const agencyLogo = reportData?.agency?.logo?.sizes?.original?.url;
  const backgroundColor = reportData?.agency?.brand_colours?.background;

  const campaignName = reportData?.campaign?.name ?? '';
  const campaignType = reportData?.campaign?.type?.id;
  const landingPage = reportData?.campaign?.landing_pages?.data?.[0];

  const spokeLandingPage =
    ['single_listing', 'multi_listing_sold'].includes(campaignType) &&
    !landingPage?.is_external;
  const webflowLandingPage =
    !!reportData?.campaign?.ad_content_sets?.data?.some((acs) =>
      /^https?:\/\/clp\.spokeapp\.io/.test(acs.final_url)
    );
  const networks = reportData?.campaign?.audience_spec?.networks || [];
  const googleOnlyCampaign =
    networks.length === 1 && networks[0]?.id === 'adwords';

  const session = {
    accounts: [reportData?.account],
    currentAccountId: reportData?.account?.id
  };
  const customBrandedReport = useFeature(
    'custom_branded_vendor_report',
    session
  );
  const googleAdsEnabled = useFeature('google_ads_enabled', session);
  const showFlexAdsPreview =
    mapAdsData?.campaignData?.type === 'flex' && mapAdsData?.adTypes?.length;

  return (
    <StylesProvider tokens={customBrandedReport ? AVNU_TOKENS : {}}>
      <RenderLoading isLoading={isLoading} minHeight='100vh'>
        {/* Client GTM */}
        {gtmId && <GoogleTagManager id={gtmId} />}

        {/* GTM used by Reach team */}
        <GoogleTagManager id='GTM-PZPF5Q8' />

        <Box {...s('container')}>
          <Helmet>
            <title>Campaign Report - {campaignName}</title>
            <meta
              name='viewport'
              content='width=device-width, initial-scale=1'
            />
          </Helmet>

          <ModalTarget />

          <Header
            customBrandedReport={customBrandedReport}
            thumbUrl={thumbUrl}
            campaignName={reportData?.campaign?.name}
            campaignLabel={reportData?.campaign?.type?.label}
            agencyLogo={agencyLogo}
            backgroundColor={backgroundColor}
            imageContain={isAgency}
          />

          <Box
            flex={1}
            flexDirection='column'
            alignItems='center'
            {...s('wrapContent')}
          >
            <Box {...s('inner')}>
              {sections.status_and_package && (
                <Status
                  campaign={reportData?.campaign}
                  status={reportData?.campaign?.status}
                  plan={reportData?.campaign?.active_package}
                  spent={reportData?.campaign?.duration?.spent}
                  duration={reportData?.campaign?.duration?.total}
                  planType={reportData?.campaign?.active_package?.type?.id}
                  dateFrom={reportData?.stats?.date_from}
                  dateTo={reportData?.stats?.date_to}
                  lastWeek={
                    reportData?.campaign?.renew === true &&
                    reportData?.campaign?.limit_renewals === true &&
                    reportData?.campaign?.renewals?.left === 0
                  }
                />
              )}

              {sections.campaign_performance && (
                <CampaignPerformance
                  googleAdsEnabled={googleAdsEnabled}
                  spokeLandingPage={spokeLandingPage}
                  webflowLandingPage={webflowLandingPage}
                  statsData={statsData}
                  campaignData={reportData?.campaign}
                />
              )}

              <Box {...s.with('content')({ display: 'block' })}>
                <DeviceStats stats={statsData} />
                <div {...s('seperator', 'screenOnly')} />
                {/*
                  NOTE: age stats not currently collected from google due to api change
                  so there was a large number of unknown. When this is fixed remember to
                  set clicks_age_unknown and impressions_age_unknown correct in the
                  demographic-stats component.
                */}
                {!googleOnlyCampaign && (
                  <>
                    <DemographicStats stats={statsData} />
                    {/* <div {...s('seperator', 'screenOnly')} />

                    <Box flexDirection='row'>
                      <InfoIcon {...s('infoIcon')} />
                      <Tiny grey>
                        We are currently experiencing technical issues, which may
                        result in the age and gender data not being displayed 100%
                        accurately. We are working to fix this for you asap.
                      </Tiny>
                    </Box> */}
                  </>
                )}
              </Box>

              {sections.audience_details && (
                <AudienceDetails
                  audienceSpec={reportData?.campaign?.audience_spec}
                />
              )}

              {sections.ads_preview && (
                <>
                  <Box
                    {...s('content', 'noPadding', 'screenOnly')}
                    flexDirection='column'
                  >
                    <Box p={token('spacing.xxl')} width='100%'>
                      <StatsHeading title='Ad Previews' Filters={() => null} />
                    </Box>
                    <Box mt='-2.4rem' width='100%' {...s('wrapAdPreviews')}>
                      {showFlexAdsPreview ? (
                        <Box {...s('flexAdPreviewsContainer')}>
                          <AdsPreview
                            adContentSet={mapAdsData.campaignData.retargeting}
                            adTypes={mapAdsData.adTypes}
                          />
                        </Box>
                      ) : (
                        <Carousel {...mapAdsData} report />
                      )}
                    </Box>
                  </Box>

                  {showFlexAdsPreview ? (
                    <FlexAdsPrintPreview
                      adContentSet={mapAdsData.campaignData.retargeting}
                      adTypes={mapAdsData.adTypes}
                      adCount={mapAdsData.adCount}
                    />
                  ) : (
                    <AdsPrintPreview {...mapAdsData} />
                  )}
                </>
              )}
            </Box>
          </Box>

          {!customBrandedReport && (
            <Box
              mt={token('spacing.xxl')}
              mb={token('spacing.xxxl')}
              {...s('footer')}
            >
              <Box
                mt={token('spacing.xs')}
                mb={token('spacing.xxs')}
                flexDirection='column'
                alignItems='center'
              >
                <Body grey as='span'>
                  Powered by
                </Body>
                {brand === 'reach' ? (
                  <a
                    href='https://spokeapp.io'
                    target='blank'
                    style={{ display: 'flex', alignItems: 'center' }}
                  >
                    <Logo type='secondary' {...s('poweredBySpoke')} />
                  </a>
                ) : (
                  <Logo type='secondary' {...s('poweredByLogo')} />
                )}
              </Box>
              {brand === 'reach' && (
                <Small grey Tag='span'>
                  Easier real estate advertising starts today.
                </Small>
              )}
            </Box>
          )}
        </Box>
      </RenderLoading>
    </StylesProvider>
  );
}

const ReportLayout = compose(
  withQuery(reportQuery),
  styled(defaultStyles)
)(ReportLayoutCore);

const coreStyles = StyleSheet({
  container: {
    position: 'fixed',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    overflow: 'auto'
  },

  wrapError: {
    padding: ({ token }) => token('spacing.xl'),
    width: '100%',
    maxWidth: '50rem',
    paddingTop: '10rem'
  }
});

function Core({ styles: s, ...props }) {
  const {
    whereabouts: { path, hash }
  } = props;

  useEffect(() => {
    navigation({
      page: '/report',
      properties: {
        path,
        hash
      }
    });
  }, [path, hash]);

  return (
    <ErrorBoundary
      ErrorView={() => (
        <Box
          {...s('container')}
          flexDirection='column'
          justifyContent='flex-start'
          alignItems='center'
        >
          <Box {...s('wrapError')}>
            <Heading>Oops, this should not happen!</Heading>
            <Body>
              An error occured and we couldn’t properly recover from it. Please
              try again. If the error persists, please contact our support team.
            </Body>
          </Box>
        </Box>
      )}
    >
      <ReportLayout {...props} />
    </ErrorBoundary>
  );
}

export default compose(styled(coreStyles), withWhereabouts)(Core);

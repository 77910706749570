import { RexApiError } from '@rexlabs/api-client';
import { useInfiniteQuery } from 'react-query';
import { useAccountId } from 'src/features/accounts';
import { InfiniteQueryConfig, InfiniteQueryResult } from 'src/lib/react-query';
import {
  AccountScopedQueryParams,
  PaginatedResponse,
  Pagination,
  PaginationParams
} from 'src/types';
import { api } from 'src/utils/api-client';
import { Strategy, StrategyType } from '../types';
import { transformStrategyResponse } from '../utils/schemas';
import { strategiesKeys } from './keys';

export type GetStrategiesParams = AccountScopedQueryParams &
  PaginationParams & {
    includes?: string[];
    type: StrategyType;
  };

export async function getStrategies({
  accountId,
  includes = [],
  type,
  ...rest
}: GetStrategiesParams): Promise<PaginatedResponse<Strategy>> {
  const { data, pagination } = await api.get('campaign-strategies', {
    include: includes.join(','),
    q:
      type === 'current'
        ? 'status.neq(finished)'
        : type === 'past'
        ? 'status.eq(finished)'
        : undefined,
    ...rest
  });
  return {
    data: data.map(transformStrategyResponse),
    pagination: pagination as Pagination
  };
}

type QueryFn = typeof getStrategies;

export type UseStrategiesOptions = Omit<
  GetStrategiesParams,
  keyof AccountScopedQueryParams | 'page'
> & {
  config?: InfiniteQueryConfig<QueryFn>;
};

export function useStrategies({
  config = {},
  ...params
}: UseStrategiesOptions): InfiniteQueryResult<Strategy> {
  const accountId = useAccountId();

  return useInfiniteQuery<PaginatedResponse<Strategy>, RexApiError>({
    queryKey: strategiesKeys.list({ accountId, ...params }),
    queryFn: ({ pageParam }) =>
      getStrategies({ accountId, page: pageParam, ...params }),
    getNextPageParam: ({ pagination }) =>
      pagination.current_page === pagination.total_pages
        ? undefined
        : pagination.current_page + 1,
    ...config
  });
}

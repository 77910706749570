import { Box } from '@rexlabs/box';
import { RenderMatchedRoutes } from '@rexlabs/react-whereabouts';
import { styled, StyleSheet } from '@rexlabs/styling';
import React from 'react';
import { Helmet } from 'react-helmet';
import { Logo } from 'src/components/elements/logo';
import ROUTES from 'src/routes/notifications';
import { BREAKPOINTS } from 'src/theme';
import { NotFoundScreenCore } from 'src/view/screens/not-found';

const defaultStyles = StyleSheet({
  container: {
    padding: '0 3rem',

    [BREAKPOINTS.TABLET_LANDSCAPE_UP]: {
      padding: '0 6rem'
    }
  },

  header: {
    padding: '3rem 0 4.8rem',

    [BREAKPOINTS.TABLET_LANDSCAPE_UP]: {
      padding: '6rem 0 3.6rem'
    }
  },

  logo: {
    color: ({ token }) => token('legacy.color.primary.default'),
    height: '4.8rem'
  }
});

function NotificationsLayout(props) {
  const { styles: s } = props;

  return (
    <>
      <Helmet>
        <meta name='viewport' content='width=device-width, initial-scale=1' />
      </Helmet>

      <Box flexDirection='column' w='100%' {...s('container')}>
        <Box Container='header' {...s('header')}>
          <a href='https://www.spokeapp.io' rel='nofollow noreferrer'>
            <Logo type='secondary' {...s('logo')} />
          </a>
        </Box>

        <RenderMatchedRoutes routes={ROUTES} NotFound={NotFoundScreenCore}>
          {(matches) => matches}
        </RenderMatchedRoutes>
      </Box>
    </>
  );
}

export default styled(defaultStyles)(NotificationsLayout);

import { Box } from '@rexlabs/box';
import { GhostButton } from '@rexlabs/button';
import { query, withQuery } from '@rexlabs/model-generator';
import { border, padding, StyleSheet, useStyles } from '@rexlabs/styling';
import React, { useEffect, useState } from 'react';
import paymentOptionsModel from 'src/data/models/entities/payment-options';
import { RenderLoading } from 'src/view/components/loading';
import { PaymentPreview } from 'src/view/components/payment';
import { Body } from 'src/view/components/text';
import ChangeCreditCardModal from 'src/view/modals/admin/settings/payments/change-credit-card';

const defaultStyles = StyleSheet({
  container: {
    backgroundColor: ({ token }) => token('color.container.static.contrast'),
    ...padding.styles({
      all: 'xs'
    }),
    ...border.styles({
      all: {
        radius: 'm'
      }
    })
  }
});

const paymentOptionsQuery = query`{
  ${paymentOptionsModel} {
    id
    name
    type
    available_funds
    attributes {
      card {
        brand
        expires_at {
          month
          year
        }
        last_4_digits
      }
    }
    is_primary
    private_to_user
  }
}`;

function PaymentOptions({
  paymentOptions,
  selectedCard,
  campaignName,
  onChange
}) {
  const [paymentOptionModal, setPaymentOptionModal] = useState(false);
  const s = useStyles(defaultStyles);

  useEffect(() => {
    if (!selectedCard && paymentOptions.list.status === 'loaded') {
      onChange(
        paymentOptions.list.items.find((po) => po.is_primary) ||
          paymentOptions.list.items[0]
      );
    }
  }, [selectedCard, paymentOptions, onChange]);

  return (
    <RenderLoading
      minHeight='9rem'
      isLoading={paymentOptions.list.status !== 'loaded'}
    >
      {selectedCard ? (
        <Box
          {...s('container')}
          flexDirection='row'
          alignItems='center'
          justifyContent='flex-start'
          flexWrap='wrap'
          data-testid={'payment-option-preview'}
        >
          <PaymentPreview
            type={selectedCard?.type?.id}
            availableFunds={selectedCard?.available_funds}
            provider={selectedCard?.attributes?.card?.brand}
            number={selectedCard?.attributes?.card?.last_4_digits}
            primary={selectedCard?.is_primary}
            name={selectedCard?.name}
            expiryDate={`${selectedCard?.attributes?.card?.expires_at?.month}/${selectedCard?.attributes?.card?.expires_at?.year}`}
            privateUserId={selectedCard?.private_to_user?.id}
          />
          <GhostButton onClick={() => setPaymentOptionModal(true)}>
            Change
          </GhostButton>
        </Box>
      ) : (
        <Box w='100%' {...s('container')}>
          <Body grey>No payment option found.</Body>
          <GhostButton onClick={() => setPaymentOptionModal(true)}>
            Add payment option
          </GhostButton>
        </Box>
      )}

      {paymentOptionModal && (
        <ChangeCreditCardModal
          campaignName={campaignName}
          paymentOption={selectedCard}
          closeModal={(selectedCard) => {
            onChange(selectedCard);
            setPaymentOptionModal(false);
          }}
        />
      )}
    </RenderLoading>
  );
}

export default withQuery(paymentOptionsQuery)(PaymentOptions);

import { StyleSheet, border, padding, useStyles } from '@rexlabs/styling';
import { partial } from 'lodash';
import React, {
  ComponentProps,
  ComponentType,
  FC,
  useCallback,
  useMemo
} from 'react';
import { ListItemProps } from 'src/components/elements/listbox';
import { Consumer } from 'src/types';
import { CommercialListing } from '../types';

const styles = StyleSheet({
  list: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'stretch',
    overflowY: 'auto',
    width: '100%',

    '> * + *': {
      ...border.styles({
        top: {
          width: 'thin',
          color: 'container.static.medium'
        },
        bottom: {
          width: 'none'
        }
      })
    },

    '> *': {
      ...padding.styles({
        y: 'xs',
        x: 'm'
      })
    }
  }
});

export type ItemProps = Pick<ListItemProps, 'posinset' | 'selected'> & {
  item: CommercialListing;
  onChange: Consumer<boolean>;
};

export type CommercialListingListProps = Omit<
  ComponentProps<'div'>,
  'children' | 'role' | 'aria-setsize'
> & {
  items: CommercialListing[];
  Item: ComponentType<ItemProps>;
  totalItems: number;
  selected: string[];
  onSelection: Consumer<string[]>;
};

export const CommercialListingList: FC<CommercialListingListProps> = ({
  items,
  totalItems,
  Item,
  selected,
  onSelection,
  className,
  style,
  ...rest
}) => {
  const s = useStyles(styles, 'TestimonialList');

  const selectionSet = useMemo(() => new Set(selected), [selected]);
  const fireChange = useCallback(() => {
    onSelection?.(Array.from(selectionSet.values()));
  }, [onSelection, selectionSet]);

  const updateSelection = useCallback(
    (state: boolean, id: string) => {
      if (state) {
        selectionSet.add(id);
      } else {
        selectionSet.delete(id);
      }
      fireChange();
    },
    [fireChange, selectionSet]
  );

  return (
    <div
      {...s.with('list')({ className, style })}
      {...rest}
      role='listbox'
      aria-setsize={totalItems}
    >
      {items.map((item, i) => (
        <Item
          key={item.id}
          item={item}
          onChange={partial(updateSelection, partial.placeholder, item.id)}
          posinset={i}
          selected={selectionSet.has(item.id)}
        />
      ))}
    </div>
  );
};

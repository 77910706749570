import { track } from '@rexlabs/analytics';
import { Box } from '@rexlabs/box';
import Icons from '@rexlabs/icons-next';
import { useModelState } from '@rexlabs/model-generator';
import {
  border,
  margin,
  padding,
  StyleSheet,
  useStyles
} from '@rexlabs/styling';
import { Body } from '@rexlabs/text';
import React from 'react';
import { useFeature } from 'shared/hooks';
import sessionModel from 'src/data/models/custom/session';
import { FormField, LabelOptionalField } from 'src/view/components/form';
import { HelpIcon } from 'src/view/components/tooltip';
import { FileUploadInput } from './file-upload';

const defaultStyles = StyleSheet({
  container: {
    ...border.styles({
      all: {
        width: 'thin',
        color: 'input.idle',
        radius: 'm'
      }
    }),
    ...padding.styles({
      x: 's',
      y: 'xxs'
    }),
    display: 'flex',
    alignItems: 'center'
  },

  fileContainer: {
    ...margin.styles({
      left: 's'
    }),
    flex: '1',
    maxWidth: '36.4rem',
    color: ({ token }) => token('color.textStyle.body.default'),
    display: 'flex',
    flexFlow: 'row nowrap'
  },

  fileName: {
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    whiteSpace: 'nowrap'
  },

  fileExt: {
    flex: '0'
  }
});

function SingleDocumentUpload({ files, DeleteFile }) {
  const s = useStyles(defaultStyles);
  const [ext, ...name] = files.filename.split('.').reverse();
  return (
    <Box {...s('container')}>
      <Icons.DocumentLegal />
      <a
        {...s('fileContainer')}
        href={files.public_url}
        target='_blank'
        rel='noopener noreferrer'
      >
        <span {...s('fileName')}>{name.reverse().join('.')}</span>
        <span {...s('fileExt')}>.{ext}</span>
      </a>
      <DeleteFile />
    </Box>
  );
}

export default function LandingPageResources() {
  const session = useModelState(sessionModel);
  const showSoi = useFeature('statement_of_information', session);

  if (!showSoi) {
    return null;
  }

  return (
    <FormField
      name='statement_of_information'
      label={
        <span>
          <LabelOptionalField text='Price guide - statement of information' />
          <HelpIcon
            Content={() => (
              <Body>
                A link to this document will appear on the landing page and can{' '}
                be downloaded.
              </Body>
            )}
          />
        </span>
      }
      Input={FileUploadInput}
      inputProps={{
        title: 'Upload Document',
        multi: false,
        accept: ['.pdf', 'application/pdf'],
        FileDisplay: SingleDocumentUpload,
        onUpload: (files: FileList) =>
          track({
            event: 'Spoke SOI uploaded',
            properties: {
              fileType: files[0].type,
              fileSize: files[0].size
            }
          })
      }}
      description='Only .pdf documents are accepted'
    />
  );
}

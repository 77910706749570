import { Box } from '@rexlabs/box';
import { GhostButton, PrimaryButton } from '@rexlabs/button';
import { useToken } from '@rexlabs/styling';
import React from 'react';
import { ModalStickyButtonGroup } from 'src/view/components/button';
import { Modal } from 'src/view/components/modal';
import { Body } from 'src/view/components/text';

function ConfirmFlushModal({ campaigns, closeModal, type }) {
  const token = useToken();

  return (
    <Modal
      title={
        type
          ? type === 'single_listing'
            ? 'Listing details changed'
            : 'Agent details changed'
          : 'Entity details changed'
      }
      subtitle={campaigns?.item?.data?.name}
      width='50rem'
      onClose={() => closeModal(false)}
    >
      <Box pb={token('spacing.xxl')} style={{ width: '100%' }}>
        <Body>
          You have changed the{' '}
          {type ? (type === 'single_listing' ? 'listing' : 'agent') : 'entity'}{' '}
          details. To reflect these changes we have to reset the campaign data.
        </Body>
        <Body grey>
          Would you like to reset the campaign data or do you want to keep
          changes made so far?
        </Body>
      </Box>

      <ModalStickyButtonGroup>
        <GhostButton
          onClick={() => {
            closeModal(false);
          }}
        >
          Cancel
        </GhostButton>

        <PrimaryButton
          onClick={() => {
            closeModal(true);
          }}
        >
          Reset to default
        </PrimaryButton>
      </ModalStickyButtonGroup>
    </Modal>
  );
}

export default ConfirmFlushModal;

import React from 'react';
import Select from './default';
import { StyleSheet } from '@rexlabs/styling';
import { BORDER_RADIUS, SHADOWS } from 'src/theme';

const inputStyles = StyleSheet({
  container: {
    background: ({ token }) => token('legacy.color.grey.light'),
    display: 'inline-flex',
    border: '0 none !important',
    borderRadius: '999px !important',
    paddingLeft: ({ token }) => token('spacing.xs'),
    paddingRight: ({ token }) => token('spacing.xs'),
    marginLeft: ({ token }) => token('spacing.xxs')
  },

  input: {
    width: '27rem'
  }
});

const menuStyles = StyleSheet({
  container: {
    marginTop: ({ token }) => `${token('spacing.xs')} !important`,
    marginLeft: ({ token }) => token('spacing.xs'),
    border: '0 none !important',
    background: ({ token }) => token('legacy.color.white'),
    borderRadius: BORDER_RADIUS.INPUT,
    width: '100%',
    minWidth: '22.5rem',
    boxShadow: SHADOWS.WIZARD_SELECT,
    overflowY: 'scroll',
    overflowX: 'hidden'
  }
});

const WizardSelect = (props) => {
  return (
    <div style={{ display: 'inline-block' }}>
      <Select
        inputStyles={inputStyles}
        menuStyles={menuStyles}
        isSearchable={false}
        isClearable={false}
        {...props}
      />
    </div>
  );
};

export default WizardSelect;

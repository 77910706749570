import { GhostButton } from '@rexlabs/button';
import { margin, StyleSheet, useStyles } from '@rexlabs/styling';
import React, { FC } from 'react';
import { Link, Outlet, useParams } from 'react-router-dom';
import { NavBar } from 'src/components/elements/navbar';
import { useWizardNavigationState } from '../hooks/useWizardNavigationState';
import { ProgressBar } from './ProgressBar';
import { WizardStepList } from './WizardStepList';
import ErrorBar from 'view/components/error-bar';

const styles = StyleSheet({
  container: {
    display: 'flex',
    flex: '1',
    flexDirection: 'column',
    alignItems: 'center',
    height: '100%'
  },

  content: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    width: '100%',
    minHeight: 0,
    height: '100%',

    '& > * + *': {
      ...margin.styles({
        top: 's'
      })
    }
  },

  hideUnderline: {
    textDecoration: 'none'
  }
});

export type WizardLayoutProps = {
  mode: 'create' | 'edit';
};

export const WizardLayout: FC<WizardLayoutProps> = ({ mode }) => {
  const s = useStyles(styles);
  const { accountIndex } = useParams();
  const { currentStepId, progress } = useWizardNavigationState();

  const exitTo =
    mode === 'edit'
      ? `/${accountIndex}/campaigns`
      : `/${accountIndex}/campaign/create`;

  return (
    <div {...s('container')}>
      <ErrorBar />
      <NavBar>
        {currentStepId !== 'confirm' ? (
          <WizardStepList currentStep={currentStepId} />
        ) : null}
        <Link {...s('hideUnderline')} to={exitTo} reloadDocument>
          <GhostButton>
            {mode === 'edit' ? 'Save and exit' : 'Close and exit'}
          </GhostButton>
        </Link>
      </NavBar>

      <ProgressBar progress={progress} />

      <div {...s('content')}>
        <Outlet />
      </div>
    </div>
  );
};

import React, {
  AriaAttributes,
  ComponentType,
  FocusEventHandler,
  ForwardedRef,
  forwardRef,
  PropsWithoutRef
} from 'react';
import { ariaAttr, callAllHandlers } from 'src/utils/dom';
import { FormControlOptions, useFormControlContext } from './FormControl';

export type UseFormControlProps<T extends HTMLElement> = FormControlOptions & {
  id?: string;
  onFocus?: FocusEventHandler<T>;
  onBlur?: FocusEventHandler<T>;
  disabled?: boolean;
  readOnly?: boolean;
  required?: boolean;
  'aria-describedby'?: string;
};

export type FormControlAriaAttributes = Pick<
  AriaAttributes,
  'aria-invalid' | 'aria-required' | 'aria-readonly'
>;

export type UseFormControlReturn<T extends HTMLElement> =
  UseFormControlProps<T> & FormControlAriaAttributes;

export const useFormControlProps = <
  T extends HTMLElement,
  P extends UseFormControlProps<T>
>(
  props: P
): P => {
  const field = useFormControlContext();

  const {
    id,
    disabled,
    readOnly,
    required,
    isRequired,
    isInvalid,
    isReadOnly,
    isDisabled,
    onFocus,
    onBlur,
    ...rest
  } = props;

  const labelIds: string[] = props['aria-describedby']
    ? [props['aria-describedby']]
    : [];

  // Error message must be described first in all scenarios.
  if (field?.hasFeedbackText && field?.isInvalid) {
    labelIds.push(field.feedbackId);
  }

  if (field?.hasHelpText) {
    labelIds.push(field.helpTextId);
  }

  return {
    ...rest,
    'aria-describedby': labelIds.join(' ') || undefined,
    id: id ?? field?.id,
    isDisabled: disabled ?? isDisabled ?? field?.isDisabled,
    isReadOnly: readOnly ?? isReadOnly ?? field?.isReadOnly,
    isRequired: required ?? isRequired ?? field?.isRequired,
    isInvalid: isInvalid ?? field?.isInvalid,
    onFocus: callAllHandlers(field?.onFocus, onFocus),
    onBlur: callAllHandlers(field?.onBlur, onBlur)
  } as P;
};

export const useFormControl = <
  T extends HTMLElement,
  P extends UseFormControlReturn<T>
>(
  props: P
): P & FormControlAriaAttributes => {
  const { isDisabled, isInvalid, isReadOnly, isRequired, ...rest } =
    useFormControlProps(props);

  return {
    ...rest,
    disabled: isDisabled,
    readOnly: isReadOnly,
    required: isRequired,
    'aria-invalid': ariaAttr(isInvalid),
    'aria-required': ariaAttr(isRequired),
    'aria-readonly': ariaAttr(isReadOnly)
  } as P;
};

export type FormInputProps<
  T extends HTMLElement,
  P extends UseFormControlReturn<T>
> = PropsWithoutRef<P> & {
  Input: ComponentType<P>;
};

export const FormInput = forwardRef(
  <
    T extends HTMLElement = HTMLElement,
    P extends UseFormControlReturn<T> = any
  >(
    { Input, ...props }: FormInputProps<T, P>,
    ref: ForwardedRef<T>
  ) => {
    const inputProps = useFormControl<T, P>(props as unknown as P);

    return (
      <Input
        {...inputProps}
        {...(inputProps['aria-invalid'] ? { meta: { error: 'invalid' } } : {})}
        ref={ref}
      />
    );
  }
);

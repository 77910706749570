import { Box } from '@rexlabs/box';
import { styled, StyleSheet } from '@rexlabs/styling';
import React from 'react';
import { COLORS, TEXTS } from 'src/theme';

import WarningIcon from 'src/assets/icons/error.svg';

const defaultStyles = StyleSheet({
  container: {
    ...TEXTS.TINY,
    padding: '1.4rem 2.4rem',
    borderRadius: '.4rem'
  },

  warningIcon: {
    flex: '0 0 auto',
    width: '4rem',
    color: ({ token }) => token('legacy.color.orange.default'),
    alignSelf: 'center',
    marginRight: '1.1rem'
  },

  orange: {
    background: ({ token }) => token('legacy.color.orange.light'),
    color: ({ token }) => token('legacy.color.orange.default')
  },

  grey: {
    background: COLORS.GREY_LIGHT,
    color: COLORS.BLUE_GREY
  },

  transparent: {
    background: 'none',
    color: ({ token }) => token('legacy.color.blue.grey')
  }
});

const Alert = ({ children, hideIcon, color = 'orange', styles: s }) => {
  return (
    <Box flex={1} alignItems='center' {...s('container', { [color]: true })}>
      {!hideIcon && <WarningIcon {...s('warningIcon', { [color]: true })} />}
      <Box>{children}</Box>
    </Box>
  );
};

export default styled(defaultStyles)(Alert);

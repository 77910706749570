import React, { PureComponent } from 'react';
import { StyleSheet } from '@rexlabs/styling';
import DefaultButton from './default';

const defaultStyles = StyleSheet({
  button: {
    fontSize: '16px',
    lineHeight: '16px',
    fontWeight: '400',
    padding: '11px 16px 13px',
    borderRadius: '3px',
    borderWidth: 0
  }
});

export default class LargeButton extends PureComponent {
  static propTypes = DefaultButton.propTypes;
  render() {
    return <DefaultButton {...this.props} styles={defaultStyles} />;
  }
}

import { GhostButton, PrimaryButton } from '@rexlabs/button';
import { StyleSheet, useToken } from '@rexlabs/styling';
import React, { useState } from 'react';
import { ModalStickyButtonGroup } from 'src/view/components/button';
import { Map, rexOfficeLngLat } from 'src/view/components/map';
import { Modal } from 'src/view/components/modal';
import { Body } from 'src/view/components/text';

const mapStyles = StyleSheet({
  map: {
    height: '35rem',
    width: '100%',
    background: ({ token }) => token('legacy.color.blue.greyLight'),
    overflow: 'hidden',
    position: 'relative',
    zIndex: 1
  }
});

function EditLocationPin({
  title = 'Custom audience location pin',
  body,
  lngLat,
  closeModal,
  handleSubmit,
  isLoading
}) {
  const [newLngLat, setNewLngLat] = useState(lngLat);
  const token = useToken();

  const handleMapDragEnd = ({ target }) => {
    setNewLngLat([target._lngLat.lng, target._lngLat.lat]);
  };

  return (
    <Modal title={title} width='60rem' onClose={closeModal} noPadding>
      <Body grey style={{ margin: token('spacing.xl') }}>
        {body}
      </Body>

      <Map
        center={lngLat || rexOfficeLngLat}
        markers={[lngLat || rexOfficeLngLat]}
        onDragEnd={handleMapDragEnd}
        hideRadius={true}
        dragPan={true}
        zoom={14}
        styles={mapStyles}
      />

      <ModalStickyButtonGroup padded>
        <GhostButton onClick={closeModal}>Cancel</GhostButton>
        <PrimaryButton
          form='editAgencyForm'
          isLoading={isLoading}
          onClick={() => handleSubmit(newLngLat)}
        >
          Update
        </PrimaryButton>
      </ModalStickyButtonGroup>
    </Modal>
  );
}

export default EditLocationPin;

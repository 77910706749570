import { ToastProvider } from '@rexlabs/notifications';
import { PortalProvider } from '@rexlabs/portal';
import { StylesProvider } from '@rexlabs/styling';
import { TextProvider } from '@rexlabs/text';
import { FlagsProvider } from 'flagged';
import React, { cloneElement, ComponentType, FC, ReactNode } from 'react';
import { ErrorBoundary as ReactErrorBoundary } from 'react-error-boundary';
import { QueryClient, QueryClientProvider } from 'react-query';
import { ReactQueryDevtools } from 'react-query/devtools';
import { Provider } from 'react-redux';
import config from 'src/config';
import { Bugsnag } from 'src/lib/bugsnag';
import { store } from 'src/store';
import { brandName, TEXTS } from 'src/theme';
import COMPONENTS from 'src/theme/components';
import { FLINK_TOKENS } from 'src/theme/flink-tokens';
import { TOKENS } from 'src/theme/tokens';
import { ModalProvider } from 'src/utils/modal';
import ErrorLayout from 'src/view/layouts/error';

const STATIC_FEATURE_FLAGS = config.FEATURE_FLAGS ?? [];
const THEME_TOKENS = brandName === 'flink' ? FLINK_TOKENS : TOKENS;

export type ErrorFallbackProps = {
  error: Error;
  clearError: () => void;
};

export type ErrorBoundaryProps = {
  children: ReactNode;
  FallbackComponent: ComponentType<ErrorFallbackProps>;
};

export const ErrorBoundary: FC<ErrorBoundaryProps> = ({
  children,
  FallbackComponent
}) => {
  const BugsnagErrorBoundary =
    Bugsnag.getPlugin('react')?.createErrorBoundary(React);

  const RealErrorBoundary = BugsnagErrorBoundary ? (
    <BugsnagErrorBoundary
      FallbackComponent={({ error, clearError }) => (
        <FallbackComponent error={error} clearError={clearError} />
      )}
    />
  ) : (
    <ReactErrorBoundary
      FallbackComponent={({ error, resetErrorBoundary }) => (
        <FallbackComponent error={error} clearError={resetErrorBoundary} />
      )}
    />
  );

  return cloneElement(RealErrorBoundary, undefined, children);
};

export type CommonProvidersProps = {
  queryClient: QueryClient;
  children: ReactNode;
};

export const CommonProviders: FC<CommonProvidersProps> = ({
  queryClient,
  children
}) => (
  <Provider store={store}>
    <FlagsProvider features={STATIC_FEATURE_FLAGS}>
      <StylesProvider
        components={COMPONENTS}
        tokens={THEME_TOKENS}
        debug={config.MODE === 'development'}
      >
        <TextProvider text={TEXTS}>
          <QueryClientProvider client={queryClient}>
            <ErrorBoundary FallbackComponent={ErrorLayout}>
              <PortalProvider>
                <ToastProvider>
                  <ModalProvider>{children}</ModalProvider>
                </ToastProvider>
              </PortalProvider>
            </ErrorBoundary>
            <ReactQueryDevtools
              initialIsOpen={false}
              position={'bottom-right'}
            />
          </QueryClientProvider>
        </TextProvider>
      </StylesProvider>
    </FlagsProvider>
  </Provider>
);

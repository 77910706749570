import { Box } from '@rexlabs/box';
import { useModelState } from '@rexlabs/model-generator';
import { withWhereabouts } from '@rexlabs/react-whereabouts';
import { keyframes, StyleSheet, useStyles, useToken } from '@rexlabs/styling';
import { Truncate } from '@rexlabs/text';
import { TextInput } from '@rexlabs/text-input';
import Tooltip, { PLACEMENTS } from '@rexlabs/tooltip';
import React, { ChangeEvent, MutableRefObject, useRef, useState } from 'react';
import { compose } from 'redux';
import ChevronDownIcon from 'src/assets/icons/chevron-down.svg';
import SearchIcon from 'src/assets/icons/search.svg';
import sessionModel from 'src/data/models/custom/session';
import { createKey } from 'src/utils/react';
import { setAccountIndex } from 'src/utils/router';

const TextInputOverrides = StyleSheet({
  wrapper: {
    flex: '1 1 auto !important'
  },
  container: {
    borderRadius: '0 !important',
    border: 'none !important',
    padding: '0 !important',
    marginTop: '5px !important'
  },
  cosmeticWrapper: {},
  input: {
    color: ({ token }) => `${token('legacy.color.blue.grey')} !important`,
    border: 'none !important',
    lineHeight: '22px !important',
    fontSize: '1.6rem !important',
    padding: '0 !important',
    margin: '0 !important',

    '::placeholder': {
      color: ({ token }) => `${token('color.textStyle.body.hint')} !important`,
      fontStyle: 'italic !important',
      opacity: '0.8 !important'
    }
  },
  prefix: {
    display: 'flex !important',
    paddingRight: ({ token }) => `${token('spacing.xs')} !important`
  }
});

const agencyListStyles = StyleSheet({
  container: {
    width: '340px !important',
    display: 'flex !important',
    flexDirection: 'column !important'
  },
  agencyName: {
    color: ({ token }) =>
      `${token('color.textStyle.primary.idle.default')} !important`,
    fontSize: '1.6rem !important',
    lineHeight: '22px !important',
    padding: '14px 15px 14px 20px !important',
    cursor: 'pointer !important',
    overflow: 'hidden !important',
    textOverflow: 'ellipsis !important',
    whiteSpace: 'nowrap !important',
    minHeight: '50px !important',

    ':hover': {
      backgroundColor: ({ token }) =>
        `${token('color.container.interactive.hover')} !important`,
      color: ({ token }) =>
        `${token('color.textStyle.primary.hover.default')} !important`,
      overflow: 'visible !important',
      whiteSpace: 'normal !important',
      minHeight: 'auto !important'
    }
  },
  filterContainer: {
    paddingBottom: '5px !important',
    margin: '0 20px !important'
  },
  filterWrapper: {
    color: '#7F7F7F !important',
    fontSize: '17px !important',
    lineHeight: '22px !important',
    fontWeight: '500 !important',
    display: 'flex !important'
  },
  separator: {
    width: 'inherit !important',
    height: '1px !important',
    opacity: 0.5,
    backgroundColor: '#E1E1E1 !important'
  },
  agenciesContainer: {
    overflowY: 'auto',
    maxHeight: '330px !important',
    display: 'flex !important',
    flexDirection: 'column !important',

    '::-webkit-scrollbar': {
      width: '6px !important'
    },

    '::-webkit-scrollbar-thumb': {
      background: '#B2B2B2 !important',
      borderRadius: '4px !important'
    }
  },
  agencyTitleContainer: {
    width: '100% !important',
    borderBottomWidth: '1px !important',
    borderBottomStyle: 'solid !important',
    display: 'flex !important',
    alignItems: 'center !important'
  },
  agencyTitle: {
    paddingLeft: '25px !important',
    fontSize: '18px !important',
    lineHeight: '26px !important',
    maxWidth: '74% !important',
    whiteSpace: 'nowrap !important',
    textOverflow: 'ellipsis !important',
    overflow: 'hidden !important'
  }
});

function AgencyList({ session, whereabouts, setInputFocus, onClose }) {
  const s = useStyles(agencyListStyles);
  const [term, setTerm] = useState('');
  const agenciesContainer: MutableRefObject<HTMLDivElement | null> =
    useRef(null);
  const agencies = session.accounts;

  const setSearchTerm = (event: ChangeEvent<HTMLInputElement>) => {
    if (agenciesContainer.current) agenciesContainer.current.scrollTop = 0;
    setTerm(event.target.value);
  };

  const handleAccountSwitch = (agencyId) => {
    if (session.currentAccountId !== agencyId) {
      const newIndex = agencies.findIndex((sa) => sa.id === agencyId);
      setAccountIndex(whereabouts, session, newIndex);
    }
  };
  const shouldShowFilter = agencies.length > 1;

  return (
    <Box {...s('container')}>
      {shouldShowFilter && (
        <Box {...s('filterContainer')}>
          <Box {...s('filterWrapper')}>
            <TextInput
              ref={setInputFocus}
              prefix={<SearchIcon />}
              name='agency-search'
              autoComplete={false}
              styles={TextInputOverrides}
              placeholder='Filter Agencies'
              onChange={setSearchTerm}
              autocomplete='off'
              value={term}
            />
          </Box>
          <Box {...s('separator')} />
        </Box>
      )}
      <div
        ref={agenciesContainer}
        {...s('agenciesContainer')}
        data-testid='agencies-list-container'
      >
        {agencies
          .filter((agency) => {
            return (
              `${agency?.name}`.toLowerCase().includes(term.toLowerCase()) ||
              agency.id.toString() === term.toString()
            );
          })
          .map((agency) => {
            const agencyName = `${agency?.name}`;
            const onClickHandler = () => {
              onClose();
              handleAccountSwitch(agency.id);
            };
            const key = createKey(`${agencyName}-${agency.id}`);
            return (
              <span key={key} onClick={onClickHandler} {...s('agencyName')}>
                {agencyName}
              </span>
            );
          })}
      </div>
    </Box>
  );
}

const toolTipStyles = StyleSheet({
  container: {
    whiteSpace: 'nowrap !important',
    maxWidth: '100% !important'
  },
  tooltip: {
    overflow: 'visible !important',
    borderRadius: '3px !important',
    padding: '5px !important'
  },
  arrow: {
    borderRadius: '2px !important',
    margin: '-4px !important'
  },
  overlay: {}
});

const agencySelectorStyles = StyleSheet({
  container: {
    display: 'flex',
    alignItems: 'center',
    height: '26px',
    fontSize: '1.8rem',
    cursor: 'pointer',
    color: ({ token }) =>
      token(
        'agencySelector.color',
        token('color.textStyle.primary.idle.default')
      )
  },
  name: {
    width: '100%'
  }
});

const enterAnimation = keyframes({
  '0%': {
    opacity: 0,
    position: 'relative',
    top: '-14px'
  },
  '100%': {
    opacity: 1,
    top: '0px',
    position: 'relative'
  }
});

const exitAnimation = keyframes({
  '0%': {
    opacity: 1,
    transform: 'scale3d(1,1,1)',
    transformOrigin: '85% -80%'
  },
  '100%': {
    opacity: 0,
    transform: 'scale3d(0.8,0.8,0.8)',
    transformOrigin: '85% -80%'
  }
});

const tetherStyles = StyleSheet({
  content: {
    transition: 'none !important'
  },
  opening: {
    animation: `${enterAnimation} forwards normal 0.2s cubic-bezier(0, 0, 0.16, 0.99) !important`
  },
  closing: {
    animation: `${exitAnimation} forwards normal 0.1s cubic-bezier(0, 0, 0.16, 0.99) !important`
  }
});

function setInputFocus(element: HTMLElement) {
  setTimeout(() => {
    element?.focus();
  }, 250);
}

function AgencySelector({ whereabouts }) {
  const s = useStyles(agencySelectorStyles);
  const token = useToken();
  const session = useModelState(sessionModel);

  const [isMenuOpen, setIsMenuOpen] = useState(false);

  const toggleMenuState = () => setIsMenuOpen((prev) => !prev);

  const currentAgency = session.accounts.find(
    (a) => a.id === session.currentAccountId
  );

  return (
    <Tooltip
      placement={PLACEMENTS.BOTTOM}
      offset='0px'
      openOn='CLICK'
      closeOn='CLICK'
      hoverTimeout={250}
      Content={() => (
        <AgencyList
          onClose={toggleMenuState}
          setInputFocus={setInputFocus}
          session={session}
          whereabouts={whereabouts}
        />
      )}
      setOpen={toggleMenuState}
      isOpen={isMenuOpen}
      distance='14px'
      styles={toolTipStyles}
      tetherStyles={tetherStyles}
    >
      <Box {...s('container')} data-testid='agency-select-dropdown'>
        <Truncate {...s('name')}>
          {currentAgency?.agencies?.data?.[0]?.business_name}
        </Truncate>
        <ChevronDownIcon style={{ marginLeft: token('spacing.xs') }} />
      </Box>
    </Tooltip>
  );
}

export default compose(withWhereabouts)(AgencySelector);

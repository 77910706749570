import { GhostButton } from '@rexlabs/button';
import { StyleSheet } from '@rexlabs/styling';
import { memoize } from 'lodash';
import React, { PureComponent } from 'react';
import { withToken } from 'src/theme';
import { timeFromSeconds } from 'src/utils/format';
import { ModalStickyButtonGroup } from 'src/view/components/button';
import { List, StatsListHeader, StatsListItem } from 'src/view/components/list';
import { Modal } from 'src/view/components/modal';

const videoLabelMap = {
  video_plays: 'Total video ad plays',
  video_play_25_percent: 'Video watches at 25%',
  video_play_50_percent: 'Video watches at 50%',
  video_play_75_percent: 'Video watches at 75%',
  video_play_100_percent: 'View watches at 100%',
  video_average_time: 'Video average watch time'
};

function statsReducer(acc, stat) {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [_platform, _total_video_ad_plays, ...stats] = Object.keys(stat);

  stats.forEach((s) => {
    acc[s] = {
      label: videoLabelMap[s],
      data: [
        ...(acc[s]?.data || []),
        {
          network_id: (stat?.platform?.label ?? '').toLowerCase(),
          value: stat[s]
        }
      ]
    };
  });
  return acc;
}

const modalStyles = StyleSheet({
  container: {
    height: '62rem'
  }
});

const listStyles = StyleSheet({
  container: {
    flexGrow: 1,

    '@media print': {
      flex: '1 1 50%'
    }
  },

  seperator: {
    width: ({ token }) => `calc(100% - (${token('spacing.xl')} * 2))`,
    margin: 'auto'
  }
});

@withToken
class VideoAdPlays extends PureComponent {
  memItems = memoize(this.items, () => 'conversions');

  items(videoPlayStats) {
    return Object.values(videoPlayStats.reduce(statsReducer, {}));
  }

  render() {
    const { stats, token } = this.props;

    /* eslint-disable camelcase */
    const videoPlayStats = stats?.video_stats?.data ?? [];
    const videoPlaysTotal = stats?.summary?.video_plays;
    /* eslint-enable camelcase */
    const items = this.memItems(videoPlayStats);

    const avgFunc = () => {
      let count = 0;
      let total = 0;
      return (acc, stat) => {
        total += stat.value;
        count++;
        return total / count;
      };
    };

    return (
      <List
        items={items}
        getItemKey={(campaign) => campaign.id}
        Header={() => (
          <StatsListHeader
            title='Total video ad plays'
            description='See how much of your video people are watching, and how many people stay till the end on Facebook and Instagram.'
            value={videoPlaysTotal}
          />
        )}
        Item={({ data: stat }) =>
          stat.label === 'Video average watch time' ? (
            <StatsListItem
              stat={stat}
              renderFunc={timeFromSeconds}
              aggregateFunc={avgFunc()}
              showPercentage={false}
            />
          ) : (
            <StatsListItem stat={stat} />
          )
        }
        animateRows={false}
        style={{ marginBottom: token('spacing.m'), overflow: 'auto' }}
        styles={listStyles}
      />
    );
  }
}

function VideoAdPlaysModal({ subTitle, closeModal, ...props }) {
  return (
    <Modal
      title='Video ad plays'
      subtitle={subTitle}
      onClose={closeModal}
      styles={modalStyles}
      noPadding
    >
      <VideoAdPlays {...props} />

      <ModalStickyButtonGroup padded>
        <GhostButton onClick={closeModal}>Close</GhostButton>
      </ModalStickyButtonGroup>
    </Modal>
  );
}

export { VideoAdPlaysModal, VideoAdPlays };

import { createRestAPIModelGenerator } from 'src/data/models/generator';
import { api } from 'src/utils/api-client';

export const TYPE = 'rex';

const config = {
  entities: {
    api: {
      trashItem: () => api.delete('/integrations/rex'),
      fetchItem: (type, args, id) => {
        return Promise.all([
          api.get('/integrations/rex', args),
          api.get('/integrations/rex/accounts', args),
          new Promise((resolve) =>
            api
              .get('/integrations/rex/locations', args)
              .then(resolve)
              .catch(() => resolve({ data: [] }))
          )
        ]).then(([{ data }, { data: accounts }, { data: locations }]) => ({
          data: {
            ...data,
            accounts,
            locations,
            id
          }
        }));
      },
      updateItem: (type, args, id) =>
        api
          .patch('/integrations/rex', args)
          .then(({ data }) => ({ data: { ...data, id } }))
    }
  }
};

const RexGenerator = createRestAPIModelGenerator(TYPE, config);
export default RexGenerator.createEntityModel();

import React, { PureComponent } from 'react';
import { facebookNetworkAdTypes } from '../utils/spoke-value-lists';

import FacebookSingleImage from './facebook-ads/multi-listing-sold/single-image';
import FacebookCarousel from './facebook-ads/multi-listing-sold/carousel';

class MultiListingSoldFacebookAd extends PureComponent {
  render() {
    const { adType, adContentSet, disableWrapper, ad } = this.props;
    const [type, variation, slideNumber] = adType.split('.');

    switch (type) {
      case facebookNetworkAdTypes.CAROUSEL:
        return (
          <FacebookCarousel
            ad={ad}
            variation={variation}
            slideNumber={slideNumber}
            adContentSet={adContentSet}
            disableWrapper={disableWrapper}
          />
        );
      case facebookNetworkAdTypes.SINGLE_IMAGE:
        return (
          <FacebookSingleImage
            ad={ad}
            adContentSet={adContentSet}
            disableWrapper={disableWrapper}
          />
        );
      default:
        return <span>Type {adType} not found</span>;
    }
  }
}

export default MultiListingSoldFacebookAd;

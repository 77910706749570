import React, {FC, ReactNode} from 'react';
import { AdType } from 'src/features/campaign-definitions';
import {
  AdContentSet,
  CommonData,
  TemplateData,
  TemplateImage
} from '../types';
import { transformAdPreviews } from '../utils/ad-previews';
import { AdPreviewCarousel } from './AdPreviewCarousel';

export type AdsPreviewProps = {
  adTypes: AdType[];
  adContentSet: AdContentSet;
  templateData?: TemplateData;
  children?: ReactNode;
};

export const AdsPreview: FC<AdsPreviewProps> = ({
  adTypes,
  adContentSet,
  templateData,
  children
}) => {
  const attributes = (
    templateData
      ? { ...adContentSet.attributes, ...templateData }
      : adContentSet.attributes
  ) as Record<string, unknown>;

  const logo = attributes.agency_logo as TemplateImage;

  const commonData: CommonData = {
    description: attributes.description as string,
    shortHeadline: attributes.short_headline as string,
    longHeadline: attributes.long_headline as string,
    websiteUrl: adContentSet.final_url,
    logoBgColor: attributes.accent_color as string,
    agencyLogo: logo?.sizes.original.url ?? '',
    agencyName: attributes.agency_name as string
  };

  const enrichedAdTypes = transformAdPreviews(adTypes, attributes);

  return (
    <AdPreviewCarousel
      adTypes={enrichedAdTypes}
      templateData={attributes}
      commonData={commonData}
      previewTitleStyle={{ large: true }}
    >
      {children}
    </AdPreviewCarousel>
  );
};

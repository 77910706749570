import React, { PureComponent } from 'react';
import Box from '@rexlabs/box';
import { styled, StyleSheet } from '@rexlabs/styling';
import Banner from './banner';
import { COLORS } from 'src/theme';

const NATIVE_AD_WIDTH = 1000;
const NATIVE_AD_HEIGHT = NATIVE_AD_WIDTH;

const defaultStyles = StyleSheet({
  wrapPicture: {
    width: `${NATIVE_AD_WIDTH}px`,
    height: `${NATIVE_AD_HEIGHT}px`,
    background: 'none no-repeat center center',
    backgroundColor: COLORS.GREY_LIGHT,
    backgroundSize: 'cover',
    overflow: 'hidden',
    position: 'relative'
  },
  wrapCarouselPictureContent: {
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0
  }
});

@styled(defaultStyles)
class CarouselImage extends PureComponent {
  render() {
    const { styles: s, adContentSet, imageUrl, scaleTo } = this.props;

    return (
      <Box
        {...s.with(
          'wrapPicture',
          'wrapCarouselPicture'
        )({
          transformOrigin: 'top left',
          transform: `scale(${scaleTo / NATIVE_AD_HEIGHT})`,
          backgroundImage: imageUrl ? `url(${imageUrl})` : 'none'
        })}
      >
        {adContentSet.banner && <Banner {...this.props} />}
      </Box>
    );
  }
}

export default CarouselImage;

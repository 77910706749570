module.exports = {
  MODE: "production",
  STAGE: "production",
  API_URL: "https://app.spokeapp.io/api/v1",
  ASSETS_URL: "https://app.spokeapp.io/static",
  FACEBOOK_APP_ID: "1452511548179700",
  STRIPE_API_KEY_AU: "pk_live_MbbtOJWy2zyLG0rIroQ0mdhP",
  STRIPE_API_KEY_NZ: "pk_live_MbbtOJWy2zyLG0rIroQ0mdhP",
  STRIPE_API_KEY_GB: "pk_live_lTo1SR4HGPOYDLNDyELDk7AO008qBplB3E",
  EMBED_API:
    "eyJ0eXAiOiJKV1QiLCJhbGciOiJSUzI1NiIsImp0aSI6IjBkNGI0MzkwOTAxMTEyMTFhZGNiZjA4NzQ1MzY1OWFlMjk1MjdmZjRiYTRmOTljYzVmZWRiZGRmNTYzMzViY2JmYTEwMDE3YzFlNDcwZDcxIn0.eyJhdWQiOiI1IiwianRpIjoiMGQ0YjQzOTA5MDExMTIxMWFkY2JmMDg3NDUzNjU5YWUyOTUyN2ZmNGJhNGY5OWNjNWZlZGJkZGY1NjMzNWJjYmZhMTAwMTdjMWU0NzBkNzEiLCJpYXQiOjE1NzU3OTg2NTYsIm5iZiI6MTU3NTc5ODY1NiwiZXhwIjo0MTAyNDQ0ODAwLCJzdWIiOiJhMDVlODRkMi0xOTlmLTExZWEtYjVjNi0yMjU2ZDM3YjFmYWUiLCJzY29wZXMiOltdfQ.N1k-BPQ19ESTWpDt6uOZ7JEe-sEBgfxmJvqEBxb3QxDpsOX1lj_xe0ynapwn1ZyOAQXJOZfr82s9U1zuHo37sr2wOKggD_JDeTwTB8JrGQvEeBqzPvCiTTsAAi5TmPVW4OyuoJ_Mico1OOvuzKkCbFL1UEwddecwzsnHFv1IW3RS3MWHOkH6pzyjFo_xMnTTuCo3KPxxnkjb_ZxX1T7kUzRIW_Zvycp8FVm6ysLnFrSFkTrAPBy1LdI2_0YkBtgFJuSO0YvSGH-bKKntvhlGrhC-dv2JLfmA0Vi2ifjcdY348ACVoZDkNdFxd-diAJ8icaKK3f6FKNtmrF4hmqL9hrlvNRNrwiyx7vey-ta12eFG89r3BxoscgdAI1Gg9Q2R7czWNRuvlY5EoOPRZ-6GQp33V3UxtGL5ZddaRK1KqM_LF8TMQC9TjokWsjmxKk62a_r2vutsN-8EEb1J0HCR_mJVwlgWRedpQmYoa7kgbby1pZmNy6W4QoA3unryQp6Dn41hLdYMdMy56FsPupyK-coPKnY91yBU3VcxDZRms4xv2UEIhUIyHHpLyEFRoxKct0MupOXTRJcvdVCkCfUVD7tOlahalI9PnnGxhGR2ax_5bS58GYfXj_AyxOLbEvxNotZYsT1MR7B_sInzW4cRS3y6dDyR5ofVzabjGrzFCH4",
  SEGMENT_ID: "XTanNXmHk4xzs5Jr49LKB2OW1u1GOJPQ",
  SPRIG_ENVIRONMENT_ID: "02sSarBDnp",
  ZENDESK_MESSENGER_API_KEY: '4d070ed4-8b84-4009-807e-0365235bc15e',
  OAUTH: {
    CLIENT_ID: "2",
    CLIENT_SECRET: "VHdEMglUWsNmHph7FlYZ0TneOy3fQISFvwvgP2uq",
  },
  PUSHER_KEY: "473a0abd256ad6636962",
  PUSHER_CLUSTER: "ap4",
  MAPBOX_TOKEN:
    "pk.eyJ1IjoicmV4c29mdHdhcmUiLCJhIjoiY2pzZTdvdGUzMTNxajQ2b2FjMzFqd3hscCJ9.8w_bJa4-lDg90X9yuXSbDQ",
  FULL_STORY_ID: "AX2QJ",
  BUGSNAG_ID: "5c75343e2e6fea948226e0b70e7cd550",
  SHOW_RETARGETING: false,
  GTM_ID: "GTM-WDXCSGB",
  LANDING_PAGE_PREVIEW: "https://app.spokeapp.io/landing-pages/preview",
  INTERCOM_ID: "nup818uj",
  MIN_AUDIENCE_RADIUS: 2,
  HUBSPOT_BOOKING_URL: "https://meetings.hubspot.com/blake-coote",
  HUBSPOT_ACCOUNT_MANAGER_NAME: "Blake Coote",
  HUBSPOT_ACCOUNT_MANAGER_EMAIL: "blake.coote@rexsoftware.com.au",
  RELEASE: {
    STAGE: "__RELEASE_STAGE__",
    VERSION: "__RELEASE_VERSION__",
    HASH: "__RELEASE_HASH__",
  },
};

import { useMutation, useQueryClient } from 'react-query';
import { useAccountId } from 'src/features/accounts';
import { MutationResult } from 'src/lib/react-query';
import { api } from 'src/utils/api-client';
import { AdContentSet, AdContentSetDTO } from '../types';
import { adContentSetSchema } from '../utils/schemas';
import { campaignAdsKeys } from './keys';

export type UpdateCampaignAdContentSetParams = {
  accountId: string;
  campaignId: string;
  adContentSetId: string;
  adContent: AdContentSetDTO;
};

export const updateCampaignAdContentSet = async ({
  accountId,
  adContentSetId,
  campaignId,
  adContent
}: UpdateCampaignAdContentSetParams): Promise<AdContentSet> => {
  const { data } = await api.patch(
    `/campaigns/${campaignId}/ad-content-sets/${adContentSetId}`,
    adContent,
    {
      headers: {
        'X-Account-Id': accountId
      }
    }
  );
  return adContentSetSchema.create(data);
};

export type UseUpdateCampaignAdContentSetOptions = Pick<
  UpdateCampaignAdContentSetParams,
  'campaignId'
>;

type Context = { previousValue?: AdContentSet };

export const useUpdateCampaignAdContentSet = ({
  campaignId
}: UseUpdateCampaignAdContentSetOptions): MutationResult<
  AdContentSet,
  Pick<UpdateCampaignAdContentSetParams, 'adContentSetId' | 'adContent'>,
  Context
> => {
  const accountId = useAccountId();
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: (vars) =>
      updateCampaignAdContentSet({ accountId, campaignId, ...vars }),
    onMutate: async ({ adContent, adContentSetId }) => {
      await queryClient.cancelQueries(
        campaignAdsKeys.detail(accountId, campaignId, adContentSetId)
      );

      const previousValue = queryClient.getQueryData<AdContentSet>(
        campaignAdsKeys.detail(accountId, campaignId, adContentSetId)
      );

      const dummy: AdContentSet = {
        id: adContentSetId,
        created_at: new Date(),
        updated_at: new Date(),
        type: {
          id: 'ads',
          label: 'Ads'
        },
        attributes: {},
        final_url: ''
      };
      queryClient.setQueryData<AdContentSet>(
        campaignAdsKeys.detail(accountId, campaignId, adContentSetId),
        (old = dummy) => ({ ...old, ...adContent })
      );

      return { previousValue };
    },
    onError: (e, { adContentSetId }, ctx) => {
      queryClient.setQueryData(
        campaignAdsKeys.detail(accountId, campaignId, adContentSetId),
        ctx?.previousValue
      );
    },
    onSuccess: (data, { adContentSetId }) => {
      queryClient.setQueryData(
        campaignAdsKeys.detail(accountId, campaignId, adContentSetId),
        data
      );
    },
    onSettled: () => {
      queryClient.invalidateQueries(
        campaignAdsKeys.lists(accountId, campaignId)
      );
    }
  });
};

import { Box } from '@rexlabs/box';
import { PrimaryButton } from '@rexlabs/button';
import { styled, StyleSheet, useToken } from '@rexlabs/styling';
import { Heading } from '@rexlabs/text';
import React, { useEffect, useState } from 'react';
import { BORDER_RADIUS, BREAKPOINTS } from 'src/theme';
import { Small } from 'src/view/components/text';

const defaultStyles = StyleSheet({
  container: {
    width: '100%',
    background: ({ token }) => token('legacy.color.white')
  },

  inner: {
    width: '100%',
    maxWidth: '108.3rem',
    paddingLeft: ({ token }) => token('spacing.m'),
    paddingRight: ({ token }) => token('spacing.m')
  },

  innerListing: {
    [BREAKPOINTS.REPORT.TABLET]: {
      padding: 0
    }
  },

  wrapTitle: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    flex: 1,

    [BREAKPOINTS.REPORT.TABLET]: {
      flexDirection: 'column'
    }
  },

  title: {
    flex: '1 1 auto',
    height: '12rem',

    [BREAKPOINTS.REPORT.TABLET]: {
      textAlign: 'center'
    }
  },

  image: {
    width: '14.1rem',
    paddingBottom: '8.6rem',
    background: ({ token }) =>
      `${token('legacy.color.background')} no-repeat center center`,
    backgroundSize: 'cover',
    marginRight: ({ token }) => token('spacing.m'),
    borderRadius: BORDER_RADIUS.INPUT,

    [BREAKPOINTS.REPORT.TABLET]: {
      width: '100%',
      paddingBottom: '60%',
      height: 'auto',
      margin: 0,
      borderRadius: 0
    }
  },

  logo: {
    width: '100%',
    height: '6.5rem',
    background: 'transparent no-repeat center center',
    backgroundSize: 'contain',
    border: '1.4rem solid transparent'
  },

  largerLogo: {
    height: '8rem',

    '@media print': {
      height: '6.5rem'
    }
  },

  imageContain: {
    backgroundSize: 'contain'
  },

  printButton: {
    display: 'inline-block',
    marginBottom: ({ token }) => token('spacing.xl'),

    [BREAKPOINTS.TABLET_LANDSCAPE_UP]: {
      marginBottom: 0
    },

    '@media print': {
      display: 'none'
    }
  }
});

function ReportHeader({
  customBrandedReport,
  campaignName,
  campaignLabel,
  thumbUrl,
  agencyLogo,
  backgroundColor,
  imageContain,
  showPrintButton = true,
  styles: s
}) {
  const token = useToken();
  const [printDisabled, setPrintDisabled] = useState(true);

  useEffect(() => {
    const timer = setTimeout(() => setPrintDisabled(false), 6000);
    return () => clearTimeout(timer);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Box flexDirection='column' alignItems='center' {...s('container')}>
      <Box
        {...s.with('logo', { largerLogo: customBrandedReport })({
          backgroundImage: agencyLogo ? `url(${agencyLogo})` : undefined,
          backgroundColor: backgroundColor || token('legacy.color.background')
        })}
      />
      <Box
        flexDirection='row'
        alignItems='center'
        justifyContent='space-between'
        {...s('inner', 'innerListing')}
      >
        <Box {...s('wrapTitle')}>
          <Box
            {...s.with('image', { imageContain })({
              backgroundImage: thumbUrl ? `url(${thumbUrl})` : undefined,
              backgroundColor: backgroundColor
            })}
          />
          <Box flexDirection='column' justifyContent='center' {...s('title')}>
            <Small grey Tag='span'>
              {campaignLabel} Campaign Report
            </Small>
            <Heading
              level={2}
              Tag='span'
              style={{ margin: 0, marginTop: token('spacing.xxs') }}
            >
              {campaignName}
            </Heading>
          </Box>
          {showPrintButton ? (
            <span {...s('printButton')}>
            <PrimaryButton
              isLoading={printDisabled}
              onClick={() => window.print()}
            >
              Print report
            </PrimaryButton>
          </span>
          ) : null}
        </Box>
      </Box>
    </Box>
  );
}

export default styled(defaultStyles)(ReportHeader);

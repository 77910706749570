/* eslint-disable max-lines */
import { Box } from '@rexlabs/box';
import { GhostButton, PrimaryButton } from '@rexlabs/button';
import { withModel } from '@rexlabs/model-generator';
import { StyleSheet } from '@rexlabs/styling';
import { autobind } from 'core-decorators';
import React, { PureComponent, useEffect } from 'react';
import session from 'src/data/models/custom/session';
import { Bugsnag } from 'src/lib/bugsnag';
import { withToken } from 'src/theme';
import { api } from 'src/utils/api-client';
import { createValidationRules } from 'src/utils/form';
import { addressToMapbox } from 'src/utils/mapbox';
import { ModalStickyButtonGroup } from 'src/view/components/button';
import {
  FieldCell,
  FieldRow,
  Form,
  FormField,
  HiddenField,
  LabelOptionalField,
  ReactForms
} from 'src/view/components/form';
import { TextInput } from 'src/view/components/input';
import {
  AustralianStatesSelect,
  CountrySelect,
  NewZealandRegionsSelect
} from 'src/view/components/input/select/value-list';
import { Map } from 'src/view/components/map';
import { Modal } from 'src/view/components/modal';
import { Body } from 'src/view/components/text';

const mapStyles = StyleSheet({
  map: {
    height: '35rem',
    width: '100%',
    background: ({ token }) => token('legacy.color.blue.greyLight'),
    overflow: 'hidden',
    position: 'relative',
    zIndex: 1
  }
});

const adminArea1Styles = StyleSheet({
  container: {
    maxHeight: '12rem'
  }
});

const locale = {
  AU: {
    unit_number: { label: 'Unit no.', placeholder: 'e.g. 402' },
    admin_area_3: {
      label: 'Suburb',
      placeholder: 'e.g. Brisbane City'
    },
    admin_area_1: { label: 'State', placeholder: 'e.g. QLD' },
    postal_code: { label: 'Postcode', placeholder: 'e.g. 4000' }
  },
  IE: {
    unit_number: { label: 'Flat no.', placeholder: 'e.g. Apt. 402' },
    admin_area_3: { label: 'City/Town/Village', placeholder: 'e.g. Dublin 2' },
    postal_code: { label: 'Eircode', placeholder: 'e.g. D02 H529' }
  },
  NZ: {
    unit_number: { label: 'Unit no.', placeholder: 'e.g. 402' },
    admin_area_3: { label: 'Suburb', placeholder: 'e.g. Albany' },
    admin_area_2: { label: 'Town/city', placeholder: 'e.g. Auckland City' },
    admin_area_1: { label: 'Region', placeholder: 'e.g. Auckland' },
    postal_code: { label: 'Postcode', placeholder: 'e.g. 0752' }
  },
  GB: {
    name: { label: 'House name', placeholder: 'e.g. Spoke House' },
    unit_number: { label: 'Flat no.', placeholder: 'e.g. 402' },
    admin_area_2: { label: 'Locality', placeholder: 'e.g. Westminister' },
    admin_area_3: { label: 'Town/City', placeholder: 'e.g. London' },
    postal_code: { label: 'Postcode', placeholder: 'SW1A 2AB' }
  },
  US: {
    unit_number: { label: 'Apt, suite.', placeholder: 'e.g. Apt 402' },
    admin_area_3: { label: 'Town/City', placeholder: 'e.g. San Francisco' },
    admin_area_1: { label: 'State', placeholder: 'e.g. CA' },
    postal_code: { label: 'ZIP code', placeholder: 'e.g. 94103' }
  }
};

const validations = {
  AU: {
    admin_area_1: ['required', 'state'],
    admin_area_3: ['required', 'suburb']
  },
  GB: {
    admin_area_3: ['required', 'town/city']
  },
  NZ: {
    admin_area_1: ['required', 'region'],
    admin_area_3: ['required', 'suburb']
  }
};

export function validate(values) {
  return createValidationRules({
    ...validations[values.country?.id || values.country],
    country: 'required',
    postal_code: 'required',
    street_name: 'required'
  })(values);
}

// Vivid Form lib no longer supports validateOnMount or TouchOnMount props
// This component works around that by checking when a hidden fiend has rendered
// and then set errors from passed in errors and setting touched.
const ValidateFormOnMount = ({
  values,
  initialErrors,
  setErrors,
  setTouched
}) => {
  useEffect(() => {
    if (Object.keys(values).includes('has_rendered')) {
      setErrors(initialErrors);
      setTouched(true);
    }
  }, [initialErrors, setErrors, setTouched, values, values.has_rendered]);

  return <HiddenField name='has_rendered' />;
};

@withToken
@withModel(session)
@autobind
class ManualAddressModal extends PureComponent {
  static defaultProps = {
    initialErrors: {}
  };

  state = {
    step: 1,
    values: undefined,
    lngLat: undefined,
    exactMatch: undefined
  };

  getInitialValues() {
    const { initialValues } = this.props;
    return {
      ...initialValues,
      admin_area_1: initialValues?.admin_area_1?.id,
      country:
        initialValues?.country?.id ||
        this.getAccount()?.agencies?.data?.[0]?.address?.country?.id
    };
  }

  getAccount() {
    const { accounts, currentAccountId } = this.props?.session ?? {};
    return accounts.find((account) => account.id === currentAccountId);
  }

  formatValues(values) {
    const newValues = {
      address: {
        ...values,
        country: { id: values.country },
        admin_area_1: { id: values.admin_area_1 }
      }
    };

    delete newValues.address.created_at;
    delete newValues.address.updated_at;
    delete newValues.address.has_rendered;

    return newValues;
  }

  async handleSubmit(values) {
    const formattedValues = this.formatValues(values);

    try {
      const { data } = await api.post(
        '/geographic-coordinates',
        formattedValues
      );
      this.setState({
        values: formattedValues,
        step: 2,
        lngLat: [data.longitude, data.latitude],
        exactMatch: data.exact_match
      });
    } catch (e) {
      const accountAddress =
        this.getAccount()?.agencies?.data?.[0]?.address ?? {};
      this.setState({
        values: formattedValues,
        exactMatch: false,
        step: 2,
        lngLat: [accountAddress.longitude, accountAddress.latitude]
      });
    }
  }

  handleSave() {
    const { onSave, initialErrors } = this.props;
    const { values, lngLat } = this.state;

    const address = {
      ...values.address,
      longitude: lngLat[0],
      latitude: lngLat[1]
    };

    // If intialErrors were passed in then we want to clear full_address so the label will
    // be regenerated with any address changes
    const hasInitialErrors = Object.values(initialErrors).length > 0;
    const mapboxAddress = addressToMapbox(
      hasInitialErrors ? { ...address, full_address: null } : address
    );

    if (!address.postal_code) {
      Bugsnag.notify(new Error('Manual address missing postal code'), {
        beforeSend: (report) => {
          report.metaData = {
            metadata: { values }
          };
        }
      });
    }

    onSave({ ...mapboxAddress, value: address });
  }

  render() {
    const { onClose, disableCountry, initialErrors, subtitle, token } =
      this.props;
    const { step, lngLat, exactMatch } = this.state;

    return (
      <Modal
        title='Manual Address Entry'
        subtitle={subtitle}
        width='65rem'
        onClose={onClose}
        noPadding={step === 2}
      >
        {step === 1 && (
          <ReactForms
            initialValues={this.getInitialValues()}
            handleSubmit={this.handleSubmit}
            validate={validate}
          >
            {({ values, submitForm, isSubmitting, setErrors, setTouched }) => {
              const { country } = values;
              return (
                <Form name='manualAddressForm'>
                  <ValidateFormOnMount
                    values={values}
                    initialErrors={initialErrors}
                    setErrors={setErrors}
                    setTouched={setTouched}
                  />

                  <Box mb={'6rem'}>
                    <FormField
                      name='country'
                      label='Country/region'
                      Input={CountrySelect}
                      inputProps={{ disabled: disableCountry }}
                    />

                    <FieldRow>
                      <FieldCell width={1 / 2}>
                        <FormField
                          name='street_number'
                          label={<LabelOptionalField text='Street no.' />}
                          Input={TextInput}
                          inputProps={{
                            placeholder: 'e.g. 123',
                            'data-testid': 'street-number'
                          }}
                        />
                      </FieldCell>
                      <FieldCell width={1 / 2}>
                        <FormField
                          name='unit_number'
                          label={
                            <LabelOptionalField
                              text={locale?.[country]?.unit_number?.label}
                            />
                          }
                          Input={TextInput}
                          inputProps={{
                            placeholder:
                              locale?.[country]?.unit_number?.placeholder,
                            'data-testid': 'unit-number'
                          }}
                        />
                      </FieldCell>
                    </FieldRow>

                    {country === 'GB' && (
                      <FormField
                        name='name'
                        label={
                          <LabelOptionalField
                            text={locale?.[country]?.name?.label}
                          />
                        }
                        Input={TextInput}
                        inputProps={{
                          placeholder: locale?.[country]?.name?.placeholder
                        }}
                      />
                    )}

                    <FormField
                      name='street_name'
                      label='Street name'
                      Input={TextInput}
                      inputProps={{
                        placeholder: 'e.g. Spoke Ave',
                        'data-testid': 'street-name'
                      }}
                    />

                    {(country === 'AU' || country === 'NZ') && (
                      <FormField
                        name='admin_area_3'
                        label={locale?.[country]?.admin_area_3?.label}
                        Input={TextInput}
                        inputProps={{
                          placeholder:
                            locale?.[country]?.admin_area_3?.placeholder,
                          'data-testid': 'admin-area-3'
                        }}
                      />
                    )}

                    {country === 'GB' && (
                      <FormField
                        name='admin_area_2'
                        label={
                          <LabelOptionalField
                            text={locale?.[country]?.admin_area_2?.label}
                          />
                        }
                        Input={TextInput}
                        inputProps={{
                          placeholder:
                            locale?.[country]?.admin_area_2?.placeholder
                        }}
                      />
                    )}

                    {country === 'NZ' && country && (
                      <FormField
                        name='admin_area_2'
                        label={locale?.[country]?.admin_area_2?.label}
                        Input={TextInput}
                        inputProps={{
                          placeholder:
                            locale?.[country]?.admin_area_2?.placeholder,
                          'data-testid': 'admin-area-2'
                        }}
                      />
                    )}

                    <FieldRow>
                      <FieldCell width={1 / 2}>
                        {(country === 'AU' || country === 'NZ') && (
                          <FormField
                            name='admin_area_1'
                            label={locale?.[country]?.admin_area_1?.label}
                            Input={
                              country === 'AU'
                                ? AustralianStatesSelect
                                : NewZealandRegionsSelect
                            }
                            inputProps={{
                              menuStyles: adminArea1Styles,
                              'data-testid': 'admin-area-1'
                            }}
                          />
                        )}

                        {country === 'GB' && (
                          <FormField
                            name='admin_area_3'
                            label={locale?.[country]?.admin_area_3?.label}
                            Input={TextInput}
                            inputProps={{
                              placeholder:
                                locale?.[country]?.admin_area_3?.placeholder
                            }}
                          />
                        )}
                      </FieldCell>
                      <FieldCell width={1 / 2}>
                        <FormField
                          name='postal_code'
                          label={locale?.[country]?.postal_code?.label}
                          Input={TextInput}
                          inputProps={{
                            placeholder:
                              locale?.[country]?.postal_code?.placeholder,
                            'data-testid': 'postal-code'
                          }}
                        />
                      </FieldCell>
                    </FieldRow>
                  </Box>

                  <ModalStickyButtonGroup>
                    <GhostButton onClick={onClose}>Cancel</GhostButton>
                    <PrimaryButton
                      data-testid={'next-button'}
                      form='manualAddressForm'
                      onClick={submitForm}
                      isLoading={isSubmitting}
                    >
                      Next
                    </PrimaryButton>
                  </ModalStickyButtonGroup>
                </Form>
              );
            }}
          </ReactForms>
        )}

        {step === 2 && (
          <>
            <Body grey style={{ padding: token('spacing.xl') }}>
              {exactMatch
                ? 'Please confirm the pin is in the correct location.'
                : 'Please move the pin to set the exact location of the listing.'}
            </Body>
            <Map
              center={lngLat}
              markers={[lngLat]}
              onDragEnd={({ target }) =>
                this.setState({
                  lngLat: [target._lngLat.lng, target._lngLat.lat]
                })
              }
              dragPan={true}
              hideRadius={true}
              zoom={14}
              styles={mapStyles}
            />
            <ModalStickyButtonGroup padded>
              <GhostButton onClick={onClose}>Cancel</GhostButton>
              <PrimaryButton
                data-testid={'save-manual-address'}
                onClick={this.handleSave}
              >
                Save
              </PrimaryButton>
            </ModalStickyButtonGroup>
          </>
        )}
      </Modal>
    );
  }
}

export default ManualAddressModal;

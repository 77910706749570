import { Box } from '@rexlabs/box';
import { styled, StyleSheet } from '@rexlabs/styling';
import React, { PureComponent } from 'react';

const defaultStyles = StyleSheet({
  container: {
    width: '40rem',
    textAlign: 'center'
  }
});

@styled(defaultStyles)
class AuthBox extends PureComponent {
  render() {
    const { styles: s, ...rest } = this.props;
    return (
      <Box
        {...s.with('container')(this.props)}
        flexDirection='column'
        {...rest}
      />
    );
  }
}

export default AuthBox;

import { Box } from '@rexlabs/box';
import { ButtonGroup, GhostButton, PrimaryButton } from '@rexlabs/button';
import { useModelActions } from '@rexlabs/model-generator';
import { useToast } from '@rexlabs/notifications';
import { useToken } from '@rexlabs/styling';
import React from 'react';
import campaignsModel from 'src/data/models/entities/campaigns';
import { Body } from 'src/view/components/text';

function ArchiveCampaignModal({ campaignId, closeModal }) {
  const campaign = useModelActions(campaignsModel);
  const token = useToken();
  const toasts = useToast();
  const archiveCampaign = async () => {
    try {
      await campaign.updateItem({
        id: campaignId,
        data: { status: { id: 'archived' } }
      });
      toasts.addToast({
        type: 'success',
        description: 'Campaign has been archived successfully'
      });
      closeModal();
    } catch (e) {
      toasts.addToast({
        target: 'modal',
        type: 'error',
        title: 'Could not archive campaign',
        description: e.message
      });
    }
  };

  return (
    <Box w='50rem' p={token('spacing.xxl')} style={{ textAlign: 'center' }}>
      <Body>Are you sure you want to archive this campaign?</Body>
      <Body grey>
        You are about to stop the campaign. All unused funds lost. You will be
        able to find this campaigns details in the Archived Campaigns section.
      </Body>
      <Box justifyContent='center' mt={token('spacing.xxl')}>
        <ButtonGroup>
          <GhostButton onClick={closeModal}>Cancel</GhostButton>
          <PrimaryButton onClick={archiveCampaign}>Archive</PrimaryButton>
        </ButtonGroup>
      </Box>
    </Box>
  );
}

export default ArchiveCampaignModal;

import React, { ReactNode } from 'react';
import { margin, StyleSheet, useStyles } from '@rexlabs/styling';
import { Label, Tiny } from '@rexlabs/text';

const styles = StyleSheet({
  field: {
    display: 'flex',

    '& > * + *': {
      ...margin.styles({
        top: 'xs'
      })
    }
  },

  fullWidth: {
    flexDirection: 'column'
  },

  inline: {
    flexDirection: 'row'
  },

  label: {
    flex: 0,
    display: 'inline',
    verticalAlign: 'middle'
  }
});

export interface FormFieldProps {
  name: string;
  label: ReactNode;
  description?: ReactNode;
  errors?: ReactNode;
  inline?: boolean;
  Input: ReactNode;
}

export function FormField({
  name,
  label,
  description,
  errors,
  inline = false,
  Input
}: FormFieldProps) {
  const s = useStyles(styles);
  return (
    <div
      {...s('field', {
        fullWidth: !inline,
        inline
      })}
    >
      <Label {...s('label')} {...{ htmlFor: name }} as='label'>
        {label}
      </Label>
      <div>{Input}</div>
      {errors}
      {description && (
        <Tiny grey as='span'>
          {description}
        </Tiny>
      )}
    </div>
  );
}

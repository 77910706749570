import { GhostButton } from '@rexlabs/button';
import { styled, StyleSheet, StylesProvider } from '@rexlabs/styling';
import { Tabs } from '@rexlabs/tabs';
import { autobind } from 'core-decorators';
import React, { Fragment, PureComponent } from 'react';
import { ModalStickyButtonGroup } from 'src/view/components/button';
import {
  FacebookActivityItem,
  List,
  StatsListHeader,
  StatsListItem
} from 'src/view/components/list';
import { Modal } from 'src/view/components/modal';

import Angry from 'src/assets/icons/fb-angry.svg';
import Haha from 'src/assets/icons/fb-haha.svg';
import Like from 'src/assets/icons/fb-like.svg';
import Love from 'src/assets/icons/fb-love.svg';
import Sad from 'src/assets/icons/fb-sad.svg';
import Wow from 'src/assets/icons/fb-wow.svg';

const modalStyles = StyleSheet({
  container: {
    height: '620rem'
  },

  contentNoPadding: {
    overflow: 'hidden',
    padding: ({ token }) => token('spacing.xl'),
    paddingTop: '0',
    paddingBottom: '0'
  }
});

const componentsStyles = {
  RenderLoading: StyleSheet({
    animationContainer: {
      height: '100%'
    },

    content: {
      height: '100%'
    }
  }),

  Tabs: StyleSheet({
    container: {
      display: 'flex',
      flexDirection: 'column',
      height: '100%',

      '@media print': {
        display: 'none'
      }
    },

    tabs: {
      overflow: 'auto',
      marginBottom: '10rem',

      '::-webkit-scrollbar': {
        WebkitAppearance: 'none',
        width: '7px'
      },

      '::-webkit-scrollbar-thumb': {
        borderRadius: '4px',
        backgroundColor: ({ token }) => token('legacy.color.blue.grey'),
        WebkitBoxShadow: '0 0 1px rgba(255,255,255,.5)'
      }
    }
  }),

  TabBar: StyleSheet({
    list: {
      overflowY: 'scroll'
    }
  }),

  Tab: StyleSheet({
    container: {
      marginRight: ({ token }) => `${token('spacing.m')} !important`,
      flex: '1 1 auto !important'
    }
  }),

  List: StyleSheet({
    seperator: {
      display: 'none'
    }
  })
};

const defaultStyles = StyleSheet({
  printList: {
    display: 'none',
    '@media print': {
      display: 'block',
      flex: '1 1 50%'
    }
  }
});

const icons = {
  reaction_haha: <Haha style={{ width: '20px' }} />,
  reaction_love: <Love style={{ width: '20px' }} />,
  reaction_wow: <Wow style={{ width: '20px' }} />,
  reaction_sad: <Sad style={{ width: '20px' }} />,
  reaction_angry: <Angry style={{ width: '20px', height: '20px' }} />,
  reaction_like: <Like style={{ width: '20px' }} />
};

@autobind
@styled(defaultStyles)
class FacebookEngagement extends PureComponent {
  state = {
    activeTab: 'all'
  };

  items() {
    const { facebookActivity: allActivities } = this.props;
    const comments = allActivities.filter((a) => a.type.id === 'comment');
    const reactions = allActivities.filter((a) =>
      a.type.id.startsWith('reaction_')
    );
    const shares = allActivities.filter((a) => a.type.id === 'share');

    return [
      {
        name: 'all',
        label: (
          <React.Fragment>
            All &nbsp;<span>({allActivities.length})</span>
          </React.Fragment>
        ),
        Tab: () => {
          return this.renderList(allActivities);
        }
      },
      {
        name: 'comments',
        label: (
          <React.Fragment>
            Comments &nbsp;<span>({comments.length})</span>
          </React.Fragment>
        ),
        Tab: () => {
          return this.renderList(comments);
        }
      },
      {
        name: 'reactions',
        label: (
          <React.Fragment>
            Reactions &nbsp;<span>({reactions.length})</span>
          </React.Fragment>
        ),
        Tab: () => {
          return this.renderList(reactions);
        }
      },
      {
        name: 'shares',
        label: (
          <React.Fragment>
            Shares &nbsp;<span>({shares.length})</span>
          </React.Fragment>
        ),
        Tab: () => {
          return this.renderList(shares);
        }
      }
    ];
  }

  itemsForPrint() {
    const { facebookActivity: allActivities } = this.props;
    const comments = allActivities.filter((a) => a.type.id === 'comment');
    const reactions = allActivities.filter((a) =>
      a.type.id.startsWith('reaction_')
    );
    const shares = allActivities.filter((a) => a.type.id === 'share');

    const filteredReactions = reactions.reduce((acc, i) => {
      const label =
        i.type.label.toLowerCase() === 'none' ? 'Other' : i.type.label;
      const reaction = acc.find((reactions) => reactions.id === i.type.id);

      if (reaction) {
        reaction.data[0].value += 1;
      } else {
        acc.push({
          id: i.type.id,
          label: (
            <span>
              {icons[i.type.id]} {label}
            </span>
          ),
          data: [{ value: 1 }]
        });
      }
      return acc;
    }, []);

    return [
      comments.length + shares.length + reactions.length,
      { label: 'Comments', data: [{ value: comments.length }] },
      { label: 'Shares', data: [{ value: shares.length }] },
      { label: 'Reactions', data: [{ value: reactions.length }] },
      ...filteredReactions
    ];
  }

  renderList(activities = []) {
    return (
      <List
        items={activities}
        Item={({ data: activity }) => (
          <FacebookActivityItem activity={activity} />
        )}
        animateRows={false}
      />
    );
  }

  render() {
    const { styles: s } = this.props;
    const [total, ...itemsForPrint] = this.itemsForPrint();

    return (
      <Fragment>
        <StylesProvider components={componentsStyles}>
          <Tabs
            onChange={({ name }) => this.setState({ activeTab: name })}
            activeTab={this.state.activeTab}
            items={this.items()}
          />
        </StylesProvider>

        <div {...s('printList')}>
          <List
            items={itemsForPrint}
            Header={() => (
              <StatsListHeader
                title='Facebook engagement'
                description='See how many people engaged with your content on Facebook and Instagram. Like, Love, Haha, Wow, Sad or Angry - see how people respond to your ads. Don’t forget to contact those who comment or share your ad!'
                value={total}
              />
            )}
            Item={({ data: stat }) => <StatsListItem stat={stat} />}
            animateRows={false}
          />
        </div>
      </Fragment>
    );
  }
}

function FacebookEngagementModal({ subTitle, closeModal, ...props }) {
  return (
    <Modal
      title='Facebook engagement'
      subtitle={subTitle}
      onClose={closeModal}
      noPadding
      styles={modalStyles}
    >
      <FacebookEngagement {...props} />

      <ModalStickyButtonGroup padded>
        <GhostButton onClick={closeModal}>Close</GhostButton>
      </ModalStickyButtonGroup>
    </Modal>
  );
}

export { FacebookEngagementModal, FacebookEngagement };

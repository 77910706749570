import { AlertDialogContentProps, Content } from '@radix-ui/react-alert-dialog';
import {
  border,
  margin,
  padding,
  StyleSheet,
  useStyles
} from '@rexlabs/styling';
import React, { FC, PropsWithoutRef } from 'react';

const styles = StyleSheet({
  container: {
    position: 'fixed',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '50vw',
    background: ({ token }) =>
      token('modal.background', token('color.container.static.default')),
    outline: 'none',
    ...padding.styles({
      target: 'modal',
      all: 'l'
    }),
    ...margin.styles({
      target: 'modal',
      x: () => 'auto'
    }),
    ...border.styles({
      target: 'modal',
      all: {
        radius: 'm'
      }
    }),
    '&:focus': { outline: 'none' }
  }
});

export type AlertContentProps = Omit<AlertDialogContentProps, 'asChild'> &
  PropsWithoutRef<JSX.IntrinsicElements['div']>;

export const AlertContent: FC<AlertContentProps> = ({
  className,
  style,
  children,
  ...rest
}) => {
  const s = useStyles(styles, 'AlertContent');

  return (
    <Content {...s.with('container')({ className, style })} {...rest}>
      {children}
    </Content>
  );
};

import { EventFn, TintIconButton } from '@rexlabs/button';
import Icons from '@rexlabs/icons-next';
import LoadingSpinner from '@rexlabs/loading-spinner';
import {
  border,
  keyframes,
  margin,
  StyleSheet,
  TokenGetter,
  useStyles
} from '@rexlabs/styling';
import { Truncate } from '@rexlabs/text';
import React, { ReactElement } from 'react';
import { useMemoCleanup } from 'src/hooks';
import { isImageMimeType } from 'src/utils/file';
import { FileDisplayProps } from '../types';
import { useFileDisplayState } from '../useFileDisplayState';

const getSuccessAnimation = (token: TokenGetter): string =>
  keyframes({
    '0%': {
      opacity: 1
    },
    '25%': {
      opacity: 1,
      backgroundColor: token('color.container.static.success.contrast')
    },
    '70%': {
      opacity: 1,
      backgroundColor: token('color.container.static.success.contrast')
    }
  });

const styles = StyleSheet({
  container: {
    position: 'relative',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    overflow: 'hidden',
    transition: 'background-color .35s ease-out',

    ...border.styles({
      all: {
        radius: 'm'
      }
    }),

    height: '10.4rem',
    width: '10.4rem',
    backgroundColor: ({ token }) => token('color.neutral.idle.contrast')
  },

  failed: {
    backgroundColor: ({ token }) =>
      token('color.container.static.danger.default')
  },

  inner: {
    width: '100%',
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',

    '& > * + *': {
      ...margin.styles({
        top: 'xxs'
      })
    }
  },

  preview: {
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    objectFit: 'cover',
    width: '100%',
    height: '100%'
  },

  previewLoading: {
    filter: 'blur(5px) saturate(50%)'
  },

  content: {
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
    fontSize: ({ token }) => token('typography.size.m'),
    fontWeight: ({ token }) => token('typography.weight.medium'),
    lineHeight: ({ token }) => token('typography.lineHeight.m')
  },

  removeButton: {
    position: 'absolute',
    top: '0.8rem',
    right: '0.8rem'
  },

  success: {
    animation: ({ token }) =>
      `${getSuccessAnimation(token)} 2s ease-in forwards`
  }
});

export type PreviewFileDisplayProps<T> = FileDisplayProps<T>;

export const PreviewFileDisplay = <T,>(
  props: PreviewFileDisplayProps<T>
): ReactElement | null => {
  const s = useStyles(styles, 'PreviewFileDisplay');
  const {
    isComplete,
    isError,
    isLoading,
    removeFile,
    state,
    error,
    file,
    previewData,
    retry
  } = useFileDisplayState(props);
  const fileImageURL = useMemoCleanup(
    () => {
      return file !== undefined && isImageMimeType(file.type)
        ? URL.createObjectURL(file as File)
        : undefined;
    },
    (prev) => prev && URL.revokeObjectURL(prev),
    [file]
  );

  const isImage = previewData?.url !== undefined || fileImageURL !== undefined;
  const previewUrl = file ? fileImageURL : previewData?.url;

  return (
    <div
      {...s('container', {
        [state]: true
      })}
    >
      <div {...s('inner')}>
        {isImage ? (
          <img
            src={previewUrl}
            {...s('preview', {
              previewLoading: !isComplete
            })}
          />
        ) : null}

        {isLoading ? (
          <LoadingSpinner size={16} strokeWidth={2} />
        ) : isError ? (
          <Icons.WarningCircle />
        ) : !isImage ? (
          <Icons.PaperClip size='m' />
        ) : null}
        {!isImage || isError ? (
          <span {...s('content')}>
            {isError ? (
              error instanceof Error ? (
                error.message
              ) : (
                'Unsuccessful.'
              )
            ) : (
              <Truncate>{previewData?.name}</Truncate>
            )}
          </span>
        ) : null}
        {isError && retry ? (
          <TintIconButton
            size='xs'
            Icon={Icons.Refresh}
            onClick={retry as unknown as EventFn}
          />
        ) : null}
        <TintIconButton
          {...s('removeButton')}
          size='xs'
          onClick={removeFile as unknown as EventFn}
          Icon={Icons.CrossSmall}
        />
      </div>
    </div>
  );
};

import { Box } from '@rexlabs/box';
import React, { FC } from 'react';
import { StyleSheet, useStyles } from '@rexlabs/styling';

const defaultStyles = StyleSheet({
  logoWrapper: {
    position: 'relative',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    overflow: 'hidden',
    height: '100%',
    width: '100%',
    border: '0.1rem solid #8aa0ae',
    boxSizing: 'border-box',
    '> img': {
      position: 'absolute',
      objectFit: 'contain'
    }
  }
});

type Props = {
  url: string;
  bgColor: string;
  scale: number;
  displayCircle?: boolean;
};
export const ScaledLogo: FC<Props> = ({
  url,
  bgColor,
  scale,
  displayCircle = false
}) => {
  const s = useStyles(defaultStyles);
  return (
    <Box
      {...s.with('logoWrapper')({
        borderRadius: displayCircle ? '100%' : '0',
        backgroundColor: bgColor
      })}
    >
      <img
        src={url}
        style={{
          width: `${scale}%`
        }}
      />
    </Box>
  );
};

import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { styled, StyleSheet } from '@rexlabs/styling';
import uuid4 from '../utils/uuid4';

const defaultStyles = StyleSheet({
  svg: {
    position: 'absolute',
    height: 0
  },
  text: {
    color: 'black',
    display: 'inline',
    boxDecorationBreak: 'clone'
  }
});

GooText.propTypes = {
  backgroundColor: PropTypes.string,
  borderRadius: PropTypes.number,
  padding: PropTypes.string
};

/**
 * This component uses an SVG as a filter to wrap children in a rounded polygon
 * https://codepen.io/julianburr/pen/bKLbVv
 */

function GooText({
  borderRadius = 3,
  backgroundColor = 'white',
  padding = '10px 8px 6px',
  children,
  styles: s
}) {
  const [filterId] = useState(uuid4());

  return (
    <span
      key={2}
      {...s.with('text')({
        filter: `url('#${filterId}')`,
        backgroundColor,
        padding
      })}
    >
      <svg xmlns='http://www.w3.org/2000/svg' version='1.1' {...s('svg')}>
        <defs>
          <filter id={filterId}>
            <feGaussianBlur
              in='SourceGraphic'
              stdDeviation={borderRadius}
              result='blur'
            />
            <feColorMatrix
              in='blur'
              mode='matrix'
              values='1 0 0 0 0  0 1 0 0 0  0 0 1 0 0  0 0 0 19 -9'
              result='goo'
            />
            <feComposite in='SourceGraphic' in2='goo' operator='atop' />
          </filter>
        </defs>
      </svg>
      {React.Children.only(children)}
    </span>
  );
}

export default styled(defaultStyles)(GooText);

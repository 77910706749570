function urlToPageName(path, hash, preHash) {
  const formattedPath = path
    .replace(/^\/\d+/, '')
    .replace(
      /[a-fA-F0-9]{8}-[a-fA-F0-9]{4}-[a-fA-F0-9]{4}-[a-fA-F0-9]{4}-[a-fA-F0-9]{12}?/,
      ':id'
    );

  const formattedHash = hash || '';
  const formattedPreHash = preHash ? `/${preHash}` : '';

  return `${formattedPath}${formattedPreHash}${formattedHash}`;
}

export default urlToPageName;

const MIME_TYPE_REGEX =
  /^(\*|[a-z0-9._-]+)\/(\*|[a-z0-9._-]+)(?:; ([a-zA-Z0-9._\-=]+))?$/;

type MatcherFn = (actual: string) => boolean;

function createMatcher(expected: string): MatcherFn {
  if (expected === '*') {
    return () => true;
  } else {
    return (actual) => actual === expected;
  }
}

type SuccesfulParseResult = {
  valid: true;
  type: string;
  subType: string;
  parameter: string;
};

type UnsuccessfulParseResult = {
  valid: false;
};

type ParseResult = SuccesfulParseResult | UnsuccessfulParseResult;

/**
 * Validate and extract information from a mime type specifier.
 * @param mimeType A value that might be a mime type
 * @returns A parsing result with the validity and extracted information.
 */
export function parse(mimeType: string): ParseResult {
  if (!mimeType) {
    return {
      valid: false
    };
  }

  const match = mimeType.match(MIME_TYPE_REGEX);

  if (!match) {
    return {
      valid: false
    };
  }

  const [, type, subType, parameter] = Array.from(match);
  return {
    valid: true,
    type,
    subType,
    parameter
  };
}

/**
 * Check if the given string is a valid mime type specifier.
 * @param mimeType The string to check
 * @returns true if the string is a mime type specifier, false otherwise.
 */
export function isValid(mimeType: string): boolean {
  return parse(mimeType).valid;
}

type MatcherObject = {
  typeMatcher: MatcherFn;
  subTypeMatcher: MatcherFn;
};

export class MimeMatcher {
  expected: MatcherObject[] = [];

  constructor(...expected: string[]) {
    this.expected = expected.map((mimeType) => {
      const res = parse(mimeType);
      if (res.valid) {
        return {
          typeMatcher: createMatcher(res.type),
          subTypeMatcher: createMatcher(res.subType)
        };
      } else {
        const msg = `Value "${mimeType}" is not valid mime type.It should have format "type/subtype".`;
        throw new TypeError(msg);
      }
    });
  }

  match(actual: string): boolean {
    const res = parse(actual);
    if (res.valid) {
      return this.expected.some(({ typeMatcher, subTypeMatcher }) => {
        return typeMatcher(res.type) && subTypeMatcher(res.subType);
      });
    } else {
      return false;
    }
  }
}

/**
 * Create a function to match a set of mime types to a given mime type
 * @param expected List of mime typoes to match
 * @returns Function that matches the `expected` mime types to the actual mime types
 */
export function matcher(...expected: string[]): MatcherFn {
  const m = new MimeMatcher(...expected);
  return (actual) => m.match(actual);
}

export const isImageMimeType = matcher('image/*');

/**
 * Transforms given file size into human readable form
 * @param bytes Number of bytes
 * @returns Formatted value of size
 */
export function humanFileSize(bytes: number): string {
  const thresh = 1000;
  const units = ['kB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];

  if (Math.abs(bytes) < thresh) {
    return bytes + ' B';
  }

  let index = -1;
  while (
    Math.round(Math.abs(bytes) * 10) / 10 >= thresh &&
    index < units.length - 1
  ) {
    index++;
    bytes /= thresh;
  }

  return `${bytes.toFixed(1)} ${units[index]}`;
}

/**
 * Computes the data URI for given file's contents
 * @param file File to generate URI for
 * @returns Data URI of the file contents
 */
export function getBase64(file: File): Promise<string> {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.onload = () => resolve(reader.result as string);
    reader.onerror = (error) => reject(error);
    reader.readAsDataURL(file);
  });
}

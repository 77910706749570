import React, { Component } from 'react';
import { autobind } from 'core-decorators';
import _isEqual from 'lodash/isEqual';

import AdwordsNative from './native';
import AdwordsWide from './wide';
import AdwordsHigh from './high';
import AdwordsSmall from './small';
import AdwordsStandard from './standard';

import { getBannerAdData } from 'shared/utils/prepare-ad-data';
import AppraisalBannerAd from 'shared/components/appraisal-banner-ad';
import ListingBannerAd from 'shared/components/listing-banner-ad';
import AgentBannerAd from 'shared/components/agent-banner-ad';

@autobind
class AdwordsAdPreview extends Component {
  shouldComponentUpdate(nextProps) {
    return !_isEqual(this.props.campaignData, nextProps.campaignData);
  }

  render() {
    const { ad, campaignData } = this.props;
    // format is campaign_type.network.ad_type.ad_variation
    const format = ad?.format?.id ?? '';
    const split = format.split('.');
    const campaignType = split[0];
    const adType = split[2];
    const adVariation = split[3];

    if (adType.startsWith('responsive')) {
      if (adVariation === 'native') return <AdwordsNative {...this.props} />;
      if (adVariation === 'wide') return <AdwordsWide {...this.props} />;
      if (adVariation === 'high') return <AdwordsHigh {...this.props} />;
      if (adVariation === 'small') return <AdwordsSmall {...this.props} />;
      if (adVariation === 'standard') {
        return <AdwordsStandard {...this.props} />;
      }
    }

    const adContentSet = getBannerAdData(campaignData, campaignType);

    if (campaignType === 'appraisal') {
      return <AppraisalBannerAd adType={adType} adContentSet={adContentSet} />;
    }

    if (campaignType === 'listing') {
      return <ListingBannerAd adType={adType} adContentSet={adContentSet} />;
    }

    if (campaignType === 'agent') {
      return <AgentBannerAd adType={adType} adContentSet={adContentSet} />;
    }

    return <span>Ad not found</span>;
  }
}

export default AdwordsAdPreview;

import { createRestAPIModelGenerator } from 'src/data/models/generator';
import { api } from 'src/utils/api-client';

export const TYPE = 'users';

const config = {
  entities: {
    api: {
      fetchList: (type, args) => api.get('/account/users', args),
      fetchItem: (type, args, id) => api.get(`/account/users/${id}`, args),
      updateItem: (type, args, id) => api.patch(`/account/users/${id}`, args),
      trashItem: (type, args, id, action) => {
        let oldXAccountId = null;

        if (
          action.payload.agencyId &&
          api.headers['X-Account-Id'] !== action.payload.agencyId
        ) {
          oldXAccountId = api.headers['X-Account-Id'];
          api.setHeader('X-Account-Id', action.payload.agencyId);
        }

        return new Promise((resolve) => {
          api.delete(`/account/users/${id}`, args).then(resolve);
          if (oldXAccountId) {
            api.setHeader('X-Account-Id', oldXAccountId);
          }
        });
      },
      createItem: (type, { id, ...args }) =>
        api.post(`/account/users/${id}`, args)
    }
  }
};

const UsersGenerator = createRestAPIModelGenerator(TYPE, config);
export default UsersGenerator.createEntityModel();

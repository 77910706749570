import React, { PureComponent } from 'react';
import { styled, StyleSheet } from '@rexlabs/styling';
import Box from '@rexlabs/box';

import instagramHeader from '../../../assets/instagram/header.png';
import instagramActionMenu from '../../../assets/instagram/icon-action-menu.png';
import instagramIconChevron from '../../../assets/instagram/icon-chevron.png';
import instagramIconLike from '../../../assets/instagram/icon-like.png';
import instagramIconComment from '../../../assets/instagram/icon-comment.png';
import instagramIconShare from '../../../assets/instagram/icon-share.png';
import instagramIconBookmark from '../../../assets/instagram/icon-bookmark.png';
import instagramIconBookmarkActive from '../../../assets/instagram/icon-bookmark-active.png';

const defaultStyles = StyleSheet({
  container: {
    width: '32rem',
    border: '.1rem solid rgba(0, 0, 0, .3)',
    background: 'white'
  },

  header: {
    width: '100%',
    height: '4rem',
    background: `url(${instagramHeader}) transparent no-repeat center center`,
    backgroundSize: 'contain'
  },

  meta: {
    padding: '1.2rem'
  },

  logo: {
    width: '3rem',
    height: '3rem',
    marginRight: '.8rem',
    background: 'transparent none center center no-repeat',
    backgroundSize: 'contain',
    border: '.3rem solid transparent'
  },

  accountName: {
    fontSize: '1.2rem',
    lineHeight: '1.1',
    fontWeight: 700
  },

  sponsored: {
    fontSize: '1.1rem',
    lineHeight: '1.1'
  },

  actionMenu: {
    width: '1.4rem',
    height: '1.4rem',
    background: `url(${instagramActionMenu}) transparent no-repeat center center`,
    backgroundSize: 'contain'
  },

  wrapLearnMore: {
    padding: '0 1.2rem'
  },

  learnMore: {
    border: '0 none',
    borderBottom: '.1rem solid #e9eaeb',
    padding: '1.2rem 0',
    background: 'transparent',
    width: '100%',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    color: '#3397f0',
    fontWeight: 700
  },

  chevron: {
    width: '1.4rem',
    height: '1.4rem',
    background: `url(${instagramIconChevron}) transparent no-repeat 0 0`,
    backgroundSize: 'contain'
  },

  actions: {
    padding: '1.2rem'
  },

  wrapActions: {
    width: '10rem'
  },

  action: {
    width: '2rem',
    height: '2rem',
    background: 'none transparent no-repeat center center',
    backgroundSize: 'contain',
    marginRight: '.8rem',
    border: '0 none',
    flexShrink: 0
  },

  actionLike: {
    backgroundImage: `url(${instagramIconLike})`
  },

  actionComment: {
    backgroundImage: `url(${instagramIconComment})`
  },

  actionShare: {
    backgroundImage: `url(${instagramIconShare})`
  },

  actionBookmark: {
    backgroundImage: `url(${instagramIconBookmark})`,
    cursor: 'pointer'
  },

  actionBookmarkActive: {
    backgroundImage: `url(${instagramIconBookmarkActive})`
  },

  description: {
    padding: '.4rem 1rem 1rem',
    fontSize: '1.1rem',
    color: '#282828',
    lineHeight: '1.5rem',
    letterSpacing: '.5px',
    minHeight: '8rem'
  },

  more: {
    color: '#929598'
  }
});

@styled(defaultStyles)
class InstagramContainer extends PureComponent {
  state = {
    bookmarked: false
  };

  render() {
    const {
      styles: s,
      adContentSet,
      shortHeadline,
      campaignDescription,
      children,
      Nav
    } = this.props;
    const { bookmarked } = this.state;

    const description =
      campaignDescription && campaignDescription.length > 135 ? (
        <span>
          {campaignDescription.substr(0, 135)}{' '}
          <span {...s('more')}>... more</span>
        </span>
      ) : (
        campaignDescription
      );

    return (
      <Box {...s('container')}>
        <Box {...s('header')} />
        <Box {...s('meta')} flexDirection='row' justifyContent='space-between'>
          <Box {...s('wrapAccount')} flexDirection='row' alignItems='center'>
            <Box
              {...s.with('logo')({
                backgroundImage: adContentSet.agencyLogo
                  ? `url(${adContentSet.agencyLogo})`
                  : 'none',
                backgroundColor: adContentSet.backgroundColor
                  ? adContentSet.backgroundColor
                  : 'transparent'
              })}
            />
            <Box {...s('wrapAccountName')} flexDirection='column'>
              <span {...s('accountName')}>{adContentSet.facebookPage}</span>
              <span {...s('sponsored')}>Sponsored</span>
            </Box>
          </Box>
          <Box {...s('actionMenu')} />
        </Box>

        {children}

        <Box {...s('wrapLearnMore')}>
          <button {...s('learnMore')}>
            <span>Learn more</span>
            <span {...s('chevron')} />
          </button>
        </Box>
        <Box
          {...s('actions')}
          flexDirection='row'
          justifyContent='space-between'
        >
          <Box flexDirection='row' {...s('wrapActions')}>
            <button {...s('action', 'actionLike')} />
            <button {...s('action', 'actionComment')} />
            <button {...s('action', 'actionShare')} />
          </Box>

          {Nav && <Nav />}

          <Box {...s('wrapActions')} justifyContent='flex-end'>
            <button
              {...s('action', 'actionBookmark', {
                actionBookmarkActive: bookmarked
              })}
              onClick={() => this.setState({ bookmarked: !bookmarked })}
            />
          </Box>
        </Box>
        <Box {...s('description')}>
          {shortHeadline}
          {shortHeadline && <br />}
          {description}
        </Box>
      </Box>
    );
  }
}

export default InstagramContainer;

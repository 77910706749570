import { Body } from '@rexlabs/text';
import React from 'react';

export function MaximiseExposureGoalDescription() {
  return (
    <Body grey>
      Put your listing in front of more potential buyers and sellers wherever
      they go online.
    </Body>
  );
}

import { TintButton } from '@rexlabs/button';
import { Checkbox } from '@rexlabs/checkbox';
import Icons from '@rexlabs/icons-next';
import { padding, StyleSheet, useStyles } from '@rexlabs/styling';
import { Body, Tiny } from '@rexlabs/text';
import { format } from 'date-fns';
import React, { ChangeEventHandler, FC, useCallback } from 'react';
import {
  AlertDescription,
  AlertTitle,
  ConfirmActionAlert
} from 'src/components/elements/alert';
import { FormControlOptions } from 'src/components/form';
import { Promisable } from 'type-fest';
import { CustomAudience } from '../types';

const styles = StyleSheet({
  container: {
    display: 'flex',
    flexDirection: 'row',
    ...padding.styles({
      y: 's',
      x: 'm'
    }),

    ':hover': {
      backgroundColor: ({ token }) => token('color.container.interactive.hover')
    },

    ':focus': {
      backgroundColor: ({ token }) =>
        token('color.container.interactive.active')
    }
  },

  selected: {
    backgroundColor: ({ token }) =>
      token('color.container.interactive.selected')
  },

  title: {
    display: 'flex',
    flexDirection: 'column',
    flex: '1'
  },

  subtitle: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center'
  },

  delete: {
    fontSize: ({ token }) => token('typography.size.m'),
    color: ({ token }) => token('color.textStyle.danger.idle.default')
  }
});

export type CustomAudienceItemProps = FormControlOptions & {
  customAudience: CustomAudience;
  selected: boolean;
  rowIndex: number;
  onSelect: ChangeEventHandler<HTMLInputElement>;
  onDelete: (id: string) => Promisable<void>;
};

export const CustomAudienceItem: FC<CustomAudienceItemProps> = ({
  customAudience,
  selected,
  rowIndex,
  onSelect,
  onDelete,
  isReadOnly
}) => {
  const s = useStyles(styles, 'CustomAudienceItem');
  const handleDelete = useCallback(
    async () => await onDelete(customAudience.id),
    [customAudience.id, onDelete]
  );

  return (
    <div {...s('container', { selected })} role='row' aria-rowindex={rowIndex}>
      <div role='gridcell' {...s('title')}>
        <Body as='span'>{customAudience.name}</Body>
        <Tiny grey as='span' {...s('subtitle')}>
          Last modified on {format(customAudience.updated_at, "MMM eo',' yyyy")}
          <Icons.CircleFilled />
          <ConfirmActionAlert
            destructive
            confirmLabel='Delete'
            onConfirm={handleDelete}
            openButton={<TintButton {...s('delete')}>Delete</TintButton>}
          >
            <AlertTitle>
              Are you sure you want to delete this custom audience?
            </AlertTitle>
            <AlertDescription>
              Please confirm the deletion of the <b>{customAudience.name}</b>{' '}
              custom audience.
            </AlertDescription>
          </ConfirmActionAlert>
        </Tiny>
      </div>
      <div role='gridcell'>
        <Checkbox
          value={selected}
          onChange={onSelect}
          name={customAudience.id}
          disabled={isReadOnly}
        />
      </div>
    </div>
  );
};

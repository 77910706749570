import { Box } from '@rexlabs/box';
import { DestructiveButton } from '@rexlabs/button';
import { query, withModel, withQuery } from '@rexlabs/model-generator';
import { autobind } from 'core-decorators';
import map from 'lodash/map';
import React, { PureComponent } from 'react';
import { Helmet } from 'react-helmet';
import wizardModel from 'src/data/models/custom/wizard';
import agentsModel from 'src/data/models/entities/agents';
import { queryAgents } from 'src/data/queries/agents';
import { formattedBrandName, withToken } from 'src/theme';
import { serializeSuburb } from 'src/utils/address';
import { createValidationRules } from 'src/utils/form';
import { suburbToMapbox } from 'src/utils/mapbox';
import { Form, ReactForms } from 'src/view/components/form';
import { RenderLoading } from 'src/view/components/loading';
import { FullscreenModal } from 'src/view/components/modal';
import { Heading } from 'src/view/components/text';
import withError from 'src/view/containers/with-error';
import withToast from 'src/view/containers/with-toast';
import EditAgentForm from 'src/view/forms/edit-agent';
import DeleteAgentModal from 'src/view/modals/admin/delete-agent';

function getId(props) {
  return props.id || props?.match?.params?.agentId;
}

const q = query`{
  ${agentsModel} (id: ${getId}) {
    id
    full_name
    position
    description
    slogan
    url
    email
    lead_capture_email
    phone_number
    suburbs
    images
    videos
  }
}`;

export const validate = createValidationRules({
  full_name: ['required', 'full name'],
  position: ['required', 'position / title'],
  slogan: ['required', 'agent slogan'],
  description: ['required', 'agent description'],
  url: ['required|url', 'agent page URL'],
  email: ['required|email', 'agent work email'],
  lead_capture_email: ['email', 'lead capture email'],
  phone_number: ['required', 'phone number'],
  'suburbs.*': ['required', 'suburbs'],
  images: ['required', 'agent photo']
});

export function mapPropsToValues(props) {
  if (getId(props)) {
    const data = props?.agents?.item?.data;
    return {
      ...data,
      full_name: data.full_name || '',
      position: data.position || '',
      slogan: data.slogan || '',
      description: data.description || '',
      url: data.url || '',
      email: data.email || '',
      lead_capture_email: data.lead_capture_email || '',
      phone_number: data.phone_number || '',
      suburbs: data.suburbs?.data ? data.suburbs.data.map(suburbToMapbox) : [],
      video: data?.videos?.[0] || null
    };
  }
  return {
    full_name: '',
    position: '',
    slogan: '',
    description: '',
    url: '',
    email: '',
    lead_capture_email: '',
    phone_number: '',
    suburbs: [],
    images: []
  };
}

@withToken
@withToast
@withModel(wizardModel)
@withError('errorModal')
@withQuery({ ...queryAgents, alias: 'agentList' })
@withQuery(q)
@autobind
class EditAgentModal extends PureComponent {
  state = {
    showDeleteModal: false
  };

  getInitialValues() {
    return mapPropsToValues(this.props);
  }

  async handleSubmit(values) {
    const {
      agents,
      agentList,
      errorModal,
      closeModal,
      toasts,
      wizard: { set }
    } = this.props;

    const isUpdate = getId(this.props);
    const action = isUpdate ? agents.updateItem : agents.createItem;

    const suburbs = (values.suburbs || [])
      .filter(Boolean)
      .map((suburb) => serializeSuburb(suburb.value));

    try {
      const { data } = await action({
        data: {
          full_name: values.full_name,
          position: values.position,
          slogan: values.slogan,
          description: values.description,
          url: values.url,
          email: values.email,
          lead_capture_email: values.lead_capture_email,
          phone_number: values.phone_number,
          suburbs: suburbs.length ? suburbs : undefined,
          images: map(values.images, (image) => ({
            id: image.id,
            name: image.name
          })),
          videos: values.video ? [{ id: values.video.id }] : []
        }
      });

      await agentList.refreshList();

      if (!isUpdate) {
        set({ selectedEntityId: data.id });
        toasts.addToast({
          title: 'Your agent profile has been successfully created'
        });
      }

      closeModal();
    } catch (e) {
      errorModal.open(e);
    }
  }

  async handleDeleteAgent({ id }) {
    const { agentList, errorModal, closeModal, toasts } = this.props;
    if (id) {
      try {
        await agentList.refreshList();
        toasts.addToast({ title: 'Agent successfully deleted' });
        closeModal();
      } catch (e) {
        errorModal.open(e);
        this.setState({ showDeleteModal: false });
      }
    } else {
      this.setState({ showDeleteModal: false });
    }
  }

  render() {
    const {
      closeModal,
      errorModal: { Error },
      agents,
      enforceCampaignValidation,
      token
    } = this.props;

    const agentId = getId(this.props);
    const initialValues = this.getInitialValues();

    return (
      <Box>
        <Helmet>
          <title>
            {formattedBrandName} -{' '}
            {agentId ? 'Edit Agent Profile' : 'Create a New Agent'}
          </title>
        </Helmet>
        <Box justifyContent='space-between'>
          <Heading>
            {enforceCampaignValidation
              ? 'Oops, we need some more information about this agent to prepare the campaign'
              : agentId
              ? 'Edit agent profile'
              : 'Create a new agent'}
          </Heading>
          {agentId && (
            <Box mt={token('spacing.m')} mb={token('spacing.m')}>
              <DestructiveButton
                onClick={() => this.setState({ showDeleteModal: true })}
              >
                Delete
              </DestructiveButton>
            </Box>
          )}
        </Box>
        <ReactForms
          initialValues={initialValues}
          handleSubmit={this.handleSubmit}
          validate={validate}
          validateOnMount={enforceCampaignValidation}
          touchOnMount={enforceCampaignValidation}
          asyncValuesReady={this.props.agents.item.status === 'loaded'}
        >
          {({ values, submitForm, isSubmitting }) => (
            <RenderLoading
              isLoading={agentId && agents?.item?.status === 'loading'}
            >
              <Form name='editAgentForm'>
                <EditAgentForm
                  onSubmit={submitForm}
                  onCancel={closeModal}
                  isSubmitting={isSubmitting}
                  images={values.images}
                  agentId={agentId}
                  initialImages={agents?.item?.data?.images ?? []}
                  readOnlyLeadCaptureEmail={false}
                />
              </Form>
            </RenderLoading>
          )}
        </ReactForms>

        {agentId && this.state.showDeleteModal && (
          <DeleteAgentModal
            agentId={agentId}
            closeModal={this.handleDeleteAgent}
          />
        )}

        <Error />
      </Box>
    );
  }
}

class Core extends PureComponent {
  render() {
    const { closeModal } = this.props;
    return (
      <FullscreenModal onClose={closeModal}>
        <EditAgentModal {...this.props} />
      </FullscreenModal>
    );
  }
}

export default Core;

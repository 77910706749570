import React, { FC } from 'react';
import { ContentTypeEditorProps } from 'src/features/wizard';
import { ResourceDownloadCreateForm } from './ResourceDownloadCreateForm';
import { ResourceDownloadEditForm } from 'src/features/resource-downloads/components/ResourceDownloadEditForm';

export const ResourceDownloadDetailsEditor: FC<ContentTypeEditorProps> = ({
  contentId,
  selectContent
}) => {
  return contentId ? (
    <ResourceDownloadEditForm
      contentId={contentId}
      selectContent={selectContent}
    />
  ) : (
    <ResourceDownloadCreateForm selectContent={selectContent} />
  );
};

import Validator from 'validatorjs';
import _ from 'lodash';

Validator.register(
  'less_than_or_equal',
  function (val, req) {
    return val <= this.validator.input[req];
  },
  'Must be less than or equal to oldest age'
);

Validator.register(
  'greater_than_or_equal',
  function (val, req) {
    return val >= this.validator.input[req];
  },
  'Must be greater than or equal to youngest age'
);

Validator.register(
  'is_domain',
  function (val) {
    return val.match(/^[a-zA-Z0-9_-]+$/);
  },
  'Must be a valid domain'
);

Validator.register(
  'fb_network_required',
  function () {
    const { facebook, instagram } = this.validator.input.networks;
    const user_lists = this.validator.input?.user_lists || [];

    if (user_lists.length > 0 && !facebook && !instagram) {
      return false;
    }

    return true;
  },
  'Must select either Facebook or Instagram when using My own contacts'
);

// this validator requires the req value passed in eg: 'required_checkboxes:required'
// you can also pass in not required values which will be filtered out eg: 'required_checkboxes:required:not-required1,not-required2
Validator.register(
  'required_checkboxes',
  function (val, req, att) {
    const [required, notRequired] = req.split(':');
    let newValues = _.set({ ...this.validator.input }, att, val)[required];

    if (notRequired) {
      newValues = Object.keys(newValues).reduce((acc, key) => {
        if (!notRequired.split(',').includes(key)) {
          acc[key] = newValues[key];
        }
        return acc;
      }, {});
    }
    return Object.values(newValues).filter(Boolean).length !== 0;
  },
  'Must select at least one :attribute'
);

function getErrorMessages(validator) {
  const errors = validator.errors.all();
  return _.reduce(
    errors,
    (acc, error, field) => {
      // Formik expects an array of errors per field
      // so this will translate a repeated field error
      // into a single array for the repeated field
      const match = field.match(/\.(\d)$/);
      if (match) {
        const fieldSplit = field.split(/\.(\d)$/);
        _.set(acc, `${fieldSplit[0]}.${fieldSplit[1]}`, error[0]);
      } else {
        _.set(acc, field, error[0]);
      }
      return acc;
    },
    {}
  );
}

function submitFormFromPropsCore(propName) {
  return (values, args = {}) => {
    const { props, setStatus, setSubmitting, ...rest } = args;
    props.onSubmit(values, { props, ...rest }).catch((e) => {
      console.warn(e);
      if (props[propName]?.open) {
        props[propName].open(e);
      } else {
        setStatus({ error: e.message });
      }
      setSubmitting(false);
    });
  };
}

export function submitFormFromProps(values, args) {
  return submitFormFromPropsCore('error')(values, args);
}

submitFormFromProps.withPropName = (propName) => {
  return submitFormFromPropsCore(propName);
};

export function createValidationRules(definitions) {
  const rules = {};
  const attributeNames = {};
  const includes = {};

  for (const key in definitions) {
    const definition = definitions[key];
    if (Array.isArray(definition)) {
      rules[key] = definition[0];
      // ValidatorJS does not have a way of mapping wildcard field names
      // to attributes so this is a workaround to fix that
      if (/(\*)/.test(key)) {
        for (let i = 0; i < 100; i++) {
          // A hack on top of hack! I'm only changing prospecting.slides.*
          // as I'm unsure of the impacts on other wildcard fields.
          if (key.includes('prospecting.slides')) {
            attributeNames[key.replace('*', i)] = definition[1];
            includes[key.replace('*', i)] = definition[2];
          }

          attributeNames[`${key.split('.*')[0]}.${i}`] = definition[1];
          includes[`${key.split('.*')[0]}.${i}`] = definition[2];
        }
      } else {
        attributeNames[key] = definition[1];
        includes[key] = definition[2];
      }
    } else {
      rules[key] = definition;
    }
  }

  return (values) => {
    let newRules = rules;
    // eslint-disable-next-line camelcase
    if (values?.campaign_type) {
      newRules = _.omitBy(rules, (value, key) => {
        return (
          includes[key] && // Not sure if that makes sense, but it seems to fix things :|
          (!includes[key].includes(values.campaign_type) ||
            (includes[key].includes('agent_listing_toggled')
              ? !values.agent_listing_toggled
              : false) ||
            (includes[key].includes('banner_text_toggled')
              ? !values.banner_text_toggled
              : false))
        );
      });
    }
    const validator = new Validator(values, newRules);
    validator.setAttributeNames(attributeNames);
    if (validator.fails()) {
      const errors = getErrorMessages(validator);
      return errors;
    } else {
      return {};
    }
  };
}

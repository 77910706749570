import React from 'react';
import { StylesProvider } from '@rexlabs/styling';
import { SecondaryButton } from '@rexlabs/button';

const tokens = {
  button: {
    secondary: {
      border: {
        color: ({ token }) => token('legacy.color.blue.greyLight')
      },
      hover: {
        border: {
          color: ({ token }) => token('legacy.color.blue.greyLight')
        }
      }
    }
  }
};

function SecondaryButtonOutline(props) {
  return (
    <StylesProvider tokens={tokens}>
      <SecondaryButton {...props} />
    </StylesProvider>
  );
}

export default SecondaryButtonOutline;

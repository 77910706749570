import React from 'react';
import Select from './default';

function CampaignDurationSelect(props) {
  const options = [
    { label: '1 week', value: 1 },
    { label: '2 weeks', value: 2 },
    { label: '3 weeks', value: 3 },
    { label: '4 weeks', value: 4 },
    { label: '2 months', value: 8 },
    { label: '3 months', value: 12 }
  ];

  return (
    <div style={{ display: 'inline-block' }}>
      <Select isSearchable={false} options={options} {...props} />
    </div>
  );
}

export default CampaignDurationSelect;

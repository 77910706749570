import React, { FC } from 'react';
import { AdPreviewProps } from '../../types';
import { InstagramAdContainer } from './InstagramAdContainer';

export const InstagramVideoAd: FC<AdPreviewProps> = ({
  children,
  agencyLogo,
  logoBgColor,
  agencyName,
  longHeadline
}) => {
  return (
    <InstagramAdContainer
      pageName={agencyName}
      pageProfilePictureUrl={agencyLogo}
      logoBgColor={logoBgColor}
      description={longHeadline}
    >
      {children}
    </InstagramAdContainer>
  );
};

import _ from 'lodash';
import { createRestAPIModelGenerator } from 'src/data/models/generator';
import { api } from 'src/utils/api-client';

export const TYPE = 'rex-sync';

const config = {
  entities: {
    api: {
      fetchList: (type, args) =>
        api.get('/integrations/rex/sync-sessions', args),
      fetchItem: (type, args, id) =>
        api.get(`/integrations/rex/sync-sessions/${id}`, args)
    }
  }
};

const actionCreators = {
  fetchRawLogs: {
    request: ({ id }) =>
      api.get(`/integrations/rex/sync-sessions/${id}/raw-logs`),
    reduce: {
      init: _.identity,
      success: _.identity,
      failure: _.identity
    }
  },

  forceSync: {
    request: () => api.post('/integrations/rex/sync-sessions'),
    reduce: {
      init: _.identity,
      success: _.identity,
      failure: _.identity
    }
  }
};

const RexSyncGenerator = createRestAPIModelGenerator(TYPE, config);
export default RexSyncGenerator.createEntityModel({ actionCreators });

import { addressSchema } from 'src/features/audiences';
import { imageSizeSchema } from 'src/features/uploads';
import {
  Date,
  Describe,
  Optional,
  String,
  Type,
  URL,
  UUID,
  array,
  assign,
  boolean,
  create,
  enums,
  integer,
  number,
  record,
  string,
  type,
  size,
  nonempty,
  min
} from 'src/lib/superstruct';
import {
  CommercialListing,
  CommercialListingAttributes,
  FormattedAddress,
  Listing
} from '../types';

export const DATA_SOURCES = ['rex', 'spoke'] as const;
export const AUTHORITY_TYPES = [
  'auction',
  'exclusive',
  'multilist',
  'conjunctional',
  'open',
  'sale',
  'setsale',
  'eoi',
  'forsale',
  'offers',
  'tender'
] as const;
export const LISTING_STATUSES = ['current', 'sold', 'leased'] as const;
export const LISTING_TYPES = ['sale', 'rental', 'sale or lease'] as const;
export const COMMERCIAL_CATEGORIES = [
  'business_sale',
  'commercial_sale',
  'commercial_sale_rental',
  'commercial_rental'
] as const;

const formattedAddressSchema: Describe<FormattedAddress> = type({
  marketing_long: String(),
  marketing_long_html: String(),
  marketing_short: String(),
  short: String()
});

const listingSchema: Describe<Listing> = type({
  id: UUID,
  marketing_headline: String(),
  description: string(),
  marketing_url: URL,
  is_premium: boolean(),
  hide_address: boolean(),
  data_source: Type(DATA_SOURCES),
  is_updateable: Optional(boolean()),
  beds: integer(),
  baths: integer(),
  cars: Optional(integer()),
  price: Optional(String()),
  authority_type: Type(AUTHORITY_TYPES),
  should_display_events: boolean(),
  formatted_address: formattedAddressSchema,
  address: addressSchema,
  listing_status: Type(LISTING_STATUSES),
  sale_or_rental: enums(LISTING_TYPES),
  external_listing: Optional(
    type({
      id: UUID
    })
  ),
  thumbnails: record(string(), imageSizeSchema),
  created_at: Date,
  updated_at: Date
});

const commercialListingAttributesSchema: Describe<CommercialListingAttributes> =
  type({
    category: Optional(enums(COMMERCIAL_CATEGORIES)),
    sub_categories: array(String()),
    buildarea: Optional(number()),
    buildarea_m2: Optional(number()),
    buildarea_max: Optional(number()),
    buildarea_max_m2: Optional(number()),
    buildarea_unit: Optional(String()),
    landarea: Optional(number()),
    landarea_m2: Optional(number()),
    landarea_unit: Optional(String())
  });
const commercialListingSchema = assign(
  // @ts-expect-error Schema does conform to type, but assign doesn't understand this.
  listingSchema,
  commercialListingAttributesSchema
) as unknown as Describe<CommercialListing>;

export const commercialListingFormSchema = type({
  category: enums(COMMERCIAL_CATEGORIES),
  sub_categories: array(String()),
  buildarea_max: min(number(), 0),
  landarea: min(number(), 0),
  landarea_unit: nonempty(String()),
  marketing_headline: nonempty(String()),
  description: nonempty(String()),
  marketing_url: URL,
  images: size(array(), 2, 99),
  cars: Optional(size(number(), 0, 100000))
});

export const transformCommercialListingResponse = (
  value: unknown
): CommercialListing => create(value, commercialListingSchema);

import { Box } from '@rexlabs/box';
import { styled, StyleSheet } from '@rexlabs/styling';
import React from 'react';
import { AutoScale } from 'src/view/components/text';

import { cropText } from './shared/utils';

import GoogleIconChevron from 'src/assets/ad-previews/google/icon-chevron.svg';
import GoogleIconInfo from 'src/assets/ad-previews/google/icon-info.svg';

const defaultStyles = StyleSheet({
  container: {
    width: '97rem',
    background: 'white',
    position: 'relative',
    border: '.1rem solid #e5e5e5',
    fontFamily: 'Roboto, Arial'
  },

  info: {
    position: 'absolute',
    top: 1,
    right: 1,
    background: 'white',
    color: '#00aecd',
    width: '1.8rem',
    height: '1.8rem'
  },

  image: {
    width: '17.2rem',
    height: '9rem',
    background: 'grey no-repeat center center',
    backgroundSize: 'cover',
    position: 'relative',
    overflow: 'hidden'
  },

  wrapHeading: {
    padding: '0 1.2rem',
    background: 'rgba(243, 243, 243, 1)',
    height: '9rem',
    width: '27.6rem'
  },

  heading: {
    padding: '.2rem 0',
    fontWeight: 700,
    fontSize: '4rem',
    lineHeight: '1.1'
  },

  name: {
    padding: '.2rem 0',
    fontSize: '1.4rem',
    lineHeight: '1.2',
    fontWeight: 300
  },

  wrapDescription: {
    padding: '0 1.2rem',
    height: '9rem'
  },

  description: {
    padding: '.2rem 0',
    fontSize: '2.1rem',
    lineHeight: '1.2',
    fontWeight: 300
  },

  wrapButton: {
    height: '9rem',
    padding: '1.2rem'
  },

  wrapChevron: {
    width: '4rem',
    height: '4rem',
    boxShadow:
      '0 0 .2rem 0 rgba(0, 0, 0, 0.12), 0 .2rem .2rem 0 rgba(0, 0, 0, 0.24)',
    borderRadius: '4rem',
    marginRight: '1.2rem',
    color: '#919191',

    '> svg': {
      height: '1.9rem',
      width: 'auto'
    }
  }
});

const AdWordsWidePreview = (props) => {
  const { styles: s, campaignData, ad } = props;

  const isMultiListingSold = campaignData.type === 'multi_listing_sold';
  const slide =
    campaignData.slides.find((s) => s.id === ad?.attributes?.slide_id) || {};

  let imageUrl;
  let imageCrop;
  let title;
  const titleLength = 30;
  let headline;
  const headlineLength = 90;
  if (isMultiListingSold) {
    const image = campaignData.images.find((i) => i.id === slide?.image?.id);

    imageUrl = image?.sizes?.['crop.landscape']?.url;
    imageCrop = Object.values(image?.crops?.landscape?.top_left ?? {}).join();
    title = cropText(slide.short_headline, titleLength);
    headline = cropText(campaignData.description, headlineLength);
  } else {
    const isAgent = campaignData.type === 'agent_profile';

    const format = ad?.format?.id ?? '';
    const isSecondary = format.includes('responsive_secondary');

    const imageKey = isAgent
      ? 'agentImage'
      : isSecondary
      ? 'secondaryImage'
      : 'primaryImage';

    imageUrl = campaignData?.[imageKey]?.landscape;
    title = cropText(campaignData.title, titleLength);
    headline = cropText(campaignData.headline, headlineLength);
  }

  return (
    <Box {...s('container')} flexDirection='row'>
      <Box {...s('info')} justifyContent='center' alignItems='center'>
        <GoogleIconInfo {...s('infoSvg')} />
      </Box>

      <Box
        key={imageCrop}
        {...s.with('image')({
          backgroundImage: imageUrl ? `url(${imageUrl})` : 'none'
        })}
      />

      <Box {...s('wrapHeading')} flexDirection='column' justifyContent='center'>
        <Box flexDirection='column'>
          <AutoScale ratio={0.15}>
            <span {...s('heading')}>{title}</span>
            {!isMultiListingSold && (
              <span {...s('name')}>{campaignData.agency}</span>
            )}
          </AutoScale>
        </Box>
      </Box>

      <Box {...s('wrapDescription')} flex={1} alignItems='center'>
        <span {...s('description')}>{headline}</span>
      </Box>

      <Box {...s('wrapButton')} alignItems='center'>
        <Box {...s('wrapChevron')} justifyContent='center' alignItems='center'>
          <GoogleIconChevron />
        </Box>
      </Box>
    </Box>
  );
};

export default styled(defaultStyles)(AdWordsWidePreview);

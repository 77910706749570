import { useModelState } from '@rexlabs/model-generator';
import sessionModel from 'src/data/models/custom/session';

function useCapability(capability) {
  const { accounts, currentAccountId } = useModelState(sessionModel);
  const account = accounts.find((account) => account.id === currentAccountId);

  return account.capabilities[capability];
}

export default useCapability;

/*
|-------------------------------------------------------------------------------
| API Client
|-------------------------------------------------------------------------------
|
| The singleton API client is created with @rexlabs/api-client. It includes:
|  - Base URL sourced from the env config
|  - Default headers for laravel servers
|  - Middleware (interceptors) for common tasks
|
*/

import { ApiConfig, create, RexApiResponse } from '@rexlabs/api-client';
import isPlainObject from 'lodash/isPlainObject';
import config from 'src/config';
import sessionModel from 'src/data/models/custom/session';
import { store } from 'src/store';
import { Pagination } from 'src/types';

declare module 'axios' {
  interface AxiosResponse {
    pagination?: Pagination;
  }
}

const clientConfig: ApiConfig = {
  baseURL: config.API_URL
};
const api = create(clientConfig);
api.setHeader('Accept', 'application/json');
api.setHeader('Content-Type', 'application/json');
api.addResponseInterceptor((response) => {
  if (isPlainObject(response.data) && response.data.data) {
    response.pagination = response.data.pagination;
    response.data = response.data.error || response.data.data;
  }
  return response;
});

api.addResponseInterceptor((response) => {
  // Enter mainentance mode
  if (
    response.status === 503 &&
    response?.data?.error?.code === 'maintenance_mode'
  ) {
    store.dispatch(sessionModel.actionCreators.enterMaintenanceMode());
  }

  // Force logout on unauthenticated error if there's an apiToken in
  // the store.
  if (
    store.getState()?.session.ready &&
    response.status === 401 &&
    response?.data?.error?.code === 'unauthenticated' &&
    store.getState()?.session?.apiToken
  ) {
    store.dispatch(sessionModel.actionCreators.logout());
  }

  if (response.problem) {
    // We use a third party auth library on BE, which doesn't give us the power to
    // customise the format of the response sent to the client
    // response?.data?.error_description handles this case, falling back to our
    // own response format for any other errors
    const errorMessage =
      response?.data?.error_description ||
      response?.data?.error?.display_message;
    const error = new Error(errorMessage, {
      cause: response
    });

    if (__DEV__) {
      console.warn('API Error:\n\n', error);
    }

    return Promise.reject(error);
  }

  return response;
});

function setAuthToken(apiToken: string): void {
  api.setHeader('Authorization', `Bearer ${apiToken}`);
}

function setAccountId(accountId: string): void {
  api.setHeader('X-Account-Id', accountId);
}

export function isRexApiResponse(value: unknown): value is RexApiResponse {
  return typeof value === 'object' && value !== null && 'problem' in value;
}

export { api, setAuthToken, setAccountId };

import React, { PureComponent } from 'react';
import { StyleSheet } from '@rexlabs/styling';
import DefaultButton from './default';

const defaultStyles = StyleSheet({
  button: {
    fontSize: '13px',
    lineHeight: '12px',
    fontWeight: '600',
    padding: '8px 14px 11px',
    borderRadius: '3px',
    borderWidth: 0
  }
});

export default class SmallButton extends PureComponent {
  static propTypes = DefaultButton.propTypes;
  render() {
    return <DefaultButton {...this.props} styles={defaultStyles} />;
  }
}

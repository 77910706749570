import { createRestAPIModelGenerator } from 'src/data/models/generator';
export const TYPE = 'network-ad-types';

const config = {
  valueLists: {
    api: {
      fetch: () =>
        Promise.resolve({
          data: [
            {
              id: 'facebook_lead_ads',
              label: 'Facebook & Instagram - lead ads',
              tooltip:
                'Maximise lead volume using a form within Facebook to collect qualified leads.'
            },
            {
              id: 'facebook_ads',
              label: 'Facebook & Instagram - exposure ads',
              tooltip:
                'Display your ads in news feeds, instant articles, and in-messenger ads.'
            },
            {
              id: 'google',
              label: 'Popular websites',
              tooltip: 'Google Ads Network'
            }
          ]
        })
    }
  }
};

const CampaignGoalsGenerator = createRestAPIModelGenerator(TYPE, config);
export default CampaignGoalsGenerator.createValueListModel();

import { Box } from '@rexlabs/box';
import { styled, StyleSheet } from '@rexlabs/styling';
import moment from 'moment';
import React, { PureComponent } from 'react';
import { FONT } from 'src/theme';
import { Body, Tiny } from 'src/view/components/text';

import Angry from 'src/assets/icons/fb-angry.svg';
import Haha from 'src/assets/icons/fb-haha.svg';
import Like from 'src/assets/icons/fb-like.svg';
import Love from 'src/assets/icons/fb-love.svg';
import Sad from 'src/assets/icons/fb-sad.svg';
import Wow from 'src/assets/icons/fb-wow.svg';
import Oval from 'src/assets/icons/oval.svg';

const defaultStyles = StyleSheet({
  container: {
    margin: ({ token }) => token('spacing.m')
  },

  activity: {
    fontSize: '1.8rem',
    paddingBottom: '0',

    '> span svg': {
      width: ({ token }) => token('spacing.m'),
      marginLeft: ({ token }) => token('spacing.xxs'),
      marginRight: ({ token }) => token('spacing.xxs')
    }
  },

  subActivity: {
    color: ({ token }) => token('legacy.color.blue.grey')
  },

  oval: {
    marginLeft: ({ token }) => token('spacing.xs'),
    marginRight: ({ token }) => token('spacing.xs'),
    marginBottom: ({ token }) => token('spacing.xxs'),
    fill: ({ token }) => token('legacy.color.blue.grey')
  }
});

@styled(defaultStyles)
class FacebookActivityItem extends PureComponent {
  activityMap = {
    reaction_haha: (
      <span>
        reacted <Haha /> to your ad!
      </span>
    ),
    reaction_love: (
      <span>
        reacted <Love /> to your ad!
      </span>
    ),
    reaction_wow: (
      <span>
        reacted <Wow /> to your ad!
      </span>
    ),
    reaction_sad: (
      <span>
        reacted <Sad /> to your ad!
      </span>
    ),
    reaction_angry: (
      <span>
        reacted <Angry /> to your ad!
      </span>
    ),
    reaction_like: (
      <span>
        reacted <Like /> to your ad!
      </span>
    ),
    reaction_none: <span>reacted to your ad!</span>,
    comment: (
      <span>
        <strong>commented</strong> on your ad!
      </span>
    ),
    share: (
      <span>
        <strong>shared</strong> your ad
      </span>
    )
  };

  activityDescription(typeId) {
    return this.activityMap[typeId];
  }

  render() {
    const { activity, styles: s } = this.props;

    return (
      <Box {...s('container')}>
        <Body {...s('activity')}>
          {activity.name} {this.activityDescription(activity.type.id)}
        </Body>
        <Tiny {...s('subActivity')}>
          {moment(activity.created_at).format('D MMM YYYY')}
          <Oval {...s('oval')} />
          <a
            style={{ fontWeight: FONT.WEIGHTS.NORMAL }}
            href={activity.url}
            target='_blank'
            rel='noopener noreferrer'
          >
            {activity.type.id === 'comment' ? 'Respond' : 'View'}
          </a>
        </Tiny>
      </Box>
    );
  }
}

export default FacebookActivityItem;

import React from 'react';
import PropTypes from 'prop-types';
import MobileBanner, { types } from './mobile-banner';

LargeMobileBanner.propTypes = {
  bannerAdText: PropTypes.string,
  shortHeadline: PropTypes.string,
  brandColor: PropTypes.string,
  backgroundColor: PropTypes.string,
  primaryImageUrls: PropTypes.object
};
export default function LargeMobileBanner(props) {
  return <MobileBanner {...props} type={types.LARGE} />;
}

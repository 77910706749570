import { StyleSheet, useStyles } from '@rexlabs/styling';
import React, {
  createContext,
  forwardRef,
  useCallback,
  useContext,
  useId,
  useState
} from 'react';
import type { Ref, RefAttributes } from 'react';
import { DOMAttributes, PropGetter, Merge } from 'src/types';
import { dataAttr } from 'src/utils/dom';
import { mergeRefs } from 'src/utils/react';

export type FormControlOptions = {
  isRequired?: boolean;
  isDisabled?: boolean;
  isInvalid?: boolean;
  isReadOnly?: boolean;
};

type FormControlContext = FormControlOptions & {
  label?: string;
  id?: string;
};

export type FormControlProps = JSX.IntrinsicElements['div'] &
  FormControlContext;

function useFormControlProvider(props: FormControlProps) {
  const {
    id: idProp,
    isRequired,
    isInvalid,
    isDisabled,
    isReadOnly,
    ...htmlProps
  } = props;

  // Generate all the required ids
  const uuid = useId();
  const id = idProp || `field-${uuid}`;

  const labelId = `${id}-label`;
  const feedbackId = `${id}-feedback`;
  const helpTextId = `${id}-helptext`;

  /**
   * Track whether the `FormErrorMessage` has been rendered.
   * We use this to append its id the `aria-describedby` of the `input`.
   */
  const [hasFeedbackText, setHasFeedbackText] = useState(false);

  /**
   * Track whether the `FormHelperText` has been rendered.
   * We use this to append its id the `aria-describedby` of the `input`.
   */
  const [hasHelpText, setHasHelpText] = useState(false);

  // Track whether the form element (e.g, `input`) has focus.
  const [isFocused, setFocus] = useState(false);

  const getHelpTextProps = useCallback(
    <P extends Record<string, unknown>, R extends DOMAttributes>(
      props: Merge<DOMAttributes, P>,
      forwardedRef: Ref<any> = null
    ): R & RefAttributes<any> =>
      ({
        id: helpTextId,
        ...props,
        /**
         * Notify the field context when the help text is rendered on screen,
         * so we can apply the correct `aria-describedby` to the field (e.g. input, textarea).
         */
        ref: mergeRefs(forwardedRef, (node) => {
          if (!node) return;
          setHasHelpText(true);
        })
      } as unknown as R & RefAttributes<any>),
    [helpTextId]
  );

  const getLabelProps = useCallback<PropGetter>(
    (props = {}, forwardedRef = null) => ({
      ...props,
      ref: forwardedRef,
      'data-focus': dataAttr(isFocused),
      'data-disabled': dataAttr(isDisabled),
      'data-invalid': dataAttr(isInvalid),
      'data-readonly': dataAttr(isReadOnly),
      id: props.id ?? labelId,
      htmlFor: props.htmlFor ?? id
    }),
    [id, isDisabled, isFocused, isInvalid, isReadOnly, labelId]
  );

  const getErrorMessageProps = useCallback<PropGetter<unknown, unknown>>(
    (props = {}, forwardedRef = null) => ({
      id: feedbackId,
      ...props,
      /**
       * Notify the field context when the error message is rendered on screen,
       * so we can apply the correct `aria-describedby` to the field (e.g. input, textarea).
       */
      ref: mergeRefs(forwardedRef, (node) => {
        if (!node) return;
        setHasFeedbackText(true);
      }),
      'aria-live': 'polite'
    }),
    [feedbackId]
  );

  const getRootProps = useCallback<PropGetter>(
    (props = {}, forwardedRef = null) => ({
      ...props,
      ...htmlProps,
      ref: forwardedRef,
      role: 'group'
    }),
    [htmlProps]
  );

  const getRequiredIndicatorProps = useCallback<PropGetter>(
    (props = {}, forwardedRef = null) => ({
      ...props,
      ref: forwardedRef,
      role: 'presentation',
      'aria-hidden': true,
      children: props.children || '*'
    }),
    []
  );

  return {
    isRequired: !!isRequired,
    isInvalid: !!isInvalid,
    isReadOnly: !!isReadOnly,
    isDisabled: !!isDisabled,
    isFocused: !!isFocused,
    onFocus: () => setFocus(true),
    onBlur: () => setFocus(false),
    hasFeedbackText,
    setHasFeedbackText,
    hasHelpText,
    setHasHelpText,
    id,
    labelId,
    feedbackId,
    helpTextId,
    htmlProps,
    getHelpTextProps,
    getErrorMessageProps,
    getRootProps,
    getLabelProps,
    getRequiredIndicatorProps
  };
}

type FormControlProviderContext = Omit<
  ReturnType<typeof useFormControlProvider>,
  'getRootProps' | 'htmlProps'
>;

const formControlContext = createContext<
  FormControlProviderContext | undefined
>(undefined);

const FormControlProvider = formControlContext.Provider;

export function useFormControlContext():
  | FormControlProviderContext
  | undefined {
  return useContext(formControlContext);
}

const styles = StyleSheet({
  container: {
    width: '100%',
    position: 'relative'
  }
});

export const FormControl = forwardRef<HTMLDivElement, FormControlProps>(
  (props, ref) => {
    const s = useStyles(styles);
    const {
      getRootProps,
      htmlProps: { className, style },
      ...context
    } = useFormControlProvider(props);

    return (
      <FormControlProvider value={context}>
        <div
          {...getRootProps({}, ref)}
          {...s.with('container')({ className, style })}
        />
      </FormControlProvider>
    );
  }
);

import React, { FC } from 'react';
import type {
  AttributionControlProps,
  FullscreenControlProps,
  GeolocateControlProps,
  NavigationControlProps,
  ScaleControlProps
} from 'react-map-gl';
import {
  FullscreenControl as MapGLFullscreenControl,
  AttributionControl as MapGLAttributionControl,
  GeolocateControl as MapGLGeolocateControl,
  NavigationControl as MapGLNavigationControl,
  ScaleControl as MapGLScaleControl
} from 'react-map-gl';

export const FullscreenControl: FC<FullscreenControlProps> = ({
  style,
  ...props
}) => <MapGLFullscreenControl {...props} style={{ ...style, zIndex: 0 }} />;

export const AttributionControl: FC<AttributionControlProps> = ({
  style,
  ...props
}) => <MapGLAttributionControl {...props} style={{ ...style, zIndex: 0 }} />;

export const GeolocateControl: FC<GeolocateControlProps> = ({
  style,
  ...props
}) => <MapGLGeolocateControl {...props} style={{ ...style, zIndex: 0 }} />;

export const NavigationControl: FC<NavigationControlProps> = ({
  style,
  ...props
}) => <MapGLNavigationControl {...props} style={{ ...style, zIndex: 0 }} />;

export const ScaleControl: FC<ScaleControlProps> = ({ style, ...props }) => (
  <MapGLScaleControl {...props} style={{ ...style, zIndex: 0 }} />
);

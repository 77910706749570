/* eslint-disable max-lines */
import React, { PureComponent } from 'react';
import Box from '@rexlabs/box';
import { styled, StyleSheet } from '@rexlabs/styling';
import { autobind } from 'core-decorators';
import { COLORS } from 'src/theme';
import _get from 'lodash/get';

import Container from '../shared/container';
import CarouselAdImage from './carousel-ad-image';
import CarouselBrand from '../listing/carousel-brand';

const CONTAINER_WIDTH = 476;
const SLIDE_WIDTH = 300;
const SLIDE_PADDING = 8;

const defaultStyles = StyleSheet({
  container: {
    width: '100%',
    marginRight: '-1.2rem',
    padding: '0 1.2rem',
    position: 'relative',
    overflow: 'hidden',
    fontFamily: 'Helvetica, Arial, sans-serif',
    color: '#1d2129',
    fontWeight: 'normal'
  },

  wrapPicture: {
    width: '100%',
    paddingBottom: '100%',
    background: 'none no-repeat center center',
    backgroundColor: COLORS.GREY_LIGHT,
    backgroundSize: 'cover',
    overflow: 'hidden',
    position: 'relative'
  },

  wrapText: {
    width: '100%',
    background: '#f6f7f9',
    padding: '1.2rem',
    whiteSpace: 'normal'
  },

  wrapTextDetails: {
    maxWidth: '80%',
    height: '35px',
    overflow: 'hidden',
    marginRight: '1.2rem'
  },

  description: {
    fontSize: '1.2rem',
    lineHeight: '1.8rem',
    fontWeight: 'bold',
    margin: '.1rem 0',
    height: '3.6rem',
    color: '#000',
    padding: 0,
    WebkitLineClamp: 2,
    overflow: 'hidden',
    paddingRight: '.4rem'
  },

  learnMore: {
    border: '.1rem solid #ced0d4',
    background: '#f6f7f9',
    color: '#4b4f56',
    borderRadius: '.2rem',
    padding: '0 .8rem',
    fontSize: '1.2rem',
    lineHeight: '2.2rem',
    marginTop: '.2rem',
    whiteSpace: 'nowrap',
    textShadow: 'none',
    verticalAlign: 'middle',
    fontWeight: 'bold',
    boxSizing: 'content-box'
  },

  carousel: {
    width: '1000000px',
    position: 'relative',
    transition: 'transform .4s',
    display: 'inline-block',
    height: '100%',
    marginLeft: '-.8rem',
    whiteSpace: 'nowrap'
  },

  carouselItem: {
    position: 'relative',
    display: 'inline-block',
    paddingLeft: '.8rem',
    paddingBottom: '.8rem',
    transition: 'opacity .3s',
    opacity: 1
  },

  carouselItemInner: {
    width: SLIDE_WIDTH,
    border: '.1rem solid #d8d8d8',
    overflow: 'hidden'
  },

  carouselPrev: {
    position: 'absolute',
    top: '50%',
    left: 0,
    width: '5rem',
    height: '5rem',
    marginTop: '-2.5rem',
    zIndex: 40,
    transition: 'opacity .3s',
    opacity: 0.8,
    border: '.1rem solid #d8d8d8',
    background: 'white',
    color: '#d8d8d8',
    fontSize: '2.4rem',
    cursor: 'pointer',

    ':hover': {
      opacity: 1
    }
  },

  carouselNext: {
    position: 'absolute',
    top: '50%',
    right: 0,
    width: '5rem',
    height: '5rem',
    marginTop: '-2.5rem',
    zIndex: 40,
    transition: 'opacity .3s',
    opacity: 0.8,
    border: '.1rem solid #d8d8d8',
    background: 'white',
    color: '#d8d8d8',
    fontSize: '2.4rem',
    cursor: 'pointer',

    ':hover': {
      opacity: 1
    }
  },

  disabled: {
    opacity: '0 !important'
  },

  title: {
    fontSize: '1.2rem',
    wordWrap: 'break-word',
    fontWeight: 600,
    padding: 0,
    fontFamily:
      'system-ui, -apple-system, BlinkMacSystemFont, ".SFNSText-Regular", sans-serif'
  },

  headline: {
    fontSize: '1.3rem',
    fontWeight: 500,
    padding: 0,
    fontFamily:
      'system-ui, -apple-system, BlinkMacSystemFont, ".SFNSText-Regular", sans-serif'
  }
});

@styled(defaultStyles)
@autobind
class FacebookCarousel extends PureComponent {
  constructor(props) {
    super(props);
    this.adContainer = React.createRef();
    this.state = {
      curr: 0,
      adWrapperDimensions: { width: 0, height: 0 }
    };
  }

  componentDidMount() {
    const width = _get(this.adContainer, 'current.parentElement.offsetWidth');
    if (!width) return;
    this.setState({ adWrapperDimensions: { width, height: width } });
  }

  slidePrev() {
    const { curr } = this.state;
    this.setState({
      curr: curr > 0 ? curr - 1 : 0
    });
  }

  slideNext() {
    const { adContentSet } = this.props;
    const { curr } = this.state;
    const slideCnt = _get(adContentSet, 'slides', []).length || 3;

    this.setState({
      curr: curr < slideCnt - 1 ? curr + 1 : slideCnt - 1
    });
  }

  getCarouselPosition() {
    const { adContentSet } = this.props;
    const { curr } = this.state;
    const slideCnt = _get(adContentSet, 'slides', []).length || 3;

    let currX =
      (CONTAINER_WIDTH - SLIDE_WIDTH - 8) / 2 -
      curr * (SLIDE_WIDTH + SLIDE_PADDING);

    const min = 0;
    const max =
      CONTAINER_WIDTH -
      (SLIDE_WIDTH * slideCnt + SLIDE_PADDING * (slideCnt - 1));

    if (currX > min) currX = min;
    if (currX < max) currX = max;

    return currX;
  }

  render() {
    const {
      styles: s,
      adContentSet,
      disableWrapper,
      variation,
      slideNumber
    } = this.props;
    const { curr, adWrapperDimensions } = this.state;

    const slides = adContentSet.slides;

    if (disableWrapper) {
      if (variation === 'listing') {
        const slide = adContentSet.slides.filter((s) => s.type === 'listing')[
          slideNumber
        ];
        return (
          <CarouselAdImage
            slide={slide}
            adContentSet={adContentSet}
            scaleTo={1125}
          />
        );
      } else {
        const slide = adContentSet.slides.find((s) => s.type !== 'listing');
        const isAgent = slide.type === 'agent';
        const agentImage = adContentSet.images.find(
          (i) => i.id === _get(slide, 'image.id')
        );

        return (
          <CarouselBrand
            backgroundColor={adContentSet.backgroundColor}
            agencyLogoUrl={adContentSet.agencyLogo}
            agentImageUrl={
              isAgent && _get(agentImage, ['sizes', 'crop.square', 'url'])
            }
            adContentSet={adContentSet}
            scaleTo={1125}
          />
        );
      }
    }

    return (
      <Container {...this.props} descriptionLimit={155}>
        <Box {...s('container')}>
          <button
            type='button'
            onClick={this.slidePrev}
            {...s('carouselPrev', { disabled: curr === 0 })}
          >
            &lsaquo;
          </button>
          <button
            type='button'
            onClick={this.slideNext}
            {...s('carouselNext', {
              disabled: curr === (slides ? slides.length - 1 : 2)
            })}
          >
            &rsaquo;
          </button>
          <Box
            {...s.with('carousel')({
              transform: `translateX(${this.getCarouselPosition()}px)`
            })}
          >
            {adContentSet.slides.map((slide, idx) => {
              const isListing = slide.type === 'listing';
              const isAgent = slide.type === 'agent';
              const entityId =
                _get(slide, 'listing.id') ||
                _get(slide, 'agency.id') ||
                _get(slide, 'agent.id');
              const agentImage = adContentSet.images.find(
                (i) => i.id === _get(slide, 'image.id')
              );
              const agentCrop = Object.values(
                _get(agentImage, 'crops.square.top_left', {})
              ).join();

              return (
                <Box key={entityId} {...s('carouselItem')}>
                  <Box {...s('carouselItemInner')}>
                    <div
                      ref={idx === 0 ? this.adContainer : undefined}
                      style={adWrapperDimensions}
                    >
                      {isListing ? (
                        <CarouselAdImage
                          slide={slide}
                          adContentSet={adContentSet}
                          scaleTo={adWrapperDimensions.width}
                        />
                      ) : (
                        <CarouselBrand
                          key={agentCrop}
                          backgroundColor={adContentSet.backgroundColor}
                          agencyLogoUrl={adContentSet.agencyLogo}
                          agentImageUrl={
                            isAgent &&
                            _get(agentImage, ['sizes', 'crop.square', 'url'])
                          }
                          adContentSet={adContentSet}
                          scaleTo={adWrapperDimensions.width}
                        />
                      )}
                    </div>

                    <Box
                      flexDirection='row'
                      justifyContent='space-between'
                      {...s('wrapText')}
                    >
                      <Box {...s('wrapTextDetails')}>
                        <h1 {...s('title')}>{slide.short_headline}</h1>
                        {isListing && (
                          <span {...s('headline')}>{slide.long_headline}</span>
                        )}
                      </Box>
                      <Box alignItems='center'>
                        <button {...s('learnMore')}>Learn More</button>
                      </Box>
                    </Box>
                  </Box>
                </Box>
              );
            })}
          </Box>
        </Box>
      </Container>
    );
  }
}

export default FacebookCarousel;

import { Box } from '@rexlabs/box';
import { styled, StyleSheet } from '@rexlabs/styling';
import React, { PureComponent } from 'react';
import { FONT, withToken } from 'src/theme';

const defaultStyles = StyleSheet({
  container: {
    fontSize: '1.4rem',
    fontWeight: FONT.WEIGHTS.SEMIBOLD,
    color: ({ token }) => token('legacy.color.blue.grey'),
    borderBottom: ({ token }) =>
      `.1rem solid ${token('legacy.color.blue.greyLight')}`
  }
});

@withToken
@styled(defaultStyles)
class TransactionListHeader extends PureComponent {
  render() {
    const { token, styles: s } = this.props;
    return (
      <Box
        p={token('spacing.m')}
        pt={0}
        flexDirection='row'
        alignItems='center'
        justifyContent='space-between'
        {...s('container')}
      >
        <Box style={{ width: '12rem' }}>Date</Box>
        <Box style={{ width: '12rem' }}>Transaction #</Box>
        <Box style={{ width: '24rem' }}>Description</Box>
        <Box flex={1} justifyContent='flex-end' pr={token('spacing.xl')}>
          Billed
        </Box>
        <Box style={{ width: '7rem', textAlign: 'center' }}>Invoice</Box>
      </Box>
    );
  }
}

export default TransactionListHeader;

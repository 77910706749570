/* eslint-disable @typescript-eslint/no-explicit-any */
import Select from './select/default';
import WizardSelect from './select/wizard';
import CampaignDurationSelect from './select/campaign-duration';
import {
  OptionWithDescription,
  SelectedOptionWithDescription
} from './select/options/with-description';
import {
  OptionWithIcon,
  SelectedOptionWithIcon
} from './select/options/with-icon';
import Checkbox from './checkbox';
import RadioGroup from './radio-group';
import Toggle from './toggle';
import ColorPicker from './color-picker';
import DomainInput from './domain';
import TextInput from './text/text';
import SmallTextInput from './text/small';
import TextInputStyledSuffix from './text/styled-suffix';
import TextInputStyledPrefix from './text/styled-prefix';
import SoftCharLimitTextInput from './text/soft-char-limit';
import TextArea from './text-area/text-area';
import SoftCharLimitTextArea from './text-area/soft-char-limit';
import { ChangeEventHandler, FocusEventHandler } from 'react';

export interface InputMeta<T> {
  error?: any;
  focused: boolean;
  initialValue?: T;
  isValidating: boolean;
  touched: boolean;
}

export interface InputActions {
  reset: (...args: any[]) => any;
  setError: (...args: any[]) => any;
  setIsValidating: (...args: any[]) => any;
  setTouched: (...args: any[]) => any;
  setValue: (...args: any[]) => any;
  validate: (...args: any[]) => any;
}

export interface InputProps<T> {
  name: string;
  id: string;
  value?: T;
  onBlur: FocusEventHandler;
  onChange: ChangeEventHandler;
  onFocus: FocusEventHandler;
  meta: InputMeta<T>;
  actions: InputActions;
}

export {
  TextInput,
  SmallTextInput,
  TextInputStyledSuffix,
  TextInputStyledPrefix,
  SoftCharLimitTextInput,
  TextArea,
  SoftCharLimitTextArea,
  Select,
  WizardSelect,
  CampaignDurationSelect,
  OptionWithDescription,
  SelectedOptionWithDescription,
  OptionWithIcon,
  SelectedOptionWithIcon,
  Checkbox,
  RadioGroup,
  Toggle,
  ColorPicker,
  DomainInput
};

import { StyleSheet, useStyles } from '@rexlabs/styling';
import { Tiny } from '@rexlabs/text';
import React, { FC } from 'react';

import MeterFace from '../assets/meter-face.svg';
import MeterNeedle from '../assets/meter-needle.svg';

const styles = StyleSheet({
  meter: {
    position: 'relative',
    display: 'flex',
    justifyContent: 'center'
  },

  needleWrapper: {
    position: 'absolute',
    top: 0,
    width: '100%',
    height: '100%'
  },

  needle: {
    position: 'absolute',
    bottom: 0,
    right: '50%',
    transformOrigin: 'right',
    color: '#002842',
    transition: 'transform 1s ease'
  },

  labels: {
    display: 'flex',
    justifyContent: 'center'
  },

  label: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center'
  },

  titleSpecific: {
    marginRight: '73.5px',
    justifyContent: 'center'
  },

  titleBroad: {
    justifyContent: 'center'
  },

  tinyTitle: {
    fontSize: '1.4rem',
    lineHeight: '1.8rem',
    fontWeight: ({ token }) => token('typography.weight.light'),
    letterSpacing: '-.02rem',
    paddingBottom: 0
  },

  tinyDescription: {
    fontSize: '1.4rem',
    lineHeight: '1.8rem',
    fontWeight: ({ token }) => token('typography.weight.light'),
    letterSpacing: '-.02rem',
    paddingTop: 0
  }
});

export type AudienceSizeGaugeProps = {
  value: number;
};

export const AudienceSizeGauge: FC<AudienceSizeGaugeProps> = ({ value }) => {
  const clampedValue = Math.max(0, Math.min(value, 180));
  const s = useStyles(styles, 'AudienceSizeGauge');

  return (
    <div>
      <div {...s('meter')}>
        <MeterFace />
        <div {...s('needleWrapper')}>
          <MeterNeedle
            {...s.with('needle')({
              transform: `rotate(${clampedValue}deg)`
            })}
          />
        </div>
      </div>
      <div {...s('labels')}>
        <div {...s('label', 'titleSpecific')}>
          <Tiny as='span' {...s('tinyTitle')}>
            Specific
          </Tiny>
          <Tiny grey as='span' {...s('tinyDescription')}>
            Too small
          </Tiny>
        </div>
        <div {...s('label', 'titleBroad')}>
          <Tiny as='span' {...s('tinyTitle')}>
            Broad
          </Tiny>
          <Tiny grey as='span' {...s('tinyDescription')}>
            Too large
          </Tiny>
        </div>
      </div>
    </div>
  );
};

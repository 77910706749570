import { Box } from '@rexlabs/box';
import { styled, StyleSheet, useToken } from '@rexlabs/styling';
import React from 'react';
import ROUTES from 'src/routes/admin';
import BackLink from 'src/view/components/back-link';

const defaultStyles = StyleSheet({
  sidebar: {
    width: '24rem',
    paddingRight: ({ token }) => token('spacing.xxxl')
  },

  sidebarHeading: {
    display: 'block',
    color: ({ token }) => token('legacy.color.blue.dark'),
    borderBottom: ({ token }) =>
      `.1rem solid ${token('legacy.color.blue.greyLight')}`,
    paddingBottom: ({ token }) => token('spacing.xs'),
    marginBottom: ({ token }) => token('spacing.xs'),
    fontSize: '1.8rem',
    letterSpacing: '-.04rem',
    lineHeight: '2.4rem'
  }
});

function Sidebar(props) {
  const { styles: s, children, title } = props;
  const token = useToken();

  return (
    <Box {...s('sidebar')}>
      <span {...s('sidebarHeading')}>{title}</span>
      <ul>
        {React.Children.map(children, (child) => (
          <li>{child}</li>
        ))}
      </ul>
      <Box mt={token('spacing.xxxl')}>
        <BackLink to={ROUTES.CAMPAIGNS}>Back to campaigns</BackLink>
      </Box>
    </Box>
  );
}

export default styled(defaultStyles)(Sidebar);

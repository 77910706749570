/* eslint-disable max-lines */
import { Box } from '@rexlabs/box';
import { GhostButton, PrimaryButton } from '@rexlabs/button';
import {
  query,
  useModelState,
  withModel,
  withQuery
} from '@rexlabs/model-generator';
import { useStyles, useToken } from '@rexlabs/styling';
import sessionModel from 'data/models/custom/session';
import agenciesModel from 'data/models/entities/agencies';
import agentsModel from 'data/models/entities/agents';
import campaignsModel from 'data/models/entities/campaigns';
import listingsModel from 'data/models/entities/listings';
import React, { useEffect, useRef, useState } from 'react';
import { compose } from 'redux';
import { useFeature } from 'shared/hooks';
import ChevronLeft from 'src/assets/icons/chevron-left.svg';
import ChevronRight from 'src/assets/icons/chevron-right.svg';
import WIZARD from 'src/routes/wizard';
import { formattedBrandName } from 'src/theme';
import AgentModal from 'src/view/modals/admin/create-agent';
import { Link, replace } from 'utils/whereabouts';
import { StickyButtonGroup } from 'view/components/button';
import EmptyStateSection from 'view/components/empty-state-section';
import { ListingListItem } from 'view/components/list';
import { RenderLoading } from 'view/components/loading';
import ScrollableContent from 'view/components/scrollable-content';
import SearchListings from 'view/components/search-listings';
import { Body, Heading } from 'view/components/text';
import { HelpIcon, TooltipStateful } from 'view/components/tooltip';
import withError from 'view/containers/with-error';
import ConfirmFlushModal from 'view/modals/admin/confirm-flush';
import { validate } from 'view/modals/admin/create-agent';
import EditAgencyModal from 'view/modals/admin/settings/edit-agency';
import EditLocationPin from 'view/modals/wizard/edit-location-pin';
import { sharedStyles } from '../shared';

export function getFilters(props) {
  const filters = [];
  const slug = props?.wizard?.slug;
  const type = slug === 'rental-listing' ? 'rental' : 'sale';
  const search = (props?.whereabouts?.query ?? {})['listings.search'];

  if (type) filters.push(`sale_or_rental.eq(${type})`);
  if (slug === 'multi-listing-sold')
    filters.push(`listing_status.in(current,sold)`);
  if (search) filters.push(`address.match(*${search}*)`);

  return filters.join(',');
}

const getToken = () => new URLSearchParams(window.location.search).get('token');

export const queryListings = query`{
  ${listingsModel} (q: ${getFilters}, campaignToken: ${getToken}) {
    id
    marketing_headline
    sold_at
    address {
      admin_area_3
      full_address
      longitude
      latitude
    }
    thumbnails
    updated_at
    data_source {
      id
      label
    }
    agents
    formatted_address {
      short
    }
  }
}`;

const getCampaignId = (props) => props?.match?.params?.campaignId;

const queryCampaign = query`{
  ${campaignsModel} (id: ${getCampaignId}) {
    id
    name
    content_source {
      listings
    }
  }
}`;

const queryAgency = query`{
  ${agenciesModel} {
    id
    website_privacy_url
  }
}`;

const SelectListingScreen = ({
  campaignTemplateId,
  campaigns,
  listings,
  agencies,
  match,
  error,
  error: { Error },
  wizard: { set, type, goal, slug, step, selectedEntities }
}) => {
  const session = useModelState(sessionModel);
  const canUseFacebookLeadAds = useFeature('facebook_lead_ads', session);

  const [loading, setLoading] = useState(false);
  const [showModal, setShowModal] = useState(null);
  const [agentErrors, setAgentErrors] = useState({});
  const [updatingListing, setUpdatingListing] = useState(false);

  const confirmedFlush = useRef(false);
  const validateAgent = useRef(true);
  const lngLatUpdated = useRef(false);
  const privacyUrlUpdate = useRef(false);

  const token = useToken();
  const s = useStyles({}, undefined, sharedStyles);

  const selectedEntityId = selectedEntities.listings?.[0]?.id;
  const crmToken = getToken();
  // There will be a currentListing if the campaign has already been created
  const currentListing =
    campaigns?.item?.data?.content_source?.listings?.data?.[0] ?? {};
  const firstListing = listings?.list?.items?.[0] ?? {};
  const campaignId = match?.params?.campaignId;
  const { accounts, currentAccountId } = session;
  const account = accounts.find((account) => account.id === currentAccountId);
  const accountAddress = account?.agencies?.data?.[0]?.address;
  const accountLngLat = [accountAddress.longitude, accountAddress.latitude];

  const selectedListing = listings.list.items.find(
    (listing) => listing.id === selectedEntityId
  );

  const validateListingAgent = () => {
    if (validateAgent.current) {
      const listingAgent = selectedListing?.agents?.data?.[0];
      const agentErrors = validate(listingAgent);

      if (listingAgent && Object.keys(agentErrors).length > 0) {
        setLoading(false);
        setShowModal('agent');
        setAgentErrors(agentErrors);

        return true;
      }
    }
    return false;
  };

  const validateAddressLngLat = () => {
    if (lngLatUpdated.current) return false;

    const latitude = parseFloat(selectedListing.address.latitude);
    const longitude = parseFloat(selectedListing.address.longitude);

    if (
      !isNaN(latitude) &&
      latitude !== 0 &&
      !isNaN(longitude) &&
      longitude !== 0
    ) {
      return false;
    }

    setLoading(false);
    setShowModal('location');

    return true;
  };

  const validateAgencyPrivacyUrl = () => {
    if (
      !canUseFacebookLeadAds ||
      privacyUrlUpdate.current ||
      slug !== 'listing'
    )
      return false;

    const hasPrivacyUrl = !!agencies?.list?.items?.[0]?.website_privacy_url;
    if (!hasPrivacyUrl) {
      setShowModal('agency');
      return true;
    }

    return false;
  };

  const handleSave = async () => {
    if (
      currentListing.id &&
      currentListing.id !== selectedListing.id &&
      !confirmedFlush.current
    ) {
      setShowModal('flush');
      return;
    }

    if (validateAddressLngLat()) return;
    if (validateListingAgent()) return;
    if (validateAgencyPrivacyUrl()) return;

    if (campaignId) {
      setLoading(true);

      try {
        await campaigns.updateItem({
          id: campaignId,
          data: {
            type: undefined,
            content_source: {
              listings: [{ id: selectedEntityId }]
            }
          }
        });
        await campaigns.refreshItem({
          id: campaignId,
          args: {
            include: 'ad_content_sets,audience_specs,images.sizes,thumbnails'
          }
        });
      } catch (e) {
        setLoading(false);
        return error.open(e.message);
      }
    } else {
      setLoading(true);

      try {
        const newCampaign = await campaigns.createItem({
          data: {
            type: { id: type },
            goal: { id: goal },
            content_source: {
              listings: [{ id: selectedEntityId }]
            },
            campaign_template_configuration: { id: campaignTemplateId },
            ...(crmToken && { crmToken })
          }
        });

        if (!newCampaign.data.id) {
          throw new Error('No ID found in API response!');
        }

        replace(WIZARD.CAMPAIGN, {
          params: { campaignType: slug, campaignId: newCampaign.data.id }
        });
      } catch (e) {
        setLoading(false);
        return error.open(e.message);
      }
    }

    set({ step: step + 1 });
  };

  const updateListingAddressLngLat = async (lngLat) => {
    setUpdatingListing(true);

    try {
      await listings.updateItem({
        id: selectedListing.id,
        data: {
          address: {
            longitude: lngLat[0],
            latitude: lngLat[1]
          }
        }
      });

      lngLatUpdated.current = true;
      setUpdatingListing(false);
      setShowModal(null);
      handleSave();
    } catch (e) {
      setUpdatingListing(false);
      error.open(e);
    }
  };

  const closeAgentModal = () => {
    setShowModal(null);
    validateAgent.current = false;
    handleSave();
  };

  useEffect(() => {
    // If the campaign has already been created we want to pre-select it's listing
    if (currentListing.id) {
      set({
        selectedEntityId: currentListing.id
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentListing.id]);

  useEffect(() => {
    // if we have a token and first listing we want to pre-select it
    if (crmToken && firstListing.id) {
      set({ selectedEntityId: firstListing.id });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [crmToken, firstListing.id]);

  const renderCrmListings = () => {
    return (
      <>
        <Heading>Advertise your listing</Heading>
        <Body grey style={{ width: '80%', marginBottom: token('spacing.xl') }}>
          You are about to create a Listing Campaign for the listing below
        </Body>

        <RenderLoading
          isLoading={!listings.list || listings.list.status === 'loading'}
        >
          {firstListing.id ? (
            <ListingListItem
              selected={true}
              onClick={() =>
                set({
                  selectedEntityId: firstListing.id
                })
              }
              data={firstListing}
            />
          ) : (
            <EmptyStateSection
              key='EmptyStateSection'
              largeText='Listing not found.'
              smallText={
                <div>
                  To get started{' '}
                  <Link
                    to={WIZARD.CAMPAIGNS}
                    params={{ campaignType: 'listing' }}
                  >
                    select an existing listing
                  </Link>{' '}
                  or create a new one.
                </div>
              }
            />
          )}
        </RenderLoading>
      </>
    );
  };

  const renderListings = () => {
    return (
      <>
        <Heading>
          Choose a {slug === 'rental-listing' ? 'rental' : ''} listing you’d
          like to advertise
        </Heading>
        <Body grey style={{ width: '80%' }}>
          {formattedBrandName} will build a marketing campaign for the listing
          you select.{' '}
          <HelpIcon
            maxWidth='40rem'
            Content={() => (
              <Box style={{ width: '30rem' }}>
                <Body>
                  {formattedBrandName} will remember each listing you create, so
                  you can start a new campaign faster next time.
                </Body>
              </Box>
            )}
          />
        </Body>

        <SearchListings
          listings={listings}
          loading={loading}
          updatingListing={updatingListing}
        />
      </>
    );
  };

  return (
    <Box flex={1} flexDirection='column'>
      <ScrollableContent {...s('content')}>
        {crmToken ? renderCrmListings() : renderListings()}
      </ScrollableContent>

      <StickyButtonGroup
        styles={crmToken && { container: { justifyContent: 'flex-end' } }}
      >
        {!crmToken && (
          <Link to={WIZARD.CREATE}>
            {({ onClick }) => (
              <Box flex={1}>
                <GhostButton IconLeft={ChevronLeft} onClick={onClick}>
                  Back
                </GhostButton>
              </Box>
            )}
          </Link>
        )}
        <TooltipStateful
          show={!selectedEntityId}
          placement='top'
          Content={() => (
            <Box style={{ width: '17rem' }}>
              <p>Please select a listing first!</p>
            </Box>
          )}
        >
          <PrimaryButton
            IconRight={ChevronRight}
            onClick={selectedEntityId && handleSave}
            isDisabled={!selectedEntityId}
            isLoading={loading}
          >
            Next
          </PrimaryButton>
        </TooltipStateful>
      </StickyButtonGroup>

      {showModal === 'flush' && (
        <ConfirmFlushModal
          key='confirmFlushModal'
          closeModal={(isFlushing) => {
            setShowModal(null);
            confirmedFlush.current = isFlushing;
            if (isFlushing) {
              handleSave();
            }
          }}
          campaigns={campaigns}
          type={type}
        />
      )}

      {showModal === 'agency' && (
        <EditAgencyModal
          title='Oops, we need some more information about your agency'
          closeModal={() => setShowModal(null)}
          onSubmitSuccess={() => {
            privacyUrlUpdate.current = true;
            handleSave();
          }}
        />
      )}

      {showModal === 'agent' && (
        <AgentModal
          key='agentModal'
          agent={selectedListing?.agents?.data?.[0]}
          agentErrors={agentErrors}
          skipButton={true}
          closeModal={closeAgentModal}
        />
      )}

      {showModal === 'location' && (
        <EditLocationPin
          title='Missing exact address location'
          body={`${selectedListing.formatted_address.short} is missing an exact pin location. Move the pin below to set the exact location of the listing`}
          lngLat={accountLngLat}
          isLoading={updatingListing}
          handleSubmit={(lngLat) => updateListingAddressLngLat(lngLat)}
          closeModal={() => setShowModal(null)}
        />
      )}
      <Error />
    </Box>
  );
};

export default compose(
  withError(),
  withModel(agentsModel),
  withQuery(queryCampaign),
  withQuery(queryListings),
  withQuery(queryAgency)
)(SelectListingScreen);

import { createRestAPIModelGenerator } from 'src/data/models/generator';
import { api } from 'src/utils/api-client';

export const TYPE = 'packages';

const config = {
  entities: {
    api: {
      fetchList: (type, args) =>
        api.get(`/campaigns/${args.campaignId}/quotes/packages`)
    }
  }
};

const PackagesGenerator = createRestAPIModelGenerator(TYPE, config);
export default PackagesGenerator.createEntityModel();

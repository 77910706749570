import React from 'react';
import PropTypes from 'prop-types';
import Leaderboard, { types } from './leaderboard';

LargeLeaderboard.propTypes = {
  bannerAdText: PropTypes.string,
  shortHeadline: PropTypes.string,
  butonText: PropTypes.string,
  brandColor: PropTypes.string,
  backgroundColor: PropTypes.string,
  primaryImageUrls: PropTypes.object,
  logoUrls: PropTypes.object
};

export default function LargeLeaderboard(props) {
  return <Leaderboard {...props} type={types.LARGE} />;
}

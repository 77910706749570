import CampaignStatus from './status';
import CampaignProgress from './progress';
import CampaignPreview from './preview';

export const STATUS = {
  ACTIVE: 'active',
  ARCHIVED: 'archived',
  EXPIRING: 'expiring',
  PAUSED: 'paused',
  DRAFT: 'draft',
  ERROR: 'failed',
  IN_REVIEW: 'in_review',
  COMPLETED: 'completed',
  SCHEDULED: 'scheduled',
  PROCESSING: 'processing'
};

export const STATUS_STYLES = {
  statusActive: 'active',
  statusArchived: 'archived',
  statusExpiring: 'expiring',
  statusPaused: 'paused',
  statusDraft: 'draft',
  statusError: 'error',
  statusInReview: 'in_review',
  statusCompleted: 'completed',
  statusScheduled: 'scheduled',
  statusProcessing: 'processing'
};

export { CampaignProgress, CampaignStatus, CampaignPreview };

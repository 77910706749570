import React, { FC } from 'react';
import { ContentTypeEditorProps } from 'src/features/wizard';
import { CommercialListingCreateForm } from './details-form/CommercialListingCreateForm';
import { CommercialListingEditForm } from './details-form/CommercialListingEditForm';

export const CommercialListingDetailsEditor: FC<ContentTypeEditorProps> = ({
  contentId,
  selectContent
}) => {
  return contentId ? (
    <CommercialListingEditForm
      contentId={contentId}
      selectContent={selectContent}
    />
  ) : (
    <CommercialListingCreateForm selectContent={selectContent} />
  );
};

import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';

export default class Arrow extends PureComponent {
  static propTypes = {
    color: PropTypes.string
  };

  static defaultProps = {
    color: '#00000'
  };

  render() {
    const { color, ...rest } = this.props;
    return (
      <svg {...rest} xmlns='http://www.w3.org/2000/svg' viewBox='0 0 14 14'>
        <path
          fill={color}
          fillRule='evenodd'
          d='M6.68 0l6.68 6.68-6.68 6.68-1.18-1.18 4.66-4.68H0V5.86h10.16L5.5 1.18z'
        />
      </svg>
    );
  }
}

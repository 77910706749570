import { Box } from '@rexlabs/box';
import {
  RenderMatchedRoutes,
  withWhereabouts
} from '@rexlabs/react-whereabouts';
import React from 'react';
import ROUTES from 'src/routes/embed';
import { replace } from 'src/utils/whereabouts';

function IntroLayout({ whereabouts }) {
  return (
    <Box flex={1} flexDirection='column' h='100%' w='100%'>
      <RenderMatchedRoutes
        routes={ROUTES}
        NotFound={() => {
          // Redirect any unknown routes to the login page
          // No 404 page!
          const check = /\/[0-9]+\/campaigns/;
          replace(ROUTES.LOGIN, {
            query:
              whereabouts.path &&
              whereabouts.path !== '/' &&
              !check.exec(whereabouts.path)
                ? { redirect: whereabouts.path }
                : undefined,
            hash: ''
          });
          return null;
        }}
      >
        {(matches) => <Box flex={1}>{matches}</Box>}
      </RenderMatchedRoutes>
    </Box>
  );
}

export default withWhereabouts()(IntroLayout);

/**
 * Convert hex-color to YIQ color space and use YIQ to determine the contrast color
 * @param  string hexColor
 * @param integer greyScale - 0 (black) to 255 (white)
 * @return string
 */
export default function getContrastColor(hexColor, greyScale = 128) {
  if (!hexColor) {
    return hexColor;
  }

  const r = parseInt(hexColor.slice(1, 3), 16);
  const g = parseInt(hexColor.slice(3, 5), 16);
  const b = parseInt(hexColor.slice(5, 7), 16);

  const yiq = (r * 299 + g * 587 + b * 114) / 1000;
  return yiq >= greyScale ? 'black' : 'white';
}

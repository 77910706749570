import { RexApiError } from '@rexlabs/api-client';
import { useQuery } from 'react-query';
import { useAccountId } from 'src/features/accounts';
import { QueryConfig, QueryResult } from 'src/lib/react-query';
import { api } from 'src/utils/api-client';
import { CommercialListing } from '../types';
import { commercialListingKeys } from './keys';
import { transformCommercialListingResponse } from 'src/features/listings/utils/schemas';

export type GetCommercialListingParams = {
  accountId: string;
  listingId: string;
};

export const getCommercialListing = async ({
  listingId
}: GetCommercialListingParams): Promise<CommercialListing> => {
  const { data } = await api.get(`/commercial-listings/${listingId}`, {
    include: 'address,thumbnails,agents,images,videos,events'
  });
  return transformCommercialListingResponse(data);
};

type QueryFnType = typeof getCommercialListing;

export type UseCommercialListingOptions = Omit<
  GetCommercialListingParams,
  'accountId'
> & {
  config?: QueryConfig<QueryFnType>;
};

export const useCommercialListing = ({
  config,
  ...params
}: UseCommercialListingOptions): QueryResult<CommercialListing> => {
  const accountId = useAccountId();

  return useQuery<CommercialListing, RexApiError>({
    queryKey: commercialListingKeys.detail({ accountId, ...params }),
    queryFn: () => getCommercialListing({ accountId, ...params }),
    ...config
  });
};

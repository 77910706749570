import { margin, padding, StyleSheet, useStyles } from '@rexlabs/styling';
import { Heading } from '@rexlabs/text';
import React, { FC, ReactNode } from 'react';

const styles = StyleSheet({
  container: {
    width: '100%',
    display: 'grid',
    gridTemplateColumns: '50rem auto',
    height: '100%',
    overflowX: 'hidden'
  },
  editorContainer: {
    display: 'flex',
    justifyContent: 'flex-end'
  },
  previewContainer: {
    display: 'flex',
    backgroundColor: ({ token }) =>
      token('color.container.static.preview.default')
  },
  symmetricSplit: {
    ...padding.styles({
      y: 'm',
      x: () => '7rem'
    })
  },
  asymmetricSplit: {
    ...padding.styles({
      y: 'm',
      x: () => '5rem'
    })
  },
  editor: {
    width: '100%',
    '& > * + *': {
      ...margin.styles({
        top: 'xxl'
      })
    }
  },
  preview: {
    width: '100%'
  }
});

export type StepEditorLayoutProps = {
  title: ReactNode;
  Preview: ReactNode;
  children: ReactNode;
  asymmetricSplit?: boolean;
};

export const StepEditorLayout: FC<StepEditorLayoutProps> = ({
  title,
  Preview,
  children,
  asymmetricSplit = false
}) => {
  const s = useStyles(styles, 'StepEditorLayout');
  return (
    <div
      {...s.with('container')({
        gridTemplateColumns: asymmetricSplit ? '50rem auto' : '50% 50%'
      })}
    >
      <div
        {...s('editorContainer', {
          asymmetricSplit,
          symmetricSplit: !asymmetricSplit
        })}
      >
        <div
          {...s.with('editor')({
            width: asymmetricSplit ? '100%' : '50rem'
          })}
        >
          <Heading level={1}>{title}</Heading>
          {children}
        </div>
      </div>
      <div
        {...s('previewContainer', {
          asymmetricSplit,
          symmetricSplit: !asymmetricSplit
        })}
      >
        <div
          {...s.with('preview')({ width: asymmetricSplit ? '100%' : '50rem' })}
        >
          {Preview}
        </div>
      </div>
    </div>
  );
};

import ActionMenu from '@rexlabs/action-menu';
import { withModel } from '@rexlabs/model-generator';
import React, { useMemo, useState } from 'react';
import { compose } from 'redux';
import { useFeature } from 'shared/hooks';
import sessionModel from 'src/data/models/custom/session';
import withError from 'src/view/containers/with-error';

import { GhostCircleButton } from '@rexlabs/button';
import Icons from '@rexlabs/icons-next';
import ChangePasswordModal from 'src/view/modals/admin/settings/user-manager/change-password';
import DeleteInvitationModal from 'src/view/modals/admin/settings/user-manager/delete-invitation';
import DeleteUserModal from 'src/view/modals/admin/settings/user-manager/delete-user';
import EditPermissionsModal from 'src/view/modals/admin/settings/user-manager/edit-permissions';
import TransferOwnershipModal from 'src/view/modals/admin/settings/user-manager/transfer-ownership';

function UserActionMenu({
  user,
  session,
  ownerRights,
  isInvitation,
  error: { Error }
}) {
  const [{ showModal, modalData }, setShowModal] = useState({});
  const capabilityMgmt = useFeature('capability_management', session);

  const items = useMemo(() => {
    if (!ownerRights) {
      return [];
    }

    if (isInvitation) {
      return [
        {
          label: 'Remove',
          onClick: () =>
            setShowModal({ showModal: 'deleteInvitation', modalData: { user } })
        }
      ];
    }

    let items = [];
    if (!user.is_owner && (!user.status || user.status.id !== 'pending')) {
      items.push({
        label: 'Transfer ownership',
        onClick: () =>
          setShowModal({
            showModal: 'transferOwnership',
            modalData: { toUser: user }
          })
      });
    }

    if (user.id !== session.user.id && !user.is_owner) {
      items = items.concat([
        {
          label: 'Remove',
          onClick: () =>
            setShowModal({
              showModal: 'deleteUser',
              modalData: { user }
            })
        }
      ]);
    }
    if (user.id !== session.user.id && session.user.is_sys_admin) {
      items = items.concat([
        {
          label: 'Change password',
          onClick: () =>
            setShowModal({
              showModal: 'changePassword',
              modalData: { user }
            })
        }
      ]);
    }

    if (capabilityMgmt) {
      if (!user.is_owner && (ownerRights || session.user.is_sys_admin)) {
        items.push({
          label: 'Edit permissions',
          onClick: () =>
            setShowModal({ showModal: 'editPermissions', modalData: { user } })
        });
      }
    }

    return items;
  }, [
    ownerRights,
    isInvitation,
    user,
    session.user.id,
    session.user.is_sys_admin,
    capabilityMgmt
  ]);

  return items.length ? (
    <span>
      <ActionMenu Icon={Icons.Kebab} Button={GhostCircleButton} items={items} />

      <Error />

      {showModal === 'transferOwnership' && (
        <TransferOwnershipModal
          {...modalData}
          closeModal={() => setShowModal({ showModal: null, modalData: {} })}
        />
      )}
      {showModal === 'deleteUser' && (
        <DeleteUserModal
          {...modalData}
          closeModal={() => setShowModal({ showModal: null, modalData: {} })}
        />
      )}
      {showModal === 'deleteInvitation' && (
        <DeleteInvitationModal
          {...modalData}
          closeModal={() => setShowModal({ showModal: null, modalData: {} })}
        />
      )}
      {showModal === 'changePassword' && (
        <ChangePasswordModal
          {...modalData}
          closeModal={() => setShowModal({ showModal: null, modalData: {} })}
        />
      )}
      {showModal === 'editPermissions' && (
        <EditPermissionsModal
          {...modalData}
          closeModal={() => setShowModal({ showModal: null, modalData: {} })}
        />
      )}
    </span>
  ) : null;
}

export default compose(withModel(sessionModel), withError())(UserActionMenu);

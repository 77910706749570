import _identity from 'lodash/identity';
import { createRestAPIModelGenerator } from 'src/data/models/generator';
import { api } from 'src/utils/api-client';

export const TYPE = 'facebook';

function fetchItem(args, id) {
  return Promise.all([
    api.get('/integrations/facebook', args),
    api.get('/integrations/facebook/pages', args)
  ]).then(([integration, pages]) => ({
    data: {
      attributes: { status: { id: 'page_not_connected' } },
      ...integration.data,
      id,
      pages: pages.data
    }
  }));
}

const config = {
  entities: {
    api: {
      createItem: (type, args) => api.post('/integrations/facebook', args),
      fetchItem: (type, args, id) => fetchItem(args, id),
      updateItem: (type, args, id) =>
        api
          .patch('/integrations/facebook', args)
          .then(() => fetchItem(args, id))
    }
  }
};

const actionCreators = {
  claimPage: {
    request: (args) => api.post('/integrations/facebook/pages', args),
    reduce: {
      initial: _identity,
      success: _identity,
      failure: _identity
    }
  }
};

const FacebookGenerator = createRestAPIModelGenerator(TYPE, config);
export default FacebookGenerator.createEntityModel({ actionCreators });

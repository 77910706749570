import { Box } from '@rexlabs/box';
import { padding, StyleSheet, useStyles } from '@rexlabs/styling';
import React from 'react';
import { SHADOWS } from 'src/theme';

const defaultStyles = StyleSheet({
  container: {
    justifyContent: 'space-between',
    width: '100%',
    boxShadow: SHADOWS.BUTTON_BAR,
    background:
      'linear-gradient(to bottom, rgba(255,255,255,0.8) 0%,rgba(255,255,255,1) 70%)',
    flexShrink: 0,
    position: 'sticky',
    bottom: 0,
    zIndex: 20,
    ...padding.styles({
      y: 'm'
    })
  }
});

function ButtonBar({ children }) {
  const s = useStyles(defaultStyles);
  return (
    <Box {...s('container')} flexDirection='row' alignItems='center'>
      {children}
    </Box>
  );
}

export default ButtonBar;

import { Box } from '@rexlabs/box';
import { styled, StyleSheet } from '@rexlabs/styling/lib/index';
import { autobind } from 'core-decorators';
import _ from 'lodash';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import VideoCameraIcon from 'src/assets/icons/video-camera.svg';
import WarningIcon from 'src/assets/icons/warning.svg';
import { BORDER_RADIUS } from 'src/theme';
import { DeleteButton } from 'src/view/components/button/icon';
import { Label, Tiny } from 'src/view/components/text';

const defaultStyles = StyleSheet({
  outer: {
    width: '100%',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-start',
    flex: '1 1 auto',
    alignItems: 'center'
  },

  thumbnailWrap: {
    width: '23.6rem',
    height: '14.4rem',
    position: 'relative',
    overflow: 'hidden',
    borderRadius: BORDER_RADIUS.INPUT
  },

  thumbnail: {
    position: 'absolute',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    background: 'none no-repeat transparent center center',
    backgroundSize: 'cover',
    zIndex: 0
  },

  overlay: {
    position: 'absolute',
    zIndex: 1,
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    transition: 'opacity 100ms ease-out',
    pointerEvents: 'auto'
  },

  overlayBackground: {
    opacity: 0,
    zIndex: 1,
    backgroundColor: ({ token }) => token('legacy.color.black'),
    '&:hover': {
      opacity: '0.5'
    }
  },

  overlayVisible: {
    opacity: 0,

    '&:hover': {
      opacity: 1,
      '& > :first-child': {
        opacity: 0.5
      }
    }
  },

  removeButton: {
    position: 'absolute',
    top: 7,
    left: 7,
    zIndex: 5
  },

  cameraIcon: {
    position: 'absolute',
    top: '1rem',
    right: '1rem',
    height: '2.5rem',
    width: '2.5rem',
    transform: 'scale(0.8)',
    zIndex: 5,
    color: 'rgba(255, 255, 255, .68)'
  },

  metadata: {
    paddingLeft: ({ token }) => token('spacing.m')
  },

  label: {
    display: 'inline-block',
    width: '18rem',
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis'
  },

  warnings: {
    width: '100%',
    backgroundColor: ({ token }) => token('legacy.color.grey.light'),
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-around',
    padding: ({ token }) => token('spacing.xs')
  },

  warningIcon: {
    color: ({ token }) => token('legacy.color.orange.default')
  },

  warningText: {
    paddingLeft: ({ token }) => token('spacing.xs')
  }
});

@styled(defaultStyles)
@autobind
class Video extends Component {
  static propTypes = {
    video: PropTypes.object.isRequired,
    clickRemove: PropTypes.func
  };

  static defaultProps = {
    clickRemove: _.noop
  };

  render() {
    const { video, clickRemove, styles: s } = this.props;
    const minutes = Math.floor((video?.meta?.duration ?? 0) / 60);
    const seconds = Math.floor((video?.meta?.duration ?? 0) % 60);
    const secondsString = (seconds + '').padStart(2, '0');

    const durationString =
      minutes > 0 ? `${minutes}:${secondsString}` : `${secondsString}s`;

    const thumbSrc = video.thumbnail.sizes['original.thumbnail'].url;
    const warnings = video?.meta?.warnings || [];

    return (
      <Box {...s('outer')}>
        <div {...s('thumbnailWrap')}>
          <div {...s('overlay', 'overlayVisible')}>
            <div {...s('overlay', { overlayBackground: !!clickRemove })} />
            {!!clickRemove && <DeleteButton onClick={clickRemove} />}
          </div>

          <VideoCameraIcon {...s('cameraIcon')} />

          <div
            {...s.with('thumbnail')({ backgroundImage: `url(${thumbSrc})` })}
          />
        </div>
        <div {...s('metadata')}>
          <Label {...s('label')}>{video.name}</Label>
          <Tiny grey>
            Resolution {video.meta.dimensions.width} x{' '}
            {video.meta.dimensions.height}
            <br />
            Length: {durationString}
          </Tiny>

          {warnings.length > 0 && (
            <Box {...s('warnings')}>
              <div {...s('warningIcon')}>
                <WarningIcon />
              </div>
              <div {...s('warningText')}>
                <Tiny grey>{warnings.join('<br />')}</Tiny>
              </div>
            </Box>
          )}
        </div>
      </Box>
    );
  }
}

export default Video;

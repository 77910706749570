import { withModel } from '@rexlabs/model-generator';
import React, { useEffect, useState } from 'react';
import { StripeProvider } from 'react-stripe-elements';
import config from 'src/config';
import sessionModel from 'src/data/models/custom/session';

function Core({ session, children }) {
  const [stripe, setStripe] = useState(null);
  const accountCountryCode = (session?.accounts ?? []).find(
    (a) => a.id === session.currentAccountId
  )?.agencies?.data?.[0]?.address?.country?.id;

  useEffect(() => {
    const stripeAccountKey = `STRIPE_API_KEY_${accountCountryCode}`;

    if (window.Stripe) {
      setStripe(window.Stripe(config[stripeAccountKey]));
    } else {
      document.querySelector('#stripe-js').addEventListener('load', () => {
        // Create Stripe instance once Stripe.js loads
        setStripe(window.Stripe(config[stripeAccountKey]));
      });
    }
  }, [accountCountryCode]);

  return <StripeProvider stripe={stripe}>{children}</StripeProvider>;
}

export default withModel(sessionModel)(Core);

/* eslint-disable max-lines */
import { Box } from '@rexlabs/box';
import { styled, StyleSheet } from '@rexlabs/styling';
import { autobind } from 'core-decorators';
import _memoize from 'lodash/memoize';
import React, { PureComponent } from 'react';
import ChevronLeft from 'src/assets/icons/chevron-left.svg';
import ChevronRight from 'src/assets/icons/chevron-right.svg';
import { BORDER_RADIUS } from 'src/theme';
import { LoadingSpinner } from 'src/view/components/loading';
import { Small } from 'src/view/components/text';
import withError from 'src/view/containers/with-error';
import CarouselCore from './core';

const defaultStyles = StyleSheet({
  container: {
    width: '100%',
    height: 'auto'
  },

  previewTitle: {
    paddingTop: ({ token }) => token('spacing.m'),
    paddingBottom: ({ token }) => token('spacing.m'),
    justifyContent: 'space-between',
    display: 'flex',
    flexDirection: 'row',
    whiteSpace: 'nowrap',
    fontSize: '1.8rem'
  },

  previewTitleLarge: {
    background: ({ token }) => token('legacy.color.white'),
    zIndex: 1,
    position: 'sticky',
    top: 0
  },

  previewTitleModal: {
    padding: ({ token }) => `${token('spacing.xs')} ${token('spacing.xl')}`
  },

  previewTitleReport: {
    padding: ({ token }) => `${token('spacing.xs')} ${token('spacing.xxl')}`,
    position: 'sticky',
    top: 0,
    background: ({ token }) => token('legacy.color.white'),
    zIndex: 900
  },

  previewContainer: {
    width: '100%',
    maxHeight: '810px',
    padding: ({ token }) => `${token('spacing.xxl')} 0`,
    background: ({ token }) => token('legacy.color.grey.light'),
    borderRadius: BORDER_RADIUS.LARGE,
    overflow: 'hidden'
  },

  previewContainerModal: {
    borderRadius: 0
  },

  previewContainerReport: {
    borderRadius: 0
  },

  truncate: {
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis'
  },

  wrapNav: {
    marginLeft: ({ token }) => token('spacing.xl'),
    position: 'relative',

    '&::before': {
      content: '" "',
      position: 'absolute',
      left: '4.35rem',
      top: '20%',
      height: '60%',
      width: '.1rem',
      background: ({ token }) => token('legacy.color.white')
    }
  },

  navText: {
    paddingTop: '10px'
  },

  navPositioning: {
    display: 'inline-flex',
    paddingLeft: ({ token }) => token('spacing.xs')
  },

  navButton: {
    border: '0 none',
    background: ({ token }) => token('legacy.color.blue.grey'),
    color: ({ token }) => token('legacy.color.white'),
    width: '4.4rem',
    height: '4.4rem',
    display: 'inline-flex',
    alignItems: 'center',
    justifyContent: 'center',
    cursor: 'pointer',

    '> svg': {
      display: 'inline-flex',
      width: '1.2rem',
      height: '1.2rem',
      transition: 'transform .2s'
    },

    ':hover': {
      '> svg': {
        transform: 'scale(1.3)'
      }
    },

    '[disabled]': {
      opacity: 0.5
    }
  },

  navPrev: {
    borderTopLeftRadius: BORDER_RADIUS.INPUT,
    borderBottomLeftRadius: BORDER_RADIUS.INPUT
  },

  navNext: {
    borderTopRightRadius: BORDER_RADIUS.INPUT,
    borderBottomRightRadius: BORDER_RADIUS.INPUT
  },

  loading: {
    minHeight: '40rem',
    width: '100%'
  },

  adBlockerWarning: {
    textAlign: 'center',
    minHeight: '40rem',
    width: '100%',
    padding: '0 20%'
  }
});

@withError()
@styled(defaultStyles)
@autobind
class AdPreviewCarousel extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      current: props.initial || 0,
      prev: 0,
      previews: [],
      loading: true,
      errors: [],
      adBlockerDetected: false
    };
    this._refs = {
      previews: []
    };
  }

  memAdsKey = (props) => {
    return (
      (props?.networks ?? [])
        .map((n) => n.id)
        .concat((props?.ads?.list?.items ?? []).map((a) => a.id))
        .join('::') + props?.campaignData?.prospecting?.video?.id
    );
  };

  memFilterAds = _memoize(this.filterAds, this.memAdsKey);

  filterAds(props) {
    const { ads, networks, campaignData } = props;
    if (ads?.list?.status === 'loading') return [];

    const enableVideo = !!(campaignData?.prospecting?.video ?? null);

    const filteredAds = (ads?.list?.items ?? []).filter((ad) => {
      if (ad.format.id.endsWith('.video') && !enableVideo) {
        return false;
      }
      return networks && networks.find((n) => n && n.id === ad.matchNetwork.id);
    });

    // multi_listing_sold needs to show facebook and instagram carousels first
    if (campaignData.campaign_type === 'multi_listing_sold') {
      return filteredAds
        .filter((a) => !a.id.endsWith('::adwords:native'))
        .sort((a, b) => {
          const aIsCarousel = a.name.toLowerCase().includes('carousel');
          const bIsCarousel = b.name.toLowerCase().includes('carousel');
          if (aIsCarousel && bIsCarousel) {
            return a.name.toLowerCase().includes('facebook') ? -1 : 0;
          }

          return aIsCarousel ? -1 : 0;
        });
    }

    return filteredAds;
  }

  handleCarouselRef(e) {
    this._refs.carousel = e;
    this.setState({ carouselRef: e });
  }

  showNext(e) {
    e.preventDefault();
    const { onChange, previewedCount } = this.props;
    const { current: prev } = this.state;
    const adCount = this.filterAds(this.props).length;
    const current = prev < adCount - 1 ? prev + 1 : adCount - 1;

    // Track how far into the ad previews the user views
    if (previewedCount && current + 1 > previewedCount.current) {
      previewedCount.current = current + 1;
    }

    this.setState({
      current
    });
    if (onChange) {
      onChange({
        prev,
        current
      });
    }
  }

  showPrev(e) {
    e.preventDefault();
    const { onChange } = this.props;
    const { current: prev } = this.state;
    const current = prev > 0 ? prev - 1 : 0;
    this.setState({
      prev,
      current
    });
    if (onChange) {
      onChange({
        prev,
        current
      });
    }
  }

  render() {
    const {
      styles: s,
      large,
      modal,
      report,
      error: { Error },
      isLoading,
      ads: adsList,
      totalAdPreviews
    } = this.props;

    const { current, carouselRef } = this.state;
    const ads = this.memFilterAds(this.props);
    if (totalAdPreviews) {
      totalAdPreviews.current = ads.length;
    }

    return (
      <div {...s('container')} ref={this.handleCarouselRef}>
        {adsList?.list?.status === 'loading' ? (
          <Box
            {...s('loading')}
            justifyContent='center'
            alignItems='center'
            flexDirection='column'
          >
            <LoadingSpinner />
            <Small grey>Loading ads...</Small>
          </Box>
        ) : (
          <Box>
            <Box
              {...s('previewTitle', {
                previewTitleLarge: large,
                previewTitleModal: modal,
                previewTitleReport: report
              })}
            >
              <Box
                {...s('truncate', 'navText')}
                title={ads?.[current]?.name ?? '-'}
              >
                {ads?.[current]?.name ?? '-'}
              </Box>

              <Box {...s('navPositioning')}>
                <Box {...s('navText')}>
                  {current + 1} of {ads.length}
                </Box>
                <Box {...s('wrapNav')}>
                  <button
                    {...s('navButton', 'navPrev')}
                    onClick={this.showPrev}
                    disabled={isLoading}
                  >
                    <ChevronLeft />
                  </button>
                  <button
                    {...s('navButton', 'navNext')}
                    onClick={this.showNext}
                    disabled={isLoading}
                  >
                    <ChevronRight />
                  </button>
                </Box>
              </Box>
            </Box>

            <Box
              {...s('previewContainer', {
                previewContainerModal: modal,
                previewContainerReport: report
              })}
            >
              <CarouselCore
                {...this.props}
                ads={ads}
                state={this.state}
                carouselRef={carouselRef}
              />
            </Box>

            <Error />
          </Box>
        )}
      </div>
    );
  }
}

export default AdPreviewCarousel;

import React, { FC, useCallback } from 'react';
import { MegaModalContent } from 'src/components/elements/modal';
import { useModalState } from 'src/hooks';
import { Consumer } from 'src/types';
import { useUpdateImage } from '../api/updateImage';
import { Image, ImageCrops } from '../types';
import { getImageCropSelectorData } from '../utils/helpers';
import { ImageCropSelector } from './ImageCropSelector';

export type ImageCropSelectorModalProps = {
  image: Image;
  onUpdate: Consumer<Image>;
  onChange?: Consumer<Image | undefined>;
};

export const ImageCropSelectorModal: FC<ImageCropSelectorModalProps> = ({
  image,
  onUpdate,
  onChange
}) => {
  const { imageId, ...props } = getImageCropSelectorData(image);
  const [, setOpen] = useModalState();
  const { mutateAsync } = useUpdateImage();

  const closeModal = useCallback(() => setOpen(false), [setOpen]);
  const updateAndClose = useCallback(
    async (crops: ImageCrops) => {
      const updated = await mutateAsync({
        imageId,
        image: { crops }
      });
      onUpdate(updated);
      // This onChange is only used when inside the DynamicFormRenderer
      if (onChange) onChange(updated);
      closeModal();
    },
    [closeModal, imageId, mutateAsync, onUpdate]
  );

  return (
    <MegaModalContent
      title='Crop image'
      description='Crop the image to fit landscape and square ad formats'
    >
      <ImageCropSelector
        onSubmit={updateAndClose}
        onCancel={closeModal}
        {...props}
      />
    </MegaModalContent>
  );
};

/* eslint-disable max-lines */
import { identify } from '@rexlabs/analytics';
import identity from 'lodash/identity';
import config from 'src/config';
import { createRestAPIModelGenerator } from 'src/data/models/generator';
import { Bugsnag } from 'src/lib/bugsnag';
import { authStore } from 'src/stores/auth';
import { api, setAccountId, setAuthToken } from 'src/utils/api-client';

function initSession({ user, apiToken, currentAccountId }) {
  // eslint-disable-next-line no-async-promise-executor
  return new Promise(async (resolve, reject) => {
    // Track user in Bugsnag
    Bugsnag.setUser(user);

    // Bail if no persisted API token exists
    if (!apiToken) {
      setAuthToken(null);
      reject(new Error('No API token defined!'));
      return;
    }

    // Otherwise set api token for client and grab user accounts,
    // to verify that the current account is still valid
    setAuthToken(apiToken);

    try {
      const perpage = 5000;
      const accountsResponse = await api.get('/accounts', {
        per_page: perpage
      });
      let accounts = accountsResponse.data;

      let page = 1;
      while (page < accountsResponse.pagination.total_pages) {
        page++;
        const pageResponse = await api
          .get('/accounts', {
            per_page: perpage,
            page
          })
          .catch(reject);
        accounts = [...accounts, ...(pageResponse?.data ?? [])];
      }

      const warnings = [];

      let currentAccount =
        currentAccountId && accounts.find((a) => a.id === currentAccountId);

      const accountId = currentAccount
        ? currentAccountId
        : accounts.length === 1
        ? accounts[0].id
        : null;

      identify({
        userId: user.id,
        properties: {
          user,
          accounts,
          account: currentAccount
        }
      });

      // Update bugsnag to include the account
      Bugsnag.setUser({ ...user, account: currentAccount?.name });

      if (accountId) {
        setAccountId(accountId);

        const accountIndex = accounts.findIndex((a) => a.id === accountId);

        await fetchAccount(accountId).then((accountInfo) => {
          accounts[accountIndex] = accountInfo;
          currentAccount = accountInfo;
        });

        if (!accounts[accountIndex].ready) {
          warnings.push({
            message:
              "We're currently reviewing your account and will activate it soon",
            action: {}
          });
        }
      }

      resolve({
        user,
        accounts,
        currentAccountId: accountId,
        currentAccount,
        apiToken,
        warnings,
        errors: []
      });
    } catch (e) {
      setAuthToken(null);
      reject(e);
    }
  });
}

const fetchAccount = (id) => {
  // eslint-disable-next-line no-async-promise-executor
  return new Promise(async (resolve, reject) => {
    try {
      const accountResponse = await api.get(
        `/accounts/${id}?include=agencies,feature_flags,hubspotManager`
      );
      resolve(accountResponse.data);
    } catch (e) {
      reject(e);
    }
  });
};

function setAccount({ id, session }) {
  const accounts = session.accounts;
  const accountIndex = accounts.findIndex((a) => a.id === id);
  const warnings = [];

  return fetchAccount(id).then((accountInfo) => {
    accounts[accountIndex] = accountInfo;

    setAccountId(id);

    identify({
      userId: session?.user?.id,
      properties: {
        user: session?.user,
        accounts,
        account: accounts.find((a) => a.id === id)
      }
    });

    if (id) {
      if (!accounts[accountIndex].ready) {
        warnings.push({
          message:
            "We're currently reviewing your account and will activate it soon",
          action: {}
        });
      }
    }

    const account = id && accounts.find((a) => a.id === id);

    return {
      ...session,
      currentAccountId: id,
      currentAccount: account,
      warnings
    };
  });
}

export const TYPE = 'session';

const initialState = {
  ready: false,
  status: null,
  apiToken: null,
  user: null,
  accounts: [],
  currentAccountId: null,
  currentAccount: undefined,
  errors: [],
  warnings: [],
  maintenanceMode: false
};

const selectors = {
  ready: (state) => state.session.ready,
  status: (state) => state.session.status,
  apiToken: (state) => state.session.apiToken,
  user: (state) => state.session.user,
  accounts: (state) => state.session.accounts,
  currentAccountId: (state) => state.session.currentAccountId,
  currentAccount: (state) => state.session.currentAccount,
  errors: (state) => state.session.errors,
  warnings: (state) => state.session.warnings,
  maintenanceMode: (state) => state.session.maintenanceMode
};

const actionCreators = {
  login: {
    request: (
      { email, password, currentAccountId },
      actions,
      dispatch,
      getState
    ) =>
      new Promise((resolve, reject) => {
        // #1: Log user in and store api auth token

        const loginParams = {
          password,
          username: email,
          grant_type: 'password',
          client_id: config.OAUTH.CLIENT_ID,
          client_secret: config.OAUTH.CLIENT_SECRET,
          scope: '*'
        };

        api
          .post('/oauth/token', loginParams)
          .then((loginResponse) => {
            const apiToken = loginResponse?.data?.access_token;

            if (!apiToken) {
              reject(new Error('No api token found in response!'));
              return;
            }

            authStore.setState({ authData: loginResponse.data });
            setAuthToken(apiToken);

            return api
              .get('/users/me')
              .then((userResponse) => {
                return {
                  user: userResponse.data,
                  apiToken
                };
              })
              .catch(reject);
          })
          .then(({ user, apiToken }) => {
            // #2: Grab all user account and store them on the session!
            initSession({
              ...getState().session,
              user,
              apiToken,
              currentAccountId
            })
              .then(({ accounts, currentAccountId, warnings, errors }) =>
                resolve({
                  user,
                  apiToken,
                  accounts,
                  currentAccountId,
                  warnings,
                  errors
                })
              )
              .catch(reject);
          })
          .catch(reject);
      }),
    reduce: {
      initial: (state) => ({ ...state, status: 'loggingIn' }),
      success: (state, action) => ({
        ...state,
        apiToken: action?.payload?.apiToken,
        user: action?.payload?.user,
        accounts: action?.payload?.accounts,
        currentAccountId: action?.payload?.currentAccountId,
        errors: action?.payload?.errors ?? [],
        warnings: action?.payload?.warnings ?? [],
        status: 'loaded'
      }),
      failure: (state) => {
        setAuthToken(null);
        return {
          ...state,
          apiToken: null,
          status: 'loaded'
        };
      }
    }
  },

  logout: {
    request: async (payload, actions, dispatch) =>
      dispatch({ type: 'ENTITY_RESET' }),
    reduce: {
      success: (state) => {
        setAuthToken(null);
        setAccountId(null);
        authStore.setState({ authData: undefined });

        return {
          ...state,
          ready: true,
          apiToken: null,
          user: null,
          accounts: [],
          currentAccountId: null,
          errors: [],
          warnings: []
        };
      }
    }
  },

  enterMaintenanceMode: {
    reduce: (state) => ({
      ...state,
      maintenanceMode: true
    })
  },

  init: {
    request: (payload, actions, dispatch, getState) =>
      initSession(getState().session),
    reduce: {
      initial: identity,
      success: (state, action) => ({
        ...state,
        ready: true,
        user: action?.payload?.user,
        accounts: action?.payload?.accounts,
        currentAccountId: action?.payload?.currentAccountId,
        currentAccount: action?.payload?.currentAccount,
        errors: action?.payload?.errors ?? [],
        warnings: action?.payload?.warnings ?? []
      }),
      failure: (state) => ({
        ...state,
        ready: true,
        apiToken: null,
        user: null,
        accounts: [],
        currentAccountId: null,
        currentAccount: null,
        errors: [],
        warnings: []
      })
    }
  },

  setAccount: {
    request: (payload, actions, dispatch, getState) =>
      Promise.resolve(
        setAccount({ id: payload.id, session: getState().session })
      ),
    reduce: {
      initial: identity,
      success: (state, action) => ({
        ...state,
        currentAccountId: action?.payload?.currentAccountId,
        currentAccount: action?.payload?.currentAccount,
        warnings: action?.payload?.warnings
      }),
      failure: () => ({
        // TODO: proper error handling
        status: 'loaded'
      })
    }
  },

  clearAccount: {
    request: (payload, actions, dispatch, getState) =>
      new Promise((resolve) => {
        resolve({
          ...getState().session,
          currentAccountId: null
        });
      }),
    reduce: {
      initial: identity,
      success: (state) => {
        return {
          ...state,
          currentAccountId: null,
          currentAccount: null
        };
      },
      failure: identity
    }
  },

  switchAccount: {
    request: async (payload, actions, dispatch, getState) => {
      dispatch({ type: 'ENTITY_RESET' });
      return setAccount({ id: payload.id, session: getState().session });
    },
    reduce: {
      initial: (state) => ({ ...state, status: 'switching' }),
      success: (state, action) => {
        const account = action.payload?.currentAccount;

        Bugsnag.setUser({ ...state.user, account: account?.name });

        return {
          ...state,
          status: 'loaded',
          currentAccount: account,
          currentAccountId: action?.payload?.currentAccountId,
          accounts: action?.payload?.accounts,
          warnings: action?.payload?.warnings
        };
      },
      failure: () => ({
        // TODO: proper error handling
        status: 'loaded'
      })
    }
  },

  updateUser: {
    request: (payload) => api.patch('/users/me', payload),
    reduce: {
      initial: identity,
      success: (state, action) => {
        const account = state.accounts.find(
          (a) => a.id === state.currentAccountId
        )?.name;

        Bugsnag.setUser({ ...action.payload.data, account });

        return {
          ...state,
          user: action.payload.data
        };
      },
      error: identity
    }
  },

  deleteUser: {
    request: (id) => api.delete(`/account/users/${id}`),
    reduce: {
      initial: identity,
      success: identity,
      failure: identity
    }
  },

  createAccount: {
    request: (payload, actions, dispatch, getState) => {
      const currState = getState();
      const { user, apiToken, currentAccountId } = currState.session;
      return api.post('/accounts', payload.data).then(() =>
        initSession({
          user,
          apiToken,
          currentAccountId
        })
      );
    },
    reduce: {
      initial: identity,
      success: (state, action) => ({
        ...state,
        accounts: action.payload.accounts,
        currentAccountId: action.payload.currentAccountId
      }),
      failure: identity
    }
  },

  register: {
    request: (payload) => api.post('/auth/register', payload),
    reduce: {
      initial: identity,
      success: identity,
      failure: identity
    }
  }
};

const SessionModel = createRestAPIModelGenerator(TYPE);
const session = SessionModel.createModel({
  initialState,
  selectors,
  actionCreators
});

export default session;

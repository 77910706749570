import _ from 'lodash';
import { createRestAPIModelGenerator } from 'src/data/models/generator';
import { api } from 'src/utils/api-client';

export const TYPE = 'agencies';

const config = {
  entities: {
    related: {
      logo: {
        include: 'logo.sizes'
      },
      address: {
        include: 'address'
      }
    }
  }
};

const actionCreators = {
  fetchImpressions: {
    request: (payload) => api.get('/stats/impressions', payload),
    reduce: {
      initial: _.identity,
      success: _.identity,
      failure: _.identity
    }
  },

  fetchAgentImpressions: {
    request: (payload) => api.get('/stats/impressions-by-agent', payload),
    reduce: {
      initial: _.identity,
      success: _.identity,
      failure: _.identity
    }
  }
};

const AgenciesGenerator = createRestAPIModelGenerator(TYPE, config);
export default AgenciesGenerator.createEntityModel({ actionCreators });

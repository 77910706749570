import React, {
  createContext,
  Dispatch,
  SetStateAction,
  useContext,
  useEffect,
  useState
} from 'react';
import { BreadcrumbLinkProps } from 'src/components/elements/breadcrumb';
import { useWhereabouts } from '@rexlabs/whereabouts';

type BreadCrumbsState = {
  breadcrumbs: BreadcrumbLinkProps[];
  setBreadcrumbs: Dispatch<SetStateAction<BreadcrumbLinkProps[]>>;
};

export const BreadcrumbsContext = createContext<BreadCrumbsState | undefined>(
  undefined
);

export function BreadcrumbsProvider({ children }) {
  const [breadcrumbs, setBreadcrumbs] = useState<BreadcrumbLinkProps[]>([]);
  const whereabouts = useWhereabouts();

  useEffect(() => {
    setBreadcrumbs([]);
  }, [whereabouts.path]);

  return (
    <BreadcrumbsContext.Provider value={{ breadcrumbs, setBreadcrumbs }}>
      {children}
    </BreadcrumbsContext.Provider>
  );
}

export const useBreadcrumbs = () => {
  const breadcrumbsContext = useContext(BreadcrumbsContext);
  if (breadcrumbsContext === undefined) {
    throw new Error('useBreadcrumbs must be inside of BreadcrumbsProvider');
  }
  return breadcrumbsContext;
};

import { ActionMenu, ActionMenuItem } from '@rexlabs/action-menu';
import { Avatar } from '@rexlabs/avatar';
import { Box } from '@rexlabs/box';
import { useModelActions, useModelState } from '@rexlabs/model-generator';
import { StyleSheet, useStyles } from '@rexlabs/styling';
import { Large, Truncate } from '@rexlabs/text';
import React, { forwardRef } from 'react';
import sessionModel from 'src/data/models/custom/session';
import ROUTES from 'src/routes/admin';
import { push } from 'src/utils/whereabouts';

const defaultStyles = StyleSheet({
  userProfile: {
    width: '20rem',
    display: 'flex',
    flexDirection: 'column',

    ':hover': {
      backgroundColor: ({ token }) =>
        token(
          'topNavBar.userProfile.hover.color',
          token('color.container.interactive.hover')
        ),
      textDecoration: 'underline'
    }
  },
  userName: {
    color: ({ token }) =>
      token(
        'topNavBar.userName.color',
        token('color.textStyle.primary.idle.default')
      )
  }
});

export interface AccountActionMenuProps {
  userMenuItems: ActionMenuItem[];
}

function AccountActionMenu({ userMenuItems }: AccountActionMenuProps) {
  const s = useStyles(defaultStyles);
  const session = useModelState(sessionModel);
  const { logout } = useModelActions(sessionModel);

  const fullName = `${session?.user?.given_name} ${session?.user?.family_name}`;
  return (
    <ActionMenu
      Button={forwardRef(({ Icon, ...props }, ref) => (
        <Avatar
          ref={ref}
          name={fullName}
          {...props}
          data-testid='avatar-menu-icon'
        />
      ))}
      placement='bottom-end'
      items={[
        {
          isCustomContent: true,
          Content: forwardRef(({ className, ...props }, ref) => (
            <Box ref={ref} {...s.with('userProfile')({ className })} {...props}>
              <Large as='span' {...s('userName')}>
                {fullName}
              </Large>
              <Truncate>{session?.user?.email}</Truncate>
            </Box>
          )),
          onClick: () => push(ROUTES.PROFILE)
        },
        ...userMenuItems,
        {
          label: 'Logout',
          onClick: () => logout({})
        }
      ]}
    />
  );
}

export default AccountActionMenu;

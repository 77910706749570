import { Checkbox } from '@rexlabs/checkbox';
import { margin, StyleSheet, useStyles } from '@rexlabs/styling';
import { Body } from '@rexlabs/text';
import { formatRFC7231 } from 'date-fns';
import React, { ChangeEventHandler, FC, useCallback, useRef } from 'react';
import {
  AlertDescription,
  AlertTitle,
  ConfirmActionAlert
} from 'src/components/elements/alert';
import {
  ListItem,
  ListItemProps
} from 'src/components/elements/listbox/ListItem';
import Avatar from 'src/view/components/avatar';
import { ResourceDownload, ResourceDownloadDTO } from '../types';
import { Link } from 'react-router-dom';
import { LinkButton } from '@rexlabs/button';

const styles = StyleSheet({
  container: {
    listStyle: 'none',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    cursor: 'pointer',
    transition: 'all .2s',

    ':hover': {
      backgroundColor: ({ token }) => token('color.container.interactive.hover')
    },

    ':active': {
      backgroundColor: ({ token }) =>
        token('color.container.interactive.active')
    },

    '&[aria-selected="true"]': {
      backgroundColor: ({ token }) =>
        token('color.container.interactive.selected')
    }
  },

  details: {
    display: 'flex',
    flexDirection: 'column',
    flex: '1 1 auto'
  },

  text: {
    margin: '0',
    padding: '0',
    lineHeight: ({ token }) => token('typography.lineHeight.l'),
    fontWeight: ({ token }) => token('typography.weight.medium'),
    fontSize: ({ token }) => token('typography.size.xl'),
    letterSpacing: '0'
  },

  textMeta: {
    fontSize: ({ token }) => token('typography.size.m')
  },

  toolbar: {
    fontSize: '1.2rem !important',

    '& > * + *': {
      ...margin.styles({
        left: 'xs'
      })
    }
  },

  action: {
    border: 'none',
    background: 'none',
    padding: 0,
    fontSize: ({ token }) => token('typography.size.l'),
    fontWeight: ({ token }) => token('typography.weight.medium'),
    color: ({ token }) => token('color.textStyle.primary.idle.default'),
    cursor: 'pointer',

    ':hover': {
      color: ({ token }) => token('color.textStyle.primary.hover.default')
    }
  },

  delete: {
    color: ({ token }) => token('color.textStyle.danger.idle.default'),

    ':hover': {
      color: ({ token }) => token('color.textStyle.danger.hover.default')
    }
  }
});

export type DeleteResourceHandler = (
  resource: ResourceDownload
) => Promise<void>;

export type EditResourceHandler = (
  id: string,
  resource: ResourceDownloadDTO
) => Promise<void>;

export type ResourceDownloadItemProps = Pick<
  ListItemProps,
  'posinset' | 'selected' | 'className' | 'style'
> & {
  resource: ResourceDownload;
  onChange: ChangeEventHandler<HTMLInputElement>;
  onEdit: EditResourceHandler;
  onDelete: DeleteResourceHandler;
};

export const ResourceDownloadItem: FC<ResourceDownloadItemProps> = ({
  resource,
  selected = false,
  onChange,
  onEdit,
  onDelete,
  className,
  style,
  ...rest
}) => {
  const s = useStyles(styles);
  const labelId = `${resource.id}-label`;

  const handleDelete = useCallback(
    () => onDelete(resource),
    [onDelete, resource]
  );

  const checkbox = useRef<HTMLInputElement>(null);
  const toggleCheckbox = useCallback(() => checkbox.current?.click(), []);

  return (
    <ListItem
      {...s.with('container')({ className, style })}
      id={resource.id}
      aria-labelledby={labelId}
      selected={selected}
      data-testid={'rd-list-item'}
      {...rest}
    >
      <Avatar
        src={resource.image.sizes['thumbnail.campaign'].url}
        onClick={toggleCheckbox}
      />
      <div {...s('details')}>
        <Body
          {...s('text')}
          as={(p) => <span id={labelId} {...p} />}
          onClick={toggleCheckbox}
        >
          {resource.title}
        </Body>
        <Body
          {...s('text', 'textMeta')}
          grey
          as='span'
          onClick={toggleCheckbox}
        >
          {formatRFC7231(resource.updated_at)}
        </Body>
        <div {...s('toolbar')}>
          <Link {...s('hideUnderline')} to={`${resource.id}/edit`}>
            <LinkButton {...s('action')}>Edit</LinkButton>
          </Link>
          <ConfirmActionAlert
            openButton={<button {...s('action', 'delete')}>Delete</button>}
            destructive
            confirmLabel='Delete'
            onConfirm={handleDelete}
          >
            <AlertTitle>
              Are you sure you want to delete this downloadable resource?
            </AlertTitle>
            <AlertDescription>
              Your downloadable resource will be permanently deleted. Any active
              campaigns using this resource will continue to work as expected.
            </AlertDescription>
          </ConfirmActionAlert>
        </div>
      </div>
      <Checkbox
        ref={checkbox}
        aria-labelledby={labelId}
        name={resource.id}
        value={selected}
        onChange={onChange}
      />
    </ListItem>
  );
};

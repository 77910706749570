import Icons from '@rexlabs/icons-next';
import { StyleSheet, useStyles } from '@rexlabs/styling';
import React, { FC, ReactNode } from 'react';

const styles = StyleSheet({
  container: {
    paddingTop: '20px'
  },
  titleContainer: {
    marginBottom: '38px',
    color: ({ token }) => token('legacy.color.blue.grey'),
    lineHeight: '30px',
    letterSpacing: '-0.004em',
    display: 'flex',
    alignItems: 'center',
    gap: '8px'
  },
  guideBoxes: {
    display: 'flex',
    flexDirection: 'column',
    gap: '30px'
  }
});

export type FormGuideLayoutProps = {
  children: ReactNode;
};

export const FormGuideLayout: FC<FormGuideLayoutProps> = ({ children }) => {
  const s = useStyles(styles, 'FormGuideLayout');
  return (
    <div {...s('container')}>
      <div {...s('titleContainer')}>
        <Icons.InfoCircle size='l' />
        <h2>Guidance</h2>
      </div>
      <div {...s('guideBoxes')}>{children}</div>
    </div>
  );
};

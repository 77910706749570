/* eslint-disable max-lines */
import { getContrastColor } from 'utils/color';
import { cropSrc } from 'utils/images';
import _get from 'lodash/get';
import _memoize from 'lodash/memoize';

async function prepareAdData(props) {
  const { account, campaignData, session, ad } = props;

  const currAccount =
    account || session.accounts.find((a) => a.id === session.currentAccountId);

  /**
   * HACK: this is adding session to the adContentSet so session can be
   * used in ad-renderer and subsequently allowing the use of the
   * useFeature hook
   */
  const fakeSession = {
    accounts: [currAccount],
    currentAccountId: session?.currentAccountId || account.id
  };

  const type = _get(campaignData, 'campaign_type');
  const format = _get(ad, 'format.id', '');

  const listing = _get(campaignData, 'listing');
  const images = _get(campaignData, 'images');

  const prospectingSet = _get(campaignData, 'prospecting');
  const retargetingSet = _get(campaignData, 'retargeting');

  const landingPagesDefaultExternal = _get(
    campaignData,
    'accountPreferences.landing_pages_default_use_external'
  );
  const landingPagesIsExternal = _get(
    campaignData,
    'landing_pages.is_external'
  );
  let website = '';
  try {
    if (type === 'single_listing') {
      if (landingPagesDefaultExternal || landingPagesIsExternal) {
        website = new URL(_get(campaignData, 'landing_pages.external_url'))
          .origin;
      } else {
        website = new URL(
          `https://${_get(campaignData, 'landing_pages.domain')}.lp.spokeapp.io`
        ).origin;
      }
    } else {
      website = new URL(_get(prospectingSet, 'final_url')).origin;
    }
  } catch (e) {
    /* Don't care about error */
  }

  const agentName =
    type === 'agent_profile'
      ? _get(prospectingSet, 'agent_name')
      : _get(prospectingSet, 'agent.agent_name');

  const agentPosition = _get(prospectingSet, 'agent_position');
  const agency =
    _get(prospectingSet, 'agency_name') ||
    _get(prospectingSet, 'agent.agency_name') ||
    currAccount.name;

  const agencyLogo = _get(prospectingSet, [
    'agency_logo',
    'sizes',
    'original',
    'url'
  ]);

  const facebookPage =
    campaignData?.facebookPage || _get(prospectingSet, 'facebookPage');
  const banner = _get(prospectingSet, 'banner_text.label');
  const smallText = _get(prospectingSet, 'small_text');
  const backgroundColor = _get(prospectingSet, 'background_color');
  const brandColor = _get(prospectingSet, 'brand_color');
  const brandTextColor = getContrastColor(brandColor);

  // Prospecting
  const prospectingTitle = _get(prospectingSet, 'short_headline', '');
  const prospectingHeadline = _get(prospectingSet, 'long_headline', '');
  const prospectingDescription = _get(prospectingSet, 'description', '');

  const prospectingPrimaryImage = await crops(
    _get(prospectingSet, 'primary_image')
  );
  const prospectingSecondaryImage = await crops(
    _get(prospectingSet, 'secondary_image')
  );

  const prospectingAgentImage = await crops(
    type === 'agent_profile'
      ? _get(prospectingSet, 'agent_image')
      : _get(prospectingSet, 'agent.agent_image')
  );

  const video = _get(prospectingSet, 'video', '');
  const retargetingVideo = _get(retargetingSet, 'video', '');
  const prospectingSlides = _get(prospectingSet, 'slides', []);

  // Retargeting
  const retargetingTitle = _get(retargetingSet, 'short_headline', '');
  const retargetingHeadline = _get(retargetingSet, 'long_headline', '');
  const retargetingDescription = _get(retargetingSet, 'description', '');
  const retargetingSlides = _get(retargetingSet, 'slides', []);

  const retargetingPrimaryImage = await crops(
    _get(retargetingSet, 'primary_image')
  );
  const retargetingSecondaryImage = await crops(
    _get(retargetingSet, 'secondary_image')
  );

  const retargetingAgentImage = await crops(
    type === 'agent_profile'
      ? _get(retargetingSet, 'agent_image')
      : _get(prospectingSet, 'agent.agent_image')
  );

  // Bundle everything into one object
  return {
    type,
    format,
    listing,

    agentName,
    agentPosition,
    agencyLogo,
    agency,
    website,
    facebookPage,

    banner,
    smallText,

    backgroundColor,
    brandColor,
    brandTextColor,

    images,

    prospecting: {
      title: prospectingTitle,
      headline: prospectingHeadline,
      description: prospectingDescription,

      primaryImage: prospectingPrimaryImage,
      secondaryImage: prospectingSecondaryImage,
      agentImage: prospectingAgentImage,

      video,

      slides: prospectingSlides
    },

    retargeting: {
      title: retargetingTitle,
      headline: retargetingHeadline,
      description: retargetingDescription,

      primaryImage: retargetingPrimaryImage,
      secondaryImage: retargetingSecondaryImage,
      agentImage: retargetingAgentImage,

      video: retargetingVideo,

      slides: retargetingSlides
    },

    session: fakeSession
  };
}

function mapPropsToValues(props) {
  // NOTE: this is used in app and ad-renderer
  // ad-render doesn't use redux so it's props are equal to the raw api repsonse
  // e.g. campaigns.item.data is just campaigns in ad-render
  // e.g. campaigns.ad_content_sets.items is ad_content_sets.data in ad-render

  const campaign =
    _get(props, 'campaigns.item.data') || _get(props, 'campaigns');
  const campaignType = _get(campaign, 'type.id');
  const sets =
    _get(campaign, 'ad_content_sets.items') ||
    _get(campaign, 'ad_content_sets.data', []);
  const propertyImages = _get(campaign, 'images', []);
  const agentImages = _get(campaign, 'agent_images', []);
  const videos = _get(campaign, 'videos', []) || [];

  const prospecting = sets.find((i) =>
    _get(i, 'type.id', '').endsWith('.prospecting')
  );
  const retargeting = sets.find((i) =>
    _get(i, 'type.id', '').endsWith('.retargeting')
  );

  const suburb = _get(campaign, 'audience_spec.locations', []).find(
    (l) => l.type.id === 'suburb'
  );

  const bannerText = _get(prospecting, 'attributes.banner_text');
  const images = _get(campaign, 'images', []);
  const prospectingSlides = addImageToSlides(
    _get(prospecting, 'attributes.slides', []),
    propertyImages
  );
  const retargetingSlides = addImageToSlides(
    _get(retargeting, 'attributes.slide', []),
    propertyImages
  );

  const prospectingPrimaryImage = getImage(
    propertyImages,
    _get(prospecting, 'attributes.primary_image.id')
  );
  const prospectingSecondaryImage = getImage(
    propertyImages,
    _get(prospecting, 'attributes.secondary_image.id')
  );

  return {
    name: _get(campaign, 'name'),
    suburb: suburb ? suburb.suburb : null,
    lead_generation_form_enabled: campaign?.lead_generation_form_enabled,
    prospecting: {
      primary_image: prospectingPrimaryImage,
      secondary_image: prospectingSecondaryImage,
      video: getVideo(videos, _get(prospecting, 'attributes.video.id')),
      video_only: !prospectingPrimaryImage && !prospectingSecondaryImage,
      small_text: _get(prospecting, 'attributes.small_text') || '',
      short_headline: _get(prospecting, 'attributes.short_headline') || '',
      long_headline: _get(prospecting, 'attributes.long_headline') || '',
      banner_text: bannerText ? { value: bannerText, label: bannerText } : null,
      description: _get(prospecting, 'attributes.description') || '',
      agent_name: _get(prospecting, 'attributes.agent_name') || '',
      suburb: _get(prospecting, 'attributes.suburb') || '',
      agency_logo: _get(campaign, 'agency_logo'),
      brand_color: _get(prospecting, 'attributes.brand_color') || '',
      background_color: _get(prospecting, 'attributes.background_color') || '',
      final_url: _get(prospecting, 'final_url') || '',
      agent_position: _get(prospecting, 'attributes.agent_position'),
      agent_image: getImage(
        agentImages,
        _get(prospecting, 'attributes.agent_image.id')
      ),
      agent: {
        agent_image: getImage(
          agentImages,
          _get(prospecting, 'attributes.agent_image.id')
        ),
        agent_name: _get(prospecting, 'attributes.agent_name') || '',
        agency_name: _get(prospecting, 'attributes.agency_name') || ''
      },
      facebookPage: _get(props, 'facebook.item.data.pages[0].name') || '',
      slides: prospectingSlides
    },
    retargeting: {
      primary_image: getImage(
        propertyImages,
        _get(retargeting, 'attributes.primary_image.id')
      ),
      secondary_image: getImage(
        propertyImages,
        _get(retargeting, 'attributes.secondary_image.id')
      ),
      agent_image: getImage(
        agentImages,
        _get(retargeting, 'attributes.agent_image.id')
      ),
      short_headline: _get(retargeting, 'attributes.short_headline') || '',
      long_headline: _get(retargeting, 'attributes.long_headline') || '',
      description: _get(retargeting, 'attributes.description') || '',
      slides: retargetingSlides
    },
    campaign_type: campaignType || '',
    agent_listing_toggled:
      campaignType === 'single_listing' &&
      (!!_get(prospecting, 'attributes.agent_image.id') ||
        !!_get(prospecting, 'attributes.agent_name') ||
        !!_get(prospecting, 'attributes.agency_name')),
    banner_text_toggled: campaignType === 'single_listing' && !!bannerText,
    accountPreferences: {
      landing_pages_default_use_external: _get(
        props,
        'accountPreferences.item.data.landing_pages_default_use_external'
      )
    },
    landing_pages: {
      domain: _get(campaign, 'landing_pages.items[0].domain'),
      is_external: _get(campaign, 'landing_pages.items[0].is_external'),
      external_url: _get(campaign, 'landing_pages.items[0].external_url')
    },
    images,
    ad_types: _get(campaign, 'campaign_definition.ad_types') || '',
    flex_ad_content_set: retargeting
  };
}

function addImageToSlides(slides, images) {
  return slides.map((slide) => {
    return {
      ...slide,
      image: images.find((i) => i.id === slide.image.id)
    };
  });
}

function getImage(images, imageId) {
  return images.find((image) => image.id === imageId) || undefined;
}

function getVideo(videos, videoId) {
  return videos.find((video) => video.id === videoId) || undefined;
}

const getBannerAdData = _memoize((campaignData, type) => {
  return {
    backgroundColor: _get(campaignData, 'backgroundColor'),
    brandColor: _get(campaignData, 'brandColor'),
    longHeadline: _get(campaignData, 'headline'),
    shortHeadline: _get(campaignData, 'title'),
    smallText: _get(campaignData, 'smallText'),
    description: _get(campaignData, 'description'),
    agentName: _get(campaignData, 'agentName'),
    agentPosition: _get(campaignData, 'agentPosition'),
    agencyName: _get(campaignData, 'agency'),
    suburb: '-',
    bannerText: _get(campaignData, 'banner'),
    primaryImageUrls:
      type === 'agent'
        ? {
            'crop.landscape': {
              url: _get(campaignData, 'agentImage.landscape')
            },
            'crop.square': {
              url: _get(campaignData, 'agentImage.square')
            }
          }
        : {
            'crop.landscape': {
              url: _get(campaignData, 'primaryImage.landscape')
            },
            'crop.square': {
              url: _get(campaignData, 'primaryImage.square')
            }
          },
    secondaryImageUrls: {
      'crop.landscape': {
        url: _get(campaignData, 'secondaryImage.landscape')
      },
      'crop.square': {
        url: _get(campaignData, 'secondaryImage.square')
      }
    },
    agentProfileUrls: {
      'crop.square': {
        url: _get(campaignData, 'agentImage.square')
      }
    },
    logoUrls: {
      original: { url: _get(campaignData, 'agencyLogo') }
    }
  };
});

async function crops(imageData) {
  const src = _get(imageData, ['sizes', 'original', 'url']);
  const crops = _get(imageData, 'crops');

  if (!src || !crops) {
    return {};
  }

  const landscape = await cropSrc(
    _get(imageData, ['sizes', 'original.thumbnail_large']),
    _get(imageData, ['sizes', 'original']),
    crops.landscape
  );
  const square = await cropSrc(
    _get(imageData, ['sizes', 'original.thumbnail_large']),
    _get(imageData, ['sizes', 'original']),
    crops.square
  );

  return {
    landscape,
    square
  };
}

export { prepareAdData, mapPropsToValues, getBannerAdData };

import { Box } from '@rexlabs/box';
import {
  border,
  margin,
  padding,
  StyleSheet,
  useStyles
} from '@rexlabs/styling';
import { Body, Heading, Small, Tiny } from '@rexlabs/text';
import React, { MouseEventHandler, ReactNode } from 'react';
import { AsyncState } from 'react-use/lib/useAsyncFn';
import { abbreviateNumber } from 'src/utils/format';
import { PlanEstimate } from '../limited-budget';
import { LoadingSpinner } from '../loading';
import Money from '../money';
import { HelpIcon } from '../tooltip';

export interface PlanPrice {
  value: number;
  currency_code: string;
}

export interface PlanItemProps {
  name: ReactNode;
  price: PlanPrice;
  duration: number;
  estimate: AsyncState<PlanEstimate>;
  estimateAudience?: string;
  recurring?: boolean;
  onSelect?: MouseEventHandler;
  Header?: ReactNode;
  Footer?: ReactNode;
}

const styles = StyleSheet({
  container: {
    height: '38rem',
    width: '100%',
    backgroundColor: ({ token }) => token('color.pageBackground.alternate'),
    ...padding.styles({
      all: 'xl'
    }),
    ...border.styles({
      all: {
        width: 'thin',
        color: 'container.static.medium',
        radius: 's'
      }
    }),

    '& > * + *': {
      ...margin.styles({
        top: 'xs'
      }),
      ...border.styles({
        top: {
          width: 'thin',
          color: 'container.static.light'
        }
      })
    }
  },

  'text-center': {
    textAlign: 'center'
  },

  estimate: {
    color: ({ token }) => token('palette.brand.600'),
    fontWeight: ({ token }) => token('typography.weight.semibold'),
    fontSize: ({ token }) => token('typography.size.3xl'),
    letterSpacing: ({ token }) => token('typography.letterSpacing.compact'),
    lineHeight: ({ token }) => token('typography.lineHeight.3xl'),
    ...margin.styles({
      y: 'xxs'
    })
  }
});

export function PlanItem({
  name,
  price,
  duration,
  recurring = false,
  estimate,
  estimateAudience,
  onSelect,
  Header,
  Footer
}: PlanItemProps) {
  const s = useStyles(styles);
  const { loading, value, error } = estimate;

  return (
    <Box
      flexDirection='column'
      alignItems='center'
      onClick={onSelect}
      {...s('container')}
    >
      <Box>
        {Header && (
          <Box flex={1} flexDirection='column' justifyContent='stretch'>
            {Header}
          </Box>
        )}

        <Small grey {...s('text-center')}>
          {name}
        </Small>

        <Box flexDirection='column' alignItems='center' justifyContent='center'>
          <Money
            amount={price.value}
            currencyCode={price.currency_code}
            integer
            Tag={({ children }) => (
              <Heading as='span' level='display'>
                {children}
              </Heading>
            )}
          />
          {recurring ? (
            <Body as='span'>per week</Body>
          ) : (
            <Small as='span'>
              {Number.isNaN(duration) ? '-' : duration} days
            </Small>
          )}
        </Box>
      </Box>

      <Box flex={1} alignItems='center' justifyContent='center'>
        {loading ? (
          <Box
            w='100%'
            flexDirection='column'
            justifyContent='center'
            alignItems='center'
          >
            <LoadingSpinner size={25} strokeWidth={5} />
            <Small grey>Loading estimates...</Small>
          </Box>
        ) : error ? (
          <Tiny grey>
            We couldn’t get estimates at this time, but your campaign will still
            run normally and reach as many people as possible with your budget.
          </Tiny>
        ) : (
          value && (
            <Box flexDirection='column' alignItems='center'>
              <Small grey as='span'>
                Estimated {recurring && 'weekly '}ad views
              </Small>
              <Body {...s('estimate')}>
                {abbreviateNumber(value.minReach)} -{' '}
                {abbreviateNumber(value.maxReach)}
                {(value.approximate ?? false) && (
                  <>
                    (approx.) <HelpIcon />
                  </>
                )}
              </Body>
              {estimateAudience && (
                <Small grey as='span'>
                  from potential {estimateAudience}
                </Small>
              )}
            </Box>
          )
        )}
      </Box>

      {Footer && (
        <Box flex={1} flexDirection='column' justifyContent='stretch'>
          {Footer}
        </Box>
      )}
    </Box>
  );
}

/* eslint-disable @typescript-eslint/no-var-requires */
export default {
  UNSUBSCRIBE: {
    config: {
      path: '/notifications/unsubscribe/:token',
      Component: require('view/screens/notifications/unsubscribe').default
    }
  },

  RESUBSCRIBE: {
    config: {
      path: '/notifications/resubscribe/:token',
      Component: require('view/screens/notifications/resubscribe').default
    }
  }
};

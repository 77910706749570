import React from 'react';
import { TextInput as VividTextInput } from '@rexlabs/text-input';
import { StyleSheet } from '@rexlabs/styling';
import { standardStyles } from './text';

const textInputStyles = StyleSheet({
  ...standardStyles,

  container: {
    ...standardStyles.container,
    padding: '0 !important',

    '& > :first-child': {
      paddingLeft: ({ token }) => token('spacing.m'),
      paddingRight: ({ token }) => token('spacing.m')
    }
  },

  suffix: {
    height: '100%',
    padding: ({ token }) => token('spacing.xs'),
    paddingLeft: ({ token }) => token('spacing.m'),
    paddingRight: ({ token }) => token('spacing.m'),
    background: ({ token }) => token('legacy.color.grey.light')
  }
});

const SmallTextInput = (props) => {
  return <VividTextInput styles={textInputStyles} {...props} />;
};

export default SmallTextInput;

import {
  StyleSheet,
  border,
  margin,
  padding,
  useStyles
} from '@rexlabs/styling';
import { Small } from '@rexlabs/text';
import React, { ReactNode } from 'react';
import {
  HTMLPolymorphicProps,
  forwardRef,
  poly
} from 'src/lib/polymorphic-factory';

const styles = StyleSheet({
  container: {
    backgroundColor: ({ token }) => token('color.container.static.contrast'),
    display: 'inline-flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    minHeight: '7.2rem',
    ...padding.styles({
      all: 'm'
    }),
    ...border.styles({
      all: {
        radius: 'm'
      }
    }),

    '> * + *': {
      ...margin.styles({
        left: 'm'
      })
    }
  },

  logo: {
    width: '6rem',
    height: '4rem',

    '> svg': {
      width: '100%',
      height: '100%'
    }
  },

  details: {
    display: 'flex',
    flexDirection: 'column',
    flex: 1
  }
});

export type PaymentMethodCardProps = HTMLPolymorphicProps<'div'> & {
  logo: ReactNode;
  heading: ReactNode;
  subtitle: ReactNode;
};

export const PaymentMethodCard = forwardRef<'div', PaymentMethodCardProps>(
  ({ logo, heading, subtitle, className, style, children, ...rest }, ref) => {
    const s = useStyles(styles, 'PaymentMethodCard');

    return (
      <poly.div
        ref={ref}
        {...s.with('container')({ className, style })}
        {...rest}
      >
        <div {...s('logo')}>{logo}</div>
        <div {...s('details')}>
          <Small as='span'>{heading}</Small>
          <Small as='span' grey>
            {subtitle}
          </Small>
        </div>
        {children}
      </poly.div>
    );
  }
);

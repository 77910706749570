import { Box } from '@rexlabs/box';
import { GhostButton, PrimaryButton } from '@rexlabs/button';
import { query, useModelState, withQuery } from '@rexlabs/model-generator';
import { useToken } from '@rexlabs/styling';
import React from 'react';
import { compose } from 'redux';
import { useFeature } from 'shared/hooks';
import sessionModel from 'src/data/models/custom/session';
import agenciesModel from 'src/data/models/entities/agencies';
import { serializeAddress } from 'src/utils/address';
import { createValidationRules } from 'src/utils/form';
import { addressToMapbox } from 'src/utils/mapbox';
import { ModalStickyButtonGroup } from 'src/view/components/button';
import { Form, ReactForms } from 'src/view/components/form';
import { RenderLoading } from 'src/view/components/loading';
import { Modal } from 'src/view/components/modal';
import withError from 'src/view/containers/with-error';
import EditAgencyForm from 'src/view/forms/settings/edit-agency';

const q = query`{
  ${agenciesModel} {
    id
    business_name
    address
    brand_colours {
      primary
      background
    }
    office_email
    office_phone_number
    logo
    logo_scale
    website
    website_privacy_url
    timezone {
      id
      label
    }
  }
}`;

// NOTE: I thought validations weren't meant to validate fields that aren't
// in the DOM but this wasn't the case so I needed to conditionally add the
// validation for website privacy policy based on the feature flag.
export const validate = (validatePrivacyUrl) => {
  return createValidationRules({
    business_name: ['required', 'company name'],
    brand_colour: ['required', 'brand colour'],
    background_colour: ['required', 'background colour'],
    address: ['required', 'agency address'],
    office_email: ['required|email', 'office email'],
    office_phone_number: ['required', 'office phone number'],
    website: ['required|url', 'website'],
    logo: ['required', 'logo'],
    ...(validatePrivacyUrl
      ? { website_privacy_url: ['required|url', 'privacy policy url'] }
      : {})
  });
};

function EditAgencyModal({
  title = 'Edit Office Profile',
  closeModal,
  onSubmitSuccess,
  agencies,
  errorModal,
  errorModal: { Error }
}) {
  const token = useToken();
  const session = useModelState(sessionModel);
  const canUseFacebookLeadAds = useFeature('facebook_lead_ads', session);
  const useCampaignGoals = useFeature('campaign_goal', session);
  const showPrivacyPolicy = canUseFacebookLeadAds || useCampaignGoals;

  const getInitialValues = () => {
    const data = agencies?.list?.items?.[0];
    return {
      ...data,
      brand_colour: data?.brand_colours?.primary,
      background_colour: data?.brand_colours?.background,
      address: addressToMapbox(data?.address),
      timezone: data?.timezone?.id,
      logo_scale: data?.logo_scale ?? 100
    };
  };

  const handleSubmit = async (values) => {
    try {
      await agencies.updateItem({
        id: values.id,
        data: {
          business_name: values?.business_name,
          brand_colours: {
            primary: values?.brand_colour,
            background: values?.background_colour
          },
          address: serializeAddress(values?.address?.value),
          website: values.website,
          office_email: values.office_email,
          office_phone_number: values.office_phone_number,
          logo: { id: values.logo.id },
          logo_scale: values.logo_scale,
          timezone: values.timezone,
          ...(showPrivacyPolicy
            ? { website_privacy_url: values?.website_privacy_url }
            : {})
        }
      });

      await agencies.refreshItem({
        id: values.id,
        args: { include: 'logo.sizes,address' }
      });

      closeModal();
      onSubmitSuccess?.();
    } catch (e) {
      errorModal.open(e);
    }
  };

  return (
    <Modal title={title} width='85rem' onClose={closeModal}>
      <Box flex={1} flexDirection='column'>
        <RenderLoading isLoading={agencies?.list?.status === 'loading'}>
          <ReactForms
            initialValues={getInitialValues()}
            handleSubmit={handleSubmit}
            validate={validate(showPrivacyPolicy)}
            validateOnMount={true} // DOES NOT WORK!
            touchOnMount={true} // DOES NOT WORK!
          >
            {({ values, submitForm, isSubmitting, setFieldValue }) => (
              <Form name='editAgency'>
                <Box pb={token('spacing.xxl')}>
                  <EditAgencyForm
                    values={values}
                    setFieldValue={setFieldValue}
                    status={agencies?.list?.status}
                    showPrivacyPolicy={showPrivacyPolicy}
                  />
                </Box>
                <ModalStickyButtonGroup>
                  <GhostButton onClick={closeModal}>Cancel</GhostButton>
                  <PrimaryButton
                    form='editAgency'
                    onClick={(e) => {
                      e.preventDefault();
                      submitForm(e);
                    }}
                    isLoading={isSubmitting}
                  >
                    Update
                  </PrimaryButton>
                </ModalStickyButtonGroup>
              </Form>
            )}
          </ReactForms>
        </RenderLoading>
        <Error />
      </Box>
    </Modal>
  );
}

export default compose(withError('errorModal'), withQuery(q))(EditAgencyModal);

import React, { Component } from 'react';
import _isEqual from 'lodash/isEqual';

import InstagramVideo from './video';
import InstagramAd from 'shared/components/instagram-ad';
import ListingInstagramAd from 'shared/components/listing-instagram-ad';
import MultiListingSoldInstagramAd from 'shared/components/multi-listing-sold-instagram-ad';

class InstagramAdPreview extends Component {
  shouldComponentUpdate(nextProps) {
    return !_isEqual(this.props.campaignData, nextProps.campaignData);
  }

  render() {
    const { ad, campaignData } = this.props;
    // format is campaign_type.network.ad_type.ad_variation
    const format = ad?.format?.id ?? '';
    const split = format.split('.');
    const campaignType = split[0];
    const adType = split[2];

    if (adType === 'video') {
      return <InstagramVideo adContentSet={campaignData} />;
    }

    if (campaignType === 'listing') {
      return (
        <ListingInstagramAd
          ad={ad}
          adType={adType}
          adContentSet={campaignData}
        />
      );
    }

    if (campaignType === 'multi_listing_sold') {
      return (
        <MultiListingSoldInstagramAd
          ad={ad}
          adType={adType}
          adContentSet={campaignData}
        />
      );
    }

    return <InstagramAd ad={ad} adType={adType} adContentSet={campaignData} />;
  }
}

export default InstagramAdPreview;

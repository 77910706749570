import { Box } from '@rexlabs/box';
import { withModel } from '@rexlabs/model-generator';
import { styled, StyleSheet } from '@rexlabs/styling';
import moment from 'moment';
import React, { PureComponent } from 'react';
import rexSyncModel from 'src/data/models/entities/rex-sync';
import { withToken } from 'src/theme';
import { ActionMenu } from 'src/view/components/action-menu';
import { Small } from 'src/view/components/text';

const defaultStyles = StyleSheet({
  container: {
    width: '100%',
    padding: ({ token }) => token('spacing.m'),
    flexShrink: 0,

    '> *': {
      flexShrink: 0
    }
  }
});

@withToken
@withModel(rexSyncModel)
@styled(defaultStyles)
class SyncLogsListItem extends PureComponent {
  render() {
    const { styles: s, data, rexSync, token } = this.props;

    const start = data?.start_time?.date || data?.scheduled_time;

    const created = data?.summary?.listings_created || [];
    const deleted = data?.summary?.listings_deleted || [];
    const updated = data?.summary?.listings_updated || [];

    const errors = data?.errors?.items || [];

    if (data?.status?.id === 'scheduled') {
      return (
        <Box
          {...s('container')}
          flexDirection='row'
          alignItems='center'
          justifyContent='space-between'
        >
          <Box flex={1} pr={token('spacing.xl')}>
            <Small>
              {start ? moment(start).format('DD/MM/YYYY h:mma') : '-'}
            </Small>
          </Box>
          <Box width={'57.4rem'} style={{ textAlign: 'center' }}>
            <Small grey>Sync scheduled and pending execution...</Small>
          </Box>
        </Box>
      );
    }

    return (
      <Box
        {...s('container')}
        flexDirection='row'
        alignItems='center'
        justifyContent='space-between'
      >
        <Box flex={1} pr={token('spacing.xl')}>
          <Small>
            {start ? moment(start).format('DD/MM/YYYY h:mma') : '-'}
          </Small>
        </Box>

        <Box style={{ width: '12.6rem', textAlign: 'center' }}>
          <Small grey>{created.length}</Small>
        </Box>
        <Box style={{ width: '12.6rem', textAlign: 'center' }}>
          <Small grey>{deleted.length}</Small>
        </Box>
        <Box style={{ width: '12.6rem', textAlign: 'center' }}>
          <Small grey>{updated.length}</Small>
        </Box>
        <Box style={{ width: '12.6rem', textAlign: 'center' }}>
          <Small grey>{errors.length}</Small>
        </Box>

        <Box
          style={{ width: '7rem' }}
          justifyContent='center'
          alignItems='center'
          onClick={(e) => e.stopPropagation()}
        >
          <ActionMenu
            items={[
              {
                label: 'Download log file',
                onClick: () => {
                  // Since raw logs still require authentication, we can't just
                  // open the endpoint in a new tab, instead we're writing the
                  // API response into the new window manually here
                  const win = window.open('about:blank', data.id);
                  rexSync.fetchRawLogs({ id: data.id }).then(({ data }) => {
                    win.document.write(`<pre>${data}</pre>`);
                  });
                }
              }
            ]}
          />
        </Box>
      </Box>
    );
  }
}

export default SyncLogsListItem;

import { Box } from '@rexlabs/box';
import { styled, StyleSheet } from '@rexlabs/styling';
import React from 'react';
import charLimit from 'shared/utils/char-limit';
import WIZARD from 'src/routes/wizard';
import { formattedBrandName } from 'src/theme';
import { Link } from 'src/utils/whereabouts';
import Avatar from 'src/view/components/avatar';
import { Checkbox } from 'src/view/components/input';
import { Body } from 'src/view/components/text';
import { StatusGoodTag } from '@rexlabs/tag';
import { UTILS } from 'src/theme';
import * as moment from 'moment';

const defaultStyles = StyleSheet({
  container: {
    width: '100%',
    padding: ({ token }) => token('spacing.m'),
    flexShrink: 0
  },

  containerClickable: {
    cursor: 'pointer'
  },

  containerSelected: {
    background: ({ token }) => token('color.container.interactive.selected')
  },

  text: {
    fontSize: '1.8rem',
    margin: 0,
    padding: 0
  },

  textMeta: {
    fontSize: '1.4rem',
    color: ({ token }) => token('legacy.color.blue.grey')
  },

  textSmall: {
    fontSize: '1.2rem',
    color: ({ token }) => token('legacy.color.blue.grey')
  },

  disabled: {
    opacity: 0.4
  },

  soldChip: {
    ...UTILS.TRUNCATE,
    maxWidth: '20rem',
    paddingTop: '0',
    paddingBottom: '0',
    fontWeight: ({ token }) => token('typography.weight.bold'),
    lineHeight: ({ token }) => token('typography.lineHeight.xs'),
    color: ({ token }) => token('color.textStyles.strategyChip.default')
  },

  inlineChipContainer: {
    display: 'inline-block',
    marginRight: '0.6rem'
  }
});

function ListingListItem({ styles: s, selected, disabled, onClick, data }) {
  return (
    <Box
      {...s('container', {
        containerSelected: selected,
        containerClickable: onClick,
        disabled
      })}
      flexDirection='row'
      alignItems='center'
      justifyContent='space-between'
      onClick={onClick}
      data-testid={`listing-${data?.id}`}
    >
      <Avatar wide src={data?.thumbnails?.['thumbnail.choose_listing']?.url} />
      <Box flex={1} flexDirection='column'>
        <Box flex={1} flexDirection='row'>

          {data?.sold_at ? (
            <div {...s('inlineChipContainer')}>
              <StatusGoodTag {...s('soldChip')}>
                Sold {moment(data.sold_at).format("MMM Do 'YY")}
              </StatusGoodTag>
            </div>
          ) : null}

          <Box flex={1} alignItems='center'>
            <Body {...s('text')}>{data?.formatted_address?.short} </Body>
          </Box>
        </Box>

        <Body {...s('text', 'textMeta')}>
          {charLimit(data?.marketing_headline, 90)}
        </Body>

        {data?.data_source?.id === 'spoke' ? (
          <Body {...s('text', 'textSmall')}>
            Created in {formattedBrandName} ∙{' '}
            <Link
              to={WIZARD.CAMPAIGN.EDIT_LISTING}
              params={{ listingId: data?.id }}
            >
              {({ onClick, target }) => (
                <a href={target} onClick={onClick}>
                  Edit
                </a>
              )}
            </Link>
          </Body>
        ) : (
          <Body {...s('text', 'textSmall')}>
            Imported from {data?.data_source?.label}
          </Body>
        )}
      </Box>
      <Box>
        <Checkbox
          id={data.id}
          name={data?.address?.full_address}
          value={!!selected}
          round
        />
      </Box>
    </Box>
  );
}

export default styled(defaultStyles)(ListingListItem);

import { useQuery, UseQueryResult } from 'react-query';
import { useAccountId } from 'src/features/accounts';
import { QueryConfig } from 'src/lib/react-query';
import { api } from 'src/utils/api-client';
import { Audience } from '../types';
import { transformAudienceResponse } from '../utils/schemas';
import { audienceKeys } from './keys';

export type GetCampaignAudienceParams = {
  campaignId: string;
  accountId: string;
};

export const getCampaignAudience = async ({
  accountId,
  campaignId
}: GetCampaignAudienceParams): Promise<Audience> => {
  const { data } = await api.get(
    `/campaigns/${campaignId}/audience-spec`,
    {},
    {
      headers: {
        'X-Account-Id': accountId
      }
    }
  );
  return transformAudienceResponse(data);
};

type QueryFn = typeof getCampaignAudience;

export type UseCampaignAudienceOptions = {
  campaignId: string;
  config?: QueryConfig<QueryFn>;
};

export const useCampaignAudience = ({
  campaignId,
  config = {}
}: UseCampaignAudienceOptions): UseQueryResult<Audience, Error> => {
  const accountId = useAccountId();

  return useQuery<Audience, Error>({
    queryKey: audienceKeys.detail(accountId, campaignId),
    queryFn: () => getCampaignAudience({ accountId, campaignId }),
    ...config
  });
};

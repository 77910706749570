import { StyleSheet, border, focus, useStyles } from '@rexlabs/styling';
import React, { CSSProperties } from 'react';
import {
  HTMLPolymorphicProps,
  forwardRef,
  poly
} from 'src/lib/polymorphic-factory';

const styles = StyleSheet({
  container: {
    display: 'flex',
    position: 'relative',
    minWidth: 0,
    wordWrap: 'break-word',
    ...focus.styles({
      shadow: 'medium'
    }),
    ...border.styles({
      all: {
        radius: () => '0.6rem'
      }
    }),
    backgroundColor: ({ token }) => token('color.container.static.default')
  }
});

export type CardOptions = {
  direction?: CSSProperties['flexDirection'];
  align?: CSSProperties['alignItems'];
  justify?: CSSProperties['justifyContent'];
};

export type CardProps = HTMLPolymorphicProps<'div'> & CardOptions;

export const Card = forwardRef<'div', CardProps>(
  (
    {
      className,
      style,
      direction = 'column',
      justify,
      align,
      children,
      ...rest
    },
    ref
  ) => {
    const s = useStyles(styles, 'Card');
    return (
      <poly.div
        ref={ref}
        {...s.with('container')({
          className,
          style: {
            ...style,
            flexDirection: direction,
            alignItems: align,
            justifyContent: justify
          }
        })}
        {...rest}
      >
        {children}
      </poly.div>
    );
  }
);

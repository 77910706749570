import React, { PureComponent } from 'react';
import { memoize } from 'core-decorators';
import { styled, StyleSheet, keyframes } from '@rexlabs/styling';
import CSSAnimationGroup from '@rexlabs/css-animation-group';

const defaultStyles = StyleSheet({
  wrapScreens: {
    display: 'flex',
    flex: 1,
    flexDirection: 'column',
    position: 'relative',
    width: '100%',

    '& > div:nth-child(2)': {
      width: '100%',
      position: 'absolute'
    }
  }
});

@styled(defaultStyles)
class WizardScreenAnimationGroup extends PureComponent {
  @memoize
  getFadeIn(currStep, prevStep) {
    const startX = currStep ? (currStep > prevStep ? '40px' : '-40px') : 0;
    return keyframes({
      '0%': {
        opacity: 0,
        transform: `translateX(${startX})`
      },
      '50%': {
        opacity: 0,
        transform: `translateX(${startX})`
      },
      '100%': {
        opacity: 1,
        transform: 'translateX(0px)'
      }
    });
  }

  @memoize
  getFadeOut(currStep, prevStep) {
    const targetX = currStep ? (currStep < prevStep ? '40px' : '-40px') : 0;
    return keyframes({
      '0%': {
        opacity: 1,
        transform: 'translateX(0px)'
      },
      '50%': {
        opacity: 0,
        transform: `translateX(${targetX})`
      },
      '100%': {
        opacity: 0,
        transform: `translateX(${targetX})`,
        display: 'none',
        pointerEvents: 'none'
      }
    });
  }

  render() {
    const { children, currStep, prevStep, styles: s } = this.props;

    const fadeIn = this.getFadeIn(currStep, prevStep);
    const fadeOut = this.getFadeOut(currStep, prevStep);

    return (
      <CSSAnimationGroup
        {...s('wrapScreens')}
        leaveAnimation={fadeOut}
        leaveDuration='400ms'
        leaveFillMode='both'
        enterAnimation={fadeIn}
        enterDuration='400ms'
        enterFillMode='both'
        children={children}
      />
    );
  }
}

export default WizardScreenAnimationGroup;

import { ActionMenu, RegularActionMenuItem } from '@rexlabs/action-menu';
import { Box } from '@rexlabs/box';
import { PrimaryCircleButton } from '@rexlabs/button';
import Icons from '@rexlabs/icons-next';
import LoadingSpinner from '@rexlabs/loading-spinner';
import { useEntityListQuery } from '@rexlabs/model-generator';
import { match, useWhereabouts } from '@rexlabs/whereabouts';
import { capitalize } from 'lodash';
import React, { FC, forwardRef, useMemo } from 'react';
import { Link } from 'react-router-dom';
import { campaignTemplatesQuery } from 'src/data/queries/campaign-templates';
import { useCampaignDefinitions } from 'src/features/campaign-definitions';
import WIZARD from 'src/routes/wizard';
import { push } from 'src/utils/whereabouts';

export const CampaignQuickLaunchMenu: FC = () => {
  const whereabouts = useWhereabouts();
  const matched = match(whereabouts, {
    path: '/:accountIndex/:rest(.*)'
  });
  const { data: campaignDefinitions } = useCampaignDefinitions<
    RegularActionMenuItem[]
  >({
    config: {
      select: ({ data }) =>
        data.map(({ label, id }) => ({
          label: (
            <Link
              // @ts-expect-error Params is a part of the
              to={`${matched.params.accountIndex}/campaigns/create/d/${id}/provide-details`}
            >
              {capitalize(label)}
            </Link>
          )
        })),
      placeholderData: {
        data: [],
        pagination: {
          count: 0,
          current_page: 0,
          per_page: 10,
          total: 0,
          total_pages: 0
        }
      }
    }
  });
  const { data: campaignTemplates = [], status } = useEntityListQuery(
    campaignTemplatesQuery
  );
  const campaignItems = useMemo<RegularActionMenuItem[]>(
    () =>
      campaignTemplates.map(({ name, slug }) => ({
        label: name,
        onClick: () => push(WIZARD.CAMPAIGN, { params: { campaignType: slug } })
      })),
    [campaignTemplates]
  );

  const items = useMemo(
    () =>
      status !== 'loaded'
        ? [
            {
              label: (
                <Box justifyContent='center' w='100%'>
                  <LoadingSpinner size={24} strokeWidth={4} />
                </Box>
              )
            }
          ]
        : campaignItems.concat(campaignDefinitions ?? []),
    [campaignDefinitions, campaignItems, status]
  );

  return (
    <ActionMenu
      Icon={Icons.Add}
      Button={forwardRef((props, ref) => (
        <PrimaryCircleButton ref={ref} title='Create new campaign' {...props} />
      ))}
      minWidth='240px'
      items={items}
    />
  );
};

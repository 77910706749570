import { useToken } from '@rexlabs/styling';
import React from 'react';
import { SingleImage } from 'src/view/components/image';

function LogoImageInput(props) {
  const token = useToken();

  return (
    <SingleImage
      {...props}
      overlayText='Replace'
      isSmall
      canCrop={false}
      ratio='square'
      padding={token('spacing.xs')}
    />
  );
}

export default LogoImageInput;

import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { styled, StyleSheet } from '@rexlabs/styling';
import Box from '@rexlabs/box';
import _ from 'lodash';
import { LargeButton } from '../../button';
import GooText from '../../goo-text';
import colorTheme from '../../../utils/color-theme';
import ScaledText from '../../scaled-text';
import { COLORS } from 'src/theme';

const fontFamily = 'Lato, sans-serif';

const defaultStyles = StyleSheet({
  halfPage: {
    border: 'solid 1px darkgray',
    overflow: 'hidden',
    flexGrow: 0,
    flexShrink: 0
  },
  header: {
    position: 'relative'
  },
  img: {
    objectFit: 'cover'
  },
  longHeadlineWrapper: {
    position: 'absolute',
    margin: '15px',
    maxWidth: '226px'
  },
  longHeadline: {
    fontSize: '20px',
    lineHeight: '25px',
    fontFamily,
    fontWeight: 500
  },
  content: {
    background: 'white'
  },
  shortHeadline: {
    fontSize: '34px',
    lineHeight: '38px',
    fontWeight: '300',
    fontFamily
  },
  button: {
    fontSize: '16px',
    fontWeight: '300',
    padding: '11px 16px',
    borderRadius: '3px',
    alignSelf: 'flex-start',
    borderWidth: 0
  },
  footer: {},
  footerLogo: {
    width: 'auto',
    maxWidth: 'calc(0.6 * 300px)',
    maxHeight: 'calc(0.7 * 100px)',
    objectFit: 'contain'
  }
});

@styled(defaultStyles)
class HalfPage extends PureComponent {
  static propTypes = {
    brandColor: PropTypes.string,
    longHeadline: PropTypes.string,
    shortHeadline: PropTypes.string,
    primaryImageUrls: PropTypes.object,
    logoUrls: PropTypes.object,
    buttonText: PropTypes.string
  };

  static defaultProps = {
    buttonText: 'Learn more'
  };

  render() {
    const {
      brandColor,
      backgroundColor,
      longHeadline,
      shortHeadline,
      primaryImageUrls,
      logoUrls,
      buttonText,
      styles: s
    } = this.props;

    const { brandColorBorder } = colorTheme(brandColor);

    return (
      <Box
        width='300px'
        height='600px'
        display='flex'
        flexDirection='column'
        {...s('halfPage')}
      >
        <Box height='265px' {...s('header')}>
          <Box {...s('longHeadlineWrapper')}>
            <GooText padding='6px 8px 3px' borderRadius={1.5}>
              <span {...s.with('longHeadline')({ color: COLORS.BLACK })}>
                {longHeadline}
              </span>
            </GooText>
          </Box>
          <img
            width='100%'
            height={265}
            src={_.get(primaryImageUrls, ['crop.square', 'url'])}
            {...s('img')}
          />
        </Box>
        <Box
          flex={1}
          display='flex'
          flexDirection='column'
          padding='30px 24px'
          {...s('content')}
        >
          <ScaledText
            maxWidth={255}
            maxTextLines={3}
            maxFontSize={36}
            minFontSize={20}
            {...s.with('shortHeadline')({ color: COLORS.BLACK })}
          >
            {shortHeadline}
          </ScaledText>
          <Box alignItems='flex-start' marginTop={24}>
            <LargeButton brandColor={brandColor}>{buttonText}</LargeButton>
          </Box>
        </Box>
        <Box
          height='100px'
          display='flex'
          flexDirection='column'
          alignItems='center'
          justifyContent='center'
          padding='15px 40px'
          {...s.with('footer')({
            backgroundColor: backgroundColor,
            borderTop: brandColorBorder
          })}
        >
          <img src={_.get(logoUrls, 'original.url')} {...s('footerLogo')} />
        </Box>
      </Box>
    );
  }
}

export default HalfPage;

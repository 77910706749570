import React, { FC } from 'react';
import { Body } from '@rexlabs/text';
import { formattedBrandName } from 'src/theme';
import ExampleCsv from '../assets/example-csv.svg';
import { margin, StyleSheet, useStyles } from '@rexlabs/styling';

const styles = StyleSheet({
  container: {
    '& > * + *': {
      ...margin.styles({
        top: 's'
      })
    }
  },

  steps: {
    listStyle: 'disc inside',

    '& > * + *': {
      ...margin.styles({
        top: 's'
      })
    }
  }
});

export const CustomAudienceUploadDescription: FC = () => {
  const s = useStyles(styles, 'CustomAudienceUploadDescription');
  return (
    <div {...s('container')}>
      <Body grey bold>
        Steps to Prepare Your Contact File
      </Body>

      <ul {...s('steps')}>
        <Body as='li' grey>
          Export <strong>at least 100</strong> of your contacts (the more the
          better!) from your CRM into an Excel or Google Spreadsheet. You can
          download a{' '}
          <a
            href='https://docs.google.com/spreadsheets/d/1XKkbXVa05mrMtf7TSIdWfaSX4sBe2qbbGv6c6U3Xq0I'
            target='_blank'
            rel='noreferrer noopener'
          >
            template file here
          </a>
          .
        </Body>
        <Body as='li' grey>
          Remove all columns except for first_name, last_name, email, phone1,
          phone2 and postcode. Note: {formattedBrandName} requires at least an
          email or phone number per contact, but the more data you have for each
          contact the better the audience.
        </Body>
        <Body as='li' grey>
          Remove all columns except for first_name, last_name, email, phone1,
          phone2 and postcode. Note: {formattedBrandName} requires at least an
          email or phone number per contact, but the more data you have for each
          contact the better the audience.
        </Body>
        <Body as='li' grey>
          Save the file as a CSV.
        </Body>
      </ul>

      <Body grey>
        Need Help?{' '}
        <a
          href='https://intercom.help/spokeapp/en/articles/5068286-database-targeting'
          target='_blank'
          rel='noreferrer noopener'
        >
          Click here to watch a short video explainer
        </a>
        .
      </Body>

      <Body bold grey>
        Example
      </Body>
      <ExampleCsv style={{ minHeight: '15.5rem' }} />
    </div>
  );
};

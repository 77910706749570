import { Box } from '@rexlabs/box';
import {
  RenderMatchedRoutes,
  withWhereabouts
} from '@rexlabs/react-whereabouts';
import { styled, StyleSheet, useToken, StylesProvider } from '@rexlabs/styling';
import { ToastTarget } from '@rexlabs/toast';
import React from 'react';
import { compose } from 'redux';
import ROUTES from 'src/routes/embed';
import { Link, replace } from 'src/utils/whereabouts';
import { LinkButton } from 'src/view/components/button';
import { ModalTarget } from 'src/view/components/portal';
import { Body } from 'src/view/components/text';
import { Logo } from 'src/components/elements/logo';
import { toastTargetTokens } from 'src/view/layouts/admin';

const defaultStyles = StyleSheet({
  topNav: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    width: '100%',
    height: '7rem',
    padding: ({ token }) => `0 ${token('spacing.xl')}`,
    background: ({ token }) => token('legacy.color.primary.default')
  },

  logoLink: {
    display: 'flex'
  },

  logo: {
    width: 'auto !important',
    height: '3.4rem !important',
    color: ({ token }) => token('legacy.color.white'),
    '& #logo-door': {
      fill: ({ token }) => token('legacy.color.primary.default')
    }
  },

  content: {
    padding: ({ token }) => token('spacing.m'),
    height: '100%'
  }
});

function DashboardLayout({ whereabouts, styles: s }) {
  const token = useToken();
  return (
    <Box flex={1} flexDirection='column' w='100%'>
      <ModalTarget />

      <Box {...s('topNav')}>
        <Link
          to={ROUTES.CAMPAIGNS_LIST}
          query={{ token: whereabouts.query.token }}
          {...s('logoLink')}
        >
          <Logo type='secondary' {...s('logo')} />
        </Link>
        <LinkButton
          href={`https://app.spokeapp.io/`}
          target='_blank'
          rel='noopener noreferrer'
          style={{
            background: token('legacy.color.primary.dark'),
            height: '4rem'
          }}
        >
          spokeapp.io
        </LinkButton>
      </Box>

      <StylesProvider tokens={toastTargetTokens}>
        <ToastTarget position='top-right' />
      </StylesProvider>

      {whereabouts.query.token ? (
        <Box {...s('content')}>
          <RenderMatchedRoutes
            routes={ROUTES}
            NotFound={() => {
              // Redirect any unknown routes to the login page
              // No 404 page!
              const check = /\/[0-9]+\/campaigns/;
              replace(ROUTES.LOGIN, {
                query:
                  whereabouts.path &&
                  whereabouts.path !== '/' &&
                  !check.exec(whereabouts.path)
                    ? { redirect: whereabouts.path }
                    : undefined,
                hash: ''
              });
              return null;
            }}
          >
            {(matches) => <Box h='100%'>{matches}</Box>}
          </RenderMatchedRoutes>
        </Box>
      ) : (
        <Box justifyContent='center'>
          <Body>No token found.</Body>
        </Box>
      )}
    </Box>
  );
}

export default compose(withWhereabouts, styled(defaultStyles))(DashboardLayout);

import _ from 'lodash';
import { createRestAPIModelGenerator } from 'src/data/models/generator';
import { api } from 'src/utils/api-client';

export const TYPE = 'ads';

// NOTE: this is a hacky escape workaround for the fact that
// many previews aren't actually individual ads, but just
// variation for the same ad, e.g.:
// we place one "single image" ad in facebook, but this ad
// generates two ad types, one for fb and one for Instagram
// So in order to show both variations in the FE preview, we
// map over the ad types here and add variations manually
//
// TODO: we need to think about a better soltion for this, also
// regarding going back to using the preview endpoint in favor
// of mocking the ad previews!
function mapAdsForPreviews(data) {
  const all = data.reduce(
    (all, item) => {
      if ((item?.format?.id ?? '').includes('.facebook.')) {
        all.facebook.push({
          ...item,
          matchNetwork: {
            id: 'facebook'
          }
        });
        // Each facebok ad has an equivalent instagram ad
        // However, we treat those ads as 1 in the BE, so we need to
        // manually split them here
        all.instagram.push({
          ...item,
          id: `${item.id}::instagram`,
          name: item.name.replace('Facebook ', 'Instagram '),
          format: {
            id: item.format.id.replace('.facebook.', '.instagram.'),
            label: item.format.label.replace('Facebook ', 'Instagram ')
          },
          matchNetwork: {
            id: 'instagram'
          },
          network: {
            id: 'instagram',
            label: 'Instagram'
          }
        });
      } else if (
        item?.format?.id.includes('.adwords.responsive') ||
        item?.format?.id.includes('.google.responsive_display_ad')
      ) {
        // Split adwords responsive ad into the differnt format variations
        // it generates on Google side
        ['Native', 'Standard', 'Wide', 'High', 'Small'].forEach((variation) => {
          all.adwords.push({
            ...item,
            id: `${item.id}::adwords:${variation.toLowerCase()}`,
            format: {
              id: `${item.format.id}.${variation.toLowerCase()}`,
              label: `${item.format.label} (${variation})`
            },
            matchNetwork: {
              id: 'adwords'
            }
          });
        });
      } else if (item?.format?.id.includes('.adwords.')) {
        all.adwords.push({
          ...item,
          matchNetwork: {
            id: 'adwords'
          }
        });
      }
      return all;
    },
    { facebook: [], instagram: [], adwords: [] }
  );
  return all.facebook.concat(all.instagram).concat(all.adwords);
}

const config = {
  entities: {
    api: {
      fetchList: (type, args = {}) =>
        // eslint-disable-next-line no-async-promise-executor
        new Promise(async (resolve, reject) => {
          const { data, pagination } = await api
            .get(`/campaigns/${args.campaignId}/ads`, args)
            .catch(reject);

          let allData = data;
          let page = 1;
          while (page < pagination.total_pages) {
            const res = await api
              .get(`/campaigns/${args.campaignId}/ads`, {
                ...args,
                page: ++page
              })
              .catch(reject);
            allData = [...allData, ...res.data];
          }

          resolve({
            data: mapAdsForPreviews(allData)
          });
        })
    }
  }
};

const actionCreators = {
  // NOTE: we are not using this endpoint atm, as we're mocking the
  // ad previews to save requests / not run into request limits as
  // frequent
  loadPreviews: {
    request: ({ campaignId, items = [], data }) =>
      Promise.all(
        items.map((ad) => {
          return api.post(
            `/campaigns/${campaignId}/ads/${ad.id}/preview`,
            data
          );
        })
      ),
    reduce: {
      initial: _.identity,
      success: _.identity,
      failure: _.identity
    }
  }
};

const AdsGenerator = createRestAPIModelGenerator(TYPE, config);
export default AdsGenerator.createEntityModel({ actionCreators });

import _ from 'lodash';
import invariant from 'invariant';

export function getAudienceObject(obj) {
  return obj
    ? {
        id: obj.id,
        networks: obj.networks,
        segments: obj.segments,
        age_range: obj.age_range,
        locations: obj.locations
      }
    : {};
}

async function fetchPackageEstimates(campaignId, pe, set, audienceSpec) {
  try {
    set({
      packageEstimates: {
        loading: true
      }
    });

    const { data: stdPackagesEstimates } = await pe.fetchList({
      id: 'packageEstimates',
      args: { campaignId }
    });

    set({
      packageEstimates: {
        loading: false,
        spec: getAudienceObject(audienceSpec),
        quotes: stdPackagesEstimates.map(
          (packageEstimate) => packageEstimate.item
        ),
        error: null
      }
    });
  } catch (e) {
    set({
      packageEstimates: {
        loading: false,
        spec: getAudienceObject(audienceSpec),
        quotes: null,
        error:
          'Could not get audience estimates for packages! ' +
          'Please try again later.'
      }
    });
  }
}

async function fetchCustomPackageEstimates(
  campaignId,
  pe,
  set,
  audienceSpec,
  customPackage,
  packageEstimates
) {
  try {
    set({
      packageEstimates: {
        loading: true,
        quotes: packageEstimates.quotes
      }
    });

    const { data: customPackageEstimate } = await pe.fetchItem({
      id: customPackage.id,
      args: { campaignId }
    });

    const newQuote = {
      id: customPackageEstimate?.package?.id,
      reach: customPackageEstimate?.estimate?.reach
    };

    const quoteExists = packageEstimates.quotes.find(
      (q) => q.id === newQuote.id
    );

    set({
      packageEstimates: {
        loading: false,
        spec: getAudienceObject(audienceSpec),
        quotes: quoteExists
          ? packageEstimates.quotes.map((q) =>
              q.id === newQuote.id ? newQuote : q
            )
          : packageEstimates.quotes.concat(newQuote),
        error: null
      }
    });
  } catch (e) {
    set({
      packageEstimates: {
        loading: false,
        spec: getAudienceObject(audienceSpec),
        quotes: packageEstimates.quotes,
        error:
          'Could not get audience estimates for packages! ' +
          'Please try again later.'
      }
    });
  }
}

export async function maybeFetchEstimates(props, prevProps) {
  // We only want to load estimates if we don't have any yet (and we didn't already try)
  // or if the audience spec changed...
  const {
    wizard: { packageEstimates, set },
    packageEstimates: pe,
    campaigns,
    customPackages
  } = props;

  invariant(
    packageEstimates && set && pe && campaigns,
    'Insufficient data given to be able to fetch estimates!'
  );

  const campaignId = campaigns?.item?.data?.id;
  const audienceSpec = campaigns?.item?.data?.audience_spec;
  const customPackage = customPackages?.list?.items?.[0] ?? {};
  const customPackageChanged =
    prevProps?.customPackages?.list?.items?.[0]?.updated_at !==
    customPackage.updated_at;

  if (
    (!packageEstimates.quotes ||
      !_.isEqual(packageEstimates.spec, getAudienceObject(audienceSpec))) &&
    !!campaignId &&
    !packageEstimates.loading
  ) {
    await fetchPackageEstimates(campaignId, pe, set, audienceSpec);
  }

  if (
    customPackage.id &&
    !packageEstimates.loading &&
    (!packageEstimates.quotes ||
      !packageEstimates.quotes.find((q) => q.id === customPackage.id) ||
      customPackageChanged)
  ) {
    await fetchCustomPackageEstimates(
      campaignId,
      pe,
      set,
      audienceSpec,
      customPackage,
      packageEstimates
    );
  }
}

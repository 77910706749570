import { Box } from '@rexlabs/box';
import { styled, StyleSheet } from '@rexlabs/styling';
import { autobind } from 'core-decorators';
import React, { PureComponent } from 'react';
import config from 'src/config';
import { withToken } from 'src/theme';
import { LoadingSpinner } from 'src/view/components/loading';
import { Small } from "view/components/text";

let id = 0;
const getId = () => ++id;

let mapboxGl = null;

const defaultStyles = StyleSheet({
  map: {
    height: '40rem',
    width: '100%',
    background: ({ token }) => token('legacy.color.blue.greyLight'),
    overflow: 'hidden',
    position: 'relative',
    zIndex: 1,
    borderRadius: ({ token }) => token('spacing.xs')
  },

  mapFullHeight: {
    height: '100%'
  },

  mapOverlay: {
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    background: ({ token }) => token('legacy.color.blue.greyLight'),
    opacity: 0.5,
    zIndex: 10
  },

  loadingOverlay: {
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    pointerEvents: 'none',
    background: ({ token }) => token('legacy.color.blue.greyLight'),
    color: ({ token }) => token('legacy.color.white'),
    transition: 'opacity .3s',
    opacity: 0,
    zIndex: 11
  },

  activeLoadingOverlay: {
    pointerEvents: 'auto',
    opacity: 1
  }
});

@withToken
@styled(defaultStyles)
@autobind
class MapWithClusters extends PureComponent {
  static defaultProps = {
    style: 'mapbox://styles/rexsoftware/cjs1fdbq00j9t1fp93hki0les',
    show: true,
    zoom: 6,
    showZoom: true
  };

  id = `map-${getId()}`;
  map = null;
  initialised = false;

  constructor() {
    super();

    this.state = {
      ready: false,
      isLoading: true,
      error: null
    };
    // eslint-disable-next-line no-console
    console.log('MapWithClusters.js logging: before import');
    import(/* webpackChunkName: "mapbox-gl" */ 'mapbox-gl')
      .then((mb) => {
        // eslint-disable-next-line no-console
        console.log('MapWithClusters.js logging: after import', { mb });
        mapboxGl = mb.default;
        mapboxGl.accessToken = config.MAPBOX_TOKEN;
        this.setState({ ready: true }, this.init);
      })
      .catch((e) => console.error('dynamic import failed', e));
  }

  init() {
    const { token } = this.props;

    // eslint-disable-next-line no-console
    console.log('MapWithClusters.js logging: init called');
    try {
      this.map = new mapboxGl.Map(this.getInitOptions());
    } catch (e) {
      this.setState({ error: e });
      return;
    }
    // eslint-disable-next-line no-console
    console.log('MapWithClusters.js logging: after new mapboxGl.Map');
    const coords = this.props.data.features.map((f) => f.geometry.coordinates);
    const totalViews = this.props.data.features.reduce(
      (acc, f) => (acc += f.properties.views),
      0
    );
    const lowestViews = this.props.data.features.reduce(
      (acc, f) => (f.properties.views < acc ? f.properties.views : acc),
      totalViews
    );
    const secondIncrement = totalViews / this.props.data.features.length;
    const firstIncrement = (secondIncrement + lowestViews) / 2;
    const thirdIncrement = (firstIncrement + totalViews) / 2;

    if (coords.length > 0) {
      // Pass the first coordinates to `lngLatBounds`
      // wrap each coordinate pair in `extend` to include them in the bounds result
      const bounds = coords.reduce(function (bounds, coord) {
        return bounds.extend(coord);
      }, new mapboxGl.LngLatBounds(coords[0], coords[0]));

      this.map.fitBounds(bounds, { padding: 40, maxZoom: 6 });
    } else {
      this.map.setZoom(0);
    }

    this.map.id = this.id;
    if (this.props.showZoom) {
      this.map.addControl(new mapboxGl.NavigationControl());
    }

    this.map.on('load', () => {
      this.map.addSource('locations', {
        type: 'geojson',
        data: this.props.data,
        cluster: true,
        clusterMaxZoom: 14, // Max zoom to cluster points on
        clusterRadius: 50, // Radius of each cluster when clustering points (defaults to 50)
        clusterProperties: {
          max: ['max', ['get', 'views']],
          sum: ['+', ['get', 'views']]
        }
      });

      this.map.addLayer({
        id: 'clusters',
        type: 'circle',
        source: 'locations',
        filter: ['has', 'sum'],
        paint: {
          'circle-color': [
            'step',
            ['get', 'sum'],
            token('palette.indigo.900'),
            firstIncrement,
            token('palette.indigo.700'),
            secondIncrement,
            token('palette.indigo.500'),
            thirdIncrement,
            token('palette.indigo.300')
          ],
          'circle-radius': [
            'step',
            ['get', 'sum'],
            20,
            firstIncrement,
            25,
            secondIncrement,
            30,
            thirdIncrement,
            35
          ]
        }
      });

      this.map.addLayer({
        id: 'cluster-label',
        type: 'symbol',
        source: 'locations',
        filter: ['has', 'point_count'],
        layout: {
          'text-field': '{sum}',
          'text-font': ['DIN Offc Pro Medium', 'Arial Unicode MS Bold'],
          'text-size': 12
        },
        paint: {
          'text-color': '#ffffff'
        }
      });

      this.map.addLayer({
        id: 'unclustered-point',
        type: 'circle',
        source: 'locations',
        filter: ['!', ['has', 'point_count']],
        paint: {
          'circle-color': [
            'step',
            ['get', 'views'],
            token('palette.indigo.900'),
            firstIncrement,
            token('palette.indigo.700'),
            secondIncrement,
            token('palette.indigo.500'),
            thirdIncrement,
            token('palette.indigo.300')
          ],
          'circle-radius': [
            'step',
            ['get', 'views'],
            20,
            firstIncrement,
            25,
            secondIncrement,
            30,
            thirdIncrement,
            35
          ]
        }
      });

      this.map.addLayer({
        id: 'unclustered-point-label',
        type: 'symbol',
        source: 'locations',
        filter: ['!', ['has', 'point_count']],
        layout: {
          'text-field': '{views}',
          'text-font': ['DIN Offc Pro Medium', 'Arial Unicode MS Bold'],
          'text-size': 12
        },
        paint: {
          'text-color': '#ffffff'
        }
      });
    });

    // inspect a cluster on click
    this.map.on('click', 'clusters', (e) => {
      const features = this.map.queryRenderedFeatures(e.point, {
        layers: ['clusters']
      });
      const clusterId = features[0].properties.cluster_id;
      this.map
        .getSource('locations')
        .getClusterExpansionZoom(clusterId, (err, zoom) => {
          if (err) return;

          this.map.easeTo({
            center: features[0].geometry.coordinates,
            zoom: zoom
          });
        });
    });

    this.map.on('mouseenter', 'clusters', () => {
      this.map.getCanvas().style.cursor = 'pointer';
    });
    this.map.on('mouseleave', 'clusters', () => {
      this.map.getCanvas().style.cursor = '';
    });

    this.map.on('data', (e) => {
      if (this.state.isLoading) {
        // If all required tiles are loaded and the current event has been fired by this map, and there is a marker currently set (makes sure that it hasn't just loaded all the tiles over the ocean)
        if (e.isSourceLoaded && e.target.id === this.map.id) {
          this.setState({ isLoading: false });
        }
      }
    });
  }

  getInitOptions() {
    const options = {
      container: this.id,
      style: this.props.style,
      zoom: this.props.zoom
    };
    return options;
  }

  render() {
    const { styles: s, fullHeight, token } = this.props;
    const { isLoading, error } = this.state;

    if (error) {
      return (
        <Box
          flexDirection='column'
          justifyContent='center'
          alignItems='center'
          {...s('map', { mapFullHeight: fullHeight })}
        >
          <Small grey>
            Oops! Sorry, the map can&apos;t be shown right now.
          </Small>
        </Box>
      );
    }

    return (
      <Box
        style={{
          position: 'relative',
          width: '100%',
          height: fullHeight ? '100%' : 'auto'
        }}
      >
        <Box
          {...s('container')}
          style={{
            visibility: isLoading ? 'hidden' : 'visible',
            height: fullHeight ? '100%' : 'auto'
          }}
        >
          <Box {...s('map', { mapFullHeight: fullHeight })} id={this.id} />
        </Box>
        {isLoading && (
          <Box
            {...s('loadingOverlay', { activeLoadingOverlay: isLoading })}
            flexDirection='column'
            justifyContent='center'
            alignItems='center'
          >
            <LoadingSpinner colors={[token('legacy.color.blue.grey')]} />
          </Box>
        )}
      </Box>
    );
  }
}

export default MapWithClusters;

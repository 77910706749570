import { Description } from '@radix-ui/react-alert-dialog';
import { Body, ExtendedTextProps } from '@rexlabs/text';
import React, { FC, ReactNode } from 'react';

export type AlertDescriptionProps = ExtendedTextProps & {
  children?: ReactNode;
};

export const AlertDescription: FC<AlertDescriptionProps> = ({
  children,
  ...rest
}) => {
  return (
    <Description asChild>
      <Body {...rest}>{children}</Body>
    </Description>
  );
};

import { StyleSheet, useStyles } from '@rexlabs/styling';
import React, { FC, ReactNode } from 'react';
import facebookIconActionMenu from '../../assets/facebook/icon-action-menu.png';
import facebookIconComment from '../../assets/facebook/icon-comment.png';
import facebookIconLike from '../../assets/facebook/icon-like.png';
import facebookIconPublic from '../../assets/facebook/icon-public.png';
import facebookIconShare from '../../assets/facebook/icon-share.png';

export const FB_AD_CONTAINER_WIDTH = 500;

const styles = StyleSheet({
  container: {
    width: `${FB_AD_CONTAINER_WIDTH}px`,
    overflow: 'hidden',
    background: 'white',
    border: '.1rem solid #dddfe2',
    borderRadius: '.3rem',
    flexShrink: 0,
    fontFamily:
      'system-ui, -apple-system, BlinkMacSystemFont, ".SFNSText-Regular", sans-serif'
  },

  header: {
    width: '100%',
    padding: '1.2rem',
    paddingBottom: '0.6rem',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between'
  },

  wrapAccount: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center'
  },

  logo: {
    width: '4rem',
    height: '4rem',
    marginRight: '.8rem',
    background: 'transparent none center center no-repeat',
    backgroundSize: 'contain',
    border: '.4rem solid transparent'
  },

  wrapAccountName: {
    display: 'flex',
    flexDirection: 'column'
  },

  accountName: {
    fontSize: '1.3rem',
    fontWeight: 700
  },

  sponsored: {
    color: 'grey',
    fontSize: '1.2rem',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center'
  },

  public: {
    display: 'inline-block',
    width: '1rem',
    height: '1rem',
    background: `url(${facebookIconPublic}) transparent no-repeat center center`,
    backgroundSize: 'contain',
    marginLeft: '.2rem'
  },

  actionMenu: {
    width: '1.6rem',
    height: '1.6rem',
    background: `url(${facebookIconActionMenu}) transparent no-repeat center center`,
    backgroundSize: 'contain'
  },

  wrapDescription: {
    padding: '1.2rem',
    paddingTop: '0.6rem'
  },

  description: {
    fontSize: '1.4rem',
    lineHeight: '1.38',
    fontWeight: 'normal',
    margin: 0,
    padding: 0,
    whiteSpace: 'pre-wrap'
  },

  more: {
    color: '#365899'
  },

  wrapActions: {
    padding: '1.2rem',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-around'
  },

  action: {
    color: 'grey',
    fontSize: '1.3rem',
    fontWeight: 700,
    border: '0 none',
    background: 'transparent',
    padding: 0,
    margin: '0 .8rem',
    position: 'relative',
    paddingLeft: '2rem',

    ':before': {
      content: ' ',
      position: 'absolute',
      width: '1.6rem',
      height: '1.6rem',
      top: '50%',
      left: 0,
      marginTop: '-.8rem',
      background: 'none center center no-repeat transparent',
      backgroundSize: 'contain'
    }
  },
  wrapText: {
    width: '100%',
    background: '#f6f7f9',
    borderBottom: '.1rem solid #d8d8d8',
    borderTop: '0 none',
    padding: '1rem 1.2rem',
    whiteSpace: 'normal',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between'
  },

  wrapTextDetails: {
    maxWidth: '80%',
    maxHeight: '66px',
    overflow: 'hidden',
    marginRight: '1.2rem'
  },

  title: {
    fontSize: '1.6rem',
    lineHeight: '2rem',
    maxHeight: '11rem',
    wordWrap: 'break-word',
    fontWeight: 600,
    marginBottom: '.5rem',
    padding: 0,
    fontFamily:
      'system-ui, -apple-system, BlinkMacSystemFont, ".SFNSText-Regular", sans-serif'
  },

  headline: {
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    fontSize: '1.2rem',
    lineHeight: '1.8rem',
    maxHeight: '8rem',
    padding: 0
  },

  website: {
    color: '#606770',
    fontSize: '1.2rem',
    lineHeight: '1.1rem',
    textTransform: 'uppercase',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap'
  },

  wrapLearnMore: {
    display: 'flex',
    alignItems: 'center'
  },

  learnMore: {
    border: '.1rem solid #ced0d4',
    background: '#f6f7f9',
    color: '#4b4f56',
    borderRadius: '.2rem',
    padding: '0 .8rem',
    fontSize: '1.2rem',
    lineHeight: '2.2rem',
    marginTop: '.2rem',
    whiteSpace: 'nowrap',
    textShadow: 'none',
    verticalAlign: 'middle',
    fontWeight: 'bold',
    boxSizing: 'content-box'
  },

  actionLike: {
    ':before': {
      backgroundImage: `url(${facebookIconLike})`
    }
  },

  actionComment: {
    ':before': {
      backgroundImage: `url(${facebookIconComment})`
    }
  },

  actionShare: {
    ':before': {
      backgroundImage: `url(${facebookIconShare})`
    }
  },

  iframeContainer: {
    width: `${FB_AD_CONTAINER_WIDTH}px`,
    height: `${FB_AD_CONTAINER_WIDTH}px`,
    overflow: 'hidden'
  }
});

export type FacebookAdContainerProps = {
  description?: string;
  descriptionLimit?: number;
  agencyLogo?: string;
  logoBgColor?: string;
  pageName: string;
  website: string;
  title: string;
  headline: string;
  children: ReactNode;
};

export const FacebookAdContainer: FC<FacebookAdContainerProps> = (props) => {
  const s = useStyles(styles);
  const {
    children,
    description = '',
    descriptionLimit = 150,
    agencyLogo,
    logoBgColor,
    pageName,
    website,
    title,
    headline
  } = props;

  const truncateDescription = description?.length > descriptionLimit;
  const descriptionText = truncateDescription
    ? `${description.substr(0, descriptionLimit)}...`
    : description;

  return (
    <div {...s('container')} data-testid={'fb-ad-container'}>
      <div {...s('header')}>
        <div {...s('wrapAccount')}>
          <div
            {...s.with('logo')({
              backgroundImage: agencyLogo ? `url(${agencyLogo})` : 'none',
              backgroundColor: logoBgColor ?? 'transparent'
            })}
          />
          <div {...s('wrapAccountName')}>
            <span {...s('accountName')}>{pageName}</span>
            <div {...s('sponsored')}>
              <span>Sponsored ∙ </span>
              <span {...s('public')} />
            </div>
          </div>
        </div>
        <div {...s('actionMenu')} />
      </div>

      {descriptionText ? (
        <div {...s('wrapDescription')} data-testid={'description-text'}>
          <h2 {...s('description')}>
            <span>
              {descriptionText}
              {truncateDescription ? (
                <span {...s('more')}>See More</span>
              ) : null}
            </span>
          </h2>
        </div>
      ) : null}

      <div {...s('iframeContainer')}>{children}</div>

      <div {...s('wrapText')}>
        <div {...s('wrapTextDetails')}>
          <span {...s('website')}>{website}</span>
          <h1 {...s('title')} data-testid={'title-text'}>
            {title}
          </h1>
          <span {...s('headline')} data-testid={'headline-text'}>
            {headline}
          </span>
        </div>
        <div {...s('wrapLearnMore')}>
          <button {...s('learnMore')}>Learn More</button>
        </div>
      </div>

      <div {...s('wrapActions')}>
        <button {...s('action', 'actionLike')}>Like</button>
        <button {...s('action', 'actionComment')}>Comment</button>
        <button {...s('action', 'actionShare')}>Share</button>
      </div>
    </div>
  );
};

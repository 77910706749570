import { createRestAPIModelGenerator } from 'src/data/models/generator';
import { api } from 'src/utils/api-client';
import { cropEntityImages } from 'src/utils/images';

export const TYPE = 'agents';

const config = {
  entities: {
    related: {
      thumbnails: {
        include: 'thumbnails'
      },
      images: {
        include: 'images.sizes'
      },
      suburbs: {
        include: 'suburbs'
      },
      videos: {
        include: 'videos.thumbnail.sizes'
      }
    },

    // Overriding fetch actions, since we want to crop images when fetching
    // campaign data (see hack note in util)
    api: {
      fetchList: (type, args) =>
        api
          .get('/agents', args)
          .then(({ data, ...rest }) =>
            Promise.all(
              data.length ? data.map((item) => cropEntityImages(item)) : []
            ).then((data) => ({ ...rest, data }))
          ),
      fetchItem: (type, args, id) =>
        api
          .get(`/agents/${id}`, args)
          .then(({ data, ...rest }) =>
            cropEntityImages(data).then((data) => ({ ...rest, data }))
          )
    }
  }
};

const AgentsModelGenerator = createRestAPIModelGenerator(TYPE, config);
export default AgentsModelGenerator.createEntityModel();

import { property } from 'lodash';
import React, { forwardRef, useCallback } from 'react';
import {
  FileUploadFn,
  SimpleFileUploadInput,
  SimpleFileUploadInputProps
} from 'src/components/elements/file-upload-input';
import { useUploadCSVFile } from '../api/createCSVUpload';
import { Upload } from '../types';
import { getUploadPreviewData } from './SimpleFileUploadInput';

const CSV_ACCEPTS = 'text/csv,text/plain';

export type CSVUploadInputProps = Omit<
  SimpleFileUploadInputProps<Upload>,
  'accepts' | 'onFileSelect' | 'getFileKey' | 'getPreviewData'
>;

export const CSVUploadInput = forwardRef<HTMLInputElement, CSVUploadInputProps>(
  (props, ref) => {
    const { mutateAsync } = useUploadCSVFile();
    const fileUploader = useCallback<FileUploadFn<Upload>>(
      (file) => mutateAsync({ file }),
      [mutateAsync]
    );

    return (
      <SimpleFileUploadInput
        ref={ref}
        accept={CSV_ACCEPTS}
        onFileSelect={fileUploader}
        getFileKey={property('id')}
        getPreviewData={getUploadPreviewData}
        {...props}
      />
    );
  }
);

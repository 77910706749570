import React, { forwardRef, ReactNode } from 'react';

export type ListItemProps = JSX.IntrinsicElements['li'] & {
  posinset: number;
  selected?: boolean;
  children?: ReactNode;
};

export const ListItem = forwardRef<HTMLLIElement, ListItemProps>(
  ({ selected = false, posinset, children, ...rest }, ref) => {
    return (
      <li
        ref={ref}
        role='option'
        aria-selected={selected ? 'true' : 'false'}
        aria-posinset={posinset}
        {...rest}
      >
        {children}
      </li>
    );
  }
);

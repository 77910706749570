import { createRestAPIModelGenerator } from 'src/data/models/generator';
import { api } from 'src/utils/api-client';

export const TYPE = 'facebookActivity';

const config = {
  entities: {
    api: {
      fetchList: (type, args) =>
        api.get(`/campaigns/${args.campaignId}/facebook-activity`, args)
    }
  }
};

const FbActivityGenerator = createRestAPIModelGenerator(TYPE, config);
export default FbActivityGenerator.createEntityModel();

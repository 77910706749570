import { z } from 'zod';

export const VENDOR_REPORT_SECTIONS = [
  'ads_preview',
  'audience_details',
  'campaign_performance',
  'status_and_package'
] as const;

export type ReportSection = typeof VENDOR_REPORT_SECTIONS[number];

export const shareReportSchema = z.object({
  email: z.string().email(),
  sections: z.array(z.enum(VENDOR_REPORT_SECTIONS)).nonempty(),
  message: z.string().optional()
});

export type ShareReportOptions = z.infer<typeof shareReportSchema>;

export interface VendorReportShareTokenResponse {
  token: string;
}

/* eslint-disable max-lines */
/*
|-------------------------------------------------------------------------------
| App "theme"
|-------------------------------------------------------------------------------
|
| The theme of an app encompasses:
|  1. Design Rules
|  2. Style Utilities
|
| These are here so we can apply discipline to styling our app, and avoid
| spaghetti styling code.
|
*/
import { keyframes, reset } from '@rexlabs/styling';
import { fontFace, placeholders } from 'src/utils/styling';
import { injectGlobal } from 'styled-components';

import { init } from '@rexlabs/theme-luna';
import CircularBlack from 'src/assets/fonts/circular-pro/CircularPro-Black.otf';
import CircularBold from 'src/assets/fonts/circular-pro/CircularPro-Bold.otf';
import CircularLight from 'src/assets/fonts/circular-pro/CircularPro-Book.otf';
import CircularRegular from 'src/assets/fonts/circular-pro/CircularPro-Medium.otf';

import { noop } from 'lodash';
import { brand, getBrandMeta } from './brand';

export const brandName = brand;

export const formattedBrandName = getBrandMeta(brand).name;

export const isSpoke = brand === 'reach';

const BASE_COLORS = {
  PRIMARY: '#00c278',
  PRIMARY_LIGHT: '#00d176',
  PRIMARY_DARK: '#00b570',
  PRIMARY_10: 'rgba(0, 194, 120, 0.1)',
  PRIMARY_80: 'rgba(0, 194, 120, 0.8)',
  SECONDARY: '#00c278',
  SECONDARY_LIGHT: '#00C278',
  SECONDARY_LIGHTER: '#00DB88',
  SECONDARY_LIGHTEST: '#00EB91',
  SECONDARY_10: 'rgba(0, 194, 120, 0.1)',
  SECONDARY_50: 'rgba(0, 194, 120, 0.5)',
  SECONDARY_80: 'rgba(0, 194, 120, 0.8)',

  SPOKE: '#00c278',
  GREEN_DARK: '#00b570',

  RED: '#f65c5c',
  RED_DARK: '#cf4040',
  RED_LIGHT: '#ff7878',
  RED_PALE: '#ffe0e0',

  YELLOW: '#f5e593',

  ORANGE: '#f5b300',
  ORANGE_LIGHT: '#fff3c6',

  BROWN: '#825F00',

  BLUE_DARK: '#002842',
  BLUE_GREY: '#8aa0ae',
  BLUE_GREY_LIGHT: '#ccd6de',

  GREY: '#dee6ec',
  GREY_LIGHT: '#f0f5f7',
  GREY_VERY_LIGHT: '#fafcfd',
  GREY_MEDIUM: '#e0e5e7',

  WHITE: '#fff',
  BLACK: '#000',

  BACKGROUND: '#f0f5f7',

  FACEBOOK_BLUE: '#486bb3',
  SITELOFT_YELLOW: '#e9bb00',
  CRM_BLUE: '#008dfc',

  PURPLE: '#d894e6',
  PURPLE_LIGHT: '#eac5f1',
  PURPLE_DARK: '#ab7bb4',

  BLUE: '#0080ca',

  BLUE_STATS: '#4a90e2',
  RED_STATS: '#f84c4c'
};

const BRAND_COLORS = {
  reach: {
    ...BASE_COLORS
  },

  flink: {
    ...BASE_COLORS,
    PRIMARY: '#401663',
    PRIMARY_LIGHT: '#612196',
    PRIMARY_DARK: '#30104A',
    PRIMARY_10: 'rgba(64, 22, 99, 0.1)',
    PRIMARY_80: 'rgba(64, 22, 99, 0.8)',
    SECONDARY: '#EC008C',
    SECONDARY_LIGHT: '#FF33AC',
    SECONDARY_LIGHTER: '#FF66C1',
    SECONDARY_LIGHTEST: '#FF99D6',
    SECONDARY_10: 'rgba(236, 0, 140, 0.1)',
    SECONDARY_50: 'rgba(236, 0, 140, 0.5)',
    SECONDARY_80: 'rgba(236, 0, 140, 0.8)'
  }
};

export const COLORS = BRAND_COLORS[brand];

export const PADDINGS = {
  TINY: '.4rem',
  XXS: '.8rem',
  XS: '1.6rem',
  S: '2.4rem',
  M: '3.2rem',
  L: '4rem',
  XL: '4.8rem',
  XXL: '5.6rem'
};

export const DIMENSIONS = {
  MAX_WIDTH: '120rem'
};

export const FONT = {
  WEIGHTS: {
    LIGHT: 300,
    NORMAL: 400,
    SEMIBOLD: 700,
    BOLD: 900
  }
};

export const BORDER_RADIUS = {
  INPUT: '.3rem',
  LARGE: '.8rem'
};

export const SHADOWS = {
  AUTH_BOX: '0 .2rem .7rem rgba(171, 171, 171, .28)',
  TOOLTIP: '0 .5rem 1.2rem rgba(0, 40, 66, .3)',
  TOOLTIP_ARROW: '-.2rem .2rem .2rem 0 rgba(0, 40, 66, .02)',
  MAP: '0 .6rem .8rem rgba(138, 160, 174, .15)',
  PLAN: '0 .9rem 1.3rem rgba(138, 160, 174, .27)',
  PLAN_NONE: '0 0 0 rgba(138, 160, 174, .27)',
  BUTTON_BAR: 'rgba(0, 0, 0, 0.3) 0 -2rem 3rem -3rem',
  MODAL: '0 .9rem 2rem rgba(0, 0, 0, .24)',
  REPORT: '0 .2rem .8rem rgba(222, 230, 236, 1)',
  WIZARD_SELECT: '0 .2rem .8rem rgba(138, 160, 174, .5)'
};

export const TEXTS = {
  HEADING: {
    fontSize: '3.2rem',
    lineHeight: '4rem',
    fontWeight: FONT.WEIGHTS.SEMIBOLD,
    marginTop: PADDINGS.XS,
    marginBottom: PADDINGS.XXS,
    letterSpacing: '-.02rem'
  },

  SUBHEADING: {
    fontSize: '2.4rem',
    lineHeight: '3rem',
    fontWeight: FONT.WEIGHTS.SEMIBOLD,
    letterSpacing: '-.02rem'
  },

  BODY: {
    fontSize: '1.6rem',
    lineHeight: '2.4rem',
    fontWeight: FONT.WEIGHTS.LIGHT,
    letterSpacing: '-.04rem'
  },

  SMALL: {
    fontSize: '1.6rem',
    lineHeight: '1.9rem',
    fontWeight: FONT.WEIGHTS.LIGHT,
    letterSpacing: '-.02rem'
  },

  TINY: {
    fontSize: '1.4rem',
    lineHeight: '1.8rem',
    fontWeight: FONT.WEIGHTS.LIGHT,
    letterSpacing: '-.02rem'
  },

  CAPITALIZED: {
    fontSize: '1rem',
    lineHeight: '1.2rem',
    fontWeight: FONT.WEIGHTS.BOLD,
    letterSpacing: '-.02rem'
  },

  LABEL: {
    fontSize: '1.4rem',
    lineHeight: '1.6rem',
    fontWeight: FONT.WEIGHTS.SEMIBOLD,
    color: COLORS.BLUE_DARK,
    display: 'flex',
    marginBottom: PADDINGS.TINY,
    marginTop: PADDINGS.XS,
    letterSpacing: '-.02rem'
  },

  PLACEHOLDER: {
    fontSize: '1.8rem',
    lineHeight: '2.4rem',
    fontWeight: FONT.WEIGHTS.LIGHT,
    color: COLORS.BLUE_GREY_LIGHT,
    letterSpacing: '-.04rem'
  }
};

export const UTILS = {
  TRUNCATE: {
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    display: 'block'
  }
};

export const GRID = {
  GUTTER: '1.2rem',
  GUTTER_WIZARD: '.8rem'
};

export const KEYFRAMES = {
  FADE_IN: keyframes({
    '0%': { opacity: 0 },
    '100%': { opacity: 1 }
  }),

  FADE_OUT: keyframes({
    '0%': { opacity: 1 },
    '100%': { opacity: 0 }
  }),

  SCREEN_FADE_IN: keyframes({
    '0%': { opacity: 0 },
    '50%': { opacity: 0 },
    '100%': { opacity: 1 }
  }),

  SCREEN_FADE_OUT: keyframes({
    '0%': { opacity: 1 },
    '50%': { opacity: 0 },
    '100%': { opacity: 0, display: 'none', pointerEvents: 'none' }
  }),

  CAROUSEL_FADE_IN_PREV: keyframes({
    '0%': { opacity: 0, transform: 'translateX(-40rem)' },
    '10%': { opacity: 0, transform: 'translateX(-40rem)' },
    '100%': { opacity: 1, transform: 'translateX(0)' }
  }),

  CAROUSEL_FADE_OUT_PREV: keyframes({
    '0%': { opacity: 1, transform: 'translateX(0)' },
    '90%': { opacity: 0, transform: 'translateX(40rem)' },
    '100%': { opacity: 0, transform: 'translateX(40rem)' }
  }),

  CAROUSEL_FADE_IN_NEXT: keyframes({
    '0%': { opacity: 0, transform: 'translateX(40rem)' },
    '10%': { opacity: 0, transform: 'translateX(40rem)' },
    '100%': { opacity: 1, transform: 'translateX(0)' }
  }),

  CAROUSEL_FADE_OUT_NEXT: keyframes({
    '0%': { opacity: 1, transform: 'translateX(0)' },
    '90%': { opacity: 0, transform: 'translateX(-40rem)' },
    '100%': { opacity: 0, transform: 'translateX(-40rem)' }
  }),

  MODAL_FADE_IN: keyframes({
    '0%': { opacity: 0, transform: 'scale(0.6)' },
    '100%': { opacity: 1, transform: 'scale(1)' }
  }),

  MODAL_FADE_OUT: keyframes({
    '0%': { opacity: 1, transform: 'scale(1)' },
    '100%': { opacity: 0, transform: 'scale(0.6)' }
  }),

  WIZARD_MODAL_FADE_IN: keyframes({
    '0%': { opacity: 0, transform: 'scale(0.8)' },
    '100%': { opacity: 1, transform: 'scale(1)' }
  }),

  WIZARD_MODAL_FADE_OUT: keyframes({
    '0%': { opacity: 1, transform: 'scale(1)' },
    '100%': {
      opacity: 0,
      transform: 'scale(0.9)',
      display: 'none',
      pointerEvents: 'none'
    }
  }),

  LOADING_FADE_IN: keyframes({
    '0%': { opacity: 0, transform: 'scale(0)' },
    '30%': { opacity: 0.5, transform: 'scale(0)' },
    '100%': { opacity: 1, transform: 'scale(1)' }
  }),

  LOADING_FADE_OUT: keyframes({
    '0%': { opacity: 1, transform: 'scale(1)' },
    '100%': { opacity: 0, transform: 'scale(0)' }
  }),

  BACKDROP_FADE_IN: keyframes({
    '0%': { opacity: 0 },
    '100%': { opacity: 0.2 }
  }),

  BACKDROP_FADE_OUT: keyframes({
    '0%': { opacity: 0.2 },
    '100%': { opacity: 0 }
  }),

  LIST_ITEM_ENTER: keyframes({
    '0%': { opacity: 0, transform: 'translateY(15px)' },
    60: { opacity: 0.5, transform: 'translateY(0px)' },
    '100%': { opacity: 1, transform: 'translateY(0px)' }
  })
};

export const EASINGS = {
  MODAL_FADE_IN: 'cubic-bezier(0.175, 0.885, 0.32, 1.15)',
  MODAL_FADE_OUT: 'cubic-bezier(0.165, 0.84, 0.44, 1)'
};

export const BREAKPOINTS = {
  PHONE_ONLY: '@media screen and (max-width: 599px)',
  TABLET_PORTRAIT_UP: '@media screen and (min-width: 600px)',
  TABLET_LANDSCAPE_UP: '@media screen and (min-width: 900px)',
  DESKTOP_UP: '@media screen and (min-width: 1200px)',
  BIG_DESKTOP_UP: '@media screen and (min-width: 1800px)',

  REPORT: {
    // old breakpoint DO NOT USE looking to remove usage
    TABLET: '@media screen and (max-width: 900px)'
  }
};

export function loadFonts() {
  return `
    ${fontFace('Circular Pro', CircularLight, FONT.WEIGHTS.LIGHT, 'normal')}
    ${fontFace('Circular Pro', CircularRegular, FONT.WEIGHTS.NORMAL, 'normal')}
    ${fontFace('Circular Pro', CircularBold, FONT.WEIGHTS.SEMIBOLD, 'normal')}
    ${fontFace('Circular Pro', CircularBlack, FONT.WEIGHTS.BOLD, 'normal')}
  `;
}

export function initTheme(tokens) {
  reset();
  init({ tokens });
  // eslint-disable-next-line @typescript-eslint/no-unused-expressions
  injectGlobal`
    ${loadFonts()}

    body {
      font-family: 'Circular Pro', serif;
      font-size: 1.5rem;
      line-height: 1.4;
      height: 100%;
      width: 100%;
      font-weight: ${FONT.WEIGHTS.LIGHT};
      text-decoration-skip: ink;
      text-rendering: optimizelegibility;
    }

    input, textarea, select, button {
      font: inherit;
    }

    b, strong {
      font-weight: ${FONT.WEIGHTS.BOLD};
    }

    ${placeholders(`
      color: ${COLORS.BLUE_GREY_LIGHT};
    `)}

    input:-webkit-autofill,
    input:-webkit-autofill:hover, 
    input:-webkit-autofill:focus,
    input:-webkit-autofill, 
    textarea:-webkit-autofill,
    textarea:-webkit-autofill:hover,
    textarea:-webkit-autofill:focus,
    select:-webkit-autofill,
    select:-webkit-autofill:hover,
    select:-webkit-autofill:focus {
      -webkit-box-shadow: 0 0 0 1000rem ${COLORS.WHITE} inset;
      -webkit-text-fill-color: ${COLORS.BLUE_DARK} !important;
    }

    #app {
      position: absolute;
      top: 0;
      left: 0;
      bottom: 0;
      right: 0;
      overflow: auto;
      display: flex;
    }

    sup {
      font-size: 50%;
      display: inline-block;
      vertical-align: super
    }

    @media print {
      @page {
        size: A4;
      }

      html {
        width: 210mm !important;
        height: 100%;
      }

      body {
        width: 210mm !important;
      }

      #app {
        width: 210mm !important;
        overflow: visible !important;
        display: block !important;
        position: static !important;
      }
    }
  `;
}

export const initFonts = noop;

export * from './with-token';

import React, { PureComponent, Fragment } from 'react';
import PropTypes from 'prop-types';
import { styled, StyleSheet } from '@rexlabs/styling';
import Box from '@rexlabs/box';
import { SmallButton } from '../../button';
import _ from 'lodash';
import colorTheme from '../../../utils/color-theme';
import GooText from '../../goo-text';
import ScaledText from '../../scaled-text';
import charLimit, { limits } from '../../../utils/char-limit';
import { COLORS } from 'src/theme';

export const types = {
  DEFAULT: 'default',
  SMALL: 'small'
};

const fontFamily = 'Lato, sans-serif';
const defaultStyles = StyleSheet({
  square: {
    display: 'flex',
    flexDirection: 'column',
    border: '1px solid darkgray',
    overflow: 'hidden'
  },
  header: {
    height: '190px',
    width: '100%',
    backgroundSize: 'cover',
    backgroundPosition: 'center',
    position: 'relative'
  },
  textWrapper: {
    position: 'absolute',
    // margin: '10px 6px'
    top: '10px',
    left: '6px'
  },
  smallText: {
    fontFamily,
    fontSize: '12px',
    fontWeight: 600,
    lineHeight: '23px',
    colors: `${COLORS.BLACK}`,
    backgroundColor: 'white',
    textTransform: 'uppercase'
  },
  shortHeadline: {
    fontFamily,
    fontWeight: 400,
    fontSize: '20px',
    color: `${COLORS.BLACK}`,
    backgroundColor: 'white',
    boxDecorationBreak: 'clone'
  },
  footer: {
    display: 'flex',
    height: '60px',
    padding: '14px'
  },
  footerLogo: {
    objectFit: 'contain'
  }
});

@styled(defaultStyles)
export default class Square extends PureComponent {
  static propTypes = {
    shortHeadline: PropTypes.string,
    bannerAdText: PropTypes.string,
    primaryImageUrls: PropTypes.object,
    logoUrls: PropTypes.object,
    brandColor: PropTypes.string,
    backgroundColor: PropTypes.string,
    buttonText: PropTypes.string,
    type: PropTypes.oneOf(Object.values(types))
  };

  static defaultProps = {
    type: types.DEFAULT,
    buttonText: 'View Property'
  };

  button() {
    const { buttonText, brandColor, type } = this.props;
    if (type === types.DEFAULT) {
      return (
        <Box style={{ minWidth: '113px' }}>
          <SmallButton brandColor={brandColor}>{buttonText}</SmallButton>
        </Box>
      );
    }
  }

  footerJustifyContent() {
    const { type } = this.props;
    switch (type) {
      case types.SMALL:
        return { justifyContent: 'center' };
      case types.DEFAULT:
      default:
        return { justifyContent: 'space-between', alignItems: 'center' };
    }
  }

  dimensions() {
    const { type } = this.props;
    switch (type) {
      case types.SMALL:
        return { width: '200px', height: '200px' };
      case types.DEFAULT:
      default:
        return { width: '250px', height: '250px' };
    }
  }

  logoDimensions() {
    const { type } = this.props;
    switch (type) {
      case types.SMALL:
        return {
          maxWidth: 'calc(0.6 * 200px)',
          marginRight: '0',
          maxHeight: 'calc(0.7 * 58px)'
        };
      case types.DEFAULT:
      default:
        return {
          maxWidth: '80%',
          maxHeight: 'calc(0.7 * 60px)'
        };
    }
  }

  render() {
    const {
      shortHeadline,
      smallText,
      primaryImageUrls,
      logoUrls,
      brandColor,
      backgroundColor,
      type,
      styles: s
    } = this.props;

    const { brandColorBorder } = colorTheme(brandColor);
    const isSmall = type === types.SMALL;

    return (
      <Box
        {...s.with('square')({
          ...this.dimensions()
        })}
      >
        <Box
          {...s.with('header')({
            backgroundImage: `url('${_.get(primaryImageUrls, [
              'crop.square',
              'url'
            ])}')`
          })}
        >
          <Box {...s('textWrapper')} width={isSmall ? 180 : 220}>
            <GooText
              borderRadius={3}
              padding={isSmall ? '3px 4px' : '4px 8px 6px'}
            >
              <Fragment>
                <strong {...s('smallText')}>
                  {charLimit(smallText, limits.SMALL_TEXT)}
                </strong>
                {smallText && shortHeadline ? <br /> : undefined}
                <ScaledText
                  maxWidth={isSmall ? 190 : 220}
                  maxTextLines={2}
                  maxFontSize={isSmall ? 22 : 24}
                  minFontSize={isSmall ? 12 : 14}
                  {...s.with('shortHeadline')({
                    lineHeight: isSmall ? 1.6 : 1.5
                  })}
                >
                  {shortHeadline}
                </ScaledText>
              </Fragment>
            </GooText>
          </Box>
        </Box>
        <Box
          {...s.with('footer')({
            ...this.footerJustifyContent(),
            backgroundColor: backgroundColor,
            borderTop: brandColorBorder
          })}
        >
          <Box
            alignItems='center'
            style={{ marginRight: `${type === types.DEFAULT ? '14px' : ''}` }}
          >
            <img
              {...s.with('footerLogo')({ ...this.logoDimensions() })}
              src={_.get(logoUrls, 'original.url')}
            />
          </Box>
          {this.button()}
        </Box>
      </Box>
    );
  }
}

import React, { lazy } from 'react';
import { StepDefinition } from '../types';

const AudienceStepPreview = lazy(
  () => import('../components/steps/AudienceStepPreview')
);
const AudienceStepEditor = lazy(
  () => import('../components/steps/AudienceStepEditor')
);
const AdsStepPreview = lazy(() => import('../components/steps/AdsStepPreview'));
const AdsStepEditor = lazy(() => import('../components/steps/AdsStepEditor'));
const LandingPagePreview = lazy(
  () => import('../components/steps/LandingPageStepPreview')
);
const LandingPageEditor = lazy(
  () => import('../components/steps/LandingPageStepEditor')
);
const BudgetSelectionStep = lazy(
  () => import('../components/steps/BudgetSelectionStep')
);

export const steps: StepDefinition[] = [
  {
    id: 'audience',
    name: 'Review audience',
    preview: <AudienceStepPreview />,
    editor: <AudienceStepEditor />
  },
  {
    id: 'ads',
    name: 'Review ads',
    preview: <AdsStepPreview />,
    editor: <AdsStepEditor />
  },
  {
    id: 'landing-page',
    name: 'Review page',
    preview: <LandingPagePreview />,
    editor: <LandingPageEditor />
  },
  {
    id: 'budget',
    name: 'Set a budget',
    preview: <BudgetSelectionStep />
  }
];

import { AlertDialogContentProps } from '@radix-ui/react-alert-dialog';
import { margin, StyleSheet, useStyles } from '@rexlabs/styling';
import React, { FC, ReactElement, ReactNode, useState } from 'react';
import { useAsyncFn } from 'react-use';
import { Runnable } from 'src/types';
import { Asyncify } from 'type-fest';
import { AlertTrigger } from '.';
import { Alert } from './Alert';
import { AlertAction } from './AlertAction';
import { AlertCancel } from './AlertCancel';
import { AlertContent } from './AlertContent';

const styles = StyleSheet({
  actions: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    ...margin.styles({
      top: 's'
    }),

    '& > * + *': {
      ...margin.styles({
        left: 'xs'
      })
    }
  }
});

export type ConfirmActionAlertProps = AlertDialogContentProps & {
  openButton: ReactElement;
  destructive?: boolean;
  confirmLabel: ReactNode;
  onConfirm: Runnable | Asyncify<Runnable>;
  children?: ReactNode;
};

export const ConfirmActionAlert: FC<ConfirmActionAlertProps> = ({
  openButton,
  confirmLabel,
  onConfirm,
  destructive,
  children,
  ...rest
}) => {
  const s = useStyles(styles);
  const [open, setOpen] = useState(false);
  const [{ loading }, handleConfirm] = useAsyncFn(async () => {
    await onConfirm();
    setOpen(false);
  }, [onConfirm]);

  return (
    <Alert open={open} onOpenChange={setOpen}>
      <AlertTrigger asChild>{openButton}</AlertTrigger>
      <AlertContent {...rest}>
        {children}

        <div {...s('actions')}>
          <AlertCancel />
          <AlertAction
            destructive={destructive}
            isLoading={loading}
            onClick={() => handleConfirm()}
          >
            {confirmLabel}
          </AlertAction>
        </div>
      </AlertContent>
    </Alert>
  );
};

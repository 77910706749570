import React from 'react';
import PropTypes from 'prop-types';
import Square, { types } from './square';

SmallSquare.propTypes = {
  shortHeadline: PropTypes.string,
  bannerAdText: PropTypes.string,
  primaryImageUrls: PropTypes.object,
  logoUrls: PropTypes.object,
  brandColor: PropTypes.string,
  backgroundColor: PropTypes.string,
  buttonText: PropTypes.string
};
export default function SmallSquare(props) {
  return <Square {...props} type={types.SMALL} />;
}

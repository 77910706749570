/* eslint-disable max-lines */
import { Box } from '@rexlabs/box';
import { PrimaryButton } from '@rexlabs/button';
import { query, withModel, withQuery } from '@rexlabs/model-generator';
import { autobind } from 'core-decorators';
import React, { PureComponent } from 'react';
import { Helmet } from 'react-helmet';
import session from 'src/data/models/custom/session';
import invitationsModel from 'src/data/models/entities/invitations';
import ROUTES from 'src/routes/admin';
import AUTH from 'src/routes/auth';
import { formattedBrandName, withToken } from 'src/theme';
import { createValidationRules } from 'src/utils/form';
import { Link } from 'src/utils/whereabouts';
import { AuthBox } from 'src/view/components/box';
import {
  Form,
  FormField,
  HiddenField,
  withForm
} from 'src/view/components/form';
import { TextInput } from 'src/view/components/input';
import { RenderLoading } from 'src/view/components/loading';
import { Body, Label } from 'src/view/components/text';
import withError from 'src/view/containers/with-error';

const q = query`{
  ${invitationsModel} (id: ${(p) => p.match.params.invitationId}) {
    id
    account {
      id
      name
    }
    email
    recipient_user_id
    status
    extra
  }
}`;

/* eslint-disable camelcase */
const acceptInvitationForm = {
  name: 'acceptInvitationForm',
  mapPropsToValues: (props) => ({
    user_id: props?.invitations?.item?.data?.recipient_user_id,
    given_name:
      props?.invitations?.item?.data?.extra?.given_name ||
      props?.session?.user?.given_name,
    family_name:
      props?.invitations?.item?.data?.extra?.family_name ||
      props?.session?.user?.family_name,
    password: '',
    password_confirmed: ''
  }),
  asyncValuesReady: (props) => props?.invitations?.item?.status === 'loaded',
  handleSubmit: (values, { props, setMeta }) => {
    const { invitations, errorModal } = props;
    return invitations
      .updateItem({
        data: {
          extra: invitations?.item?.data?.recipient_user_id
            ? undefined
            : {
                given_name: values.given_name,
                family_name: values.family_name,
                password: values.password
              },
          status: {
            id: 'accepted'
          }
        }
      })
      .then(() => {
        setMeta({ success: true });
      })
      .catch((e) => {
        errorModal.open(e.message);
      });
  },
  validate: (values, ...args) => {
    if (values.user_id) {
      return;
    }
    return createValidationRules({
      given_name: 'required',
      family_name: 'required',
      password: 'required|min:8',
      password_confirmed: [
        'same:password|required|min:8',
        'password confirmation'
      ]
    })(values, ...args);
  },
  validateOnChange: true,
  validateOnBlur: true
};
/* eslint-enable camelcase */

@withToken
@withError('errorModal')
@withQuery(q)
@withModel(session)
@withForm(acceptInvitationForm)
@autobind
class InvitationScreen extends PureComponent {
  state = {
    success: false
  };

  renderSuccess() {
    const { session, invitations, token } = this.props;

    const isLoggedIn = !!session?.user?.id;
    const isDifferentEmail =
      session?.user?.email !== invitations?.item?.data?.email;

    return (
      <Box>
        <Helmet>
          <title>{formattedBrandName} - Invitation</title>
        </Helmet>
        <Body>
          You successfully accepted the invitation to{' '}
          {invitations?.item?.data?.account?.name ?? 'unknown'} marketing
          account.{' '}
          {isLoggedIn
            ? 'You should now be able to access it via the account switcher in the app.'
            : 'You can now log in to access it.'}
        </Body>
        {isLoggedIn && isDifferentEmail ? (
          <Box>
            <Box mt={token('spacing.xs')}>
              <Link to={ROUTES.CAMPAIGNS}>
                Continue as current user ({session?.user?.email})
              </Link>
            </Box>
            <Box mt={token('spacing.xs')}>
              <Link to={AUTH.LOGIN}>
                {({ target, onClick }) => (
                  <a
                    href={target}
                    onClick={(e) => {
                      e.preventDefault();
                      session.logout();

                      if (localStorage) {
                        localStorage.setItem(
                          'invited',
                          invitations?.item?.data?.email
                        );
                      }
                      onClick(e);
                    }}
                  >
                    Go to login to continue as new user (
                    {invitations?.item?.data?.email})
                  </a>
                )}
              </Link>
            </Box>
          </Box>
        ) : isLoggedIn ? (
          <Box mt={token('spacing.xs')}>
            <Link to={ROUTES.CAMPAIGNS}>Go to campaigns</Link>
          </Box>
        ) : (
          <Box mt={token('spacing.xs')}>
            <Link to={AUTH.LOGIN}>
              {({ target, onClick }) => (
                <a
                  href={target}
                  onClick={(e) => {
                    e.preventDefault();

                    if (localStorage) {
                      localStorage.setItem(
                        'invited',
                        invitations?.item?.data?.email
                      );
                    }
                    onClick(e);
                  }}
                >
                  Go to login
                </a>
              )}
            </Link>
          </Box>
        )}
      </Box>
    );
  }

  renderNotPending() {
    const { session, invitations, token } = this.props;
    const isLoggedIn = !!session?.user?.id;
    return (
      <Box>
        <Body grey>
          This invitation is not pending anymore. Maybe you already accepted
          this invite?
        </Body>
        <Box mt={token('spacing.m')}>
          {isLoggedIn ? (
            <Link to={ROUTES.CAMPAIGNS}>Go to campaigns</Link>
          ) : (
            <Link to={AUTH.LOGIN}>
              {({ target, onClick }) => (
                <a
                  href={target}
                  onClick={(e) => {
                    e.preventDefault();

                    if (localStorage) {
                      localStorage.setItem(
                        'invited',
                        invitations?.item?.data?.email
                      );
                    }
                    onClick(e);
                  }}
                >
                  Go to login
                </a>
              )}
            </Link>
          )}
        </Box>
      </Box>
    );
  }

  renderExistingUserFlow() {
    const { invitations, acceptInvitationForm, token } = this.props;
    return (
      <Box flexDirection='column'>
        <Body grey>
          You got invited to{' '}
          <b>{invitations?.item?.data?.account?.name ?? 'unknown'}</b> marketing
          account. Accept the invitation in order to get access to the account.
        </Body>
        <Box
          mt={token('spacing.xl')}
          flexDirection='row'
          alignItems='center'
          justifyContent='center'
        >
          <PrimaryButton
            form='invitationForm'
            onClick={acceptInvitationForm.submitForm}
            isLoading={acceptInvitationForm.isSubmitting}
          >
            Accept invitation
          </PrimaryButton>
        </Box>
      </Box>
    );
  }

  renderNewUserFlow() {
    const { session, invitations, acceptInvitationForm, token } = this.props;

    const isLoggedIn = !!session?.user?.id;
    const isDifferentEmail =
      session?.user?.email !== invitations?.item?.data?.email;

    return (
      <Box flexDirection='column'>
        <Body grey>
          Please provide basic details to get access to{' '}
          <b>{invitations?.item?.data?.account?.name ?? 'unknown'}</b> marketing
          account.
        </Body>
        {isLoggedIn && isDifferentEmail && (
          <Body grey>
            Please note that you are currently logged in with an account with a
            different email address ({session?.user?.email}). You{"'"}ll need to
            log out before being able to access this account!
          </Body>
        )}
        <Box mt={token('spacing.m')}>
          <Box>
            <Label>Email</Label>
            <Body style={{ textAlign: 'left' }}>
              {invitations?.item?.data?.email ?? '-'}
            </Body>
          </Box>
          <Box>
            <FormField name='given_name' label='First name' Input={TextInput} />
            <FormField name='family_name' label='Last name' Input={TextInput} />
            <FormField
              name='password'
              label='Password'
              Input={TextInput}
              inputProps={{
                type: 'password'
              }}
            />
            <FormField
              name='password_confirmed'
              label='Confirm Password'
              Input={TextInput}
              inputProps={{
                type: 'password'
              }}
            />
          </Box>
          <Box
            mt={token('spacing.xl')}
            flexDirection='row'
            alignItems='center'
            justifyContent='space-between'
          >
            <PrimaryButton
              form='invitationForm'
              onClick={acceptInvitationForm.submitForm}
              isLoading={acceptInvitationForm.isSubmitting}
              style={{ width: '100%' }}
            >
              Get started
            </PrimaryButton>
          </Box>
        </Box>
      </Box>
    );
  }

  render() {
    const {
      errorModal: { Error },
      invitations,
      acceptInvitationForm
    } = this.props;

    // eslint-disable-next-line camelcase
    const isUser = invitations?.item?.data?.recipient_user_id;
    const isNotPending = invitations?.item?.data?.status?.id !== 'pending';

    return (
      <AuthBox>
        <Form name='invitationForm'>
          <HiddenField name='user_id' />
          {acceptInvitationForm?.meta?.success ? (
            this.renderSuccess()
          ) : (
            <RenderLoading isLoading={invitations.item.status === 'loading'}>
              {isNotPending
                ? this.renderNotPending()
                : isUser
                ? this.renderExistingUserFlow()
                : this.renderNewUserFlow()}
            </RenderLoading>
          )}
          <Error />
        </Form>
      </AuthBox>
    );
  }
}

export default InvitationScreen;

import { RexApiError } from '@rexlabs/api-client';
import { useQuery } from 'react-query';
import { useAccountId } from 'src/features/accounts';
import { QueryConfig, QueryResult } from 'src/lib/react-query';
import { PaginatedResponse, Pagination, PaginationParams } from 'src/types';
import { api } from 'src/utils/api-client';
import { CustomAudience } from '../types';
import { customAudienceSchema } from '../utils/schemas';
import { customAudienceKeys } from './keys';

export type GetCustomAudiencesParams = PaginationParams & {
  accountId: string;
};

export const getCustomAudiences = async ({
  accountId,
  ...params
}: GetCustomAudiencesParams): Promise<PaginatedResponse<CustomAudience>> => {
  const { data, pagination } = await api.get<CustomAudience[]>(
    'user-lists',
    params,
    {
      headers: {
        'X-Account-Id': accountId
      }
    }
  );
  return {
    data: data.map((v) => customAudienceSchema.create(v)),
    pagination: pagination as Pagination
  };
};

type QueryFn = typeof getCustomAudiences;

export type UseCustomAudiencesOptions = Omit<
  GetCustomAudiencesParams,
  'accountId'
> & {
  config?: QueryConfig<QueryFn>;
};

export const useCustomAudiences = ({
  config,
  ...params
}: UseCustomAudiencesOptions = {}): QueryResult<
  PaginatedResponse<CustomAudience>
> => {
  const accountId = useAccountId();

  return useQuery<PaginatedResponse<CustomAudience>, RexApiError>({
    queryKey: customAudienceKeys.list(accountId, params),
    queryFn: () => getCustomAudiences({ accountId, ...params }),
    ...config
  });
};

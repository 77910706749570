import { query } from '@rexlabs/model-generator';
import agentsModel from 'src/data/models/entities/agents';

function getFilters(props) {
  const search = (props?.whereabouts?.query ?? {})['agents.search'];
  return search ? `full_name.match(*${search}*)` : '';
}

export const queryAgents = query`{
  ${agentsModel} (q: ${getFilters}) {
    id
    full_name
    position
    description
    slogan
    url
    email
    lead_capture_email
    phone_number
    suburbs
    images
    thumbnails
    updated_at
  }
}`;

import { track } from '@rexlabs/analytics';
import { Box } from '@rexlabs/box';
import { GhostButton, PrimaryButton } from '@rexlabs/button';
import { query, withModel, withQuery } from '@rexlabs/model-generator';
import React, {useEffect, useState} from 'react';
import { compose } from 'redux';
import campaignsModel from 'src/data/models/entities/campaigns';
import listingsModel from 'src/data/models/entities/listings';
import WIZARD from 'src/routes/wizard';
import { replace } from 'src/utils/whereabouts';
import { ButtonBar } from 'src/view/components/button';
import ScrollableContent from 'src/view/components/scrollable-content';
import SearchListings from 'src/view/components/search-listings';
import SelectedListingsBoxes from 'src/view/components/selected-listings-boxes';
import { Body, Heading } from 'src/view/components/text';
import withError from 'src/view/containers/with-error';
import { getFilters } from 'src/view/screens/wizard/listing/select';
import { stepNames } from 'src/view/screens/wizard/multi-listing-sold/index';
import { sharedStyles } from '../shared';

import { useStyles } from '@rexlabs/styling';
import ChevronLeft from 'src/assets/icons/chevron-left.svg';
import ChevronRight from 'src/assets/icons/chevron-right.svg';
import {match, withWhereabouts} from "@rexlabs/whereabouts";

function getId(props) {
  const { whereabouts } = props;
  const matched = match(whereabouts, {
    path: '/:accountId?/campaign/create/:type/:campaignId'
  });
  return matched ? matched.params.campaignId : null;
}

const selectedEntityIds = (selectedEntities) => {
  return Object.keys(selectedEntities).reduce((acc, i) => {
    acc[i] = selectedEntities[i].map((i) => ({ id: i.id }));
    return acc;
  }, {});
};

const queryListings = query`{
  ${listingsModel} (q: ${getFilters}) {
    id
    marketing_headline
    address {
      admin_area_3
      full_address
      longitude
      latitude
    }
    thumbnails
    updated_at
    data_source {
      id
      label
    }
    agents
    formatted_address {
      short
    }
    sold_at
  }
}`;

function SelectMultiListingScreen({
  listings,
  campaigns,
  campaignTemplateId,
  whereabouts,
  wizard: { type, slug, setStep, step, selectedEntities, setSelectedListings, setSelectedAgents, setSelectedAgencies },
  error,
  error: { Error }
}) {
  const s = useStyles(sharedStyles);
  const [loading, setLoading] = useState(false);
  const multiSelectLimit = 5;
  const minRequired = 1;
  const campaignId = getId({whereabouts: whereabouts});

  useEffect(() => {
    async function fetchCampaign() {
      const {data} = await campaigns.fetchItem({ id: campaignId, args: {include: 'content_source{listings,agents,agencies}'}})
      setSelectedListings(data.content_source.listings.data);
      setSelectedAgents(data.content_source.agents.data);
      setSelectedAgencies(data.content_source.agencies.data);
    }
    if (campaignId) fetchCampaign();
  }, [campaignId]);

  const handlePrev = () => {
    track({
      event: 'Spoke campaign back',
      properties: {
        action: 'back_clicked',
        step_name: stepNames[step - 1].replace(/-/g, '_')
      }
    });
    setStep({ step: step - 1 });
  };

  const handleNext = async () => {
    setLoading(true);
    try {
      const campaignData = {
        type: { id: type },
        content_source: selectedEntityIds(selectedEntities),
        campaign_template_configuration: { id: campaignTemplateId }
      }
      const campaign = !!campaignId
          ? await campaigns.updateItem({id: campaignId, data: campaignData})
          : await campaigns.createItem({data: campaignData});

      replace(WIZARD.CAMPAIGN, {
        params: { campaignType: slug, campaignId: campaign.data.id }
      });
      const campaignLabel = campaign?.data?.type?.label;

      track({
        event: `Spoke campaign ${campaignLabel} draft ${campaignId ? 'updated' : 'created'}`,
        properties: {
          action: 'next_clicked',
          step_name: stepNames[step - 1].replace(/-/g, '_'),
          success: true,
          number_of_listings: selectedEntities.listings.length
        }
      });

      setStep({ step: step + 1 });
    } catch (e) {
      setLoading(false);
      return error.open(e.message);
    }
  };

  const hasLoaded = listings.list.status === 'loaded';

  return (
    <Box w='100%'>
      <ScrollableContent {...s('content')}>
        <Heading>Choose the listings you&apos;d like to advertise</Heading>
        <Body grey>Select a minimum of 1 and a maximum of 5 listings.</Body>

        <SearchListings
          listings={listings}
          loading={!hasLoaded}
          multiSelectLimit={multiSelectLimit}
        />
      </ScrollableContent>

      <ButtonBar>
        <GhostButton IconLeft={ChevronLeft} onClick={handlePrev}>
          Back
        </GhostButton>

        <Box flexDirection='row'>
          <SelectedListingsBoxes
            total={multiSelectLimit}
            required={minRequired}
            items={selectedEntities.listings}
            removeItem={(listingId) =>
              setSelectedListings(
                selectedEntities.listings.filter((l) => l.id !== listingId)
              )
            }
          />

          <PrimaryButton
            IconRight={ChevronRight}
            onClick={handleNext}
            isLoading={loading}
            isDisabled={selectedEntities.listings.length < minRequired}
          >
            Next
          </PrimaryButton>
        </Box>
      </ButtonBar>

      <Error />
    </Box>
  );
}

export default compose(
  withError(),
  withModel(campaignsModel),
  withQuery(queryListings),
  withWhereabouts(),
)(SelectMultiListingScreen);

import { Box } from '@rexlabs/box';
import { styled, StyleSheet, useToken } from '@rexlabs/styling';
import React, { useCallback, useMemo, useState } from 'react';
import { BREAKPOINTS } from 'src/theme';
import { BarChart } from 'src/view/components/charts';
import { Select } from 'src/view/components/input';
import { StatsHeading } from 'src/view/components/stats';

const defaultStyles = StyleSheet({
  container: {
    display: 'block',

    '@media print': {
      display: 'none'
    }
  },

  // HACK: recharts fail to print if they're changing from display none
  containerPrint: {
    width: '20cm',
    visibility: 'hidden',
    position: 'absolute',

    '@media print': {
      width: '20cm',
      visibility: 'visible',
      position: 'relative',
      pageBreakBefore: 'always'
    }
  },

  firstFilter: {
    [BREAKPOINTS.TABLET_PORTRAIT_UP]: {
      marginLeft: ({ token }) => token('spacing.xxl')
    }
  }
});

function DemographicStats(props) {
  const { stats, styles: s } = props;
  const [demographicsType, setDemographicsType] = useState('age');
  const [demographicsData, setDemographicsData] = useState('impressions');
  const token = useToken();

  const {
    clicks_age_18_24,
    impressions_age_18_24,
    impressions_age_25_34,
    clicks_age_25_34,
    impressions_age_35_44,
    clicks_age_35_44,
    impressions_age_45_54,
    clicks_age_45_54,
    impressions_age_55_64,
    clicks_age_55_64,
    impressions_age_65_more,
    clicks_age_65_more,
    impressions_gender_male,
    clicks_gender_male,
    impressions_gender_female,
    clicks_gender_female,
    impressions_gender_unknown,
    clicks_gender_unknown
  } = stats?.summary || {};

  /**
   * NOTE: age stats not currently collected from google due to api change
   * so there was a large number of unknown. Temporary fix is to set unknown
   * to 0 and add a comment below the chart to make users aware of this
   */
  const clicks_age_unknown = 0;
  const impressions_age_unknown = 0;

  const demographics = useCallback(
    (type = demographicsType, data = demographicsData) => {
      return type === 'age'
        ? [
            {
              name: '18-24',
              value:
                data === 'impressions'
                  ? impressions_age_18_24
                  : clicks_age_18_24
            },
            {
              name: '25-34',
              value:
                data === 'impressions'
                  ? impressions_age_25_34
                  : clicks_age_25_34
            },
            {
              name: '35-44',
              value:
                data === 'impressions'
                  ? impressions_age_35_44
                  : clicks_age_35_44
            },
            {
              name: '45-54',
              value:
                data === 'impressions'
                  ? impressions_age_45_54
                  : clicks_age_45_54
            },
            {
              name: '55-64',
              value:
                data === 'impressions'
                  ? impressions_age_55_64
                  : clicks_age_55_64
            },
            {
              name: '65+',
              value:
                data === 'impressions'
                  ? impressions_age_65_more
                  : clicks_age_65_more
            },
            {
              name: 'Unknown',
              value:
                data === 'impressions'
                  ? impressions_age_unknown
                  : clicks_age_unknown
            }
          ]
        : [
            {
              name: 'Male',
              value:
                data === 'impressions'
                  ? impressions_gender_male
                  : clicks_gender_male
            },
            {
              name: 'Female',
              value:
                data === 'impressions'
                  ? impressions_gender_female
                  : clicks_gender_female
            },
            {
              name: 'Unknown',
              value:
                data === 'impressions'
                  ? impressions_gender_unknown
                  : clicks_gender_unknown
            }
          ];
    },
    [
      clicks_age_18_24,
      clicks_age_25_34,
      clicks_age_35_44,
      clicks_age_45_54,
      clicks_age_55_64,
      clicks_age_65_more,
      clicks_gender_female,
      clicks_gender_male,
      clicks_gender_unknown,
      demographicsData,
      demographicsType,
      impressions_age_18_24,
      impressions_age_25_34,
      impressions_age_35_44,
      impressions_age_45_54,
      impressions_age_55_64,
      impressions_age_65_more,
      impressions_gender_female,
      impressions_gender_male,
      impressions_gender_unknown
    ]
  );

  const getAgeImpressions = useMemo(
    () => demographics('age', 'impressions'),
    [demographics]
  );
  const getGenderImpressions = useMemo(
    () => demographics('gender', 'impressions'),
    [demographics]
  );

  const getDemographicStats = useMemo(
    () => demographics(demographicsType, demographicsData),
    [demographics, demographicsType, demographicsData]
  );

  return (
    <>
      <div {...s('container')}>
        <StatsHeading
          title='Who were the people that saw your ads or took action?'
          Filters={() => (
            <Box flexDirection='row'>
              <Box {...s('firstFilter')}>
                <Select
                  options={[
                    { value: 'age', label: 'Age' },
                    { value: 'gender', label: 'Gender' }
                  ]}
                  onChange={(e) => setDemographicsType(e.target.value)}
                  value={demographicsType}
                />
              </Box>
              <Box ml={token('spacing.m')}>
                <Select
                  options={[
                    { value: 'impressions', label: 'Impressions' },
                    { value: 'clicks', label: 'Clicks' }
                  ]}
                  onChange={(e) => setDemographicsData(e.target.value)}
                  value={demographicsData}
                />
              </Box>
            </Box>
          )}
        />
        <Box style={{ width: '100%' }}>
          <BarChart
            width='100%'
            color={token('campaignReport.demographicStats.color')}
            data={getDemographicStats}
            barHeight={demographicsType === 'age' ? 16 : 24}
            barGap={demographicsType === 'age' ? 10 : 12}
          />
        </Box>
      </div>

      <div {...s('containerPrint')}>
        <StatsHeading title='What age were the people that saw your ads?' />
        <BarChart
          width='100%'
          color={token('campaignReport.demographicStats.color')}
          data={getAgeImpressions}
          barHeight={16}
          barGap={10}
        />

        <StatsHeading title='What gender were the people that saw your ads?' />
        <BarChart
          width='100%'
          color={token('campaignReport.demographicStats.color')}
          data={getGenderImpressions}
          barHeight={24}
          barGap={12}
        />
      </div>
    </>
  );
}

export default styled(defaultStyles)(DemographicStats);

import React from 'react';
import PropTypes from 'prop-types';
import Box from '@rexlabs/box';

import { styled, StyleSheet } from '@rexlabs/styling';
import _ from 'lodash';
import Pin from '../../pin';
import AgentOverlay from '../../agent-overlay';
import charLimit, { limits } from '../../../utils/char-limit';
import { LargeButton } from '../../button';
import ScaledText from '../../scaled-text';
import colorTheme from '../../../utils/color-theme';
import { COLORS } from 'src/theme';

const fontFamily = 'Lato, sans-serif';
const defaultStyles = StyleSheet({
  billboard: {
    width: '970px',
    height: '250px',
    border: '1px solid darkgray',
    overflow: 'hidden',
    display: 'flex'
  },
  primaryImage: {
    width: '391px',
    height: '100%',
    backgroundPosition: 'center',
    backgroundSize: 'cover',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-end',
    alignItems: 'flex-end'
  },
  agentOverlayWrapper: {
    width: '100%',
    height: '90px',
    position: 'relative'
  },
  content: {
    backgroundColor: 'white',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'flex-start',
    padding: '30px',
    flex: 1
  },
  smallText: {
    fontSize: '14px',
    fontFamily,
    fontWeight: 600,
    textTransform: 'uppercase',
    marginBottom: '8px',
    lineHeight: '15px'
  },
  shortHeadline: {
    fontSize: '32px',
    fontWeight: 300,
    fontFamily,
    lineHeight: '38px',
    marginBottom: '16'
  },
  addressWrapper: {
    display: 'flex'
  },
  longHeadline: {
    fontSize: '18px',
    fontFamily,
    fontWeight: 500,
    lineHeight: '24px',
    marginLeft: '10px'
  },
  logoWrapper: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    padding: '8px',
    width: '140px'
  },
  logo: {
    maxWidth: 'calc(0.75 * 130px)',
    maxHeight: 'calc(0.4 * 250px)',
    objectFit: 'contain'
  }
});

Billboard.propTypes = {
  shortHeadline: PropTypes.string,
  longHeadline: PropTypes.string,
  bannerAdText: PropTypes.string,
  agentName: PropTypes.string,
  agencyName: PropTypes.string,
  agentProfileUrls: PropTypes.object,
  primaryImageUrls: PropTypes.object,
  logoUrls: PropTypes.object,
  buttonText: PropTypes.string,
  brandColor: PropTypes.string,
  backgroundColor: PropTypes.string
};

function Billboard({
  buttonText = 'View Property',
  shortHeadline,
  longHeadline,
  smallText,
  agentName,
  agencyName,
  agentProfileUrls,
  primaryImageUrls,
  logoUrls,
  brandColor,
  backgroundColor,
  styles: s
}) {
  const { brandColorBorder } = colorTheme(brandColor);

  return (
    <Box {...s('billboard')}>
      <Box
        {...s.with('primaryImage')({
          backgroundImage: `url('${_.get(primaryImageUrls, [
            'crop.landscape',
            'url'
          ])}')`
        })}
      >
        <Box {...s('agentOverlayWrapper')}>
          <AgentOverlay
            agentName={agentName}
            agencyName={agencyName}
            agentProfileUrl={_.get(agentProfileUrls, ['crop.square', 'url'])}
          />
        </Box>
      </Box>
      <Box {...s('content')}>
        <span {...s.with('smallText')({ color: COLORS.BLACK })}>
          {charLimit(smallText, limits.SMALL_TEXT)}
        </span>
        <ScaledText
          maxWidth={355}
          maxTextLines={2}
          maxFontSize={36}
          minFontSize={24}
          {...s.with('shortHeadline')({ color: COLORS.BLACK })}
        >
          {shortHeadline}
        </ScaledText>
        <Box width={134} marginBottom={16}>
          <LargeButton brandColor={brandColor}>{buttonText}</LargeButton>
        </Box>
        <Box {...s('addressWrapper')}>
          <Pin brandColor={brandColor} width={11} height={15} />
          <span {...s.with('longHeadline')({ color: COLORS.BLACK })}>
            {charLimit(longHeadline, limits.LONG_HEADLINE)}
          </span>
        </Box>
      </Box>
      <Box
        {...s.with('logoWrapper')({
          backgroundColor: backgroundColor,
          borderLeft: brandColorBorder
        })}
      >
        <img {...s('logo')} src={_.get(logoUrls, 'original.url')} />
      </Box>
    </Box>
  );
}

export default styled(defaultStyles)(Billboard);

import { Box } from '@rexlabs/box';
import { styled, StyleSheet } from '@rexlabs/styling';
import { autobind } from 'core-decorators';
import React, { PureComponent } from 'react';
import { BORDER_RADIUS, FONT } from 'src/theme';
import { FormField, HiddenField } from 'src/view/components/form';
import BannerSelect from 'src/view/components/input/select/banner';
import Toggle from 'src/view/components/input/toggle';
import Label from 'src/view/components/text/label';

const defaultStyles = StyleSheet({
  title: {
    fontWeight: FONT.WEIGHTS.BOLD
  },

  text: {
    margin: '.2rem 7rem .2rem 0',
    display: 'block'
  },

  textMeta: {
    color: ({ token }) => token('legacy.color.blue.grey')
  },

  container: {
    padding: ({ token }) => token('spacing.m'),
    marginTop: ({ token }) => token('spacing.xl'),
    border: ({ token }) => `.1rem solid ${token('legacy.color.blue.grey')}`,
    borderRadius: BORDER_RADIUS.INPUT
  },

  separator: {
    margin: '15px 0px 15px 0px',
    width: '100%',
    height: '.1rem',
    background: ({ token }) => token('legacy.color.blue.greyLight')
  },

  label: {
    display: 'inline'
  },

  toggle: {
    float: 'right'
  }
});

@styled(defaultStyles)
@autobind
class BannerEdit extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      toggled: props.hasValues
    };
  }

  componentWillReceiveProps(nextProps) {
    if (!!nextProps.hasValues !== !!this.props.hasValues) {
      this.setState({
        toggled: !!nextProps.hasValues
      });
    }
  }

  handleToggle() {
    const { toggled } = this.state;
    const { change } = this.props;
    change(
      'prospecting.banner_text',
      this.state.toggled
        ? null
        : {
            value: 'For Sale',
            label: 'For Sale'
          }
    );
    this.setState({ toggled: !toggled });
  }

  render() {
    const { styles: s } = this.props;
    return (
      <Box {...s('container')}>
        <Box {...s('toggle')}>
          <Toggle toggled={this.state.toggled} onClick={this.handleToggle} />
        </Box>
        <Label {...s('label')}>Banner</Label>
        <span {...s('text', 'textMeta')}>
          Corner banner on top of the image for Facebook and Instagram ads
        </span>

        <Box style={{ display: `${this.state.toggled ? 'block' : 'none'}` }}>
          <Box {...s('separator')} />
          <FormField
            name='prospecting.banner_text'
            Input={BannerSelect}
            sendImmediate
          />
        </Box>
        <HiddenField name='banner_text_toggled' value={this.state.toggled} />
      </Box>
    );
  }
}

export default BannerEdit;

import React, { FC } from 'react';
import { Navigate, useParams, useRouteError } from 'react-router-dom';
import { isRexApiResponse } from 'src/utils/api-client';

export const WizardErrorBoundary: FC = () => {
  const { accountIndex } = useParams();
  const error = useRouteError();

  if (
    error instanceof Error &&
    isRexApiResponse(error.cause) &&
    error.cause.status === 404
  ) {
    return <Navigate to={`/${accountIndex}/campaigns/create`} replace />;
  }

  throw error;
};

import { api } from 'src/utils/api-client';
import { addressToMapbox, suburbToMapbox } from 'src/utils/mapbox';

export const radiusUnits = {
  kilometer: 'km',
  mile: 'mi'
};

async function getLocationDetails(id) {
  try {
    const { data: address } = await api.get(
      `/location/autocomplete/details/${id}`
    );

    return {
      label: address.full_address,
      value: address
    };
  } catch (e) {
    if (__DEV__) {
      console.warn('Could not get location details', { e });
    }
  }
}

export async function getAddressDetails(id, label) {
  const address = await getLocationDetails(id);
  if (address) {
    if (label) {
      /**
       * set unit_number as the backend lookup doesn't know about unit numbers
       * overwrite street_number and full_address so it matches the label
       */
      const unitNumberRegEx = /^[0-9]+[ ]*[/]/;
      const streetNumberRegEx = /^[0-9]+[a-zA-Z]/;
      const labelStreetNumber = label
        .replace(unitNumberRegEx, '')
        .trimStart()
        .match(streetNumberRegEx)?.[0];
      const streetNumber = address.value.street_number
        .replace(unitNumberRegEx, '')
        .trimStart()
        .match(streetNumberRegEx)?.[0];

      address.label = label;
      address.value.full_address = label;
      address.value.unit_number = (label.match(unitNumberRegEx)?.[0] ?? '/')
        .split('/')[0]
        .trim();

      if (labelStreetNumber !== streetNumber) {
        address.value.street_number = labelStreetNumber;
      }
    }
    address.data = addressToMapbox(address.value).data;
    return address;
  }
}

export async function getSuburbDetails(id) {
  const address = await getLocationDetails(id);
  if (address) {
    address.data = suburbToMapbox(address.value).data;
    return address;
  }
}

function serializer(address, whiteListKeys) {
  if (address === undefined || address === null) return address;

  return Object.keys(address).reduce((acc, key) => {
    const value = address[key];

    if (whiteListKeys.includes(key)) {
      if (
        value !== null &&
        typeof value === 'object' &&
        value.hasOwnProperty('label')
      ) {
        const { label, ...rest } = value;
        acc[key] = rest;
      } else if (value === null) {
        acc[key] = '';
      } else {
        acc[key] = value;
      }
    }

    return acc;
  }, {});
}

export function serializeAddress(address) {
  return serializer(address, [
    'name',
    'full_address',
    'unit_number',
    'street_number',
    'street_name',
    'address_line_2',
    'address_line_3',
    'admin_area_1',
    'admin_area_2',
    'admin_area_3',
    'admin_area_4',
    'postal_code',
    'country',
    'latitude',
    'longitude'
  ]);
}

export function serializeSuburb(suburb) {
  return serializer(suburb, [
    'full_address',
    'country',
    'admin_area_1',
    'admin_area_2',
    'admin_area_3',
    'admin_area_4',
    'postal_code',
    'latitude',
    'longitude'
  ]);
}

export function addressLabel(address) {
  const {
    unit_number,
    name,
    street_number,
    street_name,
    admin_area_1,
    admin_area_2,
    admin_area_3,
    postal_code,
    country
  } = address;

  const { id: countryCode } = country;

  switch (countryCode) {
    case 'GB':
      return [
        unit_number && `${unit_number}`,
        name ? `${name}` : '/',
        street_number && `${street_number}`,
        street_name && `${street_name},`,
        admin_area_2 && `${admin_area_2},`,
        admin_area_3 && `${admin_area_3},`,
        postal_code && `${postal_code}`
      ]
        .filter(Boolean)
        .join(' ');
    case 'NZ':
      return [
        unit_number && `${unit_number} /`,
        street_number && `${street_number}`,
        street_name && `${street_name},`,
        admin_area_3 && `${admin_area_3},`,
        admin_area_1?.id && `${admin_area_1?.id},`,
        postal_code && `${postal_code}`
      ]
        .filter(Boolean)
        .join(' ');
    default:
      return [
        unit_number && `${unit_number} /`,
        street_number && `${street_number}`,
        street_name && `${street_name},`,
        admin_area_3 && `${admin_area_3},`,
        admin_area_1?.id && `${admin_area_1?.id},`,
        postal_code && `${postal_code}`
      ]
        .filter(Boolean)
        .join(' ');
  }
}

import React from 'react';
import { SingleImage } from 'src/view/components/image';

const PropertyImageInput = (props) => {
  const { value } = props;

  return (
    <SingleImage
      {...props}
      overlayText='Change'
      canCrop={value?.id}
      ratio='landscape'
    />
  );
};

export default PropertyImageInput;

import { omit } from 'lodash';
import { useMutation, UseMutationResult, useQueryClient } from 'react-query';
import { useAccountId } from 'src/features/accounts';
import { api } from 'src/utils/api-client';
import { ResourceDownload, ResourceDownloadDTO } from '../types';
import { resourceDownloadKeys } from './keys';

export type UpdateResourceDownloadParams = {
  id: string;
  data: Partial<ResourceDownloadDTO>;
};

export const updateResourceDownload = async ({
  id,
  data
}: UpdateResourceDownloadParams): Promise<ResourceDownload> => {
  // TODO: Use accountId from params instead of implicit X-Account-ID header
  const res = await api.patch(`/resource-downloads/${id}`, data);
  return res.data;
};

export const useUpdateResourceDownload = (): UseMutationResult<
  ResourceDownload,
  Error,
  UpdateResourceDownloadParams,
  { previousValue?: ResourceDownload }
> => {
  const queryClient = useQueryClient();
  const accountId = useAccountId();

  return useMutation({
    mutationFn: ({ id, data }) => updateResourceDownload({ id, data }),
    onMutate: async ({ id, data: { upload_id, ...data } }) => {
      const key = resourceDownloadKeys.detail({ accountId, id });

      await queryClient.cancelQueries(key);

      const previousValue = queryClient.getQueryData<ResourceDownload>(key);

      queryClient.setQueryData<ResourceDownload>(key, (old) => ({
        ...(old as ResourceDownload),
        ...omit(data, 'image')
      }));

      return { previousValue };
    },
    onError: (_err, { id }, ctx) => {
      queryClient.setQueryData(
        resourceDownloadKeys.detail({ accountId, id }),
        ctx?.previousValue
      );
    },
    onSuccess: () => {
      queryClient.invalidateQueries(resourceDownloadKeys.lists(accountId));
    },
    onSettled: (_data, _err, { id }) => {
      queryClient.invalidateQueries(
        resourceDownloadKeys.detail({ accountId, id })
      );
    }
  });
};

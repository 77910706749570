import {
  Select,
  SelectInput as CoreSelectInput,
  SelectMenu
} from '@rexlabs/select-input';
import { autobind } from 'core-decorators';
import React, { Component } from 'react';
import uuid from 'src/utils/uuid';
import OptionNotFound from './options/not-found';
import {
  filterOptionsByValue,
  LoadingIndicator,
  pluckValue,
  selectFilter
} from './utils';

@autobind
class DefaultSelect extends Component {
  static defaultProps = {
    debounce: false,
    filter: selectFilter,
    isClearable: false,
    LoadingIndicator,
    SelectInput: CoreSelectInput,
    OptionNotFound
  };

  constructor(props, ...args) {
    super(props, ...args);

    let selected;
    let selectedSourceProp;
    if (props.value && props.options) {
      selected = filterOptionsByValue(props.options, props.value);
      selectedSourceProp = 'value';
    } else {
      selected = props.selected;
      selectedSourceProp = 'selected';
    }

    if (props.selected && props.value) {
      console.warn(
        `The \`selected\` & \`value\` prop were both provided to Select on startup. \`${selectedSourceProp}\` was chosen to manage initial selected options.`
      );
    }

    this.state = { selected };
  }

  componentWillReceiveProps(nextProps) {
    const changedValue = nextProps.value !== this.props.value;
    const changedOptions =
      nextProps.value && nextProps.options !== this.props.options;
    const changedSelected = nextProps.selected !== this.props.selected;

    if (changedValue || changedOptions) {
      // Note: Handling behaviour for redux-form to work.
      const selectedOptions = filterOptionsByValue(
        nextProps.options,
        nextProps.value
      );
      this.setState({ selected: selectedOptions });
    } else if (changedSelected) {
      // Note: Developer wants to handle the options.
      this.setState({ selected: nextProps.selected });
    }
  }

  render() {
    const props = this.props;

    const { SelectInput, onCleared } = props;

    const SelectProps = {
      Container: props.Container,
      options: props.options,
      selected: props.selected,
      multi: props.multi,
      isLoading: props.isLoading,
      ignoreAccents: props.ignoreAccents,
      ignoreCase: props.ignoreCase,
      filter: props.filter,
      pluckLabel: props.pluckLabel,
      pluckValue: props.pluckValue,
      onSelect: props.onSelect,
      ...props.selectProps
    };

    const InputProps = {
      prefix: props.inputPrefix,
      styles: props.inputStyles,
      DropdownIndicator: props.DropdownIndicator,
      loadingIndicatorProps: props.loadingIndicatorProps,
      ClearIndicator: props.ClearIndicator,
      LoadingIndicator: props.LoadingIndicator,
      Value: props.Value,
      name: props.id || props.name,
      placeholder: props.placeholder,
      isClearable: props.isClearable,
      autoFocus: props.autoFocus,
      required: props.required,
      disabled: props.disabled,
      // NOTE: Disabled because we don't want forms handling them - we want Select handling them.
      // onBlur: props.onBlur,
      // onFocus: props.onFocus,
      // onChange: props.onChange,
      onChange: props.onInputChange,
      onKeyDown: props.onKeyDown,
      autoBlur: props.autoBlur,
      isSearchable: props.isSearchable,
      shouldOpenOnFocus: props.shouldOpenOnFocus,
      shouldBackspaceRemove: props.shouldBackspaceRemove,
      shouldDeleteRemove: props.shouldDeleteRemove,
      shouldEscapeClearValue: props.shouldEscapeClearValue,
      shouldTabSelectValue: props.shouldTabSelectValue,
      shouldBlurResetInput: props.shouldBlurResetInput,
      shouldCloseResetInput: props.shouldCloseResetInput,
      shouldCloseOnSelect: props.shouldCloseOnSelect,
      debounce: props.debounce
    };

    // https://github.com/erikras/redux-form/blob/master/src/propTypes.js
    // Note: We don't want the other prop's, as out Select accounts for most cases.
    const FieldProps = {
      meta: props.meta,
      onChange: props.onFieldChange
    };

    const MenuProps = {
      styles: props.menuStyles,
      Option: props.Option,
      OptionNotFound: props.OptionNotFound,
      OptionSelected: props.OptionSelected,
      onOpen: props.onOpen,
      onClose: props.onClose,
      onScrollToBottom: props.onScrollToBottom,
      onOptionHover: props.onOptionHover,
      onOptionSelect: props.onOptionSelect,
      shouldCloseOnSelect: props.shouldCloseOnSelect,
      LoadingIndicator: props.LoadingIndicator
    };

    const options = props.fixtures
      ? props.options.concat(
          props.fixtures.map((fixture) => ({
            ...fixture,
            isFixture: true,
            value: fixture.value || fixture.label
          }))
        )
      : props.options;

    return (
      <Select
        {...SelectProps}
        options={options}
        onSelect={this.handleSelect}
        selected={this.state.selected}
      >
        <SelectInput
          {...InputProps}
          {...FieldProps}
          data-lpignore='true'
          autoComplete={uuid()}
          onBlur={this.handleBlur}
          onCleared={onCleared}
        />
        <div style={{ position: 'static', width: '100%' }}>
          <div style={{ position: 'relative' }}>
            <SelectMenu {...MenuProps} />
          </div>
        </div>
      </Select>
    );
  }

  handleBlur() {
    const { value, onBlur, name } = this.props;
    if (onBlur) {
      onBlur({
        persist: () => null,
        target: {
          type: 'select',
          name,
          id: name,
          value
        }
      });
    }
  }

  handleSelect(selected) {
    const { onChange, onSelect, name, multi } = this.props;
    if (onChange) {
      onChange({
        persist: () => null,
        target: {
          type: 'select',
          name,
          id: name,
          value: multi
            ? selected
                .map((s) => s.value)
                .filter(
                  (s) => selected.filter((sel) => sel.value === s).length === 1
                )
            : pluckValue(selected)
        }
      });
    } else {
      // Note: When redux-form isn't present, we manage the selected state manually.
      this.setState({ selected });
    }

    if (onSelect) {
      // Note: Developer may want to handle the options, so we put this handler last.
      onSelect(selected);
    }
  }
}

export default DefaultSelect;

import { track } from '@rexlabs/analytics';
import { Box } from '@rexlabs/box';
import { GhostButton, PrimaryButton } from '@rexlabs/button';
import { withModel, withQuery } from '@rexlabs/model-generator';
import { StyleSheet } from '@rexlabs/styling';
import { match } from '@rexlabs/whereabouts';
import { autobind } from 'core-decorators';
import React, { PureComponent } from 'react';
import { Helmet } from 'react-helmet';
import wizardModel from 'src/data/models/custom/wizard';
import { campaignsLandingPageQuery } from 'src/data/queries/campaigns';
import { rexQuery } from 'src/data/queries/rex';
import WIZARD from 'src/routes/wizard';
import { formattedBrandName, withToken } from 'src/theme';
import { withFeatureFlag } from 'src/utils/feature-flags';
import { createValidationRules } from 'src/utils/form';
import { replace } from 'src/utils/whereabouts';
import { StickyButtonGroup } from 'src/view/components/button';
import ComponentRenderer from 'src/view/components/component-renderer';
import { Form, ReactForms } from 'src/view/components/form';
import LandingPagePreview from 'src/view/components/landing-page-preview';
import { RenderLoading } from 'src/view/components/loading';
import { FullscreenModal } from 'src/view/components/modal';
import { PortalDelayed } from 'src/view/components/portal';
import { Heading } from 'src/view/components/text';
import withError from 'src/view/containers/with-error';
import withToast from 'src/view/containers/with-toast';
import EditLandingPageForm, {
  baseValidations,
  externalValidations,
  handleSubmit,
  mapPropsToValues,
  maximumLeadsValidations,
  multiListingSoldValidations,
  qualitiativeLeadsValidations
} from 'src/view/forms/edit-landing-page';

const fullScreenModalStyles = StyleSheet({
  inner: {
    '&::before': {
      width: `${102.4 * 0.5}rem`
    }
  },

  wrapLeft: {
    width: '50%'
  },

  wrapRight: {
    width: '50%'
  }
});

const navStyles = StyleSheet({
  navText: {
    color: ({ token }) => token('legacy.color.blue.grey')
  }
});

function getId(props) {
  const { whereabouts } = props;
  const matched = match(whereabouts, {
    path: '/:accountId?/campaign/create/:type/:campaignId'
  });
  return matched ? matched.params.campaignId : null;
}

export function validate(values) {
  if (values.is_external) {
    return createValidationRules(externalValidations)(values);
  }

  if (values.type === 'multi_listing_sold') {
    return createValidationRules(multiListingSoldValidations)(values);
  }

  if (values.template === 'maximum_leads') {
    return createValidationRules(maximumLeadsValidations)(values);
  }

  if (values.template === 'qualitative_leads') {
    return createValidationRules(qualitiativeLeadsValidations)(values);
  }

  return createValidationRules(baseValidations)(values);
}

@withToken
@withToast
@withError('errorModal')
@withModel(wizardModel)
@withQuery(campaignsLandingPageQuery(getId))
@withFeatureFlag('rex_integration_lead_preference', 'hasRexLeadPreference')
@withQuery(rexQuery)
@autobind
class EditLandingPageModal extends PureComponent {
  getInitialValues() {
    return mapPropsToValues(this.props);
  }

  async handleSubmit(values) {
    const {
      errorModal,
      toasts,
      campaigns,
      wizard: { slug }
    } = this.props;
    try {
      await handleSubmit(values, { props: { campaigns } });

      track({
        event: 'Spoke campaign landing page edited',
        properties: {
          action: 'clicked_save',
          section: 'edit_landing_page',
          virtual_tour_url: values?.virtual_tour_url
            ? new URL(values?.virtual_tour_url)?.hostname
            : ''
        }
      });

      /**
       * HACK: not using closeModal here because the landing page preview iframe
       * src changes are getting caught in whereabouts. Setting a key on the iframe
       * used to fix this but using a key on the iframe breaks chrome 83.
       * closeModal on FullscreenModal below is doing the same as this as well.
       */

      // closeModal();
      replace(WIZARD.CAMPAIGN, {
        params: { campaignId: campaigns?.item?.data?.id, campaignType: slug }
      });
      toasts.addToast({
        type: 'success',
        description: 'Your landing page has been successfully saved'
      });
    } catch (e) {
      errorModal.open(e);
    }
  }

  render() {
    const {
      campaigns,
      rex,
      wizard: { slug },
      errorModal: { Error },
      token,
      hasRexLeadPreference
    } = this.props;

    const campaignTemplate =
      campaigns.item.data?.campaign_template?.campaign_template_configuration
        ?.configuration;
    const dataSource =
      campaigns?.item?.data?.content_source?.listings?.items?.[0]?.data_source
        ?.id;
    const listingId =
      campaigns?.item?.data?.content_source?.listings?.items?.[0]?.id;
    const landingPageId = campaigns?.item?.data?.landing_pages?.items?.[0]?.id;
    const hasRexIntegration =
      hasRexLeadPreference && rex.item.data?.attributes?.ready;

    return (
      <FullscreenModal
        styles={fullScreenModalStyles}
        onClose={() =>
          replace(WIZARD.CAMPAIGN, {
            params: {
              campaignId: campaigns?.item?.data?.id,
              campaignType: slug
            }
          })
        }
      >
        <Box flex={1} flexDirection='column'>
          <Helmet>
            <title>{formattedBrandName} - Edit Landing Page</title>
          </Helmet>

          <Heading>Edit Page</Heading>

          <RenderLoading isLoading={campaigns.item.status !== 'loaded'}>
            <ReactForms
              initialValues={this.getInitialValues()}
              handleSubmit={this.handleSubmit}
              validate={validate}
            >
              {({
                values,
                errors,
                setFieldValue,
                submitForm,
                isSubmitting
              }) => {
                return (
                  <>
                    <Form
                      name='editPageForm'
                      data-testid={
                        Object.values(values).length > 0 && 'edit-page-form'
                      }
                    >
                      <EditLandingPageForm
                        values={values}
                        setFieldValue={setFieldValue}
                        errors={errors}
                        status={campaigns?.item?.status}
                        listingId={listingId}
                        dataSource={dataSource}
                        campaignTemplate={campaignTemplate}
                        hasRexIntegration={hasRexIntegration}
                      />

                      <StickyButtonGroup mt={token('spacing.s')} end>
                        <GhostButton
                          onClick={() => {
                            replace(WIZARD.CAMPAIGN, {
                              params: {
                                campaignId: campaigns?.item?.data?.id,
                                campaignType: slug
                              }
                            });
                          }}
                        >
                          Cancel
                        </GhostButton>
                        <PrimaryButton
                          onClick={submitForm}
                          isLoading={isSubmitting}
                        >
                          Save
                        </PrimaryButton>
                      </StickyButtonGroup>
                    </Form>

                    <PortalDelayed target='contentRight'>
                      <ComponentRenderer
                        template={campaignTemplate}
                        resource='landing_page'
                        section='preview'
                        components={{ Heading, Box, LandingPagePreview }}
                        extraPropsFn={({ type }) => {
                          let extraProps;

                          if (type === 'Heading') {
                            extraProps = {
                              style: { color: token('legacy.color.blue.grey') }
                            };
                          }

                          if (type === 'LandingPagePreview') {
                            extraProps = {
                              styles: navStyles,
                              landingPageId: landingPageId,
                              values: values
                            };
                          }

                          return extraProps;
                        }}
                      />
                    </PortalDelayed>
                  </>
                );
              }}
            </ReactForms>
            <Error />
          </RenderLoading>
        </Box>
      </FullscreenModal>
    );
  }
}

export default EditLandingPageModal;

import { Box } from '@rexlabs/box';
import { useModelState } from '@rexlabs/model-generator';
import { StyleSheet, useStyles } from '@rexlabs/styling';
import React from 'react';
import sessionModel from 'src/data/models/custom/session';
import { BORDER_RADIUS, FONT } from 'src/theme';
import { Link } from 'src/utils/whereabouts';

const defaultStyles = StyleSheet({
  container: {
    width: '100%'
  },
  errorBar: {
    background: ({ token }) => token('legacy.color.red.light'),
    color: ({ token }) => token('legacy.color.white'),
    fontWeight: FONT.WEIGHTS.SEMIBOLD,
    fontSize: '1.6rem',
    textAlign: 'center',
    flexDirection: 'row',
    width: '100%',
    height: '4rem',
    zIndex: 9,

    '& button': {
      marginLeft: ({ token }) => token('spacing.xxl'),
      padding: ({ token }) => `.2rem ${token('spacing.xs')}`,
      background: ({ token }) => token('legacy.color.white'),
      color: ({ token }) => token('legacy.color.red.default'),
      transition: 'opacity .2s',
      cursor: 'pointer',
      border: '0 none',
      borderRadius: BORDER_RADIUS.INPUT,
      opacity: 0.8
      // textTransform: 'uppercase'
    },

    '& button:hover': {
      opacity: '1'
    }
  },

  warningBar: {
    background: ({ token }) => token('legacy.color.orange.light'),
    color: ({ token }) => token('legacy.color.orange.default'),

    '& button': {
      background: ({ token }) => token('legacy.color.orange.default'),
      color: ({ token }) => token('legacy.color.white')
    }
  }
});

function ErrorBar() {
  const s = useStyles(defaultStyles);
  const session = useModelState(sessionModel);

  return (
    <div {...s('container')}>
      {session.errors.map((error, i) => (
        <Box
          alignItems='center'
          justifyContent='center'
          {...s('errorBar')}
          key={`error-${i}`}
        >
          {error.message}{' '}
          {error.action && error.action.path && (
            <Link to={{ config: { path: error.action.path } }}>
              {({ onClick }) => (
                <button onClick={onClick}>{error.action.label}</button>
              )}
            </Link>
          )}
        </Box>
      ))}

      {session.warnings.map((warning, i) => (
        <Box
          alignItems='center'
          justifyContent='center'
          {...s('errorBar', 'warningBar')}
          key={`warning-${i}`}
        >
          {warning.message}{' '}
          {warning.action && warning.action.path && (
            <Link to={{ config: { path: warning.action.path } }}>
              {({ onClick }) => (
                <button onClick={onClick}>{warning.action.label}</button>
              )}
            </Link>
          )}
        </Box>
      ))}
    </div>
  );
}

export default ErrorBar;

import { FetchQueryOptions, QueryClient } from 'react-query';
import { getAccounts } from '../api/getAccounts';
import { accountKeys } from '../api/keys';
import { Account } from '../types';

export const findAccount = async (
  queryClient: QueryClient,
  accountIndex: number,
  perPage = 10
): Promise<Account> => {
  const firstPageParams = {
    pagination: {
      page: 1,
      per_page: perPage
    },
    includes: ['agencies', 'feature_flags']
  };
  const { data: pageData, pagination } = await queryClient.fetchQuery({
    queryKey: accountKeys.list(firstPageParams),
    queryFn: () =>
      getAccounts({
        includes: firstPageParams.includes,
        ...firstPageParams.pagination
      })
  });

  if (pagination.total < accountIndex) throw new Error('Account not found');

  const expectedPage = Math.floor(accountIndex / perPage) + 1;
  const relIdx = accountIndex % perPage;

  let account;
  if (expectedPage === 1) {
    account = pageData[relIdx];
  } else {
    const expectedPageParams = {
      page: expectedPage,
      per_page: perPage
    };

    const { data } = await queryClient.fetchQuery({
      queryKey: accountKeys.list({
        pagination: expectedPageParams,
        includes: ['agencies', 'feature_flags']
      }),
      queryFn: () =>
        getAccounts({
          ...expectedPageParams,
          includes: ['agencies', 'feature_flags']
        })
    });

    account = data[relIdx];
  }

  return account;
};

export const findAccountQueryKey = (accountIndex: number) =>
  ['accountId', accountIndex] as const;

export const findAccountQuery = (
  queryClient: QueryClient,
  accountIndex: string
): FetchQueryOptions<
  Account,
  Error,
  Account,
  ReturnType<typeof findAccountQueryKey>
> => {
  const accountIdx = Number.parseInt(accountIndex, 10);
  return {
    queryKey: findAccountQueryKey(accountIdx),
    queryFn: () => findAccount(queryClient, accountIdx),
    cacheTime: Infinity,
    staleTime: 86400000
  };
};

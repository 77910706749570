import { Box } from '@rexlabs/box';
import { styled, StyleSheet } from '@rexlabs/styling';
import React from 'react';
import { BORDER_RADIUS } from 'src/theme';
import LandingPagePreview from 'src/view/components/landing-page-preview';
import { StatsHeading } from 'src/view/components/stats';

const defaultStyles = StyleSheet({
  /**
   * HACK: can't use display: none as the ads get scaled and when printing
   * the it renders while its still in the process of scaling /shrug
   */
  container: {
    height: 0,
    overflow: 'hidden',
    visibility: 'hidden',
    position: 'absolute',

    '@media print': {
      height: 'auto',
      overflow: 'auto',
      visibility: 'visible',
      position: 'relative'
    }
  },

  description: {
    color: ({ token }) => token('legacy.color.blue.grey'),
    marginBottom: '4mm'
  },

  url: {
    display: 'inline-block',
    background: ({ token }) => token('legacy.color.grey.light'),
    padding: '9px 16px 12px 16px',
    borderRadius: '3px'
  }
});

const sharedLpStyles = {
  previewTitle: {
    display: 'none'
  }
};

const lpMobileStyles = StyleSheet({
  ...sharedLpStyles,

  previewContainer: {
    width: '52mm',
    padding: '1rem 0',
    background: 'white',
    borderRadius: BORDER_RADIUS.LARGE,
    height: '100mm',
    overflow: 'hidden'
  },

  deviceWrapperMobile: {
    borderRadius: '4.5rem',
    border: ({ token }) => `1px solid ${token('legacy.color.grey.light')}`,
    boxShadow: 'none',
    padding: '1rem',
    maxWidth: '70mm'
  },

  deviceInnerMobile: {
    borderRadius: '3.5rem',
    paddingBottom: '216.534%' // corresponds to the deviceWrapperMobile max-width to get an aspect ratio of an ~iPhone X
  },

  mobileRes: {
    width: '375px',
    height: '812px'
  }
});

const lpDesktopStyles = StyleSheet({
  ...sharedLpStyles,
  previewContainer: {
    width: '140mm',
    padding: '1rem 0',
    background: 'white',
    borderRadius: BORDER_RADIUS.LARGE,
    height: '100mm',
    overflow: 'hidden'
  },

  deviceWrapperDesktop: {
    borderRadius: '1.6rem',
    border: ({ token }) => `1px solid ${token('legacy.color.grey.light')}`,
    boxShadow: 'none',
    padding: '1.2rem',
    maxWidth: '672' // a fraction of of 1920 to aim for an aspect ratio of 1920 x 1080
  }
});

function LandingPagePrintPreview({ landingPage, styles: s }) {
  const { external_url: externalUrl, domain } = landingPage;
  const isUKDomain = window.location.host.includes('uk', 'flink');

  return (
    <div {...s('container')}>
      <StatsHeading title='Landing Page Preview' />
      <p {...s('description')}>
        The devices below show previews of your landing page on desktop and
        mobile. To see the fully interactive landing page for your listing,
        visit the following URL:
      </p>

      <p {...s('url')}>
        {externalUrl ||
          domain + (isUKDomain ? '.lp.uk' : '.lp') + '.spokeapp.io'}
      </p>

      <Box flexDirection='row'>
        <LandingPagePreview
          initialViewMobile={false}
          fixedScale={0.35}
          landingPageId={landingPage.id}
          values={landingPage}
          styles={lpDesktopStyles}
        />

        <LandingPagePreview
          initialViewMobile={true}
          fixedScale={0.414}
          landingPageId={landingPage.id}
          values={landingPage}
          styles={lpMobileStyles}
        />
      </Box>
    </div>
  );
}

export default styled(defaultStyles)(LandingPagePrintPreview);

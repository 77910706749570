import { margin, styled, StyleSheet } from '@rexlabs/styling';
import React, { PureComponent } from 'react';
import { Link } from 'src/utils/whereabouts';

const defaultStyles = StyleSheet({
  sidebarLink: {
    display: 'block',
    color: ({ token }) => token('color.textStyle.link.idle.default'),
    textDecoration: 'none',
    fontSize: '1.6rem',
    letterSpacing: '-.03rem',
    lineHeight: '2.1rem',
    fontWeight: ({ token }) => token('typography.weight.normal'),
    transition: 'color .2s',
    ...margin.styles({
      y: 'm',
      x: 'none'
    }),

    ':hover': {
      color: ({ token }) => token('color.textStyle.link.hover.default'),
      textDecoration: 'none'
    }
  },

  sidebarActive: {
    color: ({ token }) => token('color.textStyle.link.active.default')
  }
});

@styled(defaultStyles)
class SidebarItem extends PureComponent {
  render() {
    const { styles: s, route, children } = this.props;
    return (
      <Link to={route} activeMatches={[route.config.path]}>
        {({ target, onClick, active }) => (
          <a
            href={target}
            onClick={onClick}
            {...s('sidebarLink', { sidebarActive: active })}
          >
            {children}
          </a>
        )}
      </Link>
    );
  }
}

export default SidebarItem;

import { RexApiError } from '@rexlabs/api-client';
import { useQuery } from 'react-query';
import { QueryConfig, QueryResult } from 'src/lib/react-query';
import { PaginatedResponse, Pagination } from 'src/types';
import { api } from 'src/utils/api-client';
import { CampaignDefinition } from '../types';
import { createCampaignDefinition } from '../utils/schemas';
import { campaignDefinitionKeys } from './keys';

export const getCampaignDefinitions = async (): Promise<
  PaginatedResponse<CampaignDefinition>
> => {
  const { data, pagination } = await api.get('/campaign-definition');
  return {
    data: data.map(createCampaignDefinition),
    pagination: pagination as Pagination
  };
};

type QueryFnType = typeof getCampaignDefinitions;

export type UseCampaignDefinitionsOptions<TData> = {
  page?: number;
  config?: QueryConfig<QueryFnType, TData>;
};

export const useCampaignDefinitions = <
  TData = PaginatedResponse<CampaignDefinition>
>({
  config,
  page = 1
}: UseCampaignDefinitionsOptions<TData> = {}): QueryResult<TData> => {
  return useQuery<PaginatedResponse<CampaignDefinition>, RexApiError, TData>({
    ...config,
    queryKey: campaignDefinitionKeys.list('test', page),
    queryFn: () => getCampaignDefinitions()
  });
};

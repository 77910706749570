import { useReducer } from 'react';

type Actions =
  | ChangePlanAction
  | ConfirmPlanAction
  | SubmitChangeAction
  | SubmitChangeSuccessAction
  | SubmitChangeErrorAction;

interface ChangePlanAction {
  type: 'change_plan';
}

interface ConfirmPlanAction {
  type: 'confirm_plan';
  planId: string;
}

interface SubmitChangeAction {
  type: 'submit_change';
  paymentId: string;
}

interface SubmitChangeSuccessAction {
  type: 'submit_change_success';
}

interface SubmitChangeErrorAction {
  type: 'submit_change_error';
  error: Error;
}

export interface State {
  stage: 'select' | 'confirm';
  newPlan?: string;
  paymentMethod?: string;
  submitting: boolean;
  error?: Error;
}

function stateReducer(state: State, action: Actions): State {
  switch (action.type) {
    case 'change_plan':
      return {
        ...state,
        stage: 'select',
        error: undefined
      };
    case 'confirm_plan':
      return {
        ...state,
        newPlan: action.planId,
        stage: 'confirm'
      };
    case 'submit_change':
      return {
        ...state,
        paymentMethod: action.paymentId,
        error: undefined,
        submitting: true
      };
    case 'submit_change_success':
      return {
        ...state,
        submitting: false
      };
    case 'submit_change_error':
      return {
        ...state,
        submitting: false,
        error: action.error
      };
    default:
      console.error('Unexpected action in ChangePlan modal', action);
      return state;
  }
}

export function useChangePlanStore() {
  const [state, dispatch] = useReducer(stateReducer, {
    stage: 'select',
    newPlan: undefined,
    paymentMethod: undefined,
    submitting: false,
    error: undefined
  });

  return {
    state,
    actions: {
      changePlan: () => dispatch({ type: 'change_plan' }),
      confirmPlan: (planId: string) =>
        dispatch({ type: 'confirm_plan', planId }),
      submitChange: (paymentId: string) =>
        dispatch({ type: 'submit_change', paymentId }),
      submitChangeError: (error: Error) =>
        dispatch({ type: 'submit_change_error', error }),
      submitChangeSuccess: () => dispatch({ type: 'submit_change_success' })
    }
  };
}

import { useState, useEffect } from 'react';
import usePusher from './use-pusher';

function useChannel(channelName) {
  const [channel, setChannel] = useState();
  const pusher = usePusher();

  useEffect(() => {
    if (!pusher) return;

    setChannel(pusher.subscribe(channelName));

    return () => {
      pusher.unsubscribe(channelName);
    };
  }, [pusher, channelName]);

  return channel;
}

export default useChannel;

/* eslint-disable max-lines */
import { Box } from '@rexlabs/box';
import FileUploadInput from '@rexlabs/file-upload-input';
import { withModel } from '@rexlabs/model-generator';
import { styled, StyleSheet } from '@rexlabs/styling/lib/index';
import { autobind } from 'core-decorators';
import _ from 'lodash';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import multipartUploadsModel from 'src/data/models/entities/multipart-uploads';
import videosModel from 'src/data/models/entities/videos';
import { BORDER_RADIUS, FONT } from 'src/theme';
import { Small } from 'src/view/components/text';
import withError from 'src/view/containers/with-error';
import { Video, VideoInProgress } from '.';

const MAX_FILE_SIZE = 1024 * 4000; // 4 GB
const CHUNK_SIZE = Math.pow(1024, 2) * 8; // 32 MiB

const ACCEPT_TYPES = [
  'application/vnd.apple.mpegurl',
  'application/x-mpegurl',
  'video/3gpp',
  'video/mp4',
  'video/mpeg',
  'video/ogg',
  'video/quicktime',
  'video/webm',
  'video/x-m4v',
  'video/ms-asf',
  'video/x-ms-wmv',
  'video/x-msvideo'
];

const ACCEPT_EXTENSIONS = [
  '.m3u',
  '.M3U',
  '.m3u8',
  '.M3U8',
  '.m3u',
  '.M3U',
  '.m3u8',
  '.M3U8',
  '.3gp',
  '.3GP',
  '.mp4',
  '.MP4',
  'm4a',
  'M4A',
  '.m4p',
  '.M4P',
  '.m4b',
  '.M4B',
  '.m4r',
  '.M4R',
  '.m4v',
  '.M4V',
  '.m1v',
  '.M1V',
  '.ogg',
  '.OGG',
  '.mov',
  '.MOV',
  '.qt',
  '.QT',
  '.webm',
  '.WEBM',
  '.m4v',
  '.M4V',
  '.asf',
  '.ASF',
  '.wma',
  '.WMA',
  '.wmv',
  '.WMV',
  '.avi',
  '.AVI'
];

const defaultStyles = StyleSheet({
  outer: {
    width: '100%',
    marginTop: '0.4rem'
  },

  container: {
    width: '100%',
    height: '14.4rem',
    overflow: 'auto'
  },

  button: {
    background: ({ token }) => token('color.primary.idle.default'),
    color: ({ token }) => token('color.textStyle.primary.idle.contrast'),
    fontWeight: FONT.WEIGHTS.SEMIBOLD,
    height: '4.4rem',
    paddingLeft: ({ token }) => token('spacing.m'),
    paddingRight: ({ token }) => token('spacing.m'),
    display: 'inline-flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
    width: 'auto',
    flexShrink: 0,
    border: '0 none',
    ':hover': {
      background: ({ token }) => token('color.primary.hover.default')
    }
  },

  inner: {
    position: 'absolute',
    top: '0',
    left: '0',
    right: '0',
    bottom: '0',
    transition: 'background-color 100ms ease-out'
  },

  innerEmpty: {},

  isDragging: {
    cursor: 'copy',
    backgroundColor: 'rgba(0, 0, 0, 0.5)'
  },

  noFileSelected: {
    backgroundColor: ({ token }) => token('color.container.static.contrast'),
    borderRadius: BORDER_RADIUS.INPUT,

    width: '100%',
    height: '100%',

    '&::before': {
      content: '" "',
      width: '100%',
      paddingBottom: `${(1 / 1.9) * 100}%`,
      display: 'inline-block'
    }
  },

  squareFirstChild: {
    '&::before': {
      paddingBottom: '100%'
    }
  },

  noFileSelectedSingle: {
    maxHeight: '18rem',
    overflow: 'hidden'
  },

  image: {
    height: '100%'
  },

  icon: {
    width: '1.4rem',
    height: '1.4rem',
    color: ({ token }) => token('legacy.color.blue.grey'),
    marginRight: ({ token }) => token('spacing.xs')
  },

  helper: {
    zIndex: 10000
  },

  inputContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-evenly',
    border: ({ token }) => `1px dashed ${token('legacy.color.blue.grey')}`,
    borderRadius: BORDER_RADIUS.INPUT,
    cursor: 'pointer',
    padding: '.8rem',
    maxHeight: '100%'
  }
});

const defaultState = {
  file: null,
  video: null,
  isUploading: false,
  uploadPercentage: 0,
  shouldCancel: false
};

@styled(defaultStyles)
class VideoUploadContainer extends Component {
  render() {
    const { children, styles: s } = this.props;

    return (
      <Box {...s('outer')} justifyContent='left'>
        {children}
      </Box>
    );
  }
}

@withError()
@withModel(multipartUploadsModel)
@withModel(videosModel)
@styled(defaultStyles)
@autobind
class VideoInput extends Component {
  static propTypes = {
    uploadChunk: PropTypes.func
  };

  static defaultProps = {
    canDelete: true
  };

  constructor(props) {
    super(props);
    this.state = defaultState;
  }

  componentDidMount() {
    const { value } = this.props;

    if (value) {
      this.setState({
        ...this.state,
        video: value
      });
    }
  }

  async handleChange(e) {
    const [file] = e.target.files;
    const { multipartUploads, onChange } = this.props;

    try {
      const { data } = await multipartUploads.createItem({
        data: {
          name: file.name
        }
      });

      return this.setState(
        {
          ...this.state,
          isUploading: true,
          shouldCancel: false,
          uploadPercentage: 0,
          file,
          multipartUpload: data
        },
        () => {
          onChange(
            this.makeChangeEvent({
              isUploading: true,
              name: file.name
            })
          );
          return multipartUploads
            .uploadChunks({
              multipartUploadId: data.id,
              file,
              chunkSize: CHUNK_SIZE,
              onChunk: this.onChunk
            })
            .then(() => this.onCompleteUpload());
        }
      );
    } catch (e) {
      const { error } = this.props;
      if ((e.problem && e.problem !== 'CANCEL_ERROR') || !error.open) {
        error.open('We encountered an error uploading your video');
      }
      this.setState(defaultState);
    }
  }

  onChunk(chunkNumber) {
    const { file, shouldCancel } = this.state;

    if (shouldCancel === true) {
      return false;
    }

    const chunks = Math.ceil(file.size / CHUNK_SIZE);

    this.setState({
      ...this.state,
      uploadPercentage: ((chunkNumber - 1) / chunks) * 100
    });
  }

  async onCompleteUpload() {
    const { multipartUploads, videos } = this.props;
    const { multipartUpload } = this.state;

    this.setState({
      ...this.state,
      uploadPercentage: 99
    });

    try {
      await multipartUploads.updateItem({
        id: multipartUpload.id,
        data: {
          status: {
            id: 'complete'
          }
        }
      });

      /* create video */
      const { data } = await videos.createItem({
        data: {
          name: multipartUpload.name,
          multipart_upload: { id: multipartUpload.id }
        }
      });

      return this.setState(
        {
          ...defaultState,
          video: data
        },
        () => {
          const { video } = this.state;
          const { onChange } = this.props;

          const e = this.makeChangeEvent(video);

          onChange(e);
        }
      );
    } catch (e) {
      const { error } = this.props;
      error.open('We encountered an error uploading your video');
      this.setState(defaultState);
    }
  }

  makeChangeEvent(video) {
    const { name } = this.props;
    return {
      persist: _.noop,
      target: {
        type: 'file',
        name,
        id: video ? video.name : null,
        files: video
      }
    };
  }

  handleInvalidFileSize() {
    const { error } = this.props;
    error.open(`File is too large, max file size: ${MAX_FILE_SIZE}KB`);
  }

  handleInvalidFileType() {
    const { error } = this.props;
    error.open(
      `File is the wrong type, accepted extensions: ${_.uniq(
        ACCEPT_EXTENSIONS.map((extension) =>
          extension.substr(1, extension.length).toLowerCase()
        )
      ).join(', ')}`
    );
  }

  onClickCancel(e) {
    e.preventDefault();
    const { onChange } = this.props;
    this.setState({
      ...defaultState,
      shouldCancel: true
    });
    onChange(this.makeChangeEvent(null));
  }

  clickRemoveVideo() {
    const { onChange } = this.props;
    this.setState({
      ...this.state,
      video: null
    });
    onChange(this.makeChangeEvent(null));
  }

  render() {
    const {
      error: { Error },
      styles: s,
      ...rest
    } = this.props;

    const loading = false;
    const { isUploading, uploadPercentage, file, video } = this.state;

    if (isUploading) {
      /* render progress bar */
      return (
        <VideoUploadContainer loading={loading}>
          <VideoInProgress
            progressPercentage={uploadPercentage}
            name={file.name}
            clickCancel={this.onClickCancel}
          />
        </VideoUploadContainer>
      );
    }

    if (video) {
      /* render video image */
      const { canDelete } = this.props;
      return (
        <Box {...s('outer')} justifyContent='left'>
          <Video
            video={video}
            clickRemove={canDelete && this.clickRemoveVideo}
          />
        </Box>
      );
    }

    /* render input */
    return (
      <VideoUploadContainer loading={loading}>
        <Error />
        <FileUploadInput
          {...rest}
          shouldAllowMultiple={false}
          acceptTypes={ACCEPT_TYPES}
          acceptExtensions={ACCEPT_EXTENSIONS}
          maxFileSize={MAX_FILE_SIZE}
          onChange={this.handleChange}
          onClick={_.noop}
          onInvalidFileSize={this.handleInvalidFileSize}
          onInvalidType={this.handleInvalidFileType}
        >
          {({ InputContainer, inputProps, dragEvents, isDragging }) => (
            <Box
              {...s('container')}
              alignItems='left'
              justifyContent='left'
              flexWrap='wrap'
            >
              <InputContainer
                {...inputProps}
                {...dragEvents}
                styles={{
                  container: defaultStyles.inputContainer
                }}
              >
                <Box
                  key='inner'
                  {...s('inner', 'innerEmpty', { isDragging })}
                  flexDirection='column'
                  alignItems='center'
                  justifyContent='center'
                >
                  <div {...s('button')}>Upload Video</div>
                  <Small grey>
                    {!isDragging ? 'or drag it in' : 'drop here'}
                  </Small>
                </Box>
                <div
                  {...s('noFileSelected', {
                    noFileSelectedSingle: true,
                    firstChild: true,
                    squareFirstChild: true
                  })}
                />
              </InputContainer>
            </Box>
          )}
        </FileUploadInput>
      </VideoUploadContainer>
    );
  }
}

export default VideoInput;

import React, { ReactNode } from 'react';
import {
  focus,
  margin,
  padding,
  StyleSheet,
  useStyles
} from '@rexlabs/styling';
import { ButtonGroup } from '@rexlabs/button';
import { BoxProps } from '@rexlabs/box';

const sticky = StyleSheet({
  container: {
    position: 'sticky',
    bottom: 0,
    width: '100%',
    transform: 'translateZ(0)',
    background:
      'linear-gradient(to bottom, rgba(255,255,255,0.8) 0%,rgba(255,255,255,1) 70%)',
    ...focus.styles({
      shadow: 'buttonGroup'
    }),
    ...margin.styles({
      x: () => 0
    }),
    ...padding.styles({
      y: 's'
    })
  }
});

interface ButtonGroupProps extends Omit<BoxProps, 'ref'> {
  children?: ReactNode;
  end?: boolean;
}

function StickyButtonGroup({
  children,
  className,
  style,
  ...props
}: ButtonGroupProps) {
  const s = useStyles(sticky);
  return (
    <ButtonGroup {...s.with('container')({ className, style })} {...props}>
      {children}
    </ButtonGroup>
  );
}

const modalSticky = StyleSheet({
  container: {
    paddingBottom: 0
  },

  padded: {
    background:
      'linear-gradient(to bottom, rgba(255,255,255,0.8) 0%,rgba(255,255,255,1) 70%)',
    ...padding.styles({
      top: 'xs',
      x: 'xl',
      bottom: 'xl'
    }),

    '& div': {
      background: 'none'
    },
    '&::after': {
      content: '""',
      height: 0,
      width: 0
    }
  }
});

interface ModalStickyButtonGroupProps extends ButtonGroupProps {
  padded?: boolean;
}

function ModalStickyButtonGroup({
  padded = false,
  children,
  className,
  style,
  ...props
}: ModalStickyButtonGroupProps) {
  const s = useStyles(modalSticky);
  return (
    <StickyButtonGroup
      {...s.with('container', { padded })({ className, style })}
      {...props}
    >
      {children}
    </StickyButtonGroup>
  );
}

export { StickyButtonGroup, ModalStickyButtonGroup };

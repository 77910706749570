import Icons from '@rexlabs/icons-next';
import { StyleSheet, useStyles, useToken } from '@rexlabs/styling';
import React, { cloneElement } from 'react';
import {
  HTMLPolymorphicProps,
  forwardRef,
  poly
} from 'src/lib/polymorphic-factory';
import { getValidChildren } from 'src/utils/react';
import { BreadcrumbOptions } from './types';

const styles = StyleSheet({
  list: {
    display: 'flex',
    alignItems: 'center'
  }
});

export type BreadcrumbProps = HTMLPolymorphicProps<'nav'> &
  BreadcrumbOptions & {
    listProps?: HTMLPolymorphicProps<'ol'>;
  };

/**
 * Breadcrumb is used to render a breadcrumb navigation landmark.
 * It renders a `nav` element with `aria-label` set to `Breadcrumb`
 *
 * @see WAI-ARIA https://www.w3.org/WAI/ARIA/apg/patterns/breadcrumb/
 */
export const Breadcrumb = forwardRef<'nav', BreadcrumbProps>(
  (
    {
      children,
      spacing,
      separator = <Icons.ChevronRight />,
      className,
      style,
      listProps,
      ...rest
    },
    ref
  ) => {
    const s = useStyles(styles, 'Breadcrumb');
    const token = useToken();
    const validChildren = getValidChildren(children);

    const clones = validChildren.map((child, index) =>
      cloneElement(child, {
        separator,
        spacing: spacing ?? token('spacing.xs'),
        isFirstChild: index === 0
      })
    );

    return (
      <poly.nav
        ref={ref}
        aria-label='breadcrumb'
        {...s.with()({ className, style })}
        {...rest}
      >
        <poly.ol {...s('list')} {...listProps}>
          {clones}
        </poly.ol>
      </poly.nav>
    );
  }
);

import React from 'react';
import { TextInput as VividTextInput } from '@rexlabs/text-input';
import { StyleSheet } from '@rexlabs/styling';
import { standardStyles } from './text';
import { FONT } from 'src/theme';

const textInputStyles = StyleSheet({
  ...standardStyles,

  charLimitExceeded: {
    color: ({ token }) => token('legacy.color.orange.default'),
    fontWeight: FONT.WEIGHTS.SEMIBOLD
  }
});

const SoftCharLimitTextInput = (props) => {
  return <VividTextInput styles={textInputStyles} {...props} />;
};

export default SoftCharLimitTextInput;

import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { styled, StyleSheet } from '@rexlabs/styling';
import Box from '@rexlabs/box';
import _ from 'lodash';
import colorTheme from '../../../utils/color-theme';
import { SmallButton } from '../../button';
import ScaledText from '../../scaled-text';
import { COLORS } from 'src/theme';

const fontFamily = 'Lato, sans-serif';

const defaultStyles = StyleSheet({
  verticalRectangle: {
    border: 'solid 1px darkgray',
    overflow: 'hidden'
  },
  header: {
    position: 'relative'
  },
  img: {
    objectFit: 'cover'
  },
  content: {
    background: 'white'
  },
  shortHeadline: {
    fontSize: '24px',
    lineHeight: '30px',
    fontWeight: '300',
    fontFamily
  },
  footer: {},
  footerLogo: {
    maxWidth: 'calc(0.6 * 240px)',
    maxHeight: 'calc(0.7 * 60px)',
    objectFit: 'contain'
  }
});

@styled(defaultStyles)
class VerticalRectangle extends PureComponent {
  static propTypes = {
    brandColor: PropTypes.string,
    shortHeadline: PropTypes.string,
    primaryImageUrls: PropTypes.object,
    logoUrls: PropTypes.object,
    buttonText: PropTypes.string
  };

  static defaultProps = {
    buttonText: 'Learn more'
  };

  render() {
    const {
      brandColor,
      backgroundColor,
      shortHeadline,
      primaryImageUrls,
      logoUrls,
      buttonText,
      styles: s
    } = this.props;

    const { brandColorBorder } = colorTheme(brandColor);

    return (
      <Box
        width='240px'
        height='400px'
        display='flex'
        flexDirection='column'
        {...s('verticalRectangle')}
      >
        <img
          width='100%'
          height={188}
          src={_.get(primaryImageUrls, ['crop.landscape', 'url'])}
          {...s('img')}
        />
        <Box
          flex={1}
          display='flex'
          flexDirection='column'
          padding='20px 24px'
          {...s('content')}
        >
          <ScaledText
            maxWidth={202}
            maxTextLines={3}
            maxFontSize={32}
            minFontSize={16}
            {...s('shortHeadline')}
            color={COLORS.BLACK}
          >
            {shortHeadline}
          </ScaledText>
          <Box alignItems='flex-start' marginTop={24}>
            <SmallButton brandColor={brandColor}>{buttonText}</SmallButton>
          </Box>
        </Box>
        <Box
          height='60px'
          display='flex'
          flexDirection='column'
          alignItems='center'
          justifyContent='center'
          padding='15px 40px'
          {...s.with('footer')({
            backgroundColor: backgroundColor,
            borderTop: brandColorBorder
          })}
        >
          <img src={_.get(logoUrls, 'original.url')} {...s('footerLogo')} />
        </Box>
      </Box>
    );
  }
}

export default VerticalRectangle;

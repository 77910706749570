/* eslint-disable @typescript-eslint/no-explicit-any */
import { Box } from '@rexlabs/box';
import { GhostIconButton } from '@rexlabs/button';
import VividFileUpload from '@rexlabs/file-upload-input';
import Icons from '@rexlabs/icons-next';
import { useModelActions } from '@rexlabs/model-generator';
import { StyleSheet, useStyles } from '@rexlabs/styling';
import { partition, uniq } from 'lodash';
import React, { ChangeEvent, ComponentType, FC } from 'react';
import uploadsModel from 'src/data/models/custom/uploads';
import { BORDER_RADIUS, FONT } from 'src/theme';
import { Small } from 'src/view/components/text';
import withError, { WithErrorProps } from 'src/view/containers/with-error';
import { InputProps } from '..';

const defaultStyles = StyleSheet({
  container: {
    width: '100%',
    height: '14.4rem',
    overflow: 'auto'
  },

  button: {
    background: ({ token }) => token('color.primary.idle.default'),
    color: ({ token }) => token('legacy.color.white'),
    fontWeight: FONT.WEIGHTS.SEMIBOLD,
    height: '4.4rem',
    paddingLeft: ({ token }) => token('spacing.m'),
    paddingRight: ({ token }) => token('spacing.m'),
    display: 'inline-flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
    width: 'auto',
    flexShrink: 0,
    border: '0 none',
    ':hover': {
      background: ({ token }) => token('color.primary.hover.default')
    }
  },

  inner: {
    position: 'absolute',
    top: '0',
    left: '0',
    right: '0',
    bottom: '0',
    transition: 'background-color 100ms ease-out'
  },

  innerEmpty: {},

  isDragging: {
    cursor: 'copy',
    backgroundColor: 'rgba(0, 0, 0, 0.5)'
  },

  noFileSelected: {
    backgroundColor: ({ token }) => token('legacy.color.grey.light'),
    borderRadius: BORDER_RADIUS.INPUT,

    width: '100%',
    height: '100%',

    '&::before': {
      content: '" "',
      width: '100%',
      paddingBottom: `${(1 / 1.9) * 100}%`,
      display: 'inline-block'
    }
  },

  squareFirstChild: {
    '&::before': {
      paddingBottom: '100%'
    }
  },

  noFileSelectedSingle: {
    maxHeight: '18rem',
    overflow: 'hidden'
  },

  inputContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-evenly',
    border: ({ token }) => `1px dashed ${token('legacy.color.blue.grey')}`,
    borderRadius: BORDER_RADIUS.INPUT,
    cursor: 'pointer',
    padding: '.8rem',
    maxHeight: '100%'
  },

  deleteButton: {
    '& svg': {
      width: '1.6rem',
      height: '1.6rem'
    }
  }
});

function UploadFile({
  title,
  InputContainer,
  inputProps,
  dragEvents,
  isDragging,
  styles: s
}) {
  return (
    <Box
      {...s('container')}
      alignItems='left'
      justifyContent='left'
      flexWrap='wrap'
    >
      <InputContainer
        {...inputProps}
        {...dragEvents}
        styles={{
          container: defaultStyles.inputContainer
        }}
      >
        <Box
          key='inner'
          {...s('inner', 'innerEmpty', { isDragging })}
          flexDirection='column'
          alignItems='center'
          justifyContent='center'
        >
          <div {...s('button')}>{title}</div>
          <Small grey>{!isDragging ? 'or drag it in' : 'drop here'}</Small>
        </Box>
        <div
          {...s('noFileSelected', {
            noFileSelectedSingle: true,
            firstChild: true,
            squareFirstChild: true
          })}
        />
      </InputContainer>
    </Box>
  );
}

function uploadFile(fileUploader: (data: FormData) => Promise<any>) {
  return (file: File) => {
    const uploadDTO = new FormData();
    uploadDTO.append('file', file);
    return fileUploader(uploadDTO);
  };
}

export interface FileDisplayProps {
  files: any;
  DeleteFile: ComponentType<any>;
}

export interface FileUploadInputProps
  extends InputProps<{ id: string }>,
    WithErrorProps {
  accept?: string[];
  multi?: boolean;
  title?: React.ReactNode;
  maxFileSize?: number;
  FileDisplay?: React.ComponentType<FileDisplayProps>;
  onUpload: (files: FileList) => void;
}

const DEFAULT_TITLE = 'Upload File';
const DEFAULT_MAX_FILE_SIZE = 1024 * 10;

const _FileUploadInput: FC<FileUploadInputProps> = ({
  value,
  accept = [],
  multi = false,
  title = DEFAULT_TITLE,
  maxFileSize = DEFAULT_MAX_FILE_SIZE,
  actions,
  error: { Error, open: openError },
  FileDisplay = () => <div>File</div>,
  onUpload
}) => {
  const upload = useModelActions(uploadsModel);
  const s = useStyles(defaultStyles);
  const [acceptExtensions, acceptTypes] = partition(accept, (v) =>
    v.startsWith('.')
  );
  const uploadFileHandler = (e: ChangeEvent<HTMLInputElement>) => {
    const files = e.target.files;
    if (files) {
      const fileUploads = Array.from(files).map(uploadFile(upload.uploadFiles));
      return Promise.all(fileUploads)
        .then((res) => {
          const fileData = res.map(({ data }, i) => ({
            id: data.id,
            filename: files[i].name
          }));
          actions.setValue(multi ? fileData : fileData[0]);
          onUpload(files);
        })
        .catch((e) => openError(e.message));
    }
  };
  const warnInvalidFile = () => {
    const extensions = uniq(
      acceptExtensions.map((extension) =>
        extension.substr(1, extension.length).toLowerCase()
      )
    ).join(', ');
    openError(`File is the wrong type, accepted extensions: ${extensions}`);
  };

  if (!value || value.id === '') {
    return (
      <>
        <VividFileUpload
          shouldAllowMultiple={multi}
          maxFileSize={maxFileSize}
          acceptExtensions={acceptExtensions}
          acceptTypes={acceptTypes}
          onChange={uploadFileHandler}
          onInvalidType={warnInvalidFile}
        >
          {(props) => <UploadFile {...props} title={title} styles={s} />}
        </VividFileUpload>
        <Error />
      </>
    );
  }
  const fileData = value;
  return (
    <FileDisplay
      files={fileData}
      DeleteFile={(...props) => (
        <GhostIconButton
          {...props}
          {...s('deleteButton')}
          onClick={() => actions.setValue({ id: '' })}
          Icon={Icons.DeleteCross}
          size='m'
        />
      )}
    />
  );
};

export const FileUploadInput = withError()(_FileUploadInput);

import { QueryClient } from 'react-query';
import { commercialListingKeys } from 'src/features/listings/api/keys';
import { getCommercialListing } from 'src/features/listings/api/getCommercialListing';

export const commercialListingEditDetailsLoader = (
  queryClient: QueryClient,
  accountId: string,
  contentId: string
) => {
  return queryClient.fetchQuery({
    queryKey: commercialListingKeys.detail({
      accountId,
      listingId: contentId
    }),
    queryFn: () =>
      getCommercialListing({ accountId, listingId: contentId })
  });
};

import { Coord, distance } from 'src/lib/turf';
import type { MapboxGeoJSONFeature, MapLayerMouseEvent } from 'mapbox-gl';
import { Dispatch, Reducer, useReducer } from 'react';
import type { Units } from 'src/types';

type State = {
  isResizing: boolean;
  resizingRadius?: number;
};

type ResizingReducer = Reducer<State, MapLayerMouseEvent>;

const featureFilter = ({ source }: MapboxGeoJSONFeature): boolean =>
  ['ns_handles', 'ew_handles'].includes(source);

const reducer =
  (centerPoint: Coord, unit: Units): ResizingReducer =>
  (prevState, event) => {
    const { type, lngLat, features = [], originalEvent } = event;
    if (type === 'mousedown') {
      return features.find(featureFilter)
        ? {
            isResizing: true,
            resizingRadius: distance(centerPoint, lngLat.toArray(), {
              units: unit
            })
          }
        : prevState;
    } else if (type === 'mousemove') {
      if (
        features.find(({ source }) => source === 'ns_handles') &&
        originalEvent.target !== null
      )
        (originalEvent.target as HTMLCanvasElement).style.cursor = 'ns-resize';
      return prevState;
    } else {
      return prevState;
    }
  };

const INITIAL_STATE: State = {
  isResizing: false
};

export const useRadiusResizingState = (
  centerPoint: Coord,
  unit: Units
): [State, Dispatch<MapLayerMouseEvent>] => {
  return useReducer<ResizingReducer>(reducer(centerPoint, unit), INITIAL_STATE);
};

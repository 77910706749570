import { ImageCropSelectorProps } from '../components/ImageCropSelector';
import { Image } from '../types';

export function getImageCropSelectorData({ id, crops, sizes }: Image): Pick<
  ImageCropSelectorProps,
  'imageSrc' | 'originalSize' | 'crops'
> & {
  imageId: string;
} {
  const { width, height, url } = sizes.original;
  return {
    imageId: id,
    originalSize: [width, height],
    imageSrc: url,
    crops
  };
}

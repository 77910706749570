import _ from 'lodash';
import React, { PureComponent } from 'react';
import { Checkbox as VividCheckbox } from '@rexlabs/checkbox';
import { styled, StyleSheet } from '@rexlabs/styling';
import { autobind } from 'core-decorators';
import { BORDER_RADIUS } from 'src/theme';

// Memoize to avoid repeated re-creation of inputClassName object.
// (& hence re-rendering of checkbox component)
// + Collocate checkbox styles with checkbox component
// (hard to find styles that were applying here).

const getStyles = _.memoize(
  (inputClassName, containerClassName) => ({
    input: inputClassName,
    container: containerClassName
  }),
  (inputClassName, containerClassName) => inputClassName + containerClassName
);

@styled(
  StyleSheet({
    container: {
      '& + label': {
        color: ({ token }) => token('legacy.color.blue.grey'),
        whiteSpace: 'nowrap'
      }
    },
    input: {
      '& + label': {
        width: '2.4rem',
        height: '2.4rem',
        margin: '.7rem',
        marginLeft: 0,
        borderColor: ({ token }) => token('legacy.color.blue.grey'),
        borderWidth: '.1rem',
        borderRadius: BORDER_RADIUS.INPUT,

        '&::before': {
          borderBottomColor: ({ token }) => token('legacy.color.white'),
          borderRightColor: ({ token }) => token('legacy.color.white'),
          height: '1rem',
          width: '0.5rem'
        },

        '&::after': {
          top: 0,
          left: 0,
          right: 0,
          bottom: 0,
          width: 'auto',
          height: 'auto'
        }
      },

      '&:checked + label': {
        borderColor: ({ token }) => token('legacy.color.secondary.default'),
        backgroundColor: ({ token }) => token('legacy.color.secondary.default')
      }
    },

    containerDisabled: {
      '& > label': {
        cursor: 'auto'
      }
    },

    inputDisabled: {
      '& + label': {
        borderColor: ({ token }) => token('legacy.color.blue.grey'),
        background: ({ token }) => token('legacy.color.grey.light')
      },

      '&:checked + label': {
        borderColor: ({ token }) => token('legacy.color.blue.grey'),
        background: ({ token }) => token('legacy.color.grey.light'),

        '&::before': {
          borderBottomColor: ({ token }) => token('legacy.color.blue.grey'),
          borderRightColor: ({ token }) => token('legacy.color.blue.grey')
        }
      }
    },

    largeInput: {
      '& + label': {
        width: '3.2rem',
        height: '3.2rem'
      }
    },
    roundInput: {
      '& + label': {
        width: '2.4rem',
        height: '2.4rem',
        borderRadius: '2.4rem'
      }
    }
  })
)
@autobind
class Checkbox extends PureComponent {
  handleChange(...args) {
    const { onChange, disabled } = this.props;
    if (!disabled && onChange) {
      onChange(...args);
    }
  }

  render() {
    const { styles, large, round, grey, disabled, onChange, ...props } =
      this.props;

    const finalProps = {
      ...props,
      round: round
    };
    const inputClassName = styles({
      input: true,
      inputDisabled: disabled,
      largeInput: large,
      roundInput: !large & round
    }).className;
    const containerClassName = styles({
      container: true,
      containerDisabled: disabled
    }).className;

    return (
      <VividCheckbox
        onClick={(e) => e.stopPropagation()}
        onChange={this.handleChange}
        {...finalProps}
        styles={getStyles(inputClassName, containerClassName)}
      />
    );
  }
}

export default Checkbox;

/* eslint-disable max-lines */
import { border, margin, StyleSheet, useStyles } from '@rexlabs/styling';
import { TextInput } from '@rexlabs/text-input';
import EyeDropper from 'assets/icons/color-picker.svg';
import React, {
  ChangeEventHandler,
  forwardRef,
  useCallback,
  useState
} from 'react';
import { useBoolean } from 'react-use';
import {
  FormControlOptions,
  useFormControl,
  useFormControlProps
} from 'src/components/form';
import { useEnsuredForwardedRef } from 'src/hooks';
import { getReadableColorFromHexString, isValidColor } from 'src/utils/color';
import {
  Popover,
  PopoverContent,
  PopoverContentProps,
  PopoverTrigger
} from '../popover';

const styles = StyleSheet({
  container: {
    display: 'flex',
    position: 'relative',
    isolation: 'isolate',
    width: '100%',
    height: ({ token }) => token('textInput.height', '4.5rem'),
    minHeight: ({ token }) => token('textInput.minHeight', '4.5rem'),
    color: ({ token }) => token('color.textStyle.body.default'),
    backgroundColor: ({ token }) =>
      token('textInput.background.color', token('color.input.idle')),
    ...border.styles({
      all: {
        color: 'input.idle',
        radius: 'm',
        style: 'solid',
        width: 'thin'
      }
    }),
    ':hover': {
      ...border.styles({
        all: {
          color: 'input.hover'
        }
      })
    },
    ':focus-within': {
      ...border.styles({
        all: {
          color: 'input.focus'
        }
      })
    },
    '> * + *': {
      ...margin.styles({
        left: 'xs'
      })
    }
  },

  containerDisabled: {
    color: ({ token }) => token('color.textStyle.body.hint'),
    backgroundColor: ({ token }) => token('color.input.disabled.default'),
    ...border.styles({
      all: {
        color: 'input.disabled'
      }
    }),
    '&:hover': {
      ...border.styles({
        all: {
          color: 'input.disabled'
        }
      })
    }
  },

  containerInvalid: {
    ...border.styles({
      all: {
        color: 'input.error'
      }
    }),
    '&:hover': {
      ...border.styles({
        all: {
          color: 'input.error'
        }
      })
    },
    '&:focus-within': {
      ...border.styles({
        all: {
          color: 'input.error'
        }
      })
    }
  },

  input: {
    border: 'none',
    width: '100%',
    fontSize: '1.8rem',
    backgroundColor: 'transparent',
    color: 'inherit',

    '&:focus': {
      boxShadow: 'none'
    }
  },

  button: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: 'transparent',
    width: ({ token }) => token('textInput.height', '4rem'),
    ...border.styles({
      all: {
        width: 'none'
      },
      right: {
        color: 'input.idle',
        style: 'solid',
        width: 'thin'
      }
    })
  },

  popover: {
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',

    '> * + *': {
      ...margin.styles({
        top: 'xs'
      })
    }
  },

  colors: {
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
    alignItems: 'center',
    width: '100%',
    gap: ({ token }) => token('spacing.xs')
  },

  color: {
    width: '3rem',
    height: '3rem',
    cursor: 'pointer',
    ...border.styles({
      all: {
        color: 'container.interactive.idle',
        radius: 'm',
        width: 'thin'
      }
    }),

    ':hover': {
      ...border.styles({
        all: {
          color: 'container.interactive.hover'
        }
      })
    }
  }
});

const inputValueSetter = Object.getOwnPropertyDescriptor(
  window.HTMLInputElement.prototype,
  'value'
)?.set;

const DEFAULT_COLORS = [
  '#000000',
  '#ffffff',
  '#7adcb6',
  '#00d084',
  '#8ed1fc',
  '#0a92e3',
  '#abb8c3',
  '#eb244d',
  '#f78ca7',
  '#9833f0'
];

export type ColorPickerInputProps = Omit<
  JSX.IntrinsicElements['input'],
  'ref' | 'value'
> &
  FormControlOptions &
  Pick<PopoverContentProps, 'side' | 'align'> & {
    value?: string;
    colors?: string[];
  };

export const ColorPickerInput = forwardRef<
  HTMLInputElement,
  ColorPickerInputProps
>((props, ref) => {
  const s = useStyles(styles, 'ColorPickerInput');
  const {
    isDisabled,
    isReadOnly,
    isInvalid,
    value,
    colors = DEFAULT_COLORS,
    side,
    align = 'start',
    className,
    style,
    ...rest
  } = useFormControlProps(props);
  const inputRef = useEnsuredForwardedRef(ref);
  const validValue = isValidColor(value) ? value : undefined;
  const inputProps = useFormControl({
    ref: inputRef,
    readOnly: true,
    defaultValue: validValue,
    isDisabled,
    isInvalid,
    ...rest
  });
  const [color, setColor] = useState(validValue);
  const [customColor, setCustomColor] = useState(validValue ?? '#');
  const [isOpen, toggleOpen] = useBoolean(false);

  const preventOpenWhenDisabled = useCallback(
    (open: boolean) => {
      if (open && (isDisabled || isReadOnly)) return;
      toggleOpen(open);
    },
    [isDisabled, isReadOnly, toggleOpen]
  );
  const setInputValue = useCallback(
    (value: string) => {
      inputValueSetter?.call(inputRef.current, value);
      const ev = new Event('input', { bubbles: true });
      inputRef.current.dispatchEvent(ev);
      setColor(value);
    },
    [inputRef]
  );
  const handleCustomColorChange = useCallback<
    ChangeEventHandler<HTMLInputElement>
  >(
    (e) => {
      let value = e.target.value;
      if (!value.startsWith('#')) value = `#${value}`;
      setCustomColor(value);
      if (isValidColor(value)) setInputValue(value);
    },
    [setInputValue]
  );

  return (
    <Popover open={isOpen} onOpenChange={preventOpenWhenDisabled} usePortal>
      <PopoverTrigger asChild>
        <span
          {...s.with('container', {
            containerDisabled: isDisabled,
            containerInvalid: isInvalid
          })({ className, style })}
        >
          <button
            {...s.with('button')({
              style: {
                backgroundColor: color
              }
            })}
            type='button'
            disabled={isDisabled}
          >
            <EyeDropper
              style={{
                color: color ? getReadableColorFromHexString(color) : '#000'
              }}
            />
          </button>
          <input {...s('input')} type='text' {...inputProps} />
        </span>
      </PopoverTrigger>
      <PopoverContent {...s('popover')} side={side} align={align}>
        <span {...s('colors')}>
          {colors.map((color) => (
            <button
              key={color}
              {...s.with('color')({
                style: {
                  backgroundColor: color
                }
              })}
              onClick={() => setInputValue(color)}
            />
          ))}
        </span>
        <TextInput
          value={customColor}
          onChange={handleCustomColorChange}
          placeholder='Enter RGB hex color'
          meta={{
            error: isValidColor(customColor) ? null : 'Invalid color'
          }}
        />
      </PopoverContent>
    </Popover>
  );
});

import { Box } from '@rexlabs/box';
import { styled, StyleSheet, useToken } from '@rexlabs/styling';
import React, { Fragment, useState } from 'react';
import { BREAKPOINTS } from 'src/theme';
import { BarChart } from 'src/view/components/charts';
import { Select } from 'src/view/components/input';
import { StatsHeading } from 'src/view/components/stats';

const defaultStyles = StyleSheet({
  container: {
    display: 'block',

    '@media print': {
      display: 'none'
    }
  },

  // HACK: recharts fail to print if they're changing from display none
  containerPrint: {
    width: '20cm',
    visibility: 'hidden',
    position: 'absolute',

    '@media print': {
      width: '20cm',
      visibility: 'visible',
      position: 'relative'
    }
  },

  filters: {
    [BREAKPOINTS.TABLET_PORTRAIT_UP]: {
      marginLeft: ({ token }) => token('spacing.xxl')
    }
  }
});

function DeviceStats(props) {
  const { stats, styles: s } = props;
  const token = useToken();
  const [deviceData, setDeviceData] = useState('impressions');

  const {
    impressions_mobile_phones: impressionsMobilePhones,
    clicks_mobile_phones: clicksMobilePhones,
    impressions_tablets: impressionsTablets,
    clicks_tablets: clicksTablets,
    impressions_computers: impressionsComputers,
    clicks_computers: clicksComputers
  } = stats?.summary || {};

  const deviceStatsImpressions = [
    {
      name: 'Mobile',
      value: impressionsMobilePhones
    },
    {
      name: 'Tablet',
      value: impressionsTablets
    },
    {
      name: 'Desktop',
      value: impressionsComputers
    }
  ];

  const deviceStatsClicks = [
    {
      name: 'Mobile',
      value: clicksMobilePhones
    },
    {
      name: 'Tablet',
      value: clicksTablets
    },
    {
      name: 'Desktop',
      value: clicksComputers
    }
  ];

  return (
    <Fragment>
      <div {...s('container')}>
        <StatsHeading
          title='Where were people when they saw your ads or took action?'
          Filters={() => (
            <Box {...s('filters')}>
              <Select
                options={[
                  { value: 'impressions', label: 'Impressions' },
                  { value: 'clicks', label: 'Clicks' }
                ]}
                onChange={(e) => setDeviceData(e.target.value)}
                value={deviceData}
              />
            </Box>
          )}
        />
        <Box style={{ width: '100%' }}>
          <BarChart
            color={token('campaignReport.deviceStats.color')}
            width='100%'
            data={
              deviceData === 'impressions'
                ? deviceStatsImpressions
                : deviceStatsClicks
            }
          />
        </Box>
      </div>

      <div {...s('containerPrint')}>
        <StatsHeading title='Which device were people using when they saw your ads?' />
        <BarChart
          width='100%'
          data={deviceStatsImpressions}
          color={token('campaignReport.deviceStats.color')}
        />
      </div>
    </Fragment>
  );
}

export default styled(defaultStyles)(DeviceStats);

import { createRestAPIModelGenerator } from 'src/data/models/generator';

export const TYPE = 'videos';

const config = {
  entities: {
    args: {
      include: 'thumbnail.sizes'
    },
    related: {
      thumbnail: {
        include: 'thumbnail.sizes'
      }
    }
  }
};

const VideosGenerator = createRestAPIModelGenerator(TYPE, config);
export default VideosGenerator.createEntityModel();

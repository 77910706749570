import { RexApiError } from '@rexlabs/api-client';
import { useQuery } from 'react-query';
import { useAccountId } from 'src/features/accounts';
import { QueryConfig, QueryResult } from 'src/lib/react-query';
import { PaginatedResponse, Pagination, PaginationParams } from 'src/types';
import { api } from 'src/utils/api-client';
import { AdContentSet } from '../types';
import { adContentSetSchema } from '../utils/schemas';
import { campaignAdsKeys } from './keys';

export type GetCampaignAdContentSetsParams = PaginationParams & {
  accountId: string;
  campaignId: string;
};

export const getCampaignAdContentSets = async ({
  accountId,
  campaignId,
  ...params
}: GetCampaignAdContentSetsParams): Promise<
  PaginatedResponse<AdContentSet>
> => {
  const { data, pagination } = await api.get(
    `campaigns/${campaignId}/ad-content-sets`,
    params,
    {
      headers: {
        'X-Account-Id': accountId
      }
    }
  );
  return {
    data: data.map((v) => adContentSetSchema.create(v)),
    pagination: pagination as Pagination
  };
};

type QueryFn = typeof getCampaignAdContentSets;

export type UseCampaignAdContentSetsOptions = Omit<
  GetCampaignAdContentSetsParams,
  'accountId'
> & {
  config?: QueryConfig<QueryFn>;
};

export const useCampaignAdContentSets = ({
  campaignId,
  config = {},
  ...params
}: UseCampaignAdContentSetsOptions): QueryResult<
  PaginatedResponse<AdContentSet>
> => {
  const accountId = useAccountId();

  return useQuery<PaginatedResponse<AdContentSet>, RexApiError>({
    queryKey: campaignAdsKeys.list(accountId, campaignId, params),
    queryFn: () =>
      getCampaignAdContentSets({ accountId, campaignId, ...params }),
    ...config
  });
};

import { Box } from '@rexlabs/box';
import { GhostButton, PrimaryButton } from '@rexlabs/button';
import { useToken } from '@rexlabs/styling';
import React, { useState } from 'react';
import AUTH from 'src/routes/auth';
import { api } from 'src/utils/api-client';
import { createValidationRules } from 'src/utils/form';
import { Link } from 'src/utils/whereabouts';
import { AuthBox } from 'src/view/components/box';
import { Form, FormField, ReactForms } from 'src/view/components/form';
import { TextInput } from 'src/view/components/input';
import { Body, Label } from 'src/view/components/text';
import withError from 'src/view/containers/with-error';

const validate = createValidationRules({
  password: 'required|min:8',
  password_confirmed: ['same:password|required|min:8', 'password confirmation']
});

function ResetPasswordScreen({ match, errorModal, errorModal: { Error } }) {
  const [resetComplete, setResetComplete] = useState(false);
  const token = useToken();
  const email = match.query?.email;
  const authToken = match.params?.token;

  const handleSubmit = async (values) => {
    try {
      await api.post('/auth/reset-password', {
        email,
        token: authToken,
        password: values.password
      });

      setResetComplete(true);
    } catch (e) {
      errorModal.open(e);
    }
  };

  return (
    <AuthBox>
      <Error />

      {resetComplete ? (
        <Box>
          <Body>
            Your password was successfully reset! You can now log in with your
            new credentials.
          </Body>
          <Box mt={token('spacing.xxl')}>
            <Link to={AUTH.LOGIN}>Go to login form</Link>
          </Box>
          <Error />
        </Box>
      ) : (
        <ReactForms handleSubmit={handleSubmit} validate={validate}>
          {({ isSubmitting, submitForm }) => (
            <Form name='resetPassword'>
              <Body grey>
                Just type in and confirm the new password and we will reset it
                for you.
              </Body>
              <Box>
                <Label>Email</Label>
                <Body style={{ textAlign: 'left' }}>{email ?? '-'}</Body>
              </Box>
              <FormField
                name='password'
                label='New Password'
                Input={TextInput}
                inputProps={{
                  type: 'password'
                }}
                sendImmediate={true}
              />
              <FormField
                name='password_confirmed'
                label='Confirm New Password'
                Input={TextInput}
                inputProps={{
                  type: 'password'
                }}
                sendImmediate={true}
              />
              <Box
                mt={token('spacing.xl')}
                flexDirection='row'
                alignItems='center'
                justifyContent='space-between'
              >
                <Link to={AUTH.LOGIN}>
                  {({ onClick }) => (
                    <GhostButton onClick={onClick}>Back to Login</GhostButton>
                  )}
                </Link>
                <PrimaryButton
                  form='resetPassword'
                  onClick={submitForm}
                  isLoading={isSubmitting}
                >
                  Reset Password
                </PrimaryButton>
              </Box>
            </Form>
          )}
        </ReactForms>
      )}
    </AuthBox>
  );
}

export default withError('errorModal')(ResetPasswordScreen);

export default {
  namespace: 'campaign-performance',

  // Sorting options
  sorting: {},

  // Filter options
  filters: {
    search: {
      label: 'Search'
    },
    status: {
      label: 'Status'
    }
  }
};

import { Box } from '@rexlabs/box';
import { styled, StyleSheet } from '@rexlabs/styling';
import moment from 'moment';
import React from 'react';
import AuctionHammer from 'src/assets/icons/auction-hammer.svg';
import Calendar from 'src/assets/icons/cal.svg';
import Oval from 'src/assets/icons/oval.svg';
import { Body, Small } from 'src/view/components/text';

const defaultStyles = StyleSheet({
  container: {
    width: '100%',
    padding: ({ token }) => token('spacing.m')
  },

  details: {
    flexGrow: '1',
    marginLeft: ({ token }) => token('spacing.m'),
    marginRight: ({ token }) => token('spacing.m')
  },

  detailsBody: {
    color: ({ token }) => token('legacy.color.blue.grey')
  },

  oval: {
    marginLeft: ({ token }) => token('spacing.xs'),
    marginRight: ({ token }) => token('spacing.xs'),
    marginBottom: ({ token }) => token('spacing.xxs'),
    fill: ({ token }) => token('legacy.color.blue.grey')
  },

  delete: {
    fontSize: '1.4rem',

    ':hover': {
      cursor: 'pointer'
    }
  }
});

function EventItem({ data, onDelete, styles: s }) {
  const formatLocation = (location) => {
    if (location.length > 23) {
      return `${location.slice(0, 20)}...`;
    }
    return location;
  };

  const type = data?.type?.id;

  return (
    <Box flexDirection='row' alignItems='center' {...s('container')}>
      {type === 'auction' ? <AuctionHammer /> : <Calendar />}
      <Box {...s('details')}>
        <Body Tag='span'>{moment(data.start_at).format('DD MMM YYYY')}</Body>
        <Box {...s('detailsBody')}>
          {type === 'auction' && 'Auction'}
          {type === 'open_home' && 'Open Home'}
          {type === 'inspection' && 'Inspection'} <Oval {...s('oval')} />{' '}
          {type === 'auction' ? (
            <span>
              {formatLocation(data.location)} <Oval {...s('oval')} />{' '}
              {moment(data.start_at).format('h:mm a')}
            </span>
          ) : (
            <span>
              {moment(data.start_at).format('h:mm a')} -{' '}
              {moment(data.end_at).format('h:mm a')}
            </span>
          )}
        </Box>
      </Box>
      <Box>
        <Small grey Tag='span' {...s('delete')}>
          <a onClick={onDelete}>Delete</a>
        </Small>
      </Box>
    </Box>
  );
}

export default styled(defaultStyles)(EventItem);

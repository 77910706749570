import Icons from '@rexlabs/icons-next';
import { query, useEntityListQuery } from '@rexlabs/model-generator';
import React, { useMemo } from 'react';
import agenciesModel from 'src/data/models/entities/agencies';
import { formattedBrandName } from 'src/theme';
import { useProvidePrivacyPolicyModal } from 'src/view/modals/admin/provide-privacy-policy';
import { InfoBanner, WarningBanner } from '.';

const q = query`{
  ${agenciesModel} {
    id
    website_privacy_url
  }
}`;

export function AdvancedStrategiesBanner() {
  const agencies = useEntityListQuery(q);
  const agency = useMemo(() => agencies.data?.[0], [agencies.data]);
  const hasPrivacyPolicy = useMemo(
    () =>
      typeof agency?.website_privacy_url === 'string' &&
      agency?.website_privacy_url.length > 0,
    [agency]
  );
  const [PrivacyPolicyModal, openPrivacyPolicyModal] =
    useProvidePrivacyPolicyModal();

  if (agency === undefined) {
    return null;
  }

  if (!hasPrivacyPolicy) {
    return (
      <>
        <WarningBanner
          Icon={Icons.InfoCircle}
          actions={[
            {
              label: 'Provide URL',
              onClick: openPrivacyPolicyModal
            }
          ]}
        >
          To adhere to the advertising policies of Facebook and Google please
          provide your website’s privacy policy URL.
        </WarningBanner>
        <PrivacyPolicyModal agencyId={agency?.id} />
      </>
    );
  }

  return (
    <InfoBanner
      Icon={Icons.InfoCircle}
      actions={[
        {
          label: 'Show me how',
          href: 'https://www.rexsoftware.com/articles/prepare-your-facebook-page-for-advanced-listings',
          external: true
        }
      ]}
    >
      To be able to use {formattedBrandName}’s Advanced Listing Campaigns we
      need increased access to your Facebook page.
    </InfoBanner>
  );
}

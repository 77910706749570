import { useQuery, UseQueryOptions } from 'react-query';
import { api } from 'src/utils/api-client';
import { LeadCaptureEmail } from '../types';
import { leadCaptureEmailsKeys } from './keys';

export const getLeadCaptureEmails = async (
  campaignId: string
): Promise<LeadCaptureEmail[]> => {
  const res = await api.get(`/campaigns/${campaignId}/lead_capture_emails`);
  return res.data;
};

export const useLeadCaptureEmails = <TData = LeadCaptureEmail[]>(
  campaignId: string,
  options?: UseQueryOptions<LeadCaptureEmail[], Error, TData>
) => {
  return useQuery<LeadCaptureEmail[], Error, TData>(
    leadCaptureEmailsKeys.list(campaignId),
    () => getLeadCaptureEmails(campaignId),
    {
      ...options
    }
  );
};

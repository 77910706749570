import { ComponentType } from 'react';
import FlinkLogo from 'src/assets/brand/flink/flink-logo.svg';
import FlinkPoweredBySpoke from 'src/assets/brand/flink/flink-powered-by-spoke.svg';
import ReachMark from 'src/assets/brand/reach/mark.svg';
import ReachPrimary from 'src/assets/brand/reach/primary.svg';
import ReachPrimaryWhite from 'src/assets/brand/reach/primary-white.svg';
import ReachRexMaster from 'src/assets/brand/reach/rex-master.svg';
import ReachSecondary from 'src/assets/brand/reach/secondary.svg';
import FlinkApple from 'src/assets/favicons/flink/apple-touch-icon.png';
import FlinkPng16 from 'src/assets/favicons/flink/favicon-16x16.png';
import FlinkPng32 from 'src/assets/favicons/flink/favicon-32x32.png';
import FlinkIcon from 'src/assets/favicons/flink/favicon.ico';
import ReachApple from 'src/assets/favicons/reach/apple-touch-icon.png';
import ReachPng16 from 'src/assets/favicons/reach/favicon-16x16.png';
import ReachPng32 from 'src/assets/favicons/reach/favicon-32x32.png';
import ReachIcon from 'src/assets/favicons/reach/favicon.ico';
import config from 'src/config';

const FLINK_HOSTNAME = 'flink.app.spokeapp.io';

export type Brand = 'reach' | 'flink';
export type LogoVariants =
  | 'rex_master'
  | 'primary'
  | 'secondary'
  | 'mark'
  | 'primary_white';
export type FaviconTypes = 'icon' | 'png16' | 'png32' | 'apple';
export type BrandMeta = {
  name: string;
  logos: Record<LogoVariants, ComponentType<React.ComponentProps<'svg'>>>;
  favicons: Record<FaviconTypes, string>;
};

export const brandMeta: Record<Brand, BrandMeta> = {
  flink: {
    name: 'Flink',
    logos: {
      primary: FlinkLogo,
      primary_white: FlinkLogo,
      secondary: FlinkPoweredBySpoke,
      rex_master: FlinkPoweredBySpoke,
      mark: FlinkLogo
    },
    favicons: {
      icon: FlinkIcon,
      png16: FlinkPng16,
      png32: FlinkPng32,
      apple: FlinkApple
    }
  },
  reach: {
    name: 'Rex Reach',
    logos: {
      primary: ReachPrimary,
      primary_white: ReachPrimaryWhite,
      secondary: ReachSecondary,
      rex_master: ReachRexMaster,
      mark: ReachMark
    },
    favicons: {
      icon: ReachIcon,
      png16: ReachPng16,
      png32: ReachPng32,
      apple: ReachApple
    }
  }
};

const detectBrand = (): Brand => {
  if (config.MODE === 'development' && config.BRAND)
    return config.BRAND as Brand;

  return window.location.hostname === FLINK_HOSTNAME ? 'flink' : 'reach';
};

export const getBrandMeta = (brand: Brand): BrandMeta => brandMeta[brand];

export const brand = detectBrand();

import {Checkbox} from '@rexlabs/checkbox';
import {StyleSheet, margin, useStyles, useToken} from '@rexlabs/styling';
import {Body} from '@rexlabs/text';
import React, {FC, useCallback, useEffect, useRef, useState} from 'react';
import {ListItem, ListItemProps} from 'src/components/elements/listbox';
import {Consumer} from 'src/types';
import {CatalogListingSet} from '../types';
import Money, {currencyCodes} from "view/components/money";
import {useModelState} from "@rexlabs/model-generator";
import session from "data/models/custom/session";
import {getAccountRegion} from "shared/utils/region";
import {Separator} from "src/components/elements/separator";
import {useCatalogListingSetListings} from "src/features/catalog-listing-sets/api/getCatalogListingSetListings";
import {Box} from "@rexlabs/box";
import {Tooltip} from "src/components/elements/tooltip";
import {CatalogListItemImage} from "src/features/catalog-listing-sets/components/CatalogListItemImage";
import {CatalogListingsCountTag} from "src/features/catalog-listing-sets/components/CatalogListingsCountTag";

const LISTING_CATEGORIES_MAP: Record<string, string> = {
  residential_sale: 'Residential Sale',
  residential_rental: 'Residential Rental',
  holiday_rental: 'Holiday Rental',
  rural_sale: 'Rural Sale',
  land_sale: 'Land Sale',
  business_sale: 'Business Sale',
  commercial_sale: 'Commercial Sale',
  commercial_sale_rental: 'Commercial Sale or Rental',
  commercial_rental: 'Commercial Rental',
}

const styles = StyleSheet({
  container: {
    listStyle: 'none',
    display: 'flex',
    flexDirection: 'row',
    gap: '24px',
    padding: '26px 16px 16px 26px',
    alignItems: 'center',
    cursor: 'pointer',
    transition: 'all .2s',

    ':hover': {
      backgroundColor: ({token}) => token('color.container.interactive.hover')
    },

    ':active': {
      backgroundColor: ({token}) =>
        token('color.container.interactive.active')
    },

    '&[aria-selected="true"]': {
      backgroundColor: ({token}) =>
        token('color.container.interactive.selected')
    }
  },

  details: {
    display: 'flex',
    flexDirection: 'column',
    flex: '1 1 auto',
    gap: '4px'
  },

  label: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    gap: ({token}) => token('spacing.m')
  },

  text: {
    margin: '0',
    padding: '0',
    lineHeight: ({token}) => token('typography.lineHeight.l'),
    fontWeight: ({token}) => token('typography.weight.bold'),
    fontSize: ({token}) => token('typography.size.xl'),
    letterSpacing: '0',
    whiteSpace: 'nowrap'
  },

  textMeta: {
    fontSize: ({token}) => token('typography.size.l')
  },

  toolbar: {
    fontSize: '1.2rem !important',
    display: 'flex',
    alignItems: 'center',

    '& > * + *': {
      ...margin.styles({
        left: 'xxs'
      })
    }
  },

  separator: {
    width: '4px',
    height: '4px',
    borderRadius: '99999px',
    backgroundColor: ({token}) => token('color.textStyle.body.subtext')
  },

  detailsList: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    flexWrap: 'wrap',
    columnGap: '10px'
  }
});

export type CatalogListingSetItemProps = Pick<
  ListItemProps,
  'posinset' | 'selected' | 'className' | 'style' | 'children'
> & {
  item: CatalogListingSet;
  onChange: Consumer<boolean>;
  isRefetching: boolean;
  displayWarning: () => void;
};

export const CatalogListingSetItem: FC<CatalogListingSetItemProps> = ({
  item,
  onChange,
  selected,
  posinset,
  className,
  style,
  children,
  displayWarning,
  isRefetching
}) => {
  const s = useStyles(styles, 'CatalogListingSetItem');
  const token = useToken();
  const sessionState = useModelState(session);
  const countryCode = getAccountRegion(sessionState);
  const [listingsFetched, setListingsFetched] = useState(false);
  const currencyCode = currencyCodes[countryCode];
  const itemId = `listing-set-${item.id}`;
  const labelId = `${itemId}-label`;

  const handleChange = useCallback(() => onChange(!selected), [onChange, selected]);
  const checkbox = useRef<HTMLInputElement>(null);
  const toggleCheckbox = useCallback(() => checkbox.current?.click(), []);

  const {data: listingSetListingsData, isLoading, isFetched} = useCatalogListingSetListings({
    listingSetId: item.id ?? '',
    config: {
      enabled: item.id !== undefined && !isRefetching && !listingsFetched
    }
  });

  const cataloguePreviewImages = listingSetListingsData?.pages?.[0]?.data?.slice(0,4)?.map((i) => i.thumbnails?.['original.thumbnail']?.url);

  useEffect(() => {
    if (isFetched && !listingsFetched) setListingsFetched(isFetched)
  }, [isFetched])

  useEffect(() => {
    if (selected && listingSetListingsData?.pages?.[0]?.pagination?.total === 0) {
      displayWarning()
    }
  }, [selected])

  return (
    <ListItem
      id={itemId}
      posinset={posinset}
      selected={selected}
      aria-labelledby={labelId}
      {...s.with('container')({className, style})}
    >
      <Box width={'72px'} height={'72px'} marginRight={token('spacing.m')} style={{flexShrink: 0}}>
        <CatalogListItemImage imageUrls={cataloguePreviewImages ?? []} />
      </Box>
      <div {...s('details')}>
        <div {...s('label')} onClick={toggleCheckbox}>
          <Body {...s('text')} id={labelId} as='span'>
            {item.name}
          </Body>
          <CatalogListingsCountTag
            count={listingSetListingsData?.pages?.[0]?.pagination?.total}
            label={'Listings'}
            isLoading={isLoading}
          />
        </div>

        <div {...s('detailsList')} onClick={toggleCheckbox}>
          <div>
            <Body {...s('text', 'textMeta')} grey as='span'>
              {`Categories: `}
            </Body>
            <Tooltip
              side='right'
              content={
                <div {...s('help')}>
                  {item.listing_categories.map((i) => (
                    <div key={i}>{LISTING_CATEGORIES_MAP[i]}</div>
                  ))}
                </div>
              }>
              <Body {...s('textMeta')} grey as='span'>
                {item.listing_categories?.slice(0, 3).map(i => LISTING_CATEGORIES_MAP[i]).join(', ')}
                {item.listing_categories?.length > 3 ? `, ` : null}
                <u>{item.listing_categories?.length > 3 ? `+${item.listing_categories.length - 3} more` : null}</u>
              </Body>
            </Tooltip>
          </div>

          {item.listing_min_price ?
            <>
            <Separator orientation='vertical' {...s('separator')} />
              <div>
                <Body {...s('text', 'textMeta')} grey as='span'>
                  {`Min price: `}
                </Body>
                <Body {...s('textMeta')} grey as='span'>
                  <Money amount={item.listing_min_price ?? 0} currencyCode={currencyCode}/>
                </Body>
              </div>
            </>
            : null}

          {item.listing_max_price ?
            <>
              <Separator orientation='vertical' {...s('separator')} />
              <div>
                <Body {...s('text', 'textMeta')} grey as='span'>
                  {`Max price: `}
                </Body>
                <Body {...s('textMeta')} grey as='span'>
                  <Money amount={item.listing_max_price ?? 0} currencyCode={currencyCode}/>
                </Body>
              </div>
            </>
            : null}

          {item.agents?.data?.length > 0 ?
            <>
              <Separator orientation='vertical' {...s('separator')} />
              <div>
                <Body {...s('text', 'textMeta')} grey as='span'>
                  {`Agents: `}
                </Body>
                <Body {...s('textMeta')} grey as='span'>
                  {item.agents.data.map(i => i.full_name).join(', ')}
                </Body>
              </div>
            </>
            : null}

          {item.show_agent_image ?
            <>
              <Separator orientation='vertical' {...s('separator')} />
              <div>
                <Body{...s('textMeta')} grey as='span'>
                  {`Include agent image`}
                </Body>
              </div>
            </>
            : null}

          {item.listing_sold ? <>
            <Separator orientation='vertical' {...s('separator')} />
            <div>
              <Body{...s('textMeta')} grey as='span'>
                {`Sold listings only`}
              </Body>
            </div>
          </> : null}

          {item.limit ? <>
            <Separator orientation='vertical' {...s('separator')} />
            <div>
              <Body{...s('textMeta')} grey as='span'>
                {`Limit ${item.limit}`}
              </Body>
            </div>
          </> : null}

        </div>

        <div {...s('toolbar')}>
          {children}
        </div>
      </div>
      <Checkbox
        ref={checkbox}
        aria-labelledby={labelId}
        name={item.id}
        value={selected}
        onChange={handleChange}
      />
    </ListItem>
  );
};

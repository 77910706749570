import { styled, StyleSheet } from '@rexlabs/styling';
import React, { PureComponent } from 'react';
import { FONT } from 'src/theme';
import { Link } from 'src/utils/whereabouts';

import BackIcon from 'src/assets/icons/back.svg';

const defaultStyles = StyleSheet({
  link: {
    fontWeight: FONT.WEIGHTS.NORMAL,
    flexDirection: 'row',
    display: 'inline-flex',
    alignItems: 'center',
    fontSize: '1.4rem',
    color: ({ token }) => token('legacy.color.blue.dark'),

    '> svg': {
      height: '1.1rem',
      width: 'auto',
      marginRight: ({ token }) => token('spacing.xs')
    }
  }
});

@styled(defaultStyles)
class BackLink extends PureComponent {
  render() {
    const { children, styles: s, ...rest } = this.props;
    return (
      <Link {...rest}>
        {({ target, onClick }) => (
          <a href={target} onClick={onClick} {...s('link')}>
            <BackIcon />
            <span> {children}</span>
          </a>
        )}
      </Link>
    );
  }
}

export default BackLink;

import { createRestAPIModelGenerator } from 'src/data/models/generator';
import { api } from 'src/utils/api-client';
import { cropEntityImages } from 'src/utils/images';
import _ from "lodash";

export const TYPE = 'listings';

const config = {
  entities: {
    related: {
      thumbnails: {
        include: 'thumbnails'
      },
      address: {
        include: 'address'
      },
      agents: {
        include: 'agents.images.sizes'
      },
      images: {
        include: 'images.sizes'
      },
      videos: {
        include: 'videos.thumbnail.sizes'
      },
      floor_plans: {
        include: 'floor_plans.sizes'
      },
      events: {
        include: 'events'
      }
    },

    // Overriding fetch actions, since we want to crop images when fetching
    // campaign data (see hack note in util)
    api: {
      fetchList: async (type, args) => {
        const { campaignToken, ...restArgs } = args;

        let response;
        // if we have a campaign token we 'put' to this endpoint which will import
        // the listing from rex
        if (campaignToken) {
          response = await api.put(`/account/campaign-tokens/${campaignToken}`);
        } else {
          response = await api.get('/listings', restArgs);
        }

        let { data, ...rest } = response;

        // if we used a campaign token the response will be a single object
        if (!Array.isArray(data)) {
          data = [data];
        }

        return Promise.all(
          data.length ? data.map((item) => cropEntityImages(item)) : []
        ).then((data) => ({ ...rest, data }));
      },
      fetchItem: (type, args, id) =>
        api
          .get(`/listings/${id}`, args)
          .then(({ data, ...rest }) =>
            cropEntityImages(data).then((data) => ({ ...rest, data }))
          )
    }
  }
};

const actionCreators = {
  patchItem: {
    request: (payload) =>
      api.patch(`/listings/${payload.id}`, payload.data),
    reduce: {
      initial: _.identity,
      success: _.identity,
      failure: _.identity
    }
  }
}

const ListingsGenerator = createRestAPIModelGenerator(TYPE, config);
export default ListingsGenerator.createEntityModel({actionCreators});

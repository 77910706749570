import { PaginationParams } from 'src/types';

type ListParams = { pagination: PaginationParams; includes?: string[] };
type DetailParams = { accountId: string; includes?: string[] };

export const accountKeys = {
  all: ['accounts'] as const,
  lists: () => [...accountKeys.all, 'list'] as const,
  list: ({ pagination, includes }: ListParams) =>
    [...accountKeys.lists(), { pagination, includes }] as const,
  details: () => [...accountKeys.all, 'detail'] as const,
  detail: ({ accountId, includes = [] }: DetailParams) =>
    [...accountKeys.details(), accountId, { includes }] as const
};

import { Box } from '@rexlabs/box';
import { GhostButton, PrimaryButton } from '@rexlabs/button';
import { withModel, withQuery } from '@rexlabs/model-generator';
import { styled, StyleSheet } from '@rexlabs/styling';
import { Heading } from '@rexlabs/text';
import { autobind } from 'core-decorators';
import React, { PureComponent } from 'react';
import { Elements, injectStripe } from 'react-stripe-elements';
import sessionModel from 'src/data/models/custom/session';
import { paymentOptionsQuery } from 'src/data/queries/payment-options';
import { BORDER_RADIUS, withToken } from 'src/theme';
import uuid from 'src/utils/uuid';
import { ModalStickyButtonGroup } from 'src/view/components/button';
import { Form, withForm } from 'src/view/components/form';
import { Checkbox } from 'src/view/components/input';
import { RenderLoading } from 'src/view/components/loading';
import { Modal } from 'src/view/components/modal';
import { PaymentPreview } from 'src/view/components/payment';
import { Body } from 'src/view/components/text';
import withError from 'src/view/containers/with-error';
import EditPaymentMethodForm from 'src/view/forms/payments/edit-payment-method';
import StripeProvider from 'src/view/forms/payments/stripe-provider';

const defaultStyles = StyleSheet({
  wrapAddPaymentOption: {
    padding: ({ token }) => token('spacing.m'),
    background: ({ token }) => token('color.container.interactive.idle'),
    borderRadius: BORDER_RADIUS.INPUT,
    display: 'inline-flex !important',
    height: '7.2rem',
    width: '100%',
    flexShrink: 0,
    marginTop: ({ token }) => token('spacing.m'),
    border: ({ token }) =>
      `.1rem solid ${token('color.border.container.interactive.idle')}`,
    cursor: 'pointer'
  },

  wrapAddPaymentOptionSelected: {
    borderColor: ({ token }) => token('color.border.input.selected')
  },

  lockIcon: {
    color: ({ token }) => token('legacy.color.blue.greyLight'),
    marginRight: ({ token }) => token('spacing.xs')
  }
});

const createPaymentOptionForm = {
  name: 'createPaymentOptionForm',
  mapPropsToValues: () => ({
    name: ''
  })
};

@withToken
@withModel(sessionModel)
@withError('errorModal')
@injectStripe
@withForm(createPaymentOptionForm)
@styled(defaultStyles)
@withQuery(paymentOptionsQuery)
@autobind
class ChangeCreditCardModal extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      selected: props?.paymentOption?.id
    };
  }

  // eslint-disable-next-line camelcase
  createPaymentOption({ name, stripe_id, privateCard }) {
    const { paymentOptions, session } = this.props;
    return paymentOptions.createItem({
      data: {
        name,
        provider: {
          id: 'stripe'
        },
        type: {
          id: 'card'
        },
        source: {
          stripe_id
        },
        ...(privateCard
          ? {
              private_to_user: {
                id: session.user.id
              }
            }
          : {})
      }
    });
  }

  handleSubmit() {
    const { paymentOptions, errorModal, createPaymentOptionForm, stripe } =
      this.props;
    const { selected } = this.state;

    this.setState({ loading: true }, () => {
      if (selected === 'new') {
        const name = createPaymentOptionForm.values.name;
        stripe.createToken({ name }).then(({ token, error }) => {
          if (!token || error) {
            errorModal.open(error?.message || 'Stripe validation error!');
            this.setState({ loading: false });
            return;
          }
          this.createPaymentOption({
            name,
            stripe_id: token.id,
            privateCard: createPaymentOptionForm.values.private
          })
            .then(({ data }) => this.close(data))
            .catch((e) => {
              errorModal.open(e.message);
              this.setState({ loading: false });
            });
        });
      } else {
        const paymentOption = (paymentOptions?.list?.items ?? []).find(
          (po) => po.id === selected
        );
        this.close(paymentOption);
      }
    });
  }

  close(paymentOption) {
    const { onSubmit, closeModal, errorModal } = this.props;
    if (onSubmit) {
      onSubmit(paymentOption)
        .then(closeModal)
        .catch((e) => {
          errorModal.open(e.message);
          this.setState({ loading: false });
        });
    } else {
      closeModal(paymentOption);
    }
  }

  // This is a pre-handleSubmit function that handles 'name' validation manually due to
  // stripe inputs requiring separate validation (that is triggered in handleSubmit)
  onSubmit() {
    const { createPaymentOptionForm } = this.props;
    const { selected } = this.state;

    if (selected === 'new') {
      if (!createPaymentOptionForm.touched.name)
        createPaymentOptionForm.setFieldTouched('name', true);

      if (createPaymentOptionForm.values.name === '') {
        createPaymentOptionForm.setFieldError('name', 'Name is required');
      } else {
        createPaymentOptionForm.setFieldError('name', null);
      }
    }
    this.handleSubmit();
  }

  render() {
    const {
      closeModal,
      paymentOptions,
      createPaymentOptionForm,
      styles: s,
      errorModal: { Error },
      token
    } = this.props;
    const { selected, loading } = this.state;

    return (
      <Box>
        <Body grey>
          Select a payment method you previously defined or set up a new one.
          The change will apply only to this campaign.
        </Body>

        <RenderLoading isLoading={paymentOptions?.list?.status === 'loading'}>
          <Box flexDirection='column' style={{ width: '100%' }}>
            <Box pb={token('spacing.xxl')}>
              {(paymentOptions?.list?.items ?? []).map((po) => (
                <PaymentPreview
                  key={po.id}
                  type={po?.type?.id}
                  availableFunds={po?.available_funds}
                  provider={po?.attributes?.card?.brand}
                  number={po?.attributes?.card?.last_4_digits}
                  primary={po?.is_primary}
                  name={po?.name}
                  expiryDate={`${po?.attributes?.card?.expires_at?.month}/${po?.attributes?.card?.expires_at?.year}`}
                  fullWidth
                  selectable
                  onClick={() => this.setState({ selected: po.id })}
                  selected={selected === po.id}
                  privateUserId={po?.private_to_user?.id}
                />
              ))}
              <Box
                {...s('wrapAddPaymentOption', {
                  wrapAddPaymentOptionSelected: selected === 'new'
                })}
                onClick={() => this.setState({ selected: 'new' })}
                flexDirection='row'
                justifyContent='space-between'
                alignItems='center'
              >
                <Body>Add new payment method</Body>
                <Checkbox
                  id={uuid()}
                  name='expand_payment_method'
                  round
                  value={selected === 'new'}
                />
              </Box>
            </Box>

            {selected === 'new' && (
              <Box mb={token('spacing.xxl')}>
                <Form name='createPaymentOptionForm'>
                  <Heading level={2}>Define a new payment method</Heading>
                  <EditPaymentMethodForm
                    setFieldError={createPaymentOptionForm.setFieldError}
                  />
                </Form>
              </Box>
            )}

            <ModalStickyButtonGroup>
              <GhostButton onClick={() => closeModal()}>Cancel</GhostButton>
              <PrimaryButton
                form='createPaymentOptionForm'
                isLoading={loading}
                isDisabled={!selected}
                onClick={this.onSubmit}
              >
                Update
              </PrimaryButton>
            </ModalStickyButtonGroup>
          </Box>
        </RenderLoading>

        <Error />
      </Box>
    );
  }
}

class Core extends PureComponent {
  render() {
    const { closeModal, campaignName } = this.props;
    return (
      <Modal
        title='Change Payment Method'
        subtitle={campaignName}
        onClose={() => closeModal()}
      >
        <StripeProvider>
          <Elements>
            <ChangeCreditCardModal {...this.props} />
          </Elements>
        </StripeProvider>
      </Modal>
    );
  }
}

export default Core;

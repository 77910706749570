import { track } from '@rexlabs/analytics';
import { Box } from '@rexlabs/box';
import { GhostButton, PrimaryButton } from '@rexlabs/button';
import { query, withModel, withQuery } from '@rexlabs/model-generator';
import { useStyles } from '@rexlabs/styling';
import React, { useRef } from 'react';
import { compose } from 'redux';
import wizardModel from 'src/data/models/custom/wizard';
import campaignsModel from 'src/data/models/entities/campaigns';
import WIZARD from 'src/routes/wizard';
import { formattedBrandName } from 'src/theme';
import { useFeature } from 'src/utils/feature-flags';
import { interpolate, interpolateRegex } from 'src/utils/format';
import { Link } from 'src/utils/whereabouts';
import AudienceMap from 'src/view/components/audience-map';
import { GoogleAdsDisabledBanner as Alert } from 'src/view/components/banner';
import ComponentRender from 'src/view/components/component-renderer';
import ScrollableContent from 'src/view/components/scrollable-content';
import {
  BodyWithHelpIcon,
  Heading,
  Text,
  Tiny
} from 'src/view/components/text';
import withError from 'src/view/containers/with-error';
import { stepNames } from 'src/view/screens/wizard/multi-listing-sold/index';

import ChevronLeft from 'src/assets/icons/chevron-left.svg';
import ChevronRight from 'src/assets/icons/chevron-right.svg';
import { StickyButtonGroup } from 'src/view/components/button';
import { sharedStyles } from '.';

const q = query`{
  ${campaignsModel} (id: ${(props) => props.match.params.campaignId}) {
    id
    type {
      id
    }
    campaign_template {
      type
      campaign_template_configuration {
        id
        configuration
      }
    }
    audience_spec {
      id
      networks
      segments
      age_range {
        youngest
        oldest
      }
      languages
      locations
    }
  }
}`;

const AudienceScreen = ({
  campaigns,
  wizard: { set, step, slug },
  error: { Error }
}) => {
  const s = useStyles({}, undefined, sharedStyles);
  const nextClicked = useRef(false);
  const googleAdsEnabled = useFeature('google_ads_enabled');
  const campaignTemplate =
    campaigns.item.data?.campaign_template?.campaign_template_configuration
      ?.configuration;

  return (
    <Box flex={1} flexDirection='column'>
      <ScrollableContent {...s('content')}>
        <ComponentRender
          template={campaignTemplate}
          resource='audience'
          section='overview'
          components={{
            Heading,
            BodyWithHelpIcon,
            AudienceMap,
            Alert,
            Tiny,
            Text
          }}
          extraPropsFn={({ type, props }) => {
            let extraProps;

            if (type === 'Alert') {
              extraProps = {
                hide: googleAdsEnabled
              };
            }

            if (type === 'BodyWithHelpIcon') {
              extraProps = Object.keys(props).reduce((acc, key) => {
                const prop = props[key];
                if (interpolateRegex.test(prop)) {
                  acc[key] = interpolate(prop, { formattedBrandName });
                }
                return acc;
              }, {});
            }

            if (type === 'AudienceMap') {
              extraProps = {
                campaigns,
                slug
              };
            }

            return extraProps;
          }}
        />
      </ScrollableContent>

      <StickyButtonGroup>
        <Box flex={1}>
          <GhostButton
            IconLeft={ChevronLeft}
            onClick={() => {
              track({
                event: 'Spoke campaign back',
                properties: {
                  action: 'back_clicked',
                  step_name: stepNames[step - 1].replace(/-/g, '_')
                }
              });
              set({ step: step - 1 });
            }}
          >
            Back
          </GhostButton>
        </Box>
        <Link to={WIZARD.EDIT_AUDIENCE}>
          {({ onClick }) => (
            <GhostButton
              data-testid={
                campaigns.item.status === 'loaded' && 'audience-overview-loaded'
              }
              onClick={(e) => {
                track({
                  event: 'Spoke campaign audience viewed',
                  properties: {
                    action: 'edit_audience_clicked',
                    step_name: stepNames[step - 1].replace(/-/g, '_')
                  }
                });
                onClick(e);
              }}
              data-intercom-target='editAudience'
            >
              Edit audience
            </GhostButton>
          )}
        </Link>
        <PrimaryButton
          IconRight={ChevronRight}
          onClick={() => {
            if (nextClicked.current) return;
            nextClicked.current = true;

            track({
              event: 'Spoke campaign next',
              properties: {
                action: 'next_clicked',
                step_name: stepNames[step - 1].replace(/-/g, '_'),
                success: true
              }
            });
            set({ step: step + 1 });
          }}
        >
          Next
        </PrimaryButton>
      </StickyButtonGroup>

      <Error />
    </Box>
  );
};

export default compose(
  withError(),
  withQuery(q),
  withModel(wizardModel)
)(AudienceScreen);

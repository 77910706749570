import { faker } from '@faker-js/faker';
import { merge } from 'lodash';
import { VIDEO_STATUSES, Video } from 'src/features/uploads';
import { PartialDeep } from 'type-fest';
import { libraryImageGenerator } from './Image';

export const videoGenerator = ({
  thumbnail,
  ...rest
}: PartialDeep<Video> = {}): Video => {
  const dimension = faker.datatype.number(1000);
  const fake: Video = {
    id: faker.datatype.uuid(),
    status: faker.helpers.arrayElement(VIDEO_STATUSES),
    name: faker.system.commonFileName('mp4'),
    url: `${faker.internet.url()}/${faker.system.commonFileName('mp4')}`,
    thumbnail: libraryImageGenerator(thumbnail),
    meta: {
      duration: `${faker.datatype.number(60)}`,
      dimensions: {
        aspect_ratio: 1,
        width: dimension,
        height: dimension
      }
    }
  };

  return merge(fake, rest);
};

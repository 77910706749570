import { StyleSheet, useStyles } from '@rexlabs/styling';
import React, { forwardRef } from 'react';
import { useFormControlContext } from './FormControl';

const styles = StyleSheet({
  indicator: {
    color: ({ token }) => token('palette.red.500'),
    marginInlineStart: ({ token }) => token('spacing.xxxs')
  }
});

export type RequiredIndicatorProps = JSX.IntrinsicElements['span'] &
  Record<string, unknown>;

export const RequiredIndicator = forwardRef<
  HTMLSpanElement,
  RequiredIndicatorProps
>((props, ref) => {
  const field = useFormControlContext();
  const s = useStyles(styles);

  if (!field?.isRequired) return null;

  const { className, style, ...rest } = field?.getRequiredIndicatorProps(
    props,
    ref
  );

  return <span {...s.with('indicator')({ className, style })} {...rest} />;
});

import CSSAnimationGroup from '@rexlabs/css-animation-group';
import { StyleSheet, useStyles, useToken } from '@rexlabs/styling';
import React, { ComponentProps, FC } from 'react';
import { BORDER_RADIUS, EASINGS, FONT, KEYFRAMES } from 'src/theme';

import { Logo } from 'src/components/elements/logo';

const defaultStyles = StyleSheet({
  container: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: '7.2rem',
    height: '7.2rem',
    marginRight: ({ token }) => token('spacing.m'),
    background: ({ token }) => token('legacy.color.grey.medium'),
    backgroundSize: 'cover',
    borderRadius: BORDER_RADIUS.INPUT,
    position: 'relative',
    flexShrink: 0,
    color: ({ token }) => token('legacy.color.white'),
    overflow: 'hidden'
  },

  wide: {
    width: '14.8rem',
    height: '8.2rem',
    marginRight: ({ token }) => token('spacing.xl')
  },

  image: {
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    background: 'transparent no-repeat center center',
    backgroundSize: 'cover'
  },

  imageContain: {
    backgroundSize: 'contain'
  },

  sold: {
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    zIndex: 2
  },

  soldTag: {
    padding: ({ token }) => token('spacing.xxs'),
    paddingLeft: ({ token }) => token('spacing.xs'),
    paddingRight: ({ token }) => token('spacing.xs'),
    background: ({ token }) => token('legacy.color.red.default'),
    color: ({ token }) => token('legacy.color.white'),
    fontWeight: FONT.WEIGHTS.BOLD,
    fontSize: '1.3rem',
    textTransform: 'uppercase',
    borderRadius: BORDER_RADIUS.INPUT
  },

  logo: {
    width: '3.5rem',
    color: '#fff',

    '> .mark-stroke': {
      fill: ({ token }) => token('legacy.color.grey.medium')
    }
  }
});

export type AvatarProps = ComponentProps<'div'> & {
  src?: string;
  isSold?: boolean;
  round?: boolean | string | 'full';
  wide?: boolean;
  imageContain?: boolean;
  bgColor?: string;
};

const Avatar: FC<AvatarProps> = ({
  src,
  isSold = false,
  round = false,
  wide = false,
  imageContain = false,
  bgColor = 'transparent',
  className,
  style,
  ...rest
}) => {
  const s = useStyles(defaultStyles);
  const token = useToken();

  const borderRadius =
    typeof round === 'boolean'
      ? round
        ? '7.2rem'
        : undefined
      : round === 'full'
      ? '9999px'
      : token(`border.radius.${round}`);

  return (
    <div
      {...s.with('container', { wide })({
        className,
        style: {
          ...style,
          borderRadius
        }
      })}
      {...rest}
    >
      <Logo type='mark' {...s('logo')} />
      <div
        {...s.with('image', { imageContain })({
          backgroundImage: src ? `url(${src})` : 'none',
          backgroundColor: bgColor
        })}
      />
      <CSSAnimationGroup
        enterOnAppear
        enterAnimation={KEYFRAMES.MODAL_FADE_IN}
        enterDuration={'300ms'}
        enterTimingFunction={EASINGS.MODAL_FADE_IN}
        leaveAnimation={KEYFRAMES.MODAL_FADE_OUT}
        leaveDuration={'300ms'}
        leaveTimingFunction={EASINGS.MODAL_FADE_OUT}
      >
        {isSold ? (
          <div {...s('sold')}>
            <div {...s('soldTag')}>Sold</div>
          </div>
        ) : null}
      </CSSAnimationGroup>
    </div>
  );
};

export default Avatar;

import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import Box from '@rexlabs/box';
import { styled, StyleSheet } from '@rexlabs/styling';
import ScaledText from '../../scaled-text';
import _ from 'lodash';
import { COLORS } from 'src/theme';

export const types = {
  DEFAULT: 'default',
  LARGE: 'large'
};

const fontFamily = 'Lato, sans-serif';
const defaultStyles = StyleSheet({
  mobileBanner: {
    border: '1px solid darkgray',
    overflow: 'hidden',
    display: 'flex'
  },
  primaryImage: {
    width: '102px',
    height: '50px',
    objectFit: 'cover'
  },
  content: {
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'flex-start',
    backgroundColor: 'white',
    padding: '16px 10px'
  },
  shortHeadline: {
    fontSize: '15px',
    fontWeight: 400,
    lineHeight: '19px',
    fontFamily
  }
});

@styled(defaultStyles)
export default class MobileBanner extends PureComponent {
  static propTypes = {
    bannerAdText: PropTypes.string,
    shortHeadline: PropTypes.string,
    brandColor: PropTypes.string,
    backgroundColor: PropTypes.string,
    primaryImageUrls: PropTypes.object,
    type: PropTypes.oneOf(Object.values(types))
  };

  static defaultProps = {
    type: types.DEFAULT
  };

  dimensions() {
    const { type } = this.props;
    switch (type) {
      case types.LARGE:
        return { width: '320px', height: '50px' };
      case types.DEFAULT:
      default:
        return { width: '300px', height: '50px' };
    }
  }

  render() {
    const { shortHeadline, primaryImageUrls, styles: s } = this.props;

    return (
      <Box {...s.with('mobileBanner')({ ...this.dimensions() })}>
        <img
          {...s('primaryImage')}
          src={_.get(primaryImageUrls, ['crop.landscape', 'url'])}
        />
        <Box {...s('content')}>
          <ScaledText
            maxWidth={175}
            maxTextLines={2}
            maxFontSize={15}
            minFontSize={12}
            {...s.with('shortHeadline')({ color: COLORS.BLACK })}
          >
            {shortHeadline}
          </ScaledText>
        </Box>
      </Box>
    );
  }
}

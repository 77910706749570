import { StyleSheet } from '@rexlabs/styling';

export const sharedStyles = StyleSheet({
  content: {
    maxHeight: ({ token }) =>
      `calc(100vh - ${token('topNavBar.height', '8rem')} - (2 * ${token(
        'spacing.s'
      )}) - 5rem)`
  }
});

import React, { PureComponent } from 'react';
import { padding, StyleSheet } from '@rexlabs/styling';
import DefaultButton from './default';

const defaultButtonStyles = StyleSheet({
  container: {
    background: ({ token }) => token('legacy.color.white'),
    color: ({ token }) => token('legacy.color.blue.grey'),
    height: '3.6rem',
    display: 'inline-flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
    flexShrink: 0,
    border: '.1rem solid currentColor',
    transition: 'color .2s',
    ...padding.styles({
      x: 'm'
    }),

    ':hover': {
      color: ({ token }) => token('legacy.color.primary.default')
    }
  }
});

class CopyTextButton extends PureComponent {
  render() {
    return <DefaultButton {...this.props} styles={defaultButtonStyles} />;
  }
}

export default CopyTextButton;

import { margin, StyleSheet, useStyles } from '@rexlabs/styling';
import { Body, Heading } from '@rexlabs/text';
import React, { CSSProperties, FC, ReactNode } from 'react';

const styles = StyleSheet({
  container: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'stretch',
    flex: '1 1 auto',
    minHeight: 0,
    width: '99.2rem',
    ...margin.styles({
      y: 'xxl'
    }),

    '& > * + *': {
      ...margin.styles({
        top: 'xs'
      })
    }
  },

  content: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'stretch',
    overflowY: 'auto',

    '& > * + *': {
      ...margin.styles({
        top: 'xs'
      })
    }
  }
});

export type StepPreviewLayoutProps = {
  children?: ReactNode;
  title: ReactNode;
  subtitle: ReactNode;
  className?: string;
  style?: CSSProperties;
};

export const StepPreviewLayout: FC<StepPreviewLayoutProps> = ({
  title,
  subtitle,
  className,
  style,
  children
}) => {
  const s = useStyles(styles, 'StepPreviewLayout');

  return (
    <main {...s('container')}>
      <Heading>{title}</Heading>
      <Body grey>{subtitle}</Body>
      <div {...s.with('content')({ className, style })}>{children}</div>
    </main>
  );
};

import { StyleSheet, useStyles } from '@rexlabs/styling';
import React, { cloneElement } from 'react';
import {
  HTMLPolymorphicProps,
  forwardRef,
  poly
} from 'src/lib/polymorphic-factory';
import { getValidChildren } from 'src/utils/react';
import { BreadcrumbLink } from './link';
import { BreadcrumbSeparator } from './separator';
import { BreadcrumbItemOtions } from './types';

const styles = StyleSheet({
  item: {
    display: 'inline-flex',
    alignItems: 'center',
    fontWeight: ({ token }) => token('typography.weight.medium'),
    fontSize: ({ token }) => token('typography.size.m')
  }
});

export type BreadcrumbItemProps = BreadcrumbItemOtions &
  HTMLPolymorphicProps<'li'>;

export const BreadcrumbItem = forwardRef<'li', BreadcrumbItemProps>(
  (
    {
      isCurrentPage,
      separator,
      isFirstChild,
      spacing,
      children,
      className,
      style,
      ...rest
    },
    ref
  ) => {
    const s = useStyles(styles, 'BreadcrumbItem');
    const validChildren = getValidChildren(children);

    const clones = validChildren.map((child) => {
      if (child.type === BreadcrumbLink) {
        return cloneElement(child, { isCurrentPage });
      }

      if (child.type === BreadcrumbSeparator) {
        return cloneElement(child, {
          spacing,
          isCurrentPage,
          children: child.props.children || separator
        });
      }

      return child;
    });

    return (
      <poly.li ref={ref} {...s.with('item')({ className, style })} {...rest}>
        {!isFirstChild ? (
          <BreadcrumbSeparator isCurrentPage={isCurrentPage} spacing={spacing}>
            {separator}
          </BreadcrumbSeparator>
        ) : null}
        {clones}
      </poly.li>
    );
  }
);

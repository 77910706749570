import { Box } from '@rexlabs/box';
import { useToken } from '@rexlabs/styling';
import moment from 'moment';
import React from 'react';
import { Body, Tiny } from 'src/view/components/text';

import CalendarIcon from 'src/assets/icons/calendar.svg';

function DateRange({ start, end }) {
  const token = useToken();
  return (
    <Box>
      <Tiny grey Tag='span'>
        Date range
      </Tiny>
      <br />
      <Box alignItems='center'>
        <Body Tag='span'>
          {moment(start).format('D MMM YYYY')} -{' '}
          {moment(end).format('D MMM YYYY')}
        </Body>
        <CalendarIcon
          style={{
            height: '1.6rem',
            width: 'auto',
            marginLeft: token('spacing.xs'),
            color: token('color.textStyle.body.subtext')
          }}
        />
      </Box>
    </Box>
  );
}

export default DateRange;

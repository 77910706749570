import { Box } from '@rexlabs/box';
import { DestructiveButton, GhostButton } from '@rexlabs/button';
import { query, withQuery } from '@rexlabs/model-generator';
import { autobind } from 'core-decorators';
import React, { PureComponent } from 'react';
import agentsModel from 'src/data/models/entities/agents';
import { withToken } from 'src/theme';
import { RenderLoading } from 'src/view/components/loading';
import { Modal } from 'src/view/components/modal';
import { Body } from 'src/view/components/text';
import withError from 'src/view/containers/with-error';

const q = query`{
  ${agentsModel} (id: ${(p) => p?.agentId}) {
    id
    full_name
  }
}`;

@withToken
@withError('errorModal')
@withQuery(q)
@autobind
class DeleteAgentModal extends PureComponent {
  state = {
    loading: false
  };

  handleSubmit() {
    const { agents, closeModal, errorModal, agentId } = this.props;
    this.setState({ loading: true }, () => {
      agents
        .trashItem({
          id: agentId
        })
        .then(() => {
          closeModal({ id: agentId });
        })
        .catch((e) => {
          errorModal.open(e);
          this.setState({ loading: false });
        });
    });
  }

  render() {
    const {
      agents,
      closeModal,
      errorModal: { Error },
      token
    } = this.props;

    return (
      <Modal
        title='Delete Agent'
        subtitle={agents?.item?.data?.full_name}
        width='50rem'
        onClose={() => closeModal({ id: null })}
      >
        <RenderLoading isLoading={agents?.item?.status === 'loading'}>
          <Box p={token('spacing.xxl')} style={{ textAlign: 'center' }}>
            <Body>
              Are you sure you want to delete the agent{' '}
              <b>{agents?.item?.data?.full_name}</b>?
            </Body>
            <Body>
              This will not remove the agent from existing campaigns, but the
              agent will not appear as an option for future campaigns anymore.
            </Body>
            <Box justifyContent='center' mt={token('spacing.xxl')}>
              <GhostButton onClick={() => closeModal({ id: null })}>
                Cancel
              </GhostButton>
              <Box ml={token('spacing.xs')}>
                <DestructiveButton
                  onClick={this.handleSubmit}
                  isLoading={this.state.loading}
                >
                  Delete
                </DestructiveButton>
              </Box>
            </Box>
          </Box>
        </RenderLoading>

        <Error />
      </Modal>
    );
  }
}

export default DeleteAgentModal;

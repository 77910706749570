import { query } from '@rexlabs/model-generator';
import campaignsModel from 'src/data/models/entities/campaigns';

export const campaignLoadAll = (id) => query`{
  ${campaignsModel} (id: ${id}) {
    id
    name
    status {
      id
      label
    }
    duration {
      total
      spent
      unit
    }
    active_package {
      id
      name
    }
    audience_spec {
      id
      networks
      segments
      age_range {
        youngest
        oldest
      }
      languages
      locations
    }
    ad_content_sets {
      id
      type {
        id
        label
      }
      attributes
      final_url
    }
  }
}`;

export const campaignQuery = (getId) => query`{
  ${campaignsModel} (id: ${getId}) {
    id
    type
    name
    status {
      id
      label
    }
    attributes {
      is_sold
    }
    duration {
      total
      spent
      unit
    }
    active_package {
      id
      name
      type {
        id
      }
    }
    ad_content_sets {
      id
      type {
        id
        label
      }
      attributes
      final_url
    }
    images
    agency_logo
    agent_images
  }
}`;

export const campaignAdQuery = (getId) => query`{
  ${campaignsModel} (id: ${getId}) {
    id
    name
    lead_generation_form_enabled
    status {
      id
    }
    campaign_template {
      type
      campaign_template_configuration {
        id
        configuration
      }
    }
    campaign_definition {
      ad_types
      name
    }
    ad_content_sets {
      id
      type {
        id
        label
      }
      attributes
      final_url
    }
    audience_spec {
      id
      networks
      segments
      age_range {
        youngest
        oldest
      }
      languages
      locations
    }
    type
    images
    videos
    agency_logo
    agent_images
    landing_pages {
      domain
      is_external
      external_url
    }
    content_source {
      listings {
        id
        beds
        baths
        cars
      }
      entities
    }
  }
}`;

export const campaignsLandingPageQuery = (getId) => query`{
  ${campaignsModel} (id: ${getId}) {
    id
    type
    campaign_template {
      type
      campaign_template_configuration {
        id
        configuration
      }
    }
    campaign_definition {
      name
    }
    statement_of_information
    agency_logo
    content_source {
      listings {
        id
        data_source
      }
      entities
    }
    landing_pages {
      id
      is_external
      external_url
      lead_capture_email
      domain
      branding_overrides {
        brand_colour
        background_colour
        logo
      }
      virtual_tour_url
      template {
        id
      }
      template_variables {
        maximum_leads {
          lead_capture_form_heading
          lead_capture_form_sub_heading
          lead_capture_form_text
        }
        qualitative_leads {
          lead_capture_form_heading
          lead_capture_form_sub_heading
          lead_capture_form_text
        }
        multi_listing_sold {
          page_headline
          lead_capture_form_heading
          lead_capture_form_sub_heading
          lead_capture_form_button_text
          contact_name
          contact_email
          contact_phone
        }
      }
      agent {
        id
      }
    }
  }
}`;

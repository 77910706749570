import config from 'src/config';

function domain() {
  switch (config.STAGE) {
    case 'production':
      return 'https://app.spokeapp.io';
    case 'beta':
      return 'https://beta.spokeapp.io';
    case 'alpha':
      return 'https://app.dev.spokeapp.io';
    default:
      return 'localhost:3000';
  }
}

export default domain;

import { AudienceHealth, AudienceSpec } from '../types';

export function calcAudienceHealth({
  geoRange = 10,
  suburbs = [],
  segments = [],
  generalAudiences = [],
  retargetingAudiences = [],
  ageRange
}: AudienceSpec): AudienceHealth {
  const formattedSegments = segments.filter((s) => s !== 'my_database');

  const selectedSegments = formattedSegments.length;
  const totalSegments = [...generalAudiences, ...retargetingAudiences].length;

  // red range 1 - 2 ticks (0 - 35deg)
  // green range 2 - 8 ticks (38 - 141deg)
  // yellow range 8 - 10 ticks (145 - 180deg)
  let value = 0;
  const tickValue = 180 / 10;

  const startingPoint =
    geoRange <= 9
      ? ((tickValue * 2) / 8) * (geoRange - 1)
      : geoRange <= 20
      ? tickValue * 4 + ((tickValue * 2) / 10) * (geoRange - 10)
      : Math.min(180, tickValue * 8 + ((tickValue * 2) / 20) * (geoRange - 20));

  const suburbsTicks = suburbs.length * 0.5;
  const exceedsGreenZone =
    startingPoint + tickValue * suburbsTicks > tickValue * 7.5;

  if (exceedsGreenZone) {
    if (suburbs.length <= 5) {
      value = tickValue * 7.5;
    } else {
      const suburbTicksAtGreenLimit =
        (tickValue * 7.5 - startingPoint) / tickValue;
      value =
        tickValue * 7.5 +
        tickValue * (suburbsTicks - Math.max(2.5, suburbTicksAtGreenLimit));
    }
  } else {
    value = startingPoint + tickValue * suburbsTicks;
  }

  const [oldest, youngest] = ageRange ?? [0, 0];
  const ageRangeDiff = oldest - youngest;
  const ageRangeTicks = ageRangeDiff < 5 ? -1 : ageRangeDiff < 15 ? 0 : 1;

  const segmentTicks =
    selectedSegments <= 1 ? -1 : selectedSegments === totalSegments ? 1 : 0;

  value += tickValue * (ageRangeTicks + segmentTicks);
  value = Math.max(0, Math.min(180, value));

  return { value: value / tickValue, degrees: value, version: '0.0.1' };
}

import React from 'react';
import { TooltipStateful as VividTooltipStateful } from '@rexlabs/tooltip';
import { StyleSheet } from '@rexlabs/styling';
import { FONT } from 'src/theme';

const tooltipStyles = StyleSheet({
  tooltip: {
    fontWeight: `${FONT.WEIGHTS.LIGHT} !important`,
    fontSize: '1.4rem !important',
    lineHeight: '1.8rem !important',
    color: ({ token }) => `${token('legacy.color.blue.grey')} !important`,
    maxWidth: '23rem !important'
  }
});

function TooltipStateful(props) {
  const { show = true, children, ...restProps } = props;

  if (show) {
    return (
      <VividTooltipStateful
        styles={tooltipStyles}
        placement='bottom'
        openOn='HOVER'
        closeOn='HOVER'
        {...restProps}
      >
        {children}
      </VividTooltipStateful>
    );
  }

  return children;
}

export default TooltipStateful;
